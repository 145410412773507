import React, { useState, useEffect } from 'react';
import { useTheme, Grid, CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';
import DrawerLayout from '../Global/DrawerLayout';
import axios from 'axios';
import StateManager from '../Global/StateManager';
import { SupportIcon } from '../Global/Icons';
import { useParams } from 'react-router-dom';
import TicketManager from '../../Components/Global/TicketManager';

export default function Support() {
  return (
    <DrawerLayout title="Support" height={'100%'} fillHeight icon={<SupportIcon />}>
      <MyTickets />
    </DrawerLayout>
  );
}

function MyTickets() {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newTicketOpen, setNewTicketOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState();
  const [openTicket, setOpenTicket] = useState(false);
  const theme = useTheme();
  const { id } = useParams();

  useEffect(() => {
    refresh();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function refresh() {
    await axios
      .get('/support/getUserTickets')
      .then((res) => {
        setTickets(res.data);
        if (id) {
          let selected = res.data.find((t) => t._id === id);
          if (selected) open(selected);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        StateManager.setErrorAlert('Failed to load tickets');
        setLoading(false);
      });
  }

  function closeNewTicket(ticket) {
    setNewTicketOpen(false);
    if (ticket?._id) {
      setTickets([ticket, ...tickets]);
    }
  }

  function open(ticket) {
    setSelectedTicket(ticket);
    setOpenTicket(true);
  }

  function closeTicket(res) {
    if (res?.canceled === true) {
      setTickets([...tickets.filter((x) => x._id !== selectedTicket._id)]);
    }
    setOpenTicket(false);
  }

  if (loading) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '85vh' }}>
        <CircularProgress style={{ color: blue[500] }} />
      </Grid>
    );
  }

  return <TicketManager ticketList={tickets} refresh={refresh} loading={loading} setLoading={setLoading} />;
}
