import { BASE_URL } from '../../../constants';

export const upload_handler = (blobInfo, success, failure, progress) => {
  var xhr, formData;
  const baseUpload = '/uploader/upload-single';
  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', BASE_URL + baseUpload);
  xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);

  xhr.upload.onprogress = function (e) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function () {
    var json;

    if (xhr.status === 403) {
      failure(`HTTP Error: ${xhr.status}`, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure(`HTTP Error: ${xhr.status}`);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.file.location != 'string') {
      failure(`Invalid JSON: ${xhr.responseText}`);
      return;
    }

    success(json.file.location);
  };

  xhr.onerror = function () {
    failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`);
  };

  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};
