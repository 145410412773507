import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { useTheme } from '@mui/material';
import { Grid, Typography } from '@mui/material';

import Field from './field';

export default function Fields({ onItemsChange, initial, initialParams, disabled }) {
  const [items, setItems] = useState([]);
  const theme = useTheme();

  const userId = localStorage.getItem('_id');

  useEffect(() => {
    if (!Array.isArray(initial)) return;
    setItems(initial);
  }, [initial]);

  function saveItem(itemId, field, value) {
    let update = [...items];
    let index = update.findIndex((x) => x.id === itemId);
    if (index > -1) {
      update[index][field] = value;
    }
    setItems(update);
    if (initialParams.length === 0) {
      onItemsChange(update);
    } else {
      let update = [...initialParams];
      let index = update.findIndex((x) => x.id === itemId);
      if (index > -1) {
        update[index][field] = value;
      }
      onItemsChange(update);
    }
  }

  function getInitialValue(item) {
    const filledItem = initialParams?.findIndex((x) => x.id === item.id);
    if (filledItem > -1) {
      return initialParams[filledItem]?.value;
    } else {
      return item.title;
    }
  }

  return (
    <Grid container>
      {Array.isArray(items) && items.length > 0 && (
        <Grid container item sx={{ padding: theme.spacing(1, 0) }}>
          {items.map((item, index) => (
            <Grid container item alignItems="center" key={index}>
              <Grid item sm={true} align="end">
                <Typography variant="h6" align="center" color="textSecondary">
                  {index + 1}
                </Typography>
              </Grid>
              <Grid item sm={11}>
                <Field
                  item={item}
                  initial={getInitialValue(item)}
                  onEdit={saveItem}
                  disabled={(item.createdBy && item.createdBy !== userId) || disabled}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}
