import { DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { useTheme } from '@mui/material';
import { FieldBuilder } from '../../../Global/Fields';
import { checkFields } from '../../../Global/Functions';
import { isArray } from 'lodash';

export default function EditTaskFieldsDialog({ initial, open, onClose, onResult, forPortal }) {
  const [items, setItems] = useState([]);
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setItems(isArray(initial) ? initial : []);
  }, [initial]);

  function save() {
    const check = checkFields(items);
    if (check.error) {
      StateManager.setErrorAlert(check.error);
      return;
    }

    onResult(items);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={!largeDevices}>
      <DialogTitle>Task fields</DialogTitle>

      <DialogContent>
        <FieldBuilder
          initial={items}
          onChange={setItems}
          text
          forPortal={forPortal}
          form={!forPortal}
          ru={!forPortal}
          withGlobal={!forPortal}
          process
          info
        />
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={save} />
    </RoundedDialog>
  );
}
