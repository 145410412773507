import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControlLabel, Collapse, DialogContent, Switch, Tabs, Tab } from '@mui/material';
import { Checkbox } from '@mui/material';
import { CheckCircleRounded, CheckCircleOutlineRounded } from '@mui/icons-material';
import { User, RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import { DatePicker, TimePicker } from '../../../Global/Components';
import { grey, green } from '@mui/material/colors';
import { ProcessIcon, FormIcon, TaskIcon } from '../../../Global/Icons';

import { isArray, isEmpty, range } from 'lodash';
import moment from 'moment';

export default function FormActionsDialog({ open, onClose, onResult, actions }) {
  const defaultDueDate = moment().add(1, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 });
  const [completeActionIndex, setCompleteActionIndex] = useState(0);
  const [completeActionsParams, setCompleteActionsParams] = useState(null);

  const completeActions = isArray(actions) ? actions : [];
  const currCompleteAction = completeActions[completeActionIndex];

  const currCompleteActionFilled =
    currCompleteAction &&
    (!currCompleteAction.provideUserChoice ||
      (completeActionsParams &&
        completeActionsParams[currCompleteAction.id] &&
        !isEmpty(completeActionsParams[currCompleteAction.id].users)));

  const hideDone = !currCompleteActionFilled;

  function done() {
    if (completeActionIndex < completeActions.length - 1) {
      setCompleteActionIndex(completeActionIndex + 1);
    } else {
      onResult({ completeActionsParams });
      onClose();
    }
  }

  useEffect(() => {
    setCompleteActionIndex(0);
  }, [actions]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth className="scroll-bar">
      {currCompleteAction && (
        <DialogContent>
          <Grid container item style={{ margin: '1rem 0' }} wrap="nowrap" alignItems="center">
            {currCompleteAction.actionType === 'startProcess' && <ProcessIcon />}
            {currCompleteAction.actionType === 'startForm' && <FormIcon />}
            {currCompleteAction.actionType === 'sendTask' && <TaskIcon />}
            <Typography style={{ marginLeft: 8, whiteSpace: 'break-spaces' }}>
              Completing this form will{' '}
              {currCompleteAction.actionType === 'startProcess' && (
                <>
                  start process: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.processTitle}</span>
                </>
              )}
              {currCompleteAction.actionType === 'startForm' && (
                <>
                  start form entry: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.formTitle}</span>
                </>
              )}
              {currCompleteAction.actionType === 'sendTask' && (
                <>
                  send task: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.task?.title}</span>
                </>
              )}
            </Typography>
          </Grid>
          {currCompleteAction.provideUserChoice &&
            currCompleteAction.userType === 'selectedUsers' &&
            !isEmpty(currCompleteAction.users) &&
            isArray(currCompleteAction.users) && (
              <Grid container item style={{ margin: '1rem 0' }}>
                <Typography style={{ fontWeight: 500 }} gutterBottom>
                  Pick users:
                </Typography>
                <Grid container item>
                  {currCompleteAction.users.map((userId, i) => (
                    <User
                      id={userId}
                      key={i}
                      fullWidth
                      markPortal
                      onClick={() => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id] }
                            : {};
                        const users = isArray(update.users) ? update.users : [];

                        if (users.includes(userId)) {
                          update.users = users.filter((x) => x !== userId);
                        } else {
                          update.users = [...users, userId];
                        }
                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                      action={
                        completeActionsParams &&
                        isArray(completeActionsParams[currCompleteAction.id]?.users) &&
                        completeActionsParams[currCompleteAction.id].users.includes(userId) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          {currCompleteAction.startAnytime && false && (
            <Grid container item style={{ margin: '0.5rem 0' }}>
              {currCompleteAction.actionType !== 'sendTask' && (
                <Grid container item style={{ paddingBottom: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={Boolean(
                          completeActionsParams && completeActionsParams[currCompleteAction.id]?.startLater,
                        )}
                        onChange={(e) => {
                          const update =
                            completeActionsParams && completeActionsParams[currCompleteAction.id]
                              ? { ...completeActionsParams[currCompleteAction.id], startLater: e.target.checked }
                              : { startLater: e.target.checked };

                          setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                        }}
                      />
                    }
                    label={
                      currCompleteAction.actionType === 'startProcess'
                        ? 'Start process later'
                        : currCompleteAction.actionType === 'startForm'
                        ? 'Start form later'
                        : 'Send task later'
                    }
                  />
                </Grid>
              )}

              <Grid container>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={Boolean(completeActionsParams && completeActionsParams[currCompleteAction.id]?.dueAt)}
                      onChange={() => {
                        const dueAt = completeActionsParams
                          ? completeActionsParams[currCompleteAction.id]?.dueAt
                          : null;

                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id] }
                            : {};

                        update.dueAt = dueAt ? null : currCompleteAction.presetTime || defaultDueDate;

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  }
                  label="Include due date"
                />
              </Grid>
              <Collapse
                unmountOnExit
                style={{ width: '100%' }}
                in={Boolean(completeActionsParams && completeActionsParams[currCompleteAction.id]?.dueAt)}
              >
                <Grid container>
                  <Grid item container xs={6} style={{ paddingRight: 4 }}>
                    <DatePicker
                      value={completeActionsParams ? completeActionsParams[currCompleteAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id], dueAt }
                            : { dueAt };

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  </Grid>

                  <Grid item container xs={6} style={{ paddingLeft: 4 }}>
                    <TimePicker
                      value={completeActionsParams ? completeActionsParams[currCompleteAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id], dueAt }
                            : { dueAt };

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          )}
        </DialogContent>
      )}

      <StandardDialogActions onClose={onClose} onDone={done} hideDone={hideDone} />
    </RoundedDialog>
  );
}
