import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  DialogContent,
  IconButton,
  Button,
  MenuItem,
  Menu,
  TextField,
  Divider,
  CircularProgress,
  Chip,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Card,
  Paper,
  CardActions,
  CardContent,
  Hidden,
  CardActionArea,
  useMediaQuery,
  Tooltip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { DialogTitle, List, Grid, ListItem, ListItemSecondaryAction, useTheme } from '@mui/material';
import {
  Add,
  AddBoxOutlined,
  DeleteOutline,
  InfoOutlined,
  FileCopy,
  FiberManualRecord,
  Clear,
  Done,
  CheckCircle,
  AddRounded,
} from '@mui/icons-material';
import Layout from '../Global/Layout';
import axios from 'axios';
import ContentEditor from '../Global/ContentEditor';
import FileViewer from '../Global/FileViewer';
import Uploader from '../Global/Uploader';
import ConfirmDialog from '../Global/ConfirmDialog';
import { User, RoundedDialog, HtmlContent, StandardDialogActions, HtmlText } from '../Global/Components';
import { grey, red, blue, green } from '@mui/material/colors';
import { FormatDate } from '../Global/Functions';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import PanoramaIcon from '@mui/icons-material/Panorama';
import PieChartIcon from '@mui/icons-material/PieChart';
import PollIcon from '@mui/icons-material/Poll';
import { v4 } from 'uuid';
import StateManager from '../Global/StateManager';

const userId = localStorage.getItem('_id');

export default function NoticeBoard() {
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [newElementOpen, setNewElementOpen] = useState(false);
  const [post, setPost] = useState(null);
  const [columnDeleteConfirm, setColumnDeleteConfirm] = useState(false);
  const [columnToDelete, setColumnToDelete] = useState();
  const [postDeleteConfirm, setPostDeleteConfirm] = useState(false);
  const [postToDelete, setPostToDelete] = useState();
  const [postOpen, setPostOpen] = useState(false);

  useEffect(() => {
    axios
      .get(`/noticeBoard/getBoard`)
      .then((res) => {
        let data = res.data;
        let cols = [];
        data.columns.forEach((c) => {
          let col = {
            _id: c._id,
            title: c.title,
            created_by: c.created_by,
            posts: data.posts.filter((x) => x.column === c._id),
          };
          cols.push(col);
        });
        setColumns(cols);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  function deleteColumn() {
    axios
      .post('/noticeBoard/deleteColumn', { id: columnToDelete._id })
      .then((res) => {
        setColumns([...columns.filter((x) => x._id !== res.data.id)]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deletePost() {
    let column = postToDelete.column;
    axios
      .post('/noticeBoard/deletePost', { id: postToDelete._id })
      .then((res) => {
        let index = columns.findIndex((x) => x._id === column);
        if (index > -1 && columns[index].posts) {
          columns[index].posts = columns[index].posts.filter((x) => x._id !== res.data.id);
        }
        setColumns([...columns]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function readPost(id, columnId) {
    axios.post('/noticeBoard/readPost', { id });
    let cols = columns;
    let index = cols.findIndex((x) => x._id === columnId);
    if (index > -1 && cols[index].posts) {
      let postIndex = cols[index].posts.findIndex((x) => x._id === id);
      if (postIndex > -1) {
        cols[index].posts[postIndex].read = true;
        setColumns([...cols]);
      }
    }
  }

  function closeNewElement(res) {
    if (res) {
      if (res.column) {
        let cols = columns;
        cols.push(res.column);
        setColumns([...cols]);
      }

      if (res.post) {
        let cols = columns;
        let index = cols.findIndex((x) => x._id === res.post.column);
        if (index > -1) {
          let col = cols[index];
          if (col.posts) col.posts.push(res.post);
          else col.posts = [res.post];
        }
        setColumns([...cols]);
      }
    }
    setNewElementOpen(false);
  }

  return (
    <Layout title="Notice Board">
      {loading ? (
        <Grid container style={{ height: '80vh' }}>
          <CircularProgress style={{ margin: 'auto', color: '#6200ea' }} />
        </Grid>
      ) : (
        <Grid container>
          <Grid container item xs={12} style={{ overflow: 'auto' }} alignItems={'flex-start'}>
            {columns.map((column) => (
              <Grid container item key={column._id} lg={3} md={4} sm={12} sx={{ p: 2 }} alignItems={'flex-start'}>
                <Card
                  elevation={3}
                  sx={{
                    p: 1,
                    height: 'fit-content',
                    width: '100%',
                  }}
                >
                  <Grid
                    container
                    item
                    alignItems="center"
                    style={{ paddingRight: userId === column.created_by ? 48 : 0, minHeight: 48 }}
                  >
                    {userId === column.created_by && (
                      <IconButton
                        onClick={() => {
                          setColumnToDelete(column);
                          setColumnDeleteConfirm(true);
                        }}
                      >
                        <DeleteOutline style={{ color: red[500] }} />
                      </IconButton>
                    )}
                    <Typography variant="h5" style={{ margin: '0 auto' }} noWrap>
                      {column.title}
                    </Typography>
                  </Grid>
                </Card>
                {column.posts &&
                  column.posts.map((post) => (
                    <Post
                      post={post}
                      key={post._id}
                      onDelete={() => {
                        setPostToDelete(post);
                        setPostDeleteConfirm(true);
                      }}
                      viewPost={() => {
                        setPost(post);
                        setPostOpen(true);
                      }}
                    />
                  ))}
              </Grid>
            ))}
          </Grid>

          <PostViewer
            open={postOpen}
            onClose={() => setPostOpen(false)}
            post={post}
            onRead={() => readPost(post?._id, post?.column)}
            setPost={setPostOpen}
          />

          <Hidden smDown>
            <Paper
              style={{
                borderRadius: '50%',
                position: 'fixed',
                bottom: 0,
                right: 0,
                margin: '2em',
                ZIndex: 10,
                background: '#002171',
              }}
              elevation={12}
            >
              <IconButton onClick={() => setNewElementOpen(true)} style={{ margin: '0.3em' }}>
                <Add fontSize="large" style={{ color: 'white' }} />
              </IconButton>
            </Paper>
          </Hidden>
          <NewElementDialog open={newElementOpen} columns={columns} onClose={closeNewElement} />

          <ConfirmDialog
            open={columnDeleteConfirm}
            onClose={() => setColumnDeleteConfirm(false)}
            title={`You are about to delete column ${columnToDelete?.title}`}
            content="Are you sure you want to continue?"
            onConfirm={deleteColumn}
          />

          <ConfirmDialog
            open={postDeleteConfirm}
            onClose={() => setPostDeleteConfirm(false)}
            title={`You are about to delete post ${postToDelete?.title}`}
            content="Are you sure you want to continue?"
            onConfirm={deletePost}
          />
        </Grid>
      )}
    </Layout>
  );
}

function Post({ post, onDelete, viewPost }) {
  const [info, setInfo] = useState(false);

  return (
    <Card elevation={3} sx={{ width: '100%', mt: 2 }} style={{ background: post.read ? '' : '#eceff1' }}>
      <CardActionArea onClick={viewPost}>
        <CardContent>
          <Grid container item alignItems="center">
            {!post.read && <FiberManualRecord style={{ color: blue[500], marginRight: '1rem' }} />}
            <Typography variant="h5" gutterBottom noWrap>
              {post.title}
            </Typography>
          </Grid>
          {post.type === 'info' && (
            <Grid container item>
              <HtmlText
                value={post.content}
                style={{
                  height: '25vh',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            </Grid>
          )}
          {post.type === 'pic' && (
            <Grid container item style={{ height: '25vh' }}>
              <img
                src={post.file.location}
                alt=""
                style={{ maxHeight: '90%', cursor: 'pointer', maxWidth: '100%', margin: 'auto' }}
              />
            </Grid>
          )}
          {post.type === 'doc' && (
            <Grid container style={{ height: '25vh' }}>
              <Grid style={{ height: '100%', cursor: 'pointer', width: '100%', margin: 'auto', display: 'flex' }}>
                <Grid style={{ margin: 'auto', display: 'flex' }}>
                  <FileCopy style={{ color: '#2196f3', margin: 'auto 1rem auto 0' }} />
                  <Typography style={{ margin: 'auto 0em', fontSize: 26, fontWeight: 700 }}>
                    {`${post.attached.length} file${post.attached.length > 1 ? 's' : ''}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {post.type === 'poll' && (
            <Grid container item>
              <HtmlContent
                content={post.content}
                style={{
                  height: '25vh',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              />
            </Grid>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <User id={post.created_by} />
        {userId === post.created_by && (
          <>
            <IconButton onClick={onDelete} style={{ marginLeft: 'auto' }}>
              <DeleteOutline style={{ color: red[500] }} />
            </IconButton>
            <IconButton onClick={() => setInfo(true)}>
              <InfoOutlined style={{ color: grey[600] }} />
            </IconButton>
            <Receipts receipts={post?.receipts} open={info} onClose={() => setInfo(false)} />
          </>
        )}
      </CardActions>
    </Card>
  );
}

function NewElementDialog(props) {
  const iconStyle = { width: 200, height: 120, color: grey[400] };
  const [columnDialogOpen, setColumnDialogOpen] = useState(false);
  const [newInfoPost, setNewInfoPost] = useState(false);
  const [newPicPost, setNewPicPost] = useState(false);
  const [newDocPost, setNewDocPost] = useState(false);
  const [newPoll, setNewPoll] = useState(false);

  function setNewColumn(column) {
    setColumnDialogOpen(false);
    if (column) {
      props.onClose({ column });
    }
  }

  return (
    <RoundedDialog open={props.open} maxWidth="md" onClose={() => props.onClose(null)}>
      <DialogContent>
        <Grid container>
          <Grid container item alignItems="center" justifyContent={'space-between'}>
            <Typography variant="h6">Select a type of post</Typography>

            <Button
              sx={{ textTransform: 'none' }}
              startIcon={<AddBoxOutlined />}
              onClick={() => setColumnDialogOpen(true)}
            >
              Or add a new column
            </Button>
            <NewColumnDialog open={columnDialogOpen} onClose={setNewColumn} />
          </Grid>
          <Grid container item style={{ margin: '1rem 0' }}>
            <Grid container item>
              <Grid item lg={4} md={4} sm={12}>
                <CardActionArea style={{ borderRadius: '0.5rem' }} onClick={() => setNewInfoPost(true)}>
                  <CardContent>
                    <Grid container item justifyContent="center">
                      <TextFieldsIcon style={iconStyle} />
                    </Grid>
                    <Grid container item justifyContent="center">
                      <Typography style={{ fontSize: 14 }}>Information</Typography>
                    </Grid>
                  </CardContent>
                </CardActionArea>
                <NewInfoPostDialog
                  columns={props.columns}
                  open={newInfoPost}
                  onClose={(post) => {
                    setNewInfoPost(false);
                    if (post) props.onClose({ post });
                  }}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={12}>
                <CardActionArea style={{ borderRadius: '0.5rem' }}>
                  <Chip
                    style={{
                      position: 'absolute',
                      right: 0,
                      background: blue[400],
                      margin: 8,
                    }}
                    label="Coming soon"
                  />
                  <CardContent>
                    <Grid container item justifyContent="center">
                      <LinkIcon style={iconStyle} />
                    </Grid>
                    <Grid container item justifyContent="center">
                      <Typography style={{ fontSize: 14 }}>Link to something</Typography>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid item lg={4} md={4} sm={12}>
                <CardActionArea style={{ borderRadius: '0.5rem' }} onClick={() => setNewDocPost(true)}>
                  <CardContent>
                    <Grid container item justifyContent="center">
                      <DescriptionIcon style={iconStyle} />
                    </Grid>
                    <Grid container item justifyContent="center">
                      <Typography style={{ fontSize: 14 }}>Documents</Typography>
                    </Grid>
                  </CardContent>
                </CardActionArea>
                <NewDocPostDialog
                  columns={props.columns}
                  open={newDocPost}
                  onClose={(post) => {
                    setNewDocPost(false);
                    if (post) props.onClose({ post });
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item style={{ margin: '1rem 0' }}>
              <Grid item lg={4} md={4} sm={12}>
                <CardActionArea style={{ borderRadius: '0.5rem' }} onClick={() => setNewPicPost(true)}>
                  <CardContent>
                    <Grid container item justifyContent="center">
                      <PanoramaIcon style={iconStyle} />
                    </Grid>
                    <Grid container item justifyContent="center">
                      <Typography style={{ fontSize: 14 }}>Picture</Typography>
                    </Grid>
                  </CardContent>
                </CardActionArea>
                <NewPicturePostDialog
                  columns={props.columns}
                  open={newPicPost}
                  onClose={(post) => {
                    setNewPicPost(false);
                    if (post) props.onClose({ post });
                  }}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={12}>
                <CardActionArea style={{ borderRadius: '0.5rem' }}>
                  <Chip
                    style={{
                      position: 'absolute',
                      right: 0,
                      background: blue[400],
                      margin: 8,
                    }}
                    label="Coming soon"
                  />
                  <CardContent>
                    <Grid container item justifyContent="center">
                      <PieChartIcon style={iconStyle} />
                    </Grid>
                    <Grid container item justifyContent="center">
                      <Typography style={{ fontSize: 14 }}>KPI</Typography>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Grid>

              <Grid item lg={4} md={4} sm={12}>
                <CardActionArea style={{ borderRadius: '0.5rem' }} onClick={() => setNewPoll(true)}>
                  <CardContent>
                    <Grid container item justifyContent="center">
                      <PollIcon style={iconStyle} />
                    </Grid>
                    <Grid container item justifyContent="center">
                      <Typography style={{ fontSize: 14 }}>Poll</Typography>
                    </Grid>
                  </CardContent>
                </CardActionArea>
                <NewPollDialog
                  columns={props.columns}
                  open={newPoll}
                  onClose={() => setNewPoll(false)}
                  onResult={(post) => props.onClose({ post })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </RoundedDialog>
  );
}

function NewColumnDialog(props) {
  const [title, setTitle] = useState('');
  const [saving, setSaving] = useState(false);

  function saveColumn() {
    setSaving(true);
    axios
      .post(`/noticeBoard/createColumn`, {
        title: title,
      })
      .then((res) => {
        setSaving(false);
        props.onClose(res.data);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        props.onClose(false);
      });
    props.onClose();
  }

  return (
    <RoundedDialog elevation={26} open={props.open} maxWidth="xs" fullWidth>
      <DialogTitle>Add a new column</DialogTitle>
      <DialogContent>
        <TextField
          label="Column title"
          fullWidth
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          sx={{ my: 2 }}
        />
      </DialogContent>
      <StandardDialogActions
        onClose={() => props.onClose(null)}
        saving={saving}
        hideDone={!title}
        onDone={saveColumn}
      />
    </RoundedDialog>
  );
}

function NewInfoPostDialog(props) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [column, setColumn] = useState(null);
  const [columnMenu, setColumnMenu] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setTitle('');
    setContent('');
  }, [props.open]);

  function savePost() {
    setSaving(true);
    axios
      .post(`/noticeBoard/createInfoPost`, {
        title,
        content,
        columnId: column._id,
      })
      .then((res) => {
        setSaving(false);
        props.onClose(res.data);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        props.onClose(false);
      });
  }

  return (
    <RoundedDialog elevation={26} open={props.open} maxWidth="lg">
      <DialogTitle>Information post</DialogTitle>
      <DialogContent>
        <Grid container direction={'column'}>
          <TextField
            variant="outlined"
            label="Post title"
            sx={{ mb: 3, mt: 1 }}
            value={title}
            id="post-title"
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
          />
          <ContentEditor handleEditorChange={(text) => setContent(text)} id="42" />
          <Grid style={{ display: 'flex', margin: '2em 0px' }}>
            <Typography style={{ fontSize: 14, color: 'lightgray', margin: 'auto 0px' }}>
              Pick a column this post will come under
            </Typography>
            <Button
              onClick={(event) => setColumnMenu(event.currentTarget)}
              variant="outlined"
              style={{ textTransform: 'none', marginLeft: '2em' }}
            >
              {column ? column.title : 'Select column'}
            </Button>
            <Menu anchorEl={columnMenu} keepMounted open={Boolean(columnMenu)} onClose={() => setColumnMenu(null)}>
              {props.columns.map((col) => (
                <MenuItem
                  key={col._id}
                  onClick={() => {
                    setColumn(col);
                    setColumnMenu(null);
                  }}
                  style={{ padding: '0.5em' }}
                >
                  {col.title}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        onClose={() => props.onClose(null)}
        onDone={savePost}
        hideDone={!(title && content && column)}
        saving={saving}
      />
    </RoundedDialog>
  );
}

function NewPicturePostDialog(props) {
  const [title, setTitle] = useState('');
  const [caption, setCaption] = useState('');
  const [column, setColumn] = useState(null);
  const [columnMenu, setColumnMenu] = useState(null);
  const [picture, setPicture] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setTitle('');
    setCaption('');
    setColumn(null);
    setPicture(null);
  }, [props.open]);

  function savePost() {
    setSaving(true);
    axios
      .post(`/noticeBoard/createPicPost`, {
        title,
        caption,
        picture,
        columnId: column._id,
      })
      .then((res) => {
        setSaving(false);
        props.onClose(res.data);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        props.onClose(false);
      });
  }

  function upload(event) {
    if (!event.target.files) return;

    const data = new FormData();
    data.append('file', event.target.files[0]);

    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        setPicture(res.data.file);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <RoundedDialog elevation={26} open={props.open} maxWidth="md" onClose={() => props.onClose(null)} fullWidth>
      <DialogTitle>Image post</DialogTitle>
      <DialogContent>
        <Grid container direction={'column'}>
          <TextField
            variant="outlined"
            label="Post title"
            sx={{ mb: 3, mt: 1 }}
            value={title}
            id="post-title"
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
          />

          <Grid container sx={{ my: 3, minHeight: '25vh' }} justifyContent={'center'} alignItems={'center'}>
            {picture ? (
              <img src={picture.location} alt={picture.originalname} style={{ maxWidth: '100%' }} />
            ) : (
              <Button
                component="label"
                style={{ fontSize: 32, textTransform: 'none' }}
                startIcon={<AddRounded style={{ fontSize: 32 }} />}
              >
                <input onChange={upload} style={{ display: 'none' }} type="file" accept="image/*" />
                Choose an image
              </Button>
            )}
          </Grid>

          <Grid container item>
            <TextField
              variant="outlined"
              label="Caption"
              fullWidth
              id="image-caption"
              onChange={(event) => setCaption(event.target.value)}
            />
          </Grid>

          <Grid container item sx={{ my: 2 }}>
            <Typography style={{ fontSize: 14, color: 'lightgray', margin: 'auto 0px' }}>
              Pick a column this post will come under
            </Typography>
            <Button
              onClick={(event) => setColumnMenu(event.currentTarget)}
              variant="outlined"
              style={{ textTransform: 'none', marginLeft: '2em' }}
            >
              {column ? column.title : 'Select column'}
            </Button>
            <Menu anchorEl={columnMenu} keepMounted open={Boolean(columnMenu)} onClose={() => setColumnMenu(null)}>
              {props.columns.map((col) => (
                <MenuItem
                  key={col._id}
                  onClick={() => {
                    setColumn(col);
                    setColumnMenu(null);
                  }}
                  style={{ padding: '0.5em' }}
                >
                  {col.title}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        saving={saving}
        onClose={() => props.onClose(null)}
        onDone={savePost}
        hideDone={!(title && column)}
      />
    </RoundedDialog>
  );
}

function NewPollDialog({ columns, open, onClose, onResult }) {
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [options, setOptions] = useState([]);
  const [optionText, setOptionText] = useState('');
  const [columnMenu, setColumnMenu] = useState(null);
  const [column, setColumn] = useState(null);

  useEffect(() => {
    if (!open) return;

    setSaving(false);
    setDescription('');
    setOptions([]);
    setTitle('');
  }, [open]);

  function addOption() {
    if (!optionText) return;
    options.push({ id: v4(), text: optionText });
    setOptions(options);
    setOptionText('');
  }

  function save() {
    if (!title) {
      StateManager.setErrorAlert('Provide title for the poll');
      return;
    }
    if (!description) {
      StateManager.setErrorAlert('Provide description for the poll');
      return;
    }
    if (!column) {
      StateManager.setErrorAlert('Pick a column this post will come under');
      return;
    }
    if (options.length < 2) {
      StateManager.setErrorAlert('Provide at least two options');
      return;
    }

    setSaving(true);

    axios
      .post('/noticeBoard/createPollPost', {
        title,
        description,
        options,
        columnId: column._id,
      })
      .then((res) => {
        setSaving(false);
        onResult(res.data);
        onClose();
      })
      .catch((err) => {
        StateManager.setErrorAlert('Failed to create a poll');
        setSaving(false);
      });
  }

  function deleteOption(id) {
    setOptions(options.filter((x) => x.id !== id));
  }

  return (
    <RoundedDialog
      open={open}
      disableBackdropClick={saving}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className="scroll-bar"
    >
      <DialogTitle>New poll</DialogTitle>
      <DialogContent>
        <Grid container item>
          <TextField
            variant="outlined"
            label="Post title"
            sx={{ mb: 3, mt: 1 }}
            value={title}
            id="post-title"
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid container item style={{ marginTop: '1rem' }}>
          <HtmlContent
            editable
            content={description}
            onEdit={setDescription}
            onSave={setDescription}
            title="Poll description"
            placeholder="Provide description for the poll"
          />
        </Grid>
        <Grid container item lg={6} md={8} sm={12} style={{ marginTop: '3rem' }}>
          <Typography variant="h6" gutterBottom>
            Options:
          </Typography>
          <List style={{ width: '100%' }}>
            {options.map((option) => (
              <ListItem key={option.id}>
                <ListItemIcon>
                  <FiberManualRecord style={{ color: blue[500] }} />
                </ListItemIcon>
                <ListItemText primary={option.text} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => deleteOption(option.id)}>
                    <DeleteOutline style={{ color: red[500] }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid container item alignItems="center" lg={7} md={8} sm={12}>
          <TextField
            style={{ flexGrow: 1, marginRight: 10, marginLeft: '1rem' }}
            placeholder="Write option title"
            autoFocus
            autoComplete="off"
            value={optionText}
            onChange={(e) => setOptionText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addOption();
              }
            }}
            inputProps={{ maxLength: 128 }}
            variant="standard"
          />
          <Tooltip title="Done" placement="top">
            <IconButton onClick={addOption}>
              <Done style={{ color: green[500] }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel" placement="top">
            <IconButton onClick={() => setOptionText('')}>
              <Clear style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid container item alignItems="center" style={{ marginTop: '1rem' }}>
          <Typography color="textSecondary">Pick a column this post will come under</Typography>
          <Button
            onClick={(event) => setColumnMenu(event.currentTarget)}
            variant="outlined"
            style={{ textTransform: 'none', marginLeft: '2em', borderRadius: '0.5rem' }}
          >
            {column ? column.title : 'Select column'}
          </Button>
          <Menu anchorEl={columnMenu} keepMounted open={Boolean(columnMenu)} onClose={() => setColumnMenu(null)}>
            {columns.map((col) => (
              <MenuItem
                key={col._id}
                onClick={() => {
                  setColumn(col);
                  setColumnMenu(null);
                }}
              >
                <ListItemText primary={col.title} />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </DialogContent>
      <StandardDialogActions saving={saving} onClose={onClose} onDone={save} />
    </RoundedDialog>
  );
}

function NewDocPostDialog(props) {
  const [title, setTitle] = useState('');
  const [caption, setCaption] = useState('');
  const [column, setColumn] = useState(null);
  const [columnMenu, setColumnMenu] = useState(null);
  const files = useRef([]);
  // for spinner
  const [saving, setSaving] = useState(false);

  function savePost() {
    setSaving(true);
    axios
      .post(`/noticeBoard/createDocPost`, {
        title,
        caption,
        files: files.current.map((x) => x.file),
        columnId: column._id,
      })
      .then((res) => {
        setSaving(false);
        props.onClose(res.data);
        files.current = [];
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        props.onClose(false);
        files.current = [];
      });
  }

  return (
    <RoundedDialog elevation={26} open={props.open} maxWidth="md" onClose={() => props.onClose(null)} fullWidth={true}>
      <DialogTitle>Document post</DialogTitle>
      <DialogContent>
        <Grid container direction={'column'}>
          <TextField
            variant="outlined"
            label="Post title"
            sx={{ mb: 3, mt: 1 }}
            value={title}
            id="post-title"
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
          />

          <Grid>
            <Uploader
              buttonText="Upload files"
              onResult={(file) => files.current.push(file)}
              onCancel={(id) => (files.current = files.current.filter((f) => f.id !== id))}
            />

            <Grid container item>
              <TextField
                variant="outlined"
                label="Caption"
                fullWidth
                value={caption}
                onChange={(event) => setCaption(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container item style={{ margin: '2em 0px' }}>
            <Typography style={{ fontSize: 14, color: 'lightgray', margin: 'auto 0px' }}>
              Pick a column this post will come under
            </Typography>
            <Button
              onClick={(event) => setColumnMenu(event.currentTarget)}
              variant="outlined"
              style={{ textTransform: 'none', marginLeft: '2em' }}
            >
              {column ? column.title : 'Select column'}
            </Button>
            <Menu anchorEl={columnMenu} keepMounted open={Boolean(columnMenu)} onClose={() => setColumnMenu(null)}>
              {props.columns.map((col) => (
                <MenuItem
                  key={col._id}
                  onClick={() => {
                    setColumn(col);
                    setColumnMenu(null);
                  }}
                  style={{ padding: '0.5em' }}
                >
                  {col.title}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={() => props.onClose(null)} onDone={savePost} hideDone={!(title && column)} />
    </RoundedDialog>
  );
}

function PostViewer({ post, open, onClose, onRead, setPost }) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (post && !post.read) {
      onRead();
    }
  }, [post]); // eslint-disable-line react-hooks/exhaustive-deps

  function vote(optionId) {
    const vote = {
      id: v4(),
      userId,
      optionId,
    };
    if (post.votes) {
      post.votes.push(vote);
    } else {
      post.votes = [vote];
    }
    setPost(post);

    axios
      .post('/noticeBoard/votePost', {
        postId: post._id,
        optionId,
      })
      .then(() => {
        StateManager.setSuccessAlert('Vote has been counted');
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to count a vote');
        post.votes = post.votes.filter((p) => p.id !== post.id);
        setPost(post);
      });
  }

  if (!post) return null;

  return (
    <RoundedDialog open={open} maxWidth="md" onClose={onClose} fullWidth fullScreen={!largeDevices}>
      <DialogTitle>{post.title}</DialogTitle>
      <DialogContent style={{ padding: '2em' }}>
        <Typography variant="h5" gutterBottom>
          {post.title}
        </Typography>
        {(() => {
          if (post.type === 'info') {
            return <Grid dangerouslySetInnerHTML={{ __html: post.content }} />;
          }
          if (post.type === 'pic') {
            return (
              <Grid style={{ textAlign: 'center' }}>
                <img src={post.file?.location} alt="" style={{ width: '100%' }} />
                <Typography style={{ margin: '2rem 0' }}>{post.caption}</Typography>
              </Grid>
            );
          }
          if (post.type === 'doc') {
            return (
              <Grid>
                <FileViewer files={post.attached} title="Attached" />
                <Typography style={{ margin: '2rem 0' }}>{post.caption}</Typography>
              </Grid>
            );
          }
          if (post.type === 'poll') {
            return (
              <Grid container item>
                <Grid container item>
                  <HtmlContent content={post.content} />
                </Grid>
                <Grid container item style={{ margin: '1rem 0' }}>
                  {post.votes && post.votes.findIndex((p) => p.userId === userId) > -1 ? (
                    <List style={{ width: '100%' }}>
                      {post.options.map((option) => {
                        let votes = post.votes.filter((v) => v.optionId === option.id);
                        let percent = Math.round((votes.length / post.votes.length) * 100);
                        return (
                          <ListItem key={option.id}>
                            <ListItemIcon>
                              {votes.findIndex((p) => p.userId === userId) > -1 ? (
                                <CheckCircle fontSize="small" style={{ color: green[500] }} />
                              ) : (
                                <FiberManualRecord style={{ color: blue[500] }} />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={option.text}
                              secondary={`${percent}% (${votes.length} vote${votes.length === 1 ? '' : 's'})`}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  ) : (
                    <FormControl component="fieldset" variant="standard">
                      <RadioGroup>
                        {post.options.map((option) => (
                          <FormControlLabel
                            onClick={() => vote(option.id)}
                            value={option.id}
                            control={<Radio color="primary" />}
                            label={option.text}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            );
          }
          return null;
        })()}
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          <>
            <User id={post.created_by} avatarSize={30} />
            <Typography color={'textSecondary'} sx={{ fontSize: 14, mr: 'auto', ml: 1 }}>
              {`Posted ${FormatDate(post.createdAt)}`}
            </Typography>
          </>
        }
        onClose={onClose}
      />
    </RoundedDialog>
  );
}

function Receipts({ receipts, open, onClose }) {
  if (!receipts || receipts.length === 0) {
    return (
      <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>Read by:</DialogTitle>
        <DialogContent>
          <Grid style={{ height: '20vh' }}>
            <Typography variant="h6">No one has read this post yet</Typography>
          </Grid>
        </DialogContent>
      </RoundedDialog>
    );
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth className="scroll-bar">
      <DialogTitle>Read by:</DialogTitle>
      <DialogContent>
        <List style={{ height: '30vh', overflow: 'auto', minWidth: '20vw' }}>
          {receipts.map((receipt, i) => (
            <React.Fragment key={receipt.id}>
              <User key={receipt.id} id={receipt.userId} />
              {i !== receipts.length - 1 && <Divider light />}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
    </RoundedDialog>
  );
}
