import React from 'react';

import { useDispatch } from 'react-redux';
import { VFORMAT, formatMiniRevisionIteration } from '../../constants';
import { previewFile } from '../../redux/actions/file';
import axios from 'axios';

import { COLORS } from '../../constants';

import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import DescriptionIcon from '@mui/icons-material/Description';

import { FileCell } from '../IssueComponents';
import StateManager from '../../../../Global/StateManager';

const SupportingDocsTable = ({ document }) => {
  const dispatch = useDispatch();

  const openFile = (file) => {
    let selected = {
      category: 'normal',
      file: file,
      title: document.title,
      encrypted: file?.encrypted,
    };
    // check if file has property encrypted
    if (file?.encrypted) {
      dispatch(previewFile(selected));
    } else {
      axios
        .get(`/uploader/getEncryptedStatus/${file.id}`)
        .then((res) => {
          const encrypted = res.data.encrypted;
          selected.encrypted = encrypted;
          dispatch(previewFile(selected));
        })
        .catch((err) => StateManager.setAxiosErrorAlert(err));
    }
  };

  return (
    <TableContainer sx={{ my: 1 }}>
      <Table aria-label="revision table table" size="small">
        <TableBody>
          {document?.supportingDocuments.map((x, i) => (
            <TableRow key={i}>
              <TableCell align="left" padding="none">
                {formatMiniRevisionIteration(
                  x.baseVersion,
                  document?.revisionFormat || VFORMAT,
                  x.version || 1,
                  document?.manualIssueControl,
                  document?.manualIssue,
                )}
              </TableCell>
              <TableCell align="left" cellSpan="4" padding="none">
                <FileCell user={x.uploadedBy} />
              </TableCell>
              <TableCell align="right">
                <IconButton size="small" onClick={() => openFile(x)}>
                  <DescriptionIcon sx={{ color: COLORS.SEARCH }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SupportingDocsTable;
