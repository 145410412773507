import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import { TextField, CircularProgress, Grid, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import StateManager from '../StateManager';

const normalIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SearchItem({ dense, url, mode = 'get', title, lazy, icon, onResult }) {
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [text, setText] = useState('');
  const timer = useRef();

  useEffect(() => {
    setOptions([]);
    setValue([]);
    setText('');
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    if (!lazy) {
      setLoading(true);
      if (mode === 'get') {
        axios
          .get(url)
          .then((res) => {
            setOptions(res.data.spaces ?? res.data);
            setLoading(false);
          })
          .catch((err) => {
            StateManager.setAxiosErrorAlert(err);
            setLoading(false);
          });
      } else {
        axios
          .post(url)
          .then((res) => {
            setOptions(res.data.areas ?? res.data.folders ?? res.data.assets);
            setLoading(false);
          })
          .catch((err) => {
            StateManager.setAxiosErrorAlert(err);
            setLoading(false);
          });
      }
    }
  }, [url, lazy, mode]);

  function search(text) {
    if (!text) {
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get(url, { params: { text } })
      .then((res) => {
        setOptions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }
  function triggerTimer(text) {
    setText(text);
    if (!lazy) return;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      search(text);
    }, 800);
  }

  return (
    <Grid container justifyContent="center" sx={{ padding: dense ? 0 : 2 }}>
      <Autocomplete
        multiple
        fullWidth
        options={options}
        inputValue={text}
        value={value}
        onInputChange={(e) => {
          if (!e) return;
          triggerTimer(e.target.value);
        }}
        onChange={(e, x) => {
          setValue(x);
          setText((x?.title ?? x?.name) || '');
          onResult(x);
        }}
        getOptionLabel={(option) => option.title ?? option.name}
        noOptionsText={text ? (loading ? 'Loading...' : 'Nothing has been found') : 'Type to search'}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option._id}>
            <Grid container alignItems="center" wrap="nowrap">
              <Checkbox
                icon={normalIcon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              {icon}
              <Typography style={{ marginLeft: '1rem' }} noWrap>
                {option.title ?? option.name}
              </Typography>
            </Grid>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  {icon}
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
}
