import React, { useState, useEffect } from 'react';
import { green, red, grey } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Avatar, TextField, MenuItem, IconButton } from '@mui/material';
import { Typography, Button, FormControlLabel, Checkbox, Collapse } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { RoundedDialog, UserAutocomplete, UserChip, GroupChip, PortalGroupChip } from '../../../Global/Components';
import { StandardDialogActions } from '../../../Global/Components';
import { Done, DeleteOutline, Public } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import StateManager from '../../../Global/StateManager';
import axios from 'axios';
import { v4 } from 'uuid';
import { isEmpty } from 'lodash';

const notifyOptions = [
  { id: 'notification', text: 'Notification' },
  { id: 'both', text: 'Notification & Email' },
];

const availableTriggers = [
  { id: 'stepStarted', text: 'Step started' },
  { id: 'stepCompleted', text: 'Step completed' },
  { id: 'stepOverdue', text: 'Step overdue' },
];

const triggers = [
  { id: 'stepCompleted', text: 'Step completed' },
  { id: 'stepOverdue', text: 'Step overdue' },
];

export default function StepNotificationsDialog({ open, onClose, ongoingStepId }) {
  const [notifyList, setNotifyList] = useState(null);
  const [newEntry, setNewEntry] = useState({ triggerId: '', notifyOptionId: 'notification' });
  const [externalDialog, setExternalDialog] = useState(false);
  const [externalUsers, setExternalUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const theme = useTheme();

  const load = open && notifyList == null && !loading && !error;

  useEffect(() => {
    if (!load || !ongoingStepId) return;

    setLoading(true);

    axios
      .get('/process/getStepNotificationsInfo', { params: { ongoingStepId } })
      .then((res) => {
        if (!res.data.notifyList) throw Error('');
        setNotifyList(res.data.notifyList);
        setExternalUsers(res.data.externalUsers || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        StateManager.setErrorAlert('Failed to load step notifications info');
      });
  }, [load, ongoingStepId]);

  function addEntry() {
    if (!newEntry.user) {
      StateManager.setErrorAlert('Provide user');
      return;
    }
    if (!newEntry.triggerId) {
      StateManager.setErrorAlert('Provide trigger');
      return;
    }

    setNotifyList([
      ...notifyList,
      { id: v4(), triggerId: newEntry.triggerId, userId: newEntry.user._id, notifyOptionId: newEntry.notifyOptionId },
    ]);
    setNewEntry({ triggerId: '', notifyOptionId: 'notification' });
  }

  function done() {
    setSaving(true);
    axios
      .post('/process/saveStepNotificationsInfo', { ongoingStepId, notifyList, externalUsers })
      .then(() => {
        onClose();
        StateManager.setSuccessAlert('Settings have been saved');
        setSaving(false);
      })
      .catch((err) => {
        StateManager.setErrorAlert('Failed to save the notification settings');
        console.error(err);
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth className="scroll-bar">
      <DialogTitle>Select users to notify</DialogTitle>
      <DialogContent>
        {loading && (
          <Grid container alignItems="center" justifyContent="center" style={{ height: '35vh' }}>
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!loading && error && (
          <Grid container alignItems="center" justifyContent="center" style={{ height: '35vh' }}>
            <Typography variant="h5" color="textSecondary">
              Something went wrong :-(
            </Typography>
          </Grid>
        )}
        {!loading && !error && notifyList && (
          <Grid container>
            <Grid container item>
              {notifyList.map((item) => (
                <Grid key={item.id} container item sx={{ mx: 1 }}>
                  <Grid container alignItems={'center'}>
                    {item.userId && <UserChip id={item.userId} />}
                    {!isEmpty(item.users) && item.users.map((userId, index) => <UserChip key={index} id={userId} />)}
                    {!isEmpty(item.groups) &&
                      item.groups.map((groupId, index) => <GroupChip key={index} id={groupId} />)}
                    {!isEmpty(item.portalGroups) &&
                      item.portalGroups.map((groupId, index) => <PortalGroupChip key={index} id={groupId} />)}
                  </Grid>

                  <Grid container alignItems={'center'} wrap="nowrap">
                    <Typography noWrap style={{ textTransform: 'lowercase' }}>
                      {item.triggerId === 'stepStarted' ? 'have been' : 'will be'} sent{' '}
                      {notifyOptions.find((t) => t.id === item.notifyOptionId)?.text} when{' '}
                      {availableTriggers.find((t) => t.id === item.triggerId)?.text}
                    </Typography>
                    {item.triggerId !== 'stepStarted' && (
                      <IconButton
                        style={{ marginLeft: 'auto' }}
                        onClick={() => setNotifyList(notifyList.filter((x) => x.id !== item.id))}
                      >
                        <DeleteOutline style={{ color: red[500] }} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ))}
              {externalUsers.map((external, i) => (
                <Grid
                  key={i}
                  container
                  item
                  alignItems="center"
                  wrap="nowrap"
                  style={{ padding: theme.spacing(1, 0), borderBottom: `2px solid ${grey[300]}` }}
                >
                  <Grid item>
                    <Typography>
                      <span style={{ fontWeight: 600 }}>{external.email}</span>{' '}
                      {`will be sent ${external.includePdf ? 'a PDF copy of the process' : 'an email'} when`}{' '}
                      <span style={{ fontWeight: 500, textTransform: 'lowercase', whiteSpace: 'nowrap' }}>
                        {triggers.find((x) => x.id === external.triggerId)?.text}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: 'auto', paddingLeft: '1rem' }}>
                    <IconButton
                      onClick={() => setExternalUsers(externalUsers.filter((x) => x.email !== external.email))}
                    >
                      <DeleteOutline style={{ color: red[500] }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container item alignItems="center" style={{ margin: theme.spacing(2, 0) }}>
              <Grid container item alignItems="center" wrap="nowrap" style={{ margin: theme.spacing(1, 0) }}>
                {newEntry?.user ? (
                  <Avatar src={newEntry?.user.avatar} style={{ background: newEntry?.user.avatarColor }}>
                    {newEntry?.user.avatarLetters}
                  </Avatar>
                ) : (
                  <Avatar />
                )}
                <UserAutocomplete
                  onResult={(user) => setNewEntry({ ...newEntry, user })}
                  style={{ margin: '0 1rem', width: 250 }}
                  selected={newEntry.user}
                />
                <Typography variant="h6">will be sent</Typography>
              </Grid>
              <Grid container item alignItems="center" wrap="nowrap" style={{ margin: theme.spacing(1, 0) }}>
                <TextField
                  select
                  label="Select"
                  value={newEntry.notifyOptionId}
                  onChange={(event) => setNewEntry({ ...newEntry, notifyOptionId: event.target.value })}
                  style={{ marginRight: '1rem', width: 200 }}
                  variant="standard"
                >
                  {notifyOptions.map((trigger) => (
                    <MenuItem key={trigger.id} value={trigger.id}>
                      {trigger.text}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography variant="h6">when</Typography>
                <TextField
                  select
                  label="Select"
                  value={newEntry.triggerId}
                  onChange={(event) => setNewEntry({ ...newEntry, triggerId: event.target.value })}
                  style={{ margin: '0 1rem', width: 150 }}
                  variant="standard"
                >
                  {triggers.map((trigger) => (
                    <MenuItem key={trigger.id} value={trigger.id}>
                      {trigger.text}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  variant="outlined"
                  onClick={addEntry}
                  style={{ textTransform: 'none', borderRadius: 8, marginLeft: 'auto' }}
                  startIcon={<Done style={{ color: green[500] }} />}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <StandardDialogActions
        saving={saving}
        onClose={onClose}
        onDone={done}
        hideDone={!notifyList}
        additionalActions={
          <Button
            onClick={() => setExternalDialog(true)}
            style={{ color: grey[700], marginRight: 'auto', borderRadius: 8 }}
            startIcon={<Public />}
          >
            add external
          </Button>
        }
      />
      <External
        open={externalDialog}
        onClose={() => setExternalDialog(false)}
        onResult={(res) => {
          if (externalUsers.find((x) => x.email === res.email && x.triggerId === res.triggerId)) return;
          setExternalUsers([...externalUsers, res]);
        }}
      />
    </RoundedDialog>
  );
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function External({ open, onClose, onResult }) {
  const [text, setText] = useState('');
  const [error, setError] = useState(false);
  const [triggerId, setTriggerId] = useState(triggers[0].id);
  const [includePdf, setIncludePdf] = useState(false);

  function add() {
    if (!validateEmail(text)) {
      setError(true);
      return;
    }
    onResult({ type: 'external', email: text, triggerId, includePdf: triggerId === 'stepCompleted' && includePdf });
    onClose();
    setText('');
    setError(false);
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Add an email</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item style={{ height: 100 }}>
            <TextField
              error={error}
              variant="outlined"
              label="Email"
              helperText={error ? 'Incorrect email format' : undefined}
              fullWidth
              value={text}
              onChange={(e) => {
                if (error) setError(false);
                setText(e.target.value);
              }}
            />
          </Grid>

          <Grid container item alignItems="center">
            <Typography variant="h6">Send when:</Typography>
            <TextField
              select
              label="Select"
              value={triggerId}
              onChange={(event) => setTriggerId(event.target.value)}
              style={{ margin: '0 1rem', width: 150 }}
              variant="standard"
            >
              {triggers.map((trigger) => (
                <MenuItem key={trigger.id} value={trigger.id}>
                  {trigger.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid container item style={{ paddingTop: '1rem' }}>
            <Collapse in={triggerId === 'stepCompleted'}>
              <FormControlLabel
                control={
                  <Checkbox checked={includePdf} color="primary" onChange={(e) => setIncludePdf(e.target.checked)} />
                }
                label="Would you like to include a PDF copy of the process?"
              />
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={add} hideDone={!text} />
    </RoundedDialog>
  );
}
