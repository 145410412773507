import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// route for all logged in users INCLUDING portal
export default function ({ children, ...rest }) {
  const loggedIn = localStorage && localStorage.getItem('token');

  if (!loggedIn) return <Redirect to="/login" />;

  return <Route {...rest} children={children} />;
}
