import axios from '../../../../../redux/apiCaller';
import { successAction, errorAction, attemptAction } from '../../../../../redux/constants';
import StateManager from '../../../../Global/StateManager';
import {
  DOCOWNERS_ATTEMPT,
  DOCOWNERS_SUCCESS,
  DOCOWNERS_FAIL,
  CLEARMYFILES,
  UPDATEDOCUMENT_ATTEMPT,
  UPDATEDOCUMENT_SUCCESS,
  UPDATEDOCUMENT_FAIL,
  COPYTEMPLATES_ATTEMPT,
  COPYTEMPLATES_SUCCESS,
  COPYTEMPLATES_ERROR,
  ADDDOCUMENTS_SUCCESS,
} from '../constants';

export const fetchOwnerList = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCOWNERS_ATTEMPT));
      const body = await axios.get(`dochub/documents/owners`, { params });
      dispatch(successAction(DOCOWNERS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DOCOWNERS_FAIL, err));
    }
  };
  return thunk;
};

export const clearDocumentState = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(CLEARMYFILES));
  };
  return thunk;
};

export const updateSetupDocument = (document_id, update, history = null) => {
  const thunk = async function thunk(dispatch) {
    const body = await axios.post(`dochub/documents/updateDocument`, { document_id, update });
    try {
      dispatch(attemptAction(UPDATEDOCUMENT_ATTEMPT));
      dispatch(successAction(UPDATEDOCUMENT_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEDOCUMENT_FAIL, err));
    }
    if (history && body.data && body.data.link) {
      setTimeout(() => {
        history.push(body.data.link);
      }, 1000);
    }
  };
  return thunk;
};

export const copyTemplatesToLocation = (data, onRedirect, setDialog) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(COPYTEMPLATES_ATTEMPT));
    try {
      const response = await axios.post(`dochub/documents/templates`, data);
      dispatch(successAction(COPYTEMPLATES_SUCCESS, response.data.result));
      if (response.data.result && onRedirect) {
        let result = response.data.result;
        setDialog(false);
        if (result?.area && result?.folder) {
          onRedirect(`/DocLibrary/areas/${result.area}/${result.folder}`);
        }
        if (result?.area && !result?.folder) {
          onRedirect(`/DocLibrary/areas/${result.area}`);
        }
        if (result?.documents) {
          dispatch(successAction(ADDDOCUMENTS_SUCCESS, { Documents: result?.documents, status: 'Ok' }));
        }
      }
      StateManager.setSuccessAlert(response?.data?.message);
    } catch (error) {
      dispatch(errorAction(COPYTEMPLATES_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const updateOwner = (id, owner, setLoader) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEDOCUMENT_ATTEMPT));
      const response = await axios.post(`dochub/documents/${id}/changeOwner`, { owner });
      dispatch(successAction(UPDATEDOCUMENT_SUCCESS, { Document: response.data?.document }));
      if (setLoader) setLoader(false);
      StateManager.setSuccessAlert(response?.data?.message);
    } catch (error) {
      if (setLoader) setLoader(false);
      dispatch(errorAction(UPDATEDOCUMENT_FAIL, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};
