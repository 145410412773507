import React from 'react';
import { Button, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Grid, Divider } from '@mui/material';
import { User } from '../../../Global/Components';
import { RoundedDialog } from '../../../Global/Components';
import { red } from '@mui/material/colors';

export default function WatchingDialog({ open, onClose, users }) {
  return (
    <RoundedDialog open={open} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle>People watching the task:</DialogTitle>

      <DialogContent>
        <Grid container direction="column">
          {users.map((user, i) => (
            <React.Fragment key={user}>
              <User id={user} />
              {i !== users.length - 1 && <Divider light />}
            </React.Fragment>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} style={{ color: red[500] }}>
          close
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
