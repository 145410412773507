import { ButtonGroup, Popover, Grid, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green } from '@mui/material/colors';
import { lightGreen, lime, yellow, amber, orange, deepOrange } from '@mui/material/colors';
import { DoneRounded, LightMode, DarkMode } from '@mui/icons-material';
import StateManager from '../StateManager';
import axios from 'axios';

export default function ThemePicker({ open, onClose, anchorEl }) {
  const [mode, setMode] = useState('dark');
  const [primary, setPrimary] = useState('blue');

  useEffect(() => {
    StateManager.subscribeToThemeChange((params) => {
      if (!params) return;
      const { mode, color } = params;
      setMode(mode);
      setPrimary(color);
    });
  }, []);

  function saveTheme(mode, color) {
    axios.post('/user/saveUserTheme', { mode, color }).catch((err) => StateManager.setAxiosErrorAlert(err));
  }

  const colours = {
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
  };

  const column = 500;

  const buttonSide = 50;
  const buttonMargin = 3;
  const buttonsPerRow = 4;
  const width = (buttonSide + buttonMargin * 2) * buttonsPerRow;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      className="scroll-bar"
      sx={{
        '.MuiPopover-paper': { width: 'min-content', mt: 5 },
      }}
    >
      <Grid sx={{ p: 1.5 }}>
        <Grid container item>
          <Typography variant="h6" gutterBottom>
            Appearance
          </Typography>
        </Grid>
        <Grid container item direction={'column'} sx={{ maxWidth: width, minWidth: width }}>
          <Typography gutterBottom variant="caption">
            Mode
          </Typography>
          <ButtonGroup variant="outlined" fullWidth>
            <Button
              variant={mode === 'light' ? 'contained' : 'outlined'}
              onClick={() => {
                StateManager.changeTheme('light', primary);
                saveTheme('light', primary);
                setMode('light');
              }}
              startIcon={<LightMode />}
            >
              Light
            </Button>
            <Button
              variant={mode === 'dark' ? 'contained' : 'outlined'}
              onClick={() => {
                StateManager.changeTheme('dark', primary);
                saveTheme('dark', primary);
                setMode('dark');
              }}
              startIcon={<DarkMode />}
            >
              Dark
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid container item sx={{ mt: 1 }}>
          <Grid container item>
            <Typography gutterBottom variant="caption">
              Application colour
            </Typography>
          </Grid>
          <Grid container style={{ maxWidth: width, minWidth: width }}>
            {Object.keys(colours).map((color) => (
              <Grid item key={color}>
                <Button
                  style={{
                    backgroundColor: colours[color][column],
                    borderRadius: 0,
                    width: buttonSide,
                    maxWidth: buttonSide,
                    minWidth: buttonSide,
                    height: buttonSide,
                    margin: buttonMargin,
                  }}
                  onClick={() => {
                    StateManager.changeTheme(mode, color);
                    saveTheme(mode, color);
                    setPrimary(color);
                  }}
                >
                  {color === primary && <DoneRounded sx={{ color: (theme) => theme.palette.background.paper }} />}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
}
