import './style.css';
import React, { useState, useCallback, useEffect, useRef } from 'react';

import { useQuery } from '../../../../constants';
import { useHistory } from 'react-router-dom';

import { Typography, TextField, Paper, IconButton } from '@mui/material';
import { Cancel, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

import Box from '@mui/material/Box';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import { EditorState, SelectionState } from 'draft-js';

import createImagePlugin from '@draft-js-plugins/image';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDividerPlugin from '@draft-js-plugins/divider';
import createTablePlugin from '../../utils/table';

import TOC from '../TOC';

import { styleMap, getBlockStyle, DividerComponent } from '../';
import { decorators } from '../../utils/decorator';
import { createListPlugin } from '../../utils';
import { createWithContent } from '../../utils/editor';
import { generateSearchDecorator } from '../../utils/block';
import { ImageComponent } from '../../utils/image';

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const listPlugin = createListPlugin({
  ulChars: ['-', '*', '•'],
  olChars: ['1.', '1)', 'A.', 'a)'],
});

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);

const dividerDecorator = composeDecorators(focusPlugin.decorator);
const tablePlugin = createTablePlugin({ decorator: dividerDecorator });
const dividerPlugin = createDividerPlugin({ decorator: dividerDecorator, dividerComponent: DividerComponent });
const imagePlugin = createImagePlugin({ decorator, imageComponent: ImageComponent });
const plugins = [
  blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  dividerPlugin,
  listPlugin,
  tablePlugin,
  imagePlugin,
];

export default function EditorInstance({ initial, hasToc, toc, isFirst, isLast, totalSections, setSearch, search }) {
  const editor = useRef(null);
  const containerRef = useRef();

  const highlight = useQuery().get('highlight');
  const history = useHistory();

  const [searchString, setSearchString] = useState(highlight || '');
  const [highlightIndex, setHighlightIndex] = useState(0);
  const [allHighlights, setAllHighlights] = useState([]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = 'RichEditor-editor RichEditor-readOnly';
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  const jumpToBlock = (text, key) => {
    let selection = new SelectionState({
      anchorKey: key, // key of block
      anchorOffset: 0,
      focusKey: key,
      focusOffset: 0, // key of block
      hasFocus: true,
      isBackward: false, // isBackward = (focusOffset < anchorOffset)
    });
    let newSelection = new SelectionState(selection);
    const decorator = generateSearchDecorator(text);
    let updatedState = EditorState.set(editorState, { decorator: decorator });
    updatedState = EditorState.forceSelection(updatedState, newSelection);
    setEditorState(updatedState);
    setTimeout(() => {
      window.getSelection().focusNode.parentElement.scrollIntoView();
      window.getSelection().anchorNode.parentElement.scrollIntoView();
      const scrolled = window.scrollY;
      if (scrolled) {
        window.scroll(0, scrolled - 300);
      }
    }, [750]);
  };

  const clearAll = useCallback(() => {
    setSearchString('');
    setHighlightIndex(0);
    setAllHighlights([]);
    const decorator = generateSearchDecorator('');
    let updatedState = EditorState.set(editorState, { decorator });
    setEditorState(updatedState);
    // remove active highlight
    const currentActiveHighlight = document.querySelector('.search-and-replace-highlight.active-highlight');
    currentActiveHighlight && currentActiveHighlight.classList.remove('active-highlight');
    const path = history.location.pathname.split('?')[0];
    history.replace(path);
  }, [history, editorState]);

  const searchAndHighlight = useCallback(
    (text, currentIndex = 0) => {
      setSearch(true);
      const path = history.location.pathname.split('?')[0];
      const currentTerm = history.location.search.split('?highlight=')[1];
      if (text !== currentTerm) {
        history.replace(path);
      }
      setSearchString(text);
      setHighlightIndex(currentIndex);
      const decorator = generateSearchDecorator(text);
      let updatedState = EditorState.set(editorState, { decorator });
      setEditorState(updatedState);
      if (text === '') {
        setAllHighlights([]);
        return;
      }
      const allHighlights = document.getElementsByClassName('search-and-replace-highlight');
      setAllHighlights(allHighlights);
      const index = currentIndex % allHighlights.length;
      const newActiveHighlight = allHighlights[index];
      const currentActiveHighlight = document.querySelector('.search-and-replace-highlight.active-highlight');
      if (newActiveHighlight && currentActiveHighlight !== newActiveHighlight) {
        currentActiveHighlight && currentActiveHighlight.classList.remove('active-highlight');
        newActiveHighlight.classList.add('active-highlight');
      }
    },
    [history, setSearch, editorState],
  );

  const scrollToHighlight = (index) => {
    setHighlightIndex(index);
    // get active highlight key
    const activeHighlight = document.querySelector('.search-and-replace-highlight.active-highlight');
    const newActiveHighlight = allHighlights[index];
    if (activeHighlight && newActiveHighlight !== activeHighlight) {
      activeHighlight.classList.remove('active-highlight');
    }
    if (newActiveHighlight) {
      newActiveHighlight.classList.add('active-highlight');
      const key = newActiveHighlight.firstChild.getAttribute('data-offset-key').split('-')[0];
      const text = newActiveHighlight.innerText;
      jumpToBlock(text, key);
    }
  };

  useEffect(() => {
    if (!initial) return;
    let state = createWithContent(initial);
    setEditorState(state);
  }, [initial]);

  useEffect(() => {
    setHighlightIndex(0);
    setAllHighlights([]);
  }, []);

  useEffect(() => {
    if (!highlight || allHighlights?.length > 0) return;
    searchAndHighlight(highlight);
  }, [highlight, searchAndHighlight, allHighlights]);

  return (
    <>
      {search && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 5,
            right: 20,
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            p: 0.5,
          }}
          component={Paper}
        >
          <TextField
            value={searchString}
            margin="dense"
            onChange={(e) => searchAndHighlight(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (allHighlights.length > 0 && highlightIndex < allHighlights.length - 1) {
                  scrollToHighlight(highlightIndex + 1);
                }
              }
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment:
                searchString !== '' ? (
                  <IconButton onClick={clearAll} size="small">
                    <Cancel />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setSearch(false)} size="small">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
            sx={{
              padding: 0,
              width: 'auto',
              '& .MuiOutlinedInput-input': {
                padding: '10px 10px',
                width: 'auto',
              },
            }}
          />
          {allHighlights?.length > 0 ? (
            <Typography variant={'caption'} sx={{ color: 'grey.500', marginLeft: 1 }}>
              {highlightIndex + 1} / {allHighlights?.length}
            </Typography>
          ) : (
            <Box component="span" sx={{ margin: 3 }} />
          )}
          <IconButton onClick={() => scrollToHighlight(highlightIndex - 1)} disabled={highlightIndex === 0}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={() => scrollToHighlight(highlightIndex + 1)}
            disabled={highlightIndex === allHighlights?.length - 1}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      )}
      {hasToc && isFirst && <TOC blocks={toc} onSearch={jumpToBlock} />}
      <Box
        sx={{
          my: '5px',
          width: '100%',
        }}
      >
        <Box
          className={className}
          ref={containerRef}
          sx={{ minHeight: (isFirst && totalSections === 1) || isLast ? '85vh' : '100%' }}
        >
          <Editor
            ref={editor}
            decorators={decorators}
            editorState={editorState}
            onChange={setEditorState}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            plugins={plugins}
            spellCheck={true}
            readOnly={true}
          />
        </Box>
      </Box>
    </>
  );
}
