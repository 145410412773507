import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid, Button, TextField } from '@mui/material';
import { CloseRounded, DoneRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import RoundedDialog from './RoundedDialog';
import { isFunction } from 'lodash';

export default function ({
  value,
  onChange,
  label = 'Pick time',
  format = 'hh:mm A',
  disablePadding,
  disableContainer,
  style,
  disabled,
  textFieldProps,
}) {
  const [open, setOpen] = useState(false);

  function handleChange(time) {
    // important - do not send invalid values
    if (time != null && !time.isValid()) {
      return;
    }
    if (!isFunction(onChange)) return;
    const result = time && moment(time, true).isValid() ? time : null;
    onChange(result);
  }

  return (
    <Grid
      container={!disableContainer}
      item
      alignItems="center"
      style={{ padding: disablePadding ? 0 : '0.5rem 0', ...style }}
    >
      <TextField
        variant="standard"
        placeholder={label}
        sx={{ minWidth: 20 }}
        value={value ? moment(value).format(format) : ''}
        onClick={() => setOpen(true)}
        disabled={disabled}
        {...textFieldProps}
      />
      {!disabled && (
        <TimeDialog
          initial={value && moment(value) ? value : null}
          open={open}
          onClose={() => setOpen(false)}
          onResult={(res) => handleChange(res)}
        />
      )}
    </Grid>
  );
}

export function TimeDialog({ open, onClose, initial, onResult }) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(initial && moment(initial).isValid() ? moment(initial) : moment());
  }, [open, initial]);

  return (
    <RoundedDialog open={open} onClose={onClose}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticTimePicker
          value={value}
          onChange={setValue}
          slots={{
            actionBar: (props) => (
              <ActionBar
                {...props}
                onDone={() => {
                  onResult(value);
                  onClose();
                }}
                onCancel={onClose}
                onDelete={() => {
                  setValue(null);
                  onResult(null);
                  onClose();
                }}
                showDelete={Boolean(initial)}
              />
            ),
          }}
        />
      </LocalizationProvider>
    </RoundedDialog>
  );
}

function ActionBar({ className, onDone, onCancel, onDelete, showDelete }) {
  return (
    <Grid container className={className} sx={{ p: 1 }} justifyContent="flex-end">
      {showDelete && (
        <Button sx={{ mr: 'auto' }} onClick={onDelete} color="secondary" startIcon={<DeleteOutlineRounded />}>
          delete
        </Button>
      )}
      <Button onClick={onCancel} startIcon={<CloseRounded />} sx={{ mr: 1 }}>
        cancel
      </Button>
      <Button onClick={onDone} startIcon={<DoneRounded />}>
        done
      </Button>
    </Grid>
  );
}
