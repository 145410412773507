import React from 'react';
import { Grid, Typography, Dialog, Button } from '@mui/material';
import { Alert } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function RestrictedNotice() {
  function switchAccount() {
    localStorage.clear();
    window.location.href = '/login';
  }

  const message = 'Your access has been restricted. Please contact your administrator.';

  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
        backgroundImage: 'url(/appHome.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Dialog open maxWidth="xs" fullWidth>
        <Alert severity="error" style={{ borderRadius: 8, width: '100%', textAlign: 'center' }}>
          <Typography gutterBottom variant="h6">
            {message}
          </Typography>
          <Typography gutterBottom>Contact below for more information:</Typography>

          <Typography gutterBottom>Tel: 0330 118 0 712</Typography>
          <Typography gutterBottom>Email: customercare@q-hub.co.uk</Typography>
          <Grid container justifyContent="center">
            <Button style={{ color: grey[700] }} onClick={switchAccount}>
              Use another account
            </Button>
          </Grid>
        </Alert>
      </Dialog>
    </Grid>
  );
}
