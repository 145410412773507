import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function Collapsible({ title, defaultPlaceholder, setField, field, disabled = false }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDone = () => {
    setExpanded(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Accordion expanded={expanded} onChange={handleChange(true)}>
        <AccordionSummary
          expandIcon={expanded ? <ArrowDropDownIcon /> : <ArrowLeftIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Box sx={{ flexBasis: '33.33%' }}>
            <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(15) }}>{title}</Typography>
          </Box>
          <Box sx={{ flexBasis: '33.33%' }}>{field ? <DoneIcon /> : <ErrorIcon />}</Box>
        </AccordionSummary>
        <AccordionDetails sx={{ alignItems: 'center' }}>
          <TextField
            id="standard-multiline-static"
            multiline
            fullWidth
            rows={5}
            placeholder={defaultPlaceholder}
            onChange={(e) => setField(e.target.value)}
            value={field}
            disabled={disabled}
            variant="standard"
          />
        </AccordionDetails>
        <Divider />
        {!disabled && (
          <AccordionActions>
            <Button size="small" color="primary" onClick={handleDone}>
              Done
            </Button>
          </AccordionActions>
        )}
      </Accordion>
    </Box>
  );
}
