import React from 'react';
import { useMediaQuery, AppBar, Toolbar, IconButton, CssBaseline } from '@mui/material';
import { Avatar, Grid, useTheme } from '@mui/material';
import { Apps } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import GlobalSearch from '../GlobalSearch';

export default function EmptyHeader() {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <Grid container>
            <Grid container item lg={4} md={4} xs={6} alignItems="center">
              <IconButton>
                <Apps style={{ color: 'white' }} />
              </IconButton>
            </Grid>

            {largeDevices && (
              <Grid container item lg={4} md={4} alignItems="center" justifyContent="center">
                <GlobalSearch empty />
              </Grid>
            )}

            <Grid container item lg={4} md={4} xs={6} alignItems="center" justifyContent="flex-end">
              <IconButton>
                <ChatIcon style={{ color: 'white' }} />
              </IconButton>

              <IconButton style={{ marginLeft: 16 }}>
                <NotificationsIcon style={{ color: 'white' }} />
              </IconButton>

              <Avatar style={{ marginLeft: 16 }} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
