import React, { useState, useEffect, useRef } from 'react';
import UserManager from '../UserManager';

export default function TextUser({ id, prefix, style, ...props }) {
  const [user, setUser] = useState({});
  const subscription = useRef();

  useEffect(() => {
    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (id && typeof id === 'string') {
      setUser(UserManager.resolveUser(id));

      if (subscription.current) {
        subscription.current.unsubscribe();
      }
      subscription.current = UserManager.subscribeToUser(id, setUser);
    }
  }, [id]);

  if (!user?.fullName) return null;

  return (
    <>
      <span
        style={{
          paddingLeft: '4px',
          paddingRight: '4px',
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          ...style,
        }}
        {...props}
      >
        {user.avatar && (
          <img style={{ height: '1.2em', width: '1.2em', borderRadius: '50%', marginRight: 5 }} src={user.avatar} />
        )}
        {user.fullName}
        {'  '}
        {prefix}
      </span>
    </>
  );
}
