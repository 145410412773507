import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isFunction } from 'lodash';

export default function ({
  value,
  onChange,
  label = 'Pick date',
  format = 'DD/MM/YYYY',
  disablePadding,
  disableContainer,
  inputProps,
  style,
  ...props
}) {
  const [date, setDate] = useState(null);
  const timer = useRef(null);
  const SAVING_TIMEOUT = 300;

  useEffect(() => {
    if (!value) {
      setDate(null);
      return;
    }
    const parsed = moment(value, true);
    setDate(parsed.isValid() ? parsed : null);
  }, [value]);

  function saveDate(value) {
    setDate(value);

    // important - do not send invalid values
    if (value != null && !value.isValid()) {
      return;
    }
    if (!isFunction(onChange)) return;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => onChange(value ? moment(value).toDate() : null), SAVING_TIMEOUT);
  }

  return (
    <Grid
      container={!disableContainer}
      item
      alignItems="center"
      style={{ padding: disablePadding ? 0 : '0.5rem 0', ...style }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          slotProps={{ textField: { variant: 'standard' } }}
          value={date}
          label={label}
          onChange={saveDate}
          format={format}
          {...props}
        />
      </LocalizationProvider>
    </Grid>
  );
}
