/**
 * * Reducer for Document Component
 */

import { COMPONENTLOADER, COMPONENTERROR, COMPONENTSUCCESS } from '../constants';

const initialState = {
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPONENTLOADER:
      return { ...state, loading: true, error: null };
    case COMPONENTERROR:
      return { ...state, loading: false, error: action.payload };
    case COMPONENTSUCCESS:
      return { ...state, loading: false, [action.payload.id]: action.payload.document };
    default:
      return state;
  }
}
