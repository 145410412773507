import axios from '../../../../../redux/apiCaller';
import { successAction, errorAction, attemptAction } from '../../../../../redux/constants';
import StateManager from '../../../../Global/StateManager';
import {
  SETSLIST_ATTEMPT,
  SETSACTION_ATTEMPT,
  SETS_ERROR,
  GETSETS,
  GETSET,
  UPDATESET,
  DELETESET,
  ADDSET,
} from '../constants';

const baseUrl = 'dochub/conventionSets';

export const getSets = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(SETSLIST_ATTEMPT));
    try {
      const response = await axios.get(`${baseUrl}`, { params });
      dispatch(successAction(GETSETS, response.data.result));
    } catch (error) {
      dispatch(errorAction(SETS_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const getSet = (id) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(SETSLIST_ATTEMPT));
    try {
      const response = await axios.get(`${baseUrl}/${id}`);
      dispatch(successAction(GETSET, response.data.result));
    } catch (error) {
      dispatch(errorAction(SETS_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const createSet = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(SETSACTION_ATTEMPT));
    try {
      const response = await axios.post(`${baseUrl}`, data);
      dispatch(successAction(ADDSET, response.data.result));
      StateManager.setSuccessAlert(response?.data?.message);
    } catch (error) {
      dispatch(errorAction(SETS_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};

export const updateSet = (id, data, onRefresh) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(SETSACTION_ATTEMPT));
    try {
      const response = await axios.put(`${baseUrl}/${id}`, data);
      dispatch(successAction(UPDATESET, response.data.result));
      StateManager.setSuccessAlert(response?.data?.message);
      if (onRefresh) onRefresh();
    } catch (error) {
      dispatch(errorAction(SETS_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
      if (onRefresh) onRefresh();
    }
  };
  return thunk;
};

export const deleteSet = (id) => {
  const thunk = async function thunk(dispatch) {
    dispatch(attemptAction(SETSACTION_ATTEMPT));
    try {
      const response = await axios.delete(`${baseUrl}/${id}`);
      dispatch(successAction(DELETESET, response.data.result));
      StateManager.setSuccessAlert(response?.data?.message);
    } catch (error) {
      dispatch(errorAction(SETS_ERROR, error));
      StateManager.setAxiosErrorAlert(error);
    }
  };
  return thunk;
};
