import React, { useEffect, useState } from 'react';
import { Grid, Typography, ListItem, CircularProgress, Tooltip, IconButton } from '@mui/material';
import { MailOutlineRounded, MarkunreadMailboxRounded } from '@mui/icons-material';
import { FormIcon } from '../Icons';
import { HtmlTooltip } from '../Components';
import StateManager from '../StateManager';
import { useHistory } from 'react-router-dom';
import { grey, green, orange } from '@mui/material/colors';
import EmailDialog from '../../Hubs/forms/components/EmailDialog';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';

export default function FormField({ params, filled, editable, stepId, onChange, forTaskTemplate, parentTaskId }) {
  const [entryInfo, setEntryInfo] = useState(null);
  const [startingEntry, setStartingEntry] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!filled?.entryId || !params?.formId) return;
    setLoading(true);
    axios
      .post('/forms/entries/getEntriesByIds', { ids: [filled.entryId] })
      .then((res) => {
        setLoading(false);
        setEntryInfo(res.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [filled, params]);

  function onFormComplete(entry) {
    if (!entryInfo?._id || entry?._id !== entryInfo?._id) return;
    setEntryInfo({ ...entryInfo, completedAt: entry.completedAt });
  }

  function onClick() {
    if (entryInfo) {
      StateManager.openFormEntry(entryInfo._id, onFormComplete);
    } else {
      if (!editable || startingEntry) return;
      setStartingEntry(true);
      axios
        .post('/forms/entries/sendEntry', {
          formId: params?.formId,
          stepInfo: { stepId, itemId: params.id },
          processStepId: stepId,
          parentTaskId,
        })
        .then((res) => {
          onChange({ entryId: res.data[0]._id, entryNo: res.data[0].number });
          setEntryInfo({ title: res.data[0].title, number: res.data[0].number, _id: res.data[0]._id });
          StateManager.openFormEntry(res.data[0]._id, onFormComplete);
          setStartingEntry(false);
          StateManager.setSuccessAlert('Entry has been started');
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setStartingEntry(false);
        });
    }
  }

  return (
    <Grid container>
      <ListItem
        button={!!editable || !!entryInfo}
        sx={{
          borderRadius: 1,
          border: entryInfo
            ? entryInfo.completedAt
              ? `3px solid ${green[600]}`
              : `3px solid ${orange[500]}`
            : `3px solid ${grey[400]}`,
          background: (theme) =>
            entryInfo
              ? entryInfo.completedAt
                ? theme.palette.mode === 'dark'
                  ? green[700]
                  : green[50]
                : theme.palette.mode === 'dark'
                ? orange[700]
                : orange[50]
              : theme.palette.mode === 'dark'
              ? grey[700]
              : grey[100],
        }}
        onClick={onClick}
      >
        <Grid container alignItems="center" style={{ padding: '8px 0' }}>
          {loading && <CircularProgress color="primary" style={{ margin: 10 }} size={30} />}
          <FormIcon style={{ marginRight: '1rem' }} />
          {entryInfo ? (
            <>
              <Typography variant="h6">{entryInfo.title}</Typography>
              <Typography variant="h6" color="textSecondary" style={{ marginLeft: '0.25em' }}>
                {`- entry #${entryInfo.number}`}
              </Typography>
              <div style={{ marginLeft: 'auto' }} />
              {entryInfo.invitationEmailSent && (
                <HtmlTooltip
                  title={`Form has been ${entryInfo.completedAt ? 'completed by' : 'sent to'} ${
                    entryInfo.invitationEmail
                  }`}
                >
                  <MarkunreadMailboxRounded style={{ color: grey[500] }} />
                </HtmlTooltip>
              )}
              {isArray(entryInfo.emailedTo) && !isEmpty(entryInfo.emailedTo) && (
                <HtmlTooltip title={`Emailed to: ${entryInfo.emailedTo.join(', ')}`}>
                  <Typography style={{ color: green[700], fontWeight: 500, fontSize: 18, marginLeft: 8 }}>
                    Form has been emailed
                  </Typography>
                </HtmlTooltip>
              )}
              {entryInfo.completedAt && (
                <Tooltip title="Email entry">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setEmailDialog(true);
                    }}
                    style={{ marginLeft: 8, height: 36, width: 36 }}
                  >
                    <MailOutlineRounded style={{ color: green[700] }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <Typography variant="h6">
              {editable ? 'Click to start the form' : 'Form'}: {params?.formName}
            </Typography>
          )}
          {startingEntry && <CircularProgress color="primary" style={{ marginLeft: 'auto' }} size={30} />}
        </Grid>
      </ListItem>
      {entryInfo?.completedAt && (
        <EmailDialog
          open={emailDialog}
          onClose={() => setEmailDialog(false)}
          entryId={entryInfo._id}
          useOptions
          onResult={(result) => setEntryInfo({ ...entryInfo, emailedTo: [...(entryInfo.emailedTo || []), ...result] })}
        />
      )}
    </Grid>
  );
}
