import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../redux/actions';
import { convertFromRaw, EditorState } from 'draft-js';

import { Grid, IconButton, Typography, CircularProgress } from '@mui/material';
import Chat from '@mui/icons-material/Chat';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { COLORS } from '../../constants';
import { NormalButton } from '../IssueComponents';
import SelectBox from '../SelectBox';

import Comment, { CommentRow } from './comment';
import CommentEditorBox from './editor';

export default function CommentsSection({
  view = 'detailed',
  comments,
  deleteComment,
  addComment,
  updateComment,
  likeComment,
  disabled = false,
  upIssueComment = true,
  showCommentBar = true,
  loading = false,
  bottomButton = false,
  versions = [],
  filterPosition = 'flex-start',
  fullWidth = false,
  versionSelector = true,
  variant = 'v1',
  isApprovalComment,
}) {
  const dispatch = useDispatch();
  const { users } = useSelector(({ settings }) => settings);
  const [openComment, setOpenComment] = useState(false);
  const [editComment, setEditComment] = useState(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [userList, setUserList] = useState('');
  const [version, setVersion] = useState('');

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const CommentFilterBar = ({ sm = 9, versionSelector = true }) => {
    return (
      <Grid
        item
        sm={sm}
        container
        direction="row"
        alignItems="center"
        sx={{ margin: (theme) => theme.spacing(1, 0) }}
        justifyContent={filterPosition}
      >
        {users && (
          <Grid item sx={{ marginRight: '7px' }}>
            <SelectBox
              options={[
                { label: 'Filter by user', value: '' },
                ...users.map((x) => ({ label: x.fullName, value: x._id })),
              ]}
              value={userList}
              handleValue={setUserList}
              minWidth={135}
              border
              small
              borderRadius={8}
              noValue={true}
            />
          </Grid>
        )}
        {versions && versionSelector && (
          <Grid item>
            <SelectBox
              options={[{ label: 'Filter by version', value: '' }, ...versions]}
              value={version}
              handleValue={setVersion}
              minWidth={135}
              border
              small
              borderRadius={8}
              noValue={true}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const handleEditComment = (comment) => {
    setEditComment(comment._id);
    // setNewComment(comment.text);
    let editState = EditorState.createWithContent(
      convertFromRaw({
        blocks: comment.text.blocks,
        entityMap: comment.text.entityMap ? comment.text.entityMap : {},
      }),
    );
    setEditorState(editState);
    setOpenComment(true);
  };

  const generateCommentVersion = (comment) => {
    return upIssueComment
      ? `v${[comment.document?.baseVersion, comment.miniVersion ? comment.miniVersion : 1].join('.')}`
      : comment.document
      ? `v${comment?.document?.issue}`
      : null;
  };

  return (
    <Grid container direction="column">
      {showCommentBar && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <CommentFilterBar sm={fullWidth ? 12 : 9} versionSelector={versionSelector} />
          {!disabled && !openComment && (
            <Grid item sm align="end">
              <IconButton
                onClick={() => {
                  setEditorState(EditorState.createEmpty());
                  setOpenComment(true);
                }}
              >
                <AddCommentIcon sx={{ color: COLORS.BOOKMARK }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
      {loading && <CircularProgress size={30} color="primary" />}
      {comments?.length === 0 && <Typography>No comments available</Typography>}
      {view === 'detailed' &&
        comments &&
        comments.length > 0 &&
        comments
          .filter((x) => !userList || x.createdBy === userList)
          .filter((x) => !version || x.miniVersion === version)
          .map((comment, index) => (
            <Comment
              key={index}
              comment={comment}
              deleteComment={deleteComment}
              disabled={disabled}
              edit={editComment}
              handleEdit={() => handleEditComment(comment)}
              commentVersion={generateCommentVersion(comment)}
              upIssueComment={upIssueComment}
              likeComment={likeComment}
              variant={variant}
            />
          ))}
      {view === 'list' &&
        comments &&
        comments.length > 0 &&
        comments
          .filter((x) => !userList || x.createdBy === userList)
          .filter((x) => !version || x.miniVersion === version)
          .map((comment, index) => (
            <CommentRow
              key={index}
              comment={comment}
              deleteComment={deleteComment}
              disabled={disabled}
              edit={editComment}
              handleEdit={() => handleEditComment(comment)}
            />
          ))}
      {openComment && (
        <CommentEditorBox
          label={editComment ? 'Update' : 'Comment'}
          addComment={addComment}
          updateComment={updateComment}
          editComment={editComment}
          setEditComment={setEditComment}
          setOpenComment={setOpenComment}
          editorState={editorState}
          setEditorState={setEditorState}
          isApprovalComment={isApprovalComment}
        />
      )}
      {!disabled && !openComment && bottomButton && (
        <Grid item container justifyContent="center">
          <NormalButton
            size="small"
            endIcon={<Chat sx={{ color: COLORS.BOOKMARK }} />}
            onClick={() => {
              setEditorState(EditorState.createEmpty());
              setOpenComment(true);
            }}
            label={'Add Comment'}
            labelColor={COLORS.BOOKMARK}
            activeLabelColor={COLORS.BOOKMARK}
            activeColor={COLORS.BOOKMARK}
            borderColor={COLORS.BOOKMARK}
            activeBorderColor={COLORS.BOOKMARK}
          />
        </Grid>
      )}
    </Grid>
  );
}
