import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../redux/actions';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '../../../../Global/Components';
import UserSelection from '../UserSelection';
import { TaskStatusButton } from '../../../tasks/workflowComponents';

const priorities = [
  { name: 'Low', color: '#30BF71' },
  { name: 'Medium', color: '#FF7F50' },
  { name: 'High', color: '#B22222' },
  { name: 'Critical', color: '#7030A0' },
];

export default ({ open, handleClose, handleConfirm, task, setTask, edit }) => {
  const dispatch = useDispatch();
  const { users } = useSelector(({ settings }) => settings);

  useEffect(() => {
    if (!open) return;
    dispatch(getAllUsers());
  }, [dispatch, open]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
      <DialogTitle id="form-dialog-title">{edit ? 'Edit' : 'Add'} Task</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column">
          <Grid item container direction="row" alignItems="center">
            <Typography sx={{ marginRight: '15px' }}>Due Date</Typography>
            <Grid item xs={5}>
              <DateTimePicker
                value={task?.dueAt}
                minDate={new Date()}
                onChange={(e) => setTask({ ...task, dueAt: e })}
              />
            </Grid>
            {edit && task && (
              <Grid item xs={3}>
                <TaskStatusButton
                  status={task?.progress}
                  setStatus={(value) => setTask({ ...task, progress: value })}
                />
              </Grid>
            )}
          </Grid>
          <Grid item sx={{ marginTop: '15px', marginBottom: '15px' }}>
            {users && (
              <UserSelection
                placeholder={'Search for a User'}
                data={users}
                option={'fullName'}
                handleChange={(e) => setTask({ ...task, assigned_to: e.target.value })}
                value={task?.assigned_to}
              />
            )}
          </Grid>
          <Grid item sx={{ my: 2 }}>
            <TextField
              label="Task Description"
              fullWidth
              id="description"
              multiline
              rows={5}
              maxRows={5}
              value={task?.instructions}
              variant="outlined"
              placeholder="Write Description About this Task"
              onChange={(e) => setTask({ ...task, instructions: e.target.value })}
            />
          </Grid>
          <Grid item container direction="row" sx={{ my: 2 }} justifyContent="space-between">
            <Grid item xs={3}>
              <Typography sx={{ marginTop: '5%', fontWeight: 'bold' }}>Set a priority</Typography>
              <div />
            </Grid>
            {priorities &&
              priorities.map((p, i) => (
                <Grid item xs={2} key={i}>
                  <Button
                    disableElevation={task?.priority === p.name ? false : true}
                    variant="contained"
                    sx={{
                      color: p.color,
                      backgroundColor: '#fff',
                      fontWeight: 'bold',
                      border: '1px solid lightgrey',
                      borderRadius: '10px',
                    }}
                    onClick={() => setTask({ ...task, priority: p.name })}
                  >
                    {p.name}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          onClick={handleClose}
          sx={{ fontSize: '16px', margin: '10px', background: 'lightgrey', textTransform: 'none' }}
        >
          Close
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{
            fontSize: '16px',
            margin: '10px',
            background: '#30CF76',
            textTransform: 'none',
            marginLeft: 'auto',
            color: 'white',
          }}
        >
          {edit ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
