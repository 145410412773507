import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Button, Typography, Paper, CardActionArea } from '@mui/material';
import {
  ArrowForwardIosRounded,
  CheckCircleOutlineRounded,
  ConstructionRounded,
  Delete,
  DescriptionOutlined,
  GridOnRounded,
  Public,
  StorageRounded,
  Timeline,
  WorkOutline,
} from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import LinkIcon from '@mui/icons-material/Link';
import StateManager from '../StateManager';
import { FormIcon, ProcessIcon, AuditIcon, RegistersIcon, DataIcon, AssetIcon, TaskIcon } from '../Icons';
import { PortalIcon } from '../Icons';
import SearchItem from './search';
import FolderPicker from '../../Hubs/dochub/components/MoveFolderDialog/tree';
import AssetsSearch from '../../Hubs/asset/components/AssetsSearch';

const hubs = [
  {
    value: 'form',
    icon: <FormIcon />,
    rowIcon: <DescriptionOutlined />,
    title: 'Link a Form',
    url: '/forms/search/getSearchForms',
  },
  {
    value: 'process',
    icon: <ProcessIcon />,
    rowIcon: <Timeline />,
    title: 'Link a Process',
    url: '/process/search/getSearchProcesses',
  },
  {
    value: 'register',
    icon: <RegistersIcon />,
    rowIcon: <GridOnRounded />,
    title: 'Link a Register',
    url: '/registers/general/getUserRegisters',
  },
  {
    value: 'audit',
    icon: <AuditIcon />,
    rowIcon: <CheckCircleOutlineRounded />,
    title: 'Link an Audit',
    url: '/audits/search/getSearchAudits',
  },
  {
    value: 'audits/schedule',
    icon: <AuditIcon />,
    rowIcon: <CheckCircleOutlineRounded />,
    title: 'Link an Audit Schedule',
    url: '/audits/schedules/getSchedules',
  },
  {
    value: 'docFolder',
    icon: <DataIcon />,
    rowIcon: <StorageRounded />,
    title: 'Link a DocArea/Folder',
    url: '/dochub/areas/getUploaderAreas',
  },
  {
    value: 'asset',
    icon: <AssetIcon />,
    rowIcon: <ConstructionRounded />,
    title: 'Link an Asset',
    url: '/asset/data/loadAssets',
    mode: 'post',
  },
  {
    value: 'asset/profile',
    icon: <AssetIcon />,
    rowIcon: <ConstructionRounded />,
    title: 'Link an Asset Profile',
    url: '/asset/profile/getProfiles',
  },
  {
    value: 'tasks/workspace',
    icon: <TaskIcon />,
    rowIcon: <WorkOutline />,
    title: 'Link a Workspace',
    url: '/workhub/workspace/getUserWorkspaces',
  },
  {
    value: 'portal/management/space',
    icon: <PortalIcon />,
    rowIcon: <Public />,
    title: 'Link a Portal',
    url: '/portal/management/getPortalSpaces',
  },
];

export default function RefLinkDialog({ handleCreate, initial }) {
  const [result, setResult] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [clickTimeout, setClickTimeout] = useState(null);

  const [option, setOption] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (result.length > 0 || deletedItems.length > 0) {
      onCreateLinks();
    }
    //eslint-disable-next-line
  }, [result, deletedItems]);

  const onCreateLinks = () => {
    const uniqueNewItems = result.filter(
      (x) => x.isNew && !deletedItems.some((item) => item.targetItem === x.targetItem),
    );
    const uniqueDeletedItems = deletedItems.filter((x) => !result.some((item) => item.targetItem === x.targetItem));

    const data = {
      new: uniqueNewItems,
      deleted: uniqueDeletedItems,
    };

    handleCreate(data);
  };

  const handleItems = (data, type) => {
    setResult([
      ...data.map((x) => ({
        title: `${x.title ?? x.name}`,
        targetType: type,
        targetItem: x._id,
        icon: hubs.find((hub) => hub.value === type)?.rowIcon,
        selectionType: 'full',
        isNew: true,
      })),
    ]);
  };

  function handleFolderResult(item, e) {
    if (clickTimeout) {
      // timeout for doubleclick detection
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      let res = {};
      res.targetType = 'docFolder';
      res.parentId = item?.area?._id;
      res.targetItem = item.folder?._id ?? '';
      res.title = `Link to ${item.folder ? 'folder' : 'doc area'} ${
        item.folder ? item?.folder?.name : item.area?.name
      }`;
      res.icon = hubs.find((hub) => hub.value === 'docFolder')?.rowIcon;
      res.isNew = true;
      setResult([res]);
    } else {
      const timeout = setTimeout(() => {
        setClickTimeout(null);
      }, 300);

      setClickTimeout(timeout);
    }
  }

  return (
    <Grid container item>
      <Grid container direction={'row'} justifyContent="space-between" alignItems={'center'}>
        <Grid item xs={6} sm={6}>
          <Typography variant={'h6'} sx={{ fontWeight: 500, padding: 1 }}>
            Manage Links
          </Typography>
        </Grid>
        <Grid item xs={6} sm={1} align="right" sx={{ padding: 1 }}>
          <LinkIcon />
        </Grid>
      </Grid>
      <Grid container>
        {hubs.map((hub) => (
          <Grid container key={hub.value} lg={6} md={6} xs={12} item style={{ padding: 8 }}>
            <CardActionArea
              sx={{
                borderRadius: 1,
                width: '100%',
                padding: 0,
              }}
              onClick={() => {
                setOption(hub);
                setTab(1);
              }}
            >
              <Paper
                variant="outlined"
                sx={{
                  borderRadius: 1,
                  padding: (theme) => theme.spacing(1, 2),
                  border: `2px solid ${grey[300]}`,
                  width: '100%',
                }}
              >
                <Grid container alignItems="center">
                  {hub.icon}
                  <Typography style={{ margin: '0px 16px' }}>{hub.title}</Typography>
                  <ArrowForwardIosRounded color="primary" style={{ marginLeft: 'auto' }} />
                </Grid>
              </Paper>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
      <Grid container sx={{ my: 2 }}>
        {option && option?.value !== 'docFolder' && option?.value !== 'asset' && tab === 1 && (
          <Grid container>
            <SearchItem
              url={option?.url}
              title={`Search ${option.value}`}
              icon={option?.icon}
              onResult={(x) => handleItems(x, option.value)}
              mode={option?.mode}
            />
          </Grid>
        )}
        {option && option?.value === 'asset' && (
          <Grid container item sx={{ mx: 2 }}>
            <AssetsSearch onResult={(x) => handleItems([x], option.value)} />
          </Grid>
        )}
        {option?.value === 'docFolder' && (
          <FolderPicker handleConfirm={handleFolderResult} title="" excludePersonal sx={{ p: 1 }} refLinkComponent />
        )}
      </Grid>
    </Grid>
  );
}
