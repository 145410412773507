import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import { TextField, Button, Grid, Paper, CircularProgress, Typography, Collapse } from '@mui/material';
import { DialogTitle, DialogContent, Box } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { darklogo } from '../../../../constants';
import { getUser } from '../../../Profile/redux/actions';
import Loader from '../../../Global/Loader';
import { blue, red } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../../constants/msal';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { MicrosoftIcon } from '../../../Global/Icons';

export default function LoginComponent() {
  const [data, setData] = useState({
    email: '',
    password: '',
    loading: false,
    loggedIn: false,
    error: false,
    errorMessage: '',
    text: 'Email or password incorrect, please try again...',
    access: '',
  });
  const [ssoError, setSsoError] = useState(false);
  const [ssoErrorMessage, setSsoErrorMessage] = useState('');
  const [ssoLoading, setSsoLoading] = useState(false);
  const [tfaDialog, setTfaDialog] = useState(false);
  const [settingUp2faDialog, setSettingUp2faDialog] = useState(false);
  const [shadowLoginError, setShadowLoginError] = useState('');
  const { user, loggedIn, fetching, error: fetchingError } = useSelector(({ profile }) => profile);

  // log user out if the user not found
  useEffect(() => {
    if (fetchingError !== 'User not found') return;

    localStorage.clear();
    location.reload();
  }, [fetchingError]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { instance, inProgress, accounts } = useMsal();
  const { accessKey, userId } = useParams();

  useEffect(() => {
    if (!accessKey || !userId) return;
    axios
      .post('/auth/shadowLogin', { accessKey, userId })
      .then((res) => {
        if (res.data.authentication) {
          localStorage.clear();
          localStorage.setItem('token', res.data.authentication.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authentication.name);
          localStorage.setItem('_id', res.data.authentication.id);
          localStorage.setItem('company', res.data.authentication.company);
          localStorage.setItem('access', res.data.authentication.access);
          localStorage.setItem('shadowLoginId', accessKey);
          window.location.href = '/home';
        }
      })
      .catch((err) => {
        console.error(err);
        setShadowLoginError(err?.response?.data?.message || 'Something went wrong');
      });
  }, [accessKey, userId]);

  const verify = () => {
    setData({ ...data, loading: true });
    axios
      .post(`/auth/login`, { email: data.email, password: data.password, twoFaCode: data.twoFaCode })
      .then((res) => {
        if (res.data.success === false && res.data.twoFaRequired) {
          setTfaDialog(true);
          setData({ ...data, loading: false, error: false, text: '' });
          return;
        }
        if (res.data.success === false && res.data.settingUp2FaRequired) {
          setSettingUp2faDialog(true);
          setData({
            ...data,
            loading: false,
            error: false,
            text: '',
            userId: res.data.userId,
            verificationId: res.data.verificationId,
          });
          return;
        }

        if (res.data.success && res.data.authJSON) {
          const previousUser = localStorage.getItem('previousUser');
          const currentUser = res.data.authJSON.id; // Store the user ID
          setData({ ...data, loggedIn: true });
          localStorage.clear();
          localStorage.setItem('token', res.data.authJSON.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authJSON.name);
          localStorage.setItem('_id', res.data.authJSON.id);
          localStorage.setItem('company', res.data.authJSON.company);
          localStorage.setItem('access', res.data.authJSON.access);
          localStorage.setItem('previousUser', currentUser);
          if (previousUser === currentUser) {
            const lastVisitedPage = localStorage.getItem('lastVisitedPage');
            if (lastVisitedPage) {
              history.push(lastVisitedPage);
            } else {
              window.location.href = '/home';
            }
          } else {
            window.location.href = '/home';
          }
        } else {
          if (res.data.forbiddenToken) {
            localStorage.setItem('forbiddenToken', res.data.forbiddenToken);
            window.location.href = '/suspended';
          } else if (res.data.companyArchived) {
            window.location.href = '/suspended';
          } else if (res.data.passwordChangeRequired) {
            window.location.href = `/change-password/${res.data.userId}?token=${res.data.token}`;
          } else if (res.data.trialEnded || res.data.subscriptionCancelled) {
            window.location.href = '/expired';
          } else if (res.data.userDeleted || res.data.userSuspended) {
            window.location.href = '/suspended';
          } else {
            setData({ ...data, error: true, loading: false, text: res.data.message, twoFaCode: '' });
          }
        }
      })
      .catch((err) => {
        setData({ ...data, error: true, loading: false, text: 'Login failed' });
      });
  };

  const handleSSOLogin = async () => {
    try {
      setSsoLoading(true);
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      setSsoLoading(false);
      console.error('Authentication error:', error);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      dispatch(getUser());
    }
    if (user && user._id && !accessKey) {
      const previousUser = localStorage.getItem('previousUser');
      const lastVisitedPage = localStorage.getItem('lastVisitedPage');

      // Store the current user in localStorage
      localStorage.setItem('previousUser', user._id);

      if (user.access === 'portal') {
        history.push('/portal');
      } else {
        if (previousUser === user._id && lastVisitedPage) {
          history.push(lastVisitedPage);
        } else {
          // Clear the last visited page if the user is different
          localStorage.removeItem('lastVisitedPage');
          history.push('/home');
        }
      }
    }
  }, [user, dispatch, history, loggedIn, accessKey]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const sessionKeys = Object.keys(sessionStorage);
    if (!accounts || accounts?.length === 0 || token || sessionKeys?.length === 0) return;
    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      setSsoLoading(true);
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Call your web api for oauth
          const jwt = accessTokenResponse.idToken;
          const accesstoken = accessTokenResponse.accessToken;
          axios
            .post(`/auth/login-with-sso`, null, {
              headers: {
                Authorization: jwt,
                accesstoken,
              },
            })
            .then((res) => {
              setSsoError(false);
              setSsoErrorMessage('');
              if (res.data.success && res.data.authJSON) {
                localStorage.clear();
                localStorage.setItem('token', res.data.authJSON.token);
                localStorage.setItem('loggedIn', 'true');
                localStorage.setItem('name', res.data.authJSON.name);
                localStorage.setItem('_id', res.data.authJSON.id);
                localStorage.setItem('company', res.data.authJSON.company);
                localStorage.setItem('access', res.data.authJSON.access);
                setSsoLoading(false);
                window.location.reload();
              } else {
                sessionStorage.clear();
                if (res.data.forbiddenToken) {
                  localStorage.setItem('forbiddenToken', res.data.forbiddenToken);
                  window.location.href = '/suspended';
                } else if (res.data.companyArchived) {
                  window.location.href = '/suspended';
                } else if (res.data.trialEnded || res.data.subscriptionCancelled) {
                  window.location.href = '/expired';
                } else if (res.data.userDeleted || res.data.userSuspended) {
                  window.location.href = '/suspended';
                } else {
                  setSsoError(true);
                  setSsoErrorMessage(res.data.message);
                  setSsoLoading(false);
                }
              }
            })
            .catch((err) => {
              setSsoLoading(false);
              setSsoError(true);
              setSsoErrorMessage('Login Failed');
            });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress]);

  if (settingUp2faDialog) {
    return (
      <SettingUp2faDialog
        open
        onClose={() => setSettingUp2faDialog(false)}
        onResult={() => setTfaDialog(true)}
        userId={data.userId}
        verificationId={data.verificationId}
      />
    );
  }

  return (
    <Grid container>
      {fetching && <Loader />}
      {!loggedIn && !user && (
        <>
          <Grid container item justifyContent="center">
            <img alt="img" src={darklogo} style={{ height: 80, marginTop: 50 }} />
          </Grid>
          <Grid container item justifyContent="center" style={{ marginTop: '5vh' }}>
            <Paper
              elevation={4}
              style={{
                marginTop: '10vh',
                borderRadius: 12,
                padding: '1rem',
                width: '100%',
                width: '80vw',
                maxWidth: 400,
              }}
            >
              {shadowLoginError && (
                <Alert severity="error" sx={{ width: '100%', borderRadius: 1, my: 1 }}>
                  {shadowLoginError}
                </Alert>
              )}
              {tfaDialog && (
                <Grid container spacing={4}>
                  <Grid container justifyContent="center" item style={{ textAlign: 'center' }}>
                    <Typography variant="h6">Please enter the code from Authy app</Typography>
                  </Grid>
                  {data.error && (
                    <Grid container item>
                      <Alert severity="error" style={{ width: '100%', borderRadius: 8 }}>
                        <AlertTitle>Error</AlertTitle>
                        {data.text}
                      </Alert>
                    </Grid>
                  )}
                  <Grid container item>
                    <TextField
                      fullWidth
                      label="Authy code"
                      variant="outlined"
                      value={data.twoFaCode}
                      onChange={(e) => {
                        e.preventDefault();
                        setData({ ...data, twoFaCode: e.target.value });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          verify();
                        }
                      }}
                    />
                  </Grid>
                  <Grid container item justifyContent="space-between">
                    <Button
                      style={{ borderRadius: 8, color: red[500] }}
                      onClick={() => {
                        setData({ ...data, twoFaCode: '' });
                        setTfaDialog(false);
                      }}
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={verify}
                      startIcon={data.loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                      style={{ borderRadius: 8 }}
                    >
                      Log In
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!tfaDialog && (
                <Grid container spacing={4}>
                  <Grid container justifyContent="center" item style={{ textAlign: 'center' }}>
                    <Typography variant="h4">Log In</Typography>
                  </Grid>
                  {data.error && (
                    <Grid container item>
                      <Alert severity="error" style={{ width: '100%', borderRadius: 8 }}>
                        <AlertTitle>Error</AlertTitle>
                        {data.text}
                      </Alert>
                    </Grid>
                  )}
                  {ssoError && (
                    <Grid container item>
                      <Alert
                        severity={ssoErrorMessage === 'Login Failed' ? 'error' : 'info'}
                        sx={{ width: '100%', borderRadius: 1 }}
                      >
                        {ssoErrorMessage === 'Login Failed' && <AlertTitle>Error</AlertTitle>}
                        {ssoErrorMessage}
                        {ssoErrorMessage !== 'Login Failed' && (
                          <Grid container alignItems="center">
                            Click{' '}
                            <Link
                              to="/signup"
                              style={{ color: blue[600], marginLeft: 4, marginRight: 4 }}
                              color="primary"
                            >
                              here
                            </Link>{' '}
                            to create an account
                          </Grid>
                        )}
                      </Alert>
                    </Grid>
                  )}
                  {!ssoLoading && (
                    <Grid container item>
                      <TextField
                        fullWidth
                        type="email"
                        id="user-email"
                        label="Email or Username"
                        variant="outlined"
                        value={data.email}
                        onChange={(e) => {
                          e.preventDefault();
                          setData({ ...data, email: e.target.value });
                        }}
                      />
                    </Grid>
                  )}
                  {!ssoLoading && (
                    <Grid container item>
                      <TextField
                        fullWidth
                        id="user-password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={data.password}
                        onChange={(e) => {
                          setData({ ...data, password: e.target.value });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            verify();
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {!ssoLoading && (
                    <Grid container item justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={verify}
                        startIcon={data.loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                        style={{ borderRadius: 8 }}
                      >
                        Log In
                      </Button>
                    </Grid>
                  )}
                  {ssoLoading && (
                    <Grid container item justifyContent="center">
                      <CircularProgress size={20} style={{ color: 'white' }} />
                    </Grid>
                  )}
                  {!ssoLoading && (
                    <Grid container item justifyContent="center">
                      <Box onClick={handleSSOLogin} sx={{ cursor: 'pointer' }}>
                        <MicrosoftIcon />
                      </Box>
                    </Grid>
                  )}
                  <Grid container item justifyContent="center" direction="column" style={{ textAlign: 'center' }}>
                    <Typography>or</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => history.push('/signup')}
                      sx={{ borderRadius: 1, my: 1.5, textTransform: 'none' }}
                      size="small"
                    >
                      Create a Q-Hub Account
                    </Button>
                    <Link to="/forgot-password">
                      <Typography style={{ color: blue[600] }}>Forgot your password?</Typography>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export function SettingUp2faDialog({ open, onClose, onResult, userId, verificationId }) {
  const [qrCode, setQrCode] = useState(null);
  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState(null);
  const qrCodeTimer = useRef();

  useEffect(() => {
    if (open && userId && verificationId) {
      setupQrCodeLoading();
    } else {
      if (qrCodeTimer.current != null) {
        clearInterval(qrCodeTimer.current);
      }
    }
  }, [open, userId, verificationId]); // eslint-disable-line

  useEffect(() => {
    return () => {
      if (qrCodeTimer.current != null) {
        clearInterval(qrCodeTimer.current);
      }
    };
  }, []);

  function setupQrCodeLoading() {
    loadQrCode();
    qrCodeTimer.current = setInterval(loadQrCode, 9 * 60 * 1000);
  }

  function loadQrCode() {
    setLoadingQrCode(true);
    axios
      .get('/auth/getAuthyQrCode', { params: { userId, verificationId } })
      .then((res) => {
        setQrCode(res.data.qrCode);
        setLoadingQrCode(false);
      })
      .catch((err) => {
        setLoadingQrCode(false);
        console.error(err);
      });
  }

  function done() {
    setChecking(true);
    axios
      .post('/auth/completeAuthySetup', { userId, verificationId })
      .then((res) => {
        setChecking(false);
        if (res.data.success) {
          onResult();
          onClose();
        } else {
          setError(res.data.message || 'Scan the QR code');
        }
      })
      .catch((err) => {
        setChecking(false);
        console.error(err);
        setError(err?.response?.data?.message || 'Something went wrong');
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>To continue to your account you need to set up two-factor authentication</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid container>
            <Alert severity="info" style={{ width: '100%', marginTop: 8, borderRadius: 8 }}>
              <AlertTitle>How to do it:</AlertTitle>
              <Typography gutterBottom>
                Download or open{' '}
                <a href="https://authy.com/download/" target={'_blank'} style={{ color: blue[700] }}>
                  Authy app
                </a>
              </Typography>
              <Typography gutterBottom>Scan the below QR code in your 'Authy' app and click 'Done'</Typography>
            </Alert>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" style={{ height: '30vh' }}>
            {loadingQrCode ? (
              <CircularProgress color="primary" />
            ) : (
              qrCode && <img alt="Authy" src={qrCode} style={{ height: '25vh', width: '25vh' }} />
            )}
          </Grid>
          <Grid container item>
            <Collapse in={Boolean(error)} style={{ width: '100%' }}>
              <Alert severity="error" style={{ width: '100%', borderRadius: 8 }}>
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions hideClose onDone={done} saving={checking} />
    </RoundedDialog>
  );
}
