import React, { useRef, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Paper,
  IconButton,
  TextField,
  useTheme,
  useMediaQuery,
  CircularProgress,
  LinearProgress,
  Box,
} from '@mui/material';
import { Undo, Clear, Visibility, EditRounded } from '@mui/icons-material';

import { grey } from '@mui/material/colors';
import { v4 } from 'uuid';
import CanvasDraw from 'react-canvas-draw';
import { isFunction, isNumber, isEmpty } from 'lodash';
import TabbedView from '../TabbedView';
import { useScreenshot } from '../hooks';
import { ImageDialog } from '../Components';
import Dropzone from '../../Hubs/dochub/components/Dropzone';

export default function Canvas({
  onResult,
  initial,
  savingTimeout = 1000,
  editable = true,
  scaling = 0.5,
  width,
  height,
  disabled,
  view = 'single', // single, tabbed
  disablePaper,
}) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [screenshot, takeScreenshot, saving] = useScreenshot();
  const [saveData, setSaveData] = useState({});
  const [name, setName] = useState('');
  const [preview, setPreview] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const nameRef = useRef();
  const canvasRef = useRef();
  const canvasHeight = height && isNumber(height) ? height : largeDevices ? 300 : 150;
  const canvasWidth = width && isNumber(width) ? width - 24 : largeDevices ? 500 : 250;

  const timer = useRef();
  const nameTimer = useRef();
  const DEBOUNCE_TIMEOUT = 1000;
  const loadedAt = useRef(null);

  function triggerNameTimer(r) {
    if (nameTimer.current) {
      clearTimeout(nameTimer.current);
    }
    nameTimer.current = setTimeout(() => captureNamedSignature(r), DEBOUNCE_TIMEOUT);
  }

  const captureNamedSignature = async (value) => {
    const image = await takeScreenshot(nameRef, theme.palette.mode === 'dark');
    saveName(image, value);
  };

  function saveName(image, value) {
    if (!image) return;
    const result = {
      id: v4(),
      base64Data: image,
      jsonData: { name: value },
    };
    setSaveData(result);
    if (!isFunction(onResult)) return;
    onResult(result);
  }

  function handleFileUpload(file) {
    if (!onResult || !file) return;
    let id = v4();
    saveData.id = id;
    onResult({ id, base64Data: file.location, jsonData: { file } });
  }

  const tabs = [
    {
      id: 'type',
      label: 'Type',
      children: (
        <Grid container justifyContent="center" alignItems="center" sx={{ py: 3, px: 2 }}>
          <Grid container item justifyContent={'center'}>
            <TextField
              value={name}
              inputRef={nameRef}
              onChange={(e) => {
                setName(e.target.value);
                triggerNameTimer(e.target.value);
              }}
              placeholder="Your Name"
              autoComplete="off"
              fullWidth
              margin="dense"
              inputProps={{ autoComplete: 'off' }}
              variant={'outlined'}
              size="large"
              sx={{
                '& .MuiInputBase-input': { fontFamily: 'signature-extended', fontSize: 25, py: 2 },
                // hide outline
                '& .MuiOutlinedInput-root': {
                  fieldset: { borderColor: 'transparent' },
                  '& .focused fieldset': { borderColor: 'inherit' },
                },
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid container item justifyContent="space-between" sx={{ mt: 2 }}>
            <IconButton onClick={() => setPreview(true)} disabled={!screenshot || disabled}>
              {saving ? <CircularProgress size={24} /> : screenshot ? <Visibility /> : <div />}
            </IconButton>
            <ImageDialog src={screenshot} open={preview} onClose={() => setPreview(false)} />
            <Button onClick={() => captureNamedSignature(name)} disabled={disabled || name === ''} variant="contained">
              confirm
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      id: 'draw',
      label: 'Draw',
      children: (
        <Grid container>
          <Grid container item sx={{ my: 1 }}>
            <Button sx={{ color: grey[700] }} startIcon={<Undo />} onClick={undo} disabled={disabled}>
              undo
            </Button>
            <Button
              sx={{ color: grey[700] }}
              startIcon={<Visibility />}
              onClick={() => setPreview(true)}
              disabled={disabled}
            >
              preview
            </Button>
            <ImageDialog src={initial?.base64Data} open={preview} onClose={() => setPreview(false)} />
          </Grid>
          <Grid container item style={{ position: 'relative', height: canvasHeight, width: canvasWidth }}>
            <CanvasDraw
              canvasHeight={canvasHeight}
              canvasWidth={canvasWidth}
              ref={(canvasDraw) => {
                canvasRef.current = canvasDraw;
              }}
              onChange={triggerTimer}
              brushRadius={2}
              loadTimeOffset={0}
              lazyRadius={0}
              style={{ position: 'absolute' }}
              saveData={saveData.jsonData}
              immediateLoading
              disabled={disabled}
            />
            {disabled && (
              <Grid
                container
                item
                style={{
                  position: 'absolute',
                  height: canvasHeight,
                  width: canvasWidth,
                  background: grey[700],
                  opacity: 0.5,
                }}
              />
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      id: 'upload',
      label: 'Upload',
      children: (
        <Grid container justifyContent="center" alignItems="center" sx={{ py: 3, px: 2 }}>
          <Dropzone
            onFileDrop={(file) => setUploading(true)}
            onFileUpload={handleFileUpload}
            onProgress={setProgress}
            disabled={disabled}
          />
          {uploading && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress value={progress} variant="determinate" />
            </Box>
          )}
          <Grid container item justifyContent="space-between" sx={{ mt: 2 }}>
            <IconButton onClick={() => setPreview(true)} disabled={!initial?.base64Data}>
              {saving ? <CircularProgress size={30} /> : initial?.base64Data ? <Visibility /> : <div />}
            </IconButton>
            <ImageDialog src={initial?.base64Data} open={preview} onClose={() => setPreview(false)} />
          </Grid>
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    if (isEmpty(initial)) {
      if (!canvasRef.current?.clear) return;
      canvasRef.current.clear();
      return;
    }
    if (initial.id === saveData?.id || !editable) return;
    loadedAt.current = Date.now();
    setSaveData(initial);
  }, [initial, editable]); // eslint-disable-line

  function save() {
    if (!canvasRef.current?.canvas?.drawing?.toDataURL || !canvasRef.current?.getSaveData || !onResult) return;
    let base64Data = canvasRef.current.canvas.drawing.toDataURL();
    let jsonData = canvasRef.current.getSaveData();
    let id = v4();
    saveData.id = id;
    onResult({ id, base64Data, jsonData });
  }

  function triggerTimer() {
    if (loadedAt.current && Date.now() - loadedAt.current < 300) return;
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(save, savingTimeout);
  }

  function undo() {
    if (!canvasRef.current?.undo) return;
    canvasRef.current.undo();
    triggerTimer();
  }

  function clear() {
    setName('');
    if (!canvasRef.current?.clear) return;
    canvasRef.current.clear();
    onResult({ id: saveData?.id });
  }

  if (!editable) {
    return (
      <Paper variant="outlined" style={{ borderRadius: 8, width: canvasWidth * scaling + 24, padding: 12 }}>
        <img
          style={{
            width: canvasWidth * scaling,
            height: canvasHeight * scaling,
            userSelect: 'none',
            pointerEvents: 'none',
            filter: theme.palette.mode === 'dark' ? 'invert(100%)' : '',
          }}
          src={initial?.base64Data}
          alt="signature"
        />
      </Paper>
    );
  }

  if (view === 'tabbed') {
    return (
      <Paper variant="outlined" style={{ borderRadius: 8, width: canvasWidth + 24, padding: 12 }}>
        <TabbedView
          tabs={tabs}
          button={
            <Button
              sx={{ color: (t) => (t.palette.mode === 'dark' ? '#fff' : grey[300]) }}
              onClick={clear}
              disabled={disabled}
            >
              clear
            </Button>
          }
        />
      </Paper>
    );
  }

  return (
    <Grid
      container
      component={disablePaper ? '' : Paper}
      variant="outlined"
      style={{ width: canvasWidth + 24, padding: 12 }}
    >
      {saveData?.base64Data && !saveData?.jsonData ? (
        <>
          <Grid container item style={{ marginBottom: 8 }}>
            <Button
              style={{ marginLeft: 8, color: grey[700] }}
              startIcon={<EditRounded />}
              onClick={() => {
                setSaveData({});
                clear();
              }}
              disabled={disabled}
            >
              edit
            </Button>
          </Grid>
          <Grid container item style={{ position: 'relative', height: canvasHeight, width: canvasWidth }}>
            <img
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                userSelect: 'none',
                pointerEvents: 'none',
              }}
              src={initial?.base64Data}
              alt="signature"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid container item style={{ marginBottom: 8 }}>
            <Button style={{ color: grey[700] }} startIcon={<Undo />} onClick={undo} disabled={disabled}>
              undo
            </Button>
            <Button
              style={{ marginLeft: 8, color: grey[700] }}
              startIcon={<Clear />}
              onClick={clear}
              disabled={disabled}
            >
              clear
            </Button>
          </Grid>
          <Grid container item style={{ position: 'relative', height: canvasHeight, width: canvasWidth }}>
            <CanvasDraw
              canvasHeight={canvasHeight}
              canvasWidth={canvasWidth}
              ref={(canvasDraw) => {
                canvasRef.current = canvasDraw;
              }}
              onChange={triggerTimer}
              brushRadius={2}
              loadTimeOffset={0}
              lazyRadius={0}
              style={{ position: 'absolute' }}
              saveData={saveData.jsonData}
              immediateLoading
              disabled={disabled}
            />
            {disabled && (
              <Grid
                container
                item
                style={{
                  position: 'absolute',
                  height: canvasHeight,
                  width: canvasWidth,
                  background: grey[700],
                  opacity: 0.5,
                }}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
