import React from 'react';
import { drawerOptions } from '../../constants';

export const firstWorkflowTour = [
  {
    title: 'Workflow',
    target: '.addIcon',
    content: 'Click here to add tasks',
    ...drawerOptions,
  },
  {
    title: 'Explore Workflow',
    target: '.exploreWorkflow',
    content: 'Explore the different pages of workflow to see what you can do',
    ...drawerOptions,
  },
  {
    target: '.calendarView',
    content: 'Use workflow in calendar view',
    ...drawerOptions,
    locale: {
      last: 'Finish',
    },
  },
];

export const initTask = [
  {
    target: '.addIcon',
    content: 'Click the plus button',
    ...drawerOptions,
    locale: {
      last: 'Next',
    },
  },
  // {
  //   target: '.task',
  //   content: 'Click here to add a task',
  //   ...drawerOptions,
  // },
];

export const createTask = [
  {
    target: '.taskTitle',
    content: 'Add a Title to your task here',
    ...drawerOptions,
  },
  {
    target: '.dueDate',
    content:
      'Pick a date the task is due on or make it a reoccuring task and schedule it to be created daily,weekly, etc...',
    ...drawerOptions,
  },
  {
    target: '.priority',
    content: 'Give the task a priority',
    ...drawerOptions,
  },
  {
    target: '.selectUser',
    content: 'Pick a user in your organization to send a task to, leaving it blank will assign the task to you',
    ...drawerOptions,
  },
  {
    target: '.description',
    content: 'Add some information about the task or what you want them to do',
    ...drawerOptions,
  },
  {
    target: '.attach',
    content: 'You can click the attach button to upload documents, or link to a document elsewhere in the app',
    ...drawerOptions,
  },
  {
    target: '.subTasks',
    content: 'You can add subtasks, like a to do list of things that need to be done',
    ...drawerOptions,
  },
  {
    target: '.linkedTasks',
    content: 'Use the linked tasks feature to link associated tasks together.',
    ...drawerOptions,
    locale: {
      last: 'Finish',
    },
  },
];

export const showProject = [
  {
    target: '.projectTab',
    content: 'This is where projects are managed',
    ...drawerOptions,
    locale: {
      last: 'Next',
    },
  },
];

export const initProject = [
  {
    target: '.projectAdd',
    content: 'Create a project by clicking here',
    ...drawerOptions,
    locale: {
      last: 'Next',
    },
  },
];

export const createProject = (setState) => [
  {
    target: '.projectTitle',
    content: (
      <>
        <p>Name your project here</p>
        <input type="text" onChange={(e) => setState(e.target.value)} />
      </>
    ),
    ...drawerOptions,
  },
  {
    target: '.projectTemplate',
    content: 'You can also choose a project template if some have been created',
    ...drawerOptions,
    placement: 'left',
  },
  {
    target: '.projectColumn',
    content: (
      <p>
        Create project columns here, each coloumn acts a task status so you can easily manage your tasks. Move these
        around into an order that suits you.{' '}
        <strong>You can't change the not started column or the complete column but you can rename them </strong>
      </p>
    ),
    ...drawerOptions,
  },
  {
    target: '.projectTags',
    content: 'Give your projects tags to help filter through tasks',
    ...drawerOptions,
  },
  {
    target: '.projectBacklog',
    content:
      'Turn the switch on to include a project backlog, where you can add a list of tasks for your project before it is started',
    ...drawerOptions,
  },
  {
    target: '.projectSave',
    content: 'Now save your project',
    ...drawerOptions,
    placement: 'left',
    locale: {
      last: 'Next',
    },
  },
];

export const createdProject = [
  {
    target: '.projectActions',
    content:
      'Search for tasks, see all the attachments in your tasks or add project resources and use the settings to change the project details.',
    ...drawerOptions,
  },
  {
    target: '.projectMembers',
    content: 'Click here to see or add members to this project',
    ...drawerOptions,
  },
  {
    target: '.projectChart',
    content: 'You can view tasks in column view or in a GANTT chart view',
    ...drawerOptions,
  },
  {
    target: '.projectComplete',
    content: 'You are all done!',
    ...drawerOptions,
    locale: {
      last: 'Finish',
    },
  },
];
