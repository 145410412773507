import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { blue } from '@mui/material/colors';
import { RegistersIcon } from '../../../Global/Icons';

export default function RowInfo({ rowId }) {
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (!rowId) return;
    axios
      .get('/registers/general/getBriefRowInfo', { params: { rowId } })
      .then((res) => setInfo(res.data))
      .catch((err) => console.error(err));
  }, [rowId]);

  if (!info || !rowId) return null;

  return (
    <Grid container item alignItems="center" style={{ margin: '1em 0' }}>
      <RegistersIcon />
      <Typography style={{ fontSize: 18, fontWeight: 500, marginRight: '0.25em', marginLeft: '1rem' }}>
        This is an entry for row #{info.rowNumber} in register
      </Typography>
      <Link
        to={`/registers/${info.registerId}?rowId=${info.rowId}&highlightRow=true`}
        style={{ fontSize: 18, fontWeight: 500, color: blue[800] }}
      >
        {info.registerTitle}
      </Link>
    </Grid>
  );
}
