import React from 'react';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { CachedOutlined, ErrorOutline, HelpOutline, Assignment, GridOnRounded } from '@mui/icons-material';
import { StorageRounded, CheckCircle, BallotRounded, GroupWork, Public } from '@mui/icons-material';
import { CheckCircleOutlineRounded, PieChart, ShoppingBasket, AccountTree, Person } from '@mui/icons-material';
import {
  WorkOutline,
  Description,
  FileCopy,
  Timeline,
  ViewComfyRounded,
  PlaylistAddCheck,
  LabelRounded,
  ConstructionRounded,
} from '@mui/icons-material';
import hubTaskImg from '../../../Images/icons/hubTask.png';
import RequiredGreyIcon from '../../../Images/icons/RequiredGrey.svg';
import RequiredRedIcon from '../../../Images/icons/RequiredRed.svg';
import { ReactComponent as Calculator } from '../../../Images/icons/calculator.svg';
import { blue, green, cyan, lightGreen, purple, teal, amber, grey } from '@mui/material/colors';
import { HUB_COLORS } from '../../../constants';

export { ReactComponent as MicrosoftIcon } from '../../../Images/icons/ms-symbollockup_signin_dark.svg';
export { ReactComponent as Microsoft365Icon } from '../../../Images/icons/microsoft.svg';

export const TaskIcon = ({ style, ...props }) => (
  <Grid style={{ display: 'flex' }} {...props}>
    <WorkOutline style={{ color: HUB_COLORS.work, ...style }} />
  </Grid>
);

export const ReadUnderstoodIcon = (props) => <PlaylistAddCheck style={{ color: blue[500] }} {...props} />;

export const FormIcon = ({ style, ...props }) => (
  <Description style={{ color: HUB_COLORS.form, ...style }} {...props} />
);

export const DocIcon = ({ style, ...props }) => <FileCopy style={{ color: HUB_COLORS.doc, ...style }} {...props} />;

export const ProcessIcon = ({ style, ...props }) => (
  <Timeline style={{ color: HUB_COLORS.process, ...style }} {...props} />
);

export const HubIcon = ({ style, ...props }) => (
  <Grid style={{ display: 'flex', ...style }} {...props}>
    <img src={hubTaskImg} alt="img" style={{ height: 24, width: 24 }} />
  </Grid>
);

export const AuditIcon = ({ style, ...props }) => (
  <CheckCircleOutlineRounded style={{ color: HUB_COLORS.audit, ...style }} {...props} />
);

export const KPIHubIcon = ({ style, ...props }) => <PieChart style={{ color: HUB_COLORS.kpi, ...style }} {...props} />;

export const ResourcesIcon = ({ style, ...props }) => <ShoppingBasket style={{ color: 'grey', ...style }} {...props} />;

export const ProjectIcon = ({ style, ...props }) => (
  <AccountTree style={{ color: 'darkorange', ...style }} {...props} />
);

export const ReoccurringIcon = ({ style, ...props }) => (
  <CachedOutlined style={{ color: 'grey', ...style }} {...props} />
);

export const RiskIcon = ({ style, ...props }) => <ErrorOutline style={{ color: 'grey', ...style }} {...props} />;

export const SupportIcon = ({ style, ...props }) => <HelpOutline style={{ color: 'grey', ...style }} {...props} />;

export const RequiredIcon = ({ style, ...props }) => (
  <Grid style={{ display: 'flex', ...style }} {...props}>
    <img src={RequiredRedIcon} alt="img" style={{ height: 20, width: 20 }} />
  </Grid>
);

export const RequiredGrey = ({ style, ...props }) => (
  <Grid style={{ display: 'flex', ...style }} {...props}>
    <img src={RequiredGreyIcon} alt="img" style={{ height: 20, width: 20 }} />
  </Grid>
);

export const CalculatorIcon = ({ style, ...props }) => (
  <Grid style={{ display: 'flex', ...style }} {...props}>
    <Calculator style={{ height: 20, width: 20, fill: style?.color || grey[600] }} />
  </Grid>
);

export const ApprovalIcon = ({ style, ...props }) => <CheckCircle style={{ color: blue[600], ...style }} {...props} />;

export const RoutineIcon = ({ style, ...props }) => <Assignment style={{ color: blue[900], ...style }} {...props} />;

export const DataIcon = ({ style, ...props }) => <StorageRounded style={{ color: blue[900], ...style }} {...props} />;

export const UserIcon = ({ style, ...props }) => <Person style={{ color: green[500], ...style }} {...props} />;

export const AssetIcon = ({ style, ...props }) => (
  <ConstructionRounded style={{ color: HUB_COLORS.asset, ...style }} {...props} />
);

export const TeamIcon = ({ style, color = cyan[600], ...props }) => (
  <GroupWork color={color} style={{ ...style }} {...props} />
);

export const RegistersIcon = ({ style, ...props }) => (
  <GridOnRounded style={{ color: HUB_COLORS.register, ...style }} {...props} />
);

export const MSIcon = ({ name, styles = {}, mini }) => (
  <Icon sx={styles} className={`ms-Icon ms-Icon--${name} ${mini ? 'ms-Icon-mini' : ''}`}></Icon>
);

export const WorkspaceIcon = ({ style, color = blue[700], ...props }) => (
  <ViewComfyRounded color={color} style={{ ...style }} {...props} />
);

export const PortalIcon = ({ style, ...props }) => <Public style={{ color: blue[600], ...style }} {...props} />;

export const FieldIcon = ({ style, ...props }) => <LabelRounded style={{ color: amber[500], ...style }} {...props} />;
