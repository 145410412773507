export const BodyParts = [
  {
    side: 'front',
    id: 'right-pectoral',
    d: 'M129.9 225a39.6 39.6 0 0022 8 34.6 34.6 0 0020-5c1.8-1.2 11.3-7.2 14-18 2.7-11.4-5.5-13.5-4-29a70.1 70.1 0 000-17 18 18 0 00-3-8 19.3 19.3 0 00-4-4c-4.7-3.5-15.8-4.4-29-1-5.9 1.4-20 5-29 18a35.5 35.5 0 00-7 19c-.5 10.7 4.7 18.8 8 24a45.4 45.4 0 0012 13z',
  },
  {
    side: 'front',
    id: 'right-rib',
    d: 'M118.9 245l2 8 3 11c2 7 1.7 10 4 18 2.1 7.3 3.4 8.4 4.3 9 2 1.3 4.3 1.6 9 2a16.4 16.4 0 005 0c1-.2 4.3-.8 6-3 2-2.4.5-4.9 0-13-.3-5.2.3-4 0-11-.2-4.8-.6-7.6 0-12a40.9 40.9 0 011-5 16 16 0 00-.5-6 14.4 14.4 0 00-.8-2c-2.8-5.8-13.5-6.8-22-12-9-5.6-10.8-12.9-13-12-1.8.6-2.7 6 2 28z',
  },
  {
    side: 'front',

    id: 'right-shoulder-front',
    d: 'M142.9 149c0-2-11.4-4.4-20-5a38 38 0 00-11 0 33.8 33.8 0 00-15 7c-6.6 5-9.8 10.7-12 15a67.1 67.1 0 00-7 25c-.8 7.1-1 8.2-.3 9 4 4.5 22.3-.4 29.3-12 3.3-5.7 1.1-7.9 6-16a49.8 49.8 0 0111-13 41.8 41.8 0 019-6c6.2-3.1 10-3 10-4z',
  },
  {
    side: 'front',
    id: 'right-elbow-front',
    d: 'M68.2 268.9c-2.5 1.4-1.4 7.6-1 10a22.1 22.1 0 004.7 10c1.4 1.8 3.4 3.6 12 8 10.5 5.4 15.8 8 18.3 6 1.1-1 1.4-3 2-7 .7-4.6 1-7 0-9-1.9-3.8-7-3-14-5-13.5-4.2-18.5-15-22-13z',
  },
  {
    side: 'front',
    id: 'right-obliques',
    d: 'M131.2 311.1c-.1 1.7-.3 4.5-1 10-1.7 14.5-2 13.8-2 16 0 3.7.8 7.5 19 33 6.2 8.8 7.6 10.4 9 10 4.6-1 3.6-21.1 3-35a143.3 143.3 0 00-2-20c-1.5-8.7-2.5-9-3-15-.6-7.8.7-11.5-2-14-1.7-1.6-4.1-1.8-9-2-5.5-.4-8.3-.6-10 1-2.3 2-1.2 4.9-2 16z',
  },
  {
    side: 'front',
    id: 'right-forearm-front',
    d: 'M65.9 285c-3.6.1-5.8 11.7-8 24-1.7 9-2.6 16.8-3 22-1 11.3-.1 10-1 16-2.2 14.8-7.7 21.7-4 26 1.3 1.4 3.8 2.5 7 4a23.8 23.8 0 006 2c5.6.7 10.4-3 12-4 5.5-4.2 6.6-9 11-19 5-11.5 5-8.7 11-22 9.5-21.3 7.8-24.8 7-26-1.4-2.2-2.4-1.4-16-7-10.3-4.4-12.7-5.9-15-8-4.8-4.6-5-8.2-7-8z',
  },
  {
    side: 'front',
    id: 'right-wrist-front',
    d: 'M47.9 375c-3.5 1.3-.8 8.3-6 18-2 3.5-3.8 5.2-3 7 .9 2 4 1.1 11 3s8.3 3.8 12 3a12.3 12.3 0 006-4 13.1 13.1 0 002-4c1.6-4.6 1.2-6.7 2-10 1.5-6.7 5-8.7 4-10-1.5-2.2-8.5 3.9-17 2-6.4-1.6-8.1-6.2-11-5z',
  },
  {
    side: 'front',
    id: 'right-knee-front',
    d: 'M128.8 577.2c-2.4.8 1.6 8 3.7 21.6l.7 4.9.2 3c.6 6.7 2 7.8 1.8 12.4 0 3.4-.8 4.5 0 6.6a8.8 8.8 0 003 3.4 12.1 12.1 0 007.2 2.5c1.5 0 1.3-.3 3.8-.5h10a44.3 44.3 0 015 1 31.4 31.4 0 006 1c1.2 0 3.9-.2 5.7-2.2a3.2 3.2 0 00.7-1c.6-1.2-.2-2.8.2-6.2.5-3.1 1.3-2.9 2.8-7.9a60.6 60.6 0 001.9-9.1c.4-2.6.5-4.4.9-7.9 1.3-13 0-10.3 1-13.7.4-2.1 1-3.3 0-4-1.8-1.1-5.5 1-12 2-2.8.5-2.9.2-16.7 0-11.3-.1-12.3 0-14.8-.6-7.3-2-9.3-5.9-11-5.3z',
  },
  {
    side: 'front',
    id: 'right-shin',
    d: 'M135.5 630c-1.6.7-1.4 4-1.8 13-.3 9.2-.6 7-.8 15-.1 3-.3 10.8 0 17 .6 11.6 2.9 12.8 6.1 30 2.5 13 2 16.3 4.5 29s3.7 12.6 3.5 17a5.9 5.9 0 00.9 4c.7.8 2 1.4 7 1a51.6 51.6 0 006.2-1 92.6 92.6 0 0111.5-2 124 124 0 010-29c.8-7 1.5-7.7 2.6-17 .7-6.2.6-6.8 1.8-25 .8-11 1-12.3.9-16 0-8.7-1-8.8-.9-16 .1-9.8 1.8-16.2 0-17-.6-.4-1 .1-2.6 1a28 28 0 01-8 2c-4.4.5-4.7-1.5-10.6-2-7.1-.9-8.5 1.8-13.2 0-4.3-1.8-5.4-4.9-7-4z',
  },
  {
    side: 'front',
    id: 'right-adbominals',
    d: 'M183.9 229c-2.5-1.8-7 .7-16 6-6.4 3.5-9.6 5.4-11 8s-1 3.3-2 15c-.7 8.5-1.1 10.1 0 11 2 1.3 5.5-2.6 15-8 10.8-6.3 14-5.8 16-10 1-2.6.7-6.4 0-14-.7-7-1.5-7.8-2-8zM178.9 262c-8.6 2.4-17.8 5-22 14-2.7 5.5-3.4 13.6 0 16 2.1 1.4 4-1 11-2 9.8-1.7 14.1 1.4 18-2 1.8-1.7 2.3-3.8 3-6 .3-1.2.7-3 0-11-.7-7.5-1.2-8.4-2-9-1.7-1.4-4.2-1.2-8 0zM186.9 292c-2-2-5.7 1.6-14 3a82.8 82.8 0 00-11 2c-1.8.4-3 .8-4 2s-1.3 2.2-1 8c.5 9.1 2 11.6 3 13a7.5 7.5 0 002 2c2.4 1.5 4.5.4 10 0 6.3-.6 7.6.5 10-1 .9-.7 1.5-1.4 4-3l3-2c1.7-2 .4-4 0-12a60.7 60.7 0 00-1-9c-.5-2.4-.7-2.8-1-3zM160.6 324.5c-2 1-.7 6.6 0 14.4.5 5.5.5 6.1.8 7.6a96.8 96.8 0 002.2 10.1c3 11.9 2.6 13 4.5 17.8l.2.6.7 1.2a60 60 0 012.9 9.7 18.5 18.5 0 001 3c1.1 2.3 2.2 3.3 4 6l2.5 4c2.3 4.1 2.5 5.7 4.5 7 .9.6 2 1.3 3 1 2.6-1 .7-10.5-1-30a170 170 0 01-1-20c.3-8 1-14 1-14 .3-3 .5-6 1-9 .8-5 1.8-8.2 0-10a4.6 4.6 0 00-2-1 13 13 0 00-7 0l-6 .8c-3.2.5-3.5.8-5.3.8h-2.8a26.9 26.9 0 00-3.2 0z',
  },
  {
    side: 'front',
    id: 'neck-right',
    d: 'M159.9 95c-1.4.6 1.2 6.3 2 17a110.2 110.2 0 010 12c-.4 13.3-1.8 17.6 1 20 1 .7 1.5.4 7 1 9 .7 12 2.7 14 1 .9-1 .9-2 1-5 .4-11.2 1.2-9 1-15-.2-7.9-.4-11.8-2-14-3-4-6.2-2.6-13-7-7.3-5-9.6-10.8-11-10z',
  },
  {
    side: 'front',
    id: 'right-quadriceps',
    d: 'M118.2 456.8v1.4l-.4 20v4c0 4.2 0 11.7 2.6 37.2 1.8 17.5 3 27.6 6.1 39.6 2.2 8.4 4.3 14.3 8.8 18 3.2 2.6 6.2 3 12.4 3.5 10.6.5 27.9 3.1 31.6-4.6 1.8-3.4.5-8.5 0-10.8-.5-1.8-2-3.5-3-7a34.2 34.2 0 01-1-4c-1.3-6.2-5.7-20.7-9-32-6.2-20.8-4.7-17-8-28-3.1-10-5.7-20.1-9-30-2.5-7-6-16.4-2-20 1.7-1.7 5-2 5-3 0-.7-1.4-1-4-2 0 0-3.4-1.2-7-3a62.8 62.8 0 01-7-4l-3-2a38.7 38.7 0 01-5-4 20 20 0 01-4-5c-2.2-4.1-1.7-8-2-8-.6 0-1 8-1 8-.4 11.9-.9 23.8-1 35.7z',
  },
  {
    side: 'front',
    id: 'right-trapezius-front',
    d: 'M159.9 115c-2.3-1.6-7.2 5-17 12-13 9.1-24.6 12-24 14 .3 1 3.3 0 10 1 6.2.7 7 1.8 13 3 4.8.8 15.7 2.8 18-1 1.3-2.3-1.4-4.3-1-11 .1-2.4.5-2.4 1-6 1-6.6 1.6-11 0-12z',
  },
  {
    side: 'front',
    id: 'right-ankle-front',
    d: 'M172.2 756.4c-1-1.2-3-1-8 0-9.9 1.8-10.4 2.3-13 2-1.6-.2-2.4-.6-3 0-1.2 1.2 1.4 4.4 3 11 .8 3 1.3 5.2 1 8-.3 3-1.3 3.8-2 8-.4 2.4-1.1 6.4 0 7 1.4.6 3.5-4.7 10-9a21.2 21.2 0 0110-4c4.5-.4 7.3 1.1 8 0s-2.4-3-4-8a18.8 18.8 0 01-1-7 18 18 0 000-5c-.2-1.5-.4-2.4-1-3z',
  },
  {
    side: 'front',
    id: 'right-foot-front',
    d: 'M177.2 783.9c-1-1.7-3.6-2-5-2a14.2 14.2 0 00-6 1 24.6 24.6 0 00-6 3 32.8 32.8 0 00-5 4 17.8 17.8 0 00-5 6 22.3 22.3 0 00-1 4c-.8 3.8-.5 4.3-1 6s-.8 1.6-4 6c-4 5.5-3.4 5.6-5 7-2.1 1.9-3.6 2.2-4 4-.3 1.3.3 2.5 1 4a14.4 14.4 0 004 5c3.6 2.5 6.5-.1 16 0 4.4 0 7.2.9 12 0a12.5 12.5 0 005-2 11.2 11.2 0 004-5 11.3 11.3 0 000-8 42.7 42.7 0 010-20v-4-5c.1-3 .4-3.3 0-4z',
  },
  {
    side: 'front',
    id: 'right-adductor',
    d: 'M156.2 480.2a940 940 0 0120.4 69.8c.3 1.6 2 9.2 2.7 9 .5 0 0-3.1.3-6.4.5-5.2 1.4-9.1 2.4-16.2 1-7.8 1.6-16.4 1.7-33.9v-6.9c0-6.2-.2-10.7-.5-16.5a337.2 337.2 0 00-2-26 2 2 0 00-1-1 1.7 1.7 0 00-1 0s-1 .2-5-2l-4-2-5-2c-.8-.4-6.7-2.8-9-3h-1.6a10.2 10.2 0 00-5 1.3c-5 3.5 1.6 20.9 6.6 35.8z',
  },
  {
    side: 'front',
    id: 'right-hip',
    d: 'M128.3 352.3c-1.3.2-2 2.6-3 7.9a186 186 0 00-3 22c-2.3 20-3.3 21.3-2 27a33.5 33.5 0 005.5 13.2c4 5.5 9.1 8.3 19.4 13.8a112 112 0 0015.2 7c6.5 2.4 8.5 2.4 13.6 5.1 3.8 2.1 5 3.4 6.2 2.9 3-1.5 1.4-12-1-19a161.9 161.9 0 00-21-37c-5.1-5.9-9.1-11-12-15-2.3-3.3-5.9-8.2-10-15-4.8-8-6-13.2-8-13z',
  },
  {
    side: 'front',
    id: 'right-biceps',
    d: 'M107.2 191.9c-1.2-.4-1.8 1.7-6 5a34.7 34.7 0 01-9 5c-4.4 1.7-7 2.7-10 2s-4-2.6-5-2c-1.5.8-.2 5 0 11 .1 2.8-.2 5.9-1 12-1.1 9-1.9 15.4-4 22a28.5 28.5 0 00-2 9.6 23.5 23.5 0 010 4.4c-.5 3.4-1.7 4.6-1 6 .6 1 1.8.9 4 2s3.2 2.3 5 4a36.5 36.5 0 0012 7c3.6 1.2 4 .5 8 2 3.7 1.4 4.7 2.5 6 2 1.9-.7 2-3.7 3-10 1.5-9.3 2-8.1 3-16a103.7 103.7 0 001-12c.2-9.9-1.6-11.5-2-23v-14c0-12.8-.4-16.6-2-17z',
  },
  {
    side: 'front',
    id: 'right-hand-front',
    d: 'M41.5 403.8a17.7 17.7 0 00-4.6 0c-3.2.6-5.3 2.6-9.4 6.5-2.9 2.8-2.9 3.3-5.6 5.6l-4.6 3.7c-4 3.2-3.7 3.4-5.6 4.7-3 2-4.4 3-6.5 3.7-2.5.8-3.9.6-4.7 1.9a3.7 3.7 0 000 3.7c1.4 2.4 5.5 2.6 8.4 1.9 4-1 7-4.2 8.4-5.6 1.9-2 3.3-4.1 4.6-3.7a1.7 1.7 0 011 .9c1.1 2.1-.3 5-1 6.5-2.6 5.8-.7 4.6-3.7 11.2-1.5 3.4-2.5 4.5-4.5 7.7a51 51 0 00-3 5.4C9 461.5 8.1 463.5 9 465a4.1 4.1 0 004 2c1.6-.2 2.5-1.6 4-4a81 81 0 005-9.9c1-2.3 2.2-4.6 4.7-9.3.7-1.4 1.9-3.7 3.3-3.6a1.8 1.8 0 011.3.8 2.3 2.3 0 010 1.8 64.7 64.7 0 01-3.7 8.4l-1.9 3.8-3.7 8.4-2 4.6c-1.2 3.7-2 5.8-1 7s4.1 1.2 6 0c1.7-1 1.7-2.3 4-8l3.3-7.4 2.8-7.4c1.5-5 2-8.2 3.7-8.4a2.2 2.2 0 011.8 1c1 1 .5 2.6 0 4.6-.7 3-1.2 6.2-1.8 9.3l-1 5.6a30.9 30.9 0 00-.8 6.7c.1 2 .2 3 1 4a3.6 3.6 0 004 1 3.5 3.5 0 002-3 52 52 0 001-6l.3-3.6c.2-2.5.5-5.7 1-9.4a40.8 40.8 0 011.8-7.4c.4-1.3.8-2.5 1.9-2.8a2.4 2.4 0 012.8 1 6.5 6.5 0 010 1.8 36.5 36.5 0 00-1 7.4v1.9c0 6-.6 7 .2 8.1a4.2 4.2 0 005 1c1.3-.8 1.3-2.5 1.4-8.2v-8.4a125.9 125.9 0 011-13c.7-7 1-10.5 1.8-14 1.3-6.3 2.6-9 1-11.2-.8-1-2.4-1.5-5.7-2.8a58.5 58.5 0 00-5.6-1.9 42.9 42.9 0 00-9.4-1.8z',
  },
  {
    side: 'front',
    id: 'head-front',
    d: 'M229.1 49.2c-1.6-1-3.4.4-5-1-1.2-1-1-3-1-7v-8c.2-9-5.7-16.5-7-18-2.6-3.3-5.4-5.2-11-9-7-4.8-10.6-5.7-13-6a22.9 22.9 0 00-4.9-.1 22.8 22.8 0 00-4.9.1c-2.3.3-6 1.2-13 6-5.6 3.8-8.3 5.7-11 9a30 30 0 00-7 18v8c.1 4 .3 6-1 7-1.5 1.4-3.3 0-5 1-3.2 2.2-.8 11.2 1 16 .7 1.6 4 10.1 8 10 1.6 0 2-1.3 3-1 2.5.8-.5 9 3 17 2.4 5.3 6.9 8.6 10 11a24.7 24.7 0 007 4 23 23 0 0010 1.2 23 23 0 009.8-1.2 25 25 0 007-4c3.2-2.4 7.7-5.7 10-11 3.6-8 .6-16.2 3-17 1-.3 1.5 1 3 1 4.1.1 7.4-8.4 8-10 1.9-4.8 4.3-13.8 1-16z',
  },
  {
    side: 'front',
    id: 'left-pectoral',
    d: 'M245.5 225a39.6 39.6 0 01-22 8 34.6 34.6 0 01-20-5c-1.8-1.2-11.3-7.2-14-18-2.7-11.4 5.5-13.5 4-29a70.1 70.1 0 010-17 18 18 0 013-8 19.3 19.3 0 014-4c4.7-3.5 15.8-4.4 29-1 5.9 1.4 20 5 29 18a35.5 35.5 0 017 19c.5 10.7-4.7 18.8-8 24a45.3 45.3 0 01-12 13z',
  },
  {
    side: 'front',
    id: 'left-rib',
    d: 'M256.5 245c-.4 1.7-1 4.5-2 8l-3 11c-2 7-1.7 10-4 18-2 7.3-3.4 8.4-4.3 9-2 1.3-4.3 1.6-9 2a16.4 16.4 0 01-5 0c-1-.2-4.3-.8-6-3-2-2.4-.5-4.9 0-13 .3-5.2-.3-4 0-11 .2-4.8.6-7.6 0-12a40.9 40.9 0 00-1-5 16 16 0 01.6-6 14.4 14.4 0 01.7-2c2.8-5.8 13.5-6.8 22-12 9-5.6 10.8-12.9 13-12 1.8.6 2.7 6-2 28z',
  },
  {
    side: 'front',
    id: 'left-shoulder-front',
    d: 'M232.5 149c0-2 11.5-4.4 20-5 5-.5 7.5-.7 11 0a33.8 33.8 0 0115 7c6.6 5 9.8 10.7 12 15a67 67 0 017 25c.8 7.1 1 8.2.3 9-4 4.5-22.2-.4-29.3-12-3.3-5.7-1.1-7.9-6-16a49.8 49.8 0 00-11-13 41.5 41.5 0 00-9-6c-6.2-3.1-10-3-10-4z',
  },
  {
    side: 'front',
    id: 'left-elbow-front',
    d: 'M307.2 268.9c2.5 1.4 1.4 7.6 1 10a22.1 22.1 0 01-4.7 10c-1.4 1.8-3.4 3.6-12 8-10.5 5.4-15.8 8-18.3 6-1.1-1-1.4-3-2-7-.6-4.6-1-7 0-9 1.9-3.8 7-3 14-5 13.6-4.2 18.5-15 22-13z',
  },
  {
    side: 'front',
    id: 'left-obliques',
    d: 'M244.2 311.1l1 10c1.7 14.5 2 13.8 2 16 0 3.7-.8 7.5-19 33-6.2 8.8-7.6 10.4-9 10-4.6-1-3.6-21.1-3-35a144.8 144.8 0 012-20c1.6-8.7 2.6-9 3-15 .6-7.8-.7-11.5 2-14 1.7-1.6 4.1-1.8 9-2 5.5-.4 8.3-.6 10 1 2.4 2 1.3 4.9 2 16z',
  },
  {
    side: 'front',
    id: 'left-forearm-front',
    d: 'M309.5 285c3.6.1 5.8 11.7 8 24 1.7 9 2.6 16.8 3 22 1 11.3.2 10 1 16 2.2 14.8 7.7 21.7 4 26-1.3 1.4-3.7 2.5-7 4a23.8 23.8 0 01-6 2c-5.6.7-10.4-3-12-4-5.5-4.2-6.6-9-11-19-5-11.5-5-8.7-11-22-9.5-21.3-7.8-24.8-7-26 1.4-2.2 2.4-1.4 16-7 10.3-4.4 12.7-5.9 15-8 4.8-4.6 5-8.2 7-8z',
  },
  {
    side: 'front',
    id: 'left-wrist-front',
    d: 'M327.5 375c3.6 1.3.8 8.3 6 18 2 3.5 3.8 5.2 3 7-.9 2-4 1.1-11 3s-8.3 3.8-12 3a12.3 12.3 0 01-6-4 13.1 13.1 0 01-2-4c-1.5-4.6-1.2-6.7-2-10-1.4-6.7-5-8.7-4-10 1.5-2.2 8.5 3.9 17 2 6.5-1.6 8.2-6.2 11-5z',
  },
  {
    side: 'front',
    id: 'left-knee-front',
    d: 'M246.6 577.2c2.4.8-1.6 8-3.7 21.6l-.6 4.9-.3 3c-.6 6.7-1.9 7.8-1.8 12.4 0 3.4.9 4.5 0 6.6a8.8 8.8 0 01-3 3.4 12.1 12.1 0 01-7.2 2.5c-1.4 0-1.2-.3-3.8-.4-1.6-.2-2 0-5 0-3.8 0-3.6-.2-5 0a44.3 44.3 0 00-5 1 31.3 31.3 0 01-6 1c-1.1-.1-3.8-.3-5.7-2.3a3.2 3.2 0 01-.7-.9c-.6-1.3.3-2.9-.2-6.3-.4-3-1.3-2.8-2.8-7.8a60.6 60.6 0 01-1.8-9.2 138 138 0 01-1-7.9c-1.2-13 0-10.3-.8-13.7-.5-2.1-1-3.3 0-4 1.7-1.1 5.4 1 12 2 2.7.5 2.7.2 16.6 0 11.3-.1 12.2 0 14.8-.6 7.2-2 9.2-5.9 11-5.3z',
  },
  {
    side: 'front',
    id: 'left-shin',
    d: 'M240 630c1.5.7 1.3 4 1.7 13 .4 9.2.6 7 .9 15 0 3 .3 10.8 0 17-.6 11.6-3 12.8-6.2 30-2.5 13-2 16.3-4.4 29s-3.8 12.6-3.6 17a5.9 5.9 0 01-.9 4c-.7.8-2 1.4-7 1a51.6 51.6 0 01-6.2-1 92.6 92.6 0 00-11.5-2 124 124 0 000-29c-.8-7-1.5-7.7-2.6-17-.7-6.2-.5-6.8-1.8-25-.7-11-.9-12.3-.9-16 .1-8.7 1-8.8 1-16-.2-9.8-2-16.2 0-17 .5-.4 1 .1 2.6 1a28.1 28.1 0 008 2c4.3.5 4.6-1.5 10.5-2 7.1-.9 8.6 1.8 13.2 0 4.3-1.8 5.5-4.9 7.1-4z',
  },
  {
    side: 'front',
    id: 'left-adbominals',
    d: 'M191.5 229c2.5-1.8 7 .7 16 6 6.4 3.5 9.6 5.4 11 8s1 3.3 2 15c.8 8.5 1.2 10.1 0 11-2 1.3-5.5-2.6-15-8-10.7-6.3-14-5.8-16-10-1-2.6-.7-6.4 0-14 .7-7 1.5-7.8 2-8zM196.5 262c8.6 2.4 17.8 5 22 14 2.7 5.5 3.4 13.6 0 16-2 1.4-4-1-11-2-9.8-1.7-14.1 1.4-18-2-1.7-1.7-2.3-3.8-3-6a31 31 0 010-11c.7-7.5 1.2-8.4 2-9 1.7-1.4 4.2-1.2 8 0zM188.5 292c2.1-2 5.7 1.6 14 3a82.8 82.8 0 0111 2c1.9.4 3 .8 4 2s1.4 2.2 1 8c-.5 9.1-2 11.6-3 13a7.5 7.5 0 01-2 2c-2.4 1.5-4.4.4-10 0-6.3-.6-7.6.5-10-1-.8-.7-1.5-1.4-4-3l-3-2c-1.7-2-.4-4 0-12a60.7 60.7 0 011-9c.5-2.4.7-2.8 1-3zM214.8 324.5c2 1 .7 6.6 0 14.4l-.7 7.6a92 92 0 01-2.3 10.1c-3 11.9-2.6 13-4.5 17.8l-.2.6-.7 1.2a60.5 60.5 0 00-2.9 9.7 18.5 18.5 0 01-1 3c-1 2.3-2.2 3.3-4 6-1.2 1.8-2 3.2-2.4 4-2.4 4.1-2.6 5.7-4.6 7-.9.6-2 1.3-3 1-2.6-1-.7-10.5 1-30a149.4 149.4 0 000-34c-.3-3-.5-6-1-9-.8-5-1.8-8.2 0-10a4.6 4.6 0 012-1 13 13 0 017 0l6 .8c3 .5 3.5.8 5.3.8h2.8a26.9 26.9 0 013.2 0z',
  },
  {
    side: 'front',
    id: 'neck-left',
    d: 'M215.5 95c1.4.6-1.2 6.3-2 17a110.2 110.2 0 000 12c.5 13.3 1.8 17.6-1 20-1 .7-1.4.4-7 1-9 .7-12 2.7-14 1-.9-1-.8-2-1-5-.4-11.2-1.1-9-1-15 .3-7.9.4-11.8 2-14 3-4 6.3-2.6 13-7 7.3-5 9.6-10.8 11-10z',
  },
  {
    side: 'front',
    id: 'left-quadriceps',
    d: 'M257.2 456.8v1.4l.4 20v4c0 4.2 0 11.7-2.6 37.2-1.7 17.6-3 27.6-6.1 39.6-2.2 8.4-4.3 14.4-8.8 18-3.1 2.6-6.2 3-12.3 3.5-10.6.6-28 3.1-31.7-4.5-1.7-3.5-.5-8.6.1-10.9.5-1.8 1.9-3.4 3-7a34.2 34.2 0 001-4c1.2-6.2 5.7-20.7 9-32 6.2-20.8 4.6-16.9 8-28 3-10 5.6-20.1 9-30 2.4-7 5.9-16.4 2-20-1.8-1.6-5-1.9-5-3 0-.7 1.3-1 4-2 0 0 3.3-1.2 7-3a61.8 61.8 0 007-4l3-2a38.7 38.7 0 005-4 19.6 19.6 0 004-5c2.2-4 1.7-8 2-8 .6 0 1 8 1 8 .3 11.9.8 23.8 1 35.7z',
  },
  {
    side: 'front',
    id: 'left-trapezius-front',
    d: 'M215.5 115c2.3-1.6 7.3 5 17 12 13 9.1 24.6 12 24 14-.3 1-3.3 0-10 1-6.1.7-7 1.8-13 3-4.8.8-15.7 2.8-18-1-1.3-2.3 1.4-4.3 1-11-.1-2.4-.4-2.4-1-6-.9-6.6-1.5-11 0-12z',
  },
  {
    side: 'front',
    id: 'left-ankle-front',
    d: 'M203.2 756.4c1-1.2 3-1 8 0 9.9 1.8 10.4 2.3 13 2 1.6-.2 2.5-.6 3 0 1.2 1.2-1.4 4.4-3 11-.8 3-1.3 5.2-1 8 .3 3 1.3 3.8 2 8 .4 2.4 1.2 6.4 0 7-1.4.6-3.4-4.7-10-9a21.2 21.2 0 00-10-4c-4.4-.4-7.3 1.1-8 0s2.5-3 4-8a18.8 18.8 0 001-7 18 18 0 010-5c.3-1.5.4-2.4 1-3z',
  },
  {
    side: 'front',
    id: 'left-foot-front',
    d: 'M198.2 783.9c1-1.7 3.6-2 5-2a14.2 14.2 0 016 1 24.6 24.6 0 016 3 32.8 32.8 0 015 4c2.7 2.5 4.1 3.8 5 6a22.3 22.3 0 011 4c.8 3.8.5 4.3 1 6s.9 1.6 4 6c4 5.5 3.5 5.6 5 7 2.1 1.9 3.6 2.2 4 4 .3 1.3-.3 2.5-1 4a14.4 14.4 0 01-4 5c-3.6 2.5-6.5-.1-16 0-4.4 0-7.2.9-12 0a12.5 12.5 0 01-5-2 11.2 11.2 0 01-4-5 11.3 11.3 0 010-8 42.7 42.7 0 000-20v-4-5c-.1-3-.4-3.3 0-4z',
  },
  {
    side: 'front',
    id: 'left-adductor',
    d: 'M219.2 480.2A940 940 0 00199 550c-.4 1.6-2 9.2-2.8 9-.5 0 0-3.1-.3-6.4-.5-5.2-1.4-9.1-2.4-16.2-1-7.8-1.6-16.4-1.7-33.9v-6.9c0-6.2.3-10.7.5-16.5a337.2 337.2 0 012-26 2 2 0 011-1 1.7 1.7 0 011 0s1 .2 5-2l4-2 5-2c.9-.4 6.7-2.8 9-3h1.6a10.2 10.2 0 015 1.3c5 3.5-1.6 20.9-6.6 35.8z',
  },
  {
    side: 'front',
    id: 'left-hip',
    d: 'M247.1 352.3c1.3.2 2 2.6 3.1 7.9a186 186 0 013 22c2.2 20 3.2 21.3 2 27a33.5 33.5 0 01-5.6 13.2c-4 5.5-9.1 8.3-19.4 13.8a112 112 0 01-15.2 7c-6.4 2.4-8.5 2.4-13.5 5.1-3.9 2.1-5 3.4-6.3 2.9-3-1.5-1.3-12 1-19a161.9 161.9 0 0121-37c5.2-5.9 9.2-11 12-15 2.4-3.3 6-8.2 10-15 4.8-8 6-13.2 8-13z',
  },
  {
    side: 'front',
    id: 'left-biceps',
    d: 'M268.2 191.9c1.2-.4 1.8 1.7 6 5a34.7 34.7 0 009 5c4.4 1.7 7 2.7 10 2s4-2.6 5-2c1.6.8.3 5 0 11-.1 2.8.3 5.9 1 12 1.1 9 1.9 15.4 4 22a28.5 28.5 0 012 9.6 23.5 23.5 0 000 4.4c.5 3.4 1.7 4.6 1 6-.6 1-1.8.9-4 2s-3.2 2.3-5 4a36.5 36.5 0 01-12 7c-3.6 1.2-4 .5-8 2-3.6 1.4-4.7 2.5-6 2-1.9-.7-2-3.7-3-10-1.5-9.3-2-8.1-3-16a103.7 103.7 0 01-1-12c-.2-9.9 1.6-11.5 2-23v-14c0-12.8.5-16.6 2-17z',
  },
  {
    side: 'front',
    id: 'left-hand-front',
    d: 'M333.9 403.8a17.7 17.7 0 014.7 0c3.1.6 5.2 2.6 9.3 6.5 2.9 2.8 2.9 3.3 5.6 5.6l4.6 3.7c4 3.2 3.7 3.4 5.6 4.7 3 2 4.4 3 6.6 3.7 2.4.8 3.8.6 4.6 1.9a3.7 3.7 0 010 3.7c-1.3 2.4-5.5 2.6-8.4 1.9-4-1-7-4.2-8.4-5.6-1.8-2-3.3-4.1-4.6-3.7a1.7 1.7 0 00-1 .9c-1.1 2.1.3 5 1 6.5 2.6 5.8.7 4.6 3.7 11.2 1.5 3.4 2.5 4.5 4.5 7.7a53.3 53.3 0 013 5.4c1.7 3.6 2.6 5.6 1.8 7.2a4.1 4.1 0 01-4 2c-1.6-.2-2.6-1.6-4-4a81 81 0 01-5-9.9c-1-2.3-2.2-4.6-4.7-9.3-.7-1.4-1.9-3.7-3.3-3.6a1.8 1.8 0 00-1.3.8 2.3 2.3 0 000 1.8 64.7 64.7 0 003.7 8.4l1.9 3.8 3.7 8.4 2 4.6c1.2 3.7 2 5.8 1 7s-4.1 1.2-6 0c-1.7-1-1.7-2.3-4-8l-3.3-7.4-2.8-7.4c-1.5-5-2-8.2-3.7-8.4a2.2 2.2 0 00-1.8 1c-1 1-.5 2.6 0 4.6.7 3 1.2 6.2 1.8 9.3l1 5.6a30.9 30.9 0 01.8 6.7c-.1 2-.2 3-1 4a3.6 3.6 0 01-4 1 3.5 3.5 0 01-2-3 52 52 0 01-1-6l-.3-3.6c-.2-2.5-.5-5.7-1-9.4a40.8 40.8 0 00-1.8-7.4c-.4-1.3-.8-2.5-1.9-2.8a2.4 2.4 0 00-2.8 1 6.5 6.5 0 000 1.8 36.5 36.5 0 011 7.4v1.9c0 6 .6 7-.2 8.1a4.2 4.2 0 01-5 1c-1.3-.8-1.3-2.5-1.4-8.2v-8.4a125.9 125.9 0 00-1-13c-.7-7-1-10.5-1.8-14-1.3-6.3-2.6-9-1-11.2.8-1 2.4-1.5 5.7-2.8a58.5 58.5 0 015.6-1.9 42.9 42.9 0 019.3-1.8z',
  },
  {
    side: 'back',
    id: 'spinal-column',
    d: 'M185.8 115.3c0 4.5.3 4.6.5 9a90 90 0 010 12 62.5 62.5 0 000 9 4.8 4.8 0 00.5 2 1 1 0 002 0 4.8 4.8 0 00.5-2 62.5 62.5 0 000-9 90 90 0 010-12c.2-4.4.4-4.5.5-9v-8c0-4 .2-5.2 0-8l-.5-5c-.1-1.2-.4-4.5-1-5a1 1 0 00-.3-.1.5.5 0 00-.3 0 1 1 0 00-.3.2c-.6.5-1 4.6-1 5-.4 4.3-.5 3.3-.6 5v16zM185.8 177.2c0 4.5.3 4.6.5 9a90 90 0 010 12 62.5 62.5 0 000 9 4.8 4.8 0 00.5 2 1 1 0 002 0 4.8 4.8 0 00.5-2 62.5 62.5 0 000-9 90 90 0 010-12c.2-4.4.4-4.5.5-9v-8c0-4 .2-5.2 0-8l-.5-5c-.1-1.2-.4-4.5-1-5a1 1 0 00-.3-.1.5.5 0 00-.3 0 .8.8 0 00-.3.1c-.6.6-1 4.6-1 5-.4 4.4-.5 3.4-.6 5v16zM190 232.8v-8c-.2-1.7-.2-.7-.6-5 0-.4-.4-4.5-1-5a.8.8 0 00-.4-.2.7.7 0 00-.2 0 .7.7 0 00-.2 0 .8.8 0 00-.3.2c-.7.5-1 4.6-1 5-.5 4.3-.5 3.3-.6 5v16c.1 4.4.4 4.5.6 9a88.3 88.3 0 010 12v.5a3.2 3.2 0 001 2 13.5 13.5 0 011 0 3.2 3.2 0 001-2v-.5a88.3 88.3 0 010-12c.2-4.5.4-4.6.5-9 .2-3.4 0-3.1.1-8zM189.3 287.3c0-6.5-.6-8.4-.5-13v-3s0-4.3-.3-4.6a1.2 1.2 0 00-.3-.2h-.8a1.2 1.2 0 00-.3.2c-.4.3-.4 4.6-.4 4.6v3c.1 4.6-.4 6.5-.5 13v11c.1 3.4.4 5.2.5 7a31.2 31.2 0 01-.5 8 5.7 5.7 0 00.5 2 1 1 0 002.1 0 5.7 5.7 0 00.5-2 31.6 31.6 0 01-.5-8c.2-1.8.5-3.6.5-7v-3-8zM189 335.2c0-5-.5-6.6-.4-10v-2.3s0-3.3-.2-3.6a1.2 1.2 0 00-.3-.1h-.6a1.2 1.2 0 00-.3.1c-.2.3-.2 3.6-.2 3.6v2.3c0 3.5-.3 5-.4 10v8.5c0 2.6.3 4 .4 5.4a24.6 24.6 0 01-.4 6.1 4.1 4.1 0 00.4 1.6.8.8 0 001.6 0 4.1 4.1 0 00.4-1.6 24.6 24.6 0 01-.4-6.1l.4-5.4v-2.3-6.2zM188.5 367.8c0-2.8-.3-3.7-.3-5.7v-1.3a9.6 9.6 0 000-2 .5.5 0 00-.2 0h-.4a.5.5 0 00-.1 0 9.6 9.6 0 00-.2 2v1.3l-.2 5.7v4.8l.2 3a14.1 14.1 0 01-.2 3.6 2.3 2.3 0 00.2.8.5.5 0 001 0 2.3 2.3 0 00.2-.8 14.1 14.1 0 01-.3-3.5l.3-3.1v-1.3-3.5z',
  },
  {
    side: 'back',
    id: 'head-back',
    d: 'M230.2 49.2c-1.6-1-3.4.4-5-1-1.2-1-1-3-1-7v-8a30 30 0 00-7-18c-2.6-3.3-5.4-5.2-11-9-7-4.8-10.6-5.7-13-6a22.8 22.8 0 00-4.9-.1 22.9 22.9 0 00-4.9.1c-2.3.3-6 1.2-13 6-5.6 3.8-8.4 5.7-11 9a30 30 0 00-7 18v8c.1 4 .3 6-1 7-1.5 1.4-3.3 0-5 1-3.2 2.2-.8 11.2 1 16 .6 1.6 4 10.1 8 10 1.5 0 1.8-1.2 3-1 3.3.5 2.4 9.2 6.6 11.1 2.4 1.1 4.3-1 9-2a24.5 24.5 0 0113 1 39.4 39.4 0 0115-1c6.3.9 9.5 3 12 1 3.4-2.5 1.7-9.4 4.2-10 1-.4 1.6 1 3 1 4.1 0 7.4-8.5 8-10 1.9-4.9 4.3-14 1-16z',
  },
  {
    side: 'back',
    id: 'back-left',
    d: 'M118.7 247.4l2 8 3 11c2.1 7.7 2.8 14.2 4 18 .6 2.2 2 11 3 29 1.4 24.7-.2 31 5.3 36 3.8 3.3 10 5.1 14 3 5-2.9 2.6-10.2 7-25 2-7 4.2-14.3 10-21 7.7-9 13.5-7.5 16-14 2.9-7.5-1.9-16-7-25-5.9-10.5-8.3-9-13-19-2.5-5.5-6-10.5-8-16a7.3 7.3 0 00-3-4c-2.4-1.5-5.4-.7-7 0a29 29 0 01-7 0c-12.5-1.6-17.6-11-21-9-4.5 2.4-.4 19.3 1.7 28z',
  },
  {
    side: 'back',
    id: 'left-shoulder-back',
    d: 'M142.7 151.4c0-2-11.4-4.2-20-5-5-.4-7.5-.6-11 0a33.8 33.8 0 00-15 7c-6.6 5-9.8 10.8-12 15a67 67 0 00-7 25c-.8 7.3-1 8.3-.3 9 4 4.6 22.3-.3 29.3-12 3.3-5.5 1.1-7.7 6-16a50 50 0 0111-13 41.9 41.9 0 019-6c6.2-3 10-2.8 10-4z',
  },
  {
    side: 'back',
    id: 'left-elbow-back',
    d: 'M68 271.4c-2.5 1.4-1.4 7.7-1 10a22 22 0 004.7 10c1.4 1.8 3.4 3.7 12 8 10.5 5.4 15.8 8 18.3 6 1.1-.9 1.4-3 2-7 .7-4.5 1-7 0-9-1.9-3.8-7-3-14-5-13.5-4.1-18.5-15-22-13z',
  },
  {
    side: 'back',
    id: 'left-forearm-back',
    d: 'M65.7 287.4c-3.6.3-5.8 11.8-8 24-1.7 9.2-2.6 17-3 22-1 11.5-.1 10.1-1 16-2.2 15-7.7 21.9-4 26 1.3 1.6 3.8 2.6 7 4a23 23 0 006 2c5.6.8 10.4-2.8 12-4 5.5-4 6.6-8.9 11-19 5-11.4 5-8.6 11-22 9.5-21.2 7.8-24.7 7-26-1.4-2.1-2.4-1.3-16-7-10.3-4.2-12.7-5.7-15-8-4.8-4.5-5-8-7-8z',
  },
  {
    side: 'back',
    id: 'left-wrist-back',
    d: 'M47.7 377.4c-3.6 1.4-.8 8.4-6 18-2 3.6-3.8 5.3-3 7 .9 2.1 4 1.2 11 3s8.3 4 12 3a12.3 12.3 0 006-4 13.1 13.1 0 002-4c1.6-4.5 1.2-6.6 2-10 1.4-6.5 5-8.6 4-10-1.5-2-8.5 4-17 2-6.4-1.4-8.2-6.1-11-5z',
  },
  {
    side: 'back',
    id: 'left-hamstring',
    d: 'M133 445.7c-6.8-6.6-7-7.6-8-7.4-6.4 1.4-7.2 32.4-7.3 33.7v4.3c0 4.6 0 12.8 2.7 40.4 1.7 18.9 3 29.7 6.1 42.7 2.2 9.2 4.3 15.5 8.8 19.6 3.2 2.7 6.2 3.3 12.4 3.6 19.1 1.2 28.7 1.7 31.6-4.9 1.7-3.9-.1-5.9 0-18.3.1-10 1.4-14 2.7-24.4 1.3-10.8.8-17.6 1.7-36.7a248.7 248.7 0 012.6-28c.3-3.9.6-5.8.7-6.8 0-1.7 1-10-2-12.2-2.3-1.6-5 1.7-13 4a44.8 44.8 0 01-20 1c-3.3-.5-6.4-1-10-3-1.3-.7-3-1.8-9-7.6z',
  },
  {
    side: 'back',
    id: 'left-calf',
    d: 'M135.3 632.4c-1.6.8-1.4 4.1-1.8 13-.3 9.3-.6 7-.8 15-.1 3-.4 10.9 0 17 .6 11.7 2.9 13 6.1 30 2.5 13 2 16.5 4.5 29s3.7 12.8 3.5 17c0 .9-.2 2.8.9 4 .7 1 2 1.5 7 1a47.3 47.3 0 006.2-1 96.6 96.6 0 0111.5-2 124 124 0 010-29c.8-7 1.5-7.5 2.6-17 .7-6 .5-6.7 1.8-25 .7-10.9 1-12.1.9-16-.1-8.6-1-8.7-1-16 .2-9.6 2-16 0-17-.5-.2-1 .3-2.6 1a28.1 28.1 0 01-7.9 2c-4.4.6-4.7-1.3-10.6-2-7.1-.7-8.5 2-13.2 0-4.3-1.6-5.5-4.8-7.1-4z',
  },
  {
    side: 'back',
    id: 'left-trapezius-back',
    d: 'M182 98.3c1-6.4 3.1-8.3 2-10-2.4-3.4-16.6-2-22 6-5.2 7.8 2.1 15.2-3.1 24.3-1 1.8-2.4 3.3-16.2 10.8-15.7 8.5-24.5 12.3-24 14 .3 1 3.3.2 10 1 5 .7 6 1.5 13 3a95.7 95.7 0 0011.3 2 49.3 49.3 0 0016-1 16 16 0 007-3c4.4-3.6 5.2-9.3 6-16 .6-5 .4-9.7 0-19-.3-6.6-.6-8.3 0-12z',
  },
  {
    side: 'back',
    id: 'left-ankle-back',
    d: 'M172 758.9c-1-1.2-3-1-8 0-9.9 1.8-10.4 2.3-13 2-1.6-.2-2.5-.5-3 0-1 1 .3 3.5 3 11a62.7 62.7 0 012 6.4 38 38 0 011 7c.3 6.7-1.9 10.6-1 11 1.3.7 4.7-8.6 12-10a11.7 11.7 0 017 1c3 1.5 4.8 4 8 9 2.7 4.2 3.4 6.2 4 6 .8-.2.9-5.2-1-10-2.8-7-7.4-7.4-9-14a40 40 0 01-1-7V767a21.3 21.3 0 000-5c-.3-1.5-.4-2.4-1-3z',
  },
  {
    side: 'back',
    id: 'left-hand-back',
    d: 'M41.5 406a17.7 17.7 0 00-4.6 0c-3.2.6-5.3 2.6-9.4 6.5-2.9 2.8-2.9 3.3-5.6 5.6l-4.6 3.7c-4 3.2-3.7 3.4-5.6 4.7-3 2-4.4 3-6.5 3.7-2.5.8-3.9.7-4.7 2a3.7 3.7 0 000 3.6c1.4 2.4 5.5 2.6 8.4 2 4-1 7-4.3 8.4-5.7 1.9-2 3.3-4.1 4.6-3.7a1.7 1.7 0 011 1c1.1 2-.3 5-1 6.4-2.6 5.9-.7 4.7-3.7 11.2-1.5 3.4-2.5 4.5-4.5 7.8a51.4 51.4 0 00-3 5.3c-1.7 3.6-2.6 5.6-1.7 7.2a4.1 4.1 0 004 2c1.6-.2 2.5-1.5 4-4a81.8 81.8 0 005-9.9c1-2.3 2.2-4.6 4.7-9.3.7-1.3 1.9-3.7 3.3-3.6a1.7 1.7 0 011.3.8 2.3 2.3 0 010 1.9 64.4 64.4 0 01-3.7 8.4l-1.9 3.7c-.9 1.9-1.3 2.7-3.7 8.4l-2 4.6c-1.2 3.7-2 5.8-1 7s4.1 1.3 6 0c1.7-1 1.7-2.3 4-8 1.6-3.8 1.7-3.3 3.3-7.4.9-2.3 1.8-4.4 2.8-7.4 1.5-5 2-8.2 3.7-8.4a2.2 2.2 0 011.8 1c1 1 .5 2.7 0 4.6-.7 3-1.2 6.2-1.8 9.3l-1 5.6a30.8 30.8 0 00-.8 6.7c.1 2 .2 3 1 4a3.7 3.7 0 004 1 3.5 3.5 0 002-3 52.9 52.9 0 001-6l.3-3.6c.2-2.5.5-5.7 1-9.3a41 41 0 011.8-7.5c.4-1.3.8-2.4 1.9-2.8a2.4 2.4 0 012.8 1 6.5 6.5 0 010 1.8 36.6 36.6 0 00-1 7.5v1.8c0 6-.6 7 .2 8.1a4.2 4.2 0 005 1c1.3-.8 1.3-2.5 1.4-8.2v-8.3a125.9 125.9 0 011-13.1c.7-7 1-10.5 1.8-14 1.3-6.3 2.6-9 1-11.2-.8-1-2.4-1.5-5.7-2.8a58.5 58.5 0 00-5.6-1.9 42.9 42.9 0 00-9.4-1.8z',
  },
  {
    side: 'back',
    id: 'left-triceps',
    d: 'M107 194.4c-1.2-.3-1.8 1.8-6 5a34.4 34.4 0 01-9 5c-4.4 1.7-7 2.8-10 2s-4-2.6-5-2c-1.6.8-.3 5 0 11 .1 2.9-.3 6-1 12a116 116 0 01-4 22 28.5 28.5 0 00-2 9.7 23.4 23.4 0 010 4.3c-.5 3.4-1.7 4.6-1 6 .6 1.1 1.8 1 4 2s3.2 2.4 5 4a36.3 36.3 0 0012 7c3.6 1.2 4 .5 8 2 3.6 1.4 4.7 2.5 6 2 1.9-.7 2-3.6 3-10 1.5-9.3 2-8.1 3-16a103.5 103.5 0 001-12c.2-9.8-1.6-11.4-2-23v-14c0-12.7-.5-16.6-2-17z',
  },
  {
    side: 'back',
    id: 'left-buttock',
    d: 'M160 366.3c6.7 3.4 9.2 4.2 12 7a98.3 98.3 0 018 9 41.5 41.5 0 018 16 44.8 44.8 0 011 9c.2 4.8-.1 6.6 0 15v5.2c0 3.8-.4 7.4-1 12.8-.3 2.7-1 6.9-4 9-2.2 1.5-3.5.5-13 1-14.5.8-14.6 2-19 1-5.7-1.3-9.6-4.2-16-9-5-3.8-7.6-5.6-9-8-2.2-3.6-2.6-7.3 0-28 1.3-10.4 1.5-10.4 2-16 1-10.3 1.4-15.5 1-20a72.6 72.6 0 010-15c.5-4.2 1-5.5 2-6 2.3-1 5 3 12 8 4.3 3.1 8.2 5 16 9z',
  },
  {
    side: 'back',
    id: 'left-scapula-surface',
    d: 'M170 150.3a217.7 217.7 0 00-32 6 46.7 46.7 0 00-13 8 36.7 36.7 0 00-8 9 23.7 23.7 0 00-4 10c-.6 5.3 1.6 5.8 2 12 .5 8.5-3.4 11.6-1 16 .8 1.5 1.4 1.5 7 5 10.7 6.8 10.1 8.1 14 9 2.7.7 7.8 2 13 0 1.6-.6 3.2-1.6 5-1 .7.3 1.8.9 4 5a48.9 48.9 0 013 7 54 54 0 003 7c3.5 6.7 6.4 8.9 11 15 6.8 9.1 8.6 15.5 10 15s.3-7.4 0-10a614.6 614.6 0 01-5-62 298 298 0 010-29c1-10.5 3.1-19.2-1-22-.8-.5-1.6-.7-8 0z',
  },
  {
    side: 'back',
    id: 'left-foot-back',
    d: 'M173 791.3a10.9 10.9 0 00-5-2c-3.9-.3-6.9 2.1-8 3-3 2.5-6 7-6 12 0 2.9 1 4.4 0 7a10.2 10.2 0 01-3 4c-2.6 2.4-4.5 2-6 4a6.4 6.4 0 00-1 6c.3.7 1.3 2.6 8 4 5 1.1 6 .4 11 1 8.2 1 9.9 3.4 14 2a11.7 11.7 0 006-5c1.3-2.3 1.3-4.4 1-9-.7-9.5-1-14.2-3-18a23.6 23.6 0 00-8-9z',
  },
  {
    side: 'back',
    id: 'left-lumbar',
    d: 'M184 299.3c-2-1-5.2 1.8-9 5a41.6 41.6 0 00-8 9 53 53 0 00-7 16c-2.8 10.8-.1 13.5-4 19-2 2.8-4.3 4.3-4 7 .2 2 1.6 3.5 5 6 4.8 3.5 8 5 12 7 7.3 4 11.1 6 14 10 3.6 5.1 3.5 10.1 4 10 1-.1-2.7-20.5-3-46-.2-12.3-.2-18.4 0-22 .8-12.2 2.7-19.7 0-21z',
  },
  {
    side: 'back',
    id: 'left-knee-back',
    d: 'M130 579.2c-2.3.8 1.7 8 3.8 21.6l.6 4.9.3 3c.6 6.7 1.9 7.8 1.8 12.4-.1 3.4-.9 4.5 0 6.6a8.8 8.8 0 003 3.4 12 12 0 007.2 2.5c1.4 0 1.2-.3 3.8-.5h10a44.3 44.3 0 015 1 31.4 31.4 0 006 1c1.2 0 3.9-.2 5.7-2.2a3.3 3.3 0 00.7-1c.6-1.3-.3-2.9.2-6.2.4-3.2 1.3-3 2.8-8a60.4 60.4 0 001.8-9 69.5 69.5 0 001-8l-.7-10.5a28 28 0 00-1-6c-.7-2.4-1-3.7-2-4-1.7-.4-3 3.9-7.4 4.8a9.6 9.6 0 01-1.4.2c-1.9.2-4 0-15.2-.2s-12.3 0-14.8-.6c-7.3-1.9-9.4-5.8-11.1-5.2z',
  },
  {
    side: 'back',
    id: 'back-right',
    d: 'M257.4 247.4l-2 8-3 11c-2.1 7.7-2.8 14.2-4 18-.6 2.2-2 11-3 29-1.4 24.7.2 31-5.3 36-3.8 3.3-10 5.1-14 3-5-2.9-2.6-10.2-7-25-2-7-4.2-14.3-10-21-7.7-9-13.4-7.5-16-14-2.8-7.5 1.9-16 7-25 5.9-10.5 8.3-9 13-19 2.5-5.5 6-10.5 8-16a7.3 7.3 0 013-4c2.4-1.5 5.4-.7 7 0a29 29 0 007 0c12.5-1.6 17.6-11 21-9 4.4 2.4.4 19.3-1.7 28z',
  },
  {
    side: 'back',
    id: 'right-shoulder-back',
    d: 'M233.4 151.4c0-2 11.5-4.2 20-5 5-.4 7.5-.6 11 0a33.9 33.9 0 0115 7c6.6 5 9.8 10.8 12 15a67.3 67.3 0 017 25c.8 7.3 1 8.3.3 9-4 4.6-22.2-.3-29.3-12-3.3-5.5-1.1-7.7-6-16a50 50 0 00-11-13 42.4 42.4 0 00-9-6c-6.2-3-10-2.8-10-4z',
  },
  {
    side: 'back',
    id: 'right-elbow-back',
    d: 'M308.1 271.4c2.5 1.4 1.4 7.7 1 10a22 22 0 01-4.7 10c-1.4 1.8-3.3 3.7-12 8-10.5 5.4-15.8 8-18.3 6-1.1-.9-1.4-3-2-7-.7-4.5-1-7 0-9 1.9-3.8 7-3 14-5 13.5-4.1 18.5-15 22-13z',
  },
  {
    side: 'back',
    id: 'right-forearm-back',
    d: 'M310.4 287.4c3.6.3 5.8 11.8 8 24 1.7 9.2 2.6 17 3 22 1 11.5.2 10.1 1 16 2.2 15 7.7 21.9 4 26a20.9 20.9 0 01-7 4 23 23 0 01-6 2c-5.6.8-10.4-2.8-12-4-5.5-4-6.6-8.9-11-19-5-11.4-5-8.6-11-22-9.5-21.2-7.8-24.7-7-26 1.4-2.1 2.4-1.3 16-7 10.3-4.2 12.7-5.7 15-8 4.8-4.5 5-8 7-8z',
  },
  {
    side: 'back',
    id: 'right-wrist-back',
    d: 'M328.4 377.4c3.6 1.4.8 8.4 6 18 2 3.6 3.8 5.3 3 7-.9 2.1-4 1.2-11 3s-8.3 4-12 3a12.1 12.1 0 01-6-4 12.7 12.7 0 01-2-4c-1.5-4.5-1.2-6.6-2-10-1.4-6.5-5-8.6-4-10 1.5-2 8.5 4 17 2 6.4-1.4 8.2-6.1 11-5z',
  },
  {
    side: 'back',
    id: 'right-hamstring',
    d: 'M243.1 445.7c6.8-6.6 7-7.6 8-7.4 6.4 1.4 7.2 32.4 7.3 33.7v4.3c0 4.6 0 12.8-2.6 40.4-1.8 18.9-3.1 29.7-6.2 42.7-2.1 9.2-4.2 15.5-8.8 19.6-3.1 2.7-6.1 3.3-12.3 3.6-19.2 1.2-28.8 1.7-31.7-4.9-1.7-3.9.2-5.9 0-18.3 0-10-1.3-14-2.6-24.4-1.3-10.8-.9-17.6-1.8-36.7a244.8 244.8 0 00-2.5-28c-.3-3.9-.7-5.8-.7-6.8-.1-1.7-1.1-10 1.9-12.2 2.3-1.6 5.1 1.7 13 4a44.8 44.8 0 0020 1c3.4-.5 6.5-1 10-3 1.3-.7 3-1.8 9-7.6z',
  },
  {
    side: 'back',
    id: 'right-calf',
    d: 'M240.8 632.4c1.6.8 1.4 4.1 1.8 13 .4 9.3.6 7 .9 15 0 3 .3 10.9 0 17-.6 11.7-3 13-6.2 30-2.5 13-2 16.5-4.4 29s-3.8 12.8-3.6 17c0 .9.2 2.8-.9 4-.7 1-2 1.5-7 1a47.6 47.6 0 01-6.2-1 96.7 96.7 0 00-11.5-2 125.2 125.2 0 000-29c-.8-7-1.5-7.5-2.6-17-.7-6-.5-6.7-1.8-25-.7-10.9-.9-12.1-.9-16 .1-8.6 1-8.7 1-16-.2-9.6-2-16 0-17 .5-.2 1 .3 2.6 1a28.2 28.2 0 008 2c4.3.6 4.6-1.3 10.5-2 7.1-.7 8.6 2 13.2 0 4.3-1.6 5.5-4.8 7.1-4z',
  },
  {
    side: 'back',
    id: 'right-trapezius-back',
    d: 'M194.1 98.3c-1-6.4-3.1-8.3-2-10 2.4-3.4 16.6-2 22 6 5.2 7.8-2.1 15.2 3.1 24.3 1 1.8 2.4 3.3 16.2 10.8 15.7 8.5 24.5 12.3 24 14-.3 1-3.3.2-10 1-5 .7-6 1.5-13 3a96.2 96.2 0 01-11.3 2 49.3 49.3 0 01-16-1 16 16 0 01-7-3c-4.4-3.6-5.2-9.3-6-16-.6-5-.4-9.7 0-19 .3-6.6.6-8.3 0-12z',
  },
  {
    side: 'back',
    id: 'right-ankle-back',
    d: 'M204.1 758.9c1-1.2 3-1 8 0 9.9 1.8 10.4 2.3 13 2 1.6-.2 2.5-.5 3 0 1 1-.3 3.5-3 11a65.5 65.5 0 00-2 6.4 38 38 0 00-1 7c-.3 6.7 1.9 10.6 1 11-1.3.7-4.7-8.6-12-10a11.7 11.7 0 00-7 1c-3 1.5-4.8 4-8 9-2.7 4.2-3.4 6.2-4 6-.8-.2-.9-5.2 1-10 2.8-7 7.4-7.4 9-14a40 40 0 001-7V767a21.3 21.3 0 010-5c.3-1.5.4-2.4 1-3z',
  },
  {
    side: 'back',
    id: 'right-hand-back',
    d: 'M334.6 406a17.7 17.7 0 014.6 0c3.2.6 5.3 2.6 9.4 6.5 2.9 2.8 2.9 3.3 5.5 5.6 1.6 1.4 1.9 1.4 4.7 3.7 4 3.2 3.7 3.4 5.6 4.7 3 2 4.4 3 6.5 3.7 2.5.8 3.9.7 4.7 2a3.8 3.8 0 010 3.6c-1.4 2.4-5.6 2.6-8.4 2-4-1-7-4.3-8.4-5.7-1.9-2-3.4-4.1-4.7-3.7a1.7 1.7 0 00-.9 1c-1.1 2 .3 5 1 6.4 2.6 5.9.7 4.7 3.7 11.2 1.5 3.4 2.5 4.5 4.5 7.8a55.5 55.5 0 013 5.3c1.7 3.7 2.6 5.6 1.7 7.2a4.1 4.1 0 01-4 2c-1.5-.2-2.5-1.5-4-4a81.8 81.8 0 01-5-9.9c-1-2.3-2.2-4.6-4.6-9.3-.7-1.3-2-3.6-3.4-3.6a1.7 1.7 0 00-1.3.8 2.3 2.3 0 000 1.9 63.8 63.8 0 003.7 8.4l1.9 3.7c.9 1.8 1.3 2.7 3.7 8.4l2 4.6c1.3 3.7 2 5.8 1 7s-4 1.3-6 0c-1.6-1-1.7-2.3-4-8-1.6-3.8-1.6-3.3-3.2-7.3-1-2.4-1.8-4.5-2.8-7.5-1.6-5-2-8.2-3.7-8.4a2.2 2.2 0 00-1.9 1c-.9 1-.4 2.7 0 4.6l1.9 9.3 1 5.6a31.3 31.3 0 01.7 6.8c0 1.9-.1 3-1 4a3.7 3.7 0 01-4 1 3.5 3.5 0 01-2-3 52.9 52.9 0 01-1-6l-.3-3.7c-.1-2.5-.4-5.6-.9-9.3a41.3 41.3 0 00-1.8-7.5c-.5-1.3-.9-2.4-2-2.8a2.4 2.4 0 00-2.7 1 6.5 6.5 0 000 1.8 35.9 35.9 0 011 7.5v1.8c0 6 .6 7-.3 8.2a4.2 4.2 0 01-5 1c-1.2-.8-1.3-2.6-1.3-8.2v-8.4a128.6 128.6 0 00-1-13c-.7-7-1-10.6-1.8-14-1.3-6.4-2.6-9.2-1-11.3.8-.9 2.4-1.5 5.7-2.8a58.5 58.5 0 015.6-1.8 42.9 42.9 0 019.3-1.9z',
  },
  {
    side: 'back',
    id: 'right-triceps',
    d: 'M269.1 194.4c1.2-.3 1.8 1.8 6 5a34.8 34.8 0 009 5c4.4 1.7 7 2.8 10 2s4-2.6 5-2c1.6.8.3 5 0 11-.1 2.9.3 6 1 12 1.1 9 1.9 15.4 4 22a28.5 28.5 0 012 9.7 24.3 24.3 0 000 4.3c.5 3.4 1.7 4.6 1 6-.6 1.1-1.8 1-4 2s-3.2 2.4-5 4a36.3 36.3 0 01-12 7c-3.6 1.2-4 .5-8 2-3.6 1.4-4.7 2.5-6 2-1.9-.7-2-3.6-3-10-1.5-9.3-2-8.1-3-16a103.5 103.5 0 01-1-12c-.2-9.8 1.6-11.4 2-23v-14c0-12.7.4-16.6 2-17z',
  },
  {
    side: 'back',
    id: 'right-buttock',
    d: 'M216.1 366.3c-6.7 3.4-9.2 4.2-12 7-.5.5-3 3-6 6.6l-2 2.4a41.5 41.5 0 00-8 16 44.8 44.8 0 00-1 9c-.2 4.8.1 6.6 0 15v5.2c0 3.8.4 7.4 1 12.8.3 2.7 1 6.9 4 9 2.2 1.5 3.5.5 13 1 14.5.8 14.6 2 19 1 5.7-1.3 9.6-4.2 16-9 5-3.8 7.6-5.6 9-8 2.2-3.6 2.6-7.3 0-28-1.3-10.4-1.5-10.4-2-16-1-10.3-1.4-15.5-1-20a72.6 72.6 0 000-15c-.5-4.2-1-5.5-2-6-2.3-1-5 3-12 8-4.3 3.1-8.2 5-16 9z',
  },
  {
    side: 'back',
    id: 'right-scapula-surface',
    d: 'M206.1 150.3a217.7 217.7 0 0132 6 46.7 46.7 0 0113 8 36.5 36.5 0 018 9 23.7 23.7 0 014 10c.6 5.3-1.6 5.8-2 12-.5 8.5 3.4 11.6 1 16-.8 1.5-1.4 1.5-7 5-10.6 6.8-10.1 8.1-14 9-2.7.7-7.8 2-13 0-1.6-.6-3.2-1.6-5-1-.7.3-1.8.9-4 5a48.9 48.9 0 00-3 7 51.3 51.3 0 01-3 7c-3.5 6.7-6.4 8.9-11 15-6.8 9.1-8.6 15.5-10 15s-.3-7.4 0-10a611.6 611.6 0 005-62c.4-15.8.5-23.8 0-29-1-10.5-3.1-19.2 1-22 .8-.5 1.6-.7 8 0z',
  },
  {
    side: 'back',
    id: 'right-foot-back',
    d: 'M203.1 791.3a10.9 10.9 0 015-2c3.9-.3 6.9 2.1 8 3 3 2.5 6 7 6 12 0 2.9-1 4.4 0 7a10.2 10.2 0 003 4c2.6 2.4 4.5 2 6 4a6.4 6.4 0 011 6c-.3.7-1.3 2.6-8 4-5 1.1-6 .4-11 1-8.2 1-9.9 3.4-14 2a11.7 11.7 0 01-6-5c-1.3-2.3-1.3-4.4-1-9 .7-9.5 1-14.2 3-18a23.7 23.7 0 018-9z',
  },
  {
    side: 'back',
    id: 'right-lumbar',
    d: 'M192.1 299.3c2-1 5.2 1.8 9 5a41.6 41.6 0 018 9 53 53 0 017 16c2.8 10.8.1 13.5 4 19 2 2.8 4.3 4.3 4 7-.2 2-1.6 3.5-5 6-4.7 3.5-8 5-12 7-7.3 4-11 6-14 10-3.6 5.1-3.5 10.1-4 10-1-.1 2.7-20.5 3-46 .2-12.3.2-18.4 0-22-.8-12.2-2.7-19.7 0-21z',
  },
  {
    side: 'back',
    id: 'right-knee-back',
    d: 'M246 579.2c2.4.8-1.6 8-3.7 21.6l-.6 4.9-.3 3c-.6 6.7-1.9 7.8-1.8 12.4.1 3.4.9 4.5 0 6.6a8.7 8.7 0 01-3 3.4 12 12 0 01-7.2 2.5c-1.5 0-1.2-.3-3.8-.5h-10a44.3 44.3 0 00-5 1 31.4 31.4 0 01-6 1c-1.2 0-3.9-.2-5.7-2.2a3.3 3.3 0 01-.7-1c-.6-1.3.2-2.9-.2-6.2-.5-3.2-1.3-3-2.8-8a60.4 60.4 0 01-1.8-9 72 72 0 01-1-8l.7-10.5a28 28 0 011-6c.7-2.4 1-3.7 2-4 1.7-.4 3 3.9 7.4 4.8a9.8 9.8 0 001.4.2c1.9.2 4 0 15.2-.2s12.3 0 14.8-.6c7.3-1.9 9.4-5.8 11.1-5.2z',
  },
];

export const PartsNames = {
  en: {
    // front
    'head-front': 'Head (front)',
    'neck-left': 'Neck (L)',
    'neck-right': 'Neck (R)',
    'left-trapezius-front': 'Left trapezius (front)',
    'right-trapezius-front': 'Right trapezius (front)',

    'left-shoulder-front': 'Left shoulder (front)',
    'right-shoulder-front': 'Right shoulder (front)',
    'left-biceps': 'Left biceps',
    'right-biceps': 'Right biceps',
    'left-elbow-front': 'Left elbow (front)',
    'right-elbow-front': 'Right elbow (front)',
    'left-forearm-front': 'Left forearm (front) (flexors)',
    'right-forearm-front': 'Right forearm (front) (flexors)',
    'left-wrist-front': 'Left wrist (front)',
    'right-wrist-front': 'Right wrist (front)',
    'left-hand-front': 'Left hand (front) (palmar surface)',
    'right-hand-front': 'Right hand (front) (palmar surface)',

    'left-pectoral': 'Left pectoral',
    'right-pectoral': 'Right pectoral',
    'left-rib': 'Left rib',
    'right-rib': 'Right rib',
    'left-adbominals': 'Left adbominals',
    'right-adbominals': 'Right adbominals',
    'left-obliques': 'Left obliques',
    'right-obliques': 'Right obliques',

    'left-hip': 'Left hip',
    'right-hip': 'Right hip',
    'left-quadriceps': 'Left quadriceps',
    'right-quadriceps': 'Right quadriceps',
    'left-adductor': 'Left adductor',
    'right-adductor': 'Right adductor',
    'left-knee-front': 'Left knee (front)',
    'right-knee-front': 'Right knee (front)',
    'left-shin': 'Left shin',
    'right-shin': 'Right shin',
    'left-ankle-front': 'Left ankle (front)',
    'right-ankle-front': 'Right ankle (front)',
    'left-foot-front': 'Left foot (front)',
    'right-foot-front': 'Right foot (front)',

    // back
    'head-back': 'Head (back)',

    'left-trapezius-back': 'Left trapezius (back)',
    'right-trapezius-back': 'Right trapezius (back)',

    'left-shoulder-back': 'Left shoulder (back)',
    'right-shoulder-back': 'Right shoulder (back)',
    'left-triceps': 'Left triceps',
    'right-triceps': 'Right triceps',
    'left-elbow-back': 'Left elbow (back)',
    'right-elbow-back': 'Right elbow (back)',
    'left-forearm-back': 'Left forearm (back) (extensors)',
    'right-forearm-back': 'Right forearm (back) (extensors)',
    'left-wrist-back': 'Left wrist (back)',
    'right-wrist-back': 'Right wrist (back)',
    'left-hand-back': 'Left hand (back) (dorsale surface)',
    'right-hand-back': 'Right hand (back) (dorsale surface)',

    'spinal-column': 'Spinal column',
    'left-scapula-surface': 'Left scapula surface',
    'right-scapula-surface': 'Right scapula surface',
    'back-left': 'Back (Left side)',
    'back-right': 'Back (Right side)',
    'left-lumbar': 'Left lumbar',
    'right-lumbar': 'Right lumbar',

    'left-buttock': 'Left buttock',
    'right-buttock': 'Right buttock',
    'left-hamstring': 'Left hamstring',
    'right-hamstring': 'Right hamstring',
    'left-knee-back': 'Left knee (back)',
    'right-knee-back': 'Right knee (back)',
    'left-calf': 'Left calf',
    'right-calf': 'Right calf',
    'left-ankle-back': 'Left ankle (back)',
    'right-ankle-back': 'Right ankle (back)',
    'left-foot-back': 'Left foot (back)',
    'right-foot-back': 'Right foot (back)',
  },
};
