import React, { useEffect, useState } from 'react';
import { RoundedDialog } from '../../Global/Components';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import { ListItemSecondaryAction, Chip, Avatar, Button, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Search, CheckCircle } from '@mui/icons-material';
import axios from 'axios';

export default function NewGroupChatDialog({ open, onClose }) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const [users, setUsers] = useState([]);
  const [pattern, setPattern] = useState('');
  const [tab, setTab] = useState(0);
  const [title, setTitle] = useState('');

  const userId = localStorage.getItem('_id');

  useEffect(() => {
    axios
      .get('/user/getUsers')
      .then((res) => {
        setUsers(res.data.filter((x) => x._id !== userId));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  function addUser(id) {
    let index = users.findIndex((x) => x._id === id);
    if (index > -1) {
      users[index].selected = true;
      setUsers([...users]);
    }
  }

  function deleteUser(id) {
    let index = users.findIndex((x) => x._id === id);
    if (index > -1) {
      users[index].selected = false;
      setUsers([...users]);
    }
  }

  return (
    <RoundedDialog maxWidth="xs" fullWidth open={open} onClose={() => onClose(null)} fullScreen={!largeDevices}>
      {tab === 0 && (
        <>
          <DialogTitle>Select users</DialogTitle>
          <DialogContent dividers style={{ padding: theme.spacing(2) }}>
            <Grid container style={{ minHeight: '3rem' }}>
              {users
                .filter((user) => user.selected === true)
                .map((user) => (
                  <Chip
                    key={user._id}
                    style={{ background: '#90caf9', color: '#2f3133', margin: '0.4rem' }}
                    avatar={<Avatar src={user.avatar}>{user.avatarLetters}</Avatar>}
                    label={user.fullName}
                    onDelete={() => deleteUser(user._id)}
                  />
                ))}
            </Grid>
            <Grid container style={{ padding: '1rem' }}>
              <TextField
                placeholder="Add people"
                fullWidth
                value={pattern}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search style={{ color: 'lightgray' }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setPattern(event.target.value.toLowerCase())}
                variant="standard"
              />
            </Grid>
            <Grid container>
              <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
                {users
                  .filter((user) => user.fullName.toLowerCase().includes(pattern))
                  .map((user, i) => (
                    <React.Fragment key={user._id}>
                      <ListItem
                        button
                        style={{ borderBottom: '1px solid lightgrey' }}
                        onClick={() => {
                          user.selected ? deleteUser(user._id) : addUser(user._id);
                          setPattern('');
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar src={user.avatar}>{user.avatarLetters}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.fullName} />
                        {user.selected && (
                          <ListItemSecondaryAction>
                            <CheckCircle style={{ color: '#64dd17' }} />
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    </React.Fragment>
                  ))}
              </List>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: '#d81b60' }} onClick={() => onClose(null)}>
              Cancel
            </Button>
            {users.findIndex((x) => x.selected) > -1 && (
              <Button style={{ color: '#1e88e5' }} onClick={() => setTab(1)}>
                Next
              </Button>
            )}
          </DialogActions>
        </>
      )}
      {tab === 1 && (
        <>
          <DialogTitle>New group chat</DialogTitle>
          <DialogContent dividers style={{ padding: theme.spacing(2) }}>
            <Grid container style={{ padding: '1rem' }}>
              <TextField
                placeholder="Group name"
                fullWidth
                autoFocus
                autoComplete="off"
                onChange={(event) => setTitle(event.target.value)}
                variant="standard"
              />
            </Grid>
            <Grid container>
              <Typography style={{ fontWeight: 700, color: '#1e88e5', marginLeft: '1rem' }}>
                {`${users.filter((user) => user.selected === true).length} participants`}
              </Typography>
            </Grid>
            <Grid container>
              <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
                {users
                  .filter((user) => user.selected === true)
                  .map((user) => (
                    <React.Fragment key={user._id}>
                      <ListItem style={{ borderBottom: '1px solid lightgrey' }}>
                        <ListItemAvatar>
                          <Avatar src={user.avatar}>{user.avatarLetters}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.fullName} />
                      </ListItem>
                    </React.Fragment>
                  ))}
              </List>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: '#d81b60' }} onClick={() => setTab(0)}>
              Back
            </Button>
            {title && (
              <Button
                style={{ color: '#1e88e5' }}
                onClick={() =>
                  onClose({ title, users: users.filter((user) => user.selected === true).map((x) => x._id) })
                }
              >
                Done
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </RoundedDialog>
  );
}
