import React, { useState, useEffect, forwardRef } from 'react';
import { Checkbox, Typography, MenuItem, ListItemText, ListItemIcon } from '@mui/material';

const CheckBoxItem = forwardRef(({ text, checked, defaultChecked = false, onChange }, ref) => {
  const [ownChecked, setOwnChecked] = useState(defaultChecked);

  function handleClick() {
    onChange(!ownChecked);
    setOwnChecked(!ownChecked);
  }

  useEffect(() => {
    setOwnChecked(checked);
  }, [checked]);

  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ListItemIcon>
        <Checkbox style={{ padding: 0 }} color="primary" checked={ownChecked} />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={<Typography sx={{ fontWeight: ownChecked ? 600 : 400 }}>{text}</Typography>}
      />
    </MenuItem>
  );
});

export default CheckBoxItem;
