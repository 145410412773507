import orderBy from 'lodash/orderBy';
/**
 * * Reducer for Document Hub, follows the cases as per relevant actions
 */
import {
  DOCLIBRARY_ATTEMPT,
  DOCLIBRARY_ERROR,
  GETMYFILES_ATTEMPT,
  GETMYFILES_SUCCESS,
  GETMYFILES_FAIL,
  SET_UPLOAD_DIALOG,
  GETAREAS_ATTEMPT,
  GETAREAS_FAIL,
  GETAREAS_SUCCESS,
  GETAREA_ATTEMPT,
  GETAREA_FAIL,
  GETAREA_SUCCESS,
  GETMYAREA_ATTEMPT,
  GETMYAREA_FAIL,
  GETMYAREA_SUCCESS,
  UPLOADFILES_ATTEMPT,
  UPLOADFILES_SUCCESS,
  UPLOADFILES_FAIL,
  UPLOADFILE_ATTEMPT,
  UPLOADFILE_SUCCESS,
  UPLOADFILE_FAIL,
  REMOVEFILE,
  REMOVEFILES,
  GETFOLDERS_ATTEMPT,
  GETFOLDERS_SUCCESS,
  GETFOLDERS_FAIL,
  GETFOLDER_ATTEMPT,
  GETFOLDER_SUCCESS,
  GETFOLDER_FAIL,
  SETCURRENTAREA,
  SETCURRENTFOLDER,
  SETISFOLDER,
  ADDFOLDER_ATTEMPT,
  ADDFOLDER_SUCCESS,
  ADDFOLDER_FAIL,
  ADDDOCUMENTS_ATTEMPT,
  ADDDOCUMENTS_SUCCESS,
  ADDDOCUMENTS_FAIL,
  ADDDOCUMENT_ATTEMPT,
  ADDDOCUMENT_SUCCESS,
  ADDDOCUMENT_FAIL,
  UPDATEDOCUMENT_ATTEMPT,
  UPDATEDOCUMENT_SUCCESS,
  UPDATEDOCUMENT_FAIL,
  DELETEDOCUMENT_ATTEMPT,
  DELETEDOCUMENT_SUCCESS,
  DELETEDOCUMENT_FAIL,
  SETCURRENTPATH,
  GOBACKPATH,
  SETCONTROLLEDINDEX,
  ADDAREA_ATTEMPT,
  ADDAREA_SUCCESS,
  ADDAREA_FAIL,
  UPDATEAREA_ATTEMPT,
  UPDATEAREA_SUCCESS,
  UPDATEAREA_FAIL,
  DELETEAREA_ATTEMPT,
  DELETEAREA_SUCCESS,
  DELETEAREA_FAIL,
  UPDATEFOLDER_ATTEMPT,
  UPDATEFOLDER_SUCCESS,
  UPDATEFOLDER_FAIL,
  DELETEFOLDER_ATTEMPT,
  DELETEFOLDER_SUCCESS,
  DELETEFOLDER_FAIL,
  ADDAREASETTINGS_ATTEMPT,
  ADDAREASETTINGS_SUCCESS,
  ADDAREASETTINGS_FAIL,
  GETAREASETTINGS_ATTEMPT,
  GETAREASETTINGS_SUCCESS,
  GETAREASETTINGSBYUSER_SUCCESS,
  GETAREASETTINGS_FAIL,
  UPDATEAREASETTINGS_ATTEMPT,
  UPDATEAREASETTINGS_SUCCESS,
  UPDATEAREASETTINGS_FAIL,
  DELETEAREASETTINGS_ATTEMPT,
  DELETEAREASETTINGS_SUCCESS,
  DELETEAREASETTINGS_FAIL,
  REQUESTAREA_ATTEMPT,
  REQUESTAREA_SUCCESS,
  REQUESTAREA_FAIL,
  GETREVISIONFILES_ATTEMPT,
  GETREVISIONFILES_SUCCESS,
  GETREVISIONFILES_FAIL,
  GETLINKEDFILES_ATTEMPT,
  GETLINKEDFILES_SUCCESS,
  GETLINKEDFILES_FAIL,
  DELETELINKEDFILE_ATTEMPT,
  DELETELINKEDFILE_SUCCESS,
  GETALLDOCS_ATTEMPT,
  GETALLDOCS_SUCCESS,
  GETALLDOCS_FAIL,
  SETSELECTEDDOC,
  ADDLINK_ATTEMPT,
  ADDLINK_SUCCESS,
  ADDLINK_FAIL,
  UPDATELINKEDFILES_ATTEMPT,
  UPDATELINKEDFILES_SUCCESS,
  UPDATELINKEDFILES_FAIL,
  SEARCH_ATTEMPT,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  QSEARCH_ATTEMPT,
  QSEARCH_SUCCESS,
  QSEARCH_FAIL,
  CLEARQRESULTS,
  SETREQUESTALERT,
  SETSETUPDIALOG,
  GETDOCUMENT_ATTEMPT,
  GETDOCUMENT_SUCCESS,
  GETDOCUMENT_FAIL,
  SETDOCALERT,
  FETCHBOOKMARKS_ATTEMPT,
  FETCHBOOKMARKS_SUCCESS,
  FETCHBOOKMARKS_FAIL,
  BOOKMARKDOC_ATTEMPT,
  BOOKMARKDOC_SUCCESS,
  BOOKMARKDOC_FAIL,
  UNBOOKMARKDOC_ATTEMPT,
  UNBOOKMARKDOC_SUCCESS,
  UNBOOKMARKDOC_FAIL,
  BOOKMARKFOLDER_ATTEMPT,
  BOOKMARKFOLDER_SUCCESS,
  BOOKMARKFOLDER_FAIL,
  UNBOOKMARKFOLDER_ATTEMPT,
  UNBOOKMARKFOLDER_SUCCESS,
  UNBOOKMARKFOLDER_FAIL,
  GETBOOKMARKEDFILES_ATTEMPT,
  GETBOOKMARKEDFILES_SUCCESS,
  GETBOOKMARKEDFILES_FAIL,
  GETBOOKMARKEDFOLDERS_ATTEMPT,
  GETBOOKMARKEDFOLDERS_SUCCESS,
  GETBOOKMARKEDFOLDERS_FAIL,
  FETCHPENDINGUPISSUE_ATTEMPT,
  FETCHPENDINGUPISSUE_SUCCESS,
  FETCHPENDINGUPISSUE_FAIL,
  FINISHPENDINGUPISSUE_ATTEMPT,
  FINISHPENDINGUPISSUE_SUCCESS,
  FINISHPENDINGUPISSUE_FAIL,
  SETSEARCHDIALOG,
  SETCONTROLDIALOG,
  HIGHLIGHTDOC,
  CANCELUPISSUE_ATTEMPT,
  CANCELUPISSUE_SUCCESS,
  CANCELUPISSUE_FAIL,
  DOCVIEWED_SUCCESS,
  GETFOLDERSETTINGS_SUCCESS,
  GETFOLDERSETTING_SUCCESS,
  GETFOLDERSETTINGSBYUSER_SUCCESS,
  ADDFOLDERSETTINGS_SUCCESS,
  UPDATEFOLDERSETTINGS_SUCCESS,
  DELETEFOLDERSETTINGS_SUCCESS,
  REQUESTFOLDER_SUCCESS,
  SHOWALERT,
  SETGLOBALDOCALERT,
  BULKDOCDELETE,
  BULKFOLDERDELETE,
  SETDOCDRAWER,
  FETCHDOCCOMMENTS,
  ADDDOCCOMMENT,
  DELETEDOCCOMMENT,
  UPDATEDOCCOMMENT,
  ADD_ISSUE,
  GETMINIREVISIONS,
  GETREVISION,
  FETCHREVCOMMENTS,
  FETCHREVCOMMENTS_ATTEMPT,
  ADDREVCOMMENT,
  DELETEREVCOMMENT,
  UPDATEREVCOMMENT,
  VALIDATESEQ,
  VALIDATESEQLOADING,
  GENERATEPDF_LOADING,
  GENERATEPDF_FINISHED,
  GETAREASETTING_ATTEMPT,
  GETFOLDERSETTING_ATTEMPT,
  GETALLAREASETTINGSBYUSER_SUCCESS,
  GETMINIREVISIONS_LOADER,
  GETMINIREVISIONS_ERROR,
  FETCHDOCCOMMENTS_ATTEMPT,
  FETCHDOCCOMMENTS_ERROR,
  CLEARMYFILES,
  COPYTEMPLATES_ATTEMPT,
  COPYTEMPLATES_SUCCESS,
  COPYTEMPLATES_ERROR,
  DELETEREVCOMMENTS,
  SETREFETCHTABLE,
  SETREFETCHTABLETYPE,
} from '../constants';
import { BASE_URL } from '../../../../../constants';

const standardPaginationOptions = {
  page: 1,
  totalPages: 1,
  hasNextPage: false,
  nextPage: null,
  hasPrevPage: false,
  prevPage: false,
  pagingCounter: 1,
};

const initialReview = {
  outcome: '',
  reason: '',
  isDocumentRead: false,
  completed: false,
  status: 'Assigned',
};

const initialState = {
  refetchTable: null,
  refetchTableType: '',
  bookmarkLoading: false,
  newDocID: null,
  docAlert: false,
  docAlertType: '',
  docMessage: '',
  globalAlert: false,
  globalAlertType: '',
  globalMessage: '',
  docLink: '',
  data: null,
  bookmarkedFiles: null,
  fetching: false,
  error: null,
  openDialog: false,
  uploadedFiles: [],
  controlledUpload: [],
  fileLoading: false,
  myArea: null,
  uploadArea: null,
  currentArea: null,
  currentFolder: null,
  currentPath: null,
  fullPath: null,
  folders: [],
  bookmarkedFolders: null,
  uploadFolder: null,
  isFolder: false,
  areas: [],
  areasLoading: false,
  areaSettings: null,
  areaSetting: null,
  userAreaSettings: null,
  request: null,
  showAlert: false,
  controlLoading: false,
  revisionFiles: null,
  miniRevisions: null,
  linkedFiles: null,
  uploadIssue: null,
  documents: [],
  setupDialog: false,
  selected: null,
  results: null,
  qresults: null,
  searchLoading: false,
  controlDialog: false,
  bookmarkedDocuments: [],
  bookmarkedGFolders: [],
  docAccess: false,
  upissue: null,
  searchDialog: false,
  controlTabIndex: 0,
  globalSearch: true,
  highlightedDocument: null,
  docStats: null,
  docFiles: {},
  views: null,
  folderSettings: null,
  folderSetting: null,
  userFolderSettings: null,
  folderRequest: null,
  docDrawer: false,
  comments: null,
  revision: null,
  revComments: null,
  revFetching: false,
  validateSeq: null,
  sort: { property: 'name', order: 'asc' },
  pdfLoading: false,
  sequenceLoading: false,
  settingLoading: false,
  folderLoading: false,
  updatingDocument: false,
  savingFolder: false,
  commentLoading: false,
  revisionLoading: false,
  areasActionLoading: false,
  bookmarkedLoading: false,
  foldersLoading: false,
  documentLoading: false,
  copyingTemplates: false,
  portalFolders: [],
  portalAreas: [],
  approvedAreas: [],
  adding: false,
  documentErrorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DOCLIBRARY_ATTEMPT:
      return { ...state, fetching: true };
    case DOCLIBRARY_ERROR:
      return { ...state, fetching: false, error: action.payload, settingLoading: false, folderLoading: false };
    case GENERATEPDF_LOADING:
      return { ...state, pdfLoading: true };
    case GENERATEPDF_FINISHED:
      return { ...state, pdfLoading: false };
    case DOCVIEWED_SUCCESS:
      return { ...state, fetching: false, views: action.payload.views };
    case FETCHDOCCOMMENTS_ATTEMPT:
      return { ...state, commentLoading: true };
    case FETCHDOCCOMMENTS:
      return { ...state, comments: action.payload.comments, commentLoading: false };
    case FETCHDOCCOMMENTS_ERROR:
      return { ...state, commentLoading: false, error: action.payload };
    case ADDDOCCOMMENT:
      return { ...state, comments: [...state.comments, action.payload.comment], fetching: false };
    case UPDATEDOCCOMMENT:
      let savedComments = state.comments.map((c) => {
        if (c._id === action.payload.id) {
          return action.payload.comment;
        } else {
          return c;
        }
      });
      return { ...state, comments: savedComments, fetching: false };
    case DELETEDOCCOMMENT:
      return { ...state, comments: state.comments.filter((x) => x._id !== action.payload.id), fetching: false };
    case SETSETUPDIALOG:
      return { ...state, setupDialog: action.payload };
    case SETCONTROLDIALOG:
      return { ...state, controlDialog: action.payload.status, controlTabIndex: action.payload.tab };
    case HIGHLIGHTDOC:
      return { ...state, highlightedDocument: action.payload };
    case GETDOCUMENT_ATTEMPT:
      return { ...state, documentLoading: true, selected: null, controlDialog: false, documentErrorMessage: '' };
    case GETDOCUMENT_SUCCESS:
      let doc = action.payload.document;
      return {
        ...state,
        documentLoading: false,
        selected: action.payload.status === 'Ok' && doc,
        controlDialog:
          Boolean(action.payload.status) &&
          action.payload.access &&
          action.payload.controlDialog &&
          (Boolean(state.review?._id) ? state.review.completed : true),
        docAccess: action.payload.access,
        controlTabIndex: action.payload.tabIndex,
        docDrawer: action.payload.access ? state.docDrawer : false,
        documentErrorMessage: action.payload.status === 'Ok' ? '' : action.payload.message,
      };
    case GETDOCUMENT_FAIL:
      return {
        ...state,
        documentLoading: false,
        selected: null,
        error: action.payload,
        controlDialog: false,
        documentErrorMessage: 'Document not found',
      };
    case GETMYFILES_ATTEMPT:
      return { ...state, fetching: true, data: [] };
    case GETMYFILES_SUCCESS:
      let myFilesResults = action.payload;
      let returnedState = {
        ...state,
        fetching: false,
        sort: action.payload?.sort,
        data: myFilesResults,
      };
      return returnedState;
    case GETMYFILES_FAIL:
      return { ...state, fetching: false, data: null, error: action.payload, selected: null };
    case CLEARMYFILES:
      return { ...state, data: null, selected: null, error: null };
    case GETBOOKMARKEDFILES_ATTEMPT:
      return { ...state, fetching: true, data: null, bookmarkedFiles: null, selected: null };
    case GETBOOKMARKEDFILES_SUCCESS:
      return {
        ...state,
        fetching: false,
        bookmarkedFiles: action.payload,
      };
    case GETBOOKMARKEDFILES_FAIL:
      return { ...state, fetching: false, data: null, error: action.payload };
    case SET_UPLOAD_DIALOG:
      return { ...state, openDialog: action.payload };
    case SETSEARCHDIALOG:
      return { ...state, searchDialog: action.payload.search, globalSearch: action.payload.global };
    case UPLOADFILES_ATTEMPT:
      return { ...state, fileLoading: true };
    case UPLOADFILES_SUCCESS:
      return {
        ...state,
        fileLoading: false,
        uploadedFiles: state.uploadedFiles.concat(action.payload.files),
        controlledUpload: state.controlledUpload.concat(
          action.payload.files.map((f, i) => {
            return { controlled: false, index: i };
          }),
        ),
      };
    case UPLOADFILES_FAIL:
      return { ...state, fileLoading: false, error: action.payload };
    case REMOVEFILE:
      return {
        ...state,
        uploadedFiles: state.uploadedFiles.filter((f, i) => i !== action.payload),
        controlledUpload: state.controlledUpload.filter((f) => f.index !== action.payload),
      };
    case REMOVEFILES:
      return { ...state, uploadedFiles: [], controlledUpload: [] };
    case GETMYAREA_ATTEMPT:
      return {
        ...state,
        fetching: true,
        currentArea: null,
        folders: [],
        uploadFolders: [],
        data: null,
      };
    case GETMYAREA_SUCCESS:
      return {
        ...state,
        myArea: action.payload.area,
        currentArea: action.payload.area,
        fetching: false,
        isFolder: false,
        currentPath: action.payload.area ? [action.payload.area.name] : [],
      };
    case GETMYAREA_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case GETFOLDERS_ATTEMPT:
      return { ...state, foldersLoading: true, folders: [], uploadFolders: [] };
    case GETFOLDERS_SUCCESS:
      let portalAreas = state.portalAreas;
      let portalFolders = state.portalFolders;
      if (action.payload?.portalAreas) {
        portalAreas = action.payload?.portalAreas;
      }
      if (action.payload?.portalFolders) {
        portalFolders = action.payload?.portalFolders;
      }
      return { ...state, foldersLoading: false, folders: action.payload.folders, portalFolders, portalAreas };
    case GETFOLDERS_FAIL:
      return { ...state, foldersLoading: false, error: action.payload };
    case GETBOOKMARKEDFOLDERS_ATTEMPT:
      return { ...state, fetching: true, bookmarkedFolders: null };
    case GETBOOKMARKEDFOLDERS_SUCCESS:
      return { ...state, fetching: false, bookmarkedFolders: action.payload.folders };
    case GETBOOKMARKEDFOLDERS_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case GETFOLDER_ATTEMPT:
      return { ...state, fetching: true, currentPath: [], currentFolder: null, uploadFolders: [] };
    case GETFOLDER_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentFolder: action.payload.folder,
        currentPath: action.payload.folder ? action.payload.folder.path : state.currentPath,
        fullPath: action.payload.folder ? action.payload.folder.fullPath : state.fullPath,
        currentArea: action.payload.area,
        folderSetting: action.payload.setting || null,
      };
    case GETFOLDER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SETCURRENTAREA:
      return { ...state, currentArea: action.payload, areas: action.payload === 'null' ? null : state.areas };
    case SETCURRENTFOLDER:
      return { ...state, currentFolder: action.payload === 'root' ? null : action.payload };
    case SETISFOLDER:
      return { ...state, isFolder: action.payload };
    case SETCURRENTPATH:
      return { ...state, currentPath: state.currentPath.concat([action.payload]) };
    case GOBACKPATH:
      state.currentPath.pop();
      return { ...state, currentPath: state.currentPath };
    case SETSELECTEDDOC:
      const newDocUpdate = action.payload.document;
      const fullUpdate = action.payload.full;
      if (fullUpdate) {
        return { ...state, selected: newDocUpdate, validateSeq: null };
      } else {
        return { ...state, selected: { ...state.selected, ...newDocUpdate }, validateSeq: null };
      }
    case VALIDATESEQLOADING:
      return {
        ...state,
        validateSeq: null,
        sequenceLoading: true,
      };
    case VALIDATESEQ:
      return {
        ...state,
        validateSeq: action.payload,
        sequenceLoading: false,
        // selected: { ...state.selected, sequence: action.payload.sequence },
      };
    case SETCONTROLLEDINDEX:
      let controlledItem = state.controlledUpload.find((u) => u.index === action.payload.index);
      if (controlledItem) {
        controlledItem.controlled = action.payload.controlled;
        return {
          ...state,
        };
      } else {
        return { ...state, controlledUpload: state.controlledUpload.concat([action.payload]) };
      }
    case ADDFOLDER_ATTEMPT:
      return { ...state, fetching: true };
    case ADDFOLDER_SUCCESS:
      return { ...state, fetching: false, folders: state.folders.concat([action.payload.folder]) };
    case ADDFOLDER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case UPDATEFOLDER_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATEFOLDER_SUCCESS:
      let updateFolders = state.folders
        ? state.folders.map((d) => {
            if (d._id === action.payload.folder._id) {
              return action.payload.folder;
            }
            return d;
          })
        : [action.payload.folder];
      let updateCurrentFolder = state.currentFolder;
      if (updateCurrentFolder && updateCurrentFolder._id === action.payload.folder._id) {
        updateCurrentFolder = action.payload.folder;
      }
      return { ...state, fetching: false, folders: updateFolders, currentFolder: updateCurrentFolder };
    case UPDATEFOLDER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case DELETEFOLDER_ATTEMPT:
      return { ...state, fetching: true };
    case DELETEFOLDER_SUCCESS:
      return {
        ...state,
        fetching: false,
        folders: action.payload.folder ? state.folders.filter((f) => f._id !== action.payload.folder) : state.folders,
      };
    case DELETEFOLDER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case ADDDOCUMENTS_ATTEMPT:
      return { ...state, adding: true };
    case ADDDOCUMENTS_SUCCESS:
      const standardOptions = {
        uploadedFiles: null,
        newDocID: action.payload.status === 'Ok' ? action.payload.Documents[0]._id : null,
        docLink:
          action.payload.Documents[0].category === 'qhub'
            ? `/DocLibrary/builder/${action.payload.Documents[0]._id}`
            : '/DocLibrary/controlled',
      };
      if (state.data) {
        return {
          ...state,
          ...standardOptions,
          adding: false,
          data: {
            ...state.data,
            totalDocs: state.data.totalDocs + action.payload?.Documents?.length,
            limit: state.data.totalDocs + action.payload?.Documents?.length,
            docs: orderBy(
              state.data.docs
                ? state.data.docs.concat(action.payload.Documents).filter((d) => !d.oldVersion)
                : action.payload.Documents.filter((d) => !d.oldVersion),
              'lastModified',
              'desc',
            ),
          },
        };
      } else {
        return {
          ...state,
          ...standardOptions,
          adding: false,
          data: {
            ...standardPaginationOptions,
            docs: orderBy(
              action.payload.Documents.filter((d) => !d.oldVersion),
              'lastModified',
              'desc',
            ),
            totalDocs: action.payload?.Documents?.length,
            limit: action.payload?.Documents?.length,
          },
        };
      }
    case ADDDOCUMENTS_FAIL:
      return {
        ...state,
        adding: false,
        error: action.payload,
        newDocID: null,
        docAlert: true,
        docAlertType: 'error',
        docMessage: 'Document Saving Failed',
      };
    case ADDDOCUMENT_ATTEMPT:
      return { ...state, fetching: true };
    case ADDDOCUMENT_SUCCESS:
      if (action.payload.Documents.upissuing) {
        return {
          ...state,
          fetching: false,
          data: {
            ...state.data,
            docs: state.data.docs.concat(action.payload.Documents).filter((d) => !d.oldVersion && !d.upissuing),
          },
          uploadedFiles: null,
          revisionFiles: [...state.revisionFiles, action.payload.Documents],
        };
      } else {
        return {
          ...state,
          fetching: false,
          data: {
            ...state.data,
            docs: state.data.docs.concat(action.payload.Documents).filter((d) => !d.oldVersion && !d.upissuing),
          },
          uploadedFiles: null,
          selected: action.payload.Documents,
          linkedFiles: action.payload.linkedFiles,
        };
      }
    case ADDDOCUMENT_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case ADD_ISSUE:
      return {
        ...state,
        fetching: false,
        revisionFiles:
          state.revisionFiles && state.revisionFiles.length > 0
            ? [...state.revisionFiles, action.payload.revision]
            : [action.payload.revision],
      };
    case FETCHPENDINGUPISSUE_ATTEMPT:
      return { ...state, fetching: true, upissue: null };
    case FETCHPENDINGUPISSUE_SUCCESS:
      return {
        ...state,
        fetching: false,
        upissue: action.payload.status === 'Ok' && action.payload.document,
        docAccess: action.payload.access,
      };
    case FETCHPENDINGUPISSUE_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case FINISHPENDINGUPISSUE_ATTEMPT:
      return { ...state, fetching: true };
    case FINISHPENDINGUPISSUE_SUCCESS:
      if (action.payload.status === 'Ok') {
        if (action.payload.revision.upissuing) {
          return { ...state, fetching: false };
        } else {
          if (state.data && state.data.docs.length > 0) {
            let updatedDoc = state.data.docs.map((d) => {
              if (d._id === action.payload.Documents._id) {
                return action.payload.Documents;
              }
              return d;
            });
            let updatedRevisions = state.revisionFiles.map((i) => {
              if (i._id === action.payload.revision._id) {
                return action.payload.revision;
              }
              return i;
            });
            return {
              ...state,
              fetching: false,
              data: {
                ...state.data,
                docs: updatedDoc,
              },
              uploadedFiles: null,
              revisionFiles: updatedRevisions,
            };
          } else {
            return {
              ...state,
              fetching: false,
              data: { ...state.data, docs: [action.payload.Documents].filter((d) => !d.oldVersion && !d.upissuing) },
              uploadedFiles: null,
            };
          }
        }
      } else {
        return { ...state, fetching: false };
      }
    case FINISHPENDINGUPISSUE_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case CANCELUPISSUE_ATTEMPT:
      return { ...state, fetching: true };
    case CANCELUPISSUE_SUCCESS:
      return {
        ...state,
        fetching: false,
        // revisionFiles: state.revisionFiles.filter((x) => x._id !== action.payload.id),
      };
    case CANCELUPISSUE_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case UPDATEDOCUMENT_ATTEMPT:
      return { ...state, updatingDocument: true };
    case UPDATEDOCUMENT_SUCCESS:
      let newData = state.data
        ? state.data.docs.map((d) => {
            if (d._id === action.payload.Document._id) {
              return action.payload.Document;
            }
            return d;
          })
        : [action.payload.Document];
      return {
        ...state,
        updatingDocument: false,
        data: { ...state.data, docs: newData.filter((d) => !d.oldVersion) },
        selected: action.payload.Document.upissuing
          ? state.selected
          : action.payload.Document.archived
          ? null
          : action.payload.Document,
      };
    case UPDATEDOCUMENT_FAIL:
      return { ...state, updatingDocument: false, error: action.payload };
    case DELETEDOCUMENT_ATTEMPT:
      return { ...state, fetching: true };
    case DELETEDOCUMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: { ...state?.data, docs: state?.data?.docs.filter((f) => f._id !== action?.payload?.Document) },
        selected: null,
      };
    case DELETEDOCUMENT_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case BULKDOCDELETE:
      return {
        ...state,
        fetching: false,
        data: { ...state.data, docs: state.data.docs.filter((f) => !action.payload.ids.includes(f._id)) },
      };
    case BULKFOLDERDELETE:
      return {
        ...state,
        fetching: false,
        folders: state.folders.filter((f) => !action.payload.ids.includes(f._id)),
      };
    case GETAREAS_ATTEMPT:
      return { ...state, areasLoading: true };
    case GETAREAS_SUCCESS:
      return {
        ...state,
        areasLoading: false,
        areas: action.payload.areas,
        approvedAreas: action.payload?.approvedAreas || [],
      };
    case GETAREAS_FAIL:
      return { ...state, areasLoading: false, error: action.payload };
    case GETAREA_ATTEMPT:
      return {
        ...state,
        fetching: true,
        area: null,
        currentArea: null,
        data: null,
        // folders: [],
        uploadFolders: [],
        currentArea: null,
      };
    case GETAREA_SUCCESS:
      return {
        ...state,
        fetching: false,
        area: action.payload.area,
        currentArea: action.payload.area,
        currentPath: [action.payload.area.name],
        areaSetting: action.payload.setting || null,
      };
    case GETAREA_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case ADDAREA_ATTEMPT:
      return { ...state, areasActionLoading: true };
    case ADDAREA_SUCCESS:
      return {
        ...state,
        areasActionLoading: false,
        areas: [...state.areas, action.payload.area],
        userAreaSettings: state.userAreaSettings
          ? state.userAreaSettings.concat(action.payload.settings)
          : action.payload.settings,
      };
    case ADDAREA_FAIL:
      return { ...state, areasActionLoading: false, error: action.payload };
    case UPDATEAREA_ATTEMPT:
      return { ...state, areasActionLoading: true };
    case UPDATEAREA_SUCCESS:
      let newArea =
        state.areas !== null && state.areas.length > 0
          ? state.areas.map((d) => {
              if (d._id === action.payload.area._id) {
                return action.payload.area;
              }
              return d;
            })
          : state.areas;
      return {
        ...state,
        areasActionLoading: false,
        areas: newArea,
        currentArea: action.payload.area,
      };
    case UPDATEAREA_FAIL:
      return { ...state, areasActionLoading: false, error: action.payload };
    case DELETEAREA_ATTEMPT:
      return { ...state, areasActionLoading: true };
    case DELETEAREA_SUCCESS:
      return {
        ...state,
        areasActionLoading: false,
        areas: action.payload.area ? state.areas.filter((area) => area._id !== action.payload.area) : state.areas,
      };
    case DELETEAREA_FAIL:
      return { ...state, areasActionLoading: false, error: action.payload };
    case GETAREASETTINGS_ATTEMPT:
      return { ...state, areasLoading: true };
    case GETAREASETTINGS_SUCCESS:
      return { ...state, areasLoading: false, areaSettings: action.payload.settings };
    case GETAREASETTING_ATTEMPT:
      return {
        ...state,
        settingLoading: true,
        areaSetting: null,
        currentArea: null,
        area: null,
        data: null,
        folders: null,
        uploadFolders: null,
      };
    case GETAREASETTINGS_FAIL:
      return { ...state, areasLoading: false, error: action.payload };
    case GETAREASETTINGSBYUSER_SUCCESS:
      return { ...state, settingLoading: false, areaSetting: action.payload.settings };
    case GETALLAREASETTINGSBYUSER_SUCCESS:
      return { ...state, areasLoading: false, userAreaSettings: action.payload.settings };
    case ADDAREASETTINGS_ATTEMPT:
      return { ...state, areasLoading: true };
    case ADDAREASETTINGS_SUCCESS:
      return { ...state, areasLoading: false, areaSettings: state.areaSettings.concat(action.payload.settings) };
    case ADDAREASETTINGS_FAIL:
      return { ...state, areasLoading: false, error: action.payload };
    case UPDATEAREASETTINGS_ATTEMPT:
      return { ...state, areasLoading: true };
    case UPDATEAREASETTINGS_SUCCESS:
      let newSetting = state.areaSettings.map((d) => {
        if (d._id === action.payload.settings._id) {
          return action.payload.settings;
        }
        return d;
      });
      return {
        ...state,
        areasLoading: false,
        areaSettings: newSetting.filter((a) => a.approved),
      };
    case UPDATEAREASETTINGS_FAIL:
      return { ...state, areasLoading: false, error: action.payload };
    case DELETEAREASETTINGS_ATTEMPT:
      return { ...state, areasLoading: true };
    case DELETEAREASETTINGS_SUCCESS:
      return {
        ...state,
        areasLoading: false,
        areaSettings: state.areaSettings.filter((a) => a._id !== action.payload.id),
      };
    case DELETEAREASETTINGS_FAIL:
      return { ...state, areasLoading: false, error: action.payload };
    case REQUESTAREA_ATTEMPT:
      return { ...state, areasLoading: true, request: null, showAlert: false };
    case REQUESTAREA_SUCCESS:
      return { ...state, areasLoading: false, request: action.payload, showAlert: true };
    case REQUESTAREA_FAIL:
      return { ...state, areasLoading: false, error: action.payload, showAlert: false };
    case SETREQUESTALERT:
      return { ...state, areasLoading: false, showAlert: false, request: null };

    case GETREVISIONFILES_ATTEMPT:
      return { ...state, revisionLoading: true };
    case GETREVISIONFILES_SUCCESS:
      return { ...state, revisionLoading: false, revisionFiles: action.payload };
    case GETREVISIONFILES_FAIL:
      return { ...state, revisionLoading: false, error: action.payload };

    case GETMINIREVISIONS_LOADER:
      return { ...state, revisionLoading: false };
    case GETMINIREVISIONS:
      return { ...state, revisionLoading: true, miniRevisions: action.payload };
    case GETMINIREVISIONS_ERROR:
      return { ...state, revisionLoading: false, miniRevisions: [] };

    case GETREVISION:
      return { ...state, revision: action.payload.issue, fetching: false };

    case UPLOADFILE_ATTEMPT:
      return { ...state, fileLoading: true };
    case UPLOADFILE_SUCCESS:
      return {
        ...state,
        fileLoading: false,
        uploadIssue: action.payload.file,
      };
    case UPLOADFILE_FAIL:
      return { ...state, fileLoading: false, error: action.payload };
    case GETALLDOCS_ATTEMPT:
      return { ...state, controlLoading: true };
    case GETALLDOCS_SUCCESS:
      return { ...state, controlLoading: false, documents: action.payload.Documents };
    case GETALLDOCS_FAIL:
      return { ...state, controlLoading: false, error: action.payload };
    case DELETELINKEDFILE_ATTEMPT:
      return { ...state, controlLoading: true };
    case DELETELINKEDFILE_SUCCESS:
      return {
        ...state,
        controlLoading: false,
        linkedFiles:
          action.payload.status === 200
            ? state.linkedFiles.filter((a) => a._id !== action.payload.id)
            : state.linkedFiles,
      };
    case GETLINKEDFILES_ATTEMPT:
      return { ...state, controlLoading: true };
    case GETLINKEDFILES_SUCCESS:
      return { ...state, controlLoading: false, linkedFiles: action.payload.linkedFiles };
    case GETLINKEDFILES_FAIL:
      return { ...state, controlLoading: false, error: action.payload };
    case ADDLINK_ATTEMPT:
      return { ...state, controlLoading: true };
    case ADDLINK_SUCCESS:
      return { ...state, controlLoading: false, linkedFiles: state.linkedFiles.concat(action.payload.updatedLink) };
    case ADDLINK_FAIL:
      return { ...state, controlLoading: false, error: action.payload };
    case UPDATELINKEDFILES_ATTEMPT:
      return { ...state, controlLoading: true };
    case UPDATELINKEDFILES_SUCCESS:
      let newLinkedData = state.linkedFiles.map((d) => {
        if (d._id === action.payload.id) {
          return (d = {
            ...d,
            viewedChanges: action.payload.status === 'Ok' ? true : false,
            ...action.payload.updatedLink,
          });
        }
        return d;
      });
      return {
        ...state,
        fetching: false,
        controlLoading: false,
        linkedFiles: newLinkedData,
      };
    case UPDATELINKEDFILES_FAIL:
      return { ...state, controlLoading: false, error: action.payload };
    case SEARCH_ATTEMPT:
      return { ...state, searchLoading: true };
    case SEARCH_SUCCESS:
      const results = action.payload;
      const searchResults = [
        ...results.areas?.map((a) => ({
          ...a,
          title: a.name,
          dataType: 'area',
        })),
        ...results.folders?.map((f) => ({
          ...f,
          title: f.name,
          dataType: 'folder',
        })),
        ...results.documents
          ?.filter((d) => ['title', 'prefix', 'convention'].includes(d.documentType))
          ?.map((d) => ({
            ...d,
            dataType: 'document',
          })),
        ...results.taggedDocuments?.map((t) => ({
          ...t,
          dataType: 'taggedDocument',
        })),
        ...results.documents
          ?.filter((d) => d.documentType === 'content')
          ?.map((d) => ({
            ...d,
            dataType: 'document',
          })),
        ...results.documents
          ?.filter((d) => d.documentType === 'table')
          ?.map((d) => ({
            ...d,
            dataType: 'document',
          })),
      ];
      return { ...state, searchLoading: false, results: action.payload, searchResults };
    case SEARCH_FAIL:
      return { ...state, searchLoading: false, error: action.payload };
    case QSEARCH_ATTEMPT:
      return { ...state, searchLoading: true };
    case QSEARCH_SUCCESS:
      return { ...state, searchLoading: false, qresults: action.payload };
    case QSEARCH_FAIL:
      return { ...state, searchLoading: false, error: action.payload };
    case CLEARQRESULTS:
      return { ...state, qresults: null };

    case SETDOCALERT:
      return { ...state, docAlert: action.payload };
    case SHOWALERT:
      return {
        ...state,
        globalAlert: true,
        globalMessage: action.payload.message,
        globalAlertType: action.payload.type,
        fetching: false,
      };
    case SETGLOBALDOCALERT:
      return { ...state, globalAlert: action.payload.state, fetching: false };
    case FETCHBOOKMARKS_ATTEMPT:
      return { ...state, bookmarkedLoading: true };
    case FETCHBOOKMARKS_SUCCESS:
      if (action.payload.bookmarks) {
        return {
          ...state,
          bookmarkedLoading: false,
          bookmarkedDocuments: action.payload.bookmarks.documents,
          bookmarkedGFolders: action.payload.bookmarks.folders,
        };
      } else {
        return { ...state, bookmarkedLoading: false };
      }
    case FETCHBOOKMARKS_FAIL:
      return { ...state, bookmarkedLoading: false, error: action.payload };
    case BOOKMARKDOC_ATTEMPT:
      return { ...state, bookmarkLoading: true };
    case BOOKMARKDOC_SUCCESS:
      return { ...state, bookmarkLoading: false, bookmarkedDocuments: action.payload.documents };
    case BOOKMARKDOC_FAIL:
      return { ...state, bookmarkLoading: false, error: action.payload };
    case UNBOOKMARKDOC_ATTEMPT:
      return { ...state, bookmarkLoading: true };
    case UNBOOKMARKDOC_SUCCESS:
      return {
        ...state,
        bookmarkLoading: false,
        bookmarkedDocuments: action.payload.documents,
        bookmarkedFiles:
          state.bookmarkedFiles && state.bookmarkedFiles.docs
            ? {
                ...state.bookmarkedFiles,
                docs: state.bookmarkedFiles.docs.filter((x) => x._id !== action.payload.id),
              }
            : state.bookmarkedFiles,
      };
    case UNBOOKMARKDOC_FAIL:
      return { ...state, bookmarkLoading: false, error: action.payload };
    case BOOKMARKFOLDER_ATTEMPT:
      return { ...state, savingFolder: true };
    case BOOKMARKFOLDER_SUCCESS:
      return { ...state, savingFolder: false, bookmarkedGFolders: action.payload.folders };
    case BOOKMARKFOLDER_FAIL:
      return { ...state, savingFolder: false, error: action.payload };
    case UNBOOKMARKFOLDER_ATTEMPT:
      return { ...state, savingFolder: true };
    case UNBOOKMARKFOLDER_SUCCESS:
      return {
        ...state,
        savingFolder: false,
        bookmarkedGFolders: action.payload.folders,
        bookmarkedFolders:
          state.bookmarkedFolders && state.bookmarkedFolders.filter((x) => x._id !== action.payload.id),
      };
    case UNBOOKMARKFOLDER_FAIL:
      return { ...state, savingFolder: false, error: action.payload };
    case GETFOLDERSETTINGS_SUCCESS:
      return { ...state, fetching: false, folderSettings: action.payload.settings };
    case GETFOLDERSETTINGSBYUSER_SUCCESS:
      return { ...state, fetching: false, userFolderSettings: action.payload.settings };
    case GETFOLDERSETTING_ATTEMPT:
      return { ...state, folderLoading: true, folderSetting: null, folders: null, uploadFolders: null, data: null };
    case GETFOLDERSETTING_SUCCESS:
      return { ...state, folderLoading: false, folderSetting: action.payload.settings };
    case ADDFOLDERSETTINGS_SUCCESS:
      return { ...state, fetching: false, folderSettings: state.folderSettings.concat(action.payload.settings) };
    case UPDATEFOLDERSETTINGS_SUCCESS:
      let newFolderSetting = state.folderSettings.map((d) => {
        if (d._id === action.payload.settings._id) {
          return action.payload.settings;
        }
        return d;
      });
      return {
        ...state,
        fetching: false,
        folderSettings: newFolderSetting.filter((a) => a.approved),
      };
    case DELETEFOLDERSETTINGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        folderSettings: state.folderSettings.filter((a) => a._id !== action.payload.id),
      };
    case REQUESTFOLDER_SUCCESS:
      return {
        ...state,
        fetching: false,
        folderRequest: {
          status: action.payload.status,
          setting: action.payload.setting,
          message: action.payload.message,
        },
        currentArea: action.payload.area,
        currentFolder: action.payload.folder,
        currentPath: action.payload.folder ? action.payload.folder.path : state.currentPath,
        fullPath: action.payload.folder ? action.payload.folder.fullPath : state.fullPath,
        showAlert: true,
      };
    case SETDOCDRAWER:
      if (typeof action?.payload === 'object') {
        return { ...state, ...action.payload };
      } else {
        return { ...state, docDrawer: action.payload, refetchTable: null, refetchTableType: '' };
      }
    case FETCHREVCOMMENTS_ATTEMPT:
      return { ...state, revFetching: true, revComments: null };
    case FETCHREVCOMMENTS:
      return { ...state, revComments: action.payload.comments, revFetching: false, fetching: false };
    case ADDREVCOMMENT:
      return { ...state, revComments: [...state.revComments, action.payload.comment], fetching: false };
    case UPDATEREVCOMMENT:
      let savedRevComments = state.revComments.map((c) => {
        if (c._id === action.payload.id) {
          return action.payload.comment;
        } else {
          return c;
        }
      });
      return { ...state, revComments: savedRevComments, fetching: false };
    case DELETEREVCOMMENT:
      return { ...state, revComments: state.revComments.filter((x) => x._id !== action.payload.id), fetching: false };
    case DELETEREVCOMMENTS:
      return {
        ...state,
        revComments: state.revComments?.filter((x) => action.payload.includes(x._id)),
        fetching: false,
      };
    case COPYTEMPLATES_ATTEMPT:
      return { ...state, copyingTemplates: true };
    case COPYTEMPLATES_SUCCESS:
      return { ...state, copyingTemplates: false };
    case COPYTEMPLATES_ERROR:
      return { ...state, copyingTemplates: false };
    case SETREFETCHTABLE:
      return { ...state, refetchTable: action.payload };
    case SETREFETCHTABLETYPE:
      return { ...state, refetchTableType: action.payload };
    default:
      return state;
  }
}
