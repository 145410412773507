import React, { useEffect, useState, useRef } from 'react';
import { DialogContent, CircularProgress, Grid, DialogTitle, TextField, Typography } from '@mui/material';
import { Collapse, Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import { Alert, AlertTitle } from '@mui/material';
import { logo } from '../../../../constants';
import { blue } from '@mui/material/colors';
import axios from 'axios';

export default function Confirmation({}) {
  const [error, setError] = useState(null);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [savingPassword, setSavingPassword] = useState(false);
  const [tfaDialog, setTfaDialog] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const { userId, verificationId } = useParams();
  const qrCodeTimer = useRef();

  useEffect(() => {
    return () => {
      if (qrCodeTimer.current != null) {
        clearInterval(qrCodeTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!verificationId || !userId) return;
    axios
      .post('/auth/confirmEmail', { userId, verificationId })
      .then((res) => {
        if (res.data.verified === true) {
          window.location.href = '/home';
          return;
        }
        if (res.data.passwordRequired === true) {
          setPasswordDialog(false);
          setPasswordDialog(true);
          return;
        }
        if (res.data.authentication) {
          localStorage.clear();
          localStorage.setItem('token', res.data.authentication.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authentication.name);
          localStorage.setItem('_id', res.data.authentication.id);
          localStorage.setItem('company', res.data.authentication.company);
          localStorage.setItem('access', res.data.authentication.access);
          window.location.href = '/home';
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Something went wrong');
      });
  }, [userId, verificationId]);

  function loadQrCode() {
    setLoadingQrCode(true);
    axios
      .get('/auth/getAuthyQrCode', { params: { userId, verificationId } })
      .then((res) => {
        setQrCode(res.data.qrCode);
        setLoadingQrCode(false);
      })
      .catch((err) => {
        setLoadingQrCode(false);
        setError(err?.response?.data?.message || 'Something went wrong');
      });
  }

  function setupQrCodeLoading() {
    loadQrCode();
    qrCodeTimer.current = setInterval(loadQrCode, 9 * 60 * 1000);
  }

  function savePassword() {
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }
    setSavingPassword(true);
    setError('');
    axios
      .post('/auth/confirmEmail', { userId, verificationId, password })
      .then((res) => {
        if (res.data.verified === true) {
          window.location.href = '/home';
          return;
        }

        if (res.data.errorMessage) {
          setError(res.data.errorMessage);
          setTimeout(() => setError(''), 8000);
        }

        if (res.data.passwordRequired === true) {
          setSavingPassword(false);
          return;
        }

        if (res.data.tfaRequired === true) {
          setSavingPassword(false);
          setTfaDialog(true);
          setupQrCodeLoading();
          return;
        }

        if (res.data.authentication) {
          localStorage.clear();
          localStorage.setItem('token', res.data.authentication.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authentication.name);
          localStorage.setItem('_id', res.data.authentication.id);
          localStorage.setItem('company', res.data.authentication.company);
          localStorage.setItem('access', res.data.authentication.access);
          window.location.href = '/home';
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Something went wrong');
        setTimeout(() => setError(''), 8000);
        setSavingPassword(false);
      });
  }

  if (tfaDialog) {
    return (
      <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container item justifyContent="center">
              <img alt="img" src={logo} style={{ height: 80, marginTop: 30 }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Grid container>
              <Alert severity="info" style={{ width: '100%', marginTop: 8, borderRadius: 8 }}>
                <AlertTitle>One last step: please set up two-factor authentication for your account</AlertTitle>
                <Typography gutterBottom>
                  Download or open{' '}
                  <a href="https://authy.com/download/" target={'_blank'} style={{ color: blue[700] }}>
                    Authy app
                  </a>
                </Typography>
                <Typography gutterBottom>Scan the below QR code in your 'Authy' app and click 'Done'</Typography>
              </Alert>
            </Grid>

            {loadingQrCode ? (
              <Grid container justifyContent="center" alignItems="center" style={{ height: '30vh' }}>
                <CircularProgress color="primary" />
              </Grid>
            ) : (
              <Grid container justifyContent="center" alignItems="center" style={{ height: '30vh' }}>
                {qrCode && <img alt="Authy" src={qrCode} style={{ height: '25vh', width: '25vh' }} />}
              </Grid>
            )}
          </Grid>

          <Collapse in={!!error} style={{ width: '100%' }}>
            <Alert severity="error" style={{ width: '100%', marginTop: 8, borderRadius: 8 }}>
              {error}
            </Alert>
          </Collapse>
        </DialogContent>
        <StandardDialogActions saving={savingPassword} onDone={savePassword} disableDone={!password} />
      </RoundedDialog>
    );
  }

  if (passwordDialog) {
    return (
      <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container item justifyContent="center">
              <img alt="img" src={logo} style={{ height: 80, marginTop: 30 }} />
            </Grid>
            <Grid container item style={{ textAlign: 'center' }} justifyContent="center">
              <Typography variant="h6" gutterBottom>
                Please set the password for your account
              </Typography>
            </Grid>
            <Grid container item direction="column">
              <Alert severity="info" style={{ width: '100%', marginTop: 8, borderRadius: 8 }}>
                <AlertTitle>To ensure the security of your account your password must:</AlertTitle>
                <Typography style={{ fontSize: 14 }}>Be at least 8 characters long</Typography>
                <Typography style={{ fontSize: 14 }}>Contain at least 1 lowercase letter</Typography>
                <Typography style={{ fontSize: 14 }}>Contain at least 1 uppercase letter</Typography>
                <Typography style={{ fontSize: 14 }}>Contain at least 1 special character</Typography>
              </Alert>
            </Grid>
            <Grid container item>
              <TextField
                type="password"
                variant="outlined"
                fullWidth
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid container item>
              <TextField
                type="password"
                variant="outlined"
                error={confirmPasswordError}
                helperText={confirmPasswordError ? 'Passwords do not match' : ''}
                fullWidth
                label="Confirm password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError(false);
                }}
              />
            </Grid>
          </Grid>
          <Collapse in={!!error} style={{ width: '100%' }}>
            <Alert severity="error" style={{ width: '100%', marginTop: 8, borderRadius: 8 }}>
              {error}
            </Alert>
          </Collapse>
        </DialogContent>
        <StandardDialogActions saving={savingPassword} onDone={savePassword} disableDone={!password} />
      </RoundedDialog>
    );
  }

  if (error) {
    return (
      <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Alert severity="error" style={{ width: '100%', marginBottom: 16, borderRadius: 8 }}>
            {error}
          </Alert>
        </DialogContent>
      </RoundedDialog>
    );
  }

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
      <CircularProgress color="primary" />
    </Grid>
  );
}
