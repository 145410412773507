import React from 'react';
import { Grid, MenuItem, ListItemIcon, Typography } from '@mui/material';

export default function IconMenuItem({ icon, selectedIcon, text, selected, onClick }) {
  return (
    <MenuItem>
      <Grid onClick={onClick} container alignItems="center">
        <ListItemIcon>{selected ? selectedIcon : icon}</ListItemIcon>
        <Typography sx={{ fontWeight: selected ? 600 : 400 }}>{text}</Typography>
      </Grid>
    </MenuItem>
  );
}
