import React, { useState } from 'react';
import { RoundedDialog, CrossButton, HtmlContent } from '../../Global/Components';
import Uploader from '../../Global/Uploader';
import StateManager from '../../Global/StateManager';
import {
  DialogContent,
  DialogTitle,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
  Chip,
  Tooltip,
  Paper,
} from '@mui/material';
import { DialogActions, TextField } from '@mui/material';
import { Reply, HelpOutline } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { grey, red, blue } from '@mui/material/colors';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PriorityButton from '../../Hubs/tasks/Components/PriorityButton';
import CustomRaisedUser from './CustomRaisedUser';
import { typeColors } from '../constants';

function get_browser() {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
}

const hubs = [
  'Workflow',
  'Projects',
  'Process Hub',
  'Form Hub',
  'Doc Library',
  'KPI Hub',
  'Audit Hub',
  'Portal',
  'Resources',
  'Asset Hub',
  'Other',
];

export default function SupportDialog({ open, onClose, disableLink = false, fromAdmin = false }) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [priority, setPriority] = useState('None');
  const [description, setDescription] = useState('');
  const [reproduction, setReproduction] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [selectedHub, setSelectedHub] = useState('');
  const [saving, setSaving] = useState(false);
  const [improvementType, setImprovementType] = useState('');
  const [subUser, setSubUser] = useState({});

  function clear() {
    setType('');
    setPriority('None');
    setDescription('');
    setReproduction('');
    setAttachments([]);
    setSelectedHub('');
    setImprovementType('');
  }

  function createBugTicket() {
    if (!title) {
      StateManager.setErrorAlert('Please give a title to the issue');
      return;
    }

    if (!selectedHub) {
      StateManager.setErrorAlert('Please specify where the problem occured');
      return;
    }

    if (!description) {
      StateManager.setErrorAlert('Please describe the problem occured');
      return;
    }

    if (!reproduction) {
      StateManager.setErrorAlert('Please describe steps to reproduce');
      return;
    }

    setSaving(true);
    let browser = get_browser();
    let body = {
      title,
      priority,
      hub: selectedHub,
      description,
      reproduction,
      attachments,
      browser: `Brouser: ${browser.name}; Version: ${browser.version}`,
      screen: `${window.screen.availWidth}x${window.screen.availHeight}`,
    };
    subUser ? (body.subUser = subUser) : null;
    axios
      .post('/support/createBugTicket', body)
      .then((res) => {
        StateManager.setSuccessAlert('Ticket has been created');
        onClose(res.data);
        clear();
        setSaving(false);
        setSubUser({});
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to create a ticket');
        setSaving(false);
        setSubUser({});
      });
  }

  function createImprovementTicket() {
    if (!title) {
      StateManager.setErrorAlert('Please give a title to the issue');
      return;
    }

    if (!improvementType) {
      StateManager.setErrorAlert('Please specify the improvement type');
      return;
    }

    if (!selectedHub) {
      StateManager.setErrorAlert('Please specify what is the improvement for');
      return;
    }

    if (!description) {
      StateManager.setErrorAlert('Please describe the improvement');
      return;
    }

    setSaving(true);
    let body = {
      title,
      priority,
      type: improvementType,
      hub: selectedHub,
      description,
    };
    subUser ? (body.subUser = subUser) : null;
    axios
      .post('/support/createImprovementTicket', body)
      .then((res) => {
        StateManager.setSuccessAlert('Improvement has been submitted');
        onClose(res.data);
        clear();
        setSaving(false);
        setSubUser({});
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to suggest an improvement');
        setSaving(false);
        setSubUser({});
      });
  }

  function createHelpTicket() {
    if (!title) {
      StateManager.setErrorAlert('Please give a title to the issue');
      return;
    }

    if (!selectedHub) {
      StateManager.setErrorAlert('Please specify the area where you need help');
      return;
    }

    if (!description) {
      StateManager.setErrorAlert('Please describe the issue');
      return;
    }

    setSaving(true);
    let body = {
      title,
      priority,
      hub: selectedHub,
      description,
    };
    subUser ? (body.subUser = subUser) : null;
    axios
      .post('/support/createHelpTicket', body)
      .then((res) => {
        StateManager.setSuccessAlert('Feedback has been sent');
        onClose(res.data);
        clear();
        setSaving(false);
        setSubUser({});
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to send a feedback');
        setSaving(false);
        setSubUser({});
      });
  }

  const descriptionTitle = {
    bug: 'Please describe what the bug is:',
    improvement: 'Please describe the improvement you want to suggest:',
    help: 'Please describe the issue you need help with:',
  };

  return (
    <RoundedDialog
      open={open}
      maxWidth={type === '' ? 'sm' : 'md'}
      fullWidth
      onClose={() => onClose(null)}
      className="scroll-bar"
      fullScreen={!largeDevices}
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <HelpOutline fontSize="large" style={{ marginRight: '1rem', color: grey[600] }} />
          <Typography variant="h5" style={{ marginRight: 'auto' }}>
            Support
          </Typography>
          <CrossButton onClick={() => onClose(null)} />
        </Grid>
      </DialogTitle>

      <DialogContent>
        {type === '' && (
          <Grid container>
            <Grid container item style={{ marginTop: theme.spacing(1) }}>
              <Typography variant="h6">What would you like to do?</Typography>
            </Grid>
            <Grid container item style={{ margin: theme.spacing(2, 0) }}>
              <FormControl component="fieldset" variant="standard">
                <RadioGroup onChange={(event) => setType(event.target.value)}>
                  <FormControlLabel value="bug" control={<Radio />} label="Report a bug" />
                  <FormControlLabel value="improvement" control={<Radio />} label="Suggest improvement" />
                  <FormControlLabel value="help" control={<Radio />} label="Ask for help" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {fromAdmin && <CustomRaisedUser fromAdmin={fromAdmin} setSubUser={setSubUser} />}
            {!disableLink && (
              <Grid container item style={{ marginTop: theme.spacing(3) }}>
                <Link to="/support">
                  <Typography variant="h6" style={{ color: blue[800] }}>
                    Go to support page
                  </Typography>
                </Link>
              </Grid>
            )}
          </Grid>
        )}
        {type && (
          <Grid container minWidth={'100%'} direction={'column'} gap={2}>
            <Grid container item>
              <Button style={{ textTransform: 'none' }} onClick={clear} startIcon={<Reply />}>
                Back
              </Button>
            </Grid>
            <Tooltip title={title}>
              <Paper variant="outlined" sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  placeholder="Enter title"
                  style={{ flexGrow: 1, width: '100%', border: '2px' }}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </Paper>
            </Tooltip>
            <Grid container item>
              <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                <Typography variant="body1">Which hub?</Typography>
              </Grid>
              <Grid container item gap={0.5}>
                {hubs.map((hub) => (
                  <Chip
                    label={hub}
                    size="small"
                    fontSize="small"
                    clickable
                    color={selectedHub === hub ? 'primary' : 'default'}
                    onClick={() => setSelectedHub(hub)}
                  />
                ))}
              </Grid>
            </Grid>
            {type === 'improvement' && (
              <Grid container item alignItems="center">
                <Typography style={{ fontWeight: 'bold', marginRight: '1em' }}>Improvement type </Typography>
                <RadioGroup row onChange={(event) => setImprovementType(event.target.value)}>
                  <FormControlLabel value="New feature" control={<Radio />} label="New feature" />
                  <FormControlLabel value="Improvement" control={<Radio />} label="Improvement" />
                </RadioGroup>
              </Grid>
            )}
            <Paper variant="outlined" sx={{ height: '100%', alignContent: 'center', padding: theme.spacing(0, 0) }}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                sx={{
                  '@media (max-width: 1024px)': {
                    flexWrap: 'wrap',
                  },
                }}
              >
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  lg={3}
                  md={6}
                  sm={12}
                  sx={{ margin: theme.spacing(1, 0) }}
                >
                  <Typography style={{ fontSize: 14, fontWeight: 700, marginRight: theme.spacing(2) }}>
                    Choose priority
                  </Typography>
                  <PriorityButton priority={priority} setPriority={setPriority} sx={{ fontSize: 14 }} />
                </Grid>

                <Grid
                  container
                  item
                  alignItems="center"
                  gap={1}
                  justifyContent="center"
                  direction="row"
                  lg={3}
                  md={6}
                  sm={12}
                  sx={{ margin: theme.spacing(1, 0) }}
                >
                  <Grid container item direction={'row'} justifyContent={'center'} flexWrap={'nowrap'}>
                    <Typography fontSize={14} style={{ fontWeight: 700, marginRight: theme.spacing(2) }}>
                      Category
                    </Typography>
                    <Chip
                      label={type}
                      size="small"
                      style={{
                        background: typeColors[type],
                        color: theme.palette.getContrastText(typeColors[type]),
                      }}
                    />
                    {improvementType && <Chip label={improvementType} size="small" />}
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  lg={3}
                  md={6}
                  sm={12}
                  sx={{ margin: theme.spacing(1, 0) }}
                >
                  <Typography style={{ fontWeight: 700, marginRight: theme.spacing(2) }}>Hub</Typography>
                  <Typography>{selectedHub}</Typography>
                </Grid>
              </Grid>
            </Paper>
            <Grid container item style={{ margin: theme.spacing(1, 0) }}>
              <Typography variant="body1">{descriptionTitle[type]}</Typography>
              <HtmlContent editable hideButtons onEdit={setDescription} onSave={setDescription} />
            </Grid>
            {type === 'bug' && (
              <>
                <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                  <Typography variant="body1">Please describe steps to reproduce the behavior:</Typography>
                  <HtmlContent editable hideButtons onEdit={setReproduction} onSave={setReproduction} />
                </Grid>
                <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                  <Typography variant="body1">Do you have any files to attach?</Typography>
                </Grid>
                <Grid container item style={{ marginBottom: theme.spacing(4) }}>
                  <Uploader
                    onResult={(file) => attachments.push(file.file)}
                    onCancel={(id) => setAttachments([...attachments.filter((x) => x.id !== id)])}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        {!saving && (
          <Button onClick={() => onClose(null)} style={{ color: red[500] }}>
            cancel
          </Button>
        )}
        {!saving && type === 'bug' && (
          <Button onClick={createBugTicket} style={{ color: blue[500] }}>
            create a ticket
          </Button>
        )}
        {!saving && type === 'improvement' && (
          <Button onClick={createImprovementTicket} style={{ color: blue[500] }}>
            submit
          </Button>
        )}
        {!saving && type === 'help' && (
          <Button onClick={createHelpTicket} style={{ color: blue[500] }}>
            send
          </Button>
        )}
        {saving && <CircularProgress style={{ color: blue[500], height: 30, width: 30, margin: 3 }} />}
      </DialogActions>
    </RoundedDialog>
  );
}
