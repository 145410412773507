/**
 * * Reducer for Approval Box
 */

import {
  ADDAPPROVALS_ATTEMPT,
  ADDAPPROVALS_SUCCESS,
  ADDAPPROVALS_FAIL,
  SETAPPROVAL,
  FETCHAPPROVALSBYACTIVITY_ATTEMPT,
  FETCHAPPROVALSBYACTIVITY_SUCCESS,
  FETCHAPPROVALSBYACTIVITY_FAIL,
  UPDATEMETAINACTIVITY_ATTEMPT,
  UPDATEMETAINACTIVITY_SUCCESS,
  UPDATEMETAINACTIVITY_FAIL,
  UPDATEAPPROVAL_ATTEMPT,
  UPDATEAPPROVAL_SUCCESS,
  UPDATEAPPROVAL_FAIL,
  REMOVEAPPROVAL_ATTEMPT,
  REMOVEAPPROVAL_SUCCESS,
  REMOVEAPPROVAL_FAIL,
  SETAPPROVALALERT,
} from '../constants';

const initialState = {
  approvals: {},
  fetching: false,
  message: null,
  status: null,
  alertStatus: null,
  alert: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETAPPROVALALERT:
      return { ...state, alert: action.payload.alert, message: action.payload.message };
    case ADDAPPROVALS_ATTEMPT:
      return { ...state, fetching: true };
    case ADDAPPROVALS_SUCCESS:
      return { ...state, fetching: false, approvals: state.approvals.concat(action.payload.approvals) };
    case ADDAPPROVALS_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case UPDATEAPPROVAL_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATEAPPROVAL_SUCCESS:
      return { ...state, fetching: false, approvals: action.payload.approvals };
    case UPDATEAPPROVAL_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case FETCHAPPROVALSBYACTIVITY_ATTEMPT:
      return { ...state, fetching: true, approvals: null };
    case FETCHAPPROVALSBYACTIVITY_SUCCESS:
      return { ...state, fetching: false, approvals: action.payload };
    case FETCHAPPROVALSBYACTIVITY_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SETAPPROVAL:
      let modifiedApprovals = state.approvals;
      modifiedApprovals[action.payload.approvalIndex].meta[action.payload.metaIndex] = action.payload.data;
      return { ...state, approvals: modifiedApprovals };
    case UPDATEMETAINACTIVITY_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATEMETAINACTIVITY_SUCCESS:
      let updatedApprovals = state.approvals.map((x) => {
        if (x._id === action.payload.approval._id) {
          return (x = action.payload.approval);
        } else {
          return x;
        }
      });
      return { ...state, fetching: false, approvals: updatedApprovals };
    case UPDATEMETAINACTIVITY_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case REMOVEAPPROVAL_ATTEMPT:
      return { ...state, fetching: true };
    case REMOVEAPPROVAL_SUCCESS:
      if (action.payload.status === 200) {
        return {
          ...state,
          fetching: false,
          approvals: state.approvals.filter((x) => x._id !== action.payload.id),
          message: action.payload.message,
          alert: true,
          alertStatus: action.payload.status,
        };
      } else {
        return {
          ...state,
          fetching: false,
          message: action.payload.message,
          alert: true,
          alertStatus: action.payload.status,
        };
      }
    case REMOVEAPPROVAL_FAIL:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
