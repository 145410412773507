import React from 'react';
import { DialogActions, Button, CircularProgress } from '@mui/material';
import { CloseRounded, DoneRounded } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import isFunction from 'lodash/isFunction';

export default function StandardDialogActions({
  onClose,
  onDone,
  saving,
  hideDone,
  hideClose,
  additionalActions,
  closeButtonText,
  doneButtonText,
  closeButtonIcon,
  doneButtonIcon,
  disableDone = false,
}) {
  return (
    <DialogActions>
      {!saving && (
        <>
          {additionalActions}
          {isFunction(onClose) && !hideClose && (
            <Button onClick={onClose} startIcon={closeButtonIcon || <CloseRounded />} color="secondary">
              {closeButtonText || 'CLOSE'}
            </Button>
          )}

          {isFunction(onDone) && !hideDone && (
            <Button
              onClick={onDone}
              style={{}}
              variant="contained"
              color="primary"
              startIcon={doneButtonIcon || <DoneRounded />}
              disabled={disableDone}
            >
              {doneButtonText || 'DONE'}
            </Button>
          )}
        </>
      )}
      {saving && <CircularProgress color="primary" size={24} style={{ margin: 6 }} />}
    </DialogActions>
  );
}
