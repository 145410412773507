import { red } from '@mui/material/colors';
import { DialogContent, DialogTitle, DialogActions, Button, Grid, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { RoundedDialog, HtmlContent } from '../Components';
import Uploader from '../Uploader';
import { TaskIcon } from '../Icons';

export default function FieldTaskDialog({ open, onClose, onResult, task }) {
  const [description, setDescription] = useState('');
  const attachments = useRef([]);

  function done() {
    onResult({
      description,
      attachments: attachments.current,
    });

    onClose();
  }

  useEffect(() => {}, []);

  useEffect(() => {
    if (!open) return;

    attachments.current = task?.attachments || [];
    setDescription(task?.description || '');
  }, [open]); // eslint-disable-line

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container items alignItems="center" justifyContent="center">
          <TaskIcon />
          <Typography variant="h6" style={{ marginLeft: '1rem' }}>
            Task
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container item style={{ margin: '1rem 0' }}>
          <HtmlContent
            content={description}
            editable
            title="Task description"
            placeholder="Enter task description"
            onEdit={setDescription}
            onSave={setDescription}
          />
        </Grid>

        <Grid container item style={{ margin: '1rem 0' }}>
          <Uploader
            onResult={(file) => attachments.current.push(file.file)}
            onCancel={(id) => (attachments.current = attachments.current.filter((x) => x.id !== id))}
            uploaded={task?.attachments || []}
            withLinkedFeatures={true}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: red[500] }}>
          cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={done} style={{ borderRadius: '0.5rem' }}>
          done
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
