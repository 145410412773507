import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { isString, isObject, isFunction } from 'lodash';
import Canvas from '../Fields/Canvas';
import { v4 } from 'uuid';
import useElememtSize from '../hooks/useElementSize';
import { useRef } from 'react';

export default function Signature({
  initial,
  onChange,
  disabled,
  editable,
  view = 'single',
  showExtraFields = true,
  additionalComponent,
}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');

  const [signature, setSignature] = useState(null);
  const [canvasContainerId] = useState(`canvas-container-${v4()}`);
  const { width } = useElememtSize(canvasContainerId);
  const timer = useRef(null);

  useEffect(() => {
    setFirstName(isString(initial?.firstName) ? initial.firstName : '');
    setLastName(isString(initial?.lastName) ? initial.lastName : '');
    setEmail(isString(initial?.email) ? initial.email : '');
    setOrganization(isString(initial?.organization) ? initial.organization : '');
    setSignature(isObject(initial?.signature) ? initial.signature : null);
  }, [initial]);

  function save(params, saveImmediately) {
    const result = { firstName, lastName, email, organization, signature, ...params };
    if (!isFunction(onChange)) return;
    if (saveImmediately) {
      onChange(result);
    } else {
      if (timer.current != null) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => onChange(result), 1000);
    }
  }

  return (
    <Grid container alignItems={'flex-start'}>
      <Grid container item lg={4} md={5} xs={12}>
        {additionalComponent}
        <Grid container item>
          <TextField
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              save({ firstName: e.target.value });
            }}
            fullWidth
            placeholder="First name"
            disabled={disabled || !editable}
            focused={!disabled}
            variant={!disabled ? 'outlined' : 'filled'}
          />
        </Grid>
        <Grid container item sx={{ mt: 2 }}>
          <TextField
            disabled={disabled || !editable}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              save({ lastName: e.target.value });
            }}
            fullWidth
            placeholder="Last name"
            focused={!disabled}
            variant={!disabled ? 'outlined' : 'filled'}
          />
        </Grid>
        {view === 'tabbed' && showExtraFields && (
          <Grid container item sx={{ mt: 2 }}>
            <TextField
              disabled={disabled || !editable}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                save({ email: e.target.value });
              }}
              fullWidth
              placeholder="Email address (optional)"
              focused={!disabled}
              variant={!disabled ? 'outlined' : 'filled'}
            />
          </Grid>
        )}
        {view === 'tabbed' && showExtraFields && (
          <Grid container item sx={{ mt: 2 }}>
            <TextField
              disabled={disabled || !editable}
              value={organization}
              onChange={(e) => {
                setOrganization(e.target.value);
                save({ organization: e.target.value });
              }}
              fullWidth
              placeholder="Organization (optional)"
              focused={!disabled}
              variant={!disabled ? 'outlined' : 'filled'}
            />
          </Grid>
        )}
      </Grid>
      <Grid container item lg={8} md={7} xs={12} sx={{ pl: 2 }}>
        <Grid container id={canvasContainerId}>
          <Canvas
            disabled={disabled}
            editable={editable}
            onResult={(res) => {
              setSignature(res);
              save({ signature: res }, true);
            }}
            initial={signature}
            width={width}
            view={view}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
