export const attemptAction = (actionType) => ({
  type: actionType,
});

export const successAction = (actionType, data) => ({
  type: actionType,
  payload: data,
});

const getErrorMessage = (err) => {
  if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message;
  }
  return err.message ? err.message : JSON.stringify(err);
};

export const errorAction = (actionType, err) => ({
  type: actionType,
  payload: getErrorMessage(err),
});

export const standardNotificationOptions = (variant = 'success') => ({
  variant,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
});

export const SELECTFILE = 'SELECTFILE';
export const TOGGLEASSISTANT = 'TOGGLEASSISTANT';
export const TOGGLEDRAWER = 'TOGGLEDRAWER';
export const SETDRAWERTOUR = 'SETDRAWERTOUR';
export const SETGUIDEDTOUR = 'SETGUIDEDTOUR';

export const GETCUSTOMUSERS_ATTEMPT = 'GETCUSTOMUSERS_ATTEMPT';
export const GETCUSTOMUSERS_SUCCESS = 'GETCUSTOMUSERS_SUCCESS';
export const GETCUSTOMUSERS_FAIL = 'GETCUSTOMUSERS_FAIL';

export const FETCHAPPROVALSBYACTIVITY_ATTEMPT = 'FETCHAPPROVALSBYACTIVITY_ATTEMPT';
export const FETCHAPPROVALSBYACTIVITY_SUCCESS = 'FETCHAPPROVALSBYACTIVITY_SUCCESS';
export const FETCHAPPROVALSBYACTIVITY_FAIL = 'FETCHAPPROVALSBYACTIVITY_FAIL';

export const ADDAPPROVALS_ATTEMPT = 'ADDAPPROVALS_ATTEMPT';
export const ADDAPPROVALS_SUCCESS = 'ADDAPPROVALS_SUCCESS';
export const ADDAPPROVALS_FAIL = 'ADDAPPROVALS_FAIL';

export const UPDATEAPPROVAL_ATTEMPT = 'UPDATEAPPROVAL_ATTEMPT';
export const UPDATEAPPROVAL_SUCCESS = 'UPDATEAPPROVAL_SUCCESS';
export const UPDATEAPPROVAL_FAIL = 'UPDATEAPPROVAL_FAIL';

export const ADDMETATOAPPROVAL_ATTEMPT = 'ADDMETATOAPPROVAL_ATTEMPT';
export const ADDMETATOAPPROVAL_SUCCESS = 'ADDMETATOAPPROVAL_SUCCESS';
export const ADDMETATOAPPROVAL_FAIL = 'ADDMETATOAPPROVAL_FAIL';

export const UPDATEMETAINACTIVITY_ATTEMPT = 'UPDATEMETAINACTIVITY_ATTEMPT';
export const UPDATEMETAINACTIVITY_SUCCESS = 'UPDATEMETAINACTIVITY_SUCCESS';
export const UPDATEMETAINACTIVITY_FAIL = 'UPDATEMETAINACTIVITY_FAIL';

export const SETAPPROVAL = 'SETAPPROVAL';

export const GETAPPROVALUSERS_ATTEMPT = 'GETAPPROVALUSERS_ATTEMPT';
export const GETAPPROVALUSERS_SUCCESS = 'GETAPPROVALUSERS_SUCCESS';
export const GETAPPROVALUSERS_FAIL = 'GETAPPROVALUSERS_FAIL';

export const REMOVEAPPROVAL_ATTEMPT = 'REMOVEAPPROVAL_ATTEMPT';
export const REMOVEAPPROVAL_SUCCESS = 'REMOVEAPPROVAL_SUCCESS';
export const REMOVEAPPROVAL_FAIL = 'REMOVEAPPROVAL_FAIL';

export const ADDAPPROVAL_ATTEMPT = 'ADDAPPROVAL_ATTEMPT';
export const ADDAPPROVAL_SUCCESS = 'ADDAPPROVAL_SUCCESS';
export const ADDAPPROVAL_FAIL = 'ADDAPPROVAL_FAIL';

export const SETAPPROVALALERT = 'SETAPPROVALALERT';

export const GENERAL_ATTEMPT = 'GENERAL_ATTEMPT';
export const GENERAL_ERROR = 'GENERAL_ERROR';

export const FETCHCOMPANYGROUPS_ATTEMPT = 'FETCHCOMPANYGROUPS_ATTEMPT';
export const FETCHCOMPANYGROUPS_SUCCESS = 'FETCHCOMPANYGROUPS_SUCCESS';
export const FETCHCOMPANYADMINS_SUCCESS = 'FETCHCOMPANYADMINS_SUCCESS';
export const FETCHCOMPANYGROUPS_FAIL = 'FETCHCOMPANYGROUPS_FAIL';

export const FETCHCOMPANYGROUP_ATTEMPT = 'FETCHCOMPANYGROUP_ATTEMPT';
export const FETCHCOMPANYGROUP_SUCCESS = 'FETCHCOMPANYGROUP_SUCCESS';

export const SEARCHATTEMPT = 'SEARCHATTEMPT';
export const SEARCHSUCCESS = 'SEARCHSUCCESS';
export const SEARCHFAIL = 'SEARCHFAIL';
export const SELECTHUB = 'SELECTHUB';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const COMPONENTLOADER = 'COMPONENTLOADER';
export const COMPONENTERROR = 'COMPONENTERROR';
export const COMPONENTSUCCESS = 'COMPONENTSUCCESS';
