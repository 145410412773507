import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, Typography, Radio, IconButton, Chip, RadioGroup } from '@mui/material';
import { DialogTitle, DialogContent, TextField, Autocomplete, FormControlLabel, Switch } from '@mui/material';
import { CheckCircleRounded, ChevronRightRounded, ExpandMoreRounded, FilePresentRounded } from '@mui/icons-material';
import { KeyboardArrowLeftRounded, CheckCircleOutlineRounded, FolderRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../Components';
import { green, grey } from '@mui/material/colors';
import Uploader from '../Uploader';
import axios from 'axios';
import { isArray, isEmpty, isFunction, uniqBy } from 'lodash';
import StateManager from '../StateManager';
import { TreeItem, TreeView } from '@mui/x-tree-view';

import { DocIcon, PortalIcon } from '../Icons';

export default function DocumentsDialog({ open, onClose, onResult, initial }) {
  const [tab, setTab] = useState(0);
  const [type, setType] = useState(null);

  const [folderType, setFolderType] = useState(null);

  const [availableDocs, setAvailableDocs] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [docsOpen, setDocsOpen] = useState(false);
  const loadDocs = docsOpen && availableDocs.length === 0;

  const [availablePortalFolders, setAvailablePortalFolders] = useState([]);
  const [selectedGlobalFileFolder, setSelectedGlobalFileFolder] = useState(null);
  const [portalFolderSpaces, setPortalFolderSpaces] = useState([]);
  const [selectedPortalForFolder, setSelectedPortalForFolder] = useState(null);
  const [loadingPortalFolderSpaces, setLoadingPortalFolderSpaces] = useState(false);
  const [portalFoldersOpen, setPortalFoldersOpen] = useState(false);
  const [selectedFilesFromPortal, setSelectedFilesFromFolder] = useState([]);
  const [noFolders, setNoFolders] = useState(false);
  const loadPortalFolders = portalFoldersOpen && isEmpty(availablePortalFolders);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [previouslySelected, setPreviouslySelected] = useState([]);

  const [foldersTree, setFoldersTree] = useState([]);
  const [selectedDlFolder, setSelectedDlFolder] = useState(null);
  const [loadingDlFolders, setLoadingDlFolders] = useState(false);
  const loadDLFolders = tab === 1 && type === 'folder' && folderType === 'DL' && isEmpty(foldersTree);

  const [globalPortalFolders, setGlobalPortalFolders] = useState([]);
  const [globalFoldersOpen, setGlobalFoldersOpen] = useState([]);
  const [selectedGlobalFolder, setSelectedGlobalFolder] = useState(null);
  const [showFilesFrom, setShowFilesFrom] = useState('');
  const [noSpaces, setNoSpaces] = useState(false);
  const loadPortalSpaces = globalFoldersOpen && isEmpty(globalPortalFolders);

  const files = uniqBy(
    [...selectedDocs.map(mapDocument), ...uploadedFiles, ...selectedFilesFromPortal, ...previouslySelected],
    'id',
  );

  const [signatureRequired, setSignatureRequired] = useState('yes');
  const ready =
    (type === 'docs' && !isEmpty(files)) ||
    (type === 'folder' && folderType === 'DL' && selectedDlFolder) ||
    (type === 'folder' && folderType === 'portal' && selectedGlobalFolder && showFilesFrom);

  useEffect(() => {
    if (!loadPortalSpaces) return;
    axios
      .get('/workhub/workspace/getGlobalFolders')
      .then(({ data }) => {
        setGlobalPortalFolders(data);
        setNoSpaces(isEmpty(data));
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadPortalSpaces]);

  useEffect(() => {
    if (!loadPortalFolders) return;
    axios
      .get('/workhub/workspace/getCompanyRootFileFolders')
      .then(({ data }) => {
        setAvailablePortalFolders(data);
        if (isEmpty(data)) {
          setNoFolders(true);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadPortalFolders]);

  useEffect(() => {
    if (!loadDocs) return;
    axios
      .get('dochub/documents/getAllDocumentsWithAccess')
      .then((res) => {
        setAvailableDocs(res.data.Documents);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadDocs]);

  useEffect(() => {
    if (!loadDLFolders) return;
    setLoadingDlFolders(true);
    axios
      .get('/dochub/areas/getUploaderAreas')
      .then(({ data }) => {
        setLoadingDlFolders(false);
        setFoldersTree(data?.tree);
      })
      .catch((err) => {
        setLoadingDlFolders(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadDLFolders]);

  useEffect(() => {
    if (!selectedGlobalFileFolder?._id) return;
    setPortalFolderSpaces([]);
    setSelectedFilesFromFolder([]);
    setLoadingPortalFolderSpaces(true);
    axios
      .post('/workhub/workspace/getPortalGlobalFoldersWithFiles', { ids: [selectedGlobalFileFolder?._id] })
      .then(({ data }) => {
        setPortalFolderSpaces(data.filter((x) => !isEmpty(x.globalFolders[0]?.files)));
        setLoadingPortalFolderSpaces(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [selectedGlobalFileFolder]);

  useEffect(() => {
    setType(initial?.type || null);
    setTab(initial ? 1 : 0);
    setFolderType(initial?.folderType || null);
    setSelectedDlFolder(initial?.dlFolder || null);
    setSelectedGlobalFolder(initial?.globalFolder || null);
    setShowFilesFrom(initial?.showFilesFrom || '');
    setSelectedDocs([]);
    setUploadedFiles([]);
    setSelectedFilesFromFolder([]);
    setSelectedGlobalFileFolder(null);
    setPreviouslySelected(isArray(initial?.files) ? initial.files : []);
    setSignatureRequired(initial?.signatureRequired === false ? 'no' : 'yes');
  }, [initial]);

  function mapDocument(doc) {
    return {
      id: doc._id,
      title: doc.title,
      type: 'qhub',
    };
  }

  function done() {
    if (tab === 1) {
      if (!ready) return;
      setTab(2);
    }

    if (tab === 2 && isFunction(onResult)) {
      const result = {
        type,
        folderType: type === 'folder' ? folderType : undefined,
        files: type === 'docs' ? files : [],
        dlFolder: type === 'folder' && folderType === 'DL' ? selectedDlFolder : undefined,
        globalFolder: type === 'folder' && folderType === 'portal' ? selectedGlobalFolder : undefined,
        showFilesFrom: type === 'folder' && folderType === 'portal' ? showFilesFrom : undefined,
        signatureRequired: signatureRequired === 'yes',
      };
      setSelectedDocs([]);
      setUploadedFiles([]);
      setSelectedFilesFromFolder([]);
      setSelectedGlobalFileFolder(null);
      setPreviouslySelected([]);
      setType(null);
      setTab(0);
      onResult(result);
      onClose();
    }
  }

  function back() {
    if (tab === 1) {
      setFolderType(null);
    }
    setTab(tab - 1);
  }

  function renderTree(node) {
    return (
      <TreeItem
        key={node._id}
        nodeId={node._id}
        label={node.name}
        endIcon={<FolderRounded />}
        onClick={() => setSelectedDlFolder({ _id: node._id, name: node.name, isArea: !node.area })}
      >
        {isArray(node.children) && !isEmpty(node.children) ? node.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Read and sign</DialogTitle>

      <DialogContent>
        {tab === 0 && (
          <Grid container>
            <Grid container item xs={6} sx={{ p: 2 }}>
              <Button
                startIcon={<FilePresentRounded style={{ fontSize: '2.5rem' }} />}
                sx={{ fontSize: 22, textTransform: 'none' }}
                fullWidth
                onClick={() => {
                  setType('docs');
                  setTab(1);
                }}
              >
                Pick documents
              </Button>
            </Grid>

            <Grid container item xs={6} sx={{ p: 2 }}>
              <Button
                startIcon={<FolderRounded style={{ fontSize: '2.5rem' }} />}
                sx={{ fontSize: 22, textTransform: 'none' }}
                fullWidth
                onClick={() => {
                  setType('folder');
                  setTab(1);
                }}
              >
                Pick folders
              </Button>
            </Grid>
          </Grid>
        )}

        {tab === 1 && type === 'docs' && (
          <Grid container>
            {!isEmpty(previouslySelected) && (
              <Grid container item sx={{ mb: 2 }}>
                <Grid container item>
                  <Typography variant="h6" gutterBottom>
                    Previously selected
                  </Typography>
                </Grid>
                <Grid container item spacing={1}>
                  {previouslySelected.map((file) => (
                    <Grid item key={file.id}>
                      <Chip
                        label={file.originalname || file.title}
                        color="primary"
                        onDelete={() => setPreviouslySelected(previouslySelected.filter((x) => x.id !== file.id))}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            <Grid container item>
              <Typography variant="h6">Pick documents</Typography>
            </Grid>
            <Grid container item sx={{ mt: 1 }}>
              <Autocomplete
                multiple
                options={availableDocs}
                disableCloseOnSelect
                fullWidth
                getOptionLabel={(option) => (option ? option.title : '')}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                openOnFocus
                value={selectedDocs}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip color="primary" key={option._id} label={option.title} {...getTagProps({ index })} />
                  ))
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option._id}>
                    <Grid container item alignItems="center">
                      <Typography>{option.title}</Typography>
                      {selected ? (
                        <CheckCircleRounded fontSize="small" style={{ color: green[500], marginLeft: 'auto' }} />
                      ) : (
                        <CheckCircleOutlineRounded fontSize="small" style={{ color: grey[300], marginLeft: 'auto' }} />
                      )}
                    </Grid>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Select documents"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadDocs ? <CircularProgress color="primary" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                onChange={(event, newValue) => setSelectedDocs([...newValue])}
                open={docsOpen}
                onOpen={() => setDocsOpen(true)}
                onClose={() => setDocsOpen(false)}
                loading={loadDocs}
              />
            </Grid>

            <Grid container item sx={{ mt: 4 }}>
              <Typography variant="h6">Or pick from portal folders</Typography>
            </Grid>

            {noFolders ? (
              <Grid container item sx={{ mt: 2 }}>
                <Typography color={'text.secondary'}>Looks like you don't have any portal folders</Typography>
              </Grid>
            ) : (
              <>
                <Grid container item sx={{ mt: 2 }}>
                  <Autocomplete
                    options={availablePortalFolders}
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    getOptionLabel={(option) => (option ? option.title : '')}
                    openOnFocus
                    value={selectedGlobalFileFolder}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <Grid container alignItems="center">
                          <Typography noWrap>{option.title}</Typography>
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        label="Select a folder"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadPortalFolders ? <CircularProgress color="primary" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    onChange={(e, value) => {
                      setSelectedGlobalFileFolder(value);
                      setSelectedFilesFromFolder([]);
                      setSelectedPortalForFolder(null);
                    }}
                    open={portalFoldersOpen}
                    onOpen={() => setPortalFoldersOpen(true)}
                    onClose={() => setPortalFoldersOpen(false)}
                    loading={loadPortalFolders}
                  />
                </Grid>

                {selectedGlobalFileFolder &&
                  (loadingPortalFolderSpaces ? (
                    <Grid container item alignItems={'center'} justifyContent={'center'} sx={{ height: 200 }}>
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <>
                      <Grid container item sx={{ mt: 2 }}>
                        <Autocomplete
                          options={portalFolderSpaces}
                          fullWidth
                          getOptionLabel={(option) => (option ? option.title : '')}
                          isOptionEqualToValue={(option, value) => option?._id === value?._id}
                          openOnFocus
                          value={selectedPortalForFolder}
                          renderInput={(params) => <TextField variant="outlined" {...params} label="Select portal" />}
                          onChange={(event, newValue) => {
                            setSelectedPortalForFolder(newValue);
                            setSelectedFilesFromFolder([]);
                          }}
                        />
                      </Grid>

                      {selectedPortalForFolder && (
                        <Grid container item sx={{ mt: 2 }}>
                          <Autocomplete
                            multiple
                            options={selectedPortalForFolder.globalFolders[0]?.files || []}
                            disableCloseOnSelect
                            fullWidth
                            getOptionLabel={(option) => (option ? option.originalname : '')}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            openOnFocus
                            value={selectedFilesFromPortal}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={option.id}
                                  color="primary"
                                  label={option.originalname}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.id}>
                                <Grid container item alignItems="center">
                                  <Typography>{option.originalname}</Typography>
                                  {selected ? (
                                    <CheckCircleRounded
                                      fontSize="small"
                                      style={{ color: green[500], marginLeft: 'auto' }}
                                    />
                                  ) : (
                                    <CheckCircleOutlineRounded
                                      fontSize="small"
                                      style={{ color: grey[300], marginLeft: 'auto' }}
                                    />
                                  )}
                                </Grid>
                              </li>
                            )}
                            renderInput={(params) => <TextField variant="outlined" {...params} label="Select files" />}
                            onChange={(event, newValue) => setSelectedFilesFromFolder([...newValue])}
                          />
                        </Grid>
                      )}
                    </>
                  ))}
              </>
            )}

            <Grid container item sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Or upload files
              </Typography>
            </Grid>

            <Grid container item>
              <Uploader uploaded={uploadedFiles} onChange={setUploadedFiles} />
            </Grid>
          </Grid>
        )}
        {tab === 1 && type === 'folder' && (
          <>
            {!folderType && (
              <Grid container>
                <Grid container>
                  <Grid container item xs={6} sx={{ p: 2 }}>
                    <Button
                      startIcon={<DocIcon style={{ fontSize: '2.5rem' }} />}
                      sx={{ fontSize: 22, textTransform: 'none', color: grey[500] }}
                      fullWidth
                      onClick={() => setFolderType('DL')}
                    >
                      Document library
                    </Button>
                  </Grid>

                  <Grid container item xs={6} sx={{ p: 2 }}>
                    <Button
                      startIcon={<PortalIcon style={{ fontSize: '2.5rem' }} />}
                      sx={{ fontSize: 22, textTransform: 'none', color: grey[500] }}
                      fullWidth
                      onClick={() => setFolderType('portal')}
                    >
                      Portal
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {folderType === 'DL' && (
              <Grid container>
                {loadingDlFolders ? (
                  <Grid container justifyContent="center" alignItems="center" sx={{ height: '50vh' }}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid container>
                    {selectedDlFolder && (
                      <Grid container sx={{ mb: 1 }} alignItems="center">
                        <Typography>You selected: </Typography>
                        <Chip
                          color="primary"
                          label={selectedDlFolder.name}
                          sx={{ ml: 2 }}
                          onDelete={() => setSelectedDlFolder(null)}
                        />
                      </Grid>
                    )}
                    {isArray(foldersTree) && !isEmpty(foldersTree) ? (
                      <TreeView
                        style={{ minHeight: 200 }}
                        defaultCollapseIcon={<ExpandMoreRounded />}
                        defaultExpandIcon={<ChevronRightRounded />}
                        sx={{
                          width: '100%',
                          '& .MuiTreeItem-content': { borderRadius: 0.5 },
                        }}
                      >
                        {foldersTree.map((node) => (
                          <TreeItem
                            key={node._id}
                            nodeId={node._id}
                            label={node.name}
                            endIcon={<FolderRounded />}
                            onClick={() => setSelectedDlFolder({ _id: node._id, name: node.name, isArea: !node.area })}
                          >
                            {isArray(node.children) && !isEmpty(node.children)
                              ? node.children.map((node) => renderTree(node))
                              : null}
                          </TreeItem>
                        ))}
                      </TreeView>
                    ) : (
                      <Grid container justifyContent="center" alignItems="center" sx={{ height: '50vh' }}>
                        <Typography color="textSecondary">No folders available</Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            )}

            {folderType === 'portal' && (
              <>
                {noSpaces ? (
                  <Grid container item sx={{ mt: 2 }}>
                    <Typography color={'text.secondary'}>
                      Looks like you don't have any global folders in portal
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid container item sx={{ mt: 2 }}>
                      <Autocomplete
                        options={globalPortalFolders}
                        fullWidth
                        isOptionEqualToValue={(option, value) => option?._id === value?._id}
                        getOptionLabel={(option) => (option ? option.title : '')}
                        openOnFocus
                        value={selectedGlobalFolder}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            <Grid container alignItems="center">
                              <Typography noWrap>{option.title}</Typography>
                            </Grid>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            label="Select a global folder"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadPortalSpaces ? <CircularProgress color="primary" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                        onChange={(e, value) => {
                          setSelectedGlobalFolder(value);
                        }}
                        open={globalFoldersOpen}
                        onOpen={() => setGlobalFoldersOpen(true)}
                        onClose={() => setGlobalFoldersOpen(false)}
                        loading={loadPortalSpaces}
                      />
                    </Grid>
                    {selectedGlobalFolder && (
                      <>
                        <Grid container item sx={{ mt: 1 }}>
                          <Typography color={'text.secondary'} sx={{ fontSize: '0.9rem' }}>
                            When you pick a global folder, it will show the user all the files within the folder. You
                            can either chose they say the files stored immediately in the folder. Or pick to show them a
                            list of the sub folders. This is great for separating document groups to sign
                          </Typography>
                        </Grid>
                        <Grid container item sx={{ mt: 1 }}>
                          <RadioGroup value={showFilesFrom} onChange={(e) => setShowFilesFrom(e.target.value)}>
                            <FormControlLabel label="Show files" value="files" control={<Radio />} />
                            <FormControlLabel label="Show list of sub folders" value="subFolders" control={<Radio />} />
                          </RadioGroup>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {tab === 2 && (
          <Grid container>
            {type === 'docs' && (
              <>
                <Grid container item>
                  <Typography gutterBottom sx={{ fontWeight: 500 }}>
                    You selected
                  </Typography>
                </Grid>

                <Grid container item spacing={1}>
                  {files.map((file) => (
                    <Grid item key={file.id}>
                      <Chip
                        label={file.originalname || file.title}
                        color="primary"
                        onDelete={() => {
                          setPreviouslySelected(previouslySelected.filter((x) => x.id !== file.id));
                          setSelectedDocs(selectedDocs.filter((x) => x._id !== file.id));
                          setUploadedFiles(uploadedFiles.filter((x) => x.id !== file.id));
                          setSelectedFilesFromFolder(selectedFilesFromPortal.filter((x) => x.id !== file.id));
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {type === 'folder' && folderType === 'DL' && selectedDlFolder && (
              <Grid container sx={{ mb: 1 }} alignItems="center">
                <Typography>You selected: </Typography>
                <Chip color="primary" label={selectedDlFolder.name} sx={{ ml: 2 }} />
              </Grid>
            )}

            {type === 'folder' && folderType === 'portal' && selectedGlobalFolder && (
              <Grid container sx={{ mb: 1 }} alignItems="center">
                <Typography>You selected: </Typography>
                <Chip color="primary" label={selectedGlobalFolder.title} sx={{ ml: 2 }} />
              </Grid>
            )}

            <Grid container item sx={{ mt: 2 }}>
              <Typography gutterBottom sx={{ fontWeight: 500 }}>
                Do you need a signature at the end?
              </Typography>
            </Grid>

            <Grid container item>
              <RadioGroup value={signatureRequired} onChange={(e) => setSignatureRequired(e.target.value)}>
                <FormControlLabel label="Yes" value="yes" control={<Radio />} />
                <FormControlLabel label="No" value="no" control={<Radio />} />
              </RadioGroup>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        hideDone={!ready || tab === 0}
        doneButtonText={tab === 1 ? 'next' : 'done'}
        additionalActions={
          tab > 0 && (
            <Button startIcon={<KeyboardArrowLeftRounded />} sx={{ color: grey[500], mr: 'auto' }} onClick={back}>
              back
            </Button>
          )
        }
      />
    </RoundedDialog>
  );
}
