import { useState, useEffect, useRef } from 'react';
import { InputBase } from '@mui/material';
import { isFunction, isString, isNumber } from 'lodash';

export default function DebouncedInput({ value, onValueChange, debounceMs, ...props }) {
  const resultTimer = useRef(null);
  const lastResults = useRef([]);
  const [text, setText] = useState('');
  const DEBOUNCE_MS = isNumber(debounceMs) ? debounceMs : 1000;

  function activateTimer(res) {
    setText(res);

    if (!res) {
      sendResult(res);
      return;
    }

    if (resultTimer.current != null) {
      clearTimeout(resultTimer.current);
    }

    resultTimer.current = setTimeout(() => sendResult(res), DEBOUNCE_MS);
  }

  function sendResult(res) {
    // store the last 3 sent results
    const resultsToStore = 3;
    lastResults.current.push(res);
    lastResults.current = lastResults.current.slice(-resultsToStore);
    isFunction(onValueChange) && onValueChange(res);
  }

  useEffect(() => {
    if ((!isString(value) && !isNumber(value)) || lastResults.current.includes(value)) return;

    setText(value);
  }, [value]);

  return <InputBase value={text} onChange={(e) => activateTimer(e.target.value)} {...props} />;
}
