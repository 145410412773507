import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { zeroPad } from '../utils';

const separatorMap = {
  '-': '-',
  _: '_',
  '/': '/',
  '.': '.',
  Space: ' ',
  None: '',
};

const useDocumentName = ({ setId, sequence, initial, title }) => {
  const { sets } = useSelector(({ sets }) => sets);
  const { conventionSets } = useSelector(({ settings }) => settings);

  const [fields, setFields] = useState([]);
  const [params, setParams] = useState([]);
  const [separator, setSeparator] = useState('_');

  useEffect(() => {
    if (!initial) return;
    setParams(initial || []);
  }, [initial]);

  useEffect(() => {
    if (!sets) return;
    const set = sets.find((set) => set._id === setId);
    setFields(set?.fields || []);
    setSeparator(set?.separator || '_');
  }, [setId, sets]);

  if (!conventionSets) return title;

  if (!setId) return title;

  if (!fields || fields.length === 0) return title;

  const getField = (field) => {
    const value = params?.find((x) => x.id === field.id)?.value;
    if (field.fieldType === 'autonumber') {
      if (!sequence) {
        return '0';
      }
      return `${zeroPad(sequence, field?.prefix?.length + 1)}`;
    }
    if (field.fieldType === 'text') {
      return field.title;
    }
    if (field.fieldType === 'number') {
      return value ? `${zeroPad(value, field?.prefix?.length + 1)}` : '~';
    }
    return value ? value?.toString() : '~';
  };
  return fields?.map((field) => getField(field)).join(separatorMap[separator]);
};

export default useDocumentName;
