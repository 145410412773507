import React, { useEffect, useState } from 'react';
import { DialogContent, CircularProgress, Grid, DialogTitle, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import { Alert } from '@mui/material';
import axios from 'axios';

export default function Confirmation({}) {
  const [error, setError] = useState(null);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [savingPassword, setSavingPassword] = useState(false);
  const [passwordChangeRequired, setPasswordResetRequired] = useState(false);
  const [userName, setUserName] = useState(false);
  const { userId, resetId } = useParams();

  useEffect(() => {
    if (!resetId || !userId) return;
    axios
      .post('/auth/findResetLink', { userId, resetId })
      .then(({ data }) => {
        setPasswordResetRequired(data.passwordChangeRequired);
        setPasswordDialog(data.passwordRequired);
        setUserName(data.userName);
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Something went wrong');
      });
  }, [userId, resetId]);

  function savePassword() {
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }
    setSavingPassword(true);
    setError('');
    axios
      .post('/auth/confirmPasswordReset', { userId, resetId, password })
      .then((res) => {
        if (res.data.authentication) {
          localStorage.clear();
          localStorage.setItem('token', res.data.authentication.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authentication.name);
          localStorage.setItem('_id', res.data.authentication.id);
          localStorage.setItem('company', res.data.authentication.company);
          localStorage.setItem('access', res.data.authentication.access);
          window.location.href = '/home';
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Something went wrong');
        setTimeout(() => setError(''), 8000);
        setSavingPassword(false);
      });
  }

  if (passwordDialog) {
    return (
      <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
        <DialogTitle sx={{ whiteSpace: 'break-spaces' }}>
          {passwordChangeRequired
            ? `Hi ${userName}, \n\nYou need to change your password before proceeding`
            : 'Set password'}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid container item sx={{ mt: 2 }}>
              <TextField
                type="password"
                variant="outlined"
                fullWidth
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid container item sx={{ mt: 2 }}>
              <TextField
                type="password"
                variant="outlined"
                error={confirmPasswordError}
                helperText={confirmPasswordError ? 'Passwords do not match' : ''}
                fullWidth
                label="Confirm password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError(false);
                }}
              />
            </Grid>
          </Grid>
          {error && (
            <Alert severity="error" style={{ width: '100%', marginTop: 8, borderRadius: 8 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <StandardDialogActions saving={savingPassword} onDone={savePassword} disableDone={!password} />
      </RoundedDialog>
    );
  }

  if (error) {
    return (
      <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Alert severity="error" style={{ width: '100%', marginBottom: 16, borderRadius: 8 }}>
            {error}
          </Alert>
        </DialogContent>
      </RoundedDialog>
    );
  }

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
      <CircularProgress color="primary" />
    </Grid>
  );
}
