import React, { useState } from 'react';
import moment from 'moment';
import { RoundedDialog } from '../../Global/Components';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Avatar,
  Divider,
  Chip,
} from '@mui/material';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import * as Ticks from './ticks';
import UserMap from '../UserMap';
import { Typography, Link, Grid } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LinkIcon from '@mui/icons-material/Link';
import { DocIcon, FormIcon, AuditIcon } from '../../Global/Icons';
import { WorkOutline } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReplyIcon from '@mui/icons-material/Reply';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { FormatDate } from '../../Global/Functions';
import { blue, grey } from '@mui/material/colors';

const userId = localStorage.getItem('_id');

const emojiRegex =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

function isEmojis(str, maxLength) {
  let clearedLength = str.replace(emojiRegex, '').trim().length;
  return clearedLength === 0 && str.length <= maxLength * 2;
}

function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default function Message(props) {
  const [open, setOpen] = useState(false); // for message info dialog
  const [src, setSrc] = useState(null); // for picture
  const [hovered, setHovered] = useState(false);
  const [menu, setMenu] = useState(null);

  var fromStr = '';
  if (props.chat.type !== 'private' && props.message.sender !== userId) {
    let participant = UserMap.resolve(props.message.sender);
    if (participant) fromStr = participant.fullName;
  }

  // for notifications
  if (props.message.type === 'notification') {
    return (
      <Grid container sx={{ py: 2 }} justifyContent={'center'}>
        <Chip label={props.message.text} />
      </Grid>
    );
  }
  const isMine = props.message.sender === userId;

  var tick = null;
  if (isMine) {
    if (props.message.failed) tick = Ticks.failed;
    else if (props.message.sent === false) tick = Ticks.sending;
    else if (props.message.receipts && props.message.receipts[0]) tick = Ticks.read;
    else if (
      (props.message.sent || props.message.sent == null) &&
      (!props.message.receipts || !props.message.receipts[0])
    )
      tick = Ticks.sent;
  }

  return (
    <Grid container>
      {props.message.firstUnread === true && ( // "New messages" divider
        <Grid container justifyContent={'center'}>
          <Chip label="New messages" />
        </Grid>
      )}
      <Grid container sx={{ p: 2 }} justifyContent={isMine ? 'flex-end' : 'flex-start'}>
        {!isMine && (
          <Grid
            sx={{
              width: 0,
              height: 0,
              borderTop: (theme) => `8px solid ${theme.palette.mode === 'dark' ? grey[900] : grey[200]}`,
              borderLeft: '8px solid transparent',
            }}
          />
        )}
        <Grid
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          sx={{
            background: (theme) =>
              theme.palette.mode === 'dark' ? (isMine ? blue[800] : grey[900]) : isMine ? blue[200] : grey[200],
            borderRadius: 1,
            p: 1,
            width: 'fit-content',
            maxWidth: '75%',
            borderTopLeftRadius: isMine ? '' : 0,
            borderTopRightRadius: isMine ? 0 : '',
          }}
        >
          {props.message.reply && (
            <Grid
              container
              item
              alignItems="center"
              sx={{
                background: (theme) => (theme.palette.mode === 'dark' ? blue[900] : blue[400]),
                borderRadius: 0.5,
              }}
            >
              <ReplyIcon sx={{ mx: 1 }} />
              <Grid item sx={{ p: 1 }}>
                <Grid item container>
                  <Typography style={{ fontWeight: 600, fontSize: 12 }}>
                    {UserMap.resolve(props.message.reply.sender)?.firstName}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography noWrap style={{ fontSize: 12, maxWidth: 200 }}>
                    {props.message.reply.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {props.message.attachments &&
            props.message.attachments
              .filter((f) => f.type && f.type.startsWith('image/'))
              .map((file) => (
                <div key={file.id} style={{ marginBottom: 5 }}>
                  <img
                    src={file.location}
                    alt={file.originalname}
                    style={{ maxWidth: '100%', cursor: props.clickable ? 'pointer' : '' }}
                    onClick={props.clickable ? () => setSrc(file.location) : null}
                  />
                </div>
              ))}
          {props.message.attachments &&
            props.clickable &&
            props.message.attachments
              .filter((f) => f.type && !f.type.startsWith('image/'))
              .map((file, i) => (
                <div>
                  <ImageList cellHeight={140}>
                    {file.link ? <LinkTile key={file.id + i} file={file} /> : <Tile key={file.id + i} file={file} />}
                  </ImageList>
                </div>
              ))}
          <div>
            <div>
              {fromStr && <Typography sx={{ fontWeight: 600, fontSize: 12 }}>{`${fromStr}:`}</Typography>}

              <Typography
                sx={{
                  fontSize: !(props.message.attachments?.length > 1) && isEmojis(props.message.text, 5) ? 40 : 14,
                  m: 0.5,
                  whiteSpace: 'break-spaces',
                }}
              >
                {props.message.text}
              </Typography>
            </div>
            {props.message.type === 'full' ? (
              <div style={{ display: 'flex', width: 'fit-content', marginLeft: 'auto', height: 24 }}>
                {props.message.createdAt && (
                  <Typography color="text.secondary" sx={{ fontSize: 11 }}>
                    {FormatDate(props.message.createdAt)}
                  </Typography>
                )}
                {tick && <div style={{ marginLeft: '0.5em', marginTop: 'auto' }}>{tick}</div>}
                {(hovered || menu) && props.clickable && (
                  <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={(e) => setMenu(e.currentTarget)} />
                )}
              </div>
            ) : (
              <div style={{ fontSize: '11px' }}>typing...</div>
            )}
          </div>
        </Grid>
        {isMine && (
          <Grid
            sx={{
              width: 0,
              height: 0,
              borderTop: (theme) => `8px solid ${theme.palette.mode === 'dark' ? blue[800] : blue[200]}`,
              borderRight: '8px solid transparent',
            }}
          />
        )}
      </Grid>
      {props.clickable && isMine && (
        <MessageInfo onClose={() => setOpen(false)} open={open} chat={props.chat} message={props.message} />
      )}
      <ImgDialog open={Boolean(src)} onClose={() => setSrc(null)} src={src} />
      <Menu anchorEl={menu} open={Boolean(menu)} onClose={() => setMenu(null)} keepMounted>
        <MenuItem
          onClick={() => {
            setMenu(null);
            props.onReply();
          }}
        >
          <ListItemIcon>
            <ReplyIcon />
          </ListItemIcon>
          <Typography>Reply</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenu(null);
            props.onDelete();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlined style={{ color: '#f44336' }} />
          </ListItemIcon>
          <Typography>Delete</Typography>
        </MenuItem>
        {isMine && (
          <MenuItem
            onClick={() => {
              setMenu(null);
              setOpen(true);
            }}
          >
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <Typography>Info</Typography>
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
}

function MessageInfo(props) {
  const { onClose } = props;

  var remaining = props.chat.participants.length - 1;

  for (var i = 0; i < props.message.receipts.length; ++i) {
    // eslint-disable-next-line
    if (props.chat.participants.find((x) => x === props.message.receipts[i].readBy)) --remaining;
  }

  // with "at"
  function FormatDate(date) {
    const dt = moment(date);
    var time = dt.format('hh:mm A');
    if (dt.isSame(moment(), 'day')) {
      // if today
      return `today at ${time}`;
    } else if (dt.isSame(moment().subtract(1, 'days'), 'day')) {
      // if yesterday
      return `yesterday at ${time}`;
    } else if (dt.isSame(moment(), 'year')) {
      // if this year
      return `${dt.format('D MMM')} at ${time}`;
    } else {
      return `${dt.format('D-MMM-YY')} at ${time}`; // if not this year
    }
  }

  var readAt = '-';
  if (props.chat.type === 'private' && props.message.receipts.length === 1) {
    readAt = FormatDate(props.message.receipts[0].readAt);
  }

  return (
    <RoundedDialog maxWidth="xs" fullWidth open={props.open} onClose={() => onClose(null)}>
      <DialogTitle>Message Info</DialogTitle>
      <DialogContent dividers>
        <div style={{ width: '100%', backgroundColor: '#8ab3ff' }}>
          <Message chat={props.chat} message={props.message} key={props.message.id} clickable={false} />
        </div>
        {props.chat.type === 'private' ? (
          <div style={{ paddingTop: '0.5em', paddingBottom: '0.5em', fontSize: '18px' }}>
            <span>{`Read: ${readAt}`}</span>
          </div>
        ) : (
          <div>
            <div style={{ paddingTop: '0.5em', paddingBottom: '0.5em', fontSize: '18px' }}>
              <span style={{ marginRight: '0.5em' }}>Read by:</span>
              <span style={{ marginLeft: 'auto' }}>{remaining > 0 && `(${remaining} remaining)`}</span>
            </div>
            <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
              {props.message.receipts.map((receipt, i) => (
                <React.Fragment key={i}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={UserMap.resolve(receipt.readBy)?.avatar}>
                        {UserMap.resolve(receipt.readBy)?.avatarLetters}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={UserMap.resolve(receipt.readBy)?.fullName}
                      secondary={FormatDate(receipt.readAt)}
                    />
                  </ListItem>
                  {i !== props.message.receipts.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(null)} style={{ color: '#1e88e5' }}>
          Close
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}

function ImgDialog({ open, onClose, src }) {
  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <div style={{ display: 'flex' }}>
        <img alt="pic" src={src} style={{ maxHeight: '80vh', maxWidth: '80vw' }} />
      </div>
    </Dialog>
  );
}

function Tile({ file }) {
  const width = 200;
  const height = 140;

  return (
    <ImageListItem
      style={{
        height,
        width,
        backgroundColor: '#fcfcfc',
        margin: '0.5rem',
        boxShadow: '#a1a1a1 0px 0px 3px 1px',
        borderRadius: '0.2rem',
      }}
    >
      {(() => {
        if (file.type === 'application/pdf') {
          return (
            <Link color="inherit" href={file.location} target="_blank">
              <div style={{ width, height, display: 'flex' }}>
                <PictureAsPdfIcon style={{ margin: 'auto' }} />
              </div>
            </Link>
          );
        } else {
          return (
            <Link color="inherit" href={file.location} target="_blank">
              <Typography style={{ margin: '1rem', userSelect: 'none' }}>{file.originalname}</Typography>
            </Link>
          );
        }
      })()}
      <ImageListItemBar
        title={file.originalname}
        subtitle={formatBytes(file.size)}
        actionIcon={
          <IconButton onClick={() => window.open(file.location, '_blank')}>
            <GetAppIcon style={{ color: 'white' }} />
          </IconButton>
        }
      />
    </ImageListItem>
  );
}

function LinkTile({ file }) {
  const width = 200;
  const height = 140;
  return (
    <ImageListItem
      style={{
        height,
        width,
        backgroundColor: '#fcfcfc',
        margin: '0.5rem',
        boxShadow: '#a1a1a1 0px 0px 3px 1px',
        borderRadius: '0.2rem',
      }}
    >
      {file.hub === 'form' && (
        <Grid container style={{ width, height, padding: '1rem 0.5rem' }}>
          <Link
            color="inherit"
            href={file.link}
            target="_blank"
            onClick={() => {
              localStorage.setItem('formID', file.form);
              localStorage.setItem('previewId', file.id);
            }}
          >
            <Grid container item alignItems="center" justifyContent="center">
              <FormIcon />
              <Typography style={{ marginLeft: '0.5em' }}>{file.originalname}</Typography>
            </Grid>
          </Link>
        </Grid>
      )}
      {file.hub !== 'form' && (
        <Grid container style={{ width, height, padding: '1rem 0.5rem' }}>
          <Link color="inherit" href={file.link} target="_blank">
            <Grid container item alignItems="center" justifyContent="center">
              {(() => {
                if (file.hub === 'doc') return <DocIcon />;
                if (file.hub === 'auditTemplate') return <WorkOutline style={{ color: '#00bfa5' }} />;
                if (file.hub === 'audit') return <AuditIcon />;
                return <LinkIcon />;
              })()}
              <Typography style={{ marginLeft: '0.5em' }}>{file.originalname}</Typography>
            </Grid>
          </Link>
        </Grid>
      )}
    </ImageListItem>
  );
}
