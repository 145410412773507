import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Box,
  TextField,
  Collapse,
  LinearProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';

import AddIcon from '@mui/icons-material/Add';
import { Delete, Image, Settings, TextFields } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import imageCompression from 'browser-image-compression';
import { RichTextField, TooltipIconButton } from '../../../../../../Global/Components';
import { v4 } from 'uuid';
import { uploadImage } from '../../../../redux/actions';
import StateManager from '../../../../../../Global/StateManager';
import { getDecryptedFile } from '../../../../redux/actions/file';

const IMAGE_LAYOUTS = [
  {
    value: 'gallery',
    label: 'Image Row',
  },
  {
    value: 'image-left',
    label: 'Image with text right',
  },
  {
    value: 'image-right',
    label: 'Image with text left',
  },
];

const UploadButton = ({ id, onClick }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 10,
        p: 2,
      }}
    >
      <Box component="input" sx={{ display: 'none' }} id={id} type="file" onChange={onClick} />
      <label htmlFor={id}>
        <IconButton component="span">
          <CloudUploadIcon color="primary" sx={{ height: 50, width: 50 }} />
        </IconButton>
      </label>
    </Box>
  );
};

const ImageTile = ({ image, editable, isCaptionEnabled, handleImageClick, onCaptionChange, onRemove }) => {
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [src, setSrc] = useState(null);

  const handleImagePicked = async (event) => {
    const file = event.target.files[0];
    const size = (file.size / 1024 / 1024).toFixed(2);
    if (size > 3) {
      StateManager.setErrorAlert('Image is too large, pelase upload smaller image');
    } else {
      setUploading(true);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      const data = await uploadImage(compressedFile, (p) => setProgress(p), true);
      if (data && data.file) {
        setUploading(false);
        setProgress(100);
        handleImageClick(data.file);
        return;
      }
      setProgress(100);
      setUploading(false);
      StateManager.setErrorAlert('There was an error uploading the image');
      return null;
    }
  };

  const fileLoad = useCallback(async () => {
    if (!image || !image.fileId || !image.fileKey || !image.location || !image.fileName) return;
    const { fileId, fileKey, fileName, location } = image;
    const decrypted = await getDecryptedFile({
      encrypted: true,
      file: {
        id: fileId,
        location,
        originalname: fileName,
        key: fileKey,
      },
    });
    setSrc(decrypted);
  }, [image]);

  useEffect(() => {
    fileLoad();
  }, [fileLoad]);

  return (
    <ImageListItem
      sx={{
        p: 2,
        minHeight: 250,
        textAlign: 'center',
        ...(editable && {
          border: '1px solid #ccc',
          borderRadius: 1,
        }),
      }}
    >
      {uploading && <LinearProgress variant="determinate" value={progress} />}
      {src ? (
        <img src={src} alt={image.caption} loading="lazy" style={{ objectFit: 'contain' }} />
      ) : (
        <Box sx={{ height: '100%', minHeight: 200 }} />
      )}
      {editable && <UploadButton id={v4()} onClick={handleImagePicked} />}
      {editable && (
        <IconButton
          color="error"
          onClick={onRemove}
          sx={{ position: 'absolute', bottom: isCaptionEnabled ? 30 : 0, right: 0, p: 4 }}
        >
          <Delete />
        </IconButton>
      )}
      {isCaptionEnabled && !editable && <ImageListItemBar title={image.caption} position="below" />}
      {editable && isCaptionEnabled && (
        <TextField
          value={image.caption}
          onChange={(event) => onCaptionChange(event)}
          size="small"
          fullWidth
          variant="standard"
        />
      )}
    </ImageListItem>
  );
};

const GalleryTiles = ({ initial, editable, onUpdate }) => {
  const [images, setImages] = useState([]);
  const [isCaption, setIsCaption] = useState(false);

  const nonEditable = images?.filter((x) => x.location);
  const cols = editable ? images?.length : nonEditable?.length;

  useEffect(() => {
    setImages(initial?.data || []);
    setIsCaption(initial?.allowCaption);
  }, [initial]);

  const onImagePicked = (file, index) => {
    const newImages = [...images];
    newImages[index] = {
      caption: newImages[index]?.caption,
      text: '',
      location: file.location,
      fileId: file.id,
      fileKey: file.key,
      fileName: file.originalname,
    };
    setImages(newImages);
    onUpdate(newImages);
  };

  const onDelete = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    onUpdate(newImages);
  };

  const onCaptionChange = (event, index) => {
    const newImages = [...images];
    newImages[index].caption = event.target.value;
    setImages(newImages);
  };

  const onAddImage = () => {
    if (images.length >= 5) return;
    const newImages = [
      ...images,
      {
        location: '',
        caption: '',
        text: '',
      },
    ];
    setImages(newImages);
    onUpdate(newImages);
  };

  return (
    <>
      <ImageList
        cols={cols}
        sx={{
          height: '100%',
          textAlign: 'center',
          width: '100%',
          minHeight: images?.length === 1 ? 350 : 200,
          maxWidth: images?.length === 1 ? 700 : '100%',
          mx: images?.length === 1 ? 'auto' : '50px',
        }}
      >
        {images
          ?.filter((x) => (editable ? x : x.location))
          ?.map((image, index) => (
            <ImageTile
              key={index}
              image={image}
              isCaptionEnabled={isCaption}
              handleImageClick={(d) => onImagePicked(d, index)}
              onCaptionChange={(event) => onCaptionChange(event, index)}
              onRemove={() => onDelete(index)}
              editable={editable}
            />
          ))}
      </ImageList>
      {images?.length < 5 && editable && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            my: 2,
          }}
        >
          <IconButton sx={{ bgcolor: 'rgba(0,0,0,0.1)' }} onClick={onAddImage}>
            <AddIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

const ImageWithText = ({ image, isCaptionEnabled, onUpdate, position, editable }) => {
  const onFilePicked = (file) => {
    onUpdate({
      ...image,
      location: file.location,
      fileId: file.id,
      fileKey: file.key,
      fileName: file.originalname,
    });
  };

  const onTextUpdate = (e) => {
    onUpdate({
      ...image,
      text: e,
    });
  };

  const onRemove = () => {
    onUpdate({
      location: '',
      caption: '',
      text: '',
    });
  };

  const onCaptionUpdate = (event) =>
    onUpdate({
      ...image,
      caption: event.target.value,
    });

  return (
    <Grid
      container
      justifyContent={'center'}
      direction="row"
      marginLeft={0}
      marginRight={0}
      columnSpacing={2}
      sx={{ mx: editable ? '20px' : '0px', my: 5 }}
    >
      <Grid item xs={6} sm={6} order={position === 'image-left' ? 1 : 2}>
        <ImageTile
          image={image}
          isCaptionEnabled={isCaptionEnabled}
          handleImageClick={(d) => onFilePicked(d)}
          onCaptionChange={onCaptionUpdate}
          onRemove={onRemove}
          editable={editable}
        />
      </Grid>
      <Grid item xs={6} sm={6} order={position === 'image-left' ? 2 : 1}>
        <RichTextField initial={image?.text} editable={editable} onChange={onTextUpdate} sx={{ py: 0 }} />
      </Grid>
    </Grid>
  );
};

export const ImageBlock = ({ initial, onSave, onRemove, editable }) => {
  const [imageData, setImageData] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!initial) return;
    setImageData(initial);
  }, [initial]);

  const onSettingSave = (setting, e) => {
    let update = {
      ...imageData,
      [setting]: e,
    };
    setImageData(update);
    onSave(update); // add a timeout to save
  };

  const onDataSave = (e) => {
    let update = {
      ...imageData,
      data: e,
    };
    setImageData(update);
    onSave(update); // add a timeout to save
  };

  return (
    <Grid container>
      {editable && (
        <Grid item xs={12} container justifyContent="flex-end" gap={2} marginBottom={2} sx={{ mx: '30px' }}>
          <TooltipIconButton
            text="Change Settings"
            onClick={() => setOpen(!open)}
            sx={{
              color: grey[500],
              '&:hover': {
                color: 'red',
              },
            }}
          >
            <Settings />
          </TooltipIconButton>
          <TooltipIconButton
            text="Delete Section"
            onClick={onRemove}
            sx={{
              color: grey[500],
              '&:hover': {
                color: 'red',
              },
            }}
          >
            <Delete />
          </TooltipIconButton>
        </Grid>
      )}
      {editable && (
        <Collapse
          in={open}
          sx={{
            width: '100%',
            backgroundColor: (t) => (t.palette.mode === 'dark' ? 'grey' : '#f0f0f0'),
          }}
        >
          <Grid item container alignItems="center" justifyContent={'space-between'} p={3}>
            {IMAGE_LAYOUTS.map((layout, index) => (
              <Grid
                item
                sm={3}
                sx={{
                  borderRadius: 2,
                  height: '100%',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: `0 0 3px 2px ${blue[500]}`,
                  },
                  boxShadow: (imageData?.layout || 'gallery') === layout.value ? `0 0 5px 2px ${blue[500]}` : 'none',
                }}
                key={index}
                gap={2}
                p={2}
                onClick={() => onSettingSave('layout', layout.value)}
              >
                <Typography align="center" sx={{ color: (t) => t.palette.getContrastText('#fff') }}>
                  {layout.label}
                </Typography>
                <Grid item container sx={{ my: 2 }} alignItems="center" justifyContent={'center'} gap={2}>
                  {layout.value === 'image-right' && <TextFields sx={{ height: 36, width: 36, color: blue[500] }} />}
                  <Image sx={{ height: 36, width: 36, color: blue[500] }} />
                  {layout.value === 'image-left' && <TextFields sx={{ height: 36, width: 36, color: blue[500] }} />}
                  {layout.value === 'gallery' && <Image sx={{ height: 36, width: 36, color: blue[500] }} />}
                  {layout.value === 'gallery' && <Image sx={{ height: 36, width: 36, color: blue[500] }} />}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sx={{ mx: '70px', my: 1 }} align="center">
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(imageData?.allowCaption)}
                    onChange={(e) => onSettingSave('allowCaption', e.target.checked)}
                    name="allowCaption"
                  />
                }
                labelPlacement="start"
                label="Add captions below the images"
              />
            </FormControl>
          </Grid>
        </Collapse>
      )}
      {imageData?.layout === 'gallery' && (
        <GalleryTiles initial={imageData} onUpdate={(d) => onDataSave(d)} editable={editable} />
      )}
      {imageData?.layout !== 'gallery' && (
        <ImageWithText
          image={imageData?.data[0]}
          isCaptionEnabled={imageData?.allowCaption}
          onUpdate={(e) => onDataSave([e])}
          position={imageData?.layout}
          editable={editable}
        />
      )}
    </Grid>
  );
};

export default ImageBlock;
