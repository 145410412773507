export const FETCHCOMPANY_ATTEMPT = 'FETCHCOMPANY_ATTEMPT';
export const FETCHCOMPANY_SUCCESS = 'FETCHCOMPANY_SUCCESS';
export const FETCHCOMPANY_FAIL = 'FETCHCOMPANY_FAIL';

export const SETCOMPANY = 'SETCOMPANY';

export const UPDATECOMPANY_ATTEMPT = 'UPDATECOMPANY_ATTEMPT';
export const UPDATECOMPANY_SUCCESS = 'UPDATECOMPANY_SUCCESS';
export const UPDATECOMPANY_FAIL = 'UPDATECOMPANY_FAIL';

export const GENERATECREDENTIALS_ATTEMPT = 'GENERATECREDENTIALS_ATTEMPT';
export const GENERATECREDENTIALS_SUCCESS = 'GENERATECREDENTIALS_SUCCESS';
export const GENERATECREDENTIALS_FAIL = 'GENERATECREDENTIALS_FAIL';

export const UPLOADLOGO_ATTEMPT = 'UPLOADLOGO_ATTEMPT';
export const UPLOADLOGO_SUCCESS = 'UPLOADLOGO_SUCCESS';
export const UPLOADLOGO_FAIL = 'UPLOADLOGO_FAIL';

export const SETUSER = 'SETUSER';
export const SETGROUPUSERS = 'SETGROUPUSERS';
export const SETGROUP = 'SETGROUP';

export const ADDUSER_ATTEMPT = 'ADDUSER_ATTEMPT';
export const ADDUSER_SUCCESS = 'ADDUSER_SUCCESS';
export const ADDUSER_FAIL = 'ADDUSER_FAIL';

export const UPDATEUSER_ATTEMPT = 'UPDATEUSER_ATTEMPT';
export const UPDATEUSER_SUCCESS = 'UPDATEUSER_SUCCESS';
export const UPDATEUSER_FAIL = 'UPDATEUSER_FAIL';

export const DELETEUSER_ATTEMPT = 'DELETEUSER_ATTEMPT';
export const DELETEUSER_SUCCESS = 'DELETEUSER_SUCCESS';
export const DELETEUSER_FAIL = 'DELETEUSER_FAIL';

export const GETUSERS_ATTEMPT = 'GETUSERS_ATTEMPT';
export const GETUSERS_SUCCESS = 'GETUSERS_SUCCESS';
export const GETUSERS_FAIL = 'GETUSERS_FAIL';

export const ADDGROUP_ATTEMPT = 'ADDGROUP_ATTEMPT';
export const ADDGROUP_SUCCESS = 'ADDGROUP_SUCCESS';
export const ADDGROUP_FAIL = 'ADDGROUP_FAIL';

export const UPDATEGROUP_ATTEMPT = 'UPDATEGROUP_ATTEMPT';
export const UPDATEGROUP_SUCCESS = 'UPDATEGROUP_SUCCESS';
export const UPDATEGROUP_FAIL = 'UPDATEGROUP_FAIL';

export const DELETEGROUP_ATTEMPT = 'DELETEGROUP_ATTEMPT';
export const DELETEGROUP_SUCCESS = 'DELETEGROUP_SUCCESS';
export const DELETEGROUP_FAIL = 'DELETEGROUP_FAIL';

export const FETCHGROUPS_ATTEMPT = 'FETCHGROUPS_ATTEMPT';
export const FETCHGROUPS_SUCCESS = 'FETCHGROUPS_SUCCESS';
export const FETCHGROUPS_FAIL = 'FETCHGROUPS_FAIL';

export const RESETPWD_ATTEMPT = 'RESETPWD_ATTEMPT';
export const RESETPWD_SUCCESS = 'RESETPWD_SUCCESS';
export const RESETPWD_FAIL = 'RESETPWD_FAIL';

export const SETADMINALERT = 'SETADMINALERT';

export const FETCHCARDS_ATTEMPT = 'FETCHCARDS_ATTEMPT';
export const FETCHCARDS_SUCCESS = 'FETCHCARDS_SUCCESS';
export const FETCHCARDS_FAIL = 'FETCHCARDS_FAIL';

export const ADDCARD_ATTEMPT = 'ADDCARD_ATTEMPT';
export const ADDCARD_SUCCESS = 'ADDCARD_SUCCESS';
export const ADDCARD_FAIL = 'ADDCARD_FAIL';

export const UPDATECARD_ATTEMPT = 'UPDATECARD_ATTEMPT';
export const UPDATECARD_SUCCESS = 'UPDATECARD_SUCCESS';
export const UPDATECARD_FAIL = 'UPDATECARD_FAIL';

export const DELETECARD_ATTEMPT = 'DELETECARD_ATTEMPT';
export const DELETECARD_SUCCESS = 'DELETECARD_SUCCESS';
export const DELETECARD_FAIL = 'DELETECARD_FAIL';

export const SETDEFAULTCARD_ATTEMPT = 'SETDEFAULTCARD_ATTEMPT';
export const SETDEFAULTCARD_SUCCESS = 'SETDEFAULTCARD_SUCCESS';
export const SETDEFAULTCARD_FAIL = 'SETDEFAULTCARD_FAIL';

export const CREATEPURCHASE_ATTEMPT = 'CREATEPURCHASE_ATTEMPT';
export const CREATEPURCHASE_SUCCESS = 'CREATEPURCHASE_SUCCESS';
export const CREATEPURCHASE_FAIL = 'CREATEPURCHASE_FAIL';

export const UPDATEPURCHASE_ATTEMPT = 'UPDATEPURCHASE_ATTEMPT';
export const UPDATEPURCHASE_SUCCESS = 'UPDATEPURCHASE_SUCCESS';
export const UPDATEPURCHASE_FAIL = 'UPDATEPURCHASE_FAIL';

export const GETPACKAGES_ATTEMPT = 'GETPACKAGES_ATTEMPT';
export const GETPACKAGES_SUCCESS = 'GETPACKAGES_SUCCESS';
export const GETPACKAGES_FAIL = 'GETPACKAGES_FAIL';

export const FETCHACTIVESUBSCRIPTION_ATTEMPT = 'FETCHACTIVESUBSCRIPTION_ATTEMPT';
export const FETCHACTIVESUBSCRIPTION_SUCCESS = 'FETCHACTIVESUBSCRIPTION_SUCCESS';
export const FETCHACTIVESUBSCRIPTION_FAIL = 'FETCHACTIVESUBSCRIPTION_FAIL';

export const CANCELSUBSCRIPTION_ATTEMPT = 'CANCELSUBSCRIPTION_ATTEMPT';
export const CANCELSUBSCRIPTION_SUCCESS = 'CANCELSUBSCRIPTION_SUCCESS';
export const CANCELSUBSCRIPTION_FAIL = 'CANCELSUBSCRIPTION_FAIL';

export const REACTIVATESUBSCRIPTION_ATTEMPT = 'REACTIVATESUBSCRIPTION_ATTEMPT';
export const REACTIVATESUBSCRIPTION_SUCCESS = 'REACTIVATESUBSCRIPTION_SUCCESS';
export const REACTIVATESUBSCRIPTION_FAIL = 'REACTIVATESUBSCRIPTION_FAIL';
