import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { grey } from '@mui/material/colors';
import PageGridField from '../../../Global/Fields/PageGridField';
import GridLayout from 'react-grid-layout';
import isArray from 'lodash/isArray';
import './page-grid.css';

export default function GridSection({ layout, items, editable, onSave }) {
  const ROW_HEIGHT = 25;
  const RATIO = 297 / 210;

  if (!isArray(layout)) {
    return <Typography>Layout not provided</Typography>;
  }

  if (!isArray(items)) {
    return <Typography>Items not provided</Typography>;
  }

  return (
    <Grid container style={{ border: `1px solid ${grey[500]}` }}>
      <Grid container>
        <AutoSizer disableHeight>
          {({ width }) => (
            <GridLayout
              margin={[0, 0]}
              layout={layout}
              cols={12}
              rowHeight={ROW_HEIGHT}
              width={width}
              maxRows={Math.round((RATIO * width) / 30)}
              style={{ height: Math.round(RATIO * width) }}
              className="no-animation-grid"
              isDraggable={false}
              isResizable={false}
            >
              {items.map((item) => (
                <div key={item.id} data-grid={layout.find((x) => x.i === item.id)}>
                  <Paper
                    variant="outlined"
                    style={{ height: '100%', borderRadius: 0, border: `1px solid ${grey[500]}` }}
                  >
                    <PageGridField
                      item={item}
                      editable={editable}
                      onSave={onSave}
                      layoutConfig={layout.find((x) => x.i === item.id)}
                    />
                  </Paper>
                </div>
              ))}
            </GridLayout>
          )}
        </AutoSizer>
      </Grid>
    </Grid>
  );
}
