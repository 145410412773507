import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser, updateTour } from '../Profile/redux/actions';
import { toggleAssistant } from '../../redux/actions';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { calendlyUrl, logo } from '../../constants';
import Layout from '../Global/Layout';
import Loader from '../Global/Loader';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import LockIcon from '@mui/icons-material/Lock';
import { Check, Duo, Face } from '@mui/icons-material';

function Welcome() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, fetching } = useSelector(({ profile }) => profile);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  let options = [
    {
      title: 'Setup your personal profile',
      action: () => history.push('/profile?start_tour=true'),
      locked: false,
      configured: user && user.tourStatus.profile,
    },
  ];

  if (user && user.isDefault) {
    options = [
      ...options,
      {
        title: 'Setup your company details',
        action: () => history.push('/admin/company?start_tour=true'),
        locked: user && !user.tourStatus.profile,
        configured: user && user.tourStatus.company,
      },
    ];
  }

  // if (user && user.access === 'admin') {
  //   options = [
  //     ...options,
  //     {
  //       title: 'Add more users',
  //       action: () => history.push('/admin/users?start_tour=true'),
  //       locked: user.isDefault ? !user.tourStatus.company : !user.tourStatus.profile,
  //       configured: user && user.tourStatus.users,
  //     },
  //     {
  //       title: 'Create groups',
  //       action: () => history.push('/admin/groups?start_tour=true'),
  //       locked: !user.tourStatus.users,
  //       configured: user && user.tourStatus.groups,
  //     },
  //   ];
  // }

  const skipTour = () => {
    dispatch(updateTour({ skipTour: true }));
    history.push('/home');
  };

  let iconStyles = {
    height: 50,
    width: 50,
  };

  let cardStyles = {
    padding: 15,
    width: 250,
    height: 200,
    marginBottom: 15,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  };

  let nextSteps = [
    {
      title: 'Book a video call with one of our team to talk you through the app',
      alt: 'video',
      icon: <Duo style={{ ...iconStyles, color: '497fb0' }} />,
      action: () => (window.location.href = calendlyUrl),
    },
    {
      title: 'Start learning how to use the app',
      alt: 'assistant',
      icon: <Face style={{ ...iconStyles, color: '#619540' }} />,
      action: () => {
        dispatch(toggleAssistant(true));
        history.push('/home');
      },
    },
  ];

  return (
    <Layout title={'Welcome'}>
      {fetching && <Loader fetching={fetching} />}
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <img src={logo} alt={'logo'} style={{ width: 250, height: 100 }} />
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
          style={{ padding: 10, marginTop: 30, marginBottom: 20 }}
        >
          <Typography variant={'h5'}>Welcome to Qhub, lets help you get set up</Typography>
        </Grid>
        {options && options.length > 0 && (
          <Grid item xs={12} sm={4}>
            <List>
              {options.map((option, index) => (
                <ListItem
                  key={index}
                  style={{
                    marginTop: 10,
                    marginBottom: 15,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar style={{ height: 32, width: 32, backgroundColor: option.configured ? 'green' : 'gray' }}>
                      {index + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={option.title} />
                  <ListItemSecondaryAction>
                    <Button
                      size={'small'}
                      variant={'outlined'}
                      style={{
                        color: option.configured || !option.locked ? 'green' : 'gray',
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                      }}
                      onClick={option.action}
                      disabled={option.locked}
                    >
                      {option.configured ? (
                        <Check style={{ color: 'green' }} />
                      ) : option.locked ? (
                        <LockIcon />
                      ) : (
                        `Let's Go`
                      )}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
        {options && options.every((x) => x.configured) && (
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
            style={{ marginTop: 15, marginBottom: 10 }}
          >
            <Grid item container xs={6} sm={5} justifyContent={'space-around'} alignItems={'center'} direction={'row'}>
              {nextSteps.map((step, index) => (
                <Paper
                  elevation={5}
                  key={index}
                  style={cardStyles}
                  onClick={() => {
                    if (step.action) {
                      step.action();
                    }
                  }}
                >
                  <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={10} xs={12}>
                      {step.icon}
                    </Grid>
                    <Grid item sm={10} xs={12}>
                      <Typography style={{ textAlign: 'center' }}>{step.title}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Grid>
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
          style={{ padding: 10, marginTop: 30, marginBottom: 20 }}
        >
          <Button variant="outlined" style={{ textTransform: 'capitalize' }} onClick={skipTour}>
            Skip Setup
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Welcome;
