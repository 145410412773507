import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, InputBase, Tooltip, IconButton, Button, CircularProgress, Paper } from '@mui/material';
import { Popover, TextField, InputAdornment, Typography, Checkbox, FormControl } from '@mui/material';
import { CardActionArea, FormGroup, FormControlLabel, MenuItem, ButtonGroup } from '@mui/material';
import { HelpOutlineRounded, FileCopyRounded, DeleteOutlined, EditRounded } from '@mui/icons-material';
import { EventRounded, ScheduleRounded, PersonRounded, TextFormatRounded, DragIndicator } from '@mui/icons-material';
import { CloudUploadOutlined, DescriptionRounded } from '@mui/icons-material';
import { PanoramaRounded, FormatSizeRounded, FormatStrikethroughRounded } from '@mui/icons-material';
import { GestureRounded } from '@mui/icons-material';
import { grey, red, blue, purple } from '@mui/material/colors';
import PickFieldTypeDialog from './PickFieldTypeDialog';
import fieldTypes from './FieldTypes';
import EditDataSetDialog from './EditDataSetDialog';
import StateManager from '../StateManager';
import OptionsDialog from './OptionsDialog';
import HelpDialog from './HelpDialog';
import PageGridTableBuilder from './PageGridTableBuilder';
import { RequiredGrey, RequiredIcon } from '../Icons';
import { isEmpty, isArray, maxBy, isNumber, isFunction } from 'lodash';
import { GeneralButton } from '../Components';
import { v4 } from 'uuid';
import axios from 'axios';
import TextStyleDialog from './TextStyleDialog';
import clsx from 'clsx';

const PREFIX = 'PageGridFieldBuilder';

const classes = {
  smallIconButton: `${PREFIX}-smallIconButton`,
  invisible: `${PREFIX}-invisible`,
  visible: `${PREFIX}-visible`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.smallIconButton}`]: {
    height: 32,
    width: 32,
  },

  [`& .${classes.invisible}`]: {
    opacity: 0,
    transition: 'opacity 700ms ease',
  },

  [`& .${classes.visible}`]: {
    opacity: 1,
    transition: 'opacity 700ms ease',
  },
}));

export default function PageGridFieldBuilder({ item, onDuplicate, onEdit, onDelete, onMinSizeChange, layoutConfig }) {
  const timer = useRef();
  const SAVING_TIMEOUT = 800;
  const DEFAULT_COLUMN_WIDTH = 200;

  const [helpText, setHelpText] = useState('');

  const [title, setTitle] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [required, setRequired] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectType, setSelectType] = useState('single');
  const [dataSetParams, setDataSetParams] = useState(null);
  const [tableParams, setTableParams] = useState(null);
  const [text, setText] = useState('');
  const [titleStyle, setTitleStyle] = useState(null);
  const [hovered, setHovered] = useState(false);

  const [picture, setPicture] = useState(null);
  const [uploadingPicture, setUploadingPicture] = useState(false);
  const [fieldtypeOpen, setFieldtypeOpen] = useState(false);
  const [optionsDialogOpen, setOptionsDialogOpen] = useState(false);
  const [dataSetDialog, setDataSetDialog] = useState(false);
  const [helpDialog, setHelpDialog] = useState(false);
  const [titleStyleDialog, setTitleStyleDialog] = useState(false);

  const optionParams = { options, isTickbox: fieldType === 'tickbox', selectType };

  useEffect(() => {
    if (!item) return;
    setTitle(item.title || '');
    setText(item.text || '');
    setRequired(item.required);
    setFieldType(item.fieldType || '');
    setOptions(item.options || []);
    setSelectType(item.selectType || 'single');
    setDataSetParams(item.dataSetParams || null);
    setTableParams(item.table || null);
    setHelpText(item.helpText || '');
    setTitleStyle(item.titleStyle || null);
    setPicture(item.picture || null);
  }, [item]);

  function handleChange(...args) {
    if (!isFunction(onEdit)) return;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => onEdit(...args), SAVING_TIMEOUT);
  }

  function duplicate() {
    if (typeof onDuplicate !== 'function') return;
    onDuplicate(item.id);
  }

  function confirmDelete() {
    if (typeof onDelete !== 'function') return;
    if (title || !isEmpty(options)) {
      StateManager.setConfirm('You are about to delete this field', () => onDelete(item.id));
      return;
    }
    onDelete(item.id);
  }

  function handleFieldType(type) {
    saveFieldType(type);

    if (type === 'dropbox' || type === 'tickbox') {
      setOptionsDialogOpen(true);
    }

    if (type === 'dataSet') {
      setDataSetDialog(true);
    }
  }

  function handleOptions(result) {
    if (isArray(result.options)) {
      saveOptions(result.options);
      if (fieldType === 'tickbox') {
        const max = maxBy(result.options, (item) => String(item.text).length);
        const minW = max ? Math.min(Math.ceil(String(max.text).length / 10), 6) : 2;
        onMinSizeChange({ minW, minH: result.options.length * 2 + 1 });
      }
    }
    if (result.selectType) {
      saveSelectType(result.selectType);
    }
  }

  function saveText(value) {
    setText(value);
    handleChange(item.id, 'text', value);
  }

  function saveDataSetParams(value) {
    setDataSetParams(value);
    handleChange(item.id, 'dataSetParams', value);
  }

  function saveTableParams(value) {
    setTableParams({ ...tableParams, ...value });
    handleChange(item.id, 'table', { ...tableParams, ...value });
  }

  function editOptions() {
    if (fieldType === 'dataSet') {
      setDataSetDialog(true);
    } else {
      setOptionsDialogOpen(true);
    }

    if (fieldType === 'people') {
      setOptionsDialogOpen(true);
    }
  }

  function saveTitle(value) {
    setTitle(value);
    handleChange(item.id, 'title', value);
  }

  function saveFieldType(value) {
    setFieldType(value);
    handleChange(item.id, 'fieldType', value);
  }

  function saveOptions(value) {
    setOptions(value);
    handleChange(item.id, 'options', value);
  }

  function saveSelectType(value) {
    setSelectType(value);
    handleChange(item.id, 'selectType', value);
  }

  function saveDataSetParams(value) {
    setDataSetParams(value);
    handleChange(item.id, 'dataSetParams', value);
  }

  function saveHelpText(text) {
    setHelpText(text);
    handleChange(item.id, 'helpText', text);
  }

  function saveTitleStyle(style) {
    setTitleStyle(style);
    handleChange(item.id, 'titleStyle', style);
  }

  function saveRequired(value) {
    setRequired(value);
    handleChange(item.id, 'required', value);
  }

  function uploadPicture(event) {
    const [file] = event.target.files;
    if (!file || !file.type?.startsWith('image/') || uploadingPicture) return;

    setUploadingPicture(true);
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        const pic = {
          id: v4(),
          name: res.data.file.originalname,
          originalname: res.data.file.originalname,
          location: res.data.file.location,
          size: res.data.file.size,
          type: res.data.file.mimetype,
          mimetype: res.data.file.mimetype,
          bucket: res.data.file.bucket,
        };
        setPicture(pic);
        handleChange(item.id, 'picture', pic);
        setUploadingPicture(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setUploadingPicture(false);
      });
  }

  const short = isNumber(layoutConfig?.h) && layoutConfig?.h < 3;

  if (!item) return null;

  return (
    <StyledGrid
      container
      style={{
        height: '100%',
        position: 'relative',
      }}
      id={item.id}
      alignContent={short ? 'center' : 'flex-start'}
      direction={short ? 'row' : 'column'}
      wrap={short ? 'wrap' : 'nowrap'}
      alignItems={short ? 'center' : 'flex-start'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {required && <RequiredIcon style={{ position: 'absolute', top: -10, left: -10, zIndex: 41 }} />}
      {item.type === 'field' && (fieldType !== 'table' || !tableParams?.hideTitle) && (
        <Grid
          container
          item
          xs={short ? 6 : 12}
          style={{ padding: '3px 0 3px 2%', flex: '0 1 auto' }}
          alignItems="flex-start"
          wrap="nowrap"
        >
          <InputBase
            value={title}
            placeholder="Add a field title"
            onChange={(e) => saveTitle(e.target.value)}
            inputProps={{
              maxLength: 128,
              style: {
                fontSize: titleStyle?.fontSize || 16,
                textAlign: titleStyle?.textAlign || undefined,
                fontStyle: titleStyle?.italic ? 'italic' : undefined,
                color: titleStyle?.color || undefined,
                textDecoration: titleStyle?.underline ? 'underline' : undefined,
                fontWeight: titleStyle?.bold ? 600 : undefined,
              },
            }}
            className="non-draggable"
            style={{ flexGrow: 1 }}
          />
          {/*<Tooltip title="Options" placement="top">
            <IconButton onClick={(e) => setPopoverAnchor(e.currentTarget)} style={{ width: 15, height: 30 }}>
              <MoreVert fontSize="small" style={{ color: grey[500] }} />
            </IconButton>
      </Tooltip>*/}
        </Grid>
      )}
      {item.type === 'styledText' && (
        <>
          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="flex-end"
            wrap="nowrap"
            style={{ padding: '0px 2% 6px 2%', marginTop: 'auto', height: '100%' }}
          >
            <TextField
              size="small"
              placeholder="Add a text"
              multiline
              inputProps={{
                style: {
                  height: '100%',
                  maxHeight: '100%',
                  fontSize: titleStyle?.fontSize || 16,
                  textAlign: titleStyle?.textAlign || undefined,
                  fontStyle: titleStyle?.italic ? 'italic' : undefined,
                  color: titleStyle?.color || undefined,
                  textDecoration: titleStyle?.underline ? 'underline' : undefined,
                  fontWeight: titleStyle?.bold ? 600 : undefined,
                },
              }}
              value={text}
              onChange={(e) => {
                const { clientWidth, clientHeight, scrollWidth, scrollHeight } = e.target;
                // if the text doesn't fit into the field return
                const doesNotFit = scrollWidth > clientWidth || scrollHeight > clientHeight;
                if (doesNotFit && String(e.target.value).length > String(text).length) return;
                saveText(e.target.value);
              }}
              InputProps={{ style: { height: '100%', maxHeight: '100%', padding: 6 } }}
              style={{ height: '100%', maxHeight: '100%', flexGrow: 1 }}
              className="non-draggable"
            />
          </Grid>
          {/*<Tooltip title="Options" placement="top">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setPopoverAnchor(e.currentTarget);
              }}
              style={{ width: 36, height: 36, position: 'absolute', top: 0, right: 0 }}
            >
              <MoreVert fontSize="small" style={{ color: grey[700] }} />
            </IconButton>
            </Tooltip>*/}
        </>
      )}
      {item.type === 'text' && (
        <Grid container item style={{ height: '100%', position: 'relative' }}>
          <Tooltip title="Delete field" placement="top">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                confirmDelete();
              }}
              style={{ width: 36, height: 36, position: 'absolute', top: 0, right: 0, zIndex: 2 }}
            >
              <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {item.type === 'image' && (
        <Grid container item style={{ position: 'relative', height: '100%' }}>
          <Grid container style={{ width: '100%', height: '100%', position: 'absolute' }}>
            <input
              accept="image/*"
              name="img"
              id="cover-photo"
              type="file"
              style={{ display: 'none' }}
              onChange={uploadPicture}
            />

            <Grid
              container
              style={{ height: '100%', maxHeight: '100%' }}
              justifyContent="center"
              alignItems="center"
              wrap="nowrap"
            >
              {uploadingPicture ? (
                <CircularProgress size={30} />
              ) : picture ? (
                <img
                  alt={picture.name}
                  src={picture.location}
                  draggable="false"
                  style={{ maxWidth: '100%', maxHeight: '100%', userSelect: 'none' }}
                />
              ) : (
                <Button
                  component="label"
                  size="small"
                  startIcon={<CloudUploadOutlined />}
                  style={{ textTransform: 'none', color: grey[500] }}
                  className="non-draggable"
                >
                  <input
                    accept="image/*"
                    name="img"
                    id="cover-photo"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={uploadPicture}
                  />
                  Upload picture
                </Button>
              )}
            </Grid>
          </Grid>
          <Tooltip title="Delete field" placement="top">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                confirmDelete();
              }}
              style={{ width: 36, height: 36, position: 'absolute', top: 0, right: 0, zIndex: 2 }}
              className="non-draggable"
            >
              <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
          {picture && (
            <IconButton
              component="label"
              style={{ width: 36, height: 36, position: 'absolute', top: 0, left: 0, zIndex: 2 }}
              className="non-draggable"
            >
              <CloudUploadOutlined fontSize="small" style={{ color: grey[500] }} />
              <input
                accept="image/*"
                name="img"
                id="cover-photo"
                type="file"
                style={{ display: 'none' }}
                onChange={uploadPicture}
              />
            </IconButton>
          )}
        </Grid>
      )}
      {item.type === 'field' && (
        <>
          {fieldType ? (
            <Grid
              container
              item
              alignItems="flex-end"
              wrap="nowrap"
              style={{ padding: fieldType === 'table' ? undefined : '0px 2% 6px 2%', marginTop: 'auto', flexGrow: 1 }}
              xs={short ? 6 : 12}
            >
              {fieldType === 'text' && (
                <TextField size="small" placeholder="Text field" disabled style={{ flexGrow: 1 }} />
              )}
              {fieldType === 'textArea' && (
                <TextField
                  size="small"
                  placeholder="Text area"
                  multiline
                  inputProps={{ style: { height: '100%', maxHeight: '100%' } }}
                  disabled
                  InputProps={{ style: { height: '100%', maxHeight: '100%', padding: 6 } }}
                  style={{ height: '100%', maxHeight: '100%', flexGrow: 1 }}
                />
              )}
              {fieldType === 'number' && (
                <TextField size="small" placeholder="Number" type={'number'} disabled style={{ flexGrow: 1 }} />
              )}
              {fieldType === 'phone' && <TextField size="small" placeholder="Phone" disabled style={{ flexGrow: 1 }} />}
              {fieldType === 'email' && <TextField placeholder="Email" disabled style={{ flexGrow: 1 }} />}
              {fieldType === 'date' && (
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventRounded />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Date"
                  size="small"
                  disabled
                  style={{ flexGrow: 1 }}
                />
              )}
              {fieldType === 'time' && (
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ScheduleRounded />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Time"
                  size="small"
                  disabled
                  style={{ flexGrow: 1 }}
                />
              )}
              {fieldType === 'user' && (
                <GeneralButton
                  disabled
                  size="small"
                  startIcon={<PersonRounded style={{ color: grey[500] }} />}
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 'auto' }}
                >
                  User picker
                </GeneralButton>
              )}
              {fieldType === 'tickbox' && (
                <>
                  {!isEmpty(options) && (
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        {options.map((value) => (
                          <FormControlLabel
                            key={value.id}
                            label={value.text}
                            disabled
                            control={<Checkbox color="primary" />}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  )}
                </>
              )}
              {fieldType === 'image' && <PanoramaRounded style={{ color: grey[500] }} />}
              {fieldType === 'file' && <DescriptionRounded style={{ color: grey[500] }} />}
              {fieldType === 'dropbox' && (
                <TextField fullWidth select label="Picker" size={'small'} disabled>
                  <MenuItem />
                </TextField>
              )}
              {fieldType === 'signature' && <GestureRounded style={{ color: grey[500] }} />}
              {fieldType === 'table' && <PageGridTableBuilder initial={tableParams} onChange={saveTableParams} />}
            </Grid>
          ) : (
            <Grid container item alignItems="center" wrap="nowrap" style={{ padding: '3px 2%' }} xs={short ? 6 : 12}>
              <Button
                onClick={() => {
                  setFieldtypeOpen(true);
                }}
                style={{ color: grey[600], textTransform: 'none' }}
                size="small"
                className="non-draggable"
              >
                Pick type
              </Button>
              <Tooltip title="Delete field" placement="top">
                <IconButton
                  onClick={() => {
                    confirmDelete();
                  }}
                  className={clsx(classes.smallIconButton, 'non-draggable')}
                >
                  <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </>
      )}

      <PickFieldTypeDialog
        open={fieldtypeOpen}
        onClose={() => setFieldtypeOpen(false)}
        onResult={handleFieldType}
        excludedTypes={['conditional', 'stock', 'status', 'action', 'calculation', 'dataSet', 'people']}
      />
      <HelpDialog open={helpDialog} onClose={() => setHelpDialog(false)} initial={helpText} onResult={saveHelpText} />
      {fieldType === 'dataSet' && (
        <EditDataSetDialog
          open={dataSetDialog}
          onClose={() => setDataSetDialog(false)}
          onResult={saveDataSetParams}
          initial={dataSetParams}
          forGrid
        />
      )}
      {(fieldType === 'dropbox' || fieldType === 'tickbox') && (
        <OptionsDialog
          initialOptions={optionParams.options}
          initialSelectType={optionParams.selectType}
          open={optionsDialogOpen}
          onClose={() => setOptionsDialogOpen(false)}
          onResult={handleOptions}
          isTickbox={optionParams.isTickbox}
        />
      )}

      {hovered && ['field', 'text', 'styledText'].includes(item.type) && (
        <Grid
          container
          style={{
            position: 'absolute',
            top: fieldType === 'table' && tableParams?.hideTitle ? -82 : -42,
            zIndex: 42,
            paddingBottom: fieldType === 'table' && tableParams?.hideTitle ? 82 : 5,
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className="non-draggable"
        >
          <Paper elevation={5}>
            <Grid container item alignItems="center" wrap="nowrap">
              <ButtonGroup>
                {item.type === 'field' && fieldTypes[fieldType] && (
                  <Button
                    onClick={() => setFieldtypeOpen(true)}
                    startIcon={fieldTypes[fieldType]?.icon}
                    size="small"
                    style={{ color: grey[600], textTransform: 'none' }}
                  >
                    {fieldTypes[fieldType]?.text}
                  </Button>
                )}
                {item.type === 'field' && ['dropbox', 'tickbox', 'dataSet'].includes(fieldType) && (
                  <Tooltip title="Edit options" placement="top">
                    <Button onClick={editOptions} style={{ width: 36, height: 36 }}>
                      <EditRounded fontSize="small" style={{ color: grey[500] }} />
                    </Button>
                  </Tooltip>
                )}
                {item.type === 'field' && (
                  <Tooltip title={helpText ? 'Edit help text' : 'Add help text'} placement="top">
                    <Button onClick={() => setHelpDialog(true)} style={{ width: 36, height: 36 }}>
                      <HelpOutlineRounded fontSize="small" style={{ color: helpText ? blue[900] : grey[500] }} />
                    </Button>
                  </Tooltip>
                )}
                {item.type === 'field' && (
                  <Tooltip title={required ? 'Make not required' : 'Make required'} placement="top">
                    <Button onClick={() => saveRequired(!required)} style={{ width: 36, height: 36 }}>
                      {required ? <RequiredIcon /> : <RequiredGrey />}
                    </Button>
                  </Tooltip>
                )}

                <Tooltip title="Duplicate" placement="top">
                  <Button onClick={() => duplicate()} style={{ width: 36, height: 36 }}>
                    <FileCopyRounded fontSize="small" style={{ color: grey[500] }} />
                  </Button>
                </Tooltip>

                {(fieldType !== 'table' || !tableParams?.hideTitle) && (
                  <Tooltip title={item.type === 'text' ? 'Text style' : 'Title style'} placement="top">
                    <Button
                      onClick={() => {
                        setTitleStyleDialog(true);
                      }}
                    >
                      <TextFormatRounded fontSize="small" style={{ color: titleStyle ? blue[900] : grey[500] }} />
                    </Button>
                  </Tooltip>
                )}

                {fieldType === 'table' && (
                  <Tooltip title={tableParams?.hideTitle ? 'Show table title' : 'Hide table title'} placement="top">
                    <Button onClick={() => setTableParams({ ...tableParams, hideTitle: !tableParams?.hideTitle })}>
                      {tableParams?.hideTitle ? (
                        <FormatSizeRounded style={{ color: grey[500] }} />
                      ) : (
                        <FormatStrikethroughRounded style={{ color: grey[500] }} />
                      )}
                    </Button>
                  </Tooltip>
                )}

                <Tooltip title="Delete field" placement="top">
                  <Button onClick={confirmDelete} style={{ width: 36, height: 36 }}>
                    <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Grid>
          </Paper>
        </Grid>
      )}

      {/*<Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        
        disableRestoreFocus
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ marginTop: 8 }}
      >
        <Grid container item alignItems="center" wrap="nowrap" style={{ padding: 4 }}>
          {item.type === 'field' && (
            <>
              <Button
                onClick={() => setFieldtypeOpen(true)}
                startIcon={fieldTypes[fieldType]?.icon}
                size="small"
                style={{ color: grey[600], textTransform: 'none' }}
              >
                {fieldTypes[fieldType]?.text}
              </Button>

              {['dropbox', 'tickbox', 'dataSet', 'table', 'people'].includes(fieldType) && (
                <Tooltip title="Edit options" placement="top">
                  <IconButton onClick={editOptions} style={{ width: 36, height: 36 }}>
                    <EditRounded fontSize="small" style={{ color: grey[500] }} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title={helpText ? 'Edit help text' : 'Add help text'} placement="top">
                <IconButton onClick={() => setHelpDialog(true)} style={{ width: 36, height: 36 }}>
                  <HelpOutlineRounded fontSize="small" style={{ color: helpText ? blue[900] : grey[500] }} />
                </IconButton>
              </Tooltip>

              <Tooltip title={required ? 'Make not required' : 'Make required'} placement="top">
                <IconButton onClick={() => setRequired(!required)} style={{ width: 36, height: 36 }}>
                  {required ? <RequiredIcon /> : <RequiredGrey />}
                </IconButton>
              </Tooltip>
            </>
          )}

          <Tooltip title="Duplicate" placement="top">
            <IconButton onClick={() => duplicate()} style={{ width: 36, height: 36 }}>
              <FileCopyRounded fontSize="small" style={{ color: grey[500] }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={item.type === 'text' ? 'Text style' : 'Title style'} placement="top">
            <IconButton
              onClick={() => {
                setPopoverAnchor(null);
                setTitleStyleDialog(true);
              }}
            >
              <TextFieldsRounded fontSize="small" style={{ color: titleStyle ? blue[900] : grey[500] }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete field" placement="top">
            <IconButton onClick={confirmDelete} style={{ width: 36, height: 36 }}>
              <DeleteOutlined fontSize="small" style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>

          {false &&
                <Menu
                  
                  open={Boolean(menuAnchor)}
                  anchorEl={menuAnchor}
                  onClose={() => setMenuAnchor(null)}
                  className="non-draggable"
                >
                  <MenuItem
                    onClick={() => {
                      setHelpDialog(true);
                      setMenuAnchor(null);
                    }}
                  >
                    <ListItemIcon>
                      <HelpOutlineRounded style={{ color: helpText ? blue[900] : grey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary={helpText ? 'Edit help text' : 'Add help text'} />
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setRequired(!required);
                      setMenuAnchor(null);
                    }}
                  >
                    <ListItemIcon>
                      <RequiredGrey />
                    </ListItemIcon>
                    <ListItemText primary={required ? 'Make not required' : 'Make required'} />
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      duplicate();
                      setMenuAnchor(null);
                    }}
                  >
                    <ListItemIcon>
                      <FileCopyRounded style={{ color: grey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary={'Duplicate'} />
                  </MenuItem>

                  {['dropbox', 'tickbox', 'dataSet', 'table', 'people'].includes(fieldType) && (
                    <MenuItem
                      onClick={() => {
                        editOptions();
                        setMenuAnchor(null);
                      }}
                    >
                      <ListItemIcon>
                        <EditRounded style={{ color: grey[500] }} />
                      </ListItemIcon>
                      <ListItemText primary={'Edit options'} />
                    </MenuItem>
                  )}
                </Menu>
              }
        </Grid>
            </Popover>*/}
      <TextStyleDialog
        open={titleStyleDialog}
        onClose={() => setTitleStyleDialog(false)}
        onResult={saveTitleStyle}
        initialText={item.type === 'text' ? 'Sample text' : title}
        initial={titleStyle}
        withFormat
      />
    </StyledGrid>
  );
}
