import React, { useState, useEffect } from 'react';
import { Button, DialogTitle, DialogContent, Grid, Typography, IconButton, Tooltip } from '@mui/material';
import { CheckCircleRounded, CancelRounded, PersonAddRounded, RemoveCircleOutlineRounded } from '@mui/icons-material';
import { RoundedDialog, User, StandardDialogActions } from '../../../Global/Components';
import { FormatDate } from '../../../Global/Functions';
import StateManager from '../../../Global/StateManager';
import { red, grey, green } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function AcceptanceDialog({ open, onClose, task, onTaskChange }) {
  const [saving, setSaving] = useState(false);
  const [items, setItems] = useState([]);
  const userId = localStorage.getItem('_id');
  const { user } = useSelector(({ profile }) => profile);
  const permission = user && (user?._id === task?.created_by || user?.access === 'admin');

  useEffect(() => {
    if (!task || !task.userAcceptance) return;

    setItems(task.userAcceptance);
  }, [task]);

  function getItemState(item) {
    if (item.attempts && item.attempts[0]?.state) return item.attempts[0].state;
    return 'pending';
  }

  function addUser(userToAdd) {
    if (task.assignedUsers?.includes(userToAdd._id)) {
      StateManager.setSuccessAlert(`${userToAdd.fullName} has already been assigned to this task`);
    }

    let body = {
      taskId: task._id,
      userId: userToAdd._id,
    };
    setSaving(true);
    axios
      .post('/tasks/addAssigneeToTask', body)
      .then((res) => {
        setSaving(false);
        onTaskChange(res.data);
        StateManager.setSuccessAlert('User has been added');
        setItems(res.data.userAcceptance);
      })
      .catch((err) => {
        console.error(err);
        setSaving(false);
        StateManager.setErrorAlert('Failed to add user');
      });
  }

  function selectUser() {
    StateManager.selectUser(addUser);
  }

  function removeUser(userToRemove) {
    let body = {
      taskId: task._id,
      userId: userToRemove,
    };
    setSaving(true);
    axios
      .post('/tasks/removeAssigneeFromTask', body)
      .then((res) => {
        setSaving(false);
        onTaskChange(res.data);
        StateManager.setSuccessAlert('User has been removed');
        setItems(res.data.userAcceptance);
      })
      .catch((err) => {
        console.error(err);
        setSaving(false);
        StateManager.setErrorAlert('Failed to remove user');
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth className="scroll-bar">
      <DialogTitle>Task acceptance</DialogTitle>
      <DialogContent>
        <Grid container>
          {items?.length === 0 && <Typography color="textSecondary">No acceptance for this task yet</Typography>}
          {items?.length > 0 &&
            items.map((item) => (
              <Grid key={item._id} sx={{ py: 1, my: 1, borderBottom: `1px solid ${grey[300]}` }} item container>
                <Grid item container alignItems="center">
                  <User id={item.userId} dense />
                  {(() => {
                    let state = getItemState(item);
                    if (state === 'accepted')
                      return <CheckCircleRounded fontSize="large" style={{ color: green[500], marginLeft: 'auto' }} />;
                    if (state === 'rejected')
                      return <CancelRounded fontSize="large" style={{ color: red[500], marginLeft: 'auto' }} />;
                    return (
                      <Typography color="textSecondary" style={{ fontSize: 14, marginLeft: 'auto' }}>
                        Pending
                      </Typography>
                    );
                  })()}
                  {permission && getItemState(item) !== 'rejected' && (
                    <Tooltip title="Remove from the task">
                      <IconButton onClick={() => removeUser(item.userId)} style={{ marginLeft: 8 }}>
                        <RemoveCircleOutlineRounded style={{ color: red[500] }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>

                {item.attempts?.length > 0 && item.attempts[0].state !== 'pending' && (
                  <Grid item container direction="column">
                    <Typography color="textSecondary" gutterBottom style={{ fontSize: 14 }}>
                      {FormatDate(item.attempts[0].decidedAt)}
                    </Typography>
                    {item.attempts[0].state === 'rejected' && (
                      <Typography color="textSecondary">
                        <span style={{ color: red[500], fontWeight: 500 }}>Rejected</span>
                        {'  '}
                        with the reason:{'  '}
                        <i>{item.attempts[0].reason}</i>
                      </Typography>
                    )}
                    {item.attempts[0].state === 'accepted' && (
                      <Typography style={{ color: green[500], fontWeight: 500 }}>Accepted</Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <StandardDialogActions
        saving={saving}
        additionalActions={
          <>
            {task?.assignedUsers?.length > 1 &&
              task?.assignedUsers?.find((x) => x === userId) &&
              user?._id === task?.created_by && (
                <Button
                  style={{ color: red[500], borderRadius: 8 }}
                  startIcon={<RemoveCircleOutlineRounded style={{ color: red[500] }} />}
                  onClick={() => removeUser(userId)}
                >
                  remove yourself
                </Button>
              )}
            {permission ? (
              <Button
                style={{ color: grey[700], borderRadius: 8 }}
                startIcon={<PersonAddRounded />}
                onClick={selectUser}
              >
                add user
              </Button>
            ) : null}
            <div style={{ marginRight: 'auto' }} />
          </>
        }
        onClose={onClose}
      />
    </RoundedDialog>
  );
}
