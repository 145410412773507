import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import editorStyles from './style.module.css';
import { convertFromRaw, EditorState } from 'draft-js';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';

import { Typography, Grid, IconButton, Box } from '@mui/material';
import { DeleteOutline, Edit, History, ThumbUpSharp } from '@mui/icons-material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { grey, blue } from '@mui/material/colors';
import User from '../../../../Global/Components/User';
import SmallUser from '../../../../Global/Components/SmallUser';
import { COLORS, formatDate } from '../../constants';
import mentionDecoratorPlugin, { mentionDecorator } from '../HTMLEditor/utils/mention';
import HistoryDialog from './history';

const userId = localStorage.getItem('_id');

function Comment({
  comment,
  commentVersion,
  deleteComment,
  likeComment,
  disabled,
  handleEdit,
  upIssueComment,
  reply = false,
  variant = 'v1',
}) {
  const issuePage = useRouteMatch('/editor/:id/upissue/:issue');
  const [openHistory, setOpenHistory] = useState(false);
  const history = useHistory();
  const [editState, setEditState] = useState(EditorState.createEmpty());
  const { commentBoxPlugins } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const focusPlugin = createFocusPlugin();
    const resizeablePlugin = createResizeablePlugin();
    const blockDndPlugin = createBlockDndPlugin();
    const alignmentPlugin = createAlignmentPlugin();
    const decorator = composeDecorators(
      resizeablePlugin.decorator,
      alignmentPlugin.decorator,
      focusPlugin.decorator,
      blockDndPlugin.decorator,
    );
    const imagePlugin = createImagePlugin({ decorator });
    const commentBoxPlugins = [
      blockDndPlugin,
      focusPlugin,
      alignmentPlugin,
      resizeablePlugin,
      imagePlugin,
      mentionDecoratorPlugin,
    ];
    return { commentBoxPlugins };
  }, []);

  useEffect(() => {
    if (!comment) return;
    if (comment?.documentCategory) {
      if (comment?.documentCategory === 'general') {
        setEditState(
          EditorState.createWithContent(
            convertFromRaw({
              blocks: comment?.text?.blocks,
              entityMap: comment?.text?.entityMap ? comment.text.entityMap : {},
            }),
          ),
        );
      } else {
        setEditState(null);
      }
    } else {
      setEditState(
        EditorState.createWithContent(
          convertFromRaw({
            blocks: comment?.text?.blocks,
            entityMap: comment?.text?.entityMap ? comment?.text?.entityMap : {},
          }),
        ),
      );
    }
  }, [comment]);

  const handleLike = () => {
    let id = comment?._id;
    let commentData = {
      liked: true,
    };
    likeComment({ id, data: commentData });
  };

  return (
    <Grid
      item
      container
      direction="column"
      key={comment.id}
      sx={{
        borderRadius: '0.5rem',
        ...(variant !== 'v1' && {
          position: 'relative',
          marginTop: '25px',
        }),
        ...(variant === 'v1' && {
          margin: '0.5rem 0',
          border: '1px solid lightgrey',
        }),
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        sx={
          variant === 'v1'
            ? { borderBottom: '1px solid lightgrey', padding: '0.3rem' }
            : variant === 'v2'
            ? { position: 'absolute', top: '-18px' }
            : {}
        }
      >
        <Grid item>
          {comment.createdBy && (
            <User
              id={comment.createdBy}
              clickable
              avatarSize={40}
              buttonStyle={variant === 'v2' ? { padding: '0px 0px' } : {}}
              listItemStyles={variant === 'v2' ? { marginTop: '-10px' } : {}}
              customUserPadding={variant === 'v2' ? 3 : 0}
            />
          )}
        </Grid>
        <Grid item align="center">
          {!disabled && !reply && comment.likes?.length > 0 && (
            <Typography
              align="center"
              variant="button"
              sx={{ fontSize: 20, color: COLORS.BOOKMARK, verticalAlign: 'middle' }}
            >
              {comment.likes.filter((x) => x.liked).length}
            </Typography>
          )}
          {variant === 'v2' && commentVersion && (
            <Typography sx={{ fontSize: 12, color: 'gray', marginTop: '-10px' }}>
              Added on version
              <Box component="span" sx={{ fontWeight: 'bold', margin: '0 0.5rem', color: blue[500] }}>
                {commentVersion}
              </Box>
            </Typography>
          )}
          {!disabled && !reply && (
            <IconButton
              onClick={handleLike}
              disabled={comment.likes?.find((x) => x.user?.toString() === userId)?.liked}
            >
              <ThumbUpSharp
                sx={{
                  color: comment.likes?.find((x) => x.user?.toString() === userId)?.liked
                    ? COLORS.BOOKMARK
                    : COLORS.UPLOAD,
                }}
              />
            </IconButton>
          )}
          {!disabled && deleteComment && userId === comment.createdBy && (
            <>
              {comment.history && comment.history.length > 0 && (
                <IconButton onClick={() => setOpenHistory(true)}>
                  <History />
                </IconButton>
              )}
              {upIssueComment && (
                <IconButton
                  onClick={() =>
                    comment.documentCategory === 'pdf' && issuePage
                      ? history.push(`?annotationId=${comment.text.modified}`)
                      : history.push(`?commentId=${comment._id}`)
                  }
                >
                  <FindInPageIcon sx={{ color: COLORS.BOOKMARK }} />
                </IconButton>
              )}
              <IconButton onClick={handleEdit}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => deleteComment(comment._id, comment?.selection, comment?.sectionId)}>
                <DeleteOutline sx={{ color: '#dd2c00' }} />
              </IconButton>
              <HistoryDialog
                open={openHistory}
                onClose={() => setOpenHistory(false)}
                data={comment.history?.map((c) => ({ ...c, createdBy: comment.createdBy }))}
                upIssueComment={upIssueComment}
              />
            </>
          )}
        </Grid>
      </Grid>
      {comment.documentCategory === 'pdf' ? (
        <Grid
          item
          container
          sx={
            variant === 'v1'
              ? { borderBottom: '1px solid lightgrey', padding: '0.5rem' }
              : {
                  padding: '24px 10px 10px 15px',
                  backgroundColor: grey[200],
                  borderRadius: '10px',
                  margin: '6px',
                }
          }
        >
          <Typography>{comment.text.bodyValue}</Typography>
        </Grid>
      ) : (
        <Grid item container sx={variant === 'v1' ? { borderBottom: '1px solid lightgrey', padding: '0.5rem' } : {}}>
          <Grid
            item
            container
            className={editorStyles.editor}
            sx={
              variant === 'v2'
                ? {
                    padding: '24px 10px 10px 15px !important',
                    backgroundColor: grey[200],
                    borderRadius: '10px',
                    margin: '4px',
                    fontSize: 16,
                  }
                : {}
            }
          >
            <Editor
              readOnly
              editorState={editState}
              onChange={setEditState}
              plugins={commentBoxPlugins}
              decorators={[mentionDecorator]}
            />
          </Grid>
        </Grid>
      )}
      {variant === 'v1' && commentVersion && (
        <Grid item container sx={{ padding: '0.5rem' }} justifyContent="space-between">
          <Typography sx={{ fontSize: 14, color: 'gray' }}>
            Added on version
            <Box component="span" sx={{ fontWeight: 'bold', margin: '0 1rem', color: blue[500] }}>
              {commentVersion}
            </Box>
          </Typography>
          <Typography sx={{ fontSize: 14, color: 'gray' }}>
            {formatDate(comment.updatedAt, 'DD/MM/YYYY hh:mm a')}
          </Typography>
        </Grid>
      )}
      {variant === 'v2' && (
        <Grid item container>
          <Typography color="textSecondary" variant="subtitle2" sx={{ fontSize: 12, marginLeft: '15px' }}>
            {formatDate(comment.updatedAt, 'DD/MM/YYYY hh:mm a')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export const CommentRow = ({ comment }) => {
  const issuePage = useRouteMatch('/editor/:id/upissue/:issue');
  const history = useHistory();
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems={'center'}
      sx={{ borderRadius: '0.5rem', position: 'relative', marginTop: '25px' }}
    >
      <SmallUser id={comment.createdBy} />
      <Typography variant="caption">{formatDate(comment.updatedAt, 'HH:MM:a - DD/MM/YYYY')}</Typography>
      {comment.document && (
        <Typography variant="caption">
          v{comment.document.baseVersion}
          {comment.document.issue}
        </Typography>
      )}
      <IconButton
        size="small"
        onClick={() =>
          comment.documentCategory === 'pdf' && issuePage
            ? history.push(`?annotationId=${comment.text.id}`)
            : history.push(`?commentId=${comment._id}`)
        }
      >
        <FindInPageIcon sx={{ color: COLORS.BOOKMARK }} />
      </IconButton>
    </Grid>
  );
};

export default Comment;
