import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ImageNotFound from '../../../Images/404.png';

export default function NotFound() {
  return (
    <Container maxWidth="md">
      <Grid container>
        <Link to={'/home'}>
          <img src={ImageNotFound} height={'100%'} width={'100%'} alt="Not Found" />
        </Link>
      </Grid>
    </Container>
  );
}
