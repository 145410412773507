import React, { useState, useEffect } from 'react';
import { isArray, isEmpty, isFunction } from 'lodash';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';
import ActionGroup from './ActionGroup';

export default function ActionGroupButton({
  preSetActions,
  activityInfo,
  columnId,
  rowId,
  allCreatedActions,
  onResult,
  editable,
  building,
  onDeletedAction,
  disableClick,
}) {
  const [dialog, setDialog] = useState(false);

  const buttonActions = isArray(allCreatedActions)
    ? allCreatedActions.filter((x) => x.rowId === rowId && x.columnId === columnId)
    : [];

  return (
    <>
      <Button
        onClick={disableClick ? undefined : () => setDialog(true)}
        fullWidth
        disabled={Boolean(building) || (isEmpty(buttonActions) && !editable)}
      >
        {isEmpty(buttonActions)
          ? 'No actions'
          : `${buttonActions.length} action${buttonActions.length === 1 ? '' : 's'}`}
      </Button>
      <ActionGroupDialog
        open={dialog}
        onClose={() => setDialog(false)}
        buttonActions={buttonActions}
        editable={editable}
        preSetActions={preSetActions}
        activityInfo={activityInfo}
        columnId={columnId}
        rowId={rowId}
        onResult={onResult}
        onDeletedAction={onDeletedAction}
      />
    </>
  );
}

function ActionGroupDialog({
  open,
  onClose,
  onResult,
  buttonActions,
  editable,
  preSetActions,
  activityInfo,
  columnId,
  rowId,
  onDeletedAction,
}) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <DialogTitle>Actions</DialogTitle>
      <DialogContent>
        <ActionGroup
          preSetActions={preSetActions}
          activityInfo={activityInfo}
          allCreatedActions={buttonActions}
          forCell
          cellInfo={{ columnId, rowId }}
          editable={editable}
          onResult={onResult}
          onDelete={onDeletedAction}
        />
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
