import React, { useState, useEffect } from 'react';
import { ListItemButton, List, ListItemIcon, ListItemText, ListItemSecondaryAction, Divider } from '@mui/material';
import { DialogContent, DialogTitle, Typography, Grid, IconButton, CircularProgress } from '@mui/material';
import { Paper, Tooltip, TextField } from '@mui/material';
import { DeleteOutlineOutlined, FiberManualRecord, AddRounded } from '@mui/icons-material';
import { RoundedDialog, ColorPicker, StandardDialogActions, GeneralButton } from './index';
import { red, blue } from '@mui/material/colors';
import StateManager from '../StateManager';
import axios from 'axios';

export default function PrefixCard({ title, type }) {
  const [prefixes, setPrefixes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prefixDialog, setPrefixDialog] = useState(false);
  const [selectedPrefix, setSelectedPrefix] = useState(null);

  useEffect(() => {
    if (!type) return;
    setLoading(true);
    axios
      .get('/tags/getTypePrefixes', { params: { type } })
      .then((res) => {
        setPrefixes(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        StateManager.setErrorAlert(`Failed to load prefixes`);
      });
  }, [type]);

  function addTag(prefix) {
    if (!prefix?._id) return;
    let index = prefixes.findIndex((x) => x._id === prefix._id);
    if (index > -1) {
      prefixes[index] = prefix;
      setPrefixes([...prefixes]);
    } else {
      setPrefixes([...prefixes, prefix]);
    }
  }

  function confirmDelete(prefix) {
    StateManager.setConfirm(`You are about to delete prefix ${prefix.text}`, () => deleteTag(prefix));
  }

  function deleteTag(prefix) {
    let id = prefix._id;
    axios
      .post('/tags/deletePrefix', { id })
      .then(() => {
        StateManager.setSuccessAlert('Prefix has been deleted');
        setPrefixes([...prefixes.filter((x) => x._id !== id)]);
      })
      .catch((err) => {
        StateManager.setErrorAlert('Failed to delete prefix');
        console.error(err);
      });
  }

  return (
    <Grid container sx={{ p: 2 }}>
      <Grid container component={Paper} elevation={4} sx={{ p: 2, borderRadius: 1.5 }}>
        <Grid container className="scroll-bar">
          <Grid container item>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
          </Grid>

          <Grid container item style={{ height: '50vh', overflow: 'auto' }}>
            {loading && <CircularProgress size={32} style={{ margin: 'auto' }} />}
            {prefixes.length === 0 && !loading && (
              <Typography variant="h6" color="textSecondary">
                Nothing here
              </Typography>
            )}
            {prefixes.length > 0 && (
              <List style={{ width: '100%' }}>
                {prefixes.map((prefix, i) => (
                  <React.Fragment key={prefix._id}>
                    <ListItemButton
                      onClick={() => {
                        setSelectedPrefix(prefix);
                        setPrefixDialog(true);
                      }}
                    >
                      <ListItemIcon>
                        <FiberManualRecord style={{ color: prefix.color }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography style={{ marginRight: '1rem' }} noWrap>
                            {prefix.text}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          style={{ marginLeft: '1rem' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            confirmDelete(prefix);
                          }}
                        >
                          <DeleteOutlineOutlined style={{ color: red[500] }} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                    {i !== prefixes.length - 1 && <Divider light />}
                  </React.Fragment>
                ))}
              </List>
            )}
          </Grid>

          <Grid container item justifyContent="flex-end" style={{ marginTop: 8 }}>
            <GeneralButton
              onClick={() => {
                setSelectedPrefix(null);
                setPrefixDialog(true);
              }}
              startIcon={<AddRounded color="textSecondary" />}
            >
              Add prefix
            </GeneralButton>
          </Grid>

          <PrefixDialog
            prefix={selectedPrefix}
            open={prefixDialog}
            onClose={() => {
              setPrefixDialog(false);
              setSelectedPrefix(null);
            }}
            onResult={addTag}
            type={type}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export function PrefixDialog({ prefix, open, onClose, onResult, type }) {
  const [text, setText] = useState('');
  const [color, setColor] = useState(blue[500]);
  const [saving, setSaving] = useState(false);
  const [colourPicker, setColourPicker] = useState();

  useEffect(() => {
    if (open && !prefix) {
      setText('');
      setColor(blue[700]);
    }
    if (open && prefix) {
      setText(prefix.text);
      setColor(prefix.color);
    }
  }, [open, prefix]);

  function createTag() {
    if (!text) {
      StateManager.setErrorAlert('Specify prefix text');
      return;
    }
    setSaving(true);
    if (prefix) {
      axios
        .post('/tags/editPrefix', { id: prefix._id, text, color })
        .then((res) => {
          StateManager.setSuccessAlert('Prefix has been saved');
          setSaving(false);
          onResult(res.data);
          onClose();
        })
        .catch((err) => {
          StateManager.setErrorAlert('Failed to save prefix');
          setSaving(false);
          console.error(err);
        });
    } else {
      axios
        .post('/tags/createPrefix', { text, type, color })
        .then((res) => {
          StateManager.setSuccessAlert('Prefix has been added');
          setSaving(false);
          onResult(res.data);
          onClose();
        })
        .catch((err) => {
          StateManager.setErrorAlert('Failed to add prefix');
          setSaving(false);
          console.error(err);
        });
    }
  }

  function selectColor(color) {
    if (color?.color) {
      setColor(color.color);
    }
    setColourPicker(null);
  }

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={() => onClose(null)}>
      <DialogTitle>{prefix ? 'Edit prefix' : 'New prefix'}</DialogTitle>

      <DialogContent>
        <Grid container alignItems="center" sx={{ pt: 1 }}>
          <TextField
            variant="outlined"
            label="Prefix title"
            value={text}
            style={{ flexGrow: 1, marginRight: 5, height: 'fit-content' }}
            onChange={(event) => setText(String(event.target.value).toUpperCase())}
            inputProps={{ maxLength: 64 }}
          />
          <Tooltip title="Pick color" placement="top">
            <IconButton onClick={(e) => setColourPicker(e.currentTarget)}>
              <FiberManualRecord style={{ color }} />
            </IconButton>
          </Tooltip>
          <ColorPicker anchor={colourPicker} open={Boolean(colourPicker)} onClose={selectColor} column={700} />
        </Grid>
      </DialogContent>
      <StandardDialogActions saving={saving} onClose={() => onClose(null)} onDone={createTag} hideDone={!text} />
    </RoundedDialog>
  );
}
