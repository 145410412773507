import React, { useMemo, useState, useEffect } from 'react';
import { Grid, Collapse, IconButton, Typography, TextField, ListItemButton } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import { AddCircleOutlineRounded, DeleteOutlineRounded, FiberManualRecord } from '@mui/icons-material';
import { DialogTitle, DialogContent, Tooltip } from '@mui/material';
import { ExpandMoreRounded, PaletteRounded } from '@mui/icons-material';
import { RoundedDialog, ColorPicker, StandardDialogActions } from './index';
import { blue, grey, red } from '@mui/material/colors';
import { isFunction } from 'lodash';
import StateManager from '../StateManager';
import { v4 } from 'uuid';

export default function ItemTree({ items, editable, withColorPicker, onItemSave, onItemDelete, type, iconComponent }) {
  const [selectedParent, setSelectedParent] = useState(null);
  const [newItemDialog, setNewItemDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const notNested = useMemo(() => {
    if (!isArray(items)) return [];

    const result = items.filter((x) => !x.parentId);
    return result;
  }, [items]);

  function addItem(parent) {
    setSelectedParent(parent);
    setSelectedItem(null);
    setNewItemDialog(true);
  }

  function editItem(item) {
    setSelectedParent(null);
    setSelectedItem(item);
    setNewItemDialog(true);
  }

  return (
    <Grid container alignContent={'flex-start'}>
      {notNested.map((item) => (
        <Item
          key={item._id}
          allItems={items}
          item={item}
          editable={editable}
          withColorPicker={withColorPicker}
          onItemAdd={addItem}
          onItemDelete={onItemDelete}
          onItemEdit={editItem}
          type={type}
          iconComponent={iconComponent}
        />
      ))}

      <ItemDialog
        open={newItemDialog}
        onClose={() => setNewItemDialog(false)}
        initial={selectedItem}
        onResult={onItemSave}
        type={type}
        parentItem={selectedParent}
        withColorPicker={withColorPicker}
      />
    </Grid>
  );
}

function Item({
  item,
  editable,
  type = 'item',
  withColorPicker,
  onItemAdd,
  onItemDelete,
  onItemEdit,
  allItems,
  iconComponent,
}) {
  const [expanded, setExpanded] = useState(false);

  const children = useMemo(() => {
    if (!isArray(allItems) || !item?._id) return [];

    const result = allItems.filter((x) => x.parentId === item._id);
    return result;
  }, [allItems, item]);

  function confirmDelete() {
    StateManager.setConfirm(`You are about to delete '${item.title}'`, () => onItemDelete(item._id));
  }

  const hasChildren = !isEmpty(children);

  if (!item) return null;

  const Icon = iconComponent || FiberManualRecord;

  return (
    <Grid container>
      <Grid
        container
        alignItems={'center'}
        wrap="nowrap"
        sx={{ p: 0.7, borderRadius: 0.7 }}
        component={ListItemButton}
        onClick={() => onItemEdit(item)}
      >
        {withColorPicker && <Icon sx={{ color: item.color, mr: 1 }} />}

        <Typography>{item.title}</Typography>

        <Grid sx={{ ml: 'auto', mr: 1 }} />

        {editable && isFunction(onItemAdd) && (
          <Tooltip title={`Add sub-${type}`}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onItemAdd(item);
              }}
            >
              <AddCircleOutlineRounded sx={{ color: grey[500] }} />
            </IconButton>
          </Tooltip>
        )}
        {hasChildren ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
            aria-expanded={expanded}
            sx={{
              transform: 'rotate(0deg)',
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              transform: expanded ? 'rotate(180deg)' : null,
            }}
          >
            <ExpandMoreRounded sx={{ color: grey[500] }} />
          </IconButton>
        ) : (
          editable &&
          isFunction(onItemDelete) && (
            <Tooltip title={`Delete ${type}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDelete();
                }}
              >
                <DeleteOutlineRounded sx={{ color: red[500] }} />
              </IconButton>
            </Tooltip>
          )
        )}
      </Grid>
      {hasChildren && (
        <Grid container component={Collapse} in={expanded}>
          <Grid container sx={{ pl: 3 }}>
            {children.map((child) => (
              <Item
                key={child._id}
                item={child}
                allItems={allItems}
                editable={editable}
                withColorPicker={withColorPicker}
                onItemAdd={onItemAdd}
                onItemDelete={onItemDelete}
                onItemEdit={onItemEdit}
                iconComponent={iconComponent}
              />
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export function ItemDialog({ open, onClose, onResult, initial, withColorPicker, parentItem, type = 'item' }) {
  const [colourPicker, setColourPicker] = useState();
  const [title, setTitle] = useState('');
  const [color, setColor] = useState(blue[500]);

  function selectColor(result) {
    setColourPicker(null);
    if (!result?.color) return;
    setColor(result.color);
  }

  useEffect(() => {
    if (!open) return;
    setTitle(initial?.title || '');
    setColor(initial?.color || blue[500]);
  }, [open]); // eslint-disable-line

  function done() {
    if (!isFunction(onResult)) return;

    const result = {
      _id: initial?._id || v4(),
      title,
      color: withColorPicker ? color : undefined,
      parentId: parentItem ? parentItem._id : initial?.parentId,
    };

    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        {initial ? `Edit ${type}` : parentItem ? `Add sub-${type} to '${parentItem.title}'` : `New ${type}`}
      </DialogTitle>

      <DialogContent>
        <Grid container alignItems="center" sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            label="Title"
            value={title}
            style={{ flexGrow: 1, marginRight: 5 }}
            onChange={(event) => setTitle(event.target.value)}
            inputProps={{ maxLength: 128 }}
            id="item-title"
          />
          <Tooltip title="Select color" placement="top">
            <IconButton onClick={(e) => setColourPicker(e.currentTarget)}>
              <PaletteRounded style={{ color }} />
            </IconButton>
          </Tooltip>
          <ColorPicker anchor={colourPicker} open={Boolean(colourPicker)} onClose={selectColor} customPicker />
        </Grid>
      </DialogContent>

      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
