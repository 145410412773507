/**
 * * Reducer for Document Hub, follows the cases as per relevant actions
 */
import {
  FETCHCOMPANY_ATTEMPT,
  FETCHCOMPANY_SUCCESS,
  FETCHCOMPANY_FAIL,
  FETCHGROUPS_ATTEMPT,
  FETCHGROUPS_SUCCESS,
  FETCHGROUPS_FAIL,
  GETUSERS_ATTEMPT,
  GETUSERS_FAIL,
  GETUSERS_SUCCESS,
  ADDUSER_ATTEMPT,
  ADDUSER_FAIL,
  ADDUSER_SUCCESS,
  UPDATEUSER_ATTEMPT,
  UPDATEUSER_FAIL,
  UPDATEUSER_SUCCESS,
  DELETEUSER_ATTEMPT,
  DELETEUSER_FAIL,
  DELETEUSER_SUCCESS,
  ADDGROUP_ATTEMPT,
  ADDGROUP_FAIL,
  ADDGROUP_SUCCESS,
  UPDATEGROUP_ATTEMPT,
  UPDATEGROUP_FAIL,
  UPDATEGROUP_SUCCESS,
  DELETEGROUP_ATTEMPT,
  DELETEGROUP_FAIL,
  DELETEGROUP_SUCCESS,
  SETCOMPANY,
  SETUSER,
  SETGROUPUSERS,
  SETGROUP,
  UPDATECOMPANY_ATTEMPT,
  UPDATECOMPANY_SUCCESS,
  UPDATECOMPANY_FAIL,
  GENERATECREDENTIALS_ATTEMPT,
  GENERATECREDENTIALS_SUCCESS,
  GENERATECREDENTIALS_FAIL,
  UPLOADLOGO_ATTEMPT,
  UPLOADLOGO_SUCCESS,
  UPLOADLOGO_FAIL,
  RESETPWD_ATTEMPT,
  RESETPWD_SUCCESS,
  RESETPWD_FAIL,
  GETPACKAGES_ATTEMPT,
  GETPACKAGES_SUCCESS,
  GETPACKAGES_FAIL,
  FETCHCARDS_ATTEMPT,
  FETCHCARDS_SUCCESS,
  FETCHCARDS_FAIL,
  ADDCARD_ATTEMPT,
  ADDCARD_SUCCESS,
  ADDCARD_FAIL,
  UPDATECARD_ATTEMPT,
  UPDATECARD_SUCCESS,
  UPDATECARD_FAIL,
  DELETECARD_ATTEMPT,
  DELETECARD_SUCCESS,
  DELETECARD_FAIL,
  CREATEPURCHASE_ATTEMPT,
  CREATEPURCHASE_SUCCESS,
  CREATEPURCHASE_FAIL,
  UPDATEPURCHASE_ATTEMPT,
  UPDATEPURCHASE_SUCCESS,
  UPDATEPURCHASE_FAIL,
  SETADMINALERT,
  SETDEFAULTCARD_ATTEMPT,
  SETDEFAULTCARD_SUCCESS,
  SETDEFAULTCARD_FAIL,
  FETCHACTIVESUBSCRIPTION_ATTEMPT,
  FETCHACTIVESUBSCRIPTION_SUCCESS,
  FETCHACTIVESUBSCRIPTION_FAIL,
  CANCELSUBSCRIPTION_ATTEMPT,
  CANCELSUBSCRIPTION_SUCCESS,
  CANCELSUBSCRIPTION_FAIL,
  REACTIVATESUBSCRIPTION_ATTEMPT,
  REACTIVATESUBSCRIPTION_SUCCESS,
  REACTIVATESUBSCRIPTION_FAIL,
} from '../constants';

import { paidRoles } from '../../../../constants';

const initialUser = {
  firstName: '',
  lastName: '',
  access: 'view',
  jobRole: '',
  mobile: '',
  groups: [],
};

const initialGroup = {
  name: '',
  count: [],
};

const initialState = {
  fetching: false,
  error: null,
  company: null,
  user: initialUser,
  users: null,
  groups: null,
  group: initialGroup,
  groupUsers: [],
  status: null,
  message: null,
  packages: null,
  cards: null,
  purchaseMessage: null,
  purchaseStatus: null,
  alertState: null,
  subscription: null,
  isMSAEnabled: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHCOMPANY_ATTEMPT:
      return { ...state, fetching: true };
    case FETCHCOMPANY_SUCCESS:
      return {
        ...state,
        fetching: false,
        company: action.payload.company,
        subscription: action.payload.subscription,
        isMSAEnabled: action.payload.isMSAEnabled,
      };
    case FETCHCOMPANY_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case UPLOADLOGO_ATTEMPT:
      return { ...state, fetching: true };
    case UPLOADLOGO_SUCCESS:
      return { ...state, fetching: false, company: { ...state.company, logo: action.payload.file.location } };
    case UPLOADLOGO_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case UPDATECOMPANY_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATECOMPANY_SUCCESS:
      return { ...state, fetching: false, company: action.payload.company };
    case UPDATECOMPANY_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case GENERATECREDENTIALS_ATTEMPT:
      return { ...state, fetching: true };
    case GENERATECREDENTIALS_SUCCESS:
      return { ...state, fetching: false, company: action.payload.company };
    case GENERATECREDENTIALS_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SETCOMPANY:
      return { ...state, company: action.payload };
    case SETUSER:
      return { ...state, user: action.payload };
    case GETUSERS_ATTEMPT:
      return { ...state, fetching: true, users: null };
    case GETUSERS_SUCCESS:
      return { ...state, fetching: false, users: action.payload.users };
    case GETUSERS_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case ADDUSER_ATTEMPT:
      return { ...state, fetching: true, status: null, message: null };
    case ADDUSER_SUCCESS:
      return {
        ...state,
        fetching: false,
        users: action.payload.user ? [...state.users, action.payload.user] : state.users,
        status: action.payload.status,
        message: action.payload.message,
        alertState: true,
        company: { ...state.company, consumedUsers: action.payload.consumedUsers },
      };
    case ADDUSER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case UPDATEUSER_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATEUSER_SUCCESS:
      let updatedUsers = state.users.map((u) => {
        if (u._id === action.payload.user._id) {
          return action.payload.user;
        }
        return u;
      });
      return {
        ...state,
        fetching: false,
        users: updatedUsers,
        user: initialUser,
        alertState: true,
        message: action.payload.message,
        status: action.payload.status,
        company: { ...state.company, consumedUsers: action.payload.consumedUsers },
      };
    case UPDATEUSER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case DELETEUSER_ATTEMPT:
      return { ...state, fetching: true };
    case DELETEUSER_SUCCESS:
      if (action.payload.status === 200) {
        const deletedUser = state.users.find((a) => a._id === action.payload.user);
        return {
          ...state,
          fetching: false,
          users: state.users.filter((a) => a._id !== action.payload.user),
          user: initialUser,
          company: {
            ...state.company,
            consumedUsers: state.company.isPremiumUser
              ? paidRoles.includes(deletedUser.access)
                ? state.company.consumedUsers - 1
                : state.company.consumedUsers
              : state.company.consumedUsers,
          },
          status: action.payload.status,
          message: action.payload.message,
          alertState: true,
        };
      } else {
        return {
          ...state,
          fetching: false,
          user: initialUser,
          status: action.payload.status,
          message: action.payload.message,
          alertState: true,
        };
      }
    case DELETEUSER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SETGROUP:
      return { ...state, group: action.payload };
    case SETGROUPUSERS:
      return { ...state, groupUsers: action.payload };
    case FETCHGROUPS_ATTEMPT:
      return { ...state, fetching: true, groups: [] };
    case FETCHGROUPS_SUCCESS:
      return { ...state, fetching: false, groups: action.payload };
    case FETCHGROUPS_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case ADDGROUP_ATTEMPT:
      return { ...state, fetching: true };
    case ADDGROUP_SUCCESS:
      return { ...state, fetching: false, groups: [...state.groups, action.payload.group] };
    case ADDGROUP_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case UPDATEGROUP_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATEGROUP_SUCCESS:
      let updatedGroups = state.groups.map((u) => {
        if (u._id === action.payload.group._id) {
          return action.payload.group;
        }
        return u;
      });
      return { ...state, fetching: false, groups: updatedGroups, group: initialGroup };
    case UPDATEGROUP_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case DELETEGROUP_ATTEMPT:
      return { ...state, fetching: true };
    case DELETEGROUP_SUCCESS:
      return {
        ...state,
        fetching: false,
        groups: state.groups.filter((a) => a._id !== action.payload.group),
        group: initialGroup,
      };
    case DELETEGROUP_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case RESETPWD_ATTEMPT:
      return { ...state, fetching: true, status: null, message: null };
    case RESETPWD_SUCCESS:
      return {
        ...state,
        fetching: false,
        status: action.payload.status,
        message: action.payload.message,
        alertState: true,
      };
    case RESETPWD_FAIL:
      return { ...state, fetching: false, error: action.payload, status: 400, alertState: true };
    case GETPACKAGES_ATTEMPT:
      return { ...state, fetching: true };
    case GETPACKAGES_SUCCESS:
      return { ...state, fetching: false, packages: action.payload.packages };
    case GETPACKAGES_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case FETCHCARDS_ATTEMPT:
      return { ...state, fetching: true };
    case FETCHCARDS_SUCCESS:
      return { ...state, fetching: false, cards: action.payload.cards };
    case FETCHCARDS_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SETADMINALERT:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
        alertState: action.payload.state,
        error: action.payload.error,
      };
    case ADDCARD_ATTEMPT:
      return { ...state, fetching: true, message: 'Adding Card', status: null };
    case ADDCARD_SUCCESS:
      if (action.payload.status === 200) {
        return {
          ...state,
          fetching: false,
          cards: state.cards.concat(action.payload.card),
          status: action.payload.status,
          message: 'Card Added Successfully',
          company: { ...state.company, defaultMethod: action.payload.company.defaultMethod },
          alertState: true,
        };
      } else {
        return {
          ...state,
          fetching: false,
          status: action.payload.status,
          error: action.payload.message,
          alertState: true,
        };
      }
    case ADDCARD_FAIL:
      return { ...state, fetching: false, error: action.payload, alertState: true };
    case UPDATECARD_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATECARD_SUCCESS:
      let updatedCards = state.cards.map((u) => {
        if (u.id === action.payload.card.id) {
          return action.payload.card;
        }
        return u;
      });
      return {
        ...state,
        fetching: false,
        cards: updatedCards,
        status: action.payload.status,
        message: action.payload.message,
        alertState: true,
      };
    case UPDATECARD_FAIL:
      return { ...state, fetching: false, error: action.payload, alertState: true };
    case SETDEFAULTCARD_ATTEMPT:
      return { ...state, fetching: true };
    case SETDEFAULTCARD_SUCCESS:
      return {
        ...state,
        fetching: false,
        company: { ...state.company, defaultMethod: action.payload.company.defaultMethod },
        status: action.payload.status,
        message: action.payload.message,
        alertState: true,
      };
    case SETDEFAULTCARD_FAIL:
      return { ...state, fetching: false, error: action.payload, alertState: true };
    case DELETECARD_ATTEMPT:
      return { ...state, fetching: true };
    case DELETECARD_SUCCESS:
      return {
        ...state,
        fetching: false,
        cards: state.cards.filter((a) => a.id !== action.payload.card.id),
        alertState: true,
        message: action.payload.message,
        status: 200,
      };
    case DELETECARD_FAIL:
      return { ...state, fetching: false, error: action.payload, alertState: true, status: 402 };
    case CREATEPURCHASE_ATTEMPT:
      return { ...state, fetching: true, status: null, message: null };
    case CREATEPURCHASE_SUCCESS:
      if (action.payload.status === 200) {
        return {
          ...state,
          fetching: false,
          message: action.payload.message,
          status: action.payload.status,
          company: { ...state.company, ...action.payload.company },
          alertState: true,
          subscription: action.payload.subscription,
        };
      } else {
        return {
          ...state,
          fetching: false,
          error: action.payload.message,
          status: action.payload.status,
          alertState: true,
        };
      }
    case CREATEPURCHASE_FAIL:
      return { ...state, fetching: false, error: action.payload.message, alertState: true };
    case UPDATEPURCHASE_ATTEMPT:
      return { ...state, fetching: true, status: null, message: null };
    case UPDATEPURCHASE_SUCCESS:
      if (action.payload.status === 200) {
        return {
          ...state,
          fetching: false,
          message: action.payload.message,
          status: action.payload.status,
          company: { ...state.company, ...action.payload.company },
          alertState: true,
          subscription: { ...state.subscription, ...action.payload.subscription },
        };
      } else {
        return {
          ...state,
          fetching: false,
          error: action.payload.message,
          status: action.payload.status,
          alertState: true,
        };
      }
    case UPDATEPURCHASE_FAIL:
      return { ...state, fetching: false, error: action.payload, status: 400, alertState: true };
    case FETCHACTIVESUBSCRIPTION_ATTEMPT:
      return { ...state, fetching: true };
    case FETCHACTIVESUBSCRIPTION_SUCCESS:
      return { ...state, fetching: false, subscription: action.payload };
    case FETCHACTIVESUBSCRIPTION_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case CANCELSUBSCRIPTION_ATTEMPT:
      return { ...state, fetching: true };
    case CANCELSUBSCRIPTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        message: action.payload.message,
        status: action.payload.status,
        alertState: true,
        subscription: { ...state.subscription, ...action.payload.subscription },
      };
    case CANCELSUBSCRIPTION_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case REACTIVATESUBSCRIPTION_ATTEMPT:
      return { ...state, fetching: true };
    case REACTIVATESUBSCRIPTION_SUCCESS:
      if (action.payload.status === 200) {
        return {
          ...state,
          fetching: false,
          message: action.payload.message,
          status: action.payload.status,
          alertState: true,
          subscription: { ...state.subscription, ...action.payload.subscription },
        };
      } else {
        return {
          ...state,
          fetching: false,
          error: action.payload.message,
          status: action.payload.status,
          alertState: true,
        };
      }
    case REACTIVATESUBSCRIPTION_FAIL:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
