import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Grid, TextField, CircularProgress, Button, Collapse } from '@mui/material';
import { Typography } from '@mui/material';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import { Alert, AlertTitle, Paper } from '@mui/material';
import { blue } from '@mui/material/colors';

import { validateEmail, validateUkNumber } from '../../../Global/Functions';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../../constants/msal';
import { getGroupLink } from '../../../../constants';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { Microsoft365Icon } from '../../../Global/Icons';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Loader from '../../../Global/Loader';

export default function GroupSignUp() {
  const [data, setData] = useState({
    name: '',
    password: '',
    confirmPassword: '',
    phone: '',
    nameError: false,
    passwordError: false,
    emailError: false,
    loggedIn: false,
    loading: false,
    success: false,
    errorText: '',
    firstNameError: false,
    lastNameError: false,
    phoneError: false,
  });

  const { linkId } = useParams();
  const loginLink = getGroupLink(linkId, 'signin', false);

  const [group, setGroup] = useState(null);
  const [groupLoading, setGroupLoading] = useState(true);
  const [groupError, setGroupError] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [ssoLoading, setSsoLoading] = useState(false);
  const [ssoJWT, setSsoJWT] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const { instance, inProgress, accounts } = useMsal();

  function verify() {
    if (!data.name) {
      setData({ ...data, errorText: 'Provide company name', nameError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!firstName) {
      setData({ ...data, errorText: 'Provide your first name', firstNameError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!lastName) {
      setData({ ...data, errorText: 'Provide your last name', lastNameError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!validateEmail(String(email).trim())) {
      setData({ ...data, errorText: 'Provide correct email', emailError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!data.password) {
      setData({ ...data, errorText: 'Provide password', passwordError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (data.password !== data.confirmPassword) {
      setData({ ...data, errorText: 'Passwords do not match', passwordError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }

    if (!validateUkNumber(String(data.phone).trim())) {
      setData({ ...data, errorText: 'Provide correct UK number', phoneError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }

    setData({
      ...data,
      loading: true,
      nameError: false,
      passwordError: false,
      emailError: false,
      firstNameError: false,
      lastNameError: false,
      phoneError: false,
      errorText: '',
    });

    axios
      .post('/auth/createCompany', {
        name: data.name,
        firstName,
        lastName,
        email,
        password: data.password,
        mobile: data.phone,
        groupId: group._id,
      })
      .then((res) => {
        setData({ ...data, success: true, loading: false });
      })
      .catch((err) => {
        setData({ ...data, loading: false, errorText: err?.response?.data?.message || 'Something went wrong' });
        setTimeout(() => {
          setData({ ...data, errorText: '' });
        }, 8000);
      });
  }

  function verifySSO() {
    if (!data.name) {
      setData({ ...data, errorText: 'Provide company name', nameError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!firstName) {
      setData({ ...data, errorText: 'Provide your first name', firstNameError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!lastName) {
      setData({ ...data, errorText: 'Provide your last name', lastNameError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!validateEmail(String(email).trim())) {
      setData({ ...data, errorText: 'Provide correct email', emailError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }

    if (!validateUkNumber(String(data.phone).trim())) {
      setData({ ...data, errorText: 'Provide correct UK number', phoneError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }

    setData({
      ...data,
      loading: true,
      nameError: false,
      passwordError: false,
      emailError: false,
      firstNameError: false,
      lastNameError: false,
      phoneError: false,
      errorText: '',
    });

    axios
      .post(
        '/auth/createCompanyWithSSO',
        {
          companyName: data.name,
          firstName,
          lastName,
          email,
          mobile: data.phone,
          groupId: group._id,
        },
        {
          headers: {
            Authorization: ssoJWT,
            accesstoken: accessToken,
          },
        },
      )
      .then((res) => {
        setData({ ...data, loading: false });
        if (res.data.success && res.data.authJSON) {
          localStorage.clear();
          localStorage.setItem('token', res.data.authJSON.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authJSON.name);
          localStorage.setItem('_id', res.data.authJSON.id);
          localStorage.setItem('company', res.data.authJSON.company);
          localStorage.setItem('access', res.data.authJSON.access);
          window.location.href = '/home';
        } else {
          if (res.data.forbiddenToken) {
            localStorage.setItem('forbiddenToken', res.data.forbiddenToken);
            window.location.href = '/suspended';
          } else if (res.data.companyArchived) {
            window.location.href = '/suspended';
          } else if (res.data.trialEnded || res.data.subscriptionCancelled) {
            window.location.href = '/expired';
          } else if (res.data.userDeleted || res.data.userSuspended) {
            window.location.href = '/suspended';
          } else {
            setData({ ...data, loading: false, errorText: res.data.message || 'Something went wrong' });
          }
        }
      })
      .catch((err) => {
        setData({ ...data, loading: false, errorText: err?.response?.data?.message || 'Something went wrong' });
        setTimeout(() => {
          setData({ ...data, errorText: '' });
        }, 8000);
      });
  }

  const handleSSOSignup = async () => {
    try {
      setSsoLoading(true);
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      setSsoLoading(false);
      console.error(`Authentication error: ${error}`);
    }
  };

  useEffect(() => {
    if (!linkId) return;
    localStorage.removeItem('token');
    setGroupLoading(false);
    axios
      .get(`/auth/getGroup/${linkId}`)
      .then((res) => {
        setGroupLoading(false);
        if (res.data.success) {
          setGroup(res.data.group);
          return;
        } else {
          setGroupError(res.data.message);
        }
      })
      .catch((err) => {
        setGroupLoading(false);
        setGroupError(err?.response?.data?.message || 'Something went wrong');
      });
  }, [linkId]);

  useEffect(() => {
    const sessionKeys = Object.keys(sessionStorage);
    if (!accounts || accounts?.length === 0 || sessionKeys?.length === 0) return;
    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    setSsoLoading(true);
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Call your web api for oauth
          const jwt = accessTokenResponse.idToken;
          const idClaims = accessTokenResponse.idTokenClaims;
          const accesstoken = accessTokenResponse.accessToken;
          let ssoEmail = idClaims.preferred_username;
          setEmail(ssoEmail);
          const [ssoFirstName, ssoLastName] = idClaims.name.split(' ');
          console.log(idClaims.name, idClaims.name.split(' '));
          setFirstName(ssoFirstName);
          setLastName(ssoLastName);
          axios
            .get('https://graph.microsoft.com/oidc/userinfo', { headers: { Authorization: `Bearer ${accesstoken}` } })
            .then((res) => {
              const e = res.data.email;
              if (e) {
                setEmail(e);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setSsoJWT(jwt);
          setAccessToken(accesstoken);
          setSsoLoading(false);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }, [instance, accounts, inProgress]);

  return (
    <Grid container>
      {groupLoading && <Loader />}
      {!groupLoading && !group && groupError && (
        <Grid container item justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
          <Paper
            elevation={4}
            style={{
              marginTop: '5vh',
              borderRadius: 12,
              padding: '1rem',
              width: '100%',
              width: '80vw',
              maxWidth: 500,
            }}
          >
            <Grid container justifyContent="center">
              <Alert severity="error" sx={{ width: '100%', borderRadius: 1 }}>
                <AlertTitle>Error</AlertTitle>
                {groupError}
              </Alert>
            </Grid>
          </Paper>
        </Grid>
      )}
      {!groupLoading && group && (
        <>
          {group?.logo && (
            <Grid container item justifyContent="center">
              <img alt="img" src={group?.logo} style={{ height: 80, marginTop: 50 }} />
            </Grid>
          )}
          {group.name && (
            <Grid
              container
              item
              justifyContent={'center'}
              sx={{
                marginTop: !group.logo ? '50px' : '10px',
              }}
            >
              <Typography variant="h4" gutterBottom>
                {group.name}
              </Typography>
            </Grid>
          )}
          <Grid container item justifyContent="center">
            <Paper
              elevation={4}
              style={{
                marginTop: '5vh',
                borderRadius: 12,
                padding: '1rem',
                width: '100%',
                width: '80vw',
                maxWidth: 500,
              }}
            >
              {data?.success ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="h4" gutterBottom>
                    Account Created!
                  </Typography>
                  <Typography style={{ textAlign: 'center', margin: '2rem 0' }}>
                    We have sent you a confirmation email, please check your inbox and click on the verification link to
                    confirm your email.
                  </Typography>
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  <Grid container item justifyContent="space-between" spacing={1}>
                    <Grid container item lg={12} md={12} xs={12}>
                      <TextField
                        required
                        error={data.nameError}
                        fullWidth
                        value={data.name}
                        label="Company name"
                        variant="outlined"
                        onChange={(e) => {
                          setData({ ...data, name: e.target.value, nameError: false, errorText: '' });
                        }}
                        inputProps={{ maxLength: 100 }}
                        id="sugn-up-company-name"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item lg={6} md={6} xs={12}>
                    <TextField
                      style={{ marginRight: 10 }}
                      required
                      error={data.firstNameError}
                      fullWidth
                      value={firstName}
                      label="First name"
                      variant="outlined"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setData({ ...data, firstName: e.target.value, firstNameError: false, errorText: '' });
                      }}
                      inputProps={{ maxLength: 64 }}
                      id="sugn-up-first-name"
                    />
                  </Grid>
                  <Grid container item lg={6} md={6} xs={12}>
                    <TextField
                      required
                      error={data.lastNameError}
                      fullWidth
                      value={lastName}
                      label="Last name"
                      variant="outlined"
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setData({ ...data, lastName: e.target.value, lastNameError: false, errorText: '' });
                      }}
                      inputProps={{ maxLength: 64 }}
                      id="sugn-up-last-name"
                    />
                  </Grid>
                  <Grid container item lg={12} md={12} xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="tel"
                      value={data.phone}
                      error={data.phoneError}
                      label="Contact Number"
                      variant="outlined"
                      onChange={(e) => {
                        const phone = String(e.target.value).replace(/\D/g, '');
                        if (phone.length > 11) return;
                        setData({ ...data, phone });
                      }}
                      id="sugn-up-number"
                    />
                  </Grid>
                  <Grid container item lg={12} md={12} xs={12}>
                    <TextField
                      required
                      error={data.emailError}
                      fullWidth
                      value={email}
                      label="Email"
                      type="email"
                      disabled={ssoJWT}
                      variant="outlined"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setData({ ...data, emailError: false, errorText: '' });
                      }}
                      inputProps={{ maxLength: 64 }}
                      id="sugn-up-email"
                    />
                  </Grid>
                  {!ssoJWT && (
                    <Grid container item lg={12} md={12} xs={12}>
                      <TextField
                        required
                        fullWidth
                        value={data.password}
                        error={data.passwordError}
                        label="Password"
                        type="password"
                        variant="outlined"
                        inputProps={{ maxLength: 64 }}
                        onChange={(e) => {
                          setData({ ...data, password: e.target.value, errorText: '', passwordError: false });
                        }}
                        id="sugn-up-password"
                        autoComplete="current-password"
                      />
                    </Grid>
                  )}
                  {!ssoJWT && (
                    <Grid container item lg={12} md={12} xs={12}>
                      <TextField
                        required
                        fullWidth
                        value={data.confirmPassword}
                        error={data.passwordError}
                        label="Confirm Password"
                        type="password"
                        variant="outlined"
                        inputProps={{ maxLength: 64 }}
                        onChange={(e) => {
                          setData({ ...data, confirmPassword: e.target.value, errorText: '', passwordError: false });
                        }}
                        autoComplete="current-password"
                        id="new_password"
                        name="password"
                      />
                    </Grid>
                  )}
                  {(data.loading || ssoLoading) && (
                    <Grid container item justifyContent="center" sx={{ m: ssoLoading ? 3 : 0, p: 2 }}>
                      <CircularProgress color="primary" />
                    </Grid>
                  )}
                  {data.errorText && (
                    <Grid container item>
                      <Collapse style={{ width: '100%' }} in={Boolean(data.errorText)}>
                        <Alert severity="error" style={{ width: '100%', marginBottom: 16, borderRadius: 8 }}>
                          <AlertTitle>Error</AlertTitle>
                          {data.errorText}
                        </Alert>
                      </Collapse>
                    </Grid>
                  )}
                  <Grid container item sx={{ pb: 2 }} lg={12} md={12} xs={12}>
                    <Grid container item justifyContent="center">
                      {!ssoJWT ? (
                        <Button
                          startIcon={<CheckCircleOutlineRounded />}
                          variant="contained"
                          color="primary"
                          onClick={verify}
                          style={{ borderRadius: 8, width: '50%' }}
                        >
                          Sign Up
                        </Button>
                      ) : (
                        <Button
                          endIcon={<ChevronRight />}
                          variant="contained"
                          color="primary"
                          onClick={verifySSO}
                          style={{ borderRadius: 8, width: '50%' }}
                        >
                          Continue
                        </Button>
                      )}
                    </Grid>
                    {!ssoJWT && (
                      <Grid container item justifyContent="center" sx={{ my: 1 }}>
                        <Button
                          variant="contained"
                          onClick={handleSSOSignup}
                          startIcon={<Microsoft365Icon />}
                          sx={{
                            borderRadius: 1,
                            backgroundColor: '#2f2f2f',
                            color: 'white',
                          }}
                        >
                          Sign Up with Microsoft
                        </Button>
                      </Grid>
                    )}
                    <Grid container item justifyContent="center"></Grid>
                    <Grid container item justifyContent="center" style={{ paddingTop: 8 }}>
                      <Typography>Already have an account?</Typography>
                    </Grid>
                    <Grid container item justifyContent="center" style={{ paddingTop: 8 }}>
                      <Link to={loginLink}>
                        <Typography style={{ color: blue[800] }}>Log In</Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}
