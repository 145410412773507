import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Menu, MenuItem, Typography, Grid, useMediaQuery } from '@mui/material';
import { FormControlLabel, Box, Collapse, Switch } from '@mui/material';
import { Description, ArrowForward, FileCopy } from '@mui/icons-material';
import { Done, ArrowDropDown, CheckBoxOutlineBlank } from '@mui/icons-material';
import moment from 'moment';
import { RoundedDialog, CheckBoxItem, DatePicker, DateTimePicker } from '../../Global/Components';
import { StatusButton } from './Components';
import { default as UserDialog } from '../../Global/SelectUserDialog';

const TaskStatusButton = StatusButton;

export { TaskStatusButton };

export function DueDate({ editable, value, setValue, included = false }) {
  const defaultDueDate = moment().add(1, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 });
  return (
    <Grid container item>
      <Grid container item alignItems="center">
        {!value && !editable && (
          <Typography color="textSecondary" variant="h6">
            This task has no due date
          </Typography>
        )}
        {editable && (
          <Grid item container>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={Boolean(value) || included}
                  onChange={() => setValue(value ? null : defaultDueDate)}
                />
              }
              label="Include due date"
            />
          </Grid>
        )}
        <Collapse in={Boolean(value) || included} style={{ width: '100%' }}>
          <Grid container alignItems="center">
            <Typography sx={{ fontWeight: 500, mr: 2 }}>Due date</Typography>

            <DateTimePicker disableContainer disabled={!editable} value={value} onChange={setValue} label="" />
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
}

export function StartAndEndDates(props) {
  return (
    <Grid container alignItems={'center'} spacing={1}>
      <Grid container item alignItems={'center'} wrap="nowrap" lg={6} md={6} xs={6}>
        <Grid item>
          <Typography sx={{ fontWeight: 600, mr: 2 }} noWrap>
            Start date
          </Typography>
        </Grid>

        <Grid item>
          <DatePicker value={props.start} onChange={(date) => props.setStart(date)} />
        </Grid>
      </Grid>

      <Grid container item alignItems={'center'} wrap="nowrap" lg={6} md={6} xs={6}>
        <Grid item>
          <Typography sx={{ fontWeight: 600, mr: 2 }} noWrap>
            End date
          </Typography>
        </Grid>

        <Grid item>
          <DatePicker value={props.end} onChange={(date) => props.setEnd(date)} />
        </Grid>
      </Grid>
    </Grid>
  );
}

const SelectUserDialog = UserDialog;

export { SelectUserDialog };

export function HubTaskViewer(props) {
  // hub task info small dialog
  const [hubTaskInfo, setHubTaskInfo] = useState(false);

  if (!props.task.hubTaskInfo || !props.task.hubTaskInfo.hub) return null;

  //`/DocLibrary/${props.task.hubTaskInfo.category === 'qhub' ? 'documents' : 'controlled'}/${props.task.hubTaskInfo.action === 'review' ? 'review' : 'upissue'}-${props.task.hubTaskInfo.id}`
  function getDocHubLink() {
    if (props.task.hubTaskInfo.action === 'review') {
      if (props.task.hubTaskInfo.link && !props.task.hubTaskInfo.reviewId) {
        return `${props.task.hubTaskInfo.link}/${props.task._id}`;
      }
      if (props.task.hubTaskInfo.reviewId)
        return `/DocLibrary/hublink/review/update/${props.task.hubTaskInfo.id}/${props.task.hubTaskInfo.reviewId}`;
      else return `/DocLibrary/hublink/review/${props.task.hubTaskInfo.id}/${props.task._id}`;
    } else if (props.task.hubTaskInfo.action === 'upissue') {
      if (props.task.hubTaskInfo.editLink) return props.task.hubTaskInfo.editLink;
      if (props.task.hubTaskInfo.link) return props.task.hubTaskInfo.link;
      return `/DocLibrary/hublink/upissue/${props.task.hubTaskInfo.id}`;
    }

    return '#';
  }

  function getFormHubLink() {
    const access = localStorage.getItem('access');
    if (access === 'portal') return '/portal/form';
    else return props.task.hubTaskInfo.action === 'upissue' ? '/formHub/formRevision' : '/formHub/useForm';
  }

  if (!props.task.completedDate && !props.task.archivedAt) {
    return (
      <div style={{ padding: '2em' }}>
        <div>
          <div
            style={{
              borderRadius: '10px',
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
              margin: '10px auto',
              width: '70%',
            }}
          >
            {(() => {
              if (props.task.hubTaskInfo.hub === 'form') {
                return (
                  <Link
                    color="inherit"
                    underline="none"
                    to={getFormHubLink()}
                    onClick={() => {
                      localStorage.setItem('formID', props.task.hubTaskInfo.id);
                      localStorage.setItem('formAction', props.task.hubTaskInfo.action);
                      localStorage.setItem('taskId', props.task._id);
                    }}
                  >
                    <div style={{ display: 'flex', padding: '0.5em' }}>
                      <Description style={{ color: '#64dd17', margin: 'auto 5px' }} />
                      <Typography style={{ fontWeight: 'bold', margin: '10px' }}>
                        {`${props.task.hubTaskInfo.action === 'submit' ? 'Submit' : 'Up issue'} a form: ${
                          props.task.hubTaskInfo.title
                        }`}
                      </Typography>
                      <ArrowForward style={{ margin: 'auto 5px auto auto' }} />
                    </div>
                  </Link>
                );
              } else {
                return (
                  <Link color="inherit" underline="none" to={getDocHubLink()}>
                    <div style={{ display: 'flex', padding: '0.5em' }}>
                      <FileCopy style={{ color: '#2196f3', margin: 'auto 5px' }} />
                      <Typography style={{ fontWeight: 'bold', margin: '10px' }}>
                        {`${props.task.hubTaskInfo.action === 'review' ? 'Review' : 'Up issue'} document: ${
                          props.task.hubTaskInfo.title
                        }`}
                      </Typography>
                      <ArrowForward style={{ margin: 'auto 5px auto auto' }} />
                    </div>
                  </Link>
                );
              }
            })()}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ padding: '2em' }}>
        <div>
          <div
            style={{
              borderRadius: '10px',
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
              margin: '10px auto',
              width: '70%',
            }}
          >
            {props.task.hubTaskInfo.hub === 'form' ? (
              <div
                style={{ display: 'flex', padding: '0.5em', cursor: 'pointer' }}
                onClick={() => setHubTaskInfo(true)}
              >
                <Description style={{ color: '#64dd17', margin: 'auto 5px' }} />
                <Typography style={{ fontWeight: 'bold', margin: '10px' }}>
                  {`${props.task.hubTaskInfo.action === 'submit' ? 'Submit' : 'Up issue'} a form: ${
                    props.task.hubTaskInfo.title
                  }`}
                </Typography>
                <div style={{ position: 'relative', margin: 'auto 10px auto auto' }}>
                  <CheckBoxOutlineBlank style={{ width: '40px', height: '40px' }} />
                  <Done
                    style={{ color: '#64dd17', margin: 'auto 10px auto auto', position: 'absolute', left: 8, top: 6 }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', padding: '0.5em' }}>
                <FileCopy style={{ color: '#2196f3', margin: 'auto 5px' }} />
                <Typography style={{ fontWeight: 'bold', margin: '10px' }}>
                  {`${props.task.hubTaskInfo.action === 'review' ? 'Review' : 'Up issue'} document: ${
                    props.task.hubTaskInfo.title
                  }`}
                </Typography>
                <div style={{ position: 'relative', margin: 'auto 10px auto auto' }}>
                  <CheckBoxOutlineBlank style={{ width: '40px', height: '40px' }} />
                  <Done
                    style={{ color: '#64dd17', margin: 'auto 10px auto auto', position: 'absolute', left: 8, top: 6 }}
                  />
                </div>
              </div>
            )}
          </div>
          <HubTaskInfo
            completedDate={props.task.completedDate}
            hubTaskInfo={props.task.hubTaskInfo}
            hubTaskResult={props.task.hubTaskResult}
            open={hubTaskInfo}
            onClose={() => setHubTaskInfo(false)}
          />
        </div>
      </div>
    );
  }
}

function HubTaskInfo(props) {
  const history = useHistory();
  if (props.hubTaskInfo.hub !== 'form') return null;

  return (
    <RoundedDialog open={props.open} onClose={props.onClose}>
      <div style={{ display: 'grid', padding: '1em 2em' }}>
        <Typography style={{ margin: '1em auto' }}>{`Form entry: ${
          props.hubTaskResult ? props.hubTaskResult.entry_value : '666'
        }`}</Typography>
        <Typography style={{ margin: '1em auto' }}>
          {`Submission: ${moment(props.completedDate).format('D/MM/YYYY HH:mm')}`}
        </Typography>

        <div
          style={{ margin: '1em auto', padding: 5, borderRadius: 5, border: '1px solid lightgray', cursor: 'pointer' }}
          onClick={() => {
            localStorage.setItem('formID', props.hubTaskInfo.id);
            localStorage.setItem('previewId', props.hubTaskResult.id);
            history.push(`/formhub/forms/${props.hubTaskInfo.action === 'submit' ? 'preview' : 'forminfo'}`);
          }}
        >
          <Typography noWrap>{`Go to ${'form'}`}</Typography>
        </div>
      </div>
    </RoundedDialog>
  );
}

export function ReoccuringSettings({ style, repeatCycle, setRepeatCycle, repeatDays, setRepeatDays }) {
  const largeDevices = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [openRepeat, setOpenRepeat] = useState(false);
  const [openDays, setOpenDays] = useState(false);
  const repeatingPeriods = {
    1: 'Daily',
    7: 'Weekly',
    14: 'Fortnightly',
    30: 'Monthly',
    91: 'Every 3 Months',
    183: 'Every 6 Months',
    365: 'Yearly',
  };

  const periodsArray = [
    { value: 1, text: 'Daily' },
    { value: 7, text: 'Weekly' },
    { value: 14, text: 'Fortnightly' },
    { value: 30, text: 'Monthly' },
    { value: 91, text: 'Every 3 Months' },
    { value: 183, text: 'Every 6 Months' },
    { value: 265, text: 'Yearly' },
  ];

  const [days, setDays] = useState([
    { text: 'Monday', value: 'Mon', selected: true },
    { text: 'Tuesday', value: 'Tue', selected: true },
    { text: 'Wednesday', value: 'Wed', selected: true },
    { text: 'Thursday', value: 'Thu', selected: true },
    { text: 'Friday', value: 'Fri', selected: true },
    { text: 'Saturday', value: 'Sat', selected: false },
    { text: 'Sunday', value: 'Sun', selected: false },
  ]);

  useEffect(() => {
    if (repeatDays && repeatDays.length > 0) {
      let newDays = days;
      repeatDays.forEach((x) => {
        if (x.day) {
          let index = newDays.findIndex((d) => d.value === x.day);
          if (index > -1) {
            newDays[index].selected = x.repeat;
          }
        }
      });
      setDays([...newDays]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function checkDay(day) {
    let newDays = days;
    let index = newDays.findIndex((x) => x.value === day);
    if (index > -1) {
      newDays[index].selected = !newDays[index].selected;
    }
    setDays([...newDays]);
    if (setRepeatDays) setRepeatDays(newDays.map((x) => ({ day: x.value, repeat: x.selected })));
  }

  function getPeriod(number) {
    let str = repeatingPeriods[number];
    return str ? str : largeDevices ? 'Select Period' : 'Period';
  }

  return (
    <div style={style}>
      <div style={{ display: 'flex', justifyContent: largeDevices ? 'flex-start' : 'space-around' }}>
        <Typography style={{ fontSize: 16, color: '#212224', margin: 'auto 0' }} noWrap={largeDevices}>
          How often would you like the task to repeat?
        </Typography>
        <Button
          size={largeDevices ? 'medium' : 'small'}
          onClick={(event) => setOpenRepeat(event.currentTarget)}
          style={{ textTransform: 'none', background: 'whitesmoke', margin: '0 1rem' }}
        >
          {getPeriod(repeatCycle)}
          <ArrowDropDown style={{ cursor: 'pointer', color: '#6e6e6e' }} />
        </Button>

        <Menu
          anchorEl={openRepeat}
          keepMounted
          open={Boolean(openRepeat)}
          onClose={() => setOpenRepeat(null)}
          PaperProps={{
            style: {
              borderRadius: '0.5rem',
            },
          }}
        >
          {periodsArray.map((x, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setOpenRepeat(false);
                setRepeatCycle(x.value);
              }}
              style={{ borderBottom: '1px solid lightgrey' }}
            >
              {x.text}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div style={{ display: 'flex', marginTop: '1rem', justifyContent: largeDevices ? 'flex-start' : 'space-around' }}>
        <Button
          size={largeDevices ? 'medium' : 'small'}
          onClick={(event) => setOpenDays(event.currentTarget)}
          style={{ textTransform: 'none', background: 'whitesmoke', marginRight: '1rem' }}
        >
          {largeDevices ? 'Select days' : 'Days'}
          <ArrowDropDown style={{ cursor: 'pointer', color: '#6e6e6e' }} />
        </Button>

        <Typography style={{ fontSize: 16, color: '#212224', margin: 'auto 0' }} noWrap>{`On: ${days
          .filter((x) => x.selected)
          .map((x) => x.value)
          .join(', ')}`}</Typography>

        <Menu anchorEl={openDays} keepMounted open={Boolean(openDays)} onClose={() => setOpenDays(null)}>
          {days.map((x) => (
            <CheckBoxItem key={x.value} text={x.text} checked={x.selected} onChange={() => checkDay(x.value)} />
          ))}
        </Menu>
      </div>
    </div>
  );
}
