import React, { useEffect, useState } from 'react';
import { DialogContent, DialogTitle, List, ListItem, Collapse, CircularProgress } from '@mui/material';
import { ListItemAvatar, ListItemText, Grid, TextField, InputAdornment, IconButton } from '@mui/material';
import { ListItemIcon, Button, Avatar, useTheme, useMediaQuery, Tabs, Tab, Typography } from '@mui/material';
import { Search, CheckCircleRounded, CheckCircleOutlineRounded, ExpandMore, ExpandLess } from '@mui/icons-material';
import { Group, DeleteOutlineRounded } from '@mui/icons-material';
import { green, grey, red } from '@mui/material/colors';
import {
  RoundedDialog,
  User,
  StandardDialogActions,
  UserChip,
  TooltipTypography,
  GroupChip,
  PortalGroupChip,
} from '../Components';
import { isArray, isEmpty } from 'lodash';
import axios from 'axios';

import StateManager from '../StateManager';
import UserManager from '../UserManager';

export default function UsersDialog({
  open,
  onClose,
  link = '/user/getUsers',
  map = (x) => x._id,
  showGroups,
  showPortalGroups,
  initiallySelected,
  initiallySelectedGroups,
  initiallySelectedPortalGroups,
  includedUsers,
  excludeCurrent,
  excludedUsers,
  superGroupAdminsOnly,
  title,
  additionalActions,
  access,
  reload,
  withoutPortal,
  singleSelect,
  withClearButton,
}) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const userId = localStorage.getItem('_id');

  const [tab, setTab] = useState('normalUsers');
  const [loading, setLoading] = useState(false);
  const [pattern, setPattern] = useState('');
  const [groups, setGroups] = useState([]);
  const [portalGroups, setPortalGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedPortalGroups, setSelectedPortalGroups] = useState([]);
  const [deselectedGroups, setDeselectedGroups] = useState([]);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const loadGroups = open && groups.length === 0 && showGroups;
  const loadPortalGroups = open && portalGroups.length === 0 && showPortalGroups;

  const [users, setUsers] = useState([]);
  const includedFilter = Array.isArray(includedUsers) && includedUsers.length > 0 ? includedUsers : null;
  const accessFilter = access ? (Array.isArray(access) ? access : [access]) : null;

  const normalUsersAvailable = users.filter(
    (user) =>
      user.access !== 'portal' &&
      (!excludeCurrent || user._id !== userId) &&
      (!excludedUsers || !excludedUsers.includes(user._id)) &&
      (!superGroupAdminsOnly || (user.access === 'admin' && user.isSuperGroupAdmin)) &&
      (!includedFilter || includedFilter.includes(user._id)) &&
      (!accessFilter || accessFilter.includes(user.access)),
  );
  const filteredNormalUsers = normalUsersAvailable.filter(
    (user) => !pattern || user.fullName.toLowerCase().includes(pattern),
  );

  const portalUsersAvailable = users.filter(
    (user) =>
      user.access === 'portal' &&
      !withoutPortal &&
      (!excludeCurrent || user._id !== userId) &&
      (!excludedUsers || !excludedUsers.includes(user._id)) &&
      (!includedFilter || includedFilter.includes(user._id)) &&
      (!accessFilter || accessFilter.includes(user.access)),
  );

  const filteredPortalUsers = portalUsersAvailable.filter(
    (user) => !pattern || user.fullName.toLowerCase().includes(pattern),
  );

  const showNormalUsersTab = !isEmpty(normalUsersAvailable);
  const showPortalUsersTab = !isEmpty(portalUsersAvailable);
  const showGroupsTab = showGroups && !isEmpty(groups) && !singleSelect;
  const showPortalGroupsTab = showPortalGroups && !isEmpty(portalGroups) && !singleSelect;

  // show tabs header when there are at least 2 tabs
  const showTabsHeader =
    [showNormalUsersTab, showPortalUsersTab, showGroupsTab, showPortalGroupsTab].filter((x) => x).length > 1;

  // when dialog opened show the first tab
  useEffect(() => {
    if (!open) return;
    if (showNormalUsersTab) {
      setTab('normalUsers');
      return;
    }
    if (showPortalUsersTab) {
      setTab('portalUsers');
      return;
    }
    if (showGroupsTab) {
      setTab('groups');
      return;
    }
  }, [open, showNormalUsersTab, showPortalUsersTab, showGroupsTab]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deselectedUsers, setDeselectedUsers] = useState([]);
  const [disabled, setDisabled] = useState([]);

  useEffect(() => {
    if (!open || !link) return;
    setLoading(true);
    const promise = link === '/user/getUsers' ? UserManager.getUserList(true) : axios.get(link);
    promise
      .then((res) => {
        setLoading(false);
        setUsers(res.data);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [link, open]);

  useEffect(() => {
    if (!link || !reload) return;
    setLoading(true);
    const promise = link === '/user/getUsers' ? UserManager.getUserList() : axios.get(link);
    promise
      .then((res) => {
        setLoading(false);
        setUsers(res.data);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [reload]); // eslint-disable-line

  useEffect(() => {
    if (!loadGroups) return;
    UserManager.getGroupList()
      .then((res) => {
        setGroups(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadGroups]);

  useEffect(() => {
    if (!loadPortalGroups) return;

    UserManager.getPortalGroupList()
      .then(({ data }) => {
        setPortalGroups(data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadPortalGroups]);

  useEffect(() => {
    if (!isArray(initiallySelectedPortalGroups)) {
      setSelectedPortalGroups([]);
    } else {
      setSelectedPortalGroups(initiallySelectedPortalGroups);
    }
  }, [initiallySelectedPortalGroups, open]);

  useEffect(() => {
    if (!isArray(initiallySelected) || isEmpty(initiallySelected)) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(initiallySelected);
    }

    if (!isArray(initiallySelectedGroups) || isEmpty(initiallySelectedGroups)) {
      setSelectedGroups([]);
      updateSelectedUsers([], initiallySelected);
    } else {
      setSelectedGroups(initiallySelectedGroups);
      updateSelectedUsers(initiallySelectedGroups, initiallySelected);
    }
  }, [initiallySelected, initiallySelectedGroups, groups, users]); // eslint-disable-line

  /**
   * Makes users in selected groups unavailable to choose
   */
  function updateSelectedUsers(selectedGroups, allSelectedUsers) {
    if (!isArray(groups) || isEmpty(groups) || !isArray(users) || isEmpty(users)) return;
    const toDisable = groups.filter((x) => selectedGroups.includes(x._id)).flatMap((x) => x.users);

    setDisabled(toDisable);
    const result = (allSelectedUsers || selectedUsers).filter((x) => !toDisable.includes(x));
    setSelectedUsers(result);
  }

  function addDeleteUser(id) {
    let index = selectedUsers.indexOf(id);
    if (index > -1) {
      setSelectedUsers(selectedUsers.filter((x) => x !== id));
      setDeselectedUsers([...deselectedUsers, id]);
    } else {
      setSelectedUsers(singleSelect ? [id] : [...selectedUsers, id]);
    }
  }

  function addDeleteGroup(group, groupUsers) {
    if (selectedGroups.includes(group)) {
      const result = selectedGroups.filter((x) => x !== group);
      updateSelectedUsers(result);
      setSelectedGroups(result);
      setDeselectedGroups([...deselectedGroups, group]);
    } else {
      const result = [...selectedGroups, group];
      updateSelectedUsers(result);
      setSelectedGroups(result);
      if (groupUsers.length > 0) {
        groupUsers.forEach((x) => {
          if (selectedUsers.includes(x)) {
            setSelectedUsers(selectedUsers.filter((b) => b !== x));
            setDeselectedUsers([...deselectedUsers, x]);
          }
        });
      }
    }
  }

  function addDeletePortalGroup(groupId) {
    if (selectedPortalGroups.includes(groupId)) {
      const result = selectedPortalGroups.filter((x) => x !== groupId);
      setSelectedPortalGroups(result);
    } else {
      const result = [...selectedPortalGroups, groupId];
      setSelectedPortalGroups(result);
    }
  }

  function onSearch(value) {
    setPattern(value);
    const normal = normalUsersAvailable?.filter(
      (user) => !value || user.fullName?.toLowerCase().includes(value.trim()),
    );
    const portal = portalUsersAvailable?.filter(
      (user) => !value || user.fullName?.toLowerCase().includes(value.trim()),
    );
    if (normal.length > 0) {
      setTab('normalUsers');
    } else if (portal.length > 0) {
      setTab('portalUsers');
    }
  }

  function done() {
    if (showGroups || showPortalGroups) {
      const result = {
        users: map ? users.filter((x) => selectedUsers.includes(x._id)).map(map) : selectedUsers,
        groups: selectedGroups,
        portalGroups: selectedPortalGroups,
        deselectedGroups,
        deselectedUsers,
      };
      onClose(result);
    } else {
      const result = { users: map ? users.filter((x) => selectedUsers.includes(x._id)).map(map) : selectedUsers };
      onClose(result);
    }
  }

  function clear() {
    setSelectedUsers([]);
    setDeselectedUsers([]);
    setSelectedGroups([]);

    onClose({
      users: [],
      groups: [],
      portalGroups: [],
      deselectedGroups: [],
      deselectedUsers: [],
    });
  }

  return (
    <RoundedDialog
      className="scroll-bar"
      maxWidth="xs"
      fullWidth
      open={Boolean(open)}
      onClose={() => onClose(null)}
      fullScreen={!largeDevices}
    >
      <DialogTitle>{title || 'Select users'}</DialogTitle>
      <DialogContent dividers style={{ padding: theme.spacing(2, 1) }}>
        {loading ? (
          <Grid container style={{ height: '30vh' }} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid container style={{ minHeight: '3rem' }}>
              {selectedUsers.map((id, i) => (
                <UserChip margin={4} size="small" key={i} id={id} onDelete={() => addDeleteUser(id)} />
              ))}
              {selectedGroups.map((id, i) => (
                <GroupChip margin={4} size="small" key={i} id={id} onDelete={() => addDeleteGroup(id)} />
              ))}
              {selectedPortalGroups.map((id, i) => (
                <PortalGroupChip margin={4} size="small" key={i} id={id} onDelete={() => addDeletePortalGroup(id)} />
              ))}
            </Grid>
            <Grid container style={{ padding: theme.spacing(1) }}>
              <TextField
                placeholder="Search"
                fullWidth
                value={pattern}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search style={{ color: 'lightgray' }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => onSearch(event.target.value.toLowerCase())}
                variant="standard"
              />
            </Grid>
            <Grid container>
              {showTabsHeader && (
                <Tabs indicatorColor="primary" textColor="primary" value={tab} sx={{ mb: 1, width: '100%' }}>
                  {showNormalUsersTab && (
                    <Tab label="Users" value="normalUsers" onClick={() => setTab('normalUsers')} />
                  )}
                  {showPortalUsersTab && (
                    <Tab label="Portal" value="portalUsers" onClick={() => setTab('portalUsers')} />
                  )}
                  {showGroupsTab && <Tab label="Groups" value="groups" onClick={() => setTab('groups')} />}
                  {showPortalGroupsTab && (
                    <Tab label="Portal roles" value="portalGroups" onClick={() => setTab('portalGroups')} />
                  )}
                </Tabs>
              )}
              {tab === 'normalUsers' && (
                <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%', marginTop: theme.spacing(1) }}>
                  {isEmpty(normalUsersAvailable) && (
                    <Grid container item>
                      <Typography variant="h6" color="textSecondary">
                        No users available
                      </Typography>
                    </Grid>
                  )}
                  {!isEmpty(normalUsersAvailable) && isEmpty(filteredNormalUsers) && (
                    <Grid container item>
                      <Typography variant="h6" color="textSecondary">
                        No users match your search
                      </Typography>
                    </Grid>
                  )}
                  {filteredNormalUsers.map((user, i) => (
                    <ListItem
                      key={user._id}
                      button
                      style={{ borderRadius: 5 }}
                      onClick={() => {
                        addDeleteUser(user._id);
                        setPattern('');
                      }}
                      disabled={disabled.includes(user._id)}
                    >
                      <ListItemAvatar>
                        <Avatar src={user.avatar} style={{ background: user.avatarColor }}>
                          {user.avatarLetters}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography>
                            {user.fullName}{' '}
                            {user.access === 'portal' ? (
                              <span style={{ fontWeight: 600, color: green[500] }}>portal</span>
                            ) : (
                              ''
                            )}
                          </Typography>
                        }
                        secondary={
                          user.access === 'portal' && user.portalspaces && user.portalspaces[0] ? (
                            <TooltipTypography color="textSecondary" style={{ fontSize: 14 }}>
                              {user.portalspaces.map((x) => x.title).join(', ')}{' '}
                            </TooltipTypography>
                          ) : undefined
                        }
                      />
                      <ListItemIcon style={{ marginLeft: 'auto' }}>
                        {selectedUsers.includes(user._id) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )}
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              )}
              {tab === 'portalUsers' && (
                <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%', marginTop: theme.spacing(1) }}>
                  {isEmpty(portalUsersAvailable) && (
                    <Grid container item>
                      <Typography variant="h6" color="textSecondary">
                        No users available
                      </Typography>
                    </Grid>
                  )}
                  {!isEmpty(portalUsersAvailable) && isEmpty(filteredPortalUsers) && (
                    <Grid container item>
                      <Typography variant="h6" color="textSecondary">
                        No users match your search
                      </Typography>
                    </Grid>
                  )}
                  {filteredPortalUsers.map((user, i) => (
                    <ListItem
                      key={user._id}
                      button
                      style={{ borderRadius: 5 }}
                      onClick={() => {
                        addDeleteUser(user._id);
                        setPattern('');
                      }}
                      disabled={disabled.includes(user._id)}
                    >
                      <ListItemAvatar>
                        <Avatar src={user.avatar} style={{ background: user.avatarColor }}>
                          {user.avatarLetters}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography>
                            {user.fullName}{' '}
                            {user.access === 'portal' ? (
                              <span style={{ fontWeight: 600, color: green[500] }}>portal</span>
                            ) : (
                              ''
                            )}
                          </Typography>
                        }
                        secondary={
                          user.access === 'portal' && user.portalspaces && user.portalspaces[0] ? (
                            <TooltipTypography color="textSecondary" style={{ fontSize: 14 }}>
                              {user.portalspaces.map((x) => x.title).join(', ')}{' '}
                            </TooltipTypography>
                          ) : undefined
                        }
                      />
                      <ListItemIcon style={{ marginLeft: 'auto' }}>
                        {selectedUsers.includes(user._id) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )}
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              )}
              {tab === 'groups' && (
                <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
                  {groups
                    .filter((g) => g.name.toLowerCase().includes(pattern))
                    .map((group, i) => (
                      <React.Fragment key={group._id}>
                        <ListItem style={{ borderRadius: 5 }}>
                          <ListItemAvatar>
                            <Avatar>
                              <Group />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={group.name} />
                          <IconButton
                            onClick={() => {
                              if (expandedGroup === group._id) {
                                setExpandedGroup(null);
                              } else {
                                setExpandedGroup(group._id);
                              }
                            }}
                            style={{ marginLeft: 'auto' }}
                          >
                            {expandedGroup === group._id ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                          <IconButton onClick={() => addDeleteGroup(group._id, group.users)}>
                            {selectedGroups.includes(group._id) ? (
                              <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                            ) : (
                              <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                            )}
                          </IconButton>
                        </ListItem>
                        <Collapse in={expandedGroup === group._id} style={{ width: '100%' }}>
                          <Grid container style={{ padding: theme.spacing(1, 2) }}>
                            {group.users.map((user, i) => (
                              <User key={i} id={user} fullWidth />
                            ))}
                          </Grid>
                        </Collapse>
                      </React.Fragment>
                    ))}
                </List>
              )}
              {tab === 'portalGroups' && (
                <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
                  {portalGroups
                    .filter((g) => g.title.toLowerCase().includes(pattern))
                    .map((group, i) => (
                      <React.Fragment key={group._id}>
                        <ListItem style={{ borderRadius: 5 }}>
                          <ListItemAvatar>
                            <Avatar>
                              <Group />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={group.title} />
                          <IconButton onClick={() => addDeletePortalGroup(group._id)}>
                            {selectedPortalGroups.includes(group._id) ? (
                              <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                            ) : (
                              <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                            )}
                          </IconButton>
                        </ListItem>
                      </React.Fragment>
                    ))}
                </List>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          <>
            {withClearButton &&
              (!isEmpty(selectedUsers) || !isEmpty(selectedGroups) || !isEmpty(selectedPortalGroups)) && (
                <Button sx={{ color: red[500] }} onClick={clear} startIcon={<DeleteOutlineRounded />}>
                  Clear
                </Button>
              )}
            {additionalActions}

            <Grid style={{ marginRight: 'auto' }} />
          </>
        }
        onClose={() => onClose(null)}
        onDone={done}
      />
    </RoundedDialog>
  );
}
