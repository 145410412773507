import React, { useEffect, useState } from 'react';
import { DialogContent, CircularProgress, Grid, DialogTitle, DialogActions, Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { RoundedDialog } from '../../../Global/Components';
import { Alert } from '@mui/material';
import axios from 'axios';

export default function UserRequest() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { type, token } = useParams();

  useEffect(() => {
    if (!type || !token) return;
    axios
      .post('/auth/makeUserRequest', { type, token })
      .then(({ data }) => {
        if (data.success === true) {
          setSuccess(true);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [type, token]);

  if (success) {
    return (
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        style={{
          height: '100vh',
          backgroundImage: 'url(/appHome.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <Alert severity="success" style={{ width: '100%', borderRadius: 8 }}>
              We'll be in touch with you soon
            </Alert>
          </DialogContent>
          <DialogActions>
            <Link to="/login">
              <Button>go to the app</Button>
            </Link>
          </DialogActions>
        </RoundedDialog>
      </Grid>
    );
  }

  if (error) {
    return (
      <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Alert severity="error" style={{ width: '100%', borderRadius: 8 }}>
            Oops, something went wrong
          </Alert>
        </DialogContent>
        <DialogActions>
          <Link to="/login">
            <Button>go to the app</Button>
          </Link>
        </DialogActions>
      </RoundedDialog>
    );
  }

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
      <CircularProgress color="primary" />
    </Grid>
  );
}
