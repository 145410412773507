import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { UserGroup } from '../../Components';
import { isEmpty, isArray } from 'lodash';
import { PeopleAltRounded } from '@mui/icons-material';
import UsersDialog from '../../UsersDialog';

/**
 * For picking from company users
 */
export default function UserInput({ item, applyValue }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    setSelectedUsers(isArray(item?.value) ? item.value : []);
  }, [item]);

  function selectUsers() {
    setDialog(true);
  }

  function applySelection(selected) {
    setDialog(false);
    if (!selected?.users) return;
    setSelectedUsers(selected.users);
    applyValue({ ...item, value: selected.users });
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: 2,
      }}
    >
      {isEmpty(selectedUsers) ? (
        <Button onClick={selectUsers} startIcon={<PeopleAltRounded />}>
          pick users
        </Button>
      ) : (
        <UserGroup ids={selectedUsers} avatarSize={32} onClick={selectUsers} />
      )}

      <UsersDialog open={dialog} onClose={applySelection} initiallySelected={selectedUsers} />
    </Box>
  );
}
