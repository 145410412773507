import { useState, useEffect } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import {
  FiberManualRecord,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  KeyboardArrowUpRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { isArray, isEmpty } from 'lodash';
import { v4 } from 'uuid';

export default function Legend({ direction = 'row', items, style, ...props }) {
  return direction === 'column' ? (
    <VerticalLegend items={items} style={style} {...props} />
  ) : (
    <HorizontalLegend items={items} style={style} {...props} />
  );
}

function VerticalLegend({ items, style, ...props }) {
  const [id] = useState(v4());
  const [hasUpArrow, setHasUpArrow] = useState(false);
  const [hasDownArrow, setHasDownArrow] = useState(true);
  const hideButtons = !hasUpArrow && !hasDownArrow;

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (!element) {
        return;
      }

      setHasDownArrow(element.scrollHeight > element.offsetHeight);
    }, 300); // timeout is to let it render
  }, [id]);

  if (!isArray(items) || isEmpty(items)) return null;

  function scrollDown() {
    const element = document.getElementById(id);
    if (!element) return;

    const top = element.scrollTop + element.offsetHeight * 0.8;

    element.scrollTo({
      top,
      behavior: 'smooth',
    });

    setHasUpArrow(top > 0);
    setHasDownArrow(top + element.offsetHeight < element.scrollHeight);
  }

  function scrollUp() {
    const element = document.getElementById(id);
    if (!element) return;

    const top = element.scrollTop - element.offsetHeight * 0.8;

    element.scrollTo({
      top,
      behavior: 'smooth',
    });

    setHasUpArrow(top > 0);
    setHasDownArrow(top + element.offsetHeight < element.scrollHeight);
  }

  return (
    <Grid
      container
      alignItems={'center'}
      style={{ height: '100%', maxHeight: '100%', ...style }}
      direction={'column'}
      wrap="nowrap"
      {...props}
    >
      {!hideButtons && (
        <Grid container justifyContent={'center'}>
          <IconButton size="small" onClick={scrollUp} disabled={!hasUpArrow}>
            <KeyboardArrowUpRounded style={{ cursor: 'pointer' }} />
          </IconButton>
        </Grid>
      )}

      <Grid container alignItems={'center'} alignContent={'center'} id={id} style={{ overflow: 'hidden', flexGrow: 1 }}>
        {items.map((item, i) => (
          <LegendItem key={i} item={item} fullWidth />
        ))}
      </Grid>

      {!hideButtons && (
        <Grid container justifyContent={'center'}>
          <IconButton size="small" onClick={scrollDown} disabled={!hasDownArrow}>
            <KeyboardArrowDownRounded style={{ cursor: 'pointer', marginLeft: 'auto' }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

function HorizontalLegend({ items, style, ...props }) {
  const [id] = useState(v4());
  const [hasLeltArrow, setHasLeftArrow] = useState(false);
  const [hasRightArrow, setHasRightArrow] = useState(true);
  const hideButtons = !hasLeltArrow && !hasRightArrow;

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (!element) {
        return;
      }

      setHasRightArrow(element.scrollWidth > element.offsetWidth);
    }, 300); // timeout is to let it render
  }, [id]);

  if (!isArray(items) || isEmpty(items)) return null;

  function scrollRight() {
    const element = document.getElementById(id);
    if (!element) return;

    const left = element.scrollLeft + element.offsetWidth * 0.8;

    element.scrollTo({
      left,
      behavior: 'smooth',
    });

    setHasLeftArrow(left > 0);
    setHasRightArrow(left + element.offsetWidth < element.scrollWidth);
  }

  function scrollLeft() {
    const element = document.getElementById(id);
    if (!element) return;

    const left = element.scrollLeft - element.offsetWidth * 0.8;

    element.scrollTo({
      left,
      behavior: 'smooth',
    });

    setHasRightArrow(left + element.offsetWidth < element.scrollWidth);
    setHasLeftArrow(left > 0);
  }

  return (
    <Grid
      container
      alignItems={'center'}
      style={{ height: '100%', maxHeight: '100%', ...style }}
      wrap="nowrap"
      {...props}
    >
      {!hideButtons && (
        <Grid item>
          <IconButton size="small" onClick={scrollLeft} disabled={!hasLeltArrow}>
            <KeyboardArrowLeftRounded style={{ cursor: 'pointer' }} />
          </IconButton>
        </Grid>
      )}
      <Grid container style={{ position: 'relative', height: '100%', width: 'auto', flex: 1 }}>
        <Grid
          container
          justifyContent={hideButtons ? 'center' : 'flex-start'}
          alignItems={'center'}
          wrap="nowrap"
          id={id}
          style={{ position: 'absolute', height: '100%', overflow: 'hidden', minWidth: '100%', maxWidth: '100%' }}
        >
          {items.map((item, i) => (
            <LegendItem key={i} item={item} />
          ))}
        </Grid>
      </Grid>

      {!hideButtons && (
        <Grid item>
          <IconButton size="small" onClick={scrollRight} disabled={!hasRightArrow}>
            <KeyboardArrowRightRounded style={{ cursor: 'pointer', marginLeft: 'auto' }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

function LegendItem({ item, fullWidth }) {
  return (
    <Grid item container alignItems={'center'} wrap="nowrap" sx={{ width: fullWidth ? '100%' : 'fit-content', p: 0.4 }}>
      <FiberManualRecord sx={{ color: item?.color || grey[500] }} />
      <Typography noWrap>{item.label}</Typography>
    </Grid>
  );
}
