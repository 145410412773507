import React, { useState, useEffect, useRef } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Avatar } from '@mui/material';
import { Grid, DialogContent, IconButton, Typography, Tooltip } from '@mui/material';
import UserManager from '../UserManager';
import StateManager from '../StateManager';
import RoundedDialog from './RoundedDialog';
import ChatIcon from '@mui/icons-material/Chat';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { blue } from '@mui/material/colors';
import { FormatDate } from '../Functions';

const userId = localStorage.getItem('_id');

export default function ({ id, style, ...props }) {
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const subscription = useRef();

  useEffect(() => {
    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (id && typeof id === 'string') {
      setUser(UserManager.resolveUser(id));

      if (subscription.current) {
        subscription.current.unsubscribe();
      }
      subscription.current = UserManager.subscribeToUser(id, setUser);
    }
  }, [id]);

  if (!user?.fullName) return null;

  return (
    <>
      <span
        style={{
          background: blue[500],
          borderRadius: 4,
          paddingLeft: '4px',
          paddingRight: '4px',
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
          fontWeight: 500,
          color: 'white',
          whiteSpace: 'nowrap',
          ...style,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        {...props}
      >
        {user.avatar && (
          <img style={{ height: '1.2em', width: '1.2em', borderRadius: '50%', marginRight: 5 }} src={user.avatar} />
        )}
        {user.fullName}
      </span>
      {user._id && open && (
        <RoundedDialog open maxWidth="xs" fullWidth onClose={() => setOpen(false)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid container item alignItems="center">
                <Grid item lg={5} md={5} sm={12}>
                  <Avatar
                    src={user.avatar}
                    style={{ background: user.avatarColor, width: 140, height: 140, fontSize: 55 }}
                  >
                    {user.avatarLetters}
                  </Avatar>
                </Grid>
                <Grid item lg={7} md={7} sm={12}>
                  <Typography variant="h5" style={{ marginBottom: 5 }}>
                    {user.fullName}
                  </Typography>
                  {user.access === 'portal' && (
                    <Typography variant="h6" style={{ marginBottom: 5 }}>
                      Portal user
                    </Typography>
                  )}
                  {user.online ? (
                    <Typography style={{ color: '#2196f3' }}>online</Typography>
                  ) : (
                    <Typography style={{ fontSize: 15 }}>
                      {`${user.lastSeen ? 'last seen ' : ''}${FormatDate(user.lastSeen, true)}`}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container item>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <MailOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={user.email} />
                  </ListItem>
                  {user.mobile && (
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary={user.mobile} />
                    </ListItem>
                  )}
                  {user.jobRole && (
                    <ListItem>
                      <ListItemIcon>
                        <WorkOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary={user.jobRole} />
                    </ListItem>
                  )}
                </List>
              </Grid>

              {userId !== user._id && (
                <Grid container item alignItems="center" justifyContent="center">
                  <Grid item>
                    <Tooltip title="Send a message" placement="top" arrow>
                      <IconButton
                        onClick={() => {
                          setOpen(false);
                          StateManager.selectChat(user._id);
                        }}
                      >
                        <ChatIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item style={{ marginLeft: '1rem' }}>
                    <Tooltip title="Send a task" placement="top" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          StateManager.setNewTask({ userId: user._id });
                        }}
                      >
                        <WorkOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </RoundedDialog>
      )}
    </>
  );
}
