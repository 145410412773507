import React, { useEffect, useState } from 'react';
import { Typography, Grid, Chip, CircularProgress, Collapse } from '@mui/material';
import { FormGroup, Checkbox, FormControlLabel, Autocomplete, TextField, Paper, Button } from '@mui/material';
import { isArray, isEmpty, isFunction } from 'lodash';
import axios from 'axios';
import StateManager from '../../../Global/StateManager';
import { CheckCircleOutlineRounded, CheckCircleRounded, DoneRounded, CloseRounded } from '@mui/icons-material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { green, grey } from '@mui/material/colors';

export default function PortalSpacesSection({
  open,
  onClose,
  onOpen,
  entryId,
  initial,
  type = 'form',
  portalList,
  required,
}) {
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [selectedAutocompleteSpaces, setSelectedAutocompleteSpaces] = useState([]);
  const [saving, setSaving] = useState(false);

  const filteredSpaces =
    isArray(portalList) && !isEmpty(portalList) ? spaces.filter((x) => portalList.includes(x._id)) : spaces;

  const renderAsAutocomplete = spaces.length > 6;

  const loadPortalSpaces = open && isEmpty(spaces);

  useEffect(() => {
    if (!loadPortalSpaces) return;
    setLoading(true);
    axios
      .get('/portal/user/getPortalSpacesForLinks')
      .then(({ data }) => {
        setSpaces(data);
        if (isArray(initial) && !isEmpty(initial)) {
          setSelectedSpaces(initial);
          setSelectedAutocompleteSpaces(data.filter((x) => initial.includes(x._id)));
        }
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [loadPortalSpaces, initial]);

  function done() {
    setSaving(true);
    axios
      .post(type === 'form' ? '/forms/entries/saveLinkedPortalSpaces' : '/process/entries/saveLinkedPortalSpaces', {
        entryId,
        spaces: renderAsAutocomplete ? selectedAutocompleteSpaces.map((x) => x._id) : selectedSpaces,
      })
      .then(() => {
        StateManager.setSuccessAlert('Saved');
        setSaving(false);
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function skip() {
    onClose();
    axios
      .post(type === 'form' ? '/forms/entries/skipSettingPortalSpaces' : '/process/entries/skipSettingPortalSpaces', {
        entryId,
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <Grid container sx={{ py: 1 }}>
      <Grid
        container
        sx={{ py: 1, px: 2, borderRadius: 2 }}
        component={Paper}
        elevation={3}
        id="portal-spaces-selection"
      >
        {!open && (
          <Grid container component={Collapse} in={!open}>
            <Grid container justifyContent={'space-between'} alignItems="center">
              <Typography variant="h6">Portal selection</Typography>
              {isFunction(onOpen) && (
                <Button startIcon={<KeyboardArrowDownRounded />} onClick={() => onOpen()}>
                  open
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        <Grid container component={Collapse} in={open}>
          <Grid container sx={{ py: 1 }}>
            {loading ? (
              <Grid container>
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <Grid container sx={{ mb: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Pick portals you would like to make this entry available for
                  </Typography>
                </Grid>
                {!isEmpty(filteredSpaces) ? (
                  <Grid container>
                    {renderAsAutocomplete ? (
                      <Grid container sx={{ mb: 2 }}>
                        <Autocomplete
                          multiple
                          fullWidth
                          disableCloseOnSelect
                          value={selectedAutocompleteSpaces}
                          isOptionEqualToValue={(option, value) => option?._id === value?._id}
                          onChange={(event, newValue) => setSelectedAutocompleteSpaces([...newValue])}
                          getOptionLabel={(option) => (option ? option.title : '')}
                          options={filteredSpaces}
                          renderInput={(params) => (
                            <TextField {...params} label="Select portal spaces" variant="outlined" />
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip key={index} color="primary" label={option.title} {...getTagProps({ index })} />
                            ))
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Grid container item alignItems="center">
                                <Typography style={{ marginLeft: '1rem' }}>{option.title}</Typography>
                                {selected ? (
                                  <CheckCircleRounded
                                    fontSize="small"
                                    style={{ color: green[500], marginLeft: 'auto' }}
                                  />
                                ) : (
                                  <CheckCircleOutlineRounded
                                    fontSize="small"
                                    style={{ color: grey[300], marginLeft: 'auto' }}
                                  />
                                )}
                              </Grid>
                            </li>
                          )}
                        />
                      </Grid>
                    ) : (
                      <FormGroup>
                        {filteredSpaces.map((space) => (
                          <FormControlLabel
                            key={space._id}
                            control={<Checkbox checked={selectedSpaces.includes(space._id)} />}
                            label={space.title}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedSpaces([...selectedSpaces, space._id]);
                              } else {
                                setSelectedSpaces(selectedSpaces.filter((x) => x !== space._id));
                              }
                            }}
                          />
                        ))}
                      </FormGroup>
                    )}
                  </Grid>
                ) : (
                  <Grid container>
                    <Typography variant="h6">No portal spaces available</Typography>
                  </Grid>
                )}
              </>
            )}
            <Grid container item justifyContent="flex-end" alignItems="center">
              {saving ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  {!required && (
                    <Button color="secondary" startIcon={<CloseRounded />} onClick={skip}>
                      skip
                    </Button>
                  )}
                  <Button variant="contained" startIcon={<DoneRounded />} sx={{ ml: 1 }} onClick={done}>
                    done
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
