import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Typography } from '@mui/material';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  top: `${PREFIX}-top`,
  circle: `${PREFIX}-circle`,
  backdrop: `${PREFIX}-backdrop`,
  message: `${PREFIX}-message`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
  },

  [`& .${classes.top}`]: ({ color }) => ({
    color: color,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  }),

  [`& .${classes.circle}`]: {
    strokeLinecap: 'round',
  },

  [`& .${classes.backdrop}`]: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 500,
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.message}`]: {
    margin: 20,
  },
}));

export default function ({
  fetching,
  size = 50,
  thickness = 4,
  color = 'steelblue',
  isBackdrop = false,
  message,
  ...props
}) {
  if (isBackdrop) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress
          variant="indeterminate"
          thickness={thickness}
          size={size}
          color="primary"
          classes={{
            circle: classes.circle,
          }}
          {...props}
        />
        {message && (
          <Typography variant="h5" align="center" className={classes.message} sx={{ ml: 2 }}>
            {message}
          </Typography>
        )}
      </Backdrop>
    );
  } else {
    return (
      <Root className={classes.root}>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={size}
          thickness={4}
          {...props}
        />
      </Root>
    );
  }
}
