import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// route for all logged in users EXCEPT portal
export default function ({ children, ...rest }) {
  const { user } = useSelector(({ profile }) => profile);
  const loggedIn = localStorage && localStorage.getItem('token');
  const portalUser = user?.access === 'portal' || localStorage.getItem('access') === 'portal';
  const portalAccesibleHubs = ['/forms', '/processes', '/process', '/DocLibrary', '/registers'];

  if (!loggedIn) return <Redirect to="/login" />;

  if (portalUser) {
    if (portalAccesibleHubs.includes(rest.path)) {
      const to = `/portal${rest?.location?.pathname}${rest?.location?.search}`;
      return <Redirect to={to} />;
    }
    if (rest.path.includes('print')) {
      return <Route {...rest} children={children} />;
    }
    return <Redirect to="/portal" />;
  }

  return <Route {...rest} children={children} />;
}
