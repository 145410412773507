import { useEffect, useRef } from 'react';
import { WS_BASE_URL } from '../../../constants';
import { useSnackbar } from 'notistack';
import StateManager from '../StateManager';

const NEW_ACTION_EVENT = 'newAction';

const useSnackbarNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const socket = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || !enqueueSnackbar || !closeSnackbar) return;

    import('socket.io-client').then(({ io }) => {
      if (socket.current) return; // to prevent double connection

      socket.current = io(`${WS_BASE_URL}/snackbar`, {
        auth: { token },
        transports: ['websocket'],
        secure: true,
      });

      socket.current.on(NEW_ACTION_EVENT, (notification) => {
        StateManager.reportNewExecutedAction(notification);
        enqueueSnackbar('', {
          variant: 'actionSnack',
          actionTitle: notification.actionTitle,
          executedActionId: notification.executedActionId,
          closeSnackbar,
          autoHideDuration: 10 * 1000,
        });
      });
    });
  }, [enqueueSnackbar, closeSnackbar]);
};

export default useSnackbarNotifications;
