import axios from 'axios';
import { Subject } from 'rxjs';
import moment from 'moment';
import TaskManager from '../TaskManager';

const taskTimersSub = new Subject();
var confirmPauseSub = null;
const timersSubscribtions = {};
var timers = [];

//init
(() => {
  if (!localStorage.getItem('token')) return;
  TaskManager.subscribeToAnyTaskComplete(({ taskId }) => stopTaskTimer(taskId, true));

  axios
    .get('/tasks/getUserTaskTimers')
    .then((res) => {
      timers = res.data;
      if (!timers) timers = [];
      taskTimersSub.next([...timers]);
      timers.forEach((timer) => {
        if (timersSubscribtions[timer.taskId]) {
          timersSubscribtions[timer.taskId].next({ ...timer });
        }
      });
    })
    .catch((err) => {
      console.error(err);
    });
})();

function getTimers() {
  return [...timers];
}

function subscribeToTimers(callback) {
  return taskTimersSub.subscribe(callback);
}

async function startTaskTimer(taskId, confirm = false) {
  try {
    if (confirm) {
      let running = timers.filter((x) => x.state === 'active').map((x) => x.taskName);
      if (running.length > 0 && confirmPauseSub) {
        confirmPauseSub.next({ running, taskId });
        return null;
      }
    }
    let timer = timers.find((x) => x.taskId === taskId);
    if (timer) {
      if (timer.state === 'active') return;
      timer.state = 'active';
      timer.startedAt = moment();
      taskTimersSub.next([...timers]);
      if (timersSubscribtions[taskId]) {
        timersSubscribtions[taskId].next({ ...timer });
      }
    }
    let res = await axios.post('/tasks/startTaskTimer', { taskId });
    if (!timer) {
      timers.unshift(res.data);
      taskTimersSub.next([...timers]);
      if (timersSubscribtions[taskId]) {
        timersSubscribtions[taskId].next({ ...res.data });
      }
    }
    return { ...res.data };
  } catch (err) {
    console.error(err);
  }
}

function startTaskTimerAndStopOthers(taskId) {
  timers
    .filter((x) => x.state === 'active')
    .forEach((timer) => {
      stopTaskTimer(timer.taskId);
    });
  startTaskTimer(taskId);
}

function stopTaskTimer(taskId, forComplete = false) {
  let timer = timers.find((x) => x.taskId === taskId);
  if (!timer) return;
  if (timer.state !== 'active') return;
  timer.state = 'stoped';
  timer.elapsed += moment().diff(moment(timer.startedAt), 'seconds');
  timer.startedAt = null;
  taskTimersSub.next([...timers]);
  if (timersSubscribtions[taskId]) {
    timersSubscribtions[taskId].next({ ...timer });
  }
  if (!forComplete) {
    axios.post('/tasks/stopTaskTimer', { timerId: timer._id }).catch(console.error);
  } else {
    deleteTaskTimer(timer._id);
  }
  return { ...timer };
}

function deleteTaskTimer(timerId) {
  timers = timers.filter((t) => t._id !== timerId);
  taskTimersSub.next([...timers]);
  axios.post('/tasks/deleteTaskTimer', { timerId }).catch(console.error);
}

function getTimer(taskId) {
  let index = timers.findIndex((t) => t.taskId === taskId);
  if (index === -1) return null;
  return timers[index];
}

function subscribeToTaskTimer(taskId, callback) {
  if (!timersSubscribtions[taskId]) {
    timersSubscribtions[taskId] = new Subject();
  }
  return timersSubscribtions[taskId].subscribe(callback);
}

function subscribeToConfirm(callback) {
  if (!confirmPauseSub) {
    confirmPauseSub = new Subject();
  }
  confirmPauseSub.subscribe(callback);
}

const TimerManager = {
  stopTaskTimer,
  startTaskTimer,
  subscribeToTimers,
  deleteTaskTimer,
  startTaskTimerAndStopOthers,
  subscribeToTaskTimer,
  subscribeToConfirm,
  getTimers,
  getTimer,
};

export default TimerManager;
