import React from 'react';
import { DialogTitle, DialogContent, Grid, Chip, Typography } from '@mui/material';
import { RoundedDialog, StandardDialogActions, UserChip } from './index';

export default function EmailDialog({ open, onClose, message }) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{message?.subject}</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" style={{ marginBottom: '1rem' }}>
          <Typography style={{ fontWeight: 600 }}>To:</Typography>
          {Array.isArray(message?.users) && message.users.map((user, i) => <UserChip key={i} id={user} margin={8} />)}
          {Array.isArray(message?.emails) &&
            message.emails.map((email, i) => <Chip key={i} color="primary" label={email} style={{ margin: 8 }} />)}
        </Grid>
        <div dangerouslySetInnerHTML={{ __html: message?.body }} />
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
