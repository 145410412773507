import React, { useState, useEffect, useMemo } from 'react';
import { red, grey, green } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, TextField, InputBase, Switch } from '@mui/material';
import { IconButton, Collapse } from '@mui/material';
import { FormControlLabel, Paper, Tooltip, Checkbox } from '@mui/material';
import { FormControl, FormLabel, RadioGroup, Radio } from '@mui/material';
import { DoneRounded, DragIndicator, FiberManualRecord } from '@mui/icons-material';
import { Close, DeleteOutlineRounded, PaletteRounded } from '@mui/icons-material';
import { AddCircleOutlineRounded, ExpandMoreRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions, ColorPicker } from '../Components';
import { v4 } from 'uuid';
import { DnDList } from '../Components';
import { randomColor } from '../Functions';
import { maxBy, isArray, isEmpty, isNumber, toNumber } from 'lodash';
import StateManager from '../StateManager';

export default function OptionsDialog({
  initialOptions,
  initialSelectType,
  initialDisplayType,
  initialReadOnly,
  open,
  onClose,
  onResult,
  isTickbox,
  isWeighted,
  withColors,
  withReadOnly,
  withDisplayType,
}) {
  const [options, setOptions] = useState([]);
  const [text, setText] = useState('');
  const [selectType, setSelectType] = useState('multiple');
  const [displayType, setDisplayType] = useState('tickbox');
  const filteredOptions = options.filter(
    (item) => item.text && (!item.parentId || options.some((option) => option.id === item.parentId)),
  );
  const [color, setColor] = useState(randomColor(null, 700));
  const [colourPicker, setColourPicker] = useState(null);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (!open) return;
    if (initialOptions) {
      setOptions([...initialOptions]);
    } else {
      setOptions([]);
    }
    setSelectType(initialSelectType || 'multiple');
    setDisplayType(initialDisplayType || 'tickbox');
    setReadOnly(Boolean(initialReadOnly));
    setText('');
    setColor(randomColor(null, 700));
  }, [open]); // eslint-disable-line

  const notNested = useMemo(() => {
    const result = options.filter((x) => !x.parentId);
    return result;
  }, [options]);

  function addOption() {
    if (!text) return;

    const weight = isWeighted ? (maxBy(options, (o) => (o.weight != null ? o.weight : 0))?.weight || 0) + 1 : undefined;
    const optionColor = withColors ? color : undefined;
    const option = { id: v4(), text, color: optionColor, weight };

    options.push(option);
    setText('');
    setColor(randomColor(null, 700));
    setOptions([...options]);
  }

  function deleteOption(id) {
    // delete the option and all its children
    const allChildren = findChildren(options, [id]);
    const result = options.filter((x) => x.id !== id && !allChildren.includes(x.id));

    setOptions(result);
  }

  function findChildren(items, parents) {
    const children = items.filter((x) => parents.includes(x.parentId)).map((x) => x.id);
    if (isEmpty(children)) return [];

    return [...children, ...findChildren(items, children)];
  }

  function done() {
    let result = [...filteredOptions];
    if (text) {
      result.push({ id: v4(), text, color: withColors ? color : undefined });
      setOptions([...result]);
    }
    setText('');

    onResult({
      options: result,
      selectType,
      readOnly: withReadOnly ? readOnly : undefined,
      displayType: withDisplayType ? displayType : null,
    });
    onClose();
  }

  function selectColor(res) {
    const color = res?.color;
    setColourPicker(null);
    if (!color) return;

    setColor(res.color);
  }

  function saveOption(optionId, update) {
    const index = options.findIndex((x) => x.id === optionId);
    if (index > -1) {
      const copy = [...options];
      copy[index] = { ...copy[index], ...update };
      setOptions(copy);
    } else {
      setOptions([...options, update]);
    }
  }

  return (
    <RoundedDialog
      open={open}
      onClose={onClose}
      maxWidth={isWeighted ? 'sm' : 'xs'}
      fullWidth
      className="non-draggable scroll-bar"
    >
      <DialogTitle>Options</DialogTitle>
      <DialogContent>
        {isTickbox && (
          <Grid container item>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Select type</FormLabel>
              <RadioGroup row value={selectType} onChange={(event) => setSelectType(event.target.value)}>
                <FormControlLabel value="single" control={<Radio color="primary" />} label="Single" />
                <FormControlLabel value="multiple" control={<Radio color="primary" />} label="Multiple" />
              </RadioGroup>
            </FormControl>

            {withDisplayType && (
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Display as</FormLabel>
                <RadioGroup row value={displayType} onChange={(event) => setDisplayType(event.target.value)}>
                  <FormControlLabel value="tickbox" control={<Radio color="primary" />} label="Tickboxes" />
                  <FormControlLabel value="buttons" control={<Radio color="primary" />} label="Buttons" />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
        )}
        <Grid container item>
          <DnDList
            items={notNested}
            spacing={0}
            setItems={(items) => {
              const children = options.filter((x) => x.parentId);
              setOptions([...items, ...children]);
            }}
            renderItem={(item) => (
              <Option
                allItems={options}
                item={item}
                withColors={withColors}
                isWeighted={isWeighted}
                onItemDelete={deleteOption}
                onItemChange={saveOption}
              />
            )}
          />
        </Grid>
        <Grid container item alignItems="center" style={{ marginTop: '1rem' }}>
          <TextField
            placeholder="Enter option"
            value={text}
            onChange={(e) => setText(e.target.value)}
            style={{ flexGrow: 1, marginRight: '1rem' }}
            autoFocus
            autoComplete="off"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addOption();
              }
            }}
            variant="standard"
            inputProps={{ maxLength: 48 }}
          />
          {withColors && (
            <>
              <Tooltip title="Pick color" placement="top">
                <IconButton
                  onClick={(e) => {
                    setColourPicker(e.currentTarget);
                  }}
                >
                  <FiberManualRecord style={{ color }} />
                </IconButton>
              </Tooltip>
              <ColorPicker
                anchor={colourPicker}
                open={Boolean(colourPicker)}
                onClose={selectColor}
                column={700}
                customPicker
              />
            </>
          )}
          <Tooltip title="Add" placement="top">
            <IconButton onClick={addOption}>
              <DoneRounded style={{ color: green[500] }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clear" placement="top">
            <IconButton onClick={() => setText('')}>
              <Close style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          withReadOnly ? (
            <FormControlLabel
              onChange={(e) => setReadOnly(e.target.checked)}
              control={<Checkbox color="primary" checked={readOnly} />}
              label="Read only"
              style={{ marginRight: 'auto', marginLeft: 4 }}
            />
          ) : undefined
        }
        onClose={onClose}
        onDone={done}
        hideDone={filteredOptions.length === 0}
      />
    </RoundedDialog>
  );
}

function Option({ item, nested, withColors, isWeighted, onItemChange, onItemDelete, allItems }) {
  const [color, setColor] = useState(null);
  const [colourPicker, setColourPicker] = useState(null);
  const [weight, setWeight] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    setColor(item.color || null);
    setWeight(item.weight || '');
    setText(item.text || '');
  }, [item]);

  const children = useMemo(() => {
    if (!isArray(allItems) || !item?.id) return [];

    const result = allItems.filter((x) => x.parentId === item.id);
    return result;
  }, [allItems, item]);

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this item', () => onItemDelete(item.id));
  }

  function addSubItem() {
    const newItem = {
      id: v4(),
      color: withColors ? randomColor(null, 700) : undefined,
      weight: isWeighted ? 1 : undefined,
      text: '',
      parentId: item.id,
    };
    onItemChange(newItem.id, newItem);
  }

  function saveText(value) {
    setText(value);
    onItemChange(item.id, { text: value });
  }

  function saveWeight(value) {
    const numValue = isNumber(value) ? value : value == null || value === '' ? '' : toNumber(value);
    setWeight(numValue);
    onItemChange(item.id, { weight: numValue });
  }

  function selectColor(res) {
    setColourPicker(null);
    if (!res?.color) return;
    setColor(res.color);
    onItemChange(item.id, { color: res.color });
  }

  const hasChildren = !isEmpty(children);

  if (!item) return null;

  return (
    <Grid
      container
      component={nested ? undefined : Paper}
      elevation={nested ? undefined : 3}
      sx={nested ? undefined : { my: 1 }}
    >
      <Grid container alignItems={'center'} sx={{ p: 0.7 }}>
        <Grid container alignItems="center">
          {!nested && <DragIndicator style={{ color: grey[500], mr: 1 }} />}
          {withColors && (
            <>
              <Tooltip title="Change color" placement="top">
                <IconButton onClick={(e) => setColourPicker(e.currentTarget)} sx={{ height: 36, width: 36, ml: 0.5 }}>
                  <PaletteRounded fontSize="small" style={{ color }} />
                </IconButton>
              </Tooltip>
              <ColorPicker
                anchor={colourPicker}
                open={Boolean(colourPicker)}
                onClose={selectColor}
                column={700}
                customPicker
              />
            </>
          )}
          {isWeighted && (
            <InputBase
              placeholder="Weight"
              value={weight}
              sx={{
                width: 80,
                ml: 1,
                borderRadius: 0.5,
                background: (theme) => (theme.palette.mode === 'dark' ? grey[700] : grey[100]),
                pl: 0.5,
              }}
              type="number"
              onChange={(e) => saveWeight(e.target.value)}
            />
          )}
          <InputBase
            value={text}
            placeholder="Add option"
            sx={{ flexGrow: 1, ml: 1 }}
            onChange={(e) => saveText(e.target.value)}
            inputProps={{ maxLength: 48 }}
          />
          <Tooltip title={`Add sub-item`}>
            <IconButton onClick={addSubItem}>
              <AddCircleOutlineRounded sx={{ color: grey[500] }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete option" placement="top">
            <IconButton onClick={confirmDelete} sx={{ height: 36, width: 36, ml: 'auto' }}>
              <DeleteOutlineRounded fontSize="small" style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {hasChildren && (
        <Grid container sx={{ pl: 2 }}>
          {children.map((child) => (
            <Option
              key={child.id}
              item={child}
              allItems={allItems}
              withColors={withColors}
              isWeighted={isWeighted}
              onItemChange={onItemChange}
              onItemDelete={onItemDelete}
              nested
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
}
