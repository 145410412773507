import React, { useState, useEffect, useMemo } from 'react';
import { Button, DialogContent, DialogTitle, Grid, List, ListItemButton } from '@mui/material';
import { ListItemText, Typography } from '@mui/material';
import { LinkRounded, LinkOffRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../Components';
import DataGridBuilder from './DataGridBuilder';
import StateManager from '../StateManager';
import { checkFieldColumns } from '../Functions';
import { cloneDeep, isArray, isEmpty } from 'lodash';

export default function FieldGridBuilderDialog({
  open,
  onClose,
  initial,
  itemId,
  onResult,
  allItems,
  excludedTypes = ['conditional', 'table', 'stock', 'action'],
}) {
  const [result, setResult] = useState(null);
  const [dialog, setDialog] = useState(false);

  const otherTables = useMemo(() => {
    if (!isArray(allItems)) return [];

    const result = allItems.filter((x) => x.type === 'table' && x.id !== itemId && !x.table?.linkedTableId);

    return result;
  }, [allItems, itemId]);

  const linkedTable = useMemo(() => {
    if (!result?.linkedTableId || isEmpty(otherTables)) return null;

    const res = otherTables.find((x) => x.id === result?.linkedTableId);

    return res;
  }, [result?.linkedTableId, otherTables]);

  useEffect(() => {
    if (!open) return;
    setResult(initial || { columns: [], rows: [] });
  }, [open, initial]);

  function done() {
    if (!result?.linkedTableId) {
      const { error } = checkFieldColumns(result?.columns);
      if (error) {
        StateManager.setErrorAlert(error);
        return;
      }
    }

    onResult(result);
    onClose();
  }

  const dialogWidth = linkedTable ? 'sm' : 'xl';

  return (
    <RoundedDialog open={open} maxWidth={dialogWidth} fullWidth onClose={onClose}>
      <DialogTitle>Edit table</DialogTitle>
      <DialogContent>
        {linkedTable ? (
          <Grid container alignItems={'center'} sx={{ my: 2 }}>
            <Grid container alignItems={'center'}>
              <Typography sx={{ mr: 2 }}>
                You have linked to <b>{linkedTable.title}</b>
              </Typography>

              <Button
                onClick={() => {
                  setResult({ columns: [], rows: [] });
                }}
                startIcon={<LinkOffRounded />}
              >
                unlink table
              </Button>
            </Grid>

            <Grid container alignItems={'center'} sx={{ mt: 2 }}>
              <Typography color={'textSecondary'}>
                By linking the table to another, you will see all the same columns, rows and cell data. You will be able
                to edit the data, which will change the data in both places
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <DataGridBuilder
            initialColumns={result?.columns}
            initialRows={result?.rows}
            onTableChange={(res) => {
              const updated = cloneDeep({ ...result, ...res });
              setResult(updated);
            }}
            excludedTypes={excludedTypes}
          />
        )}

        <TablePickerDialog
          open={dialog}
          onClose={() => setDialog(false)}
          tables={otherTables}
          onResult={(table) => {
            setResult({ linkedTableId: table.id });
          }}
        />
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        hideDone={(!isArray(result?.columns) || isEmpty(result.columns)) && !result?.linkedTableId}
        additionalActions={
          !isEmpty(otherTables) && (
            <Button onClick={() => setDialog(true)} startIcon={<LinkRounded />} sx={{ mr: 'auto' }}>
              Link another table
            </Button>
          )
        }
      />
    </RoundedDialog>
  );
}

function TablePickerDialog({ open, onClose, tables, onResult }) {
  return (
    <RoundedDialog open={open} maxWidth={'xs'} fullWidth onClose={onClose}>
      <DialogTitle>Pick table</DialogTitle>
      <DialogContent>
        <Grid container>
          <List sx={{ width: '100%' }}>
            {!isEmpty(tables) &&
              isArray(tables) &&
              tables.map((table) => (
                <ListItemButton
                  key={table.id}
                  sx={{ borderRadius: 1 }}
                  onClick={() => {
                    onResult(table);
                    onClose();
                  }}
                >
                  <ListItemText primary={table.title} />
                </ListItemButton>
              ))}
          </List>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
