import React, { useEffect, useState, Suspense } from 'react';
import { DialogContent, Grid, Typography, Button } from '@mui/material';
import { DeleteOutlineRounded, EditRounded, PrintRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { grey, red } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../../constants';
import { kpiColors } from '../../../Global/Functions';
import axios from 'axios';
import Chart from '../../../Global/Charts/Chart';

export default function RoutineAdherenceKPIDialog({ open, onClose, onDelete, kpi }) {
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState([]);

  const userId = localStorage.getItem('_id');

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this KPI', deleteKPI);
  }

  function deleteKPI() {
    setSaving(true);
    axios
      .post('/tasks/routines/kpi/deleteKpi', { id: kpi._id })
      .then((res) => {
        onClose();
        onDelete();
        StateManager.setSuccessAlert('KPI has been deleted');
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function print() {
    setSaving(true);
    const element = document.getElementById('chart');
    if (!element) {
      setSaving(false);
      return;
    }

    import('html2canvas').then(({ default: html2canvas }) => {
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const body = {
          image,
          title: kpi.title,
        };
        axios
          .post('/kpi/exportToPdf', body)
          .then((res) => {
            window.open(`${BASE_URL}${res.data.link}`, '_blank');
            setSaving(false);
          })
          .catch((err) => {
            setSaving(false);
            StateManager.setAxiosErrorAlert(err);
          });
      });
    });
  }

  useEffect(() => {
    if (!kpi) return;
    setData(kpi.data);
  }, [kpi]);

  if (!kpi) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Grid container item>
          <Typography variant="h3" gutterBottom>
            {kpi.title}
          </Typography>
        </Grid>

        <Grid container item style={{ height: '55vh' }} id="chart">
          <Chart data={data} chartType={'pie'} />

          {data.length === 0 && (
            <Grid container item style={{ width: '100%', height: '55vh' }}>
              <Typography variant="h5" color="textSecondary" style={{ margin: 'auto' }}>
                There is no data to display
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <StandardDialogActions
        onClose={onClose}
        saving={saving}
        additionalActions={
          <>
            {userId === kpi.userId && (
              <>
                <Link to={`/kpiHub/routine-adherence-kpi-builder/${kpi._id}`}>
                  <Button style={{ color: grey[700] }} startIcon={<EditRounded />}>
                    Edit
                  </Button>
                </Link>
                {onDelete && (
                  <Button style={{ color: red[500] }} onClick={confirmDelete} startIcon={<DeleteOutlineRounded />}>
                    delete
                  </Button>
                )}
                <Button onClick={print} style={{ color: grey[600] }} startIcon={<PrintRounded />}>
                  print
                </Button>
                <div style={{ marginRight: 'auto' }} />
              </>
            )}
          </>
        }
      />
    </RoundedDialog>
  );
}
