import { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, Button } from '@mui/material';
import { RoundedDialog, StandardDialogActions, DateRangePicker } from './index';
import { NotInterestedRounded } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import moment from 'moment';

export default function IntervalDialog({ open, onClose, onResult, onClear, initial }) {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(moment());

  function done() {
    if (!start || !end) {
      return;
    }
    onResult({ start, end });
    onClose();
  }

  useEffect(() => {
    setStart(initial?.start || null);
    setEnd(initial?.end || null);
  }, [initial]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Select interval</DialogTitle>
      <DialogContent>
        <Grid container>
          <DateRangePicker
            value={[start, end]}
            onChange={([start, end]) => {
              setStart(start);
              setEnd(end);
            }}
          />
        </Grid>
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          onClear ? (
            <Button
              startIcon={<NotInterestedRounded />}
              style={{ borderRadius: 8, color: red[500], marginRight: 'auto' }}
              onClick={() => {
                onClear();
                onClose();
              }}
            >
              clear
            </Button>
          ) : null
        }
        onClose={onClose}
        onDone={done}
        hideDone={!start || !end}
      />
    </RoundedDialog>
  );
}
