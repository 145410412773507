import { DialogContent, DialogTitle, Grid, TextField, Button } from '@mui/material';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import ConfirmDialog from '../../../Global/ConfirmDialog';
import StateManager from '../../../Global/StateManager';
import QRCode from '../../../Global/QRCode';
import PrefillFieldsDialog from './PrefillFieldsDialog';
import axios from 'axios';

export default function NewSurveyDialog({ open, onClose, form, actionReferenceInfo, actionReference }) {
  const [title, setTitle] = useState('');
  const [saving, setSaving] = useState(false);
  const [link, setLink] = useState('');
  const [surveyId, setSurveyId] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [prefillFieldsDialog, setPrefillFieldsDialog] = useState(false);
  const [sections, setSections] = useState([0]);
  const normalTitle = String(title).trim();

  useEffect(() => {
    if (!open) return;
    axios
      .get('/forms/general/getFormSections', { params: { formId: form._id } })
      .then((res) => setSections(res.data))
      .catch((err) => StateManager.setAxiosErrorAlert(err));
    setTitle('');
    setLink('');
    //eslint-disable-next-line
  }, [open]);

  function done(status) {
    if (!normalTitle) return Promise.reject('Title is required');

    setSaving(true);
    return axios
      .post('/forms/invitations/createSurvey', {
        formId: form._id,
        formVersionId: form.versionId,
        title,
        status,
        formPreFilledSections: sections,
        actionReferenceInfo,
        actionReference,
      })
      .then((res) => {
        setSaving(false);
        setSurveyId(res.data._id);
        return res.data;
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setErrorAlert('Failed to send the invitation');
        console.error(err);
        throw err;
      });
  }

  function onFieldsSave(section) {
    setSections(section);
  }

  function handleClose(data, generateLink) {
    if (generateLink) {
      done('live')
        .then((res) => {
          setConfirmation(false);
          onClose(res);
        })
        .catch((err) => {
          console.error('Error when is survey creating:', err);
          setSaving(false);
        });
    }

    if (!generateLink) {
      done('draft')
        .then((res) => {
          setConfirmation(false);
          onClose(res);
        })
        .catch((err) => {
          console.error('Error when is survey creating:', err);
          setSaving(false);
        });
    }
  }

  const prefilledFields = sections?.reduce((acc, val) => {
    const prefCount = val.prefilledField || 0;
    prefCount ? (acc += prefCount) : acc;
    return acc;
  }, 0);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New survey</DialogTitle>
      <DialogContent>
        <Grid container style={{ margin: '0.5rem 0' }}>
          <TextField
            label="Survey name"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setConfirmation(true);
              }
            }}
            variant="outlined"
          />
        </Grid>
        {!link && (
          <Grid container item gap={1} marginTop={5}>
            <Typography>
              You can prefill fields for the survey entries. Pre-filled fields are not editable for the assignees Please
              note that pre-filled fields can only be configured before the survey is released.
            </Typography>
            <Grid item container direction={'row'}>
              <Button
                color="primary"
                variant="outlined"
                style={{ alignSelf: 'flex-start' }}
                onClick={() => setPrefillFieldsDialog(true)}
              >
                {!prefilledFields ? 'Pre-fill form' : 'Edit prefilled fields'}
              </Button>
              {prefilledFields > 0 && (
                <Typography m={1} sx={{ color: grey[400] }}>{`${prefilledFields} field${
                  prefilledFields > 1 ? 's' : ''
                } prefilled`}</Typography>
              )}
            </Grid>
          </Grid>
        )}
        {link && (
          <Grid container direction="column" style={{ margin: '0.5rem 0' }}>
            <Typography variant="h6">Here is the link to your survey: </Typography>
            <TextField fullWidth value={link} variant="outlined" style={{ margin: '0.5rem 0' }} />
            <Typography gutterBottom>You can send this link to people or embed it on your website</Typography>
            <Typography>
              You will be able to view all survey entries, edit or cancel survey if you click 'Survey' button
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          link && surveyId ? (
            <>
              <QRCode linkType={'form_survey'} linkId={surveyId} sx={{ mx: 1 }} />
            </>
          ) : null
        }
        saving={saving}
        onClose={onClose}
        onDone={() => setConfirmation(true)}
        hideDone={!normalTitle || link}
        doneButtonText="CREATE"
      />
      <PrefillFieldsDialog
        open={prefillFieldsDialog}
        onClose={() => setPrefillFieldsDialog(false)}
        onDone={onFieldsSave}
        formId={form?._id}
        section={sections}
        editable
      />
      <ConfirmDialog
        title={'Do you want to publish this survey'}
        content={'Once published, editing will not be possible'}
        open={confirmation}
        onClose={(e) => {
          if (e) {
            const targetText = e.target?.textContent || e.target?.innerText;
            if (targetText === 'SAVE AS DRAFT') {
              return handleClose(sections, false);
            } else {
              return setConfirmation(false);
            }
          }
        }}
        onConfirm={() => handleClose(sections, true)}
        saveAsDraft
        doneButtonText="RELEASE"
        closeButtonText="SAVE AS DRAFT"
      />
    </RoundedDialog>
  );
}
