import React, { useEffect, useState, useRef, useMemo } from 'react';

import { Grid, CircularProgress, Typography, Button, TextField, MenuItem, IconButton } from '@mui/material';
import { ListItemText, ListItemIcon, Chip, Paper, Menu, useMediaQuery, Fab } from '@mui/material';
import { DialogContent, DialogTitle, DialogActions, Tooltip, useTheme } from '@mui/material';

import { MoreHoriz, InfoOutlined, DeleteOutlineRounded, GetAppRounded, CancelOutlined } from '@mui/icons-material';
import { NotificationsActiveRounded, CheckCircleOutlineRounded, PauseCircleOutline } from '@mui/icons-material';
import { PlayCircleOutline, Visibility, VisibilityOff, EditRounded, HistoryRounded } from '@mui/icons-material';
import { HighlightOffRounded, UnarchiveRounded } from '@mui/icons-material';
import { User, RoundedDialog, PageContainer, UserGroup, BackArrowButton } from '../../../Global/Components';
import { TitleTypography, CancelDialog, AssetReference, PortalSpaceReference } from '../../../Global/Components';
import { ProcessIcon, FormIcon } from '../../../Global/Icons';
import { blue, grey, green, deepPurple, red, amber, teal, deepOrange } from '@mui/material/colors';
import StateManager from '../../../Global/StateManager';
import { useSelector } from 'react-redux';
import { FormatDate, FormatOnlyDate } from '../../../Global/Functions';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import OngoingProcessInfo from '../components/OngoingProcessInfo';
import ProcessNotificationsDialog from '../components/ProcessNotificationsDialog';
import Step from '../components/Step';
import { useQuery } from '../../../../constants';
import { BASE_URL } from '../../../../constants';
import { isEmpty, isArray, last, isNumber } from 'lodash';
import FieldReference from '../../../Global/Fields/FieldReference';
import ExecutedActionReference from '../../../Global/Fields/ExecutedActionReference';

import PortalSpacesSection from '../../forms/components/PortalSpacesSection';
import NcrInfo from '../../../Global/Components/NcrInfo';
import DeleteProcessDialog from '../components/DeleteProcessDialog';
import ActionsBar from '../../forms/components/ActionsBar';
import AttachmentsBar from '../components/AttachmentsBar';
import EntryVersionsDialog from '../components/EntryVersionDialog';

function numberToColumn(n) {
  const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[n % 26];
  return n >= 26 ? numberToColumn(Math.floor(n / 26) - 1) + res : res;
}

export function formatEntryVersion(versionFormat, entryVersion) {
  if (!isNumber(entryVersion)) return;

  if (!versionFormat || versionFormat === 'v') return `v${entryVersion}`;
  if (versionFormat === '01') return `${entryVersion < 10 ? 0 : ''}${entryVersion}`;
  if (versionFormat === '1') return `${entryVersion}`;
  if (versionFormat === 'A') return numberToColumn(entryVersion - 1);
}

export default function OngoingProcess() {
  const query = useQuery();
  const redirected = query.get('redirected');
  const itemId = query.get('itemId');
  const { id, stepId } = useParams();
  const history = useHistory();
  const { user } = useSelector(({ profile }) => profile);
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const [process, setProcess] = useState();
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [processMenuAnchor, setProcessMenuAnchor] = useState();
  const [cancelDialog, setCancelDialog] = useState(false);
  const [holdDialog, setHoldDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const [watching, setWatching] = useState(false);
  const [completedAt, setCompletedAt] = useState();
  const [notificationsDialog, setNotificationsDialog] = useState(false);
  const [downloading, setDownloading] = useState('');
  const [startingEditing, setStartingEditing] = useState('');
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(null);
  const [calculatedItems, setCalculatedItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [portalSpacesSection, setPortalSpacesSection] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [files, setFiles] = useState([]);
  const [unarchiving, setUnarchiving] = useState(false);
  const [versionsDialog, setVersionsDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [cancellingEditing, setCancellingEditing] = useState(false);
  const [allCreatedActions, setAllCreatedActions] = useState([]);
  const [allExecutedActions, setAllExecutedActions] = useState([]);
  const executedActionsRef = useRef([]);

  const currentlyEditing = process && (['editing', 'upissuing'].includes(process.state) || editMode);

  const editingByUser = currentlyEditing && process.editingUsers?.includes(user?._id);

  const isProcessOwner = user && process && (process.processOwner === user._id || process.coOwners?.includes(user._id));

  const admin = user && user.access === 'admin';

  const processEditable = user && process && (admin || process.createdBy === user._id || isProcessOwner);

  const involvedUsers = useMemo(() => {
    const result = steps.reduce((prev, curr) => [...prev, ...(curr.owners || [])], []);
    return result;
  }, [steps]);

  // whether at least one step can be editable
  const stepsEditable = useMemo(() => {
    if (!user || !process) return false;

    // admins can edit anything
    if (user.access === 'admin') return true;

    const editableSettings = process.editableSettings;

    if (!editableSettings?.allowEditingSteps) return false;

    if (completedAt || ['editing', 'upissuing'].includes(process.state)) {
      const assignedPassed =
        (editableSettings.completedEditing?.involved || editableSettings.completedEditing?.assigned) &&
        involvedUsers.includes(user._id);

      const ownersPassed =
        editableSettings.completedEditing?.owners &&
        (process.processOwner === user._id || process.coOwners?.includes(user._id));

      return assignedPassed || ownersPassed;
    } else {
      if (editableSettings.disableBeingApproved) {
        const hasPendingApproval = steps.some((x) => x.approval?.state === 'pending');
        if (hasPendingApproval) return false;
      }

      const assignedPassed =
        (editableSettings.inProgressEditing?.involved || editableSettings.inProgressEditing?.assigned) &&
        involvedUsers.includes(user._id);

      const ownersPassed =
        editableSettings.inProgressEditing?.owners &&
        (process.processOwner === user._id || process.coOwners?.includes(user._id));

      return assignedPassed || ownersPassed;
    }
  }, [user, process, completedAt, involvedUsers, steps]);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    axios
      .get('/process/getOngoingProcess', { params: { id } })
      .then((res) => {
        setProcess(res.data.process);
        setCompletedAt(res.data.process.completedAt);
        setSteps(res.data.ongoingSteps);
        setHiddenItems(res.data.hiddenItems);
        setInfo(res.data.info);
        setPortalSpacesSection(res.data.portalSpaceLinksOpen);
        setAllCreatedActions(res.data.processActions);
        setAllExecutedActions(res.data.executedActions);

        executedActionsRef.current = res.data.executedActions;
        if (res.data.process.files) {
          setFiles(res.data.process.files);
        }
        setLoading(false);

        if (itemId) {
          setTimeout(() => {
            const item = document.getElementById(itemId);
            if (!item) return;
            item.scrollIntoView();
            item.classList.add('highlighted-element');
          }, 400); // to let it render
        }

        StateManager.subscribeToExecutedActions(onExecutedActionChange);
      })
      .catch((err) => {
        setProcess(null);
        setError(err.response?.data?.message || 'Something went wrong');
        setLoading(false);
      });
  }, [id]); // eslint-disable-line

  useEffect(() => {
    if (!user || !process) return;
    setWatching(Boolean(process.watching) && process.watching.indexOf(user._id) > -1);
    setEditMode(['editing', 'upissuing'].includes(process.state) && process.editingUsers?.includes(user?._id));
  }, [user, process]);

  useEffect(() => {
    if (scrolled || !stepId || !steps[0]) return;
    setScrolled(true);
    setTimeout(() => {
      const element = document.getElementById(stepId);
      if (!element) return;
      element.scrollIntoView();
    }, 700);
  }, [steps, stepId]); // eslint-disable-line

  function downloadPDF() {
    if (downloading) return;
    setDownloading('pdf');
    axios
      .get('/process/processToPDF', { params: { id: process._id } })
      .then((res) => {
        window.open(`${BASE_URL}${res.data.link}`, '_blank');
        setDownloading('');
      })
      .catch((err) => {
        console.error(err);
        StateManager.setErrorAlert('Something went wrong');
        setDownloading('');
      });
  }

  function startEdit() {
    if (!completedAt) {
      setStartingEditing(true);

      axios
        .post('/process/entries/checkProcessEdit', { entryId: process._id })
        .then(() => {
          setStartingEditing(false);
          setEditMode(true);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setStartingEditing(false);
        });
      return;
    }
    setStartingEditing(true);

    axios
      .post('/process/entries/startEdit', { entryId: process._id })
      .then(({ data }) => {
        setStartingEditing(false);
        setProcess({ ...process, ...data });
        setCompletedAt(null);
        setEditMode(true);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setStartingEditing(false);
      });
  }

  function checkUpIssue() {
    if (!['editing', 'upissuing'].includes(process.state)) {
      setEditMode(false);
      return;
    }
    finishEdit();
  }

  function finishEdit() {
    setSubmiting(true);
    const body = { entryId: process._id };
    axios
      .post('/process/entries/finishEdit', body)
      .then(({ data }) => {
        setProcess({ ...process, ...data });
        if (data?.completedAt) {
          setCompletedAt(data.completedAt);
        }

        StateManager.setSuccessAlert('Process has been saved');
        setSubmiting(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSubmiting(false);
      });
  }

  function onStepComplete({ result, completedStep }) {
    if (result.finished) {
      StateManager.setSuccessAlert('Congratulations! Process has been finished');
      setCompletedAt(new Date());
      if (isEmpty(completedStep.actionsToStart)) {
        goBack();
      }
    }
    // close the process if needed
    if (completedStep.closeProcessOnComplete) {
      goBack();
    }

    if (isArray(result.steps)) {
      setSteps(result.steps);
      const nextStep = last(result.steps);
      // if the next step owners do not include the user - close the process
      if (isArray(nextStep.owners) && !nextStep.owners.includes(user?._id) && isEmpty(completedStep.actionsToStart)) {
        goBack();
      }
    }

    if (isArray(result?.hiddenItems)) {
      setHiddenItems(result.hiddenItems);
    }
  }

  function cancelProcess({ reason }) {
    setSaving(true);
    const body = {
      ongoingProcessId: process._id,
      reason,
    };
    axios
      .post('/process/cancelProcess', body)
      .then((res) => {
        StateManager.setSuccessAlert('Process has been cancelled');
        setSaving(false);
        goBack();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function holdProcess(reason) {
    setSaving(true);
    const body = {
      ongoingProcessId: process._id,
      reason,
    };
    axios
      .post('/process/holdProcess', body)
      .then((res) => {
        StateManager.setSuccessAlert('Process has been put on hold');
        setSaving(false);
        goBack();
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to hold the process');
        setSaving(false);
      });
  }

  function resumeProcess() {
    setSaving(true);
    axios
      .post('/process/resumeProcess', { ongoingProcessId: process._id })
      .then((res) => {
        setProcess(res.data.process);
        setSteps(res.data.ongoingSteps);
        StateManager.setSuccessAlert('Process has been resumed');
        setSaving(false);
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to resume the process');
        setSaving(false);
      });
  }

  function watchProcess() {
    axios
      .post('/process/watchProcess', { ongoingProcessId: process._id })
      .then((res) => {
        StateManager.setSuccessAlert(watching ? 'Removed from watching' : 'Added to watching');
        setWatching(!watching);
      })
      .catch(() => {
        StateManager.setErrorAlert(watching ? 'Failed to remove from watching' : 'Failed to add to watching');
      });
  }

  function addZeros(number) {
    return number < 100 ? (number < 10 ? `00${number}` : `0${number}`) : number;
  }

  function onCalculationUpdate(update) {
    if (isArray(update?.calculated) && !isEmpty(update.calculated)) {
      setCalculatedItems(update.calculated);
    }
    if (isArray(update?.hiddenItems)) {
      setHiddenItems(update?.hiddenItems);
    }
  }

  function onExecutedActionChange(event) {
    if (event.type === 'newAction' && event.notification?.source?.processEntryId === id && event.notification?.item) {
      executedActionsRef.current.push(event.notification.item);
      setAllExecutedActions([...executedActionsRef.current]);
    }

    if (event.type === 'undoneAction') {
      executedActionsRef.current = executedActionsRef.current.filter(
        (x) => x.executedActionId !== event.executedActionId,
      );
      setAllExecutedActions([...executedActionsRef.current]);
    }
  }

  function deleteExecutedActionById(id) {
    executedActionsRef.current = executedActionsRef.current.filter((x) => x._id !== id);
    setAllExecutedActions([...executedActionsRef.current]);

    onDeletedAction(id);
  }

  function onCreatedAction(action) {
    setAllCreatedActions([...allCreatedActions, action]);
  }

  function onDeletedAction(actionId) {
    setAllCreatedActions(allCreatedActions.filter((x) => x._id !== actionId));
  }

  function confirmCancelEdit() {
    StateManager.setConfirm('You are about to cancel edit and revert all the changes made', cancelEdit);
  }

  function cancelEdit() {
    setCancellingEditing(true);
    setLoading(true);

    axios
      .post('/process/entries/cancelEdit', { entryId: process._id })
      .then(({ data }) => {
        if (data) {
          setProcess({ ...process, ...data.entryUpdate });
          setCompletedAt(data.entryUpdate);
          setSteps(data.ongoingSteps);
          setHiddenItems(data.hiddenItems);
        }
        setLoading(false);
        StateManager.setSuccessAlert('Done');
        setCancellingEditing(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setCancellingEditing(false);
        setLoading(false);
      });
  }

  function unarchive() {
    setUnarchiving(true);

    axios
      .post('/process/archive/unarchiveEntries', { entriesIds: [process._id], processId: process.processId })
      .then(({ data }) => {
        if (data.unarchived?.length > 0) {
          setProcess({ ...process, archivedAt: null });
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setUnarchiving(false);
      });
  }

  if (loading) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '85vh' }}>
        <CircularProgress style={{ color: blue[500] }} />
      </Grid>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <Typography variant="h4">{error}</Typography>
      </PageContainer>
    );
  }

  if (!process) return null;

  function goBack() {
    history.go(redirected ? -2 : -1);
  }
  return (
    <Grid container>
      <Grid
        container
        sx={{
          borderBottom: `2px solid ${grey[200]}`,
          pb: 1,
          mb: 2,
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid container item lg={4} md={6} sm={12} wrap="nowrap">
            <BackArrowButton onClick={goBack} />

            <div style={{ position: 'relative', display: 'grid' }}>
              <TitleTypography item={process} variant="h5" number={process.processNumber} />
              {process.testModeId && (
                <Chip
                  label={'Test'}
                  size="small"
                  style={{
                    background: deepOrange[700],
                    color: 'white',
                    position: 'absolute',
                    right: -32,
                    top: -16,
                    zIndex: 155,
                  }}
                />
              )}
            </div>
          </Grid>

          <Grid
            container
            item
            lg={4}
            md={6}
            sm={12}
            alignItems="center"
            spacing={1}
            justifyContent={completedAt ? 'space-between' : 'flex-start'}
          >
            {completedAt && (
              <Grid container item style={{ width: 'max-content' }} wrap="nowrap" alignItems="center">
                <CheckCircleOutlineRounded
                  fontSize="large"
                  style={{ color: green[600], marginRight: theme.spacing(1) }}
                />
                <Typography style={{ fontWeight: 500, fontSize: 18 }}>Completed {FormatDate(completedAt)}</Typography>
              </Grid>
            )}

            {info.currStepOwners && !completedAt && !currentlyEditing && (
              <Grid container item style={{ width: 'max-content' }} wrap="nowrap" alignItems="center">
                <Typography style={{ marginRight: theme.spacing(1), fontWeight: 500 }}>Currently with</Typography>
                <UserGroup onlyAvatar avatarSize={28} ids={info.currStepOwners} />
              </Grid>
            )}
            {info.startedBy && (
              <Grid container item style={{ width: 'max-content' }} wrap="nowrap" alignItems="center">
                <Typography style={{ marginRight: theme.spacing(1), fontWeight: 500 }}>Started by</Typography>
                <User onlyAvatar avatarSize={28} id={info.startedBy} />
              </Grid>
            )}
            {currentlyEditing && !isEmpty(process.editingUsers) && (
              <Grid container item style={{ width: 'max-content' }} wrap="nowrap" alignItems="center">
                <Typography sx={{ mr: 1, fontWeight: 500 }}>Being edited by</Typography>
                <UserGroup onlyAvatar avatarSize={28} ids={process.editingUsers} />
              </Grid>
            )}
          </Grid>

          <Grid
            container
            item
            lg={4}
            md={12}
            sm={12}
            alignItems="center"
            justifyContent={largeDevices ? 'flex-end' : undefined}
          >
            {process.state === 'editing' && (
              <Chip label="Editing" sx={{ background: deepOrange[500], color: 'white', mr: 2 }} />
            )}
            {process.state === 'upissuing' && (
              <Chip label="Up-issuing" sx={{ background: deepOrange[500], color: 'white', mr: 2 }} />
            )}
            <Chip label={`v${process.version}`} style={{ background: deepPurple[500], color: 'white' }} />
            {process.number != null && (
              <Chip
                label={`#${addZeros(Number(process.number))}${
                  process.entryVersion ? `-${formatEntryVersion(process.entryVersionFormat, process.entryVersion)}` : ''
                }`}
                style={{ background: teal[500], color: 'white', margin: theme.spacing(0, 1) }}
              />
            )}
            {largeDevices && (
              <Tooltip title="Flow chart">
                <IconButton onClick={() => history.push(`/processes/view-ongoing/${process._id}`)}>
                  <ProcessIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Info">
              <IconButton onClick={() => setInfoOpen(true)}>
                <InfoOutlined style={{ color: grey[600] }} />
              </IconButton>
            </Tooltip>

            <OngoingProcessInfo
              process={process}
              open={infoOpen}
              onChange={(update) => setProcess({ ...process, ...update })}
              onClose={() => setInfoOpen(false)}
              processEditable={processEditable}
            />

            <Tooltip title="Export to PDF">
              <IconButton onClick={downloadPDF}>
                {downloading ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  <GetAppRounded style={{ color: grey[600] }} />
                )}
              </IconButton>
            </Tooltip>

            {stepsEditable && !editMode && !['editing', 'upissuing'].includes(process.state) && (
              <Tooltip title={process.entriesControlled ? 'Up-issue process' : 'Edit process'}>
                <IconButton onClick={startEdit}>
                  {startingEditing ? (
                    <CircularProgress color="primary" size={24} />
                  ) : (
                    <EditRounded style={{ color: grey[600] }} />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {['editing', 'upissuing'].includes(process.state) && (editingByUser || admin) && (
              <Tooltip title={'Cancel edit'}>
                <IconButton onClick={confirmCancelEdit}>
                  {cancellingEditing ? (
                    <CircularProgress color="primary" size={24} />
                  ) : (
                    <HighlightOffRounded style={{ color: red[600] }} />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {processEditable && !completedAt && (
              <ProcessNotificationsDialog
                open={notificationsDialog}
                onClose={() => setNotificationsDialog(false)}
                ongoingProcessId={process._id}
              />
            )}

            {false && (
              <>
                <Tooltip title="Version history">
                  <IconButton onClick={() => setVersionsDialog(true)}>
                    <HistoryRounded style={{ color: grey[600] }} />
                  </IconButton>
                </Tooltip>

                <EntryVersionsDialog
                  open={versionsDialog}
                  onClose={() => setVersionsDialog(false)}
                  entryId={process._id}
                />
              </>
            )}

            {processEditable && (
              <>
                <Tooltip title={saving ? '' : 'More'}>
                  <IconButton onClick={saving ? undefined : (e) => setProcessMenuAnchor(e.currentTarget)}>
                    {saving ? (
                      <CircularProgress color="primary" size={24} />
                    ) : (
                      <MoreHoriz style={{ color: grey[600] }} />
                    )}
                  </IconButton>
                </Tooltip>
                <CancelDialog
                  title="You are about to cancel this process"
                  open={cancelDialog}
                  onClose={() => setCancelDialog(false)}
                  onResult={cancelProcess}
                />
                <HoldDialog open={holdDialog} onClose={() => setHoldDialog(false)} onResult={holdProcess} />

                <Menu
                  open={Boolean(processMenuAnchor)}
                  onClose={() => setProcessMenuAnchor(null)}
                  anchorEl={processMenuAnchor}
                >
                  {!process.cancelledAt && !completedAt && !currentlyEditing && (
                    <MenuItem
                      onClick={() => {
                        setProcessMenuAnchor(null);
                        watchProcess();
                      }}
                    >
                      <ListItemIcon>
                        {watching ? (
                          <VisibilityOff style={{ color: grey[600] }} />
                        ) : (
                          <Visibility style={{ color: grey[600] }} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={watching ? 'Unwatch' : 'Watch'} />
                    </MenuItem>
                  )}
                  {!process.cancelledAt && !completedAt && !currentlyEditing && (
                    <MenuItem
                      onClick={() => {
                        setProcessMenuAnchor(null);
                        setNotificationsDialog(true);
                      }}
                    >
                      <ListItemIcon>
                        <NotificationsActiveRounded style={{ color: grey[600] }} />
                      </ListItemIcon>
                      <ListItemText primary="Notifications" />
                    </MenuItem>
                  )}
                  {!process.cancelledAt && !completedAt && !currentlyEditing && (
                    <MenuItem
                      onClick={() => {
                        setProcessMenuAnchor(null);
                        setCancelDialog(true);
                      }}
                    >
                      <ListItemIcon>
                        <CancelOutlined style={{ color: red[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="Cancel process" />
                    </MenuItem>
                  )}
                  {!process.heldAt && !completedAt && !process.cancelledAt && !currentlyEditing && (
                    <MenuItem
                      onClick={() => {
                        setProcessMenuAnchor(null);
                        setHoldDialog(true);
                      }}
                    >
                      <ListItemIcon>
                        <PauseCircleOutline style={{ color: amber[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="Hold process" />
                    </MenuItem>
                  )}
                  {process.heldAt && (
                    <MenuItem
                      onClick={() => {
                        setProcessMenuAnchor(null);
                        resumeProcess();
                      }}
                    >
                      <ListItemIcon>
                        <PlayCircleOutline style={{ color: green[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="Resume process" />
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      setProcessMenuAnchor(null);
                      setDeleteDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteOutlineRounded style={{ color: red[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Delete process" />
                  </MenuItem>
                </Menu>

                <DeleteProcessDialog
                  open={deleteDialog}
                  onClose={() => setDeleteDialog(false)}
                  process={process}
                  onResult={() => goBack()}
                />
              </>
            )}
          </Grid>
        </Grid>

        {info.refValue && (
          <Grid container item>
            <Typography>Ref: {info.refValue}</Typography>
          </Grid>
        )}

        {process.cancelledAt && (
          <Grid
            container
            item
            sx={{
              borderTop: `2px solid ${grey[200]}`,
              my: 1,
              pt: 1,
            }}
            direction="column"
          >
            <Typography variant="h6" gutterBottom style={{ color: red[500] }}>
              {`Cancelled ${FormatDate(process.cancelledAt)}`}
            </Typography>
            <Grid container>
              <Typography>{`Reason: ${process.cancellationReason}`}</Typography>
            </Grid>
          </Grid>
        )}
        {process.heldAt && (
          <Grid
            container
            item
            sx={{
              borderTop: `2px solid ${grey[200]}`,
              my: 1,
              pt: 1,
            }}
            direction="column"
          >
            <Typography variant="h6" gutterBottom style={{ color: amber[800] }}>
              {`Put on hold ${FormatDate(process.heldAt)}`}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Reason:
            </Typography>
            <Typography>{process.heldReason}</Typography>
          </Grid>
        )}
        {process.archivedAt && (
          <Grid
            container
            item
            sx={{
              borderTop: `2px solid ${grey[200]}`,
              my: 1,
              pt: 1,
            }}
            alignItems={'center'}
          >
            <Typography variant="h6">The process was archived on {FormatOnlyDate(process.archivedAt)}</Typography>
            {processEditable && (
              <Button
                sx={{ ml: 2 }}
                startIcon={unarchiving ? <CircularProgress size={24} /> : <UnarchiveRounded />}
                onClick={() => unarchive()}
              >
                unarchive
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center">
        <Grid container item lg={8} md={10} sm={12}>
          {process.ncrId && (
            <NcrInfo
              ncrId={process.ncrId}
              component={Paper}
              elevation={3}
              sx={{
                p: 2,
                borderRadius: 1.5,
                mb: 2,
              }}
            />
          )}
          {process.assetId && (
            <AssetReference
              assetId={process.assetId}
              component={Paper}
              elevation={3}
              sx={{
                p: 2,
                borderRadius: 1.5,
                mb: 2,
              }}
            />
          )}
          {process.portalSpaceId && (
            <PortalSpaceReference
              portalSpaceId={process.portalSpaceId}
              component={Paper}
              elevation={3}
              sx={{
                p: 2,
                borderRadius: 1.5,
                mb: 2,
              }}
            />
          )}
          {!isEmpty(process.parentProcess) && (
            <Grid container sx={{ py: 1 }}>
              <Grid
                container
                component={Paper}
                elevation={3}
                sx={{
                  p: (theme) => theme.spacing(2, 1),
                  borderRadius: 1.5,
                }}
                alignItems="center"
              >
                <Typography>This process was started because of</Typography>
                <Button
                  onClick={() => history.push(`/processes/ongoing/${process.parentProcess._id}`)}
                  endIcon={<ProcessIcon />}
                  style={{ margin: '0 8px', borderRadius: 8, textTransform: 'none', fontSize: '1rem' }}
                >
                  {process.parentProcess.title} #{process.parentProcess.number}
                </Button>
                <Typography>
                  The last user{process.parentProcess.owners?.length === 1 ? '' : 's'} involved was
                </Typography>
                <UserGroup avatarSize={32} ids={process.parentProcess.owners} style={{ marginLeft: 8 }} />
              </Grid>
            </Grid>
          )}
          {!isEmpty(process.parentFormEntry) && (
            <Grid container sx={{ py: 1 }}>
              <Grid
                container
                component={Paper}
                elevation={3}
                sx={{
                  p: (theme) => theme.spacing(2, 1),
                  borderRadius: 1.5,
                }}
                alignItems="center"
              >
                <Typography style={{ fontWeight: 500 }}>This process was started because of</Typography>
                <Button
                  onClick={() => history.push(`/forms/entry/${process.parentFormEntry._id}`)}
                  endIcon={<FormIcon />}
                  style={{ margin: '0 8px', borderRadius: 8, textTransform: 'none', fontSize: '1rem' }}
                >
                  {process.parentFormEntry.title} #{process.parentFormEntry.number}
                </Button>
              </Grid>
            </Grid>
          )}
          {!isEmpty(process.actionReferenceInfo) && (
            <Grid
              container
              component={Paper}
              elevation={3}
              alignItems="center"
              sx={{
                my: 1,
                px: 1.5,
                borderRadius: 1.5,
              }}
            >
              <FieldReference
                actionReferenceInfo={process.actionReferenceInfo}
                actionReferences={process.actionReferences}
              />
            </Grid>
          )}
          {process.executedActionId && (
            <Grid
              container
              component={Paper}
              elevation={3}
              alignItems="center"
              sx={{
                my: 1,
                px: 1.5,
                borderRadius: 1.5,
              }}
            >
              <ExecutedActionReference executedActionId={process.executedActionId} />
            </Grid>
          )}
          {process.linkEntriesToPortal && (
            <Grid container item sx={{ mb: 2 }}>
              <PortalSpacesSection
                open={portalSpacesSection}
                onClose={() => setPortalSpacesSection(false)}
                onOpen={() => setPortalSpacesSection(true)}
                initial={process.portalSpaceLinks}
                entryId={process._id}
                portalList={process.portalList}
                required={process.portalSelectionMandatory}
                type="process"
              />
            </Grid>
          )}
          {isArray(steps) &&
            steps.map((step, index) => (
              <Step
                key={step.ongoingStepId}
                step={step}
                onComplete={onStepComplete}
                user={user}
                process={process}
                largeDevices={largeDevices}
                calculatedItems={calculatedItems}
                hiddenItems={hiddenItems}
                onCalculationUpdate={onCalculationUpdate}
                onCreatedAction={onCreatedAction}
                onDeletedAction={onDeletedAction}
                onExecutedActionDelete={deleteExecutedActionById}
                onStepsUpdate={setSteps}
                isLastStep={index === steps.length - 1}
                isFirstStep={index === 0}
                allExecutedActions={allExecutedActions}
                allCreatedActions={allCreatedActions}
                involvedUsers={involvedUsers}
                editMode={editMode}
              />
            ))}

          <AttachmentsBar
            initial={files}
            onChange={setFiles}
            ongoingProcessId={process._id}
            editable={processEditable}
          />

          <ActionsBar
            actions={allCreatedActions}
            executedActions={allExecutedActions}
            editable={processEditable}
            onDelete={deleteExecutedActionById}
            type="process"
            entryId={process._id}
          />
        </Grid>
      </Grid>
      {currentlyEditing && (editingByUser || editMode) && (
        <Grid item style={{ position: 'fixed', bottom: largeDevices ? 32 : 64, right: 32 }}>
          <Fab variant="extended" color="primary" onClick={checkUpIssue}>
            {submiting ? (
              <CircularProgress color="primary" style={{ color: 'white' }} size={24} />
            ) : (
              <CheckCircleOutlineRounded />
            )}
            <div style={{ marginLeft: 8 }} />
            {process.state === 'upissuing' ? 'Finish up-issue' : 'Finish edit'}
          </Fab>
        </Grid>
      )}
    </Grid>
  );
}

/*function NextProcessButton({ lastStep, process, style }) {
  const [processTitle, setProcessTitle] = useState('');
  const [createdProcesses, setCreatedProcesses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!lastStep || !lastStep.nextProcessId || !process) return;
    setLoading(true);
    axios
      .get('/process/getNextProcessInfo', {
        params: { nextProcessId: lastStep.nextProcessId, ongoingProcessId: process._id },
      })
      .then((res) => {
        setProcessTitle(res.data.nextProcessTitle);
        setCreatedProcesses(res.data.createdProcesses);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [lastStep, process]);

  function startProcess() {
    setStarting(true);
    axios
      .post('/process/startProcess', { processId: lastStep?.nextProcessId, parentProcessStepId: lastStep._id })
      .then((res) => {
        StateManager.setSuccessAlert('Process has been started');
        setStarting(false);
        history.push(`/processes/ongoing/${res.data.ongoingProcess._id}?started=true`);
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to start the process');
        setStarting(false);
      });
  }

  if (!loading && !processTitle) return null;

  return (
    <Grid container justifyContent="center" style={style}>
      {!loading && (
        <Grid container style={{ marginBottom: 12 }} justifyContent="center">
          {createdProcesses.length === 0 && (
            <Typography color="textSecondary">No processes were created from this entry</Typography>
          )}
          {createdProcesses.length > 0 && (
            <Grid container item lg={8} md={10} xs={12}>
              <Grid container style={{ textAlign: 'center' }}>
                <Typography gutterBottom style={{ fontWeight: 500 }} variant="h5">
                  Processes started from this entry:
                </Typography>
              </Grid>
              {createdProcesses.map((entry) => (
                <Grid container item style={{ margin: '8px 0' }}>
                  <Link to={`/processes/ongoing/${entry._id}`} style={{ width: '100%' }}>
                    <Paper
                      style={{
                        borderRadius: 12,
                        width: '100%',
                      }}
                    >
                      <ListItem
                        button
                        style={{
                          borderRadius: 12,
                          border: entry.completedAt ? `3px solid ${green[600]}` : `3px solid ${orange[500]}`,
                          background: entry.completedAt ? green[50] : orange[50],
                          padding: 0,
                        }}
                      >
                        <Grid
                          container
                          style={{ padding: 12 }}
                          alignItems="center"
                          wrap="nowrap"
                          justifyContent="space-between"
                        >
                          <Typography variant="h6">
                            Entry #{entry.number}: {entry.title}
                          </Typography>
                          {entry.completedAt && (
                            <CheckCircleOutlineRounded fontSize="large" style={{ color: green[600] }} />
                          )}
                        </Grid>
                      </ListItem>
                    </Paper>
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      )}
      {loading ? (
        <CircularProgress color="primary" size={32} />
      ) : (
        <>
          {lastStep.nextProcessStarts === 'anytime' && (
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: 'none', borderRadius: 8 }}
              startIcon={<PlayArrowRounded />}
              endIcon={starting ? <CircularProgress size={24} style={{ color: 'white' }} /> : null}
              onClick={startProcess}
            >
              Start process: {processTitle}
            </Button>
          )}
        </>
      )}
    </Grid>
  );
}*/

function HoldDialog({ open, onClose, onResult }) {
  const [text, setText] = useState('');

  function done() {
    onResult(text);
    onClose();
  }

  useEffect(() => {
    if (!open) return;
    setText('');
  }, [open]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Please provide the reason for putting the process on hold</DialogTitle>
      <DialogContent>
        <Grid container style={{ padding: '1rem 0' }}>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={5}
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Type here"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: red[500], borderRadius: 8 }}>
          cancel
        </Button>
        {text && (
          <Button onClick={done} variant="outlined" color="primary" style={{ borderRadius: 8 }}>
            done
          </Button>
        )}
      </DialogActions>
    </RoundedDialog>
  );
}
