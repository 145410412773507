import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { TreeItem } from '@mui/x-tree-view';
import Box from '@mui/material/Box';

function StyledTreeItem(props) {
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    indent = false,
    indentLevel = 1,
    labelPosition = 'left',
    actions = [],
    ...other
  } = props;
  return (
    <TreeItem
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', padding: (theme) => theme.spacing(0.5, 0) }}>
          {labelPosition === 'left' && <LabelIcon color="inherit" sx={{ marginRight: 1 }} />}
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'inherit',
              flexGrow: 1,
              ...(indent && {
                margin: `5px ${20 * indentLevel}px`,
                color: (t) => (t.palette.mode === 'dark' ? 'white' : 'black'),
                alignItems: 'center',
                fontWeight: indentLevel === 1 ? 'bold' : 'inherit',
              }),
            }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {labelPosition === 'right' && <LabelIcon color="inherit" sx={{ marginRight: 1 }} />}
          {actions}
        </Box>
      }
      sx={{
        color: (theme) => theme.palette.text.secondary,
        '&:hover > $content': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
          backgroundColor: bgColor,
          color,
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
          backgroundColor: 'transparent',
        },
        margin: (theme) => theme.spacing(0.5, 0),
        '& .MuiTreeItem-label': {
          fontWeight: 'inherit',
          color: 'inherit',
        },
        '& .MuiTreeItem-content': {
          color: (theme) => theme.palette.text.secondary,
          borderTopRightRadius: (theme) => theme.spacing(2),
          borderBottomRightRadius: (theme) => theme.spacing(2),
          paddingRight: (theme) => theme.spacing(2),
          fontWeight: (theme) => theme.typography.fontWeightMedium,
          '&.Mui-expanded': {
            fontWeight: (theme) => theme.typography.fontWeightRegular,
          },
        },
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default StyledTreeItem;
