import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, Typography, TextField } from '@mui/material';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';

export default function StockDialog({ initial, open, onClose, onResult }) {
  const [action, setAction] = useState('remove');
  const [value, setValue] = useState(0);
  const max = action === 'remove' ? initial : undefined;
  const remaining = initial + (action === 'remove' ? -1 : 1) * value;

  function done() {
    onResult({ value: isNaN(+value) ? 0 : +value, action });
    setValue(0);
    onClose();
  }

  useEffect(() => {
    if (!open) return;
    setAction('remove');
    setValue(0);
  }, [open]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Remove/Add Stock</DialogTitle>
      <DialogContent>
        <Grid container style={{ padding: '8px 0' }} alignItems="flex-end">
          <FormControl component="fieldset">
            <FormLabel component="legend">Action</FormLabel>
            <RadioGroup name="action" value={action} onChange={(e) => setAction(e.target.value)} row>
              <FormControlLabel value={'remove'} label={'Remove'} control={<Radio color="primary" />} />
              <FormControlLabel value={'add'} label={'Add'} control={<Radio color="primary" />} />
            </RadioGroup>
          </FormControl>
          <Typography gutterBottom style={{ fontSize: 14, marginLeft: 'auto' }} color="textSecondary">
            Remaining: {remaining}
          </Typography>
        </Grid>

        <Grid container item alignItems="center" style={{ padding: '8px 0' }}>
          <Typography style={{ fontWeight: 500, marginRight: 8 }}>How many?</Typography>

          <TextField
            value={value}
            onChange={(e) => {
              if (action === 'remove' && e.target.value > +initial) {
                setValue(isNaN(+initial) ? 0 : +initial);
                return;
              }
              if (!e.target.value || e.target.value >= 0) {
                setValue(e.target.value);
              }
            }}
            style={{ flexGrow: 1 }}
            type="number"
            inputProps={{ maxLength: 16, min: 0, step: 1, max }}
          />
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
