/**
 * * Reducer for File Previewer
 */

import { SELECTFILE } from '../constants';

const initialState = {
  file: null,
  previewDialog: false,
  fullScreen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECTFILE:
      return {
        ...state,
        file: action.payload.file,
        previewDialog: action.payload.state,
        fullScreen: action.payload.fullScreen || state.fullScreen,
      };
    default:
      return state;
  }
}
