import React, { useEffect, useState, useRef } from 'react';
import { GeneralButton, CheckboxWithIcon, ClearFilter } from '../../../../Global/Components';
import { LanguageRounded, DashboardRounded, TrendingFlatRounded, TextFieldsRounded } from '@mui/icons-material';
import { ArrowBackIosRounded, AddRounded, FolderRounded, NavigateNextRounded } from '@mui/icons-material';
import {
  LinkRounded,
  MoreHoriz,
  DeleteOutlineRounded,
  FilterListRounded,
  FolderSpecialOutlined,
} from '@mui/icons-material';
import { grey, blue, red, teal } from '@mui/material/colors';
import { Grid, CircularProgress, Typography, IconButton, Paper, Button, Breadcrumbs } from '@mui/material';
import { Menu, MenuItem, ListItemIcon, ListItemText, Avatar, CardActionArea, Hidden } from '@mui/material';
import { TaskIcon, FormIcon, ProcessIcon, DocIcon, KPIHubIcon } from '../../../../Global/Icons';
import { AssetIcon, AuditIcon, RegistersIcon, RoutineIcon, ProjectIcon } from '../../../../Global/Icons';
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router';
import StateManager from '../../../../Global/StateManager';
import FolderDialog from './FolderDialog';
import AddLinkDialog from './AddLinkDialog';
import FolderTreeDialog from './FolderTreeDialog';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import uniq from 'lodash/uniq';
import axios from 'axios';

const typesMap = {
  formEntry: 'form',
  processEntry: 'process',
  area: 'doc',
  kpiDashboard: 'kpi',
};

const types = {
  work: { icon: <TaskIcon />, text: 'Work hub' },
  task: { icon: <TaskIcon />, text: 'Tasks' },
  form: { icon: <FormIcon />, text: 'Form hub' },
  formEntry: { icon: <FormIcon />, text: 'Form hub' },
  process: { icon: <ProcessIcon />, text: 'Process hub' },
  processEntry: { icon: <ProcessIcon />, text: 'Process hub' },
  doc: { icon: <DocIcon />, text: 'Doc hub' },
  area: { icon: <FolderSpecialOutlined />, text: 'Doc hub Area' },
  docFolder: { icon: <FolderRounded />, text: 'Doc hub Area Folder' },
  kpiDashboard: { icon: <DashboardRounded style={{ color: teal[500] }} />, text: 'KPI hub' },
  kpi: { icon: <KPIHubIcon />, text: 'KPI hub' },
  asset: { icon: <AssetIcon />, text: 'Asset hub' },
  assetProfile: { icon: <AssetIcon />, text: 'Asset hub' },
  audit: { icon: <AuditIcon />, text: 'Audit hub' },
  register: { icon: <RegistersIcon />, text: 'Register hub' },
  project: { icon: <ProjectIcon />, text: 'Projects' },
  routine: { icon: <RoutineIcon />, text: 'Routines' },
  web: { icon: <LanguageRounded color="primary" />, text: 'Web page' },
};

export default function Folder({ initial, workspaceId, readOnly, forPortal, portalSpaceId }) {
  const [currFolder, setCurrFolder] = useState(null);
  const [addFolderDialog, setAddFolderDialog] = useState(false);
  const [addLinkDialog, setAddLinkDialog] = useState(false);
  const [path, setPath] = useState([]);
  const [addMenuAnchor, setAddMenuAnchor] = useState(null);
  const [moveDialog, setMoveDialog] = useState(false);
  const [renameDialog, setRenameDialog] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [movingId, setMovingId] = useState(null);
  const folders = useRef({});
  const loadedChildern = useRef({});
  const history = useHistory();

  const [filterMenu, setFilterMenu] = useState(null);
  const [currFolderTypes, setCurrFolderTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const filtedItems = Array.isArray(currFolder?.items)
    ? currFolder.items.filter(
        (item) => selectedTypes.length === 0 || selectedTypes.includes(typesMap[item.type] || item.type),
      )
    : [];

  useEffect(() => {
    if (!Array.isArray(currFolder?.items)) return;
    setCurrFolderTypes(uniq(currFolder.items.map((x) => typesMap[x.type] || x.type)));
  }, [currFolder]);

  function addFolder(folder) {
    currFolder.folders.push(folder);
    setCurrFolder({ ...currFolder });
    folders.current[folder._id] = folder;
  }

  function addItem(result) {
    if (Array.isArray(result)) {
      for (let i = 0; i < result.length; ++i) {
        currFolder.items.push(result[i]);
      }
    } else {
      currFolder.items.push(result);
    }

    currFolder.items = orderBy(currFolder.items, (o) => String(o.title).toLowerCase());
    setCurrFolder({ ...currFolder });
    folders.current[currFolder._id] = currFolder;
  }

  function deleteItem(itemId) {
    currFolder.items = currFolder.items.filter((x) => x.id !== itemId);
    setCurrFolder({ ...currFolder });
    folders.current[currFolder._id] = currFolder;
  }

  function deleteFolder(folderId) {
    currFolder.folders = currFolder.folders.filter((x) => x._id !== folderId);
    setCurrFolder({ ...currFolder });
  }

  function renameFolder(folder) {
    const index = currFolder.folders.findIndex((x) => folder._id === x._id);
    if (index === -1) return;
    currFolder.folders[index].title = folder.title;
    setCurrFolder({ ...currFolder });

    if (folders.current[folder._id]) {
      folders.current[folder._id].title = folder.title;
    }

    if (folders.current[folder.parentId] && Array.isArray(folders.current[folder.parentId].folders)) {
      const folderIndex = folders.current[folder.parentId].folders.findIndex((x) => folder._id === x._id);
      if (folderIndex !== -1) {
        folders.current[folder.parentId].folders[folderIndex].title = folder.title;
      }
    }
  }

  function preloadChildren(parentId) {
    if (loadedChildern.current[parentId]) return;
    axios
      .get('/workhub/workspace/getFolderChildren', { params: { parentId } })
      .then((res) => {
        loadedChildern.current[parentId] = true;
        if (!Array.isArray(res.data)) return;
        res.data.forEach((folder) => (folders.current[folder._id] = folder));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (!initial) return;
    setCurrFolder(initial);
    folders.current[initial._id] = initial;
    setPath([{ _id: initial._id, title: 'Root' }]);
    preloadChildren(initial._id);
  }, [initial]); // eslint-disable-line

  function updatePath(currentFolder) {
    let curr = currentFolder;
    let newPath = [];
    while (curr?.parentId) {
      newPath.unshift({ _id: curr._id, title: curr.title });
      curr = folders.current[curr.parentId];
    }
    if (curr) {
      newPath.unshift({ _id: curr._id, title: 'Root' });
    }
    setPath(newPath);
  }

  function goToFolder(folderId) {
    if (currFolder._id === folderId) return;
    setSelectedTypes([]);
    if (folders.current[folderId]) {
      setCurrFolder(folders.current[folderId]);
      updatePath(folders.current[folderId]);
      preloadChildren(folderId);
    } else {
      setCurrFolder(null);
      axios
        .get('/workhub/workspace/getFolder', { params: { folderId } })
        .then((res) => {
          setCurrFolder(res.data);
          updatePath(res.data);
          folders.current[folderId] = res.data;
          preloadChildren(folderId);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
        });
    }
  }

  function move(dest) {
    if (currFolder._id === dest._id) return;

    if (selectedFolder) {
      setMovingId(selectedFolder._id);
      axios
        .post('/workhub/workspace/moveFolder', {
          folderId: selectedFolder._id,
          destinationFolderId: dest._id,
        })
        .then(() => {
          deleteFolder(selectedFolder._id);
          StateManager.setSuccessAlert('Folder has been moved');
          setMovingId(null);
          if (folders.current[dest._id]) {
            folders.current[dest._id].folders.push(selectedFolder);
          }
        })
        .catch((err) => {
          setMovingId(null);
          StateManager.setAxiosErrorAlert(err);
        });
    } else if (selectedItem) {
      setMovingId(selectedItem.id);
      axios
        .post('/workhub/workspace/moveItem', {
          folderId: currFolder._id,
          item: selectedItem,
          destinationFolderId: dest._id,
        })
        .then(() => {
          deleteItem(selectedItem.id);
          StateManager.setSuccessAlert('Item has been moved');
          setMovingId(null);
          if (folders.current[dest._id]) {
            folders.current[dest._id].items.push(selectedItem);
          }
        })
        .catch((err) => {
          setMovingId(null);
          StateManager.setAxiosErrorAlert(err);
        });
    }
  }

  /*if (!currFolder) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: 300 }}>
        <CircularProgress color="primary" />
      </Grid>
    );
  }*/

  return (
    <Grid container style={{ padding: 8 }}>
      <Grid container alignItems="center">
        {(!currFolder || currFolder.parentId) && (
          <Button startIcon={<ArrowBackIosRounded />} color="primary" onClick={() => goToFolder(currFolder.parentId)}>
            back
          </Button>
        )}
        {path.length > 1 && (
          <Breadcrumbs separator={<NavigateNextRounded fontSize="small" />} style={{ marginLeft: 16 }}>
            {path.map((item) => (
              <Typography
                key={item._id}
                onClick={() => goToFolder(item._id)}
                sx={{
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {item.title}
              </Typography>
            ))}
          </Breadcrumbs>
        )}
      </Grid>

      <Grid container style={{ padding: 8 }} alignItems="center">
        {(!currFolder || currFolder.folders.length > 0) && <Typography variant="h6">Folders</Typography>}
        {currFolder?.folders?.length === 0 && currFolder?.items?.length > 0 && (
          <Typography variant="h6">Links</Typography>
        )}
        {currFolder?.folders?.length + currFolder?.items?.length === 0 && (
          <Typography variant="h6" color="textSecondary" style={{ marginLeft: 8 }}>
            {readOnly ? `No items in this folder` : `Click 'Plus' button on the right to add the first folder or link`}
          </Typography>
        )}
        <div style={{ marginLeft: 'auto' }} />
        {currFolderTypes.length > 1 && (
          <>
            <GeneralButton
              style={{ marginRight: 16, border: selectedTypes.length > 0 ? `2px solid ${blue[500]}` : undefined }}
              endIcon={<FilterListRounded style={{ color: selectedTypes.length > 0 ? blue[500] : grey[500] }} />}
              onClick={(e) => setFilterMenu(e.currentTarget)}
            >
              Filter by type
            </GeneralButton>

            <Menu anchorEl={filterMenu} open={Boolean(filterMenu)} onClose={() => setFilterMenu(null)}>
              {currFolderTypes.map((type, i) => (
                <CheckboxWithIcon
                  key={i}
                  checked={selectedTypes.includes(type)}
                  onChange={(val) => {
                    if (val) {
                      setSelectedTypes([...selectedTypes, type]);
                    } else {
                      setSelectedTypes(selectedTypes.filter((x) => x !== type));
                    }
                  }}
                  text={types[type]?.text}
                  icon={types[type]?.icon}
                />
              ))}
              <ClearFilter
                onClick={() => {
                  setSelectedTypes([]);
                  setFilterMenu(null);
                }}
              />
            </Menu>
          </>
        )}

        {!readOnly && (
          <>
            <IconButton
              style={{ background: blue[500], width: 48, height: 48 }}
              onClick={(e) => setAddMenuAnchor(e.currentTarget)}
            >
              <AddRounded fontSize="large" style={{ color: 'white' }} />
            </IconButton>
            <Menu anchorEl={addMenuAnchor} open={Boolean(addMenuAnchor)} onClose={() => setAddMenuAnchor(null)}>
              <MenuItem
                onClick={() => {
                  setAddLinkDialog(true);
                  setAddMenuAnchor(null);
                }}
              >
                <ListItemIcon>
                  <LinkRounded style={{ color: grey[500] }} />
                </ListItemIcon>
                <ListItemText primary="Add link" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAddFolderDialog(true);
                  setAddMenuAnchor(null);
                }}
              >
                <ListItemIcon>
                  <FolderRounded style={{ color: grey[500] }} />
                </ListItemIcon>
                <ListItemText primary="Add folder" />
              </MenuItem>
            </Menu>
            <AddLinkDialog
              folderId={currFolder?._id}
              open={addLinkDialog}
              onClose={() => setAddLinkDialog(false)}
              onResult={addItem}
              forPortal={forPortal}
              allowBulk
            />
            <FolderDialog
              parentId={currFolder?._id}
              open={addFolderDialog}
              onClose={() => setAddFolderDialog(false)}
              onResult={addFolder}
            />
            <FolderDialog
              open={renameDialog}
              onClose={() => setRenameDialog(false)}
              onResult={renameFolder}
              initial={selectedFolder}
            />
          </>
        )}
      </Grid>

      <Grid container>
        {Array.isArray(currFolder?.folders) &&
          currFolder.folders.map((folder) => (
            <Grid container key={folder._id} lg={3} md={4} xs={12} item style={{ padding: 8 }}>
              <FolderItem
                readOnly={readOnly}
                folder={folder}
                onClick={() => goToFolder(folder._id)}
                onDelete={() => deleteFolder(folder._id)}
                onMove={() => {
                  setMoveDialog(true);
                  setSelectedFolder(folder);
                  setSelectedItem(null);
                }}
                moving={movingId === folder._id}
                onRename={() => {
                  setRenameDialog(true);
                  setSelectedFolder(folder);
                  setSelectedItem(null);
                }}
              />
            </Grid>
          ))}
        {!Array.isArray(currFolder?.folders) &&
          range(2).map((x) => (
            <Grid container key={x} lg={3} md={4} xs={12} item style={{ padding: 8 }}>
              <Paper
                variant="outlined"
                sx={{
                  borderRadius: 1,
                  py: 1,
                  px: 2,
                  border: `2px solid ${grey[300]}`,
                  width: '100%',
                }}
              >
                <Grid container alignItems="center">
                  <Skeleton variant="circular" width={48} height={48} />
                  <Skeleton variant="text" width={150} style={{ marginLeft: 16, fontSize: '1.25rem' }} />
                </Grid>
              </Paper>
            </Grid>
          ))}
      </Grid>
      {(!currFolder || (currFolder.items.length > 0 && currFolder.folders.length > 0)) && (
        <Grid container style={{ padding: 8 }}>
          <Typography variant="h6">Links</Typography>
        </Grid>
      )}
      <Grid container>
        {Array.isArray(currFolder?.items) &&
          filtedItems.map((item) => (
            <Item
              readOnly={readOnly}
              key={item.id}
              item={item}
              folderId={currFolder._id}
              history={history}
              onDelete={() => deleteItem(item.id)}
              moving={movingId === item.id}
              portalSpaceId={portalSpaceId}
              onMove={() => {
                setMoveDialog(true);
                setSelectedItem(item);
                setSelectedFolder(null);
              }}
            />
          ))}
        {!Array.isArray(currFolder?.items) &&
          range(2).map((x) => (
            <Grid container key={x} item style={{ padding: 8 }}>
              <Paper
                variant="outlined"
                sx={{
                  borderRadius: 1,
                  py: 1,
                  px: 2,
                  border: `2px solid ${grey[300]}`,
                  width: '100%',
                }}
              >
                <Grid container alignItems="center">
                  <Skeleton variant="circular" width={48} height={48} />
                  <Skeleton variant="text" width={250} style={{ marginLeft: 16, fontSize: '1.25rem' }} />
                </Grid>
              </Paper>
            </Grid>
          ))}
      </Grid>

      <FolderTreeDialog
        workspaceId={workspaceId}
        open={moveDialog}
        onClose={() => setMoveDialog(false)}
        onResult={move}
      />
    </Grid>
  );
}

function Item({ folderId, item, history, onDelete, onMove, moving, readOnly, portalSpaceId }) {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [saving, setSaving] = useState(false);

  const webPageIcon = item?.type === 'web' ? getWebPageIcon() : null;

  function getWebPageIcon() {
    let parsed;
    try {
      parsed = new URL(item.link);
    } catch (_) {
      return null;
    }
    return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${parsed.host}&size=64`;
  }

  function getItemLink() {
    if (item.link) return item.link;
    if (item.type === 'project') return `/tasks/projects/project/${item.itemId}`;
    if (item.type === 'routine') return `/tasks/routine/${item.itemId}`;
    if (item.type === 'form') return `/forms/${item.itemId}${portalSpaceId ? `?portalSpaceId=${portalSpaceId}` : ''}`;
    if (item.type === 'process')
      return `/processes/${item.itemId}${portalSpaceId ? `?portalSpaceId=${portalSpaceId}` : ''}`;
    if (item.type === 'asset') return `/asset/${item.itemId}`;
    if (item.type === 'assetProfile') return `/asset/profile/${item.itemId}`;
    if (item.type === 'audit') return `/audits/${item.itemId}`;
    if (item.type === 'register') return `/registers/${item.itemId}`;
    if (item.type === 'doc') return `/DocLibrary/preview/${item.itemId}`;
    if (item.type === 'area') {
      if (item.subId) {
        return `/DocLibrary/areas/${item.itemId}/${item.subId}`;
      }
      return `/DocLibrary/areas/${item.itemId}`;
    }
    if (item.type === 'docFolder') {
      if (item.subId) {
        return `/DocLibrary/areas/${item.itemId}/${item.subId}`;
      }
    }
    if (item.type === 'kpiDashboard') return `/kpiHub/dashboard/${item.itemId}`;

    return '#';
  }

  function onClick() {
    if (item.type === 'task' && item.itemId) {
      StateManager.selectTask(item.itemId);
      return;
    }
    if (item.type === 'web') {
      window.open(item.link, '_blank');
      return;
    }
    const link = getItemLink();
    history.push(link);
  }

  function deleteItem() {
    setSaving(true);
    axios
      .post('/workhub/workspace/deleteItem', { folderId, item })
      .then(() => {
        onDelete();
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <Grid container item style={{ padding: 8 }}>
      <CardActionArea
        sx={{
          borderRadius: 1,
          width: '100%',
          padding: 0,
        }}
        onClick={onClick}
      >
        <Paper
          variant="outlined"
          sx={{
            borderRadius: 1,
            py: 1,
            px: 2,
            border: `2px solid ${grey[300]}`,
            width: '100%',
          }}
        >
          <Grid container alignItems="center" wrap="nowrap">
            <Grid container item alignItems="center" wrap="nowrap" lg={6} md={6} xs={10}>
              {webPageIcon ? (
                <Avatar variant="rounded" style={{ height: 24, width: 24 }} src={webPageIcon}>
                  <LanguageRounded color="primary" />
                </Avatar>
              ) : (
                types[item.type]?.icon
              )}
              <Typography variant="h6" noWrap style={{ marginLeft: 16 }}>
                {item.title}
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid container item alignItems="center" wrap="nowrap" xs={4}>
                <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                  <Typography color="textSecondary" style={{ marginRight: 12 }}>
                    Linked to
                  </Typography>
                </Grid>

                <Grid container item xs={6} justifyContent="flex-start" alignItems="center">
                  <Typography color="textSecondary">{types[item.type]?.text}</Typography>
                </Grid>
              </Grid>
            </Hidden>
            {!readOnly && (
              <Grid container item alignItems="center" justifyContent="flex-end" xs={2}>
                {saving || moving ? (
                  <CircularProgress color="primary" size={28} style={{ margin: 10 }} />
                ) : (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setMenuAnchor(e.currentTarget);
                    }}
                  >
                    <MoreHoriz color="primary" />
                  </IconButton>
                )}
                <Menu
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={(e) => {
                    e.stopPropagation();
                    setMenuAnchor(null);
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setMenuAnchor(null);
                      deleteItem();
                    }}
                  >
                    <ListItemIcon>
                      <DeleteOutlineRounded style={{ color: red[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Delete item" />
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setMenuAnchor(null);
                      onMove();
                    }}
                  >
                    <ListItemIcon>
                      <TrendingFlatRounded style={{ color: grey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Move item" />
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </Paper>
      </CardActionArea>
    </Grid>
  );
}

function FolderItem({ folder, onClick, onDelete, onMove, onRename, moving, readOnly }) {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [saving, setSaving] = useState(false);

  function confirmDelete() {
    StateManager.setConfirm(
      'You are about to delete this folder and all its subfolders',
      deleteFolder,
      'This action cannot be undone. Are you sure you want to continue?',
    );
  }

  function deleteFolder() {
    setSaving(true);
    axios
      .post('/workhub/workspace/deleteFolder', { folderId: folder._id })
      .then(() => {
        onDelete();
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <CardActionArea sx={{ borderRadius: 1, width: '100%', p: 0 }} onClick={onClick}>
      <Paper
        variant="outlined"
        sx={{
          borderRadius: 1,
          py: 1,
          px: 2,
          border: `2px solid ${grey[300]}`,
          width: '100%',
        }}
      >
        <Grid container alignItems="center" wrap="nowrap">
          <FolderRounded fontSize="large" style={{ color: grey[400], marginRight: 16 }} />
          <Typography noWrap variant="h6">
            {folder.title}
          </Typography>
          <div style={{ marginLeft: 'auto' }} />
          {!readOnly && (
            <>
              {saving || moving ? (
                <CircularProgress color="primary" size={28} style={{ margin: 10 }} />
              ) : (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuAnchor(e.currentTarget);
                  }}
                >
                  <MoreHoriz color="primary" />
                </IconButton>
              )}
            </>
          )}
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={(e) => {
              e.stopPropagation();
              setMenuAnchor(null);
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setMenuAnchor(null);
                confirmDelete();
              }}
            >
              <ListItemIcon>
                <DeleteOutlineRounded style={{ color: red[500] }} />
              </ListItemIcon>
              <ListItemText primary="Delete folder" />
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setMenuAnchor(null);
                onMove();
              }}
            >
              <ListItemIcon>
                <TrendingFlatRounded style={{ color: grey[500] }} />
              </ListItemIcon>
              <ListItemText primary="Move folder" />
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setMenuAnchor(null);
                onRename();
              }}
            >
              <ListItemIcon>
                <TextFieldsRounded style={{ color: grey[500] }} />
              </ListItemIcon>
              <ListItemText primary="Rename folder" />
            </MenuItem>
          </Menu>
        </Grid>
      </Paper>
    </CardActionArea>
  );
}
