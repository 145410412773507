import React, { useState, useEffect } from 'react';
import { red, green } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Tooltip, IconButton, TextField, Chip } from '@mui/material';
import { MailOutlineRounded, DoneRounded, CloseRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import { validateEmail } from '../../../Global/Functions';
import StateManager from '../../../Global/StateManager';
import axios from 'axios';

export default function EmailDialog({ open, onClose, entryId, onResult, useOptions }) {
  const [emails, setEmails] = useState([]);
  const [currEmail, setCurrEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailing, setEmailing] = useState(false);

  useEffect(() => {
    if (!open) return;
    setCurrEmail('');
    setEmails([]);
  }, [open]);

  function send() {
    if (emailing) return;
    if (currEmail && validateEmail(currEmail)) {
      emails.push(currEmail);
    }
    setEmailing(true);
    axios
      .post('/forms/entries/emailCompletedEntry', { entryId, emails, useOptions })
      .then((res) => {
        StateManager.setSuccessAlert('Form has been emailed');
        setEmailing(false);
        if (typeof onResult === 'function') {
          onResult(res.data.sentTo);
        }
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setEmailing(false);
      });
  }

  function addEmail() {
    let email = String(currEmail).trim().toLowerCase();
    if (!email) return;
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    if (emails.includes(email)) {
      setCurrEmail('');
      return;
    }
    setEmails([...emails, email]);
    setCurrEmail('');
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Email the entry</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid container item alignItems="center">
            {emails.map((email) => (
              <Chip
                key={email}
                style={{ margin: '8px 16px 8px 0px' }}
                color="primary"
                label={email}
                onDelete={() => setEmails(emails.filter((x) => x !== email))}
              />
            ))}
          </Grid>
          <Grid container item style={{ margin: '0.5rem 0' }}>
            <TextField
              placeholder="Enter email and press 'Enter'"
              helperText={emailError ? 'Enter valid email' : ''}
              error={emailError}
              style={{ flexGrow: 1, marginRight: 8 }}
              value={currEmail}
              onChange={(e) => {
                setCurrEmail(e.target.value);
                if (emailError) {
                  setEmailError(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addEmail();
                }
              }}
              variant="standard"
            />

            <Tooltip title="Add" placement="top">
              <IconButton onClick={addEmail}>
                <DoneRounded style={{ color: green[500] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Clear" placement="top">
              <IconButton
                onClick={() => {
                  setCurrEmail('');
                  setEmailError(false);
                }}
              >
                <CloseRounded style={{ color: red[500] }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        saving={emailing}
        onClose={onClose}
        onDone={send}
        hideDone={emails.length === 0 && !currEmail}
        doneButtonText="SEND"
        closeButtonText="CANCEL"
        doneButtonIcon={<MailOutlineRounded />}
      />
    </RoundedDialog>
  );
}
