import React, { useState, useEffect, useRef } from 'react';
import { Chip } from '@mui/material';
import { GroupRounded } from '@mui/icons-material';
import UserManager from '../UserManager';

function isHex24(str) {
  if (!str || typeof str !== 'string') return false;
  const re = /\b[0-9A-Fa-f]{24}\b/;
  return re.test(str);
}

export default function GroupChip({ id, onDelete, margin = '8px 16px 8px 0px', sx, ...props }) {
  const [group, setGroup] = useState(null);
  const subscription = useRef(null);

  useEffect(() => {
    return () => {
      if (!subscription.current) return;
      subscription.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isHex24(id)) return;
    const resolved = UserManager.resolveGroup(id);
    if (resolved) {
      setGroup(resolved);
    } else {
      subscription.current = UserManager.subscribeToGroup(id, (res) => {
        setGroup(res);
        subscription.current.unsubscribe();
      });
    }
  }, [id]);

  return (
    <Chip
      sx={sx}
      style={{ margin }}
      icon={<GroupRounded />}
      label={group?.name || 'Loading...'}
      onDelete={onDelete}
      {...props}
    />
  );
}
