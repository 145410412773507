/**
 * * Reducer for Document Hub, follows the cases as per relevant actions
 */
import {
  GETTAGS_ATTEMPT,
  GETTAGS_SUCCESS,
  GETTAGS_FAIL,
  ADDTAG_ATTEMPT,
  ADDTAG_SUCCESS,
  ADDTAG_FAIL,
  UPDATETAG_ATTEMPT,
  UPDATETAG_SUCCESS,
  UPDATETAG_FAIL,
  REMOVETAG_ATTEMPT,
  REMOVETAG_SUCCESS,
  REMOVETAG_FAIL,
  GETPREFIXES_ATTEMPT,
  GETPREFIXES_SUCCESS,
  GETPREFIXES_FAIL,
  ADDPREFIX_ATTEMPT,
  ADDPREFIX_SUCCESS,
  ADDPREFIX_FAIL,
  UPDATEPREFIX_ATTEMPT,
  UPDATEPREFIX_SUCCESS,
  UPDATEPREFIX_FAIL,
  REMOVEPREFIX_ATTEMPT,
  REMOVEPREFIX_SUCCESS,
  REMOVEPREFIX_FAIL,
  GETDOCUSERS_ATTEMPT,
  GETDOCUSERS_SUCCESS,
  GETDOCUSERS_FAIL,
  GETSETTINGS_ATTEMPT,
  GETSETTINGS_SUCCESS,
  GETSETTINGS_FAIL,
  UPDATESETTINGS_ATTEMPT,
  UPDATESETTINGS_SUCCESS,
  UPDATESETTINGS_FAIL,
  SETUPDATE,
  SETSUGGESTIONS,
} from '../constants';

const initialState = {
  revisionFormat: 'V1,V2,V3,V4',
  workflowDays: 5,
  documentPrefix: 'DOC',
  conventionSets: false,
  loading: false,
  users: [],
  tags: [],
  prefixes: [],
  suggestions: [],
  documentSharing: false,
  defaultDocumentSetting: false,
  downloadFiles: true,
  printFiles: true,
  uploadSetting: false,
  uploadSettingType: 'All',
  uploadAreas: [],
  upIssueSignOff: false,
  ruAccess: false,
  ruPageAccess: null,
  standardUpIssueTasks: false,
  adhocActions: false,
  upIssueActions: [],
  defaultHeaderFooter: false,
  headerConfig: [],
  footerConfig: [],
  defaultDataGridPageSize: 500,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETSETTINGS_ATTEMPT:
      return { ...state, loading: true };
    case GETSETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        revisionFormat: action.payload.revisionFormat,
        workflowDays: action.payload.workflowDays,
        conventionSets: action.payload.conventionSets,
        documentSharing: action.payload.documentSharing,
        defaultDocumentSetting: action.payload.defaultDocumentSetting,
        downloadFiles: action.payload.downloadFiles,
        printFiles: action.payload.printFiles,
        uploadSetting: action.payload.uploadSetting,
        uploadSettingType: action.payload.uploadSettingType,
        uploadAreas: action.payload.uploadAreas,
        upIssueSignOff: action.payload.upIssueSignOff,
        ruPageAccess: action.payload.ruPageAccess,
        ruAccess: action.payload.ruAccess,
        standardUpIssueTasks: action.payload.standardUpIssueTasks,
        adhocActions: action.payload.adhocActions,
        upIssueActions: action.payload.upIssueActions,
        defaultHeaderFooter: action.payload.defaultHeaderFooter,
        headerConfig: action.payload.headerConfig,
        footerConfig: action.payload.footerConfig,
        defaultDataGridPageSize: action.payload.defaultDataGridPageSize,
      };
    case GETSETTINGS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPDATESETTINGS_ATTEMPT:
      return { ...state, loading: true };
    case UPDATESETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        revisionFormat: action.payload.revisionFormat,
        workflowDays: action.payload.workflowDays,
        conventionSets: action.payload.conventionSets,
        defaultDocumentSetting: action.payload.defaultDocumentSetting,
        documentSharing: action.payload.documentSharing,
        downloadFiles: action.payload.downloadFiles,
        printFiles: action.payload.printFiles,
        uploadSetting: action.payload.uploadSetting,
        uploadSettingType: action.payload.uploadSettingType,
        uploadAreas: action.payload.uploadAreas,
        upIssueSignOff: action.payload.upIssueSignOff,
        ruAccess: action.payload.ruAccess,
        standardUpIssueTasks: action.payload.standardUpIssueTasks,
        adhocActions: action.payload.adhocActions,
        upIssueActions: action.payload.upIssueActions,
        defaultHeaderFooter: action.payload.defaultHeaderFooter,
        headerConfig: action.payload.headerConfig,
        footerConfig: action.payload.footerConfig,
        defaultDataGridPageSize: action.payload.defaultDataGridPageSize,
      };
    case UPDATESETTINGS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SETUPDATE:
      return { ...state, loading: false, ...action.payload };
    case GETTAGS_ATTEMPT:
      return { ...state, loading: true, tags: [] };
    case GETTAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action.payload.tags,
      };
    case GETTAGS_FAIL:
      return { ...state, loading: false, tags: null, error: action.payload };
    case ADDTAG_ATTEMPT:
      return { ...state, loading: true };
    case ADDTAG_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: state.tags ? [...state.tags, action.payload.tag] : [action.payload.tag],
      };
    case ADDTAG_FAIL:
      return { ...state, loading: false, tags: null, error: action.payload };
    case UPDATETAG_ATTEMPT:
      return { ...state, loading: true };
    case UPDATETAG_SUCCESS:
      let updatedTags = state.tags.map((d) => {
        if (d._id === action.payload.tag._id) {
          return action.payload.tag;
        }
        return d;
      });
      return { ...state, loading: false, tags: updatedTags };
    case UPDATETAG_FAIL:
      return { ...state, loading: false, tags: null, error: action.payload };
    case REMOVETAG_ATTEMPT:
      return { ...state, loading: true };
    case REMOVETAG_SUCCESS:
      return { ...state, loading: false, tags: state.tags.filter((tag) => tag._id !== action.payload.tag) };
    case REMOVETAG_FAIL:
      return { ...state, loading: false, tags: null, error: action.payload };
    case GETPREFIXES_ATTEMPT:
      return { ...state, loading: true, prefixes: [] };
    case GETPREFIXES_SUCCESS:
      return {
        ...state,
        loading: false,
        prefixes: action.payload.prefixes,
      };
    case GETPREFIXES_FAIL:
      return { ...state, loading: false, prefixes: null, error: action.payload };
    case ADDPREFIX_ATTEMPT:
      return { ...state, loading: true };
    case ADDPREFIX_SUCCESS:
      return {
        ...state,
        loading: false,
        prefixes: state.prefixes ? [...state.prefixes, action.payload.prefix] : [action.payload.prefix],
      };
    case ADDPREFIX_FAIL:
      return { ...state, loading: false, prefixes: null, error: action.payload };
    case UPDATEPREFIX_ATTEMPT:
      return { ...state, loading: true };
    case UPDATEPREFIX_SUCCESS:
      let updatedPrefixes = state.prefixes.map((d) => {
        if (d._id === action.payload.prefix._id) {
          return action.payload.prefix;
        }
        return d;
      });
      return { ...state, loading: false, prefixes: updatedPrefixes };
    case UPDATEPREFIX_FAIL:
      return { ...state, loading: false, prefixes: null, error: action.payload };
    case REMOVEPREFIX_ATTEMPT:
      return { ...state, loading: true };
    case REMOVEPREFIX_SUCCESS:
      return {
        ...state,
        loading: false,
        prefixes: state.prefixes.filter((prefixe) => prefixe._id !== action.payload.prefix),
      };
    case REMOVEPREFIX_FAIL:
      return { ...state, loading: false, prefixes: null, error: action.payload };
    case GETDOCUSERS_ATTEMPT:
      return { ...state, loading: true };
    case GETDOCUSERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        suggestions: action.payload.users
          ?.map((u) => ({
            _id: u._id,
            name: u.fullName,
            avatar: u.picture
              ? u.picture
              : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
          }))
          ?.filter((a, b, c) => c.findIndex((d) => d._id === a._id) === b),
      };
    case GETDOCUSERS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SETSUGGESTIONS:
      return { ...state, suggestions: action.payload };
    default:
      return state;
  }
}
