import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { TooltipTypography } from '../Components';
import SectionsPreview from '../../Hubs/dochub/components/HTMLEditor/components/Sections/preview';
import HeaderFooter from '../../Hubs/dochub/components/HeaderFooterPreview/headerFooter';

export default function QDocViewer({
  title,
  src,
  hasToc,
  handleScroll,
  enableScroll,
  document,
  isTemplate,
  height = '70vh',
  searchParams,
}) {
  const { defaultHeaderFooter, headerConfig, footerConfig } = useSelector(({ settings }) => settings);

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent={'center'}
      sx={{ minHeight: '100%', overflow: 'hidden' }}
    >
      <Grid
        component={Paper}
        elevation={2}
        item
        sm={10}
        container
        direction={'row'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        onScroll={handleScroll}
        sx={{
          // mt: 2,
          padding: '20px 2%',
          minHeight: '100%',
          height: enableScroll ? height : '100%',
          overflowY: enableScroll ? 'scroll' : 'inherit',
        }}
      >
        <HeaderFooter
          document={document}
          header={defaultHeaderFooter ? headerConfig : document?.headerConfig}
          qtemplate={isTemplate}
        />

        <Grid item sm xs={12} sx={{ marginLeft: '0px', padding: '10px 0px' }}>
          <TooltipTypography
            sx={{ fontWeight: 'bold', fontSize: 'var(--title-size)', margin: '0px 70px' }}
            noWrap={false}
          >
            {title}
          </TooltipTypography>
        </Grid>

        <SectionsPreview initial={src} hasToc={hasToc} {...searchParams} />

        <HeaderFooter
          document={document}
          footer={defaultHeaderFooter ? footerConfig : document?.footerConfig}
          qtemplate={isTemplate}
        />
      </Grid>
    </Grid>
  );
}
