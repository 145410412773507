import { drawerOptions } from '../../../constants';

export const setupUsers = [
  {
    target: '.add-user',
    content: 'Click to Add User',
    ...drawerOptions,
    locale: {
      last: 'Next',
    },
  },
];

export const createUser = [
  {
    target: '.userInfo',
    content: 'Add User Information',
    ...drawerOptions,
  },
  {
    target: '.userAccess',
    content: 'Set User Access',
    ...drawerOptions,
  },
  {
    target: '.addUserBtn',
    content: 'Finish Creating User',
    ...drawerOptions,
    locale: {
      last: 'Finish',
    },
  },
];
