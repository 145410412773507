import React, { useState, useEffect, useRef } from 'react';
import { Grid, Paper, Collapse, Button, TextField, Typography, InputAdornment } from '@mui/material';
import { DialogContent, DialogTitle, CircularProgress, List, ListItem, ListItemIcon } from '@mui/material';
import { IconButton } from '@mui/material';
import { grey, red, green, yellow } from '@mui/material/colors';
import { PersonAddRounded, ExpandLessRounded, ExpandMoreRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { PersonRounded, AlternateEmailRounded, PhoneRounded, NotesRounded, SearchRounded } from '@mui/icons-material';
import { AccountCircleRounded, CheckCircleOutlineRounded, CheckCircleRounded, ClearRounded } from '@mui/icons-material';
import { validateUkNumber, validateEmail } from '../Functions';
import { RoundedDialog, StandardDialogActions, TooltipTypography } from '../Components';
import { isEmpty, isArray, isString } from 'lodash';
import axios from 'axios';
import { v4 } from 'uuid';
import StateManager from '../StateManager';
import { styled } from '@mui/material/styles';

const TableCellContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0.6),
}));

export default function PeopleCard({ initial, editable, options, onChange }) {
  const [people, setPeople] = useState([]);
  const [dialog, setDialog] = useState(null);
  const timer = useRef(null);
  const SAVING_DELAY = 1000;

  useEffect(() => {
    if (Array.isArray(initial)) {
      setPeople(initial);
    } else {
      if (!options || !editable || options.selectFromContacts) return;
      if (options.min !== null) {
        const remaining = options.min - people.length;
        for (let i = 0; i < remaining; ++i) {
          const person = {
            id: v4(),
            firstName: '',
            lastName: '',
            email: '',
          };
          people.push(person);
        }
        setPeople([...people]);
      }
    }
  }, [options, editable, initial]); // eslint-disable-line

  function addPerson() {
    const person = {
      id: v4(),
      firstName: '',
      lastName: '',
      email: '',
    };
    setPeople([...people, person]);
  }

  function savePerson(person) {
    if (!person?.id) return;
    const index = people.findIndex((x) => x.id === person.id);
    if (index === -1) return;
    people[index] = person;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => save(people), SAVING_DELAY);
  }

  function save(result) {
    onChange(result);
  }

  function deletePerson(id) {
    const remaining = people.filter((x) => x.id !== id);
    setPeople(remaining);
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => save(remaining), SAVING_DELAY);
  }

  return (
    <Grid container spacing={1}>
      {people.map((person, index) => (
        <Grid
          container
          item
          lg={index === people.length - 1 && people.length % 2 === 1 ? 12 : 6}
          md={index === people.length - 1 && people.length % 2 === 1 ? 12 : 6}
          xs={12}
          key={person.id}
        >
          <Person
            requiredFields={options?.requiredFields}
            initial={person}
            editable={editable && !options?.selectFromContacts}
            onChange={savePerson}
            onDelete={() => deletePerson(person.id)}
          />
        </Grid>
      ))}
      {options?.selectFromContacts
        ? editable && (
            <Grid container item>
              <Button
                startIcon={<PersonAddRounded />}
                style={{ color: grey[600], borderRadius: 8 }}
                onClick={() => setDialog(true)}
              >
                select people
              </Button>
              <ContactsDialog
                open={dialog}
                onClose={() => setDialog(false)}
                options={options}
                onResult={(res) => {
                  setPeople(res);
                  save(res);
                }}
                initial={people.map((p) => p.id)}
              />
            </Grid>
          )
        : editable &&
          (!options?.max || people.length < options.max) && (
            <Grid container item>
              <Button
                startIcon={<PersonAddRounded />}
                style={{ color: grey[600], borderRadius: 8 }}
                onClick={addPerson}
              >
                add a person
              </Button>
            </Grid>
          )}
    </Grid>
  );
}

function Person({ requiredFields, initial, editable, onChange, onDelete }) {
  const [person, setPerson] = useState({ id: v4(), firstName: '', lastName: '', email: '' });
  const [expanded, setExpanded] = useState(false);
  const emailError = !!person.email && !validateEmail(person.email);
  const phoneError = !!person.mobile && !validateUkNumber(person.mobile);

  const manualRequired = isArray(requiredFields) && !isEmpty(requiredFields);
  const firstNameRequired = manualRequired ? requiredFields.includes('firstName') : true;
  const lastNameRequired = manualRequired ? requiredFields.includes('lastName') : true;
  const emailRequired = manualRequired ? requiredFields.includes('email') : true;
  const mobileRequired = manualRequired ? requiredFields.includes('mobile') : false;

  useState(() => {
    if (!initial) return;
    setPerson(initial);
  }, [initial]);

  function savePerson(field, value) {
    const updated = { ...person, [field]: value };
    setPerson(updated);
    if (field === 'email' && !validateEmail(value)) return;
    if (field === 'mobile' && !validateUkNumber(value)) return;
    const toSave = {
      ...updated,
      email: updated.email && validateEmail(updated.email) ? updated.email : '',
      mobile: updated.mobile && validateUkNumber(updated.mobile) ? updated.mobile : '',
    };
    onChange(toSave);
  }

  if (!editable) {
    return (
      <Paper variant="outlined" style={{ borderRadius: 12, width: '100%', padding: 8 }}>
        <Grid container direction="column" spacing={1}>
          <Grid container item>
            <Typography gutterBottom>
              <b>First name:</b> {person.firstName}
            </Typography>
          </Grid>
          <Grid container item>
            <Typography gutterBottom>
              <b>Last name:</b> {person.lastName}
            </Typography>
          </Grid>
          <Grid container item>
            <Typography gutterBottom>
              <b>Email:</b> {person.email}
            </Typography>
          </Grid>
          {person.mobile && (
            <Grid container item>
              <Typography gutterBottom>
                <b>Mobile:</b> {person.mobile}
              </Typography>
            </Grid>
          )}
          {person.role && (
            <Grid container item>
              <Typography gutterBottom>
                <b>Job role:</b> {person.role}
              </Typography>
            </Grid>
          )}
          {person.notes && (
            <Grid container item>
              <Typography gutterBottom>
                <b>Notes:</b> {person.notes}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper variant="outlined" style={{ borderRadius: 12, width: '100%', padding: 8 }}>
      <Grid container direction="column">
        {firstNameRequired && (
          <TextField
            value={person.firstName}
            label="First name"
            variant="standard"
            onChange={(e) => savePerson('firstName', e.target.value)}
            inputProps={{ maxLength: 128 }}
            required
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleRounded style={{ color: grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {lastNameRequired && (
          <TextField
            value={person.lastName}
            label="Last name"
            variant="standard"
            onChange={(e) => savePerson('lastName', e.target.value)}
            style={{ marginTop: 8 }}
            inputProps={{ maxLength: 128 }}
            required
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleRounded style={{ color: grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {emailRequired && (
          <TextField
            value={person.email}
            label="Email"
            variant="standard"
            onChange={(e) => savePerson('email', e.target.value)}
            style={{ marginTop: 8 }}
            required
            error={emailError}
            helperText={emailError ? 'Enter a correct email' : ''}
            inputProps={{ maxLength: 128 }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailRounded style={{ color: grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {mobileRequired && (
          <TextField
            value={person.mobile || ''}
            label="Mobile"
            variant="standard"
            onChange={(e) => {
              const mobile = e.target.value;
              if (mobile && !/^\d+$/.test(mobile)) return;
              savePerson('mobile', mobile);
            }}
            style={{ marginTop: 8 }}
            fullWidth
            required
            error={phoneError}
            helperText={phoneError ? 'Enter a correct phone number' : ''}
            inputProps={{ maxLength: 11 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneRounded style={{ color: grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        <Collapse style={{ width: '100%' }} in={expanded}>
          <Grid container direction="column">
            {!firstNameRequired && (
              <TextField
                value={person.firstName}
                label="First name"
                variant="standard"
                onChange={(e) => savePerson('firstName', e.target.value)}
                inputProps={{ maxLength: 128 }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleRounded style={{ color: grey[500] }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {!lastNameRequired && (
              <TextField
                value={person.lastName}
                label="Last name"
                variant="standard"
                onChange={(e) => savePerson('lastName', e.target.value)}
                style={{ marginTop: 8 }}
                inputProps={{ maxLength: 128 }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleRounded style={{ color: grey[500] }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {!emailRequired && (
              <TextField
                value={person.email}
                variant="standard"
                label="Email"
                onChange={(e) => savePerson('email', e.target.value)}
                style={{ marginTop: 8 }}
                error={emailError}
                helperText={emailError ? 'Enter a correct email' : ''}
                inputProps={{ maxLength: 128 }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailRounded style={{ color: grey[500] }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {!mobileRequired && (
              <TextField
                value={person.mobile || ''}
                label="Mobile"
                variant="standard"
                onChange={(e) => {
                  const mobile = e.target.value;
                  if (mobile && !/^\d+$/.test(mobile)) return;
                  savePerson('mobile', mobile);
                }}
                style={{ marginTop: 8 }}
                fullWidth
                error={phoneError}
                helperText={phoneError ? 'Enter a correct phone number' : ''}
                inputProps={{ maxLength: 11 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneRounded style={{ color: grey[500] }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <TextField
              value={person.role || ''}
              label="Job role"
              onChange={(e) => savePerson('role', e.target.value)}
              style={{ marginTop: 8 }}
              inputProps={{ maxLength: 128 }}
              fullWidth
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonRounded style={{ color: grey[500] }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={person.notes || ''}
              label="Notes"
              onChange={(e) => savePerson('notes', e.target.value)}
              style={{ marginTop: 8 }}
              inputProps={{ maxLength: 1024 }}
              variant="standard"
              fullWidth
              multiline
              minRows={4}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <NotesRounded style={{ color: grey[500] }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Collapse>
        <Grid container item style={{ marginTop: 8 }} justifyContent="space-between">
          <Button startIcon={<DeleteOutlineRounded />} style={{ color: red[600], borderRadius: 8 }} onClick={onDelete}>
            delete
          </Button>
          <Button
            endIcon={expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            style={{ color: grey[600], borderRadius: 8 }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'less details' : 'more details'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

function ContactsDialog({ open, onClose, options, onResult, initial }) {
  const [data, setData] = useState([]);
  const [pattern, setPattern] = useState('');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const filtered = data.filter(
    (x) =>
      !pattern ||
      [x.lastName, x.fistName, x.email, x.company || '', x.role || '', x.notes || '', x.mobile]
        .join(' ')
        .toLowerCase()
        .includes(pattern),
  );

  useEffect(() => {
    setSelected(isArray(initial) ? initial : []);
  }, [initial]);

  useEffect(() => {
    if (!open) return;
    const groups =
      isArray(options.contactGroups) && !isEmpty(options.contactGroups) ? options.contactGroups.map((x) => x._id) : [];
    if (isEmpty(groups)) return;
    setLoading(true);
    axios
      .post('/data/getContactsByGroups', { groups })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [options, open]);

  function handleContactClick(id) {
    if (selected.includes(id)) {
      setSelected(selected.filter((x) => x !== id));
    } else {
      setSelected([...selected, id]);
    }
  }

  function done() {
    const result = data.filter((contact) => selected.includes(contact.id));
    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="lg" className="scroll-bar">
      <DialogTitle>Select users</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems="center" justifyContent="center">
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <Grid container direction="column">
            <Grid container item sx={{ mt: 1 }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRounded />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {pattern && (
                        <IconButton onClick={() => setPattern('')} onMouseDown={(e) => e.preventDefault()}>
                          <ClearRounded />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                value={pattern}
                fullWidth
                variant="outlined"
                label="Search"
                onChange={(e) => setPattern(e.target.value)}
                id="contacts-dialog-search"
              />
            </Grid>
            <Grid container item alignItems="center" sx={{ p: 0.6 }} wrap="nowrap">
              <TableCellContainer container item style={{ width: '5%' }}></TableCellContainer>
              <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                <Typography style={{ fontWeight: 500 }}>First name</Typography>
              </TableCellContainer>
              <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                <Typography style={{ fontWeight: 500 }}>Last name</Typography>
              </TableCellContainer>
              <TableCellContainer container item style={{ width: '20%', maxWidth: '20%' }}>
                <Typography style={{ fontWeight: 500 }}>Email</Typography>
              </TableCellContainer>
              <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                <Typography style={{ fontWeight: 500 }}>Mobile</Typography>
              </TableCellContainer>
              <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                <Typography style={{ fontWeight: 500 }}>Company</Typography>
              </TableCellContainer>
              <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                <Typography style={{ fontWeight: 500 }}>Job role</Typography>
              </TableCellContainer>
              <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                <Typography style={{ fontWeight: 500 }}>Notes</Typography>
              </TableCellContainer>
              <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}></TableCellContainer>
            </Grid>
            <List style={{ width: '100%', height: '50vh', overflow: 'auto' }}>
              {filtered.map((contact, i) => (
                <React.Fragment key={contact.id}>
                  <ListItem
                    button
                    key={contact.id}
                    onClick={() => handleContactClick(contact.id)}
                    style={{ borderRadius: 5, padding: 0 }}
                  >
                    <Grid container item alignItems="center" sx={{ p: 0.6 }} wrap="nowrap">
                      <Grid container item style={{ width: '5%' }} justifyContent="flex-start">
                        <PersonRounded style={{ color: grey[500] }} />
                      </Grid>
                      <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                        <TooltipTypography>
                          {contact.firstName ? VisualiseSearch(contact.firstName, pattern) : ''}
                        </TooltipTypography>
                      </TableCellContainer>
                      <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                        <TooltipTypography>
                          {contact.lastName ? VisualiseSearch(contact.lastName, pattern) : ''}
                        </TooltipTypography>
                      </TableCellContainer>
                      <TableCellContainer container item style={{ width: '20%', maxWidth: '20%' }}>
                        <TooltipTypography>
                          {contact.email ? VisualiseSearch(contact.email, pattern) : ''}
                        </TooltipTypography>
                      </TableCellContainer>
                      <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                        <TooltipTypography>
                          {contact.mobile ? VisualiseSearch(contact.mobile, pattern) : ''}
                        </TooltipTypography>
                      </TableCellContainer>
                      <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                        <TooltipTypography>
                          {contact.company ? VisualiseSearch(contact.company, pattern) : ''}
                        </TooltipTypography>
                      </TableCellContainer>
                      <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                        <TooltipTypography>
                          {contact.role ? VisualiseSearch(contact.role, pattern) : ''}
                        </TooltipTypography>
                      </TableCellContainer>
                      <TableCellContainer container item style={{ width: '10%', maxWidth: '10%' }}>
                        <TooltipTypography>
                          {contact.notes ? VisualiseSearch(contact.notes, pattern) : ''}
                        </TooltipTypography>
                      </TableCellContainer>
                      <TableCellContainer
                        container
                        item
                        style={{ width: '10%', maxWidth: '10%' }}
                        justifyContent="flex-end"
                      >
                        {selected.includes(contact.id) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[600] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )}
                      </TableCellContainer>
                    </Grid>
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}

function VisualiseSearch(string, pattern, highlightColor = yellow[500]) {
  if (!isString(string) || !string || !isString(pattern) || !pattern) return string;
  const index = string.search(new RegExp(pattern, 'i'));
  if (index === -1) return string;
  return (
    <>
      {string.substring(0, index)}
      <span style={{ background: highlightColor, borderRadius: 3 }}>
        {string.substring(index, index + pattern.length)}
      </span>
      {string.substring(index + pattern.length)}
    </>
  );
}
