export const msalConfig = {
  auth: {
    clientId: '0e026edc-5f53-4376-9301-92c9dac0cafe', // Replace with your Azure AD B2C client ID
    redirectUri: `${window.location.origin}/oauth/redirect`, // Replace with your Redirect URI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [],
};
