import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions } from '../Components';
import { DialogContent, DialogTitle, Grid, TextField, FormControl, FormLabel, Switch } from '@mui/material';
import { FormGroup, FormControlLabel, Checkbox, Chip, CircularProgress, Typography, Collapse } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { GroupRounded } from '@mui/icons-material';
import StateManager from '../StateManager';
import { grey } from '@mui/material/colors';
import { isEmpty } from 'lodash';
import axios from 'axios';

export default function PeopleOptionsDialog({ open, initial, onClose, onResult }) {
  const defaultRequired = () => ['firstName', 'lastName', 'email'];
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10);
  const [requiredFields, setRequiredFields] = useState(defaultRequired());
  const [selectFromContacts, setSelectFromContacts] = useState(false);
  const [contactGroups, setContactGroups] = useState([]);
  const [openGroups, setOpenGroups] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const loadContacts = contactGroups.length === 0 && openGroups;

  const availableFields = [
    { text: 'First name', value: 'firstName' },
    { text: 'Last name', value: 'lastName' },
    { text: 'Email', value: 'email' },
    { text: 'Mobile', value: 'mobile' },
  ];

  useEffect(() => {
    if (!loadContacts) return;
    setGroupsLoading(true);
    axios
      .get('/data/getContactGroups')
      .then((res) => {
        setContactGroups(res.data);
        setGroupsLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setGroupsLoading(false);
      });
  }, [loadContacts]);

  useEffect(() => {
    if (!initial) return;
    setMin(initial?.min != null ? initial.min : 0);
    setMax(initial?.max != null ? initial.max : 10);
    setRequiredFields(Array.isArray(initial?.requiredFields) ? initial.requiredFields : defaultRequired());
    setSelectFromContacts(Boolean(initial?.selectFromContacts));
    setSelectedGroups(Array.isArray(initial?.contactGroups) ? initial.contactGroups : []);
  }, [initial]);

  function saveMin(value) {
    if (value < 0) return;
    setMin(value);
    if (max < value) {
      setMax(value);
    }
  }

  function saveMax(value) {
    if (value < 1) return;
    setMax(value);
    if (value < min) {
      setMin(value);
    }
  }

  function save() {
    if (selectFromContacts && isEmpty(selectedGroups)) {
      StateManager.setErrorAlert('Select at least one contact group');
      return;
    }
    const result = {
      min,
      max,
      requiredFields,
      selectFromContacts,
      contactGroups: selectFromContacts ? selectedGroups : null,
    };
    onResult(result);
    onClose();
  }

  function handleRequiredField(value, field) {
    setRequiredFields(value ? [...requiredFields, field] : requiredFields.filter((x) => x !== field));
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Options</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item spacing={1} wrap="nowrap" sx={{ mt: 1 }}>
            <Grid container item alignItems="center" xs={6}>
              <TextField
                type="number"
                label="Min people"
                value={min}
                onChange={(e) => saveMin(Number(e.target.value))}
                fullWidth
              />
            </Grid>
            <Grid container item alignItems="center" xs={6}>
              <TextField
                type="number"
                label="Max people"
                value={max}
                onChange={(e) => saveMax(Number(e.target.value))}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container item>
            <Grid container item>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={selectFromContacts}
                    onChange={(e) => setSelectFromContacts(e.target.checked)}
                  />
                }
                label="Select from contact groups"
              />
            </Grid>
            <Collapse in={selectFromContacts} style={{ width: '100%' }}>
              <Autocomplete
                fullWidth
                style={{ marginTop: 12 }}
                open={openGroups}
                onOpen={() => setOpenGroups(true)}
                onClose={() => setOpenGroups(false)}
                loading={groupsLoading}
                options={contactGroups}
                value={selectedGroups}
                onChange={(e, x) => {
                  if (!e) return;
                  setSelectedGroups(x);
                }}
                multiple
                getOptionLabel={(option) => option?.title || ''}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <GroupRounded style={{ color: grey[500] }} />
                      <Typography style={{ marginLeft: '1rem' }} noWrap>
                        {option.title}
                      </Typography>
                    </Grid>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select groups"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {groupsLoading ? <CircularProgress color="primary" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={option._id} color="primary" label={option.title} {...getTagProps({ index })} />
                  ))
                }
              />
            </Collapse>
          </Grid>
          <Grid container item>
            <FormControl component="fieldset">
              <FormLabel component="legend">Required fields:</FormLabel>
              <FormGroup>
                {availableFields.map((field) => (
                  <FormControlLabel
                    key={field.value}
                    control={
                      <Checkbox
                        color="primary"
                        checked={requiredFields.includes(field.value)}
                        onChange={(e) => handleRequiredField(e.target.checked, field.value)}
                      />
                    }
                    label={field.text}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={save} />
    </RoundedDialog>
  );
}
