import React, { useState } from 'react';
import { DialogTitle, DialogContent, TextField } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';

export default function RejectTaskDialog({ open, onClose }) {
  const [reason, setReason] = useState('');

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={() => onClose(null)}>
      <DialogTitle>Please provide a reason</DialogTitle>

      <DialogContent>
        <TextField
          variant="outlined"
          label="Reason"
          multiline
          minRows={3}
          maxRows={6}
          fullWidth
          onChange={(event) => setReason(event.target.value)}
        />
      </DialogContent>
      <StandardDialogActions onClose={() => onClose(null)} hideDone={!reason} onDone={() => onClose(reason)} />
    </RoundedDialog>
  );
}
