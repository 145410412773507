import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import Fab from '@mui/material/Fab';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import AppsIcon from '@mui/icons-material/Apps';

export default function MobileBar({ menuAction, addAction, moreAction, showAdd }) {
  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        top: 'auto',
        bottom: 0,
      }}
    >
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={menuAction}>
          <MenuIcon />
        </IconButton>
        {showAdd && (
          <Fab
            color="primary"
            aria-label="add"
            style={{
              position: 'absolute',
              zIndex: 1,
              top: -15,
              left: 0,
              right: 0,
              margin: '0 auto',
            }}
            onClick={addAction}
          >
            <AddIcon />
          </Fab>
        )}
        <div style={{ flexGrow: 1 }} />
        <IconButton edge="end" color="inherit" onClick={moreAction}>
          <AppsIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
