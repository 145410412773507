import React from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FileGridItem from '../../../../Global/FileGridItem';

export default function Info({ document, onFilePreview }) {
  return (
    <Grid container fluid="true" direction="row" spacing={2} alignItems="center">
      <Grid item xs={6}>
        <TextField
          autoFocus
          margin="dense"
          id="filename"
          label="Filename"
          type="text"
          fullWidth
          disabled={true}
          value={document?.title}
          variant="standard"
        />
        <TextField
          margin="dense"
          id="uploadAt"
          label="Uploaded At"
          type="text"
          fullWidth
          disabled
          value={moment(document?.createdAt).format('DD/MM/YY')}
          variant="standard"
        />
        <TextField
          margin="dense"
          id="uploadBy"
          label="Uploaded By"
          type="text"
          fullWidth
          disabled
          value={document?.ownerName}
          variant="standard"
        />
        <TextField
          margin="dense"
          id="issue"
          label="Revision"
          type="text"
          fullWidth
          disabled
          value={document?.issue}
          variant="standard"
        />
      </Grid>
      {/* <Grid item xs={3}>
        <TextField
          margin="dense"
          id="uploadBy"
          label="Uploaded By"
          type="text"
          fullWidth
          disabled
          value={document?.ownerName}
          variant="standard"
        />
        <TextField
          margin="dense"
          id="issue"
          label="Revision"
          type="text"
          fullWidth
          disabled
          value={document?.issue}
          variant="standard"
        />
      </Grid> */}
      <Grid item xs={6}>
        <FileGridItem
          onSelect={onFilePreview}
          title={document?.title}
          image={document?.filePreview}
          fileKey={document?.file?.location}
          extension={document?.file?.mimetype}
          conventionSet={document?.conventionSet}
          selected={document?.id}
          conventionSetParams={document?.conventionSetParams}
          // sx={{ overflow: 'hidden', maxHeight: 120 }}
        />
      </Grid>
    </Grid>
  );
}
