import { DialogTitle, DialogContent, FormControl, FormLabel, FormControlLabel, Radio, Grid } from '@mui/material';
import { RadioGroup } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions } from '../Components';

export default function NumberOptionsDialog({ open, onClose, initial, onResult }) {
  const [format, setFormat] = useState('');

  useEffect(() => {
    setFormat(initial?.format || 'number');
  }, [initial]);

  function done() {
    onResult({ format });
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth className="non-draggable">
      <DialogTitle>Options</DialogTitle>
      <DialogContent>
        <Grid container item alignItems="center" sx={{ mt: 1 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Format</FormLabel>
            <RadioGroup value={format} onChange={(e) => setFormat(e.target.value)}>
              <FormControlLabel value={'number'} label={'Number'} control={<Radio />} />
              <FormControlLabel value={'dollar'} label={'Dollar'} control={<Radio />} />
              <FormControlLabel value={'pound'} label={'Pound'} control={<Radio />} />
              <FormControlLabel value={'euro'} label={'Euro'} control={<Radio />} />
              <FormControlLabel value={'percent'} label={'Percent'} control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </DialogContent>
      <StandardDialogActions onDone={done} onClose={onClose} />
    </RoundedDialog>
  );
}
