import React, { useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, Typography } from '@mui/material';
import { isNumber, isFunction, isArray, isEmpty } from 'lodash';

export default function NumberRangePicker({ columns, initial, prefix, onChange }) {
  const [operator, setOperator] = useState('');
  const [column, setColumn] = useState('');
  const [number, setNumber] = useState(10);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(10);

  const operators = ['<', '>', '=', '>=', '<=', 'between'];

  useEffect(() => {
    /*if (
      !initial?.column ||
      !['<', '>', '=', '>=', '<=', 'between'].includes(initial?.operator) ||
      (!isNumber(initial.number) && !isNumber(initial.from) && !isNumber(initial.to))
    ) {
      return;
    }*/
    setColumn(initial?.column || '');
    setOperator(initial?.operator || '');
    setNumber(isNumber(initial?.number) || initial?.number === '' ? initial.number : 10);
    setFrom(isNumber(initial?.from) || initial?.from === '' ? initial.from : 0);
    setTo(isNumber(initial?.to) || initial?.to === '' ? initial.to : 0);
  }, [initial]);

  function triggerChange(update) {
    if (!isFunction(onChange)) return;

    const changed = {
      operator,
      column,
      number: Number(number),
      from: Number(from),
      to: Number(to),
      ...update,
    };

    onChange(changed);
  }

  return (
    <Grid container alignItems="baseline" wrap="nowrap">
      {prefix && (
        <Typography gutterBottom style={{ fontWeight: 500, marginRight: 8 }}>
          {prefix}
        </Typography>
      )}
      {isArray(columns) && !isEmpty(columns) && (
        <TextField
          label="Column"
          variant="standard"
          select
          value={column}
          onChange={(e) => {
            setColumn(e.target.value);
            triggerChange({ column: e.target.value });
          }}
          style={{ minWidth: 100, marginRight: 8, fontSize: 18, fontWeight: 600 }}
        >
          {columns.map((column, i) => (
            <MenuItem key={i} value={column.id}>
              {column.title}
            </MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        label="Operator"
        variant="standard"
        select
        value={operator}
        onChange={(e) => {
          setOperator(e.target.value);
          triggerChange({ operator: e.target.value });
        }}
        style={{ minWidth: 100, marginRight: 8, fontSize: 18, fontWeight: 600 }}
      >
        {operators.map((operator, i) => (
          <MenuItem key={i} value={operator}>
            {operator}
          </MenuItem>
        ))}
      </TextField>
      {operator === 'between' ? (
        <>
          <TextField
            type="number"
            variant="standard"
            value={from}
            disabled={!operator}
            onChange={(e) => {
              const value = e.target.value === '' ? '' : Number(e.target.value);
              if (value !== '' && value < 0) return;
              setFrom(value);
              triggerChange({ from: value });
            }}
            style={{ width: '5rem' }}
          />
          <Typography gutterBottom style={{ fontWeight: 500, margin: '0 8px' }}>
            and
          </Typography>
          <TextField
            type="number"
            variant="standard"
            value={to}
            disabled={!operator}
            onChange={(e) => {
              const value = e.target.value === '' ? '' : Number(e.target.value);
              if (value !== '' && value < 0) return;
              setTo(value);
              triggerChange({ to: value });
            }}
            style={{ width: '5rem' }}
          />
        </>
      ) : (
        <TextField
          type="number"
          variant="standard"
          value={number}
          disabled={!operator}
          onChange={(e) => {
            const value = e.target.value === '' ? '' : Number(e.target.value);
            if (value !== '' && value < 0) return;
            setNumber(value);
            triggerChange({ number: value });
          }}
          style={{ width: '5rem' }}
          id="range-picker-number-input"
        />
      )}
    </Grid>
  );
}
