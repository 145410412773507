import React from 'react';
import { Tooltip, Fab } from '@mui/material';

export default function HeaderButton({ icon, onClick, text, sx, size = '40px', ...props }) {
  return (
    <Tooltip title={text || ''} placement="top">
      <Fab
        color="primary"
        sx={{
          mx: 1,
          boxShadow: 'none',
          color: 'white',
          width: '40px',
          height: '40px',
          minHeight: 0,
          '& > svg': {
            width: '24px',
            height: '24px',
          },
          ...sx,
        }}
        onClick={onClick}
        {...props}
      >
        {icon}
      </Fab>
    </Tooltip>
  );
}
