import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Tooltip, Typography, Paper } from '@mui/material';
import EmailDesigner from '../EmailDesigner';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import SaveButton from './SaveButton';

export default function EmailContent({
  content,
  editable = false,
  onSave,
  elevated = false,
  outlined = false,
  onSubmit,
  submitTitle = 'Submit',
  title,
  placeholder,
  editIfEmpty = true,
  onEdit,
  hideButtons,
  ...props
}) {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState(content);
  const [changed, setChanged] = useState(false);

  function save() {
    if (onSave) onSave(text);
    setChanged(false);
    setEdit(false);
  }

  useEffect(() => {
    if (changed) return;
    setText(content);
  }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!content && editable && editIfEmpty) setEdit(true);
  }, [content, editable]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!content && !editable && !placeholder && !title) return <Grid />;

  return (
    <Grid container direction="column" {...props}>
      <Paper
        style={{ width: '100%' }}
        elevation={elevated ? 2 : 0}
        sx={{ my: 1, p: 1 }}
        variant={outlined ? 'outlined' : 'elevation'}
      >
        {editable && !hideButtons ? (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ pb: 1 }}
            style={{ borderBottom: edit ? '' : '1px solid #e4e4e4' }}
          >
            <Typography variant="h6">{title}</Typography>
            <Grid style={{ display: 'flex' }}>
              <Tooltip title={edit ? 'Cancel edit' : 'Edit'} placement="top">
                <IconButton
                  onClick={() => {
                    setEdit(!edit);
                    setChanged(false);
                    setText(content);
                  }}
                  style={{ background: edit ? '#e57373' : '#ffd54f', height: '2rem', width: '2rem' }}
                >
                  {edit ? (
                    <ClearIcon fontSize="small" style={{ color: 'white' }} />
                  ) : (
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                  )}
                </IconButton>
              </Tooltip>
              {changed && (
                <Tooltip title="Save changes" placement="top">
                  <IconButton
                    onClick={save}
                    style={{ background: '#81c784', height: '2rem', width: '2rem', marginLeft: '0.5rem' }}
                  >
                    <DoneIcon fontSize="small" style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        ) : (
          <>
            {title && (
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-start"
                sx={{ pb: 1 }}
                style={{ borderBottom: edit ? '' : '1px solid #e4e4e4' }}
              >
                <Typography variant="h6">{title}</Typography>
              </Grid>
            )}
          </>
        )}
        {edit && editable ? (
          <Grid item container>
            <EmailDesigner
              setContent={(d) => {
                setText(d);
                if (onEdit) onEdit(d);
                if (!changed) setChanged(true);
              }}
              design={content?.design}
              tags={{
                firstName: {
                  name: 'First Name',
                  value: '{{firstName}}',
                  sample: 'John',
                },
                lastName: {
                  name: 'Last Name',
                  value: '{{lastName}}',
                  sample: 'Doe',
                },
                fullName: {
                  name: 'full Name',
                  value: '{{fullName}}',
                  sample: 'John Doe',
                },
                email: {
                  name: 'Email',
                  value: '{{email}}',
                  sample: 'abc@sample.com',
                },
                companyLogo: {
                  name: 'Company Logo',
                  value: '{{companyLogo}}',
                  sample: 'https://app.qhub.co.uk/letter-logo.png',
                },
                companyName: {
                  name: 'Company Name',
                  value: '{{companyName}}',
                  sample: 'Qhub',
                },
                body: {
                  name: 'Notification Body',
                  value: '{{{body}}}',
                  sample: '',
                },
              }}
            />
          </Grid>
        ) : (
          <Grid
            alignItems="baseline"
            container
            item
            sx={{
              p: 1,
              '& > *': {
                height: 'auto !important',
                width: 'auto !important',
                maxWidth: '100% !important',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14pt',
              },
            }}
          >
            {content && <div dangerouslySetInnerHTML={{ __html: content?.html }} />}
            {!content && placeholder && (
              <Typography
                sx={{
                  color: '#bdbdbd',
                  fontSize: 20,
                  mt: 2,
                }}
              >
                {placeholder}
              </Typography>
            )}
          </Grid>
        )}
        {onSubmit && (
          <SaveButton
            startIcon={<CheckCircleOutlineRounded />}
            text={submitTitle}
            onClick={() => onSubmit(text != null ? text : content)}
          />
        )}
      </Paper>
    </Grid>
  );
}
