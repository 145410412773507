import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const SelectableButton = styled((props) => <Button {...props} variant="outlined" />, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  background: selected ? theme.palette.primary.main : '',
  ':hover': {
    background: selected ? theme.palette.primary.dark : '',
  },
  color: selected ? theme.palette.getContrastText(theme.palette.primary.main) : theme.palette.primary.main,
  borderRadius: 100,
}));

export default SelectableButton;
