import React from 'react';

import { Typography, Grid } from '@mui/material';
import { RoundedDialog } from '../../../../Global/Components';
import Comment from './comment';

function HistoryDialog({ open, onClose, data, upIssueComment }) {
  return (
    <RoundedDialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <Grid container direction="column" alignItems={'center'} justifyContent={'space-around'} sx={{ padding: '1em' }}>
        <Typography variant="h5">History</Typography>
        {data?.map((comment) => (
          <Comment
            key={comment?._id}
            comment={comment}
            disabled={true}
            edit={false}
            commentVersion={
              upIssueComment
                ? `v${[comment?.baseVersion, comment?.version].join('.')}`
                : comment?.version
                ? `v${comment?.version}`
                : 'N/A'
            }
          />
        ))}
      </Grid>
    </RoundedDialog>
  );
}

export default HistoryDialog;
