import { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Menu, MenuItem, Badge } from '@mui/material';
import { GeneralButton, IntervalDialog, DateFormatter, CheckboxWithIcon } from '../../../Global/Components';
import { ClearFilter, SearchMenu } from '../../../Global/Components';
import { CalendarMonthRounded, FilterAltRounded } from '@mui/icons-material';
import StateManager from '../../../Global/StateManager';
import AssetFilterDialog from './AssetFilterDialog';
import { isFunction, isArray, isEmpty } from 'lodash';
import FieldFilterDialog from './FieldFilterDialog';
import axios from 'axios';
import QRCodeComp from '../../../Global/QRCode';

export const TimeFilterOptions = [
  {
    title: 'Day to date',
    type: 'to-date',
    value: {
      startOf: 'day',
    },
  },
  {
    title: 'Week to date',
    type: 'to-date',
    value: {
      startOf: 'week',
    },
  },
  {
    title: 'Month to date',
    type: 'to-date',
    value: {
      startOf: 'month',
    },
  },
  {
    title: 'Quarter to date',
    type: 'to-date',
    value: {
      startOf: 'quarter',
    },
  },
  {
    title: 'Year to date',
    type: 'to-date',
    value: {
      startOf: 'year',
    },
  },

  {
    title: 'Previous day',
    type: 'prev',
    value: {
      prev: 'day',
    },
  },
  {
    title: 'Previous week',
    type: 'prev',
    value: {
      prev: 'week',
    },
  },
  {
    title: 'Previous month',
    type: 'prev',
    value: {
      prev: 'month',
    },
  },
  {
    title: 'Previous quarter',
    type: 'prev',
    value: {
      prev: 'quarter',
    },
  },
  {
    title: 'Previous year',
    type: 'prev',
    value: {
      prev: 'year',
    },
  },

  {
    title: 'Last week',
    type: 'rolling',
    value: {
      rollingDays: 7,
    },
  },
  {
    title: 'Last month',
    type: 'rolling',
    value: {
      rollingDays: 30,
    },
  },
  {
    title: 'Last quarter',
    type: 'rolling',
    value: {
      rollingDays: 90,
    },
  },
  {
    title: 'Last 6 months',
    type: 'rolling',
    value: {
      rollingDays: 180,
    },
  },
  {
    title: 'Last year (default)',
    type: 'rolling',
    default: true,
    value: {
      rollingDays: 365,
    },
  },
];

export default function KpiFilters({ initial, onChange, justifyContent, dataSource, kpiId, pageType, kpiTitle }) {
  // === time filters ===
  const [timeMenu, setTimeMenu] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [intervalDialog, setIntervalDialog] = useState(false);

  // === portal spaces ===
  const [portalSpaces, setPortalSpaces] = useState([]);
  const [portalSpacesMenu, setPortalSpacesMenu] = useState(null);
  const [portalSpacesFilters, setPortalSpacesFilters] = useState([]);

  // === asset filters ===
  const [assetFilters, setAssetFilters] = useState(null);
  const [assetFiltersDialog, setAssetFiltersDialog] = useState(false);

  // === user filters ===
  const [selectedUsers, setSelectedUsers] = useState([]);

  // === field filters ===
  const [fieldFilterDialog, setFieldFilterDialog] = useState(false);
  const [fieldFilters, setFieldFilters] = useState(null);

  // === survey filter (only for forms) ===
  const [surveys, setSurveys] = useState([]);
  const [loadingSurveys, setLoadingSurveys] = useState(false);
  const [surveyMenu, setSurveyMenu] = useState(null);
  const [surveyFilters, setSurveyFilters] = useState([]);

  const formId = dataSource?.formParams?.formId;
  const surveysLoadedFormId = useRef(null);

  useEffect(() => {
    if (!formId || !surveyMenu) return;

    surveysLoadedFormId.current = formId;

    setLoadingSurveys(true);
    axios
      .get('/forms/invitations/getSurveyList', { params: { formId } })
      .then(({ data }) => {
        setSurveys(data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoadingSurveys(false));
  }, [formId, surveyMenu]);

  useEffect(() => {
    axios
      .get('/kpi/general/getPortalSpacesForKpiFilters')
      .then(({ data }) => {
        setPortalSpaces(data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (!initial) {
      setSelectedTimeFrame(null);
      setSelectedDateRange(null);
      setPortalSpacesFilters([]);
      setAssetFilters(null);
      setSelectedUsers([]);
      setFieldFilters([]);
      setSurveyFilters([]);
      return;
    }

    if (initial.time?.type === 'to-date') {
      setSelectedTimeFrame(TimeFilterOptions.find((x) => x.value.startOf === initial.time.startOf));
    }

    if (initial.time?.type === 'rolling') {
      setSelectedTimeFrame(TimeFilterOptions.find((x) => x.value.rollingDays === initial.time.rollingDays));
    }

    if (initial.time?.type === 'prev') {
      setSelectedTimeFrame(TimeFilterOptions.find((x) => x.value.prev === initial.time.prev));
    }

    if (initial.time?.type === 'custom') {
      setSelectedDateRange(initial.time.dateRange);
    }

    setPortalSpacesFilters(isArray(initial?.portalSpaces) ? initial.portalSpaces : []);
    setAssetFilters(initial.assets);
    setSelectedUsers(initial.users);
    setFieldFilters(initial.fieldFilters);
    setSurveyFilters(isArray(initial?.surveys) ? initial.surveys : []);
  }, [initial]);

  function applySurveyFilter(value) {
    setSurveyFilters(value);
    applyFilters({ surveys: value });
  }

  function selectTimeFrame(frame) {
    setSelectedTimeFrame(frame);
    setSelectedDateRange(null);

    const result = {
      type: frame.type,
      ...frame.value,
    };

    applyFilters({ time: result });
  }

  function selectDateRange(dateRange) {
    setSelectedDateRange(dateRange);
    setSelectedTimeFrame(null);

    const result = {
      type: 'custom',
      dateRange,
    };

    applyFilters({ time: result });
  }

  function selectUsers() {
    StateManager.selectMultipleUsers(saveUsers, {
      initiallySelected: selectedUsers,
      withClearButton: true,
    });
  }

  function saveUsers(res) {
    if (!res.users) return;

    setSelectedUsers(res.users);
    applyFilters({ users: res.users });
  }

  function applyFilters(data) {
    const timeFilter = selectedDateRange
      ? { type: 'custom', dateRange: selectedDateRange }
      : selectedTimeFrame
      ? { type: selectedTimeFrame.type, ...selectedTimeFrame.value }
      : null;

    const filters = {
      assets: assetFilters,
      portalSpaces: portalSpacesFilters,
      users: selectedUsers,
      time: timeFilter,
      fieldFilters,
      surveys: surveyFilters,
      ...data,
    };
    isFunction(onChange) && onChange(filters);
  }

  if (!dataSource?.type) return null;
  return (
    <Grid container justifyContent={justifyContent || 'flex-end'} alignItems={'center'}>
      <Typography variant="body2" sx={{ mr: 1, fontWeight: 500 }}>
        Time frame
      </Typography>
      <GeneralButton onClick={(e) => setTimeMenu(e.currentTarget)} startIcon={<CalendarMonthRounded />}>
        {selectedDateRange ? (
          <>
            <DateFormatter date={selectedDateRange.start} /> - <DateFormatter date={selectedDateRange.end} />
          </>
        ) : (
          selectedTimeFrame?.title || 'Select'
        )}
      </GeneralButton>

      <Menu anchorEl={timeMenu} open={Boolean(timeMenu)} onClose={() => setTimeMenu(null)}>
        <MenuItem sx={{ fontWeight: 600 }}>To-date</MenuItem>
        {TimeFilterOptions.filter((x) => x.type === 'to-date').map((option, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setTimeMenu(null);
              selectTimeFrame(option);
            }}
          >
            {option.title}
          </MenuItem>
        ))}
        <MenuItem sx={{ fontWeight: 600 }}>Previous</MenuItem>
        {TimeFilterOptions.filter((x) => x.type === 'prev').map((option, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setTimeMenu(null);
              selectTimeFrame(option);
            }}
          >
            {option.title}
          </MenuItem>
        ))}
        <MenuItem sx={{ fontWeight: 600 }}>Rolling</MenuItem>
        {TimeFilterOptions.filter((x) => x.type === 'rolling').map((option, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setTimeMenu(null);
              selectTimeFrame(option);
            }}
          >
            {option.title}
          </MenuItem>
        ))}
        <MenuItem onClick={() => setIntervalDialog(true)}>Select custom</MenuItem>
      </Menu>

      <IntervalDialog
        open={intervalDialog}
        onClose={() => setIntervalDialog(false)}
        onResult={selectDateRange}
        initial={selectedDateRange}
      />

      {['form', 'process'].includes(dataSource?.type) && (
        <>
          {isArray(portalSpaces) && !isEmpty(portalSpaces) && (
            <>
              <GeneralButton
                onClick={(e) => setPortalSpacesMenu(e.currentTarget)}
                startIcon={
                  <Badge variant="dot" color="error" invisible={isEmpty(portalSpacesFilters)}>
                    <FilterAltRounded />
                  </Badge>
                }
              >
                Filter by portal spaces
              </GeneralButton>

              <Menu
                anchorEl={portalSpacesMenu}
                open={Boolean(portalSpacesMenu)}
                onClose={() => setPortalSpacesMenu(null)}
                keepMounted
              >
                {portalSpaces.map((option) => (
                  <CheckboxWithIcon
                    key={option._id}
                    checked={portalSpacesFilters.includes(option._id)}
                    onChange={(checked) => {
                      const value = checked
                        ? [...portalSpacesFilters, option._id]
                        : portalSpacesFilters.filter((x) => x !== option._id);
                      setPortalSpacesFilters(value);
                      applyFilters({ portalSpaces: value });
                    }}
                    text={option.title}
                  />
                ))}
                <ClearFilter
                  onClick={() => {
                    setPortalSpacesFilters([]);
                    applyFilters({ portalSpaces: [] });
                  }}
                />
              </Menu>
            </>
          )}
          <GeneralButton
            onClick={() => setAssetFiltersDialog(true)}
            startIcon={
              <Badge variant="dot" color="error" invisible={isEmpty(assetFilters)}>
                <FilterAltRounded />
              </Badge>
            }
          >
            Filter by assets
          </GeneralButton>

          {dataSource?.type === 'form' && (
            <GeneralButton
              onClick={(e) => setSurveyMenu(e.currentTarget)}
              startIcon={
                <Badge variant="dot" color="error" invisible={isEmpty(surveyFilters)}>
                  <FilterAltRounded />
                </Badge>
              }
            >
              Filter by surveys
            </GeneralButton>
          )}

          <SearchMenu
            anchorEl={surveyMenu}
            open={Boolean(surveyMenu)}
            items={surveys}
            loading={loadingSurveys}
            textField="title"
            onClose={() => setSurveyMenu(false)}
            withCheckboxes
            idField="_id"
            checkedItems={surveyFilters}
            onCheck={(id) => applySurveyFilter([...surveyFilters, id])}
            onUncheck={(id) => applySurveyFilter(surveyFilters.filter((x) => x !== id))}
            onClear={() => applySurveyFilter([])}
            showClear
          />

          <AssetFilterDialog
            open={assetFiltersDialog}
            onClose={() => setAssetFiltersDialog(false)}
            onResult={(filters) => {
              setAssetFilters(filters);
              applyFilters({ assets: filters });
            }}
            initial={assetFilters}
          />

          <GeneralButton
            onClick={selectUsers}
            startIcon={
              <Badge variant="dot" color="error" invisible={isEmpty(selectedUsers)}>
                <FilterAltRounded />
              </Badge>
            }
          >
            Filter by users
          </GeneralButton>
        </>
      )}

      <GeneralButton
        onClick={() => setFieldFilterDialog(true)}
        startIcon={
          <Badge variant="dot" color="error" invisible={isEmpty(fieldFilters)}>
            <FilterAltRounded />
          </Badge>
        }
      >
        Filter by field
      </GeneralButton>
      <QRCodeComp linkType={pageType === 'my' ? 'kpi_web_app' : 'company_kpi_web'} linkId={kpiId} title={kpiTitle} />
      <FieldFilterDialog
        open={fieldFilterDialog}
        onClose={() => setFieldFilterDialog(false)}
        dataSource={dataSource}
        initial={fieldFilters}
        onResult={(filters) => {
          setFieldFilters(filters);
          applyFilters({ fieldFilters: filters });
        }}
      />
    </Grid>
  );
}
