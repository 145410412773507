/* eslint-disable no-use-before-define */
import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import User from '../../../../Global/Components/UserChip';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { tagStyles } from '../../constants';

export default function AutoSelection({
  data,
  label,
  placeholder,
  handleChange,
  optionTitle,
  value,
  disabled = false,
  multiple = true,
  limit = 2,
  freeSolo = false,
  param = '_id',
  pictureField = 'picture',
  letterField = 'avatarLetter',
  icon,
  tag,
  ...props
}) {
  return (
    <Autocomplete
      fullWidth
      freeSolo={freeSolo}
      multiple={multiple}
      limitTags={limit}
      id={label}
      options={data}
      getOptionLabel={(option) =>
        option.hasOwnProperty(optionTitle)
          ? option[optionTitle]
          : data.length > 0
          ? data.find((a) => a[param] === option)[optionTitle]
          : option
      }
      isOptionEqualToValue={(option, value) =>
        typeof value === 'string' ? option[param] === value : option[param] === value[param]
      }
      renderTags={(value, getTagProps) =>
        value.map((option, index) =>
          tag ? (
            <Chip
              label={option[optionTitle]}
              {...getTagProps({ index })}
              variant="outlined"
              sx={tagStyles(option.color)}
            />
          ) : icon ? (
            <Chip
              label={option[optionTitle]}
              {...getTagProps({ index })}
              variant="outlined"
              icon={<FileCopyIcon sx={{ color: '#2196f3' }} />}
            />
          ) : (
            <User id={typeof value === 'string' ? option : option[param]} {...getTagProps({ index })} />
          ),
        )
      }
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              sx={{ marginRight: '5px' }}
              checked={selected}
            />
            {typeof option === 'string' ? data.find((a) => a[param] === option)[optionTitle] : option[optionTitle]}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} variant="standard" />}
      onChange={handleChange}
      disabled={disabled}
      value={value}
      {...props}
    />
  );
}
