import React from 'react';
import { Grid, Chip, useTheme, Typography } from '@mui/material';
import { isArray, isEmpty, take, isFunction } from 'lodash';

export default function Tags({
  tags,
  max = 2,
  justifyContent = 'flex-end',
  onClick,
  styleProps,
  chipProps,
  typographyProps,
  onDelete,
}) {
  const theme = useTheme();
  return (
    <Grid
      container
      item
      alignItems="center"
      justifyContent={justifyContent}
      wrap="nowrap"
      onClick={(e) => onClick && onClick(e)}
      style={styleProps}
    >
      {isArray(tags) && !isEmpty(tags) && (
        <>
          {take(tags, max).map((tag) => (
            <Chip
              key={tag?._id}
              label={tag?.text}
              style={{
                background: tag?.color,
                color: tag.color ? theme.palette.getContrastText(tag.color) : undefined,
                marginRight: 8,
              }}
              onDelete={isFunction(onDelete) ? () => onDelete(tag?._id) : undefined}
              {...chipProps}
            />
          ))}
          {tags.length > max && (
            <Typography variant="h6" color="textSecondary" style={{ marginRight: 8 }} {...typographyProps}>
              +{tags.length - max}
            </Typography>
          )}
        </>
      )}
    </Grid>
  );
}
