import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Tooltip, Typography, Paper } from '@mui/material';
import ContentEditor from '../ContentEditor';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import { v4 } from 'uuid';
import SaveButton from './SaveButton';

export default function HtmlContent({
  content,
  editable = false,
  onSave,
  elevated = false,
  outlined = false,
  onSubmit,
  submitTitle = 'Submit',
  title,
  placeholder,
  editIfEmpty = true,
  onEdit,
  hideButtons,
  customId,
  ...props
}) {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState(content);
  const [changed, setChanged] = useState(false);
  const [prettifiedContent, setPrettifiedContent] = useState('');

  function save() {
    if (onSave) onSave(text);
    setChanged(false);
    setEdit(false);
  }

  useEffect(() => {
    if (changed) return;
    setText(content);
  }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!content && editable && editIfEmpty) setEdit(true);
  }, [content, editable]); // eslint-disable-line react-hooks/exhaustive-deps

  function prettifyImages(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const imgs = doc.getElementsByTagName('img');

    for (let i = 0; i < imgs.length; i++) {
      imgs[i].removeAttribute('width');
      imgs[i].removeAttribute('height');
      imgs[i].style.maxWidth = '100%';
    }

    return doc.documentElement.outerHTML;
  }

  useEffect(() => {
    if (edit || !content) return;
    setPrettifiedContent(prettifyImages(content));
  }, [edit, content]);

  if (!content && !editable && !placeholder && !title) return <Grid />;

  let contentButtonStyles = {
    position: 'absolute',
    right: '-4px',
    top: '-4px',
    zIndex: 999,
  };

  if (!edit) {
    contentButtonStyles = {};
  }

  return (
    <Grid container direction="column" {...props}>
      <Grid
        container
        component={Paper}
        elevation={elevated ? 2 : 0}
        sx={{ my: 1, p: 1 }}
        variant={outlined ? 'outlined' : 'elevation'}
      >
        {editable && !hideButtons ? (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ pb: 1 }}
            style={{ borderBottom: edit ? '' : '1px solid #e4e4e4', position: 'relative' }}
          >
            <Typography variant="h6">{title}</Typography>
            <Grid
              style={{
                display: 'flex',
                ...contentButtonStyles,
              }}
            >
              <Tooltip title={edit ? 'Cancel edit' : 'Edit'} placement="top">
                <IconButton
                  onClick={() => {
                    setEdit(!edit);
                    setChanged(false);
                    setText(content);
                  }}
                  style={{ background: edit ? '#e57373' : '#ffd54f', height: '2rem', width: '2rem' }}
                >
                  {edit ? (
                    <ClearIcon fontSize="small" style={{ color: 'white' }} />
                  ) : (
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                  )}
                </IconButton>
              </Tooltip>
              {changed && (
                <Tooltip title="Save changes" placement="top">
                  <IconButton
                    onClick={save}
                    style={{ background: '#81c784', height: '2rem', width: '2rem', marginLeft: '0.5rem' }}
                  >
                    <DoneIcon fontSize="small" style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        ) : (
          <>
            {title && (
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-start"
                sx={{ pb: 1 }}
                style={{ borderBottom: edit ? '' : '1px solid #e4e4e4' }}
              >
                <Typography variant="h6">{title}</Typography>
              </Grid>
            )}
          </>
        )}
        {edit && editable ? (
          <Grid item container>
            <ContentEditor
              config={{
                width: '100%',
                height: 300,
                min_height: 300,
                resize: true,
                toolbar:
                  'image | fontsizeselect forecolor backcolor bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist',
                statusbar: false,
              }}
              handleEditorChange={(t) => {
                setText(t);
                if (onEdit) onEdit(t);
                if (!changed) setChanged(true);
              }}
              value={text}
              id={customId || `content-editor${v4()}`}
            />
          </Grid>
        ) : (
          <Grid
            alignItems="baseline"
            container
            item
            sx={{
              p: 1,
              '& > *': {
                height: 'auto !important',
                width: 'auto !important',
                maxWidth: '100% !important',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14pt',
                wordBreak: 'break-word',
              },
            }}
          >
            {content && <div dangerouslySetInnerHTML={{ __html: prettifiedContent }} />}
            {!content && placeholder && (
              <Typography color={'textSecondary'} variant="h6">
                {placeholder}
              </Typography>
            )}
          </Grid>
        )}
        {onSubmit && (
          <SaveButton
            startIcon={<CheckCircleOutlineRounded />}
            text={submitTitle}
            onClick={() => onSubmit(text != null ? text : content)}
          />
        )}
      </Grid>
    </Grid>
  );
}
