/**
 * * Reducer for Document Hub, follows the cases as per relevant actions
 */

import {
  GETPROFILE_ATTEMPT,
  GETPROFILE_SUCCESS,
  GETPROFILE_FAIL,
  UPDATEPROFILE_ATTEMPT,
  UPDATEPROFILE_SUCCESS,
  UPDATEPROFILE_FAIL,
  SETPROFILE,
  UPLOADPICTURE_ATTEMPT,
  UPLOADPICTURE_SUCCESS,
  UPLOADPICTURE_FAIL,
  CHANGEPASSWORD_ATTEMPT,
  CHANGEPASSWORD_SUCCESS,
  CHANGEPASSWORD_FAIL,
  FETCHDEFAULTUSER_ATTEMPT,
  FETCHDEFAULTUSER_SUCCESS,
  FETCHDEFAULTUSER_FAIL,
} from '../constants';

const initialState = {
  user: null,
  fetching: false,
  error: null,
  message: null,
  defaultAdmin: null,
  loggedIn: localStorage.getItem('loggedIn'),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETPROFILE_ATTEMPT:
      return { ...state, fetching: true, user: null };
    case GETPROFILE_SUCCESS:
      return { ...state, fetching: false, user: action.payload.user };
    case GETPROFILE_FAIL:
      return { ...state, fetching: false, error: action.payload, user: null, loggedIn: false };
    case UPDATEPROFILE_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATEPROFILE_SUCCESS:
      return { ...state, fetching: false, user: action.payload.user };
    case UPDATEPROFILE_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case CHANGEPASSWORD_ATTEMPT:
      return { ...state, fetching: true, message: null };
    case CHANGEPASSWORD_SUCCESS:
      if (action.payload.status === 200) {
        localStorage.setItem('token', action.payload.token);
      }
      return { ...state, fetching: false, message: action.payload.message };
    case CHANGEPASSWORD_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SETPROFILE:
      return { ...state, user: action.payload };
    case UPLOADPICTURE_ATTEMPT:
      return { ...state, fetching: true };
    case UPLOADPICTURE_SUCCESS:
      return { ...state, fetching: false, user: { ...state.user, picture: action.payload.file.location } };
    case UPLOADPICTURE_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case FETCHDEFAULTUSER_ATTEMPT:
      return { ...state, fetching: true, defaultAdmin: null };
    case FETCHDEFAULTUSER_SUCCESS:
      return { ...state, fetching: false, defaultAdmin: action.payload.defaultAdmin };
    case FETCHDEFAULTUSER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
