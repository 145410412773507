import React, { useState } from 'react';
import { Grid, Popover, Button, ButtonGroup, CircularProgress } from '@mui/material';
import { DoneRounded } from '@mui/icons-material';
import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green } from '@mui/material/colors';
import { lightGreen, lime, yellow, amber, orange, deepOrange, grey, blueGrey } from '@mui/material/colors';
import { HexColorPicker } from 'react-colorful';
import { isFunction } from 'lodash';

export default function ColorPicker({ anchor, open, onClose, onResult, column = 500, customPicker = false, ...props }) {
  const [tab, setTab] = useState(0);
  const [color, setColor] = useState(blue[column]);

  const colours = [
    red[column],
    pink[column],
    purple[column],
    deepPurple[column],
    indigo[column],
    blue[column],
    lightBlue[column],
    cyan[column],
    teal[column],
    green[column],
    lightGreen[column],
    lime[column],
    yellow[column],
    amber[column],
    orange[column],
    deepOrange[column],
    grey[column],
    blueGrey[column],
  ];

  return (
    <Popover
      anchorEl={anchor}
      open={open}
      onClose={() => onClose(null)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      {...props}
    >
      {customPicker && (
        <Grid container style={{ padding: 8 }} justifyContent="center">
          <ButtonGroup style={{ borderRadius: 8 }}>
            <Button color="primary" variant={tab === 0 ? 'contained' : 'outlined'} onClick={() => setTab(0)}>
              Palette
            </Button>
            <Button color="primary" variant={tab === 1 ? 'contained' : 'outlined'} onClick={() => setTab(1)}>
              Custom
            </Button>
          </ButtonGroup>
        </Grid>
      )}

      {tab === 0 && (
        <Grid container style={{ maxWidth: 46 * 6 }}>
          {colours.map((color) => (
            <Grid item key={color}>
              <Button
                style={{ backgroundColor: color, borderRadius: 5, width: 40, minWidth: 40, height: 40, margin: 3 }}
                onClick={() => {
                  onClose({ color });
                  if (isFunction(onResult)) {
                    onResult(color);
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {tab === 1 && (
        <Grid container style={{ width: 46 * 6 }}>
          <Grid container justifyContent="center">
            <HexColorPicker color={color} onChange={setColor} />
          </Grid>
          <Grid container justifyContent="flex-end" style={{ padding: 8 }}>
            <Button
              onClick={() => {
                onClose({ color });
                if (isFunction(onResult)) {
                  onResult(color);
                }
              }}
              startIcon={<DoneRounded />}
              variant="contained"
              color="primary"
              style={{ borderRadius: 8 }}
            >
              done
            </Button>
          </Grid>
        </Grid>
      )}
    </Popover>
  );
}
