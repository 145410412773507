import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { WS_BASE_URL } from '../../../constants';

const NEW_CHAT_NOTIFICATION_EVENT = 'newChatNotification';
const CHAT_NOTIFICATION_DELETED = 'chatNotificationDeleted';

const groupNotifications = (notifications) => {
  let obj = notifications.reduce(function (rv, x) {
    (rv[x.chatId] = rv[x.chatId] || []).push(x);
    return rv;
  }, {});

  return Object.keys(obj).map((k) => ({
    _id: k,
    text: obj[k][0].text,
    title: obj[k][0].title,
    picture: obj[k][0].picture,
    count: obj[k].length,
  }));
};

const useNotifications = (isDisabled) => {
  const [notifications, setNotifications] = useState([]); // current notifications
  const currNotifications = useRef([]); // to store data

  const socket = useRef(); // WS connection

  const processNotification = (notification) => {
    currNotifications.current.unshift(notification);
    setNotifications([...currNotifications.current]);
  };

  const processNotificationDelete = (ids) => {
    let filtered = [];
    for (let i = 0; i < currNotifications.current.length; ++i) {
      if (ids.findIndex((x) => x === currNotifications.current[i]._id) === -1)
        filtered.push(currNotifications.current[i]);
    }
    currNotifications.current = filtered;
    setNotifications([...currNotifications.current]);
  };

  useEffect(() => {
    if (isDisabled) return;

    const token = localStorage.getItem('token');

    import('socket.io-client').then(({ io }) => {
      if (socket.current) return;

      socket.current = io(`${WS_BASE_URL}/chatNotifications`, {
        query: {
          token,
        },
        transports: ['websocket'],
        secure: true,
      });

      // Processing notifications
      socket.current.on(NEW_CHAT_NOTIFICATION_EVENT, (notification) => {
        processNotification(notification);
      });

      // Processing notification delete
      socket.current.on(CHAT_NOTIFICATION_DELETED, (ids) => {
        processNotificationDelete(ids);
      });
    });

    // load notifications
    axios.get('/notifications/getChatNotifications').then((res) => {
      currNotifications.current = res.data;
      setNotifications(res.data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { chatNotifications: groupNotifications(notifications) };
};

export default useNotifications;
