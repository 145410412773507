import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, List } from '@mui/material';
import { ListItem, ListItemText, Chip, Avatar } from '@mui/material';
import { DialogContent, DialogTitle, DialogActions, InputAdornment, ListItemAvatar } from '@mui/material';
import { ListItemSecondaryAction } from '@mui/material';
import { Search } from '@mui/icons-material';
import { CheckCircle } from '@mui/icons-material';
import { RoundedDialog } from '../Components';
import { blue, red } from '@mui/material/colors';
import UserManager from '../UserManager';

function hex24() {
  return [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
}

export default function ProjectDialog({ open, onClose, onResult, project }) {
  const [tab, setTab] = useState(0);
  const [title, setTitle] = useState('');
  const [users, setUsers] = useState([]);
  const [pattern, setPattern] = useState('');
  const userId = localStorage.getItem('_id');

  function close() {
    setTab(0);
    setTitle('');
    onClose();
    users.forEach((u) => (u.selected = false));
  }

  function done() {
    let result = {
      _id: hex24(),
      title,
      members: users.filter((user) => user.selected === true).map((u) => u._id),
    };
    onResult(result);
    close();
  }

  useEffect(() => {
    if (!project) return;
    setTitle(project.title);
    if (Array.isArray(project.members)) {
      project.members.forEach((m) => {
        let index = users.findIndex((u) => u._id === m);
        if (index > -1) {
          users[index].selected = true;
        }
      });
    }
  }, [project]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    UserManager.getUserList()

      .then((res) => {
        setUsers(res.data.filter((x) => x._id !== userId));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function addUser(id) {
    let index = users.findIndex((x) => x._id === id);
    if (index > -1) {
      users[index].selected = true;
      setUsers([...users]);
    }
  }

  function deleteUser(id) {
    let index = users.findIndex((x) => x._id === id);
    if (index > -1) {
      users[index].selected = false;
      setUsers([...users]);
    }
  }

  return (
    <RoundedDialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>
        {tab === 0 && 'Name the project'}
        {tab === 1 && 'Select project members (optional)'}
      </DialogTitle>

      <DialogContent>
        {tab === 0 && (
          <TextField
            variant="outlined"
            value={title}
            placeholder="Enter project title here"
            onChange={(event) => setTitle(event.target.value)}
            style={{ width: '100%' }}
            inputProps={{ style: { fontSize: 20 } }}
          />
        )}
        {tab === 1 && (
          <Grid container>
            <Grid container style={{ minHeight: '3rem' }}>
              {users
                .filter((user) => user.selected === true)
                .map((user) => (
                  <Chip
                    key={user._id}
                    style={{
                      background: '#90caf9',
                      color: '#2f3133',
                      margin: '0.4rem',
                    }}
                    avatar={<Avatar src={user.avatar}>{user.avatarLetters}</Avatar>}
                    label={user.fullName}
                    onDelete={() => deleteUser(user._id)}
                  />
                ))}
            </Grid>
            <Grid container style={{ padding: '1rem' }}>
              <TextField
                placeholder="Add members"
                fullWidth
                value={pattern}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search style={{ color: 'lightgray' }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setPattern(event.target.value.toLowerCase())}
                variant="standard"
              />
            </Grid>
            <Grid container>
              <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
                {users
                  .filter((user) => user.fullName.toLowerCase().includes(pattern))
                  .map((user, i) => (
                    <ListItem
                      button
                      key={user._id}
                      style={{ borderBottom: '1px solid lightgrey' }}
                      onClick={() => {
                        user.selected ? deleteUser(user._id) : addUser(user._id);
                        setPattern('');
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar src={user.avatar}>{user.avatarLetters}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={user.fullName} />
                      {user.selected && (
                        <ListItemSecondaryAction>
                          <CheckCircle style={{ color: '#64dd17' }} />
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        {tab === 0 && (
          <Button onClick={close} style={{ color: red[500] }}>
            cancel
          </Button>
        )}
        {tab === 1 && (
          <Button onClick={() => setTab(0)} style={{ color: red[500] }}>
            back
          </Button>
        )}
        {title && tab === 0 && (
          <Button onClick={() => setTab(1)} style={{ color: blue[500] }}>
            next
          </Button>
        )}
        {tab === 1 && (
          <Button onClick={done} style={{ color: blue[500] }}>
            done
          </Button>
        )}
      </DialogActions>
    </RoundedDialog>
  );
}
