import React from 'react';
import { Grid } from '@mui/material';

export default function TabPanel({ children, value, index, ...props }) {
  return (
    <Grid
      container
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...props}
    >
      <Grid container>{children}</Grid>
    </Grid>
  );
}
