import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { DatePicker, TimePicker } from '../../../../Global/Components';

const DueDate = ({ editable, value, setValue }) => {
  return (
    <Grid container item>
      <Grid container item alignItems="center">
        {!value && !editable && (
          <Typography color="textSecondary" variant="h6">
            This task has no due date
          </Typography>
        )}
        <Collapse in={Boolean(value)} sx={{ width: '100%' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container lg={2} md={2} xs={12}>
              <Typography sx={{ fontWeight: 500 }}>Due Date</Typography>
            </Grid>

            <Grid item container lg={5} md={5} xs={6}>
              <DatePicker disabled={!editable} value={value} onChange={setValue} label="" />
            </Grid>

            <Grid item container lg={5} md={5} xs={6}>
              <TimePicker disabled={!editable} value={value} onChange={setValue} label="" />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default DueDate;
