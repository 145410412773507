import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, Typography, TextField, CircularProgress } from '@mui/material';
import { FormControlLabel, Radio, Checkbox, RadioGroup } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import { ProcessIcon } from '../../../../Global/Icons';
import Autocomplete from '@mui/material/Autocomplete';
import StateManager from '../../../../Global/StateManager';
import axios from 'axios';
import { isArray, isEmpty } from 'lodash';
import { highlightElementError } from '../../../../Global/Functions';

export default function ProcessTableDialog({ open, onClose, onResult }) {
  const [processes, setProcesses] = useState([]);
  const [view, setView] = useState('state');
  const [loading, setLoading] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [hideTitle, setHideTitle] = useState(false);
  const [hideCompleted, setHideCompleted] = useState(false);

  const loadProcesses = open && processes.length === 0;

  useEffect(() => {
    if (!loadProcesses) return;
    setLoading(true);
    axios
      .get('/process/search/getSearchProcesses', { params: { withSteps: true } })
      .then((res) => {
        setLoading(false);
        setProcesses(res.data);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadProcesses]);

  useEffect(() => {
    if (!open) return;
    setSelectedProcess(null);
  }, [open]);

  function done() {
    if (!selectedProcess) return;
    if (view === 'button' && !selectedStep) {
      StateManager.setErrorAlert('Select the step to display');
      highlightElementError('step-select');
      return;
    }
    onResult({
      processId: selectedProcess._id,
      view,
      hideTitle,
      hideCompleted,
      selectedStepId: view === 'button' ? selectedStep.lineId : undefined,
      title: selectedStep?.title,
    });
    onClose();
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose} className="scroll-bar">
      <DialogTitle>Select a process:</DialogTitle>

      <DialogContent>
        <Grid container item sx={{ mt: 1 }}>
          <Autocomplete
            fullWidth
            options={processes}
            onChange={(e, process) => {
              if (!e) return;
              setSelectedProcess(process);
              setSelectedStep(null);
            }}
            getOptionLabel={(option) => option?.title}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            loading={loading}
            renderOption={(props, option) => (
              <li {...props}>
                <Grid container alignItems="center" wrap="nowrap">
                  <ProcessIcon />
                  <Typography style={{ marginLeft: '1rem' }} noWrap>
                    {option.title}
                  </Typography>
                </Grid>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select process"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <React.Fragment>
                      <ProcessIcon />
                      {params.InputProps.startAdornment}
                    </React.Fragment>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid container item sx={{ mt: 1 }}>
          <Typography gutterBottom>Choose a view option:</Typography>
        </Grid>

        <Grid container item>
          <RadioGroup value={view} onChange={(e) => setView(e.target.value)} row>
            <FormControlLabel value={'state'} label={'Group entries by status'} control={<Radio />} />
            <FormControlLabel value={'step'} label={'Group entries by current step'} control={<Radio />} />
            <FormControlLabel value={'button'} label={'Button for one step'} control={<Radio />} />
          </RadioGroup>
        </Grid>

        {view === 'button' && isArray(selectedProcess?.steps) && !isEmpty(selectedProcess?.steps) && (
          <Grid container item sx={{ mt: 2 }} id={'step-select'}>
            <Autocomplete
              fullWidth
              value={selectedStep}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              onChange={(event, newValue) => setSelectedStep(newValue)}
              getOptionLabel={(option) => (option ? option.title : '')}
              options={selectedProcess.steps}
              renderInput={(params) => <TextField {...params} label="Select step" variant="outlined" />}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Grid container item alignItems="center">
                    <Typography>{option.title}</Typography>
                  </Grid>
                </li>
              )}
            />
          </Grid>
        )}

        {view !== 'button' && (
          <>
            <Grid container item sx={{ mt: 2 }}>
              <FormControlLabel
                onChange={(e) => setHideTitle(e.target.checked)}
                label={'Hide process title'}
                control={<Checkbox checked={hideTitle} />}
              />
            </Grid>
            <Grid container item sx={{ mt: 1 }}>
              <FormControlLabel
                onChange={(e) => setHideCompleted(e.target.checked)}
                label={'Hide completed processes'}
                control={<Checkbox checked={hideCompleted} />}
              />
            </Grid>
          </>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={!selectedProcess} />
    </RoundedDialog>
  );
}
