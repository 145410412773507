import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Typography, FormControlLabel, Collapse, DialogContent, Switch, Tabs, Tab } from '@mui/material';
import { Checkbox, InputBase, DialogTitle, Button, Chip } from '@mui/material';
import { CheckCircleRounded, CheckCircleOutlineRounded, InfoOutlined, SearchRounded } from '@mui/icons-material';
import { CloseRounded } from '@mui/icons-material';
import { User, RoundedDialog, StandardDialogActions, UserChip, Group, GroupChip } from '../../../Global/Components';
import { DatePicker, TimePicker, DateTimePicker } from '../../../Global/Components';
import { grey, green } from '@mui/material/colors';
import ViewStepDialog from '../components/ViewStepDialog';
import { ProcessIcon, FormIcon, TaskIcon } from '../../../Global/Icons';
import { useSelector } from 'react-redux';
import { isArray, isEmpty, uniq } from 'lodash';
import moment from 'moment';
import { styled } from '@mui/material/styles';

const TabHeader = styled(Tab)(() => ({
  px: 1.5,
  py: 0.5,
  minWidth: 140,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  minHeight: 48,
}));

export default function NextStepDialog({ open, onClose, onResult, nextStepInfo }) {
  const defaultDueDate = moment().add(1, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 });
  const [section, setSection] = useState('users');
  const [completeActionIndex, setCompleteActionIndex] = useState(0);
  const [completeActionsParams, setCompleteActionsParams] = useState(null);
  const [startActionIndex, setStartActionIndex] = useState(0);
  const [startActionsParams, setStartActionsParams] = useState(null);
  const [nextStepInfoDialog, setNextStepInfoDialog] = useState(false);
  const [dueAt, setDueAt] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [normalUsers, setNormalUsers] = useState([]);
  const [portalUsers, setPortalUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tab, setTab] = useState('users');
  const [pattern, setPattern] = useState('');
  const [onlyPickFromGroups, setOnlyPickFromGroups] = useState(false);
  const [noUsers, setNoUsers] = useState(false);

  const { user } = useSelector(({ profile }) => profile);
  const userId = user?._id;

  const normPattern = String(pattern).trim().toLowerCase();

  const { filteredNormalUsers, filteredPortalUsers, filteredGroups } = useMemo(() => {
    if (!normPattern) {
      return {
        filteredNormalUsers: normalUsers,
        filteredPortalUsers: portalUsers,
        filteredGroups: groups,
      };
    }

    return {
      filteredNormalUsers: normalUsers.filter((x) => String(x.fullName).toLowerCase().includes(normPattern)),
      filteredPortalUsers: portalUsers.filter((x) => String(x.fullName).toLowerCase().includes(normPattern)),
      filteredGroups: groups.filter((x) => String(x.name).toLowerCase().includes(normPattern)),
    };
  }, [normPattern, normalUsers, portalUsers, groups]);

  const usersToDisplay = tab === 'portalUsers' ? filteredPortalUsers : filteredNormalUsers;

  const usersIdsToDisplay = usersToDisplay.map((x) => x._id);
  const groupsIdsToDisplay = filteredGroups.map((x) => x._id);

  const notFound =
    (!isEmpty(normalUsers) || !isEmpty(portalUsers) || !isEmpty(groups)) &&
    isEmpty(filteredNormalUsers) &&
    isEmpty(filteredPortalUsers) &&
    isEmpty(filteredGroups);

  const finalUsers = uniq([
    ...selectedUsers,
    ...groups.filter((x) => selectedGroups.includes(x._id)).flatMap((x) => x.users),
  ]);

  const completeActions = isArray(nextStepInfo?.completeActions) ? nextStepInfo.completeActions : [];
  const currCompleteAction = completeActions[completeActionIndex];
  const startActions = isArray(nextStepInfo?.startActions) ? nextStepInfo.startActions : [];
  const currStartAction = startActions[startActionIndex];

  const currCompleteActionFilled =
    currCompleteAction &&
    (!currCompleteAction.provideUserChoice ||
      (completeActionsParams &&
        completeActionsParams[currCompleteAction.id] &&
        !isEmpty(completeActionsParams[currCompleteAction.id].users)));

  const currStartActionFilled =
    currStartAction &&
    (!currStartAction.provideUserChoice ||
      (startActionsParams &&
        startActionsParams[currStartAction.id] &&
        !isEmpty(startActionsParams[currStartAction.id].users)));

  useEffect(() => {
    if (!nextStepInfo) return;
    const hasCompleteActions = isArray(nextStepInfo.completeActions) && !isEmpty(nextStepInfo.completeActions);

    if (hasCompleteActions) {
      setSection('completeActions');
    } else {
      setSection('users');
    }

    setDueAt(nextStepInfo.presetTime);

    if (!isArray(nextStepInfo.nextStepResolvedUsers)) {
      setNormalUsers([]);
      setPortalUsers([]);
    }

    const nextStepNormalUsers = nextStepInfo.nextStepResolvedUsers?.filter((x) => x.access !== 'portal') || [];
    const nextStepPortalUsers = nextStepInfo.nextStepResolvedUsers?.filter((x) => x.access === 'portal') || [];
    const nextStepGroups = nextStepInfo.nextStepResolvedGroups || [];

    // could be that all the groups are empty
    setNoUsers(isEmpty(nextStepInfo.nextStepResolvedUsers));

    // may need to pick only time
    if (!nextStepInfo?.usersPickable) {
      if (nextStepInfo.onlyPickFromGroups) {
        setSelectedGroups(nextStepInfo.nextStepGroups || []);
      } else {
        setSelectedUsers(nextStepInfo.nextStepUsers || []);
      }
    } else {
      setSelectedGroups([]);
      setSelectedUsers([]);
    }

    // if only portal users available - switch to portal tab
    if (isEmpty(nextStepNormalUsers) && !isEmpty(nextStepPortalUsers)) {
      setTab('portalUsers');
    } else {
      setTab('users');
    }

    setOnlyPickFromGroups(nextStepInfo.onlyPickFromGroups);
    setNormalUsers(nextStepNormalUsers);
    setPortalUsers(nextStepPortalUsers);
    setGroups(nextStepGroups);
  }, [nextStepInfo]);

  const hideDone =
    (section === 'completeActions' && !currCompleteActionFilled) ||
    (section === 'users' && (!nextStepInfo || (nextStepInfo?.usersPickable && isEmpty(finalUsers)))) ||
    (section === 'startActions' && !currStartActionFilled);

  function done() {
    const users = finalUsers;
    if (section === 'completeActions') {
      if (completeActionIndex < completeActions.length - 1) {
        setCompleteActionIndex(completeActionIndex + 1);
      } else {
        if (!nextStepInfo.usersPickable && !nextStepInfo.timePickable) {
          if (isEmpty(startActions)) {
            onResult({ users, dueDate: dueAt, completeActionsParams });
            onClose();
          } else {
            setSection('startActions');
          }
        } else {
          setSection('users');
        }
      }
    } else if (section === 'users') {
      if (isEmpty(startActions)) {
        onResult({ users, dueDate: dueAt, completeActionsParams });
        onClose();
      } else {
        setSection('startActions');
      }
    } else if (section === 'startActions') {
      if (startActionIndex < startActions.length - 1) {
        setStartActionIndex(startActionIndex + 1);
      } else {
        onResult({ users, dueDate: dueAt, completeActionsParams, startActionsParams });
        onClose();
      }
    }
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth className="scroll-bar">
      <DialogTitle>Send next step</DialogTitle>
      {section === 'completeActions' && currCompleteAction && (
        <DialogContent>
          <Grid container item style={{ margin: '1rem 0' }} wrap="nowrap" alignItems="center">
            {currCompleteAction.actionType === 'startProcess' && <ProcessIcon />}
            {currCompleteAction.actionType === 'startForm' && <FormIcon />}
            {currCompleteAction.actionType === 'sendTask' && <TaskIcon />}
            <Typography style={{ marginLeft: 8, whiteSpace: 'break-spaces' }}>
              Completing this step will{' '}
              {currCompleteAction.actionType === 'startProcess' && (
                <>
                  start process: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.processTitle}</span>
                </>
              )}
              {currCompleteAction.actionType === 'startForm' && (
                <>
                  start form entry: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.formTitle}</span>
                </>
              )}
              {currCompleteAction.actionType === 'sendTask' && (
                <>
                  send task: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.task?.title}</span>
                </>
              )}
            </Typography>
          </Grid>
          {currCompleteAction.provideUserChoice &&
            currCompleteAction.userType === 'selectedUsers' &&
            !isEmpty(currCompleteAction.users) &&
            isArray(currCompleteAction.users) && (
              <Grid container item style={{ margin: '1rem 0' }}>
                <Typography style={{ fontWeight: 500 }} gutterBottom>
                  Pick users:
                </Typography>
                <Grid container item>
                  {currCompleteAction.users.map((userId, i) => (
                    <User
                      id={userId}
                      key={i}
                      fullWidth
                      markPortal
                      onClick={() => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id] }
                            : {};
                        const users = isArray(update.users) ? update.users : [];

                        if (users.includes(userId)) {
                          update.users = users.filter((x) => x !== userId);
                        } else {
                          update.users = [...users, userId];
                        }
                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                      action={
                        completeActionsParams &&
                        isArray(completeActionsParams[currCompleteAction.id]?.users) &&
                        completeActionsParams[currCompleteAction.id].users.includes(userId) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          {currCompleteAction.startAnytime && (
            <Grid container item style={{ margin: '0.5rem 0' }}>
              {currCompleteAction.actionType !== 'sendTask' && (
                <Grid container item style={{ paddingBottom: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={Boolean(
                          completeActionsParams && completeActionsParams[currCompleteAction.id]?.startLater,
                        )}
                        onChange={(e) => {
                          const update =
                            completeActionsParams && completeActionsParams[currCompleteAction.id]
                              ? { ...completeActionsParams[currCompleteAction.id], startLater: e.target.checked }
                              : { startLater: e.target.checked };

                          setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                        }}
                      />
                    }
                    label={
                      currCompleteAction.actionType === 'startProcess'
                        ? 'Start process later'
                        : currCompleteAction.actionType === 'startForm'
                        ? 'Start form later'
                        : 'Send task later'
                    }
                  />
                </Grid>
              )}

              <Grid container>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={Boolean(completeActionsParams && completeActionsParams[currCompleteAction.id]?.dueAt)}
                      onChange={() => {
                        const dueAt = completeActionsParams
                          ? completeActionsParams[currCompleteAction.id]?.dueAt
                          : null;

                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id] }
                            : {};

                        update.dueAt = dueAt ? null : currCompleteAction.presetTime || defaultDueDate;

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  }
                  label="Include due date"
                />
              </Grid>
              <Collapse
                unmountOnExit
                style={{ width: '100%' }}
                in={Boolean(completeActionsParams && completeActionsParams[currCompleteAction.id]?.dueAt)}
              >
                <Grid container>
                  <Grid item container xs={6} style={{ paddingRight: 4 }}>
                    <DatePicker
                      value={completeActionsParams ? completeActionsParams[currCompleteAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id], dueAt }
                            : { dueAt };

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  </Grid>

                  <Grid item container xs={6} style={{ paddingLeft: 4 }}>
                    <TimePicker
                      value={completeActionsParams ? completeActionsParams[currCompleteAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id], dueAt }
                            : { dueAt };

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          )}
        </DialogContent>
      )}

      {section === 'users' && !nextStepInfo?.final && (
        <DialogContent>
          {nextStepInfo?.nextStep?.title && (
            <Grid container item justifyContent="space-between" alignItems="center">
              <Typography>Next step: {nextStepInfo?.nextStep.title}</Typography>

              <Button onClick={() => setNextStepInfoDialog(true)} startIcon={<InfoOutlined />}>
                Info
              </Button>

              <ViewStepDialog
                step={nextStepInfo?.nextStep}
                open={nextStepInfoDialog}
                onClose={() => setNextStepInfoDialog(false)}
              />
            </Grid>
          )}

          {nextStepInfo?.timePickable && (
            <Grid container>
              <Grid container>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={Boolean(dueAt)}
                      onChange={() => setDueAt(dueAt ? null : nextStepInfo.presetTime || defaultDueDate)}
                    />
                  }
                  label="Include due date"
                />
              </Grid>
              <Collapse style={{ width: '100%' }} in={Boolean(dueAt)}>
                <DateTimePicker value={dueAt} onChange={setDueAt} />
              </Collapse>
            </Grid>
          )}

          <Grid container sx={{ pt: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              Next step users:
            </Typography>
          </Grid>
          {(!isEmpty(selectedUsers) || !isEmpty(selectedGroups)) && nextStepInfo?.usersPickable && (
            <Grid item container>
              {selectedUsers.map((id, i) => (
                <UserChip
                  margin={4}
                  size="small"
                  key={i}
                  id={id}
                  onDelete={() => setSelectedUsers(selectedUsers.filter((x) => x !== id))}
                />
              ))}
              {selectedGroups.map((id, i) => (
                <GroupChip
                  margin={4}
                  size="small"
                  key={i}
                  id={id}
                  onDelete={() => setSelectedGroups(selectedGroups.filter((x) => x !== id))}
                />
              ))}
            </Grid>
          )}
          {!noUsers && (
            <Grid container sx={{ pt: 1 }}>
              <InputBase
                variant="standard"
                startAdornment={<SearchRounded sx={{ mr: 1 }} />}
                value={pattern}
                onChange={(e) => setPattern(e.target.value)}
                fullWidth
                placeholder="Search in users and groups"
                autoFocus
                endAdornment={
                  pattern && (
                    <CloseRounded
                      sx={{ cursor: 'pointer', mx: 1 }}
                      onClick={() => {
                        setPattern('');
                      }}
                    />
                  )
                }
              />
            </Grid>
          )}
          {(!isEmpty(portalUsers) || !isEmpty(groups)) && !onlyPickFromGroups && !noUsers && (
            <Tabs indicatorColor="primary" textColor="primary" value={tab} sx={{ mb: 1, width: '100%' }}>
              {!isEmpty(normalUsers) && (
                <TabHeader
                  value={'users'}
                  label={
                    <>
                      Users <Chip label={filteredNormalUsers.length} sx={{ mx: 1 }} />
                    </>
                  }
                  onClick={() => setTab('users')}
                />
              )}
              {!isEmpty(portalUsers) && (
                <TabHeader
                  value={'portalUsers'}
                  label={
                    <>
                      Portal <Chip label={filteredPortalUsers.length} sx={{ mx: 1 }} />
                    </>
                  }
                  onClick={() => setTab('portalUsers')}
                />
              )}
              {!isEmpty(groups) && (
                <TabHeader
                  value={'groups'}
                  label={
                    <>
                      Groups <Chip label={filteredGroups.length} sx={{ mx: 1 }} />
                    </>
                  }
                  onClick={() => setTab('groups')}
                />
              )}
            </Tabs>
          )}

          {notFound ? (
            <Grid container sx={{ mt: 2, height: '40vh' }} justifyContent={'center'} alignItems={'center'}>
              <Typography color={'text.secondary'}>No users found</Typography>
            </Grid>
          ) : noUsers ? (
            <Grid container sx={{ mt: 2, height: '40vh' }} justifyContent={'center'} alignItems={'center'}>
              <Typography variant="h6">No users for the next step found</Typography>
            </Grid>
          ) : (
            <Grid container sx={{ mt: 2, height: '40vh', overflow: 'auto' }} alignContent={'flex-start'}>
              {['users', 'portalUsers'].includes(tab) && !onlyPickFromGroups && (
                <>
                  {usersIdsToDisplay.includes(userId) && (
                    <User
                      id={userId}
                      fullWidth
                      markPortal
                      onClick={() => {
                        if (!nextStepInfo?.usersPickable) return;

                        const res = selectedUsers.includes(userId)
                          ? selectedUsers.filter((x) => x !== userId)
                          : [...selectedUsers, userId];

                        setSelectedUsers(res);
                      }}
                      action={
                        nextStepInfo?.usersPickable ? (
                          selectedUsers.includes(userId) ? (
                            <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                          ) : (
                            <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                          )
                        ) : null
                      }
                    />
                  )}
                  {usersIdsToDisplay
                    .filter((x) => x !== userId)
                    .map((user) => (
                      <User
                        key={user}
                        id={user}
                        fullWidth
                        markPortal
                        onClick={() => {
                          if (!nextStepInfo?.usersPickable) return;

                          const res = selectedUsers.includes(user)
                            ? selectedUsers.filter((x) => x !== user)
                            : [...selectedUsers, user];

                          setSelectedUsers(res);
                        }}
                        action={
                          nextStepInfo?.usersPickable ? (
                            selectedUsers.includes(user) ? (
                              <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                            ) : (
                              <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                            )
                          ) : null
                        }
                      />
                    ))}
                </>
              )}
              {(tab === 'groups' || onlyPickFromGroups) &&
                groupsIdsToDisplay.map((group) => (
                  <Group
                    key={group}
                    id={group}
                    fullWidth
                    onClick={() => {
                      if (!nextStepInfo?.usersPickable) return;

                      const res = selectedGroups.includes(group)
                        ? selectedGroups.filter((x) => x !== group)
                        : [...selectedGroups, group];

                      setSelectedGroups(res);
                    }}
                    action={
                      nextStepInfo?.usersPickable ? (
                        selectedGroups.includes(group) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )
                      ) : null
                    }
                  />
                ))}
            </Grid>
          )}
        </DialogContent>
      )}
      {section === 'startActions' && (
        <DialogContent>
          <Grid container item style={{ margin: '1rem 0' }} wrap="nowrap" alignItems="center">
            {currStartAction.actionType === 'startProcess' && <ProcessIcon />}
            {currStartAction.actionType === 'startForm' && <FormIcon />}
            {currStartAction.actionType === 'sendTask' && <TaskIcon />}
            <Typography style={{ marginLeft: 8, whiteSpace: 'break-spaces' }}>
              Starting the next step will{' '}
              {currStartAction.actionType === 'startProcess' && (
                <>
                  start process: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currStartAction.processTitle}</span>
                </>
              )}
              {currStartAction.actionType === 'startForm' && (
                <>
                  start form entry: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currStartAction.formTitle}</span>
                </>
              )}
              {currStartAction.actionType === 'sendTask' && (
                <>
                  send task: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currStartAction.task?.title}</span>
                </>
              )}
            </Typography>
          </Grid>
          {currStartAction.provideUserChoice &&
            currStartAction.userType === 'selectedUsers' &&
            !isEmpty(currStartAction.users) &&
            isArray(currStartAction.users) && (
              <Grid container item style={{ margin: '1rem 0' }}>
                <Typography style={{ fontWeight: 500 }} gutterBottom>
                  Pick users:
                </Typography>
                <Grid container item>
                  {currStartAction.users.map((userId, i) => (
                    <User
                      id={userId}
                      key={i}
                      fullWidth
                      markPortal
                      onClick={() => {
                        const update =
                          startActionsParams && startActionsParams[currStartAction.id]
                            ? { ...startActionsParams[currStartAction.id] }
                            : {};
                        const users = isArray(update.users) ? update.users : [];

                        if (users.includes(userId)) {
                          update.users = users.filter((x) => x !== userId);
                        } else {
                          update.users = [...users, userId];
                        }
                        setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                      }}
                      action={
                        startActionsParams &&
                        isArray(startActionsParams[currStartAction.id]?.users) &&
                        startActionsParams[currStartAction.id].users.includes(userId) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          {currStartAction.startAnytime && (
            <Grid container item style={{ margin: '0.5rem 0' }}>
              {currStartAction.actionType !== 'sendTask' && (
                <Grid container item style={{ paddingBottom: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={Boolean(startActionsParams && startActionsParams[currStartAction.id]?.startLater)}
                        onChange={(e) => {
                          const update =
                            startActionsParams && startActionsParams[currStartAction.id]
                              ? { ...startActionsParams[currStartAction.id], startLater: e.target.checked }
                              : { startLater: e.target.checked };

                          setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                        }}
                      />
                    }
                    label={
                      currStartAction.actionType === 'startProcess'
                        ? 'Start process later'
                        : currStartAction.actionType === 'startForm'
                        ? 'Start form later'
                        : 'Send task later'
                    }
                  />
                </Grid>
              )}

              <Grid container>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={Boolean(startActionsParams && startActionsParams[currStartAction.id]?.dueAt)}
                      onChange={() => {
                        const dueAt = startActionsParams ? startActionsParams[currStartAction.id]?.dueAt : null;

                        const update =
                          startActionsParams && startActionsParams[currStartAction.id]
                            ? { ...startActionsParams[currStartAction.id] }
                            : {};

                        update.dueAt = dueAt ? null : currStartAction.presetTime || defaultDueDate;

                        setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                      }}
                    />
                  }
                  label="Include due date"
                />
              </Grid>
              <Collapse
                unmountOnExit
                style={{ width: '100%' }}
                in={Boolean(startActionsParams && startActionsParams[currStartAction.id]?.dueAt)}
              >
                <Grid container>
                  <Grid item container xs={6} style={{ paddingRight: 4 }}>
                    <DatePicker
                      value={startActionsParams ? startActionsParams[currStartAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          startActionsParams && startActionsParams[currStartAction.id]
                            ? { ...startActionsParams[currStartAction.id], dueAt }
                            : { dueAt };

                        setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                      }}
                    />
                  </Grid>

                  <Grid item container xs={6} style={{ paddingLeft: 4 }}>
                    <TimePicker
                      value={startActionsParams ? startActionsParams[currStartAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          startActionsParams && startActionsParams[currStartAction.id]
                            ? { ...startActionsParams[currStartAction.id], dueAt }
                            : { dueAt };

                        setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          )}
        </DialogContent>
      )}

      <StandardDialogActions onClose={onClose} onDone={done} hideDone={hideDone} />
    </RoundedDialog>
  );
}
