import { useState, useEffect } from 'react';

import * as zip from '@zip.js/zip.js';
import * as fflate from 'fflate';

import { TreeView } from '@mui/x-tree-view';

import { Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import StyledTreeItem from '../../Hubs/dochub/components/StyledTreeItem';
import { v4 } from 'uuid';

const { Deflate, Inflate } = zip.initShimAsyncCodec(fflate, undefined, (codec, onData) => (codec.ondata = onData));

zip.configure({
  useWebWorkers: false,
  Deflate,
  Inflate,
});

const model = {
  getEntries: (file, options) => {
    return new zip.ZipReader(new zip.BlobReader(file)).getEntries(options);
  },
  getURL: async (entry, options) => {
    return URL.createObjectURL(await entry.getData(new zip.BlobWriter(), options));
  },
};

// buildTree function to build tree object from entries
const buildTree = (entries) => {
  const tree = {};
  entries.forEach((entry) => {
    const path = entry.filename.split('/');
    let current = tree;
    path.forEach((folder) => {
      if (!current[folder]) {
        current[folder] = {};
      }
      current = current[folder];
    });
  });
  return tree;
};

// SystemFiles array to ignore system files
const SystemFiles = [
  '__MACOSX',
  '.DS_Store',
  'Thumbs.db',
  'desktop.ini',
  '._*',
  '.Spotlight-V100',
  '.Trashes',
  '.fseventsd',
  '',
];

// renderTree function to render tree from tree object
const renderTree = (nodes) => {
  return Object.keys(nodes).map((key, index) => {
    if (SystemFiles.includes(key)) {
      return null;
    }
    if (Object.keys(nodes[key]).length > 0) {
      return (
        <StyledTreeItem key={index} nodeId={`folder-${v4()}`} labelText={key} labelIcon={FolderIcon}>
          {renderTree(nodes[key])}
        </StyledTreeItem>
      );
    }
    return <StyledTreeItem key={index} nodeId={`file-${v4()}`} labelText={key} labelIcon={DescriptionIcon} />;
  });
};

const ZipViewer = ({ src }) => {
  const [tree, setTree] = useState({});

  useEffect(() => {
    (async () => {
      const file = await fetch(src).then((res) => res.blob());
      const entries = await model.getEntries(file);
      const tree = buildTree(entries);
      setTree(tree);
    })();
  }, [src]);

  return (
    <Grid container>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ height: 500, flexGrow: 1, overflowY: 'auto' }}
        defaultExpanded={['root']}
      >
        <StyledTreeItem key={v4()} nodeId={'root'} labelText={'Root'} labelIcon={FolderIcon}>
          {renderTree(tree)}
        </StyledTreeItem>
      </TreeView>
    </Grid>
  );
};

export default ZipViewer;
