import React, { useEffect, useState } from 'react';
import { DialogContent, CircularProgress, Grid, Button, Typography } from '@mui/material';
import { HighlightOffRounded, CheckCircleOutlineRounded } from '@mui/icons-material';
import StateManager from '../StateManager';
import { RoundedDialog, StandardDialogActions } from '../Components';

import axios from 'axios';

export default function QhubAccessDialog({ open, onClose, requestId }) {
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [accepting, setAccepting] = useState(false);

  useEffect(() => {
    if (!open || !requestId) return;

    setLoading(true);

    axios
      .get('/user/getQhubAccessRequest', { params: { requestId } })
      .then(({ data }) => {
        setLoading(false);
        setRequest(data);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [open, requestId]);

  function reject() {
    setRejecting(true);

    axios
      .post('/user/respondToAccessRequest', { response: 'rejected', requestId })
      .then(({ data }) => {
        setRejecting(false);
        onClose();
        StateManager.setSuccessAlert('Access request has been rejected');
      })
      .catch((err) => {
        setRejecting(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function accept() {
    setAccepting(true);

    axios
      .post('/user/respondToAccessRequest', { response: 'accepted', requestId })
      .then(({ data }) => {
        setAccepting(false);
        onClose();
        StateManager.setSuccessAlert('Access request has been accepted');
      })
      .catch((err) => {
        setAccepting(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        {loading ? (
          <Grid container sx={{ height: '15vh' }} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : request.state === 'pending' ? (
          <Grid container sx={{ height: '15vh' }} alignItems={'flex-start'}>
            <Grid container>
              <Typography variant="h6">Q-Hub has requested access to your account</Typography>
            </Grid>

            {request.reason && (
              <Grid container>
                <Typography style={{ whiteSpace: 'break-spaces' }}>Reason: {request.reason}</Typography>
              </Grid>
            )}

            <Grid container sx={{ mt: 'auto' }}>
              <Grid container item xs={6} sx={{ p: 0.5 }}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="error"
                  startIcon={rejecting ? <CircularProgress size={24} /> : <HighlightOffRounded />}
                  size="large"
                  onClick={reject}
                >
                  reject
                </Button>
              </Grid>
              <Grid container item xs={6} sx={{ p: 0.5 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  startIcon={accepting ? <CircularProgress size={24} /> : <CheckCircleOutlineRounded />}
                  size="large"
                  onClick={accept}
                >
                  accept
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : request?.state ? (
          <Grid container sx={{ height: '15vh' }} alignItems={'center'} justifyContent={'center'}>
            <Typography color="textSecondary">Requests has been {request.state}</Typography>
          </Grid>
        ) : (
          <Grid container sx={{ height: '15vh' }} alignItems={'center'} justifyContent={'center'}>
            <Typography color="textSecondary">Request not found</Typography>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
