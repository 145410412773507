import { useEffect, useState, useRef } from 'react';

const useElementSize = (elementId) => {
  const [size, setSize] = useState({ width: null, height: null });
  const resizeObserver = useRef(null);
  const retryTimeout = useRef(null);

  useEffect(() => {
    function observeElementResize(elem) {
      resizeObserver.current = new ResizeObserver((entries) => {
        const entry = entries[0];
        if (entry) {
          const { width, height } = entry.contentRect;
          setSize({ width, height });
        }
      });
      resizeObserver.current.observe(elem);
    }

    function findElement() {
      const elem = document.getElementById(elementId);
      if (elem) {
        clearTimeout(retryTimeout.current);
        observeElementResize(elem);
      } else {
        retryTimeout.current = setTimeout(findElement, 400);
      }
    }

    if (elementId) {
      findElement();
    }
    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
      clearTimeout(retryTimeout.current);
    };
  }, [elementId]);

  return size;
};

export default useElementSize;
