import axios from 'axios';
import { WEB_PUSH_URL } from '../../constants';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function subscribeUserToPush() {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_URL),
      };

      return registration.pushManager.subscribe(subscribeOptions);
    })
    .then((pushSubscription) => {
      axios
        .post('/notifications/savePushSubscription', { subscription: pushSubscription })
        .then((res) => localStorage.setItem('pushSubscription', res.data.id));
      console.log(`registered: ${JSON.stringify(pushSubscription)}`);
    })
    .catch((err) => {
      console.error(err);
      localStorage.removeItem('pushSubscription');
    });
}

export function needToRequest() {
  if (!('serviceWorker' in navigator)) {
    console.log('Service Worker isnt supported on this browser, disable or hide UI');
    return false;
  }

  if (!('PushManager' in window)) {
    console.log('Push isnt supported on this browser, disable or hide UI');
    return false;
  }

  return localStorage.getItem('pushSubscription') == null && localStorage.getItem('notificationsRejected') !== 'true';
}

export function requestPermission() {
  if (Notification.permission !== 'granted') {
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        subscribeUserToPush();
      } else if (result === 'denied') {
        localStorage.setItem('notificationsRejected', 'true');
      }
    });
  } else {
    subscribeUserToPush();
  }
}

export function saveRejected() {
  localStorage.setItem('notificationsRejected', 'true');
}
