import axios from 'axios';

export default class UserMap {
  static async init() {
    try {
      if (this.map) return;
      const response = await axios.get('/user/getUsers');
      if (response) {
        const data = response.data;
        this.users = data;
        let map = {};
        data.forEach((user) => {
          map[user._id] = {
            _id: user._id,
            fullName: user.fullName,
            avatarLetters: user.avatarLetters,
            avatar: user.avatar,
            firstName: user.firstName,
            lastName: user.lastName,
          };
        });
        this.map = map;
      }
    } catch (err) {
      console.error(err);
    }
  }

  static resolve(id) {
    if (!this.map) return {};
    return { ...this.map[id] };
  }

  static getAllUsers() {
    if (!this.users) return [];
    return this.users.map((x) => ({ ...x }));
  }
}
