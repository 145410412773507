import { Grid, Typography, Paper, Button } from '@mui/material';
import { DialogContent, DialogTitle } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { SettingsOverscanRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import StateManager from '../../../Global/StateManager';
import { Field } from '../../../Global/Fields';
import { HtmlContent, PicturesCarousel, FromNow } from '../../../Global/Components';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import { grey } from '@mui/material/colors';
import GridSection from '../components/GridSection';
import axios from 'axios';
import moment from 'moment';
import _, { isArray, isFunction } from 'lodash';
import { styled } from '@mui/material/styles';
import FieldDataGrid from '../../../Global/Fields/FieldDataGrid';

const SectionPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
  borderRadius: theme.spacing(2),
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 0.5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 1),
  },
}));

export default function PrefillFieldsDialog({ open, onClose, formId, surveyId, editable, onDone, section }) {
  const [values, setValues] = useState([]);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && !surveyId && !section) {
      setLoading(true);
      axios
        .get(`/forms/general/getFormSections`, { params: { formId } })
        .then((res) => {
          setSections(res.data);
          setLoading(false);
        })
        .catch((error) => {
          StateManager.setAxiosErrorAlert(error);
          setLoading(false);
        });
    } else if (open && !surveyId && section) {
      setSections(section);
      setLoading(false);
    } else if (surveyId) {
      axios
        .get('/forms/invitations/getSurveyForForm', { params: { surveyId } })
        .then((res) => {
          setSections(res.data.formPreFilledSections);
          setLoading(false);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setLoading(false);
        });
    }
    //eslint-disable-next-line
  }, [formId, open, surveyId]);

  function onSave(field) {
    if (!editable) return;
    const updatedSections = sections.map((section) => {
      const updatedItems = section.items.map((item) => {
        if (item.id === field.fieldId) {
          return {
            ...item,
            filledValue: field.value,
          };
        }
        return item;
      });
      const updatedTable =
        field.sectionId === field.fieldId ? { ...section.table, rows: field.table.rows } : section.table;
      return {
        ...section,
        prefilledField: updatedItems.filter((item) => item.filledValue?.value).length || null,
        items: updatedItems,
        table: updatedTable,
      };
    });
    setSections(updatedSections);
  }

  function handleClose() {
    onClose();
    onDone(sections);
  }

  return (
    <RoundedDialog
      open={open}
      onClose={onClose}
      className="scroll-bar"
      maxWidth="lg"
      fullWidth
      sx={{ width: 'fit-content', margin: 'auto' }}
    >
      <DialogTitle>{`Pre-fill survey fields`}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container height={'20vh'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid container direction="column">
              <Grid container width="fit-content">
                {sections?.map((section) => (
                  <Section
                    key={section.id}
                    section={section}
                    editable={editable}
                    onSave={onSave}
                    onChange={onSave}
                    hiddenItems={[]}
                  />
                ))}

                {/*<Field key={column.id} item={column} editable={editable} onSave={onSave} />*/}
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>

      <StandardDialogActions
        onClose={onClose}
        doneButtinText={surveyId ? 'Edit' : 'Done'}
        onDone={handleClose}
        hideDone={!editable}
      ></StandardDialogActions>
    </RoundedDialog>
  );
}

export function Section({
  entry,
  section,
  editable,
  hiddenItems,
  onChange,
  autoSave = true,
  allCreatedActions,
  onCreatedAction,
  onDeletedAction,
  executedActions,
  onExecutedActionDelete,
}) {
  const [items, setItems] = useState([]);
  const [savedAt, setSavedAt] = useState(null);
  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const [rows, setRows] = useState();
  const defaultDueDate = moment().add(1, 'week').set({ minutes: 0, seconds: 0, milliseconds: 0 });

  useEffect(() => {
    if (isArray(section?.items)) {
      setItems(section.items);
    }
    if (isArray(section.table?.rows)) {
      setRows(section.table.rows);
    }
  }, [section]);

  function onSave(fieldId, value) {
    const body = {
      fieldId: Object.keys(fieldId),
      sectionId: section.id,
      fieldId,
      value,
    };

    if (_.isFunction(onChange)) onChange(body);

    if (!autoSave) {
      return;
    }
    setSavedAt(null);
  }

  function onRowsSave(fieldId, rows) {
    const body = {
      fieldId,
      sectionId: section.id,
      table: { rows },
    };
    if (_.isFunction(onChange)) onChange(body);

    if (!autoSave) {
      return;
    }
  }

  const itemsToRender = useMemo(
    () => items.filter((x) => !x.conditionalFieldId).filter(({ id }) => !hiddenItems?.includes(id)),
    [items, hiddenItems],
  );

  if (!section) return null;

  return (
    <SectionContainer container item>
      <Grid container component={SectionPaper} variant="outlined" elevation={3}>
        {section.title && (
          <Grid container item sx={{ py: 1 }} alignItems="center">
            <Typography variant="h5">{section.title}</Typography>
            {editable && (
              <FromNow value={savedAt} color="textSecondary" style={{ fontSize: '0.75rem', marginLeft: 'auto' }} />
            )}
          </Grid>
        )}

        <Grid container item sx={{ py: 1 }}>
          {section.type !== 'grid' &&
            itemsToRender.map((item) => (
              <Field
                key={item.id}
                item={item}
                editable={editable && !item.filledValue?.locked}
                onSave={onSave}
                allItems={items}
                assignedUsers={entry?.assignedUsers}
                dueDate={defaultDueDate}
                activityId={entry?._id}
                assignedBy={entry?.startedBy}
                hiddenItems={hiddenItems}
                activity={'FormEntry'}
                executedActions={executedActions}
                onExecutedActionDelete={onExecutedActionDelete}
                allCreatedActions={allCreatedActions}
                onCreatedAction={onCreatedAction}
                onDeletedAction={onDeletedAction}
                activityInfo={{
                  type: 'form',
                  entryId: entry?._id,
                  sectionId: section.id,
                }}
              />
            ))}
          {section.type === 'table' && _.isArray(section.table?.columns) && !_.isEmpty(section.table.columns) && (
            <>
              <FieldDataGrid
                columns={section.table.columns}
                initial={rows}
                editable={editable}
                onChange={(data) => onRowsSave(section.id, data)}
                allCreatedActions={allCreatedActions}
                onCreatedAction={onCreatedAction}
                onDeletedAction={onDeletedAction}
                forceUpdate={tableDialogOpen}
                activityInfo={{
                  type: 'form',
                  entryId: entry?._id,
                  sectionId: section?.id,
                }}
              />
              <Grid container justifyContent="flex-end" sx={{ mt: 1 }}>
                <Button
                  startIcon={<SettingsOverscanRounded />}
                  onClick={() => setTableDialogOpen(true)}
                  style={{ color: grey[700] }}
                >
                  open in dialog
                </Button>
              </Grid>
              <TableDialog
                title={section.title}
                columns={section.table.columns}
                rows={rows}
                editable={editable}
                onChange={(data) => {
                  onRowsSave(data);
                }}
                open={tableDialogOpen}
                onClose={() => setTableDialogOpen(false)}
                activityInfo={{
                  type: 'form',
                  entryId: entry?._id,
                  sectionId: section.id,
                }}
                onCreatedAction={onCreatedAction}
              />
            </>
          )}
          {section.type === 'info' && section.info && (
            <Grid container>
              <Grid
                container
                item
                lg={section.info.layout === 'textAndImages' && section.info.images[0] ? 8 : 12}
                md={section.info.layout === 'textAndImages' && section.info.images[0] ? 8 : 12}
                xs={12}
                style={{ paddingRight: 8 }}
              >
                <HtmlContent content={section.info.text} />
              </Grid>
              {section.info.layout === 'textAndImages' && section.info.images[0] && (
                <Grid container item lg={4} md={4} xs={12} alignContent="flex-start">
                  <Grid container item>
                    <PicturesCarousel pictures={section.info.images} />
                  </Grid>
                  <Grid container item style={{ marginTop: 8 }}>
                    <Typography variant="h6">{section.info.caption}</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {section.type === 'grid' && (
            <GridSection layout={section.layout} items={section.items} editable={editable} onSave={onSave} />
          )}
        </Grid>
        {!section.title && editable && (
          <Grid container item alignItems="center">
            <FromNow value={savedAt} color="textSecondary" style={{ fontSize: '0.75rem', marginLeft: 'auto' }} />
          </Grid>
        )}
      </Grid>
    </SectionContainer>
  );
}

function TableDialog({
  title,
  columns,
  rows,
  editable,
  onChange,
  open,
  onClose,
  activityInfo,
  allCreatedActions,
  onCreatedAction,
}) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <FieldDataGrid
          columns={columns}
          initial={rows}
          editable={editable}
          onChange={onChange}
          activityInfo={activityInfo}
          allCreatedActions={allCreatedActions}
          onCreatedAction={onCreatedAction}
          forDialog
        />
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={onClose} hideDone={!editable} />
    </RoundedDialog>
  );
}
