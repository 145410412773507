import axios from 'axios';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import StateManager from '../StateManager';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../Layout';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useState } from 'react';

// === tasks ===
const NEW_TASK_EVENT = 'newTask';
const TASK_IS_DUE_EVENT = 'taskIsDue';
const TASK_STATUS_CHANGED_EVENT = 'taskStatusChanged';
const TASK_COMPLETED_EVENT = 'taskCompleted';
const TASK_ACCEPTED = 'taskAccepted';
const TASK_REJECTED = 'taskRejected';
const NEW_COMMENT_EVENT = 'newComment';
const TASK_DELETED_EVENT = 'taskDeleted';
const ASSIGNEE_CHANGED_EVENT = 'assigneeChanged';
const TASK_IS_OVERDUE_EVENT = 'taskIsOverdue';
const NEW_SUB_TASK = 'newSubTask';
const SUB_TASK_STATUS_CHANGED = 'subTaskStatusChanged';
const SUB_TASK_NEW_COMMENT = 'subTaskNewComment';
const TASK_REMINDER = 'taskReminder';
const WATCH_INVITE = 'watchInvite';
const TASK_WATCHED = 'taskWatched';
const PROJECT_TASK_ADDED = 'projectTaskAdded';
const TASK_OWNER_CHANGED = 'taskOwnerChanged';
const ADDED_TO_PROJECT = 'addedToProject';
const REMOVED_FROM_PROJECT = 'removedFromProject';
const NEW_ROUTINE_TASK = 'newRoutineTask';
const NEW_RU_TASK = 'newRuTask';

// dl review
const NEW_DL_REVIEW = 'newDLReview';
const NEW_DL_REVIEW_ASSIGNEE = 'newDLReviewAssignee';
const NEW_DL_AUTOMATED_REVIEW = 'newDLAutomatedReview';
const COMPLETE_DL_REVIEW = 'completeDLReview';

const FINISH_REVIEW = 'finishReview';
const NEW_POST = 'newPost';
const ISSUE_REVIEW = 'issueReview';
const NEW_ISSUE_REVIEW = 'newIssueReview';
const CANCEL_ISSUE_REVIEW = 'cancelIssueReview';

// === forms ===
const ENTRY_SENT = 'entrySent';
const ENTRY_STARTED = 'entryStarted';
const ENTRY_SUBMITTED = 'entrySubmitted';
const ROW_STATUS = 'rowStatus';
const FORM_REMINDER = 'formReminder';
const FORM_ENTRY_EDIT = 'formEdit';
const PROCESS_ENTRY_EDIT = 'processEdit';

// === audits ===
const NEW_AUDIT = 'newAudit';
const AUDIT_STATE_CHANGED = 'auditStateChanged';
const AUDIT_RESCHEDULED = 'auditRescheduled';
const AUDIT_OBSERVATION = 'auditObservation';
const AUDIT_STARTED = 'auditStarted';
const AUDIT_FINISHED = 'auditFinished';
const AUDIT_CLOSED = 'auditClosed';
const AUDIT_CANCELLED = 'auditCancelled';
const NCR_CANCELLED = 'ncrCancelled';
const NCR_SECTION_SUBMITTED = 'NCRSectionSubmitted';
const NCR_SECTION_APPROVED = 'NCRSectionApproved';
const NCR_SECTION_REJECTED = 'NCRSectionRejected';
const NCR_SUBMITTED = 'NCRSubmitted';
const NCR_APPROVED = 'NCRApproved';
const NCR_REJECTED = 'NCRRejected';

// === support ===
const TICKET_REGISTERED = 'ticketRegistered';
const TICKET_NEW_COMMENT = 'ticketNewComment';
const TICKET_STATUS_CHANGED = 'ticketStatusChanged';
const TICKET_RESPONSE = 'ticketResponse';
const TICKET_CLOSED = 'ticketClosed';

// === approvals ===
const NEW_APPROVAL = 'newApproval';
const APPROVAL_APPROVED = 'approvalApproved';
const APPROVAL_REJECTED = 'approvalRejected';
const APPROVAL_CANCELLED = 'approvalCancelled';
const USER_APPROVAL_APPROVED = 'userApprovalApproved';
const USER_APPROVAL_REJECTED = 'userApprovalRejected';

// === process ====
const PROCESS_STATE_CHANGED = 'processStateChanged';
const STEP_STARTED = 'stepStarted';
const STEP_SENT = 'stepSent';
const STEP_COMPLETE = 'stepComplete';
const STEP_OWNER_CHANGED = 'stepOwnerChanged';
const STEP_OVERDUE = 'stepOverdue';
const STEP_REMINDER = 'stepReminder';

// === mention ====
const TASK_MENTION = 'taskMention';
const MENTION_EVENT = 'mentionEvent';

// === asset ===
const RETURN_ASSET = 'returnAsset';
const ASSET_EXPIRY = 'assetExpiry';
const ASSET_OVERDUE = 'assetOverdue';
const ASSET_EXPIRED = 'assetExpired';
const ASSET_REPORT_SHARED = 'assetReportShared';
const ASSET_BOOKED_OUT = 'assetBookedOut';
const ASSET_BOOKED_IN = 'assetBookedIn';
const ASSET_UNAVAILABLE = 'assetUnavailable';
const ASSET_STATUS_CHANGE = 'assetStatusChange';
const ASSET_RECALL_STARTED = 'assetRecallStarted';
const ASSET_RECALL_COMPLETED = 'assetRecallCompleted';
const ASSET_RECALL_CANCELLED = 'assetRecallCancelled';
const ASSET_PRE_BOOKING = 'assetPreBooking';
const ASSET_PRE_BOOKING_REMINDER = 'assetPreBookingReminder';
const ASSET_ARCHIVED = 'assetArchived';
const ASSET_UNARCHIVED = 'assetUnarchived';

// === dl ===
const ISSUE_UPDATE = 'issueUpdate';

// === reviews ===
const NEW_REVIEW = 'newReview';

// === others ===
const OTHER = 'other';

const NotificationRedirect = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const goToNotificationDetailsPage = useCallback(
    (type, notification) => {
      if (
        [
          TASK_STATUS_CHANGED_EVENT,
          TASK_IS_DUE_EVENT,
          NEW_TASK_EVENT,
          TASK_COMPLETED_EVENT,
          TASK_ACCEPTED,
          TASK_REJECTED,
          TASK_DELETED_EVENT,
          TASK_OWNER_CHANGED,
          ASSIGNEE_CHANGED_EVENT,
          TASK_WATCHED,
          TASK_REMINDER,
          WATCH_INVITE,
          NEW_REVIEW,
          TASK_IS_OVERDUE_EVENT,
          NEW_COMMENT_EVENT,
          PROJECT_TASK_ADDED,
          ASSET_EXPIRY,
          NEW_ROUTINE_TASK,
          NEW_RU_TASK,
        ].includes(type)
      ) {
        return StateManager.selectTask(notification.taskId);
      }
      if ([NEW_DL_REVIEW, NEW_DL_REVIEW_ASSIGNEE, NEW_DL_AUTOMATED_REVIEW, COMPLETE_DL_REVIEW].includes(type)) {
        return StateManager.openReviewDialog(notification.body.reviewId);
      }
      if (
        [
          NEW_ISSUE_REVIEW,
          CANCEL_ISSUE_REVIEW,
          ISSUE_REVIEW,
          ISSUE_UPDATE,
          FINISH_REVIEW,
          NEW_APPROVAL,
          APPROVAL_APPROVED,
          APPROVAL_REJECTED,
          APPROVAL_CANCELLED,
          USER_APPROVAL_APPROVED,
          USER_APPROVAL_REJECTED,
          PROCESS_STATE_CHANGED,
          STEP_STARTED,
          STEP_SENT,
          STEP_COMPLETE,
          STEP_OWNER_CHANGED,
          STEP_OVERDUE,
          MENTION_EVENT,
          OTHER,
        ].includes(type)
      ) {
        return history.push(notification.body.link);
      }
      if ([NEW_POST].includes(type)) {
        return history.push(`/noticeBoard`);
      }
      if ([NEW_SUB_TASK, SUB_TASK_STATUS_CHANGED, SUB_TASK_NEW_COMMENT, TASK_MENTION].includes(type)) {
        return StateManager.selectTask({ taskId: notification.taskId, subTaskId: notification.body.subTaskId });
      }
      if (
        [
          NEW_AUDIT,
          AUDIT_STATE_CHANGED,
          AUDIT_RESCHEDULED,
          AUDIT_STARTED,
          AUDIT_FINISHED,
          AUDIT_CANCELLED,
          AUDIT_CLOSED,
        ].includes(type)
      ) {
        return history.push(`/audits/${notification.body.auditId}`);
      }
      if ([NCR_CANCELLED].includes(type)) {
        if (notification.body.ncrType === 'task') {
          return StateManager.selectTask(notification.body.activityId);
        } else {
          const link =
            notification.body.ncrType === 'form'
              ? `/forms/entry/${notification.body.activityId}`
              : notification.body.ncrType === 'process'
              ? `/processes/ongoing/${notification.body.activityId}`
              : `/audits/${notification.body.auditId}`;
          return history.push(link);
        }
      }
      if ([AUDIT_OBSERVATION].includes(type)) {
        return history.push(`/audits/${notification.body.auditId}?requirementId=${notification.body.requirementId}`);
      }
      if ([ASSET_REPORT_SHARED].includes(type)) {
        return history.push(`/asset/reports/${notification.body.sharedReportId}`);
      }
      if (
        [
          RETURN_ASSET,
          ASSET_OVERDUE,
          ASSET_EXPIRED,
          ASSET_BOOKED_OUT,
          ASSET_BOOKED_IN,
          ASSET_UNAVAILABLE,
          ASSET_STATUS_CHANGE,
          ASSET_RECALL_STARTED,
          ASSET_RECALL_COMPLETED,
          ASSET_RECALL_CANCELLED,
          ASSET_PRE_BOOKING,
          ASSET_PRE_BOOKING_REMINDER,
          ASSET_ARCHIVED,
          ASSET_UNARCHIVED,
        ].includes(type)
      ) {
        return history.push(`/asset/${notification.body.assetId}`);
      }
      if ([ENTRY_SENT, ENTRY_STARTED, ENTRY_SUBMITTED, FORM_REMINDER, FORM_REMINDER, FORM_ENTRY_EDIT].includes(type)) {
        return history.push(`/forms/entry/${notification.body.entryId}`);
      }
      if ([ROW_STATUS].includes(type)) {
        const link = notification.body.forProcess
          ? `/processes/step/${notification.body.stepId}`
          : `/forms/entry/${notification.body.entryId}`;
        return history.push(link);
      }
      if ([PROCESS_ENTRY_EDIT].includes(type)) {
        return history.push(`/processes/ongoing/${notification.body.entryId}`);
      }

      if ([ADDED_TO_PROJECT, REMOVED_FROM_PROJECT].includes(type)) {
        return history.push(`/tasks/projects/project/${notification?.body?.projectId}`);
      }
      if (
        [
          NCR_SECTION_SUBMITTED,
          NCR_SECTION_APPROVED,
          NCR_SECTION_REJECTED,
          NCR_SUBMITTED,
          NCR_APPROVED,
          NCR_REJECTED,
        ].includes(type)
      ) {
        return history.push(`/audits/ncrs/${notification?.activityId ?? notification?.taskId}`);
      }

      if ([STEP_REMINDER].includes(type)) {
        return history.push(`/processes/step/${notification.body.stepId}`);
      }

      if (
        [TICKET_REGISTERED, TICKET_NEW_COMMENT, TICKET_STATUS_CHANGED, TICKET_RESPONSE, TICKET_CLOSED].includes(type)
      ) {
        return history.push(`/support/${notification.body.ticketId}`);
      }
    },
    [history],
  );

  const handleNotificationData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`/notifications/${id}`)
      .then(async (res) => {
        if (res?.data) {
          await goToNotificationDetailsPage(res?.data?.type, res?.data);
          setLoading(false);
        } else {
          StateManager.setAxiosErrorAlert(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [id, goToNotificationDetailsPage]);

  useEffect(() => {
    handleNotificationData();
  }, [handleNotificationData]);

  return (
    <Layout
      icon={<NotificationsActiveOutlinedIcon style={{ color: '#4a4e54' }} />}
      title="Notifications"
      hideNotificationIcon={true}
    >
      {loading && <Loader />}
    </Layout>
  );
};

export default NotificationRedirect;
