import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, Button, ListItemIcon, ListItemText } from '@mui/material';
import { deepPurple, grey, green, amber, deepOrange } from '@mui/material/colors';
import { Whatshot } from '@mui/icons-material';

export default function PriorityButton({ priority, setPriority, disabled }) {
  const [priorityChangeMenu, setPriorityChangeMenu] = useState(null);
  const [ownPriority, setOwnPriority] = useState(null);

  const priorities = ['None', 'Low', 'Medium', 'High', 'Critical'];

  const priorityColors = {
    None: grey[400],
    Low: green[500],
    Medium: amber[500],
    High: deepOrange[500],
    Critical: deepPurple[500],
  };

  useEffect(() => {
    if (priority) setOwnPriority(priority);
  }, [priority]);

  return (
    <>
      <Button
        startIcon={<Whatshot fontSize="large" style={{ color: priorityColors[ownPriority] }} />}
        disabled={Boolean(disabled)}
        sx={{ fontWeight: 600, textTransform: 'none', fontSize: 18, color: (theme) => theme.palette.text.secondary }}
        onClick={(event) => setPriorityChangeMenu(event.currentTarget)}
      >
        {ownPriority}
      </Button>

      <Menu
        anchorEl={priorityChangeMenu}
        keepMounted
        open={Boolean(priorityChangeMenu)}
        onClose={() => setPriorityChangeMenu(null)}
      >
        {priorities.map((x, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setPriorityChangeMenu(null);
              setPriority(x);
              setOwnPriority(x);
            }}
            style={{ fontWeight: x === ownPriority ? 700 : '' }}
          >
            <ListItemIcon>
              <Whatshot style={{ color: priorityColors[x] }} />
            </ListItemIcon>
            <ListItemText primary={x} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
