/**
 * * Reducer for Document Hub, follows the cases as per relevant actions
 */
import {
  SETQDOC,
  SETQSTATUS,
  GETQDOC_ATTEMPT,
  GETQDOC_SUCCESS,
  GETQDOC_FAIL,
  UPDATEQDOC_ATTEMPT,
  UPDATEQDOC_SUCCESS,
  UPDATEQDOC_FAIL,
  SETMOVEDIALOG,
  ADDQDOC_ATTEMPT,
  ADDQDOC_SUCCESS,
  ADDQDOC_FAIL,
  ADDDRAFTQDOC_ATTEMPT,
  ADDDRAFTQDOC_SUCCESS,
  ADDDRAFTQDOC_FAIL,
  INITQDOC,
  GETQREVISION_ATTEMPT,
  GETQREVISION_SUCCESS,
  GETQREVISION_ERROR,
  ADDCOMMENTTOSTATE,
  UPDATEQREVISION,
  FETCHREVCOMMENT,
  UPDATEREPLYINREVCOMMENT,
  SETNEWFILE,
  SETREMOVECOMMENT,
  SETREMOVECOMMENTFILE,
  FETCHREVCOMMENT_ATTEMPT,
  UPDATEHF,
  SETQDOCTITLE,
  SETSECTIONS,
} from '../constants';

const initialState = {
  fetching: false,
  qdoc: {
    title: 'Untitled Document',
  },
  document: null,
  qLoading: false,
  saving: false,
  qAccess: null,
  sections: [],
  status: null,
  message: null,
  moveDialog: false,
  content: null,
  comment: null,
  commentUpdate: false,
  newFile: false,
  rawContent: null,
  removeComments: false,
  plainPdf: null,
  revisionReason: '',
  revisionChanges: '',
  title: '',
  currentRevisionFile: null,
  isFirstUpIssue: false,
  sections: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETREMOVECOMMENT:
      return { ...state, removeComments: action.payload.comment };
    case SETREMOVECOMMENTFILE:
      return { ...state, plainPdf: action.payload.file };
    case ADDQDOC_ATTEMPT:
      return { ...state, qLoading: true, setupDialog: false };
    case ADDQDOC_SUCCESS:
      return {
        ...state,
        qLoading: false,
        qdoc: action.payload.Documents,
        selected: action.payload.Documents,
        highlightedDocument: action.payload.Documents,
      };
    case ADDQDOC_FAIL:
      return { ...state, qLoading: false, error: action.payload, setupDialog: false };
    case ADDDRAFTQDOC_ATTEMPT:
      return { ...state, saving: true, setupDialog: false, document: null };
    case ADDDRAFTQDOC_SUCCESS:
      return {
        ...state,
        saving: false,
        title: 'Untitled Document',
        document: action.payload.Documents,
        selected: action.payload.Documents,
        highlightedDocument: action.payload.Documents,
      };
    case ADDDRAFTQDOC_FAIL:
      return { ...state, saving: false, error: action.payload, setupDialog: false };
    case SETMOVEDIALOG:
      return { ...state, moveDialog: action.payload };
    case INITQDOC:
      return {
        ...state,
        qdoc: {
          title: 'Untitled Document',
        },
        document: null,
      };
    case SETQDOC:
      return {
        ...state,
        document: action.payload,
        revisionChanges: action.payload.revisionChanges,
        revisionReason: action.payload.revisionReason,
      };
    case SETNEWFILE:
      return { ...state, newFile: action.payload };
    case SETQSTATUS:
      return { ...state, status: action.payload };
    case GETQDOC_ATTEMPT:
      return { ...state, fetching: true, qAccess: 'fetching' };
    case GETQDOC_SUCCESS:
      return {
        ...state,
        fetching: false,
        title: action.payload.status === 'Ok' ? action.payload.document?.title : state.title,
        qdoc: action.payload.status === 'Ok' ? action.payload.document : null,
        document: action.payload.status === 'Ok' ? action.payload.document : null,
        qAccess: action.payload.access,
        sections: action.payload.document?.sections,
      };
    case GETQDOC_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case GETQREVISION_ATTEMPT:
      return { ...state, qLoading: true, sections: [], qdoc: null, qAccess: 'fetching', currentRevisionFile: null };
    case GETQREVISION_SUCCESS:
      if (action.payload.issue) {
        if (action.payload.issue.category === 'qhub') {
          return {
            ...state,
            qLoading: false,
            qdoc: action.payload.issue.document,
            document: action.payload.issue,
            revisionChanges: action.payload.issue.revisionChanges,
            revisionReason: action.payload.issue.revisionReason,
            rawContent: action.payload.issue.raw,
            isFirstUpIssue: action.payload.isFirst,
            sections: action.payload.issue?.sections,
          };
        } else {
          return {
            ...state,
            qLoading: false,
            qdoc: action.payload.issue.document,
            document: action.payload.issue,
            revisionChanges: action.payload.issue.revisionChanges,
            revisionReason: action.payload.issue.revisionReason,
            currentRevisionFile: action.payload.currentRevisionFile,
            isFirstUpIssue: action.payload.isFirst,
          };
        }
      } else {
        return {
          ...state,
          qLoading: false,
        };
      }
    case GETQREVISION_ERROR:
      return { ...state, qLoading: false, error: action.payload };
    case UPDATEQREVISION:
      return {
        ...state,
        document: action.payload.issue,
        qdoc: action.payload.issue.document,
        newFile: action.payload.newFile,
        currentRevisionFile: action.payload.currentRevisionFile
          ? action.payload.currentRevisionFile
          : state.currentRevisionFile,
        sections:
          state.document?.stage !== action.payload.issue?.stage ? action.payload.issue?.sections : state.sections,
      };
    case SETSECTIONS:
      return { ...state, sections: action.payload };
    case UPDATEHF:
      return {
        ...state,
        document: {
          ...state.document,
          headerConfig: action.payload.headerConfig,
          footerConfig: action.payload.footerConfig,
          defaultHeaderFooter: action.payload.defaultHeaderFooter,
        },
      };

    case UPDATEQDOC_ATTEMPT:
      return { ...state, qLoading: true };
    case UPDATEQDOC_SUCCESS:
      return {
        ...state,
        qLoading: false,
        document: action.payload.Document,
        status: action.payload.status,
        message: action.payload.status === 'Ok' ? 'Document Saved' : 'Error saving Document',
      };
    case UPDATEQDOC_FAIL:
      return { ...state, qLoading: false, error: action.payload };

    case ADDCOMMENTTOSTATE:
      return { ...state, editorState: action.payload, commentUpdate: true };

    case FETCHREVCOMMENT_ATTEMPT:
      return { ...state, fetching: true, comment: null };

    case FETCHREVCOMMENT:
      return { ...state, fetching: false, comment: action.payload.comment };

    case SETQDOCTITLE:
      return { ...state, title: action.payload };

    case UPDATEREPLYINREVCOMMENT:
      if (state.comment && action.payload.id === state.comment._id) {
        return { ...state, fetching: false, comment: action.payload.comment };
      } else {
        return { ...state, fetching: false };
      }

    default:
      return state;
  }
}
