import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green } from '@mui/material/colors';
import { lightGreen, lime, yellow, amber, orange, deepOrange, grey, blueGrey } from '@mui/material/colors';
import moment from 'moment';
import { cloneDeep, isArray, isNumber, isEmpty, isString } from 'lodash';
import { v4 } from 'uuid';

export function hex24() {
  return [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
}

export function randomHexString(length = 24) {
  return [...Array(length)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
}

export function getURLParams() {
  return Object.fromEntries(new URLSearchParams(location.search));
}

export function FormatDate(date, forLastSeen) {
  if (!date) return forLastSeen ? 'never logged in' : 'a long time ago';
  const dt = moment(date);
  var time = dt.format('hh:mm A');
  if (dt.isSame(moment(), 'day')) {
    // if today, only time
    return `at ${time}`;
  } else if (dt.isSame(moment().subtract(1, 'days'), 'day')) {
    // if yesterday
    return `yesterday at ${time}`;
  } else if (dt.isSame(moment(), 'year')) {
    // if this year
    return `${dt.format('D MMM')} at ${time}`;
  } else {
    return `${dt.format('D-MMM-YY')} at ${time}`; // if not this year
  }
}

export function FormatDateShort(date) {
  if (!date) return 'a long time ago';
  const dt = moment(date);
  var time = dt.format('hh:mm A');
  if (dt.isSame(moment(), 'day')) {
    // if today, only time
    return `${time}`;
  } else {
    return `${dt.format('D MMMM YYYY')}`;
  }
}

export function FormatDateFull(date) {
  if (!date) return '-';
  return moment(date).format('DD/MM/YYYY hh:mm A');
}

export function FormatDateCustom(date) {
  if (!date) return '-';
  return moment(date).format('hh:mm:ss A DD/MM/YYYY');
}

export function FormatOnlyDate(date) {
  if (!date) return '-';
  return moment(date).format('DD/MM/YYYY');
}

export function FormatOnlyTime(date) {
  if (!date) return '-';
  return moment(date).format('hh:mm A');
}

export function FormatDuration(milliseconds) {
  if (isNaN(milliseconds)) return '-';
  const duration = moment.duration(Number(milliseconds));

  const days = Math.floor(duration.asDays());
  duration.subtract(moment.duration(days, 'days'));

  //Get hours and subtract from duration
  const hours = Math.floor(duration.asHours());
  duration.subtract(moment.duration(hours, 'hours'));

  //Get Minutes and subtract from duration
  const minutes = Math.floor(duration.minutes());

  const daysPart = days > 0 ? `${days}d` : '';
  const hoursPart = hours > 0 ? `${hours}h` : '';
  const minutesPart = minutes > 0 ? `${minutes}m` : '';

  const result = `${daysPart} ${hoursPart} ${minutesPart}`.replace(/\s+/g, ' ').trim();

  if (result) return result;

  duration.subtract(moment.duration(minutes, 'minutes'));

  const seconds = duration.seconds();

  return `${seconds}s`;
}

export function isHex24(id) {
  if (!id || typeof id !== 'string') return false;
  if (!/^[0-9a-f]{24}$/.test(id)) {
    return false;
  }
  return true;
}

export function isUuid(id) {
  if (!id || typeof id !== 'string') return false;
  if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(id)) {
    return false;
  }
  return true;
}

export function validateEmail(email) {
  if (!email || typeof email !== 'string') return false;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateUkNumber(num) {
  const re = /^0[0-9]{9,10}$/;
  return re.test(num);
}

export function sortDataGridColumns(columns, model) {
  if (!isArray(columns)) return [];

  const copy = cloneDeep(columns);

  const modelArray = isArray(model) ? model : [];

  const result = !isEmpty(modelArray)
    ? copy.sort((a, b) =>
        // important: push the unknown columns to the back of the table
        modelArray.some((x) => x.field === a.field) && modelArray.some((x) => x.field === b.field)
          ? modelArray.findIndex((x) => x.field === a.field) - modelArray.findIndex((x) => x.field === b.field)
          : 1,
      )
    : copy;

  if (!isEmpty(modelArray)) {
    for (let i = 0; i < result.length; ++i) {
      const fieldModel = modelArray.find((x) => x.field === result[i].field);
      if (!fieldModel?.width) continue;
      result[i] = { ...result[i], width: fieldModel?.width };
    }
  }

  return result;
}

const colors = [
  blue,
  orange,
  purple,
  deepOrange,
  cyan,
  red,
  green,
  deepPurple,
  pink,
  lightBlue,
  amber,
  indigo,
  lightGreen,
  grey,

  lime,
  teal,
  yellow,
  blueGrey,
];

export function randomColor(number, column = 500) {
  const i = isNumber(number) ? Math.abs(number) % colors.length : Math.floor(Math.random() * (colors.length - 1));
  return colors[i][column];
}

export function getStandardColors(column = 500) {
  return colors.map((color) => color[column]);
}

export const kpiColors = getStandardColors(700);

export function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const decimals = i < 2 ? 0 : 1;
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
}

export function checkFields(fields) {
  if (!Array.isArray(fields)) return { error: `Provide fields` };
  for (let i = 0; i < fields.length; ++i) {
    // check if all fields have title
    if (!fields[i].title && (fields[i].type === 'field' || fields[i].type === 'table')) {
      return { error: `Provide title for field number ${i + 1}` };
    }

    if (fields[i].type === 'text' && !fields[i].title && !fields[i].text) {
      return { error: `Provide text for field number ${i + 1}` };
    }

    // check that forms set
    if (fields[i].type === 'form' && !fields[i].formId) {
      return { error: `Provide form to fill for field number ${i + 1}` };
    }

    // check that process set
    if (fields[i].type === 'process' && !fields[i].processId) {
      return { error: `Provide process to for field number ${i + 1}` };
    }

    // check if all fields have type
    if (fields[i].type === 'field' && !fields[i].fieldType) {
      return { error: `Provide field type for field "${fields[i].title}"` };
    }

    // check table params
    if (fields[i].type === 'field' && fields[i].fieldType === 'table' && !fields[i].table) {
      return { error: `Provide table columns for field "${fields[i].title}"` };
    }

    // check data set params
    if (fields[i].type === 'field' && fields[i].fieldType === 'dataSet' && !fields[i].dataSetParams) {
      return { error: `Provide data set options for field "${fields[i].title}"` };
    }

    // check options set
    if (
      fields[i].type === 'field' &&
      (fields[i].fieldType === 'tickbox' || fields[i].fieldType === 'dropbox') &&
      (!Array.isArray(fields[i].options) || fields[i].options.length === 0)
    ) {
      return { error: `Provide options for field "${fields[i].title}"` };
    }

    // check data set params
    if (fields[i].type === 'resources' && (!Array.isArray(fields[i].files) || fields[i].files.length === 0)) {
      return { error: `Provide resources for field "${fields[i].title}"` };
    }

    // conditional fields
    if (fields[i].type === 'field' && fields[i].fieldType === 'conditional') {
      if (!Array.isArray(fields[i].conditions) || fields[i].conditions.length < 2) {
        return { error: `Provide at least 2 conditional actions for field ${fields[i].title}` };
      }
    }

    // if linked table is selected - no need to check the columns
    if (fields[i].type === 'table' && !fields[i].table?.linkedTableId) {
      const { error } = checkFieldColumns(fields[i].table?.columns);
      if (error) {
        return { error };
      }
    }
  }

  return { error: null };
}

export function ReplaceValuesInObject(object, oldValue, newValue) {
  if (object && typeof object === 'object') {
    for (let key in object) {
      object[key] = object[key] === oldValue ? newValue : ReplaceValuesInObject(object[key], oldValue, newValue);
    }
  }
  return object;
}

export function checkFieldColumns(columns) {
  if (!Array.isArray(columns) || columns.length === 0) return { error: `Provide columns for table` };
  for (let i = 0; i < columns.length; ++i) {
    const column = columns[i];
    if (!column.title) return { error: `Provide title for column number ${i + 1}` };
    if (!column.fieldType) return { error: `Provide type for column '${column.title}'` };
    if (
      ['tickbox', 'dropbox', 'status'].includes(column.fieldType) &&
      (!Array.isArray(column.options) || column.options.length === 0)
    ) {
      return { error: `Provide options for column '${column.title}'` };
    }
  }

  return { error: null };
}

export function stripHtml(html) {
  let tmp = document.createElement(`div-${v4()}`);
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export function isHtml(str) {
  if (!str || typeof str !== 'string') return false;

  return /<\/?[a-z][\s\S]*>/i.test(str);
}

export function highlightElementError(id) {
  const element = document.getElementById(id);
  if (!element) return;

  element.scrollIntoView({ behavior: 'smooth' });
  const className = 'error-highlight';
  const timeout = 5000;
  element.classList.add(className);

  setTimeout(() => {
    element.classList.remove(className);
  }, timeout);
}

export function highlightElement(id) {
  const element = document.getElementById(id);
  if (!element) return;

  element.scrollIntoView({ behavior: 'smooth' });
  const className = 'highlighted-element';
  const timeout = 5000;
  element.classList.add(className);

  setTimeout(() => {
    element.classList.remove(className);
  }, timeout);
}

function replaceKeysAndValuesInObject(object, oldValue, newValue) {
  if (object && typeof object === 'object') {
    for (let key in object) {
      const value = object[key] === oldValue ? newValue : replaceKeysAndValuesInObject(object[key], oldValue, newValue);
      if (key === oldValue) {
        delete object[key];
        object[newValue] = value;
      } else {
        object[key] = value;
      }
    }
  }
  return object;
}

function cloneTable(initial) {
  if (!isArray(initial?.columns)) return initial;
  const columns = initial.columns;
  const rows = initial.rows || [];
  for (let i = 0; i < columns.length; ++i) {
    const oldId = columns[i].id;
    const newId = v4();
    columns[i].id = newId;
    columns[i].dataKey = newId;
    for (let j = 0; j < columns.length; ++j) {
      const column = columns[j];
      if (column.fieldType !== 'calculation' || !column.expressionParams?.columns?.includes(oldId)) continue;
      const expression = column.expressionParams.expression;
      const text = column.expressionParams.text;
      const bareOldId = oldId.replace(new RegExp('-', 'g'), '');
      const bareNewId = newId.replace(new RegExp('-', 'g'), '');
      columns[j].expressionParams.expression = String(expression).replace(new RegExp(bareOldId, 'g'), bareNewId);
      columns[j].expressionParams.text = String(text).replace(new RegExp(oldId, 'g'), newId);
      columns[j].expressionParams.columns = replaceKeysAndValuesInObject(column.expressionParams.columns, oldId, newId);
    }

    if (Array.isArray(columns[i].options)) {
      columns[i].options.forEach((x) => {
        const oldOptionId = x.id;
        const newOptionId = v4();
        x.id = newOptionId;
        replaceKeysAndValuesInObject(rows, oldOptionId, newOptionId);
      });
    }
    replaceKeysAndValuesInObject(rows, oldId, newId);
  }

  for (let i = 0; i < rows.length; ++i) {
    const newId = v4();
    rows[i].id = newId;
  }

  return { columns, rows };
}

function cloneOldTable(initial) {
  if (!isArray(initial?.columns)) return initial;
  initial.columns = initial.columns.map((x) => ({ ...x, id: v4() }));
  return initial;
}

export function DuplicateField(field) {
  if (!field) return null;
  const copy = cloneDeep(field);

  // for global fields we need to retain the ids, it's critical
  const isGlobal = copy.globalFieldId;

  // { [oldId]: newId }
  const optionsMap = {};

  // change ids in options array and memorize the old for automations
  if (isArray(copy.options) && !isEmpty(copy.options) && !isGlobal) {
    for (let i = 0; i < copy.options.length; ++i) {
      const newId = v4();
      const option = copy.options[i];

      optionsMap[option.id] = newId;

      option.id = newId;
    }
  }

  // change ids in automations
  if (isArray(copy.automations) && !isEmpty(copy.automations)) {
    for (let i = 0; i < copy.automations.length; ++i) {
      const automation = copy.automations[i];
      automation.id = v4();
      automation.actions = isArray(automation.actions) ? automation.actions.map((x) => ({ ...x, id: v4() })) : [];

      if (automation.trigger?.selectedOptionId && !isGlobal) {
        automation.trigger.selectedOptionId = optionsMap[automation.trigger.selectedOptionId];
      }

      if (isArray(automation.trigger?.selectedOptions) && !isGlobal) {
        automation.trigger.selectedOptions = automation.trigger.selectedOptions.map((x) => optionsMap[x]);
      }
    }
  }

  const cloned = {
    id: v4(),
    globalFieldId: copy.globalFieldId,
    globalFieldVersionId: copy.globalFieldVersionId,
    type: copy.type,
    sectionId: copy.sectionId,
    fieldType: copy.fieldType,
    title: copy.title,
    text: copy.text,
    required: copy.required,
    allowDuplicating: copy.allowDuplicating,
    expression: copy.expression,
    preSetActions: isArray(copy.preSetActions)
      ? copy.preSetActions.map((action) => ({ ...action, id: v4() }))
      : copy.preSetActions,
    titleStyle: copy.titleStyle,
    readAndSignOptions: copy.readAndSignOptions,
    picture: copy.picture,
    condition: copy.condition,
    options: copy.options,
    selectType: copy.selectType,
    conditions: copy.conditions,
    dataSetParams: copy.dataSetParams,
    table: cloneTable(copy.table),
    tableParams: cloneOldTable(copy.tableParams),
    peopleOptions: copy.peopleOptions,
    helpText: copy.helpText,
    documentId: copy.documentId,
    documentTitle: copy.documentTitle,
    files: copy.files,
    conditionalFieldId: copy.conditionalFieldId,
    displayType: copy.displayType,
    readOnly: copy.readOnly,
    automations: copy.automations,
  };

  return cloned;
}

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function getOrdinalSuffix(num) {
  const number = Number(num);
  var j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
}

export function SchedulingSettingsToString(settings, routineType = 'task') {
  const Types = {
    task: 'Task',
    report: 'Report',
  };

  if (settings.type === 'weekly') {
    let days = settings.days
      .filter((x) => x.working)
      .map((x) => daysOfWeek[x.day])
      .join(', ');
    return `${Types[routineType]} sent on ${days} ${
      settings.weekInterval === 1 ? '' : `every ${settings.weekInterval} weeks`
    }`;
  } else if (settings.type === 'monthly') {
    let days = settings.monthDays
      .map((day) =>
        day.type === 'day'
          ? `on the ${day.day}${getOrdinalSuffix(day.day)} day`
          : `on ${day.ordinal}${getOrdinalSuffix(day.ordinal)} ${daysOfWeek[day.day]}`,
      )
      .join(', ');
    return `${Types[routineType]} sent: ${days} ${
      settings.monthInterval === 1 ? 'every month' : `every ${settings.monthInterval} months`
    }`;
  } else if (settings.type === 'yearly') {
    const days = settings.yearDates.map((day) => moment(day.day).format('DD-MMMM'));
    if (settings.sendTheSameDay && settings.firstOccurence) {
      days.push(moment(settings.firstOccurence).format('DD-MMMM'));
    }
    const result = days.join(', ');

    return `${Types[routineType]} sent on ${result}`;
  } else if (settings.type === 'repeatAfterDays') {
    return `${Types[routineType]} sent every ${settings.daysInterval} days`;
  }

  return '';
}

export function PadNumber(paddingCount, number) {
  if (!paddingCount || !isNumber(paddingCount) || !isNumber(number)) return number;
  const repeat = paddingCount - Math.floor(Math.log10(number));
  if (repeat <= 0) return number;
  return `${'0'.repeat(repeat)}${number}`;
}

export function VisualiseSearch(string, pattern, highlightColor = yellow[500]) {
  if (!isString(string) || !string || !isString(pattern) || !pattern) return string;
  const index = string.search(new RegExp(pattern, 'i'));
  if (index === -1) return string;
  return (
    <>
      {string.substring(0, index)}
      <span style={{ background: highlightColor, borderRadius: 3 }}>
        {string.substring(index, index + pattern.length)}
      </span>
      {string.substring(index + pattern.length)}
    </>
  );
}

export function calculateStartAndEndDates(inputString) {
  let startDate, endDate;

  if (moment(inputString, 'MMM-YYYY', true).isValid()) {
    // Format: Jan-2024
    startDate = moment(inputString, 'MMM-YYYY').startOf('month');
    endDate = moment(inputString, 'MMM-YYYY').endOf('month');
  } else if (inputString.startsWith('Week')) {
    // Format: Week 27
    let weekNumber = parseInt(inputString.split(' ')[1]);
    startDate = moment().week(weekNumber).startOf('week');
    endDate = moment().week(weekNumber).endOf('week');
  } else if (moment(inputString, 'ddd DD-MMM', true).isValid()) {
    // Format: Mon 29-Jul
    startDate = moment(inputString, 'ddd DD-MMM');
    endDate = startDate.clone().endOf('day');
  } else {
    return {
      startDate: null,
      endDate: null,
    };
  }

  return {
    startDate: startDate.startOf('day').toDate(),
    endDate: endDate.endOf('day').toDate(),
  };
}

/**
 * Checks if string cantains html tags
 * @param {*} str
 * @returns
 */
export function ContainsHTMLTags(str) {
  if (!isString(str)) return false;

  const regex = /<\/?[a-z][\s\S]*>/i;
  return regex.test(str);
}

/**
 * Transforms html containing string to plain text. Optionally takes first n characters and adds ellipsis
 * @param {String} htmlString
 * @param {Number} firstN
 * @param {Boolean} addEllipsis
 * @returns
 */
export function HtmlToPlainText(htmlString, firstN, addEllipsis) {
  // Create a temporary DOM element
  const tempElement = document.createElement('div');

  // Set the HTML content
  tempElement.innerHTML = htmlString;

  // Retrieve the plain text
  const plainText = tempElement.textContent || tempElement.innerText || '';

  if (isNumber(firstN)) {
    // Take first n characters
    const substring = plainText.substring(0, firstN);

    // Add ellipsis if needed
    if (addEllipsis && plainText.length > firstN) {
      return `${substring}...`;
    }

    return substring;
  }

  return plainText;
}
