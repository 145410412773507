import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from 'axios';

export default function NCReader({ src, height = '75vh' }) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (!src) return;
    const fetch = async () => {
      // extract name
      const name = src.split('/').pop()?.split('?')[0];
      const response = await axios.post('/uploader/getFileContent', { name });
      setContent(response?.data?.content);
    };
    fetch();
  }, [src]);

  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        sx={{
          height,
          overflow: 'auto',
        }}
      >
        {content && <pre>{content}</pre>}
      </Box>
    </Container>
  );
}
