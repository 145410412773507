import React from 'react';
import { useState, useEffect, Suspense } from 'react';
import {
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
  Tooltip,
  Badge,
  DialogTitle,
  DialogActions,
  Switch,
  InputBase,
  Fab,
  Divider,
} from '@mui/material';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Button,
  DialogContent,
  Collapse,
} from '@mui/material';
import ChatListElement from './components/ChatListElement';
import ChatHeader from './components/ChatHeader';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import SelectUserDialog from '../Global/SelectUserDialog';
import Uploader from '../Global/Uploader';
import useChat from './useChat';
import Message from './components/Message';
import { v4 } from 'uuid';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import NewGroupChatDialog from './components/NewGroupChatDialog';
import 'emoji-mart/css/emoji-mart.css';
import ReplyIcon from '@mui/icons-material/Reply';
import ClearIcon from '@mui/icons-material/Clear';
import UserMap from './UserMap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { needToRequest, requestPermission, saveRejected } from './push';
import { RoundedDialog } from '../Global/Components';
import axios from 'axios';
import { styled, alpha } from '@mui/material/styles';
import { SearchRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 50,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => {
  return {
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
    },
  };
});

const Picker = React.lazy(() => import('emoji-mart').then((module) => ({ default: module.Picker })));

const userId = localStorage.getItem('_id');

export default function Chats({ open, onClose, selectedChat, selectedUser }) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [currMessage, setCurrMessage] = useState({ id: v4(), text: '', files: [] });
  const [isLive, setIsLive] = useState(localStorage.getItem('liveChat') === 'false' ? false : true);
  const [addMenu, setAddMenu] = useState();
  const [groupChatDialog, setGroupChatDialog] = useState(false);
  const [emoji, setEmoji] = useState(false);
  const [filesSection, setFilesSection] = useState(false);
  const [replyMessage, setReplyMessage] = useState(null);
  const [toBottom, setToBottom] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [image, setImage] = useState();
  // eslint-disable-next-line
  const [pattern, setPattern] = useState('');

  const [permissionsDialog, setPermissionsDialog] = useState(false);

  const MAX_MESSAGE_LENGTH = 2048;

  // selected chat
  const [currChat, setCurrChat] = useState({});

  const {
    loading,
    loadingChat,
    messages,
    partial,
    chats,
    newPrivateChat,
    newGroupChat,
    sendMessage,
    updateChat,
    loadMore,
    readChat,
    deleteMessage,
  } = useChat(currChat._id, open); // chat manager

  function onScroll(event) {
    setToBottom(event.target.scrollTop < -64);
    if (event.target.scrollHeight - event.target.offsetHeight + event.target.scrollTop <= 1) loadMore();
  }

  useEffect(() => {
    if (needToRequest()) setPermissionsDialog(true);
  }, []);

  useEffect(() => {
    if (currChat._id && chats) {
      let index = chats.findIndex((x) => x._id === currChat._id);
      if (index > -1) {
        setCurrChat({ ...chats[index] });
      } else {
        // means user left chat
        setCurrChat({});
      }
    }
  }, [chats]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedChat && typeof selectedChat === 'string') {
      let index = chats.findIndex((x) => x._id === selectedChat);
      if (index > -1) {
        setCurrChat({ ...chats[index] });
      }
    }
  }, [selectedChat]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedUser && selectedUser._id) {
      const chat = newPrivateChat(selectedUser);
      setCurrChat(chat);
    }
  }, [selectedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  function createNewPrivateChat(user) {
    setUserDialogOpen(false);
    if (user && user._id) {
      const chat = newPrivateChat(user);
      setCurrChat(chat);
    }
  }

  function createNewGroupChat(args) {
    setGroupChatDialog(false);
    if (args?.title) {
      newGroupChat(args).then((res) => setCurrChat(res));
    }
  }

  function sendFullMessage() {
    // prevent sending empty messages
    if ((!currMessage.text || !currMessage.text.trim()) && currMessage.files.length === 0 && !image) return;

    sendMessage({
      _id: currMessage.id,
      type: 'full',
      chatId: currChat._id,
      chatType: currChat.type,
      text: currMessage.text,
      sender: userId,
      receipts: [],
      attachments: image ? [...currMessage.files, image] : currMessage.files,
      reply: replyMessage,
    });
    currMessage.id = v4();
    currMessage.text = '';
    setCurrMessage({ id: v4(), text: '', files: [] });
    setEmoji(false);
    setFilesSection(false);
    setReplyMessage(null);
    setImage(null);
  }

  // for live messaging
  function sendPartialMessage(text) {
    if (currChat.type !== 'private') return;
    sendMessage({
      _id: currMessage.id,
      type: 'partial',
      chatId: currChat._id,
      to: currChat.participants.find((x) => x !== userId),
      sender: userId,
      text,
    });
  }

  // to remove a partial message if live messaging is cancelled
  function sendEmptyMessage() {
    if (currChat.type !== 'private') return;
    sendMessage({
      _id: currMessage.id,
      type: 'partial',
      chatId: currChat._id,
      to: currChat.participants.find((x) => x !== userId),
      sender: userId,
      text: '',
    });
  }

  function addFile(file) {
    currMessage.files.push(file.file);
  }

  function deleteFile(id) {
    currMessage.files = currMessage.files.filter((x) => x.id !== id);
  }

  function scrollToBottom() {
    const element = document.getElementById('chat-container');
    if (element) {
      element.scrollTop = 0;
      readChat();
    }
  }

  function uploadFile(file) {
    setUploadingImage(true);
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        const file = {
          id: v4(),
          name: res.data.file.originalname,
          originalname: res.data.file.originalname,
          location: res.data.file.location,
          size: res.data.file.size,
          type: res.data.file.mimetype,
          mimetype: res.data.file.mimetype,
          bucket: res.data.file.bucket,
        };
        setImage(file);
        setUploadingImage(false);
      })
      .catch((err) => {
        console.error(err);
        setUploadingImage(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullScreen={!largeDevices} maxWidth="md" fullWidth>
      <DialogContent style={{ padding: 0 }}>
        <Grid container justifyContent="center" style={{ height: largeDevices ? '85vh' : '100%', maxWidth: '100%' }}>
          {loading && <CircularProgress style={{ margin: 'auto' }} />}
          {(largeDevices || !Boolean(currChat._id)) && !loading && (
            <Grid container item xs={largeDevices ? 4 : 12} alignContent="flex-start">
              <Grid container item sx={{ p: 1 }} justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Search>
                  <SearchIconWrapper>
                    <SearchRounded />
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={pattern}
                      onChange={(e) => setPattern(e.target.value)}
                    />
                  </SearchIconWrapper>
                </Search>

                <IconButton onClick={(e) => setAddMenu(e.currentTarget)}>
                  <AddIcon />
                </IconButton>
                <Menu anchorEl={addMenu} keepMounted open={Boolean(addMenu)} onClose={() => setAddMenu(null)}>
                  <MenuItem
                    onClick={() => {
                      setAddMenu(null);
                      setUserDialogOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Private chat" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAddMenu(null);
                      setGroupChatDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Group chat" />
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid container item alignContent="flex-start">
                {chats.map((chat) => (
                  <ChatListElement
                    onSelect={() => {
                      setCurrChat(chat);
                      setReplyMessage(null);
                    }}
                    key={chat._id}
                    item={chat}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          {(largeDevices || Boolean(currChat._id)) && !loading && (
            <Grid container item xs={largeDevices ? 8 : 12} className={`scroll-bar`}>
              <Grid container item alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
                {currChat._id && (
                  <Grid item container direction="column" style={{ height: '100%' }}>
                    <header>
                      <ChatHeader
                        chat={currChat}
                        onBack={largeDevices ? null : () => setCurrChat({})}
                        onUpdate={updateChat}
                      />
                    </header>
                    <Grid
                      item
                      container
                      sx={{
                        position: 'relative',
                        flex: 1,
                      }}
                    >
                      <Grid
                        item
                        container
                        id="chat-container"
                        direction="column-reverse"
                        style={{ overflow: 'auto', height: '100%', position: 'absolute' }}
                        onScroll={onScroll}
                        onClick={() => {
                          if (emoji) setEmoji(false);
                        }}
                      >
                        <Grid container style={{ flex: 1, alignContent: 'baseline' }}>
                          {loadingChat && (
                            <Grid container item style={{ height: 100 }}>
                              <CircularProgress style={{ color: '#1e88e5', margin: 'auto' }} />
                            </Grid>
                          )}
                          {messages &&
                            messages.map((item) => (
                              <Message
                                chat={currChat}
                                message={item}
                                key={item._id}
                                clickable
                                onReply={() => setReplyMessage(item)}
                                onDelete={() => deleteMessage(currChat._id, item._id)}
                              />
                            ))}
                          {partial && partial.text && partial.chatId === currChat._id && (
                            <Message chat={currChat} message={partial} key={partial._id} />
                          )}
                        </Grid>
                      </Grid>
                      {toBottom && (
                        <Grid
                          item
                          sx={{
                            position: 'absolute',
                            bottom: 20,
                            right: 20,
                          }}
                        >
                          <Badge
                            badgeContent={currChat.unreads[userId]}
                            color="error"
                            invisible={!currChat.unreads[userId] || currChat.unreads[userId] === 0}
                          >
                            <Fab
                              size="small"
                              color="primary"
                              sx={{
                                boxShadow: (theme) => theme.shadows[10],
                              }}
                              onClick={scrollToBottom}
                            >
                              <ExpandMoreIcon />
                            </Fab>
                          </Badge>
                        </Grid>
                      )}
                    </Grid>

                    {currChat.participants.indexOf(userId) > -1 && (
                      <footer style={{ marginTop: 'auto' }}>
                        <Divider sx={{ width: '100%' }} />
                        <Collapse in={uploadingImage || Boolean(image)} style={{ width: '100%' }}>
                          <Grid
                            container
                            item
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ borderBottom: `1px solid ${grey[300]}` }}
                          >
                            {uploadingImage && (
                              <CircularProgress style={{ color: '#1e88e5', margin: largeDevices ? '3rem' : '1rem' }} />
                            )}
                            {!uploadingImage && image && (
                              <img alt={image.name} src={image.location} style={{ maxHeight: 100, margin: 5 }} />
                            )}
                            {!uploadingImage && (
                              <IconButton onClick={() => setImage(null)} style={{ height: 'fit-content', margin: 5 }}>
                                <ClearIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Collapse>

                        <Collapse in={Boolean(replyMessage)} style={{ width: '100%' }}>
                          {Boolean(replyMessage) && (
                            <Grid container item sx={{ borderBottom: `1px solid ${grey[300]}` }} alignItems="center">
                              <Grid item container xs={1} alignItems="center" justifyContent="center">
                                <ReplyIcon style={{ color: '#2196f3' }} />
                              </Grid>
                              <Grid item container xs={10} direction="column" sx={{ p: 1 }}>
                                <Grid item container>
                                  <Typography style={{ color: '#2196f3', fontWeight: 600 }}>
                                    {UserMap.resolve(replyMessage.sender)?.firstName}
                                  </Typography>
                                </Grid>
                                <Grid item container>
                                  <Typography noWrap style={{ maxWidth: 300 }}>
                                    {replyMessage.text}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item container xs={1} alignItems="center" justifyContent="center" sx={{ p: 1 }}>
                                <IconButton onClick={() => setReplyMessage(null)}>
                                  <ClearIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          )}
                        </Collapse>
                        <Grid container item sx={{ pb: 2 }} alignItems="center" wrap="nowrap">
                          {currChat.type === 'private' && (
                            <div style={{ marginTop: '10px' }}>
                              <Tooltip title="Send your message as you type" placement="top">
                                <Grid
                                  container
                                  item
                                  justifyContent="center"
                                  direction="column"
                                  style={{ width: 'fit-content' }}
                                >
                                  <Typography style={{ fontSize: 14, margin: 'auto' }}>Live</Typography>
                                  {largeDevices ? (
                                    <Switch
                                      checked={isLive}
                                      onChange={(event) => {
                                        setIsLive(event.target.checked);
                                        if (!event.target.checked && currMessage.text) sendEmptyMessage();
                                        localStorage.setItem('liveChat', event.target.checked);
                                      }}
                                    />
                                  ) : (
                                    <Switch
                                      checked={isLive}
                                      onChange={(event) => {
                                        setIsLive(event.target.checked);
                                        if (!event.target.checked && currMessage.text) sendEmptyMessage();
                                        localStorage.setItem('liveChat', event.target.checked);
                                      }}
                                    />
                                  )}
                                </Grid>
                              </Tooltip>
                            </div>
                          )}
                          {largeDevices && (
                            <Grid>
                              <IconButton size="small" onClick={() => setEmoji(!emoji)}>
                                <InsertEmoticonOutlinedIcon />
                              </IconButton>
                            </Grid>
                          )}
                          <Grid onClick={() => setFilesSection(!filesSection)}>
                            <IconButton size="small">
                              <AttachmentOutlinedIcon />
                            </IconButton>
                          </Grid>
                          <Grid sx={{ flexGrow: 1, ml: 1 }}>
                            <TextField
                              id="text-area"
                              fullWidth
                              placeholder="Write a message"
                              autoFocus
                              multiline
                              autoComplete="off"
                              maxRows={5}
                              value={currMessage.text}
                              inputProps={{ maxLength: MAX_MESSAGE_LENGTH }}
                              onChange={(event) => {
                                const { value } = event.target;
                                currMessage.text = value;
                                setCurrMessage({ ...currMessage });
                                if (isLive) sendPartialMessage(value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  sendFullMessage();
                                }
                                if (e.shiftKey && e.key === 'Enter') {
                                  e.preventDefault();
                                  currMessage.text = `${currMessage.text}\n`;
                                  setCurrMessage({ ...currMessage });
                                }
                              }}
                              onPaste={(e) => {
                                if (
                                  !uploadingImage &&
                                  e.clipboardData.files[0] &&
                                  e.clipboardData.files[0].type.startsWith('image/')
                                ) {
                                  uploadFile(e.clipboardData.files[0]);
                                }
                              }}
                              variant="standard"
                            />
                          </Grid>
                          <IconButton size="small" onClick={sendFullMessage} sx={{ ml: 1 }}>
                            <SendIcon />
                          </IconButton>
                        </Grid>
                        <Collapse in={emoji} style={{ width: '100%' }}>
                          <Grid container item>
                            <Suspense
                              fallback={
                                <Typography variant="h6" color="textSecondary" style={{ margin: 8 }}>
                                  Loading...
                                </Typography>
                              }
                            >
                              <Picker
                                onSelect={(e) => {
                                  currMessage.text += e.native;
                                  setCurrMessage({ ...currMessage });
                                }}
                                style={{ width: '100%', borderRadius: 0 }}
                                title=":)"
                                native
                              />
                            </Suspense>
                          </Grid>
                        </Collapse>
                        <Collapse in={filesSection} style={{ width: '100%' }}>
                          <Grid container item style={{ height: '20vh', overflow: 'auto', padding: '0.5rem' }}>
                            <Uploader onResult={addFile} onCancel={deleteFile} open={filesSection} />
                          </Grid>
                        </Collapse>
                      </footer>
                    )}
                  </Grid>
                )}
                {!currChat._id && (
                  <Typography variant="h6" align="center">
                    Select a chat or create a new one to start messaging
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <SelectUserDialog open={userDialogOpen} onClose={createNewPrivateChat} excluded={userId} />
        <NewGroupChatDialog open={groupChatDialog} onClose={createNewGroupChat} />

        <RoundedDialog open={permissionsDialog} maxWidth="xs" fullWidth>
          <DialogTitle>Do you want to receive push notifications?</DialogTitle>
          <DialogActions>
            <Button
              style={{ color: '#d81b60' }}
              onClick={() => {
                setPermissionsDialog(false);
                saveRejected();
              }}
            >
              No
            </Button>
            <Button
              style={{ color: '#1e88e5' }}
              onClick={() => {
                setPermissionsDialog(false);
                requestPermission();
              }}
            >
              Yes, sure
            </Button>
          </DialogActions>
        </RoundedDialog>
      </DialogContent>
      {!largeDevices && (
        <DialogActions style={{ borderTop: '1px solid #e0e0e0' }}>
          <Button style={{ color: '#1e88e5' }} onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      )}
    </RoundedDialog>
  );
}
