import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertBox({ open, handleClose, duration = 2000, message, type = 'success', action, ...props }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      {...props}
    >
      <div>
        <Alert onClose={handleClose} severity={type} style={{ alignItems: 'center' }}>
          {message} {action}
        </Alert>
      </div>
    </Snackbar>
  );
}
