import React, { useState, useEffect, useRef } from 'react';
import { RoundedDialog, CrossButton, HtmlContent, GeneralButton } from '../../Global/Components';
import FileViewer from '../../Global/FileViewer';
import Uploader from '../../Global/Uploader';
import { Action } from '../../Global/Fields/ActionGroup';
import {
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  Chip,
  Avatar,
  DialogActions,
  Button,
  Paper,
  Switch,
  Tooltip,
} from '@mui/material';
import { CancelOutlined, HelpOutline, WorkOutlineRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { StatusButton, PriorityButton } from '../../Hubs/tasks/Components';
import { amber, indigo, green, deepPurple, blue } from '@mui/material/colors';
import { FormatDate } from '../../Global/Functions';
import CommentsSection from '../../Global/CommentsSection';
import ConfirmDialog from '../../Global/ConfirmDialog';
import axios from 'axios';
import moment from 'moment';
import StateManager from '../../Global/StateManager';
import { typeColors } from '../constants';

const statuses = [
  { text: 'Sent', color: grey[400] },
  { text: 'Being reviewed', color: blue[400] },
  { text: 'Responded', color: indigo[400] },
  { text: 'Awaiting feedback', color: amber[400] },
  { text: 'Actioned', color: blue[400] },
  { text: 'Completed', color: green[400] },
  { text: 'Canceled', color: red[400] },
  { text: 'Not Started', color: grey[100] }, // shoulld be deprecated with new statuses
  { text: 'Started', color: deepPurple[100] },
  { text: 'Working', color: blue[100] },
  { text: 'Held', color: amber[100] },
  { text: 'Complete', color: green[100] },
];

export default function TicketDialog({
  ticket,
  open,
  onClose,
  fromAdmin = false,
  fromCompanyAdmin = false,
  onSave,
  loading,
}) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [disabled, setDisabled] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [saving, setSaving] = useState('');
  const [status, setStatus] = useState();
  const [priority, setPriority] = useState();
  const [description, setDescription] = useState('');
  const [reproduction, setReproduction] = useState('');
  const [task, setTask] = useState('');
  const [taskShow, setTaskShow] = useState(false);
  const [response, setResponse] = useState();
  const files = useRef([]);
  const [responseAttachments, setResponseAttachments] = useState([]);
  const [comments, setComments] = useState([]);
  const filesTimer = useRef();
  useEffect(() => {
    if (ticket && ticket._id) {
      const dueAt = !fromAdmin ? '' : ticket?.task?.dueAt;
      const assignedTo = !fromAdmin ? '' : ticket?.task?.assignedTo;
      setDisabled(ticket.canceledAt || ticket.archivedAt);
      setResponse(ticket.response || '');
      setResponseAttachments(ticket.responseAttachments || []);
      setStatus(ticket.status || '');
      setPriority(ticket.priority || 'None');
      setDescription(ticket.description || '');
      setReproduction(ticket.reproduction || '');
      setComments(ticket.comments || '');
      setTaskShow(ticket.showTaskToUser || false);
      setTask({ ...ticket.task, type: 'task', dueAt, assignedTo } ?? {});
      setSaving('');
      files.current = [];
    }
  }, [ticket, fromAdmin]);

  function save(update) {
    setSaving('Saving...');
    axios
      .post('/support/saveTicket', { ticketId: ticket._id, update })
      .then((res) => {
        setSaving('Saved');
        onSave(res.data);
      })
      .catch((err) => {
        console.error(err);
        setSaving('Failed to save the ticket');
      });
  }
  function saveDescription(value) {
    save({
      field: 'description',
      value,
    });
    setDescription(value);
  }

  function saveReproduction(value) {
    save({
      field: 'reproduction',
      value,
    });
    setReproduction(value);
  }
  function savePriority(value) {
    save({
      field: 'priority',
      value,
    });
    setPriority(value);
  }

  function saveStatus(value) {
    save({
      field: 'status',
      value,
    });
    setStatus(value);
  }

  function saveResponse(value) {
    save({
      field: 'response',
      value,
    });
    setResponse(value);
  }

  function saveResponseAttachment(file) {
    files.current.push(file.file);
    if (filesTimer.current) clearTimeout(filesTimer.current);

    filesTimer.current = setTimeout(() => saveFiles(), 1500);
  }

  function saveFiles() {
    save({
      field: 'responseAttachments',
      action: 'add',
      value: files.current,
    });
  }

  function deleteResponseAttachment(id) {
    save({
      field: 'responseAttachments',
      action: 'delete',
      value: id,
    });
    files.current = files.current.filter((f) => f.id !== id);
  }

  function deleteComment(id) {
    save({
      field: 'comments',
      action: 'delete',
      value: id,
    });
    setComments([...comments.filter((x) => x.id !== id)]);
  }

  function saveComment(comment) {
    save({
      field: 'comments',
      action: 'add',
      value: comment,
    });
    setComments([...comments, comment]);
  }

  function saveTaskShow(event) {
    save({
      field: 'showTaskToUser',
      value: event.target.checked,
    });
    setTaskShow(event.target.checked);
  }

  function saveTask(task) {
    save({
      field: 'task',
      value: {
        _id: task._id,
        title: task.title,
        dueAt: task.dueAt,
        progress: task.progress,
        assignedTo: task.assignedTo,
      },
    });
    setTask({ ...task, type: 'task' });
    onSave({ ...ticket, task, QHubTaskStatus: task.progress });
  }

  function cancelTicket() {
    axios
      .post('/support/cancelTicket', { ticketId: ticket._id })
      .then(() => {
        StateManager.setSuccessAlert('Ticket has been cancelled');
        onSave({ ...ticket, status: 'Canceled', canceledAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ') });
        onClose({ canceled: true });
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to cancel the ticket');
      });
  }
  if (!ticket) return null;
  return (
    <RoundedDialog
      open={open}
      maxWidth="lg"
      fullWidth
      maxHeight="90vh"
      onClose={() => onClose(null)}
      className="scroll-bar"
      fullScreen={!largeDevices}
    >
      {loading ? (
        <Grid container height={'90vh'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <DialogTitle>
            <Grid container alignItems="center">
              <HelpOutline style={{ marginRight: '1rem', color: grey[600] }} />
              <Typography variant="h6">Support ticket</Typography>
              <Typography style={{ marginLeft: '2rem', fontSize: 14, color: grey[500] }}>{saving}</Typography>
              {ticket.closedAt && (
                <Typography variant="h6" style={{ color: '#009624' }}>{`Closed ${FormatDate(
                  ticket.closedAt,
                )}`}</Typography>
              )}
              {ticket.canceledAt && (
                <Typography variant="h6" style={{ color: red[500] }}>{`Cancelled ${FormatDate(
                  ticket.canceledAt,
                )}`}</Typography>
              )}

              <Grid style={{ marginLeft: 'auto' }}>
                <CrossButton onClick={() => onClose(null)} />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container minWidth={'100%'} direction={'column'} gap={2}>
              <Tooltip title={ticket.title}>
                <Paper variant="outlined" sx={{ width: '100%' }}>
                  <Typography
                    variant="body1"
                    alignItems={'center'}
                    width={'100%'}
                    sx={{
                      padding: theme.spacing(1, 1),
                      fontSize: 19,
                      height: '4em',
                      wordBreak: 'break-word',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      lineHeight: 1.7,
                    }}
                  >
                    {`${ticket.title}`}
                  </Typography>
                </Paper>
                <Typography fontSize={10} color={grey[400]}>{`Created ${FormatDate(ticket.createdAt)}`}</Typography>
              </Tooltip>
              <Paper variant="outlined" sx={{ alignContent: 'center', padding: theme.spacing(0, 0) }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  sx={{
                    '@media (max-width: 1024px)': {
                      flexWrap: 'wrap',
                    },
                  }}
                >
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="center"
                    direction="row"
                    lg={3}
                    md={6}
                    sm={12}
                    sx={{ margin: theme.spacing(1, 0) }}
                  >
                    <Typography style={{ fontSize: 14, fontWeight: 700, marginRight: theme.spacing(2) }}>
                      Priority
                    </Typography>
                    <PriorityButton
                      priority={priority}
                      setPriority={savePriority}
                      sx={{ fontSize: 14 }}
                      disabled={disabled || (!fromAdmin && !fromCompanyAdmin)}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    alignItems="center"
                    gap={1}
                    justifyContent="center"
                    direction="row"
                    lg={3}
                    md={6}
                    sm={12}
                    sx={{ margin: theme.spacing(1, 0) }}
                  >
                    <Grid container item direction={'row'} flexWrap={'nowrap'}>
                      <Typography fontSize={14} style={{ fontWeight: 700, marginRight: theme.spacing(2) }}>
                        Category
                      </Typography>
                      <Chip
                        label={ticket.type}
                        size="small"
                        style={{
                          background: typeColors[ticket.type],
                          color: theme.palette.getContrastText(typeColors[ticket.type]),
                        }}
                      />
                      {ticket.improvementType && <Chip label={ticket.improvementType} size="small" />}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="center"
                    direction="row"
                    lg={3}
                    md={6}
                    sm={12}
                    sx={{ margin: theme.spacing(1, 0) }}
                  >
                    <Typography style={{ fontWeight: 700, marginRight: theme.spacing(2) }}>Hub</Typography>
                    <Typography>{ticket.hub}</Typography>
                  </Grid>

                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="center"
                    direction="row"
                    lg={3}
                    md={6}
                    sm={12}
                    sx={{ margin: theme.spacing(1, 0) }}
                  >
                    <Typography style={{ fontWeight: 700 }}>Ticket Status:</Typography>
                    <StatusButton
                      status={status}
                      setStatus={saveStatus}
                      projectStatuses={statuses}
                      isDisabled={!fromAdmin || disabled}
                      style={{ marginLeft: theme.spacing(1) }}
                    />
                  </Grid>
                </Grid>
              </Paper>
              {fromAdmin && (
                <>
                  <Typography component="h3">Your info:</Typography>
                  <Paper variant="outlined" sx={{ alignContent: 'center' }}>
                    <Grid
                      container
                      item
                      direction="row"
                      justifyContent="space-around"
                      sx={{
                        '@media (max-width: 1024px)': {
                          flexWrap: 'wrap',
                        },
                      }}
                    >
                      <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        sx={{ margin: theme.spacing(1, 0) }}
                        lg={3}
                        md={6}
                        sm={12}
                      >
                        <Typography style={{ fontWeight: 700 }}>Company:</Typography>
                        <Typography style={{ marginLeft: theme.spacing(2) }}>{ticket.companyName}</Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        sx={{ margin: theme.spacing(1, 0) }}
                        lg={3}
                        md={6}
                        sm={12}
                      >
                        <Typography style={{ fontWeight: 700 }}>User:</Typography>
                        <Avatar style={{ margin: theme.spacing(0, 2) }} src={ticket?.user?.avatar}>
                          {ticket.user?.avatarLetters}
                        </Avatar>
                        <Typography>{ticket.user?.fullName}</Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        sx={{ margin: theme.spacing(1, 0) }}
                        lg={3}
                        md={6}
                        sm={12}
                      >
                        <Typography style={{ fontWeight: 700 }}>Email:</Typography>
                        <Typography style={{ marginLeft: theme.spacing(2) }}>{ticket.user?.email}</Typography>
                      </Grid>
                      {ticket.user?.phone && (
                        <Grid
                          container
                          item
                          alignItems="center"
                          justifyContent="center"
                          sx={{ margin: theme.spacing(1, 0) }}
                          lg={3}
                          md={6}
                          sm={12}
                        >
                          <Typography style={{ fontWeight: 700 }}>Phone:</Typography>
                          <Typography style={{ marginLeft: theme.spacing(2) }}>{ticket.user?.phone}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </>
              )}
              <Typography component="h3">Ticket Details:</Typography>
              <Grid container item>
                <HtmlContent
                  editable={!disabled && (fromAdmin || fromCompanyAdmin) && !ticket.closedAt}
                  outlined
                  title="Description"
                  content={description}
                  onEdit={setDescription}
                  onSave={saveDescription}
                />
              </Grid>
              {reproduction && (
                <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                  <HtmlContent
                    editable={!disabled && (fromAdmin || fromCompanyAdmin) && !ticket.closedAt}
                    outlined
                    title="Steps to reproduce"
                    content={reproduction}
                    onEdit={setReproduction}
                    onSave={saveReproduction}
                  />
                </Grid>
              )}
              {ticket.attachments && ticket.attachments.length > 0 && (
                <Grid container item style={{ margin: theme.spacing(3, 0) }}>
                  <FileViewer files={ticket.attachments} />
                </Grid>
              )}
              {(fromAdmin || ticket.showTaskToUser) && (
                <>
                  <Typography component="h3">Linked Task</Typography>
                  <Paper variant="outlined" sx={{ width: '100%', padding: theme.spacing(1, 1) }}>
                    <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                      {fromAdmin && (
                        <>
                          <Grid container item direction="row" alignItems="center">
                            <Typography variant="span">Show Task to User</Typography>
                            <Switch checked={taskShow} onChange={saveTaskShow} />
                          </Grid>
                        </>
                      )}
                      {!task.title && fromAdmin && (
                        <Paper sx={{ width: '100%', height: 'fit-content' }}>
                          <Grid container item justifyContent={'center'} sx={{ width: '100%' }}>
                            <Button
                              onClick={() =>
                                StateManager.setNewTask({ referenceData: { ticketId: ticket.id } }, saveTask)
                              }
                              fullWidth
                              startIcon={<WorkOutlineRounded />}
                            >
                              Create a task
                            </Button>
                          </Grid>
                        </Paper>
                      )}
                      {task.title && (
                        <>
                          <Action
                            initial={task}
                            disabledAssignedUsers={!fromAdmin}
                            disabled={!fromAdmin}
                            onSave={saveTask}
                          />
                        </>
                      )}
                    </Grid>
                  </Paper>
                </>
              )}
              {(fromAdmin || response) && (
                <Grid container item style={{ margin: theme.spacing(3, 0) }}>
                  <HtmlContent
                    editable={fromAdmin && !disabled}
                    outlined
                    title="Response"
                    content={response}
                    onSave={saveResponse}
                  />
                </Grid>
              )}
              {fromAdmin && !disabled && (
                <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                  <Uploader onResult={saveResponseAttachment} onCancel={deleteResponseAttachment} />
                </Grid>
              )}
              {ticket.responseAttachments && ticket.responseAttachments.length > 0 && (
                <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                  <FileViewer
                    title="Response attachments"
                    files={responseAttachments}
                    onDelete={disabled ? null : deleteResponseAttachment}
                  />
                </Grid>
              )}
              <Paper variant="outlined" sx={{ width: '100%', padding: theme.spacing(1, 1) }}>
                <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                  <CommentsSection
                    comments={comments}
                    deleteComment={deleteComment}
                    addComment={saveComment}
                    onlyUserName
                    disabled={disabled}
                  />
                </Grid>
              </Paper>
            </Grid>
          </DialogContent>
          {!ticket.closedAt && !disabled && (
            <DialogActions>
              <Grid container>
                <Button
                  style={{ color: red[500] }}
                  onClick={() => setCancelOpen(true)}
                  startIcon={<CancelOutlined style={{ color: red[500] }} />}
                >
                  cancel ticket
                </Button>
              </Grid>
            </DialogActions>
          )}
          <ConfirmDialog
            open={cancelOpen}
            onClose={() => setCancelOpen(false)}
            title="You are about to cancel this ticket"
            content="Are you sure you want to continue?"
            onConfirm={cancelTicket}
          />
        </>
      )}
    </RoundedDialog>
  );
}
