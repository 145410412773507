import React from 'react';
import { Button } from '@mui/material';

export default function CancelButton({ onClick, text = 'Cancel' }) {
  return (
    <Button
      onClick={onClick}
      style={{
        fontSize: 16,
        margin: '10px',
        textTransform: 'none',
        padding: '0.2rem 1rem',
        borderRadius: '0.5rem',
        border: '2px solid lightgray',
      }}
    >
      {text}
    </Button>
  );
}
