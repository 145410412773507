import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { previewFile } from '../../redux/actions/file';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { NormalButton } from './';
import { blue, green, red } from '@mui/material/colors';
import {
  CircularProgress,
  List,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
} from '@mui/material';
import FileIconAvatar from '../FileIconAvatar';
import DescriptionIcon from '@mui/icons-material/Description';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dropzone from '../Dropzone';
import { formatRevision, formatDate } from '../../constants';
import User from '../../../../Global/Components/User';
import { RoundedDialog, TooltipTypography } from '../../../../Global/Components';

function FileUploadDialog({
  open,
  onClose,
  onDone,
  fileHistory,
  baseVersion,
  revisionFormat,
  encrypted,
  manualIssueControl,
  manualIssue,
}) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleDone = () => {
    onDone(uploadedFile);
  };

  useEffect(() => {
    if (!open) return;
    setSelectedFile(null);
    setUploadedFile(null);
    setUploading(false);
    setProgress(0);
  }, [open]);

  const handleFilePreview = (selected) => {
    dispatch(previewFile({ ...selected, category: 'normal', file: selected, encrypted }));
  };

  return (
    <RoundedDialog
      open={open}
      onClose={onClose}
      titleId="upload-dialog-issue"
      maxWidth={fileHistory && fileHistory.length > 0 ? 'md' : 'sm'}
      fullWidth
    >
      <DialogContent dividers>
        <Grid container direction="column" justifyContent="center">
          <Grid item sm>
            <Dropzone
              onFileDrop={setSelectedFile}
              onFileUpload={setUploadedFile}
              setUploading={setUploading}
              onProgress={setProgress}
              encrypted={encrypted}
            />
          </Grid>
          {selectedFile && (
            <Grid item sm sx={{ margin: '15px 0px' }}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <FileIconAvatar
                      document={{
                        category: 'normal',
                        file: {
                          ...selectedFile,
                          mimetype: selectedFile.mimetype ? selectedFile.mimetype : selectedFile.type,
                        },
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText sx={{ textOverflow: 'ellipsis', width: '90%' }}>{selectedFile.name}</ListItemText>
                  <ListItemSecondaryAction sx={{ alignItems: 'center', verticalAlign: 'middle' }}>
                    {uploading && (
                      <CircularProgress variant="determinate" value={progress} size={20} sx={{ margin: '0px 10px' }} />
                    )}
                    {uploadedFile && (
                      <VisibilityIcon
                        sx={{ color: blue[500], marginRight: '15px', cursor: 'pointer' }}
                        onClick={() => handleFilePreview(uploadedFile)}
                        disabled={!uploadedFile || !uploadedFile.location || uploading}
                      />
                    )}
                    <CancelIcon
                      onClick={() => {
                        setSelectedFile(null);
                        setUploadedFile(null);
                      }}
                      sx={{ color: red[500], cursor: 'pointer' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          )}
          {fileHistory && fileHistory.length > 0 && (
            <Grid item sm sx={{ margin: '15px 0px' }}>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600 }}>
                Recent Uploads
              </Typography>
              <TableContainer sx={{ margin: (theme) => theme.spacing(1, 0) }}>
                <Table aria-label="file history table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'primary.main', fontWeight: 600 }}>Version</TableCell>
                      <TableCell sx={{ color: 'primary.main', fontWeight: 600 }}>Details</TableCell>
                      <TableCell align="center" sx={{ color: 'primary.main', fontWeight: 600 }}>
                        Uploaded By
                      </TableCell>
                      <TableCell align="center" sx={{ color: 'primary.main', fontWeight: 600 }}>
                        Uploaded At
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fileHistory.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell align="left">
                          {formatRevision(
                            [baseVersion, f.version ? f.version : 1].join('.'),
                            revisionFormat,
                            manualIssueControl,
                            manualIssue,
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                            <FileIconAvatar document={{ category: 'normal', file: f }} />
                            <TooltipTypography sx={{ marginLeft: '5px', width: '300px' }}>
                              {f.name ? f.name : f.originalname}
                            </TooltipTypography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <User id={f.uploadedBy} avatarSize={20} />
                        </TableCell>
                        <TableCell align="center">{formatDate(f.uploadedAt, 'DD/MM/YYYY hh:mm a')}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleFilePreview(f)}>
                            <DescriptionIcon sx={{ color: blue[500] }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Grid item sm={2}>
          <NormalButton
            onClick={onClose}
            label="Cancel"
            labelColor={red[500]}
            activeLabelColor={red[500]}
            borderColor={red[500]}
            activeBorderColor={red[500]}
          />
        </Grid>
        <Grid item sm={2} align="end">
          <NormalButton
            onClick={handleDone}
            label="Confirm"
            labelColor={selectedFile ? '#fff' : green[500]}
            activeLabelColor={selectedFile ? '#fff' : green[800]}
            borderColor={green[500]}
            activeBorderColor={green[500]}
            backgroundColor={selectedFile ? green[500] : '#fff'}
            disabled={!selectedFile || uploading}
          />
        </Grid>
      </DialogActions>
    </RoundedDialog>
  );
}

export default FileUploadDialog;
