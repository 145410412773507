const sortableTypes = [
  'number',
  'stock',
  'calculation',
  'text',
  'date',
  'time',
  'datetime',
  'textArea',
  'phone',
  'email',
  'status',
  'weightedList',
  'dropbox',
  'conditional',
  'tickbox',
  'file',
  'image',
  'dataSet',
  'people',
];

const filterableTypes = [
  'number',
  'stock',
  'calculation',
  'text',
  'date',
  'datetime',
  'textArea',
  'phone',
  'email',
  'status',
  'weightedList',
  'dropbox',
  'conditional',
  'tickbox',
  'file',
  'image',
  'dataSet',
  'people',
  'user',
];

const calculatableTypes = [
  'number',
  'calculation',
  'stock',
  'user',
  'dropbox',
  'tickbox',
  'status',
  'weightedList',
  'dataSet',
];

export function isTypeCalculatable(type) {
  return calculatableTypes.includes(type);
}

export function isColumnSortable(fieldType) {
  return sortableTypes.includes(fieldType);
}

export function isColumnFilterable(fieldType) {
  return filterableTypes.includes(fieldType);
}

export function areTypesCompatible(oldType, newType) {
  if (oldType === newType || !oldType) return 'fully';

  // TODO: datasets
  // what is convertable to text
  const convertableToTextTypes = [
    'number',
    'calculation',
    'text',
    'textArea',
    'email',
    'phone',
    'date',
    'datetime',
    'time',
    'dropbox',
    'tickbox',
    'weightedList',
    'status',
    'user',
  ];

  // conver to text what's convertable
  if (['text', 'textArea'].includes(newType) && convertableToTextTypes.includes(oldType)) {
    return 'fully';
  }

  // for phone and email might be compatible
  if (['phone', 'email'].includes(newType)) {
    if (!['text', 'textArea'].includes(oldType)) return 'none';
    return 'partly';
  }

  // the same for number

  if (newType === 'number') {
    if (!['text', 'textArea', 'calculation'].includes(oldType)) return 'none';
    return 'partly';
  }

  // and date/time
  if (['date', 'time', 'datetime'].includes(newType)) {
    if (['datetime'].includes(oldType)) return 'fully';
    if (!['text', 'textArea'].includes(oldType)) return 'none';
    return 'partly';
  }

  if (
    (['file', 'image'].includes(newType) && !['file', 'image'].includes(oldType)) ||
    (newType === 'user' && oldType !== 'user') ||
    (newType === 'dataSet' && oldType !== 'dataSet') ||
    (newType === 'signature' && oldType !== 'signature') ||
    (newType === 'calculation' && oldType !== 'calculation') ||
    (newType === 'action' && oldType !== 'action') ||
    (newType === 'actionGroup' && oldType !== 'actionGroup')
  ) {
    return 'none';
  }

  if (
    ['status', 'dropbox', 'tickbox', 'weightedList'].includes(newType) &&
    ['status', 'dropbox', 'weightedList', 'text'].includes(oldType)
  ) {
    return 'fully';
  }

  if (
    ['status', 'dropbox', 'tickbox', 'weightedList'].includes(newType) &&
    !['status', 'dropbox', 'tickbox', 'text', 'textArea', 'weightedList'].includes(oldType)
  ) {
    return 'none';
  }

  return 'partly';
}
