import React, { useEffect, useState } from 'react';
import { FieldBuilder } from '../../../Global/Fields';
import { isArray } from 'lodash';

export default function Fields({ onItemsChange, initial, hideForms, disabled, forPortal }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(isArray(initial) ? initial : []);
  }, [initial]);

  return (
    <FieldBuilder
      initial={items}
      onChange={(items) => {
        setItems(items);
        onItemsChange(items);
      }}
      text
      forPortal={forPortal}
      form={!forPortal && !hideForms}
      ru={!forPortal}
      withGlobal={!forPortal}
      disabled={disabled}
      process
      info
    />
  );
}
