import React, { useState, useEffect, useMemo } from 'react';
import { Button, Typography, Avatar, Grid, IconButton, Collapse, CircularProgress } from '@mui/material';
import { ListItemAvatar, ListItemText, Paper, ListItemButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { DeleteOutlineRounded, AddCommentOutlined, Clear, SendRounded, AttachFileRounded } from '@mui/icons-material';
import { ReplyRounded, ModeCommentRounded, ArrowDropDownRounded, HighlightOffRounded } from '@mui/icons-material';

import { MentionsInput, Mention } from 'react-mentions';
import User from '../Components/User';
import { v4 } from 'uuid';

import { grey } from '@mui/material/colors';
import { FormatDate, isHex24 } from '../Functions';
import axios from 'axios';
import { RoundedDialog } from '../Components';
import UserMention from '../Components/UserMention';
import { green, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import StateManager from '../StateManager';
import { isArray, isEmpty, isFunction } from 'lodash';
import { useSelector } from 'react-redux';
import { darken } from '@mui/material/styles';

const userId = localStorage.getItem('_id');

const StyledMentionsInput = styled(MentionsInput)(({ theme }) => ({
  '& div[class*="suggestions"]': {
    boxShadow: theme.shadows[12],
    borderRadius: 8,
  },
  '& ul': {
    borderRadius: 8,
    background: theme.palette.background.paper,
  },
  '& textarea': {
    border: 'none',
    borderRadius: 0.5,
    outline: 0,
    color: theme.palette.text.primary,
  },
}));

export default function CommentsSection({
  comments,
  disabled = false,
  deleteComment,
  addComment,
  title = 'Comments',
  type = 'comment',
  newCommentText,
  onlyUserName = false,
  sendMentions,
  mentionType,
  mentionTitle,
  mentionLink,
  onAddingCommentChange,
  buttonText = 'Add a comment',
  onCommentEdit,
  withStatus,
  statuses,
  withCancel,
  onCancel,
  openCommentBox,
  buttonVariant = 'outlined',
}) {
  const [addingComment, setAddingComment] = useState(false || openCommentBox);

  function sendMentionNotification(to) {
    if (!sendMentions || !mentionType || !mentionTitle || !mentionLink) return;
    const body = {
      to,
      link: mentionLink,
      type: mentionType,
      title: mentionTitle,
    };
    axios.post('/notifications/sendMentionNotification', body).catch(console.error);
  }

  function onAddCommentClick() {
    setAddingComment(true);
    if (typeof onAddingCommentChange === 'function') {
      onAddingCommentChange(true);
    }
  }

  const notNested = useMemo(
    () => (isArray(comments) && !isEmpty(comments) ? comments.filter((x) => !x.parentId) : []),
    [comments],
  );

  return (
    <Grid container sx={{ pt: 2 }}>
      {comments[0] && title && (
        <Grid item container>
          <Typography gutterBottom noWrap>
            {title}
          </Typography>
        </Grid>
      )}

      {notNested.map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          allComments={comments}
          deleteComment={deleteComment}
          disabled={disabled}
          onlyUserName={onlyUserName}
          sendMentionNotification={sendMentionNotification}
          onReply={addComment}
          onCommentEdit={onCommentEdit}
          withStatus={withStatus}
          statuses={statuses}
          withCancel={withCancel}
          onCancel={onCancel}
          type={type}
        />
      ))}

      {addingComment && (
        <NewComment
          newCommentText={newCommentText}
          onResult={addComment}
          onClose={() => setAddingComment(false)}
          onAddingCommentChange={onAddingCommentChange}
          sendMentionNotification={sendMentionNotification}
        />
      )}
      {!disabled && !addingComment && (
        <Grid item container alignItems={'center'} justifyContent="flex-end" sx={{ padding: '10px 0px' }}>
          <Button variant={buttonVariant} startIcon={<AddCommentOutlined />} onClick={onAddCommentClick}>
            {buttonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export function Comment({
  comment,
  allComments,
  deleteComment,
  disabled,
  onlyUserName,
  dense,
  onReply,
  sendMentionNotification,
  onCommentEdit,
  withStatus,
  statuses,
  withCancel,
  onCancel,
  type,
}) {
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [replying, setReplying] = useState(false);
  const [selectedStatusId, setSelectedStatusId] = useState();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const { user } = useSelector(({ profile }) => profile);

  useEffect(() => {
    setSelectedStatusId(comment?.selectedStatusId || null);
  }, [comment]);

  const splited = useMemo(() => {
    const text = comment?.text || comment?.note;
    if (!text) return '';
    return String(text)
      .replace(/\@\[.+?\]\(([0-9a-f]{24})\)/g, '$1')
      .split(/([0-9a-f]{24})/g)
      .map((x, i) => (isHex24(x) ? <UserMention key={i} id={x} /> : x));
  }, [comment]);

  function confirmDelete() {
    StateManager.setConfirm(`You are about to delete this ${type}`, () => deleteComment(comment.id));
  }

  function confirmCancel() {
    StateManager.setConfirm(`You are about to cancel this ${type}`, () => onCancel(comment.id));
  }

  const children = useMemo(
    () => (isArray(allComments) ? allComments.filter((x) => x.parentId === comment.id) : []),
    [allComments, comment],
  );

  const hasStatuses = useMemo(
    () => withStatus && isArray(statuses) && !isEmpty(statuses) && statuses.every((x) => x.id && x.title),
    [withStatus, statuses],
  );

  const selectedStatus = hasStatuses && selectedStatusId ? statuses.find((x) => x.id === selectedStatusId) : null;

  return (
    <Grid item container>
      {comment.cancelled && (
        <Grid item container sx={{ py: 1 }} alignItems={'center'}>
          <Typography variant="subtitle1" color="textSecondary">
            <span style={{ textTransform: 'capitalize' }}>{type}</span> is cancelled
          </Typography>
          <Button variant="outlined" size="small" sx={{ ml: 2 }} onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Hide' : 'Show'}
          </Button>
        </Grid>
      )}
      <Grid item container component={Collapse} in={!comment.cancelled || expanded}>
        <Grid
          item
          container
          sx={{ my: 1, border: comment.cancelled ? '2px red solid' : undefined }}
          id={comment.id}
          component={Paper}
          variant="outlined"
        >
          <Grid item container alignItems="center" style={{ padding: '0.3rem' }}>
            <Grid item>
              {comment.createdBy && !onlyUserName ? (
                <User id={comment.createdBy} avatarSize={dense ? 28 : 40} />
              ) : (
                <Typography style={{ fontWeight: 700, marginLeft: '1rem' }}>
                  {comment.creatorName || comment.name}
                </Typography>
              )}
            </Grid>

            <div style={{ marginLeft: 'auto' }} />

            {!disabled && (
              <>
                {onReply && (
                  <IconButton onClick={() => setReplying(true)}>
                    <ReplyRounded style={{ color: grey[500] }} />
                  </IconButton>
                )}

                {deleteComment && (user?._id === comment.createdBy || user?.access === 'admin') && (
                  <Tooltip title={`Delete ${type}`}>
                    <IconButton onClick={confirmDelete}>
                      <DeleteOutlineRounded style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                )}

                {withCancel &&
                  isFunction(onCancel) &&
                  (user?._id === comment.createdBy || user?.access === 'admin') &&
                  !comment.cancelled && (
                    <Tooltip title={`Cancel ${type}`}>
                      <IconButton onClick={confirmCancel}>
                        <HighlightOffRounded style={{ color: red[500] }} />
                      </IconButton>
                    </Tooltip>
                  )}
              </>
            )}

            {hasStatuses && (
              <>
                <Button
                  startIcon={<ArrowDropDownRounded />}
                  onClick={(e) => setMenuAnchor(e.currentTarget)}
                  variant="outlined"
                  sx={{
                    ml: 2,
                    background: selectedStatus?.color || '',
                    color: selectedStatus?.color ? (theme) => theme.palette.getContrastText(selectedStatus?.color) : '',
                    '&:hover': {
                      background: selectedStatus?.color || '',
                    },
                  }}
                  size="small"
                >
                  {selectedStatus ? selectedStatus.title : 'Pick status'}
                </Button>

                <Menu open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)} anchorEl={menuAnchor}>
                  {statuses.map((status) => (
                    <MenuItem
                      key={status.id}
                      sx={{
                        background: status.color,
                        '&:hover': {
                          background: status.color ? darken(status.color, 0.2) : '',
                        },
                      }}
                      onClick={() => {
                        setMenuAnchor(null);
                        setSelectedStatusId(status.id);
                        if (isFunction(onCommentEdit)) {
                          onCommentEdit({ ...comment, selectedStatusId: status.id });
                        }
                      }}
                    >
                      <ListItemText
                        sx={{ color: status.color ? (theme) => theme.palette.getContrastText(status.color) : '' }}
                      >
                        {status.title}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Grid>
          <Grid item container style={{ padding: '0.5rem' }}>
            <Grid item container style={{ fontSize: 16 }}>
              <Typography style={{ whiteSpace: 'break-spaces' }}>{splited}</Typography>
            </Grid>
            {!isEmpty(comment.attachments) && (
              <>
                <Grid item container style={{ padding: 5 }}>
                  <img
                    alt={comment.attachments[0].name}
                    src={comment.attachments[0].location}
                    style={{ maxHeight: 300, cursor: 'pointer', borderRadius: 5, maxWidth: '100%' }}
                    onClick={() => setImageDialogOpen(true)}
                  />
                </Grid>
                <ImgDialog
                  open={imageDialogOpen}
                  onClose={() => setImageDialogOpen(false)}
                  src={comment.attachments[0].location}
                />
              </>
            )}
          </Grid>

          <Grid item container style={{ padding: '0.3rem' }}>
            <Typography color="textSecondary" style={{ fontSize: 12 }}>
              {FormatDate(comment.createdAt || comment.date)}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container sx={{ pl: 4 }}>
          {children.map((comment) => (
            <Comment
              comment={comment}
              disabled={disabled}
              deleteComment={deleteComment}
              onCommentEdit={onCommentEdit}
              type={type}
            />
          ))}
          {replying && (
            <NewComment
              isReply
              parentId={comment.id}
              onClose={() => setReplying(false)}
              onResult={onReply}
              sendMentionNotification={sendMentionNotification}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

function ImgDialog({ open, onClose, src }) {
  return (
    <RoundedDialog maxWidth="xl" open={open} onClose={onClose}>
      <img alt="pic" src={src} style={{ maxHeight: '80vh', maxWidth: '80vw' }} />
    </RoundedDialog>
  );
}

function NewComment({
  newCommentText,
  isReply,
  parentId,
  onClose,
  onResult,
  sendMentionNotification,
  onAddingCommentChange,
}) {
  const [text, setText] = useState('');
  const [mentions, setMentions] = useState([]);
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState([]);
  const [uploadingImage, setUploadingImage] = useState(false);

  const loadUsers = isEmpty(users);

  useEffect(() => {
    if (!loadUsers) return;
    axios
      .get('/user/getUsers')
      .then((res) => {
        setUsers(res.data);
      })
      .catch(console.error);
  }, [loadUsers]);

  function done() {
    if (!text && !image) return;

    const result = {
      id: v4(),
      text,
      createdAt: new Date(),
      createdBy: userId,
      creatorName: localStorage.getItem('name'),
      mentions: mentions.map((m) => m.id),
      attachments: image ? [image] : [],
      parentId,
    };
    if (isFunction(onAddingCommentChange)) {
      onAddingCommentChange(false);
    }
    if (!isEmpty(mentions) && isFunction(sendMentionNotification)) {
      mentions.forEach((mention) => sendMentionNotification(mention.id));
    }
    setText('');
    setMentions([]);
    setImage(null);
    onResult(result);
    onClose();
  }

  function uploadFile(file) {
    setUploadingImage(true);
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        const file = {
          id: v4(),
          name: res.data.file.originalname,
          originalname: res.data.file.originalname,
          location: res.data.file.location,
          size: res.data.file.size,
          type: res.data.file.mimetype,
          mimetype: res.data.file.mimetype,
          bucket: res.data.file.bucket,
        };
        setImage(file);
        setUploadingImage(false);
      })
      .catch((err) => {
        console.error(err);
        setUploadingImage(false);
      });
  }

  return (
    <Grid item container direction="column" component={Paper} variant="outlined" sx={{ my: 1 }}>
      <Grid item container alignItems="center" justifyContent="space-between" sx={{ p: 1 }}>
        {isReply ? (
          <ReplyRounded sx={{ color: grey[500], mr: 1 }} />
        ) : (
          <ModeCommentRounded sx={{ color: grey[500], mr: 1 }} />
        )}
        <Typography>{isReply ? 'Your reply:' : newCommentText || 'Your comment:'}</Typography>
        <IconButton
          sx={{ ml: 'auto' }}
          onClick={() => {
            setText('');
            setMentions([]);
            setImage(null);
            onClose();
          }}
        >
          <DeleteOutlineRounded style={{ color: red[500] }} />
        </IconButton>
      </Grid>

      <Grid item container sx={{ p: 1 }}>
        <Grid sx={{ borderRadius: 1, border: '1px solid rgb(255, 255, 255)' }} container>
          <StyledMentionsInput
            value={text}
            style={{ minHeight: '6rem', margin: 8, width: '100%' }}
            onChange={(event, newValue, newPlainTextValue, textMentions) => {
              setText(event.target.value);

              if (!isEmpty(textMentions)) {
                setMentions(textMentions);
              }
            }}
            onKeyDown={(e) => {
              if (e.shiftKey && e.key === 'Enter') {
                e.preventDefault();
                setText(`${text}\n`);
              } else if (e.key === 'Enter') {
                e.preventDefault();
                done();
              }
            }}
            placeholder="Type here"
            onPaste={(e) => {
              if (!uploadingImage && e.clipboardData.files[0] && e.clipboardData.files[0].type.startsWith('image/')) {
                uploadFile(e.clipboardData.files[0]);
              }
            }}
          >
            <Mention
              renderSuggestion={(entry, search, highlightedDisplay, index, focused) => (
                <>
                  <React.Fragment>
                    <ListItemButton
                      dense
                      sx={{
                        backgroundColor: focused
                          ? (theme) => (theme.palette.mode === 'dark' ? grey[700] : grey[100])
                          : '',
                        borderRadius: 1,
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={entry.user.avatar}
                          style={{ background: entry.user.avatarColor, width: 32, height: 32 }}
                        >
                          {entry.user.avatarLetters}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={entry.user.fullName} />
                    </ListItemButton>
                  </React.Fragment>
                </>
              )}
              data={users.map((user) => ({ id: user._id, display: user.fullName, user }))}
            />
          </StyledMentionsInput>
        </Grid>
      </Grid>

      <Grid container item>
        <Collapse in={uploadingImage || image} style={{ width: '100%' }}>
          <Grid container item style={{ padding: '0.5rem' }} alignItems="center">
            {uploadingImage && <CircularProgress style={{ margin: '2rem' }} />}
            {!uploadingImage && image && (
              <img alt={image.name} src={image.location} style={{ maxHeight: 100, maxWidth: '100%' }} />
            )}
            {!uploadingImage && (
              <IconButton onClick={() => setImage(null)} style={{ margin: 8, marginLeft: 'auto' }}>
                <Clear />
              </IconButton>
            )}
          </Grid>
        </Collapse>
      </Grid>
      <Grid container item style={{ padding: 8 }} alignItems="center">
        {!uploadingImage && !image && (
          <Button
            style={{ borderRadius: 8, textTransform: 'none' }}
            startIcon={<AttachFileRounded />}
            component="label"
          >
            <input
              accept="image/*"
              id="cover-photo"
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (!e.target.files || e.target.files.length === 0 || !e.target.files[0].type.startsWith('image/'))
                  return;
                uploadFile(e.target.files[0]);
              }}
            />
            Attach picture
          </Button>
        )}
        <Button
          variant="contained"
          style={{ borderRadius: 8, marginLeft: 'auto' }}
          onClick={done}
          color="primary"
          startIcon={<SendRounded />}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
