import React from 'react';
import { Grid, Paper, ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const ItemPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '0.8em',
  margin: theme.spacing(1, 0),
  display: 'flex',
  width: '100%',
}));

const Item = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  width: '100%',
  borderRadius: '0.8em',
}));

export default function ItemBox({ children, elevation = 3, paperProps, ...props }) {
  return (
    <Grid container item>
      <ItemPaper elevation={elevation} {...paperProps}>
        <Item {...props}>{children}</Item>
      </ItemPaper>
    </Grid>
  );
}
