import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Collapse, DialogContent, Switch, DialogTitle } from '@mui/material';
import { CheckCircleRounded, CheckCircleOutlineRounded } from '@mui/icons-material';
import { User, RoundedDialog, StandardDialogActions, DateTimePicker } from '../../../Global/Components';
import { grey, green } from '@mui/material/colors';
import UserManager from '../../../Global/UserManager';
import { isEmpty, isArray } from 'lodash';
import moment from 'moment';

export default function ApprovalDialog({ open, onClose, onResult, step }) {
  const defaultDueDate = step?.plannedApprovalTime
    ? moment()
        .add(step.plannedApprovalTime.days, 'days')
        .add(step.plannedApprovalTime.hours, 'hours')
        .set({ seconds: 0, milliseconds: 0 })
    : moment().add(1, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 });
  const [dueAt, setDueAt] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!step || !open) return;

    const presetTime = step.plannedApprovalTime
      ? moment()
          .add(step.plannedApprovalTime.days, 'days')
          .add(step.plannedApprovalTime.hours, 'hours')
          .set({ seconds: 0, milliseconds: 0 })
      : null;

    setDueAt(presetTime);

    const groupUsers = isArray(step.approvalGroups)
      ? step.approvalGroups.flatMap((x) => UserManager.resolveGroup(x)?.users || [])
      : [];
    const approvalUsers = step.approvalUsers || [];
    const userToApprove = [...groupUsers, ...approvalUsers];

    // if send to all users
    if (step.sendApprovalToAllUsers) {
      onResult({ users: userToApprove, dueAt: presetTime });
      onClose();
    } else {
      setUsers(userToApprove);
      setSelectedUsers([]);
    }
  }, [step, open]); // eslint-disable-line

  const hideDone = !step || isEmpty(selectedUsers);
  const allowSettingApprovalTime = step?.allowSettingApprovalTime == null || step?.allowSettingApprovalTime === true;

  function done() {
    onResult({ users: selectedUsers, dueAt });
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth className="scroll-bar">
      <DialogTitle>Select users to approve the step</DialogTitle>
      <DialogContent>
        {allowSettingApprovalTime && (
          <Grid container>
            <Grid container>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={Boolean(dueAt)}
                    onChange={() => setDueAt(dueAt ? null : defaultDueDate)}
                  />
                }
                label="Include due date"
              />
            </Grid>
            <Collapse style={{ width: '100%' }} in={Boolean(dueAt)}>
              <Grid container>
                <DateTimePicker value={dueAt} onChange={setDueAt} />
              </Grid>
            </Collapse>
          </Grid>
        )}

        <Grid container style={{ margin: '1rem 0', maxHeight: '40vh', overflow: 'auto' }}>
          {users.map((user) => (
            <User
              key={user}
              id={user}
              fullWidth
              markPortal
              onClick={() => {
                if (selectedUsers.includes(user)) {
                  setSelectedUsers(selectedUsers.filter((x) => x !== user));
                } else {
                  setSelectedUsers([...selectedUsers, user]);
                }
              }}
              action={
                selectedUsers.includes(user) ? (
                  <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                ) : (
                  <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                )
              }
            />
          ))}
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={hideDone} />
    </RoundedDialog>
  );
}
