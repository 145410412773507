import React, { useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { getEntityRange, getSelectionEntity } from './inline';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { COLORS } from '../../../constants';
import { Grid, IconButton, Paper, Box } from '@mui/material';
// import { RefDialog } from '../components/PreviewHTML';

//Add Reference Link Component

export const RefLink = ({ entityKey, contentState, children }) => {
  const isPreviewPage = useRouteMatch('/DocLibrary/preview/:id');
  const isIssuePage = useRouteMatch(`/editor/:id/upissue/:issue`);
  const { document } = useSelector(({ builder }) => builder);
  let { targetDocument, targetSelection, selectionType, title, text, type } = contentState
    .getEntity(entityKey)
    .getData();
  const [popOver, setPopOver] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [link, setLink] = useState(false);

  return (
    <Box
      component="span"
      sx={{ display: 'inline-block', alignItems: 'center', position: 'relative', cursor: 'pointer' }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => {
        setShowTooltip(false);
        setPopOver(false);
      }}
    >
      {popOver && (
        <Grid sx={{ position: 'absolute', bottom: '0%' }} component={Paper} elevation={2}>
          {!isPreviewPage && (
            <IconButton size="small" aria-label={'Edit Link Details'} onClick={() => setLink(true)}>
              <EditIcon />
            </IconButton>
          )}
          {!isPreviewPage && (
            <IconButton size="small" aria-label={'Delete Link'}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      )}
      <Tooltip title={title} placement="top" open={showTooltip}>
        <Typography
          sx={{
            color: type ? (type === 'outbound' ? COLORS.SEARCH : COLORS.GREEN) : COLORS.SEARCH,
            marginRight: 0.5,
            fontWeight: 500,
            textDecoration: 'underline',
            pointer: 'cursor',
          }}
          onClick={() => {
            setPopOver(!isPreviewPage && isIssuePage && document && document.stage === 'Editing');
            setShowTooltip(false);
            setLink(Boolean(isPreviewPage) || Boolean(isIssuePage && document && document.stage !== 'Editing'));
          }}
        >
          {children}
        </Typography>
      </Tooltip>
      {(isPreviewPage || (isIssuePage && document && document.stage !== 'Editing')) && link && (
        <RefDialog
          open={link}
          onClose={() => setLink(false)}
          targetDocument={targetDocument}
          targetSelection={targetSelection}
          selectionType={selectionType}
          title={title}
          linkText={text}
        />
      )}
    </Box>
  );
};

export const findRefLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'REFLINK';
  }, callback);
};

export const referenceDecorator = {
  strategy: findRefLinkEntities,
  component: RefLink,
};

// call all together
export const onAddRefLink = (dispatch) => (linkData, editorState, setEditorState) => {
  const text = linkData.text;
  let selection = editorState.getSelection();
  const entityKey = editorState
    .getCurrentContent()
    .createEntity('REFLINK', 'MUTABLE', linkData)
    .getLastCreatedEntityKey();

  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    `${text}`,
    editorState.getCurrentInlineStyle(),
    entityKey,
  );
  let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');

  // insert a blank space after link
  selection = newEditorState.getSelection();
  newEditorState = EditorState.acceptSelection(newEditorState, selection);
  contentState = Modifier.insertText(
    newEditorState.getCurrentContent(),
    selection,
    ' ',
    newEditorState.getCurrentInlineStyle(),
    undefined,
  );
  let newState = EditorState.push(newEditorState, contentState, 'insert-characters');
  dispatch(setEditorState(EditorState.forceSelection(newState, editorState.getSelection())));
};

export const onAddRefLinkOnly = (linkData, editorState) => {
  // const text = linkData.text;
  let selection = editorState.getSelection();
  let currentContent = editorState.getCurrentContent();
  // let currentStyle = editorState.getCurrentInlineStyle();
  const contentStateWithEntity = currentContent.createEntity('REFLINK', 'MUTABLE', linkData);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);

  // let contentState = Modifier.replaceText(currentContent, selection, text, currentStyle, entityKey);
  let newEditorState = EditorState.push(editorState, contentStateWithLink, 'apply-entity');
  selection = newEditorState.getSelection();
  newEditorState = EditorState.acceptSelection(newEditorState, selection);
  return EditorState.forceSelection(newEditorState, editorState.getSelection());
};
