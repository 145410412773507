import React from 'react';
import { Chip, ListItemButton, Grid, Typography } from '@mui/material';

const HubItem = ({ title, icon, entry, tags, onAction }) => {
  return (
    <Grid
      container
      alignItems="center"
      component={ListItemButton}
      onClick={onAction}
      wrap="nowrap"
      sx={{ borderRadius: 0.5, borderBottom: (t) => `0.1px solid ${t.palette.grey[100]}` }}
    >
      <Typography noWrap>{title}</Typography>
      {tags &&
        tags.length > 0 &&
        tags
          ?.filter((_, i) => i < 3)
          ?.map((tag, i) => (
            <Chip
              key={tag?._id}
              label={tag?.name}
              sx={{
                background: `#${tag?.color}`,
                color: (theme) => (tag.color ? theme.palette.getContrastText(`#${tag.color}`) : undefined),
                marginRight: 2,
                ...(i === 0 && { marginLeft: 'auto' }),
              }}
            />
          ))}
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 500,
          marginRight: 8,
          marginLeft: (tags?.length === 0 || !tags) && 'auto',
          paddingLeft: 8,
        }}
        color="grey"
      >
        {entry}
      </Typography>
      {icon}
    </Grid>
  );
};

export default HubItem;
