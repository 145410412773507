import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { WS_BASE_URL } from '../../../constants';

const NOTIFICATION_UPDATE = 'notificationUpdate';
const NEW_NOTIFICATION_EVENT = 'newNotification';
const NOTIFICATION_DELETE = 'notificationDelete';

const useNotifications = (isDisabled) => {
  const [notifications, setNotifications] = useState([]); // current notifications
  const currNotifications = useRef([]); // to store data

  const socket = useRef(); // WS connection

  const processNotification = (notification) => {
    currNotifications.current.unshift(notification);
    setNotifications([...currNotifications.current]);
  };

  const processNotificationUpdate = (notification) => {
    const index = currNotifications.current.findIndex((x) => x._id === notification._id);
    if (index > -1) {
      currNotifications.current[index] = notification;
      setNotifications([...currNotifications.current]);
    }
  };

  const processNotificationDelete = (id) => {
    currNotifications.current = currNotifications.current.filter((x) => x._id !== id);
    setNotifications([...currNotifications.current]);
  };

  useEffect(() => {
    if (isDisabled) return;

    const token = localStorage.getItem('token');

    import('socket.io-client').then(({ io }) => {
      if (socket.current) return;

      socket.current = io(`${WS_BASE_URL}/notifications`, {
        query: {
          token,
        },
        transports: ['websocket'],
        secure: true,
      });

      // Processing notifications
      socket.current.on(NEW_NOTIFICATION_EVENT, (notification) => {
        processNotification(notification);
      });

      // Processing notification updates
      socket.current.on(NOTIFICATION_UPDATE, (notification) => {
        processNotificationUpdate(notification);
      });

      // Processing notification delete
      socket.current.on(NOTIFICATION_DELETE, (notification) => {
        processNotificationDelete(notification);
      });
    });

    // load notifications
    axios
      .get('/notifications/getAll', {
        params: {
          loadCleared: false,
        },
      })
      .then((res) => {
        currNotifications.current = res.data;
        setNotifications(res.data);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const read = (id) => {
    if (id) {
      axios.post('/notifications/markAsRead', { ids: [id] });
      const index = currNotifications.current.findIndex((x) => x._id === id);
      if (index > -1) {
        currNotifications.current[index].readAt = new Date();
        setNotifications([...currNotifications.current]);
      }
    } else {
      let ids = currNotifications.current
        .filter((x) => !x.readAt && (x.type !== 'newTask' || x.body.toCreator))
        .map((x) => x._id);
      //let ids = currNotifications.current.filter(x => !x.readAt).map(x => x._id);
      axios.post('/notifications/markAsRead', { ids });
      currNotifications.current.forEach((x) => {
        if (x.type !== 'newTask' || x.body.toCreator) x.readAt = new Date();
      });
      setNotifications([...currNotifications.current]);
    }
  };

  const clear = (id) => {
    if (id) {
      const allNotification = currNotifications.current.filter((x) => x._id !== id);
      setNotifications([...allNotification]);
      axios.post('/notifications/markAsCleared', { ids: [id] });
    } else {
      var toDelete = [];
      var toRemain = [];
      currNotifications.current.forEach((x) => {
        if (x.readAt) toDelete.push(x._id);
        else toRemain.push(x);
      });
      currNotifications.current = toRemain;
      setNotifications([...currNotifications.current]);
      axios.post('/notifications/markAsCleared', { ids: toDelete });
    }
  };

  const view = () => {
    let ids = currNotifications.current.filter((x) => !x.viewedAt).map((x) => x._id);
    if (ids[0]) axios.post('/notifications/markAsViewed', { ids: ids });
    currNotifications.current.forEach((x) => {
      x.viewedAt = new Date();
    });
    //setNotifications([...currNotifications.current]);
  };

  const refresh = () => {
    setNotifications([...currNotifications.current]);
  };

  const unread = (id) => {
    if (id) {
      axios.post('/notifications/markAsUnread', { ids: [id] });
      const index = currNotifications.current.findIndex((x) => x._id === id);
      if (index > -1) {
        currNotifications.current[index].readAt = null;
        currNotifications.current[index].clearedAt = null;
        setNotifications([...currNotifications.current]);
      }
    } else {
      let ids = currNotifications.current
        .filter((x) => x.readAt && (x.type !== 'newTask' || x.body.toCreator))
        .map((x) => x._id);
      //let ids = currNotifications.current.filter(x => !x.readAt).map(x => x._id);
      axios.post('/notifications/markAsUnread', { ids });
      currNotifications.current.forEach((x) => {
        if (x.type !== 'newTask' || x.body.toCreator) {
          x.readAt = null;
          x.clearedAt = null;
        }
      });
      setNotifications([...currNotifications.current]);
    }
  };

  return { notifications, read, clear, view, refresh, unread };
};

export default useNotifications;
