import React, { useEffect, useState, Suspense } from 'react';
import { DialogContent, Grid, CircularProgress, Typography, Button, Collapse } from '@mui/material';
import { DeleteOutlineRounded, LabelRounded, EditRounded, FilterListRounded } from '@mui/icons-material';
import { VisibilityRounded, VisibilityOffRounded, PrintRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions, IntervalDialog, GeneralButton } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { RoutineIcon, TaskIcon } from '../../../Global/Icons';
import { grey, red, blue, amber } from '@mui/material/colors';
import { kpiColors } from '../../../Global/Functions';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../../constants';
import moment from 'moment';
import axios from 'axios';
import KpiTimescale from './KpiTimescale';
import { isNumber, isEmpty } from 'lodash';
import Chart from '../../../Global/Charts/Chart';

export default function RoutineFieldKpiDialog({ open, onClose, onDelete, kpi, onChange }) {
  const [hideSlices, setHideSlices] = useState(false);
  const [saving, setSaving] = useState(false);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [intervalDialog, setIntervalDialog] = useState(false);
  const [data, setData] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);
  const [userFilters, setUserFilters] = useState([]);
  const userId = localStorage.getItem('_id');

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this KPI', deleteKPI);
  }

  function deleteKPI() {
    setSaving(true);
    axios
      .post('/tasks/routines/kpi/deleteKpi', { id: kpi._id })
      .then((res) => {
        onClose();
        onDelete();
        StateManager.setSuccessAlert('KPI has been deleted');
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setErrorAlert(err.response?.data?.message || 'Something went wrong');
      });
  }

  useEffect(() => {
    setInfo(null);
    if (!kpi) return;

    setData(kpi.data);
    setStart(kpi.appliedTimeFilters?.start ? moment(kpi.appliedTimeFilters?.start) : null);
    setEnd(kpi.appliedTimeFilters?.end ? moment(kpi.appliedTimeFilters?.end) : null);
    setInterval(isNumber(kpi.appliedTimeFilters?.days) ? kpi.appliedTimeFilters?.days : kpi.interval || 180);
    setLoading(true);
    axios
      .get('/tasks/routines/kpi/getKpiInfo', { params: { kpiId: kpi._id } })
      .then((res) => {
        setInfo(res.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [kpi]);

  function applyFilters(days, from, to, userFilters) {
    const start = from
      ? moment(from)
      : moment()
          .subtract(days || interval, 'days')
          .startOf('day');
    const end = to ? moment(to) : moment().endOf('day');

    setLoadingChart(true);

    const params = {
      kpiId: kpi._id,
      start: start.toISOString(),
      end: end.toISOString(),
      userFilters,
      days,
    };

    axios
      .post('/tasks/routines/kpi/apllyKpiFilters', params)
      .then((res) => {
        setLoadingChart(false);
        setData(res.data);
        if (onChange) onChange({ data: res.data, _id: kpi?._id });
      })
      .catch((err) => {
        console.error(err);
        setLoadingChart(false);
        StateManager.setErrorAlert('Failed to load the chart');
      });
  }

  function print() {
    setSaving(true);
    const element = document.getElementById('chart');
    if (!element) {
      setSaving(false);
      return;
    }

    import('html2canvas').then(({ default: html2canvas }) => {
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const body = {
          image,
          title: kpi.title,
          routineInfo: info,
        };
        axios
          .post('/kpi/exportToPdf', body)
          .then((res) => {
            window.open(`${BASE_URL}${res.data.link}`, '_blank');
            setSaving(false);
          })
          .catch((err) => {
            setSaving(false);
            StateManager.setAxiosErrorAlert(err);
          });
      });
    });
  }

  function selectFilterUsers() {
    StateManager.selectMultipleUsers(applyUserFilters, { initiallySelected: userFilters });
  }

  function applyUserFilters(res) {
    setUserFilters(res?.users);
    applyFilters(interval, start, end, res?.users);
  }

  useEffect(() => {
    if (interval > 0) {
      setStart(null);
      setEnd(null);
    }
  }, [interval]);

  if (!kpi) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Grid container item>
          <Collapse in={Boolean(info)} style={{ width: '100%' }} collapsedSize={40}>
            {loading && (
              <Grid container item>
                <CircularProgress color="primary" size={30} style={{ margin: 5 }} />
              </Grid>
            )}
            {info && (
              <Grid container item>
                <Grid container item alignItems={'center'} sx={{ mb: 1 }}>
                  <Typography variant="h3">{kpi.title}</Typography>
                  <Typography variant="h6" sx={{ mr: 2, ml: 'auto' }}>
                    Change KPI timescale:
                  </Typography>
                  <KpiTimescale
                    value={interval}
                    onChange={(value) => {
                      setInterval(value);
                      if (value === -1) {
                        setIntervalDialog(true);
                      } else {
                        applyFilters(value);
                      }
                    }}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                    <RoutineIcon />
                    <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                      Routine:
                    </Typography>
                    <Link to={`/tasks/routine/${info.routineId}`}>
                      <Typography variant="h6" style={{ color: blue[700], marginLeft: '0.25rem' }}>
                        {info.routineTitle}
                      </Typography>
                    </Link>
                  </Grid>

                  <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                    <TaskIcon />
                    <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                      Template: {info.templateTitle}
                    </Typography>
                  </Grid>

                  <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                    <LabelRounded style={{ color: amber[500] }} />

                    <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                      Field:
                    </Typography>
                    <Typography variant="h6" style={{ marginLeft: '0.25rem' }}>
                      {info.fieldTitle}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={6} alignItems="flex-start" alignContent={'flex-start'}>
                  {start && end && (
                    <Grid container item justifyContent="flex-end" alignItems="center" style={{ marginTop: '0.5rem' }}>
                      <Typography style={{ fontWeight: 500 }}>
                        {`From ${start.format('DD/MM/yyyy')} to ${end.format('DD/MM/yyyy')}`}
                      </Typography>
                    </Grid>
                  )}
                  <Grid container item sx={{ my: 1 }} justifyContent="flex-end">
                    <GeneralButton
                      sx={{
                        mr: 2,
                        border: (theme) => (!isEmpty(userFilters) ? `2px solid ${theme.palette.primary.dark}` : ''),
                        fontWeight: !isEmpty(userFilters) ? 700 : 500,
                        color: (theme) => (!isEmpty(userFilters) ? theme.palette.primary.dark : ''),
                      }}
                      onClick={selectFilterUsers}
                      startIcon={<FilterListRounded />}
                    >
                      Filter by user
                    </GeneralButton>
                  </Grid>

                  <Grid container item style={{ marginTop: '0.5rem' }} justifyContent="flex-end">
                    {kpi.chartType === 'PieChart' && (
                      <Button
                        style={{ color: grey[500], textTransform: 'none', fontSize: 14 }}
                        startIcon={hideSlices ? <VisibilityRounded /> : <VisibilityOffRounded />}
                        onClick={() => setHideSlices(!hideSlices)}
                      >
                        {hideSlices ? 'Show small slices' : 'Hide small slices'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Collapse>
        </Grid>

        <Grid container item id="chart" sx={{ height: '55vh' }}>
          {!loadingChart && <Chart data={data} chartType={kpi.chartType} barChartType={kpi.barChartType} />}
          {loadingChart && (
            <Grid container item alignItems="center" justifyContent="center" style={{ height: '100%' }}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>

        <IntervalDialog
          open={intervalDialog}
          onClose={() => setIntervalDialog(false)}
          onResult={(res) => {
            setStart(res.start);
            setEnd(res.end);
            applyFilters(-1, res.start, res.end);
          }}
        />
      </DialogContent>

      <StandardDialogActions
        saving={saving}
        onClose={onClose}
        additionalActions={
          <>
            {userId === kpi.userId && (
              <Link to={`/kpiHub/routine-field-kpi-builder/${kpi._id}`}>
                <Button style={{ color: grey[600] }} startIcon={<EditRounded />}>
                  Edit
                </Button>
              </Link>
            )}

            {userId === kpi.userId && onDelete && (
              <Button style={{ color: red[500] }} onClick={confirmDelete} startIcon={<DeleteOutlineRounded />}>
                delete
              </Button>
            )}

            {info && (
              <Button onClick={print} style={{ color: grey[600] }} startIcon={<PrintRounded />}>
                print
              </Button>
            )}

            <div style={{ marginRight: 'auto' }} />
          </>
        }
      />
    </RoundedDialog>
  );
}
