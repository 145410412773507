import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, DialogContent, DialogTitle } from '@mui/material';
import { RoundedDialog, StandardDialogActions, UserGroup } from '../Components';
import { isEmpty, isArray } from 'lodash';

export default function SelectedRowsDialog({ columns, rows, open, onClose }) {
  if (!Array.isArray(columns) || !Array.isArray(rows)) return null;
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Selected</DialogTitle>
      <DialogContent sx={{ minWidth: '20vw' }}>
        <Table style={{ width: 'fit-content' }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} hover>
                {columns.map((column) => (
                  <TableCell key={`${column.id}#${row.id}`}>
                    <DataSetCellValue column={column} value={row[column.id]} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}

function DataSetCellValue({ column, value }) {
  return column.type === 'users' ? (
    isArray(value) && !isEmpty(value) ? (
      <UserGroup max={8} ids={value} fullWidth avatarSize={30} clickable={false} />
    ) : null
  ) : (
    value
  );
}
