import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Collapse,
  CircularProgress,
  ListItemButton,
  Chip,
} from '@mui/material';
import { ListItemAvatar, ListItemText, Grid, TextField, InputAdornment, IconButton } from '@mui/material';
import { Button, Avatar, useTheme, useMediaQuery, Tabs, Tab, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CheckCircleRounded,
  CheckCircleOutlineRounded,
  ExpandMore,
  ExpandLess,
  GroupRounded,
} from '@mui/icons-material';
import { Group, DeleteOutlineRounded } from '@mui/icons-material';
import { green, grey, red } from '@mui/material/colors';
import { GroupChip, RoundedDialog, StandardDialogActions } from '../Components';
import { isArray, isEmpty } from 'lodash';
import axios from 'axios';

import StateManager from '../StateManager';
import { AddCircleOutlineRounded } from '@mui/icons-material';
import { validateEmail } from '../Functions';
import { COLORS } from '../../Hubs/dochub/constants';

export default function ExternalUsersDialog({
  open,
  onClose,
  map = (x) => x._id,
  showGroups,
  initiallySelectedGroups,
  title,
  additionalActions,
  singleSelect,
  withClearButton,
}) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const [tab, setTab] = useState('normalUsers');
  const [loading, setLoading] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [email, setEmail] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [groups, setGroups] = useState([]);
  const [newEmail, setNewEmail] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [deselectedGroups, setDeselectedGroups] = useState([]);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const loadGroups = open && groups.length === 0 && showGroups;
  let avatarSize = 40;
  let fullWidth = false;
  const fontSize = Math.round(avatarSize / 2);
  const padding = Math.round(avatarSize / 8);
  const [users, setUsers] = useState([]);

  const showGroupsTab = showGroups && !isEmpty(groups) && !singleSelect;

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);
  const [deselectedUsers, setDeselectedUsers] = useState([]);

  const getContactByGroupId = async (groupId) => {
    return await axios
      .get('/data/getContactGroup', { params: { groupId } })
      .then((res) => {
        setGroupUsers(res.data.contacts);
        setExpandedGroup(groupId);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  };

  useEffect(() => {
    if (!loadGroups || !open) return;
    axios
      .get('/data/getContactGroups')
      .then((res) => {
        setGroups(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loadGroups, open]);

  useEffect(() => {
    if (!open) return;
    if (!isArray(initiallySelectedGroups) || isEmpty(initiallySelectedGroups)) {
      setSelectedGroups([]);
    } else {
      setSelectedGroups(initiallySelectedGroups);
    }
  }, [initiallySelectedGroups, groups, open]); // eslint-disable-line

  function addDeleteGroup(group) {
    if (selectedGroups.includes(group)) {
      const result = selectedGroups.filter((x) => x !== group);
      setSelectedGroups(result);
      setDeselectedGroups([...deselectedGroups, group]);
    } else {
      const result = [...selectedGroups, group];
      setSelectedGroups(result);
    }
  }

  function done() {
    if (showGroups) {
      onClose({
        groups: selectedGroups,
        deselectedGroups,
      });
    } else {
      onClose({ users: map ? users.filter((x) => selectedUsers.includes(x._id)).map(map) : selectedUsers });
    }
  }

  function clear() {
    setSelectedGroups([]);
    onClose({
      groups: [],
      deselectedGroups: [],
    });
  }

  const handleAddEmail = (email) => {
    if (!newEmail.includes(email) && validateEmail(email)) {
      setNewEmail((val) => [...val, email]);
      setEmail('');
    }
  };
  const handleEditEmail = (email, index) => {
    if (!newEmail?.filter((_, i) => i !== index).includes(email) && validateEmail(email)) {
      const updatedEmails = [...newEmail];
      updatedEmails.splice(index, 1, email);
      console.log('updatedEmails: ', updatedEmails);
      setNewEmail(updatedEmails);
      setIsEditEmail(false);
      setEditEmail('');
    }
  };

  return (
    <RoundedDialog
      className="scroll-bar"
      maxWidth="xs"
      fullWidth
      open={Boolean(open)}
      onClose={() => onClose(null)}
      fullScreen={!largeDevices}
    >
      <DialogTitle>{title || 'Select external users'}</DialogTitle>
      <DialogContent dividers style={{ padding: theme.spacing(2, 1) }}>
        {loading ? (
          <Grid container style={{ height: '30vh' }} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {selectedGroups?.length > 0 && (
              <Grid container style={{ minHeight: '3rem' }}>
                {selectedGroups.map((id, i) => (
                  <Chip
                    style={{ margin: 4 }}
                    size="small"
                    icon={<GroupRounded />}
                    key={i}
                    id={id}
                    label={groups.filter((x) => id === x._id)?.[0]?.title || 'Loading...'}
                    onDelete={() => addDeleteGroup(id)}
                  />
                ))}
              </Grid>
            )}
            <Grid container>
              <Tabs indicatorColor="primary" textColor="primary" value={tab} sx={{ mb: 1, width: '100%' }}>
                <Tab label="Users" value="normalUsers" onClick={() => setTab('normalUsers')} />
                {showGroupsTab && <Tab label="Groups" value="groups" onClick={() => setTab('groups')} />}
              </Tabs>
              {tab === 'normalUsers' && (
                <>
                  <Grid container style={{ padding: theme.spacing(1) }}>
                    <Grid item style={{ width: '80%' }}>
                      <TextField
                        type="email"
                        id="email"
                        placeholder="Add New Email"
                        fullWidth
                        value={email}
                        error={
                          email.length > 0
                            ? !validateEmail(email)
                              ? true
                              : newEmail.includes(email)
                              ? true
                              : false
                            : false
                        }
                        helperText={
                          email.length > 0
                            ? !validateEmail(email)
                              ? 'Please add validate email'
                              : newEmail.includes(email)
                              ? 'This email is already added'
                              : ''
                            : ''
                        }
                        onChange={(event) => setEmail(event.target.value.toLowerCase())}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item pl={1}>
                      <IconButton size="small" onClick={() => handleAddEmail(email)}>
                        <AddCircleOutlineRounded style={{ color: COLORS.MAINBLUE }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid style={{ width: '100%' }}>
                    {newEmail?.length > 0 &&
                      newEmail.map((email, index) => {
                        return (
                          <ListItemButton
                            style={{
                              borderRadius: 5,
                              padding: `${padding}px ${padding * 2}px`,
                              width: '100%',
                            }}
                          >
                            {isEditEmail && editIndex === index ? (
                              <Grid item style={{ width: '90%', paddingBottom: '4px' }}>
                                <TextField
                                  type="email"
                                  id="email"
                                  placeholder="Add New Email"
                                  fullWidth
                                  value={editEmail}
                                  error={
                                    editEmail.length > 0
                                      ? !validateEmail(editEmail)
                                        ? true
                                        : newEmail?.filter((_, i) => i !== index).includes(editEmail)
                                        ? true
                                        : false
                                      : false
                                  }
                                  helperText={
                                    editEmail.length > 0
                                      ? !validateEmail(editEmail)
                                        ? 'Please add validate email'
                                        : newEmail?.filter((_, i) => i !== index).includes(editEmail)
                                        ? 'This email is already added'
                                        : ''
                                      : ''
                                  }
                                  onChange={(event) => setEditEmail(event.target.value.toLowerCase())}
                                  variant="standard"
                                />
                              </Grid>
                            ) : (
                              <Grid item style={{ width: '90%' }}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography noWrap style={{ fontSize: 18 }} color={'textPrimary'}>
                                      {email ?? ''}{' '}
                                    </Typography>
                                  }
                                />
                              </Grid>
                            )}
                            <Grid item pl={2} display="flex" alignItems="center">
                              {isEditEmail && editIndex === index ? (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleEditEmail(editEmail, index);
                                  }}
                                >
                                  <CheckCircleOutlineRounded style={{ color: green[300] }} />
                                </IconButton>
                              ) : (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setIsEditEmail(true);
                                    setEditIndex(index);
                                    setEditEmail(email);
                                  }}
                                >
                                  <EditIcon
                                    sx={{
                                      color: (t) => t.palette.grey[500],
                                      '&:hover': {
                                        color: COLORS.MAINBLUE,
                                      },
                                    }}
                                  />
                                </IconButton>
                              )}
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setNewEmail(newEmail?.filter((_, i) => i !== index));
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: (t) => t.palette.grey[500],
                                    '&:hover': {
                                      color: (t) => t.palette.error.main,
                                    },
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </ListItemButton>
                        );
                      })}
                  </Grid>
                </>
              )}
              {tab === 'groups' && (
                <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
                  {groups.length > 0 &&
                    groups.map((group, i) => (
                      <React.Fragment key={group._id}>
                        <ListItem style={{ borderRadius: 5 }}>
                          <ListItemAvatar>
                            <Avatar>
                              <Group />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={group.title} />
                          <IconButton
                            onClick={() => {
                              if (expandedGroup === group._id) {
                                setExpandedGroup(null);
                              } else {
                                getContactByGroupId(group._id);
                              }
                            }}
                            style={{ marginLeft: 'auto' }}
                          >
                            {expandedGroup === group._id ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                          <IconButton onClick={() => addDeleteGroup(group._id)}>
                            {selectedGroups.includes(group._id) ? (
                              <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                            ) : (
                              <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                            )}
                          </IconButton>
                        </ListItem>
                        <Collapse in={expandedGroup === group._id} style={{ width: '100%' }}>
                          <Grid style={{ padding: theme.spacing(1, 2) }}>
                            {groupUsers?.length > 0 &&
                              groupUsers?.map((user, i) => (
                                <ListItemButton
                                  // selected={selected}
                                  style={{
                                    borderRadius: 5,
                                    padding: `${padding}px ${padding * 2}px`,
                                    width: fullWidth ? '100%' : 'auto',
                                  }}
                                >
                                  {user.firstName && user.lastName && (
                                    <ListItemAvatar
                                      style={{
                                        minWidth: 'auto',
                                        paddingRight: padding * 3,
                                      }}
                                    >
                                      <Avatar
                                        style={{
                                          width: avatarSize,
                                          height: avatarSize,
                                          fontSize,
                                        }}
                                      >
                                        {user.firstName[0] + user.lastName[0]}
                                      </Avatar>
                                    </ListItemAvatar>
                                  )}
                                  <ListItemText
                                    disableTypography
                                    primary={
                                      <Typography noWrap style={{ fontSize: 16 }} color={'textPrimary'}>
                                        {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''}{' '}
                                      </Typography>
                                    }
                                  />
                                </ListItemButton>
                              ))}
                          </Grid>
                        </Collapse>
                      </React.Fragment>
                    ))}
                </List>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          <>
            {withClearButton && (!isEmpty(selectedUsers) || !isEmpty(selectedGroups)) && (
              <Button sx={{ color: red[500] }} onClick={clear} startIcon={<DeleteOutlineRounded />}>
                Clear
              </Button>
            )}
            {additionalActions}

            <Grid style={{ marginRight: 'auto' }} />
          </>
        }
        onClose={() => onClose(null)}
        onDone={done}
      />
    </RoundedDialog>
  );
}
