import React, { useMemo, useState } from 'react';
import { Grid, FormControlLabel, Checkbox, Collapse, Button, Badge } from '@mui/material';
import { isArray, isEmpty, isFunction } from 'lodash';
import { ExpandMoreRounded } from '@mui/icons-material';

export default function CheckboxItem({ editable, option, allOptions, checkedItems, onChange }) {
  const [expanded, setExpanded] = useState(false);

  const SUB_ITEMS_MIN = 2;
  const SUB_ITEMS_MAX = 4;

  const allChildren = useMemo(() => {
    return allOptions.filter((x) => x.parentId === option.id);
  }, [allOptions, option]);

  const { childerenToRender, extraChildren } = useMemo(() => {
    const result = {
      childerenToRender: allChildren.length > SUB_ITEMS_MAX ? allChildren.slice(0, SUB_ITEMS_MIN) : allChildren,
      extraChildren: allChildren.length > SUB_ITEMS_MAX ? allChildren.slice(SUB_ITEMS_MIN) : [],
    };

    return result;
  }, [allChildren]);

  const extraChildrenSelected = useMemo(() => {
    if (isEmpty(extraChildren) || isEmpty(checkedItems)) return;

    const result = extraChildren.some((x) => checkedItems.includes(x.id));
    return result;
  }, [extraChildren, checkedItems]);

  return (
    <>
      <Grid container item>
        <FormControlLabel
          style={{ cursor: editable ? '' : 'default' }}
          label={option.text}
          control={
            <Checkbox
              color="primary"
              id={option.id}
              checked={isArray(checkedItems) && checkedItems.includes(option.id)}
              onChange={(e) => isFunction(onChange) && onChange(option.id, e.target.checked)}
              style={{ cursor: editable ? '' : 'default' }}
            />
          }
        />
      </Grid>
      {!isEmpty(allChildren) && (
        <Grid container item sx={{ pl: 2 }}>
          {childerenToRender.map((child) => (
            <CheckboxItem
              key={child.id}
              editable={editable}
              option={child}
              allOptions={allOptions}
              checkedItems={checkedItems}
              onChange={onChange}
            />
          ))}
          {!isEmpty(extraChildren) && (
            <>
              <Grid container item component={Collapse} in={expanded}>
                {extraChildren.map((child) => (
                  <CheckboxItem
                    key={child.id}
                    editable={editable}
                    option={child}
                    allOptions={allOptions}
                    checkedItems={checkedItems}
                    onChange={onChange}
                  />
                ))}
              </Grid>
              <Grid container item>
                <Button
                  endIcon={
                    <Badge variant="dot" color="error" invisible={!extraChildrenSelected || expanded}>
                      <ExpandMoreRounded
                        sx={{
                          marginLeft: 'auto',
                          transform: 'rotate(0deg)',
                          marginLeft: 'auto',
                          transition: (theme) =>
                            theme.transitions.create('transform', {
                              duration: theme.transitions.duration.shortest,
                            }),
                          transform: expanded ? 'rotate(180deg)' : null,
                        }}
                      />
                    </Badge>
                  }
                  onClick={() => setExpanded(!expanded)}
                >
                  Show {expanded ? 'less' : 'more'}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
}
