import { useState } from 'react';
import html2canvas from 'html2canvas';

/**
 * A custom hook that allows taking a screenshot of a given HTML element.
 * @returns {[string|null, string|null, function]} An array containing the screenshot data URL, error message (if any), and a function to take the screenshot.
 */
const useScreenshot = () => {
  const [screenshot, setScreenshot] = useState(null);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  /**
   * Takes a screenshot of the given HTML element.
   * @param {React.RefObject} targetRef The reference to the HTML element to take a screenshot of.
   * @param {boolean} isDarkMode Whether to take the screenshot in dark mode or not.
   */
  const takeScreenshot = async (targetRef, isDarkMode = true) => {
    if (!targetRef.current) {
      setError('Target ref is not set');
      return;
    }
    setSaving(true);
    try {
      console.log('isDarkMode', isDarkMode);
      const canvas = await html2canvas(targetRef.current, {
        ...(isDarkMode && { backgroundColor: '#000' }),
        scale: 3,
        removeContainer: true,
        width: targetRef.current.scrollWidth * 0.5,
        height: targetRef.current.scrollHeight - 25,
      });
      setError(null);
      setSaving(false);
      let result = canvas.toDataURL();
      setScreenshot(result);
      return result;
    } catch (err) {
      setSaving(false);
      setScreenshot(null);
      setError(err.message);
    }
  };

  return [screenshot, takeScreenshot, saving, error];
};

export default useScreenshot;
