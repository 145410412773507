import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Popover,
  ListItem,
  Button,
  Tooltip,
  Typography,
  Grid,
  ListItemButton,
  Paper,
  Avatar,
  Box,
  IconButton,
  Divider,
  List,
  ListItemAvatar,
  ListItemText,
  Chip,
  Tabs,
  Tab,
} from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import FormatNotification from '../Notifications/notificationFormatter';
import moment from 'moment';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { red, blue, grey } from '@mui/material/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { DeleteOutlineRounded } from '@mui/icons-material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';

// export default function Notifications(props) {
//   return (
//     <Popover
//       open={props.open}
//       anchorEl={props.anchorEl}
//       onClose={props.onClose}
//       style={{
//         marginTop: '40px',
//       }}
//       className="scroll-bar"
//     >
//       <div style={{ minWidth: '40vw' }}>
//         <Grid container style={{ padding: '8px 12px', borderBottom: `1px solid ${grey[500]}` }} alignItems="center">
//           <NotificationsIcon style={{ color: blue[900] }} />

//           <Link to="/notifications">
//             <Tooltip title="Open notifications page" placement="top" arrow>
//               <Typography variant="h6" color={'textPrimary'} style={{ marginLeft: '10px' }}>
//                 Notifications
//               </Typography>
//             </Tooltip>
//           </Link>

//           <div style={{ marginLeft: 'auto' }}></div>

//           {props.notifications.filter((x) => !x.readAt)[0] && (
//             <Button
//               onClick={() => props.read()}
//               color="primary"
//               variant="outlined"
//               style={{ borderRadius: 8, textTransform: 'none' }}
//             >
//               Read all
//             </Button>
//           )}

//           {props.notifications.filter((x) => x.readAt)[0] && (
//             <Button
//               onClick={props.clear}
//               variant="outlined"
//               style={{ borderRadius: 8, textTransform: 'none', marginLeft: 16, color: red[500] }}
//               startIcon={<DeleteOutlineRounded />}
//             >
//               Clear read
//             </Button>
//           )}
//         </Grid>
//         <div style={{ overflow: 'auto', maxHeight: '450px' }}>
//           {!props.notifications[0] && (
//             <div style={{ textAlign: 'center', padding: '20px' }}>
//               <Typography variant="h6">You don't have any notifications</Typography>
//             </div>
//           )}
//           {props.notifications.map((notification) => (
//             <ListItemButton
//               key={notification._id}
//               onClick={() => {
//                 if ((notification.type !== 'newTask' || notification.body?.toCreator) && !notification.readAt) {
//                   props.read(notification._id);
//                 }
//               }}
//             >
//               <Notification read={props.read} key={notification._id} notification={notification} />
//             </ListItemButton>
//           ))}
//         </div>
//       </div>
//     </Popover>
//   );
// }

// function Notification({ notification }) {
//   function acceptTask() {
//     if (notification.body.taskId) {
//       axios
//         .post('/tasks/saveTaskAcceptance', {
//           taskId: notification.taskId,
//           accept: true,
//         })
//         .catch(console.error);
//     }
//   }

//   const button =
//     notification.type === 'newTask' && !notification.body?.toCreator ? (
//       <Button size="small" variant="outlined" onClick={acceptTask}>
//         Accept
//       </Button>
//     ) : (
//       <Button size="small" variant="outlined">
//         Read
//       </Button>
//     );

//   const icon =
//     notification.type === 'newTask' && notification.taskAcceptance === 'false' ? (
//       <CloseIcon style={{ height: 30, width: 30, color: red[500] }} />
//     ) : (
//       <DoneIcon style={{ height: 30, width: 30, color: blue[700] }} />
//     );

//   return (
//     <Grid container style={{ height: 50 }} alignItems="center" wrap="nowrap">
//       <div style={{ display: 'flex', alignItems: 'center' }}>
//         {!notification.readAt && <FiberManualRecord style={{ color: red[600], width: 5, height: 5, marginRight: 5 }} />}
//       </div>
//       <Typography color="textSecondary" style={{ minWidth: 120, fontSize: 13 }}>
//         {moment(notification.createdAt).fromNow()}
//       </Typography>
//       {FormatNotification({ notification })}
//       <Grid container justifyContent="center" alignItems="center" style={{ marginLeft: 'auto', width: 64 }}>
//         {notification.readAt ? icon : button}
//       </Grid>
//     </Grid>
//   );
// }

export default function Notifications(props) {
  const history = useHistory();
  const [tab, setTab] = useState('unread');
  const TASK_MENTION = 'taskMention';
  const MENTION_EVENT = 'mentionEvent';
  const tabList = [
    {
      label: 'Unread',
      value: 'unread',
    },
    {
      label: 'Mentions',
      value: 'task_mention',
    },
    {
      label: 'All',
      value: 'all',
    },
  ];
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      style={{
        marginTop: '40px',
      }}
      className="scroll-bar"
    >
      <div
        style={{
          width: 500,
          boxShadow: '0 0 20px rgba(0,0,0,0.1)',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {/* Header Section */}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ padding: '16px 16px 3px 16px', flexWrap: 'nowrap' }}
        >
          <Grid container alignItems="center" spacing={1} margin={0} width={'auto'}>
            <Typography variant="h6" sx={{ marginLeft: 1 }}>
              Notifications
              {props.notifications?.filter((x) => !x.readAt).length > 0 && (
                <Chip
                  sx={{ ml: 'auto', mr: 1, ml: 1 }}
                  label={props.notifications?.filter((x) => !x.readAt).length}
                  size="small"
                />
              )}
            </Typography>
          </Grid>

          {/* <IconButton onClick={() => props.onClose()}>
            <CloseIcon />
          </IconButton> */}
          {props.notifications?.filter((x) => x.readAt)[0] && (
            <Button
              onClick={() => props.clear()}
              variant="outlined"
              style={{ borderRadius: 8, textTransform: 'none', marginLeft: 16, color: red[500] }}
              startIcon={<DeleteOutlineRounded />}
            >
              Clear read
            </Button>
          )}
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          // sx={{ padding: '2px 16px', borderBottom: '1px solid #e0e0e0', flexWrap: 'nowrap' }}
          sx={{ padding: '2px 16px', flexWrap: 'nowrap' }}
        >
          <Grid container alignItems="center" spacing={1} margin={0} width={'auto'}>
            <Tabs indicatorColor="primary" textColor="primary" value={tab} sx={{ mb: 1, width: '100%' }}>
              {tabList?.map((tab) => (
                <Tab label={tab.label} value={tab.value} onClick={() => setTab(tab.value)} />
              ))}
            </Tabs>
          </Grid>
        </Grid>

        {/* Notifications List */}
        <List sx={{ padding: 0, overflow: 'auto', maxHeight: '450px' }}>
          {/* {!props.notifications[0] && (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Typography variant="h6">You don't have any notifications</Typography>
            </div>
          )} */}
          {tab === 'all' &&
            (props?.notifications?.length > 0 ? (
              props.notifications?.map((notification) => (
                <React.Fragment key={notification.id}>
                  <ListItem
                    key={notification._id}
                    onClick={(e) => {
                      e.stopPropagation();
                      if ((notification.type !== 'newTask' || notification.body?.toCreator) && !notification.readAt) {
                        props.read(notification._id);
                      }
                    }}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {FormatNotification({
                      notification: notification,
                      read: props.read,
                      clear: props.clear,
                      hideButton: props.hideButton,
                      unread: props.unread,
                    })}
                  </ListItem>
                  <Divider variant="inset" component="li" sx={{ margin: '0px 25px' }} />
                </React.Fragment>
              ))
            ) : (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <Typography variant="h6">You don't have any notifications</Typography>
              </div>
            ))}
          {tab === 'task_mention' &&
            (props?.notifications?.filter((data) => [TASK_MENTION, MENTION_EVENT].includes(data.type)).length > 0 ? (
              props?.notifications
                .filter((data) => [TASK_MENTION, MENTION_EVENT].includes(data.type))
                ?.map((notification) => (
                  <React.Fragment key={notification.id}>
                    <ListItem
                      key={notification._id}
                      onClick={(e) => {
                        e.stopPropagation();
                        if ((notification.type !== 'newTask' || notification.body?.toCreator) && !notification.readAt) {
                          props.read(notification._id);
                        }
                      }}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {FormatNotification({
                        notification: notification,
                        read: props.read,
                        clear: props.clear,
                        hideButton: props.hideButton,
                        unread: props.unread,
                      })}
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{ margin: '0px 25px' }} />
                  </React.Fragment>
                ))
            ) : (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <Typography variant="h6">You don't have any notifications</Typography>
              </div>
            ))}
          {tab === 'unread' &&
            (props?.notifications?.filter((x) => !x.readAt).length > 0 ? (
              props?.notifications
                ?.filter((x) => !x.readAt)
                ?.map((notification) => (
                  <React.Fragment key={notification.id}>
                    <ListItem
                      key={notification._id}
                      onClick={(e) => {
                        e.stopPropagation();
                        if ((notification.type !== 'newTask' || notification.body?.toCreator) && !notification.readAt) {
                          props.read(notification._id);
                        }
                      }}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {FormatNotification({
                        notification: notification,
                        read: props.read,
                        clear: props.clear,
                        hideButton: props.hideButton,
                        unread: props.unread,
                      })}
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{ margin: '0px 25px' }} />
                  </React.Fragment>
                ))
            ) : (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <Typography variant="h6">You don't have any notifications</Typography>
              </div>
            ))}
        </List>

        {/* Footer Section */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: 2, borderTop: '1px solid #e0e0e0' }}
        >
          <Grid display="flex" justifyContent="flex-start" alignItems="center">
            <IconButton onClick={() => history.push('/notifications/settings')}>
              <SettingsOutlinedIcon
                size="small"
                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
              />
            </IconButton>

            {props.notifications?.filter((x) => !x.readAt)[0] && (
              <Button
                size="small"
                variant="text"
                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', marginLeft: '6px' }}
                onClick={() => props.read()}
              >
                <DoneAllIcon sx={{ fontSize: 20, marginRight: 1 }} />
                Mark all as read
              </Button>
            )}
          </Grid>
          <Grid display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              size="small"
              variant="contained"
              color="primary"
              sx={{ textTransform: 'none' }}
              onClick={() => history.push('/notifications')}
            >
              View all
            </Button>
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
}
