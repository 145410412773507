import React, { useState } from 'react';
import { DialogTitle, DialogContent, TextField } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import axios from 'axios';

export default function FailTaskDialog({ open, onClose, onResult, taskId }) {
  const [reason, setReason] = useState('');
  const [saving, setSaving] = useState(false);

  function markAsFailed() {
    setSaving(true);
    axios
      .post('/tasks/markAsFailed', { taskId, reason })
      .then(() => {
        StateManager.setSuccessAlert('Task failed successfully');
        setSaving(false);
        onResult();
        onClose();
      })
      .catch(() => {
        setSaving(false);
        StateManager.setErrorAlert('Failed to mark the task as failed');
      });
  }

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={() => onClose(null)}>
      <DialogTitle>Please provide a reason</DialogTitle>

      <DialogContent>
        <TextField
          variant="outlined"
          label="Reason"
          multiline
          minRows={6}
          maxRows={6}
          fullWidth
          onChange={(event) => setReason(event.target.value)}
        />
      </DialogContent>
      <StandardDialogActions saving={saving} onClose={onClose} hideDone={!reason} onDone={markAsFailed} />
    </RoundedDialog>
  );
}
