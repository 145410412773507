import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUploaderAreas } from '../../redux/actions';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { TreeView } from '@mui/x-tree-view/TreeView';
import Box from '@mui/material/Box';

import FolderIcon from '@mui/icons-material/Folder';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import { ExpandMoreRounded, ChevronRightRounded } from '@mui/icons-material';

import StyledTreeItem from '../StyledTreeItem';
import { COLORS } from '../../constants';

function FolderPicker({
  title = 'Where are you saving the files?',
  folder,
  excludePersonal,
  handleConfirm,
  refLinkComponent,
  sx,
}) {
  const dispatch = useDispatch();
  const { tree, areasLoading } = useSelector(({ path }) => path);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const handleOptionsOnLoad = useCallback(() => {
    dispatch(getUploaderAreas());
  }, [dispatch]);

  useEffect(() => {
    handleOptionsOnLoad();
  }, [handleOptionsOnLoad]);

  function renderTree(node, root) {
    if (folder?._id === node?._id) {
      return null;
    }
    return (
      <StyledTreeItem
        key={node._id}
        nodeId={node._id}
        labelText={node.name}
        labelIcon={FolderIcon}
        onClick={(e) => {
          setBreadcrumbs([
            { id: root._id, name: root.name },
            { id: node._id, name: node.name },
          ]);
          const data = {
            area: root,
            folder: node,
            root: !Boolean(node),
          };
          refLinkComponent ? handleConfirm(data, e) : handleConfirm(data);
        }}
        color={COLORS.WHITE}
        bgColor={COLORS.MAINBLUE}
      >
        {Array.isArray(node.children) ? node.children.map((n) => renderTree(n, root)) : null}
      </StyledTreeItem>
    );
  }

  return (
    <Grid container direction="column" sx={sx}>
      {title && <Typography variant="h6">{title}</Typography>}
      <Grid container>
        <Grid item sm>
          <Breadcrumbs aria-label="breadcrumb" separator=">" maxItems={2}>
            {breadcrumbs.map((x) => (
              <Typography key={x.id}>{x.name}</Typography>
            ))}
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid dividers>
        {tree && tree.length > 0 && (
          <Grid container>
            <TreeView
              sx={{ height: 400, flexGrow: 1, maxWidth: '100%', overflowY: 'scroll' }}
              defaultCollapseIcon={<ExpandMoreRounded />}
              defaultExpandIcon={<ChevronRightRounded />}
              defaultEndIcon={<Box sx={{ width: 24 }} />}
            >
              {tree
                ?.filter((x) => (excludePersonal ? !x.personal : x))
                ?.map((area) => (
                  <StyledTreeItem
                    key={area._id}
                    nodeId={area._id}
                    labelText={area.name}
                    labelIcon={FolderSpecialIcon}
                    labelInfo={area.personal ? 'Personal' : area.openAccess ? 'Public' : 'Private'}
                    color={COLORS.WHITE}
                    bgColor={COLORS.MAINBLUE}
                    onClick={(e) => {
                      setBreadcrumbs([{ id: area._id, name: area.name }]);
                      const data = {
                        area: area,
                        folder: null,
                        root: true,
                      };
                      refLinkComponent ? handleConfirm(data, e) : handleConfirm(data);
                    }}
                  >
                    {Array.isArray(area.children) ? area.children.map((n) => renderTree(n, area)) : null}
                  </StyledTreeItem>
                ))}
            </TreeView>
          </Grid>
        )}
        {refLinkComponent && tree.length === 0 && !areasLoading && (
          <Grid container>
            <Typography variant="h6" color="text.secondary" textAlign={'center'} margin={'0 aut'}>
              No folders found. Please create a new folder.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default FolderPicker;
