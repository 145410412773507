/**
 * * Reducer for Revisions, follows the cases as per relevant actions
 */

import {
  LINK_ATTEMPT,
  LINK_ERROR,
  LINK_SUCCESS,
  REVISION_ATTEMPT,
  REVISION_ERROR,
  INBOUND_SUCCESS,
  SETANNOTATION_COUNT,
  SETSUPPORTINGDOCS,
} from '../constants';

const initialState = {
  listLoading: false,
  linkDocument: null,
  linkLoading: false,
  linkError: null,
  inboundLinks: [],
  pdfLinks: [],
  error: null,
  annotationCount: 0,
  supportingDocs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LINK_ATTEMPT:
      return { ...state, linkLoading: true, linkDocument: null };
    case LINK_ERROR:
      return { ...state, linkLoading: false, linkError: action.payload };
    case LINK_SUCCESS:
      return { ...state, linkLoading: false, linkDocument: action.payload, linkError: null };
    case REVISION_ATTEMPT:
      return { ...state, listLoading: true };
    case INBOUND_SUCCESS:
      return { ...state, listLoading: false, inboundLinks: action.payload.qLinks, pdfLinks: action.payload.pdfLinks };
    case REVISION_ERROR:
      return { ...state, listLoading: false, error: action.payload };
    case SETANNOTATION_COUNT:
      return { ...state, annotationCount: action.payload };
    case SETSUPPORTINGDOCS:
      return { ...state, supportingDocs: action.payload };
    default:
      return state;
  }
}
