import { forwardRef, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { EditorBlock } from 'draft-js';

import 'emoji-mart/css/emoji-mart.css';
import { TwitterPicker } from 'react-color';
import { Typography, Menu, MenuItem, ListItemIcon, ListItemText, Box } from '@mui/material';

import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green } from '@mui/material/colors';
import { lightGreen, lime, yellow, amber, orange, deepOrange, grey, blueGrey } from '@mui/material/colors';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import GridOnIcon from '@mui/icons-material/GridOn';
import TocIcon from '@mui/icons-material/Toc';
import RemoveIcon from '@mui/icons-material/Remove';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import TitleIcon from '@mui/icons-material/Title';

const Picker = lazy(() => import('emoji-mart').then((module) => ({ default: module.Picker })));

import EditorToolbar from './EditorToolbar';

export const StyledToggleButtonGroup = ({ children, ...props }) => (
  <ToggleButtonGroup
    {...props}
    sx={{
      margin: (theme) => theme.spacing(0),
      border: 'none',
      '&:not(:first-child)': {
        borderRadius: (theme) => theme.shape.borderRadius,
      },
      '&:first-child': {
        borderRadius: (theme) => theme.shape.borderRadius,
      },
    }}
  >
    {children}{' '}
  </ToggleButtonGroup>
);

export const TOC_TYPES = ['header-one', 'header-two', 'header-three', 'header-four', 'header-five', 'header-six'];

export const BLOCK_TYPES = [
  { label: 'Normal', style: 'unstyled' },
  { label: 'Heading 1', style: 'header-one' },
  { label: 'Heading 2', style: 'header-two' },
  { label: 'Heading 3', style: 'header-three' },
  { label: 'Heading 4', style: 'header-four' },
  { label: 'Heading 5', style: 'header-five' },
  { label: 'Heading 6', style: 'header-six' },
];

export const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: <FormatBoldIcon /> },
  { label: 'Italic', style: 'ITALIC', icon: <FormatItalicIcon /> },
];

export const MORE_INLINE_STYLES = [
  { label: 'Underline', style: 'UNDERLINE', icon: <FormatUnderlinedIcon sx={{ height: 16, width: 16 }} /> },
  { label: 'Strikethrough', style: 'STRIKETHROUGH', icon: <FormatUnderlinedIcon sx={{ height: 16, width: 16 }} /> },
  { label: 'Monospace', style: 'CODE', icon: <CodeIcon sx={{ height: 16, width: 16 }} /> },
];

export const MORE_MENU = [
  { label: 'Table of Content', value: 'table-content', icon: <TocIcon sx={{ height: 16, width: 16 }} /> },
  { label: 'Add Header/Footer', value: 'header-footer', icon: <TitleIcon sx={{ height: 16, width: 16 }} /> },
  { label: 'Add a Table', value: 'table', icon: <GridOnIcon sx={{ height: 16, width: 16 }} /> },
  // { label: 'References Table', value: 'ref-table', icon: <ListIcon /> },
  { label: 'Page Divider', value: 'divider', icon: <RemoveIcon sx={{ height: 16, width: 16 }} /> },
];

export const FORMAT_STYLES = [
  { label: <FormatAlignLeftIcon sx={{ height: 16, width: 16 }} />, value: 'align-left' },
  { label: <FormatAlignCenterIcon sx={{ height: 16, width: 16 }} />, value: 'align-center' },
  { label: <FormatAlignRightIcon sx={{ height: 16, width: 16 }} />, value: 'align-right' },
  { label: <FormatAlignJustifyIcon sx={{ height: 16, width: 16 }} />, value: 'justify' },
];

export const INDENT_STYLES = [
  {
    value: 'depth-increase',
    label: <FormatIndentIncreaseIcon sx={{ height: 16, width: 16 }} />,
  },
  {
    value: 'depth-decrease',
    label: <FormatIndentDecreaseIcon sx={{ height: 16, width: 16 }} />,
  },
];

export const LIST_STYLES = [
  {
    value: 'unordered-list-item',
    label: <FormatListBulletedIcon sx={{ height: 16, width: 16 }} />,
  },
  {
    value: 'ordered-list-item',
    label: <FormatListNumberedIcon sx={{ height: 16, width: 16 }} />,
  },
];

export const COLORS = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
  '#000000',
];

const column = 400;

const colours = [
  red[column],
  pink[column],
  purple[column],
  deepPurple[column],
  indigo[column],
  blue[column],
  lightBlue[column],
  cyan[column],
  teal[column],
  green[column],
  lightGreen[column],
  lime[column],
  yellow[column],
  amber[column],
  orange[column],
  deepOrange[column],
  grey[column],
  blueGrey[column],
];

export const colorStyleMap = () => {
  const styleMap = {};
  colours.map((c) => {
    styleMap[`color-${c.replace('#', '')}`] = { color: c };
  });
  return styleMap;
};

export const getColor = (hex) => {
  return `color-${hex.toUpperCase().replace('#', '')}`;
};

// Custom overrides for "code" style.
export const styleMap = {
  CODE: {
    fontFamily: '"Source Pro", "Menlo", "Consolas", monospace',
    fontSize: '14px',
    padding: '3px 6px',
  },
  ...colorStyleMap(),
};

export function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'align-left':
      return 'RichEditor-left';
    case 'align-right':
      return 'RichEditor-right';
    case 'align-center':
      return 'RichEditor-center';
    case 'justify':
      return 'RichEditor-justify';

    default:
      return null;
  }
}

export function DividerComponent({ block, className, theme = {}, ...otherProps }) {
  const {
    blockProps,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    contentState,
    blockStyleFn,
    preventScroll,
    ...elementProps
  } = otherProps;
  return (
    <Divider
      {...elementProps}
      sx={{
        height: 3,
        backgroundColor: 'gray',
        margin: '0.5rem 70px',
        width: 'auto',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  );
}

function AtomicBlock(props) {
  return (
    <Box sx={{ margin: 0 }}>
      <EditorBlock {...props} />
    </Box>
  );
}

export function getBlockRenderFn(block) {
  switch (block.getType()) {
    case 'atomic':
      return {
        component: AtomicBlock,
        editable: false,
      };
    default:
      return null;
  }
}

export const MoreFormatMenu = ({ open, handleClose, handleClick }) => {
  return (
    <Menu
      disablePortal
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
      disableAutoFocusItem={true}
      autoFocus={false}
      id="more-format-menu"
      anchorEl={open}
      keepMounted
      open={Boolean(open)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {MORE_INLINE_STYLES.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            handleClick(item.style);
            handleClose();
          }}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export const EmojiMenu = forwardRef(({ open, handleClose, handleClick }, ref) => (
  <Menu
    ref={ref}
    disablePortal
    disableAutoFocus={true}
    disableEnforceFocus={true}
    disableRestoreFocus={true}
    disableAutoFocusItem={true}
    autoFocus={false}
    id="emoji-format-menu"
    anchorEl={open}
    keepMounted
    open={Boolean(open)}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    sx={{
      '& .MuiList-root': {
        py: 0,
      },
    }}
  >
    <Suspense
      fallback={
        <Typography variant="h6" color="textSecondary" sx={{ margin: 8 }}>
          Loading...
        </Typography>
      }
    >
      <Picker
        ref={ref}
        onSelect={(e) => {
          handleClick(e.native);
          handleClose();
        }}
        color={'#2196f3'}
        native
        emoji={'memo'}
        title={'Pick Emoji ...'}
        theme={'auto'}
        enableFrequentEmojiSort
      />
    </Suspense>
  </Menu>
));

export const ColorMenu = ({ open, handleClose, handleClick }) => (
  <Menu
    disablePortal
    disableAutoFocus={true}
    disableEnforceFocus={true}
    disableRestoreFocus={true}
    disableAutoFocusItem={true}
    autoFocus={false}
    id="color-format-menu"
    anchorEl={open}
    keepMounted
    open={Boolean(open)}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    sx={{
      '& .MuiList-root': {
        py: 0,
      },
    }}
  >
    <TwitterPicker
      colors={COLORS}
      onChange={({ hex }) => handleClick(getColor(hex))}
      styles={{ default: { input: { display: 'none' }, hash: { display: 'none' } } }}
    />
  </Menu>
);

export const MoreMenu = ({ open, hasToc, handleClose, handleClick }) => {
  const { defaultHeaderFooter } = useSelector(({ settings }) => settings);

  const getTOCLabel = () => {
    if (hasToc) {
      return 'Disable Table of Content';
    } else {
      return 'Enable Table of Content';
    }
  };

  return (
    <Menu
      disablePortal
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
      disableAutoFocusItem={true}
      autoFocus={false}
      id="more-menu"
      anchorEl={open}
      keepMounted
      open={Boolean(open)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{ marginTop: '5px' }}
    >
      <Typography variant="caption" sx={{ padding: '20px', color: 'gray' }}>
        Additional document features
      </Typography>
      {MORE_MENU.filter((item) => (defaultHeaderFooter ? item.value !== 'header-footer' : true)).map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            handleClick(item.value);
            handleClose();
          }}
        >
          <ListItemIcon fontSize="small">{item.icon}</ListItemIcon>
          <ListItemText primary={item.label === 'Table of Content' ? getTOCLabel() : item.label} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export const SearchHighlight = (props) => <span className="search-and-replace-highlight">{props.children}</span>;

export { EditorToolbar };
