import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloudDownload from '@mui/icons-material/CloudDownload';
import PrimaryButton from '../PrimaryButton';

export default function NoSupport({ src }) {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <Grid
        item
        container
        direction="column"
        sm={8}
        xs={12}
        component={Paper}
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '20px' }}
      >
        <Typography sx={{ marginBottom: '20px', textAlign: 'center' }}>
          Sorry this file is not supported by the viewer. Would you like to download the file?
        </Typography>
        <PrimaryButton title="Download" startIcon={<CloudDownload />} action={() => window.open(src)} />
      </Grid>
    </Grid>
  );
}
