import React from 'react';

import { FileCopy, DateRange, CheckCircle, Cancel, ThumbUpSharp, LibraryBooksRounded } from '@mui/icons-material';
import { TableChartRounded, NotificationsActiveRounded, ChatRounded, WarningRounded } from '@mui/icons-material';
import { PersonRounded } from '@mui/icons-material';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Tooltip, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { TaskIcon, SupportIcon, ProjectIcon, ApprovalIcon, ProcessIcon, AssetIcon, RegistersIcon } from '../Icons';
import { RoutineIcon, FormIcon, DocIcon, AuditIcon } from '../Icons';
import { FormatDate } from '../Functions';
import StateManager from '../StateManager';
import { blue, red, green, deepPurple, grey, amber } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { UserChip, DateFormatter } from '../Components';
import moment from 'moment';
import axios from 'axios';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CloseIcon from '@mui/icons-material/Close';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';

const TASK_ACCEPTED = 'taskAccepted';
const TASK_REJECTED = 'taskRejected';
const FINISH_REVIEW = 'finishReview';
const NEW_COMMENT_EVENT = 'newComment';
const TASK_DELETED_EVENT = 'taskDeleted';
const ASSIGNEE_CHANGED_EVENT = 'assigneeChanged';
const TASK_IS_OVERDUE_EVENT = 'taskIsOverdue';
const NEW_POST = 'newPost';
const FORM_SHARED = 'formShared';
const SUB_TASK_STATUS_CHANGED = 'subTaskStatusChanged';
const SUB_TASK_NEW_COMMENT = 'subTaskNewComment';
const NEW_SUB_TASK = 'newSubTask';
const TASK_REMINDER = 'taskReminder';
const WATCH_INVITE = 'watchInvite';
const TASK_WATCHED = 'taskWatched';
const PROJECT_TASK_ADDED = 'projectTaskAdded';
const TASK_OWNER_CHANGED = 'taskOwnerChanged';
const ADDED_TO_PROJECT = 'addedToProject';
const REMOVED_FROM_PROJECT = 'removedFromProject';
const NEW_ROUTINE_TASK = 'newRoutineTask';
const NEW_RU_TASK = 'newRuTask';
const NEW_DL_REVIEW = 'newDLReview';
const NEW_DL_REVIEW_ASSIGNEE = 'newDLReviewAssignee';
const NEW_DL_AUTOMATED_REVIEW = 'newDLAutomatedReview';
const COMPLETE_DL_REVIEW = 'completeDLReview';

// === audits ===
const NEW_AUDIT = 'newAudit';
const AUDIT_STATE_CHANGED = 'auditStateChanged';
const AUDIT_RESCHEDULED = 'auditRescheduled';
const AUDIT_OBSERVATION = 'auditObservation';
const AUDIT_STARTED = 'auditStarted';
const AUDIT_FINISHED = 'auditFinished';
const AUDIT_CLOSED = 'auditClosed';
const AUDIT_CANCELLED = 'auditCancelled';
const NCR_CANCELLED = 'ncrCancelled';
const AUDIT_ACTION_OVERDUE = 'auditActionOverdue';

// === support ===
const TICKET_REGISTERED = 'ticketRegistered';
const TICKET_NEW_COMMENT = 'ticketNewComment';
const TICKET_STATUS_CHANGED = 'ticketStatusChanged';
const TICKET_RESPONSE = 'ticketResponse';
const TICKET_CLOSED = 'ticketClosed';

// === dochub ===
const AREASETTINGS_UPDATE = 'areaUpdate';
const FOLDERSETTINGS_UPDATE = 'folderUpdate';
const FOLDERSETTINGS_REQUEST = 'folderRequest';
const AREASETTINGS_REQUEST = 'areaRequest';
const REVIEW_CANCEL = 'reviewCancel';
const NEW_ISSUE_TO_OWNER = 'newIssueToOwner';
const ISSUE_CANCEL = 'issueCancel';
const ISSUE_UPDATE = 'issueUpdate';
const LIKECOMMENT = 'commentLike';
const ISSUE_REVIEW = 'issueReview';
const NEW_ISSUE_REVIEW = 'newIssueReview';
const CANCEL_ISSUE_REVIEW = 'cancelIssueReview';
const DOC_OWNER_CHANGED = 'documentOwnerChanged';
const DOC_LINK_DELETED = 'linkedFileDeleted';

// === approvals ===
const NEW_APPROVAL = 'newApproval';
const APPROVAL_APPROVED = 'approvalApproved';
const APPROVAL_REJECTED = 'approvalRejected';
const USER_APPROVAL_APPROVED = 'userApprovalApproved';
const USER_APPROVAL_REJECTED = 'userApprovalRejected';
const APPROVAL_CANCELLED = 'approvalCancelled';

// === process ====
const PROCESS_STATE_CHANGED = 'processStateChanged';
const STEP_SENT = 'stepSent';
const STEP_STARTED = 'stepStarted';
const STEP_COMPLETE = 'stepComplete';
const STEP_OWNER_CHANGED = 'stepOwnerChanged';
const STEP_OVERDUE = 'stepOverdue';
const STEP_REMINDER = 'stepReminder';

// === mention ====
const TASK_MENTION = 'taskMention';
const MENTION_EVENT = 'mentionEvent';

// === asset ===
const ASSET_EXPIRY = 'assetExpiry';
const ASSET_EXPIRED = 'assetExpired';
const ASSET_OVERDUE = 'assetOverdue';
const ASSET_REPORT_SHARED = 'assetReportShared';
const ASSET_BOOKED_OUT = 'assetBookedOut';
const ASSET_BOOKED_IN = 'assetBookedIn';

const ASSET_UNAVAILABLE = 'assetUnavailable';
const RETURN_ASSET = 'returnAsset';
const ASSET_RECALL_STARTED = 'assetRecallStarted';
const ASSET_RECALL_COMPLETED = 'assetRecallCompleted';
const ASSET_RECALL_CANCELLED = 'assetRecallCancelled';
const ASSET_STATUS_CHANGE = 'assetStatusChange';
const ASSET_PRE_BOOKING = 'assetPreBooking';
const ASSET_PRE_BOOKING_REMINDER = 'assetPreBookingReminder';
const ASSET_ARCHIVED = 'assetArchived';
const ASSET_UNARCHIVED = 'assetUnarchived';

// === forms ===
const ENTRY_SENT = 'entrySent';
const ENTRY_STARTED = 'entryStarted';
const ENTRY_SUBMITTED = 'entrySubmitted';
const ROW_STATUS = 'rowStatus';
const FORM_REMINDER = 'formReminder';
const FORM_ENTRY_EDIT = 'formEdit';
const PROCESS_ENTRY_EDIT = 'processEdit';

// === reviews ===
const NEW_REVIEW = 'newReview';

const QHUB_ACCESS_REQUESTED = 'qhubAccessRequested';

const OTHER = 'other';

const BoldSpan = styled('span')({ fontWeight: 600 });

const taskStatuses = {
  'not started': grey[500],
  started: deepPurple[500],
  working: blue[500],
  held: amber[500],
  complete: green[600],
  cancelled: red[500],
  failed: red[500],
};

function getStatusColor(status) {
  let text = String(status).toLowerCase();
  return taskStatuses[text] || grey[500];
}

const icons = {
  register: <RegistersIcon />,
};

function getIcon(icon) {
  return icons[icon] || <NotificationsActiveRounded style={{ color: grey[500] }} />;
}

export default function FormatNotification({ notification, read, clear, hideButton, unread }) {
  const history = useHistory();

  if (notification.type === 'newTask') {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            {notification.body.toCreator ? (
              <>
                You were sent task <BoldSpan>{notification.body.title}</BoldSpan>
              </>
            ) : (
              <>
                <BoldSpan>{notification.body.senderName}</BoldSpan> has sent you task{' '}
                <BoldSpan>{notification.body.title}</BoldSpan>
              </>
            )}
          </>
        }
      />
    );
  }

  if (notification.type === NEW_DL_REVIEW) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.openReviewDialog(notification.body.reviewId)}
        icon={<DocIcon />}
        content={
          <>
            <BoldSpan>{notification.body.senderName}</BoldSpan> has started a review on{' '}
            <BoldSpan>{notification.body.title}</BoldSpan> that you are the owner of
          </>
        }
      />
    );
  }

  if (notification.type === NEW_DL_REVIEW_ASSIGNEE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.openReviewDialog(notification.body.reviewId)}
        icon={<DocIcon />}
        content={
          <>
            You have been asked to review the document <BoldSpan>{notification.body.title}</BoldSpan> by{' '}
            <BoldSpan>{notification.body.senderName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_DL_AUTOMATED_REVIEW) {
    const isUpIssue = notification.body.upissue;
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.openReviewDialog(notification.body.reviewId)}
        icon={<DocIcon />}
        content={
          isUpIssue ? (
            <>
              A review is scheduled for <BoldSpan>{notification.body.title}</BoldSpan> which is currently being up
              issued - this review will be automatically competed as a result
            </>
          ) : notification.body.toCreator ? (
            <>
              <BoldSpan>{notification.body.userNames}</BoldSpan> {notification.body.count > 1 ? 'have' : 'has'} been
              sent a scheduled review on <BoldSpan>{notification.body.title}</BoldSpan> that you are the owner of.
            </>
          ) : (
            <>
              You have been sent an automated task to review document - <BoldSpan>{notification.body.title}.</BoldSpan>{' '}
              This review is due on <BoldSpan>{notification.body.dueDate}</BoldSpan>
            </>
          )
        }
      />
    );
  }

  if (notification.type === COMPLETE_DL_REVIEW) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.openReviewDialog(notification.body.reviewId)}
        icon={<DocIcon />}
        content={
          <>
            A review has been completed by <BoldSpan>{notification.body.users}</BoldSpan> on{' '}
            <BoldSpan>{notification.body.title}.</BoldSpan>
            The review <BoldSpan>{notification.body.upIssueTriggered ? 'did ' : 'did not '}</BoldSpan> result in a
            change being needed.
          </>
        }
      />
    );
  }

  if (notification.type === 'taskIsDue') {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            Task <BoldSpan>{notification.body.taskName}</BoldSpan> is due{' '}
            <BoldSpan style={{ color: '#ff6d00' }}>{notification.body.due}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === 'taskStatusChanged') {
    const status = notification.body.newStatus;

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.assigneeName}</BoldSpan> changed the status to{' '}
            <BoldSpan style={{ color: notification.body.statusColor || getStatusColor(status) }}>{status}</BoldSpan> for
            task <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === 'taskCompleted') {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.assigneeName}</BoldSpan> has{' '}
            <BoldSpan style={{ color: green[600] }}>completed</BoldSpan> task{' '}
            <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TASK_ACCEPTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.senderName}</BoldSpan> has{' '}
            <BoldSpan style={{ color: green[600] }}>accepted</BoldSpan> task{' '}
            <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TASK_REJECTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.senderName}</BoldSpan> has{' '}
            <BoldSpan style={{ color: red[600] }}>rejected</BoldSpan> task{' '}
            <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === DOC_OWNER_CHANGED) {
    const documentLink = notification.body.link;

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FileCopy />}
        onClick={() => history.push(documentLink)}
        content={
          <>
            {notification.body.text} <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_ISSUE_TO_OWNER) {
    const issueEditorlink = notification.body.link;
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FileCopy />}
        onClick={() => history.push(issueEditorlink)}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan> {notification.body.text}{' '}
            <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_ISSUE_REVIEW) {
    const issueReviewlink = notification.body.link;
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FileCopy />}
        onClick={() => history.push(issueReviewlink)}
        content={
          <>
            You have been <BoldSpan style={{ color: green[500] }}>asked</BoldSpan> to review{' '}
            <BoldSpan>{notification.body.title}</BoldSpan> by <BoldSpan>{notification.body.senderName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === CANCEL_ISSUE_REVIEW) {
    const issueReviewlink = notification.body.link;
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<RemoveCircleIcon />}
        onClick={() => history.push(issueReviewlink)}
        content={
          <>
            You have been <BoldSpan style={{ color: red[500] }}>removed</BoldSpan> as a <BoldSpan>reviewer</BoldSpan>{' '}
            from <BoldSpan>{notification.body.title}</BoldSpan> by <BoldSpan>{notification.body.senderName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === DOC_LINK_DELETED) {
    const linkType = notification.body.type;
    const linkId = notification.body.id;
    const linkTitle = notification.body.linkTitle;
    const sourceTitle = notification.body.title;

    const openLinkFile = () => {
      if (linkType === 'document') {
        history.push(`/DocLibrary/preview/${linkId}`);
      }
      if (linkType === 'form') {
        history.push(`/forms/${linkId}`);
      }
      if (linkType === 'process') {
        history.push(`/processes/${linkId}`);
      }
      if (linkType === 'register') {
        history.push(`/registers/${linkId}`);
      }
      return;
    };

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<Cancel />}
        onClick={() => openLinkFile()}
        content={
          <>
            The <BoldSpan style={{ color: blue[500] }}>document - {sourceTitle}</BoldSpan> which was linked to your{' '}
            <BoldSpan style={{ color: blue[500] }}>
              {linkType} - {linkTitle}
            </BoldSpan>{' '}
            has been deleted.
          </>
        }
      />
    );
  }

  if (notification.type === ISSUE_REVIEW) {
    const issueReviewlink = notification.body.link;

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FileCopy />}
        onClick={() => history.push(issueReviewlink)}
        content={
          <>
            <BoldSpan>{notification.body.title}</BoldSpan>
            {'  '}
            has been
            {'  '}
            <BoldSpan style={{ color: notification.body.status === 'approved' ? green[500] : red[500] }}>
              {notification.body.status}
            </BoldSpan>
            {'  '}
            by
            {'  '}
            <BoldSpan>{notification.body.assigneeName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === ISSUE_UPDATE) {
    const issueLink = notification.body.link;

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FileCopy />}
        onClick={() => history.push(issueLink)}
        content={
          <>
            <BoldSpan>{notification.body.title}</BoldSpan>
            {'  '}
            {notification.body.text}
            {'  '}
            <BoldSpan>{notification.body.version}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === 'linkUpdate') {
    const linkDoc = notification.body.link;
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FileCopy />}
        onClick={() => history.push(linkDoc)}
        content={
          <>
            <BoldSpan>{notification.body.title}</BoldSpan>
            {'  '}
            {notification.body.text}
          </>
        }
      />
    );
  }

  if (notification.type === AREASETTINGS_REQUEST || notification.type === FOLDERSETTINGS_REQUEST) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FolderSpecialOutlinedIcon />}
        onClick={() => history.push(notification.body.link)}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan>
            {'  '}
            {notification.body.title}
            {'  '}
            <BoldSpan>
              {notification.type === AREASETTINGS_REQUEST ? notification.body.area : notification.body.folder}
            </BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === AREASETTINGS_UPDATE || notification.type === FOLDERSETTINGS_UPDATE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FolderSpecialOutlinedIcon />}
        onClick={() => history.push(notification.body.link)}
        content={
          <>
            {notification.body.title}
            {'  '}
            <BoldSpan>
              {notification.type === AREASETTINGS_UPDATE ? notification.body.area : notification.body.folder}
            </BoldSpan>
            {'  '}
            {notification.body.text}
            {'  '}
            <BoldSpan>{notification.body.status}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === LIKECOMMENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<ThumbUpSharp style={{ color: 'ffa500' }} />}
        onClick={() => history.push(notification.body.link)}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan>
            {'  '}
            {notification.body.title}
            {'  '}
            <BoldSpan>{notification.body.document}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === FINISH_REVIEW) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FileCopy />}
        onClick={() => history.push(notification.body.link)}
        content={
          <>
            Please finish the review of document{'  '}
            <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === REVIEW_CANCEL || notification.type === ISSUE_CANCEL) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FileCopy />}
        content={
          <>
            <BoldSpan>{notification.body.title}</BoldSpan> {notification.body.text}
          </>
        }
      />
    );
  }

  if (notification.type === NEW_COMMENT_EVENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan> has commented on task{'  '}
            <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TASK_DELETED_EVENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan> has{'  '}
            <BoldSpan style={{ color: red[500] }}>deleted</BoldSpan> task{'  '}
            <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === ASSIGNEE_CHANGED_EVENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<TaskIcon />}
        onClick={() => StateManager.selectTask(notification.taskId)}
        content={
          <>
            <BoldSpan>{notification.body.oldAssigneeName}</BoldSpan> has changed the assignee to{'  '}
            <BoldSpan>{notification.body.newAssigneeName}</BoldSpan> for the task{'  '}
            <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TASK_IS_OVERDUE_EVENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<TaskIcon />}
        onClick={() => StateManager.selectTask(notification.taskId)}
        content={
          <>
            Task <BoldSpan>{notification.body.taskName}</BoldSpan> is{'  '}
            <BoldSpan style={{ color: red[500] }}>overdue</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_POST) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<DateRange style={{ color: blue[900] }} />}
        onClick={() => history.push(`/noticeBoard`)}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan>
            {'  '}
            has posted
            {'  '}
            <BoldSpan>{notification.body.postTitle}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === FORM_SHARED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        icon={<FormIcon />}
        content={
          <>
            Your form <BoldSpan>{notification.body.formName}</BoldSpan>
            {'  '}
            has been shared with
            {'  '}
            <BoldSpan>{notification.body.externalUser}</BoldSpan> by{'  '}
            <BoldSpan>{notification.body.userName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_SUB_TASK) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask({ taskId: notification.taskId, subTaskId: notification.body.subTaskId })}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan>
            {'  '}
            added sub task
            {'  '}
            <BoldSpan>{notification.body.subTaskName}</BoldSpan> to the task{'  '}
            <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === SUB_TASK_STATUS_CHANGED) {
    const status = String(notification.body.newStatus).toLowerCase();

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask({ taskId: notification.taskId, subTaskId: notification.body.subTaskId })}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan>
            {'  '}
            changed the status to
            {'  '}
            <BoldSpan style={{ color: getStatusColor(status) }}>{status}</BoldSpan> for sub task{'  '}
            <BoldSpan>{notification.body.subTaskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === SUB_TASK_NEW_COMMENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask({ taskId: notification.taskId, subTaskId: notification.body.subTaskId })}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan>
            {'  '}
            has commented on sub task
            {'  '}
            <BoldSpan>{notification.body.subTaskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_AUDIT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/audits/${notification.body.auditId}`)}
        icon={<AuditIcon />}
        content={
          <>
            You have been assigned to audit{'  '}
            <BoldSpan>{notification.body.auditName}</BoldSpan>
            {'  '}
            {notification.body.role === 'auditor' ? 'as the' : 'as an'}
            {'  '}
            <BoldSpan>{notification.body.role}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === AUDIT_STARTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/audits/${notification.body.auditId}`)}
        icon={<AuditIcon />}
        content={
          <>
            Audit{'  '}
            <BoldSpan>{notification.body.auditName}</BoldSpan>
            {'  '}
            has{'  '}
            <BoldSpan>started</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === AUDIT_FINISHED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/audits/${notification.body.auditId}`)}
        icon={<AuditIcon />}
        content={
          <>
            Audit{'  '}
            <BoldSpan>{notification.body.auditName}</BoldSpan>
            {'  '}
            has been{'  '}
            <BoldSpan>finished</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === AUDIT_CLOSED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/audits/${notification.body.auditId}`)}
        icon={<AuditIcon />}
        content={
          <>
            Audit{'  '}
            <BoldSpan>{notification.body.auditTitle}</BoldSpan>
            {'  '}
            has been{'  '}
            <BoldSpan>closed</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === AUDIT_CANCELLED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/audits/${notification.body.auditId}`)}
        icon={<AuditIcon />}
        content={
          <>
            Audit{'  '}
            <BoldSpan>{notification.body.auditTitle}</BoldSpan>
            {'  '}
            has been{'  '}
            <BoldSpan>cancelled</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NCR_CANCELLED) {
    function onClick() {
      if (notification.body.ncrType === 'task') {
        StateManager.selectTask(notification.body.activityId);
      } else {
        const link =
          notification.body.ncrType === 'form'
            ? `/forms/entry/${notification.body.activityId}`
            : notification.body.ncrType === 'process'
            ? `/processes/ongoing/${notification.body.activityId}`
            : `/audits/${notification.body.auditId}`;
        history.push(link);
      }
    }

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={onClick}
        icon={<AuditIcon />}
        content={
          <>
            NCR{'  '}
            <BoldSpan>{notification.body.ncrTitle}</BoldSpan>
            {'  '}
            has been{'  '}
            <BoldSpan style={{ color: red[600] }}>cancelled</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === AUDIT_ACTION_OVERDUE) {
    const url = `/audits/${notification.body.auditId}?requirementId=${notification.body.requirementId}`;

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(url)}
        icon={<AuditIcon />}
        content={
          <>
            Action{'  '}
            <BoldSpan>{notification.body.actionTitle}</BoldSpan>
            {'  '}
            is{'  '}
            <BoldSpan style={{ color: red[600] }}>overdue</BoldSpan>
          </>
        }
        secondaryContent={<>Requirement: {notification.body.requirementTitle}</>}
      />
    );
  }

  if (notification.type === AUDIT_STATE_CHANGED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/audits/${notification.body.auditId}`)}
        icon={<AuditIcon />}
        content={
          <>
            Audit <BoldSpan>{notification.body.auditTitle}</BoldSpan> has been{' '}
            <BoldSpan>{notification.body.state}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === AUDIT_RESCHEDULED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/audits/${notification.body.auditId}`)}
        icon={<AuditIcon />}
        content={
          <>
            <BoldSpan>{notification.body.auditTitle}</BoldSpan> has been rescheduled for{' '}
            <BoldSpan>{notification.body.start}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === AUDIT_OBSERVATION) {
    const url = `/audits/${notification.body.auditId}?requirementId=${notification.body.requirementId}`;

    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(url)}
        icon={<AuditIcon />}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan> commented on{' '}
            <BoldSpan>{notification.body.requirementTitle}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TASK_REMINDER) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan> reminds you of task{' '}
            <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TICKET_REGISTERED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/support/${notification.body.ticketId}`)}
        icon={<SupportIcon />}
        content={
          <>
            Ticket <BoldSpan>{notification.body.ticketName}</BoldSpan> has been registered
          </>
        }
      />
    );
  }

  if (notification.type === TICKET_NEW_COMMENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/support/${notification.body.ticketId}`)}
        icon={<SupportIcon />}
        content={
          <>
            Ticket <BoldSpan>{notification.body.ticketName}</BoldSpan> has been commented on
          </>
        }
      />
    );
  }

  if (notification.type === TICKET_STATUS_CHANGED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/support/${notification.body.ticketId}`)}
        icon={<SupportIcon />}
        content={
          <>
            Status of ticket <BoldSpan>{notification.body.ticketName}</BoldSpan> has been changed to{' '}
            <BoldSpan>{notification.body.ticketStatus}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TICKET_RESPONSE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/support/${notification.body.ticketId}`)}
        icon={<SupportIcon />}
        content={
          <>
            You have a response for ticket <BoldSpan>{notification.body.ticketName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TICKET_CLOSED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/support/${notification.body.ticketId}`)}
        icon={<SupportIcon />}
        content={
          <>
            Ticket <BoldSpan>{notification.body.ticketName}</BoldSpan> has been{' '}
            <BoldSpan style={{ color: '#00c853' }}>closed</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === WATCH_INVITE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            User <BoldSpan>{notification.body.userName}</BoldSpan> invites you to watch task{' '}
            <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TASK_WATCHED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            Task <BoldSpan>{notification.body.taskName}</BoldSpan> is being watched by{' '}
            <BoldSpan>{notification.body.userName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === PROJECT_TASK_ADDED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<ProjectIcon />}
        content={
          <>
            Task <BoldSpan>{notification.body.taskName}</BoldSpan> has been added to project{' '}
            <BoldSpan>{notification.body.projectName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TASK_OWNER_CHANGED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<TaskIcon />}
        content={
          <>
            <BoldSpan>{notification.body.userName}</BoldSpan> assigned you as the owner of task{' '}
            <BoldSpan>{notification.body.taskName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === ADDED_TO_PROJECT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/tasks/projects/project/${notification.body.projectId}`)}
        icon={<ProjectIcon />}
        content={
          <>
            You were added to project <BoldSpan>{notification.body.projectName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === REMOVED_FROM_PROJECT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/tasks/projects/project/${notification.body.projectId}`)}
        icon={<ProjectIcon />}
        content={
          <>
            You were removed from project <BoldSpan>{notification.body.projectName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_APPROVAL) {
    const isDocumentApproval = notification?.body?.type === 'document';
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => notification.body.link && history.push(notification.body.link)}
        icon={<ApprovalIcon />}
        content={
          <>
            {isDocumentApproval ? (
              <>
                You have been asked to approve <BoldSpan>{notification.body.title}</BoldSpan> by{' '}
                <BoldSpan>{notification.body.user}</BoldSpan>
              </>
            ) : (
              <>
                You have new approval{notification.body.title ? ':' : ''}
                {'    '}
                {notification.body.title && <BoldSpan>{notification.body.title}</BoldSpan>}
              </>
            )}
          </>
        }
      />
    );
  }

  if (notification.type === APPROVAL_APPROVED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => notification.body.link && history.push(notification.body.link)}
        icon={<CheckCircle style={{ color: green[500] }} />}
        content={
          <>
            <BoldSpan>{notification.body.title}</BoldSpan> has been approved
          </>
        }
      />
    );
  }

  if (notification.type === APPROVAL_REJECTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => notification.body.link && history.push(notification.body.link)}
        icon={<Cancel style={{ color: red[500] }} />}
        content={
          <>
            <BoldSpan>{notification.body.title}</BoldSpan> has been rejected
          </>
        }
      />
    );
  }

  if (notification.type === USER_APPROVAL_APPROVED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => notification.body.link && history.push(notification.body.link)}
        icon={<CheckCircle style={{ color: green[500] }} />}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan> approved <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === USER_APPROVAL_REJECTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => notification.body.link && history.push(notification.body.link)}
        icon={<Cancel style={{ color: red[500] }} />}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan> rejected <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === APPROVAL_CANCELLED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => notification.body.link && history.push(notification.body.link)}
        icon={<Cancel style={{ color: red[500] }} />}
        content={
          <>
            <BoldSpan>{notification.body.title}</BoldSpan> has been cancelled
          </>
        }
      />
    );
  }

  if (notification.type === PROCESS_STATE_CHANGED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(notification.body.link)}
        icon={<ProcessIcon />}
        content={
          <>
            Process{'    '}
            <BoldSpan>{notification.body.title}</BoldSpan>
            {'    '}
            has been{'    '}
            <BoldSpan style={{ color: notification.body.color }}>{notification.body.state}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === STEP_SENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(notification.body.link)}
        icon={<ProcessIcon />}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan>
            {'    '}
            sent you step{'    '}
            <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === STEP_STARTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(notification.body.link)}
        icon={<ProcessIcon />}
        content={
          <>
            Step{'    '}
            <BoldSpan>{notification.body.title}</BoldSpan>
            {'    '}
            has been started
          </>
        }
      />
    );
  }

  if (notification.type === STEP_COMPLETE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(notification.body.link)}
        icon={<ProcessIcon />}
        content={
          <>
            Step{'    '}
            <BoldSpan>{notification.body.title}</BoldSpan>
            {'    '}
            has been completed
          </>
        }
      />
    );
  }

  if (notification.type === STEP_OWNER_CHANGED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(notification.body.link)}
        icon={<ProcessIcon />}
        content={
          <>
            Owner of the step{'    '}
            <BoldSpan>{notification.body.title}</BoldSpan>
            {'    '}
            has been changed
          </>
        }
      />
    );
  }

  if (notification.type === STEP_OVERDUE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(notification.body.link)}
        icon={<ProcessIcon />}
        content={
          <>
            Step{'    '}
            <BoldSpan>{notification.body.title}</BoldSpan>
            {'    '}
            is{'    '}
            <BoldSpan style={{ color: red[500] }}>overdue</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === TASK_MENTION) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask({ taskId: notification.taskId, subTaskId: notification.body.subTaskId })}
        icon={<TaskIcon />}
        content={
          <>
            You were mentioned in {notification.body.subTaskId ? 'sub ' : ''}task
            {'    '}
            <BoldSpan>{notification.body.taskTitle}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === MENTION_EVENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(notification.body.link)}
        icon={<ChatRounded color="primary" />}
        content={
          <>
            You were mentioned in {notification.body.type}
            {'    '}
            <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_EXPIRY) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<AssetIcon />}
        content={
          <>
            Asset{'    '}
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'    '}
            expires
            {notification.body.expiresIn > 1 ? ` in ${notification.body.expiresIn} days` : ' today'}
          </>
        }
        secondaryContent={notification.body.assetLocation && <>Asset location: {notification.body.assetLocation}</>}
      />
    );
  }

  if (notification.type === ASSET_EXPIRED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            Asset
            {'    '}
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'    '}
            has
            {'    '}
            <BoldSpan sx={{ color: red[600] }}>expired</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_OVERDUE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'    '}
            is overdue for
            {'    '}
            <BoldSpan>{notification.body.taskTitle}</BoldSpan>
            {'    '}
            {notification.body.showReturn && 'please return it as soon as possible'}
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_REPORT_SHARED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/reports/${notification.body.sharedReportId}`)}
        icon={<AssetIcon />}
        content={
          notification.body.user ? (
            <>
              <BoldSpan>{notification.body.user}</BoldSpan>
              {'  '}
              shared with you an asset report
            </>
          ) : (
            <>Asset report has been shared with you</>
          )
        }
      />
    );
  }

  if (notification.type === ASSET_BOOKED_OUT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'  '}
            has been booked OUT by
            {'  '}
            {notification.body.userId ? (
              <UserChip
                color="default"
                size="small"
                id={notification.body.userId}
                margin={0}
                style={{ marginRight: 8 }}
                component={'span'}
              />
            ) : (
              <BoldSpan>{notification.body.user}</BoldSpan>
            )}
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_ARCHIVED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'  '}
            has been archived by
            {'  '}
            <UserChip
              color="default"
              size="small"
              id={notification.body.userId}
              margin={0}
              style={{ marginLeft: 8 }}
              component={'span'}
            />
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_UNARCHIVED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'  '}
            has been unarchived by
            {'  '}
            <UserChip
              color="default"
              size="small"
              id={notification.body.userId}
              margin={0}
              style={{ marginLeft: 8 }}
              component={'span'}
            />
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_BOOKED_IN) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'  '}
            has been booked IN by
            {'  '}
            {notification.body.userId ? (
              <UserChip
                color="default"
                size="small"
                id={notification.body.userId}
                margin={0}
                style={{ marginRight: 8 }}
                component={'span'}
              />
            ) : (
              <BoldSpan>{notification.body.user}</BoldSpan>
            )}
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_UNAVAILABLE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<WarningRounded sx={{ color: amber[600] }} />}
        content={
          <>
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'  '}
            is no longer available
          </>
        }
      />
    );
  }

  if (notification.type === RETURN_ASSET) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<WarningRounded sx={{ color: amber[600] }} />}
        content={
          <>
            Please return asset
            {'  '}
            <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            {'  '}
            as soon as possible
            {notification.body.reason && ` - ${notification.body.reason}`}
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_RECALL_STARTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        disableTypography
        content={
          <Grid container alignItems={'center'}>
            <UserChip color="default" id={notification.body.userId} margin={0} />

            <Typography sx={{ ml: 1, fontSize: 14 }}>
              has started recalling asset <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            </Typography>
          </Grid>
        }
      />
    );
  }

  if (notification.type === ASSET_RECALL_COMPLETED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        disableTypography
        content={
          <Grid container alignItems={'center'}>
            <UserChip color="default" id={notification.body.userId} margin={0} />

            <Typography sx={{ ml: 1, fontSize: 14 }}>
              has completed recalling asset <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            </Typography>
          </Grid>
        }
      />
    );
  }

  if (notification.type === ASSET_RECALL_CANCELLED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        disableTypography
        content={
          <Grid container alignItems={'center'}>
            <UserChip color="default" id={notification.body.userId} margin={0} />

            <Typography sx={{ ml: 1, fontSize: 14 }}>
              has cancelled recalling asset <BoldSpan>{notification.body.assetTitle}</BoldSpan>
            </Typography>
          </Grid>
        }
      />
    );
  }

  if (notification.type === ASSET_STATUS_CHANGE) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            <UserChip
              color="default"
              size="small"
              id={notification.body.userId}
              margin={0}
              style={{ marginRight: 8 }}
              component={'span'}
            />
            changed status of asset <BoldSpan>{notification.body.assetTitle}</BoldSpan> from{' '}
            <BoldSpan>{notification.body.oldStatus}</BoldSpan> to <BoldSpan>{notification.body.newStatus}</BoldSpan>
          </>
        }
        secondaryContent={notification.body.assetLocation && <>Asset location: {notification.body.assetLocation}</>}
      />
    );
  }

  if (notification.type === ASSET_PRE_BOOKING) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            <UserChip
              color="default"
              size="small"
              id={notification.body.userId}
              margin={0}
              style={{ marginRight: 8 }}
              component={'span'}
            />
            pre-booked asset <BoldSpan>{notification.body.assetTitle}</BoldSpan> from{' '}
            <DateFormatter style={{ fontWeight: 500 }} date={notification.body.startDate} /> to{' '}
            <DateFormatter style={{ fontWeight: 500 }} date={notification.body.endDate} />
          </>
        }
      />
    );
  }

  if (notification.type === ASSET_PRE_BOOKING_REMINDER) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/asset/${notification.body.assetId}`)}
        icon={<AssetIcon />}
        content={
          <>
            You have a pre-booking for asset{' '}
            <BoldSpan>
              {notification.body.assetTitle} {notification.body.bookingStartsIn}
            </BoldSpan>
          </>
        }
        secondaryContent={
          <>
            From <DateFormatter style={{ fontWeight: 500 }} date={notification.body.startDate} /> to{' '}
            <DateFormatter style={{ fontWeight: 500 }} date={notification.body.endDate} />
          </>
        }
      />
    );
  }

  if (notification.type === NEW_ROUTINE_TASK) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.taskId)}
        icon={<RoutineIcon />}
        content={
          <>
            You have been sent routine task{'  '}
            <BoldSpan>{notification.body.taskTitle}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === ENTRY_SENT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/forms/entry/${notification.body.entryId}`)}
        icon={<FormIcon />}
        content={
          <>
            {notification.body.toCreator ? (
              <Typography style={{ fontSize: 14 }}>
                You were sent a form{'  '}
                <BoldSpan>{notification.body.formTitle}</BoldSpan>
              </Typography>
            ) : (
              <Typography style={{ fontSize: 14 }}>
                <BoldSpan>{notification.body.user}</BoldSpan>
                {'   '}
                sent you form{'   '}
                <BoldSpan>{notification.body.formTitle}</BoldSpan>
              </Typography>
            )}
          </>
        }
      />
    );
  }

  if (notification.type === ENTRY_SUBMITTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/forms/entry/${notification.body.entryId}`)}
        icon={<FormIcon />}
        content={
          <>
            Form{'   '}
            <BoldSpan>{notification.body.formTitle}</BoldSpan>
            {'   '}
            has been submitted by{'   '}
            <BoldSpan>{notification.body.user}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === ROW_STATUS) {
    const link = notification.body.forProcess
      ? `/processes/step/${notification.body.stepId}`
      : `/forms/entry/${notification.body.entryId}`;
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(link)}
        icon={<TableChartRounded sx={{ color: grey[500] }} />}
        content={
          <>
            A row in the
            {'   '}
            <span style={{ textTransform: 'lowercase', fontWeight: 500 }}>
              {notification.body.stepTitle || notification.body.formName}
            </span>
            {'   '}
            has been changed to
            {'   '}
            <span style={{ color: notification.body.statusColor, textTransform: 'lowercase', fontWeight: 500 }}>
              {notification.body.statusText}
            </span>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_REVIEW) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.body.taskId)}
        icon={<LibraryBooksRounded sx={{ color: grey[500] }} />}
        content={
          <>
            You have been sent a review: <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === QHUB_ACCESS_REQUESTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.openQhubAccessDialog(notification.body.requestId)}
        icon={<PersonRounded sx={{ color: grey[500] }} />}
        content={<>Q-Hub admin has requested access to your account</>}
        secondaryContent={<>Tap here to accept ot reject</>}
      />
    );
  }

  if (notification.type === ENTRY_STARTED) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/forms/entry/${notification.body.entryId}`)}
        icon={<FormIcon />}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan> has started filling out form{' '}
            <BoldSpan>{notification.body.formTitle}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === FORM_REMINDER) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/forms/entry/${notification.body.entryId}`)}
        icon={<FormIcon />}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan> reminds you of the form{' '}
            <BoldSpan>{notification.body.formName}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === FORM_ENTRY_EDIT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/forms/entry/${notification.body.entryId}`)}
        icon={<FormIcon />}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan> asks you to {notification.body.upIssue ? 'up-issue' : 'edit'}
            {'  '}
            <BoldSpan>
              {notification.body.entryTitle} #{notification.body.entryNumber}
            </BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === PROCESS_ENTRY_EDIT) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`/processes/ongoing/${notification.body.entryId}`)}
        icon={<FormIcon />}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan> asks you to {notification.body.upIssue ? 'up-issue' : 'edit'}
            {'  '}
            <BoldSpan>
              {notification.body.processTitle} #{notification.body.entryNumber}
            </BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === STEP_REMINDER) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => history.push(`processes/step/${notification.body.stepId}`)}
        icon={<ProcessIcon />}
        content={
          <>
            <BoldSpan>{notification.body.user}</BoldSpan> reminds you of the step{' '}
            <BoldSpan>{notification.body.stepTitle}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === NEW_RU_TASK) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => StateManager.selectTask(notification.body.taskId)}
        icon={<DocIcon />}
        content={
          <>
            You have been sent <BoldSpan>{notification.body.title}</BoldSpan>
          </>
        }
      />
    );
  }

  if (notification.type === OTHER) {
    return (
      <Notification
        notification={notification}
        unread={unread}
        read={read}
        clear={clear}
        hideButton={hideButton}
        onClick={() => {
          if (notification.body.link) {
            history.push(notification.body.link);
          } else if (notification.body.taskId) {
            StateManager.selectTask(notification.body.taskId);
          } else if (notification.body.dataRequestId) {
            StateManager.openDataRequestDialog(notification.body.dataRequestId);
          }
        }}
        icon={getIcon(notification.body.icon)}
        content={<span dangerouslySetInnerHTML={{ __html: notification.body.text }} />}
      />
    );
  }

  return <div>{notification.type}</div>;
}

function Notification({
  onClick,
  icon,
  content,
  secondaryContent,
  disableTypography,
  notification,
  read,
  clear,
  hideButton,
  unread,
}) {
  // const [rejecting, setRejecting] = useState(false);

  function acceptTask(e, notificationData) {
    e.stopPropagation();
    if (notificationData.body.taskId) {
      axios
        .post('/tasks/saveTaskAcceptance', {
          taskId: notification.taskId,
          accept: true,
        })
        .catch(console.error);
    }
  }

  // function rejectTask(reason) {
  //   if (!reason) return;
  //   let taskId = notification?.body?.taskId;
  //   if (notification?.body?.taskId) {
  //     taskId = notification?.taskId;
  //   }
  //   axios
  //     .post('/tasks/saveTaskAcceptance', {
  //       taskId,
  //       accept: false,
  //       reason,
  //     })
  //     .catch(() => StateManager.setErrorAlert('Failed to reject task'));
  //   setRejecting(false);
  // }

  return (
    <ListItem alignItems="flex-start" sx={{ padding: 1 }} onClick={onClick}>
      <ListItemAvatar>{icon}</ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Grid display="flex" justifyContent="space-between">
              <Typography component="span" variant="body2" sx={{ fontWeight: 'bold', display: 'flex', width: '90%' }}>
                {disableTypography ? content : <Typography style={{ fontSize: 16 }}>{content}</Typography>}
              </Typography>
              {notification?.readAt ? (
                <>
                  {!hideButton && (
                    <Tooltip title="Clear" placement="top">
                      <CloseIcon
                        sx={{ fontSize: 120, color: blue[600], width: '19px', height: '15px', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          clear(notification._id);
                        }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Mark as unread" placement="top">
                    <MarkAsUnreadIcon
                      sx={{ fontSize: 120, color: blue[600], width: '19px', height: '15px', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        unread(notification._id);
                      }}
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Mark as read" placement="top">
                  <FiberManualRecordIcon
                    sx={{ fontSize: 120, color: blue[600], width: '19px', height: '13px', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      read(notification?._id);
                    }}
                  />
                </Tooltip>
              )}
            </Grid>
            <Grid display="flex" justifyContent="space-between">
              <Typography
                component="span"
                variant="body2"
                sx={{
                  display: 'flex',
                  color: '#888',
                  margin: '6px 0',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontSize: 13,
                }}
              >
                {FormatDate(notification?.createdAt)}
              </Typography>
            </Grid>
          </>
        }
        secondary={
          <>
            {secondaryContent && (
              <Grid sx={{ backgroundColor: grey[300], borderRadius: 1, padding: '11px', marginTop: 1.5 }}>
                <Typography color={'black'} style={{ fontSize: 14, fontWeight: 500 }}>
                  {secondaryContent}
                </Typography>
              </Grid>
            )}
            {notification?.type === 'newTask' && !notification?.body?.toCreator && !notification.readAt && (
              <>
                {/* <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: 'none', marginRight: 1, marginTop: 2 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRejecting(true);
                  }}
                >
                  Decline
                </Button> */}
                <Button
                  variant="contained"
                  size="small"
                  sx={{ textTransform: 'none', marginRight: 1, marginTop: 2 }}
                  onClick={(e) => acceptTask(e, notification)}
                >
                  Accept
                </Button>
              </>
            )}
            {/* <Grid sx={{ border: `1px solid ${grey[300]}`, borderRadius: 1, padding: '11px', marginTop: 1.5 }}>
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingX: 2,
                    width: '100%',
                  }}
                >
                  <Typography variant="body2">{'attachment.name'}</Typography>
                  <Typography variant="caption" sx={{ marginLeft: 'auto' }}>
                    {'attachment.size'}
                  </Typography>
                </Button>
              </Grid>
            </Grid> */}
          </>
        }
      />
    </ListItem>
  );
}
