import React, { useMemo, forwardRef } from 'react';
import { FormatOnlyDate } from '../Functions';
import { isFunction } from 'lodash';

const DateFormatter = forwardRef(({ date, formatFunction = FormatOnlyDate, ...props }, ref) => {
  const formatted = useMemo(() => {
    if (!isFunction(formatFunction)) return null;
    return formatFunction(date);
  }, [date, formatFunction]);

  return (
    <span ref={ref} {...props}>
      {formatted}
    </span>
  );
});

export default DateFormatter;
