const measurements = {
  m: { short: 'm', full: 'Metres' },
  mm: { short: 'mm', full: 'Millimetres' },
  cm: { short: 'cm', full: 'Centimetres' },
  in: { short: "'", full: 'Inches' },
  ft: { short: 'ft', full: 'Feet' },

  amp: { short: 'A', full: 'Amperes' },
  vlt: { short: 'V', full: 'Volts' },
  ohm: { short: 'Ω', full: 'Ohm' },
  w: { short: 'W', full: 'Watts' },
  kw: { short: 'Kw', full: 'Kilowatts' },

  cel: { short: 'C', full: 'Celsius' },
  fr: { short: 'F', full: 'Fahrenheits' },

  g: { short: 'G', full: 'Gram' },
  kg: { short: 'Kg', full: 'Kilograms' },
  lb: { short: 'Lb', full: 'Pounds' },

  sec: { short: 'Sec', full: 'Seconds' },
  min: { short: 'Min', full: 'Minutes' },
  hrs: { short: 'Hrs', full: 'Hours' },

  n: { short: 'N', full: 'Newtons' },
  lbf: { short: 'Lbf', full: 'Pounds-force' },
  nm: { short: 'Nm', full: 'Newton metres' },

  lt: { short: 'L', full: 'Litres' },
  cl: { short: 'Cl', full: 'Centilitres' },
  ml: { short: 'Ml', full: 'Milliliters' },

  b: { short: 'B', full: 'Bar' },
  psi: { short: 'Psi', full: 'Pounds per square inch' },

  lm: { short: 'Lm', full: 'Lumens' },
};

export default measurements;
