import { COLORS } from '../../constants';
import { grey } from '@mui/material/colors';

export const getColor = (ext) => {
  switch (ext) {
    case 'qhub':
      return '#0d7dcd';
    case 'pdf':
    case 'octet-stream':
    case 'application/pdf':
    case 'application/x-pdf':
      return 'red';
    case 'xls':
    case 'vnd.ms-excel':
    case 'application/vnd.ms-excel':
    case 'xlsx':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'csv':
    case 'text/csv':
      return 'green';
    case 'doc':
    case 'msword':
    case 'application/msword':
    case 'docx':
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'blue';
    case 'ppt':
    case 'application/vnd.ms-powerpoint':
    case 'vnd.ms-powerpoint':
    case 'pptx':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'orange';
    case 'jpeg':
    case 'jpg':
    case 'tiff':
    case 'png':
    case 'image/jpeg':
    case 'image/png':
    case 'image/tiff':
    case 'image/jpg':
    case 'mp4':
    case 'webm':
    case 'ogg':
    case 'ogv':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'flv':
    case 'mkv':
    case 'audio/mp4':
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
    case 'video/ogv':
    case 'video/avi':
    case 'video/mov':
    case 'video/wmv':
    case 'video/flv':
    case 'video/mkv':
      return 'black';
    case 'mp3':
    case 'wav':
    case 'ogg':
    case 'flac':
    case 'aac':
    case 'm4a':
    case 'wma':
    case 'aiff':
    case 'aif':
    case 'mpeg':
    case 'audio/mp3':
    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/ogg':
    case 'audio/flac':
    case 'audio/aac':
    case 'audio/m4a':
    case 'audio/wma':
    case 'audio/aiff':
    case 'audio/aif':
      return COLORS.SEARCH;
    case 'zip':
    case 'application/zip':
      return grey[600];
    default:
      return 'black';
  }
};
