import React, { useState, useEffect } from 'react';
import { blue, green } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Typography, useTheme } from '@mui/material';
import { CheckCircleOutline, Send } from '@mui/icons-material';
import { RoundedDialog, HtmlContent, GroupChip, UserChip } from '../../../Global/Components';
import { StandardDialogActions } from '../../../Global/Components';
import { FormIcon } from '../../../Global/Icons';
import FieldPreview from '../../../Global/Fields/FieldPreview';
import { useMediaQuery } from '@mui/material';
import { isArray, isEmpty } from 'lodash';

const triggers = [
  { id: 'stepStarted', text: 'Step started' },
  { id: 'stepCompleted', text: 'Step completed' },
  { id: 'stepOverdue', text: 'Step overdue' },
];

const notifyOptions = [
  { id: 'notification', text: 'Notification' },
  //{ id: 'email', text: 'Email' },
  { id: 'both', text: 'Notification & Email' },
];

export default function ViewStepDialog({ step, open, onClose, allSteps }) {
  const [title, setTitle] = useState('');
  const [nextStepId, setNextStepId] = useState(null);
  const [decisions, setDecisions] = useState([]);
  const [type, setType] = useState('normal');
  const [items, setItems] = useState([]);
  const theme = useTheme();

  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (!open) return;
    setTitle(step ? step.title : '');
    setType(step.type);
    setNextStepId(step && step.nextStepId ? step.nextStepId : null);
    setDecisions(step && step.decisions ? step.decisions : []);
    setItems(step ? step.items : []);
  }, [open, step]);

  function close() {
    onClose();
  }

  return (
    <RoundedDialog
      open={open}
      onClose={close}
      maxWidth="md"
      fullWidth
      fullScreen={!largeDevices}
      className="scroll-bar"
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {type === 'end' && (
          <Grid container item sx={{ my: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              When this step is reached the process will be {step.endOption === 'complete' ? 'completed' : 'cancelled'}
            </Typography>
          </Grid>
        )}
        {step?.plannedTime && (
          <Grid container item sx={{ my: 1 }}>
            <Typography>
              {`Planned time for this step: ${step.plannedTime.days}d ${step.plannedTime.hours || 0}h`}
            </Typography>
          </Grid>
        )}

        {type === 'decision' && step?.decisionDescription && (
          <Grid container item sx={{ my: 1 }}>
            <HtmlContent content={step?.decisionDescription} />
          </Grid>
        )}

        {step?.contentType === 'form' && step.formParams?.title && (
          <Grid container item alignItems="center" sx={{ my: 1 }}>
            <FormIcon />
            <Typography style={{ marginLeft: '1rem' }} variant="h6">
              {step.formParams.title}
            </Typography>
          </Grid>
        )}

        {step?.contentType !== 'form' && (
          <Grid container item sx={{ my: 1 }}>
            <Grid container item sx={{ my: 1 }}>
              <Grid item container>
                {items
                  .filter((x) => !x.conditionalFieldId)
                  .map((item) => (
                    <Grid container item key={item.id}>
                      <FieldPreview item={item} allItems={items} />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        {type === 'normal' && allSteps && allSteps.length > 1 && (
          <Grid
            container
            item
            alignItems="center"
            sx={{
              my: 2,
              pt: 2,
            }}
          >
            <Send style={{ color: blue[800], marginRight: theme.spacing(2) }} />
            <Typography style={{ fontWeight: 500, marginRight: '1em' }}>Next step:</Typography>
            <Typography>{allSteps.find((x) => x._id === nextStepId)?.title}</Typography>
          </Grid>
        )}

        {type === 'decision' && allSteps && allSteps.length > 1 && (
          <Grid container item sx={{ my: 1 }}>
            <Grid container item>
              <Typography gutterBottom variant="subtitle1" sx={{ fontWeight: 500 }}>
                Decision:
              </Typography>
            </Grid>
            <Grid container item>
              {decisions.map((decision, i) => (
                <Grid key={decision.id} container item alignItems="center" sx={{ my: 1 }}>
                  <Typography>
                    <strong>Step:</strong> {allSteps.find((x) => x._id === decision.stepId)?.title} (
                    {decision.condition})
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {isArray(step?.notifyList) && !isEmpty(step?.notifyList) && (
          <Grid container sx={{ mb: 1 }} alignItems="center">
            <Grid container item>
              <Typography gutterBottom variant="subtitle1" sx={{ fontWeight: 500 }}>
                Notifications:
              </Typography>
            </Grid>
            {step.notifyList.map((item, i) => (
              <Grid container sx={{ py: 1 }} key={i}>
                <Grid container alignItems={'center'}>
                  {item.userId && <UserChip id={item.userId} o />}
                  {!isEmpty(item.users) && item.users.map((userId, index) => <UserChip key={index} id={userId} />)}
                  {!isEmpty(item.groups) && item.groups.map((groupId, index) => <GroupChip key={index} id={groupId} />)}
                </Grid>
                <Grid container alignItems={'center'} wrap="nowrap">
                  <Typography noWrap style={{ textTransform: 'lowercase' }}>
                    Will be sent {notifyOptions.find((t) => t.id === item.notifyOptionId)?.text} when{' '}
                    {triggers.find((t) => t.id === item.triggerId)?.text}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        {step?.approvalNeeded && step.approvalUsers && (
          <Grid container sx={{ my: 1 }}>
            <Grid container item alignItems="center">
              <CheckCircleOutline style={{ color: green[500], marginRight: '1rem' }} />
              <Typography variant="h6">Approval is needed for this step</Typography>
            </Grid>

            {step.approvalUsers?.length > 0 && (
              <Grid container item sx={{ my: 1 }}>
                <Grid container>
                  <Typography gutterBottom variant="h6">
                    Users to approve are:
                  </Typography>
                </Grid>
                <Grid container item>
                  {step.approvalUsers.map((user) => (
                    <UserChip key={user} id={user} />
                  ))}
                </Grid>
              </Grid>
            )}
            {step.approvalGroups?.length > 0 && (
              <Grid container item sx={{ my: 1 }}>
                <Grid container item>
                  <Typography gutterBottom variant="h6">
                    Groups to approve are:
                  </Typography>
                </Grid>
                <Grid container item>
                  {step.approvalGroups.map((group, i) => (
                    <GroupChip key={i} id={group} />
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {step?.autoOption && (type !== 'decision' || !step?.useDecisionUsers) && type !== 'final' && (
          <Grid container alignItems="center" item sx={{ my: 1 }}>
            <Grid container item alignItems="center">
              <Typography gutterBottom variant="subtitle1" sx={{ fontWeight: 500, mr: 2 }}>
                The next step will be sent to users{step.pickFromUserList ? ' (user will be asked to pick)' : ''}:
              </Typography>
              {isArray(step.nextStepUsers) && step.nextStepUsers.map((user, i) => <UserChip key={i} id={user} />)}

              {isArray(step.nextStepGroups) && step.nextStepGroups.map((group, i) => <GroupChip key={i} id={group} />)}
            </Grid>
          </Grid>
        )}
        {step?.onlyPickFromGroups && (
          <Grid container alignItems="center" item sx={{ my: 1 }}>
            <Typography gutterBottom>
              For the next step user will only be able to pick whole groups to send it to
            </Typography>
          </Grid>
        )}
        {step?.toTheSameUser && (
          <Grid container alignItems="center" item sx={{ my: 1 }}>
            <Typography gutterBottom>The next step will be sent to the same user who completed this step</Typography>
          </Grid>
        )}
        {step?.completeWhenActionsCompleted && (
          <Grid container alignItems="center" item sx={{ my: 1 }}>
            <Typography gutterBottom>
              The step will be automatically completed when all the actions within the step are completed
            </Typography>
          </Grid>
        )}
        {step?.completeWhenActionsCompleted && (
          <Grid container alignItems="center" item sx={{ my: 1 }}>
            <Typography gutterBottom>
              The step cannot be completed until all the actions within the step are completed
            </Typography>
          </Grid>
        )}
        {step?.useDecisionUsers && type === 'decision' && (
          <Grid container alignItems="center" item sx={{ my: 1 }}>
            <Grid container item alignItems="center">
              <Typography gutterBottom variant="h6">
                The next step will be sent to users depending on decision:
              </Typography>
            </Grid>
            <Grid container item sx={{ my: 1 }}>
              {allSteps &&
                step.decisions &&
                step.decisions.map((decision, index) => (
                  <Grid container item key={index} alignItems="center">
                    <Typography style={{ fontWeight: 500, marginRight: 16 }}>
                      {allSteps.find((x) => x._id === decision.stepId)?.title}:
                    </Typography>
                    {isArray(decision.users) && decision.users.map((user) => <UserChip key={user} id={user} />)}
                    {isArray(decision.groups) && decision.groups.map((user) => <GroupChip key={user} id={user} />)}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}
        {step?.additionalActions && step.sendTask && step.completeTask?.assignedTo && (
          <Grid container alignItems="center" item sx={{ my: 1 }}>
            <Grid container item alignItems="center">
              <Typography gutterBottom variant="h6">
                When this step is completed a task will be sent to users:
              </Typography>
            </Grid>
            <Grid container item alignItems="center">
              {step.completeTask.assignedTo.map((user) => (
                <UserChip key={user._id || user} id={user._id || user} />
              ))}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={close} />
    </RoundedDialog>
  );
}
