import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, Grid, Paper, ListItemButton, Chip, Collapse, CircularProgress } from '@mui/material';
import { isEmpty, isArray, isFunction } from 'lodash';
import { ExpandMoreRounded, DownloadRounded } from '@mui/icons-material';
import FileViewer from '../../../Global/FileViewer';
import Uploader from '../../../Global/Uploader';
import StateManager from '../../../Global/StateManager';
import { BASE_URL } from '../../../../constants';
import axios from 'axios';

export default function AttachmentsBar({ initial, onChange, entryId, editable }) {
  const [files, setFiles] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const debounceTimer = useRef(null);
  const DEBOUNCE = 1000;

  useEffect(() => {
    setFiles(isArray(initial) ? initial : []);
  }, [initial]);

  function deleteFile(id) {
    const result = files.filter((x) => x.id !== id);
    setFiles(result);
    triggerTimer(result);
  }

  function onFilesChange(uploaded) {
    setFiles(uploaded);
    triggerTimer(uploaded);
  }

  function triggerTimer(uploaded) {
    if (debounceTimer.current != null) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => saveFiles(uploaded), DEBOUNCE);
  }

  function saveFiles(uploaded) {
    axios
      .post('/forms/entries/saveEntryAttachments', { entryId, files: uploaded })
      .catch((err) => StateManager.setAxiosErrorAlert(err));
    isFunction(onChange) && onChange(uploaded);
  }

  function downloadAll() {
    if (downloading) return;

    setDownloading(true);

    axios
      .get('/forms/entries/downloadEntryAttachments', { params: { entryId } })
      .then((res) => {
        window.open(`${BASE_URL}${res.data.link}`, '_blank');
        setDownloading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setDownloading(false);
      });
  }

  const expandable = editable || !isEmpty(files);

  return (
    <Grid container component={Paper} elevation={3} sx={{ borderRadius: 2, my: 1 }}>
      <Grid
        container
        component={expandable ? ListItemButton : undefined}
        onClick={() => setExpanded(!expanded)}
        sx={{ p: 2, borderRadius: 2 }}
        alignItems={'center'}
      >
        <Typography variant="h6">Attachments:</Typography>
        <Chip sx={{ ml: 2 }} color="primary" label={files.length} />

        {expandable && (
          <Grid
            item
            container
            aria-expanded={expanded}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              transform: 'rotate(0deg)',
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              transform: expanded ? 'rotate(180deg)' : null,
              width: '35px',
              height: '35px',
              ml: 'auto',
            }}
          >
            <ExpandMoreRounded />
          </Grid>
        )}
      </Grid>
      {expandable && (
        <Grid container component={Collapse} in={expanded} mountOnEnter>
          <Grid container sx={{ p: 2 }}>
            <FileViewer title="" files={files} onDelete={editable ? deleteFile : null} />

            {editable && <Uploader uploaded={files} clearOnDone onChange={onFilesChange} withLinkedFeatures={true} />}
          </Grid>
          {!isEmpty(files) && (
            <Grid container sx={{ p: 2 }}>
              <Button
                onClick={downloadAll}
                startIcon={downloading ? <CircularProgress size={24} /> : <DownloadRounded />}
              >
                download all
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}
