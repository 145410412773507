import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

export default function KpiTimescale({ value, onChange }) {
  return (
    <FormControl>
      <Select
        variant="outlined"
        style={{ width: 200 }}
        value={value}
        onChange={(e) => {
          if (e.target.value === -1) return;
          onChange(e.target.value);
        }}
      >
        <MenuItem value={7}>Last week</MenuItem>
        <MenuItem value={14}>Last 2 weeks</MenuItem>
        <MenuItem value={30}>Last 30 days</MenuItem>
        <MenuItem value={90}>Last 3 months</MenuItem>
        <MenuItem value={180}>Last 6 months</MenuItem>
        <MenuItem value={365}>Last 12 months</MenuItem>
        <MenuItem value={-1} onClick={() => onChange(-1)}>
          Select manually
        </MenuItem>
      </Select>
    </FormControl>
  );
}
