import React, { useState, useEffect, useRef } from 'react';
import { Chip } from '@mui/material';
import { GroupRounded } from '@mui/icons-material';
import UserManager from '../UserManager';
import { isUuid } from '../Functions';

export default function PortalGroupChip({ id, onDelete, margin = '8px 16px 8px 0px', sx, ...props }) {
  const [group, setGroup] = useState(null);
  const subscription = useRef(null);

  useEffect(() => {
    return () => {
      if (!subscription.current) return;
      subscription.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isUuid(id)) return;
    const resolved = UserManager.resolvePortalGroup(id);
    if (resolved) {
      setGroup(resolved);
    } else {
      subscription.current = UserManager.subscribeToPortalGroup(id, (res) => {
        setGroup(res);
        subscription.current.unsubscribe();
      });
    }
  }, [id]);

  return (
    <Chip
      sx={sx}
      style={{ margin }}
      icon={<GroupRounded />}
      label={group?.title || 'Loading...'}
      onDelete={onDelete}
      {...props}
    />
  );
}
