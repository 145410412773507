/**
 * * Reducer for Assistant
 */

import { TOGGLEASSISTANT, SETDRAWERTOUR, SETGUIDEDTOUR } from '../constants';

const initialState = {
  assistant: false,
  showDrawerTour: false,
  drawerTour: null,
  drawerEndAction: null,
  guidedTour: null,
  showGuidedTour: false,
  guidedTourEndAction: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLEASSISTANT:
      return { ...state, assistant: action.payload.assistant };
    case SETDRAWERTOUR:
      return {
        ...state,
        drawerTour: action.payload.drawerTour,
        showDrawerTour: action.payload.showDrawerTour,
        drawerEndAction: action.payload.action,
      };
    case SETGUIDEDTOUR:
      const { guidedTour, showGuidedTour } = action.payload;
      return {
        ...state,
        guidedTour,
        showGuidedTour,
        guidedTourEndAction: action.payload.guidedTourEndAction ? action.payload.guidedTourEndAction : null,
      };
    default:
      return state;
  }
}
