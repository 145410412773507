export const setupProfile = [
  {
    target: '.phone',
    content: 'Enter your Phone Number',
    disableBeacon: true,
  },
  {
    target: '.jobRole',
    content: 'Enter your Role',
  },
  {
    target: '.profile-picture',
    content: 'Upload your profile picture',
  },
];
