/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import { Component } from 'react';
import ViewSDKClient from './ViewSDKClient';
import { v4 } from 'uuid';

class InLine extends Component {
  constructor(props) {
    super();
    this.state = {
      id: v4(),
    };
  }
  componentDidMount() {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      if (this.props.file) {
        const divId = `pdf-div-${this.state.id}`;
        viewSDKClient.previewFile(
          divId,
          {
            /* Pass the embed mode option here */
            dockPageControls: true,
            showLeftHandPanel: true,
            showDownloadPDF: this.props.enableDownload,
            showPrintPDF: this.props.enablePrint,
            enableAnnotationAPIs: false,
            includePDFAnnotations: false,
            showAnnotationTools: false,
          },
          this.props.file,
        );
      }
    });
  }

  render() {
    return <div id={`pdf-div-${this.state.id}`} className="full-screen-div" />;
  }
}

export default InLine;
