import React, { useState, useEffect } from 'react';
import { Menu, Button, Box } from '@mui/material';
import { FilterListRounded } from '@mui/icons-material';
import { CheckboxWithIcon } from '../../Components';
import { isEmpty, isArray } from 'lodash';

/**
 * For displaying list of pre-set options and picking one or more of them.
 * Options must have '_id' and 'title' fields
 */
export default function OptionsInput({ item, applyValue, options }) {
  const [statusMenu, setStatusMenu] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(isArray(item?.value) ? item.value : []);
  }, [item]);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: 2,
      }}
    >
      <Button startIcon={<FilterListRounded />} onClick={(e) => setStatusMenu(e.currentTarget)} sx={{ ml: 1 }}>
        {isEmpty(selectedOptions) ? 'Select options' : `${selectedOptions.length} selected`}
      </Button>

      {isArray(options) && !isEmpty(options) && (
        <Menu anchorEl={statusMenu} open={Boolean(statusMenu)} onClose={() => setStatusMenu(null)}>
          {options.map((option) => (
            <CheckboxWithIcon
              key={option._id}
              checked={selectedOptions.includes(option._id)}
              onChange={(checked) => {
                const value = checked
                  ? [...selectedOptions, option._id]
                  : selectedOptions.filter((x) => x !== option._id);
                setSelectedOptions(value);
                applyValue({ ...item, value });
              }}
              text={option.title}
            />
          ))}
        </Menu>
      )}
    </Box>
  );
}
