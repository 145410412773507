import React from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import PieChart from './PieChart';
import BarChart from './BarChart';
import LineChart from './LineChart';

export default function Chart({
  data,
  chartType,
  barChartType,
  onBarClick,
  onPieClick,
  onMarkClick,
  loading,
  isPreview,
  numberChartFontSize,
  chartParams,
}) {
  if (loading) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (chartType === 'number') {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Typography style={{ fontSize: numberChartFontSize || (isPreview ? '10rem' : '16rem') }}>
          {data?.count || 0}
        </Typography>
      </Grid>
    );
  }

  if (['PieChart', 'pie'].includes(chartType) || !chartType) {
    return <PieChart data={data} onPieClick={onPieClick} isPreview={isPreview} chartParams={chartParams} />;
  }

  if (['ColumnChart', 'bar'].includes(chartType)) {
    return (
      <BarChart
        data={data}
        barChartType={barChartType}
        onBarClick={onBarClick}
        isPreview={isPreview}
        chartParams={chartParams}
      />
    );
  }

  if (['LineChart', 'line'].includes(chartType)) {
    return <LineChart data={data} onMarkClick={onMarkClick} isPreview={isPreview} chartParams={chartParams} />;
  }

  return null;
}
