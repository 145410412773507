import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUploaderAreas } from '../../Hubs/dochub/redux/actions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { RoundedDialog, StandardDialogActions } from './index';
import FolderIcon from '@mui/icons-material/Folder';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

import { TreeView } from '@mui/x-tree-view/TreeView';
import { ExpandMoreRounded, ChevronRightRounded } from '@mui/icons-material';

import StyledTreeItem from '../../Hubs/dochub/components/StyledTreeItem';
import { COLORS } from '../../Hubs/dochub/constants';

function SelectFolderDialog({ title = 'Select a Folder', onClose, open, folder, excludePersonal, onResult }) {
  const dispatch = useDispatch();
  const { tree } = useSelector(({ path }) => path);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const handleClose = () => {
    setSelectedArea(null);
    setSelectedFolder(null);
    setBreadcrumbs([]);
    onClose(false);
  };

  const handleDone = () => {
    let data = {
      area: selectedArea?._id,
      path: [selectedArea?.name],
    };
    if (selectedFolder) {
      data.folder = selectedFolder?._id;
      data.path = selectedFolder?.path;
    }
    onResult(data);
    handleClose();
  };

  const handleOptionsOnLoad = useCallback(() => {
    dispatch(getUploaderAreas());
  }, [dispatch]);

  useEffect(() => {
    handleOptionsOnLoad();
  }, [handleOptionsOnLoad]);

  function renderTree(node, root) {
    if (folder?._id === node?._id) {
      return null;
    }
    return (
      <StyledTreeItem
        key={node._id}
        nodeId={node._id}
        labelText={node.name}
        labelIcon={FolderIcon}
        onClick={() => {
          setSelectedFolder(node);
          setSelectedArea(root);
          setBreadcrumbs([
            { id: root._id, name: root.name },
            { id: node._id, name: node.name },
          ]);
        }}
        color={COLORS.WHITE}
        bgColor={COLORS.MAINBLUE}
      >
        {Array.isArray(node.children) ? node.children.map((n) => renderTree(n, root)) : null}
      </StyledTreeItem>
    );
  }

  return (
    <RoundedDialog
      onClose={handleClose}
      aria-labelledby="location"
      open={open}
      disableEscapeKeyDown
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="location" style={{ fontWeight: 'bold' }}>
        <Typography variant="h6">{title}</Typography>
        <Grid container>
          <Grid item sm>
            <Breadcrumbs aria-label="breadcrumb" separator=">" maxItems={2}>
              {breadcrumbs.map((x) => (
                <Typography key={x.id}>{x.name}</Typography>
              ))}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {tree && tree.length > 0 && (
          <Grid container>
            <TreeView
              sx={{
                height: 400,
                flexGrow: 1,
                maxWidth: '100%',
                overflowY: 'scroll',
              }}
              defaultCollapseIcon={<ExpandMoreRounded />}
              defaultExpandIcon={<ChevronRightRounded />}
              defaultEndIcon={<div style={{ width: 24 }} />}
            >
              {tree
                ?.filter((x) => (excludePersonal ? !x.personal : x))
                ?.map((area) => (
                  <StyledTreeItem
                    key={area._id}
                    nodeId={area._id}
                    labelText={area.name}
                    labelIcon={FolderSpecialIcon}
                    labelInfo={area.personal ? 'Personal' : area.openAccess ? 'Public' : 'Private'}
                    color={COLORS.WHITE}
                    bgColor={COLORS.MAINBLUE}
                    onClick={() => {
                      setSelectedArea(area);
                      setSelectedFolder(null);
                      setBreadcrumbs([{ id: area._id, name: area.name }]);
                    }}
                  >
                    {Array.isArray(area.children) ? area.children.map((n) => renderTree(n, area)) : null}
                  </StyledTreeItem>
                ))}
            </TreeView>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        onClose={handleClose}
        onDone={handleDone}
        disableDone={!selectedArea}
        doneButtonText={'Confirm'}
      />
    </RoundedDialog>
  );
}

export default SelectFolderDialog;
