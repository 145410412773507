import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { isFunction, isArray } from 'lodash';

export default function ({
  value,
  onChange,
  startLabel = 'Start',
  endLabel = 'End',
  format = 'DD/MM/YYYY',
  disablePadding,
  disableContainer,
  inputProps,
  style,
  ...props
}) {
  const [innerValue, setInnerValue] = useState([null, null]);
  const timer = useRef(null);
  const SAVING_TIMEOUT = 300;

  useEffect(() => {
    if (!isArray(value) || value.length < 2) {
      setInnerValue([null, null]);
      return;
    }
    const parsedStart = moment(value[0], true);
    const parsedEnd = moment(value[1], true);
    setInnerValue([parsedStart.isValid() ? parsedStart : null, parsedEnd.isValid() ? parsedEnd : null]);
  }, [value]);

  function saveDate(value) {
    setInnerValue(value);
    if (!isFunction(onChange)) return;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => onChange(value), SAVING_TIMEOUT);
  }

  return (
    <Grid
      container={!disableContainer}
      item
      alignItems="center"
      style={{ padding: disablePadding ? 0 : '0.5rem 0', ...style }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateRangePicker
          slotProps={{ textField: { variant: 'standard' } }}
          localeText={{ start: startLabel, end: endLabel }}
          value={innerValue}
          onChange={saveDate}
          format={format}
          {...props}
        />
      </LocalizationProvider>
    </Grid>
  );
}
