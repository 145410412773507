import React, { useEffect } from 'react';
import { Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentById } from '../../../redux/actions/component';
import FileIconAvatar from '../../Hubs/dochub/components/FileIconAvatar';
import useDocumentName from '../../Hubs/dochub/hooks/useDocumentName';

export default function DocumentChip({
  id,
  document,
  onDelete,
  margin = '0px 16px 8px 0px',
  padding = 10,
  characters = 35,
  variant = 'outlined',
  size = 'small',
  ...props
}) {
  const dispatch = useDispatch();
  const documentStates = useSelector(({ component }) => component);

  const documentState = document || documentStates[id];

  useEffect(() => {
    if (document) return;
    if (!id || typeof id !== 'string') return;
    dispatch(getDocumentById(id));
  }, [dispatch, id, document]);

  const title = useDocumentName({
    title: documentState?.title,
    setId: documentState?.conventionSet,
    initial: documentState?.conventionSetParams,
    sequence: documentState?.sequence,
  });

  return (
    <Chip
      {...props}
      color="primary"
      size={size}
      variant={variant}
      style={{ margin, padding }}
      avatar={<FileIconAvatar document={documentState} mini />}
      label={title?.length > characters ? title?.substring(0, characters) : title || 'Loading...'}
      onDelete={onDelete}
    />
  );
}
