import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, CardActionArea, IconButton, Tooltip, Dialog, Button } from '@mui/material';
import { DialogContent, DialogTitle, Paper, TextField, CircularProgress } from '@mui/material';
import { CloudUploadOutlined, ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { PanoramaRounded, DeleteOutlineRounded } from '@mui/icons-material';
import StateManager from '../StateManager';
import { v4 } from 'uuid';
import axios from 'axios';
import { DnDList, RoundedDialog, StandardDialogActions } from './index';
import { isArray, isEmpty } from 'lodash';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const ImageContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  height: 250,
  borderRadius: 5,
}));

const NavigateButton = styled(IconButton)(({ theme }) => ({
  opacity: 0.2,
  background: '#fff',
  '&:hover': {
    opacity: 0.2,
    background: '#fff',
  },
  boxShadow: theme.shadows[6],
}));

export default function PicturesCarousel({ editable, pictures, onChange, large, minHeight = 'auto' }) {
  const [currIndex, setCurrIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [id] = useState(v4());
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!Array.isArray(pictures)) return;
    setFiles(pictures);
  }, [pictures]);

  function deletePicture() {
    files.splice(currIndex, 1);
    setCurrIndex(files.length === 0 ? 0 : (currIndex + (files.length - 1)) % files.length);
    setFiles([...files]);
    if (typeof onChange === 'function') onChange(files);
  }

  function upload(event) {
    if (!event.target.files || uploading || !editable) return;
    const toUpload = Array.from(event.target.files).filter((file) => file.type?.startsWith('image/'));
    if (toUpload.length === 0) return;

    setUploading(true);

    const data = new FormData();
    for (let i = 0; i < toUpload.length; ++i) {
      data.append('file', toUpload[i]);
    }

    axios
      .post(`/uploader/upload-multiple`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        if (!Array.isArray(res.data.files)) return;
        const images = res.data.files.map((file) => ({
          id: v4(),
          name: file.originalname,
          originalname: file.originalname,
          location: file.location,
          size: file.size,
          type: file.mimetype,
          mimetype: file.mimetype,
          bucket: file.bucket,
        }));
        const newFiles = [...files, ...images];
        setFiles(newFiles);
        if (typeof onChange === 'function') onChange(newFiles);
        setUploading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setUploading(false);
      });
  }

  return (
    <Grid container>
      <input
        accept="image/*"
        id={id}
        type="file"
        style={{ display: 'none' }}
        onChange={upload}
        name="files"
        multiple="multiple"
      />
      {isEmpty(files) && editable && (
        <ImageContainer container alignContent="center">
          <CardActionArea style={{ width: '100%', height: '100%', borderRadius: 5 }}>
            {!uploading && (
              <label htmlFor={id} style={{ cursor: 'pointer' }}>
                <Grid container alignContent="center">
                  <Grid container item justifyContent="center">
                    <CloudUploadOutlined
                      sx={{
                        width: 150,
                        height: 150,
                        color: grey[300],
                      }}
                    />
                  </Grid>
                  <Grid container item style={{ paddingTop: 8, textAlign: 'center' }} justifyContent="center">
                    <Typography color="textSecondary">Upload images</Typography>
                  </Grid>
                </Grid>
              </label>
            )}
            {uploading && (
              <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                <CircularProgress />
              </Grid>
            )}
          </CardActionArea>
        </ImageContainer>
      )}
      {isEmpty(files) && !editable && (
        <ImageContainer container alignContent="center" justifyContent="center">
          <PanoramaRounded
            sx={{
              width: 150,
              height: 150,
              color: grey[300],
            }}
          />
        </ImageContainer>
      )}
      {!isEmpty(files) && (
        <>
          <ImageContainer
            container
            alignContent="center"
            justifyContent="center"
            style={{ background: 'black', minHeight, overflow: 'hidden' }}
          >
            <img
              src={files[currIndex].location}
              alt={files[currIndex].name}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                cursor: 'pointer',
                zIndex: 9,
              }}
            />
            <Grid
              container
              sx={{
                height: '100%',
                position: 'absolute',
                zIndex: 10,
              }}
            >
              <CardActionArea
                style={{ width: '100%', height: '100%', borderRadius: 5 }}
                onClick={() => {
                  if (editable) {
                    setEditDialogOpen(true);
                  } else {
                    setSelectedImage(files[currIndex].location);
                    setImageDialogOpen(true);
                  }
                }}
              >
                <Grid container alignItems="center" alignContent="space-between" sx={{ height: '100%', p: 1 }}>
                  <Grid container item style={{ height: large ? 80 : 32 }} justifyContent="flex-end">
                    {(files.length > 1 || editable) && (
                      <Typography style={{ color: '#fff', opacity: 0.5, textShadow: '2px 2px 3px #545454' }}>
                        {currIndex + 1}/{files.length}
                      </Typography>
                    )}
                  </Grid>
                  {(files.length > 1 || editable) && (
                    <Grid container item justifyContent="space-between">
                      <NavigateButton
                        sx={{ width: large ? 64 : 32, height: large ? 64 : 32 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrIndex((currIndex + (files.length - 1)) % files.length);
                        }}
                      >
                        <ChevronLeftRounded sx={{ color: grey[900] }} fontSize={large ? 'large' : ''} />
                      </NavigateButton>
                      <NavigateButton
                        sx={{ width: large ? 64 : 32, height: large ? 64 : 32 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrIndex((currIndex + 1) % files.length);
                        }}
                      >
                        <ChevronRightRounded sx={{ color: grey[900] }} fontSize={large ? 'large' : ''} />
                      </NavigateButton>
                    </Grid>
                  )}

                  <Grid container item justifyContent="space-between" style={{ height: large ? 80 : 32 }}>
                    {editable && (
                      <>
                        <Tooltip title="Delete">
                          <NavigateButton
                            sx={{ width: large ? 64 : 32, height: large ? 64 : 32 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              deletePicture();
                            }}
                          >
                            <DeleteOutlineRounded sx={{ color: grey[900] }} />
                          </NavigateButton>
                        </Tooltip>
                        <Tooltip title="Upload more">
                          <NavigateButton
                            sx={{ width: large ? 64 : 32, height: large ? 64 : 32 }}
                            component="label"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <CloudUploadOutlined sx={{ color: grey[900] }} />
                            <input
                              accept="image/*"
                              type="file"
                              id="files"
                              name="files"
                              multiple="multiple"
                              style={{ display: 'none' }}
                              onChange={upload}
                            />
                          </NavigateButton>
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardActionArea>
            </Grid>
          </ImageContainer>
          {files[currIndex].caption && !editable && (
            <Grid container sx={{ pt: 1, textAlign: 'center' }} direction="column">
              <Typography>{files[currIndex].caption}</Typography>
            </Grid>
          )}
        </>
      )}
      <ImgDialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)} src={selectedImage} />
      {editable && (
        <PicturesDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          initial={files}
          onChange={(files) => {
            setFiles(files);
            onChange(files);
          }}
        />
      )}
    </Grid>
  );
}

function ImgDialog({ open, onClose, src }) {
  if (!src) return null;
  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <img alt="pic" src={src} style={{ maxHeight: '80vh', maxWidth: '80vw' }} />
    </Dialog>
  );
}

function PicturesDialog({ initial, open, onClose, onChange }) {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const timer = useRef(null);
  const SAVING_TIMEOUT = 1200;

  useEffect(() => {
    setImages(isArray(initial) ? initial : []);
  }, [initial]);

  function deleteImage(id) {
    const toRemain = images.filter((x) => x.id !== id);
    setImages(toRemain);
    activateTimer(toRemain);
  }

  function upload(event) {
    if (!event.target.files || uploading) return;
    const toUpload = Array.from(event.target.files).filter((file) => file.type?.startsWith('image/'));
    if (toUpload.length === 0) return;

    setUploading(true);

    const data = new FormData();
    for (let i = 0; i < toUpload.length; ++i) {
      data.append('file', toUpload[i]);
    }

    axios
      .post(`/uploader/upload-multiple`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        if (!Array.isArray(res.data.files)) return;
        const uploaded = res.data.files.map((file) => ({
          id: v4(),
          name: file.originalname,
          originalname: file.originalname,
          location: file.location,
          size: file.size,
          type: file.mimetype,
          mimetype: file.mimetype,
          bucket: file.bucket,
        }));
        const newFiles = [...images, ...uploaded];
        setImages(newFiles);
        activateTimer(newFiles);
        setUploading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setUploading(false);
      });
  }

  function activateTimer(value) {
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => onChange(value), SAVING_TIMEOUT);
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="md" className="horizontal-scroll-bar">
      <DialogTitle>Edit images</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          {isEmpty(images) ? (
            <Grid container item style={{ height: '25vh' }} alignItems="center" justifyContent="center">
              <Typography color="textSecondary" gutterBottom variant="h6">
                Click the below button to add pictures
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid container item>
                <Typography color="textSecondary" gutterBottom>
                  Drag images to reorder
                </Typography>
              </Grid>
              <DnDList
                items={images}
                setItems={(images) => {
                  setImages(images);
                  activateTimer(images);
                }}
                spacing={1}
                direction="horizontal"
                style={{ overflow: 'auto' }}
                dropableProps={{ wrap: 'nowrap' }}
                renderItem={(item) => (
                  <Grid container component={Paper} item sx={{ p: 1, width: 300, height: '100%' }}>
                    <Grid container item>
                      <img alt="picture" src={item.location} style={{ width: '100%', height: 'fit-content' }} />
                    </Grid>
                    <Grid container item sx={{ mt: 'auto', pt: 1 }} alignItems="center">
                      <TextField
                        style={{ flexGrow: 1, marginRight: 4 }}
                        variant="outlined"
                        placeholder="Image caption"
                        value={item.caption || ''}
                        onChange={(e) => {
                          item.caption = e.target.value;
                          setImages([...images]);
                          activateTimer(images);
                        }}
                      />
                      <IconButton onClick={() => deleteImage(item.id)}>
                        <DeleteOutlineRounded color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              />
            </>
          )}

          <Grid container item style={{ marginTop: 12 }}>
            {uploading ? (
              <CircularProgress color="primary" size={36} />
            ) : (
              <Button
                component="label"
                startIcon={<CloudUploadOutlined />}
                style={{ borderRadius: 8 }}
                color="primary"
                variant="outlined"
              >
                <input
                  accept="image/*"
                  type="file"
                  id="files"
                  name="files"
                  multiple="multiple"
                  style={{ display: 'none' }}
                  onChange={upload}
                />
                Upload more
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={onClose} />
    </RoundedDialog>
  );
}
