import {
  SETSLIST_ATTEMPT,
  SETSACTION_ATTEMPT,
  SETS_ERROR,
  GETSETS,
  GETSET,
  UPDATESET,
  DELETESET,
  ADDSET,
} from '../constants';

const initialState = {
  listLoading: false,
  actionLoading: false,
  error: null,
  sets: [],
  set: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETSLIST_ATTEMPT:
      return {
        ...state,
        listLoading: true,
        error: null,
      };
    case SETSACTION_ATTEMPT:
      return {
        ...state,
        actionLoading: true,
        error: null,
      };
    case SETS_ERROR:
      return {
        ...state,
        actionLoading: false,
        listLoading: false,
        error: action.payload,
      };
    case GETSETS:
      return {
        ...state,
        listLoading: false,
        sets: action.payload,
      };
    case GETSET:
      return {
        ...state,
        listLoading: false,
        set: action.payload,
      };
    case ADDSET:
      return {
        ...state,
        actionLoading: false,
        sets: [...state.sets, action.payload],
      };
    case UPDATESET:
      return {
        ...state,
        actionLoading: false,
        sets: state.sets.map((template) => {
          if (template.id === action.payload.id) {
            return action.payload;
          }
          return template;
        }),
      };
    case DELETESET:
      return {
        ...state,
        actionLoading: false,
        sets: state.sets.filter((template) => template.id !== action.payload.id),
      };
    default:
      return state;
  }
}
