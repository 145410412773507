import ViewSDKClient from './ViewSDKClient';

export const openPDFDialog = (file, download = false, print = false) => {
  const viewSDKClient = new ViewSDKClient();
  viewSDKClient.ready().then(() => {
    viewSDKClient.previewFile(
      '',
      {
        embedMode: 'LIGHT_BOX',
        showDownloadPDF: download,
        showPrintPDF: print,
      },
      file,
    );
  });
};
