import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box } from '@mui/material';

export default function HtmlContainer({
  html,
  sx,
  fontSize,
  lineCount = 2,
  seeMore = false,
  charLimit = 100,
  minHeight = 'auto',
  onHeightUpdate,
  ...props
}) {
  const [prettifiedContent, setPrettifiedContent] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [isContentLong, setIsContentLong] = useState(false);
  const contentRef = useRef(null);

  function prettifyImages(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const imgs = doc.getElementsByTagName('img');

    for (let i = 0; i < imgs.length; i++) {
      imgs[i].removeAttribute('width');
      imgs[i].removeAttribute('height');
      imgs[i].style.maxWidth = '100%';
    }

    return doc.documentElement.outerHTML;
  }

  useEffect(() => {
    if (!html) {
      setPrettifiedContent('');
      setIsContentLong(false);
      return;
    }
    const prettified = prettifyImages(html);
    setPrettifiedContent(prettified);
    setIsContentLong(prettified.replace(/<[^>]+>/g, '').length > charLimit);
  }, [html, charLimit]);

  useEffect(() => {
    if (contentRef.current && minHeight !== 'auto') {
      const contentHeight = contentRef.current.scrollHeight;
      const minHeightValue = parseFloat(minHeight);
      // const minHeightUnit = minHeight.replace(minHeightValue, '').trim();
      if (contentHeight > minHeightValue) {
        setIsContentLong(true);
      } else {
        setIsContentLong(false);
      }
    }
  }, [prettifiedContent, minHeight]);

  if (!prettifiedContent) return null;

  return (
    <Grid
      container
      item
      onClick={(e) => {
        e.stopPropagation();
        setShowMore(!showMore);
        if (onHeightUpdate && contentRef.current) {
          onHeightUpdate(showMore ? minHeight : contentRef.current.scrollHeight);
        }
      }}
      sx={{
        '& > *': {
          width: 'auto !important',
          maxWidth: '100% !important',
          fontFamily: 'Roboto, sans-serif',
          fontSize: fontSize ?? '14pt',
          whiteSpace: 'inherit',
        },
        div: {
          transition: 'max-height 0.3s ease',
          maxHeight: showMore ? 'none' : minHeight,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...(isContentLong && {
            display: '-webkit-box',
            WebkitLineClamp: showMore ? 'none' : lineCount,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }),
        },
        'div > h1, div > h2, div > h3, div > h4, div > h5, div > h6': {
          marginBlockStart: '0em',
          marginBlockEnd: '0em',
        },
        ...sx,
      }}
      {...props}
    >
      <div ref={contentRef} dangerouslySetInnerHTML={{ __html: prettifiedContent }} />
      {seeMore && isContentLong && !showMore && <Box sx={{ cursor: 'pointer' }}>...</Box>}
    </Grid>
  );
}
