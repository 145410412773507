import React, { useState, useEffect } from 'react';
import { grey, blue, red } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { CircularProgress, Button } from '@mui/material';
import { InfoOutlined, GetApp, DeleteOutlineRounded, RemoveCircleOutlineRounded } from '@mui/icons-material';
import { PersonAddRounded, SwapVertRounded, ScheduleRounded, ArchiveRounded } from '@mui/icons-material';
import { RoundedDialog, CrossButton, User, GeneralButton, StandardDialogActions } from '../../../Global/Components';
import { UserGroup, DatePicker, TimePicker } from '../../../Global/Components';
import { FormatDate, FormatOnlyDate } from '../../../Global/Functions';
import StateManager from '../../../Global/StateManager';
import { BASE_URL } from '../../../../constants';
import { Link } from 'react-router-dom';
import EntryDueDate from './EntryDueDate';
import axios from 'axios';
import RowInfo from './RowInfo';
import StepInfo from './StepInfo';
import DeleteEntryDialog from './DeleteEntryDialog';

export default function EntryInfoDialog({ open, onClose, onChange, entry, user, onDelete }) {
  const [downloading, setDownloading] = useState('');
  const [saving, setSaving] = useState(false);
  const [kpiDateDialog, setKpiDateDialog] = useState(false);
  const [submissionDateDialog, setSubmissionDateDialog] = useState(false);
  const [dueDateDialog, setDueDateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  function downloadPDF() {
    if (downloading) return;
    setDownloading('pdf');
    axios
      .get('/forms/entries/formToPdf', { params: { entryId: entry._id } })
      .then((res) => {
        window.open(`${BASE_URL}${res.data.link}`, '_blank');
        setDownloading('');
      })
      .catch((err) => {
        console.error(err);
        StateManager.setErrorAlert('Something went wrong');
        setDownloading('');
      });
  }

  function confirmRemoveUser(userId) {
    StateManager.setConfirm('You are about to remove this user', () => removeUser(userId));
  }

  function removeUser(userId) {
    setSaving(true);
    axios
      .post('/forms/entries/removeUserFromEntry', { entryId: entry._id, userId })
      .then((res) => {
        onChange({ assignedUsers: res.data.assignedUsers });
        setSaving(false);
        StateManager.setSuccessAlert('User has been removed');
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setErrorAlert(err?.response?.data?.message || 'Something went wrong');
      });
  }

  function selectUsers() {
    StateManager.selectMultipleUsers(addUsers, { initiallySelected: [] });
  }

  function addUsers(result) {
    if (!result.users) return;
    setSaving(true);
    axios
      .post('/forms/entries/addUsersToEntry', { entryId: entry._id, users: result.users })
      .then((res) => {
        onChange({ assignedUsers: res.data.assignedUsers });
        setSaving(false);
        StateManager.setSuccessAlert('Users have been added');
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setErrorAlert(err?.response?.data?.message || 'Something went wrong');
      });
  }

  function changeDueDate(dueAt) {
    setSaving(true);
    axios
      .post('/forms/entries/setDueDate', { entryId: entry._id, dueAt })
      .then((res) => {
        onChange({ dueAt: res.data.dueAt });
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function confirmArchiving() {
    StateManager.setConfirm('You are about to archive this entry', () => archiveEntry());
  }

  function archiveEntry() {
    setSaving(true);

    axios
      .post('/forms/archive/archiveEntries', { entriesIds: [entry._id] })
      .then(({ data }) => {
        if (data.archived?.length > 0) {
          onChange({ archivedAt: new Date() });
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <InfoOutlined style={{ color: grey[500] }} />
          <Typography variant="h6">Entry info</Typography>
          <CrossButton onClick={onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid sx={{ py: 1, my: 1 }} item container>
            <Grid container item alignItems="center">
              <Typography style={{ marginRight: '1rem', fontWeight: 500 }}>
                {`Started ${FormatDate(entry.createdAt)} by`}
              </Typography>
              <User id={entry.startedBy} dense />
            </Grid>
            <Grid container item style={{ marginTop: '0.7rem' }}>
              <Link to={`/forms/${entry.formId}`} style={{ color: blue[700] }}>
                <Typography>Go to form details</Typography>
              </Link>
            </Grid>
          </Grid>

          <Grid sx={{ py: 1, my: 1 }} item container>
            {entry.dueAt ? (
              <Typography variant="h6">
                The entry {entry.completedAt ? 'was' : 'is'} due {FormatDate(entry.dueAt)}
              </Typography>
            ) : (
              <Typography sx={{ fontWeight: 500 }}>
                This entry {entry.completedAt ? 'had' : 'has'} no due date
              </Typography>
            )}

            {entry.startedBy === user._id && !entry.completedAt && (
              <>
                <GeneralButton style={{ marginLeft: 'auto' }} onClick={() => setDueDateDialog(true)}>
                  Set due date
                </GeneralButton>

                <EntryDueDate
                  initial={entry.dueAt}
                  open={dueDateDialog}
                  onClose={() => setDueDateDialog(false)}
                  onResult={changeDueDate}
                />
              </>
            )}
          </Grid>

          {entry.rowId && (
            <Grid sx={{ py: 1, my: 1 }} item container>
              <RowInfo rowId={entry.rowId} />
            </Grid>
          )}
          {entry.processStepId && (
            <Grid sx={{ py: 1, my: 1 }} item container>
              <StepInfo ongoingStepId={entry.processStepId} />
            </Grid>
          )}
          {!entry.completedAt && (
            <Grid sx={{ py: 1, my: 1 }} item container>
              <Grid container item>
                <Typography sx={{ fontWeight: 500 }} gutterBottom>
                  Assigned users:
                </Typography>
              </Grid>
              {entry.assignedUsers.map((userId) => (
                <Grid key={userId} item container justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
                  <User id={userId} dense />
                  {entry.startedBy === user._id && (
                    <Tooltip title="Remove user">
                      <IconButton onClick={() => confirmRemoveUser(userId)} style={{ marginLeft: 8 }}>
                        <RemoveCircleOutlineRounded style={{ color: red[500] }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              ))}
              {entry.startedBy === user._id && (
                <Grid container item>
                  <GeneralButton style={{ color: grey[700] }} startIcon={<PersonAddRounded />} onClick={selectUsers}>
                    Add users
                  </GeneralButton>
                </Grid>
              )}
            </Grid>
          )}
          {entry.completedAt && (
            <Grid sx={{ py: 1, my: 1 }} item container alignItems="center">
              <Typography style={{ marginRight: '1rem', fontWeight: 500 }}>Assigned users:</Typography>
              <UserGroup ids={entry.assignedUsers} dialogTitle="Assigned users" />
            </Grid>
          )}
          {['editing', 'upissuing'].includes(entry.state) && (
            <Grid sx={{ py: 1, my: 1 }} item container>
              <Grid item container alignItems="center">
                <Typography sx={{ mr: 1, fontWeight: 500 }}>
                  This entry is being {entry.state === 'upissuing' ? 'up-issued' : 'edited'} by
                </Typography>
                <UserGroup avatarSize={32} ids={entry.editingUsers} />
              </Grid>
            </Grid>
          )}
          {entry.completedAt && (
            <Grid sx={{ py: 1, my: 1 }} item container alignItems="center">
              <Typography style={{ marginRight: '1rem', fontWeight: 500 }}>
                {`Submitted ${FormatDate(entry.completedAt)} by`}
              </Typography>
              <User id={entry.completedBy} dense />
            </Grid>
          )}

          {entry.completedAt && (entry.formOwners?.includes(user._id) || user.access === 'admin') && (
            <Grid sx={{ py: 1, my: 1 }} item container alignItems="center" justifyContent="space-between">
              <Typography>
                {`Date that will be used in KPI: ${FormatOnlyDate(entry.changedCompletedAt || entry.completedAt)}`}
              </Typography>
              <GeneralButton
                onClick={() => setKpiDateDialog(true)}
                startIcon={<SwapVertRounded style={{ color: blue[900] }} />}
              >
                Change
              </GeneralButton>
              <KpiDateDialog
                entryId={entry._id}
                initial={entry.changedCompletedAt || entry.completedAt}
                open={kpiDateDialog}
                onClose={() => setKpiDateDialog(false)}
                onResult={onChange}
              />
            </Grid>
          )}

          <Grid sx={{ py: 1, my: 1 }} item container>
            <GeneralButton
              onClick={downloadPDF}
              startIcon={downloading === 'pdf' ? <CircularProgress color="primary" size={20} /> : <GetApp />}
            >
              Export to PDF
            </GeneralButton>
            {entry.completedAt && (
              <>
                {(entry.formOwners?.includes(user._id) || user.access === 'admin') && (
                  <>
                    <GeneralButton
                      onClick={() => setDeleteDialog(true)}
                      startIcon={<DeleteOutlineRounded />}
                      style={{ color: red[500] }}
                    >
                      Delete entry
                    </GeneralButton>

                    <DeleteEntryDialog
                      open={deleteDialog}
                      onClose={() => setDeleteDialog(false)}
                      entry={entry}
                      onResult={onDelete}
                    />
                  </>
                )}

                {(entry.formOwners?.includes(user._id) || user.access === 'admin') && (
                  <GeneralButton onClick={() => setSubmissionDateDialog(true)} startIcon={<ScheduleRounded />}>
                    Change submission date
                  </GeneralButton>
                )}
                <SubmissionDateDialog
                  entryId={entry._id}
                  initial={entry.completedAt}
                  open={submissionDateDialog}
                  onClose={() => setSubmissionDateDialog(false)}
                  onResult={onChange}
                />

                {(entry.formOwners?.includes(user._id) || user.access === 'admin') &&
                  !entry.archivedAt &&
                  entry.completedAt && (
                    <GeneralButton onClick={confirmArchiving} startIcon={<ArchiveRounded />}>
                      Archive entry
                    </GeneralButton>
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions saving={saving} onClose={onClose} />
    </RoundedDialog>
  );
}

function KpiDateDialog({ entryId, initial, open, onClose, onResult }) {
  const [date, setDate] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setDate(initial);
  }, [initial]);

  function save() {
    setSaving(true);
    axios
      .post('/forms/entries/changeKpiDate', { entryId, changedDate: date })
      .then((res) => {
        StateManager.setSuccessAlert('Date have been saved');
        setSaving(false);
        onResult({ changedCompletedAt: res.data.changedCompletedAt });
        onClose();
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setErrorAlert('Failed to save the date');
        console.error(err);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Select KPI date</DialogTitle>
      <DialogContent>
        <DatePicker value={date} onChange={setDate} />
      </DialogContent>

      <StandardDialogActions saving={saving} onClose={onClose} onDone={save} hideDone={!date} />
    </RoundedDialog>
  );
}

function SubmissionDateDialog({ entryId, initial, open, onClose, onResult }) {
  const [date, setDate] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setDate(initial);
  }, [initial]);

  function save() {
    setSaving(true);
    axios
      .post('/forms/entries/changeSubmissionDate', { entryId, changedDate: date })
      .then((res) => {
        StateManager.setSuccessAlert('Date have been saved');
        setSaving(false);
        onResult({ completedAt: res.data.completedAt, createdAt: res.data.createdAt });
        onClose();
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setErrorAlert('Failed to save the date');
        console.error(err);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Select submission date</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center">
          <Grid container item xs={6}>
            <DatePicker value={date} onChange={setDate} style={{ padding: 4 }} />
          </Grid>

          <Grid container item xs={6}>
            <TimePicker value={date} onChange={setDate} style={{ padding: 4 }} />
          </Grid>
        </Grid>
      </DialogContent>

      <StandardDialogActions saving={saving} onClose={onClose} onDone={save} hideDone={!date} />
    </RoundedDialog>
  );
}
