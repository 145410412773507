import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { FilterAltOffRounded } from '@mui/icons-material';
import { orange } from '@mui/material/colors';

export default function ClearFilter({ onClick }) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <FilterAltOffRounded />
      </ListItemIcon>
      <ListItemText primary="Clear filter" />
    </MenuItem>
  );
}
