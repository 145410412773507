import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { PortalIcon } from '../Icons';
import { Link } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import axios from 'axios';

export default function PortalSpaceReference({ portalSpaceId, ...props }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { user } = useSelector(({ profile }) => profile);

  useEffect(() => {
    if (!portalSpaceId) return;
    setLoading(true);

    axios
      .get('/portal/user/getPortalSpaceInfo', { params: { portalSpaceId } })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [portalSpaceId]);

  if (!loading && !data) return null;

  return (
    <Grid container alignItems={'center'} {...props}>
      {loading ? (
        <CircularProgress />
      ) : (
        data && (
          <>
            <PortalIcon />

            <Typography sx={{ ml: 2, fontSize: 18 }}>
              This entry was created in portal space{' '}
              <Link
                to={user.access === 'portal' ? `/portal/space/${data._id}` : `/portal/management/space/${data._id}`}
              >
                <span style={{ fontWeight: 500, color: blue[500] }}>{data.title}</span>
              </Link>
            </Typography>
          </>
        )
      )}
    </Grid>
  );
}
