import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Grid,
  IconButton,
  Button,
  Typography,
  Divider,
  ListItemSecondaryAction,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { ArrowBack, ExitToApp, DeleteOutlineOutlined, Add } from '@mui/icons-material';
import { RoundedDialog, User } from '../../Global/Components';
import SelectUserDialog from '../../Global/SelectUserDialog';
import ConfirmDialog from '../../Global/ConfirmDialog';
import GroupIcon from '@mui/icons-material/Group';
import UserMap from '../UserMap';
import moment from 'moment';
import axios from 'axios';

const userId = localStorage.getItem('_id');

function formatDate(date) {
  const dt = moment(date);
  var time = dt.format('hh:mm A');
  if (dt.isSame(moment(), 'day')) {
    // if today
    return `today at ${time}`;
  } else if (dt.isSame(moment().subtract(1, 'days'), 'day')) {
    // if yesterday
    return `yesterday at ${time}`;
  } else if (dt.isSame(moment(), 'year')) {
    // if this year
    return `on ${dt.format('D MMM')} at ${time}`;
  } else {
    return `on ${dt.format('D-MMM-YY')} at ${time}`; // if not this year
  }
}

export default function ChatHeader({ chat, onBack, onUpdate }) {
  const [interlocutor, setInterlocutor] = useState();
  const [infoOpen, setInfoOpen] = useState(false);

  useEffect(() => {
    if (chat && chat.type === 'private' && chat.participants) {
      setInterlocutor(chat.participants.find((x) => x !== userId));
    }
  }, [chat]);

  return (
    <Grid container item alignItems="center">
      {chat.type === 'private' ? (
        <Grid container item alignItems="center">
          <User id={interlocutor} disableChatButton clickable style={{ width: '100%' }} />
        </Grid>
      ) : (
        <Grid container item alignItems="center">
          <ListItem button onClick={() => setInfoOpen(true)}>
            {onBack && (
              <ListItemIcon>
                <IconButton onClick={onBack} style={{ width: '2rem', height: '2rem' }}>
                  <ArrowBack />
                </IconButton>
              </ListItemIcon>
            )}
            {chat.picture ? (
              <ListItemAvatar>
                <Avatar src={chat.picture} />
              </ListItemAvatar>
            ) : (
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
            )}
            <ListItemText
              primary={chat.title}
              secondary={chat.participants.map((user) => UserMap.resolve(user)?.firstName).join(', ')}
            />
          </ListItem>
        </Grid>
      )}
      <GroupInfo chat={chat} open={infoOpen} onClose={() => setInfoOpen(false)} onUpdate={onUpdate} />
    </Grid>
  );
}

function GroupInfo({ chat, open, onClose, onUpdate }) {
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  function uploadFile(file) {
    if (!file) return;
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        axios
          .post('/chat/updateChatPicture', { chatId: chat._id, picture: res.data.file.location })
          .then(() => {
            onUpdate(chat._id);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function leaveChat() {
    axios
      .post('/chat/leaveChat', { chatId: chat._id })
      .then(() => onUpdate(chat._id))
      .catch((err) => console.error(err));
  }

  function deleteChat() {
    axios.post('/chat/deleteChat', { chatId: chat._id });
  }

  function deleteChatParticipant() {
    axios.post('/chat/deleteChatParticipant', { chatId: chat._id, participant: userToDelete });
    setUserToDelete(null);
  }

  function addChatParticipant(user) {
    if (user && user._id) {
      axios.post('/chat/addChatParticipant', { chatId: chat._id, participant: user._id });
    }
    setAddOpen(false);
  }

  return (
    <RoundedDialog maxWidth="xs" fullWidth open={open} onClose={onClose} fullScreen={!largeDevices}>
      <DialogTitle style={{ padding: '1rem 0.5rem' }}>
        <input
          accept="image/*"
          id="cover-photo"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => uploadFile(e.target.files[0])}
        />
        <ListItem>
          {chat.picture ? (
            <label htmlFor="cover-photo">
              <ListItemAvatar style={{ cursor: 'pointer' }}>
                <Avatar src={chat.picture} />
              </ListItemAvatar>
            </label>
          ) : (
            <label htmlFor="cover-photo">
              <ListItemIcon style={{ cursor: 'pointer' }} component="span">
                <GroupIcon />
              </ListItemIcon>
            </label>
          )}
          <ListItemText primary={chat.title} />
        </ListItem>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Typography style={{ marginBottom: '1rem' }}>
            {`Created by ${UserMap.resolve(chat.createdBy)?.fullName} ${formatDate(chat.createdAt)}`}
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6" style={{ color: '#1e88e5' }}>
            {`${chat.participants.length} participants`}
          </Typography>
          {userId === chat.createdBy && (
            <Button
              startIcon={<Add style={{ color: '#1e88e5' }} />}
              style={{ color: '#1e88e5' }}
              onClick={() => setAddOpen(true)}
            >
              Add user
            </Button>
          )}
        </Grid>
        <Grid container>
          <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
            {chat.participants.map((user, i) => (
              <React.Fragment key={user}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={UserMap.resolve(user)?.avatar}>{UserMap.resolve(user)?.avatarLetters}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={UserMap.resolve(user)?.fullName} />
                  {userId === chat.createdBy && userId !== user && (
                    <ListItemSecondaryAction>
                      <IconButton>
                        <DeleteOutlineOutlined
                          style={{ color: '#f44336' }}
                          onClick={() => {
                            setDeleteUserOpen(true);
                            setUserToDelete(user);
                          }}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                {i !== chat.participants.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          {userId === chat.createdBy ? (
            <Button
              style={{ color: '#f44336' }}
              onClick={() => setDeleteOpen(true)}
              startIcon={<DeleteOutlineOutlined style={{ color: '#f44336' }} />}
            >
              Delete group
            </Button>
          ) : (
            <Button
              style={{ color: '#f44336' }}
              onClick={() => setLeaveOpen(true)}
              startIcon={<ExitToApp style={{ color: '#f44336' }} />}
            >
              Leave group
            </Button>
          )}

          <Button style={{ color: '#1e88e5' }} onClick={() => onClose()}>
            Close
          </Button>
        </Grid>
      </DialogActions>
      <ConfirmDialog
        open={leaveOpen}
        onConfirm={leaveChat}
        onClose={() => setLeaveOpen(false)}
        title="Are you sure you want to leave this chat?"
        content="This action cannot be undone"
      />
      <ConfirmDialog
        open={deleteOpen}
        onConfirm={deleteChat}
        onClose={() => setDeleteOpen(false)}
        title="Are you sure you want to delete this chat?"
        content="This action cannot be undone"
      />
      <ConfirmDialog
        open={deleteUserOpen}
        onConfirm={deleteChatParticipant}
        onClose={() => setDeleteUserOpen(false)}
        title="You are about to remove this user?"
        content="Are you sure you want to do it?"
      />
      <SelectUserDialog open={addOpen} onClose={addChatParticipant} />
    </RoundedDialog>
  );
}
