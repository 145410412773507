import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Typography, MenuItem, Checkbox, Grid, Avatar } from '@mui/material';
import { isHex24 } from '../Functions';
import UserManager from '../UserManager';

const CheckboxWithUser = forwardRef(({ checked, onChange, text, userId }, ref) => {
  const [user, setUser] = useState(null);
  const subscription = useRef(null);

  useEffect(() => {
    return () => {
      if (!subscription.current) return;
      subscription.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isHex24(userId)) return;
    let resolved = UserManager.resolveUser(userId);
    if (resolved?._id) {
      setUser(resolved);
    } else {
      subscription.current = UserManager.subscribeToUser(userId, (res) => {
        setUser(res);
        subscription.current.unsubscribe();
      });
    }
  }, [userId]);

  return (
    <MenuItem onClick={() => onChange(!checked)}>
      <Grid container alignItems="center" wrap="nowrap">
        <Checkbox sx={{ p: 0 }} checked={checked} />
        <Typography sx={{ mx: 2 }}>{user?.fullName}</Typography>
        <Avatar sx={{ height: 24, width: 24, ml: 'auto', fontSize: 12 }} src={user?.avatar}>
          {user?.avatarLetters}
        </Avatar>
      </Grid>
    </MenuItem>
  );
});

export default CheckboxWithUser;
