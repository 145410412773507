import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getReadUnderstood } from '../../Hubs/dochub/redux/actions/ru';
import { Backdrop, Portal, CircularProgress } from '@mui/material';
import axios from 'axios';
import TaskDialog from '../../Hubs/tasks/TaskDialog/index';
import { useHistory } from 'react-router-dom';
import StateManager from '../StateManager';

export default function Task({ id, open, onClose, onSelect, onChange, onDelete, onMovingToBacklog }) {
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState();
  const [subTaskId, setSubTaskId] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  function getDocHubLink(taskId, hubTaskInfo) {
    if (hubTaskInfo.link && !hubTaskInfo.reviewId) {
      return `${hubTaskInfo.link}/${taskId}`;
    }

    if (hubTaskInfo.action === 'review') {
      if (hubTaskInfo.reviewId) return `/DocLibrary/hublink/review/update/${hubTaskInfo.id}/${hubTaskInfo.reviewId}`;
      else return `/DocLibrary/hublink/review/${hubTaskInfo.id}/${taskId}`;
    }

    if (hubTaskInfo.action === 'upissue') {
      if (hubTaskInfo.category === 'qhub') return `/DocLibrary/builder/${hubTaskInfo.id}/upissue/${taskId}`;
      else return `/DocLibrary/hublink/upissue/${hubTaskInfo.id}/${taskId}`;
    }

    return '#';
  }

  useEffect(() => {
    if (!id || !open) return;

    setLoading(true);
    setTask(null);
    setSubTaskId(null);
    axios
      .get('/tasks/getTask', { params: { id: typeof id === 'string' ? id : id.taskId } })
      .then((res) => {
        if (res.data) {
          let task = res.data;
          if (task.type === 'process_step') {
            history.push(`/processes/step/${task.ongoingStepId}?fromTask=true`);
          } else if (task.type === 'hub' && task.hubTaskInfo.action === 'review') {
            if (task.hubTaskInfo.reviewId) {
              return StateManager.openReviewDialog(task.hubTaskInfo.reviewId);
            }
            history.push(getDocHubLink(task._id, task.hubTaskInfo));
          } else if (
            (task.type === 'doc_approval' && task.hubTaskInfo.link) ||
            (task.type === 'hub' && task.hubTaskInfo.link && task.hubTaskInfo.action === 'upissue')
          ) {
            history.push(task.hubTaskInfo.link);
          } else if (task.type === 'approval') {
            history.push(task.activityLink);
          } else if (task.type === 'form_entry') {
            history.push(`/forms/entry/${task.formEntryId}`);
          } else if (task.type === 'ru') {
            dispatch(getReadUnderstood(task._id));
          } else if (task.type === 'audit') {
            history.push(`/audits/${task.auditId}`);
          } else if (task.hubTaskInfo?.reviewId && task.type === 'hub' && task.hubTaskInfo.action === 'review') {
            StateManager.openReviewDialog(task.hubTaskInfo.reviewId);
          } else if (task.type === 'dataRequest') {
            StateManager.openDataRequestDialog(task.dataRequestId, task.title);
          } else {
            setTask(task);
            if (typeof id !== 'string' && id.subTaskId) {
              setSubTaskId(id.subTaskId);
            }
          }
        } else {
          onSelect(null);
        }
      })
      .catch((err) => {
        console.error(err);
        StateManager.setErrorAlert('Failed to load the task');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, open]); // eslint-disable-line

  return (
    <>
      {loading ? (
        <Backdrop open={loading} style={{ color: '#fff' }}>
          <CircularProgress style={{ color: '#2196f3' }} />
        </Backdrop>
      ) : (
        <Portal>
          <TaskDialog
            task={task}
            open={open}
            onClose={onClose}
            onSelect={onSelect}
            selectedSubTaskId={subTaskId}
            onResult={onChange}
            onDelete={onDelete}
            onMovingToBacklog={onMovingToBacklog}
          />
        </Portal>
      )}
    </>
  );
}
