import { useState, useEffect, useMemo } from 'react';
import { Button } from '@mui/material';
import { ArrowDropDownRounded, VisibilityRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { isArray, isEmpty, uniq } from 'lodash';
import SelectedRowsDialog from './SelectedRowsDialog';
import { DataSetMenu } from './DataSetField';
import { UserGroup } from '../Components';

export default function DataSetCell({ params, onResult, value, editable, disableClick, textAlign }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowsIds = selectedRows.map((x) => x.id);

  const [menuAnchor, setMenuAnchor] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!isArray(value?.rows)) return;

    setSelectedRows(value.rows);
  }, [value]);

  // no columns defined - unable to display
  if (!isArray(params?.columns) || isEmpty(params?.columns)) {
    return editable ? <Typography color="textSecondary">No data set</Typography> : null;
  }

  const { columns, setId, selectType } = params;

  // === NOT EDITABLE (DISPLAY VALUE) ===
  if (!editable) {
    // nothing selected
    if (!isArray(value?.rows) || isEmpty(value?.rows)) return null;

    const rows = value.rows;

    return (
      <>
        <Button
          style={{
            textTransform: 'none',
            minWidth: 150,
            maxWidth: '100%',
            justifyContent: textAlign || 'flex-start',
          }}
          onClick={disableClick ? undefined : () => setDialogOpen(true)}
          startIcon={<VisibilityRounded sx={{ color: (theme) => theme.palette.text.secondary }} />}
          fullWidth
        >
          <ButtonContent columns={columns} rows={rows} />
        </Button>

        <SelectedRowsDialog open={dialogOpen} onClose={() => setDialogOpen(false)} rows={rows} columns={columns} />
      </>
    );
  }

  // === EDITABLE ===

  function handleRows(row) {
    if (!row) return;

    const rowId = row.id;

    let updatedSelectedRows = [];

    if (selectedRowsIds.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((x) => x.id !== rowId);
    } else {
      if (selectType === 'single') {
        updatedSelectedRows = [row];
      } else {
        updatedSelectedRows = [...selectedRows, row];
      }
    }

    const columnIds = columns.map((x) => x.id);

    const result = updatedSelectedRows.map((row) => ({
      id: row.id,
      ...columnIds.reduce((prev, columnId) => ({ ...prev, [columnId]: row[columnId] }), {}),
    }));

    setSelectedRows(result);

    onResult({ rows: result });

    // close the menu if single select
    if (selectType === 'single') {
      setMenuAnchor(null);
    }
  }

  const buttonText = isEmpty(selectedRows)
    ? columns.length === 1
      ? `Pick option${selectType === 'single' ? '' : 's'}`
      : `Pick row${selectType === 'single' ? '' : 's'}`
    : columns.length === 1
    ? selectedRows.map((row) => row[columns[0].id]).join(', ')
    : `${selectedRows.length} row${selectedRows.length === 1 ? '' : 's'} selected`;

  return (
    <>
      <Button
        style={{
          textTransform: 'none',
          minWidth: 150,
          maxWidth: '100%',
          justifyContent: textAlign || 'flex-start',
        }}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
        startIcon={<ArrowDropDownRounded sx={{ color: (theme) => theme.palette.text.secondary }} />}
      >
        <ButtonContent columns={columns} rows={selectedRows} editable selectType={selectType} />
      </Button>

      <DataSetMenu
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        columns={columns}
        setId={setId}
        onRowClick={(rows, rowId) => handleRows(rows, rowId)}
        selectedRowsIds={selectedRowsIds}
      />
    </>
  );
}

function ButtonContent({ columns, rows, editable, selectType }) {
  const result = useMemo(() => {
    if (columns.length > 1 || isEmpty(rows)) {
      return (
        <Typography noWrap sx={{ fontWeight: 500, color: (theme) => theme.palette.text.secondary }}>
          {editable && isEmpty(rows)
            ? `Pick row${selectType === 'single' ? '' : 's'}`
            : `${rows.length} row${rows.length === 1 ? '' : 's'} selected`}
        </Typography>
      );
    }

    if (columns.length === 1 && columns[0].type !== 'users') {
      return (
        <Typography noWrap sx={{ fontWeight: 500, color: (theme) => theme.palette.text.secondary }}>
          {rows.map((row) => row[columns[0].id]).join(', ')}
        </Typography>
      );
    }

    if (columns.length === 1 && columns[0].type === 'users') {
      const ids = uniq(rows.flatMap((row) => (isArray(row[columns[0].id]) ? row[columns[0].id] : [])));
      return <UserGroup ids={ids} avatarSize={24} clickable={false} />;
    }

    return null;
  }, [columns, rows, editable, selectType]);

  return result;
}
