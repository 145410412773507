import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Divider, useTheme } from '@mui/material';
import { DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { Person, DeleteOutlineOutlined } from '@mui/icons-material';
import { User, HtmlContent, GeneralButton, RoundedDialog } from '../Components';
import { UserChip, StandardDialogActions } from '../Components';
import { grey, red } from '@mui/material/colors';
import StateManager from '../StateManager';
import { DueDate } from '../../Hubs/tasks/workflowComponents';
import { Fields, SubTasks } from '../../Hubs/tasks/Components';
import Uploader from '../Uploader';
import moment from 'moment';

function hex24() {
  return [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
}

export default function TaskDialog({ open, onClose, onResult, params, task }) {
  const theme = useTheme();

  const [ownerId, setOwnerId] = useState();
  const [dueDate, setDueDate] = useState();
  const [owners, setOwners] = useState([]);
  const [ownerDialog, setOwnerDialog] = useState(false);
  const [description, setDescription] = useState('');
  const [assignedUsers, setAssignedUsers] = useState([]);

  const [attachments, setAttachments] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (!open) return;
    setOwnerId(task?.ownerId);
    setAssignedUsers(task?.assignedUsers || []);
    setDescription(task?.description || params?.description || '');
    setDueDate(task?.dueDate ? moment(task?.dueDate) : null);
    setAttachments(task?.attachments || params?.attachments || []);
    setSubTasks(task?.subTasks || params?.subTasks || []);
    setFields(task?.fields || params?.fields || []);

    if (params?.ownerList) {
      setOwners(params.ownerList);
    }
  }, [open]); // eslint-disable-line

  function selectAssignee() {
    StateManager.selectMultipleUsers(
      (res) => {
        if (res.users) {
          setAssignedUsers(res.users);
        }
      },
      { initiallySelected: assignedUsers, title: 'Select assignees' },
    );
  }

  function selectOwner() {
    if (params.ownerSelect === 'list') {
      setOwnerDialog(true);
    }

    if (params.ownerSelect === 'any') {
      StateManager.selectUser((user) => {
        setOwnerId(user._id);
      });
    }
  }

  function done() {
    if (!assignedUsers[0]) {
      StateManager.setErrorAlert('Select assignees');
      return;
    }
    if (params.ownerSelect !== 'fixed' && !ownerId) {
      StateManager.setErrorAlert('Select owner');
      return;
    }

    const result = {
      _id: hex24(),
      assignedUsers,
      ownerId,
      description,
      title: params.title,
      priority: params.priority,
      dueDate,
      attachments,
      subTasks,
      fields,
      templateId: params.templateId,
    };
    onResult(result);
    onClose();
  }

  function deleteTask() {
    onResult(null);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="md" className="scroll-bar">
      <DialogTitle>Create task</DialogTitle>
      <DialogContent>
        <Grid container>
          <Typography variant="h5" gutterBottom>
            {params?.title}
          </Typography>
        </Grid>

        <Grid container alignItems="center" style={{ marginTop: '1rem' }}>
          <DueDate editable value={dueDate} setValue={setDueDate} />
        </Grid>

        <Grid container item style={{ padding: theme.spacing(2, 0) }}>
          <Grid container item alignItems="center">
            <GeneralButton startIcon={<Person style={{ color: grey[500] }} />} onClick={selectAssignee}>
              Select assignees
            </GeneralButton>
            {assignedUsers.map((user) => (
              <UserChip
                margin={8}
                key={user}
                id={user}
                onDelete={() => setAssignedUsers(assignedUsers.filter((x) => x !== user))}
              />
            ))}
          </Grid>

          <Grid container item alignItems="center" style={{ marginTop: '1rem' }}>
            {params.ownerSelect !== 'fixed' && (
              <GeneralButton startIcon={<Person style={{ color: grey[500] }} />} onClick={selectOwner}>
                Select task owner
              </GeneralButton>
            )}
            {ownerId && <User id={ownerId} style={{ marginLeft: '1rem' }} />}
          </Grid>
        </Grid>

        <Divider />

        <Grid container style={{ padding: theme.spacing(2, 0) }}>
          <HtmlContent outlined editable content={description} title="Task description" onEdit={setDescription} />
        </Grid>

        <Divider />

        <Grid container item style={{ padding: theme.spacing(2, 0) }}>
          <Typography variant="h6">Attachments:</Typography>
          <Uploader uploaded={attachments} onChange={setAttachments} withLinkedFeatures={true} />
        </Grid>

        <Divider />

        <Grid container item style={{ padding: theme.spacing(2, 0) }}>
          <Typography variant="h6">Task Fields:</Typography>
          <Fields initial={fields} onItemsChange={setFields} hideTasks />
        </Grid>

        <Divider />

        <Grid container item style={{ padding: theme.spacing(2, 0) }}>
          <SubTasks subTasks={subTasks} setSubTasks={setSubTasks} isNewTask />
        </Grid>

        <OwnerList open={ownerDialog} onClose={() => setOwnerDialog(false)} users={owners} onResult={setOwnerId} />
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          task && (
            <Button
              onClick={deleteTask}
              style={{ borderRadius: 8, color: red[500], marginRight: 'auto' }}
              startIcon={<DeleteOutlineOutlined />}
            >
              delete task
            </Button>
          )
        }
        onClose={onClose}
        onDone={done}
      />
    </RoundedDialog>
  );
}

function OwnerList({ open, onClose, onResult, users }) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Select owner</DialogTitle>
      <DialogContent>
        <Grid container style={{ margin: '1rem 0' }}>
          {users &&
            users.map((user) => (
              <User
                key={user}
                id={user}
                style={{ width: '100%' }}
                onClick={() => {
                  onResult(user);
                  onClose();
                }}
              />
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: red[500] }}>
          cancel
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
