/**
 * * Reducer for Document Hub, follows the cases as per relevant actions
 */
import {
  GETPATHAREAS_ATTEMPT,
  GETPATHAREAS_SUCCESS,
  GETPATHAREAS_FAIL,
  GETPATHAREA_ATTEMPT,
  GETPATHAREA_SUCCESS,
  GETPATHAREA_FAIL,
  GETPATHFOLDERS_ATTEMPT,
  GETPATHFOLDERS_SUCCESS,
  GETPATHFOLDERS_FAIL,
  GETPATHFOLDER_ATTEMPT,
  GETPATHFOLDER_SUCCESS,
  GETPATHFOLDER_FAIL,
  SETPATHCURRENTFOLDER,
  SETINITIALUPLOADCONFIG,
} from '../constants';

const initialState = {
  fetching: false,
  areas: null,
  folders: null,
  currentArea: null,
  currentFolder: null,
  currentPath: null,
  fullPath: null,
  areasLoading: false,
  areaLoading: false,
  initialConfig: null,
  tree: [],
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETPATHAREAS_ATTEMPT:
      return { ...state, areasLoading: true };
    case GETPATHAREAS_SUCCESS:
      return {
        ...state,
        areasLoading: false,
        areas: action.payload.areas,
        folders: null,
        currentFolder: null,
        tree: action.payload.tree,
      };
    case GETPATHAREAS_FAIL:
      return { ...state, areasLoading: false, error: action.payload };
    case GETPATHAREA_ATTEMPT:
      return { ...state, areaLoading: true };
    case GETPATHAREA_SUCCESS:
      return {
        ...state,
        areaLoading: false,
        currentArea: action.payload.area,
        folders: action.payload.folders,
        currentFolder: null,
      };

    case GETPATHAREA_FAIL:
      return { ...state, areaLoading: false, error: action.payload };
    case SETPATHCURRENTFOLDER:
      return { ...state, currentFolder: action.payload === 'root' ? null : action.payload, folders: null };
    case GETPATHFOLDERS_ATTEMPT:
      return { ...state, fetching: true, folders: [] };
    case GETPATHFOLDERS_SUCCESS:
      return { ...state, fetching: false, folders: action.payload.folders };
    case GETPATHFOLDERS_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case GETPATHFOLDER_ATTEMPT:
      return { ...state, fetching: true, currentPath: [], currentFolder: null };
    case GETPATHFOLDER_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentFolder: action.payload.folder,
        currentPath: action.payload.folder.path,
        fullPath: action.payload.folder.fullPath,
      };
    case GETPATHFOLDER_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SETINITIALUPLOADCONFIG:
      return {
        ...state,
        initialConfig: action.payload,
        currentArea: action.payload
          ? action.payload.area
            ? action.payload.area
            : state.currentArea
          : state.currentArea,
        currentFolder: action.payload
          ? action.payload.folder
            ? action.payload.folder
            : state.currentFolder
          : state.currentFolder,
      };
    default:
      return state;
  }
}
