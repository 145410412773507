import { useState, useEffect } from 'react';
import { Grid, Autocomplete, TextField } from '@mui/material';
import StateManager from '../../Global/StateManager';
import axios from 'axios';

export default function ChooseRaisedUser({ fromAdmin, setSubUser }) {
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState('');
  const [user, setUser] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  async function loadCompanies() {
    await axios
      .get('/superadmin/companies/getCompanyList')
      .then((res) => {
        const options = res.data.map((company) => {
          return { label: company.name, id: company.id };
        });
        setCompanies(options);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }

  async function loadUsers(companyId) {
    await axios
      .get(`/superadmin/companies/getUserList?company=${companyId}`)
      .then((res) => {
        const options = res.data.map((user) => {
          return { label: user.fullName, id: user.id };
        });
        setUsers(options);
        setLoading(false);
      })
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }

  useEffect(() => {
    loadCompanies();
  }, []);

  useEffect(() => {
    if (company) loadUsers(company.id);
  }, [company]);

  function onCompanyChange(company) {
    setCompany(company);
  }

  function onUserChange(user) {
    setUser(user);
    setSubUser({ sub: user.id, company: company.id });
  }

  if (!fromAdmin) return;
  return (
    <Grid container item direction={'row'} justifyContent={'space-between'}>
      <Autocomplete
        loading={loading}
        options={companies}
        value={company}
        onChange={(event, newValue) => {
          onCompanyChange(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Company" />}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        sx={{ width: '45%' }}
      />
      {company && (
        <Autocomplete
          loading={loading}
          options={users}
          value={user}
          onChange={(event, newValue) => {
            onUserChange(newValue);
          }}
          renderInput={(params) => <TextField required {...params} label="User" />}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          sx={{ width: '45%' }}
        />
      )}
    </Grid>
  );
}
