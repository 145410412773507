import React from 'react';
import { MSIcon } from '../../../../Global/Icons';
import { QDOCSvg } from '../../../../../constants';
import { getColor } from './color';
import { ReactComponent as CSV } from '../../../../../Images/icons/csv.svg';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import FolderZipIcon from '@mui/icons-material/FolderZip';

const Icon = ({ ext, fontSize = 24, mini }) => {
  const color = getColor(ext);
  const styles = {
    fontSize,
    height: fontSize,
    width: fontSize,
    color: (t) => (t.palette.mode === 'dark' ? (color === 'black' ? 'white' : color) : color),
  };
  switch (ext) {
    case 'qhub':
      return <QDOCSvg styles={styles} height={fontSize} width={fontSize} />;
    case 'pdf':
    case 'octet-stream':
    case 'application/pdf':
    case 'application/x-pdf':
      return <MSIcon name="PDF" styles={styles} mini={mini} />;
    case 'xls':
    case 'vnd.ms-excel':
    case 'application/vnd.ms-excel':
    case 'application/vnd.ms-excel.sheet.macroenabled.12':
    case 'xlsx':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <MSIcon name="ExcelLogoInverse16" styles={styles} mini={mini} />;
    case 'doc':
    case 'msword':
    case 'application/msword':
    case 'docx':
    case 'dotx':
    case 'vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <MSIcon name="WordLogoInverse16" styles={styles} mini={mini} />;
    case 'ppt':
    case 'application/vnd.ms-powerpoint':
    case 'vnd.ms-powerpoint':
    case 'pptx':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
      return <MSIcon name="PowerPointLogoInverse16" styles={styles} mini={mini} />;
    case 'jpeg':
    case 'jpg':
    case 'tiff':
    case 'png':
    case 'image/jpeg':
    case 'image/png':
    case 'image/tiff':
    case 'image/jpg':
      return <MSIcon name="FileImage" styles={styles} mini={mini} />;
    case 'mp4':
    case 'webm':
    case 'ogg':
    case 'ogv':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'flv':
    case 'mkv':
    case 'quicktime':
    case 'audio/mp4':
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
    case 'video/ogv':
    case 'video/avi':
    case 'video/mov':
    case 'video/wmv':
    case 'video/flv':
    case 'video/mkv':
    case 'video/quicktime':
      return <MSIcon name="PhotoVideoMedia" styles={styles} mini={mini} />;
    case 'mp3':
    case 'wav':
    case 'ogg':
    case 'flac':
    case 'aac':
    case 'm4a':
    case 'wma':
    case 'aiff':
    case 'aif':
    case 'mpeg':
    case 'audio/mp3':
    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/ogg':
    case 'audio/flac':
    case 'audio/aac':
    case 'audio/m4a':
    case 'audio/wma':
    case 'audio/aiff':
    case 'audio/aif':
      return <MSIcon name="MusicInCollectionFill" styles={styles} mini={mini} />;
    case 'zip':
    case 'application/zip':
      return <FolderZipIcon styles={styles} />;
    case 'csv':
    case 'text/csv':
      return <CSV style={styles} />;
    default:
      return <BrokenImageIcon color="error" styles={styles} />;
  }
};

export default Icon;
