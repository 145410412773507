import { useState, useEffect } from 'react';
import {
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Alert,
} from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../../Components';
import { useSelector } from 'react-redux';

const options = [
  { label: 'Public', value: 'public' },
  { label: 'Private', value: 'private' },
];

const ViewDialog = ({ open, onClose, onResult, initial, saving, privateOnly }) => {
  const [title, setTitle] = useState('');
  const [access, setAccess] = useState('private');
  const { user } = useSelector(({ profile }) => profile);

  const handleDone = () => {
    onResult({
      title,
      access,
    });
  };

  useEffect(() => {
    if (!open) return;
    setTitle(initial?.title || '');
    setAccess(initial?.access || 'private');
  }, [open, initial]);

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>Create View</DialogTitle>
      <DialogContent>
        <Grid container item>
          <Grid container item>
            <Typography variant="subHeading">Name</Typography>
          </Grid>
          <Grid container item sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              placeholder="Enter View Name"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              sx={{
                input: {
                  padding: '10px',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container item sx={{ mt: 2 }}>
          <Grid container item>
            <Typography variant="subHeading">Access</Typography>
          </Grid>
          <Grid container item sx={{ mt: 1 }}>
            <RadioGroup
              aria-label="access"
              name="access"
              value={access}
              onChange={(e) => setAccess(e.target.value)}
              style={{ color: 'black', display: 'flex', flexDirection: 'row' }}
            >
              {options
                .filter((option) => user?.access === 'admin' || option.value === 'private')
                .filter((option) => !privateOnly || option.value === 'private')
                .map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    label={option.label}
                    control={<Radio color={'primary'} />}
                  />
                ))}
            </RadioGroup>
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            <Alert severity="info" style={{ width: '100%', borderRadius: 8 }}>
              {access === 'private' ? 'Only you can access this view' : 'Everyone can access this view'}
            </Alert>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={handleDone}
        doneButtonText={'Save View'}
        saving={saving}
        hideDone={title?.trim() === '' || access === ''}
      />
    </RoundedDialog>
  );
};

export default ViewDialog;
