import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, Typography } from '@mui/material';
import { ChevronRightRounded, LabelRounded, TextFieldsRounded, PanoramaRounded } from '@mui/icons-material';
import { SubjectRounded } from '@mui/icons-material';
import { grey, amber } from '@mui/material/colors';
import AutoSizer from 'react-virtualized-auto-sizer';
import GridLayout from 'react-grid-layout';
import PageGridFieldBuilder from './PageGridFieldBuilder';
import FieldDataGrid from './FieldDataGrid';
import { isArray, cloneDeep, isNumber, isEqual, differenceBy } from 'lodash';
import { HtmlContent, PicturesCarousel } from '../Components';
import { v4 } from 'uuid';
import './page-grid.css';

export default function PageGridBuilder({ initialLayout, initialItems, onChange, scale, section }) {
  const [layout, setLayout] = useState([]);
  const [items, setItems] = useState([]);
  const [showLayout, setShowLayout] = useState(true);

  const ROW_HEIGHT = 25;
  const RATIO = 297 / 210;

  function getDefaultLayoutConfig(id, type) {
    return {
      i: id,
      x: 0,
      y: layout.length * 4,
      w: type === 'text' ? 8 : 4,
      h: type === 'text' ? 15 : type === 'image' ? 6 : 4,
      minH: type === 'text' ? 15 : type === 'image' ? 5 : 2,
      minW: type === 'text' ? 8 : 2,
      maxH: type === 'text' ? 15 : undefined,
    };
  }

  useEffect(() => {
    if (isEqual(initialLayout, layout) && isEqual(initialItems, items)) return;

    const updatedLayout = isArray(initialLayout) ? cloneDeep(initialLayout) : [];

    const absent = isArray(initialItems)
      ? differenceBy(
          initialItems,
          updatedLayout.map((x) => ({ id: x.i })),
          'id',
        )
      : [];

    for (let i = 0; i < absent.length; ++i) {
      const defaultLayout = getDefaultLayoutConfig(absent[i].id, absent[i].type);
      updatedLayout.push(defaultLayout);
    }

    setLayout(updatedLayout);

    setItems(isArray(initialItems) ? cloneDeep(initialItems) : []);
  }, [initialLayout, initialItems]); // eslint-disable-line

  function addItem(type) {
    const id = v4();
    const item = { id, title: '', type };

    const layoutConfig = {
      i: id,
      x: 0,
      y: layout.length * 4,
      w: type === 'text' ? 8 : 4,
      h: type === 'text' ? 15 : type === 'image' ? 6 : 4,
      minH: type === 'text' ? 15 : type === 'image' ? 5 : 2,
      minW: type === 'text' ? 8 : 2,
      maxH: type === 'text' ? 15 : undefined,
    };
    layout.push(layoutConfig);
    setLayout([...layout]);
    items.push(item);
    setItems([...items]);
    onChange({ layout, items });
  }

  function duplicateSimpleItem(item) {
    // make deep clone
    const cloned = cloneDeep(item);
    // change it's id
    cloned.id = v4();

    // change ids in options
    if (cloned.options) {
      cloned.options.forEach((x) => (x.id = v4()));
    }

    // change ids in table columns
    if (cloned.tableParams?.columns) {
      cloned.tableParams.columns.forEach((x) => (x.id = v4()));
    }

    return cloned;
  }

  function duplicateField(itemId) {
    const index = items.findIndex((x) => x.id === itemId);
    if (index === -1) return;
    const cloned = duplicateSimpleItem(items[index]);

    const layoutConfig = {
      i: cloned.id,
      x: 0,
      y: layout.length * 4,
      w: cloned.type === 'text' ? 8 : 2,
      h: cloned.type === 'text' ? 15 : cloned.type === 'image' ? 6 : 4,
      minH: cloned.type === 'text' ? 15 : cloned.type === 'image' ? 5 : 2,
      minW: cloned.type === 'text' ? 8 : 2,
      maxH: cloned.type === 'text' ? 15 : undefined,
    };
    layout.push(layoutConfig);
    setLayout([...layout]);

    items.push(cloned);
    setItems([...items]);

    onChange({ layout, items });
  }

  function saveItem(itemId, field, value) {
    const index = items.findIndex((x) => x.id === itemId);
    if (index === -1) return;
    items[index][field] = value;
    onChange({ layout, items });
  }

  function deleteItem(id) {
    const index = items.findIndex((x) => x.id === id);
    let remaining = items;
    if (index > -1 && items[index].fieldType === 'conditional') {
      // delete all conditional fields
      if (items[index].conditions) {
        for (let i = 0; i < items[index].conditions.length; ++i) {
          remaining = remaining.filter((x) => x.conditionalFieldId !== items[index].conditions[i].id);
        }
      }
    }
    const result = remaining.filter((x) => x.id !== id);
    setItems(result);
  }

  function changeLayout(itemId, minH, minW) {
    if (!isNumber(minH) || !isNumber(minW)) return;
    const layoutConfigIndex = layout.findIndex((x) => x.i === itemId);
    if (!layoutConfigIndex === -1) return;
    layout[layoutConfigIndex].minH = Math.max(minH, 2);
    layout[layoutConfigIndex].minW = Math.max(minW, 2);
    layout[layoutConfigIndex].w = Math.max(minW, layout[layoutConfigIndex].w);
    layout[layoutConfigIndex].h = Math.max(minH, layout[layoutConfigIndex].h);

    setLayout(cloneDeep(layout));

    // stupid component doesn't resize items when the layout updated
    // https://github.com/react-grid-layout/react-grid-layout/issues/382
    setShowLayout(false);
    setTimeout(() => setShowLayout(true), 10);

    onChange({ layout, items });
  }

  function onResize(layout, oldLayoutItem, layoutItem, placeholder) {
    if (layoutItem.h < 3 && layoutItem.w < 4) {
      layoutItem.w = 4;
      placeholder.w = 4;
    }
  }

  return (
    <Grid container>
      {false && (
        <Grid container style={{ paddingBottom: 8 }}>
          <Button
            startIcon={<LabelRounded style={{ color: amber[500] }} />}
            style={{ textTransform: 'none', color: grey[600], borderRadius: 8 }}
            onClick={() => addItem('field')}
          >
            Add field
          </Button>

          <Button
            startIcon={<TextFieldsRounded />}
            style={{ textTransform: 'none', color: grey[600], borderRadius: 8, marginLeft: 12 }}
            onClick={() => addItem('styledText')}
          >
            Add text
          </Button>

          <Button
            startIcon={<SubjectRounded />}
            style={{ textTransform: 'none', color: grey[600], borderRadius: 8, marginLeft: 12 }}
            onClick={() => addItem('text')}
          >
            Add text box
          </Button>

          <Button
            startIcon={<PanoramaRounded />}
            style={{ textTransform: 'none', color: grey[600], borderRadius: 8, marginLeft: 12 }}
            onClick={() => addItem('image')}
          >
            Add image
          </Button>
        </Grid>
      )}
      <Grid container>
        <Grid container>
          <AutoSizer disableHeight>
            {({ width }) => {
              if (!showLayout) {
                return <div style={{ width, height: Math.round(RATIO * width) }} />;
              }

              if (section?.type === 'table' && isArray(section?.table?.rows) && isArray(section?.table?.columns)) {
                return (
                  <Grid container style={{ width, height: Math.round(RATIO * width) }} alignItems="baseline">
                    <Grid container style={{ padding: 8, overflow: 'auto', width: 'auto' }}>
                      <FieldDataGrid initial={section?.table?.rows} columns={section?.table?.columns} />
                    </Grid>
                  </Grid>
                );
              }

              if (section?.type === 'info') {
                return (
                  <Grid container style={{ width, height: Math.round(RATIO * width) }}>
                    <Grid container style={{ padding: 8 }}>
                      <Grid
                        container
                        item
                        lg={section.info.layout === 'textAndImages' && section.info.images[0] ? 8 : 12}
                        md={section.info.layout === 'textAndImages' && section.info.images[0] ? 8 : 12}
                        xs={12}
                        style={{ paddingRight: 8 }}
                      >
                        <HtmlContent content={section.info.text} />
                      </Grid>
                      {section.info.layout === 'textAndImages' && section.info.images[0] && (
                        <Grid container item lg={4} md={4} xs={12} alignContent="flex-start">
                          <Grid container item>
                            <PicturesCarousel pictures={section.info.images} />
                          </Grid>
                          <Grid container item style={{ marginTop: 8 }}>
                            <Typography variant="h6">{section.info.caption}</Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              }

              return (
                <GridLayout
                  className="page-grid"
                  margin={[0, 0]}
                  onLayoutChange={(layout) => {
                    setLayout(layout);
                    onChange({ layout, items });
                  }}
                  layout={layout}
                  cols={12}
                  rowHeight={ROW_HEIGHT}
                  width={width}
                  maxRows={Math.round((RATIO * width) / ROW_HEIGHT)}
                  style={{ height: Math.round(RATIO * width) }}
                  draggableCancel=".non-draggable"
                  onResize={onResize}
                  useCSSTransforms={false}
                  transformScale={scale}
                >
                  {items.map((item, index) => {
                    const layoutConfig = layout.find((x) => x.i === item.id);
                    return (
                      <div key={item.id} data-grid={layoutConfig}>
                        <Paper
                          variant="outlined"
                          style={{ height: '100%', borderRadius: 0, cursor: 'grab', border: `1px solid ${grey[500]}` }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <PageGridFieldBuilder
                            item={item}
                            onDuplicate={duplicateField}
                            onDelete={deleteItem}
                            onEdit={saveItem}
                            onMinSizeChange={({ minH, minW }) => changeLayout(item.id, minH, minW)}
                            layoutConfig={layoutConfig}
                          />
                        </Paper>
                        <div className="react-resizable-handle">
                          <ChevronRightRounded
                            fontSize="large"
                            style={{ color: grey[300], transform: 'rotate(45deg)' }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </GridLayout>
              );
            }}
          </AutoSizer>
        </Grid>
      </Grid>
    </Grid>
  );
}
