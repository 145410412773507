import axios from 'axios';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import StateManager from '../StateManager';
import { useCallback } from 'react';

const RedirectQRPage = () => {
  const { id } = useParams();

  const handleGetQRData = useCallback(() => {
    axios
      .get(`general/qr/validate/${id}`)
      .then(async (res) => {
        if (res?.data?.success) {
          window.location.href = res?.data?.url;
        } else {
          StateManager.setAxiosErrorAlert(res?.data?.message);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [id]);

  useEffect(() => {
    handleGetQRData();
  }, [handleGetQRData]);

  return (
    <>
      <Loader />
    </>
  );
};

export default RedirectQRPage;
