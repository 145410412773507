import React, { useEffect, useState } from 'react';
import { Grid, Typography, ListItem, CircularProgress } from '@mui/material';
import { ReadUnderstoodIcon } from '../Icons';
import StateManager from '../StateManager';
import { TooltipTypography } from '../Components';
import { useDispatch, useSelector } from 'react-redux';
import { getReadUnderstood } from '../../Hubs/dochub/redux/actions/ru';
import { grey, green, orange } from '@mui/material/colors';
import axios from 'axios';

export default function ReadUnderstoodField({
  params,
  filled,
  onChange,
  users,
  dueDate,
  assignedBy,
  activityId,
  activity,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector(({ profile }) => profile);
  const { readUnderstood } = useSelector(({ ru }) => ru);
  const [entryInfo, setEntryInfo] = useState(null);
  const [startingEntry, setStartingEntry] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!filled || !params?.documentId || (filled && !filled.ruId)) return;
    setLoading(true);
    axios
      .get(`/readunderstood/id/${filled.ruId}`)
      .then((res) => {
        setLoading(false);
        setEntryInfo(res.data.result);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [params, filled]);

  useEffect(() => {
    if (!readUnderstood || !filled) return;
    if (readUnderstood && readUnderstood._id === filled.ruId) {
      setEntryInfo(readUnderstood);
    }
  }, [filled, readUnderstood]);

  function onClick() {
    if (filled?.ruId) {
      dispatch(getReadUnderstood(filled.ruId, 'ru'));
    } else {
      if (users.includes(user?._id)) {
        setStartingEntry(true);
        axios
          .post('/readunderstood/activity', {
            document: params?.documentId,
            users,
            dueDate,
            assignedBy,
            activity,
            activityId,
          })
          .then((res) => {
            onChange({ ruId: res.data[0]._id });
            setEntryInfo(res.data[0]);
            setTimeout(() => {
              StateManager.setSuccessAlert('Entry has been started');
              setStartingEntry(false);
              dispatch(getReadUnderstood(res.data[0]._id, 'ru'));
            }, 1000);
          })
          .catch((err) => {
            StateManager.setAxiosErrorAlert(err);
            setStartingEntry(false);
          });
      } else {
        StateManager.setErrorAlert('You are not allowed to start this entry');
        setStartingEntry(false);
      }
    }
  }

  return (
    <ListItem
      button
      style={{
        borderRadius: 8,
        border: entryInfo
          ? entryInfo.completedAt
            ? `3px solid ${green[600]}`
            : `3px solid ${orange[500]}`
          : `3px solid ${grey[400]}`,
        background: (theme) =>
          entryInfo
            ? entryInfo.completedAt
              ? theme.palette.mode === 'dark'
                ? green[700]
                : green[50]
              : theme.palette.mode === 'dark'
              ? orange[700]
              : orange[50]
            : theme.palette.mode === 'dark'
            ? grey[700]
            : grey[100],
      }}
      onClick={onClick}
    >
      <Grid container alignItems="center" style={{ padding: '8px 0' }} wrap="nowrap">
        {loading && <CircularProgress color="primary" style={{ margin: 10 }} size={30} />}
        <ReadUnderstoodIcon style={{ marginRight: '1rem' }} />
        {entryInfo?.activityId ? (
          <>
            <TooltipTypography variant="h6" noWrap>
              {entryInfo.document?.title}
            </TooltipTypography>
            <TooltipTypography variant="h6" color="textSecondary" style={{ marginLeft: '0.25em' }} noWrap>
              {`- activity`}
            </TooltipTypography>
          </>
        ) : (
          <TooltipTypography variant="h6" noWrap>
            Click to start the read and understood: {params?.documentTitle}
          </TooltipTypography>
        )}
        {startingEntry && <CircularProgress color="primary" style={{ marginLeft: 'auto' }} size={30} />}
      </Grid>
    </ListItem>
  );
}
