import React, { useRef, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Paper,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
  CircularProgress,
  LinearProgress,
  Box,
  Tabs,
  Tab,
  Alert,
} from '@mui/material';
import { SaveRounded, Visibility, DrawRounded } from '@mui/icons-material';

import { v4 } from 'uuid';
import { isNumber, isFunction } from 'lodash';
import { useScreenshot } from '../hooks';
import { ImageDialog } from '../Components';
import Dropzone from '../../Hubs/dochub/components/Dropzone';
import { styled } from '@mui/material/styles';
import Canvas from './Canvas';
import { Extensions } from '../FilePreviewer/types';

const ImageAccept = Extensions.Image.reduce((prev, curr) => ({ ...prev, [curr]: [] }), {});

const TabHeader = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(1, 0.5),
  minWidth: 140,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  minHeight: 48,
}));

function TabPanel({ children, value, index, ...props }) {
  return (
    <Grid
      container
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...props}
    >
      {value === index && <Grid container>{children}</Grid>}
    </Grid>
  );
}

export default function TabbedSignature({
  onResult,
  initial,
  editable = true,
  scaling = 0.5,
  width,
  height,
  disabled,
}) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [tab, setTab] = useState(0);
  const [screenshot, takeScreenshot] = useScreenshot();
  const [saveData, setSaveData] = useState({});
  const [name, setName] = useState('');
  const [preview, setPreview] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const nameRef = useRef();
  const canvasRef = useRef();
  const canvasHeight = height && isNumber(height) ? height : largeDevices ? 300 : 150;
  const canvasWidth = width && isNumber(width) ? width - 24 : largeDevices ? 500 : 250;

  const loadedAt = useRef(null);
  const nameTimer = useRef(null);
  const DEBOUNCE_TIMEOUT = 1000;

  function triggerNameTimer(value) {
    if (nameTimer.current != null) {
      clearTimeout(nameTimer.current);
    }

    nameTimer.current = setTimeout(() => captureNamedSignature(value), DEBOUNCE_TIMEOUT);
  }

  const captureNamedSignature = async (name) => {
    const image = await takeScreenshot(nameRef, theme.palette.mode === 'dark');
    saveName(image, name);
  };

  function saveName(image, text) {
    if (!image) return;
    const result = {
      id: v4(),
      base64Data: image,
      text,
      type: 'text',
    };
    setSaveData(result);

    if (!isFunction(onResult)) return;
    onResult(result);
  }

  function handleFileUpload(file) {
    if (!file) return;
    const result = {
      id: v4(),
      location: file.location,
      type: 'file',
    };
    setUploading(false);
    setSaveData(result);
    if (!isFunction(onResult)) return;
    onResult(result);
  }

  useEffect(() => {
    if (!initial) {
      setName('');
      setSaveData({});
      if (!canvasRef.current?.clear) return;
      canvasRef.current.clear();
      return;
    }

    if (!initial.type || initial.type === 'signature') setTab(0);
    if (initial.type === 'text') {
      setTab(1);
      setName(initial.text || '');
    }
    if (initial.type === 'file') {
      setTab(2);
    }

    if (initial.id === saveData?.id || !editable) return;
    loadedAt.current = Date.now();
    setSaveData(initial);
  }, [initial, editable]); // eslint-disable-line

  if (!editable) {
    if (!initial) {
      return (
        <Grid container sx={{ py: 1 }}>
          <Typography color="textSecondary">No signature provided</Typography>
        </Grid>
      );
    }

    if (!initial.type || initial.type === 'signature') {
      return (
        <Paper variant="outlined" style={{ width: canvasWidth * scaling + 24, padding: 12 }}>
          <img
            style={{
              width: canvasWidth * scaling,
              height: canvasHeight * scaling,
              userSelect: 'none',
              pointerEvents: 'none',
              filter: theme.palette.mode === 'dark' ? 'invert(100%)' : '',
            }}
            src={initial?.base64Data}
            alt="signature"
          />
        </Paper>
      );
    }

    if (initial.type === 'text') {
      return (
        <Paper variant="outlined" sx={{ p: 1 }}>
          <Grid container>
            <Typography color="textSecondary" sx={{ fontStyle: 'italic' }} gutterBottom>
              This signature was typed in
            </Typography>
          </Grid>

          <Grid container>
            <img
              style={{
                maxWidth: canvasWidth * scaling * 3,
                maxHeight: canvasHeight * scaling,
                userSelect: 'none',
                pointerEvents: 'none',
              }}
              src={initial?.base64Data}
              alt="signature"
            />
          </Grid>
        </Paper>
      );
    }

    if (initial.type === 'file') {
      return (
        <Paper sx={{ p: 1 }} variant="outlined">
          <Grid container>
            <Typography color="textSecondary" sx={{ fontStyle: 'italic' }} gutterBottom>
              This signature was uploaded
            </Typography>
          </Grid>

          <Grid container>
            <img
              style={{
                maxWidth: canvasWidth * scaling,
                maxHeight: canvasHeight * scaling,
                userSelect: 'none',
                pointerEvents: 'none',
              }}
              src={initial?.location}
              alt="signature"
            />
          </Grid>
        </Paper>
      );
    }

    return null;
  }

  return (
    <Paper variant="outlined" sx={{ p: 1 }}>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(e, i) => setTab(i)}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mb: 1 }}
      >
        <TabHeader label={'Draw'} icon={<DrawRounded sx={{ mr: 1 }} />} />
        <Tab label={'Type'} sx={{ fontSize: '0.75rem' }} />
        <Tab label={'Upload'} sx={{ fontSize: '0.75rem' }} />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <Canvas
          disablePaper
          initial={!saveData?.type || saveData?.type === 'signature' ? saveData : {}}
          onResult={(res) => {
            setSaveData(res);
            if (!isFunction(onResult)) return;
            onResult({ ...res, type: 'signature' });
          }}
          editable={editable}
        />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <Grid container item justifyContent={'center'}>
          <TextField
            value={name}
            inputRef={nameRef}
            onChange={(e) => {
              setName(e.target.value);
              triggerNameTimer(e.target.value);
            }}
            placeholder="Your Name"
            autoComplete="off"
            fullWidth
            margin="dense"
            inputProps={{ autoComplete: 'off' }}
            variant={'outlined'}
            size="large"
            sx={{
              '& .MuiInputBase-input': { fontFamily: 'signature-extended', fontSize: 25, py: 2 },
              // hide outline
              '& .MuiOutlinedInput-root': {
                fieldset: { borderColor: 'transparent' },
                '& .focused fieldset': { borderColor: 'inherit' },
              },
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid container item sx={{ py: 1 }}>
          <Alert severity="warning">By using this field you are validating it as your handwritten signature</Alert>
        </Grid>
        <Grid container item sx={{ mt: 2 }}>
          {saveData?.base64Data && saveData.type === 'text' && (
            <>
              <Button startIcon={<Visibility />} onClick={() => setPreview(true)}>
                <img style={{ maxWidth: 300, maxHeight: 50 }} src={saveData?.base64Data} />
              </Button>
              <ImageDialog src={saveData.base64Data} open={preview} onClose={() => setPreview(false)} />
            </>
          )}
        </Grid>
      </TabPanel>

      <TabPanel value={tab} index={2}>
        <Dropzone
          onFileDrop={() => setUploading(true)}
          onFileUpload={handleFileUpload}
          onProgress={setProgress}
          disabled={disabled}
          accept={ImageAccept}
        />
        {uploading && (
          <Box sx={{ width: '100%', mt: 1 }}>
            <LinearProgress value={progress} variant="determinate" />
          </Box>
        )}
        <Grid container item sx={{ py: 1 }}>
          <Alert severity="warning">By using this field you are validating it as your handwritten signature</Alert>
        </Grid>
        <Grid container item sx={{ mt: 2 }}>
          {saveData?.location && saveData.type === 'file' && (
            <>
              <Button startIcon={<Visibility />} onClick={() => setPreview(true)}>
                <img style={{ maxWidth: 300, maxHeight: 50 }} src={saveData?.location} />
              </Button>
              <ImageDialog src={saveData?.location} open={preview} onClose={() => setPreview(false)} />
            </>
          )}
        </Grid>
      </TabPanel>
    </Paper>
  );
}
