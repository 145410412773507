import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotificationPage from './notifications';
import NotificationSettings from './settings';
import NotificationRedirect from '../Global/Notifications/notificationRedirect';

export default function Notifications() {
  return (
    <Switch>
      <Route exact path="/notifications" component={NotificationPage} />
      <Route exact path="/notifications/settings" component={NotificationSettings} />
      <Route exact path="/notifications/redirect/:id" component={NotificationRedirect} />
    </Switch>
  );
}
