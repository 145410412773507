import React, { useState, useEffect } from 'react';
import { Typography, TextField, ListItem, Grid, TableContainer, Table, TableBody } from '@mui/material';
import { Checkbox, useMediaQuery, CircularProgress, Paper, TableRow, TableCell } from '@mui/material';
import { Clear } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';
import { useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { TaskIcon, HubIcon } from '../../../Global/Icons';
import StateManager from '../../../Global/StateManager';
import { grey } from '@mui/material/colors';

export default function LinkedTasks({ linkedTasks, setLinkedTasks, taskId, disabled }) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    axios
      .get('/tasks/getTasksForSearch')
      .then((res) => {
        setOptions(res.data);
      })
      .catch(console.error);
  }, [loading]);

  return (
    <Grid container>
      <Grid
        container
        item
        spacing={1}
        lg={9}
        md={9}
        xs={12}
        alignItems="center"
        style={{ padding: theme.spacing(1, 0) }}
      >
        <Grid container item lg={3} md={3} xs={12} alignItems="center">
          <Typography variant="h6">Linked tasks:</Typography>
        </Grid>
        <Grid container item lg={9} md={9} xs={12}>
          <Autocomplete
            style={{ width: '100%' }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={options.filter((o) => !taskId || o._id !== taskId)}
            loading={loading}
            disabled={disabled}
            renderOption={(props, option) => (
              <li {...props}>
                <Grid container alignItems="center">
                  {option.type === 'hub' || option.type === 'formhub' || option.type === 'dochub' ? (
                    <HubIcon />
                  ) : (
                    <TaskIcon />
                  )}
                  <Typography style={{ marginLeft: '1rem' }} noWrap>
                    {option.title}
                  </Typography>
                </Grid>
              </li>
            )}
            onChange={(e, x) => {
              if (!x) return;
              if (linkedTasks.find((t) => x._id === t._id)) return;
              linkedTasks.push(x);
              setLinkedTasks([...linkedTasks]);
            }}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select tasks"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="primary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            {linkedTasks.map((task) => (
              <TableRow key={task._id}>
                <TableCell style={{ border: 'none' }}>
                  <Paper elevation={2} style={{ borderRadius: '0.5em', width: '100%' }}>
                    <ListItem
                      style={{ borderRadius: '0.5em' }}
                      onClick={() => StateManager.selectTask(task._id)}
                      button
                    >
                      <Grid container item alignItems="center" style={{ padding: theme.spacing(1, 0) }}>
                        {task.type === 'hub' || task.type === 'formhub' || task.type === 'dochub' ? (
                          <HubIcon />
                        ) : (
                          <TaskIcon />
                        )}
                        <Typography
                          variant="h6"
                          style={{
                            marginLeft: '1em',
                            whiteSpace: largeDevices ? 'nowrap' : 'normal',
                          }}
                        >
                          {task.title}
                        </Typography>
                        <div style={{ marginLeft: 'auto' }}>
                          <TaskStatus status={task.progress} />
                        </div>
                      </Grid>
                    </ListItem>
                  </Paper>
                </TableCell>
                {largeDevices && (
                  <TableCell align="center" style={{ border: 'none', padding: '1em' }}>
                    {task.isReferenced || task.isReviewAction ? (
                      <LinkIcon style={{ color: '#0091ea' }} />
                    ) : (
                      <Clear
                        style={{ cursor: 'pointer', color: 'lightgray' }}
                        onClick={() => setLinkedTasks(linkedTasks.filter((x) => x._id !== task._id))}
                      />
                    )}
                  </TableCell>
                )}
                {largeDevices && (
                  <TableCell align="center" style={{ border: 'none', padding: '1em' }}>
                    {task.isReferenced ? (
                      <span style={{ color: 'lightgray', fontsize: 11 }}>
                        {task.isReviewAction ? 'This task is a review action' : 'This task is linked'}
                      </span>
                    ) : (
                      <Checkbox
                        checked={task.required || task.isReviewAction}
                        disabled={task.isReviewAction}
                        onClick={(event) => {
                          task.required = event.target.checked;
                          // update in the array
                          let index = linkedTasks.findIndex((x) => x._id === task._id);
                          if (index > -1) {
                            let data = linkedTasks;
                            data[index].required = event.target.checked;
                            setLinkedTasks(data);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

function TaskStatus({ status }) {
  if (!status) return null;

  const statusColors = {
    Working: '#0070c0',
    Complete: '#00b050',
    Held: '#fcc000',
    Overdue: '#FF0000',
    'Not Started': grey[100],
  };

  return (
    <div
      style={{
        padding: '0.5em 1em',
        borderRadius: '5px',
        background: statusColors[status],
        color: status === 'Not Started' ? 'black' : 'white',
        width: 120,
        textAlign: 'center',
      }}
    >
      {status}
    </div>
  );
}
