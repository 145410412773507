import React, { useState, useEffect, useRef } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Avatar } from '@mui/material';
import { Grid, DialogContent, IconButton, Typography, Tooltip } from '@mui/material';
import UserManager from '../UserManager';
import StateManager from '../StateManager';
import Skeleton from '@mui/material/Skeleton';
import RoundedDialog from './RoundedDialog';
import ChatIcon from '@mui/icons-material/Chat';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { blue } from '@mui/material/colors';
import { FormatDate } from '../Functions';

const userId = localStorage.getItem('_id');

export default function User({ id, mention, styleProps, fontSize = '1.25rem', ...props }) {
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const subscription = useRef();

  useEffect(() => {
    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (id && typeof id === 'string') {
      setUser(UserManager.resolveUser(id));

      if (subscription.current) {
        subscription.current.unsubscribe();
      }
      subscription.current = UserManager.subscribeToUser(id, setUser);
    }
  }, [id]);

  return (
    <Grid {...props}>
      <ListItem
        button={Boolean(user._id)}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        style={{ borderRadius: 4, padding: 0, ...styleProps }}
      >
        <Grid
          container
          alignItems="center"
          style={{ borderRadius: 4, padding: '1px 4px', background: mention ? blue[500] : '' }}
        >
          {user._id ? (
            <Avatar
              src={user.avatar}
              style={{ background: user.avatarColor, height: '1.2rem', width: '1.2rem', fontSize }}
            >
              {user.avatarLetters}
            </Avatar>
          ) : (
            <Skeleton variant="circular" width={'1.2rem'} height={'1.2rem'} />
          )}
          <Typography noWrap style={{ marginLeft: 8, fontWeight: mention ? 700 : '', color: mention ? 'white' : '' }}>
            {user.fullName}
          </Typography>
        </Grid>
      </ListItem>
      {user._id && open && (
        <RoundedDialog open maxWidth="xs" fullWidth onClose={() => setOpen(false)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid container item alignItems="center">
                <Grid item lg={5} md={5} sm={12}>
                  <Avatar
                    src={user.avatar}
                    style={{ background: user.avatarColor, width: 140, height: 140, fontSize: 55 }}
                  >
                    {user.avatarLetters}
                  </Avatar>
                </Grid>
                <Grid item lg={7} md={7} sm={12}>
                  <Typography variant="h5" style={{ marginBottom: 5 }}>
                    {user.fullName}
                  </Typography>
                  {user.access === 'portal' && (
                    <Typography variant="h6" style={{ marginBottom: 5 }}>
                      Portal user
                    </Typography>
                  )}
                  {user.online ? (
                    <Typography style={{ color: '#2196f3' }}>online</Typography>
                  ) : (
                    <Typography style={{ fontSize: 15 }}>
                      {`${user.lastSeen ? 'last seen ' : ''}${FormatDate(user.lastSeen, true)}`}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container item>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <MailOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={user.email} />
                  </ListItem>
                  {user.mobile && (
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary={user.mobile} />
                    </ListItem>
                  )}
                  {user.jobRole && (
                    <ListItem>
                      <ListItemIcon>
                        <WorkOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary={user.jobRole} />
                    </ListItem>
                  )}
                </List>
              </Grid>

              {userId !== user._id && (
                <Grid container item alignItems="center" justifyContent="center">
                  <Grid item>
                    <Tooltip title="Send a message" placement="top" arrow>
                      <IconButton
                        onClick={() => {
                          setOpen(false);
                          StateManager.selectChat(user._id);
                        }}
                      >
                        <ChatIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item style={{ marginLeft: '1rem' }}>
                    <Tooltip title="Send a task" placement="top" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          StateManager.setNewTask({ userId: user._id });
                        }}
                      >
                        <WorkOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </RoundedDialog>
      )}
    </Grid>
  );
}
