import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, CardActionArea, Paper, Typography, Button } from '@mui/material';
import { TextField, CircularProgress } from '@mui/material';
import { ArrowForwardIosRounded, ArrowBackIosRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import { TaskIcon, FormIcon, ProcessIcon } from '../../../../Global/Icons';
import { RegistersIcon } from '../../../../Global/Icons';
import Autocomplete from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import StateManager from '../../../../Global/StateManager';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';

function TabPanel({ children, value, index, ...props }) {
  return (
    <Grid
      container
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...props}
    >
      <Grid container>{children}</Grid>
    </Grid>
  );
}

const hubs = (forPortal) => {
  const res = [
    {
      type: 'taskTemplate',
      icon: <TaskIcon />,
      title: 'New task from template',
      actionText: 'Select template',
      url: '/tasks/templates/getTaskTemplates',
    },
    {
      type: 'form',
      icon: <FormIcon />,
      title: 'Start form',
      actionText: 'Select form',
      url: '/forms/search/getSearchForms',
    },
    {
      type: 'process',
      icon: <ProcessIcon />,
      title: 'Start process',
      actionText: 'Select process',
      url: '/process/search/getSearchProcesses',
    },
  ];
  if (!forPortal) {
    res.push({
      type: 'register',
      icon: <RegistersIcon />,
      title: 'Add register row',
      actionText: 'Select register',
      url: '/registers/general/getUserRegisters',
    });
  }
  return res;
};

export default function AddActionDialog({ open, onClose, onResult, forPortal }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [result, setResult] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setSelectedOption(null);
    setResult(null);
    setTab(0);
  }, [open]);

  function handleItemResult(item) {
    const res = {
      action: true,
      type: selectedOption.type,
      id: item._id,
      title: item.title,
    };
    setResult(res);
  }

  function saveResult() {
    if (!result) return;
    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose} className="scroll-bar">
      <DialogTitle>Action</DialogTitle>

      <DialogContent>
        <Grid container>
          <SwipeableViews
            index={tab}
            onChangeIndex={setTab}
            style={{ width: '100%' }}
            slideStyle={{ height: '100%' }}
            containerStyle={{ height: '100%' }}
            disabled
          >
            <TabPanel value={tab} index={0}>
              <Grid container>
                {hubs(forPortal).map((hub, i) => (
                  <Grid container key={i} item style={{ padding: 8 }}>
                    <CardActionArea
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        padding: 0,
                      }}
                      onClick={() => {
                        setSelectedOption(hub);
                        setTab(1);
                      }}
                    >
                      <Paper
                        variant="outlined"
                        sx={{
                          borderRadius: 1,
                          py: 1,
                          px: 2,
                          border: `2px solid ${grey[300]}`,
                          width: '100%',
                        }}
                      >
                        <Grid container alignItems="center" wrap="nowrap">
                          {hub.icon}
                          <Typography style={{ margin: '0px 16px' }}>{hub.title}</Typography>
                          <ArrowForwardIosRounded color="primary" style={{ marginLeft: 'auto' }} />
                        </Grid>
                      </Paper>
                    </CardActionArea>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>

            <TabPanel value={tab} index={1}>
              <Grid container>
                <Grid container item>
                  <Button
                    startIcon={<ArrowBackIosRounded />}
                    color="primary"
                    onClick={() => {
                      setTab(0);
                      setSelectedOption(null);
                      setResult(null);
                    }}
                  >
                    back
                  </Button>
                </Grid>
                {selectedOption && (
                  <Grid container item style={{ paddingTop: 16, paddingBottom: 200 }}>
                    <SearchItem
                      url={selectedOption.url}
                      title={selectedOption.actionText}
                      onResult={handleItemResult}
                      icon={selectedOption.icon}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={saveResult} hideDone={!result} />
    </RoundedDialog>
  );
}

function SearchItem({ url, title, icon, onResult }) {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions([]);
    setValue(null);
    setLoading(true);

    axios
      .get(url)
      .then((res) => {
        setOptions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [url]);

  return (
    <Grid container justifyContent="center" style={{ padding: 16 }}>
      <Autocomplete
        fullWidth
        options={options}
        value={value}
        onChange={(e, x) => {
          setValue(x);
          onResult(x);
        }}
        getOptionLabel={(option) => option.title}
        renderOption={(props, option) => (
          <li {...props}>
            <Grid container alignItems="center" wrap="nowrap">
              {icon}
              <Typography style={{ marginLeft: '1rem' }} noWrap>
                {option.title}
              </Typography>
            </Grid>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  {icon}
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
}
