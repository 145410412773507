import { useState, useEffect, useMemo } from 'react';
import { Typography, DialogContent, DialogTitle, Grid, CircularProgress } from '@mui/material';
import { red } from '@mui/material/colors';
import { RoundedDialog, StandardDialogActions, FromNow, HtmlContainer } from '../Components';
import StateManager from '../StateManager';
import { Field } from '../Fields';
import { FormatDate } from '../Functions';
import { useSelector } from 'react-redux';
import { isEmpty, isNumber } from 'lodash';
import axios from 'axios';
import moment from 'moment';

export default function DataRequestDialog({ open, onClose, requestId, title }) {
  const [loading, setLoading] = useState(true);
  const [dataRequest, setDataRequest] = useState(null);
  const [fields, setFields] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState('');
  const [savedAt, setSavedAt] = useState(null);
  const { user } = useSelector(({ profile }) => profile);

  const editable =
    dataRequest &&
    !dataRequest.submittedAt &&
    !dataRequest.cancelledAt &&
    user &&
    (user.access === 'admin' || user._id === dataRequest.assignedUserId);

  const submittable = useMemo(() => {
    return fields.every((x) => isNumber(x.filledValue?.value) || !isEmpty(x.filledValue?.value));
  }, [fields]);

  useEffect(() => {
    setStatus('');
    setSavedAt(null);

    if (!requestId) {
      setDataRequest(null);

      return;
    }

    setLoading(true);
    axios
      .get('/data-requests/getDataRequest', { params: { requestId } })
      .then(({ data }) => {
        setDataRequest(data);
        setFields(data.fields);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestId]);

  function submit() {
    setSubmitting(true);
    axios
      .post('/data-requests/submitDataRequest', { requestId: dataRequest._id })
      .then((res) => {
        setSubmitting(false);
        StateManager.setSuccessAlert('Data request has been submitted');
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSubmitting(false);
      });
  }

  function saveFields(fields) {
    setStatus('Saving...');
    setSavedAt(null);
    axios
      .post('/data-requests/saveDataRequestFields', { requestId: dataRequest._id, fields })
      .then(() => {
        setStatus('Saved');
        setSavedAt(new Date());
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setStatus('Failed to save');
      });
  }

  function onSave(fieldId, value) {
    const index = fields.findIndex((x) => x.id === fieldId);

    if (index === -1) return;

    fields[index].filledValue = value;

    setFields([...fields]);

    saveFields(fields);
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{title || 'Data request'}</DialogTitle>

      {loading || !dataRequest ? (
        <DialogContent>
          <Grid container sx={{ height: 300 }} alignItems="center" justifyContent={'center'}>
            <CircularProgress />
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          {dataRequest.details?.message && <HtmlContainer html={dataRequest.details?.message} />}
          {dataRequest.submittedAt && (
            <Grid container item sx={{ py: 1 }} alignItems="center">
              <Typography color="textSecondary">Submitted {FormatDate(dataRequest.submittedAt)}</Typography>
            </Grid>
          )}
          {dataRequest.cancelledAt && (
            <Grid container item sx={{ py: 1 }} alignItems="center">
              <Typography color="textSecondary">Data request has been cancelled</Typography>
            </Grid>
          )}
          {dataRequest.dueAt && !dataRequest.submittedAt && !dataRequest.cancelledAt && (
            <Grid container item sx={{ py: 1 }} alignItems="center">
              <Typography sx={{ fontWeight: 500, color: moment() > moment(dataRequest.dueAt) ? red[600] : undefined }}>
                Due {FormatDate(dataRequest.dueAt)}
              </Typography>
            </Grid>
          )}
          <Grid container item sx={{ py: 1 }}>
            {fields.map((item) => (
              <Field
                key={item.id}
                item={item}
                onSave={onSave}
                editable={editable && !item.locked}
                allItems={fields}
                external
              />
            ))}
          </Grid>
        </DialogContent>
      )}

      <StandardDialogActions
        onClose={onClose}
        disableDone={!submittable}
        onDone={submit}
        hideDone={!editable}
        additionalActions={
          <FromNow
            value={savedAt}
            prefix={status}
            color="textSecondary"
            style={{ fontSize: 14, marginRight: 'auto' }}
          />
        }
        saving={submitting}
      />
    </RoundedDialog>
  );
}
