import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import { DialogTitle, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useEffect, useState } from 'react';

export default function TextFormatter({ size, initial, saveFont }) {
  const [formatter, setFormatter] = useState(false);

  const openFormatter = () => setFormatter(true);

  return size === 'small' ? (
    <Grid container item>
      <Tooltip title="Select an icon">
        <IconButton onClick={openFormatter}>
          <TextFieldsRoundedIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  ) : (
    <Grid container item>
      <IconButton
        size="small"
        component="label"
        sx={{ width: 150, height: '100%', borderRadius: 1, display: 'flex', justifyContent: 'flex-start', gap: 1 }}
        className="non-draggable"
        onClick={openFormatter}
      >
        <TextFieldsRoundedIcon />
        <Typography>Text format</Typography>
      </IconButton>
      <TextFormatterView open={formatter} setOpen={setFormatter} initial={initial} saveFont={saveFont} />
    </Grid>
  );
}

function TextFormatterView({ open, setOpen, initial, saveFont }) {
  const [alignment, setAlignment] = useState('left');
  const [size, setSize] = useState('16px');

  useEffect(() => {
    if (initial?.alignment) setAlignment(initial.alignment);
    if (initial?.size) setSize(initial.size);
  }, [initial]);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleSize = (event, newSize) => {
    if (newSize !== null) {
      setSize(newSize);
    }
  };

  const saveResult = () => {
    saveFont({ alignment, size });
    setOpen(false);
  };

  return (
    <RoundedDialog open={open} onClose={() => setOpen(false)} sx={{ width: '100%' }}>
      <DialogTitle>Format text</DialogTitle>
      <Grid
        container
        item
        direction={'column'}
        padding={'auto'}
        sx={{ minWidth: 300, paddingX: 5, paddingBottom: 2.5 }}
      >
        <Typography mb={1}>Font alignment:</Typography>
        <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment} aria-label="text alignment">
          <ToggleButton value="left" aria-label="left aligned">
            <FormatAlignLeftIcon />
          </ToggleButton>
          <ToggleButton value="center" aria-label="centered">
            <FormatAlignCenterIcon />
          </ToggleButton>
          <ToggleButton value="right" aria-label="right aligned">
            <FormatAlignRightIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid container item direction={'column'} sx={{ minWidth: 300, paddingX: 5, paddingBottom: 2.5 }}>
        <Typography mb={1}>Font size:</Typography>
        <ToggleButtonGroup value={size} exclusive onChange={handleSize} aria-label="text size">
          <ToggleButton value="24px" aria-label="large font size">
            <Typography fontSize={'24px'}>Big</Typography>
          </ToggleButton>
          <ToggleButton value="16px" aria-label="medium font size">
            <Typography fontSize={'16px'}>Medium</Typography>
          </ToggleButton>
          <ToggleButton value="10px" aria-label="small font size">
            <Typography fontSize={'10px'}>Small</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <StandardDialogActions onClose={() => setOpen(false)} onDone={saveResult} />
    </RoundedDialog>
  );
}
