import { useEffect, useState } from 'react';
import StateManager from '../StateManager';
import { useSelector } from 'react-redux';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium';
import axios from 'axios';

const useDataGridConfig = (dataGridClass, checkboxPosition, type = 'company') => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [pinnedColumns, setPinnedColumns] = useState({});
  const [columnsModel, setColumnsModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState([]);
  const [loading, setLoading] = useState(true);
  const [configId, setConfigId] = useState(null);
  const [views, setViews] = useState([]);
  const { user } = useSelector(({ profile }) => profile);
  const [defaultView, setDefaultView] = useState(null);
  const [rowGroupingModel, setRowGroupingModel] = useState([]);
  const [groupingColDef, setGroupingColDef] = useState({});

  useEffect(() => {
    if (!dataGridClass) return;

    setLoading(true);

    axios
      .get('/general/datagrid/getDataGridConfiguration', { params: { dataGridClass, type } })
      .then(({ data }) => {
        setConfigId(data?._id);
        setDefaultView(data.viewId || null);
        setColumnVisibilityModel(data?.columnVisibilityModel || {});
        setPinnedColumns(data?.pinnedColumns || {});
        setColumnsModel(data?.columnsModel || []);
        setFilterModel(data?.filterModel || { items: [] });
        setRowGroupingModel(data?.rowGroupingModel || []);
        setGroupingColDef(data?.groupingColDef || {});
        setSortModel(data?.sortModel || []);
        setViews(data.views);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [dataGridClass]); // eslint-disable-line

  function save(data) {
    if (user?.access !== 'admin') {
      return;
    }

    axios.post('/general/datagrid/saveDataGridConfiguration', { dataGridClass, data, type }).catch((err) => {
      StateManager.setAxiosErrorAlert(err);
    });
  }

  function saveColumnVisibilityModel(model) {
    setColumnVisibilityModel(model);
    save({ columnVisibilityModel: model });
  }

  function savePinnedColumns(model) {
    if (checkboxPosition === 'left' && model.left && model.left[0] !== GRID_CHECKBOX_SELECTION_COL_DEF.field) {
      model.left.unshift(GRID_CHECKBOX_SELECTION_COL_DEF.field);
    }
    setPinnedColumns(model);
    save({ pinnedColumns: model });
  }

  function saveColumnsModel(_event, _details, data) {
    if (!data?.api) return;
    const allColumns = data.api.getAllColumns();
    const layout = allColumns.map((col) => ({ field: col.field, width: col.width }));
    setColumnsModel(layout);
    save({ columnsModel: layout });
  }

  function saveFilterModel(filterModel) {
    setFilterModel(filterModel);
    save({ filterModel });
  }

  function onRowGroupingModelChange(rowGroupingModel) {
    setRowGroupingModel(rowGroupingModel);
    let disableObj = {};
    rowGroupingModel.map((data, index) => {
      disableObj[data] = false;
    });
    setColumnVisibilityModel(disableObj);
    setGroupingColDef({ leafField: rowGroupingModel?.[0], headerName: 'Group' });
    save({
      rowGroupingModel,
      columnVisibilityModel: disableObj,
      groupingColDef: { leafField: rowGroupingModel?.[0], headerName: 'Group' },
    });
  }

  function saveSortModel(sortModel) {
    setSortModel(sortModel);
    save({ sortModel });
  }

  function onConfigurationChange(data) {
    if (!data) return;

    setConfigId(data.configId);
    setColumnVisibilityModel(data.columnVisibilityModel || {});

    setPinnedColumns(data.pinnedColumns || {});
    setColumnsModel(data.columnsModel || []);
    setFilterModel(data.filterModel || { items: [] });
    setRowGroupingModel(data?.rowGroupingModel || []);
    setGroupingColDef(data?.groupingColDef || {});
    setSortModel(data.sortModel || []);

    // save view
    save({
      viewId: data._id || null,
      filterModel: data.filterModel,
      sortModel: data.sortModel,
      pageSize: data.pageSize,
      pinnedColumns: data.pinnedColumns || {},
    });
  }

  return {
    gridProps: {
      columnVisibilityModel,
      pinnedColumns,
      onColumnVisibilityModelChange: saveColumnVisibilityModel,
      onPinnedColumnsChange: savePinnedColumns,
      onColumnOrderChange: saveColumnsModel,
      onColumnWidthChange: saveColumnsModel,
    },
    saveFilterModel,
    saveSortModel,
    filterModel,
    rowGroupingModel,
    groupingColDef,
    onRowGroupingModelChange,
    columnsModel,
    sortModel,
    loading,
    configId,
    defaultView,
    onConfigurationChange,
    views,
  };
};

export default useDataGridConfig;
