import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

export const getRaw = (editorState) => {
  return convertToRaw(editorState.getCurrentContent());
};

export const getCurrentTextSelection = (editorState) => {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const selectedText = currentContentBlock.getText().slice(start, end);
  return selectedText;
};

export const getSelectedText = (selectionState, currentContent) => {
  var anchorKey = selectionState.getAnchorKey();
  var currentContentBlock = currentContent.getBlockForKey(anchorKey);
  var start = selectionState.getStartOffset();
  var end = selectionState.getEndOffset();
  var selectedText = currentContentBlock.getText().slice(start, end);
  return selectedText;
};

export const createWithContent = (raw) => {
  const newState = EditorState.createWithContent(
    convertFromRaw({
      ...raw,
      entityMap: raw.entityMap ? raw.entityMap : {},
    }),
  );
  return newState;
  // let finalState = EditorState.moveFocusToEnd(newState);
  // return finalState;
};
