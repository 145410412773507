import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const OutlinedButton = styled(Button)(() => ({
  textTransform: 'none',
}));

export default ({ children, ...props }) => {
  return (
    <OutlinedButton variant="outlined" color="primary" {...props}>
      {children}
    </OutlinedButton>
  );
};
