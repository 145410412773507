import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, CircularProgress, Typography, Backdrop, Dialog, Button } from '@mui/material';
import { Alert } from '@mui/material';
import { grey } from '@mui/material/colors';
import axios from 'axios';

export default function SuspendedNotice() {
  const [data, setData] = useState(null);
  const accountSuspended = data?.userSuspended;
  const companyArchived = data?.companyArchived;
  const trialOver = data?.trialEnded || data?.subscriptionCancelled;

  useEffect(() => {
    if (!localStorage) return;
    const forbiddenToken = localStorage.getItem('forbiddenToken');
    const normalToken = localStorage.getItem('token');

    axios
      .post('/auth/checkToken', { normalToken, forbiddenToken })
      .then((res) => {
        console.log();
        if (res.data.authJSON) {
          localStorage.clear();
          localStorage.setItem('token', res.data.authJSON.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authJSON.name);
          localStorage.setItem('_id', res.data.authJSON.id);
          localStorage.setItem('company', res.data.authJSON.company);
          localStorage.setItem('access', res.data.authJSON.access);
          window.location.href = '/home';
        } else {
          localStorage.clear();
          localStorage.setItem('forbiddenToken', res.data.forbiddenToken);
          setData(res.data);
        }
      })
      .catch(() => {
        localStorage.clear();
        window.location.href = '/login';
      });
  }, [localStorage]); // eslint-disable-line

  function switchAccount() {
    localStorage.clear();
    window.location.href = '/login';
  }

  if (!data) {
    return (
      <Backdrop style={{ color: '#fff' }} open>
        <Grid container alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
          <CircularProgress color="primary" />
        </Grid>
      </Backdrop>
    );
  }

  if (!companyArchived && !trialOver && !accountSuspended) {
    return <Redirect to="/login" />;
  }

  const message = companyArchived
    ? 'Account is currently suspended'
    : trialOver
    ? 'Account trial is over'
    : 'Your  access has been revoked';

  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
        backgroundImage: 'url(/appHome.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Dialog open maxWidth="xs" fullWidth>
        <Alert severity="error" style={{ borderRadius: 8, width: '100%', textAlign: 'center' }}>
          <Typography gutterBottom variant="h6">
            {message}
          </Typography>
          {(companyArchived || trialOver) && (
            <>
              <Typography gutterBottom>Please contact us to activate your account:</Typography>

              <Typography gutterBottom>Tel: 0330 118 0 712</Typography>
              <Typography gutterBottom>Email: customercare@q-hub.co.uk</Typography>
            </>
          )}
          {accountSuspended && (
            <Typography gutterBottom>Please contact your company admin to activate your account</Typography>
          )}
          <Grid container justifyContent="center">
            <Button style={{ color: grey[700] }} onClick={switchAccount}>
              Use another account
            </Button>
          </Grid>
        </Alert>
      </Dialog>
    </Grid>
  );
}
