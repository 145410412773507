import React, { useState, useEffect, useRef } from 'react';
import { ListItemAvatar, ListItemText, Avatar, ListItemButton } from '@mui/material';
import { Grid, DialogContent, DialogTitle, Typography, Skeleton } from '@mui/material';
import UserManager from '../UserManager';
import RoundedDialog from './RoundedDialog';
import { GroupRounded } from '@mui/icons-material';
import { isHex24 } from '../Functions';
import User from './User';
import { isArray, isEmpty } from 'lodash';
import UserGroup from './UserGroup';

export default function Group({
  id,
  clickable = true,
  fullWidth,
  action,
  additionalText,
  onClick,
  avatarSize = 40,
  buttonStyle,
  selected = false,
  customPadding = null,
  ...props
}) {
  const [group, setGroup] = useState(null);
  const [open, setOpen] = useState(false);
  const subscription = useRef();

  useEffect(() => {
    return () => {
      if (!subscription.current) return;
      subscription.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isHex24(id)) return;
    const resolved = UserManager.resolveGroup(id);
    if (resolved) {
      setGroup(resolved);
    } else {
      subscription.current = UserManager.subscribeToGroup(id, (res) => {
        setGroup(res);
        subscription.current.unsubscribe();
      });
    }
  }, [id]);

  const padding = Math.round(avatarSize / 8);

  return (
    <Grid container={fullWidth} {...props} onClick={(e) => e.stopPropagation()}>
      <ListItemButton
        selected={selected}
        onClick={(e) => {
          if (!clickable || !group?._id) return;
          if (onClick) {
            onClick();
          } else {
            setOpen(true);
          }
        }}
        style={{
          borderRadius: 5,
          padding: `${padding}px ${padding * 2}px`,
          width: fullWidth ? '100%' : 'auto',
          ...buttonStyle,
        }}
      >
        <ListItemAvatar style={{ minWidth: 'auto', paddingRight: customPadding ? customPadding : padding * 3 }}>
          {group?._id ? (
            !isEmpty(group?.users) && false ? (
              <UserGroup ids={group?.users} max={4} avatarSize={avatarSize} onlyAvatar clickable={false} />
            ) : (
              <Avatar style={{ width: avatarSize, height: avatarSize }}>
                <GroupRounded />
              </Avatar>
            )
          ) : (
            <Skeleton variant="circular" width={avatarSize} height={avatarSize} />
          )}
        </ListItemAvatar>

        {group?._id ? (
          <>
            <ListItemText
              disableTypography
              primary={
                <Typography noWrap>
                  {group.name} {additionalText}
                </Typography>
              }
            />
            {action && <Grid sx={{ ml: 1 }}>{action}</Grid>}
          </>
        ) : (
          <>
            <Skeleton width={100} />
            <Skeleton width={50} />
          </>
        )}
      </ListItemButton>

      {clickable && isArray(group?.users) && open && (
        <RoundedDialog open maxWidth="xs" fullWidth onClose={() => setOpen(false)}>
          <DialogTitle>Group users</DialogTitle>
          <DialogContent>
            <Grid container>
              {group.users.map((user, i) => (
                <User key={i} id={user} fullWidth />
              ))}
            </Grid>
          </DialogContent>
        </RoundedDialog>
      )}
    </Grid>
  );
}
