/* eslint-disable no-use-before-define */
import React from 'react';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function UserSelection({
  data,
  placeholder,
  handleChange,
  value,
  disabled = false,
  fullWidth = true,
  option,
  ...props
}) {
  return (
    <Select
      fullWidth={fullWidth}
      disabled={disabled}
      labelId="user-simple-select-label"
      id="user-simple-select"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      variant="standard"
      {...props}
    >
      {data.map((data) => (
        <MenuItem value={data._id} key={data._id}>
          {data[option]}
        </MenuItem>
      ))}
    </Select>
  );
}
