import React from 'react';
import { RoundedDialog, StandardDialogActions } from '../Components';
import { DialogTitle, DialogContent } from '@mui/material';
import ReadAndSign from './ReadAndSign';

export default function ReadAndSignDialog({ open, onClose, column, initial, onResult, editable }) {
  if (!column) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={'md'} fullWidth className="scroll-bar">
      <DialogTitle>{column.title}</DialogTitle>
      <DialogContent sx={{ py: 1 }}>
        <ReadAndSign
          config={column.readAndSignOptions}
          item={column}
          editable={editable}
          onResult={onResult}
          initial={initial}
        />
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={onClose} />
    </RoundedDialog>
  );
}
