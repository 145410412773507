import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { COLORS } from '../../Hubs/dochub/constants';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { TreeView } from '@mui/x-tree-view';

import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import FolderSpecial from '@mui/icons-material/FolderSpecial';
import {
  ExpandMoreRounded,
  ChevronRightRounded,
  CheckCircleRounded,
  CheckCircleOutlineRounded,
} from '@mui/icons-material';

import StyledTreeItem from '../../Hubs/dochub/components/StyledTreeItem';

import StateManager from '../StateManager';
import { green } from '@mui/material/colors';

function UserDLTree({ initial, handleConfirm }) {
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]); // selected nodes
  const [loading, setLoading] = useState(false);

  const [tree, setTree] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const handleToggle = (event, nodeIds) => {
    event.persist();
    let iconClicked =
      event.target.closest('.MuiTreeItem-iconContainer') ||
      event.target.closest('.MuiTypography-root.makeStyles-labelText') ||
      event.target.closest('.MuiSvgIcon-root.makeStyles-labelIcon');
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleSelectDocument = (id) => {
    let update = [...selectedDocuments];
    if (selectedDocuments.includes(id)) {
      update = update.filter((x) => x !== id);
      setSelectedDocuments(update);
    } else {
      update = [...update, id];
      setSelectedDocuments(update);
    }
    handleConfirm(update);
  };

  useEffect(() => {
    setSelectedDocuments(initial || []);
  }, [initial]);

  useEffect(() => {
    setExpanded(['root']);
    setSelected([]);
    setSearch('');
    setLoading(true);
    axios
      .get('/dochub/general/getTree')
      .then((res) => {
        const result = res.data?.tree;
        setTree(result);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setErrorAlert('Failed to get tree');
        setLoading(false);
      });
  }, []);

  function renderTree(node) {
    if (node.type === 'document') {
      return (
        <StyledTreeItem
          key={node._id}
          nodeId={node._id}
          labelText={node.title}
          labelIcon={DescriptionIcon}
          color={COLORS.DARKPURPLE}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSelectDocument(node._id);
          }}
          actions={
            <>
              <IconButton onClick={() => handleSelectDocument(node._id)}>
                {selectedDocuments?.includes(node._id) ? (
                  <CheckCircleRounded sx={{ color: green[500] }} />
                ) : (
                  <CheckCircleOutlineRounded />
                )}
              </IconButton>
            </>
          }
        />
      );
    }

    return (
      <StyledTreeItem
        key={node._id}
        nodeId={node._id}
        labelText={node.name}
        labelIcon={FolderIcon}
        color={COLORS.PUBLIC}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (expanded.includes(node._id)) {
            setExpanded(expanded.filter((x) => x !== node._id));
          } else {
            setExpanded([...expanded, node._id]);
          }
        }}
      >
        {Array.isArray(node.children)
          ? node.children
              ?.filter((x) => x.name?.toLowerCase().includes(search?.toLowerCase()))
              ?.map((n) => renderTree(n))
          : null}
      </StyledTreeItem>
    );
  }

  return (
    <>
      {loading && (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
      {!loading && (
        <Grid container>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 2 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      )}
      {!loading && tree && (
        <Grid container>
          <TreeView
            sx={{
              height: '100%',
              flexGrow: 1,
              maxWidth: '100%',
              overflowY: 'auto',
              padding: (theme) => theme.spacing(0, 1),
            }}
            defaultCollapseIcon={<ExpandMoreRounded />}
            defaultExpandIcon={<ChevronRightRounded />}
            defaultEndIcon={<Box sx={{ width: 24 }} />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
          >
            {tree
              ?.filter((x) => !x.personal)
              ?.filter((x) => x.name?.toLowerCase().includes(search?.toLowerCase()))
              ?.map((t) => (
                <StyledTreeItem
                  key={t._id}
                  nodeId={t._id}
                  labelText={t.name}
                  labelIcon={FolderSpecial}
                  color={COLORS.PUBLIC}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (expanded.includes(t._id)) {
                      setExpanded(expanded.filter((x) => x !== t._id));
                    } else {
                      setExpanded([...expanded, t._id]);
                    }
                  }}
                >
                  {Array.isArray(t.children)
                    ? t.children
                        .filter((x) => x.area === t._id)
                        ?.filter((x) => {
                          if (x.type === 'document') {
                            return x.title?.toLowerCase().includes(search?.toLowerCase());
                          }
                          return x.name?.toLowerCase().includes(search?.toLowerCase());
                        })
                        .map((n) => renderTree(n))
                    : null}
                </StyledTreeItem>
              ))}
          </TreeView>
        </Grid>
      )}
    </>
  );
}

export default UserDLTree;
