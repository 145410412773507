import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { InputBase, InputLabel, Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { COLORS } from '../../constants';

export const PrimaryField = (props) => (
  <TextField
    sx={{
      color: (t) => (t.palette.mode === 'dark' ? 'white' : 'black'),
      borderColor: COLORS.SEARCH,
      '& label.Mui-focused': {
        color: (t) => (t.palette.mode === 'dark' ? 'white' : 'black'),
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: COLORS.SEARCH,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: COLORS.SEARCH,
        },
        '&:hover fieldset': {
          borderColor: COLORS.SEARCH,
        },
        '&.Mui-focused fieldset': {
          borderColor: COLORS.SEARCH,
        },
      },
      margin: (t) => t.spacing(1, 0),
    }}
    {...props}
  />
);

export const RoleField = ({ label, name, value, onChange, ...props }) => {
  return (
    <Grid container direction="row" spacing={2} alignItems={'center'}>
      <InputLabel htmlFor={name} sx={{ color: '#000', fontSize: '1.2rem' }}>
        {label}
      </InputLabel>
      <InputBase
        sx={{ border: '1px solid #ccc', borderRadius: '7px', marginLeft: '10px', padding: '5px' }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputProps={{ 'aria-label': 'naked', sx: { textAlign: 'center' }, ...props }}
      />
    </Grid>
  );
};

export default function InputField({ onSave, onCancel, initialValue, field, setField, ...props }) {
  const done = () => {
    if (onSave) {
      onSave(field);
      onCancel();
    }
  };

  const cancel = () => {
    setField(initialValue);
    onCancel();
  };

  return (
    <PrimaryField
      value={field}
      onChange={(e) => setField(e.target.value)}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" color="primary" onClick={done}>
              <CheckIcon sx={{ color: 'green' }} />
            </IconButton>
            <IconButton size="small" onClick={cancel} sx={{ color: 'red' }}>
              <CancelIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export function EditInputField({ initialValue, onSave, shrink = true, ...props }) {
  const [mode, setMode] = useState(false);
  const [field, setField] = useState(initialValue);

  const done = () => {
    if (onSave) {
      onSave(field);
      setMode(false);
    }
  };

  const cancel = () => {
    setField(initialValue);
    setMode(false);
  };

  useEffect(() => {
    if (!initialValue) return;
    setField(initialValue);
  }, [initialValue]);

  return (
    <PrimaryField
      value={field}
      onChange={(e) => setField(e.target.value)}
      variant="outlined"
      InputLabelProps={{ shrink: shrink }}
      disabled={!mode}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {mode ? (
              <>
                <IconButton size="small" color="primary" onClick={done}>
                  <CheckIcon sx={{ color: 'green' }} />
                </IconButton>
                <IconButton size="small" onClick={cancel} sx={{ color: 'red' }}>
                  <CancelIcon />
                </IconButton>
              </>
            ) : (
              <IconButton size="small" onClick={() => setMode(true)}>
                <EditIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export function StandardInputField({ field, setField, icon, shrink = true, ...props }) {
  return (
    <PrimaryField
      value={field}
      onChange={(e) => setField(e.target.value)}
      variant="outlined"
      InputLabelProps={{ shrink }}
      InputProps={{
        endAdornment: icon ? <InputAdornment position="end">{icon}</InputAdornment> : null,
      }}
      {...props}
    />
  );
}
