import { Subject, BehaviorSubject } from 'rxjs';
import UserManager from '../UserManager';

const task = new Subject();
const formEntry = new Subject();
const chat = new Subject();
const newTask = new Subject();
const alert = new Subject();
const selectUserDialog = new Subject();
const confirmDialog = new Subject();
const usersDialog = new Subject();
const theme = new BehaviorSubject();
const executedActions = new Subject();
const reviewDialog = new Subject();
const qhubAccessDialog = new Subject();
const pdfDialog = new Subject();
const dataRequestDialog = new Subject();

var userMap = {};
UserManager.getUserMap()
  .then((users) => (userMap = users))
  .catch(console.error);

function subscribeToChat(callback) {
  return chat.subscribe(callback);
}

function selectChat(userId) {
  chat.next(userMap[userId]);
}

function selectChatById(chatId) {
  chat.next(chatId);
}

function subscribeToNewTask(callback) {
  return newTask.subscribe(callback);
}

function setNewTask(params, onResult) {
  newTask.next({ params, onResult });
}

function subscribeToTask(callback) {
  return task.subscribe(callback);
}

function selectTask(taskId, onResult, onDelete, onMovingToBacklog) {
  task.next({ taskId, onResult, onDelete, onMovingToBacklog });
}

function subscribeToFormEntry(callback) {
  return formEntry.subscribe(callback);
}

function openFormEntry(entryId, onFormComplete, onFormDelete) {
  formEntry.next({ entryId, onFormComplete, onFormDelete });
}

function subscribeToAlert(callback) {
  return alert.subscribe(callback);
}

function setErrorAlert(message) {
  alert.next({ type: 'error', message });
}

function setWarningAlert(message) {
  alert.next({ type: 'warning', message });
}

function setAxiosErrorAlert(err) {
  console.error(err);
  let message = err?.response?.data?.message || 'Something went wrong';
  alert.next({ type: 'error', message });
}

function setSuccessAlert(message) {
  alert.next({ type: 'success', message });
}

function subscribeToSelectUser(callback) {
  return selectUserDialog.subscribe(callback);
}

function selectUser(onSelect, onCancel = null, params) {
  selectUserDialog.next({ onSelect, onCancel, ...params });
}

function selectUserWithTitle(title, onSelect, onCancel = null) {
  selectUserDialog.next({ onSelect, onCancel, title });
}

function subscribeToConfirm(callback) {
  return confirmDialog.subscribe(callback);
}

function setConfirm(title, onConfirm, content = 'Are you sure you want to continue?') {
  confirmDialog.next({ title, onConfirm, content });
}

function selectMultipleUsers(onResult, params) {
  usersDialog.next({ onResult, ...params });
}

function subscribeToUsersDialog(callback) {
  return usersDialog.subscribe(callback);
}

function subscribeToThemeChange(callback) {
  return theme.subscribe(callback);
}

function changeTheme(mode, color) {
  theme.next({ mode, color });
}

function subscribeToExecutedActions(callback) {
  return executedActions.subscribe(callback);
}

function reportNewExecutedAction(notification) {
  executedActions.next({ type: 'newAction', notification });
}

function reportUndoneExecutedAction(executedActionId) {
  executedActions.next({ type: 'undoneAction', executedActionId });
}

function subscribeToReviewDialog(callback) {
  return reviewDialog.subscribe(callback);
}

function openReviewDialog(reviewId, params) {
  reviewDialog.next({ reviewId, ...params });
}

function subscribeToDataRequestDialog(callback) {
  return dataRequestDialog.subscribe(callback);
}

function openDataRequestDialog(requestId, title) {
  dataRequestDialog.next({ requestId, title });
}

function subscribeToQhubAccessDialog(callback) {
  return qhubAccessDialog.subscribe(callback);
}

function openQhubAccessDialog(requestId) {
  qhubAccessDialog.next({ requestId });
}

function openPdfDialog(url, title) {
  pdfDialog.next({ url, title });
}

function subscribeToPdfDialog(callback) {
  return pdfDialog.subscribe(callback);
}

const StateManager = {
  setSuccessAlert,
  setErrorAlert,
  setWarningAlert,
  setAxiosErrorAlert,
  subscribeToAlert,
  selectTask,
  subscribeToTask,
  subscribeToFormEntry,
  openFormEntry,
  subscribeToNewTask,
  selectChatById,
  selectChat,
  subscribeToChat,
  subscribeToSelectUser,
  selectUser,
  selectUserWithTitle,
  setConfirm,
  subscribeToConfirm,
  setNewTask,
  selectMultipleUsers,
  subscribeToUsersDialog,
  subscribeToThemeChange,
  changeTheme,
  subscribeToExecutedActions,
  reportNewExecutedAction,
  reportUndoneExecutedAction,
  subscribeToReviewDialog,
  openReviewDialog,
  subscribeToQhubAccessDialog,
  openQhubAccessDialog,
  openPdfDialog,
  subscribeToPdfDialog,
  subscribeToDataRequestDialog,
  openDataRequestDialog,
};

export default StateManager;
