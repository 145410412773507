import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, CardActionArea, Avatar, DialogTitle, Tooltip } from '@mui/material';
import { DialogContent, InputBase } from '@mui/material';
import { DeleteOutlineRounded, PaletteRounded } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';
import StateManager from '../../../../Global/StateManager';
import { TooltipTypography, RoundedDialog, ColorPicker } from '../../../../Global/Components';
import { StandardDialogActions } from '../../../../Global/Components';
import { isArray, isEmpty } from 'lodash';
import axios from 'axios';
import EntriesTable from '../../../processes/pages/process/EntriesTable';

export default function ProcessTable({
  processId,
  editable,
  onDelete,
  view,
  hideTitle,
  hideCompleted,
  selectedStepId,
  onEdit,
  initialColor,
  initialTitle,
}) {
  const [loading, setLoading] = useState(true);
  const [process, setProcess] = useState(null);
  const [color, setColor] = useState(null);
  const [colorPicker, setColorPicker] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [title, setTitle] = useState(null);

  const [inProgressSteps, setInProgressSteps] = useState([]);
  const [workingInitial, setWorkingInitial] = useState(null);

  const pageSize = 10;

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);

  useEffect(() => {
    if (!processId) return;
    axios
      .get('/process/getProcessInfo', {
        params: {
          id: processId,
          selectedStepId: view === 'button' ? selectedStepId : null,
          state: hideCompleted ? 'working' : null,
          pageSize,
          view,
          withoutEntries: view !== 'button',
        },
      })
      .then(({ data }) => {
        setProcess(data.process);

        if (isArray(data.inProgress)) {
          setWorkingInitial({ entries: mapEntries(data.inProgress), total: data.process.inProcessEntriesCount });
        }

        if (view !== 'button') {
          if (isArray(data.inProgressSteps) && !isEmpty(data.inProgressSteps)) {
            setInProgressSteps(data.inProgressSteps);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [processId]); // eslint-disable-line

  function mapEntries(entries) {
    return entries.map((row) => ({
      ...row,
      id: row._id,
      ...row.referenceFieldsData,
    }));
  }

  if (view === 'button') {
    return (
      <Grid container style={{ height: '100%', position: 'relative' }}>
        <Grid
          container
          component={editable ? undefined : CardActionArea}
          sx={{ borderRadius: 1, py: 2, px: 2, height: '100%', background: color }}
          onClick={editable ? undefined : () => setDialog(true)}
        >
          <Grid container item alignItems={'center'} wrap="nowrap" justifyContent={editable ? '' : 'center'}>
            {editable ? (
              <InputBase
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  onEdit({ title: e.target.value });
                }}
                style={{ flexGrow: 1 }}
                className="non-draggable"
              />
            ) : (
              <TooltipTypography
                sx={{ color: color ? (theme) => theme.palette.getContrastText(color) : '' }}
                style={{ fontSize: 18 }}
              >
                {title}
              </TooltipTypography>
            )}
            <Avatar sx={{ background: blue[500], ml: 1, width: '50px', height: '50px' }}>
              <Typography variant="h5">{process?.inProcessEntriesCount}</Typography>
            </Avatar>
          </Grid>
        </Grid>
        {!editable && process && (
          <StepDialog
            title={title}
            open={dialog}
            onClose={() => setDialog(false)}
            process={process}
            initial={workingInitial}
            lineId={selectedStepId}
          />
        )}
        {editable && (
          <Grid
            container
            style={{ width: 'auto', position: 'absolute', right: 0, top: 0 }}
            alignItems={'center'}
            wrap="nowrap"
          >
            <Tooltip title="Change color" placement="top">
              <IconButton
                size="small"
                onClick={(e) => {
                  setColorPicker(e.currentTarget);
                  e.stopPropagation();
                }}
                style={{ background: 'white' }}
                className="non-draggable"
              >
                <PaletteRounded style={{ color: color || grey[500] }} />
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <DeleteOutlineRounded style={{ color: red[500] }} />
            </IconButton>
            <ColorPicker
              anchor={colorPicker}
              open={Boolean(colorPicker)}
              column={400}
              customPicker
              onClose={() => setColorPicker(null)}
              onResult={(color) => {
                setColor(color);
                onEdit({ color });
              }}
              className="non-draggable"
            />
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container style={{ padding: 8, height: '100%' }}>
      <Grid container style={{ overflow: 'auto', maxHeight: '100%', position: 'relative' }} alignContent={'flex-start'}>
        {!hideTitle && (
          <Grid container item sx={{ my: 1 }}>
            <Typography variant="h5" gutterBottom>
              {loading || !process ? <Skeleton width={150} /> : process.title}
            </Typography>
          </Grid>
        )}

        {editable && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            <DeleteOutlineRounded style={{ color: red[500] }} />
          </IconButton>
        )}

        {view === 'step' ? (
          inProgressSteps.map((step, index) => (
            <EntriesTable
              key={index}
              state="working"
              process={process}
              title={step.title}
              lineId={step.lineId}
              initial={step}
              withoutPaper
            />
          ))
        ) : (
          <EntriesTable state="working" process={process} withoutPaper />
        )}

        {!hideCompleted && <EntriesTable state="completed" process={process} withoutPaper />}
      </Grid>
    </Grid>
  );
}

function StepDialog({ title, initial, open, onClose, process, lineId }) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth className="scroll-bar">
      <DialogContent className={'horizontal-scroll-bar'}>
        {isEmpty(initial?.entries) || !isArray(initial?.entries) ? (
          <Grid container style={{ height: '25vh' }} justifyContent={'center'} alignItems={'center'}>
            <Typography>No entries available</Typography>
          </Grid>
        ) : (
          <EntriesTable
            state="working"
            process={process}
            title={title}
            lineId={lineId}
            initial={initial}
            withoutPaper
          />
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
