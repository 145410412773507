import React, { useState, useEffect } from 'react';
import { Button, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Grid, IconButton, Typography, CircularProgress } from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { DeleteOutline, Edit, Add } from '@mui/icons-material';
import { RoundedDialog, User, DateTimePicker } from '../../../Global/Components';
import { red, grey, blue } from '@mui/material/colors';
import axios from 'axios';
import moment from 'moment';
import StateManager from '../../../Global/StateManager';

export default function RecordedTime({ open, onClose, taskId }) {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [editOpen, setEditOpen] = useState(false);
  const [selected, setSelected] = useState();

  function reload() {
    setLoading(true);

    axios
      .get('/tasks/getTaskIntervals', { params: { taskId } })
      .then((res) => {
        setRecords(res.data.intervals);
        setTotal(res.data.total);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function update() {
    setUpdating(true);

    axios
      .get('/tasks/getTaskIntervals', { params: { taskId } })
      .then((res) => {
        setRecords(res.data.intervals);
        setTotal(res.data.total);
        setUpdating(false);
      })
      .catch(() => {
        setUpdating(false);
      });
  }

  useEffect(() => {
    if (open) reload();
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  function formatDate(date) {
    let parsed = moment(date);
    return parsed.format('DD MMMM YYYY');
  }
  function formatTime(date) {
    let parsed = moment(date);
    return parsed.format('hh:mm:ss A');
  }

  function formatDuration(duration) {
    return moment.utc(duration * 1000).format('HH:mm:ss');
  }

  return (
    <RoundedDialog open={open} maxWidth="md" fullWidth onClose={onClose} className="scroll-bar">
      {!loading && <DialogTitle>{`Total ${formatDuration(total)} recorded`}</DialogTitle>}

      <DialogContent>
        {loading ? (
          <Grid container>
            <CircularProgress style={{ color: blue[500], margin: '5rem auto' }} />
          </Grid>
        ) : (
          <Grid container style={{ maxHeight: '70vh' }}>
            {updating && (
              <Grid container item>
                <CircularProgress style={{ color: blue[500], margin: '0.5rem auto' }} />
              </Grid>
            )}
            {records.length === 0 && <Typography variant="h6">No time recorded for this task</Typography>}
            {records.length > 0 && (
              <TableContainer style={{ maxHeight: '70vh' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>User</TableCell>
                      <TableCell style={{ fontWeight: 700 }}>Date</TableCell>
                      <TableCell style={{ fontWeight: 700 }}>Start</TableCell>
                      <TableCell style={{ fontWeight: 700 }}>End</TableCell>
                      <TableCell style={{ fontWeight: 700 }}>Time recorded</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {records.map((record) => (
                      <TableRow key={record._id}>
                        <TableCell>
                          <User id={record.userId} avatarSize={32} />
                        </TableCell>
                        <TableCell>{formatDate(record.startedAt)}</TableCell>
                        <TableCell>{formatTime(record.startedAt)}</TableCell>
                        <TableCell>{formatTime(record.stopedAt)}</TableCell>
                        <TableCell>{formatDuration(record.duration)}</TableCell>
                        <TableCell style={{ width: '1.5em' }}>
                          <IconButton
                            style={{
                              width: '1.5em',
                              height: '1.5em',
                            }}
                            onClick={() => {
                              setSelected(record);
                              setEditOpen(true);
                            }}
                          >
                            <Edit style={{ color: grey[500] }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        )}
        <TaskIntervalDialog
          open={editOpen}
          onClose={() => setEditOpen(false)}
          interval={selected}
          onDelete={update}
          onSave={update}
          taskId={taskId}
        />
      </DialogContent>

      <DialogActions>
        {!loading && (
          <Button
            style={{ color: blue[500], marginRight: 'auto' }}
            startIcon={<Add style={{ color: blue[500] }} />}
            onClick={() => {
              setSelected(null);
              setEditOpen(true);
            }}
          >
            add interval
          </Button>
        )}
        <Button onClick={onClose} style={{ color: red[500] }}>
          close
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}

function TaskIntervalDialog({ interval, open, onClose, onSave, onDelete, taskId }) {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!open) return;

    if (interval) {
      setStart(moment(interval.startedAt));
      setEnd(moment(interval.stopedAt));
    } else {
      setStart(moment().subtract(2, 'hours'));
      setEnd(moment());
    }
  }, [open, interval]);

  function save() {
    if (end.isSameOrBefore(start)) {
      StateManager.setErrorAlert('Start time must be before end time');
      return;
    }
    setSaving(true);
    let body = {
      taskId,
      start,
      end,
    };
    if (interval) {
      body.intervalId = interval._id;
    }
    axios
      .post(`/tasks/${interval ? 'editTaskInterval' : 'addTaskInterval'}`, body)
      .then(() => {
        StateManager.setSuccessAlert('Interval has been saved');
        setSaving(false);
        onSave();
        onClose();
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to save interval');
        setSaving(false);
      });
  }

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this time interval', deleteInterval);
  }

  function deleteInterval() {
    setSaving(true);
    axios
      .post('/tasks/deleteTaskInterval', { intervalId: interval._id })
      .then(() => {
        StateManager.setSuccessAlert('Interval has been deleted');
        setSaving(false);
        onDelete();
        onClose();
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to delete interval');
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        {interval?.userId && (
          <Grid container sx={{ mb: 1 }} alignItems={'center'}>
            <Typography sx={{ mr: 1 }} variant="h6">
              Recorded by
            </Typography>
            <User avatarSize={24} id={interval?.userId} />
          </Grid>
        )}

        <Grid container>
          <Typography>From:</Typography>
        </Grid>
        <Grid container>
          <DateTimePicker label="Select start time" value={start} onChange={(value) => setStart(value)} />
        </Grid>
        <Grid container>
          <Typography>To:</Typography>
        </Grid>
        <Grid container>
          <DateTimePicker label="Select end time" value={end} onChange={(value) => setEnd(value)} />
        </Grid>
      </DialogContent>

      <DialogActions>
        {!saving && interval && (
          <Button
            style={{ color: red[500], marginRight: 'auto' }}
            startIcon={<DeleteOutline style={{ color: red[500] }} />}
            onClick={confirmDelete}
          >
            delete
          </Button>
        )}
        {!saving && (
          <Button onClick={onClose} style={{ color: red[500] }}>
            close
          </Button>
        )}
        {!saving && (
          <Button onClick={save} style={{ color: blue[500] }}>
            save
          </Button>
        )}
        {saving && <CircularProgress style={{ color: blue[500], height: 30, width: 30, margin: 3 }} />}
      </DialogActions>
    </RoundedDialog>
  );
}
