export const GETPROFILE_ATTEMPT = 'GETPROFILE_ATTEMPT';
export const GETPROFILE_SUCCESS = 'GETPROFILE_SUCCESS';
export const GETPROFILE_FAIL = 'GETPROFILE_FAIL';

export const UPDATEPROFILE_ATTEMPT = 'UPDATEPROFILE_ATTEMPT';
export const UPDATEPROFILE_SUCCESS = 'UPDATEPROFILE_SUCCESS';
export const UPDATEPROFILE_FAIL = 'UPDATEPROFILE_FAIL';

export const SETPROFILE = 'SETPROFILE';

export const UPLOADPICTURE_ATTEMPT = 'UPLOADPICTURE_ATTEMPT';
export const UPLOADPICTURE_SUCCESS = 'UPLOADPICTURE_SUCCESS';
export const UPLOADPICTURE_FAIL = 'UPLOADPICTURE_FAIL';

export const CHANGEPASSWORD_ATTEMPT = 'CHANGEPASSWORD_ATTEMPT';
export const CHANGEPASSWORD_SUCCESS = 'CHANGEPASSWORD_SUCCESS';
export const CHANGEPASSWORD_FAIL = 'CHANGEPASSWORD_FAIL';

export const FETCHDEFAULTUSER_ATTEMPT = 'FETCHDEFAULTUSER_ATTEMPT';
export const FETCHDEFAULTUSER_SUCCESS = 'FETCHDEFAULTUSER_SUCCESS';
export const FETCHDEFAULTUSER_FAIL = 'FETCHDEFAULTUSER_FAIL';
