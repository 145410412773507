import React, { useState } from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import { DueDate } from '../workflowComponents';
import { ReoccuringSettings } from '../workflowComponents';
import { SaveButton } from '../../../Global/Components';
import { RoundedDialog } from '../../../Global/Components';
import moment from 'moment';

export default function ReoccuringDialog({ task, open, onClose }) {
  const [repeatCycle, setRepeatCycle] = useState(task.repeatCycle);
  const [newDueDate, setNewDueDate] = useState(moment(task.dueAt));
  const [repeatDays, setRepeatDays] = useState(task.repeatDays);

  return (
    <RoundedDialog fullWidth elevation={28} maxWidth="sm" open={open} onClose={() => onClose(null)}>
      <DialogContent>
        <ReoccuringSettings
          setRepeatCycle={setRepeatCycle}
          repeatCycle={repeatCycle}
          repeatDays={repeatDays}
          setRepeatDays={setRepeatDays}
        />
        <div style={{ margin: '3rem 0 1rem 0' }}>
          <DueDate editable={true} value={newDueDate} setValue={setNewDueDate} />
        </div>
      </DialogContent>

      <DialogActions>
        <SaveButton
          onClick={() => {
            onClose({ repeatCycle, dueAt: newDueDate, repeatDays });
          }}
          saving={false}
        />
      </DialogActions>
    </RoundedDialog>
  );
}
