import React, { useState, useEffect } from 'react';

import EditorInstance from '../Editor/preview';
import { ImageBlock } from '../ImageBlock';
import { FieldDataGrid } from '../../../../../../Global/Fields';
import { Box } from '@mui/material';

export default function SectionsPreview({ initial, hasToc, search, setSearch }) {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(initial || []);
  }, [initial]);

  return (
    <React.Fragment>
      {sections?.map((section, index) => {
        if (section.type === 'editor') {
          return (
            <EditorInstance
              initial={section.value}
              key={section.id}
              isFirst={index === 0}
              isLast={index === sections.length - 1}
              totalSections={sections.length}
              hasToc={hasToc}
              toc={sections
                ?.filter((x) => x.type === 'editor')
                ?.map((x) => x?.value?.blocks)
                ?.flat()}
              index={index}
              search={search}
              setSearch={setSearch}
            />
          );
        }
        if (section?.type === 'table') {
          const columns = section.value.columns;
          const rows = section.value.rows;
          return (
            <Box key={section.id} sx={{ mx: '70px', width: '100%', my: 2 }}>
              <FieldDataGrid columns={columns} initial={rows} textWrapping={true} autoRowHeight={true} />
            </Box>
          );
        }
        if (section?.type === 'image-section') {
          const value = section.value;
          return (
            <Box
              key={section.id}
              sx={{
                mx: '50px',
                width: '100%',
                my: 2,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ImageBlock initial={value} />
            </Box>
          );
        }
        if (section?.type === 'drawing') {
          const value = section.value;
          const image = value.image;
          const size = value.size;
          return (
            <Box
              key={section.id}
              sx={{
                mx: '50px',
                width: '100%',
                my: 2,
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Box
                component="img"
                src={image}
                alt={`drawing-${section.id}`}
                sx={{
                  my: 2,
                  width: size === 'small' ? 250 : size === 'medium' ? 350 : 500,
                  height: 'auto',
                  objectFit: 'contain',
                }}
                lazy="loading"
              />
            </Box>
          );
        }
        return null;
      })}
    </React.Fragment>
  );
}
