import React, { forwardRef } from 'react';
import { Dialog, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(1.5),
  },
}));

export default function ImageDialog({ src, open, onClose, imageStyle, ...props }) {
  return (
    <StyledDialog maxWidth="xl" open={open} onClose={onClose} TransitionComponent={Transition} {...props}>
      <img alt="pic" src={src} style={{ maxHeight: '80vh', maxWidth: '80vw', minWidth: '30vw', ...imageStyle }} />
    </StyledDialog>
  );
}
