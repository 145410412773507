import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Typography, Grid, IconButton, CircularProgress } from '@mui/material';
import { Tooltip, TextField, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { FiberManualRecord, AddRounded } from '@mui/icons-material';
import { RoundedDialog, ColorPicker, StandardDialogActions } from './index';
import { blue } from '@mui/material/colors';
import StateManager from '../StateManager';
import axios from 'axios';

export default function TagsMenu({ type, anchor, open, onClose, onResult }) {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tagDialog, setTagDialog] = useState(false);

  useEffect(() => {
    if (!type) return;
    axios
      .get('/tags/getTypeTags', { params: { type } })
      .then((res) => {
        setTags(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [type]);

  function addTag(tag) {
    if (!tag?._id) return;
    setTags([...tags, tag]);
    onResult(tag);
    onClose();
  }

  return (
    <Menu anchorEl={anchor} open={open} onClose={onClose}>
      {loading && (
        <Grid container>
          <CircularProgress color="primary" size={22} style={{ margin: 8 }} />
        </Grid>
      )}
      {tags.map((tag) => (
        <MenuItem
          key={tag._id}
          onClick={() => {
            onResult(tag);
            onClose();
          }}
        >
          <ListItemIcon>
            <FiberManualRecord style={{ color: tag.color }} />
          </ListItemIcon>
          <ListItemText primary={<Typography noWrap>{tag.text}</Typography>} />
        </MenuItem>
      ))}
      <MenuItem onClick={() => setTagDialog(true)}>
        <ListItemIcon>
          <AddRounded style={{ color: blue[900] }} />
        </ListItemIcon>
        <ListItemText primary={<Typography noWrap>Add tag</Typography>} />
      </MenuItem>
      <TagDialog
        open={tagDialog}
        onClose={() => {
          setTagDialog(false);
        }}
        onResult={addTag}
        tagType={type}
      />
    </Menu>
  );
}

function TagDialog({ open, onClose, onResult, tagType }) {
  const [text, setText] = useState('');
  const [color, setColor] = useState(blue[500]);
  const [saving, setSaving] = useState(false);
  const [colourPicker, setColourPicker] = useState();

  useEffect(() => {
    if (!open) return;
    setText('');
    setColor(blue[500]);
  }, [open]);

  function createTag() {
    if (!text) {
      StateManager.setErrorAlert('Specify tag text');
      return;
    }
    setSaving(true);
    axios
      .post('/tags/createTag', { text, type: tagType, color })
      .then((res) => {
        StateManager.setSuccessAlert('Tag has been added');
        setSaving(false);
        onResult(res.data);
        onClose();
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function selectColor(color) {
    if (color?.color) {
      setColor(color.color);
    }
    setColourPicker(null);
  }

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={() => onClose(null)}>
      <DialogTitle>New tag</DialogTitle>

      <DialogContent>
        <Grid container alignItems="center">
          <TextField
            variant="outlined"
            label="Tag title"
            value={text}
            style={{ flexGrow: 1, marginRight: 5, height: 'fit-content' }}
            onChange={(event) => setText(event.target.value)}
            inputProps={{ maxLength: 64 }}
          />
          <Tooltip title="Pick color" placement="top">
            <IconButton onClick={(e) => setColourPicker(e.currentTarget)}>
              <FiberManualRecord style={{ color }} />
            </IconButton>
          </Tooltip>
          <ColorPicker anchor={colourPicker} open={Boolean(colourPicker)} onClose={selectColor} />
        </Grid>
      </DialogContent>
      <StandardDialogActions saving={saving} onClose={() => onClose(null)} onDone={createTag} hideDone={!text} />
    </RoundedDialog>
  );
}
