import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, CircularProgress, Grid, Typography } from '@mui/material';
import { ExpandMoreRounded, ChevronRightRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import { TreeView, TreeItem } from '@mui/x-tree-view';

import StateManager from '../../../../Global/StateManager';
import axios from 'axios';

export default function FolderTreeDialog({ workspaceId, open, onClose, onResult }) {
  const [loading, setLoading] = useState(false);
  const [tree, setTree] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(null);
    if (!workspaceId || !open) return;
    setTree(null);
    setLoading(true);
    axios
      .get('/workhub/workspace/getFolderTree', { params: { workspaceId } })
      .then((res) => {
        setLoading(false);
        setTree(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [workspaceId, open]);

  function done() {
    onResult(selected);
    onClose();
  }

  function renderTree(node) {
    return (
      <TreeItem
        key={node._id}
        nodeId={node._id}
        label={node.title}
        onClick={() => setSelected({ _id: node._id, title: node.title })}
      >
        {Array.isArray(node.children) ? node.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  }

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>Pick a folder</DialogTitle>

      <DialogContent>
        {loading && (
          <Grid container justifyContent="center" alignItems="center" style={{ height: '25vh' }}>
            <CircularProgress color="primary" />
          </Grid>
        )}
        {tree?.children?.length === 0 && (
          <Typography gutterBottom color="textSecondary" variant="h6">
            Sorry, there are no folders to pick from <span style={{ fontSize: '2em' }}>😕</span>
          </Typography>
        )}
        {tree && tree.children.length > 0 && (
          <Grid container>
            <TreeView
              style={{ minHeight: 200 }}
              defaultCollapseIcon={<ExpandMoreRounded />}
              defaultExpanded={[tree._id]}
              defaultExpandIcon={<ChevronRightRounded />}
            >
              {renderTree(tree)}
            </TreeView>
          </Grid>
        )}
        {selected && (
          <Typography style={{ marginTop: '1rem' }} variant="h6">
            You picked: {selected.title}
          </Typography>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={!selected} />
    </RoundedDialog>
  );
}
