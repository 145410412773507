import React, { useState, useEffect } from 'react';
import { Button, DialogTitle, DialogContent, DialogActions, Grid, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { ArrowForward, CheckCircle, ArrowDownward, CancelOutlined, ClearOutlined } from '@mui/icons-material';
import { RoundedDialog, User, UserGroup } from '../../../Global/Components';
import { FormatDate, FormatDateFull } from '../../../Global/Functions';
import { red, grey, blue, green, deepPurple, amber } from '@mui/material/colors';
import axios from 'axios';

export default function HistoryDialog({ open, onClose, taskId }) {
  const [logs, setLogs] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!taskId || !open) return;

    setLoading(true);
    axios
      .get('/tasks/getTaskHistory', { params: { taskId } })
      .then((res) => {
        setLogs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLogs([]);
        setLoading(false);
      });
  }, [taskId, open]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth className="scroll-bar">
      <DialogTitle>Task history</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <Grid container>
            {logs.length === 0 && (
              <Typography variant="h6" color="textSecondary">
                No history recorderd for this task
              </Typography>
            )}
            {logs?.length > 0 && (
              <Grid container>
                {logs.map((item) => (
                  <Log item={item} />
                ))}
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: red[500] }} onClick={onClose}>
          close
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}

// ['statusChange', 'assigneeChange', 'ownerChange', 'completing', 'titleChange', 'cancellation', 'failure']

const statusColors = {
  Working: blue[600],
  Complete: green[600],
  Held: amber[600],
  Overdue: red[600],
  Started: deepPurple[600],
  Failed: red[600],
  Cancelled: red[600],
  Approved: green[600],
  Denied: red[600],
};

const getStatusColor = (status) => statusColors[status] || grey[500];

function Log({ item }) {
  var content = null;
  if (item.type === 'statusChange') {
    content = (
      <>
        <Grid container item alignItems="center">
          <User id={item.userId} dense />
          <Typography sx={{ margin: '0 0.25em' }}>changed status:</Typography>
        </Grid>
        <Grid container item alignItems="center" sx={{ pt: 1 }}>
          <Typography
            style={{ color: item.data.oldStatusColor || getStatusColor(item.data.oldStatus), fontWeight: 700 }}
          >
            {item.data.oldStatus}
          </Typography>
          <ArrowForward style={{ color: grey[500], margin: '0 1rem' }} />
          <Typography
            style={{ color: item.data.newStatusColor || getStatusColor(item.data.newStatus), fontWeight: 700 }}
          >
            {item.data.newStatus}
          </Typography>
        </Grid>
      </>
    );
  }

  if (item.type === 'assigneeChange') {
    content = (
      <>
        <Grid container item alignItems="center">
          <User id={item.userId} dense />
          <Typography sx={{ margin: '0 0.25em' }}>changed assignee:</Typography>
        </Grid>
        <Grid container item alignItems="center" sx={{ pt: 1 }}>
          <User id={item.data.oldAssignee} dense />
          <ArrowForward style={{ color: grey[500], margin: '0 1rem' }} />
          <User id={item.data.newAssignee} dense />
        </Grid>
      </>
    );
  }

  if (item.type === 'ownerChange') {
    content = (
      <>
        <Grid container item alignItems="center">
          <User id={item.userId} dense />
          <Typography sx={{ margin: '0 0.25em' }}>changed the owner:</Typography>
        </Grid>
        <Grid container item alignItems="center" sx={{ pt: 1 }}>
          <User id={item.data.oldOwner} dense />
          <ArrowForward style={{ color: grey[500], margin: '0 1rem' }} />
          <User id={item.data.newOwner} dense />
        </Grid>
      </>
    );
  }

  if (item.type === 'completing') {
    content = (
      <>
        <Grid container item alignItems="center">
          <User id={item.userId} dense />
          <Typography sx={{ margin: '0 0.25em' }}>completed the task</Typography>
          <CheckCircle style={{ color: green[500], marginLeft: '0.5rem' }} />
        </Grid>
      </>
    );
  }

  if (item.type === 'titleChange') {
    content = (
      <>
        <Grid container item alignItems="center">
          <User id={item.userId} dense />
          <Typography sx={{ margin: '0 0.25em' }}>changed the title:</Typography>
        </Grid>
        <Grid container item sx={{ pt: 1 }}>
          <Grid container item>
            <Typography variant="h6" gutterBottom>
              {item.data.oldTitle}
            </Typography>
          </Grid>
          <Grid container item>
            <ArrowDownward style={{ color: grey[500], marginBottom: '0.5rem' }} />
          </Grid>
          <Grid container item>
            <Typography variant="h6">{item.data.newTitle}</Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  if (item.type === 'cancellation') {
    content = (
      <>
        <User id={item.userId} dense />
        <Typography sx={{ margin: '0 0.25em' }}>cancelled the task</Typography>
        <CancelOutlined style={{ color: red[500], marginLeft: '0.5rem' }} />
      </>
    );
  }

  if (item.type === 'failure') {
    content = (
      <>
        <User id={item.userId} dense />
        <Typography sx={{ margin: '0 0.25em' }}>marked the task as failed</Typography>
        <ClearOutlined style={{ color: red[500], marginLeft: '0.5rem' }} />
      </>
    );
  }

  if (item.type === 'dueDateChange') {
    content = (
      <>
        <Grid container item alignItems="center">
          <User id={item.userId} dense />
          <Typography sx={{ margin: '0 0.25em' }}>changed the due date:</Typography>
        </Grid>
        <Grid container item alignItems="center" sx={{ pt: 1 }}>
          <Typography>{FormatDateFull(item.data.oldDueDate)}</Typography>
          <ArrowForward style={{ color: grey[500], margin: '0 1rem' }} />
          <Typography>{FormatDateFull(item.data.newDueDate)}</Typography>
        </Grid>
      </>
    );
  }

  if (item.type === 'assigneeAdded') {
    content = (
      <>
        <Grid container item alignItems="center">
          <User id={item.userId} dense />
          <Typography style={{ margin: '0px 1rem' }}>added</Typography>
          <User id={item.data.addedAssignee} dense />
        </Grid>
        <Grid container item alignItems="center" sx={{ pt: 1 }}>
          <Grid container item>
            <Typography color="textSecondary" gutterBottom>
              Task assignees after change are:
            </Typography>
          </Grid>
          <Grid container item>
            <UserGroup ids={item.data.currentAssignees} max={8} dialogTitle="Task assignees" />
          </Grid>
        </Grid>
      </>
    );
  }

  if (item.type === 'assigneeRemoved') {
    content = (
      <>
        <Grid container item alignItems="center">
          <User id={item.userId} dense />
          <Typography style={{ margin: '0px 1rem' }}>removed</Typography>
          <User id={item.data.removedAssignee} dense />
        </Grid>
        <Grid container item alignItems="center" sx={{ pt: 1 }}>
          <Grid container item>
            <Typography color="textSecondary" gutterBottom>
              Task assignees after change are:
            </Typography>
          </Grid>
          <Grid container item>
            <UserGroup ids={item.data.currentAssignees} max={8} dialogTitle="Task assignees" />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Grid container item sx={{ my: 1, borderBottom: `1px solid ${grey[300]}` }}>
      <Grid container item sx={{ py: 1 }}>
        <Typography color="textSecondary">{FormatDate(item.createdAt)}</Typography>
      </Grid>
      <Grid container item sx={{ py: 1 }} alignItems="center">
        {content}
      </Grid>
    </Grid>
  );
}
