import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { GeneralButton } from '../../../Global/Components';
import { ProcessIcon } from '../../../Global/Icons';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

export default function ProcessStep({ ongoingStepId }) {
  const [process, setProcess] = useState();
  const history = useHistory();

  useEffect(() => {
    if (!ongoingStepId) {
      setProcess(null);
      return;
    }

    axios
      .get('/process/getOngoingProcessByStep', { params: { stepId: ongoingStepId } })
      .then((res) => setProcess(res.data))
      .catch(console.error);
  }, [ongoingStepId]);

  if (!process) return null;

  return (
    <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
      <Typography variant="h6">{`This is a task for process ${process.title}`}</Typography>
      <GeneralButton
        startIcon={<ProcessIcon />}
        style={{ marginLeft: '1rem' }}
        onClick={() => history.push(`/processes/step/${ongoingStepId}`)}
      >
        Go to step
      </GeneralButton>
    </Grid>
  );
}
