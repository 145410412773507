import React, { useState, useEffect } from 'react';
import { Grid, TextField, Typography, Collapse, FormControlLabel, Checkbox, Button } from '@mui/material';
import { PersonRounded } from '@mui/icons-material';
import { UserChip, RichTextField } from './index';
import { PriorityButton, Fields, SubTasks } from '../../Hubs/tasks/Components';
import Uploader from '../Uploader';
import StateManager from '../StateManager';

export default function TaskParams({ initial, onResult, assignees, hideTitle, withPlannedTime }) {
  const [title, setTitle] = useState('');
  const [priority, setPriority] = useState('Low');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [sendIndividually, setSendIndividually] = useState(false);
  const [subTasks, setSubTasks] = useState([]);
  const [fields, setFields] = useState([]);

  const [setPlannedTime, setSetPlannedTime] = useState(false);
  const [plannedDays, setPlannedDays] = useState(1);

  useEffect(() => {
    setTitle(initial?.title || '');
    setDescription(initial?.description || '');
    setPriority(initial?.priority || 'Low');
    setAttachments(initial?.attachments || []);
    setAssignedTo(initial?.assignedTo || []);
    setSendIndividually(initial?.sendIndividually || false);
    setSubTasks(initial?.subTasks || []);
    setFields(initial?.fields || []);
    setPlannedDays(initial?.plannedTime?.days || 1);
    setSetPlannedTime(Boolean(initial?.plannedTime?.days));
  }, [initial]);

  function saveChange(change) {
    if (!onResult) return;
    const result = {
      title,
      priority,
      description,
      attachments,
      assignedTo,
      sendIndividually,
      subTasks,
      fields,
      plannedTime: withPlannedTime && setPlannedTime && plannedDays ? { days: plannedDays } : undefined,
      ...change,
    };
    onResult(result);
  }

  function addUsers() {
    StateManager.selectMultipleUsers(
      (res) => {
        if (res.users) {
          setAssignedTo(res.users);
          saveChange({ assignedTo: res.users });
        }
      },
      { initiallySelected: assignedTo },
    );
  }

  const typographyProps = {
    variant: 'subtitle1',
    sx: { fontWeight: 500 },
  };

  return (
    <Grid container direction="column">
      {!hideTitle && (
        <Grid container item alignItems="center" sx={{ py: 1 }}>
          <TextField
            value={title}
            variant="outlined"
            label="Task title"
            id="task-title"
            fullWidth
            onChange={(e) => {
              const value = e.target.value;
              setTitle(value);
              saveChange({ title: value });
            }}
          />
        </Grid>
      )}

      {withPlannedTime && (
        <Grid container item alignItems="center" sx={{ py: 1 }}>
          <FormControlLabel
            label="Set planned time"
            control={<Checkbox checked={setPlannedTime} />}
            onChange={(e) => {
              setSetPlannedTime(e.target.checked);
              saveChange({ plannedTime: e.target.checked && plannedDays ? { days: plannedDays } : undefined });
            }}
          />

          <TextField
            value={plannedDays}
            disabled={!setPlannedTime}
            type="number"
            variant="standard"
            size="small"
            sx={{ width: 50, ml: 4 }}
            onChange={(e) => {
              const value = e.target.value === '' ? '' : Number(e.target.value);
              if (value !== '' && value < 0) return;
              setPlannedDays(value);
              saveChange({ plannedTime: setPlannedTime && value ? { days: value } : undefined });
            }}
          />

          <Typography color={setPlannedTime ? 'textPrimary' : 'textSecondary'} sx={{ ml: 1 }}>
            days are planned for the task
          </Typography>
        </Grid>
      )}

      <Grid container item alignItems="center" sx={{ py: 1 }}>
        <Typography style={{ marginRight: 16 }} {...typographyProps}>
          Priority:
        </Typography>
        <PriorityButton priority={priority} setPriority={setPriority} />
      </Grid>

      {assignees && (
        <>
          <Grid container item alignItems="center" sx={{ py: 1 }}>
            <Typography {...typographyProps} noWrap>
              Send to:
            </Typography>

            <Button
              sx={{ mx: 2 }}
              onClick={addUsers}
              variant="outlined"
              startIcon={<PersonRounded />}
              className={'selectUser'}
            >
              Pick users
            </Button>

            {assignedTo.map((user, i) => (
              <UserChip
                key={i}
                id={user}
                onDelete={() => {
                  const remaining = assignedTo.filter((x) => x !== user);
                  setAssignedTo(remaining);
                  saveChange({ assignedTo: remaining });
                }}
              />
            ))}
          </Grid>
          <Collapse style={{ width: '100%' }} in={assignedTo.length > 1}>
            <Grid container item>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={sendIndividually}
                    onChange={(event) => {
                      setSendIndividually(event.target.checked);
                      saveChange({ sendIndividually: event.target.checked });
                    }}
                    disabled={fields.length > 0 && fields.some((x) => x.type === 'ru')}
                  />
                }
                label="Send as individual tasks to all assignees"
              />
            </Grid>
          </Collapse>
        </>
      )}

      <Grid container item sx={{ py: 1 }}>
        <Typography {...typographyProps} gutterBottom>
          Description:
        </Typography>
        <RichTextField
          placeholder="Provide description for the task"
          editable
          initial={description}
          onChange={(value) => {
            setDescription(value);
            saveChange({ description: value });
          }}
        />
      </Grid>

      <Grid container item sx={{ py: 1 }}>
        <Typography {...typographyProps} gutterBottom>
          Attachments:
        </Typography>
        <Uploader
          uploaded={attachments}
          onChange={(value) => {
            setAttachments(value);
            saveChange({ attachments: value });
          }}
          withLinkedFeatures={true}
        />
      </Grid>

      <Grid container item sx={{ py: 1 }}>
        <Typography {...typographyProps} gutterBottom>
          Fields:
        </Typography>
        <Fields
          initial={fields}
          onItemsChange={(value) => {
            setFields(value);
            saveChange({ fields: value });
          }}
          hideTasks
        />
      </Grid>

      <Grid container item sx={{ py: 1 }}>
        <SubTasks
          subTasks={subTasks}
          setSubTasks={(value) => {
            setSubTasks(value);
            saveChange({ subTasks: value });
          }}
          isNewTask
        />
      </Grid>
    </Grid>
  );
}
