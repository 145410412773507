import React from 'react';

import Button from '@mui/material/Button';

export default function PrimaryButton({ title, action, ...props }) {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#2e7d32',
        color: 'white',
        '&:hover': {
          backgroundColor: '#2e7d32',
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: '#2e7d32',
        },
        '&:disabled': {
          boxShadow: 'none',
          backgroundColor: '#ccc',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
      }}
      onClick={action}
      {...props}
    >
      {title}
    </Button>
  );
}
