import { DialogTitle, DialogContent, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions } from '../Components';

export default function HelpDialog({ open, onClose, initial, onResult }) {
  const [text, setText] = useState('');

  useEffect(() => {
    setText(initial);
  }, [initial]);

  function done() {
    onResult(text);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth className="non-draggable">
      <DialogTitle>Help text</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          placeholder="Enter help text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          multiline
          minRows={5}
          maxRows={10}
          inputProps={{ maxLength: 1024 }}
        />
      </DialogContent>
      <StandardDialogActions onDone={done} onClose={onClose} />
    </RoundedDialog>
  );
}
