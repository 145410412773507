import { grey, blue, indigo, amber, green, red, purple } from '@mui/material/colors';

const statusColors = {
  Sent: grey[400],
  'Being reviewed': blue[400],
  Responded: indigo[400],
  'Awaiting feedback': amber[400],
  Actioned: blue[400],
  Completed: green[400],
  Cancelled: red[400],
  'Not Started': grey[100], //this and all below should be deprecated
  Held: amber[100],
  Started: blue[100],
  Working: blue[100],
  Complete: green[100],
};

const types = [
  { text: 'Bug', color: red[400] },
  { text: 'Help', color: amber[400] },
  { text: 'Improvement', color: indigo[400] },
];

const typeColors = {
  Bug: red[400],
  Help: amber[400],
  Improvement: indigo[400],
  bug: red[400],
  help: amber[400],
  improvement: indigo[400],
};

const priorityColors = {
  None: grey[400],
  Low: green[400],
  Medium: amber[400],
  High: red[400],
  Critical: purple[400],
};

const statuses = [
  'Sent',
  'Being reviewed',
  'Responded',
  'Awaiting feedback',
  'Actioned',
  'Completed',
  'Not Started',
  'Held',
  'Started',
  'Working',
  'Held',
  'Complete',
];

// Undefined should be deprecated after all existing tickets are prioritized
const priorities = ['None', 'Low', 'Medium', 'High', 'Critical'];

const hubs = [
  'Workflow',
  'Projects',
  'Form Hub',
  'Doc Library',
  'KPI Hub',
  'Audit Hub',
  'Risk Hub, Portal',
  'Resources',
  'Other',
];

export { typeColors, statusColors, types, statuses, priorities, priorityColors, hubs };
