import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, ButtonGroup } from '@mui/material';
import { DialogTitle, DialogContent, Button, Tooltip } from '@mui/material';
import { grey, blue } from '@mui/material/colors';

import { FormatAlignLeftRounded, FormatAlignCenterRounded, FormatAlignRightRounded } from '@mui/icons-material';

import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';

export default function ColumnSettingsDialog({ initial, open, onClose, onResult }) {
  const [text, setText] = useState('');
  const [textAlign, setTextAlign] = useState('left');

  useEffect(() => {
    setText(initial?.description || '');
    setTextAlign(initial?.textAlign || 'left');
  }, [initial]);

  function done() {
    onResult({ text, textAlign });
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Column settings</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item>
            <Grid container item>
              <Typography style={{ fontWeight: 500 }} gutterBottom>
                Description
              </Typography>
            </Grid>
            <Grid container item>
              <TextField
                fullWidth
                variant="outlined"
                label="Column description"
                multiline
                minRows={5}
                maxRows={30}
                value={text}
                onChange={(e) => setText(e.target.value)}
                inputProps={{ maxLength: 1024 }}
              />
            </Grid>
          </Grid>
          <Grid container item style={{ marginTop: 16 }}>
            <Grid container item>
              <Typography style={{ fontWeight: 500 }} gutterBottom>
                Alignment
              </Typography>
            </Grid>
            <Grid container item>
              <ButtonGroup>
                <Tooltip title="Left" placement="top">
                  <Button onClick={() => setTextAlign('left')}>
                    <FormatAlignLeftRounded style={{ color: textAlign === 'left' ? blue[900] : grey[600] }} />
                  </Button>
                </Tooltip>

                <Tooltip title="Center" placement="top">
                  <Button onClick={() => setTextAlign('center')}>
                    <FormatAlignCenterRounded style={{ color: textAlign === 'center' ? blue[900] : grey[600] }} />
                  </Button>
                </Tooltip>

                <Tooltip title="Right" placement="top">
                  <Button onClick={() => setTextAlign('right')}>
                    <FormatAlignRightRounded style={{ color: textAlign === 'right' ? blue[900] : grey[600] }} />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
