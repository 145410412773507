import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { IconButton, useTheme, useMediaQuery, InputBase } from '@mui/material';
import { SearchRounded, ArrowBackIosRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderButton } from '../Components';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Skeleton } from '@mui/material';
import { styled, darken } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const Search = styled(Grid)(({ theme }) => ({
  position: 'relative',
  borderRadius: '1.5rem',
  backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[200],
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? darken(grey[800], 0.1) : darken(grey[200], 0.1),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '15ch',
  },
}));

export default function PageHeader({
  title,
  icon,
  onSearch,
  options,
  description,
  breadcrumbs,
  back,
  handleBack,
  disableSearch,
  disableTypography,
  suffixElement,
  searchDebounceMs = 150,
  ...props
}) {
  const searchTimer = useRef();
  const history = useHistory();
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down('sm'));
  const [search, setSearch] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);

  function updateSearchPattern(value) {
    if (searchTimer.current != null) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => onSearch(value), searchDebounceMs);
  }

  return (
    <Grid container direction={'column'} sx={{ my: 2 }} {...props}>
      <Grid item container justifyContent="space-between" alignItems="center" spacing={description ? 2 : 0}>
        <Grid item container xs={5} sm={6} md={6} alignItems="center" wrap="nowrap">
          {back && (
            <IconButton
              onClick={() => (handleBack ? handleBack() : history.goBack())}
              color="primary"
              style={{ marginRight: 8 }}
            >
              <ArrowBackIosRounded />
            </IconButton>
          )}
          {breadcrumbs ? (
            breadcrumbs
          ) : (
            <>
              {icon && !smallDevices && (
                <Grid item sx={{ mr: 2 }}>
                  {icon}
                </Grid>
              )}
              {disableTypography ? (
                title
              ) : (
                <Typography variant={smallDevices ? 'h6' : 'h4'} noWrap>
                  {title ? title : <Skeleton width="60" animation="wave" />}
                </Typography>
              )}

              {suffixElement}
            </>
          )}
        </Grid>
        <Grid item container direction="row" xs={7} sm={6} md={6} justifyContent={'flex-end'} alignItems="center">
          {!disableSearch &&
            onSearch &&
            (search ? (
              !smallDevices && (
                <Grid item>
                  <Search>
                    <SearchIconWrapper>
                      <SearchRounded />
                    </SearchIconWrapper>
                    <StyledInputBase
                      id="page-header-search"
                      placeholder="Search…"
                      onChange={(e) => updateSearchPattern(String(e.target.value).toLowerCase().trim())}
                      inputRef={(input) => input && input.focus()}
                      endAdornment={
                        <CloseIcon
                          sx={{ cursor: 'pointer', mx: 1 }}
                          onClick={() => {
                            setSearch(false);
                            onSearch('');
                          }}
                        />
                      }
                    />
                  </Search>
                </Grid>
              )
            ) : (
              <HeaderButton
                disabled={moreOptions}
                icon={<SearchRounded fontSize="large" style={{ color: 'white' }} />}
                onClick={() => setSearch(!search)}
                text="Search"
              />
            ))}

          {!smallDevices && options}
          {options && smallDevices && (
            <HeaderButton
              icon={
                moreOptions ? <CloseIcon style={{ color: 'white' }} /> : <ViewListIcon style={{ color: 'white' }} />
              }
              onClick={() => setMoreOptions(!moreOptions)}
              text="More Options"
            />
          )}
        </Grid>

        {smallDevices && (
          <Grid item xs={12} container justifyContent="center">
            {search && onSearch && (
              <Grid item>
                <Search>
                  <SearchIconWrapper>
                    <SearchRounded />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    id="page-header-search"
                    onChange={(e) => updateSearchPattern(String(e.target.value).toLowerCase().trim())}
                    inputRef={(input) => input && input.focus()}
                    endAdornment={
                      <CloseIcon
                        sx={{ cursor: 'pointer', mx: 1 }}
                        onClick={() => {
                          setSearch(false);
                          onSearch('');
                        }}
                      />
                    }
                  />
                </Search>
              </Grid>
            )}
            {moreOptions && options}
          </Grid>
        )}
      </Grid>
      {description && (
        <Grid container>
          <Typography
            sx={{ fontSize: 16, color: (t) => (t.palette.mode === 'dark' ? t.palette.grey[400] : '#4a4e54') }}
          >
            {description}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
