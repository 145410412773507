import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { shareDocLinkQUser, shareDocPublic } from '../../redux/actions';

import { MuiMenuItem } from './forwarded';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';

import EmailDialog from './EmailDialog';
import UsersDialog from '../../../../Global/UsersDialog';
import { MailOutline, People } from '@mui/icons-material';

export default function ShareMenu({
  document,
  open,
  onClose,
  anchorEl,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
}) {
  const dispatch = useDispatch();
  const [emailDialog, setEmailDialog] = useState(false);
  const [userDialog, setUserDialog] = useState(false);

  if (document) {
    return (
      <>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <MuiMenuItem
            onClick={() => {
              onClose();
              setUserDialog(true);
            }}
            dense
          >
            <ListItemIcon>
              <People fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Share QHub User" />
          </MuiMenuItem>
          <MuiMenuItem
            onClick={() => {
              onClose();
              setEmailDialog(true);
            }}
            dense
          >
            <ListItemIcon>
              <MailOutline fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Share via Email" />
          </MuiMenuItem>
        </Menu>
        {document && (
          <EmailDialog
            open={emailDialog}
            handleClose={() => setEmailDialog(false)}
            handleConfirm={(email) => {
              dispatch(shareDocPublic(document, email));
              setEmailDialog(false);
            }}
          />
        )}
        <UsersDialog
          open={userDialog}
          onClose={(data) => {
            if (data && data.users) {
              dispatch(
                shareDocLinkQUser({
                  to: data.users,
                  text: 'Please take a look at this file',
                  document,
                }),
              );
            }
            setUserDialog(false);
          }}
        />
      </>
    );
  } else {
    return null;
  }
}
