import { red, grey, green, blue } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Typography, CircularProgress } from '@mui/material';
import { InfoOutlined, GetApp, ArchiveRounded } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RoundedDialog, CrossButton, User, GeneralButton, StandardDialogActions } from '../../../Global/Components';
import { FormatDate } from '../../../Global/Functions';
import StateManager from '../../../Global/StateManager';
import { BASE_URL } from '../../../../constants';
import axios from 'axios';
import moment from 'moment';
import RowInfo from './RowInfo';

function formatDuration(delta) {
  const duration = moment.duration(delta);

  //Get hours and subtract from duration
  const hours = Math.floor(duration.asHours());
  duration.subtract(moment.duration(hours, 'hours'));

  //Get minutes and subtract from duration
  const minutes = Math.floor(duration.minutes());

  return `${hours}h ${minutes}m`;
}

function distinct(array) {
  return array.filter((v, i, a) => a.indexOf(v) === i).filter((x) => x);
}

export default function OngoingProcessInfo({ open, onClose, onChange, process, processEditable }) {
  const [involved, setInvolved] = useState([]);
  const [performance, setPerformance] = useState();
  const [downloading, setDownloading] = useState('');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!open || !process) return;
    axios
      .get('/process/getInvolvedUsersList', { params: { ongoingProcessId: process._id } })
      .then((res) => {
        setInvolved(res.data);
      })
      .catch(console.error);

    axios
      .get('/process/getProcessPerfomance', { params: { ongoingProcessId: process._id } })
      .then((res) => {
        setPerformance(res.data);
      })
      .catch(console.error);
  }, [open]); // eslint-disable-line

  function downloadPDF() {
    if (downloading) return;
    setDownloading('pdf');
    axios
      .get('/process/processToPDF', { params: { id: process._id } })
      .then((res) => {
        window.open(`${BASE_URL}${res.data.link}`, '_blank');
        setDownloading('');
      })
      .catch((err) => {
        console.error(err);
        StateManager.setErrorAlert('Something went wrong');
        setDownloading('');
      });
  }

  function downloadExcel() {
    if (downloading) return;
    setDownloading('excel');
    axios
      .get('/process/exportToExcel', { params: { id: process._id } })
      .then((res) => {
        window.open(`${BASE_URL}${res.data.link}`, '_blank');
        setDownloading('');
      })
      .catch((err) => {
        console.error(err);
        StateManager.setErrorAlert('Something went wrong');
        setDownloading('');
      });
  }

  function confirmArchiving() {
    StateManager.setConfirm('You are about to archive this entry', () => archiveEntry());
  }

  function archiveEntry() {
    setSaving(true);

    axios
      .post('/process/archive/archiveEntries', { entriesIds: [process._id] })
      .then(({ data }) => {
        if (data.archived?.length > 0) {
          onChange && onChange({ archivedAt: new Date() });
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <InfoOutlined style={{ color: grey[500] }} />
          <Typography variant="h6">Process info</Typography>
          <CrossButton onClick={onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid
            container
            item
            style={{ padding: '0.5rem 0', marginBottom: '0.5rem', borderBottom: `1px solid ${grey[200]}` }}
          >
            <Grid container item alignItems="center">
              <Typography variant="h6" gutterBottom style={{ marginRight: '1rem' }}>
                {`Started ${FormatDate(process.startedAt)} by`}
              </Typography>
              <User id={process.createdBy} />
            </Grid>
            {process.startedFrom && (
              <Grid container item style={{ marginTop: '0.7rem' }}>
                <Link to={`/processes/ongoing/${process.startedFrom}`} style={{ color: blue[700] }}>
                  <Typography>Go to the process this process was started from</Typography>
                </Link>
              </Grid>
            )}
          </Grid>
          {process.rowId && (
            <Grid
              style={{ padding: '0.5rem 0', marginBottom: '0.5rem', borderBottom: `1px solid ${grey[200]}` }}
              item
              container
            >
              <RowInfo rowId={process.rowId} />
            </Grid>
          )}
          {process.completedAt && (
            <Grid
              container
              item
              style={{ padding: '0.5rem 0', marginBottom: '0.5rem', borderBottom: `1px solid ${grey[200]}` }}
            >
              <Typography variant="h6" gutterBottom>
                {`Competed ${FormatDate(process.completedAt)}`}
              </Typography>
            </Grid>
          )}
          <Grid
            container
            item
            style={{ padding: '0.5rem 0', marginBottom: '0.5rem', borderBottom: `1px solid ${grey[200]}` }}
          >
            <Grid container item>
              <Typography variant="h6" gutterBottom>
                Access:
              </Typography>
            </Grid>
            <Grid container item alignItems="center" style={{ margin: '0.5rem 0' }}>
              {process.restrictedAccess ? (
                <Typography gutterBottom>Only the following users have access to this process:</Typography>
              ) : (
                <Typography gutterBottom>Everyone in the company has access to this process</Typography>
              )}
            </Grid>
            {process.restrictedAccess && process.accessList && (
              <Grid container item>
                {distinct([process.createdBy, ...process.accessList, ...process.coOwners, process.processOwner]).map(
                  (user) => (
                    <User key={user} id={user} style={{ width: '100%' }} />
                  ),
                )}
              </Grid>
            )}
          </Grid>

          {involved[0] && (
            <Grid
              container
              item
              style={{ padding: '0.5rem 0', marginBottom: '0.5rem', borderBottom: `1px solid ${grey[200]}` }}
            >
              <Grid container item>
                <Typography variant="h6" gutterBottom>
                  Users involved:
                </Typography>
              </Grid>
              <Grid container item>
                {involved.map((user) => (
                  <User key={user} id={user} clickable style={{ width: '100%' }} />
                ))}
              </Grid>
            </Grid>
          )}
          {performance && performance.totalAlloted > 0 && (
            <Grid
              container
              item
              style={{ padding: '0.5rem 0', marginBottom: '0.5rem', borderBottom: `1px solid ${grey[200]}` }}
              direction="column"
            >
              <Typography variant="h6" gutterBottom>
                Process performance (for completed steps):
              </Typography>

              <Typography gutterBottom>{`Total alloted time: ${formatDuration(performance.totalAlloted)}`}</Typography>

              <Typography gutterBottom>{`Total actual time: ${formatDuration(performance.totalActual)}`}</Typography>

              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
                <Typography style={{ marginRight: '0.25em' }}>Total delta:</Typography>
                <Typography
                  style={{
                    color: performance.totalDelta > 0 ? green[600] : performance.totalDelta < 0 ? red[600] : grey[600],
                  }}
                >
                  {formatDuration(performance.totalDelta)}
                </Typography>
              </div>
            </Grid>
          )}
          <Grid
            container
            item
            style={{ padding: '0.5rem 0', marginBottom: '0.5rem', borderBottom: `1px solid ${grey[200]}` }}
          >
            <GeneralButton
              onClick={downloadPDF}
              startIcon={downloading === 'pdf' ? <CircularProgress color="primary" size={20} /> : <GetApp />}
            >
              Download as PDF
            </GeneralButton>

            <GeneralButton
              onClick={downloadExcel}
              startIcon={downloading === 'excel' ? <CircularProgress color="primary" size={20} /> : <GetApp />}
            >
              Download as excel
            </GeneralButton>
            {processEditable &&
              !process.archivedAt &&
              (process.completedAt || process.cancelledAt || process.heldAt) && (
                <GeneralButton onClick={confirmArchiving} startIcon={<ArchiveRounded />}>
                  Archive entry
                </GeneralButton>
              )}
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} saving={saving} />
    </RoundedDialog>
  );
}
