import React from 'react';

import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ToggleRow = ({ title, toggle, setToggle, field, fields, hasSuffix, children, labelFontSize = 500 }) => {
  return (
    <Grid container direction="column" justifyContent="space-between" sx={{ mb: 2 }}>
      <Grid container direction="row" alignItems="center" gap={2}>
        <Typography sx={{ fontWeight: labelFontSize }}>{title}</Typography>
        <Switch
          checked={toggle}
          onChange={(e) => setToggle(e.target.checked)}
          color="primary"
          name={field}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        {hasSuffix && <Typography sx={{ fontWeight: labelFontSize }}>{toggle ? 'yes' : 'no'}</Typography>}
        {fields}
      </Grid>
      {children}
    </Grid>
  );
};

export default ToggleRow;
