import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Box from '@mui/material/Box';
import { isArray } from 'lodash';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ maxWidth: '100%' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * A component that renders a tabbed view with multiple tabs.
 * @param {Object} props - The props object that contains the tabs array.
 * @param {Array} props.tabs - An array of objects that represent the tabs to be rendered.
 * @param {string} props.tabs.label - The label to be displayed on the tab.
 * @param {ReactNode} props.tabs.children - The content to be displayed when the tab is active.
 * @returns {JSX.Element} - The JSX element that represents the tabbed view.
 */
export default function TabbedView({ tabs, button }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!tabs || !isArray(tabs) || tabs?.length === 0) return null;

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabbed view"
          sx={{
            '& .MuiTabs-flexContainer': {
              alignItems: 'center',
            },
          }}
        >
          {tabs?.map((tab, index) => (
            <Tab label={tab.label} {...a11yProps(index)} />
          ))}
          {button && <Box sx={{ ml: 'auto', alignItems: 'center' }}>{button}</Box>}
        </Tabs>
      </Box>
      {tabs?.map((tab, index) => (
        <CustomTabPanel value={value} index={index}>
          {tab.children}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
