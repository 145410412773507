import React, { useRef, useEffect, useState } from 'react';
import { Grid, IconButton, Typography, useTheme, InputBase } from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, TextField } from '@mui/material';
import { DeleteOutline, Add } from '@mui/icons-material';
import { green, grey, red } from '@mui/material/colors';
import { GeneralButton } from '../Components';
import { isEqual, isArray } from 'lodash';

export default function FieldTable({ onResult, initial, params, savingTimeout = 1200, editable = true }) {
  const [rows, setRows] = useState([]);
  const theme = useTheme();
  const timer = useRef();

  const DEFAULT_COLUMN_WIDTH = 200;

  const cellStyle = { padding: '8px 0px' };

  useEffect(() => {
    if (!isArray(initial) || isEqual(initial, rows)) return;
    setRows(initial);
  }, [initial]); // eslint-disable-line

  function save(value) {
    onResult(value);
  }

  function saveCell(i, j, value) {
    rows[i][j] = value;
    setRows([...rows]);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(save, savingTimeout, rows);
  }

  function addRow() {
    rows.push(new Array(params.columns.length).fill(''));
    setRows([...rows]);
  }

  function deleteRow(i) {
    rows.splice(i, 1);
    setRows([...rows]);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(save, savingTimeout, rows);
  }

  if (!Array.isArray(params?.columns)) return null;

  return (
    <Grid container style={{ overflow: 'auto' }}>
      <Grid container item wrap="nowrap">
        {params.columns.map((column) => (
          <Grid
            key={column.id}
            container
            style={{
              width: column.width || DEFAULT_COLUMN_WIDTH,
              border: '1px solid black',
              padding: theme.spacing(0.75),
            }}
          >
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>{column.title}</Typography>
          </Grid>
        ))}
      </Grid>
      {rows.map((row, i) => (
        <Grid key={i} container item wrap="nowrap">
          {params.columns.map((column, j) => (
            <Grid
              key={column.id}
              container
              style={{
                width: column.width || DEFAULT_COLUMN_WIDTH,
                border: '1px solid black',
                padding: theme.spacing(0.5),
              }}
            >
              {editable ? (
                <InputBase value={row[j] || ''} onChange={(e) => saveCell(i, j, e.target.value)} />
              ) : (
                <Typography style={{ fontWeight: 500, fontSize: 14 }}>{row[j] || ''}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      ))}
      {false && (
        <TableContainer>
          <Table size="small" style={{ width: editable ? '' : 'fit-content' }}>
            <TableHead>
              <TableRow>
                {params.columns.map((column) => (
                  <TableCell
                    style={{ borderBottom: `2px solid ${grey[700]}`, width: column.width || DEFAULT_COLUMN_WIDTH }}
                    key={column.id}
                  >
                    {column.title}
                  </TableCell>
                ))}
                {editable && <TableCell style={{ width: 50, borderBottom: `2px solid ${grey[700]}` }} />}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={i}>
                  {row.length > 0 &&
                    row.map((cell, j) =>
                      editable ? (
                        <TableCell key={`${i}-${j}`} style={cellStyle}>
                          <CustomizedTextField
                            type={params.columns[j].type}
                            value={cell}
                            onChange={(value) => saveCell(i, j, value)}
                          />
                        </TableCell>
                      ) : (
                        <TableCell key={`${i}-${j}`} style={cellStyle}>
                          {cell}
                        </TableCell>
                      ),
                    )}
                  {editable && (
                    <TableCell style={cellStyle}>
                      <IconButton onClick={() => deleteRow(i)}>
                        <DeleteOutline style={{ color: red[500] }} />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {editable && (
        <Grid container item style={{ marginTop: '1rem' }}>
          <GeneralButton startIcon={<Add style={{ color: green[500] }} />} onClick={addRow}>
            Add row
          </GeneralButton>
        </Grid>
      )}
    </Grid>
  );
}

function CustomizedTextField({ type, value, onChange }) {
  function handleChange(event) {
    if ((type === 'money' || type === 'number') && event.target.value < 0) {
      event.preventDefault();
      return;
    }
    if (type === 'number') {
      onChange(Math.floor(Number(event.target.value)));
    } else if (type === 'money') {
      onChange(Number(event.target.value));
    } else {
      onChange(event.target.value);
    }
  }

  if (type === 'money') {
    return (
      <TextField
        value={value}
        placeholder="Enter amount"
        type="number"
        style={{ padding: 8, flexGrow: 1 }}
        onChange={handleChange}
        variant="standard"
      />
    );
  }

  if (type === 'number') {
    return (
      <TextField
        value={value}
        placeholder="Enter number"
        type="number"
        InputProps={{ min: 0, max: 10000000, step: 1 }}
        style={{ padding: 8, flexGrow: 1 }}
        onChange={handleChange}
        variant="standard"
      />
    );
  }

  return (
    <TextField
      value={value}
      placeholder="Enter text"
      type="text"
      style={{ padding: 8, flexGrow: 1 }}
      onChange={handleChange}
      variant="standard"
    />
  );
}
