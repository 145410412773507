import React, { useState, forwardRef, useEffect, useRef } from 'react';
import moment from 'moment';

const Countdown = forwardRef(({ targetDate, ...props }, ref) => {
  const [text, setText] = useState('');
  const timer = useRef();

  useEffect(() => {
    calculateText();

    timer.current = setInterval(() => calculateText(), 1000);

    return () => {
      if (timer.current != null) {
        clearInterval(timer.current);
      }
    };
  }, []); // eslint-disable-line

  function calculateText() {
    if (!targetDate) {
      setText('');
      return;
    }

    const diff = moment(targetDate).diff(moment(), 'milliseconds');

    const result = diff > 0 ? FormatRemainingTime(diff) : '';

    setText(result);
  }

  return (
    <span ref={ref} {...props}>
      {text}
    </span>
  );
});

export function FormatRemainingTime(milliseconds) {
  if (isNaN(milliseconds)) return '-';
  const duration = moment.duration(Number(milliseconds));

  //Get hours and subtract from duration
  const hours = Math.floor(duration.asHours());
  duration.subtract(moment.duration(hours, 'hours'));

  //Get Minutes and subtract from duration
  const minutes = Math.floor(duration.minutes());
  duration.subtract(moment.duration(minutes, 'minutes'));

  const seconds = Math.round(duration.asSeconds());

  const hoursPart = hours > 0 ? (hours >= 10 ? `${hours}:` : `0${hours}:`) : '';
  const minutesPart = minutes >= 10 ? `${minutes}:` : `0${minutes}:`;
  const secondsPart = seconds >= 10 ? seconds : `0${seconds}`;

  const result = `${hoursPart}${minutesPart}${secondsPart}`;

  return result;
}

export default Countdown;
