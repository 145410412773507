import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { RoundedDialog, User, StandardDialogActions } from '../../../Global/Components';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { DateFormatter, TooltipTypography } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { formatEntryVersion } from '../pages/formEntry';

export default function EntryVersionsDialog({ open, onClose, entryId }) {
  const [loading, setLoading] = useState(false);
  const [versions, setVersions] = useState([]);
  const [format, setFormat] = useState(null);
  const history = useHistory();

  const loadEntries = open && isEmpty(versions);

  useEffect(() => {
    if (!entryId || !loadEntries) return;

    setLoading(true);

    axios
      .get('/forms/entries/getEntryVersions', { params: { entryId } })
      .then(({ data }) => {
        setVersions(data.versions);
        setFormat(data.format);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [entryId, loadEntries]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Previous versions</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container sx={{ height: '40vh' }} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container>
            {isEmpty(versions) ? (
              <Grid container sx={{ height: '40vh' }} alignItems="center" justifyContent="center">
                <Typography color="textSecondary">No versions available</Typography>
              </Grid>
            ) : (
              <TableContainer style={{ my: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Version</TableCell>
                      <TableCell style={{ fontWeight: 700 }}>Up-issued at</TableCell>
                      <TableCell style={{ fontWeight: 700 }}>Up-issued by</TableCell>
                      <TableCell style={{ fontWeight: 700, width: 150 }}>Up-issue reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {versions.map((entry) => (
                      <TableRow
                        key={entry._id}
                        hover
                        style={{ cursor: 'pointer' }}
                        onClick={entry.current ? undefined : () => history.push(`/forms/entry-version/${entry._id}`)}
                      >
                        <TableCell>{formatEntryVersion(format, entry.entryVersion)}</TableCell>
                        <TableCell>
                          <DateFormatter date={entry.upIssuedAt} />
                        </TableCell>
                        <TableCell>
                          <User avatarSize={32} id={entry.upIssuedBy} />
                        </TableCell>
                        <TableCell sx={{ maxWidth: 150, width: 150 }}>
                          <TooltipTypography>{entry.upIssueReason}</TooltipTypography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
