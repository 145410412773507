import React, { useEffect, useState, useRef } from 'react';
import { Popover, List, ListItemIcon, Button, IconButton } from '@mui/material';
import { Typography, ListItemText, ListItemButton } from '@mui/material';
import { DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material';
import { PauseCircleOutline, PlayCircleOutline, DeleteOutlineRounded } from '@mui/icons-material';
import moment from 'moment';
import StateManager from '../StateManager';
import { red, blue } from '@mui/material/colors';
import TimerManager from '../../Global/TimerManager';
import { RoundedDialog } from '../Components';

export default function Timers({ timers, open, anchorEl, onClose }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      className="scroll-bar"
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List>
        {timers.map((timer) => (
          <Timer timer={timer} key={timer._id} />
        ))}
      </List>
    </Popover>
  );
}

function Timer({ timer }) {
  const [duration, setDuration] = useState('');
  const interval = useRef();

  function updateDuration(start, initialElapsed) {
    let diff = moment().diff(start);
    setDuration(moment.utc(diff + initialElapsed * 1000).format('HH:mm:ss'));
  }

  useEffect(() => {
    if (!timer) return;

    if (timer.state === 'active') {
      let start = moment(timer.startedAt);
      updateDuration(start, timer.elapsed);
      interval.current = setInterval(() => {
        updateDuration(start, timer.elapsed);
      }, 1000);
    } else {
      if (interval.current) clearInterval(interval.current);
      setDuration(moment.utc(timer.elapsed * 1000).format('HH:mm:ss'));
    }
  }, [timer, timer.state]);

  function switchActive(event) {
    event.stopPropagation();
    if (timer.state === 'active') {
      TimerManager.stopTaskTimer(timer.taskId);
    } else {
      TimerManager.startTaskTimer(timer.taskId);
    }
  }

  function deleteTimer(event) {
    event.stopPropagation();
    if (interval.current) clearInterval(interval.current);
    TimerManager.deleteTaskTimer(timer._id);
  }

  return (
    <ListItemButton onClick={(event) => StateManager.selectTask(timer.taskId)}>
      <ListItemIcon>
        <IconButton onClick={switchActive}>{timer.state === 'active' ? <Pause /> : <PlayArrow />}</IconButton>
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography style={{ marginRight: '0.5em', maxWidth: 200 }} noWrap>
            {timer.taskName}
          </Typography>
        }
        secondary={duration}
      />
      <IconButton onClick={deleteTimer}>
        <DeleteOutlineRounded color="error" />
      </IconButton>
    </ListItemButton>
  );
}

export function PauseDialog({ open, running, onConfirm, onClose }) {
  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        {running.length === 1
          ? `There is a running timer for task ${running[0]}`
          : `There are ${running.length} running timers`}
      </DialogTitle>

      <DialogContent style={{ padding: '3em 2em' }}>
        <Typography>{`Dou you want to pause ${running.length > 1 ? 'them' : 'it'}?`}</Typography>
      </DialogContent>

      <DialogActions>
        <Button startIcon={<PlayCircleOutline />} onClick={onClose} style={{ color: red[500], marginRight: 'auto' }}>
          keep timers running
        </Button>
        <Button startIcon={<PauseCircleOutline />} onClick={onConfirm} style={{ color: blue[500] }}>
          pause
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
