import React, { useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { Badge, Button, Box, Fade } from '@mui/material';
//Add Comment Component

export const Comment = ({ entityKey, contentState, children }) => {
  const history = useHistory();
  const isPreviewPage = useRouteMatch('/DocLibrary/preview/:id');
  let { comment } = contentState.getEntity(entityKey).getData();
  const [popOver, setPopOver] = useState(false);

  if (isPreviewPage) {
    return <span>{children}</span>;
  }
  return (
    <>
      <Badge variant={'dot'} color="primary" onMouseEnter={() => setPopOver(true)} overlap="rectangular">
        <Box
          component="span"
          sx={{ backgroundColor: popOver ? 'rgba(0, 0, 0, 0.15)' : 'inherit', cursor: 'pointer' }}
          onClick={() => setPopOver(!isPreviewPage)}
        >
          {children}
        </Box>
      </Badge>
      {popOver && !isPreviewPage && (
        <Fade in={popOver} timeout={2000}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => history.push(`?commentId=${comment}`)}
            onMouseLeave={() => setPopOver(false)}
          >
            Open Comment
          </Button>
        </Fade>
      )}
    </>
  );
};

export const findCommentEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'COMMENT';
  }, callback);
};

export const commentDecorator = {
  strategy: findCommentEntities,
  component: Comment,
};

// call all together
export const onAddComment = (linkData, editorState, setEditorState) => {
  const text = linkData.text;
  let selection = editorState.getSelection();
  const entityKey = editorState
    .getCurrentContent()
    .createEntity('COMMENT', 'MUTABLE', linkData)
    .getLastCreatedEntityKey();

  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    `${text}`,
    editorState.getCurrentInlineStyle(),
    entityKey,
  );
  let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
  selection = newEditorState.getSelection();
  newEditorState = EditorState.acceptSelection(newEditorState, selection);
  setEditorState(EditorState.forceSelection(newEditorState, editorState.getSelection()));
};

export const onAddCommentOnly = (linkData, editorState) => {
  const text = linkData.text;
  let selection = editorState.getSelection();
  const entityKey = editorState
    .getCurrentContent()
    .createEntity('COMMENT', 'MUTABLE', { ...linkData, selection })
    .getLastCreatedEntityKey();

  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    `${text}`,
    editorState.getCurrentInlineStyle(),
    entityKey,
  );
  let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
  selection = newEditorState.getSelection();
  newEditorState = EditorState.acceptSelection(newEditorState, selection);
  return EditorState.forceSelection(newEditorState, editorState.getSelection());
};
