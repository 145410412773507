import React from 'react';
import { ListItem, Grid, Typography, Paper, DialogTitle, DialogContent } from '@mui/material';
import { WhatshotRounded } from '@mui/icons-material';
import { UserGroup, RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import { grey, red, green, amber, deepOrange, deepPurple } from '@mui/material/colors';
import { StatusButton } from '../../Components';
import moment from 'moment';
import StateManager from '../../../../Global/StateManager';

export default function TasksDialog({ tasks, title, open, onClose }) {
  const priorityColors = {
    None: grey[400],
    Low: green[500],
    Medium: amber[500],
    High: deepOrange[500],
    Critical: deepPurple[500],
  };

  function formatDate(date) {
    if (!date) return '-';
    const dt = moment(date);
    if (dt.isSame(moment(), 'day')) {
      // if today, only time
      return dt.format('hh:mm A');
    }
    return dt.format('DD/MM/YYYY');
  }

  return (
    <RoundedDialog open={open} maxWidth="md" fullWidth onClose={onClose} className="scroll-bar">
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Grid container item alignContent="flex-start">
          {tasks.map((task) => (
            <Paper
              key={task._id}
              variant="outlined"
              sx={{
                width: '100%',
                padding: 0,
                my: 1,
                border: `2px solid ${grey[300]}`,
                borderRadius: 1.5,
              }}
            >
              <ListItem
                onClick={() => StateManager.selectTask(task._id)}
                button
                sx={{
                  py: 1,
                  px: 2,
                  width: '100%',
                  borderRadius: 1.5,
                }}
              >
                <Grid container item alignItems="center">
                  <Grid container item alignItems="center" xs={6}>
                    <WhatshotRounded style={{ color: priorityColors[task.priority], marginRight: 16 }} />
                    <Typography style={{ fontSize: 18, fontWeight: 500 }} noWrap>
                      {task.title}
                    </Typography>
                  </Grid>
                  <Grid container item alignItems="center" xs={6} justifyContent="space-between">
                    <UserGroup ids={task.assignedUsers} />

                    <Typography
                      style={{ color: task.isOverdue ? red[500] : '', fontWeight: task.isOverdue ? 700 : 400 }}
                      noWrap
                    >
                      {formatDate(task.dueAt)}
                    </Typography>

                    <StatusButton status={task.progress} isDisabled isOverdue={task.isOverdue} />
                  </Grid>
                </Grid>
              </ListItem>
            </Paper>
          ))}
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
