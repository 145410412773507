import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import SwipeableViews from 'react-swipeable-views';
import { List, ListItemButton, ListItemAvatar, ListItemText } from '@mui/material';
import { ListItemIcon, TextField, Avatar, InputAdornment } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import TabPanel from '../../../../AdminPanel/components/DashboardItem/TabPanel';
import RoundedDialog from '../../../../Global/Components/RoundedDialog';
import { StandardDialogActions } from '../../../../Global/Components';

import { green, grey } from '@mui/material/colors';
import { CheckCircleOutlineRounded, CheckCircleRounded, Search } from '@mui/icons-material';
import StateManager from '../../../../Global/StateManager';

function ControlledDialog({ open, onClose, initial, documentId }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [areaReviewers, setAreaReviewers] = useState([]);
  const [areaApprovers, setAreaApprovers] = useState([]);

  const [tab, setTab] = useState(0);
  const [title, setTitle] = useState('Add files or folder');
  const [reviewers, setReviewers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [pattern, setPattern] = useState('');
  const [saving, setSaving] = useState(false);
  const [area, setArea] = useState(null);

  const handleClose = (ev, reason) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  const handleDone = () => {
    if (!area?.sendAutoAll && area?.uploadRules && tab < 1) {
      setTab(tab + 1);
      setTitle(
        tab === 0 ? 'Please select who should review these documents' : 'Please select who will approve your documents',
      );
      return;
    }
    let documentUpdate = {
      areaReviewers,
      areaApprovers,
    };
    setSaving(true);
    axios
      .post(`dochub/documents/convertToControlled/${documentId}`, documentUpdate)
      .then(({ data }) => {
        setSaving(false);
        onClose(data);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  };

  useEffect(() => {
    if (!open) return;
    setTab(0);
    setTitle('Please select who should review these documents');
    setAreaReviewers([]);
    setAreaApprovers([]);
    setPattern('');
    setSaving(false);
    setArea(initial || null);
    const areaId = initial?._id;
    if (areaId) {
      Promise.all([
        axios.get(`/dochub/areas/getAreaRoles/${areaId}/Reviewer`),
        axios.get(`/dochub/areas/getAreaRoles/${areaId}/Approver`),
      ])
        .then((res) => {
          if (res[0].data && res[0].data.users) {
            setReviewers(res[0].data.users);
          }
          if (res[1].data && res[1].data.users) {
            setApprovers(res[1].data.users);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [open, initial]);

  const validateConfirm = () => {
    if (
      !area?.personal &&
      area?.uploadRules &&
      area?.sendAutoAll &&
      ((areaReviewers.length < Number(area?.minimumReviewers) && tab === 0) ||
        (areaApprovers.length < Number(area?.minimumApprovers) && tab === 1))
    ) {
      return true;
    }
    return false;
  };

  function addDeleteReviewer(id) {
    let index = areaReviewers.indexOf(id);
    if (index > -1) {
      setAreaReviewers(areaReviewers.filter((x) => x !== id));
    } else {
      setAreaReviewers([...areaReviewers, id]);
    }
  }

  function addDeleteApprover(id) {
    let index = areaApprovers.indexOf(id);
    if (index > -1) {
      setAreaApprovers(areaApprovers.filter((x) => x !== id));
    } else {
      setAreaApprovers([...areaApprovers, id]);
    }
  }

  const handleBack = () => {
    if (tab === 1) {
      setTab(0);
      setTitle('Please select who should review these documents');
    } else {
      handleClose();
    }
  };

  return (
    <RoundedDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="controlled-dialog"
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle id="controlled-title" sx={{ fontWeight: 'bold' }}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <SwipeableViews
            index={tab}
            onChangeIndex={setTab}
            style={{ width: '100%' }}
            slideStyle={{ height: '100%' }}
            containerStyle={{ height: '100%' }}
            disabled
          >
            <TabPanel value={tab} index={0}>
              <Grid container sx={{ padding: (theme) => theme.spacing(1), margin: (theme) => theme.spacing(2, 0) }}>
                <TextField
                  placeholder="Add people"
                  fullWidth
                  value={pattern}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search sx={{ color: 'lightgray' }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => setPattern(event.target.value.toLowerCase())}
                  variant="standard"
                />
              </Grid>
              <Grid container>
                <List
                  sx={{ minHeight: '30vh', overflow: 'auto', width: '100%', marginTop: (theme) => theme.spacing(1) }}
                >
                  {reviewers
                    ?.filter((user) => user?.fullName?.toLowerCase()?.includes(pattern))
                    ?.map((user) => (
                      <ListItemButton
                        key={user._id}
                        sx={{ borderRadius: '5px' }}
                        onClick={() => {
                          addDeleteReviewer(user._id);
                          setPattern('');
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar src={user.avatar} sx={{ height: 42, width: 42 }}>
                            {user.avatarLetters}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.fullName} />
                        <ListItemIcon sx={{ marginLeft: 'auto' }}>
                          {areaReviewers.includes(user._id) ? (
                            <CheckCircleRounded sx={{ color: green[500] }} />
                          ) : (
                            <CheckCircleOutlineRounded sx={{ color: grey[300] }} />
                          )}
                        </ListItemIcon>
                      </ListItemButton>
                    ))}
                </List>
                {area?.minimumRequired && (
                  <ListItemText
                    primary={`*Minimum ${area?.minimumReviewers} required`}
                    primaryTypographyProps={{
                      sx: { color: 'red', fontSize: '0.8rem', marginLeft: '1rem' },
                    }}
                  />
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container sx={{ padding: (theme) => theme.spacing(1), margin: (theme) => theme.spacing(2, 0) }}>
                <TextField
                  placeholder="Add people"
                  fullWidth
                  value={pattern}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search sx={{ color: 'lightgray' }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => setPattern(event.target.value.toLowerCase())}
                  variant="standard"
                />
              </Grid>
              <Grid container>
                <List
                  sx={{ minHeight: '30vh', overflow: 'auto', width: '100%', marginTop: (theme) => theme.spacing(1) }}
                >
                  {approvers
                    ?.filter((user) => user?.fullName?.toLowerCase()?.includes(pattern))
                    ?.map((user) => (
                      <ListItemButton
                        key={user._id}
                        sx={{ borderRadius: '5px' }}
                        onClick={() => {
                          addDeleteApprover(user._id);
                          setPattern('');
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar src={user.avatar} sx={{ height: 42, width: 42 }}>
                            {user.avatarLetters}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.fullName} />
                        <ListItemIcon sx={{ marginLeft: 'auto' }}>
                          {areaApprovers.includes(user._id) ? (
                            <CheckCircleRounded sx={{ color: green[500] }} />
                          ) : (
                            <CheckCircleOutlineRounded sx={{ color: grey[300] }} />
                          )}
                        </ListItemIcon>
                      </ListItemButton>
                    ))}
                </List>
                {area?.minimumRequired && (
                  <ListItemText
                    primary={`*Minimum ${area?.minimumApprovers} required`}
                    primaryTypographyProps={{
                      sx: { color: 'red', fontSize: '0.8rem', marginLeft: '1rem' },
                    }}
                  />
                )}
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        onDone={handleDone}
        onClose={handleClose}
        doneButtonText={area?.uploadRules && !area?.sendAutoAll && tab < 1 ? 'Next' : 'Done'}
        disableDone={saving || validateConfirm()}
        saving={saving}
        additionalActions={
          <>
            {tab > 0 && (
              <Button onClick={handleBack} disabled={saving}>
                Back
              </Button>
            )}
          </>
        }
      />
    </RoundedDialog>
  );
}

export default ControlledDialog;
