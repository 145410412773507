import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, CardActionArea, Paper, Typography, Button, Radio } from '@mui/material';
import { TextField, CircularProgress, FormControl, RadioGroup, FormControlLabel, MenuItem } from '@mui/material';
import { Checkbox, Collapse } from '@mui/material';
import { ArrowForwardIosRounded, ArrowBackIosRounded, FaceRounded, GroupRounded } from '@mui/icons-material';
import { FiberManualRecord, GroupAddRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions, UserChip } from '../../../../Global/Components';
import { ProjectIcon, TeamIcon, ProcessIcon } from '../../../../Global/Icons';
import Autocomplete from '@mui/material/Autocomplete';
import { grey, blue } from '@mui/material/colors';
import StateManager from '../../../../Global/StateManager';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';

function TabPanel({ children, value, index, ...props }) {
  return (
    <Grid
      container
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...props}
    >
      {children}
    </Grid>
  );
}

const taskOptions = [
  {
    type: 'my',
    icon: <FaceRounded color="primary" />,
    title: 'My tasks',
  },
  {
    type: 'assigned',
    icon: <GroupRounded style={{ color: grey[500] }} />,
    title: 'Assigned tasks',
  },
  {
    type: 'projects',
    icon: <ProjectIcon />,
    title: 'From projects',
  },
  {
    type: 'project',
    icon: <ProjectIcon />,
    title: 'From a project',
    url: '/project/getUserProjects',
    actionText: 'Select project',
  },
  {
    type: 'process',
    icon: <ProcessIcon />,
    title: 'From a process',
    url: '/process/search/getSearchProcesses',
    actionText: 'Select process',
  },
  {
    type: 'team',
    icon: <TeamIcon />,
    title: 'From a team',
    url: '/tasks/getTeams',
    actionText: 'Select team',
  },
];

const dueOptions = [
  { value: 'no', text: 'No filter' },
  { value: 'today', text: 'Due today' },
  { value: 'thisWeek', text: 'Due this week' },
  { value: 'overdue', text: 'Overdue' },
];

export default function TaskBoxOptions({ open, onClose, onResult, initial }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [result, setResult] = useState(null);
  const [additionalOptions, setAdditionalOptions] = useState(false);
  const [displayAs, setDisplayAs] = useState('tile');
  const [dueFilter, setDueFilter] = useState('no');
  const [fromUsers, setFromUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (!open) return;
    setSelectedOption(initial ? taskOptions.find((x) => x.type === initial.type) : null);
    setAdditionalOptions(!!initial);
    setResult(initial || null);
    setFromUsers(Array.isArray(initial?.users) && initial.users.length > 0);
    setTab(0);
    setDisplayAs(initial?.displayAs || 'tile');
    setDueFilter(initial?.dueFilter || 'no');
    setUsers(Array.isArray(initial?.users) ? initial.users : []);
  }, [open, initial]);

  function handleItemResult(item) {
    const res = {
      type: selectedOption.type,
      id: item._id,
      title: item.title,
    };
    setResult(res);
    setAdditionalOptions(true);
  }

  function saveResult() {
    if (!result) return;
    onResult({ ...result, displayAs, dueFilter, users: fromUsers ? users : [] });
    onClose();
  }

  function handleOptionSelect(option) {
    setSelectedOption(option);
    if (option.url) {
      setAdditionalOptions(false);
      setTab(1);
    } else {
      const res = {
        type: option.type,
      };
      setResult(res);
      setAdditionalOptions(true);
    }
  }

  function selectUsers() {
    StateManager.selectMultipleUsers(saveUsers, { initiallySelected: users });
  }

  function saveUsers(res) {
    if (!Array.isArray(res?.users)) return;
    setUsers(res.users);
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose} className="scroll-bar">
      <DialogTitle>Display tasks in the box:</DialogTitle>

      <DialogContent>
        <Grid container>
          <SwipeableViews
            index={tab}
            onChangeIndex={setTab}
            style={{ width: '100%' }}
            slideStyle={{ height: '100%' }}
            containerStyle={{ height: '100%' }}
            disabled
          >
            <TabPanel value={tab} index={0}>
              <Grid container>
                {taskOptions.map((option, i) => (
                  <Grid container key={i} item style={{ padding: 8 }}>
                    <CardActionArea
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        padding: 0,
                      }}
                      onClick={() => handleOptionSelect(option)}
                    >
                      <Paper
                        variant="outlined"
                        sx={{
                          borderRadius: 1,
                          py: 1,
                          px: 2,
                          width: '100%',
                        }}
                        style={{ border: `2px solid ${option.type === selectedOption?.type ? blue[500] : grey[300]}` }}
                      >
                        <Grid container alignItems="center" wrap="nowrap">
                          {option.icon}
                          <Typography style={{ margin: '0px 16px' }}>{option.title}</Typography>
                          {option.type === selectedOption?.type ? (
                            <FiberManualRecord color="primary" style={{ marginLeft: 'auto' }} />
                          ) : (
                            <ArrowForwardIosRounded color="primary" style={{ marginLeft: 'auto' }} />
                          )}
                        </Grid>
                      </Paper>
                    </CardActionArea>
                  </Grid>
                ))}
                {additionalOptions && tab === 0 && (
                  <Grid container style={{ marginTop: 16 }}>
                    {selectedOption?.type !== 'process' && (
                      <Grid container item>
                        <FormControlLabel
                          onChange={(e) => setFromUsers(e.target.checked)}
                          control={<Checkbox color="primary" checked={fromUsers} />}
                          label="Show only tasks from specific users"
                        />
                        <Collapse in={fromUsers} style={{ width: '100%' }}>
                          <Grid container item alignItems="center">
                            <Button
                              style={{ color: grey[600], borderRadius: 8, marginRight: 16 }}
                              startIcon={<GroupAddRounded />}
                              onClick={selectUsers}
                            >
                              Add users
                            </Button>
                            {users.map((user) => (
                              <UserChip
                                key={user}
                                id={user}
                                onDelete={() => setUsers(users.filter((x) => x !== user))}
                              />
                            ))}
                          </Grid>
                        </Collapse>
                      </Grid>
                    )}

                    <Grid container item>
                      <FormControl component="fieldset" variant="standard">
                        <RadioGroup row value={displayAs} onChange={(event) => setDisplayAs(event.target.value)}>
                          <FormControlLabel
                            value="tile"
                            control={<Radio color="primary" />}
                            label="Display number of tasks"
                          />
                          <FormControlLabel value="table" control={<Radio color="primary" />} label="Display as list" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid container item alignItems="center">
                      <Typography style={{ marginRight: 16 }}>Filter by due date</Typography>
                      <TextField
                        select
                        value={dueFilter}
                        onChange={(e) => setDueFilter(e.target.value)}
                        style={{ width: 200 }}
                      >
                        {dueOptions.map((x, i) => (
                          <MenuItem value={x.value} key={i}>
                            {x.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </TabPanel>

            <TabPanel value={tab} index={1} style={{ height: '100%' }}>
              <Grid container direction="column">
                <Grid container item>
                  <Button
                    startIcon={<ArrowBackIosRounded />}
                    color="primary"
                    onClick={() => {
                      setTab(0);
                      setSelectedOption(null);
                      setResult(null);
                      setAdditionalOptions(false);
                      setUsers([]);
                    }}
                  >
                    back
                  </Button>
                </Grid>
                {selectedOption?.url && (
                  <Grid container item style={{ paddingTop: 8 }}>
                    <SearchItem
                      url={selectedOption.url}
                      title={selectedOption.actionText}
                      onResult={handleItemResult}
                      icon={selectedOption.icon}
                    />
                  </Grid>
                )}
                {additionalOptions && tab === 1 && (
                  <Grid container item style={{ marginTop: 'auto' }}>
                    {selectedOption?.type !== 'process' && (
                      <Grid container item>
                        <FormControlLabel
                          onChange={(e) => setFromUsers(e.target.checked)}
                          control={<Checkbox color="primary" checked={fromUsers} />}
                          label="Show only tasks from specific users"
                        />
                        <Collapse in={fromUsers} style={{ width: '100%' }}>
                          <Grid container item alignItems="center">
                            <Button
                              style={{ color: grey[600], borderRadius: 8, marginRight: 16 }}
                              startIcon={<GroupAddRounded />}
                              onClick={selectUsers}
                            >
                              Add users
                            </Button>
                            {users.map((user) => (
                              <UserChip
                                key={user}
                                id={user}
                                onDelete={() => setUsers(users.filter((x) => x !== user))}
                              />
                            ))}
                          </Grid>
                        </Collapse>
                      </Grid>
                    )}
                    <Grid container item>
                      <FormControl component="fieldset" variant="standard">
                        <RadioGroup row value={displayAs} onChange={(event) => setDisplayAs(event.target.value)}>
                          <FormControlLabel
                            value="tile"
                            control={<Radio color="primary" />}
                            label="Display number of tasks"
                          />
                          <FormControlLabel value="table" control={<Radio color="primary" />} label="Display as list" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid container item alignItems="center">
                      <Typography style={{ marginRight: 16 }}>Filter by due date</Typography>
                      <TextField
                        select
                        value={dueFilter}
                        onChange={(e) => setDueFilter(e.target.value)}
                        style={{ width: 200 }}
                      >
                        {dueOptions.map((x, i) => (
                          <MenuItem value={x.value} key={i}>
                            {x.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={saveResult} hideDone={!result} />
    </RoundedDialog>
  );
}

function SearchItem({ url, title, icon, onResult }) {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions([]);
    setValue(null);
    setLoading(true);

    axios
      .get(url)
      .then((res) => {
        setOptions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [url]);

  return (
    <Grid container justifyContent="center" style={{ padding: 16 }}>
      <Autocomplete
        fullWidth
        options={options}
        value={value}
        onChange={(e, x) => {
          setValue(x);
          onResult(x);
        }}
        getOptionLabel={(option) => option.title}
        renderOption={(props, option) => (
          <li {...props}>
            <Grid container alignItems="center" wrap="nowrap">
              {icon}
              <Typography style={{ marginLeft: '1rem' }} noWrap>
                {option.title}
              </Typography>
            </Grid>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  {icon}
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
}
