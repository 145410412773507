import React from 'react';
import { Typography, Box } from '@mui/material';
import { formatDate, formatRevision, VFORMAT } from '../../../../constants';

const captionStyles = {
  margin: 0,
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  marginBottom: '0.35em',
  display: 'block',
};

const DynamicTemplateComponent = ({ value, document, metaData }) => {
  switch (value) {
    case 'file-name':
      return (
        <Typography variant="caption" sx={captionStyles}>
          {document.title}
        </Typography>
      );
    case 'doc-owners-name':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          John Doe
        </Typography>
      );
    case 'current-version':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          {formatRevision(123, VFORMAT)}
        </Typography>
      );
    case 'image':
      return metaData ? (
        <Box component="img" src={metaData} sx={{ width: '100%', height: 30, objectFit: 'contain' }} />
      ) : null;
    case 'custom-text':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          {metaData}
        </Typography>
      );
    case 'doc-prefix-number':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          XX-123
        </Typography>
      );
    case 'last-review-date':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          Reviewed {formatDate(new Date(), 'DD/MM/YYYY')}
        </Typography>
      );
    case 'last-up-issue-date':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          Up-issued {formatDate(new Date(), 'DD/MM/YYYY')}
        </Typography>
      );
    case 'published-date':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          Published {formatDate(new Date(), 'DD/MM/YYYY')}
        </Typography>
      );
    case 'page-number':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          Page #1
        </Typography>
      );
    default:
      return null;
  }
};

export default DynamicTemplateComponent;
