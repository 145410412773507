import React from 'react';
import { ListItemText, List, ListItem, ListItemIcon, DialogContent, DialogTitle } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { RoundedDialog, StandardDialogActions } from '../Components';

export default function SelectedOptionsDialog({ options, open, onClose }) {
  if (!Array.isArray(options)) return null;
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Selected</DialogTitle>
      <DialogContent>
        <List>
          {options.map((option, i) => (
            <ListItem key={i}>
              <ListItemIcon>
                <FiberManualRecord style={{ color: grey[500] }} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
