import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { uniq } from 'lodash';
import { getTags, getPrefixes } from '../../redux/actions';
import { getSets } from '../../redux/actions/set';
import { updateSetupDocument, updateOwner } from '../../redux/actions/document';
import { previewFile } from '../../redux/actions/file';

import axios from '../../../../../redux/apiCaller';

import useDocumentName from '../../hooks/useDocumentName';
import { range } from '../../utils';
import { VFORMAT, NFORMAT, AFORMAT, COLORS } from '../../constants';
import moment from 'moment';
import AutoSelection from '../Autocomplete';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import AlertMessage from '../AlertMessage';
import InputLabel from '@mui/material/InputLabel';
import { PrimaryButton } from '../Buttons';
import RoundedDialog from '../../../../Global/Components/RoundedDialog';
import UseConventionSetDialog from '../UseConventionSetDialog';
import ChangeOwnerDialog from '../ChangeOwnerDialog';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { RadioGroup, Radio, FormControl } from '@mui/material';
import { useMediaQuery, Paper, FormLabel } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CheckCircle, Cancel, PersonRounded } from '@mui/icons-material';
import { DatePicker, UserChip, GeneralButton } from '../../../../Global/Components';
import StateManager from '../../../../Global/StateManager';
import GroupButton from '../../../../Global/GroupButton';
import ToggleRow from '../ToggleRow';
import ControlledDialog from '../UploadDialog/controlled';

const currentUser = localStorage.getItem('_id');

export default function ({ initial, setup, handleSetup, edit = true, onResult }) {
  const SEARCH_TIMEOUT = 500;

  const history = useHistory();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { tags, prefixes, conventionSets } = useSelector(({ settings }) => settings);
  const { refetchTable } = useSelector(({ dochub }) => dochub);
  const { sets } = useSelector(({ sets }) => sets);
  const { user } = useSelector(({ profile }) => profile);

  const [ownerDialog, setOwnerDialog] = useState(false);
  const [ownerUpdating, setOwnerUpdating] = useState(false);

  const [sequenceLoading, setSequenceLoading] = useState(false);
  const [sequenceError, setSequenceError] = useState(false);
  const [sequenceMessage, setSequenceMessage] = useState(false);
  const [validateSeq, setValidateSeq] = useState(false);

  // timer for sequence
  const timer = useRef(null);

  const MenuProps = {
    sx: {
      '& .MuiPaper-root': {
        borderRadius: '10px',
        marginTop: 1,
        '& .MuiList-root': {
          py: 0,
          background: (t) => (t.palette.mode === 'dark' ? 'black' : 'white'),
          maxHeight: 48 * 4.5,
          '& li': {
            fontWeight: 200,
            py: '12px',
          },
          '& li:hover': {
            background: COLORS.SETTINGS,
            color: 'white',
          },
          '& li.Mui-initial': {
            color: 'white',
            background: COLORS.SEARCH,
          },
          '& li.Mui-initial:hover': {
            background: COLORS.SEARCH,
          },
        },
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    disableAutoFocus: true,
    disableEnforceFocus: true,
    disableRestoreFocus: true,
    disableAutoFocusItem: true,
    autoFocus: false,
  };

  // toggles
  const [editable, setEditable] = useState(false);

  // setup fields
  const [title, setTitle] = useState('');
  const [prefix, setPrefix] = useState('');
  const [sequence, setSequence] = useState();
  const [reviewer, setReviewer] = useState(currentUser);
  const [controlLoading, setControlLoading] = useState(true);
  const [controlled, setControlled] = useState(null);
  const [issue, setIssue] = useState(1);
  const [revisionFormat, setRevisionFormat] = useState(VFORMAT);
  const [reviewSettings, setReviewSettings] = useState(false);
  const [reviewPeriod, setReviewPeriod] = useState('1');
  const [reviewStage, setReviewStage] = useState('years');
  const [reviewDate, setReviewDate] = useState(moment.utc());

  const [docTags, setDocTags] = useState([]);
  const [conventionSet, setConventionSet] = useState('');
  const [conventionSetParams, setConventionSetParams] = useState([]);
  const [initialConventionSet, setSelectedConventionSet] = useState(null);
  const [manualIssueControl, setManualIssueControl] = useState(false);
  const [manualIssue, setManualIssue] = useState('');

  const [reviewUsers, setReviewUsers] = useState([]);
  const [reviewGroups, setReviewGroups] = useState([]);
  const [minimumReviewerRequired, setMinimumReviewerRequired] = useState(false);
  const [minimumReviewers, setMinimumReviewers] = useState(1);
  const [sendReviewToAll, setSendReviewToAll] = useState(false);
  const [sendReviewToOwner, setSendReviewToOwner] = useState(false);

  const [upIssueNotificationType, setUpIssueNotificationType] = useState('');
  const [issueUsers, setIssueUsers] = useState([]);
  const [issueGroups, setIssueGroups] = useState([]);
  const [ruType, setRuType] = useState('');
  const [ruTaskUsers, setRuTaskUsers] = useState([]);
  const [ruTaskGroups, setRuTaskGroups] = useState([]);

  const [controlDialog, setControlDialog] = useState(false);
  const [controlInitial, setControlInitial] = useState(null);
  const [controlUpdating, setControlUpdating] = useState(null);

  const [documentState, setDocumentState] = useState(null);
  const [preApproval, setPreApproval] = useState(false);
  const [status, setStatus] = useState(false);

  const cantChangeOwner =
    user?.access !== 'admin'
      ? user?._id !== initial?.owner_id?._id ||
        initial?.locked ||
        ['In Work', 'In Review', 'Waiting Approval', 'Under Review', 'Pre-approval'].includes(documentState)
      : false;

  const documentName = useDocumentName({
    title,
    sequence,
    setId: conventionSet,
    initial: conventionSetParams,
  });

  const handleDone = () => {
    // add validation checks

    if (ruType === 'specific' && ruTaskUsers.length === 0 && ruTaskGroups.length === 0) {
      StateManager.setErrorAlert('Please select users or groups for the Read and Understand task');
      return;
    }

    if (upIssueNotificationType === 'specific' && issueUsers.length === 0 && issueGroups.length === 0) {
      StateManager.setErrorAlert('Please select users or groups for the up-issue notification');
      return;
    }

    if (reviewSettings && reviewUsers.length === 0 && reviewGroups.length === 0) {
      StateManager.setErrorAlert('Please select users or groups for the review');
      return;
    }

    let update = {
      title: documentName,
      documentState: controlled ? 'Controlled' : 'Normal',
      status,
      prefix,
      sequence,
      controlled,
      reviewSettings,
      manualIssueControl,
      manualIssue,
    };
    if (documentState === 'Pre-approval setup' || preApproval) {
      update.documentState = 'Pre-approval';
    }
    if (documentState === 'Template') {
      update.documentState = 'Template';
    }
    if (docTags.length > 0) {
      update.tags = docTags.map((x) => x._id);
    }
    if (!status) {
      update.revisionFormat = revisionFormat;
      update.issue = issue;
      update.status = true;
    }
    if (reviewSettings) {
      if (reviewDate === initial?.reviewDate) {
        update.reviewDate = initial?.reviewDate;
      } else {
        update.reviewDate = reviewDate;
      }
      update.reviewPeriod = reviewPeriod;
      update.reviewStage = reviewStage;
      update.reviewer = reviewer;
      update.minimumReviewerRequired = minimumReviewerRequired;
      update.minimumReviewers = minimumReviewers;
      update.sendReviewToAll = sendReviewToAll;
      update.reviewUsers = reviewUsers;
      update.reviewGroups = reviewGroups;
      update.sendReviewToOwner = sendReviewToOwner;
    }
    if (conventionSets && conventionSet) {
      update.conventionSet = conventionSet;
      update.conventionSetParams = conventionSetParams;
    } else {
      update.conventionSet = '';
    }
    update.issueUsers = issueUsers;
    update.issueGroups = issueGroups;
    update.ruTaskUsers = ruTaskUsers;
    update.ruTaskGroups = ruTaskGroups;
    update.upIssueNotificationType = upIssueNotificationType;
    update.ruType = ruType;
    if (edit) {
      dispatch(updateSetupDocument(initial._id, update, history)).then(() => {
        if (refetchTable) {
          refetchTable(true);
        }
      });
      clear();
      handleSetup(false);
    } else {
      onResult(update);
      clear();
    }
  };

  const clear = () => {
    setEditable(false);
    setReviewPeriod(1);
    setReviewStage('years');
    setReviewDate(moment.utc());
    setReviewer('');
    setDocTags([]);
  };

  const handleOwnerChange = (owner) => {
    setOwnerUpdating(true);
    dispatch(
      updateOwner(initial._id, owner, () => {
        setOwnerUpdating(false);
        setOwnerDialog(false);
      }),
    );
  };

  useEffect(() => {
    if (!setup) return;
    batch(() => {
      dispatch(getTags());
      dispatch(getPrefixes());
    });
  }, [setup, dispatch]);

  useEffect(() => {
    if (!initial || !setup) return;
    if (initial !== null) {
      setValidateSeq(false);
      setSequenceError(false);
      setSequenceMessage('');
      setTitle(initial?.title);
      setControlled(initial?.controlled);
      setControlLoading(false);
      setRevisionFormat(initial?.revisionFormat);
      setManualIssueControl(initial?.manualIssueControl);
      setManualIssue(initial?.manualIssue);
      setIssue(initial?.issue);
      setSequence(initial?.sequence);

      setDocumentState(initial?.documentState || 'Setup');
      setStatus(initial?.status || false);
      setPreApproval(initial?.preApproval || false);

      setReviewSettings(initial?.reviewSettings);
      setReviewUsers(initial?.reviewUsers || []);
      setMinimumReviewerRequired(initial?.minimumReviewerRequired || false);
      setMinimumReviewers(initial?.minimumReviewers);
      setSendReviewToAll(initial?.sendReviewToAll || false);

      if (!initial.reviewDate) {
        setReviewDate(moment().add(initial.reviewPeriod, initial.reviewStage).format('YYYY-MM-DD'));
      } else {
        setReviewDate(moment(initial.reviewDate));
      }

      setIssueUsers(initial?.issueUsers || []);
      setIssueGroups(initial?.issueGroups || []);
      setRuTaskUsers(initial?.ruTaskUsers || []);
      setRuTaskGroups(initial?.ruTaskGroups || []);
      setReviewGroups(initial?.reviewGroups || []);
      setUpIssueNotificationType(initial?.upIssueNotificationType || 'none');
      setRuType(initial?.ruType || 'none');
      setSendReviewToOwner(initial?.sendReviewToOwner || false);

      if (initial.prefix) {
        setPrefix(initial.prefix?._id || '');
      } else {
        setPrefix('');
      }
      setDocTags(initial?.tags || []);
      if (initial.reviewPeriod) {
        setReviewPeriod(initial.reviewPeriod);
      }
      if (initial.reviewStage) {
        setReviewStage(initial.reviewStage);
      }
      if (initial.conventionSet) {
        setConventionSet(initial.conventionSet);
      }
      if (initial.conventionSetParams) {
        setConventionSetParams(initial.conventionSetParams);
      }
    }
  }, [dispatch, setup, initial]);

  useEffect(() => {
    if (!conventionSets || !setup) return;
    dispatch(getSets());
  }, [setup, conventionSets, dispatch]);

  const handleSequence = (seq, pref, set, params) => {
    setSequenceLoading(true);
    axios
      .post(`dochub/documents/validateSequence`, {
        sequence: seq,
        prefix: pref,
        set,
        setParams: params,
        documentId: initial?._id,
      })
      .then((res) => {
        setValidateSeq(res.data);
        setSequence(Number(res.data?.sequence));
        setSequenceError(!res.data?.available);
        setSequenceMessage(res.data?.message);
        setTitle(documentName);
        setSequenceLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSequenceLoading(false);
      });
  };

  const checkSequence = (seq) => {
    handleSequence(seq, prefix, conventionSet, conventionSetParams);
  };

  const handleConventionSet = (e) => {
    setConventionSetParams(e);
    handleSequence('', '', conventionSet, e);
    setSelectedConventionSet(null);
  };

  function activateTimer(query) {
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(checkSequence, SEARCH_TIMEOUT, query);
  }

  const getStartValue = (option) => {
    return revisionFormat === VFORMAT
      ? `V${option}`
      : revisionFormat === NFORMAT
      ? option
      : String.fromCharCode(64 + option);
  };

  const onAddUsers = () => {
    StateManager.selectMultipleUsers(
      (res) => {
        if (res.users) {
          setReviewUsers(res.users);
        }
        if (res.groups) {
          setReviewGroups(res.groups);
        }
      },
      { initiallySelected: reviewUsers, initiallySelectedGroups: reviewGroups, showGroups: true, withoutPortal: true },
    );
  };

  const onAddIssueUsers = () => {
    StateManager.selectMultipleUsers(
      (res) => {
        if (res.users) {
          setIssueUsers(res.users);
        }
        if (res.groups) {
          setIssueGroups(res.groups);
        }
      },
      { initiallySelected: issueUsers, initiallySelectedGroups: issueGroups, showGroups: true, withoutPortal: true },
    );
  };

  const onAddRuUsers = () => {
    StateManager.selectMultipleUsers(
      (res) => {
        if (res.users) {
          setRuTaskUsers(res.users);
        }
        if (res.groups) {
          setRuTaskGroups(res.groups);
        }
      },
      { initiallySelected: ruTaskUsers, initiallySelectedGroups: ruTaskGroups, showGroups: true, withoutPortal: true },
    );
  };

  const controlDoc = (id) => {
    StateManager.setConfirm(
      'You are about to make this document to controlled and would need to setup this document',
      () => {
        setControlUpdating(id);
        axios
          .post('dochub/areas/getArea', { area_id: initial.area._id || initial.area })
          .then((res) => {
            const result = res.data?.area;
            const uploadRules = result?.uploadRules;
            const sendAutoAll = result?.sendAutoAll;
            const isDialogRequired = !sendAutoAll && uploadRules;
            if (isDialogRequired) {
              // open up dialog
              setControlInitial(result);
              setControlDialog(true);
            } else {
              convertToControlled(id);
            }
          })
          .catch((err) => {
            setControlUpdating(null);
            StateManager.setAxiosErrorAlert(err);
          });
      },
    );
  };

  const convertToControlled = (id) => {
    axios
      .post(`dochub/documents/convertToControlled/${id}`)
      .then(() => {
        setControlUpdating(null);
        StateManager.setSuccessAlert('Document has been made controlled');
        if (refetchTable) {
          refetchTable;
        }
        setControlled(true);
      })
      .catch((err) => {
        setControlUpdating(null);
        StateManager.setAxiosErrorAlert(err);
      });
  };

  return (
    <RoundedDialog
      keepMounted
      fullWidth
      fullScreen={isMobile}
      maxWidth="md"
      open={Boolean(setup)}
      scroll={'paper'}
      onClose={() => handleSetup(false)}
      disableAutoFocus
      titleId="document-setup"
    >
      <DialogTitle id="document-setup-title" sx={{ padding: (theme) => theme.spacing(1, 2) }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item container alignItems="center" sm={10}>
            <Typography variant="h6">Document Setup</Typography>
            <InfoIcon sx={{ verticalAlign: 'middle', marginLeft: '10px' }} fontSize="small" />
          </Grid>
          {initial && (
            <Grid item>
              <IconButton onClick={() => dispatch(previewFile(initial))}>
                <VisibilityIcon sx={{ verticalAlign: 'middle' }} fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      {!initial && (
        <DialogContent dividers>
          <Container maxWidth={false}>
            <AlertMessage message="Please select a document" type="warning" sx={{ width: '100%' }} grid />
          </Container>
        </DialogContent>
      )}
      {initial && (
        <DialogContent dividers>
          {controlled && (
            <Grid container fluid="true" direction="row" spacing={2}>
              <Grid item xs>
                <TextField
                  margin="dense"
                  label="Sequence"
                  type="text"
                  fullWidth
                  value={sequence?.toString()}
                  variant="standard"
                  onChange={(e) => {
                    setSequence(Number(e.target.value));
                    activateTimer(e.target.value);
                  }}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  helperText={sequenceLoading ? 'Validating Sequence' : sequenceMessage}
                  error={sequenceError}
                  onClick={(e) => e.stopPropagation()}
                  inputProps={{ pattern: '[0-9]*' }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="loader" disabled edge="end">
                          {sequenceLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : validateSeq ? (
                            validateSeq.available ? (
                              <CheckCircle color="primary" />
                            ) : (
                              <Cancel color="error" />
                            )
                          ) : null}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {!conventionSet && (
                <Grid item xs>
                  <InputLabel htmlFor="document-prefix">Document Prefix</InputLabel>
                  <Select
                    fullWidth
                    labelId="prefix-label"
                    id="prefix"
                    value={prefix}
                    placeholder="Choose Document Prefix"
                    variant="standard"
                    MenuProps={MenuProps}
                    displayEmpty
                    disabled={Boolean(conventionSet)}
                    onChange={(e) => {
                      setPrefix(e.target.value);
                      handleSequence('', e.target.value, '', '');
                    }}
                  >
                    <MenuItem value="">No Prefix</MenuItem>
                    {prefixes &&
                      prefixes?.map((prefix) => (
                        <MenuItem value={prefix._id} key={prefix._id}>
                          {prefix.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              )}
            </Grid>
          )}
          <Grid container fluid="true" direction="row" spacing={2}>
            <Grid item xs>
              <TextField
                autoFocus
                className={'docTitle'}
                variant="standard"
                margin="dense"
                id="filename"
                label="Filename"
                type="text"
                fullWidth
                disabled={!editable}
                value={documentName}
                onChange={(e) => setTitle(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle edit"
                        onClick={() => {
                          if (conventionSets && conventionSet) {
                            setSelectedConventionSet(conventionSet);
                          } else {
                            setEditable(true);
                          }
                        }}
                        onMouseDown={() => setEditable(false)}
                        edge="end"
                      >
                        {editable ? <EditOutlinedIcon /> : <EditIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <AutoSelection
                limit={1}
                placeholder={'Add a Tag'}
                label={'Tags'}
                data={tags}
                optionTitle={'name'}
                handleChange={(e, value) => setDocTags(value)}
                value={docTags}
                className={'tags'}
                tag
              />
            </Grid>
            <Grid item xs>
              <TextField
                margin="dense"
                id="uploadAt"
                label="Uploaded At"
                type="text"
                fullWidth
                disabled
                value={moment(initial.createdAt).format('DD/MM/YY')}
                variant="standard"
              />
              <TextField
                margin="dense"
                id="uploadBy"
                label="Uploaded By"
                type="text"
                fullWidth
                disabled
                value={initial.ownerName}
                variant="standard"
              />
            </Grid>
          </Grid>
          {!status && (
            <Grid container fluid="true" direction="row" spacing={2}>
              <Grid item xs sm={12}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={manualIssueControl}
                      onChange={() => {
                        setManualIssueControl(!manualIssueControl);
                        setRevisionFormat(manualIssueControl ? VFORMAT : '');
                      }}
                    />
                  }
                  label="Manual Issue Control"
                />
              </Grid>
              {manualIssueControl && (
                <Grid item xs sm={12}>
                  <TextField
                    margin="dense"
                    id="manualIssue"
                    label="Manual Revision"
                    type="text"
                    fullWidth
                    value={manualIssue}
                    onChange={(e) => setManualIssue(e.target.value)}
                    variant="standard"
                  />
                </Grid>
              )}
              {!manualIssueControl && (
                <Grid item xs>
                  <InputLabel htmlFor="revision-format">Revision Format</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={revisionFormat}
                    placeholder="Choose Revision Format"
                    onChange={(e) => setRevisionFormat(e.target.value)}
                    variant="standard"
                  >
                    <MenuItem value={VFORMAT}>{VFORMAT}</MenuItem>
                    <MenuItem value={NFORMAT}>{NFORMAT}</MenuItem>
                    <MenuItem value={AFORMAT}>{AFORMAT}</MenuItem>
                  </Select>
                </Grid>
              )}
              {!manualIssueControl && (
                <Grid item xs>
                  <InputLabel htmlFor="revision-format">Revision Start</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={issue}
                    placeholder="Choose Revision Start"
                    onChange={(e) => setIssue(e.target.value)}
                    variant="standard"
                    MenuProps={MenuProps}
                  >
                    {range(1, revisionFormat === VFORMAT || revisionFormat === NFORMAT ? 100 : 26, 1).map((option) => (
                      <MenuItem value={option} key={option}>
                        {getStartValue(option)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
            </Grid>
          )}
          {conventionSets && controlled && (
            <Grid container sx={{ margin: (theme) => theme.spacing(2, 0) }}>
              <Grid item sm>
                <InputLabel htmlFor="convention-set-select-label">Convention Set</InputLabel>
                <Select
                  fullWidth
                  labelId="convention-set-select-label"
                  id="convention-set-select"
                  placeholder="Choose Convention Set"
                  value={conventionSet}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setPrefix('');
                    } else {
                      handleSequence('', '', '', '');
                    }
                    setConventionSet(e.target.value);
                    setSelectedConventionSet(e.target.value);
                  }}
                  variant="standard"
                  MenuProps={MenuProps}
                  displayEmpty
                >
                  <MenuItem value="">No Convention Set</MenuItem>
                  {sets?.map((set) => (
                    <MenuItem value={set._id} key={set._id}>
                      {set.title}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          )}
          {controlLoading && (
            <Grid container justifyContent={'center'} alignItems="center" sx={{ my: 2 }}>
              <CircularProgress color="primary" />
            </Grid>
          )}
          {controlled !== null && (
            <Grid container direction="row" fluid="true" justifyContent="center" alignItems="center" sx={{ my: 2 }}>
              {controlUpdating ? (
                <CircularProgress color="primary" />
              ) : (
                <RadioGroup
                  row
                  value={String(controlled)}
                  onChange={(e) => {
                    setControlled(e.target.value === 'true');
                    if (e.target.value === 'true' && initial._id) {
                      controlDoc(initial._id);
                    }
                  }}
                >
                  <FormControlLabel value="false" label="Uncontrolled" control={<Radio />} />
                  <FormControlLabel value="true" label="Controlled" control={<Radio />} />
                </RadioGroup>
              )}
            </Grid>
          )}
          {controlled && (
            <Grow in={controlled}>
              <Grid container direction="column" spacing={2}>
                <Grid item container fluid="true" direction="row" alignItems="center" sx={{ mb: 1 }}>
                  <TextField
                    disabled
                    id="owner-name"
                    margin="dense"
                    size="small"
                    type="text"
                    label="Owner"
                    variant="outlined"
                    value={initial?.ownerName}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle edit"
                            onClick={() => {}}
                            onMouseDown={() => setOwnerDialog(true)}
                            disabled={cantChangeOwner}
                            edge="end"
                          >
                            {cantChangeOwner ? <EditOutlinedIcon /> : <EditIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Divider />
                <Grid item className="review">
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={reviewSettings}
                        onChange={() => {
                          const newValue = !reviewSettings;
                          setReviewSettings(newValue);
                          if (newValue) {
                            setSendReviewToOwner(true);
                            let newUsers = [...reviewUsers, initial.owner_id?._id];
                            newUsers = uniq(newUsers);
                            setReviewUsers(newUsers);
                          }
                        }}
                      />
                    }
                    label="Review settings"
                    labelPlacement="start"
                    sx={{ ml: 0 }}
                  />
                </Grid>
                {reviewSettings && (
                  <Grid item container direction="column" justifyContent="flex-start" gap={2}>
                    <Paper sx={{ p: 2 }} elevation={5}>
                      <Grid item xs={8} container>
                        <InputLabel htmlFor="reviewer-select-label" sx={{ mb: 1 }}>
                          Who do you want to review this document?
                        </InputLabel>
                        <Grid container item lg={12} md={12} sm={12}>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={sendReviewToOwner}
                                  onChange={(e) => {
                                    setSendReviewToOwner(e.target.checked);
                                    if (e.target.checked) {
                                      let newUsers = [...reviewUsers, initial.owner_id?._id];
                                      newUsers = uniq(newUsers);
                                      setReviewUsers(newUsers);
                                    } else {
                                      let newUsers = reviewUsers.filter((x) => x !== initial.owner_id?._id);
                                      newUsers = uniq(newUsers);
                                      setReviewUsers(newUsers);
                                    }
                                  }}
                                  name="sendReviewToOwner"
                                />
                              }
                              label="Review should be sent to the document owner"
                            />
                          </FormControl>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12}>
                          <GeneralButton
                            onClick={onAddUsers}
                            startIcon={<PersonRounded sx={{ color: (t) => t.palette.grey[500] }} />}
                          >
                            Select Users and Groups
                          </GeneralButton>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} alignItems="center" sx={{ my: 1 }}>
                          <Typography sx={{ marginRight: '10px', fontWeight: 500 }}>Users</Typography>
                          {reviewUsers?.map((user) => (
                            <UserChip
                              key={user}
                              id={user}
                              onDelete={() => setReviewUsers(reviewUsers?.filter((x) => x !== user))}
                            />
                          ))}
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} alignItems="center" sx={{ my: 1 }}>
                          <Typography sx={{ marginRight: '10px', fontWeight: 500 }}>Groups</Typography>
                          {reviewGroups?.map((group) => (
                            <Grid item key={group} lg={3} md={4} sm={12}>
                              <GroupButton
                                id={group}
                                key={group}
                                dense
                                onDelete={() => {
                                  setReviewGroups(reviewGroups?.filter((x) => x !== group));
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Paper>
                    <Paper sx={{ p: 2 }} elevation={5}>
                      <ToggleRow
                        title="Do you need a minimum number of people that need to review the document?"
                        toggle={minimumReviewerRequired}
                        setToggle={(e) => {
                          setMinimumReviewerRequired(e);
                          if (minimumReviewerRequired) {
                            setSendReviewToAll(false);
                          }
                        }}
                        name="minimumReviewerRequired"
                        fields={
                          minimumReviewerRequired && (
                            <Grid container item sm gap={2}>
                              <TextField
                                margin="none"
                                size="small"
                                value={minimumReviewers}
                                variant="outlined"
                                type="number"
                                onChange={(e) => setMinimumReviewers(e.target.value)}
                              />
                            </Grid>
                          )
                        }
                      />
                      {minimumReviewerRequired && (
                        <ToggleRow
                          title="Do you want to send the review to all allowed to review and automatically complete it when the minimum number of reviews has been completed?"
                          toggle={sendReviewToAll}
                          setToggle={setSendReviewToAll}
                          name="sendReviewToAll"
                          hasSuffix
                        />
                      )}
                    </Paper>
                    <Paper sx={{ p: 2 }} elevation={5}>
                      <Grid item container sx={{ my: 1 }}>
                        <AlertMessage
                          message={'Changing the review period will change all future review dates'}
                          type={'error'}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                      <Grid item container direction="row" alignItems="center">
                        <Typography sx={{ marginRight: '10px' }}>The document should be reviewed every</Typography>
                        <TextField
                          margin="dense"
                          id="reviewPeriod"
                          label=""
                          type="number"
                          min={1}
                          value={reviewPeriod}
                          variant="outlined"
                          onChange={(e) => {
                            setReviewPeriod(e.target.value);
                            setReviewDate(moment().add(e.target.value, reviewStage).format('YYYY-MM-DD'));
                          }}
                          sx={{
                            width: '10%',
                            marginRight: '15px',
                            '& .MuiOutlinedInput-input': {
                              height: '0.4375em',
                            },
                          }}
                          inputProps={{
                            min: 1,
                          }}
                        />
                        <Select
                          labelId="reviewPeriod-select"
                          id="reviewPeriod-select-box"
                          value={reviewStage}
                          placeholder="Choose"
                          onChange={(e) => {
                            setReviewStage(e.target.value);
                            setReviewDate(moment().add(reviewPeriod, e.target.value).format('YYYY-MM-DD'));
                          }}
                          variant="standard"
                        >
                          <MenuItem value={'days'}>Days</MenuItem>
                          <MenuItem value={'weeks'}>Weeks</MenuItem>
                          <MenuItem value={'months'}>Months</MenuItem>
                          <MenuItem value={'years'}>Years</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item container direction="row" alignItems="center">
                        <Typography sx={{ marginRight: '10px' }}>
                          I want the set the document's next review date to be
                        </Typography>
                        <DatePicker
                          disableContainer
                          margin="dense"
                          value={reviewDate}
                          minDate={moment.utc()}
                          label=""
                          onChange={(e) => setReviewDate(e)}
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                )}
                <Divider sx={{ my: 2 }} />
                <Grid item>
                  <Typography>Revision Settings</Typography>
                </Grid>
                <Grid item container direction="column" justifyContent="flex-start" gap={2}>
                  <Paper sx={{ p: 2 }} elevation={5}>
                    <Grid container direction="column" justifyContent="space-between" sx={{ mb: 2 }}>
                      <FormControl>
                        <FormLabel id="upIssueUsers-label">
                          Who would you like to notify if this document is up-issued?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="upIssueUsers-label"
                          name="upIssueNotificationType"
                          value={upIssueNotificationType}
                          onChange={(e) => setUpIssueNotificationType(e.target.value)}
                        >
                          <FormControlLabel value="all" control={<Radio />} label={'All app users'} />
                          <FormControlLabel
                            value="access-only"
                            control={<Radio />}
                            label={'All users with access to the area'}
                          />
                          <FormControlLabel value="specific" control={<Radio />} label={'Pick users and groups'} />
                          <FormControlLabel value="none" control={<Radio />} label={'None'} />
                        </RadioGroup>
                      </FormControl>
                      {upIssueNotificationType === 'specific' && (
                        <Grid container item lg={12} md={12} sm={12}>
                          <GeneralButton
                            onClick={onAddIssueUsers}
                            startIcon={<PersonRounded sx={{ color: (t) => t.palette.grey[500] }} />}
                          >
                            Select Users and Groups
                          </GeneralButton>
                        </Grid>
                      )}
                      {upIssueNotificationType === 'specific' && (
                        <Grid container item lg={12} md={12} sm={12} alignItems="center" sx={{ my: 1 }}>
                          <Typography sx={{ marginRight: '10px', fontWeight: 500 }}>Users</Typography>
                          {issueUsers?.map((user) => (
                            <UserChip
                              key={user}
                              id={user}
                              onDelete={() => setIssueUsers(issueUsers?.filter((x) => x !== user))}
                            />
                          ))}
                        </Grid>
                      )}
                      {upIssueNotificationType === 'specific' && (
                        <Grid container item lg={12} md={12} sm={12} alignItems="center" sx={{ my: 1 }}>
                          <Typography sx={{ marginRight: '10px', fontWeight: 500 }}>Groups</Typography>
                          {issueGroups?.map((group) => (
                            <Grid item key={group} lg={3} md={4} sm={12}>
                              <GroupButton
                                id={group}
                                key={group}
                                dense
                                onDelete={() => {
                                  setIssueGroups(issueGroups?.filter((x) => x !== group));
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item container direction="column" justifyContent="flex-start" gap={2}>
                  <Paper sx={{ p: 2 }} elevation={5}>
                    <Grid container direction="column" justifyContent="space-between" sx={{ mb: 2 }}>
                      <FormControl>
                        <FormLabel id="ruTaskUsers-label">
                          Choose who you like to send a Read and understood task to if the document is updated (if left
                          blank no R&U tasks will be sent)
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="ruTaskUsers-label"
                          name="ruTaskUsers"
                          value={ruType}
                          onChange={(e) => setRuType(e.target.value)}
                        >
                          <FormControlLabel value="all" control={<Radio />} label={'All app users'} />
                          <FormControlLabel
                            value="access-only"
                            control={<Radio />}
                            label={'All users with access to the area'}
                          />
                          <FormControlLabel value="specific" control={<Radio />} label={'Pick users and groups'} />
                          <FormControlLabel value="none" control={<Radio />} label={'None'} />
                        </RadioGroup>
                      </FormControl>
                      {ruType === 'specific' && (
                        <Grid container item lg={12} md={12} sm={12}>
                          <GeneralButton
                            onClick={onAddRuUsers}
                            startIcon={<PersonRounded sx={{ color: (t) => t.palette.grey[500] }} />}
                          >
                            Select Users and Groups
                          </GeneralButton>
                        </Grid>
                      )}
                      {ruType === 'specific' && (
                        <Grid container item lg={12} md={12} sm={12} alignItems="center" sx={{ my: 1 }}>
                          <Typography sx={{ marginRight: '10px', fontWeight: 500 }}>Users</Typography>
                          {ruTaskUsers?.map((user) => (
                            <UserChip
                              key={user}
                              id={user}
                              onDelete={() => setRuTaskUsers(ruTaskUsers?.filter((x) => x !== user))}
                            />
                          ))}
                        </Grid>
                      )}
                      {ruType === 'specific' && (
                        <Grid container item lg={12} md={12} sm={12} alignItems="center" sx={{ my: 1 }}>
                          <Typography sx={{ marginRight: '10px', fontWeight: 500 }}>Groups</Typography>
                          {ruTaskGroups?.map((group) => (
                            <Grid item key={group} lg={3} md={4} sm={12}>
                              <GroupButton
                                id={group}
                                key={group}
                                dense
                                onDelete={() => {
                                  setRuTaskGroups(ruTaskGroups?.filter((x) => x !== group));
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grow>
          )}
        </DialogContent>
      )}
      <UseConventionSetDialog
        open={Boolean(initialConventionSet)}
        handleClose={() => setSelectedConventionSet(false)}
        setId={initialConventionSet}
        initial={conventionSetParams}
        sequence={sequence}
        title={title}
        handleConfirm={handleConventionSet}
      />
      <ChangeOwnerDialog
        open={ownerDialog}
        handleClose={() => setOwnerDialog(false)}
        initiallySelected={initial?.owner_id?._id?.toString()}
        handleConfirm={handleOwnerChange}
        confirmButtonSuffix="Owner"
        changing={ownerUpdating}
      />
      <ControlledDialog
        open={controlDialog}
        onClose={(data) => {
          setControlDialog(false);
          setControlInitial(null);
          setControlUpdating(null);
          if (data) {
            setDocumentState(data?.documentState);
            setPreApproval(data?.preApproval);
            setStatus(data?.status);
          }
        }}
        initial={controlInitial}
        documentId={initial?._id}
      />
      <DialogActions sx={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
        <Button onClick={() => handleSetup(false)}>Close</Button>
        {preApproval && (
          <Grid item sm={5} align={'center'}>
            <AlertMessage message={'Click save to start the pre approval process'} type={'info'} />
          </Grid>
        )}
        <PrimaryButton
          action={handleDone}
          title="Save"
          disabled={validateSeq ? !validateSeq.available : !initial || sequenceLoading}
        />
      </DialogActions>
    </RoundedDialog>
  );
}
