import { EditorState } from 'draft-js';
import { getRaw } from '../../components/HTMLEditor/utils';
import {
  QTEMPLATESLIST_ATTEMPT,
  QTEMPLATESACTION_ATTEMPT,
  QTEMPLATES_ERROR,
  GETQTEMPLATES,
  GETQTEMPLATE,
  UPDATEQTEMPLATE,
  DELETEQTEMPLATE,
  ADDQTEMPLATE,
  SETTEMPLATEEDITORSTATE,
  SETQTEMPLATETITLE,
} from '../constants';

import { createWithContent } from '../../components/HTMLEditor/utils/editor';

const initialState = {
  listLoading: false,
  actionLoading: false,
  error: null,
  qTemplates: [],
  qTemplate: null,
  editorState: EditorState.createEmpty(),
  title: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case QTEMPLATESLIST_ATTEMPT:
      return {
        ...state,
        listLoading: true,
        error: null,
      };
    case GETQTEMPLATES:
      return {
        ...state,
        listLoading: false,
        qTemplates: action.payload,
      };
    case GETQTEMPLATE:
      return {
        ...state,
        listLoading: false,
        title: action.payload?.title,
        qTemplate: action.payload,
        content: createWithContent(action.payload.raw),
        editorState: createWithContent(action.payload.raw),
      };
    case ADDQTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        qTemplates: [...state.qTemplates, action.payload],
      };
    case UPDATEQTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        qTemplates: state.qTemplates.map((qTemplate) => {
          if (qTemplate.id === action.payload.id) {
            return action.payload;
          }
          return qTemplate;
        }),
        qTemplate: state.qTemplate
          ? state.qTemplate.id === action.payload.id
            ? action.payload
            : state.qTemplate
          : null,
      };
    case DELETEQTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        qTemplates: state.qTemplates.filter((qTemplate) => qTemplate.id !== action.payload.id),
      };
    case QTEMPLATESACTION_ATTEMPT:
      return {
        ...state,
        actionLoading: true,
        error: null,
      };
    case QTEMPLATES_ERROR:
      return {
        ...state,
        actionLoading: false,
        listLoading: false,
        error: action.payload,
      };
    case SETQTEMPLATETITLE:
      return { ...state, title: action.payload };
    case SETTEMPLATEEDITORSTATE:
      return { ...state, editorState: action.payload, rawContent: getRaw(action.payload) };

    default:
      return state;
  }
}
