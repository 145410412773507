import axios from '../../../../../redux/apiCaller';
import { successAction, errorAction, attemptAction } from '../../../../../redux/constants';
import { getRaw } from '../../components/HTMLEditor/utils';
import { onAddCommentOnly } from '../../components/HTMLEditor/utils/comment';
import {
  SETSUGGESTIONS,
  DOCLIBRARY_ATTEMPT,
  DOCLIBRARY_ERROR,
  ADDREVCOMMENT,
  UPDATEREVCOMMENT,
  FETCHREVCOMMENTS,
  DELETEREVCOMMENT,
  ADDCOMMENTTOSTATE,
  FETCHREVCOMMENT,
  FETCHREVCOMMENTS_ATTEMPT,
  UPDATEREPLYINREVCOMMENT,
  FETCHREVCOMMENT_ATTEMPT,
  DELETEREVCOMMENTS,
} from '../constants';
import StateManager from '../../../../Global/StateManager';

export const setSuggestions = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETSUGGESTIONS, data));
  };
  return thunk;
};

export const fetchComments = (id, params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHREVCOMMENTS_ATTEMPT));
      const body = await axios.get(`dochub/revisions/${id}/comments`, { params });
      if (body.data.status === 200) {
        dispatch(successAction(FETCHREVCOMMENTS, body.data));
      } else {
        StateManager.setErrorAlert('We were unable to fetch comments for this document');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const fetchComment = (id, cid) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHREVCOMMENT_ATTEMPT));
      const body = await axios.get(`dochub/revisions/${id}/comments/${cid}`);
      if (body.data.status === 200) {
        dispatch(successAction(FETCHREVCOMMENT, body.data));
      } else {
        StateManager.setErrorAlert('We were unable to fetch comment for this document');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const addComment = (id, data, onResponse) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/comments`, data);
      if (body.data.status === 200) {
        dispatch(successAction(ADDREVCOMMENT, body.data));
        StateManager.setSuccessAlert('Comment Added Successfully');
        if (onResponse) onResponse(body.data?.comment);
      } else {
        StateManager.setErrorAlert('We were unable to add comment for this document');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const updateComment = (id, cid, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/comments/${cid}`, data);
      if (body.data.status === 200) {
        dispatch(successAction(UPDATEREVCOMMENT, body.data));
        dispatch(successAction(UPDATEREPLYINREVCOMMENT, body.data));
        StateManager.setSuccessAlert('Comment Updated Successfully');
      } else {
        StateManager.setErrorAlert('We were unable to update comment for this document');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const likeComment = (id, cid, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/comments/${cid}/like`, data);
      if (body.data.status === 200) {
        dispatch(successAction(UPDATEREVCOMMENT, body.data));
        StateManager.setSuccessAlert('Comment Liked Successfully');
      } else {
        StateManager.setErrorAlert('We were unable to like comment for this document');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const deleteComment = (id, cid) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.delete(`dochub/revisions/${id}/comments/${cid}`);
      if (body.data.status === 200) {
        dispatch(successAction(DELETEREVCOMMENT, body.data));
        StateManager.setSuccessAlert('Comment Deleted Successfully');
      } else {
        StateManager.setErrorAlert('We were unable to delete comment for this document');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const deleteComments = (cids) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(DELETEREVCOMMENTS, cids));
  };
  return thunk;
};
