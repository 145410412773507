import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ArrowBackIosRounded } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

export default function BackArrowButton({ onClick, text, number = -1, ...props }) {
  const history = useHistory();

  const click = typeof onClick === 'function' ? onClick : () => history.go(number);

  return (
    <Tooltip title={text || 'Back'}>
      <IconButton onClick={click} color="primary" {...props}>
        <ArrowBackIosRounded />
      </IconButton>
    </Tooltip>
  );
}
