import React, { forwardRef } from 'react';
import { Typography, MenuItem, Checkbox, Grid } from '@mui/material';

const CheckboxWithIcon = forwardRef(({ checked, onChange, text, icon }, ref) => {
  return (
    <MenuItem onClick={() => onChange(!checked)}>
      <Grid container alignItems="center" wrap="nowrap">
        <Checkbox sx={{ p: 0 }} checked={checked} />
        <Typography sx={{ mx: 2 }}>{text}</Typography>
        <Grid style={{ marginLeft: 'auto' }}>{icon}</Grid>
      </Grid>
    </MenuItem>
  );
});

export default CheckboxWithIcon;
