import React, { useState, useEffect, useRef } from 'react';
import { isArray, isEqual, isFunction } from 'lodash';
import { Grid, Typography, InputBase } from '@mui/material';

export default function PageGridTable({ initial, onChange, editable }) {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const timer = useRef(null);

  const HEADER_HEIGHT = 30;
  const SAVING_TIMEOUT = 1200;

  useEffect(() => {
    if (isArray(initial?.rows) && !isEqual(initial.rows, rows)) {
      setRows(initial.rows);
    }
    if (isArray(initial?.columns) && !isEqual(initial.columns, columns)) {
      setColumns(initial.columns);
    }
  }, [initial]); // eslint-disable-line

  function handleChange(value) {
    if (!isFunction(onChange)) return;

    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => onChange(value), SAVING_TIMEOUT);
  }

  return (
    <Grid conatainer wrap="nowrap">
      <Grid container wrap="nowrap" style={{ position: 'relative', width: 'auto' }}>
        {columns.map((column) => (
          <Grid key={column.id} container style={{ width: column.width }}>
            <Grid container style={{ height: HEADER_HEIGHT, border: '1px solid black' }}>
              <Typography style={{ fontWeight: 500 }}>{column.title}</Typography>
            </Grid>
            {rows.map((row, rowIndex) => (
              <Grid
                key={row.id}
                container
                style={{ height: row.height, border: '1px solid black' }}
                alignItems="flex-end"
                alignContent="flex-end"
              >
                {editable ? (
                  <InputBase
                    value={row.data[column.id]?.value || ''}
                    type={column.type === 'number' ? 'number' : 'text'}
                    onChange={(e) => {
                      rows[rowIndex].data[column.id] = { value: e.target.value };
                      setRows([...rows]);
                      handleChange({ rows });
                    }}
                    inputProps={{ style: { padding: '0 4px' } }}
                    fullWidth
                  />
                ) : (
                  <Typography>{row.data[column.id]?.value || ''}</Typography>
                )}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
