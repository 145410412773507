import React, { useState, useEffect } from 'react';
import RoundedDialog from './RoundedDialog';
import {
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  ListItemText,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Alert, CircularProgress, FormControlLabel, Checkbox, Collapse } from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import StandardDialogActions from './StandardDialogActions';
import { isEmpty, isFunction, isArray } from 'lodash';
import UserGroup from './UserGroup';
import { GroupAddRounded, ScheduleRounded, EditRounded, SendRounded } from '@mui/icons-material';
import { PlayCircleFilledWhiteRounded, HighlightOffRounded } from '@mui/icons-material';
import StateManager from '../StateManager';
import DatePicker from './DatePicker';
import axios from 'axios';
import { FormatOnlyDate, FormatDate } from '../Functions';
import { grey, red } from '@mui/material/colors';
import ReviewTaskDialog from './ReviewTaskDialog';
import { useSelector } from 'react-redux';
import moment from 'moment';

const timeUnits = ['day', 'week', 'month', 'year'];
const Types = {
  form: 'form',
  process: 'process',
  formEntry: 'form entry',
  processEntry: 'process entry',
};

export default function ReviewDialog({ open, onClose, type, targetId, onEntryCreated, closeOnEntryCreated }) {
  const [review, setReview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [assigned, setAssigned] = useState([]);
  const [interval, setInterval] = useState(1);
  const [unit, setUnit] = useState('month');
  const [nextDate, setNextDate] = useState(null);
  const [sendNow, setSendNow] = useState(false);
  const [withDueDate, setWithDueDate] = useState(false);
  const [sendBefore, setSendBefore] = useState(7);
  const [editMode, setEditMode] = useState(false);
  const [sending, setSending] = useState(false);
  const [sendingWitoutSchedule, setSendingWitoutSchedule] = useState(false);
  const [entries, setEntries] = useState([]);
  const [entryDialog, setEntryDialog] = useState(false);
  const [selectedEntryId, setSelectedEntryId] = useState(false);
  const [switching, setSwitching] = useState(false);
  const [datePickerDialog, setDatePickerDialog] = useState(false);
  const { user } = useSelector(({ profile }) => profile);

  const active = review?.state === 'active';
  const passive = review?.state === 'passive';
  const editing = editMode || !review || passive;

  const loadReview = !review && open;

  useEffect(() => {
    if (!loadReview || !targetId) return;

    setLoading(true);
    axios
      .get('/reviews/general/getReviewByTargetId', { params: { targetId } })
      .then(({ data }) => {
        if (data) {
          setAssigned(data.assigned);
          setInterval(data.frequency?.interval || 1);
          setUnit(data.frequency?.unit || 'month');
          setNextDate(data.nextDate || data.scheduledAt);
          setEntries(data.entries);
          setWithDueDate(Boolean(data.withDueDate));
          setSendBefore(data.sendBefore || 7);
          setReview(data);
        } else {
          setReview(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [loadReview, targetId]);

  function done() {
    const body = {
      reviewId: review?._id,
      type,
      targetId,
      assigned,
      frequency: {
        interval,
        unit,
      },
      nextDate,
      sendNow,
      withDueDate,
      sendBefore,
    };
    const url = review ? '/reviews/management/editReview' : '/reviews/management/createReview';
    setSaving(true);
    axios
      .post(url, body)
      .then(({ data }) => {
        setReview(data);
        if (isArray(data.sentEntries) && !isEmpty(data.sentEntries)) {
          setEntries([...data.sentEntries, ...entries]);
        }
        if (!review && data.entries?.length === 1 && isFunction(onEntryCreated) && closeOnEntryCreated) {
          onEntryCreated(data.entries[0]);
          onClose();
        }
        setNextDate(data.nextDate);
        setEditMode(false);
        setSaving(false);
        StateManager.setSuccessAlert('Review has been saved');
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function pickAssigned() {
    StateManager.selectMultipleUsers(saveAssigned, { initiallySelected: assigned });
  }

  function saveAssigned(res) {
    if (!res?.users) return;
    setAssigned(res.users);
  }

  function updateEntry(entry) {
    const index = entries.findIndex((x) => x._id === entry._id);
    if (index === -1) return;
    entries[index] = entry;
    setEntries([...entries]);
  }

  function deleteEntry(entryId) {
    setEntries(entries.filter((x) => x._id !== entryId));
  }

  function switchReview() {
    setSwitching(true);

    axios
      .post('/reviews/management/switchReview', { reviewId: review._id })
      .then(({ data }) => {
        setReview({ ...review, state: data.state });
        setSwitching(false);
        StateManager.setSuccessAlert(`Review has been ${data.state === 'active' ? '' : 'de'}activated`);
      })
      .catch((err) => {
        setSwitching(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function sendWithoutSchedule() {
    setSendingWitoutSchedule(true);

    axios
      .post('/reviews/management/sendReviewWithoutSchedule', { targetType: type, targetId })
      .then(({ data }) => {
        setReview(data.review);
        setEntries([...entries, data.entry]);

        if (isFunction(onEntryCreated) && closeOnEntryCreated) {
          onEntryCreated(data.entry);
          onClose();
        }

        setSendingWitoutSchedule(false);
        StateManager.setSuccessAlert(`Review has been sent`);
      })
      .catch((err) => {
        setSendingWitoutSchedule(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Review settings</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems={'center'} justifyContent={'center'} sx={{ height: '50vh' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container>
            {!editing && (
              <Grid container item alignItems={'center'} justifyContent={'space-between'}>
                {review && !passive ? (
                  <>
                    {active ? (
                      <Alert severity="success">Review is scheduled </Alert>
                    ) : (
                      <Alert severity="error">Review is deactivated </Alert>
                    )}

                    <Button
                      sx={{ color: review.state === 'active' ? red[500] : undefined, ml: 2 }}
                      startIcon={
                        switching ? (
                          <CircularProgress size={24} />
                        ) : review.state === 'active' ? (
                          <HighlightOffRounded />
                        ) : (
                          <PlayCircleFilledWhiteRounded />
                        )
                      }
                      onClick={switchReview}
                    >
                      {review.state === 'active' ? 'deactivate' : 'activate'} review
                    </Button>
                  </>
                ) : (
                  <Alert severity="warning">Review for this {Types[type]} is not scheduled</Alert>
                )}
              </Grid>
            )}
            <Grid container item alignItems={'center'} wrap="nowrap" sx={{ mt: 2 }}>
              <Typography noWrap sx={{ fontWeight: 500, mr: 3, fontSize: '1.1rem' }}>
                Assigned users:
              </Typography>
              {isEmpty(assigned) ? (
                <>
                  {editing && (
                    <Button startIcon={<GroupAddRounded />} onClick={pickAssigned} sx={{ textWrap: 'nowrap' }}>
                      Add users
                    </Button>
                  )}
                  <Typography sx={{ ml: 1, fontSize: '0.9rem', fontWeight: 400 }} color={'textSecondary'}>
                    If you don't select users the review will automatically be sent to the {Types[type]} owner
                  </Typography>
                </>
              ) : (
                <UserGroup ids={assigned} onClick={editing ? pickAssigned : null} />
              )}
            </Grid>
            <Grid container item alignItems={'center'} wrap="nowrap" sx={{ mt: 2 }}>
              <Typography noWrap sx={{ fontWeight: 500, mr: 3, fontSize: '1.1rem' }}>
                Frequency:
              </Typography>
              {review?.state === 'cancelled' ? (
                <Typography>Not scheduled</Typography>
              ) : (
                <>
                  <Typography noWrap>
                    This {Types[type]} should be reviewed every{' '}
                    {!editing && (
                      <>
                        {interval} {unit}
                        {interval > 1 ? 's' : ''}
                      </>
                    )}
                  </Typography>
                  {editing && (
                    <>
                      <TextField
                        label="Interval"
                        type="number"
                        value={interval}
                        onChange={(e) => {
                          const value = e.target.value === '' ? '' : Number(e.target.value);
                          if (value !== '' && value < 1) return;
                          setInterval(value);
                        }}
                        sx={{ width: '50px', ml: 2 }}
                        variant="standard"
                      />
                      <TextField
                        select
                        value={unit}
                        label="Unit"
                        onChange={(e) => setUnit(e.target.value)}
                        sx={{ minWidth: 100, ml: 2 }}
                        variant="standard"
                      >
                        {timeUnits.map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            <ListItemText>
                              {unit}
                              {interval > 1 ? 's' : ''}
                            </ListItemText>
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid container component={Collapse} in={!sendNow || !editing}>
              <Grid container item alignItems={'center'} wrap="nowrap" sx={{ mt: 2 }}>
                <Typography noWrap sx={{ fontWeight: 500, mr: 3, fontSize: '1.1rem' }}>
                  Next review {withDueDate ? 'due' : 'sent'}:
                </Typography>
                {review?.state === 'cancelled' ? (
                  <Typography>Not scheduled</Typography>
                ) : (
                  <>
                    {editing ? (
                      <Grid item>
                        <DatePicker value={nextDate} onChange={setNextDate} label="Pick next date" />
                      </Grid>
                    ) : (
                      <>
                        <Typography>{FormatOnlyDate(nextDate)}</Typography>

                        {review && (
                          <>
                            <Tooltip title="Edit next due date">
                              <IconButton onClick={() => setDatePickerDialog(true)} sx={{ ml: 2 }}>
                                <EditRounded />
                              </IconButton>
                            </Tooltip>

                            <DatePickerDialog
                              reviewId={review._id}
                              open={datePickerDialog}
                              onClose={() => setDatePickerDialog(false)}
                              onResult={setNextDate}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            {editing && (
              <>
                <Grid container item alignItems={'center'} wrap="nowrap" sx={{ mt: 1 }}>
                  <FormControlLabel
                    label="Include due date"
                    control={<Checkbox checked={withDueDate} />}
                    onChange={(e) => setWithDueDate(e.target.checked)}
                  />
                </Grid>

                <Grid container component={Collapse} in={withDueDate}>
                  <Grid container sx={{ py: 1 }} alignItems="baseline">
                    <Typography>Send review</Typography>
                    <TextField
                      label="Days"
                      type="number"
                      value={sendBefore}
                      onChange={(e) => {
                        const value = e.target.value === '' ? '' : Number(e.target.value);
                        if (value !== '' && value < 1) return;
                        setSendBefore(value);
                      }}
                      sx={{ width: '50px', mx: 2 }}
                      variant="standard"
                    />
                    <Typography>before it's due</Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} wrap="nowrap" sx={{ mt: 1 }}>
                  <FormControlLabel
                    label="Send review now"
                    control={<Checkbox checked={sendNow} />}
                    onChange={(e) => setSendNow(e.target.checked)}
                  />
                </Grid>
              </>
            )}

            {!active && (
              <Grid container item alignItems={'center'} wrap="nowrap" sx={{ mt: 2 }}>
                <Typography variant="h6">Alternatively:</Typography>
              </Grid>
            )}
            <Grid container item alignItems={'center'} wrap="nowrap" sx={{ mt: 1 }}>
              <Button
                variant="contained"
                startIcon={sendingWitoutSchedule ? <CircularProgress size={24} /> : <SendRounded />}
                onClick={sendWithoutSchedule}
              >
                Start a review now
              </Button>
            </Grid>

            {(!editing || passive) && (
              <Grid container item alignItems={'center'} wrap="nowrap" sx={{ mt: 2 }}>
                {isEmpty(entries) ? (
                  <Typography color={'textSecondary'}>No reviews has been sent</Typography>
                ) : (
                  <Grid container>
                    <TableContainer style={{ borderTop: `2px solid ${grey[300]}`, margin: '1rem' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sent</TableCell>
                            <TableCell>Assigned</TableCell>
                            <TableCell>Date completed</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {entries.map((entry) => (
                            <TableRow
                              key={entry._id}
                              hover
                              onClick={() => {
                                setEntryDialog(true);
                                setSelectedEntryId(entry._id);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <TableCell>{FormatDate(entry.createdAt)}</TableCell>
                              <TableCell>
                                <UserGroup avatarSize={32} ids={entry.assigned} />
                              </TableCell>
                              <TableCell>{entry.completedAt ? FormatDate(entry.completedAt) : '-'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <ReviewTaskDialog
                      open={entryDialog}
                      onClose={() => setEntryDialog(false)}
                      reviewEntryId={selectedEntryId}
                      onComplete={updateEntry}
                      onDelete={deleteEntry}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        doneButtonIcon={review && !passive ? null : <ScheduleRounded />}
        doneButtonText={review && !passive ? 'save' : 'schedule'}
        saving={saving}
        hideDone={!editing || loading}
        additionalActions={
          review &&
          review.state === 'active' && (
            <>
              {editing ? (
                <Button
                  sx={{ color: red[500], mr: 'auto' }}
                  startIcon={<HighlightOffRounded />}
                  onClick={() => setEditMode(false)}
                >
                  cancel
                </Button>
              ) : (
                <Button
                  sx={{ color: grey[500], mr: 'auto' }}
                  startIcon={<EditRounded />}
                  onClick={() => setEditMode(true)}
                >
                  edit
                </Button>
              )}
            </>
          )
        }
      />
    </RoundedDialog>
  );
}

function DatePickerDialog({ open, onClose, onResult, reviewId }) {
  const [date, setDate] = useState(null);
  const [saving, setSaving] = useState(false);

  function done() {
    setSaving(true);

    axios
      .post('/reviews/management/changeScheduledDate', { reviewId, nextDue: date })
      .then(({ data }) => {
        onResult(data.nextDate);
        onClose();
        setSaving(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Pick the next review date</DialogTitle>
      <DialogContent sx={{ py: 2 }}>
        <DatePicker
          value={date}
          onChange={(date) => {
            if (moment(date) <= moment()) {
              setDate(moment().add(1, 'day').startOf('day').toDate());
            } else {
              setDate(date);
            }
          }}
        />
      </DialogContent>
      <StandardDialogActions onClose={onClose} saving={saving} hideDone={!date} onDone={done} />
    </RoundedDialog>
  );
}
