export const drawerOptions = {
  disableBeacon: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  placement: 'bottom',
  spotlightClicks: true,
  styles: {
    options: {
      zIndex: 10000,
    },
  },
};

export const CASES = {
  SETUPPROFILE: 'setupProfile',
  SETUPCOMPANY: 'setupCompany',
  SETUPGROUPS: 'setupGroups',
  CREATEGROUP: 'createGroup',
  SETUPUSERS: 'setupUsers',
  ADDUSER: 'addUser',
  DOCHUBFIRSTTOUR: 'dochubFirstTour',
  FORMHUBFIRSTTOUR: 'formhubFirstTour',
  WORKFLOWFIRSTTOUR: 'workflowFirstTour',
  DOCSETTING: 'documentSettings',
  CREATEAREA: 'createArea',
  INITTASK: 'initTaskTour',
  CREATETASK: 'createTask',
  SHOWPROJECT: 'showProject',
  INITPROJECT: 'initProject',
  CREATEPROJECT: 'createProject',
  CREATEDPROJECT: 'createdProject',
};
