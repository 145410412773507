import { RoundedDialog, StandardDialogActions, RichTextField } from '../../../Global/Components';
import { validateEmail } from '../../../Global/Functions';
import { DialogContent, DialogTitle, Grid, Typography, Tooltip } from '@mui/material';
import { TextField, IconButton, Button, List, ListItem, ListItemText } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails, Chip } from '@mui/material';
import { Table, TableContainer, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { MailOutlineRounded, DoneRounded, CloseRounded, ExpandMore, DescriptionRounded } from '@mui/icons-material';
import { styled } from '@mui/material';
import { red, green, grey } from '@mui/material/colors';
import { useState, useEffect } from 'react';
import axios from 'axios';
import StateManager from '../../../Global/StateManager';
import { isFunction } from 'lodash';

const ExcelCell = styled(TableCell)(() => ({
  border: `1px solid ${grey[300]}`,
}));

export default function SendExternalDialog({ surveyId, open, data, onClose, onResult }) {
  const [recipients, setRecipients] = useState([]);
  const [currRecipient, setCurrRecipient] = useState({});
  const [emailError, setEmailError] = useState('');
  const [templateData, setTemplateData] = useState({});
  const [excelDialog, setExcelDialog] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (data) {
      setRecipients(data.recipients);
      setTemplateData(data.templateData);
    }
  }, [data]);

  function handleDone() {
    setSending(true);

    // if there is email in the field - add it
    if (validateEmail(String(currRecipient.email).trim().toLowerCase())) {
      recipients.push({ ...currRecipient, id: Date.now() });
      setCurrRecipient({ fullName: '', email: '' });
    }

    axios
      .post('/forms/invitations/sendSurveyExternally', { surveyId, recipients, ...templateData })
      .then(({ data }) => {
        StateManager.setSuccessAlert(
          `Survey sent to ${data?.invitationIds?.length} ${data?.invitationIds?.length === 1 ? 'person' : 'people'}`,
        );
        isFunction(onResult) && onResult();
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setSending(false);
      });
  }

  function addRecipient() {
    let email = String(currRecipient.email).trim().toLowerCase();

    if (!email) return;

    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }

    if (recipients.some((recipient) => recipient.email === email)) {
      setCurrRecipient({ fullName: '', email: '' });
      return;
    }

    setRecipients([...recipients, { ...currRecipient, id: Date.now() }]);

    setCurrRecipient({ fullName: '', email: '' });
  }

  function handleExcel(result) {
    const emails = result[0].fieldType === 'email' ? result[0].values : null;
    const fullNames = result[1].fieldType === 'text' ? result[1].values : null;

    const newRecipients = emails
      .map((d, i) => ({
        fullName: fullNames[i],
        email: d,
      }))
      .filter((newRecipient) => !recipients.some((recipient) => recipient.email === newRecipient.email));

    if (newRecipients.length > 0) {
      setRecipients((prevRecipients) => [...prevRecipients, ...newRecipients]);
    }
  }

  return (
    <RoundedDialog open={open} className={'scroll-bar'}>
      <DialogTitle>Email survey</DialogTitle>
      <DialogContent>
        <Grid container sx={{ height: '100%' }}>
          <Grid container item>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }} gutterBottom>
              Add recipients:
            </Typography>
            <Grid container direction="column">
              <Grid container item direction="column">
                <TextField
                  placeholder="Enter Full Name"
                  variant="standard"
                  value={currRecipient.fullName}
                  style={{ flexGrow: 1 }}
                  onChange={(e) => {
                    let fullName = e.target.value;
                    if (!fullName) fullName = null;
                    setCurrRecipient({ ...currRecipient, fullName });
                  }}
                  id="survey-recipient-full-name"
                />
                <Grid item container>
                  <TextField
                    placeholder="Enter email and press 'Enter'"
                    required
                    helperText={emailError ? 'Enter valid email' : ''}
                    error={emailError}
                    sx={{ flexGrow: 2, mr: 1, mt: 1 }}
                    id="survey-recipient-email"
                    value={currRecipient.email}
                    onChange={(e) => {
                      setCurrRecipient({ ...currRecipient, email: e.target.value });
                      if (emailError) {
                        setEmailError(false);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        addRecipient();
                      }
                    }}
                    variant="standard"
                  />

                  <Tooltip title="Add" placement="top">
                    <IconButton onClick={addRecipient}>
                      <DoneRounded style={{ color: green[500] }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Clear" placement="top">
                    <IconButton
                      onClick={() => {
                        setCurrRecipient('');
                        setEmailError(false);
                      }}
                    >
                      <CloseRounded style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item position={'relative'} sx={{ marginY: 1 }}>
            <Accordion style={{ width: '100%', maxHeight: '350px', overflow: 'auto' }} className={'scroll-bar'}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>
                  Recipients <Chip label={recipients.length} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={'scroll-bar'}>
                <List style={{ width: '100%' }}>
                  {recipients.map((recipient, index) => (
                    <ListItem
                      key={index}
                      sx={{ maxHeight: 'fit-content' }}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          onClick={() => setRecipients(recipients.filter((r) => r.email !== recipient.email))}
                        >
                          <CloseRounded style={{ color: red[500] }} />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={`${recipient.fullName ? recipient.fullName : ' '}`}
                        secondary={recipient.email}
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid container item justifyContent={'column'} wrap="wrap" style={{ marginTop: '2rem' }}>
            <Grid container direction={'column'} justifyContent={'space-between'}>
              <TextField
                placeholder="Email Subject"
                required
                variant="outlined"
                value={templateData.subject || ''}
                fullWidth
                onChange={(e) => {
                  let subject = e.target.value;
                  subject = subject.replace(/[^a-zA-Z0-9 .,!? \-]+/g, '');
                  setTemplateData({ ...templateData, subject });
                }}
              />
            </Grid>
            <RichTextField
              editable
              initial={templateData.text}
              hideButton
              onChange={(text) => setTemplateData({ ...templateData, message: text })}
            />
          </Grid>
        </Grid>
        <UploadDialog open={excelDialog} onClose={() => setExcelDialog(false)} onResult={(res) => handleExcel(res)} />
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          <>
            <Button onClick={() => setExcelDialog(true)} variant="outlined" color="primary">
              Import from Excel
            </Button>
            <Grid mr={'auto'} />
          </>
        }
        onClose={onClose}
        onDone={handleDone}
        doneButtonText={'Send'}
        doneButtonIcon={<MailOutlineRounded />}
        saving={sending}
      />
    </RoundedDialog>
  );
}

function UploadDialog({ open, onClose, onResult }) {
  const [uploading, setUploading] = useState(false);

  function uploadFile(file) {
    const data = new FormData();
    data.append('file', file);
    setUploading(true);
    axios
      .post(`/excel/import`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        onResult(data.rawTable);
        onClose();
        setUploading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setUploading(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Upload recipients</DialogTitle>

      <DialogContent>
        <Grid container item sx={{ mb: 2 }}>
          <Typography>Please upload an excel file that includes survey recipients in the following format:</Typography>
        </Grid>
        <TableContainer>
          <Table size="small" style={{ borderCollapse: 'collapse' }}>
            <TableHead>
              <TableRow hover>
                <ExcelCell align="center" style={{ width: 30 }}></ExcelCell>
                <ExcelCell align="center" style={{ width: 250 }}>
                  A
                </ExcelCell>
                <ExcelCell align="center" style={{ width: 300 }}>
                  B
                </ExcelCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <ExcelCell align="center">1</ExcelCell>
                <ExcelCell>First column - Email(Header is required)</ExcelCell>
                <ExcelCell>Second column - Full Name (Header is required)</ExcelCell>
              </TableRow>
              <TableRow hover>
                <ExcelCell align="center">2</ExcelCell>
                <ExcelCell>test@q-hub.co.uk</ExcelCell>
                <ExcelCell>Test Testman</ExcelCell>
              </TableRow>
              <TableRow hover>
                <ExcelCell align="center">3</ExcelCell>
                <ExcelCell>john@q-hub.co.uk</ExcelCell>
                <ExcelCell>John Smith</ExcelCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container item sx={{ mt: 2 }}>
          <Button variant="contained" startIcon={<DescriptionRounded />} component="label">
            <input
              style={{ display: 'none' }}
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => {
                if (!e.target.files || e.target.files.length === 0) return;
                uploadFile(e.target.files[0]);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            Pick file
          </Button>
        </Grid>
      </DialogContent>

      <StandardDialogActions onClose={onClose} saving={uploading} />
    </RoundedDialog>
  );
}
