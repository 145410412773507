/**
 * * Reducer for General
 */

import axios from 'axios';

const SETSUPERGROUPS = 'SETSUPERGROUPS';
const SETSUPERGROUP = 'SETSUPERGROUP';

const initialState = {
  groups: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETSUPERGROUPS:
      return { ...state, groups: action.payload };
    case SETSUPERGROUP:
      return {
        ...state,
        groups: state.groups?.map((group) => (group._id === action.payload.id ? action.payload.update : group)),
      };
    default:
      return state;
  }
}

export const fetchSuperGroups = () => async (dispatch) => {
  try {
    const result = await axios.get('/supergroupadmin/super-groups');
    dispatch({ type: SETSUPERGROUPS, payload: result.data });
  } catch (err) {
    console.error(err);
  }
};

export const setSuperGroup = (id, update) => (dispatch) => dispatch({ type: SETSUPERGROUP, payload: { id, update } });
