import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import {
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@mui/material';
import { Paper } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import { FormIcon, ProcessIcon, RegistersIcon, TaskIcon } from '../../../Global/Icons';
import { grey } from '@mui/material/colors';
import axios from 'axios';
import StateManager from '../../../Global/StateManager';

export default function DeleteEntryDialog({ open, entry, onClose, onResult }) {
  const [choice, setChoice] = useState('');
  const [tree, setTree] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!open || !entry) return;

    setChoice('');
    setLoading(true);
    axios
      .get('/forms/entries/getEntryChildActions', { params: { entryId: entry._id } })
      .then(({ data }) => {
        setTree(data);

        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [open, entry]);

  function deleteEntry() {
    setSaving(true);
    axios
      .post('/forms/entries/deleteEntry', { entryId: entry._id, deleteChildren: choice === 'all' })
      .then(() => {
        StateManager.setSuccessAlert('Entry has been deleted');
        setSaving(false);
        onClose();
        onResult();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  const hasChildren = !isEmpty(tree);

  return (
    <RoundedDialog maxWidth={hasChildren ? 'md' : 'sm'} fullWidth open={open} onClose={onClose} className="scroll-bar">
      <DialogTitle>You are about to delete this entry</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems={'center'} justifyContent={'center'} style={{ height: 300 }}>
            <CircularProgress />
          </Grid>
        ) : hasChildren ? (
          <Grid container>
            <Grid container item>
              <Typography gutterBottom>
                This entry has the following linked action{tree.length === 1 ? '' : 's'}:
              </Typography>
            </Grid>

            <Grid container item>
              {tree.map((data) => (
                <ShortAction key={data._id} data={data} />
              ))}
            </Grid>

            <Grid container item sx={{ mt: 3 }}>
              <Typography>Do you want to delete them as well or just delete the entry?</Typography>
            </Grid>

            <Grid container item sx={{ mt: 1 }}>
              <RadioGroup value={choice} onChange={(e) => setChoice(e.target.value)}>
                <FormControlLabel value={'all'} label={'Delete all the above items'} control={<Radio />} />
                <FormControlLabel value={'entry'} label={'Delete only the entry'} control={<Radio />} />
              </RadioGroup>
            </Grid>
          </Grid>
        ) : (
          <Grid container item sx={{ mt: 2 }}>
            <Typography>Are you sure you want to continue?</Typography>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={deleteEntry}
        saving={saving}
        hideDone={loading || (hasChildren && !choice)}
      />
    </RoundedDialog>
  );
}

function ShortAction({ data }) {
  let content = null;

  if (data?.type === 'form') {
    content = (
      <>
        <FormIcon />
        <Typography sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}>
          {data.title} - entry #{data.number}
        </Typography>
      </>
    );
  }

  if (data?.type === 'process') {
    content = (
      <>
        <ProcessIcon />
        <Typography sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}>
          {data.title} - entry #{data.number}
        </Typography>
      </>
    );
  }

  if (data?.type === 'register') {
    content = (
      <>
        <RegistersIcon />
        <Typography sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}>
          {data.title} - row #{data.index}
        </Typography>
      </>
    );
  }

  if (data?.type === 'task') {
    content = (
      <>
        <TaskIcon />
        <Typography sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}>{data.title}</Typography>
      </>
    );
  }

  return (
    <Grid container>
      <Grid
        container
        item
        sx={{ my: 0.75, py: 1.5, px: 2, border: `1px solid ${grey[400]}`, borderRadius: 1.5 }}
        component={Paper}
        alignItems={'center'}
        wrap="nowrap"
      >
        {content}
      </Grid>
      {!isEmpty(data.childActions) && isArray(data.childActions) && (
        <Grid container sx={{ pl: 4 }}>
          {data.childActions.map((child) => (
            <ShortAction key={child._id} data={child} />
          ))}
        </Grid>
      )}
    </Grid>
  );
}
