import React, { useState } from 'react';
import { SnackbarContent } from 'notistack';
import { Grid, CircularProgress, Typography, Paper, Button } from '@mui/material';
import { UndoRounded, CloseRounded, FlashOnRounded, DoneRounded } from '@mui/icons-material';
import { green, grey, red } from '@mui/material/colors';
import { isFunction } from 'lodash';
import axios from 'axios';
import StateManager from '../StateManager';

const ActionSnack = React.forwardRef((props, ref) => {
  const [cancelling, setCancelling] = useState(false);

  const {
    id,
    message,
    // custom snackbar props
    executedActionId,
    actionTitle,
    closeSnackbar,
    ...other
  } = props;

  function undo() {
    setCancelling(true);
    axios
      .post('/automations/undoExecutedAction', { executedActionId })
      .then(() => {
        setCancelling(false);
        StateManager.reportUndoneExecutedAction(executedActionId);
        if (!isFunction(closeSnackbar)) return;
        closeSnackbar(id);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setCancelling(false);
      });
  }

  function close() {
    if (!isFunction(closeSnackbar)) return;
    closeSnackbar(id);
  }

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
        component={Paper}
        elevation={12}
        sx={{ p: 1, borderRadius: 2 }}
      >
        <FlashOnRounded sx={{ color: grey[500] }} />
        <Typography sx={{ fontWeight: 400, ml: 1.5 }}>
          <span style={{ fontWeight: 500 }}>{actionTitle}</span> has been executed
        </Typography>

        <Button
          onClick={undo}
          sx={{ ml: 2, color: red[500] }}
          startIcon={cancelling ? <CircularProgress size={24} /> : <UndoRounded />}
        >
          undo
        </Button>

        <Button onClick={close} startIcon={<DoneRounded />} sx={{ color: green[500], ml: 1 }}>
          got it
        </Button>
      </Grid>
    </SnackbarContent>
  );
});

export default ActionSnack;
