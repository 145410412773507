import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { ContainsHTMLTags } from '../Functions';

export default function HtmlText({ value, ...props }) {
  const renderAsTypography = useMemo(() => {
    return !ContainsHTMLTags(value);
  }, [value]);

  // if string does not contain html - render as typography
  if (renderAsTypography) {
    return (
      <Grid container alignItems="baseline" sx={{ p: 1 }} {...props}>
        <Typography style={{ whiteSpace: 'break-spaces' }}>{value}</Typography>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="baseline" sx={{ p: 1 }} {...props}>
      {value && (
        <Grid
          item
          sx={{
            '& *': {
              height: 'auto !important',
              width: 'auto !important',
              maxWidth: '100% !important',
              fontFamily: 'Roboto, sans-serif',
              //fontSize: '13pt',
              wordBreak: 'break-word',
            },
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </Grid>
      )}
    </Grid>
  );
}
