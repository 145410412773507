import React from 'react';
import { deleteTask, setTaskDialog } from '../../redux/actions/review';
import { useDispatch, useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import AlarmIcon from '@mui/icons-material/Alarm';

import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

const taskIcons = (progress) => {
  return progress === 'Not Started' ? (
    <CancelIcon sx={styles(progress)} />
  ) : progress === 'Complete' ? (
    <CheckCircleIcon sx={styles(progress)} />
  ) : progress === 'Held' ? (
    <BlockIcon sx={styles(progress)} />
  ) : progress === 'Overdue' ? (
    <AlarmIcon sx={styles(progress)} />
  ) : (
    <AutorenewIcon sx={styles(progress)} />
  );
};

const styles = (progress) => {
  return {
    color:
      progress === 'Working'
        ? '#0070c0'
        : progress === 'Complete'
        ? '#00b050'
        : progress === 'Held'
        ? '#fcc000'
        : progress === 'Overdue'
        ? '#FF0000'
        : 'black',
  };
};

export default function TaskInfo({ task, setTask, setEditTask, actions = true }) {
  const dispatch = useDispatch();
  const { users } = useSelector(({ settings }) => settings);
  const handleEdit = () => {
    setEditTask(true);
    setTask(task);
    dispatch(setTaskDialog(true));
  };
  const getUserName = (id) => {
    if (users.length > 0) {
      return users.find((a) => a._id === id) ? users.find((a) => a._id === id).fullName : 'N/A';
    } else {
      return 'N/A';
    }
  };
  return (
    <ListItem>
      <ListItemAvatar>{taskIcons(task?.progress)}</ListItemAvatar>
      <ListItemText primary={task?.title} secondary={task?.instructions} />
      <ListItemText primary={'Assigned To'} secondary={getUserName(task?.assigned_to)} />
      {actions && (
        <ListItemSecondaryAction>
          <IconButton size="small" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => dispatch(deleteTask(task?._id))}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
