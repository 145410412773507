import axios from 'axios';
import { BASE_URL } from '../constants';

const errorHandler = (error) => {
  if (error.response && error.response.status === 401) {
    const previousUser = localStorage.getItem('previousUser');
    const currentUser = localStorage.getItem('_id');
    console.error(error);
    // save the current path to redirect after login
    localStorage.setItem('lastVisitedPage', window.location.pathname);
    // Clear local storage only if the user is different
    if (previousUser !== currentUser) {
      localStorage.clear();
    }
    // Store the current user for future reference
    localStorage.setItem('previousUser', currentUser);
    if (!String(window.location.pathname).startsWith('/login')) {
      window.location.href = '/login';
    }
  }
  if (error.response && error.response.status === 403) {
    console.error(error);
    localStorage.clear();
    if (!String(window.location.pathname).startsWith('/blocked')) {
      window.location.href = '/blocked';
    }
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};

const configHandler = (config) => {
  config.baseURL = BASE_URL;
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

axios.defaults.baseURL = BASE_URL;

axios.interceptors.request.use(
  (config) => configHandler(config),
  (error) => errorHandler(error),
);

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export default axios;
