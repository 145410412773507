import { useState } from 'react';
import { Menu, IconButton, Grid, Tooltip, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PanoramaRounded, PaletteRounded, DeleteOutlineRounded } from '@mui/icons-material';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CircularProgress from '@mui/material/CircularProgress';
import { grey, red } from '@mui/material/colors';
import IconPicker from '../../../../Global/Components/IconPicker';
import TextFormatter from './TextFormatter';

export default function MenuList({
  image,
  uploadingImage,
  item,
  uploadFile,
  setSizeDialog,
  onChange,
  color,
  setColorPicker,
  icon,
  saveIcon,
  onDelete,
  fontSettings,
  saveFontSettings,
  mode,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          handleClick(e);
        }}
        onMouseDown={(e) => e.stopPropagation()}
        sx={props.sx}
      >
        <MoreVertIcon style={{ color: !image ? null : '#FFFFFF' }} />
      </IconButton>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        sx={{ zIndex: 50 }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {(mode === 'shortcut' || mode === 'action') && (
          <IconButton
            size="small"
            component="label"
            style={{
              width: 150,
              height: '100%',
              borderRadius: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 10,
            }}
            className="non-draggable"
          >
            <input
              accept="image/*"
              id={`${item.id}-image`}
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (!e.target.files || e.target.files.length === 0 || !e.target.files[0].type.startsWith('image/'))
                  return;
                uploadFile(e.target.files[0]);
              }}
            />

            {uploadingImage ? <CircularProgress size={18} /> : <PanoramaRounded size="small" />}
            <Typography>{image ? 'Change image' : 'Upload image'}</Typography>
          </IconButton>
        )}
        {image && (
          <IconButton
            size="small"
            component="label"
            style={{
              width: 150,
              height: '100%',
              borderRadius: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 10,
            }}
            className="non-draggable"
            onClick={() => setSizeDialog(true)}
          >
            <AspectRatioIcon />
            <Typography>{'Image size'}</Typography>
          </IconButton>
        )}
        {onChange && (
          <IconButton
            size="small"
            component="label"
            style={{
              width: 150,
              height: '100%',
              borderRadius: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 10,
            }}
            className="non-draggable"
            onClick={(e) => {
              setColorPicker(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <PaletteRounded style={{ color: color || grey[500] }} />
            <Typography>{'Change color'}</Typography>
          </IconButton>
        )}
        {onChange && (mode === 'shortcut' || mode === 'action') && (
          <IconPicker onSelect={saveIcon} icon={icon} size="big" />
        )}
        {saveFontSettings && (
          <TextFormatter onChange={onchange} initial={fontSettings} saveFont={saveFontSettings} size="big" />
        )}
        {onDelete && (
          <IconButton
            size="small"
            component="label"
            style={{
              minWidth: '100%',
              height: '100%',
              borderRadius: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 11,
            }}
            className="non-draggable"
            onClick={(e) => onDelete()}
          >
            <DeleteOutlineRounded fontSize="small" style={{ color: red[500] }} />
            <Typography>Delete</Typography>
          </IconButton>
        )}
      </Menu>
    </>
  );
}
