export const setupCompany = [
  {
    target: '.c-name',
    content: 'Enter your Company Name',
    disableBeacon: true,
  },
  {
    target: '.c-address',
    content: 'Enter your Company Address and Postal Code',
  },
  {
    target: '.company-picture',
    content: 'Upload your company logo',
  },
];
