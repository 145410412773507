import { useState, useEffect } from 'react';
import { Grid, CircularProgress, Typography, Button } from '@mui/material';
import axios from 'axios';
import StateManager from '../../../Global/StateManager';
import { AuditIcon } from '../../../Global/Icons';
import { Link } from 'react-router-dom';
import { blue } from '@mui/material/colors';

export default function AuditReference({ auditId, requirementId, onTaskClose }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!auditId) {
      setData(null);
      return;
    }

    setLoading(true);
    axios
      .get('/audits/general/getAuditBriefInfo', { params: { auditId, requirementId } })
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auditId, requirementId]);

  if (!loading && !data) return null;

  const url = data
    ? data.requirementId
      ? `/audits/${data.auditId}?requirementId=${data.requirementId}`
      : `/audits/${data.auditId}`
    : '#';

  return (
    <Grid container direction="column" sx={{ py: 1 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Link to={url} onClick={onTaskClose}>
            <Typography gutterBottom sx={{ color: blue[600], fontWeight: 500 }}>
              This task is from audit {data.auditTitle}
            </Typography>
          </Link>

          {data.requirementTitle && (
            <Link to={url} onClick={onTaskClose}>
              <Typography sx={{ color: blue[600], fontWeight: 500 }}>Requirement: {data.requirementTitle}</Typography>
            </Link>
          )}
        </>
      )}
    </Grid>
  );
}
