/**
 * * Actions for Revision
 */

import axios from '../../../../../redux/apiCaller';
import {
  successAction,
  errorAction,
  attemptAction,
  FETCHAPPROVALSBYACTIVITY_SUCCESS,
} from '../../../../../redux/constants';
import {
  DOCLIBRARY_ATTEMPT,
  DOCLIBRARY_ERROR,
  GETMINIREVISIONS,
  GETMINIREVISIONS_LOADER,
  GETMINIREVISIONS_ERROR,
  GETREVISIONFILES_ATTEMPT,
  GETREVISIONFILES_SUCCESS,
  GETREVISION,
  GETQREVISION_ATTEMPT,
  GETQREVISION_ERROR,
  GETQREVISION_SUCCESS,
  SETSELECTEDDOC,
  SETCURRENTAREA,
  LINK_ATTEMPT,
  LINK_ERROR,
  LINK_SUCCESS,
  UPDATEQREVISION,
  SETREMOVECOMMENT,
  SETREMOVECOMMENTFILE,
  UPDATEHF,
  GETREVISIONFILES_FAIL,
  SETANNOTATION_COUNT,
  ADDDOCUMENT_ATTEMPT,
  ADDDOCUMENT_FAIL,
  ADD_ISSUE,
  SETSUPPORTINGDOCS,
  REVISION_ATTEMPT,
  REVISION_ERROR,
  SETSECTIONS,
} from '../constants';
import StateManager from '../../../../Global/StateManager';

export const getMajorRevisions = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETREVISIONFILES_ATTEMPT));
      const body = await axios.get(`dochub/documents/${id}/history`);
      dispatch(successAction(GETREVISIONFILES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETREVISIONFILES_FAIL, err));
    }
  };
  return thunk;
};

export const getMiniRevisions = (id, version) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETMINIREVISIONS_LOADER));
      const body = await axios.get(`dochub/documents/${id}/history/${version || 0}`);
      dispatch(successAction(GETMINIREVISIONS, body.data));
    } catch (err) {
      dispatch(errorAction(GETMINIREVISIONS_ERROR, err));
    }
  };
  return thunk;
};

export const getRevision = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.get(`dochub/revisions/${id}`);
      dispatch(successAction(GETREVISION, body.data));
      if (body.data.issue && body.data.issue.supportingDocuments) {
        dispatch(successAction(SETSUPPORTINGDOCS, body.data.issue.supportingDocuments));
      }
    } catch (err) {
      StateManager.setAxiosErrorAlert(err);
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const getQRevision = (id, history = null) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETQREVISION_ATTEMPT));
      const body = await axios.get(`dochub/revisions/${id}`);
      if (body.status === 200) {
        if (body.data.issue && body.data.issue.document) {
          if (Boolean(body.data.issue.stage === 'Approval' && body.data.issue.status === 'published')) {
            dispatch(errorAction(GETQREVISION_ERROR, 'This revision is already published'));
            if (history) {
              history.push(`/DocLibrary/preview/${body.data.issue.document._id}`);
            }
          } else {
            let url = body.data?.url;
            if (body.data?.issue?.encrypted) {
              let token = localStorage.getItem('token');
              url = `${url}?user_token=${token}`;
            }
            dispatch(successAction(GETQREVISION_SUCCESS, { ...body.data, currentRevisionFile: url }));
            dispatch(successAction(SETSELECTEDDOC, body.data.issue.document));
            dispatch(successAction(SETCURRENTAREA, body.data.issue.area));
          }
        }
        if (body.data.issue && body.data.issue.supportingDocuments) {
          dispatch(successAction(SETSUPPORTINGDOCS, body.data.issue.supportingDocuments));
        }
      }
    } catch (err) {
      StateManager.setAxiosErrorAlert(err);
      dispatch(errorAction(GETQREVISION_ERROR, err));
    }
  };
  return thunk;
};

export const addIssue = (data, history) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDDOCUMENT_ATTEMPT));
      const body = await axios.post(`dochub/revisions`, data);
      dispatch(successAction(ADD_ISSUE, body.data));
      if (body.status === 200 && body.data.revision) {
        let url = body.data?.url;
        if (body.data?.revision?.encrypted && body.data?.revision?.category === 'normal') {
          let token = localStorage.getItem('token');
          url = `${url}?user_token=${token}`;
        }
        dispatch(
          successAction(UPDATEQREVISION, {
            issue: body.data.revision,
            newFile: Boolean(body.data.revision.category === 'normal'),
            currentRevisionFile: url,
          }),
        );
        history.push(`/editor/${body.data.revision.document}/upissue/${body.data.revision._id}`);
      }
    } catch (err) {
      dispatch(errorAction(ADDDOCUMENT_FAIL, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const setSections = (sections) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETSECTIONS, sections));
  };
  return thunk;
};

export const updateRevision = (id, data, newFile = false, hf = false) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}`, data);
      if (body.status === 200) {
        let url = body.data?.url;
        if (body.data.issue.category === 'normal') {
          if (body.data?.issue?.encrypted) {
            let token = localStorage.getItem('token');
            url = `${url}?user_token=${token}`;
          }
        }
        dispatch(
          successAction(UPDATEQREVISION, {
            issue: body.data.issue,
            newFile,
            currentRevisionFile: url,
          }),
        );
        if (body.data.issue.category === 'qhub' && hf) {
          dispatch(
            successAction(UPDATEHF, {
              headerConfig: body.data.issue.headerConfig,
              footerConfig: body.data.issue.footerConfig,
              defaultHeaderFooter: body.data.issue.defaultHeaderFooter,
            }),
          );
        }
        // StateManager.setSuccessAlert('Your changes have been saved');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const addSupportingDocs = (id, files) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(REVISION_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/supportingDocs`, { files });
      if (body.status === 200) {
        dispatch(successAction(SETSUPPORTINGDOCS, body.data.supportingDocuments));
        StateManager.setSuccessAlert('Supporting Documents Added Successfully');
      }
    } catch (err) {
      dispatch(errorAction(REVISION_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const setIssueDoc = (data, newFile = false) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(successAction(UPDATEQREVISION, { issue: data, newFile }));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const setRemoveComment = (comment) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(successAction(SETREMOVECOMMENT, { comment }));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const setRemoveCommentFile = (file) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(successAction(SETREMOVECOMMENTFILE, { file }));
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
    }
  };
  return thunk;
};

export const submitForReview = (id, data, history, openLink, onLoad) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/review`, data);
      if (body.status === 200) {
        dispatch(successAction(UPDATEQREVISION, { issue: body.data.issue, newFile: false }));
        StateManager.setSuccessAlert('You have submitted the document for review');
        if (onLoad) {
          onLoad();
        }
        if (body.data.issue && body.data.issue.document && body.data.issue.document._id && openLink) {
          history.push(`/DocLibrary/preview/${body.data.issue.document._id}`);
        }
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
      if (onLoad) {
        onLoad();
      }
    }
  };
  return thunk;
};

export const changeEditor = (id, data, history, openLink, setSaving) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/changeEditor`, data);
      if (body.status === 200) {
        setSaving(false);
        dispatch(successAction(UPDATEQREVISION, { issue: body.data.issue, newFile: false }));
        StateManager.setSuccessAlert('You have successfully changed the editor for this document');
        if (body.data.issue && body.data.issue.document && body.data.issue.document._id && openLink) {
          history.push(`/DocLibrary/preview/${body.data.issue.document._id}`);
        }
      }
    } catch (err) {
      setSaving(false);
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const addReviewers = (id, data, history, openLink, setSaving) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/review/add`, data);
      if (body.status === 200) {
        setSaving(false);
        dispatch(successAction(UPDATEQREVISION, { issue: body.data.issue, newFile: false }));
        StateManager.setSuccessAlert(
          `You have successfully added ${data?.users?.length} new reviewer${
            data?.users?.length > 1 ? 's' : ''
          } to this document`,
        );
        if (body.data.issue && body.data.issue.document && body.data.issue.document._id && openLink) {
          history.push(`/DocLibrary/preview/${body.data.issue.document._id}`);
        }
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const removeReviewer = (id, data, setDeleteId) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/review/remove`, data);
      if (body.status === 200) {
        setDeleteId(null);
        dispatch(successAction(UPDATEQREVISION, { issue: body.data.issue, newFile: false }));
        StateManager.setSuccessAlert('You have successfully removed the reviewer from this document');
        // if (body.data.issue && body.data.issue.document && body.data.issue.document._id && openLink) {
        //   history.push(`/DocLibrary/preview/${body.data.issue.document._id}`);
        // }
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const confirmReview = (id, data, history, openLink) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/review/confirm`, data);
      if (body.status === 200) {
        if (body.data.approvals) {
          dispatch(successAction(FETCHAPPROVALSBYACTIVITY_SUCCESS, body.data.approvals));
        }
        dispatch(successAction(UPDATEQREVISION, { issue: body.data.issue }));
        if (openLink) {
          history.push(`/DocLibrary/preview/${body.data.issue.document._id}`);
        }
        StateManager.setSuccessAlert('You have confirmed the document ready for approval');
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const rejectForEditing = (id, data, history) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/review/reject`, data);
      if (body.status === 200) {
        dispatch(successAction(UPDATEQREVISION, { issue: body.data.issue }));
        StateManager.setSuccessAlert('You have rejected the document back for editing');
        if (body.data.redirect) {
          history.push(`/DocLibrary/preview/${body.data.issue.document}`);
        }
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const cancelReviews = (id, history) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DOCLIBRARY_ATTEMPT));
      const body = await axios.post(`dochub/revisions/${id}/review/cancel`);
      if (body.status === 200) {
        dispatch(successAction(UPDATEQREVISION, { issue: body.data.issue }));
        StateManager.setSuccessAlert('Review Cancelled successfully');
        if (body.data.redirect) {
          history.push(`/DocLibrary/preview/${body.data.issue.document}`);
        }
      }
    } catch (err) {
      dispatch(errorAction(DOCLIBRARY_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const getLinkDocument = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(LINK_ATTEMPT));
      const body = await axios.post(`dochub/linked`, { id });
      if (body.status === 200 && body.data.status === 'Ok') {
        dispatch(successAction(LINK_SUCCESS, body.data.document));
      } else {
        dispatch(errorAction(LINK_ERROR, { message: body.data.message }));
      }
    } catch (err) {
      dispatch(errorAction(LINK_ERROR, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const setAnnotationCount = (count) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETANNOTATION_COUNT, count));
  };
  return thunk;
};
