import React, { useState, useEffect, useRef } from 'react';
import { Grid, CircularProgress, Typography, TextField } from '@mui/material';
import { LocalOfferRounded } from '@mui/icons-material';
import { blue, green } from '@mui/material/colors';
import StateManager from '../../../Global/StateManager';
import { VisualiseSearch } from '../../../Global/Functions';
import Autocomplete from '@mui/material/Autocomplete';
import MiniField from '../../../Global/Fields/MiniField';
import { isArray, isEmpty } from 'lodash';
import axios from 'axios';

export default function AssetsSearch({ rounded, onResult, value, searchInAssetData }) {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pattern, setPattern] = useState('');
  const timer = useRef(null);

  useEffect(() => {
    if (!pattern) {
      setAssets([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    timer.current = setTimeout(() => search(pattern), 800);
  }, [pattern]); // eslint-disable-line

  function search(pattern) {
    if (!pattern) {
      setAssets([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    axios
      .get('/asset/search/searchAssets', { params: { pattern, searchInAssetData } })
      .then(({ data }) => {
        setLoading(false);
        setAssets(data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }

  return (
    <Autocomplete
      options={assets}
      filterOptions={(x) => x}
      loading={loading}
      inputValue={pattern}
      value={value}
      onInputChange={(event, newInputValue) => {
        setPattern(newInputValue);
      }}
      fullWidth
      noOptionsText={!pattern ? 'Type to search' : loading ? 'Loading..' : 'Nothing found'}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      openOnFocus
      renderOption={(props, asset) => (
        <li {...props} key={asset._id}>
          <Grid container>
            <Grid container alignItems="center">
              <LocalOfferRounded style={{ color: green[500], marginRight: '1rem' }} />
              <Typography>
                {asset.prefix ? (
                  <span style={{ color: blue[500] }}>
                    {asset.prefix}-{asset.number}
                    {'   '}
                  </span>
                ) : (
                  <span style={{ color: blue[500] }}>
                    {asset.number}
                    {'   '}
                  </span>
                )}
                {VisualiseSearch(asset.title, pattern)}
              </Typography>
            </Grid>
            {!isEmpty(asset.fields) && isArray(asset.fields) && (
              <Grid container item alignItems={'center'}>
                {asset.fields.map((field) => (
                  <Grid item key={field.id}>
                    <MiniField emptyAsNull field={field} initial={field.filledValue} searchPattern={pattern} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ '& .MuiOutlinedInput-root': { borderRadius: rounded ? 5 : 1 } }}
          variant="outlined"
          label="Search assets"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(e, asset) => {
        onResult(asset);
      }}
    />
  );
}
