import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import clsx from 'clsx';
import { getDecryptedFile } from '../../../redux/actions/file';
import { CircularProgress } from '@mui/material';

export const ImageComponent = React.forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function Image(props, ref) {
    const { block, className, theme = {}, ...otherProps } = props;
    const [url, setUrl] = useState(null);
    // leveraging destructuring to omit certain properties from props
    const {
      blockProps,
      customStyleMap,
      customStyleFn,
      decorator,
      forceSelection,
      offsetKey,
      selection,
      tree,
      blockStyleFn,
      preventScroll,
      contentState,
      ...elementProps
    } = otherProps;
    const combinedClassName = clsx(theme.image, className);
    const { src, fileKey, fileId, encrypted, fileName } = contentState.getEntity(block.getEntityAt(0)).getData();

    const fileLoad = useCallback(async () => {
      const decrypted = await getDecryptedFile({
        encrypted,
        file: {
          id: fileId,
          location: src,
          originalname: fileName,
          key: fileKey,
        },
      });
      setUrl(decrypted);
    }, [src, fileName, fileId, fileKey, encrypted]);

    useEffect(() => {
      fileLoad();
    }, [fileLoad]);

    if (!url) {
      return <CircularProgress sx={{ ml: '70px', my: '5px' }} />;
    }

    return <img {...elementProps} ref={ref} src={url} role="presentation" className={combinedClassName} />;
  },
);

export const onAddImage = (editorState, url, extraData) => {
  const urlType = 'IMAGE';
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(urlType, 'IMMUTABLE', { ...extraData, src: url });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
  return EditorState.forceSelection(newEditorState, newEditorState.getCurrentContent().getSelectionAfter());
};
