import { isEmpty, isObject } from 'lodash';
import { VFORMAT, NFORMAT, AFORMAT } from '../constants';

export const range = (from, to, step) => [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);

export const getVersion = (version, revisionFormat) => {
  switch (revisionFormat) {
    case VFORMAT:
      return `V${version}`;
    case NFORMAT:
      return version;
    case AFORMAT:
      return String.fromCharCode(64 + version);
    default:
      return version;
  }
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const getSignatureName = (t) => {
  if (isEmpty(t?.signature)) return '';
  const jsonData = t?.signature?.jsonData;
  if (isObject(jsonData)) {
    if (jsonData?.firstName && jsonData?.lastName) {
      return `by ${jsonData?.firstName} ${jsonData?.lastName}`;
    } else if (jsonData?.firstName) {
      return `by ${jsonData?.firstName}`;
    } else if (jsonData?.lastName) {
      return `by ${jsonData?.lastName}`;
    } else {
      return '';
    }
  } else {
    return '';
  }
};
