import React, { useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { setEditorState } from '../../../redux/actions';
import { ensureSafeUrl } from './url';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { COLORS } from '../../../constants';
import Box from '@mui/material/Box';

//Add Link Component
export const Link = ({ entityKey, contentState, children }) => {
  let { url, targetOption } = contentState.getEntity(entityKey).getData();
  const [popOver, setPopOver] = useState(false);

  const openLink = () => {
    const linkTab = window.open(ensureSafeUrl(url), targetOption); // eslint-disable-line no-undef
    // linkTab can be null when the window failed to open.
    if (linkTab) {
      linkTab.focus();
    }
  };

  return (
    <Box
      component="span"
      sx={{ display: 'flex', alignItems: 'center' }}
      onMouseEnter={() => setPopOver(true)}
      onMouseLeave={() => setPopOver(false)}
    >
      <Box
        component="a"
        href={ensureSafeUrl(url)}
        target={targetOption}
        sx={{ color: COLORS.SEARCH, marginRight: '2px', cursor: popOver ? 'pointer' : 'text', fontWeight: 500 }}
        onClick={openLink}
      >
        {children}
      </Box>
      {popOver && <OpenInNewIcon onClick={openLink} fontSize="small" sx={{ height: 18, width: 18 }} />}
    </Box>
  );
};

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

export const linkDecorator = {
  strategy: findLinkEntities,
  component: Link,
};

// call all together
export const onAddLink = (linkData, editorState, dispatch) => {
  const { link, target } = linkData;
  let selection = editorState.getSelection();
  const data = {
    url: link,
    targetOption: target,
  };
  let currentContent = editorState.getCurrentContent();
  const contentStateWithEntity = currentContent.createEntity('LINK', 'MUTABLE', data);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);

  let newEditorState = EditorState.push(editorState, contentStateWithLink, 'apply-entity');
  selection = newEditorState.getSelection();
  newEditorState = EditorState.acceptSelection(newEditorState, selection);
  const newState = EditorState.forceSelection(newEditorState, editorState.getSelection());
  dispatch(setEditorState(newState));
};
