/**
 * * Actions for File Previewer
 */

import {
  successAction,
  attemptAction,
  errorAction,
  SELECTFILE,
  TOGGLEASSISTANT,
  FETCHAPPROVALSBYACTIVITY_ATTEMPT,
  FETCHAPPROVALSBYACTIVITY_SUCCESS,
  FETCHAPPROVALSBYACTIVITY_FAIL,
  ADDAPPROVALS_ATTEMPT,
  ADDAPPROVALS_SUCCESS,
  ADDAPPROVALS_FAIL,
  SETAPPROVAL,
  UPDATEMETAINACTIVITY_ATTEMPT,
  UPDATEMETAINACTIVITY_SUCCESS,
  UPDATEMETAINACTIVITY_FAIL,
  REMOVEAPPROVAL_ATTEMPT,
  REMOVEAPPROVAL_SUCCESS,
  REMOVEAPPROVAL_FAIL,
  SETAPPROVALALERT,
  FETCHCOMPANYGROUPS_ATTEMPT,
  FETCHCOMPANYGROUPS_SUCCESS,
  FETCHCOMPANYGROUP_ATTEMPT,
  FETCHCOMPANYGROUP_SUCCESS,
  FETCHCOMPANYADMINS_SUCCESS,
  GENERAL_ATTEMPT,
  GENERAL_ERROR,
  FETCHCOMPANYGROUPS_FAIL,
  SETDRAWERTOUR,
  SETGUIDEDTOUR,
  TOGGLEDRAWER,
  SEARCHATTEMPT,
  SEARCHSUCCESS,
  SEARCHFAIL,
  SELECTHUB,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../constants';
import { v4 } from 'uuid';

import axios from '../apiCaller';

export const selectFile = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SELECTFILE, data));
  };
  return thunk;
};

export const toggleAssistant = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(TOGGLEASSISTANT, { assistant: data }));
  };
  return thunk;
};

export const toggleDrawer = (drawer) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(TOGGLEDRAWER, { drawer }));
  };
  return thunk;
};

export const setDrawerTour = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETDRAWERTOUR, data));
  };
  return thunk;
};

export const setGuidedTour = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETGUIDEDTOUR, data));
  };
  return thunk;
};

export const setApproval = (approvalIndex, metaIndex, data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETAPPROVAL, { approvalIndex, metaIndex, data }));
  };
  return thunk;
};

export const setApprovalAlert = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETAPPROVALALERT, data));
  };
  return thunk;
};

export const fetchApprovalsByActivity = (activityId) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHAPPROVALSBYACTIVITY_ATTEMPT));
      const body = await axios.get(`/standardApproval/getApprovalByActivityId?activityId=${activityId}`);
      dispatch(successAction(FETCHAPPROVALSBYACTIVITY_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(FETCHAPPROVALSBYACTIVITY_FAIL, err));
    }
  };
  return thunk;
};

export const addApprovals = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDAPPROVALS_ATTEMPT));
      const body = await axios.post(`/approvals`, data);
      dispatch(successAction(ADDAPPROVALS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(ADDAPPROVALS_FAIL, err));
    }
  };
  return thunk;
};

export const updateMeta = (activityId, metaId, update) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEMETAINACTIVITY_ATTEMPT));
      const body = await axios.post(`/approvals/${activityId}/${metaId}`, update);
      dispatch(successAction(UPDATEMETAINACTIVITY_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEMETAINACTIVITY_FAIL, err));
    }
  };
  return thunk;
};

export const deleteApproval = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(REMOVEAPPROVAL_ATTEMPT));
      const body = await axios.delete(`/approvals/${id}`);
      dispatch(successAction(REMOVEAPPROVAL_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(REMOVEAPPROVAL_FAIL, err));
    }
  };
  return thunk;
};

export const fetchCompanyGroups = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHCOMPANYGROUPS_ATTEMPT));
      const body = await axios.get('/general/groups');
      dispatch(successAction(FETCHCOMPANYGROUPS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(FETCHCOMPANYGROUPS_FAIL, err));
    }
  };
  return thunk;
};

export const fetchCompanyGroup = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHCOMPANYGROUP_ATTEMPT));
      const body = await axios.get(`/general/groups/${id}`);
      dispatch(successAction(FETCHCOMPANYGROUP_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GENERAL_ERROR, err));
    }
  };
  return thunk;
};

export const fetchCompanyAdmins = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GENERAL_ATTEMPT));
      const body = await axios.get(`/user/getAdmins`);
      dispatch(successAction(FETCHCOMPANYADMINS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GENERAL_ERROR, err));
    }
  };
  return thunk;
};

export const search = (q, hub = null) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SEARCHATTEMPT));
      const body = hub
        ? await axios.get(`/search/${hub}`, { params: { q } })
        : await axios.get('/search', { params: { q } });
      dispatch(successAction(SEARCHSUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SEARCHFAIL, err));
    }
  };
  return thunk;
};

export const selectHub = (hub) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SELECTHUB, hub));
  };
  return thunk;
};

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || v4(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});
