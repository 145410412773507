import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const GeneralButton = styled(Button)(({ theme }) => ({
  // border: theme.palette.mode === 'dark' ? `1px solid ${grey[200]}` : `2px solid ${grey[300]}`,
  textTransform: 'none',

  //padding: '6px 8px',
  color: theme.palette.text.secondary,
}));

export default GeneralButton;
