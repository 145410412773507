import { DialogContent, DialogTitle, Grid, Typography, CircularProgress, TextField } from '@mui/material';
import { InputAdornment, IconButton, ListItemIcon, ListItemButton, ListItemText } from '@mui/material';
import { SearchRounded, ClearRounded } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { TaskIcon } from '../../../Global/Icons';
import axios from 'axios';

export default function TaskTemplatesDialog({ open, onClose, onResult }) {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pattern, setPattern] = useState('');
  const loadTemplates = open && templates.length === 0;
  const filteredTemplates = pattern
    ? templates.filter((t) => String(t.title).search(new RegExp(pattern, 'i')) > -1)
    : templates;

  useEffect(() => {
    if (!loadTemplates) return;
    setLoading(true);
    axios
      .get('/tasks/templates/getTaskTemplates')
      .then((res) => {
        setTemplates(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [loadTemplates]);

  useEffect(() => {
    if (!open) return;
    setPattern('');
  }, [open]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Select a template</DialogTitle>

      <DialogContent>
        {loading && (
          <Grid container>
            <Grid container justifyContent="center" alignItems="center" style={{ height: 200 }}>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        )}
        {!loading && templates.length === 0 && (
          <Grid container justifyContent="center" alignItems="center" style={{ height: 200 }}>
            <Typography variant="h6" color="textSecondary">
              No templates available <span style={{ fontSize: '2rem' }}> 🤷‍♂️</span>
            </Typography>
          </Grid>
        )}
        {!loading && templates.length > 0 && (
          <Grid container sx={{ pt: 1 }}>
            <Grid container item>
              {templates.length > 2 && (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRounded />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {pattern && (
                          <IconButton onClick={() => setPattern('')} onMouseDown={(e) => e.preventDefault()}>
                            <ClearRounded />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  value={pattern}
                  fullWidth
                  variant="outlined"
                  label="Search for a template"
                  onChange={(e) => setPattern(String(e.target.value).toLowerCase())}
                />
              )}
            </Grid>
            <Grid
              container
              item
              style={{ marginTop: '1rem', height: '40vh', overflow: 'auto' }}
              alignContent="flex-start"
            >
              {filteredTemplates.map((template) => (
                <ListItemButton
                  key={template._id}
                  style={{ width: '100%', borderRadius: 5 }}
                  onClick={() => {
                    onResult(template);
                    onClose();
                  }}
                >
                  <ListItemIcon>
                    <TaskIcon />
                  </ListItemIcon>
                  <ListItemText primary={template.title} />
                </ListItemButton>
              ))}
              {!loading && filteredTemplates.length === 0 && templates.length > 0 && (
                <Grid container justifyContent="center" alignItems="center" style={{ height: 200 }}>
                  <Typography variant="h6" color="textSecondary">
                    No templates match your search <span style={{ fontSize: '2rem' }}> 🤷‍♂️</span>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} closeButtonText="CANCEL" />
    </RoundedDialog>
  );
}
