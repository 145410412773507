import { red, grey } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, TextField, Menu, Typography, useTheme } from '@mui/material';
import { IconButton, MenuItem, ListItemIcon, ListItemText, Button, Chip, Paper, Tooltip } from '@mui/material';
import { Switch, FormControlLabel, Collapse } from '@mui/material';
import { DeleteOutlineRounded, AddCircleOutlineRounded, FlashOnRounded, FiberManualRecord } from '@mui/icons-material';
import { ArrowDropDownRounded, ClearRounded } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions, UserGroup, NumberRangePicker } from '../Components';
import { v4 } from 'uuid';
import StateManager from '../StateManager';
import { isEmpty, isArray, isObject } from 'lodash';

export default function ActionsDialog({
  initialActions,
  initialConditions,
  options,
  open,
  allColumns,
  onClose,
  onResult,
}) {
  const theme = useTheme();
  const [actions, setActions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const selectedStatusValue =
    selectedStatus && Array.isArray(options) ? options.find((x) => x.id === selectedStatus) : null;
  const numberColumns = isArray(allColumns)
    ? allColumns.filter((x) => ['number', 'calculation', 'weightedList'].includes(x.fieldType))
    : [];
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserOption, setSelectedUserOption] = useState('');
  const [selectedNotifyOption, setSelectedNotifyOption] = useState('');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [setAutomatically, setSetAutomatically] = useState(false);
  const [statusConditions, setStatusConditions] = useState({});

  const userOptions = [
    { value: 'chosen', text: 'Pick users' },
    { value: 'started', text: 'User who started the entry' },
    { value: 'row', text: 'Users in the row' },
  ];
  const notificationOptions = [
    { value: 'notificaton', text: 'Notification' },
    { value: 'email', text: 'Email' },
    { value: 'both', text: 'Notification & Email' },
  ];

  useEffect(() => {
    if (!open) return;

    setActions(Array.isArray(initialActions) ? initialActions : []);
    setSelectedStatus('');
    setSelectedUserOption('');
    setSelectedNotifyOption('');
    setSelectedUserIds([]);
    setSetAutomatically(isObject(initialConditions) && !isEmpty(initialConditions));
    setStatusConditions(isObject(initialConditions) && !isEmpty(initialConditions) ? initialConditions : {});
  }, [open]); // eslint-disable-line

  function done() {
    if (
      selectedStatus &&
      selectedUserOption &&
      selectedNotifyOption &&
      (selectedUserOption !== 'chosen' || !isEmpty(selectedUserIds))
    ) {
      const action = {
        id: v4(),
        userOption: selectedUserOption,
        notifyOption: selectedNotifyOption,
        status: selectedStatus,
        userIds: selectedUserOption === 'chosen' ? selectedUserIds : undefined,
      };
      actions.push(action);
      setSelectedStatus('');
      setSelectedUserOption('');
      setSelectedNotifyOption('');
      setSelectedUserIds([]);
    }
    const result = [];
    // filter out invalid actions
    for (let i = 0; i < actions.length; ++i) {
      const action = actions[i];
      const userOption = userOptions.find((x) => x.value === action.userOption);
      const notifyOption = notificationOptions.find((x) => x.value === action.notifyOption);
      const status = action.status && Array.isArray(options) ? options.find((x) => x.id === action.status) : null;
      if (!userOption || !notifyOption || !status) continue;
      result.push(action);
    }
    // and status conditions
    if (isArray(options) && !isEmpty(statusConditions)) {
      const keys = Object.keys(statusConditions);
      for (let i = 0; i < keys.length; ++i) {
        if (!options.some((o) => o.id === keys[i])) {
          delete statusConditions[keys[i]];
        }
      }
    }
    onResult({
      actions,
      statusConditions: isEmpty(statusConditions) || !setAutomatically ? null : statusConditions,
      setAutomatically,
    });
    onClose();
  }

  function addAction() {
    if (selectedUserOption === 'chosen' && !selectedUserIds[0]) return;
    const action = {
      id: v4(),
      userOption: selectedUserOption,
      notifyOption: selectedNotifyOption,
      status: selectedStatus,
      userIds: selectedUserOption === 'chosen' ? selectedUserIds : undefined,
    };
    setActions([...actions, action]);
    setSelectedStatus('');
    setSelectedUserOption('');
    setSelectedNotifyOption('');
    setSelectedUserIds([]);
  }

  function deleteAction(id) {
    setActions(actions.filter((x) => x.id !== id));
  }

  function selectUsers(initiallySelected = []) {
    StateManager.selectMultipleUsers((res) => setSelectedUserIds(res.users || []), { initiallySelected });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Actions</DialogTitle>
      <DialogContent>
        <Grid style={{ width: 'min-content' }}>
          <Grid container>
            {actions.map((action) => {
              const userOption = userOptions.find((x) => x.value === action.userOption);
              const notifyOption = notificationOptions.find((x) => x.value === action.notifyOption);
              const status =
                action.status && Array.isArray(options) ? options.find((x) => x.id === action.status) : null;
              if (!userOption || !notifyOption || !status) return null;
              const textColor = theme.palette.getContrastText(status.color);
              return (
                <Grid container key={action.id} style={{ minWidth: 'max-content' }}>
                  <Paper
                    style={{
                      borderRadius: theme.spacing(1),
                      padding: theme.spacing(1, 2),
                      margin: theme.spacing(1, 0),
                      width: '100%',
                    }}
                    elevation={3}
                  >
                    <Grid container alignItems="center" wrap="nowrap">
                      <FlashOnRounded style={{ color: grey[500], marginRight: '1rem' }} />
                      {userOption.value === 'chosen' && action.userIds && <UserGroup ids={action.userIds} />}
                      {userOption.value !== 'chosen' && <Typography>{userOption.text}</Typography>}
                      <Typography noWrap style={{ marginLeft: '0.25em', textTransform: 'lowercase' }}>
                        will be sent {notifyOption.text} when status is
                      </Typography>
                      <Chip
                        label={status.text}
                        style={{ color: textColor, background: status.color, margin: theme.spacing(0, 1) }}
                      />

                      <IconButton style={{ marginLeft: 'auto' }} onClick={() => deleteAction(action.id)}>
                        <DeleteOutlineRounded style={{ color: red[500] }} />
                      </IconButton>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
          {(!Array.isArray(options) || !options[0]) && (
            <Typography variant="h6" color="textSecondary" noWrap>
              Please set statuses before defining actions
            </Typography>
          )}
          {Array.isArray(options) && options[0] && (
            <Grid
              container
              style={{
                marginTop: theme.spacing(2),
                padding: theme.spacing(2),
                borderRadius: theme.spacing(1),
              }}
              component={Paper}
              variant="outlined"
            >
              <Grid container item alignItems="center" wrap="nowrap">
                <Typography noWrap>When status changed to</Typography>

                <Button
                  variant={selectedStatusValue ? 'text' : 'outlined'}
                  style={{
                    textTransform: 'none',
                    minWidth: 220,
                    maxWidth: 220,
                    background: selectedStatusValue ? selectedStatusValue.color : undefined,
                    borderRadius: 8,
                    marginLeft: 16,
                  }}
                  onClick={(e) => setMenuAnchor(e.currentTarget)}
                  endIcon={<ArrowDropDownRounded style={{ color: selectedStatusValue ? 'white' : grey[500] }} />}
                  fullWidth
                >
                  <Typography noWrap style={{ fontWeight: 500, color: selectedStatusValue ? 'white' : undefined }}>
                    {selectedStatusValue ? selectedStatusValue.text : 'Select status'}
                  </Typography>
                </Button>

                <Menu
                  PaperProps={{ style: { borderRadius: 8, minWidth: 220 } }}
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={() => setMenuAnchor(null)}
                >
                  {Array.isArray(options) &&
                    options.map((value) => (
                      <MenuItem
                        key={value.id}
                        onClick={() => {
                          setSelectedStatus(value.id);
                          setMenuAnchor(null);
                        }}
                      >
                        <ListItemIcon>
                          <FiberManualRecord style={{ color: value.color }} />
                        </ListItemIcon>
                        <ListItemText primary={value.text} />
                      </MenuItem>
                    ))}
                </Menu>
              </Grid>
              <Grid container item alignItems="center" wrap="nowrap" style={{ marginTop: theme.spacing(2) }}>
                <Typography noWrap>send a</Typography>
                <TextField
                  style={{ width: 180, margin: '0 1rem' }}
                  select
                  value={selectedNotifyOption}
                  onChange={(e) => setSelectedNotifyOption(e.target.value)}
                  variant="standard"
                >
                  {notificationOptions.map((value) => (
                    <MenuItem key={value.value} value={value.value}>
                      {value.text}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography style={{ marginRight: '1rem' }}>to</Typography>
                {selectedUserIds[0] && (
                  <Grid container item alignItems="center" style={{ width: 240 }}>
                    <UserGroup ids={selectedUserIds} onClick={() => selectUsers(selectedUserIds)} />
                    <Tooltip title="Clear users" placement="top">
                      <IconButton
                        size="small"
                        style={{ marginLeft: 4 }}
                        onClick={() => {
                          setSelectedUserOption('');
                          setSelectedUserIds([]);
                        }}
                      >
                        <ClearRounded fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                {!selectedUserIds[0] && (
                  <TextField
                    style={{ width: 240 }}
                    select
                    value={selectedUserOption}
                    onChange={(e) => {
                      setSelectedUserOption(e.target.value);
                      if (e.target.value === 'chosen') {
                        selectUsers();
                      }
                    }}
                    variant="standard"
                  >
                    {userOptions.map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {value.text}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                <div style={{ width: theme.spacing(4), height: theme.spacing(2) }} />
                <Button
                  startIcon={<AddCircleOutlineRounded />}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 'auto', borderRadius: 8 }}
                  onClick={addAction}
                  disabled={
                    !selectedStatus ||
                    !selectedUserOption ||
                    !selectedNotifyOption ||
                    (selectedUserOption === 'chosen' && !selectedUserIds[0])
                  }
                >
                  add
                </Button>
              </Grid>
            </Grid>
          )}

          {!isEmpty(numberColumns) && isArray(options) && !isEmpty(options) && (
            <Grid container style={{ padding: theme.spacing(1.5, 0) }}>
              <Grid container item style={{ padding: theme.spacing(1.5, 0) }}>
                <FormControlLabel
                  label="Set statuses automatically based on other column's value"
                  control={<Switch color="primary" checked={setAutomatically} />}
                  onChange={(e) => setSetAutomatically(e.target.checked)}
                />
              </Grid>
              <Grid container item>
                <Collapse in={setAutomatically} style={{ width: '100%' }}>
                  {options.map((option) => (
                    <Grid
                      key={option.id}
                      container
                      item
                      alignItems="flex-end"
                      wrap="nowrap"
                      style={{ padding: theme.spacing(1.5, 0) }}
                    >
                      <FiberManualRecord style={{ color: option.color }} />

                      <Typography style={{ marginLeft: 12, marginRight: 12 }}>{option.text}:</Typography>

                      <Grid container item style={{ width: 'auto', flexGrow: 1 }}>
                        <NumberRangePicker
                          columns={numberColumns}
                          initial={statusConditions[option.id]}
                          onChange={(value) => setStatusConditions({ ...statusConditions, [option.id]: value })}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Collapse>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <StandardDialogActions
        closeButtonText={'Cancel'}
        onClose={onClose}
        onDone={done}
        hideDone={isEmpty(actions) && isEmpty(statusConditions)}
      />
    </RoundedDialog>
  );
}
