import React from 'react';
import { useHistory } from 'react-router-dom';
import guides from './guides';
import { lazy, Suspense } from 'react';

const Joyride = lazy(() => import('react-joyride'));
import { STATUS, ACTIONS } from 'react-joyride';

const Tours = ({ id, onTourEnd, onTourStart, redirectLink, sendIndex, setState = null, ...props }) => {
  const steps = guides(id, setState);
  const history = useHistory();

  const handleCallback = (data) => {
    const { status, action, index } = data;
    if (sendIndex) {
      sendIndex(index);
    }
    if (status === STATUS.FINISHED) {
      if (onTourEnd) {
        onTourEnd();
        if (redirectLink) {
          history.push(redirectLink);
        }
      }
    }
    if (action === ACTIONS.NEXT) {
      if (onTourStart) {
        onTourStart();
      }
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Joyride steps={steps} callback={handleCallback} {...props} />
    </Suspense>
  );
};

export default Tours;
