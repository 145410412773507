import React from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AlertBox = ({ setOpen, message, type = 'info' }) => {
  return (
    <Alert
      action={
        setOpen && (
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
      }
      severity={type}
    >
      {message}
    </Alert>
  );
};

export default function AlertMessage({ grid = true, ...props }) {
  return grid ? (
    <Grid item sm={12} sx={{ height: '4rem', marginBottom: '3px', display: 'flex', alignItems: 'center' }}>
      <AlertBox {...props} />
    </Grid>
  ) : (
    <AlertBox {...props} />
  );
}
