import React, { useState, useEffect, useRef } from 'react';
import { blue, grey, red, indigo } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Paper, TextField } from '@mui/material';
import { Tooltip, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GeneralButton, RoundedDialog, StandardDialogActions } from '../Components';
import StateManager from '../StateManager';
import { v4 } from 'uuid';
import { AccountBalanceWallet, DeleteOutline, LooksTwoRounded, TextFields, ViewWeek } from '@mui/icons-material';
import { isEqual } from 'lodash';
import PageGridTableBuilder from './PageGridTableBuilder';

const MAX_COLUMNS = 8;

const columnTypes = [
  { type: 'number', text: 'Number', icon: <LooksTwoRounded style={{ color: indigo[900] }} /> },
  { type: 'string', text: 'Text', icon: <TextFields style={{ color: indigo[900] }} /> },
  { type: 'money', text: 'Money', icon: <AccountBalanceWallet style={{ color: indigo[900] }} /> },
];

export default function TableDialog({ open, onClose, onResult, initial }) {
  const [tableParams, setTableParams] = useState(null);

  useEffect(() => {
    if (isEqual(initial, tableParams)) return;
    setTableParams(initial);
  }, [initial]); // eslint-disable-line

  function done() {
    onResult(tableParams);
    onClose();
  }

  /*function addColumn() {
    if (columns.length >= MAX_COLUMNS) return;
    setColumns([...columns, { id: v4(), title: `Column ${columns.length + 1}`, type: 'string' }]);
  }

  function setColumnTitle(index, title) {
    columns[index].title = title;
    setColumns([...columns]);
  }

  function deleteColumn(id) {
    setColumns(columns.filter((x) => x.id !== id));
  }

  function changeColumnType(type) {
    if (currColumn.current > -1) {
      columns[currColumn.current].type = type;
      setColumns([...columns]);
    }
  }

  function onDragEnd(result) {
    if (!result.destination) return;

    const [removed] = columns.splice(result.source.index, 1);
    columns.splice(result.destination.index, 0, removed);
  }*/

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth className="non-draggable">
      <DialogTitle>Create table columns</DialogTitle>
      <DialogContent>
        <Grid container style={{ padding: '50px 50px 64px 0px' }}>
          <PageGridTableBuilder initial={tableParams} onChange={setTableParams} />
          {/*
          {false && (
            <Grid container item style={{ overflow: 'auto', background: grey[200], borderRadius: 8 }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided) => (
                    <Grid container wrap="nowrap" ref={provided.innerRef} {...provided.droppableProps}>
                      {columns.map((column, columnIndex) => (
                        <Draggable key={column.id} draggableId={column.id} index={columnIndex}>
                          {(provided, snapshot) => (
                            <Grid
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                minWidth: 250,
                                width: 250,
                                margin: '1rem 0.5rem',
                              }}
                            >
                              <Paper
                                style={{
                                  width: '100%',
                                  background: snapshot.isDragging ? blue[200] : '',
                                  height: 120,
                                  borderRadius: 10,
                                  padding: '0.5rem',
                                }}
                              >
                                <Grid container>
                                  <Grid container item alignItems="center" wrap="nowrap">
                                    <Tooltip title="Change type" placement="top">
                                      <IconButton
                                        onClick={(e) => {
                                          currColumn.current = columnIndex;
                                          setTypeMenu(e.currentTarget);
                                        }}
                                      >
                                        {columnTypes.find((x) => x.type === column.type)?.icon}
                                      </IconButton>
                                    </Tooltip>
                                    <TextField
                                      value={column.title}
                                      fullWidth
                                      placeholder="Column title"
                                      style={{ padding: 3, marginLeft: 8, flexGrow: 1 }}
                                      onChange={(e) => setColumnTitle(columnIndex, e.target.value)}
                                      inputProps={{ maxLength: 32 }}
                                    />
                                    <Tooltip title="Delete column" placement="top">
                                      <IconButton onClick={() => deleteColumn(column.id)}>
                                        <DeleteOutline style={{ color: red[500] }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          )}

          {false && columns.length < MAX_COLUMNS && (
            <Grid container item style={{ margin: '0.5rem 0' }}>
              <GeneralButton startIcon={<ViewWeek style={{ color: grey[500] }} />} onClick={addColumn}>
                Add column
              </GeneralButton>
            </Grid>
          )}
          {false && <TypesMenu anchorEl={typeMenu} onClose={() => setTypeMenu(null)} onResult={changeColumnType} />}
           */}
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}

function TypesMenu({ anchorEl, onClose, onResult }) {
  return (
    <Menu keepMounted open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
      {columnTypes.map((type) => (
        <MenuItem
          key={type.type}
          onClick={() => {
            onResult(type.type);
            onClose();
          }}
        >
          <ListItemIcon>{type.icon}</ListItemIcon>
          <ListItemText primary={type.text} />
        </MenuItem>
      ))}
    </Menu>
  );
}
