import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

export default function AuditRedirect() {
  return (
    <Switch>
      <Route exact path="/audit">
        <Redirect to="/audits/home" />
      </Route>

      <Route path="/audit/:id" render={({ match }) => <Redirect to={`/audits/${match.params.id}`} />} />
    </Switch>
  );
}
