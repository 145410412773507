import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { uploadImage } from '../../redux/actions';
import clsx from 'clsx';
import editorStyles from './style.module.css';
import { EditorState } from 'draft-js';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import createImagePlugin from '@draft-js-plugins/image';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';

import { Grid, Box, CircularProgress } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import Chat from '@mui/icons-material/Chat';
import { grey, red } from '@mui/material/colors';

import { getRaw } from '../HTMLEditor/utils';
import { onAddImage } from '../HTMLEditor/utils/image';
import { COLORS } from '../../constants';
import { CommentUploadButton, NormalButton } from '../IssueComponents/index';
import { UserMention } from '../../../../Global/Components';

export default function CommentEditorBox({
  label = 'Comment',
  addComment,
  updateComment,
  editComment,
  setEditComment,
  setOpenComment,
  editorState,
  setEditorState,
  boxShadow = true,
  saving,
}) {
  const ref = useRef(null);
  const { suggestions } = useSelector(({ settings }) => settings);
  const [mentionList, setMentionList] = useState([]);
  const [suggestionList, setSuggestionList] = useState(suggestions);
  const [newComment, setNewComment] = useState(() => EditorState.createEmpty());
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (suggestions !== null) {
      setSuggestionList(suggestions);
    }
  }, [suggestions]);

  const { MentionSuggestions, AlignmentTool, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({ mentionComponent: ({ mention }) => <UserMention id={mention._id} /> });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const focusPlugin = createFocusPlugin();
    const resizeablePlugin = createResizeablePlugin();
    const blockDndPlugin = createBlockDndPlugin();
    const alignmentPlugin = createAlignmentPlugin();
    const { AlignmentTool } = alignmentPlugin;

    const decorator = composeDecorators(
      resizeablePlugin.decorator,
      alignmentPlugin.decorator,
      focusPlugin.decorator,
      blockDndPlugin.decorator,
    );
    const imagePlugin = createImagePlugin({ decorator });

    const plugins = [blockDndPlugin, focusPlugin, alignmentPlugin, resizeablePlugin, imagePlugin, mentionPlugin];
    return { plugins, AlignmentTool, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }) => {
      setSuggestionList(defaultSuggestionsFilter(value, suggestions));
    },
    [suggestions],
  );

  function saveComment() {
    if (!editorState) return;
    let text = getRaw(editorState);
    if (setOpenComment) {
      setOpenComment(false);
    }
    setEditorState(EditorState.createEmpty());
    let comment = {
      text,
      createdAt: new Date(),
    };
    addComment({ ...comment, mentionList });
  }

  function handleEditComment() {
    if (!newComment) return;
    let id = editComment;
    let text = getRaw(editorState);
    setEditComment(null);
    let comment = {
      text,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    if (updateComment) {
      updateComment({ id, data: comment });
    }
    setOpenComment(false);
  }

  const uploadFile = async (file) => {
    const data = await uploadImage(file);
    if (data && data.file) {
      return data.file;
    }
  };

  const handleImage = async (e) => {
    const file = await uploadFile(e.target.files[0]);
    if (file) {
      let newState = onAddImage(editorState, file.location, { alt: `image${file.id}` });
      setEditorState(newState);
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      sx={{
        padding: (theme) => theme.spacing(1),
        boxShadow: boxShadow ? `0px 0px 10px ${grey[300]}` : 'none',
        margin: (theme) => theme.spacing(1, 0),
        borderRadius: '0.5rem',
      }}
    >
      <Box sx={{ position: 'relative', padding: (theme) => theme.spacing(0.5) }}>
        <Box
          className={clsx(editorStyles.editor, editorStyles.editorOutline)}
          onClick={() => ref.current?.focus()}
          sx={{ height: 150, overflowY: 'hidden', paddingTop: '10px' }}
        >
          <Editor
            editorKey={'commentEditor'}
            ref={ref}
            editorState={editorState}
            onChange={(e) => setEditorState(e)}
            plugins={plugins}
          />
          <AlignmentTool />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestionList}
            onSearchChange={onSearchChange}
            onAddMention={(d) => setMentionList([...mentionList, d._id])}
          />
        </Box>
      </Box>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: (theme) => theme.spacing(0.5, 1) }}
      >
        <Grid item sm>
          <CommentUploadButton onClick={handleImage} id="comment" />
        </Grid>
        <Grid item sm align="end">
          {setOpenComment && (
            <NormalButton
              endIcon={<CancelOutlined />}
              size="small"
              onClick={() => {
                setOpenComment(false);
                setNewComment('');
                if (editComment) {
                  setEditComment(null);
                }
              }}
              label={'Cancel'}
              labelColor={'#212224'}
              activeBorderColor={red[500]}
              activeLabelColor={red[500]}
            />
          )}
          <NormalButton
            onClick={editComment ? handleEditComment : saveComment}
            color="primary"
            endIcon={saving ? <CircularProgress /> : <Chat />}
            label={label}
            labelColor={'white'}
            activeLabelColor={'white'}
            activeBorderColor={COLORS.BOOKMARK}
            backgroundColor={COLORS.BOOKMARK}
            spacing={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
