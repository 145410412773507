import React, { useState, useRef, useEffect } from 'react';
import { Avatar } from '@mui/material';
import { isHex24 } from '../Functions';
import UserManager from '../UserManager';

export default function UserAvatar({ size = 24, userId, sx, ...props }) {
  const [user, setUser] = useState(null);
  const subscription = useRef(null);

  useEffect(() => {
    return () => {
      if (!subscription.current) return;
      subscription.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isHex24(userId)) return;
    let resolved = UserManager.resolveUser(userId);
    if (resolved?._id) {
      setUser(resolved);
    } else {
      subscription.current = UserManager.subscribeToUser(userId, (res) => {
        setUser(res);
        subscription.current.unsubscribe();
      });
    }
  }, [userId]);

  return (
    <Avatar sx={{ height: size, width: size, fontSize: 12, ...sx }} src={user?.avatar}>
      {user?.avatarLetters}
    </Avatar>
  );
}
