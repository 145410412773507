import {
  Alert,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import {
  CloudDownload,
  QrCode2Outlined,
  // FileCopyRounded,
  // Print,
} from '@mui/icons-material';
// import { useSelector } from 'react-redux';
import axios from 'axios';
import LinkIcon from '@mui/icons-material/Link';
import html2canvas from 'html2canvas';
import { Box } from '@mui/system';
import { FormIcon, ProcessIcon } from '../Icons';
import {
  CrossButton,
  // DebouncedTextField,
  RoundedDialog,
} from '../Components';
import StateManager from '../StateManager';

const QRCodeDialog = ({ open, onClose, title, description, qrData, link, setQrData }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  // const { user } = useSelector(({ profile }) => profile);
  // const [saving, setSaving] = useState(null);
  // const [showMessage, setShowMessage] = useState(null);

  // const updateQRCode = (value) => {
  //   setQrData((prev) => ({ ...prev, description: value }));
  //   setSaving(true);
  //   const id = qrData?._id;
  //   axios
  //     .put(`general/qr/${id}`, { description: value })
  //     .then((res) => {
  //       setShowMessage({ message: 'QR Code Description updated successfully', type: 'success' });
  //       setSaving(false);
  //     })
  //     .catch((err) => {
  //       setShowMessage({ message: err, type: 'error' });
  //       setSaving(false);
  //     });
  // };

  // const handleGetQRPdfLink = async () => {
  //   let body = {
  //     title,
  //     id: qrData?._id,
  //     description,
  //   };
  //   if (qrData?.description?.trim() !== '' || qrData?.description) {
  //     body.description = qrData?.description;
  //   }
  //   let result = await axios.post(`general/qr/generate/pdf`, body);
  //   const url = result.data.url;
  //   var instance = axios.create();
  //   delete instance.defaults.headers.common['Authorization'];
  //   const response = await instance.get(url, {
  //     responseType: 'blob',
  //     headers: {
  //       'Access-Control-Allow-Origin': '*',
  //     },
  //   });
  //   const file = new Blob([response.data], { type: 'application/pdf' });
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(file);
  //   link.download = result.data.fileName;
  //   return link;
  // };

  const handleDownload = async () => {
    // const link = await handleGetQRPdfLink();
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    html2canvas(ref.current).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'qrcode.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    });
  };

  // const handlePrint = async () => {
  //   const link = await handleGetQRPdfLink();
  //   const printWindow = window.open(link.href, '_blank');
  //   printWindow.print();
  // };

  // const handleCopy = async () => {
  //   const img = new Image();
  //   img.onload = function () {
  //     const canvas = document.createElement('canvas');
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     const ctx = canvas.getContext('2d');
  //     ctx.drawImage(img, 0, 0);
  //     canvas.toBlob(async (blob) => {
  //       await navigator.clipboard.write([
  //         new ClipboardItem({
  //           'image/png': blob,
  //         }),
  //       ]);
  //     });
  //   };
  //   img.src = qrData?.qr;
  // };

  const handleCopyLink = async () => {
    StateManager.setSuccessAlert('QR link has been copied.');
    await navigator.clipboard.writeText(link);
  };

  // setTimeout(() => {
  //   setShowMessage(null);
  // }, 5000);

  return (
    <RoundedDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className="scroll-bar"
      fullScreen={!largeDevices}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item alignItems="center">
            <Typography sx={{ fontSize: 20, margin: (theme) => theme.spacing(0, 2) }}>{title ?? 'QR Code'}</Typography>
          </Grid>
          <Grid item align="end">
            <CrossButton onClick={onClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item alignItems="center" display={'flex'} width={'100%'}>
            <Alert sx={{ fontSize: 13, margin: (theme) => theme.spacing(0, 2), width: '100%' }} severity="info">
              {description}
            </Alert>
          </Grid>
        </Grid>
        <Grid container item sx={{ mt: 1, flexDirection: 'column' }} alignItems={'center'} justifyContent={'center'}>
          <Grid
            item
            alignItems="center"
            display={'flex'}
            sx={{ padding: (theme) => theme.spacing(0, 2), width: '100%', flexDirection: 'column' }}
          >
            <Box
              sx={{
                p: 1,
                display: 'flex',
                borderRadius: '10px',
                border: '1px solid lightgrey',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 1,
              }}
              ref={ref}
            >
              {qrData?.qr && <img src={qrData?.qr} alt="QR Code" />}
            </Box>
            {/* {user?.access === 'admin' && (
              <>
                <DebouncedTextField
                  required
                  placeholder="Description"
                  fullWidth
                  value={qrData?.description ?? ''}
                  onValueChange={(value) => {
                    updateQRCode(value);
                  }}
                  debounceMs={400}
                  multiline
                  rows={5}
                  maxRows={5}
                  sx={{ my: 1 }}
                />
                {showMessage !== null && (
                  <Grid
                    sx={{ mb: 2, color: showMessage?.type === 'success' ? 'green' : 'red' }}
                    alignContent={'flex-start'}
                    width="100%"
                  >
                    {showMessage?.message}
                  </Grid>
                )}
              </>
            )} */}
            <OutlinedInput
              fullWidth
              value={link}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => handleCopyLink()} edge="end">
                    <LinkIcon />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ my: 1 }}
            />
          </Grid>
          <Grid
            display={'flex'}
            gap={'20px'}
            mt={2}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'flex-end'}
            flexWrap={'wrap'}
          >
            <Button onClick={handleDownload} variant={'outlined'} startIcon={<CloudDownload />}>
              Download
            </Button>
            <Button onClick={handleCopyLink} variant={'outlined'} startIcon={<LinkIcon />}>
              Copy Link
            </Button>
            {/* <Button startIcon={<Print />} onClick={handlePrint} variant={'outlined'}>
              Print
            </Button>
            <Button startIcon={<FileCopyRounded />} onClick={handleCopy} variant={'outlined'}>
              Copy Image
            </Button> */}
          </Grid>
        </Grid>
      </DialogContent>
    </RoundedDialog>
  );
};

const QRCode = ({ linkType, linkId, sx, title }) => {
  const [QRDialog, setQRDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuIndex, setMenuIndex] = useState(0);
  const [qrData, setQrData] = useState(null);
  const [link, setLink] = useState(null);

  const QRMenuOption = {
    spaces_workspace: {
      title: `Go to a Space - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'space',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to your workspace.',
    },
    task_template: {
      title: `Go to Task Template - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'task-template',
      type: 'shortcut',
      description: 'Scanning this QR Code will open up a task templates.',
    },
    form_page: [
      {
        title: `Go to Form - ${title ?? ''}`,
        icon: <FormIcon fontSize="small" color="primary" />,
        linkType: 'form',
        type: 'shortcut',
        description: 'Scanning this QR Code will take you to a form.',
      },
      {
        title: `Start an Entry - ${title ?? ''}`,
        icon: <FormIcon fontSize="small" color="primary" />,
        linkType: 'form-entry',
        type: 'action',
        description: 'Scanning this QR Code will start a form entry.',
      },
    ],
    form_survey: {
      title: `Form Survey link - ${title ?? ''}`,
      icon: <FormIcon fontSize="small" color="primary" />,
      linkType: 'external-form-entry',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to a form survey entry and can be filled out by guest users.',
    },
    process_page: [
      {
        title: `Start a Process - ${title ?? ''}`,
        icon: <ProcessIcon fontSize="small" />,
        linkType: 'process-entry',
        type: 'action',
        description: 'Scanning this QR Code will start a process.',
      },
      {
        title: `Go to Process page - ${title ?? ''}`,
        icon: <ProcessIcon fontSize="small" />,
        linkType: 'process',
        type: 'shortcut',
        description: 'Scanning this QR Code will take you to a process page.',
      },
    ],
    area: {
      title: `Go to Area - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'area',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to an area in DocLibrary.',
    },
    sub_folder: {
      title: `Go to Sub folder - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'folder',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to a sub folder in DocLibrary.',
    },
    doc: {
      title: `Go to Document - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'document',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to a document in DocLibrary.',
    },
    kpi_web_app: {
      title: `Go to My kpi web app - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'kpi_web',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to the My KPI web app.',
    },
    company_kpi_web: {
      title: `Go to Company kpi web app - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'company_kpi_web',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to the Company KPI web app.',
    },
    register: {
      title: `Go to Register- ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'register',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to the register page.',
    },
    audit_schedule: {
      title: `Go to Audit Schedule- ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'audit-schedule',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to the audit schedule page.',
    },
    audit: {
      title: `Go to Audit - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'audit',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to the audit page.',
    },
    asset: {
      title: `Go to Asset - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'asset',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to the asset page.',
    },
    asset_profile: {
      title: `Go to Asset Profile- ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'asset-profile',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to the asset profile page.',
    },
    routine_task: {
      title: `Carry out one off routine task - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'routine-task',
      type: 'action',
      description: 'Scanning this QR Code will open up a routine task.',
    },
    routine: {
      title: `Go to Routine - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'routine',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to the routine page.',
    },
    portal: {
      title: `Go to Portal - ${title ?? ''}`,
      icon: <CloudDownload fontSize="small" color="primary" />,
      linkType: 'portal',
      type: 'shortcut',
      description: 'Scanning this QR Code will take you to a portal.',
    },
  };

  const handleQRButtonClick = (e) => {
    if (!QRMenuOption[linkType].title && QRMenuOption[linkType]?.length > 0) {
      setMenuAnchor(e.currentTarget);
    } else {
      setLoading(true);
      let newLinkType = QRMenuOption[linkType].linkType ?? QRMenuOption[linkType][menuIndex].linkType;
      let title = QRMenuOption[linkType].title ?? QRMenuOption[linkType][menuIndex].title;
      let type = QRMenuOption[linkType].type ?? QRMenuOption[linkType][menuIndex].type ?? 'shortcut';
      axios
        .post(`general/qr`, { linkType: newLinkType, linkId, type, title })
        .then(async (res) => {
          setQrData(res?.data);
          let qrLink = `${window.origin}/qr/${res?.data?._id}`;
          if (res.data?.linkType === 'external-form-entry') {
            qrLink = `${window.origin}/external/survey/${linkId}`;
          }
          setLink(qrLink);
          setQRDialog(true);
          setLoading(false);
          await navigator.clipboard.writeText(qrLink);
          StateManager.setSuccessAlert('QR link has been copied.');
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setLoading(false);
        });
    }
  };

  if (!linkType) return null;

  if (!QRMenuOption[linkType]) return null;

  return (
    <>
      <Grid display={'flex'} alignItems="center" justifyContent="center" sx={sx}>
        {QRMenuOption[linkType].title && loading ? (
          <CircularProgress color="primary" size={25} />
        ) : (
          <Tooltip title={QRMenuOption[linkType]?.title} placement="top">
            <IconButton size="small" onClick={handleQRButtonClick}>
              <QrCode2Outlined fontSize="medium" style={{ color: 'primary' }} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <QRCodeDialog
        open={QRDialog}
        onClose={() => {
          setQRDialog(false);
          setMenuIndex(0);
        }}
        qrData={qrData}
        link={link}
        setQrData={setQrData}
        title={QRMenuOption[linkType]?.title ?? QRMenuOption[linkType][menuIndex].title}
        description={QRMenuOption[linkType]?.description ?? QRMenuOption[linkType][menuIndex].description}
      />
      {QRMenuOption[linkType]?.length > 0 && (
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={(e) => {
            e.stopPropagation();
            setMenuAnchor(null);
          }}
        >
          {QRMenuOption[linkType].map((item, index) => (
            <MenuItem
              key={index}
              onClick={(e) => {
                setMenuIndex(index);
                setLoading(true);
                let newLinkType = QRMenuOption[linkType][index].linkType;
                let title = QRMenuOption[linkType][index].title;
                let type = QRMenuOption[linkType][index].type ?? 'shortcut';
                axios
                  .post(`general/qr`, { linkType: newLinkType, linkId, type, title })
                  .then((res) => {
                    setQrData(res?.data);
                    setLink(`${window.origin}/qr/${res?.data?._id}`);
                    setQRDialog(true);
                    setLoading(false);
                    setMenuAnchor(null);
                  })
                  .catch((err) => {
                    StateManager.setAxiosErrorAlert(err);
                    setLoading(false);
                  });
              }}
            >
              {menuIndex === index && loading ? (
                <ListItemIcon>
                  <CircularProgress color="primary" size={20} />
                </ListItemIcon>
              ) : (
                <ListItemIcon>{item.icon}</ListItemIcon>
              )}
              <ListItemText primary={item.title} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default QRCode;
