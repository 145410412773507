import {
  VIEWSACTION_ATTEMPT,
  VIEWSLIST_ATTEMPT,
  VIEWS_ERROR,
  GETVIEWS,
  ADDVIEW,
  UPDATEVIEW,
  DELETEVIEW,
  VIEWSTATSCOUNT,
} from '../constants';

const initialState = {
  listLoading: false,
  actionLoading: false,
  error: null,
  views: [],
  viewStats: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VIEWSLIST_ATTEMPT:
      return { ...state, listLoading: true };
    case VIEWSACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case VIEWS_ERROR:
      return { ...state, listLoading: false, actionLoading: false, error: action.payload };
    case GETVIEWS:
      return { ...state, listLoading: false, views: action.payload };
    case ADDVIEW:
      return { ...state, actionLoading: false, views: [...state.views, action.payload] };
    case UPDATEVIEW:
      return {
        ...state,
        actionLoading: false,
        views: state.views.map((view) => (view.id === action.payload.id ? action.payload : view)),
      };
    case DELETEVIEW:
      return {
        ...state,
        actionLoading: false,
        views: state.views.filter((view) => view.id !== action.payload.id),
      };
    case VIEWSTATSCOUNT:
      return { ...state, viewStats: { ...state.viewStats, ...action.payload } };
    default:
      return state;
  }
}
