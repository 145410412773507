import React from 'react';

import TextField from '@mui/material/TextField';
import { Checkbox } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const RenderInput = (params) => (
  <TextField
    {...params}
    required
    label="Choose"
    variant="outlined"
    inputProps={{
      ...params.inputProps,
      autoComplete: 'new-password', // disable autocomplete and autofill
    }}
  />
);

export const RenderOption = ({ option, selected, field }) => {
  return (
    <React.Fragment>
      <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ marginRight: '8px' }} checked={selected} />
      {option[field]}
    </React.Fragment>
  );
};
