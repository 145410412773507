import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Save } from '@mui/icons-material';

export default function SaveButton({ onClick, saving, disabled = false, text = 'Save', style, startIcon, ...props }) {
  return (
    <Button
      onClick={(e) => {
        if (saving) return;
        onClick(e);
      }}
      disabled={disabled}
      style={{
        margin: '10px',
        marginLeft: 'auto',
        borderRadius: '0.5rem',
        ...style,
      }}
      color="primary"
      variant="contained"
      {...props}
      startIcon={
        saving ? (
          <CircularProgress style={{ height: 20, width: 20, color: 'white' }} />
        ) : startIcon ? (
          startIcon
        ) : (
          <Save />
        )
      }
    >
      {text}
    </Button>
  );
}
