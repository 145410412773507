import { useEffect, useState, useMemo } from 'react';
import { DialogContent, Grid, DialogTitle, CircularProgress, Typography, Button } from '@mui/material';
import { DeleteOutlineRounded, LabelRounded, EditRounded } from '@mui/icons-material';
import { VisibilityRounded, VisibilityOffRounded, PrintRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions, IntervalDialog, User, UserGroup } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { FormatDate } from '../../../Global/Functions';
import { grey, red, amber } from '@mui/material/colors';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { BASE_URL } from '../../../../constants';
import { isEmpty, last, first, isArray, isNumber, toNumber } from 'lodash';
import KpiTimescale from './KpiTimescale';
import Chart from '../../../Global/Charts/Chart';
import UserInput from '../../../Global/DataGrid/Filters/UserInput';
import { DataGridPremium } from '@mui/x-data-grid-premium';

export default function FieldKpiDialog({ open, onClose, onDelete, kpi, onChange }) {
  const [hideSlices, setHideSlices] = useState(false);
  const [saving, setSaving] = useState(false);
  const [info, setInfo] = useState(null);
  const [interval, setInterval] = useState(365);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [intervalDialog, setIntervalDialog] = useState(false);
  const [data, setData] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);

  const [entriesDialog, setEntriesDialog] = useState(false);
  const [entriesParams, setEntriesParams] = useState();
  const userId = localStorage.getItem('_id');

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this KPI', deleteKPI);
  }

  function deleteKPI() {
    setSaving(true);
    axios
      .post('/fields/kpi/deleteFieldKpi', { id: kpi._id })
      .then((res) => {
        onClose();
        onDelete();
        StateManager.setSuccessAlert('KPI has been deleted');
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setErrorAlert(err.response?.data?.message || 'Something went wrong');
      });
  }

  useEffect(() => {
    setInfo(null);
    if (!kpi || !open) return;

    setData(kpi.data);
    const preSetStart =
      kpi.appliedTimeFilters?.start && kpi.appliedTimeFilters?.days === -1
        ? moment(kpi.appliedTimeFilters?.start)
        : null;
    const preSetEnd =
      kpi.appliedTimeFilters?.end && kpi.appliedTimeFilters?.days === -1 ? moment(kpi.appliedTimeFilters?.end) : null;
    setStart(preSetStart);
    setEnd(preSetEnd);
    setInterval(isNumber(kpi.appliedTimeFilters?.days) ? kpi.appliedTimeFilters?.days : kpi.interval || 180);
  }, [kpi, open]);

  function applyTimeFilters(days, from, to) {
    const start = from
      ? moment(from)
      : moment()
          .subtract(days || interval, 'days')
          .startOf('day');
    const end = to ? moment(to) : moment().endOf('day');

    setLoadingChart(true);

    const params = {
      kpiId: kpi._id,
      start: start.toISOString(),
      end: end.toISOString(),
      days,
    };

    axios
      .post('/fields/kpi/apllyKpiFilters', params)
      .then((res) => {
        setLoadingChart(false);
        setData(res.data.data);
        if (onChange) onChange({ data: res.data.data, _id: kpi?._id });
      })
      .catch((err) => {
        setLoadingChart(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function print() {
    setSaving(true);
    const element = document.getElementById('chart');
    if (!element) {
      setSaving(false);
      return;
    }

    import('html2canvas').then(({ default: html2canvas }) => {
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const body = {
          image,
          title: kpi.title,
          formInfo: info,
        };
        axios
          .post('/kpi/exportToPdf', body)
          .then((res) => {
            window.open(`${BASE_URL}${res.data.link}`, '_blank');
            setSaving(false);
          })
          .catch((err) => {
            setSaving(false);
            StateManager.setAxiosErrorAlert(err);
          });
      });
    });
  }

  function onBarClick(seriesIndexStr, dataIndex) {
    if (!seriesIndexStr || !isNumber(dataIndex) || isEmpty(kpi.data?.series)) {
      return;
    }

    const seriesIndex = toNumber(seriesIndexStr);
    const seriesEntries = kpi.data.series[seriesIndex]?.entries;

    if (!isArray(seriesEntries)) {
      return;
    }

    const entries = kpi.data.series[seriesIndex].entries[dataIndex];

    if (!isArray(entries)) {
      return;
    }

    const timeframe = kpi.data.xAxis[dataIndex];
    const label = kpi.data.series[seriesIndex].label;

    setEntriesParams({ entriesIds: entries, title: `${label} in ${timeframe}` });
    setEntriesDialog(true);
  }

  function onMarkClick(dataIndex) {
    if (!isNumber(dataIndex) || isEmpty(kpi.data?.data)) {
      return;
    }

    const entries = kpi.data.entries?.[dataIndex];

    if (!isArray(entries)) {
      return;
    }

    const timeframe = kpi.data.xAxis[dataIndex];

    setEntriesParams({ entriesIds: entries, title: timeframe });
    setEntriesDialog(true);
  }

  function onPieClick(pieData) {
    setEntriesParams({ entriesIds: pieData.entries, title: pieData.label });
    setEntriesDialog(true);
  }

  useEffect(() => {
    if (interval > 0) {
      setStart(null);
      setEnd(null);
    }
  }, [interval]);

  if (!kpi) return null;

  const hasTargetLines = isArray(kpi.targetLines) && !isEmpty(kpi.targetLines);
  const isComboChart = hasTargetLines && kpi.chartType === 'ColumnChart';

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Grid container item>
          <Grid container item alignItems="center">
            <Typography variant="h3">{kpi.title}</Typography>
            <Typography variant="h6" style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              Change KPI timescale:
            </Typography>
            <KpiTimescale
              value={interval}
              onChange={(value) => {
                setInterval(value);
                if (value === -1) {
                  setIntervalDialog(true);
                } else {
                  setStart(null);
                  setEnd(null);
                  applyTimeFilters(value, null, null);
                }
              }}
            />
          </Grid>

          <Grid container item>
            <Grid container item xs={6} alignContent="flex-start">
              {kpi.fieldTitle && (
                <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                  <LabelRounded style={{ color: amber[500] }} />

                  <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                    Field:
                  </Typography>
                  <Typography variant="h6" style={{ marginLeft: '0.25rem' }}>
                    {kpi.fieldTitle}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={6} alignItems="baseline">
              {start && end && (
                <Grid container item justifyContent="flex-end" alignItems="center" style={{ marginTop: '0.5rem' }}>
                  <Typography style={{ fontWeight: 500 }}>
                    {`From ${start.format('DD/MM/yyyy')} to ${end.format('DD/MM/yyyy')}`}
                  </Typography>
                </Grid>
              )}

              <Grid container item style={{ marginTop: '0.5rem' }} justifyContent="flex-end">
                {kpi.chartType === 'PieChart' && (
                  <Button
                    style={{ color: grey[500], textTransform: 'none', fontSize: 14 }}
                    startIcon={hideSlices ? <VisibilityRounded /> : <VisibilityOffRounded />}
                    onClick={() => setHideSlices(!hideSlices)}
                  >
                    {hideSlices ? 'Show small slices' : 'Hide small slices'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item style={{ height: '55vh' }} id="chart">
          {!loadingChart && (
            <Chart
              data={data}
              chartType={kpi.chartType}
              barChartType={kpi.barChartType}
              onBarClick={onBarClick}
              onMarkClick={onMarkClick}
              onPieClick={onPieClick}
            />
          )}

          {loadingChart && (
            <Grid container item style={{ height: '100%' }}>
              <CircularProgress color="primary" style={{ margin: 'auto' }} />
            </Grid>
          )}
          {!loadingChart && !Array.isArray(data) && data?.count != null && (
            <Grid container alignItems="center" justifyContent="center">
              <Typography style={{ fontSize: '16rem' }}>{data.count}</Typography>
            </Grid>
          )}
          {!loadingChart && data.length === 0 && (
            <Grid container item style={{ height: '100%' }}>
              <Typography variant="h5" color="textSecondary" style={{ margin: 'auto' }}>
                There is no data to display
              </Typography>
            </Grid>
          )}
        </Grid>

        <IntervalDialog
          open={intervalDialog}
          onClose={() => setIntervalDialog(false)}
          onResult={(res) => {
            setStart(res.start);
            setEnd(res.end);
            applyTimeFilters(-1, res.start, res.end);
          }}
        />
        <EntriesDialog open={entriesDialog} onClose={() => setEntriesDialog(false)} {...entriesParams} />
      </DialogContent>

      <StandardDialogActions
        saving={saving}
        onClose={onClose}
        additionalActions={
          <>
            {userId === kpi.owner && (
              <Link to={`/kpiHub/field-kpi-builder/${kpi._id}`}>
                <Button style={{ color: grey[600], borderRadius: 8 }} startIcon={<EditRounded />}>
                  Edit
                </Button>
              </Link>
            )}

            {userId === kpi.owner && onDelete && (
              <Button
                style={{ color: red[500], borderRadius: 8 }}
                onClick={confirmDelete}
                startIcon={<DeleteOutlineRounded />}
              >
                delete
              </Button>
            )}

            <Button onClick={print} style={{ color: grey[600], borderRadius: 8 }} startIcon={<PrintRounded />}>
              print
            </Button>

            <div style={{ marginRight: 'auto' }} />
          </>
        }
      />
    </RoundedDialog>
  );
}

function EntriesDialog({ open, onClose, entriesIds, title }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [formEntries, setFormEntries] = useState([]);
  const [processEntries, setProcessEntries] = useState([]);

  useEffect(() => {
    if (!isArray(entriesIds)) return;
    const params = entriesIds.reduce(
      (prev, curr) => {
        const splitted = String(curr).split(':');
        const type = first(splitted);
        const id = last(splitted);
        if (type === 'task') {
          prev.tasks.push(id);
        }
        if (type === 'form') {
          prev.forms.push(id);
        }
        if (type === 'process') {
          prev.processes.push(id);
        }
        return prev;
      },
      { tasks: [], forms: [], processes: [] },
    );
    setLoading(true);
    axios
      .post('/fields/kpi/resolveEntries', params)
      .then(({ data }) => {
        setTasks(data.tasks);
        setFormEntries(data.formEntries);
        setProcessEntries(data.processEntries);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [entriesIds]);

  const processColumns = useMemo(() => {
    const usersOnlyOperators = [
      {
        label: 'Includes',
        value: 'includes',
        InputComponent: UserInput,
        InputComponentProps: { type: 'user' },
        getValueAsString: (value) => (isArray(value) ? value.join(', ') : ''),
      },
    ];

    const result = [
      {
        width: 150,
        headerName: 'Process',
        field: 'processTitle',
      },
      {
        width: 50,
        headerName: '#',
        field: 'number',
        type: 'number',
      },
      {
        width: 300,
        headerName: 'Started',
        field: 'createdAt',
        type: 'date',
        valueGetter: ({ row }) => moment(row.createdAt).toDate(),
        renderCell: ({ row }) => FormatDate(row.createdAt),
      },
      {
        width: 250,
        headerName: 'Started by',
        field: 'createdBy',
        sortable: false,
        renderCell: ({ row }) => <User avatarSize={32} id={row.createdBy} />,
        filterOperators: usersOnlyOperators,
      },
      {
        width: 300,
        headerName: 'Completed',
        field: 'completedAt',
        type: 'date',
        valueGetter: ({ row }) => (row.completedAt ? moment(row.completedAt).toDate() : null),
        renderCell: ({ row }) => (row.completedAt ? FormatDate(row.completedAt) : '-'),
      },
    ];

    return result;
  }, []);

  const formColumns = useMemo(() => {
    const usersOnlyOperators = [
      {
        label: 'Includes',
        value: 'includes',
        InputComponent: UserInput,
        InputComponentProps: { type: 'user' },
        getValueAsString: (value) => (isArray(value) ? value.join(', ') : ''),
      },
    ];

    const result = [
      {
        width: 150,
        headerName: 'Form',
        field: 'formTitle',
      },
      {
        width: 50,
        headerName: '#',
        field: 'number',
        type: 'number',
      },
      {
        width: 300,
        headerName: 'Started',
        field: 'createdAt',
        type: 'date',
        valueGetter: ({ row }) => moment(row.createdAt).toDate(),
        renderCell: ({ row }) => FormatDate(row.createdAt),
      },
      {
        width: 250,
        headerName: 'Started by',
        field: 'startedBy',
        sortable: false,
        renderCell: ({ row }) => <User avatarSize={32} id={row.startedBy} />,
        filterOperators: usersOnlyOperators,
      },
      {
        width: 250,
        headerName: 'Assigned users',
        field: 'assignedUsers',
        sortable: false,
        renderCell: ({ row }) => <UserGroup avatarSize={32} ids={row.assignedUsers} />,
        valueGetter: ({ row }) => '',
        filterOperators: usersOnlyOperators,
      },
    ];

    return result;
  }, []);

  const taskColumns = useMemo(() => {
    const usersOnlyOperators = [
      {
        label: 'Includes',
        value: 'includes',
        InputComponent: UserInput,
        InputComponentProps: { type: 'user' },
        getValueAsString: (value) => (isArray(value) ? value.join(', ') : ''),
      },
    ];

    const result = [
      {
        width: 150,
        headerName: 'Task',
        field: 'title',
      },
      {
        width: 250,
        headerName: 'Created by',
        field: 'created_by',
        sortable: false,
        renderCell: ({ row }) => <User avatarSize={32} id={row.created_by} />,
        filterOperators: usersOnlyOperators,
      },
      {
        width: 250,
        headerName: 'Assigned users',
        field: 'assignedUsers',
        sortable: false,
        renderCell: ({ row }) => <UserGroup avatarSize={32} ids={row.assignedUsers} />,
        valueGetter: ({ row }) => '',
        filterOperators: usersOnlyOperators,
      },
      {
        width: 300,
        headerName: 'Completed',
        field: 'completedDate',
        type: 'date',
        valueGetter: ({ row }) => (row.completedDate ? moment(row.completedDate).toDate() : null),
        renderCell: ({ row }) => (row.completedDate ? FormatDate(row.completedDate) : '-'),
      },
    ];

    return result;
  }, []);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>{title || 'Entries'}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container item alignItems="center" justifyContent="center" style={{ height: '60vh' }}>
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <Grid container item style={{ height: '60vh', overflow: 'auto' }} alignContent="flex-start">
            {!isEmpty(tasks) && (
              <Grid container style={{ margin: '8px 0' }}>
                <Grid container item>
                  <Typography variant="h6" gutterBottom>
                    Tasks
                  </Typography>
                </Grid>
                <Grid container>
                  <DataGridPremium
                    disableAggregation
                    disableRowGrouping
                    getRowId={(row) => row._id}
                    columns={taskColumns}
                    rows={tasks}
                    disableRowSelectionOnClick
                    onRowClick={({ row }) => StateManager.selectTask(row._id)}
                    autoHeight
                  />
                </Grid>
              </Grid>
            )}
            {!isEmpty(formEntries) && (
              <Grid container style={{ margin: '8px 0' }}>
                <Grid container item>
                  <Typography variant="h6" gutterBottom>
                    Forms
                  </Typography>
                </Grid>
                <Grid container>
                  <DataGridPremium
                    disableAggregation
                    disableRowGrouping
                    getRowId={(row) => row._id}
                    columns={formColumns}
                    rows={formEntries}
                    disableRowSelectionOnClick
                    onRowClick={({ row }) => history.push(`/forms/entry/${row._id}`)}
                    autoHeight
                  />
                </Grid>
              </Grid>
            )}
            {!isEmpty(processEntries) && (
              <Grid container style={{ margin: '8px 0' }}>
                <Grid container item>
                  <Typography variant="h6" gutterBottom>
                    Processes
                  </Typography>
                </Grid>
                <Grid container item>
                  <DataGridPremium
                    disableAggregation
                    disableRowGrouping
                    getRowId={(row) => row._id}
                    columns={processColumns}
                    rows={processEntries}
                    disableRowSelectionOnClick
                    onRowClick={({ row }) => history.push(`/processes/ongoing/${row._id}`)}
                    autoHeight
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </RoundedDialog>
  );
}
