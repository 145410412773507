import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
  ButtonGroup,
  Button,
  colors,
} from '@mui/material';
import Layout from '../Global/Layout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { GeneralButton } from '../Global/Components';
import {
  TaskIcon,
  AuditIcon,
  FormIcon,
  SupportIcon,
  ApprovalIcon,
  ProcessIcon,
  AssetIcon,
  DocIcon,
} from '../Global/Icons';
import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';
import { Comment } from '@mui/icons-material';

const WORKFLOW = 'workflow';
const AUDIT = 'audit';
const FORM = 'form';
const SUPPORT = 'support';
const APPROVAL = 'approval';
const PROCESS = 'process';
const MENTION = 'mention';
const ASSET = 'asset';
const DL = 'docLibrary';

const descriptions = {
  [WORKFLOW]: 'All workflow related notifications will be sent to you',
  [AUDIT]: 'All audit related notifications will be sent to you',
  [FORM]: 'All form related notifications will be sent to you',
  [SUPPORT]: 'All support related notifications will be sent to you',
  [APPROVAL]: 'All approval related notifications will be sent to you',
  [PROCESS]: 'All process related notifications will be sent to you',
  [MENTION]: 'All mention related notifications will be sent to you',
  [ASSET]: 'All asset related notifications will be sent to you',
  [DL]: 'All document library related notifications will be sent to you',
};

const getIcon = (type) => {
  switch (type) {
    case WORKFLOW:
      return <TaskIcon />;
    case AUDIT:
      return <AuditIcon />;
    case FORM:
      return <FormIcon />;
    case SUPPORT:
      return <SupportIcon />;
    case APPROVAL:
      return <ApprovalIcon />;
    case PROCESS:
      return <ProcessIcon />;
    case ASSET:
      return <AssetIcon />;
    case DL:
      return <DocIcon />;
    case MENTION:
      return <Comment sx={{ color: colors.yellow[700] }} />;
    default:
      return <TaskIcon />;
  }
};

export default function Settings() {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get('/notifications/getUserSettings')
      .then((res) => {
        setLoading(false);
        setSettings(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function switchSetting(type, enabled) {
    let newSettings = settings;
    let index = newSettings.findIndex((x) => x.notificationCategory === type);
    if (index > -1) {
      newSettings[index].enabled = enabled;
      setSettings([...newSettings]);
      axios.post('/notifications/setSetting', { setting: type, value: enabled }).catch((err) => {
        newSettings[index].enabled = enabled;
        setSettings([...newSettings]);
        console.error(err);
      });
    }
  }

  function disableAll() {
    let newSettings = settings;
    let oldSettings = settings.map((a) => ({ ...a }));
    newSettings.forEach((setting) => (setting.enabled = false));
    setSettings([...newSettings]);
    axios.post('/notifications/disableAll').catch((err) => {
      console.error(err);
      setSettings([...oldSettings]);
    });
  }

  function enableAll() {
    let newSettings = settings;
    let oldSettings = settings.map((a) => ({ ...a }));
    newSettings.forEach((setting) => (setting.enabled = true));
    setSettings([...newSettings]);
    axios.post('/notifications/enableAll').catch((err) => {
      console.error(err);
      setSettings([...oldSettings]);
    });
  }

  return (
    <Layout icon={<SettingsOutlinedIcon style={{ color: '#4a4e54' }} />} title="Notifications Settings" back={true}>
      <Grid container justifyContent="center">
        <Grid
          item
          container
          direction={largeDevices ? 'row' : 'column'}
          style={{ marginBottom: '0.4rem' }}
          justifyContent={largeDevices ? 'space-between' : 'flex-start'}
          alignItems="center"
          lg={10}
          md={10}
          xs={12}
        >
          <Grid item container xs={12} sm={6}>
            <Typography
              sx={{
                color: (t) => (t.palette.mode === 'light' ? '#353940' : '#f4f4f4'),
                fontSize: '26px',
                fontWeight: 500,
              }}
            >
              Settings
            </Typography>
          </Grid>
          {!loading && (
            <Grid item xs={12} sm={6} container direction="row" justifyContent={largeDevices ? 'flex-end' : 'center'}>
              <GeneralButton
                variant="outlined"
                startIcon={<BlockIcon style={{ color: 'red' }} />}
                style={{
                  textTransform: 'none',
                  marginRight: '1em',
                  width: 'fit-content',
                }}
                onClick={disableAll}
              >
                Disable all
              </GeneralButton>
              <GeneralButton
                variant="outlined"
                startIcon={<DoneIcon style={{ color: 'green' }} />}
                style={{
                  textTransform: 'none',
                  width: 'fit-content',
                }}
                onClick={enableAll}
              >
                Enable all
              </GeneralButton>
            </Grid>
          )}
          <Grid item container>
            <Typography style={{ marginTop: 15, fontSize: 16, fontWeight: 400 }}>
              Select what notifications will be emailed to you
            </Typography>
          </Grid>
        </Grid>

        {loading ? (
          <Grid container style={{ height: '85vh' }}>
            <CircularProgress color="primary" style={{ margin: 'auto' }} />
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid container item direction="column" lg={8} md={10} sm={12} justifyContent="center">
              {settings.map((setting) => (
                <Grid
                  key={setting._id}
                  item
                  container
                  style={{ marginTop: '1rem', padding: '0.5rem 0', borderTop: '1px solid #d0d0d0aa' }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item container style={{ width: '70%' }} direction="row" alignItems="center">
                    <Grid item style={{ marginRight: '1rem' }}>
                      {getIcon(setting.notificationCategory)}
                    </Grid>
                    <Grid item container direction="column" sm={10}>
                      <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                        {setting.notificationCategory}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: (t) => (t.palette.mode === 'light' ? '#353940' : '#f4f4f4'), fontSize: '14px' }}
                      >
                        {descriptions[setting.notificationCategory]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <ButtonGroup variant="outlined">
                    <Button
                      variant={setting.enabled ? 'contained' : 'outlined'}
                      onClick={() => {
                        switchSetting(setting.notificationCategory, true);
                      }}
                    >
                      On
                    </Button>
                    <Button
                      variant={setting.enabled ? 'outlined' : 'contained'}
                      onClick={() => {
                        switchSetting(setting.notificationCategory, false);
                      }}
                    >
                      Off
                    </Button>
                  </ButtonGroup>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
