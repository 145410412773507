import { UserMention } from '../../../../../Global/Components';

const findMentionEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'mention';
  }, callback);
};

export const mentionDecorator = {
  strategy: findMentionEntities,
  component: ({ entityKey, contentState }) => {
    let { mention } = contentState.getEntity(entityKey).getData();
    return <UserMention id={mention._id} />;
  },
};

const mentionDecoratorPlugin = { decorators: [mentionDecorator] };

export default mentionDecoratorPlugin;
