import { useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { isArray, isEmpty, isFunction, cloneDeep, sumBy } from 'lodash';
import { blueberryTwilightPalette } from '@mui/x-charts/colorPalettes';
import Legend from './Legend';

export default function Chart({ data, onPieClick, isPreview, chartParams }) {
  const theme = useTheme();
  const standardPalette = blueberryTwilightPalette(theme?.palette?.mode || 'light');
  const legendPosition = chartParams?.legendPosition || 'top';
  const hideLegend = chartParams?.hideLegend;

  // customize pie chart data using chartParams
  const chartData = useMemo(() => {
    if (!isArray(data)) return [];

    const usePercentForSlices = chartParams?.usePercentForSlices;
    const hasOptionsParams = isArray(chartParams?.optionsParams) && !isEmpty(chartParams?.optionsParams);

    const sum = usePercentForSlices ? sumBy(data, 'value') : 0;
    const result = cloneDeep(data);

    for (let i = 0; i < result.length; ++i) {
      // color the slices
      if (hasOptionsParams) {
        const normLabel = String(result[i].label).trim();
        const config = chartParams.optionsParams.find((x) => String(x.value).trim() === normLabel);

        result[i].color = config?.color;
      }

      // if no colour defined in the config - take from standard palette
      if (!result[i].color) {
        result[i].color = standardPalette[i % standardPalette.length];
      }

      // calculate percentage

      if (usePercentForSlices) {
        const percentValue = String(Math.ceil((result[i].value / sum) * 100 * 10) / 10);
        result[i].percentValue = percentValue;
      }
    }

    return result;
  }, [chartParams, data, standardPalette]);

  const legendData = useMemo(() => {
    return chartData.map((x) => ({ color: x.color, label: x.label }));
  }, [chartData]);

  if (isEmpty(chartData)) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Typography color={'textSecondary'} variant={isPreview ? 'subtitle1' : 'h6'}>
          No data to display
        </Typography>
      </Grid>
    );
  }

  const ChartComponent = (
    <PieChart
      series={[
        {
          data: chartData,
          cornerRadius: 4,
          innerRadius: 24,
          valueFormatter: (item) => (chartParams?.usePercentForSlices ? `${item.percentValue}%` : String(item.value)),
        },
      ]}
      onItemClick={(_event, _info, pieData) => isFunction(onPieClick) && onPieClick(pieData)}
      tooltip={{ trigger: 'item' }}
      slotProps={{ legend: { hidden: true } }}
    />
  );

  if (legendPosition === 'top' || legendPosition === 'bottom') {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: '100%' }}
        direction={'column'}
        wrap="nowrap"
      >
        {!hideLegend && legendPosition === 'top' && (
          <Grid container sx={{ height: 32 }}>
            <Legend items={legendData} />
          </Grid>
        )}
        <Grid container style={{ flexGrow: 1, position: 'relative' }}>
          <Grid container style={{ height: '100%', position: 'absolute' }}>
            {ChartComponent}
          </Grid>
        </Grid>
        {!hideLegend && legendPosition === 'bottom' && (
          <Grid container sx={{ height: 32 }}>
            <Legend items={legendData} />
          </Grid>
        )}
      </Grid>
    );
  }

  if (legendPosition === 'left' || legendPosition === 'right') {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: '100%' }}
        direction={'row'}
        wrap="nowrap"
      >
        {!hideLegend && legendPosition === 'left' && (
          <Grid container sx={{ height: '100%', width: '30%' }}>
            <Legend direction="column" items={legendData} />
          </Grid>
        )}
        <Grid container sx={{ height: '100%', width: '70%' }}>
          {ChartComponent}
        </Grid>
        {!hideLegend && legendPosition === 'right' && (
          <Grid container sx={{ height: '100%', width: '30%' }}>
            <Legend direction="column" items={legendData} />
          </Grid>
        )}
      </Grid>
    );
  }

  return null;
}
