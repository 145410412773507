import React from 'react';
import { DialogContent, DialogTitle } from '@mui/material';
import FieldDataGrid from './FieldDataGrid';
import { RoundedDialog, StandardDialogActions } from '../Components';

export default function TableDialog({ title, columns, rows, editable, onChange, open, onClose, activityInfo }) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <FieldDataGrid
          columns={columns}
          initial={rows}
          editable={editable}
          activityInfo={activityInfo}
          onChange={onChange}
          forDialog
        />
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={onClose} hideDone={!editable} />
    </RoundedDialog>
  );
}
