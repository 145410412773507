import React from 'react';
import { Avatar, Popover, List, ListItem, Tooltip, Typography, ListItemText, Grid } from '@mui/material';
import { ListItemAvatar } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { blue, grey } from '@mui/material/colors';

export default function ChatNotifications({ open, anchorEl, onClose, onEntering, notifications, openChats }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onEntering={onEntering}
      style={{
        marginTop: '40px',
      }}
      className="scroll-bar"
    >
      <Grid container style={{ minWidth: '30vw' }}>
        <Grid container item style={{ minHeight: '52px', borderBottom: `1px solid ${grey[500]}` }} alignItems="center">
          <ChatIcon style={{ margin: '0 15px', color: blue[900] }} />

          <Tooltip title="Open chats" placement="top" arrow>
            <Typography style={{ fontSize: 20, marginLeft: '10px', cursor: 'pointer' }} onClick={() => openChats(true)}>
              Chats
            </Typography>
          </Tooltip>
        </Grid>
        <Grid container item style={{ overflow: 'auto', maxHeight: '450px' }}>
          {!notifications[0] && (
            <Grid container item justifyContent="center" style={{ padding: '20px' }}>
              <Typography variant="h6">You don't have any chat notifications</Typography>
            </Grid>
          )}
          <List style={{ width: '100%' }}>
            {notifications.map((notification) => (
              <ListItem key={notification._id} button onClick={() => openChats(notification._id)}>
                <ListItemAvatar>
                  <Avatar src={notification.picture || '/icon-192x192.png'}></Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${notification.title} (${notification.count})`} secondary={notification.text} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Popover>
  );
}
