import React from 'react';
import AppHeader from '../AppHeader';
import { Grid } from '@mui/material';

export default function Layout({ children, title, icon, logo, sideMenu, enableSideMenu = true, hideNotificationIcon }) {
  return (
    <Grid container>
      <AppHeader title={title} icon={icon} picture={logo} hideNotificationIcon={hideNotificationIcon} back />
      <Grid container sx={{ flexGrow: 1, mt: '64px', p: 2 }}>
        {children}
      </Grid>
    </Grid>
  );
}
