import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, TextField } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import StateManager from '../../../../Global/StateManager';
import axios from 'axios';

export default function FolderDialog({
  parentId,
  open,
  onClose,
  onResult,
  initial,
  forFileFolder,
  forGlobalFolder,
  workspaceId,
}) {
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setTitle(initial?.title || '');
    setDescription(initial?.description || '');
    setSaving(false);
  }, [open, initial]);

  function done() {
    setSaving(true);

    const body = initial
      ? { folderId: initial._id, title, description, workspaceId }
      : { parentId, title, description, workspaceId };
    axios
      .post(
        forGlobalFolder && !initial
          ? '/workhub/workspace/createGlobalFolder'
          : initial
          ? `/workhub/workspace/${forFileFolder ? 'renameFileFolder' : 'renameFolder'}`
          : `/workhub/workspace/${forFileFolder ? 'createFileFolder' : 'createFolder'}`,
        body,
      )
      .then((res) => {
        setSaving(false);
        onResult(res.data);
        StateManager.setSuccessAlert(`Folder has been ${initial ? 'renamed' : 'created'}`);
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} maxWidth={forFileFolder ? 'sm' : 'xs'} fullWidth onClose={onClose}>
      <DialogTitle>{initial ? 'Rename folder' : 'Add folder'}</DialogTitle>

      <DialogContent>
        <TextField
          variant="outlined"
          label="Folder title"
          value={title}
          fullWidth
          id="folder-title"
          onChange={(event) => setTitle(event.target.value)}
          inputProps={{ maxLength: 128 }}
          sx={{ my: 1 }}
        />

        {forFileFolder && (
          <TextField
            variant="outlined"
            label="Folder description"
            value={description}
            fullWidth
            id="folder-description"
            onChange={(event) => setDescription(event.target.value)}
            inputProps={{ maxLength: 2048 }}
            multiline
            minRows={5}
            maxRows={25}
            sx={{ my: 1 }}
          />
        )}
      </DialogContent>
      <StandardDialogActions saving={saving} onClose={onClose} onDone={done} hideDone={!title} />
    </RoundedDialog>
  );
}
