import {
  successAction,
  attemptAction,
  errorAction,
  COMPONENTLOADER,
  COMPONENTERROR,
  COMPONENTSUCCESS,
} from '../constants';

import axios from '../apiCaller';

export const getDocumentById = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(COMPONENTLOADER));
      const body = await axios.post(`/dochub/documents/getDocument`, { id });
      if (body.data.status === 'Ok' && body.data.document) {
        let doc = body.data.document;
        dispatch(successAction(COMPONENTSUCCESS, { id, document: doc }));
      } else {
        dispatch(errorAction(COMPONENTERROR, 'Document Access Denied'));
      }
    } catch (err) {
      dispatch(errorAction(COMPONENTERROR, err));
    }
  };
  return thunk;
};
