import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { TextField, Button, Grid, Paper, Collapse, CircularProgress, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { darklogo } from '../../../../constants';
import Loader from '../../../Global/Loader';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../../constants/msal';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { Microsoft365Icon } from '../../../Global/Icons';
import { validateEmail } from '../../../Global/Functions';

export default function SignUpPortalUserComponent() {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    passwordError: false,
    emailError: false,
    loggedIn: false,
    loading: false,
    success: false,
    errorText: '',
    firstNameError: false,
    lastNameError: false,
  });
  const [ssoError, setSsoError] = useState(false);
  const [ssoErrorMessage, setSsoErrorMessage] = useState('');
  const [ssoLoading, setSsoLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [company, setCompany] = useState(null);

  const { instance, inProgress, accounts } = useMsal();
  const { linkId } = useParams();

  const verify = () => {
    if (!data.firstName) {
      setData({ ...data, errorText: 'Provide your first name', firstNameError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!data.lastName) {
      setData({ ...data, errorText: 'Provide your last name', lastNameError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (company?.linkType === 'email' && !validateEmail(String(data.email).trim())) {
      setData({ ...data, errorText: 'Provide correct email', emailError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (!data.password) {
      setData({ ...data, errorText: 'Provide password', passwordError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (data.password && String(data.password).length < 3) {
      setData({ ...data, errorText: 'Password must be at least 3 characters long', passwordError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }
    if (String(data.password) !== String(data.confirmPassword)) {
      setData({ ...data, errorText: 'Passwords do not match', passwordError: true });
      setTimeout(() => setData({ ...data, errorText: '' }), 8000);
      return;
    }

    setData({
      loading: true,
      passwordError: false,
      emailError: false,
      firstNameError: false,
      lastNameError: false,
      errorText: '',
    });
    axios
      .post(`/auth/register-portal-user`, {
        linkId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        username: data.username,
        password: data.password,
      })
      .then((res) => {
        if (res.data.success && res.data.authJSON) {
          localStorage.clear();
          localStorage.setItem('token', res.data.authJSON.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authJSON.name);
          localStorage.setItem('_id', res.data.authJSON.id);
          localStorage.setItem('company', res.data.authJSON.company);
          localStorage.setItem('access', res.data.authJSON.access);
          window.location.href = '/home';
        } else {
          if (res.data.forbiddenToken) {
            localStorage.setItem('forbiddenToken', res.data.forbiddenToken);
            window.location.href = '/suspended';
          } else if (res.data.companyArchived) {
            window.location.href = '/suspended';
          } else if (res.data.trialEnded || res.data.subscriptionCancelled) {
            window.location.href = '/expired';
          } else if (res.data.userDeleted || res.data.userSuspended) {
            window.location.href = '/suspended';
          } else {
            setData({
              ...data,
              success: true,
              loading: false,
            });
          }
        }
      })
      .catch((err) => {
        setData({ ...data, loading: false, errorText: err?.response?.data?.message || 'Something went wrong' });
        setTimeout(() => {
          setData({ ...data, errorText: '' });
        }, 8000);
      });
  };

  const handleSSOSignup = async () => {
    try {
      setSsoLoading(true);
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      setSsoLoading(false);
      console.error(`Authentication error: ${error}`);
    }
  };

  useEffect(() => {
    if (!linkId) return;
    setCompanyLoading(true);
    setSsoErrorMessage('');
    setSsoError(false);
    localStorage.removeItem('token');
    axios
      .get(`/auth/getPortal/${linkId}`)
      .then((res) => {
        setCompanyLoading(false);
        if (res.data.success) {
          setCompany(res.data.details);
          localStorage.setItem('linkId', linkId);
          return;
        } else {
          setCompanyError(res.data.message);
        }
      })
      .catch((err) => {
        setCompanyLoading(false);
        setCompanyError(err?.response?.data?.message || 'Something went wrong');
      });
  }, [linkId]);

  useEffect(() => {
    if (!accounts || accounts?.length === 0) return;
    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    setSsoLoading(true);
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Call your web api for oauth
          const jwt = accessTokenResponse.idToken;
          const accesstoken = accessTokenResponse.accessToken;
          const id = localStorage.getItem('linkId');
          // delete existing Authorization header from axios
          delete axios.defaults.headers.common['Authorization'];
          axios
            .post(
              `/auth/register-portal-user-with-sso`,
              { linkId: id },
              {
                headers: {
                  Authorization: jwt,
                  accesstoken,
                },
              },
            )
            .then((res) => {
              setSsoError(false);
              setSsoErrorMessage('');
              if (res.data.success && res.data.authJSON) {
                localStorage.clear();
                localStorage.setItem('token', res.data.authJSON.token);
                localStorage.setItem('loggedIn', 'true');
                localStorage.setItem('name', res.data.authJSON.name);
                localStorage.setItem('_id', res.data.authJSON.id);
                localStorage.setItem('company', res.data.authJSON.company);
                localStorage.setItem('access', res.data.authJSON.access);
                window.location.href = '/home';
              } else {
                if (res.data.forbiddenToken) {
                  localStorage.setItem('forbiddenToken', res.data.forbiddenToken);
                  window.location.href = '/suspended';
                } else if (res.data.companyArchived) {
                  window.location.href = '/suspended';
                } else if (res.data.trialEnded || res.data.subscriptionCancelled) {
                  window.location.href = '/expired';
                } else if (res.data.userDeleted || res.data.userSuspended) {
                  window.location.href = '/suspended';
                } else {
                  sessionStorage.clear();
                  setSsoError(true);
                  setSsoErrorMessage(res.data.message);
                  setSsoLoading(false);
                }
              }
            })
            .catch((err) => {
              sessionStorage.clear();
              setSsoLoading(false);
              setSsoError(true);
              setSsoErrorMessage(err?.response?.data?.message || 'Something went wrong');
            });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress]);

  return (
    <Grid container>
      {companyLoading && <Loader />}
      {!companyLoading && !company && companyError && (
        <Grid container item justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
          <Paper
            elevation={4}
            style={{
              marginTop: '10vh',
              borderRadius: 12,
              padding: '1rem',
              width: '100%',
              width: '80vw',
              maxWidth: 500,
            }}
          >
            <Grid container justifyContent="center">
              <Alert severity="error" sx={{ width: '100%', borderRadius: 1 }}>
                <AlertTitle>Error</AlertTitle>
                {companyError}
              </Alert>
            </Grid>
          </Paper>
        </Grid>
      )}
      {!companyLoading && company && (
        <>
          <Grid container item justifyContent="center">
            <img alt="img" src={company?.logo || darklogo} style={{ height: 80, marginTop: 50 }} />
          </Grid>
          <Grid container item justifyContent="center">
            <Paper
              elevation={4}
              style={{
                marginTop: '10vh',
                borderRadius: 12,
                padding: '1rem',
                width: '100%',
                width: '80vw',
                maxWidth: 500,
              }}
            >
              {data?.success ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="h4" gutterBottom>
                    Account Created!
                  </Typography>
                  {company?.linkType === 'email' && (
                    <Typography style={{ textAlign: 'center', margin: '2rem 0' }}>
                      We have sent you a confirmation email, please check your inbox and click on the verification link
                      to confirm your email.
                    </Typography>
                  )}
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  {!ssoLoading && (
                    <>
                      <Grid container justifyContent="center" item sx={{ textAlign: 'center' }} direction="column">
                        {(company?.name || company?.title) && (
                          <Typography variant="h5" sx={{ my: 2 }}>
                            {company?.title} - {company?.name}
                          </Typography>
                        )}
                        <Typography>
                          {company?.linkType === 'email'
                            ? 'Please sign up with your email to join the portal'
                            : company?.linkType === 'username'
                            ? 'Please sign up with your username to join the portal'
                            : 'Please click the sign up with Microsoft to join the portal'}
                        </Typography>
                      </Grid>
                      {company?.linkType !== 'sso' && (
                        <>
                          <Grid container item justifyContent="space-between" spacing={1}>
                            <Grid container item lg={6} md={6} xs={12}>
                              <TextField
                                style={{ marginRight: 10 }}
                                required
                                error={data.firstNameError}
                                fullWidth
                                value={data.firstName}
                                label="First name"
                                variant="outlined"
                                onChange={(e) => {
                                  setData({ ...data, firstName: e.target.value, firstNameError: false, errorText: '' });
                                }}
                                inputProps={{ maxLength: 64 }}
                                id="sugn-up-first-name"
                              />
                            </Grid>
                            <Grid container item lg={6} md={6} xs={12}>
                              <TextField
                                required
                                error={data.lastNameError}
                                fullWidth
                                value={data.lastName}
                                label="Last name"
                                variant="outlined"
                                onChange={(e) => {
                                  setData({ ...data, lastName: e.target.value, lastNameError: false, errorText: '' });
                                }}
                                inputProps={{ maxLength: 64 }}
                                id="sugn-up-last-name"
                              />
                            </Grid>
                          </Grid>
                          {company?.linkType === 'email' && (
                            <Grid container item>
                              <TextField
                                required
                                error={data.emailError}
                                fullWidth
                                value={data.email}
                                label="Email"
                                type="email"
                                variant="outlined"
                                onChange={(e) => {
                                  setData({ ...data, email: e.target.value, emailError: false, errorText: '' });
                                }}
                                inputProps={{ maxLength: 64 }}
                                id="sugn-up-email"
                              />
                            </Grid>
                          )}
                          {company?.linkType === 'username' && (
                            <Grid container item>
                              <TextField
                                required
                                fullWidth
                                value={data.username}
                                label="Username"
                                type="username"
                                variant="outlined"
                                onChange={(e) => {
                                  setData({ ...data, username: e.target.value });
                                }}
                                inputProps={{ maxLength: 64 }}
                                id="sugn-up-email"
                              />
                            </Grid>
                          )}
                          <Grid container item>
                            <TextField
                              required
                              fullWidth
                              value={data.password}
                              error={data.passwordError}
                              label="Password"
                              type="password"
                              variant="outlined"
                              inputProps={{ maxLength: 64 }}
                              onChange={(e) => {
                                setData({ ...data, password: e.target.value, errorText: '', passwordError: false });
                              }}
                              id="sugn-up-password"
                              autoComplete="current-password"
                            />
                          </Grid>
                          <Grid container item>
                            <TextField
                              required
                              fullWidth
                              value={data.confirmPassword}
                              error={data.passwordError}
                              label="Confirm Password"
                              type="password"
                              variant="outlined"
                              inputProps={{ maxLength: 64 }}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  confirmPassword: e.target.value,
                                  errorText: '',
                                  passwordError: false,
                                });
                              }}
                              autoComplete="current-password"
                              id="new_password"
                              name="password"
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {(data.loading || ssoLoading) && (
                    <Grid container item justifyContent="center" sx={{ m: ssoLoading ? 3 : 0, p: 2 }}>
                      <CircularProgress color="primary" />
                    </Grid>
                  )}
                  {data.errorText && (
                    <Grid container item>
                      <Collapse style={{ width: '100%' }} in={Boolean(data.errorText)}>
                        <Alert severity="error" sx={{ width: '100%', borderRadius: 1 }}>
                          <AlertTitle>Error</AlertTitle>
                          {data.errorText}
                        </Alert>
                      </Collapse>
                    </Grid>
                  )}
                  {ssoError && (
                    <Grid container item>
                      <Collapse sx={{ width: '100%' }} in={Boolean(ssoError)}>
                        <Alert severity="error" sx={{ width: '100%', borderRadius: 1 }}>
                          <AlertTitle>Error</AlertTitle>
                          {ssoErrorMessage}
                        </Alert>
                      </Collapse>
                    </Grid>
                  )}
                  {!ssoLoading && company?.linkType !== 'sso' && (
                    <Grid container item justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={verify}
                        disabled={!company}
                        startIcon={data.loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                        sx={{ borderRadius: 1 }}
                      >
                        Sign Up
                      </Button>
                    </Grid>
                  )}
                  {!ssoLoading && company?.linkType === 'sso' && (
                    <>
                      <Grid container item justifyContent="center">
                        <Button
                          variant="contained"
                          onClick={handleSSOSignup}
                          startIcon={<Microsoft365Icon />}
                          disabled={!company}
                          sx={{
                            borderRadius: 1,
                            backgroundColor: '#2f2f2f',
                            color: 'white',
                          }}
                        >
                          Sign Up with Microsoft
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}
