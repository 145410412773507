import React from 'react';

import { ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CheckCircle, Cancel } from '@mui/icons-material';

const StatusIcon = ({ status, avatarSize, fullWidth = false }) => {
  const fontSize = Math.round(avatarSize / 2);
  const padding = Math.round(avatarSize / 8);
  const iconStyles = { marginBottom: '4px', height: 32, width: 32 };
  return (
    <ListItem
      sx={{
        borderRadius: '5px',
        padding: `${padding}px 0px`,
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      <ListItemText
        disableTypography
        primary={
          <Typography
            noWrap
            sx={{ fontSize: 12, textTransform: 'capitalize', color: status === 'approved' ? green[800] : red[500] }}
          >
            {status}
          </Typography>
        }
        sx={{ marginTop: '-10px' }}
      />
      <ListItemIcon sx={{ minWidth: 'auto', width: avatarSize, height: avatarSize, fontSize }}>
        {status === 'approved' && <CheckCircle sx={{ color: green[800], ...iconStyles }} />}
        {status === 'rejected' && <Cancel sx={{ color: red[500], ...iconStyles }} />}
      </ListItemIcon>
    </ListItem>
  );
};

export default StatusIcon;
