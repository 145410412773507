import React, { useEffect, useState } from 'react';
import { DialogContent, DialogTitle, Grid } from '@mui/material';
import { CircularProgress, Chip, TextField, Typography, Button } from '@mui/material';
import { NotInterestedRounded, CheckCircleRounded } from '@mui/icons-material';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { grey, red, amber, green } from '@mui/material/colors';
import Autocomplete from '@mui/material/Autocomplete';
import { isArray, isEmpty } from 'lodash';
import axios from 'axios';

export default function FieldFilterDialog({ open, onClose, onResult, initial, dataSource }) {
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);

  function done() {
    const result = fields
      .filter((x) => !isEmpty(x.selected) && isArray(x.selected))
      .map((field) => ({
        fieldId: field.fieldId,
        values: field.selected,
      }));

    onResult(result);
    onClose();
  }

  function clear() {
    for (let i = 0; i < fields.length; ++i) {
      fields[i].selected = [];
    }

    setFields([...fields]);

    onResult(null);
    onClose();
  }

  useEffect(() => {
    if (!dataSource || !open) return;
    setLoading(true);
    axios
      .post('/kpi/general/getKpiFieldFiltersByDataSource', { dataSource })
      .then(({ data }) => {
        if (!isArray(initial) || isEmpty(initial)) {
          setFields(data);
          return;
        }

        for (let i = 0; i < initial.length; ++i) {
          const field = data.find((x) => x.fieldId === initial[i].fieldId);

          if (!field) continue;

          field.selected = initial[i].values;
        }

        setFields(data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open, dataSource]); // eslint-disable-line

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select fields to filter by</DialogTitle>
      <DialogContent>
        <Grid container alignItems="baseline">
          {!loading && !isEmpty(fields) && (
            <Grid container sx={{ mb: 4, mt: 1 }}>
              {fields.map((field, i) => (
                <Grid container sx={{ my: 1 }} alignItems="center" wrap="nowrap" key={i}>
                  <Grid item container xs={3}>
                    <Typography sx={{ fontWeight: 500, mr: 2 }} variant="h6">
                      {field.title}:
                    </Typography>
                  </Grid>
                  <Grid item container xs={9}>
                    <Autocomplete
                      multiple
                      fullWidth
                      disableCloseOnSelect
                      value={field.selected || []}
                      onChange={(_event, newValue) => {
                        field.selected = [...newValue];
                        setFields([...fields]);
                      }}
                      getOptionLabel={(option) => option || ''}
                      freeSolo
                      options={field.values.map((x) => x.value)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label="Select or type values" />}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option}>
                          <Grid container item alignItems="center">
                            <Typography style={{ marginLeft: '1rem' }}>{option}</Typography>
                            {selected ? (
                              <CheckCircleRounded fontSize="small" style={{ color: green[500], marginLeft: 'auto' }} />
                            ) : (
                              <CheckCircleOutlineRounded
                                fontSize="small"
                                style={{ color: grey[300], marginLeft: 'auto' }}
                              />
                            )}
                          </Grid>
                        </li>
                      )}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          {loading && (
            <Grid container style={{ height: '40vh' }} alignItems={'center'} justifyContent={'center'}>
              <CircularProgress />
            </Grid>
          )}
          {!loading && isEmpty(fields) && (
            <Grid container style={{ height: '40vh' }} alignItems={'center'} justifyContent={'center'}>
              <Typography gutterBottom variant="h5" color="textSecondary">
                No fields availabe to filter
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          <Button style={{ color: red[500], marginRight: 'auto' }} onClick={clear} startIcon={<NotInterestedRounded />}>
            Clear filters
          </Button>
        }
        onClose={onClose}
        onDone={done}
      />
    </RoundedDialog>
  );
}
