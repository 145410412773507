import { EditorState, Modifier, RichUtils, SelectionState, getDefaultKeyBinding } from 'draft-js';

/**
 * Determine whether the start of the paragraph indicates that it is part of an ordered list
 */
function shouldEnterOl(text, olRegex) {
  return olRegex.test(text);
}

/**
 * Determine whether the start of the paragraph indicates that it is part of an unordered list
 */
function shouldEnterUl(text, ulChars) {
  return ulChars.includes(text[0]);
}

/**
 * Start a list of the desired type and remove the characters which were typed for starting the list
 */
function startList(editorState, content, blockKey, firstSpacePos, blockType) {
  const selectionToRemove = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: 0,
    focusKey: blockKey,
    focusOffset: firstSpacePos + 1,
  });
  const updatedContent = Modifier.removeRange(content, selectionToRemove, 'backward');
  let updatedState = EditorState.push(editorState, updatedContent, 'change-block-type');
  updatedState = EditorState.forceSelection(updatedState, updatedContent.getSelectionAfter());
  updatedState = RichUtils.toggleBlockType(updatedState, blockType);
  return updatedState;
}

const CONFIG_DEFAULTS = {
  allowNestedLists: true,
  maxDepth: 4,
  olRegex: /\d\./,
  ulChars: ['-', '–', '*'],
};

const BlockTypes = {
  UL: 'unordered-list-item',
  OL: 'ordered-list-item',
  TEXT: 'unstyled',
};

const createListPlugin = (config) => {
  const { allowNestedLists, maxDepth, olRegex, ulChars } = {
    ...CONFIG_DEFAULTS,
    ...config,
  };

  // Parameter validation
  if (maxDepth < 1) {
    throw Error(`maxDepth cannot be ${maxDepth} (must be a positive integer)`);
  }
  ulChars.forEach((char) => {
    if (char.length !== 1) {
      throw Error(`ulChar "${char}" must be exactly one character long`);
    }
  });

  const plugin = {
    /**
     * Handle space key presses
     */
    handleBeforeInput(chars, editorState, _eventTimeStamp, { setEditorState }) {
      // On space press: Start list if necessary
      if (chars === ' ') {
        // Get editor content
        const content = editorState.getCurrentContent();

        // Get currently selected block
        const selection = editorState.getSelection();
        const blockKey = selection.getStartKey();
        const block = content.getBlockForKey(blockKey);

        // Exit if already in a list
        if (block.getType() !== BlockTypes.TEXT) {
          return 'not-handled';
        }

        // Get current position of cursor in block
        const cursorPos = selection.getStartOffset();

        // Get block text
        const text = `${block.getText()} `;

        // Calculate position of first space character in block
        const firstSpacePos = text.indexOf(' ');

        // If the typed space is the first one in the block: Check if list needs to be inserted
        if (cursorPos === firstSpacePos) {
          if (shouldEnterUl(text, ulChars)) {
            // Start unordered list
            const updatedState = startList(editorState, content, blockKey, firstSpacePos, BlockTypes.UL);
            setEditorState(updatedState);
            return 'handled';
          }
          if (shouldEnterOl(text, olRegex)) {
            // Start ordered list
            const updatedState = startList(editorState, content, blockKey, firstSpacePos, BlockTypes.OL);
            setEditorState(updatedState);
            return 'handled';
          }
        }
      }
      return 'not-handled';
    },
  };

  // Handle tab and shift+tab presses if nested lists are allowed
  if (allowNestedLists) {
    plugin.keyBindingFn = (e, { getEditorState, setEditorState }) => {
      if (e.key === 'Tab') {
        const editorState = getEditorState();
        const updatedState = RichUtils.onTab(e, editorState, maxDepth);
        setEditorState(updatedState);
      }
      return getDefaultKeyBinding(e);
    };
  }

  return plugin;
};

export default createListPlugin;
