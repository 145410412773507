import { DOCOWNERS_ATTEMPT, DOCOWNERS_SUCCESS, DOCOWNERS_FAIL } from '../constants';

const initialState = {
  ownerList: [],
  loadingOwners: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DOCOWNERS_ATTEMPT:
      return { ...state, loadingOwners: true, ownerList: [] };
    case DOCOWNERS_SUCCESS:
      return { ...state, loadingOwners: true, ownerList: action.payload };
    case DOCOWNERS_FAIL:
      return { ...state, loadingOwners: false, error: action.payload };
    default:
      return state;
  }
}
