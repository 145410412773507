import React, { useState, useEffect } from 'react';
import axios from '../../../../../redux/apiCaller';

import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { Menu, Button, Typography, MenuItem, Checkbox, ListItemText, CircularProgress } from '@mui/material';
import { red } from '@mui/material/colors';

const FileButton = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        '&:hover': {
          borderColor: '#000',
          boxShadow: '0 0 0 0.1rem rgb(0,0,00)',
        },
        '&:active': {
          boxShadow: 'none',
          borderColor: '#000',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.1rem rgb(0,0,0)',
        },
      }}
    >
      {children}
    </Button>
  );
};

const RoleButton = (props) => {
  const { ids = [], title, setData, label = 'Click to add', minimum = 0 } = props;
  const [loading, setLoading] = useState(false);
  const [userMenu, setUserMenu] = useState(null);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState('');

  const handleUserSelect = (x) => {
    if (ids.length === 0) {
      setData([...ids, x]);
    } else {
      if (ids.includes(x)) {
        setData(ids.filter((a) => a !== x));
      } else {
        setData([...ids, x]);
      }
    }
  };

  useEffect(() => {
    axios['get'](`/dochub/areas/getAreaRoles/${props.area}/${props.role}`)
      .then((res) => {
        setLoading(true);
        if (res.data && res.data.users) {
          setResponse(res.data.users);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.area, props.role]);

  return (
    <Grid container direction="row" spacing={2} alignItems={'center'}>
      {title && <Typography>{title}</Typography>}
      <Grid item sm>
        <Grid
          sx={{
            border: '1px solid #e2e2e2',
            borderRadius: '7px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: '5px',
            ...((ids.length === 0) &
              {
                borderColor: red[500],
              }),
          }}
          onClick={(e) => setUserMenu(e.currentTarget)}
          aria-controls="fade-menu"
          aria-haspopup="true"
        >
          {loading && <CircularProgress size={24} color="primary" />}
          {response && response.length > 0 && response.filter((x) => ids.includes(x._id)).length > 0 ? (
            response.filter((x) => ids.includes(x._id)).length > 1 ? (
              <AvatarGroup max={5}>
                {response
                  .filter((x) => ids.includes(x._id))
                  .map((r) => (
                    <Avatar
                      key={r._id}
                      sx={{
                        backgroundColor: r?.avatarColor || '#aa00ff',
                        height: 32,
                        width: 32,
                        fontSize: 14,
                      }}
                      src={r.picture}
                    >
                      {r.avatarLetter}
                    </Avatar>
                  ))}
              </AvatarGroup>
            ) : (
              <>
                <Typography>{response.find((x) => ids[0] === x._id)?.fullName}</Typography>
                <Avatar
                  src={response.find((x) => ids[0] === x._id)?.picture}
                  sx={{
                    backgroundColor: response.find((x) => ids[0] === x._id)?.avatarColor || '#aa00ff',
                    marginLeft: 5,
                    height: 32,
                    width: 32,
                    fontSize: 14,
                  }}
                >
                  {response.find((x) => ids[0] === x._id)?.avatarLetter}
                </Avatar>
              </>
            )
          ) : (
            <Typography variant="h6" sx={{ color: '#9e9e9e' }}>
              {label}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Menu id="fade-menu" anchorEl={userMenu} open={Boolean(userMenu)} keepMounted onClose={() => setUserMenu(null)}>
        {response &&
          response.length > 0 &&
          response.map((x) => (
            <MenuItem onClick={() => handleUserSelect(x._id)} key={x._id}>
              <Checkbox checked={ids.indexOf(x._id) > -1} />
              <ListItemText primary={x.fullName} />
            </MenuItem>
          ))}
      </Menu>
    </Grid>
  );
};

const SecondaryButton = ({ action, title, sxProps = {}, ...props }) => (
  <Button
    sx={{
      backgroundColor: '#0277bd',
      color: 'white',
      '&:hover': {
        backgroundColor: '#0277bd',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0277bd',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
      ...sxProps,
    }}
    onClick={action}
    variant="contained"
    {...props}
  >
    {title}
  </Button>
);

const PrimaryButton = ({ action, title, sxProps = {}, ...props }) => (
  <Button
    sx={{
      backgroundColor: '#2e7d32',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2e7d32',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#2e7d32',
      },
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: '#ccc',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
      ...sxProps,
    }}
    onClick={action}
    variant="contained"
    {...props}
  >
    {title}
  </Button>
);

export { RoleButton, FileButton, PrimaryButton, SecondaryButton };
