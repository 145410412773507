import React, { useEffect, useState, Suspense } from 'react';
import { DialogContent, Grid } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Typography, Button, Collapse } from '@mui/material';
import { DeleteOutlineRounded, LabelRounded, EditRounded } from '@mui/icons-material';
import { VisibilityRounded, VisibilityOffRounded, PrintRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { AssetIcon } from '../../../Global/Icons';
import { grey, red, blue, amber } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../../constants';
import axios from 'axios';
import Chart from '../../../Global/Charts/Chart';

export default function AssetKpiDialog({ open, onClose, onDelete, kpi }) {
  const [hideSlices, setHideSlices] = useState(false);
  const [saving, setSaving] = useState(false);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const userId = localStorage.getItem('_id');

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this KPI', deleteKPI);
  }

  function deleteKPI() {
    setSaving(true);
    axios
      .post('/asset/kpi/deleteKpi', { id: kpi._id })
      .then((res) => {
        onClose();
        onDelete(kpi._id);
        StateManager.setSuccessAlert('KPI has been deleted');
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  useEffect(() => {
    setInfo(null);
    if (!kpi) return;

    setData(kpi.data);
    setLoading(true);
    axios
      .get('/asset/kpi/getFieldInfo', { params: { kpiId: kpi._id } })
      .then((res) => {
        setInfo(res.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [kpi]);

  function print() {
    setSaving(true);
    const element = document.getElementById('chart');
    if (!element) {
      setSaving(false);
      return;
    }

    import('html2canvas').then(({ default: html2canvas }) => {
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const body = {
          image,
          title: kpi.title,
          assetInfo: info,
        };
        axios
          .post('/kpi/exportToPdf', body)
          .then((res) => {
            window.open(`${BASE_URL}${res.data.link}`, '_blank');
            setSaving(false);
          })
          .catch((err) => {
            setSaving(false);
            StateManager.setAxiosErrorAlert(err);
          });
      });
    });
  }

  if (!kpi) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Grid container item>
          <Collapse in={Boolean(info)} style={{ width: '100%' }} collapsedSize={40}>
            {loading && (
              <Grid container item>
                <CircularProgress color="primary" size={30} style={{ margin: 5 }} />
              </Grid>
            )}
            {info && (
              <Grid container item>
                <Grid container item xs={6}>
                  <Grid container item style={{ paddingBottom: '0.5rem' }}>
                    <Typography variant="h3">{kpi.title}</Typography>
                  </Grid>
                  <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                    <AssetIcon />
                    <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                      Profile:
                    </Typography>
                    <Link to={`/asset/profile/${info.profileId}`}>
                      <Typography variant="h6" style={{ color: blue[700], marginLeft: '0.25rem' }}>
                        {info.profileTitle}
                      </Typography>
                    </Link>
                  </Grid>

                  <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                    <LabelRounded style={{ color: amber[500] }} />

                    <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                      Field:
                    </Typography>
                    <Typography variant="h6" style={{ marginLeft: '0.25rem' }}>
                      {info.fieldTitle}
                    </Typography>
                  </Grid>

                  {info.outdated && (
                    <Grid container item>
                      <Typography gutterBottom style={{ fontWeight: 500, color: red[700] }}>
                        This field does not exist in the latest version of the profile
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid container item xs={6} alignItems="baseline">
                  <Grid container item style={{ marginTop: '0.5rem' }} justifyContent="flex-end">
                    <Button
                      style={{ color: grey[500], textTransform: 'none', fontSize: 14 }}
                      startIcon={hideSlices ? <VisibilityRounded /> : <VisibilityOffRounded />}
                      onClick={() => setHideSlices(!hideSlices)}
                    >
                      {hideSlices ? 'Show small slices' : 'Hide small slices'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Collapse>
        </Grid>

        <Grid container item id="chart" style={{ height: '55vh' }}>
          <Chart data={data} chartType={'pie'} />

          {data.length === 0 && (
            <Grid container item style={{ width: '100%', height: '55vh' }}>
              <Typography variant="h5" color="textSecondary" style={{ margin: 'auto' }}>
                There is no data to display
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <StandardDialogActions
        saving={saving}
        onClose={onClose}
        additionalActions={
          <>
            {userId === kpi.userId && (
              <Link to={`/kpiHub/asset-kpi-builder/${kpi._id}`}>
                <Button style={{ color: grey[600] }} startIcon={<EditRounded />}>
                  Edit
                </Button>
              </Link>
            )}

            {userId === kpi.userId && onDelete && (
              <Button style={{ color: red[500] }} onClick={confirmDelete} startIcon={<DeleteOutlineRounded />}>
                delete
              </Button>
            )}

            {info && (
              <Button onClick={print} style={{ color: grey[600] }} startIcon={<PrintRounded />}>
                print
              </Button>
            )}

            <div style={{ marginRight: 'auto' }} />
          </>
        }
      />
    </RoundedDialog>
  );
}
