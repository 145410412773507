import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  confirmReadUnderstood,
  updateReadUnderstoodTask,
  deleteReadUnderstoodTask,
} from '../../Hubs/dochub/redux/actions/ru';
import { previewFile } from '../../Hubs/dochub/redux/actions/file';
import moment from 'moment';
import {
  RoundedDialog,
  CrossButton,
  SaveButton,
  TooltipTypography,
  User,
  StandardDialogActions,
  Signature,
} from '../Components';
import StateManager from '../StateManager';
import Canvas from '../Fields/Canvas';
import useUserList from '../useUserList';
import { ReadUnderstoodIcon, TaskIcon, FormIcon, ProcessIcon } from '../Icons';
import { Flag, FlagOutlined, Person, MoreHoriz, DeleteOutlineRounded, Launch } from '@mui/icons-material';
import {
  Alert,
  Divider,
  Paper,
  useTheme,
  Button,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  TextField,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { grey, red, blue } from '@mui/material/colors';
import FilePreviewer from '../FilePreviewer';
import { formatDate } from '../../Hubs/dochub/constants';
import Loader from '../Loader';
import { isObject } from 'lodash';
import FileGridItem from '../FileGridItem';

const SignatureAdditionalComponent = ({ scrollEnd }) => {
  return (
    <Grid container sx={{ mb: 2 }}>
      {scrollEnd ? (
        <Alert severity="success" style={{ width: '100%', borderRadius: 8 }}>
          Well done - you’ve read the document. You can now sign.
        </Alert>
      ) : (
        <Alert severity="warning" style={{ width: '100%', borderRadius: 8 }}>
          Please read the document before signing
        </Alert>
      )}
    </Grid>
  );
};

const ReadUnderstoodDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isRUPage = useRouteMatch('/DocLibrary/read-and-understood')?.isExact;
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const { readUnderstood, ruSaving, ruLoading, ruActivity } = useSelector(({ ru }) => ru);
  const { user } = useSelector(({ profile }) => profile);
  const users = useUserList();
  const isAdmin = user?.access === 'admin';
  const isPortalUser = user?.access === 'portal';
  const [confirm, setConfirm] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [signature, setSignature] = useState({});
  const [signatureBox, setSignatureBox] = useState(false);
  const [reasonDialog, setReasonDialog] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [organization, setOrganization] = useState('');
  const [email, setEmail] = useState('');

  const [taskMenu, setTaskMenu] = useState(null);

  const handleScroll = (e) => {
    const top = e.target.scrollHeight === e.target.scrollHeight - e.target.scrollTop;
    const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight;
    if (!readUnderstood?.completedAt && user?._id === readUnderstood?.assignee) {
      if (top) {
        setScrollEnd(false);
        setConfirm(false);
        return;
      }
      if (bottom) {
        setConfirm(true);
        setScrollEnd(true);
        return;
      } else {
        setConfirm(false);
        setScrollEnd(false);
        return;
      }
    }
  };

  const handleDone = () => {
    setSignatureBox(false);
    const signatureUpdate = {
      ...signature,
      jsonData: {
        ...signature?.jsonData,
        firstName,
        lastName,
        email,
        organization,
      },
    };
    dispatch(
      confirmReadUnderstood(readUnderstood?._id, {
        signature: signatureUpdate,
        confirm,
      }),
    );
  };

  const handleFile = () => {
    dispatch(previewFile(readUnderstood?.document));
  };

  const switchFlagged = () => {
    let value = !Boolean(readUnderstood?.task?.flagged);
    dispatch(updateReadUnderstoodTask(readUnderstood?.task?._id, { flagged: value }));
  };

  const changeOwner = () => {
    StateManager.selectUser((user) => {
      if (user._id === readUnderstood?.task.created_by) return;
      dispatch(updateReadUnderstoodTask(readUnderstood?.task?._id, { created_by: user._id }));
    });
  };

  const confirmTaskDelete = () => {
    StateManager.setConfirm('You are about to delete this task', () => {
      setReasonDialog(true);
    });
  };

  const saveReason = (reason) => {
    dispatch(deleteReadUnderstoodTask(readUnderstood?.task?._id, { reason }));
    setReasonDialog(false);
  };

  useEffect(() => {
    if (!readUnderstood || !open) return;
    if (readUnderstood && readUnderstood.document) {
      if (readUnderstood.completedAt || readUnderstood.cancelledAt) {
        setConfirm(true);
        setCurrentStep(1);
      } else {
        setCurrentStep(0);
        setConfirm(false);
      }
      if (readUnderstood.signature) {
        setSignature(readUnderstood.signature);
        if (isObject(readUnderstood.signature?.jsonData)) {
          setFirstName(readUnderstood.signature?.jsonData?.firstName || '');
          setLastName(readUnderstood.signature?.jsonData?.lastName || '');
          setOrganization(readUnderstood.signature?.jsonData?.organization || '');
          setEmail(readUnderstood.signature?.jsonData?.email || '');
        }
      } else {
        setSignature({});
        setFirstName('');
        setLastName('');
        setOrganization('');
        setEmail('');
      }
      if (readUnderstood.document.category !== 'qhub') {
        setScrollEnd(true);
      } else {
        setScrollEnd(false);
      }
    }
  }, [readUnderstood, open]);

  function StatusDate() {
    const overDue =
      !readUnderstood?.completedAt &&
      !readUnderstood?.cancelledAt &&
      moment(readUnderstood?.dueDate).isBefore(moment());
    if (overDue) {
      return (
        <Typography variant="h6" color="error" style={{ margin: theme.spacing(0, 1) }}>
          Overdue - {formatDate(readUnderstood?.dueDate, 'DD/MM/YYYY')}
        </Typography>
      );
    }
    return (
      <Typography variant="h6" color="textSecondary" style={{ margin: theme.spacing(0, 1) }}>
        Sent at {formatDate(readUnderstood?.createdAt, 'DD/MM/YYYY hh:mm a')}
      </Typography>
    );
  }

  const formatActivity = (activity) => {
    if (['Task', 'Document'].includes(activity)) {
      return activity;
    }
    if (activity === 'FormEntry') {
      return 'Entry';
    }
    if (activity === 'ProcessStep') {
      return 'Step';
    }
    return null;
  };

  const handleLink = (activity, activityId) => {
    if (activity === 'Task') {
      return StateManager.selectTask(activityId);
    }
    if (activity === 'FormEntry') {
      history.push(`/forms/entry/${activityId}`);
      handleClose();
    }
    if (activity === 'ProcessStep') {
      history.push(`/processes/step/${activityId}`);
      handleClose();
    }
  };

  const handleIcon = (activity) => {
    if (activity === 'Task') {
      return <TaskIcon />;
    }
    if (activity === 'FormEntry') {
      return <FormIcon />;
    }
    if (activity === 'ProcessStep') {
      return <ProcessIcon />;
    }
    return <TaskIcon />;
  };

  const handleActivityNumber = () => {
    if (readUnderstood?.activityId && ruActivity) {
      return ruActivity.number;
    }
    if (readUnderstood?.task) {
      return readUnderstood?.task?.number;
    }
    return 'N/A';
  };

  const getAssignee = () => {
    if (readUnderstood?.assignee) {
      const assignee = users.find((u) => u._id === readUnderstood?.assignee);
      return assignee?.fullName;
    }
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return '';
  };

  if (ruLoading) {
    return <Loader isBackdrop />;
  }

  return (
    <Grid container>
      <RoundedDialog
        titleId={'ruDialog'}
        fullWidth
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="xl"
        fullScreen={!largeDevices}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item sm={2} container alignItems="center">
              <User id={readUnderstood?.assignedBy} />
            </Grid>
            <Grid
              container
              item
              xs={4}
              sm={4}
              justifyContent="center"
              alignItems="center"
              onClick={() => handleLink(readUnderstood?.activityType, readUnderstood?.activityId)}
              style={{ cursor: 'pointer' }}
            >
              {handleIcon(readUnderstood?.activityType)}
              {largeDevices && (
                <Typography style={{ fontSize: 20, color: grey[500], marginLeft: '0.7em' }}>
                  Associated with {ruActivity?.title} {handleActivityNumber()}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4} sm={4} container justifyContent="flex-end" alignItems="center">
              <StatusDate />
              {user?._id === readUnderstood?.assignedBy &&
                !readUnderstood?.cancelledAt &&
                largeDevices &&
                !isPortalUser &&
                readUnderstood?.task && (
                  <Tooltip title={`${readUnderstood?.task?.flagged ? 'Unflag' : 'Flag'} the task`}>
                    <IconButton onClick={switchFlagged} style={{ width: '1.5em', height: '1.5em' }}>
                      {readUnderstood?.task?.flagged ? (
                        <Flag style={{ color: red[500] }} />
                      ) : (
                        <FlagOutlined style={{ color: grey[500] }} />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              {readUnderstood?.task &&
                !readUnderstood?.cancelledAt &&
                (user?._id === readUnderstood?.assignedBy || isAdmin) &&
                !isPortalUser && (
                  <IconButton
                    onClick={(event) => setTaskMenu(event.currentTarget)}
                    style={{ width: '1.5em', height: '1.5em' }}
                  >
                    <MoreHoriz />
                  </IconButton>
                )}
              <Menu anchorEl={taskMenu} open={Boolean(taskMenu)} onClose={() => setTaskMenu(null)}>
                {user?._id === readUnderstood?.assignedBy && !readUnderstood?.cancelledAt && (
                  <MenuItem
                    onClick={() => {
                      confirmTaskDelete();
                      setTaskMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteOutlineRounded style={{ color: red[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Delete task" />
                  </MenuItem>
                )}

                {user?._id === readUnderstood?.assignedBy && !readUnderstood?.cancelledAt && !largeDevices && (
                  <MenuItem
                    onClick={() => {
                      switchFlagged();
                      setTaskMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      {readUnderstood?.task?.flagged ? (
                        <Flag style={{ color: red[500] }} />
                      ) : (
                        <FlagOutlined style={{ color: grey[500] }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={`${readUnderstood?.task?.flagged ? 'Unflag' : 'Flag'} the task`} />
                  </MenuItem>
                )}
                {(user?._id === readUnderstood?.assignedBy || isAdmin) && !isPortalUser && (
                  <MenuItem
                    onClick={() => {
                      changeOwner();
                      setTaskMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      <Person style={{ color: grey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Change owner" />
                  </MenuItem>
                )}
              </Menu>
              <CrossButton onClick={handleClose} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ '& .full-window-div': { height: '75vh', border: 'none' } }}>
          {readUnderstood?.document?.category !== 'qhub' && (
            <Grid container justifyContent="space-between" alignItems="center">
              <TooltipTypography style={{ fontSize: 20, color: grey[500], marginLeft: '0.7em' }}>
                {readUnderstood?.task?.title}
              </TooltipTypography>
            </Grid>
          )}
          {readUnderstood?.document && currentStep === 0 && (
            <Grid container sx={{ maxHeight: '75vh', p: 1 }}>
              <FilePreviewer
                document={readUnderstood?.document}
                title={readUnderstood?.document?.title}
                src={
                  readUnderstood?.document?.category === 'normal'
                    ? readUnderstood?.documentUrl
                    : readUnderstood?.document?.sections
                }
                extension={
                  readUnderstood?.document?.category === 'normal' ? readUnderstood?.document?.file?.mimetype : 'qhub'
                }
                hasToc={readUnderstood?.document?.hasToc}
                handleScroll={handleScroll}
                enableScroll
              />
            </Grid>
          )}
          {currentStep === 1 && (
            <Grid container sx={{ maxHeight: '75vh', p: 1 }}>
              {readUnderstood?.completedAt && (
                <Grid container sx={{ my: 2, backgroundColor: (t) => t.palette.grey[100], borderRadius: 1, p: 2 }}>
                  <Typography color="text.secondary" sx={{ fontWeight: 'bold' }}>
                    I confirm that I have read and understood this document and that my signature is as valid and
                    legally binding as a handwritten signature.
                  </Typography>
                </Grid>
              )}
              {!readUnderstood?.cancelledAt && !readUnderstood?.completedAt && (
                <Signature
                  view="tabbed"
                  initial={{ signature, firstName, lastName, organization, email }}
                  onChange={(res) => {
                    setSignature(res?.signature || {});
                    setFirstName(res?.firstName || '');
                    setLastName(res?.lastName || '');
                    setOrganization(res?.organization || '');
                    setEmail(res?.email || '');
                    if (res?.signature?.base64Data && res?.firstName && res?.lastName) {
                      setConfirm(true);
                    }
                  }}
                  disabled={!scrollEnd}
                  editable={scrollEnd}
                  additionalComponent={<SignatureAdditionalComponent scrollEnd={scrollEnd} />}
                />
              )}
              {readUnderstood?.completedAt && (
                <Grid container item sm={4}>
                  <Paper variant="outlined" sx={{ p: 1, borderRadius: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{
                        width: '100%',
                        objectFit: 'contain',
                        userSelect: 'none',
                        pointerEvents: 'none',
                        verticalAlign: 'middle',
                      }}
                      src={signature?.base64Data}
                      alt="signature"
                    />
                  </Paper>
                </Grid>
              )}
              <Grid container item sm={4} direction="column" sx={{ ml: readUnderstood?.completedAt ? 2 : 0 }}>
                {readUnderstood?.completedAt && (
                  <>
                    <Typography variant="h6" color="textSecondary" style={{ margin: theme.spacing(0.5) }}>
                      Signed at {formatDate(readUnderstood?.completedAt, 'DD/MM/YYYY hh:mm a')}
                    </Typography>
                    {firstName && lastName && (
                      <Typography variant="h6" color="textSecondary" style={{ margin: theme.spacing(0.5) }}>
                        Signed by {[firstName, lastName].join(' ')}{' '}
                      </Typography>
                    )}
                    {email && (
                      <Typography variant="h6" color="textSecondary" style={{ margin: theme.spacing(0.5) }}>
                        Signed with {email}
                      </Typography>
                    )}
                    {organization && (
                      <Typography variant="h6" color="textSecondary" style={{ margin: theme.spacing(0.5) }}>
                        Signed for {organization}
                      </Typography>
                    )}
                    <Divider inset />
                    <Typography variant="h6" color="textSecondary" style={{ margin: theme.spacing(0.5) }}>
                      {getAssignee()} - {readUnderstood?.assignee}
                    </Typography>
                  </>
                )}
                {readUnderstood?.cancelledAt && (
                  <>
                    {readUnderstood?.cancelledBy ? (
                      <User
                        id={readUnderstood?.cancelledBy}
                        avatarSize={20}
                        additionalText={` cancelled this task at ${formatDate(
                          readUnderstood?.cancelledAt,
                          'DD/MM/YYYY hh:mm a',
                        )} for ${readUnderstood?.cancelReason}`}
                        additionalTextStyles={{ color: red[600], fontWeight: 'bold' }}
                      />
                    ) : (
                      <Typography style={{ fontSize: 16, color: red[600] }}>
                        {readUnderstood?.cancelReason
                          ? `This task was cancelled at ${formatDate(
                              readUnderstood?.cancelledAt,
                              'DD/MM/YYYY hh:mm a',
                            )} for ${readUnderstood?.cancelReason}`
                          : 'This task has been cancelled by the owner'}
                      </Typography>
                    )}
                  </>
                )}
                {(readUnderstood?.completedAt || readUnderstood?.cancelledAt) && (
                  <Grid sx={{ width: '40%', height: '20%' }}>
                    <FileGridItem
                      onSelect={handleFile}
                      title={readUnderstood?.document.title}
                      image={readUnderstood?.document.filePreview}
                      fileKey={readUnderstood?.document.file?.location}
                      extension={readUnderstood?.document?.file?.mimetype}
                      conventionSet={readUnderstood?.document?.conventionSet}
                      selected={readUnderstood?.document?.id}
                      conventionSetParams={readUnderstood?.document?.conventionSetParams}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container direction="row" justifyContent={'flex-start'} alignItems="center">
            {!readUnderstood?.cancelledAt &&
              readUnderstood?.activityId &&
              (user?._id === readUnderstood?.assignedBy || isAdmin || user?._id === readUnderstood?.assignee) &&
              !isPortalUser &&
              isRUPage && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => handleLink(readUnderstood?.activityType, readUnderstood?.activityId)}
                  sx={{ textTransform: 'none', marginRight: '10px' }}
                >
                  Go To Original {formatActivity(readUnderstood?.activityType)}
                </Button>
              )}
            {!readUnderstood?.completedAt && !readUnderstood?.cancelledAt && (
              <Button
                onClick={handleFile}
                children="Launch Document"
                endIcon={<Launch />}
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  mx: 2,
                  color: blue[500],
                  borderColor: blue[600],
                  '&:hover': {
                    borderColor: blue[600],
                    color: blue[600],
                  },
                }}
              />
            )}
            {readUnderstood?.task &&
              !readUnderstood?.cancelledAt &&
              (user?._id === readUnderstood?.assignedBy || isAdmin) &&
              !isPortalUser && (
                <Button
                  onClick={confirmTaskDelete}
                  children="Delete Task"
                  endIcon={<DeleteOutlineRounded />}
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    mx: 2,
                    color: red[500],
                    borderColor: red[600],
                    '&:hover': {
                      borderColor: red[600],
                      color: red[600],
                    },
                  }}
                />
              )}
            {!readUnderstood?.cancelledAt && (
              <Grid item sm={6} sx={{ ml: 'auto' }} container justifyContent="flex-end" alignItems="center">
                {scrollEnd &&
                  !readUnderstood?.completedAt &&
                  !readUnderstood?.cancelledAt &&
                  readUnderstood?.assignee === user?._id && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setCurrentStep(currentStep === 0 ? 1 : 0)}
                      sx={{ textTransform: 'none', marginRight: '10px' }}
                      disabled={!scrollEnd}
                    >
                      {currentStep === 0 ? 'Next' : 'Prev'}
                    </Button>
                  )}
                {ruSaving && (
                  <Typography style={{ fontSize: 14, color: grey[600], marginRight: '1rem' }}>
                    {ruSaving ? 'Saving...' : 'Saved'}
                  </Typography>
                )}
                {confirm &&
                  !readUnderstood?.completedAt &&
                  user?._id === readUnderstood?.assignee &&
                  firstName !== '' &&
                  lastName !== '' &&
                  Object.keys(signature)?.length > 0 && (
                    <SaveButton
                      saving={ruSaving}
                      text={'Complete Task'}
                      onClick={handleDone}
                      startIcon={<ReadUnderstoodIcon style={{ color: 'white' }} />}
                      style={{ textTransform: 'none', marginLeft: 0 }}
                      disabled={ruSaving || !signature || !confirm || Object.values(signature).length === 0}
                    />
                  )}
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </RoundedDialog>
      <SignatureDialog
        open={signatureBox}
        handleClose={() => setSignatureBox(false)}
        handleConfirm={setSignature}
        initial={signature}
      />
      <ReasonDialog open={reasonDialog} handleClose={() => setReasonDialog(false)} handleConfirm={saveReason} />
    </Grid>
  );
};

const SignatureDialog = ({ open, handleClose, handleConfirm, initial = {} }) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    setValue(initial || {});
  }, [initial]);

  function done() {
    handleConfirm(value);
    handleClose();
  }

  return (
    <RoundedDialog titleId={'ruSignature'} open={open} onClose={handleClose}>
      <DialogContent>
        {open && (
          <Grid container justifyContent="center">
            <Canvas onResult={setValue} initial={value} editable={true} savingTimeout={100} scaling={1} view="tabbed" />
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={handleClose} onDone={done} />
    </RoundedDialog>
  );
};

const ReasonDialog = ({ open, handleClose, handleConfirm }) => {
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (open) {
      setReason('');
    }
  }, [open]);

  return (
    <RoundedDialog titleId={'reasonDialog'} open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography style={{ fontSize: 14, color: grey[600] }}>
            Please enter a reason for cancelling this task
          </Typography>
          <TextField
            style={{ marginTop: '1rem' }}
            id="standard-multiline-flexible"
            label="Reason"
            multiline
            rowsMax="4"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
          />
        </Grid>
      </DialogContent>
      <StandardDialogActions
        onClose={handleClose}
        onDone={() => {
          handleConfirm(reason);
          handleClose();
        }}
      />
    </RoundedDialog>
  );
};

export default ReadUnderstoodDialog;
