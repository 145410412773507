import React from 'react';
import { Backdrop, Grid, Typography, Button } from '@mui/material';
import { HomeRounded, ReplayRounded } from '@mui/icons-material';
import { isDev } from '../../../constants';

const CustomError = ({ error }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
      {isDev ? (
        <Grid container justifyContent="center" direction={'column'}>
          <Typography align="center" gutterBottom variant="h6">
            {error.message}
          </Typography>
          <Typography align="center" sx={{ whiteSpace: 'break-spaces' }}>
            {error.stack}
          </Typography>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <Grid container justifyContent="center">
            <Typography style={{ color: 'white' }}>
              There was a client side error in the application. Don't worry, we have been notified about it
            </Typography>
          </Grid>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Button
              variant="contained"
              onClick={() => (window.location.href = '/home')}
              startIcon={<HomeRounded />}
              sx={{ borderRadius: '8px' }}
            >
              Go Home
            </Button>
            <Button
              variant="contained"
              onClick={() => location.reload()}
              sx={{ ml: 2, borderRadius: '8px' }}
              startIcon={<ReplayRounded />}
            >
              Reload page
            </Button>
          </Grid>
        </Grid>
      )}
    </Backdrop>
  );
};

export default CustomError;
