import React, { useEffect, useState } from 'react';
import { DialogContent, Typography, Grid, DialogTitle, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { RoundedDialog, StandardDialogActions } from '../../Global/Components';
import { useQuery } from '../../Global/hooks';
import { Alert, AlertTitle } from '@mui/material';
import axios from 'axios';

export default function PasswordChangePage() {
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [savingPassword, setSavingPassword] = useState(false);

  const { userId } = useParams();

  const { token } = useQuery();

  function savePassword() {
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }
    setSavingPassword(true);
    setError('');
    axios
      .post('/auth/changePassword', { userId, token, password })
      .then((res) => {
        if (res.data.authentication) {
          localStorage.clear();
          localStorage.setItem('token', res.data.authentication.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authentication.name);
          localStorage.setItem('_id', res.data.authentication.id);
          localStorage.setItem('company', res.data.authentication.company);
          localStorage.setItem('access', res.data.authentication.access);
          window.location.href = '/home';
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Something went wrong');
        setTimeout(() => setError(''), 12000);
        setSavingPassword(false);
      });
  }

  return (
    <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
      <DialogTitle sx={{ whiteSpace: 'break-spaces' }}>You need to change your password before proceeding</DialogTitle>
      <DialogContent>
        <Grid container item>
          <Alert severity="info" style={{ width: '100%', marginBottom: 16, borderRadius: 8 }}>
            <AlertTitle>To ensure the security of the account your password must:</AlertTitle>
            <Typography style={{ fontSize: 14 }}>Be at least 8 characters long</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 lowercase letter</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 uppercase letter</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 special character</Typography>
          </Alert>
        </Grid>
        <Grid container>
          <Grid container item sx={{ mt: 2 }}>
            <TextField
              type="password"
              variant="outlined"
              fullWidth
              label="New password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid container item sx={{ mt: 2 }}>
            <TextField
              type="password"
              variant="outlined"
              error={confirmPasswordError}
              helperText={confirmPasswordError ? 'Passwords do not match' : ''}
              fullWidth
              label="Confirm new password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordError(false);
              }}
            />
          </Grid>
        </Grid>
        {error && (
          <Alert severity="error" style={{ width: '100%', marginTop: 8, borderRadius: 8 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <StandardDialogActions saving={savingPassword} onDone={savePassword} disableDone={!password} />
    </RoundedDialog>
  );
}
