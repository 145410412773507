import * as Icons from '@mui/icons-material';
import { ImageSearch } from '@mui/icons-material';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Divider,
  TextField,
  Typography,
  Button,
  Box,
  Tooltip,
  DialogTitle,
  createTheme,
} from '@mui/material';
import { createElement, useState, useMemo, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';
import RoundedDialog from './RoundedDialog';
import StandardDialogActions from './StandardDialogActions';
import ColorPicker from './colorPicker';

function splitCamelCaseString(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

const iconArray = Object.keys(Icons).map((iconName) => ({
  name: splitCamelCaseString(iconName),
  techName: iconName,
  component: createElement(Icons[iconName]),
}));

export default function IconPicker({ onSelect, icon, size = 'small' }) {
  const [iconPicker, setIconPicker] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [color, setColor] = useState('');
  const theme = createTheme();
  function handleChange(icon) {
    const coloredElement = { icon, color };
    setSelectedIcon(coloredElement);
    onSelect(coloredElement);
    setIconPicker(false);
  }

  return size === 'small' ? (
    <Grid container item>
      <Tooltip title="Select an icon">
        <IconButton onClick={() => setIconPicker(true)}>
          {icon ? createElement(Icons[icon?.icon], { style: { color: icon?.color } }) : <ImageSearch />}
        </IconButton>
      </Tooltip>
      <IconPickerView
        open={iconPicker}
        onClose={() => setIconPicker(false)}
        onSelect={handleChange}
        color={color}
        setColor={setColor}
      />
    </Grid>
  ) : (
    <Grid container item>
      <IconButton
        size="small"
        component="label"
        sx={{
          width: 150,
          height: '100%',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          gap: 1,
        }}
        className="non-draggable"
        onClick={() => setIconPicker(true)}
      >
        {icon ? createElement(Icons[icon?.icon], { style: { color: icon?.color } }) : <ImageSearch />}
        <Typography>{'Select an Icon'}</Typography>
      </IconButton>
      <IconPickerView
        open={iconPicker}
        onClose={() => setIconPicker(false)}
        onSelect={handleChange}
        color={color}
        setColor={setColor}
      />
    </Grid>
  );
}

function IconPickerView({ open, onClose, onSelect, color, setColor }) {
  const [filter, setFilter] = useState('Filled');
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleIcons, setVisibleIcons] = useState(20);
  const [colorPicker, setColorPicker] = useState(false);
  const observer = useRef();
  //eslint-disable-next-line
  const filterValues = ['Outlined', 'Rounded', 'TwoTone', 'Sharp'];

  function handleChange(e) {
    setFilter(e.target.value);
  }
  //eslint-disable-next-line
  const handleSearchChange = useCallback(
    debounce((e) => {
      setSearchTerm(e.target.value.toLowerCase());
    }, 300),
    [],
  );
  const filteredIcons = useMemo(() => {
    // Разбиваем строку поиска на массив слов
    const searchWords = searchTerm
      .toLowerCase()
      .split(' ')
      .filter((word) => word.length > 0);

    return iconArray.filter((icon) => {
      const iconName = icon.techName.toLowerCase();

      const matchesSearch = searchWords.every((word) => iconName.includes(word));

      const matchesFilter =
        filter === 'Filled' || !filter
          ? !filterValues.some((value) => icon.techName.includes(value))
          : icon.techName.includes(filter);

      return matchesSearch && matchesFilter;
    });
    // eslint-disable-next-line
  }, [filter, searchTerm, filterValues]);

  const loadMoreIcons = useCallback(() => {
    setVisibleIcons((prev) => prev + 20);
  }, []);

  const lastIconRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreIcons();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadMoreIcons],
  );

  return (
    <RoundedDialog open={open} onClose={onClose}>
      <DialogTitle>Pick an Icon</DialogTitle>
      <Grid container direction="row" flexWrap="nowrap" item width={600} height={500}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="column"
          sx={{ height: '100%', p: 2 }}
          flexWrap="nowrap"
        >
          <RadioGroup value={filter} onChange={handleChange} column width="20%">
            <FormControlLabel value="Filled" control={<Radio />} label="Filled" />
            <FormControlLabel value="Outlined" control={<Radio />} label="Outlined" />
            <FormControlLabel value="Rounded" control={<Radio />} label="Rounded" />
            <FormControlLabel value="TwoTone" control={<Radio />} label="Two Tone" />
            <FormControlLabel value="Sharp" control={<Radio />} label="Sharp" />
          </RadioGroup>
          <Grid container item>
            <Button onClick={(e) => setColorPicker(e.currentTarget)} variant="outlined">
              <Box backgroundColor={color}></Box>Pick a Colour
            </Button>
            <ColorPicker
              anchor={colorPicker}
              open={Boolean(colorPicker)}
              onClose={() => setColorPicker(null)}
              onResult={(color) => setColor(color)}
              customPicker
            />
          </Grid>
        </Grid>
        <Divider orientation={'vertical'} variant="middle" width={2} spacing={2} />
        <Grid container minWidth="75%" minHeight="90%">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search icons..."
            onChange={handleSearchChange}
            size="small"
            sx={{
              m: 1,
              borderRadius: 10,
              '& .MuiOutlinedInput-root': {
                borderRadius: 10,
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '14px',
              },
            }}
          />
          <Grid
            container
            item
            spacing={2}
            margin={2}
            justifyContent="center"
            alignItems="center"
            overflow="auto"
            height="72%"
            sx={{
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {filteredIcons.slice(0, visibleIcons).map((icon, index) => (
              <IconButton
                item
                key={icon.techName}
                ref={index === visibleIcons - 1 ? lastIconRef : null}
                onClick={() => onSelect(icon.techName)}
                sx={{
                  height: 100,
                  width: 100,
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 1,
                  justifyContent: 'flex-start',
                  color: color,
                }}
              >
                {icon.component}
                <Typography
                  sx={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    textAlign: 'center',
                    marginTop: 2,
                    fontSize: 12,
                  }}
                >
                  {icon.name}
                </Typography>
              </IconButton>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <StandardDialogActions onClose={onClose}></StandardDialogActions>
    </RoundedDialog>
  );
}
