import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { InputAdornment, Grid } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import OutlinedButton from '../../../../../Global/OutlinedButton';
import { RoundedDialog, StandardDialogActions } from '../../../../../Global/Components';

export default function EmailDialog({ open, handleClose, handleConfirm }) {
  const [email, setEmail] = useState(['']);

  const handleChange = (value, a) => {
    let update = email.map((x, i) => {
      if (i === a) {
        return value;
      } else {
        return x;
      }
    });
    setEmail(update);
  };

  useEffect(() => {
    if (!open) return;
    setEmail(['']);
  }, [open]);

  return (
    <RoundedDialog open={open} onClose={handleClose} aria-labelledby="email-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="email-dialog-title">Enter Email Address</DialogTitle>
      <DialogContent dividers>
        <OutlinedButton onClick={() => setEmail([...email, ''])} startIcon={<Add />}>
          Add New Email Address
        </OutlinedButton>
        <Grid container direction="column" spacing={2} sx={{ p: 2, my: 1 }}>
          {email?.map((a, i) => (
            <TextField
              autoFocus
              key={i}
              margin="dense"
              id="email"
              label="Email"
              type="email"
              value={a}
              onChange={(event) => handleChange(event.target.value, i)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Delete onClick={() => setEmail(email.filter((x, index) => i !== index))} />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              sx={{ my: 1 }}
            />
          ))}
        </Grid>
      </DialogContent>
      <StandardDialogActions
        onDone={() => handleConfirm(email.toString())}
        onClose={handleClose}
        doneButtonText={'Confirm'}
        closeButtonText={'Cancel'}
      />
    </RoundedDialog>
  );
}
