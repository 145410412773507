import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentById } from '../../../redux/actions/component';
import { previewFile } from '../../Hubs/dochub/redux/actions/file';
import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { Grid, Box } from '@mui/material';
import { ListItemSecondaryAction } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import FileIconAvatar from '../../Hubs/dochub/components/FileIconAvatar';
import TooltipTypography from './TooltipTypography';
import User from './User';
import useDocumentName from '../../Hubs/dochub/hooks/useDocumentName';
import axios from 'axios';

export default function Document({
  id,
  clickable = true,
  onlyAvatar,
  fullWidth,
  action,
  additionalText,
  onClick,
  avatarSize = 40,
  buttonStyle,
  markPortal,
  selected = false,
  document,
  titleFontSize = 16,
  showOwner,
  ...props
}) {
  const fontSize = Math.round(avatarSize / 2);
  const padding = Math.round(avatarSize / 8);
  const [prefixObject, setPrefixObject] = useState('');

  const dispatch = useDispatch();
  const documentStates = useSelector(({ component }) => component);

  const documentState = document || documentStates[id];
  const prefix = documentState?.prefix;

  useEffect(() => {
    if (document) return;
    if (!id || typeof id !== 'string') return;
    dispatch(getDocumentById(id));
  }, [dispatch, id, document]);

  useEffect(() => {
    if (!prefix || typeof prefix !== 'string' || prefixObject?._id === prefix) return;
    axios
      .get(`dochub/settings/prefixes/${prefix}`)
      .then((res) => {
        setPrefixObject(res.data);
      })
      .catch((err) => console.log(err));
  }, [prefix, prefixObject]);

  const title = useDocumentName({
    title: documentState?.title,
    setId: documentState?.conventionSet,
    initial: documentState?.conventionSetParams,
    sequence: documentState?.sequence,
  });

  const generatePrefixTitle = () => {
    if (prefix) {
      if (typeof prefix === 'string' && prefixObject?.name) {
        return (
          <Box
            sx={{
              color: `#${prefixObject?.color || '000'}`,
              fontWeight: 'bold',
              mr: 0.5,
            }}
            component="span"
          >
            {prefixObject?.name}-{documentState?.sequence}
          </Box>
        );
      } else if (prefix?.name) {
        return (
          <Box
            sx={{
              color: `#${prefix?.color || '000'}`,
              fontWeight: 'bold',
              mr: 0.5,
            }}
            component="span"
          >
            {prefix?.name}-{documentState?.sequence}
          </Box>
        );
      }
    }
    return null;
  };

  return (
    <Grid container={fullWidth} {...props} onClick={(e) => e.stopPropagation()}>
      <ListItemButton
        selected={selected}
        button={clickable ? Boolean(documentState)?.toString() : undefined}
        style={{
          borderRadius: 5,
          padding: `${padding}px ${padding * 2}px`,
          width: fullWidth ? '100%' : 'auto',
          ...buttonStyle,
        }}
        onClick={(e) => {
          if (!clickable) return;
          if (onClick) {
            onClick();
          } else {
            dispatch(previewFile(documentState));
          }
        }}
      >
        {onlyAvatar && (
          <FileIconAvatar document={documentState} extraStyles={{ width: avatarSize, height: avatarSize, fontSize }} />
        )}
        {!onlyAvatar && (
          <>
            <ListItemAvatar style={{ minWidth: 'auto', paddingRight: padding * 3 }}>
              {documentState ? (
                <FileIconAvatar
                  document={documentState}
                  extraStyles={{ width: avatarSize, height: avatarSize, fontSize }}
                  fontSize={avatarSize}
                />
              ) : documentState?.loading ? (
                <Skeleton variant="circular" width={avatarSize} height={avatarSize} />
              ) : (
                ''
              )}
            </ListItemAvatar>

            {documentState ? (
              <>
                <ListItemText
                  disableTypography
                  primary={
                    <TooltipTypography
                      style={{ fontSize: titleFontSize, marginRight: action ? 25 : 'inherit', display: 'flex' }}
                    >
                      {generatePrefixTitle()}
                      {title}
                    </TooltipTypography>
                  }
                />

                {action && <ListItemSecondaryAction style={{ marginLeft: 'auto' }}>{action}</ListItemSecondaryAction>}
              </>
            ) : documentState?.loading ? (
              <div>
                <Skeleton width={100} />
              </div>
            ) : (
              document?.title || 'N/A'
            )}
          </>
        )}
        {showOwner && documentState && documentState?.created_by && (
          <User id={documentState?.created_by} onlyAvatar avatarSize={avatarSize} sx={{ marginLeft: 'auto' }} />
        )}
      </ListItemButton>
    </Grid>
  );
}
