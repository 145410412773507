import { combineReducers } from 'redux';

import { user } from '../../Components/Profile/redux/reducers';
import { admin } from '../../Components/AdminPanel/redux/reducers';

import {
  dochub,
  settings,
  builder,
  path,
  filter,
  revision,
  home,
  ru,
  ruView,
  qTemplate,
  folderTemplate,
  normalTemplate,
  sets,
  draft,
  review,
} from '../../Components/Hubs/dochub/redux/reducers';

import assistant from './assistant';
import file from './file';
import approval from './approval';
import general from './general';
import component from './component';
import supergroup from '../../Components/SuperGroupAdmin/redux';

const rootReducer = combineReducers({
  dochub,
  settings,
  builder,
  path,
  profile: user,
  admin,
  review,
  file,
  approval,
  assistantHub: assistant,
  general,
  revision,
  filter,
  home,
  ru,
  ruView,
  component,
  qTemplate,
  folderTemplate,
  normalTemplate,
  sets,
  draft,
  supergroup,
});

export default rootReducer;
