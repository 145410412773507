import {
  NORMALTEMPLATESLIST_ATTEMPT,
  NORMALTEMPLATESACTION_ATTEMPT,
  NORMALTEMPLATES_ERROR,
  GETNORMALTEMPLATES,
  GETNORMALTEMPLATE,
  ADDNORMALTEMPLATE,
  UPDATENORMALTEMPLATE,
  DELETENORMALTEMPLATE,
  USENORMALTEMPLATE,
} from '../constants';

const initialState = {
  listLoading: false,
  actionLoading: false,
  error: null,
  normalTemplates: [],
  normalTemplate: null,
  redirectLink: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NORMALTEMPLATESLIST_ATTEMPT:
      return {
        ...state,
        listLoading: true,
        error: null,
      };
    case NORMALTEMPLATESACTION_ATTEMPT:
      return {
        ...state,
        actionLoading: true,
        error: null,
      };
    case NORMALTEMPLATES_ERROR:
      return {
        ...state,
        actionLoading: false,
        listLoading: false,
        error: action.payload,
      };
    case GETNORMALTEMPLATES:
      return {
        ...state,
        listLoading: false,
        normalTemplates: action.payload,
      };
    case GETNORMALTEMPLATE:
      return {
        ...state,
        listLoading: false,
        normalTemplate: action.payload,
      };
    case ADDNORMALTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        normalTemplates: [...state.normalTemplates, action.payload],
      };
    case UPDATENORMALTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        normalTemplates: state.normalTemplates.map((template) => {
          if (template.id === action.payload.id) {
            return action.payload;
          }
          return template;
        }),
      };
    case DELETENORMALTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        normalTemplates: state.normalTemplates.filter((template) => template.id !== action.payload.id),
      };
    case USENORMALTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        redirectLink: action.payload,
      };
    default:
      return state;
  }
}
