import React, { useState, useEffect, useRef } from 'react';
import { Grid, Paper, Tooltip, IconButton, Button, ButtonGroup, InputBase, ListItemText } from '@mui/material';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { AddRounded, DeleteOutlineRounded, PaletteRounded } from '@mui/icons-material';
import { grey, red, blue } from '@mui/material/colors';
import TextStyleDialog from './TextStyleDialog';
import FieldTypes from './FieldTypes';
import { isNumber, isArray, isEqual, isFunction } from 'lodash';
import Draggable from 'react-draggable';
import { v4 } from 'uuid';

export default function PageGridTableBuilder({ initial, onChange }) {
  const [hovered, setHovered] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [typeMenuAnchor, setTypeMenuAnchor] = useState(null);
  const [titleStyleDialog, setTitleStyleDialog] = useState(false);
  const timer = useRef(null);

  const availbaleColumnTypes = ['text', 'number', 'email', 'phone'];
  const HEADER_HEIGHT = 30;
  const DEFAULT_ROW_HEIGHT = 25;
  const MIN_ROW_HEIGHT = 25;
  const DEFAULT_COLUMN_WIDTH = 200;
  const MIN_COLUMN_WIDTH = 50;
  const SAVING_TIMEOUT = 1200;

  useEffect(() => {
    if (isArray(initial?.rows) && !isEqual(initial.rows, rows)) {
      setRows(initial.rows);
    }
    if (isArray(initial?.columns) && !isEqual(initial.columns, columns)) {
      setColumns(initial.columns);
    }
  }, [initial]); // eslint-disable-line

  function handleChange(value) {
    if (!isFunction(onChange)) return;

    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => onChange(value), SAVING_TIMEOUT);
  }

  function addColumn() {
    const column = { id: v4(), title: '', fieldType: 'text', width: DEFAULT_COLUMN_WIDTH, type: 'text' };
    columns.push(column);
    setColumns([...columns]);
    handleChange({ columns, rows });
  }

  function addRow() {
    const row = { id: v4(), height: DEFAULT_ROW_HEIGHT, data: {} };
    rows.push(row);
    setRows([...rows]);
    handleChange({ columns, rows });
  }

  function deleteColumn(id) {
    const remainingColumns = columns.filter((x) => x.id !== id);
    setColumns(remainingColumns);
    handleChange({ columns: remainingColumns, rows });
    setHoveredColumn(null);
  }

  function deleteRow(id) {
    const remainingRows = rows.filter((x) => x.id !== id);
    handleChange({ columns, rows: remainingRows });
    setRows(remainingRows);
    setHoveredRow(null);
  }

  function saveColumnType(columnId, type) {
    const index = columns.findIndex((x) => x.id === columnId);
    if (index === -1) return;
    const oldType = columns[index].type;
    columns[index].type = type;
    if (oldType !== 'number' && type === 'number') {
      for (let i = 0; i < rows.length; ++i) {
        const num = Number(rows[i].data[columnId]?.value);
        rows[i].data[columnId] = { value: isNumber(num) ? num : '' };
      }
    }

    setColumns([...columns]);
    handleChange({ columns, rows });
  }

  function saveColumnStyles(columnId, style) {
    const index = columns.findIndex((x) => x.id === columnId);
    if (index === -1) return;
    columns[index].style = style;
    setColumns([...columns]);
    handleChange({ columns, rows });
  }

  return (
    <Grid
      container
      style={{ position: 'relative', minHeight: DEFAULT_ROW_HEIGHT * 2, alignSelf: 'flex-start', height: '100%' }}
      alignItems="flex-start"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="non-draggable"
    >
      <Grid container wrap="nowrap" style={{ position: 'relative', width: 'auto' }}>
        {columns.map((column, index) => (
          <Grid key={column.id} container style={{ width: column.width || DEFAULT_COLUMN_WIDTH }}>
            <Grid
              container
              style={{ height: HEADER_HEIGHT, border: '1px solid black', paddingLeft: '3%', position: 'relative' }}
              wrap="nowrap"
              onMouseEnter={() => setHoveredColumn(column.id)}
              onMouseLeave={() => setHoveredColumn(null)}
            >
              <InputBase
                value={column.title}
                placeholder="Column title"
                onChange={(e) => {
                  columns[index].title = e.target.value;
                  setColumns([...columns]);
                  handleChange({ columns, rows });
                }}
                inputProps={{ maxLength: 128 }}
                className="non-draggable"
                style={{ flexGrow: 1 }}
              />
              {(hoveredColumn === column.id || (typeMenuAnchor && column.id === selectedColumn?.id)) && (
                <Grid container style={{ position: 'absolute', top: -38, width: 0 }}>
                  <Paper>
                    <ButtonGroup style={{ height: '100%' }}>
                      <Tooltip title={FieldTypes[column.type]?.text} placement="top">
                        <Button
                          onClick={(e) => {
                            setSelectedColumn(column);
                            setTypeMenuAnchor(e.currentTarget);
                          }}
                        >
                          {FieldTypes[column.type]?.icon}
                        </Button>
                      </Tooltip>

                      <Tooltip title={column.style ? 'Change column style' : 'Add column style'} placement="top">
                        <Button
                          onClick={() => {
                            setSelectedColumn(column);
                            setTitleStyleDialog(true);
                          }}
                        >
                          <PaletteRounded style={{ color: column.style ? blue[900] : grey[500] }} />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Delete column" placement="top">
                        <Button onClick={() => deleteColumn(column.id)}>
                          <DeleteOutlineRounded style={{ color: red[500] }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </Paper>
                </Grid>
              )}
              <Draggable
                axis="x"
                position={{ x: 0 }}
                zIndex={999}
                onDrag={(event, args) => {
                  const width = (columns[index].width || DEFAULT_COLUMN_WIDTH) + args.deltaX;
                  if (width < MIN_COLUMN_WIDTH) return;
                  columns[index].width = width;
                  setColumns([...columns]);
                  handleChange({ columns, rows });
                }}
              >
                <div style={{ width: 5, minWidth: 5, height: '100%', cursor: 'col-resize' }} />
              </Draggable>
            </Grid>
            {rows.map((row, rowIndex) => (
              <Grid
                key={row.id}
                container
                style={{ height: row.height, border: '1px solid black' }}
                onMouseEnter={() => setHoveredRow(row.id)}
                onMouseLeave={() => setHoveredRow(null)}
                alignItems="flex-end"
                alignContent="flex-end"
              >
                <Grid container alignItems="flex-end" alignContent="flex-end" wrap="nowrap">
                  <InputBase
                    value={row.data ? row.data[column.id]?.value || '' : ''}
                    type={column.type === 'number' ? 'number' : 'text'}
                    onChange={(e) => {
                      rows[rowIndex].data[column.id] = { value: e.target.value };
                      setRows([...rows]);
                      handleChange({ columns, rows });
                    }}
                    inputProps={{ style: { padding: '0 0 0 4px' } }}
                    style={{ flexGrow: 1 }}
                  />

                  <Draggable
                    axis="x"
                    position={{ x: 0 }}
                    zIndex={999}
                    onDrag={(event, args) => {
                      const width = (columns[index].width || DEFAULT_COLUMN_WIDTH) + args.deltaX;
                      if (width < MIN_COLUMN_WIDTH) return;
                      columns[index].width = width;
                      setColumns([...columns]);
                      handleChange({ columns, rows });
                    }}
                  >
                    <div style={{ width: 5, minWidth: 5, height: row.height - 5, cursor: 'col-resize' }} />
                  </Draggable>
                </Grid>

                <Grid container>
                  <Draggable
                    axis="y"
                    position={{ y: 0 }}
                    zIndex={999}
                    onDrag={(event, args) => {
                      const height = rows[rowIndex].height + args.deltaY;
                      if (height < MIN_ROW_HEIGHT) return;
                      rows[rowIndex].height = height;
                      setRows([...rows]);
                      handleChange({ columns, rows });
                    }}
                  >
                    <div style={{ width: '100%', height: 4, minHeight: 4, cursor: 'row-resize' }} />
                  </Draggable>
                </Grid>
              </Grid>
            ))}
            {hoveredRow && (
              <Grid
                container
                style={{
                  position: 'absolute',
                  top: HEADER_HEIGHT,
                  right: -1 * DEFAULT_ROW_HEIGHT,
                  width: DEFAULT_ROW_HEIGHT,
                }}
              >
                {rows.map((row) => (
                  <Grid
                    key={row.id}
                    container
                    style={{ height: row.height }}
                    onMouseEnter={() => setHoveredRow(row.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    {row.id === hoveredRow && (
                      <IconButton
                        onClick={() => deleteRow(row.id)}
                        style={{ width: DEFAULT_ROW_HEIGHT, height: DEFAULT_ROW_HEIGHT }}
                      >
                        <DeleteOutlineRounded fontSize="small" style={{ color: red[500] }} />
                      </IconButton>
                    )}
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        ))}
        {(hovered || rows.length === 0) && (
          <Grid container alignItems="center" style={{ position: 'absolute', bottom: -50, left: 0, height: 50 }}>
            <Paper>
              <ButtonGroup size="small">
                <Button size="small" startIcon={<AddRounded />} onClick={addColumn} style={{ color: grey[600] }}>
                  column
                </Button>
                {columns.length > 0 && (
                  <Button size="small" startIcon={<AddRounded />} onClick={addRow} style={{ color: grey[600] }}>
                    row
                  </Button>
                )}
              </ButtonGroup>
            </Paper>
          </Grid>
        )}
      </Grid>

      <Menu anchorEl={typeMenuAnchor} open={Boolean(typeMenuAnchor)} onClose={() => setTypeMenuAnchor(null)}>
        {availbaleColumnTypes.map((type) => (
          <MenuItem
            key={type}
            onClick={() => {
              saveColumnType(selectedColumn?.id, type);
              setTypeMenuAnchor(null);
            }}
          >
            <ListItemIcon>{FieldTypes[type]?.icon}</ListItemIcon>
            <ListItemText primary={FieldTypes[type]?.text} />
          </MenuItem>
        ))}
      </Menu>
      <TextStyleDialog
        open={titleStyleDialog}
        onClose={() => setTitleStyleDialog(false)}
        initial={selectedColumn?.style}
        initialText={selectedColumn?.title || 'Column title'}
        onResult={(res) => saveColumnStyles(selectedColumn?.id, res)}
        withFormat
      />
    </Grid>
  );
}
