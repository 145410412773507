import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DialogTitle, DialogContent, Grid, Typography, TextField, MenuItem, Checkbox, IconButton } from '@mui/material';
import { RadioGroup, Radio, FormControlLabel, Button, FormControl, Select, InputLabel, Chip } from '@mui/material';
import { RoundedDialog, StandardDialogActions, NumberRangePicker, UserAutocomplete } from '../Components';
import { SearchMenu, UserChip } from '../Components';
import { isArray, isEmpty } from 'lodash';
import StateManager from '../StateManager';
import axios from 'axios';
import moment from 'moment';
import { DeleteOutlineRounded, ArrowDropDownRounded, AddCircleOutlineRounded } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import { v4 } from 'uuid';

const filledTypes = ['textArea', 'signature', 'image', 'file', 'phone', 'email', 'time', 'people'];
const optionTypes = ['status', 'dropbox', 'tickbox', 'conditional'];
const textTypes = ['text'];
const numberTypes = ['number', 'calculation', 'weightedList'];
const dateTypes = ['date', 'datetime'];
const userTypes = ['user'];

const daysOptions = {
  today: 0,
  yesterday: -1,
  tomorrow: 1,
  past: null,
  pastOrMore: null,
  pastOrLess: null,
  until: null,
  untilOrMore: null,
  untilOrLess: null,
};

export default function ConditionsDialog({
  open,
  onClose,
  initial,
  onResult,
  items,
  type = 'field',
  withPortal = false,
}) {
  const [fieldId, setFieldId] = useState('');
  const [actionType, setActionType] = useState('hidden');
  const [dataSet, setDataSet] = useState(null);
  const [loadingDataSet, setLoadingDataSet] = useState(null);
  const [selectedDataSetRow, setSelectedDataSetRow] = useState(null);
  const [basedOnPortal, setBasedOnPortal] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState('any');
  const [selectedDateOption, setSelectedDateOption] = useState('today');
  const [selectedPortals, setSelectedPortals] = useState([]);
  const [textValue, setTextValue] = useState('');
  const [numberRange, setNumberRange] = useState(null);
  const [days, setDays] = useState(7);
  const [userSelectType, setUserSelectType] = useState('any');
  const [selectedUser, setSelectedUser] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menu2Anchor, setMenu2Anchor] = useState(null);
  const [keepValue, setKeepValue] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [checkedPortals, setCheckedPortals] = useState([]);
  const [portals, setPortals] = useState(null);

  const isPortalsLoaded = useRef(false);
  const isFetching = useRef(false);

  const [itemsToRender, setItemsToRender] = useState([]);

  useEffect(() => {
    if (!isArray(items)) {
      setItemsToRender([]);
      return;
    }

    const result = [...items];

    if (withPortal && !result.some((x) => x.basedOnPortal)) {
      result.push({
        title: 'Portal is',
        fieldType: 'portal',
        id: null,
        basedOnPortal: true,
        groupId: 'portal',
        groupTitle: 'Portals',
      });
    }

    setItemsToRender(result);
  }, [items, withPortal]);

  let selectedField = itemsToRender.find((x) => {
    if (!x.id && x.basedOnPortal && basedOnPortal) return x;
    return x.id === fieldId;
  });
  let selectedType = selectedField?.fieldType;
  let selectedFieldTitle = selectedField?.title || '';

  const filledTrigger = filledTypes.includes(selectedType);

  useEffect(() => {
    if (!open) return;
    //console.log('initial', initial);
    if (initial) {
      setActionType(initial.actionType || 'hidden');
      setKeepValue(initial.keepValue || false);
      if (isArray(initial.conditions) && !isEmpty(initial.conditions)) {
        if (initial.conditions.length === 1) {
          setFieldId(initial.conditions[0].fieldId || '');
          setSelectedOptionId(initial.conditions[0].trigger?.selectedOptionId || 'any');
          setTextValue(initial.conditions[0]?.trigger.textValue || '');
          setNumberRange(initial.conditions[0]?.trigger.numberRange || null);
          setSelectedDateOption(initial.conditions[0].trigger?.selectedDateOption || 'today');
          setDays(initial.conditions[0].trigger?.days || 7);
          setUserSelectType(initial.conditions[0].trigger?.userSelectType || 'any');
          setSelectedUser(initial.conditions[0].trigger?.selectedUser || null);
          setSelectedPortals(initial.conditions[0].trigger?.selectedPortals || null);
          setCheckedPortals(initial.conditions[0].trigger?.selectedPortals?.map((portal) => portal._id) || null);
          setBasedOnPortal(initial.conditions[0].basedOnPortals || null);
          setConditions([]);
        } else {
          setConditions(initial.conditions);
          nullifyEditor();
        }
      } else {
        setFieldId(initial.fieldId || '');
        setSelectedOptionId(initial.trigger?.selectedOptionId || 'any');
        setTextValue(initial?.trigger.textValue || '');
        setNumberRange(initial?.trigger.numberRange || null);
        setSelectedDateOption(initial.trigger?.selectedDateOption || 'today');
        setDays(initial.trigger?.days || 7);
        setUserSelectType(initial.trigger?.userSelectType || 'any');
        setSelectedUser(initial.trigger?.selectedUser || null);
        setSelectedPortals(initial.trigger?.selectedPortals);
        setCheckedPortals(initial.trigger?.selectedPortals.map((portal) => portal._id));
        setBasedOnPortal(initial?.basedOnPortals || null);
      }
    } else {
      setActionType('hidden');
      nullifyEditor();
      setConditions([]);
    }
    // eslint-disable-next-line
  }, [initial, open]);

  const getPortals = useCallback(() => {
    if (!isPortalsLoaded.current && !isFetching.current) {
      isFetching.current = true;
      axios
        .get('/portal/management/getPortalSpaces')
        .then((res) => {
          const data = res.data.spaces.map((space) => ({ _id: space._id, title: space.title }));
          setPortals(data);
          isPortalsLoaded.current = true;
          isFetching.current = false;
        })
        .catch(() => {
          isFetching.current = false;
        });
    }
  }, []);

  useEffect(() => {
    if (open) {
      getPortals();
    }
    // eslint-disable-next-line
  }, [open]);

  function nullifyEditor() {
    setFieldId('');
    setSelectedOptionId('any');
    setTextValue('');
    setNumberRange(null);
    setSelectedDateOption('today');
    setDays(7);
    setUserSelectType('any');
    setSelectedUser(null);
    setSelectedPortals([]);
    setCheckedPortals([]);
    setMenu2Anchor(null);
    setBasedOnPortal(null);
    selectedField = null;
    selectedFieldTitle = null;
    selectedType = null;
  }

  function formatDateOption(option) {
    let content = null;
    if (option === 'today') {
      content = (
        <>
          is the <b>{selectedFieldTitle}</b>
        </>
      );
    } else if (option === 'yesterday') {
      content = (
        <>
          is the day after the <b>{selectedFieldTitle}</b>
        </>
      );
    } else if (option === 'tomorrow') {
      content = (
        <>
          is the day before the <b>{selectedFieldTitle}</b>
        </>
      );
    } else if (option === 'past') {
      content = (
        <>
          is passed the <b>{selectedFieldTitle}</b>, by <b>{days}</b> days
        </>
      );
    } else if (option === 'pastOrMore') {
      content = (
        <>
          is passed the <b>{selectedFieldTitle}</b>, by <b>{days}</b> days or more
        </>
      );
    } else if (option === 'pastOrLess') {
      content = (
        <>
          is passed the <b>{selectedFieldTitle}</b>, by up to <b>{days}</b> days
        </>
      );
    } else if (option === 'until') {
      content = (
        <>
          is <b>{days}</b> days before the <b>{selectedFieldTitle}</b>
        </>
      );
    } else if (option === 'untilOrMore') {
      content = (
        <>
          is <b>{days}</b> days or more, before the <b>{selectedFieldTitle}</b>
        </>
      );
    } else if (option === 'untilOrLess') {
      content = (
        <>
          is <b>{days}</b> days or less, before the <b>{selectedFieldTitle}</b>
        </>
      );
    }

    return <Typography>{content}</Typography>;
  }

  function formatExampleForDateOption(option) {
    let content = <></>;
    if (daysOptions[option] != null) return null;

    if (option === 'past') {
      content = <>({moment().add(days, 'days').format('D MMM')})</>;
    } else if (option === 'pastOrMore') {
      content = <>({moment().add(days, 'days').format('D MMM')} or later)</>;
    } else if (option === 'pastOrLess') {
      content = (
        <>
          ({moment().add(1, 'days').format('D MMM')} - {moment().add(days, 'days').format('D MMM')})
        </>
      );
    } else if (option === 'until') {
      content = <>({moment().subtract(days, 'days').format('D MMM')})</>;
    } else if (option === 'untilOrMore') {
      content = <>({moment().subtract(days, 'days').format('D MMM')} or earlier)</>;
    } else if (option === 'untilOrLess') {
      content = (
        <>
          ({moment().subtract(days, 'days').format('D MMM')} - {moment().subtract(1, 'days').format('D MMM')})
        </>
      );
    }

    return (
      <Typography color="textSecondary" style={{ marginLeft: 16, fontSize: 14 }}>
        {content}
      </Typography>
    );
  }

  function getTriggerText(item, trigger) {
    const type = item?.fieldType;
    const itemTitle = item?.title;
    if (filledTypes.includes(type)) {
      return (
        <>
          <b>{itemTitle}</b> is filled
        </>
      );
    } else if (optionTypes.includes(type)) {
      if (!isArray(item?.options)) return null;
      const selectedOption = item.options.find((x) => x.id === trigger.selectedOptionId);
      if (!selectedOption) return null;
      return (
        <>
          <b>{itemTitle}</b> is <span style={{ fontWeight: 500 }}>{selectedOption.text}</span>
        </>
      );
    } else if (textTypes.includes(type)) {
      return (
        <>
          <b>{itemTitle}</b> is <span style={{ fontWeight: 500 }}>{trigger.textValue}</span>
        </>
      );
    } else if (numberTypes.includes(type) && trigger.numberRange) {
      return (
        <>
          <b>{itemTitle}</b>
          {'   '}
          is
          {'   '}
          <b>{trigger.numberRange.operator}</b>
          {'   '}
          {trigger.numberRange.operator === 'between'
            ? `${trigger.numberRange.from} and ${trigger.numberRange.to}`
            : ''}
          {'   '}
          {trigger.numberRange.operator !== 'between' ? trigger.numberRange.number : ''}
        </>
      );
    } else if (dateTypes.includes(type)) {
      const option = trigger.selectedDateOption;
      if (option === 'today') {
        return (
          <>
            today is the <b>{itemTitle}</b>
          </>
        );
      } else if (option === 'yesterday') {
        return (
          <>
            today is the day after the <b>{itemTitle}</b>
          </>
        );
      } else if (option === 'tomorrow') {
        return (
          <>
            today is the day before the <b>{itemTitle}</b>
          </>
        );
      } else if (option === 'past') {
        return (
          <>
            today is passed the <b>{itemTitle}</b>, by <b>{trigger.days}</b> days
          </>
        );
      } else if (option === 'pastOrMore') {
        return (
          <>
            today is passed the <b>{itemTitle}</b>, by <b>{trigger.days}</b> days or more
          </>
        );
      } else if (option === 'pastOrLess') {
        return (
          <>
            today is passed the <b>{itemTitle}</b>, by up to <b>{trigger.days}</b> days
          </>
        );
      } else if (option === 'until') {
        return (
          <>
            today is <b>{trigger.days}</b> days before the <b>{itemTitle}</b>
          </>
        );
      } else if (option === 'untilOrMore') {
        return (
          <>
            today is <b>{trigger.days}</b> days or more, before the <b>{itemTitle}</b>
          </>
        );
      } else if (option === 'untilOrLess') {
        return (
          <>
            today is <b>{trigger.days}</b> days or less, before the <b>{itemTitle}</b>
          </>
        );
      }
    } else if (userTypes.includes(type)) {
      return <UserChip id={trigger.selectedUser} />;
    } else if (type === 'portal' && isArray(trigger.selectedPortals)) {
      return (
        <>
          portal is
          {trigger.selectedPortals.map((p) => (
            <Chip key={p._id} label={p.title} sx={{ mx: 0.5 }} />
          ))}
        </>
      );
    }

    return null;
  }

  function loadDataSet(id) {
    if (!id) return;
    setLoadingDataSet(true);
    axios
      .get('/data/getDataSet', { params: { id } })
      .then((res) => {
        setDataSet(res.data);
        setLoadingDataSet(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingDataSet(false);
      });
  }

  function selectColumn(id) {
    setFieldId(id);
    setDataSet(null);
    setSelectedDataSetRow(null);
    const selected = itemsToRender.find((x) => x.id === id);
    if (!selected) return;
    if (selected.fieldType === 'dataSet' && selected.dataSetParams.setId) {
      loadDataSet(selected.dataSetParams.setId);
    }
  }

  function addCondition() {
    if (!selectedField) {
      StateManager.setErrorAlert('Select field');
      return;
    }
    if (optionTypes.includes(selectedType) && !selectedOptionId) {
      StateManager.setErrorAlert('Select option');
    }
    if (textTypes.includes(selectedType) && !textValue) {
      StateManager.setErrorAlert('Provide value');
    }
    const condition = {
      id: v4(),
      fieldId,
      basedOnPortals: selectedType === 'portal' ? true : undefined,
      trigger: {
        selectedOptionId: optionTypes.includes(selectedType) ? selectedOptionId : undefined,
        isFilled: filledTrigger,
        textValue: textTypes.includes(selectedType) ? textValue : undefined,
        numberRange: numberTypes.includes(selectedType) ? numberRange : undefined,
        selectedDateOption: dateTypes.includes(selectedType) ? selectedDateOption : undefined,
        days: dateTypes.includes(selectedType) ? days : undefined,
        userSelectType: userTypes.includes(selectedType) ? userSelectType : undefined,
        selectedUser: userTypes.includes(selectedType) ? selectedUser : undefined,
        selectedPortals: selectedType === 'portal' ? selectedPortals : undefined,
      },
    };

    conditions.push(condition);
    setConditions([...conditions]);

    nullifyEditor();
  }

  function deleteCondition(id) {
    setConditions(conditions.filter((x) => x.id !== id));
  }

  const conditionFinished =
    selectedField &&
    (!optionTypes.includes(selectedType) || selectedOptionId) &&
    (!textTypes.includes(selectedType) || textValue) &&
    (!numberTypes.includes(selectedType) || numberRange);

  function done() {
    if (!conditionFinished && isEmpty(conditions)) {
      return;
    }

    if (conditionFinished) {
      addCondition();
    }

    const result = {
      keepValue,
      actionType,
      conditions,
    };

    onResult(result);
    onClose();
  }

  function onCheck(portal) {
    const chosenPortal = portals.filter((p) => p._id === portal);
    setSelectedPortals([...selectedPortals, ...chosenPortal]);
    setCheckedPortals([...checkedPortals, portal]);
  }

  function onUncheck(portal) {
    setSelectedPortals(selectedPortals.filter((p) => p._id !== portal));
    setCheckedPortals(checkedPortals.filter((p) => p !== portal));
  }
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Hide/show logic</DialogTitle>
      <DialogContent>
        {!isEmpty(itemsToRender) ? (
          <Grid container>
            <Grid container item alignItems={'center'} sx={{ my: 1 }}>
              <Typography style={{ fontWeight: 500, marginRight: 16 }}>This {type} will be</Typography>

              <RadioGroup row value={actionType} onChange={(e) => setActionType(e.target.value)}>
                <FormControlLabel value={'hidden'} label={'hidden'} control={<Radio />} />
                <FormControlLabel value={'shown'} label={'shown'} control={<Radio />} />
              </RadioGroup>
            </Grid>
            {conditions.map((condition) => {
              if (!condition.trigger) return null;
              const selectedField = itemsToRender.find((x) => {
                if (x.basedOnPortal) return x;
                return x.id === condition.fieldId;
              });

              let content = null;
              if (!selectedField) {
                content = <Typography>The linked item does not exist anymore</Typography>;
              }

              const conditionText = getTriggerText(selectedField, condition.trigger);

              if (conditionText) {
                content = <Typography>When {conditionText}</Typography>;
              } else {
                content = <Typography>Invalid condition</Typography>;
              }

              return (
                <Grid key={condition.id} container item sx={{ py: 1 }} wrap={'nowrap'} alignItems={'center'}>
                  <Grid container item style={{ width: 'auto', flexGrow: 1 }}>
                    {content}
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => deleteCondition(condition.id)}>
                      <DeleteOutlineRounded style={{ color: red[500] }} />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
            <Grid container item alignItems={'center'} sx={{ my: 1 }}>
              <Typography style={{ fontWeight: 500 }}>when</Typography>

              <Button
                sx={{ ml: 1 }}
                onClick={(e) => setMenuAnchor(e.currentTarget)}
                startIcon={<ArrowDropDownRounded />}
                variant="outlined"
              >
                {selectedFieldTitle || 'Select field'}
              </Button>
              <SearchMenu
                open={Boolean(menuAnchor)}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor(false)}
                items={itemsToRender}
                onResult={selectColumn}
                groupId={'groupId'}
                groupTitle={'groupTitle'}
                iconField={'icon'}
                textField="title"
              />
              {selectedType === 'portal' && (
                <>
                  <Button
                    sx={{ ml: 1 }}
                    onClick={(e) => setMenu2Anchor(e.currentTarget)}
                    startIcon={<ArrowDropDownRounded />}
                    variant="outlined"
                  >
                    {selectedPortals.length > 0
                      ? selectedPortals.map((portal) => <Chip key={portal._id} label={portal.title} />)
                      : 'Select portal'}
                  </Button>
                  <SearchMenu
                    open={Boolean(menu2Anchor)}
                    anchorEl={menu2Anchor}
                    onClose={() => setMenu2Anchor(false)}
                    items={portals}
                    withCheckboxes
                    onCheck={onCheck}
                    onUncheck={onUncheck}
                    checkedItems={checkedPortals}
                    onResult={selectColumn}
                    groupId={'groupId'}
                    groupTitle={'groupTitle'}
                    idField="_id"
                    textField="title"
                  />
                </>
              )}
            </Grid>
            <Grid container item alignItems={'flex-end'} sx={{ my: 1 }}>
              {filledTrigger && (
                <Typography gutterBottom style={{ fontWeight: 500 }}>
                  is filled
                </Typography>
              )}
              {optionTypes.includes(selectedType) && (
                <>
                  <Typography style={{ fontWeight: 500 }}>is: </Typography>
                  <TextField
                    label="Option"
                    variant="standard"
                    select
                    value={selectedOptionId}
                    onChange={(e) => setSelectedOptionId(e.target.value)}
                    style={{ minWidth: 100, margin: '0 8px', fontSize: 18, fontWeight: 600 }}
                  >
                    <MenuItem value={'any'} style={{ fontWeight: 500 }}>
                      filled
                    </MenuItem>
                    {selectedType !== 'conditional' &&
                      isArray(selectedField?.options) &&
                      !isEmpty(selectedField?.options) &&
                      selectedField.options.map((option) => (
                        <MenuItem key={option.id} value={option.id} style={{ color: option.color, fontWeight: 500 }}>
                          {option.text}
                        </MenuItem>
                      ))}
                    {selectedType === 'conditional' &&
                      isArray(selectedField?.conditions) &&
                      !isEmpty(selectedField?.conditions) &&
                      selectedField.conditions.map((condition) => (
                        <MenuItem
                          key={condition.id}
                          value={condition.id}
                          style={{ color: condition.color, fontWeight: 500 }}
                        >
                          {condition.text}
                        </MenuItem>
                      ))}
                  </TextField>
                </>
              )}
              {textTypes.includes(selectedType) && (
                <Grid container wrap="nowrap" alignItems={'flex-end'}>
                  <Typography sx={{ fontWeight: 500, mr: 2 }} noWrap>
                    the field is
                  </Typography>

                  <TextField
                    value={textValue}
                    label="Value"
                    variant="standard"
                    onChange={(e) => setTextValue(e.target.value)}
                    sx={{ flexGrow: 1, marginLeft: 2 }}
                    id="text-value-input"
                  />
                </Grid>
              )}
              {numberTypes.includes(selectedType) && (
                <Grid container wrap="nowrap" alignItems={'flex-end'}>
                  <Typography sx={{ fontWeight: 500, mr: 2 }} noWrap>
                    the field is
                  </Typography>
                  <Grid item>
                    <NumberRangePicker initial={numberRange} onChange={setNumberRange} />
                  </Grid>
                </Grid>
              )}
              {dateTypes.includes(selectedType) && (
                <>
                  <Typography gutterBottom style={{ fontWeight: 500 }}>
                    today
                  </Typography>
                  <FormControl style={{ minWidth: 120, margin: '0 0.5rem' }}>
                    <InputLabel>Option</InputLabel>
                    <Select
                      value={selectedDateOption}
                      onChange={(e) => setSelectedDateOption(e.target.value)}
                      label="Option"
                      renderValue={(option) => {
                        if (!option) return '';
                        return formatDateOption(option);
                      }}
                      variant="standard"
                    >
                      {Object.keys(daysOptions).map((option) => (
                        <MenuItem key={option} value={option}>
                          {formatDateOption(option)} {formatExampleForDateOption(option)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {['past', 'pastOrLess', 'pastOrMore', 'until', 'untilOrLess', 'untilOrMore'].includes(
                    selectedDateOption,
                  ) && (
                    <TextField
                      type="number"
                      variant="standard"
                      value={days}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value >= 2) {
                          setDays(value);
                        }
                      }}
                      style={{ width: '3rem' }}
                      label="Days"
                      id="number-value-input"
                    />
                  )}
                </>
              )}
              {userTypes.includes(selectedType) && (
                <Grid container item alignItems="center" style={{ paddingTop: 12 }}>
                  <Typography style={{ fontWeight: 600, marginRight: 16 }}>the user is</Typography>
                  <FormControl component="fieldset" variant="standard">
                    <RadioGroup row value={userSelectType} onChange={(e) => setUserSelectType(e.target.value)}>
                      <FormControlLabel value="any" control={<Radio color="primary" />} label="Any user" />
                      <FormControlLabel value="selected" control={<Radio color="primary" />} label="Selected" />
                    </RadioGroup>
                  </FormControl>
                  {userSelectType === 'selected' && (
                    <UserAutocomplete
                      width={250}
                      selected={selectedUser}
                      onResult={(user) => setSelectedUser(user._id)}
                    />
                  )}
                </Grid>
              )}
            </Grid>
            {conditionFinished && (
              <Grid container sx={{ pt: 1 }} justifyContent={'flex-end'}>
                <Button variant="outlined" startIcon={<AddCircleOutlineRounded />} onClick={addCondition}>
                  add another condition
                </Button>
              </Grid>
            )}
            <Grid container sx={{ pt: 1 }}>
              <FormControlLabel
                onChange={(e) => setKeepValue(e.target.checked)}
                control={<Checkbox checked={keepValue} />}
                label="Keep value if the field is hidden"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Typography variant="h6" color={'GrayText'}>
              No items available
            </Typography>
          </Grid>
        )}
      </DialogContent>

      <StandardDialogActions
        additionalActions={
          initial && (
            <Button
              color="error"
              onClick={() => {
                onResult(null);
                onClose();
              }}
              sx={{ mr: 'auto' }}
              startIcon={<DeleteOutlineRounded />}
            >
              delete
            </Button>
          )
        }
        onClose={onClose}
        onDone={done}
        hideDone={!conditionFinished && isEmpty(conditions)}
      />
    </RoundedDialog>
  );
}
