import { red, grey, blue } from '@mui/material/colors';
import { DialogContent, DialogTitle, DialogActions, Button, Grid, Typography } from '@mui/material';
import { List, ListItem, ListItemText, Link } from '@mui/material';
import { DeleteOutlined, Visibility } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog } from '../Components';
import axios from 'axios';

export default function EditProjectDialog({ open, onClose, onResult, selectedId, onDelete }) {
  const [templates, setTemplates] = useState([]);
  const access = localStorage.getItem('access');

  useEffect(() => {
    axios
      .get('/project/getProjectTemplates')
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function selectTemplate(templateId) {
    onResult(templateId);
    onClose();
  }

  function deleteTemplateId() {
    onDelete();
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Please select a project template</DialogTitle>

      <DialogContent>
        {selectedId && (
          <Grid container>
            <Typography gutterBottom variant="h6">{`You selected: ${
              templates.find((x) => x._id === selectedId)?.title
            }`}</Typography>
          </Grid>
        )}
        {!templates[0] && (
          <Grid container>
            <Grid container item>
              <Typography gutterBottom variant="h6">
                Looks like you don't have any project templates
              </Typography>
            </Grid>
            {access === 'admin' && (
              <Grid container item>
                <Link target="_blank" style={{ color: blue[700] }} href="/tasks/projects/new-project-template">
                  <Typography gutterBottom variant="h6">
                    You can create one here
                  </Typography>
                </Link>
              </Grid>
            )}
          </Grid>
        )}
        <List>
          {templates.map((template) => (
            <ListItem
              key={template._id}
              button
              style={{ borderRadius: '0.5rem' }}
              onClick={() => selectTemplate(template._id)}
            >
              <ListItemText primary={template.title} />
              <Button
                startIcon={<Visibility style={{ color: grey[600] }} />}
                style={{
                  border: `2px solid ${grey[400]}`,
                  textTransform: 'none',
                  borderRadius: '0.5rem',
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(`/tasks/projects/template/${template._id}`, '_blank').focus();
                }}
              >
                View template
              </Button>
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        {selectedId && (
          <Button
            startIcon={<DeleteOutlined />}
            onClick={deleteTemplateId}
            style={{ color: red[500], marginRight: 'auto' }}
          >
            delete template
          </Button>
        )}
        <Button onClick={onClose} style={{ color: red[500] }}>
          cancel
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
