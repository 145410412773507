import { useState, useEffect } from 'react';
import { Grid, CircularProgress, Typography, Button } from '@mui/material';
import axios from 'axios';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

export default function TicketReference({ ticketId, setTicket, setTicketOpen }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!ticketId) {
      setData(null);
      return;
    }
    setLoading(true);
    axios
      .get(`/support/getTicketBriefInfo?ticketId=${ticketId}`)
      .then(({ data }) => {
        setData(data);
        setTicket(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ticketId, setTicket]);

  if (!data) return null;

  return (
    <Grid container alignItems="center" sx={{ py: 1 }}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <Typography>{`Created from Support Ticket`}</Typography>
          <Button
            startIcon={<SupportAgentIcon />}
            onClick={() => setTicketOpen(true)}
            sx={{ ml: 1, textTransform: 'none', color: (theme) => theme.palette.text.primary }}
          >
            {data.title} - #{data.status}
          </Button>
        </>
      )}
    </Grid>
  );
}
