import React, { useState, useEffect, useRef } from 'react';
import { isFunction } from 'lodash';
import { Typography, InputBase, Select, MenuItem } from '@mui/material';
import { Paper, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { COLORS } from '../../constants';

export default function Field({ item, initial, onEdit }) {
  const timer = useRef();
  const SAVING_TIMEOUT = 150;

  const MenuProps = {
    sx: {
      '& .MuiPaper-root': {
        borderRadius: '10px',
        marginTop: 1,
        '& .MuiList-root': {
          py: 0,
          background: 'white',
          maxHeight: 48 * 4.5,
          '& li': {
            fontWeight: 200,
            py: '12px',
          },
          '& li:hover': {
            background: COLORS.SETTINGS,
            color: 'white',
          },
          '& li.Mui-selected': {
            color: 'white',
            background: COLORS.SEARCH,
          },
          '& li.Mui-selected:hover': {
            background: COLORS.SEARCH,
          },
        },
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    disableAutoFocus: true,
    disableEnforceFocus: true,
    disableRestoreFocus: true,
    disableAutoFocusItem: true,
    autoFocus: false,
  };

  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [options, setOptions] = useState([]);

  function handleChange(...args) {
    if (!isFunction(onEdit)) return;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => onEdit(...args), SAVING_TIMEOUT);
  }

  function saveTitle(e) {
    setValue(e);
    handleChange(item.id, 'value', e);
  }

  function getLabel() {
    if (fieldType === 'autonumber') {
      return 'This field is automatically numbered';
    }
    if (fieldType === 'number') {
      return 'This field requires your number input to fill in';
    }
    if (fieldType === 'textArea') {
      return label || 'This field requires you to fill in the description';
    }
    if (fieldType === 'dropbox') {
      return 'This field requires you to pick from dropdown list';
    }
    return 'This field has fixed text mentioned below';
  }

  useEffect(() => {
    if (!item) return;
    setValue(initial || item.title || '');
    setLabel(item.label || '');
    setOptions(item.options || []);
    setFieldType(item.fieldType || '');
  }, [item, initial]);

  if (item.type === 'field') {
    return (
      <Grid container item sx={{ position: 'relative' }}>
        <Paper
          id={item.id}
          elevation={3}
          variant="elevation"
          sx={{ margin: (theme) => theme.spacing(1, 0), borderRadius: '0.5rem', width: '100%' }}
        >
          <Grid container sx={{ padding: 1, borderRadius: '0.5rem' }}>
            <Grid item container alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputLabel id={`field-type-label-${item.id}`} sx={{ marginBottom: 1 }}>
                  {getLabel()}
                </InputLabel>
              </Grid>
              <Grid item container lg={12} md={12} sm={12} alignItems="center" wrap="nowrap">
                {['number', 'textArea'].includes(fieldType) && (
                  <InputBase
                    value={value}
                    placeholder={fieldType === 'textArea' ? 'Enter your Description' : 'Enter your Number'}
                    variant="outlined"
                    sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(1) }}
                    onChange={(e) => saveTitle(e.target.value)}
                    inputProps={{ maxLength: 128 }}
                    type={fieldType === 'number' ? 'number' : 'text'}
                    multiline={fieldType === 'textArea'}
                    label={getLabel()}
                  />
                )}
                {['text'].includes(fieldType) && (
                  <Typography variant="body1" sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(1) }}>
                    {value}
                  </Typography>
                )}
                {['autonumber'].includes(fieldType) && (
                  <Typography variant="body1" sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(1) }}>
                    Auto Generated Number
                  </Typography>
                )}
                {['dropbox'].includes(fieldType) && (
                  <Grid
                    item
                    container
                    alignItems="center"
                    sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(1) }}
                  >
                    <Select
                      fullWidth
                      labelId="options-label"
                      id="options-label"
                      placeholder="Choose Option from the list"
                      label="Dropbox"
                      value={value}
                      onChange={(e) => saveTitle(e.target.value)}
                      variant="standard"
                      MenuProps={MenuProps}
                      displayEmpty
                    >
                      <MenuItem value="">No Option Selected</MenuItem>
                      {options?.map((option) => (
                        <MenuItem value={option.text} key={option._id}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return (
    <Grid container item>
      <Paper elevation={3} sx={{ margin: (theme) => theme.spacing(1, 0), borderRadius: '0.5rem', width: '100%' }}>
        <Grid item container alignItems="center" sx={{ padding: 1, borderRadius: '0.5rem' }}>
          Item {item.type} not supported yet
        </Grid>
      </Paper>
    </Grid>
  );
}
