import React, { lazy, Suspense, useEffect, useState } from 'react';
import './App.css'; // important
import { Route, Switch, Redirect } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { useSelector, useDispatch } from 'react-redux';
import { openSetupDialog } from './Components/Hubs/dochub/redux/actions';
import { updateTour } from './Components/Profile/redux/actions';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeTheme } from './theme';
import CssBaseline from '@mui/material/CssBaseline';

import ProtectedRoute from './routers/Protected';
import PortalRoute from './routers/Portal';

import NotFound from './Components/Global/NotFound';
import Login from './Components/Auth/pages/Login';
import SignUp from './Components/Auth/pages/SignUp';
import GroupSignUp from './Components/Auth/pages/GroupSignUp';
import GroupSignIn from './Components/Auth/pages/GroupSignIn';
import SignUpUser from './Components/Auth/pages/SignupUser';
import SignupPortalUser from './Components/Auth/pages/SignupPortalUser';
import OAuthRedirect from './Components/Auth/pages/OAuthRedirect';
import Confirmation from './Components/Auth/pages/Confirmation';
import PasswordReset from './Components/Auth/pages/PasswordReset';
import PasswordChange from './Components/Auth/pages/PasswordChange';
import ForgotPassword from './Components/Auth/pages/ForgotPassword';

import Profile from './Components/Profile';
import EmptyHeader from './Components/Global/AppHeader/EmptyHeader';

import { CASES } from './Components/Global/Assistant/Tours/constants';
import Welcome from './Components/Welcome';
import Home from './Components/Home';

import WorkflowRedirect from './Components/Hubs/workflow/workflowRedirect';
import ProcessRedirect from './Components/Hubs/workflow/processRedirect';
import Notifications from './Components/Notifications';
import NoticeBoard from './Components/Home/noticeBoard';

import FilePreview from './Components/FilePreview';
import SuspendedNotice from './Components/Pages/SuspendedNotice';
import BlockedNotice from './Components/Pages/Blocked';
import Support from './Components/Support';
import SetupDialog from './Components/Hubs/dochub/components/SetupDialog';
import Tours from './Components/Global/Assistant/Tours';

import DocumentDrawer from './Components/Hubs/dochub/components/DocumentDrawer';

import { useSnackbarNotifications } from './Components/Global/hooks';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { WifiOffRounded } from '@mui/icons-material';
import UserRequest from './Components/Auth/pages/Request';
import AuditRedirect from './Components/Hubs/audits/redirect';
import StateManager from './Components/Global/StateManager';
import { LicenseInfo } from '@mui/x-license-pro';
import RedirectQRPage from './Components/Global/QRCode/RedirectQR';

// hubs !!!!! USE LAZY LOAD !!!!!!
const RegisterHub = lazy(() => import('./Components/Hubs/registers'));
const DocLibrary = lazy(() => import('./Components/Hubs/dochub'));
const KpiHub = lazy(() => import('./Components/Hubs/kpiHub'));
const Portal = lazy(() => import('./Components/Hubs/portal'));
const Audits = lazy(() => import('./Components/Hubs/audits'));
const FormHub = lazy(() => import('./Components/Hubs/forms'));
const ProcessHub = lazy(() => import('./Components/Hubs/processes'));
const DataHub = lazy(() => import('./Components/Hubs/data'));
const AssetHub = lazy(() => import('./Components/Hubs/asset'));
const Admin = lazy(() => import('./Components/AdminPanel'));
const SuperAdmin = lazy(() => import('./Components/SuperAdmin'));
const SuperGroupAdmin = lazy(() => import('./Components/SuperGroupAdmin'));
const Tasks = lazy(() => import('./Components/Hubs/tasks'));
const ResourcesHome = lazy(() => import('./Components/Hubs/resources/pages/public'));
const InfoProcessExternal = lazy(() => import('./Components/Hubs/processes/pages/infoProcessExternal'));
const ExternalEntry = lazy(() => import('./Components/Hubs/forms/pages/externalEntry'));
const ExternalAudit = lazy(() => import('./Components/Hubs/audits/pages/externalAudit'));
const Survey = lazy(() => import('./Components/Hubs/forms/pages/survey'));
const DataRequest = lazy(() => import('./Components/External/dataRequest'));
const EditorWithLayout = lazy(() => import('./Components/Hubs/dochub/components/HTMLEditor'));
const IssueProcess = lazy(() => import('./Components/Hubs/dochub/pages/IssueProcess'));
const FullScreenPreview = lazy(() => import('./Components/Hubs/dochub/pages/FullScreenPreview/preview'));
const FullScreenPdfPreview = lazy(() => import('./Components/Hubs/dochub/pages/FullScreenPreview'));
const FullScreenPrintPreview = lazy(() => import('./Components/Hubs/dochub/pages/FullScreenPreview/print'));
const TemplateEditorWithLayout = lazy(() => import('./Components/Hubs/dochub/components/HTMLEditor/template'));

function App({ instance }) {
  const dispatch = useDispatch();
  const { selected, setupDialog, docDrawer } = useSelector(({ dochub }) => dochub);
  const { showDrawerTour, drawerTour, drawerEndAction } = useSelector(({ assistantHub }) => assistantHub);
  const { user } = useSelector(({ profile }) => profile);

  useSnackbarNotifications();

  const [offline, setOffline] = useState(false);
  const [userTheme, setUserTheme] = useState(null);

  useEffect(() => {
    // mui x
    const key = process.env.REACT_APP_MUI_X_LICENSE_KEY;
    LicenseInfo.setLicenseKey(key);
    window.addEventListener('online', () => setOffline(false));
    window.addEventListener('offline', () => setOffline(true));

    StateManager.subscribeToThemeChange((params) => {
      if (!params) return;
      const { mode, color } = params;
      setUserTheme({ mode, color });
      localStorage.setItem('themeMode', mode);
      localStorage.setItem('themeColor', color);
    });
  }, []);

  useEffect(() => {
    // find out theme
    let mode = user?.theme?.mode || localStorage.getItem('themeMode');
    if (!mode) {
      // detect system theme
      mode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    let color = user?.theme?.color || localStorage.getItem('themeColor');
    if (!color) {
      const defaultPrimaryColor = 'blue';
      color = defaultPrimaryColor;
    }

    StateManager.changeTheme(mode, color);
    setUserTheme({ mode, color });
  }, [user]);

  const availableHubs = user?.company?.availableHubs;
  const renderAudit = !availableHubs || availableHubs.includes('audit');
  const renderAsset = !availableHubs || availableHubs.includes('asset');
  const renderRegisters = !availableHubs || availableHubs.includes('register');

  const fallback = (
    <Grid container style={{ height: '100vh' }}>
      <Grid container item>
        <EmptyHeader />
      </Grid>
      <Grid container item justifyContent={'center'} alignItems={'center'} style={{ height: '85vh' }}>
        <CircularProgress />
      </Grid>
    </Grid>
  );

  const theme = makeTheme(userTheme);

  return (
    <MsalProvider instance={instance}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          {user &&
            showDrawerTour &&
            [CASES.DOCHUBFIRSTTOUR, CASES.FORMHUBFIRSTTOUR, CASES.WORKFLOWFIRSTTOUR].includes(drawerTour) && (
              <Tours
                run={showDrawerTour}
                id={drawerTour}
                onTourEnd={() => dispatch(updateTour(drawerEndAction))}
                continuous
              />
            )}

          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login/:userId?/:accessKey?" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/signup/:linkId" component={GroupSignUp} />
            <Route exact path="/signin/:linkId" component={GroupSignIn} />
            <Route exact path="/signup-user/:companyId?" component={SignUpUser} />
            <Route exact path="/signup-portal-user/:linkId?" component={SignupPortalUser} />
            <Route exact path="/oauth/redirect" component={OAuthRedirect} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/confirmation/:userId/:verificationId" component={Confirmation} />
            <Route exact path="/reset-password/:userId/:resetId" component={PasswordReset} />
            <Route exact path="/setup-password/:userId/:resetId" component={PasswordReset} />
            <Route exact path="/change-password/:userId" component={PasswordChange} />
            <Route exact path="/request/:type/:token" component={UserRequest} />

            <Route
              exact
              path="/external/form/:invitationId"
              children={
                <Suspense fallback={fallback}>
                  <ExternalEntry />
                </Suspense>
              }
            />
            <Route
              exact
              path="/external/audits/:invitationId"
              children={
                <Suspense fallback={fallback}>
                  <ExternalAudit />
                </Suspense>
              }
            />
            <Route
              exact
              path="/external/info-process/:invitationId"
              children={
                <Suspense fallback={fallback}>
                  <InfoProcessExternal />
                </Suspense>
              }
            />
            <Route
              exact
              path="/external/survey/:surveyId"
              children={
                <Suspense fallback={fallback}>
                  <Survey />
                </Suspense>
              }
            />
            <Route
              exact
              path="/external/data-request/:invitationId"
              children={
                <Suspense fallback={fallback}>
                  <DataRequest />
                </Suspense>
              }
            />
            <Route
              exact
              path="/resources"
              children={
                <Suspense fallback={fallback}>
                  <ResourcesHome />
                </Suspense>
              }
            />
            <ProtectedRoute exact path="/home">
              <Home />
            </ProtectedRoute>
            <PortalRoute exact path="/profile">
              <Profile />
            </PortalRoute>
            <ProtectedRoute path="/admin">
              <Suspense fallback={fallback}>
                <Admin />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute path="/superadmin">
              <Suspense fallback={fallback}>
                <SuperAdmin />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute path="/supergroup">
              <Suspense fallback={fallback}>
                <SuperGroupAdmin />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute path="/tasks/:option?">
              <Suspense fallback={fallback}>
                <Tasks />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute path="/DocLibrary">
              <Suspense fallback={fallback}>
                <DocLibrary />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute path="/kpiHub">
              <Suspense fallback={fallback}>
                <KpiHub />
              </Suspense>
            </ProtectedRoute>
            <PortalRoute path="/notifications">
              <Notifications />
            </PortalRoute>
            <ProtectedRoute exact path="/noticeBoard">
              <NoticeBoard />
            </ProtectedRoute>
            <ProtectedRoute exact path="/qr/:id">
              <RedirectQRPage />
            </ProtectedRoute>
            <PortalRoute path="/portal">
              <Suspense fallback={fallback}>
                <Portal />
              </Suspense>
            </PortalRoute>

            {renderAudit && (
              <ProtectedRoute path="/audit">
                <AuditRedirect />
              </ProtectedRoute>
            )}

            {renderAudit && (
              <ProtectedRoute path="/audits">
                <Suspense fallback={fallback}>
                  <Audits />
                </Suspense>
              </ProtectedRoute>
            )}

            <PortalRoute path="/support/:id?">
              <Support />
            </PortalRoute>
            <ProtectedRoute path="/workflow">
              <WorkflowRedirect />
            </ProtectedRoute>
            <ProtectedRoute path="/forms">
              <Suspense fallback={fallback}>
                <FormHub />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute path="/processes">
              <Suspense fallback={fallback}>
                <ProcessHub />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute path="/process">
              <ProcessRedirect />
            </ProtectedRoute>
            <ProtectedRoute path="/welcome">
              <Welcome />
            </ProtectedRoute>
            <ProtectedRoute path="/data">
              <Suspense fallback={fallback}>
                <DataHub />
              </Suspense>
            </ProtectedRoute>
            {renderAsset && (
              <ProtectedRoute path="/asset">
                <Suspense fallback={fallback}>
                  <AssetHub />
                </Suspense>
              </ProtectedRoute>
            )}
            {renderRegisters && (
              <ProtectedRoute path="/registers">
                <Suspense fallback={fallback}>
                  <RegisterHub />
                </Suspense>
              </ProtectedRoute>
            )}
            <Route exact path="/expired" component={SuspendedNotice} />
            <Route exact path="/suspended" component={SuspendedNotice} />
            <Route exact path="/blocked" component={BlockedNotice} />
            <Route
              exact
              path="/editor"
              children={
                <Suspense fallback={fallback}>
                  <EditorWithLayout />
                </Suspense>
              }
            />
            <Route
              exact
              path="/editor/:id"
              children={
                <Suspense fallback={fallback}>
                  <EditorWithLayout />
                </Suspense>
              }
            />
            <Route
              exact
              path="/template/:id"
              children={
                <Suspense fallback={fallback}>
                  <TemplateEditorWithLayout />
                </Suspense>
              }
            />
            <Route
              exact
              path="/editor/:id/upissue"
              children={
                <Suspense fallback={fallback}>
                  <IssueProcess />
                </Suspense>
              }
            />
            <ProtectedRoute exact path="/qdoc-pdf/:id">
              <Suspense fallback={fallback}>
                <FullScreenPdfPreview />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute exact path="/document/:id">
              <Suspense fallback={fallback}>
                <FullScreenPreview />
              </Suspense>
            </ProtectedRoute>
            <ProtectedRoute exact path="/document/print/:id">
              <Suspense fallback={fallback}>
                <FullScreenPrintPreview />
              </Suspense>
            </ProtectedRoute>
            <Route
              exact
              path="/editor/:id/upissue/:issue"
              children={
                <Suspense fallback={fallback}>
                  <IssueProcess />
                </Suspense>
              }
            />
            <Route component={NotFound} />
          </Switch>
          <FilePreview />
          {docDrawer && <DocumentDrawer />}
          {setupDialog && (
            <SetupDialog setup={setupDialog} handleSetup={(d) => dispatch(openSetupDialog(d))} initial={selected} />
          )}
          {offline && (
            <Grid
              container
              sx={{ height: '70px', background: red[700], position: 'fixed', top: 0, zIndex: 10000, p: 2 }}
              alignItems={'center'}
            >
              <WifiOffRounded style={{ color: 'white' }} />
              <Typography sx={{ color: 'white', ml: 2 }}>You are offline, please check your connection</Typography>
            </Grid>
          )}
        </StyledEngineProvider>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
