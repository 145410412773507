import React from 'react';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RestoreIcon from '@mui/icons-material/Restore';
import CloudDownload from '@mui/icons-material/CloudDownload';

export default function ImageControls({
  onZoomIn,
  onZoomOut,
  rotateClockwise,
  rotateCounterClockwise,
  reset,
  download,
}) {
  return (
    <Toolbar>
      <IconButton onClick={onZoomIn}>
        <ZoomIn />
      </IconButton>
      <IconButton onClick={onZoomOut}>
        <ZoomOut />
      </IconButton>
      <IconButton onClick={rotateClockwise}>
        <RotateRightIcon />
      </IconButton>
      <IconButton onClick={rotateCounterClockwise}>
        <RotateLeftIcon />
      </IconButton>
      <IconButton onClick={reset}>
        <RestoreIcon />
      </IconButton>
      <IconButton onClick={download}>
        <CloudDownload />
      </IconButton>
    </Toolbar>
  );
}
