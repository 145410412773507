import React from 'react';
import { DialogContent, Button } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import { FormIcon } from '../../../Global/Icons';
import { grey } from '@mui/material/colors';
import FormEntry from '../pages/formEntry';
import { Link } from 'react-router-dom';

export default function FormEntryDialog({ open, onClose, entryId, onFormComplete, onFormDelete }) {
  if (!open) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogContent sx={{ p: 1.5 }}>
        <FormEntry
          dialogEntryId={entryId}
          fromDialog
          onDialogClose={onClose}
          onFormComplete={onFormComplete}
          onFormDelete={onFormDelete}
        />
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        additionalActions={
          <>
            <Link to={`/forms/entry/${entryId}`}>
              <Button startIcon={<FormIcon />}>go to hub</Button>
            </Link>
            <div style={{ marginRight: 'auto' }} />
          </>
        }
      />
    </RoundedDialog>
  );
}
