import { useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { isArray, isEmpty, isFunction, cloneDeep } from 'lodash';
import Legend from './Legend';
import { blueberryTwilightPalette } from '@mui/x-charts/colorPalettes';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { ChartsGrid } from '@mui/x-charts';

export default function Chart({ data, barChartType, onBarClick, isPreview, chartParams }) {
  const theme = useTheme();
  const standardPalette = blueberryTwilightPalette(theme?.palette?.mode || 'light');
  const legendPosition = chartParams?.legendPosition || 'top';
  const hideLegend = chartParams?.hideLegend || Boolean(isPreview) || data?.useValue;
  const xAxisLabel = data?.xAxisLabel || '';
  const yAxisLabel = data?.yAxisLabel || '';

  // color target lines
  const targetLines = useMemo(() => {
    if (!isArray(data?.targetLines) || isEmpty(data?.targetLines)) return [];

    const result = cloneDeep(data.targetLines);

    for (let i = 0; i < result.length; ++i) {
      if (!result[i].color) {
        result[i].color = standardPalette[i % standardPalette.length];
      }
    }

    return result;
  }, [data, standardPalette]);

  // customize bar chart data using chartParams
  const chartSeries = useMemo(() => {
    if (!isArray(data?.series)) return [];

    const result = data.series.map((x, i) => ({
      data: [],
      ...x,
      id: String(i),
      stack: barChartType === 'stacked' ? 'total' : undefined,
      type: 'bar',
    }));

    const hasOptionsParams = isArray(chartParams?.optionsParams) && !isEmpty(chartParams?.optionsParams);

    for (let i = 0; i < result.length; ++i) {
      // color the series
      if (hasOptionsParams) {
        const normLabel = String(result[i].label).trim();
        const config = chartParams.optionsParams.find((x) => String(x.value).trim() === normLabel);

        result[i].color = config?.color;
      }

      // if no colour defined in the config - take from standard palette
      if (!result[i].color) {
        result[i].color = standardPalette[i % standardPalette.length];
      }
    }

    if (!isEmpty(targetLines)) {
      result.push(...targetLines);
    }

    return result;
  }, [chartParams, data, barChartType, standardPalette, targetLines]);

  const legendData = useMemo(() => {
    const seriesLabels = chartSeries.map((x) => ({ color: x.color, label: x.label }));
    // target lines already included here
    return seriesLabels;
  }, [chartSeries]);

  // if render as 'no data'
  if (data?.series === null) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Typography color={'textSecondary'} variant={isPreview ? 'subtitle1' : 'h6'}>
          No data to display
        </Typography>
      </Grid>
    );
  }

  // something's wrong
  if (!isArray(data?.series) || !isArray(data?.xAxis)) return null;

  const isComboChart = !isEmpty(targetLines);

  if (isComboChart) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: '100%' }}
        direction={'column'}
        wrap="nowrap"
      >
        {!hideLegend && legendPosition === 'top' && (
          <Grid container sx={{ height: 32 }}>
            <Legend items={legendData} />
          </Grid>
        )}
        <Grid container style={{ flexGrow: 1, position: 'relative' }}>
          <Grid container style={{ height: '100%', position: 'absolute' }}>
            <ResponsiveChartContainer
              series={chartSeries}
              xAxis={[{ data: data.xAxis, scaleType: 'band', id: 'x-axis-id' }]}
            >
              <BarPlot
                onItemClick={(_event, { dataIndex, seriesId }) =>
                  isFunction(onBarClick) && onBarClick(seriesId, dataIndex)
                }
              />
              <LinePlot />
              <MarkPlot />
              <ChartsXAxis position="bottom" axisId="x-axis-id" label={xAxisLabel} />
              <ChartsYAxis label={yAxisLabel} />
              <ChartsAxisHighlight />
              <ChartsTooltip trigger="item" />
              <ChartsGrid horizontal vertical />
            </ResponsiveChartContainer>
          </Grid>
        </Grid>
        {!hideLegend && legendPosition === 'bottom' && (
          <Grid container sx={{ height: 32 }}>
            <Legend items={legendData} />
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
      direction={'column'}
      wrap="nowrap"
    >
      {!hideLegend && legendPosition === 'top' && (
        <Grid container sx={{ height: 32 }}>
          <Legend items={legendData} />
        </Grid>
      )}
      <Grid container style={{ flexGrow: 1, position: 'relative' }}>
        <Grid container style={{ height: '100%', position: 'absolute' }}>
          <BarChart
            series={chartSeries}
            xAxis={[{ data: data.xAxis, scaleType: 'band', label: xAxisLabel }]}
            yAxis={[{ label: yAxisLabel }]}
            onItemClick={(_event, { dataIndex, seriesId }) => isFunction(onBarClick) && onBarClick(seriesId, dataIndex)}
            grid={{ horizontal: true, vertical: true }}
            tooltip={{ trigger: 'item' }}
            slotProps={{ legend: { hidden: true } }}
          />
        </Grid>
      </Grid>
      {!hideLegend && legendPosition === 'bottom' && (
        <Grid container sx={{ height: 32 }}>
          <Legend items={legendData} />
        </Grid>
      )}
    </Grid>
  );
}
