import { setupCompany } from './admin/company';
import { createGroup, setupGroups } from './admin/groups';
import { createUser, setupUsers } from './admin/users';
import { createArea, documentSetting, firstDocTour } from './dochub';
import { firstFormTour } from './formhub';
import {
  createTask,
  firstWorkflowTour,
  initTask,
  createProject,
  initProject,
  showProject,
  createdProject,
} from './workflow';
import { setupProfile } from './profile';
import { CASES } from '../constants';

const guides = (id, setState = null) => {
  switch (id) {
    case CASES.SETUPPROFILE:
      return setupProfile;
    case CASES.SETUPCOMPANY:
      return setupCompany;
    case CASES.SETUPGROUPS:
      return setupGroups;
    case CASES.CREATEGROUP:
      return createGroup;
    case CASES.SETUPUSERS:
      return setupUsers;
    case CASES.ADDUSER:
      return createUser;
    case CASES.DOCHUBFIRSTTOUR:
      return firstDocTour;
    case CASES.FORMHUBFIRSTTOUR:
      return firstFormTour;
    case CASES.WORKFLOWFIRSTTOUR:
      return firstWorkflowTour;
    case CASES.INITTASK:
      return initTask;
    case CASES.CREATETASK:
      return createTask;
    case CASES.DOCSETTING:
      return documentSetting;
    case CASES.CREATEAREA:
      return createArea;
    case CASES.SHOWPROJECT:
      return showProject;
    case CASES.INITPROJECT:
      return initProject;
    case CASES.CREATEPROJECT:
      return createProject(setState);
    case CASES.CREATEDPROJECT:
      return createdProject;
    default:
      return [];
  }
};

export default guides;
