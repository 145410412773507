import React, { useState, useEffect, useRef, createElement } from 'react';
import {
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Grid,
  Typography,
  Paper,
  InputBase,
  ToggleButtonGroup,
} from '@mui/material';
import { IconButton, CardActionArea, CircularProgress, Avatar, Tooltip, useTheme } from '@mui/material';
import { TextFieldsRounded, SubjectRounded, ImageRounded, AssessmentRounded, EditRounded } from '@mui/icons-material';
import { FlashOnRounded, AdjustRounded, ViewStreamRounded, WorkOutlineRounded } from '@mui/icons-material';
import { ChevronRightRounded, DeleteOutlineRounded, CloudUploadOutlined, LanguageRounded } from '@mui/icons-material';
import RoundedDialog from '../../../../Global/Components/RoundedDialog';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import {
  DashboardRounded,
  AddRounded,
  WhatshotRounded,
  Timeline,
  PaletteRounded,
  ViewQuiltRounded,
} from '@mui/icons-material';
import { Skeleton, DialogTitle, DialogContent, ToggleButton } from '@mui/material';
import {
  ImageDialog,
  UserGroup,
  ColorPicker,
  HtmlText,
  ContentEditorDialog,
  StandardDialogActions,
} from '../../../../Global/Components';
import { grey, blue, red, teal, green, amber, deepOrange, deepPurple } from '@mui/material/colors';
import AddKpiDialog from '../../../kpiHub/components/AddKpiDialog';
import KpiPreviewDashboard from '../../../kpiHub/components/KpiPreviewDashboard';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { TaskIcon, FormIcon, ProcessIcon, DocIcon, KPIHubIcon } from '../../../../Global/Icons';
import { AssetIcon, AuditIcon, RegistersIcon, RoutineIcon, ProjectIcon } from '../../../../Global/Icons';
import { StatusButton } from '../../Components';
import { useHistory } from 'react-router-dom';
import { cloneDeep, range, isArray } from 'lodash';
import moment from 'moment';
import AddActionDialog from './AddActionDialog';
import AddLinkDialog from './AddLinkDialog';
import TaskBoxOptions from './TaskBoxOptions';
import StateManager from '../../../../Global/StateManager';
import TasksDialog from './TasksDialog';
import ProcessTableDialog from './ProcessTableDialog';
import ProcessTable from './ProcessTable';
import useElementSize from '../../../../Global/hooks/useElementSize';
import TaskManager from '../../../../Global/TaskManager';
import { SelectFolderDialog, TooltipTypography } from '../../../../Global/Components';
import { FolderRounded, PanoramaRounded } from '@mui/icons-material';
import { isHtml } from '../../../../Global/Functions';
import { v4 } from 'uuid';
import axios from 'axios';
import { useSelector } from 'react-redux';
import * as Icons from '@mui/icons-material';
import { createTheme } from '@mui/material/styles';
import MenuList from './MenuList';
const theme = createTheme();
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const itemTypes = [
  {
    icon: <TextFieldsRounded style={{ color: grey[500] }} />,
    text: 'Heading',
    type: 'heading',
  },
  {
    icon: <SubjectRounded style={{ color: grey[500] }} />,
    text: 'Text box',
    type: 'text',
  },
  {
    icon: <ImageRounded style={{ color: grey[500] }} />,
    text: 'Image box',
    type: 'image',
  },
  {
    icon: <AssessmentRounded style={{ color: grey[500] }} />,
    text: 'KPI',
    type: 'kpi',
  },
  {
    icon: <FlashOnRounded style={{ color: grey[500] }} />,
    text: 'Action',
    type: 'action',
  },
  {
    icon: <AdjustRounded style={{ color: grey[500] }} />,
    text: 'Shortcut',
    type: 'shortcut',
  },
  {
    icon: <ViewStreamRounded style={{ color: grey[500] }} />,
    text: 'Shortcut bar',
    type: 'shortcutBar',
  },
  {
    icon: <WorkOutlineRounded style={{ color: grey[500] }} />,
    text: 'Task box',
    type: 'taskBox',
  },
  {
    icon: <Timeline style={{ color: grey[500] }} />,
    text: 'Process table',
    type: 'processTable',
  },
  {
    icon: <FolderRounded style={{ color: grey[500] }} />,
    text: 'Document folder',
    type: 'dlfolder',
  },
];

export default function Dashboard({ initialItems, initialLayouts, editable, onChange, forPortal, portalSpaceId }) {
  const [layouts, setLayouts] = useState({});
  const [items, setItems] = useState([]);
  const [addKpiDialog, setAddKpiDialog] = useState(false);
  const [addActionDialog, setAddActionDialog] = useState(false);
  const [addLinkDialog, setAddLinkDialog] = useState(false);
  const [taskBoxDialog, setTaskBoxDialog] = useState(false);
  const [processTableDialog, setProcessTableDialog] = useState(false);
  const [dlFolderDialog, setDlFolderDialog] = useState(false);

  useEffect(() => {
    if (!Array.isArray(initialItems)) return;
    setItems(cloneDeep(initialItems));
  }, [initialItems]);

  useEffect(() => {
    // to resize layout
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }, [editable]);

  useEffect(() => {
    if (!initialLayouts) return;
    setLayouts({ ...initialLayouts });
  }, [initialLayouts]);

  function addItem(item, type) {
    let res;
    if (type === 'kpi') {
      res = { data: item, id: v4(), type };
    }
    if (res) {
      items.push(res);
      setItems([...items]);
      onChange({ items, layouts });
    }
  }

  function saveItem(index, data) {
    if (items[index]?.type === 'taskBox' && items[index]?.data?.displayAs !== data?.displayAs) {
      items.push({ data, id: v4(), type: 'taskBox' });
      setItems(items.filter((x) => x.id !== items[index].id));
    } else if (items[index]?.type === 'processTable') {
      items[index] = { ...items[index], ...data };
    } else {
      items[index].data = data;
    }

    onChange({ items, layouts });
  }

  function onItemClick(type) {
    if (type === 'kpi') {
      setAddKpiDialog(true);
    } else if (type === 'heading' || type === 'text') {
      items.push({ data: { text: '' }, id: v4(), type });
      setItems([...items]);
      onChange({ items, layouts });
    } else if (type === 'image') {
      items.push({ data: { file: null }, id: v4(), type });
      setItems([...items]);
      onChange({ items, layouts });
    } else if (type === 'action') {
      setAddActionDialog(true);
    } else if (type === 'shortcut') {
      setAddLinkDialog(true);
    } else if (type === 'shortcutBar') {
      items.push({ data: { title: '', items: [] }, id: v4(), type });
      setItems([...items]);
    } else if (type === 'taskBox') {
      setTaskBoxDialog(true);
    } else if (type === 'processTable') {
      setProcessTableDialog(true);
    } else if (type === 'dlfolder') {
      setDlFolderDialog(true);
    }
  }

  function addShortcut(shortcut) {
    items.push({ data: shortcut, id: v4(), type: 'shortcut' });
    setItems([...items]);
    onChange({ items, layouts });
  }

  function addAction(action) {
    items.push({ data: action, id: v4(), type: 'action' });
    setItems([...items]);
    onChange({ items, layouts });
  }

  function deleteItem(id) {
    setItems(items.filter((x) => x.id !== id));
  }

  function addTaskBox(options) {
    items.push({ data: options, id: v4(), type: 'taskBox' });
    setItems([...items]);
    onChange({ items, layouts });
  }

  function editItem(itemId, update, layoutUpdate) {
    const index = items.findIndex((x) => x.id === itemId);
    if (index === -1) return;
    items[index].data = { ...items[index].data, ...update };
    if (layoutUpdate) {
      // otherwise stupid layout does not update
      resizeLayoutItem(itemId, layoutUpdate);
      const itemsCopy = cloneDeep(items);
      const layoutsCopy = cloneDeep(layouts);
      deleteItem(itemId);
      setTimeout(() => {
        setLayouts(layoutsCopy);
        setItems(itemsCopy);
      }, 20);
      onChange({ items: itemsCopy, layouts: layoutsCopy });
    } else {
      onChange({ items, layouts });
    }
  }

  function getMinWidth(item) {
    if (item.type === 'shortcut') return 4; // replace if hight
    if (item.type === 'shortcutBar') return 20;
    if (item.type === 'processTable' && item.view !== 'button') return 18;
    if (item.type === 'taskBox' && item.data?.displayAs === 'table') return 20;
    return 3;
  }

  function getDefaultWidth(item) {
    if (item.type === 'heading') return 20;
    if (item.type === 'shortcutBar') return 20;
    if (item.type === 'processTable' && item.view !== 'button') return 18;
    if (item.type === 'taskBox' && item.data?.displayAs === 'table') return 20;
    return 6;
  }

  function getMinHeight(item) {
    if (item.type === 'shortcutBar') return 6;
    if (item.type === 'processTable' && item.view !== 'button') return 8;
    if (item.type === 'taskBox' && item.data?.displayAs === 'table') return 8;
    return 2;
  }

  function getDefaultHeight(item) {
    if (item.type === 'heading') return 5;
    if (item.type === 'action') return 2;
    if (item.type === 'kpi') return 10;
    return 8;
  }

  function saveProcessTable(data) {
    items.push({ data: data.processId, id: v4(), type: 'processTable', ...data });
    setItems([...items]);
    onChange({ items, layouts });
  }

  function addDlFolder(folder) {
    items.push({ data: { folder }, id: v4(), type: 'dlfolder' });
    setItems([...items]);
    onChange({ items, layouts });
  }

  function resizeLayoutItem(itemId, layoutUpdate) {
    const keys = Object.keys(layouts);
    for (let i = 0; i < keys.length; ++i) {
      const config = layouts[keys[i]];
      if (!isArray(config)) continue;
      const itemIndex = config.findIndex((x) => x.i === itemId);
      if (itemIndex === -1) continue;

      config[itemIndex] = { ...config[itemIndex], ...layoutUpdate };
    }
  }

  return (
    <Grid container wrap="nowrap" alignItems="flex-start" style={{ overflow: 'hidden', padding: '0px 0' }}>
      <Grid container item xs={editable ? 10 : 12}>
        {items.length === 0 && (
          <Grid container alignItems="center" justifyContent="center" style={{ height: '25vh' }}>
            <Typography variant="h6">
              Dashboard is empty <span style={{ fontSize: '2em' }}>😐</span>
            </Typography>
          </Grid>
        )}
        <ResponsiveReactGridLayout
          className="layout"
          rowHeight={20}
          cols={{ lg: 24, md: 20, sm: 10, xs: 4, xxs: 4 }}
          breakpoints={{ lg: 800, md: 600, sm: 400, xs: 300, xxs: 0 }}
          onLayoutChange={(curr, all) => {
            if (!editable) return;
            const res = { lg: curr, md: curr, sm: curr, xs: curr, xxs: curr };
            setLayouts(res);
            onChange({ items, layouts: res });
          }}
          useCSSTransforms
          style={{ width: '100%' }}
          margin={[10, 10]}
          layouts={layouts}
          isDraggable={Boolean(editable)}
          isResizable={Boolean(editable)}
          draggableCancel=".non-draggable"
        >
          {items.map((item, index) => (
            <div
              key={item.id}
              data-grid={{
                x: index * 4,
                y: 0,
                w: getDefaultWidth(item),
                h: getDefaultHeight(item),
                i: item.id,
                minW: getMinWidth(item),
                minH: getMinHeight(item),
              }}
            >
              <Paper
                variant="outlined"
                style={{ height: '100%', borderRadius: 5, cursor: editable ? 'grab' : undefined, overflow: 'hidden' }}
              >
                {item.type === 'kpi' && (
                  <KpiPreviewDashboard
                    download={!editable}
                    kpi={item.data}
                    onDelete={editable ? () => deleteItem(item.id) : null}
                    clickable={!editable}
                  />
                )}
                {item.type === 'text' && (
                  <TextBox
                    editable={editable}
                    onEdit={(data) => saveItem(index, data)}
                    initial={item.data}
                    onDelete={() => deleteItem(item.id)}
                    onChange={(update, layoutUpdate) => editItem(item.id, update, layoutUpdate)}
                  />
                )}
                {item.type === 'heading' && (
                  <TextArea
                    large
                    editable={editable}
                    onEdit={(data) => saveItem(index, data)}
                    initial={item.data}
                    onDelete={() => deleteItem(item.id)}
                    onChange={(update, layoutUpdate) => editItem(item.id, update, layoutUpdate)}
                  />
                )}
                {item.type === 'image' && (
                  <ImageBox
                    editable={editable}
                    onEdit={(data) => saveItem(index, data)}
                    initial={item.data}
                    onDelete={() => deleteItem(item.id)}
                  />
                )}
                {item.type === 'action' && (
                  <ActionItem
                    editable={editable}
                    item={item.data}
                    onDelete={() => deleteItem(item.id)}
                    onChange={(update, layoutUpdate) => editItem(item.id, update, layoutUpdate)}
                    portalSpaceId={portalSpaceId}
                  />
                )}
                {item.type === 'shortcut' && (
                  <ShortcutItem
                    editable={editable}
                    item={item.data}
                    onDelete={() => deleteItem(item.id)}
                    onChange={(update) => editItem(item.id, update)}
                    portalSpaceId={portalSpaceId}
                  />
                )}

                {item.type === 'shortcutBar' && (
                  <ShortcutBar
                    editable={editable}
                    data={item.data}
                    onDelete={() => deleteItem(item.id)}
                    onEdit={(data) => saveItem(index, data)}
                    forPortal={forPortal}
                    portalSpaceId={portalSpaceId}
                  />
                )}
                {item.type === 'taskBox' && (
                  <TaskBox
                    editable={editable}
                    onDelete={() => deleteItem(item.id)}
                    params={item.data}
                    onEdit={(data) => saveItem(index, data)}
                    onChange={(update) => editItem(item.id, update)}
                  />
                )}
                {item.type === 'processTable' && (
                  <ProcessTable
                    processId={item.processId || item.data}
                    editable={editable}
                    onDelete={() => deleteItem(item.id)}
                    view={item.view}
                    hideTitle={item.hideTitle}
                    hideCompleted={item.hideCompleted}
                    selectedStepId={item.selectedStepId}
                    onEdit={(data) => saveItem(index, data)}
                    initialColor={item.color}
                    initialTitle={item.title}
                  />
                )}
                {item.type === 'dlfolder' && (
                  <FolderItem
                    data={item.data}
                    onEdit={(data) => saveItem(index, data)}
                    editable={editable}
                    onDelete={() => deleteItem(item.id)}
                    onChange={(update) => editItem(item.id, update)}
                  />
                )}
              </Paper>
              {editable && (
                <div className="react-resizable-handle">
                  <ChevronRightRounded fontSize="large" style={{ color: grey[300], transform: 'rotate(45deg)' }} />
                </div>
              )}
            </div>
          ))}
        </ResponsiveReactGridLayout>
      </Grid>
      {editable && (
        <Grid container item xs={2} style={{ paddingLeft: 24 }}>
          <Paper
            variant="outlined"
            style={{ border: `2px solid ${blue[500]}`, borderRadius: 12, padding: '1rem 0px', width: '100%' }}
          >
            <Grid container>
              <Typography variant="h6" gutterBottom style={{ marginLeft: '0.75rem' }}>
                Add to dashboard
              </Typography>
              <List style={{ width: '100%' }}>
                {itemTypes.map((item, i) => (
                  <ListItem key={i} button onClick={() => onItemClick(item.type)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Paper>
          <AddKpiDialog
            open={addKpiDialog}
            onClose={() => setAddKpiDialog(false)}
            onResult={(res) => addItem(res, 'kpi')}
          />
          <AddActionDialog
            forPortal={forPortal}
            open={addActionDialog}
            onClose={() => setAddActionDialog(false)}
            onResult={addAction}
          />
          <AddLinkDialog
            open={addLinkDialog}
            onClose={() => setAddLinkDialog(false)}
            forDashboard
            onResult={addShortcut}
            forPortal={forPortal}
          />
          <TaskBoxOptions open={taskBoxDialog} onClose={() => setTaskBoxDialog(false)} onResult={addTaskBox} />
          <ProcessTableDialog
            open={processTableDialog}
            onClose={() => setProcessTableDialog(false)}
            onResult={saveProcessTable}
          />
          <SelectFolderDialog
            open={dlFolderDialog}
            onClose={() => setDlFolderDialog(false)}
            onResult={addDlFolder}
            excludePersonal
          />
        </Grid>
      )}
    </Grid>
  );
}

function TextArea({ large, onEdit, initial, editable, onDelete, onChange }) {
  const [text, setText] = useState(initial?.text || '');
  const [colorPicker, setColorPicker] = useState(null);
  const [color, setColor] = useState(undefined);
  const [fontSettings, setFontSettings] = useState({});
  const [elementId] = useState(v4());
  const { height, width } = useElementSize(elementId);
  const textAreaRef = useRef(null);
  const containerRef = useRef(null);
  const fontSizeCoefficient = fontSettings?.size
    ? fontSettings.size === '24px'
      ? 1
      : fontSettings.size === '16px'
      ? 3.5
      : fontSettings.size === '10px'
      ? 8
      : 1
    : 1;
  const fontSize = height / fontSizeCoefficient - (fontSizeCoefficient === 1 ? 25 : 0);

  useEffect(() => {
    if (initial?.color) {
      setColor(initial.color);
    }
    if (initial?.fontSettings) {
      setFontSettings(initial.fontSettings);
    }
    //eslint-disable-next-line
  }, [initial]);

  const checkOverflow = () => {
    const container = containerRef.current;
    const textArea = textAreaRef.current;

    if (textArea && container) {
      const isOverflowing =
        textArea.scrollHeight > container.offsetHeight || textArea.scrollWidth > container.offsetWidth;
      return isOverflowing;
    }

    return false;
  };

  useEffect(() => {
    const resizeTextArea = () => {
      const textArea = textAreaRef.current;
      if (textArea) {
        textArea.style.height = 'auto';
        //eslint-disable-next-line
        textArea.style.height = textArea.scrollHeight + 'px';
      }

      resizeTextArea();
    };
  }, [text]);

  function saveColor(col) {
    setColor(col);
    onChange({ color: col });
  }

  function saveFontSettings(settings) {
    setFontSettings(settings);
    onChange({ fontSettings: settings });
  }

  return (
    <Grid
      container
      style={{ height: '100%', backgroundColor: color || 'inherit', padding: 2 }}
      id={elementId}
      ref={containerRef}
    >
      <Grid
        container
        item
        style={{ overflow: 'hidden', position: 'relative', flex: 1, padding: 2 }}
        alignItems="center"
      >
        {editable ? (
          <Grid
            container
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
            }}
            wrap="nowrap"
          >
            <InputBase
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                onEdit({ text: e.target.value });
              }}
              onMouseDown={(e) => e.stopPropagation()}
              ref={textAreaRef}
              fullWidth
              multiline
              placeholder="Enter text"
              inputProps={{
                maxLength: large ? 256 : 2048,
                style: {
                  width: '100%',
                  minHeight: 'fit-content',
                  textAlign: fontSettings?.alignment ? fontSettings.alignment : 'left',
                  margin: 0,
                  lineHeight: 1,
                  fontSize,
                },
              }}
              style={{
                flexGrow: 1,
                overflow: 'auto',
                alignItems: fontSizeCoefficient === 1 ? 'flex-start' : 'center',
              }}
            />
            <ColorPicker
              anchor={colorPicker}
              open={Boolean(colorPicker)}
              column={400}
              customPicker
              onClose={(res) => {
                if (res?.color) {
                  saveColor(res?.color);
                }
                setColorPicker(null);
              }}
              className="non-draggable"
            />
            <MenuList
              mode={'header'}
              onDelete={onDelete}
              setColorPicker={setColorPicker}
              color={color}
              onChange={onChange}
              fontSettings={fontSettings}
              saveFontSettings={saveFontSettings}
              sx={{ position: 'absolute', top: 5, right: 5 }}
            />
          </Grid>
        ) : (
          <Grid
            container
            alignItems="flex-start"
            style={{
              height: '95%',
              position: 'absolute',
              left: 0,
              top: 0,
              alignItems: fontSizeCoefficient !== '24px' ? 'center' : 'flex-start',
            }}
          >
            <Typography
              style={{
                fontSize,
                width: '100%',
                padding: '10px',
                overflow: 'hidden',
                whiteSpace: 'break-spaces',
                marginLeft: 16,
                marginRight: 16,
                textAlign: fontSettings?.alignment || 'left',
                lineHeight: 1,
              }}
            >
              {initial?.text}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

function TextBox({ open, onEdit, initial, editable, onDelete, onChange }) {
  const [text, setText] = useState(initial?.text || '');
  const [dialog, setDialog] = useState(false);
  const [colorPicker, setColorPicker] = useState(null);
  const [color, setColor] = useState(undefined);
  const [elementId] = useState(v4());

  useEffect(() => {
    if (!open) return;
    setText(initial?.text || '');
  }, [initial, open]);

  useEffect(() => {
    if (initial?.color) {
      setColor(initial.color);
    }
  }, [initial]);

  function saveColor(col) {
    setColor(col);
    onChange({ color: col });
  }

  return (
    <Grid
      container
      direction={'column'}
      style={{ height: '100%', position: 'relative', backgroundColor: color || 'inherit' }}
      id={elementId}
    >
      <Grid
        container
        item
        sx={{ height: '100%', overflow: 'hidden', position: 'relative', flex: 1 }}
        alignItems="flex-start"
      >
        {editable && (
          <>
            <ColorPicker
              anchor={colorPicker}
              open={Boolean(colorPicker)}
              column={400}
              customPicker
              onClose={(res) => {
                if (res?.color) {
                  saveColor(res?.color);
                }
                setColorPicker(null);
              }}
              className="non-draggable"
            />
            <Grid
              container
              item
              justifyContent={'flex-end'}
              sx={{ position: 'absolute', zIndex: 1, top: 15, left: -10 }}
            >
              <MenuList
                mode={'textbox'}
                onDelete={onDelete}
                setColorPicker={setColorPicker}
                color={color}
                onChange={onChange}
              />
            </Grid>
          </>
        )}
        <Grid
          container
          alignItems="flex-start"
          sx={{
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            px: 2,
            cursor: editable ? 'grab' : '',
          }}
        >
          <Grid
            container
            alignItems="flex-start"
            style={{
              height: '100%',
              maxHeight: '100%',
              cursor: editable ? 'pointer' : '',
              overflow: 'auto',
            }}
            onClick={editable ? () => setDialog(true) : undefined}
          >
            {isHtml(text) ? (
              <HtmlText value={text} />
            ) : editable && !text ? (
              <Typography color="textSecondary">Click to add text</Typography>
            ) : (
              <Typography
                style={{
                  maxHeight: '100%',
                  overflow: 'auto',
                  whiteSpace: 'break-spaces',
                }}
              >
                {text}
              </Typography>
            )}
          </Grid>
        </Grid>
        {editable && (
          <ContentEditorDialog
            open={dialog}
            onClose={() => setDialog(false)}
            initial={text}
            onResult={(txt) => {
              setText(txt);
              onEdit({ text: txt });
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

function ImageBox({ onEdit, initial, editable, onDelete }) {
  const [id] = useState(v4());
  const [uploadingImage, setUploadingImage] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [sizeDialog, setSizeDialog] = useState(false);
  const [imageSize, setImageSize] = useState('contain');
  const [image, setImage] = useState(initial?.file);

  // useEffect(() => {
  //   setImageSize(initial?.file?.size || 'contain');
  // }, [initial]);

  // function saveImageSize(size) {
  //   setImageSize(size);
  //   onEdit({ imageSize: size });
  // }

  // function handleOpenSize() {
  //   setSizeDialog(true);
  // }

  function uploadFile(e) {
    if (!e.target.files || e.target.files.length === 0 || !e.target.files[0].type.startsWith('image/')) {
      return;
    }
    const file = e.target.files[0];
    setUploadingImage(true);
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        const file = {
          id: v4(),
          name: res.data.file.originalname,
          location: res.data.file.location,
          type: res.data.file.mimetype,
        };
        setImage(file);
        setUploadingImage(false);
        onEdit({ file });
      })
      .catch((err) => {
        console.error(err);
        setUploadingImage(false);
      });
  }

  return (
    <Grid
      container
      style={{
        height: '100%',
        padding: 16,
        backgroundImage: image?.location ? `url('${image.location}')` : null,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={() => initial?.file && setDialog(true)}
    >
      <Grid container item style={{ overflow: 'hidden', position: 'relative', flex: 1 }} alignItems="center">
        {editable ? (
          <>
            <IconButton
              size="small"
              component="label"
              style={{
                width: 150,
                height: '100%',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 10,
              }}
              className="non-draggable"
              onClick={() => setSizeDialog(true)}
            >
              <AspectRatioIcon />
              <Typography>{'Image size'}</Typography>
            </IconButton>
            <IconButton
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                zIndex: 5,
              }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <DeleteOutlineRounded style={{ color: red[500] }} />
            </IconButton>
            <Grid
              container
              alignItems="flex-start"
              style={{
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
              }}
              wrap="nowrap"
            >
              <Grid
                container
                component={Paper}
                variant="outlined"
                margin="auto"
                sx={{
                  border: `2px solid ${grey[300]}`,
                  borderRadius: 1.5,
                  height: 50,
                  width: 50,
                }}
              >
                <input
                  accept="image/*"
                  name="img"
                  id={id}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={uploadFile}
                />
                <CardActionArea style={{ borderRadius: 12, height: '100%', cursor: 'pointer' }}>
                  <label htmlFor={id}>
                    <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                      {uploadingImage ? (
                        <CircularProgress size={30} />
                      ) : (
                        <CloudUploadOutlined sx={{ color: grey[300] }} fontSize="large" />
                      )}
                    </Grid>
                  </label>
                </CardActionArea>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background: 'transparent',
            }}
            wrap="nowrap"
          >
            {initial?.file ? (
              <ImageDialog open={dialog} onClose={() => setDialog(false)} src={initial.file.location} />
            ) : (
              <ImageRounded fontSize="large" style={{ color: grey[500] }} />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

const types = {
  taskTemplate: {
    icon: <TaskIcon />,
    text: 'New task',
  },
  taskTemplateBig: {
    icon: <TaskIcon style={{ height: '80%', width: '80%', alignSelf: 'center', justifySelf: 'center' }} />,
    text: 'New task',
  },
  form: { icon: <FormIcon />, text: 'Fill out a' },
  formBig: {
    icon: <FormIcon style={{ height: '80%', width: '80%' }} />,
    text: 'Fill out a',
  },
  process: { icon: <ProcessIcon />, text: 'Start a' },
  processBig: { icon: <ProcessIcon style={{ height: '80%', width: '80%' }} />, text: 'Start a' },
  register: { icon: <RegistersIcon />, text: 'New row' },
  registerBig: { icon: <RegistersIcon style={{ height: '80%', width: '80%' }} />, text: 'New row' },
};

const shortcutsTypes = {
  work: { icon: <TaskIcon />, text: 'Work hub' },
  task: { icon: <TaskIcon />, text: 'Tasks' },
  form: { icon: <FormIcon />, text: 'Form hub' },
  formEntry: { icon: <FormIcon />, text: 'Form hub' },
  process: { icon: <ProcessIcon />, text: 'Process hub' },
  processEntry: { icon: <ProcessIcon />, text: 'Process hub' },
  doc: { icon: <DocIcon />, text: 'Doc hub' },
  area: { icon: <DocIcon />, text: 'Doc hub' },
  areas: { icon: <DocIcon />, text: 'Doc hub' },
  kpiDashboard: { icon: <DashboardRounded style={{ color: teal[500] }} />, text: 'KPI hub' },
  dashboard: { icon: <DashboardRounded style={{ color: blue[500] }} />, text: 'Dashboard' },
  kpi: { icon: <KPIHubIcon />, text: 'KPI hub' },
  asset: { icon: <AssetIcon />, text: 'Asset hub' },
  assetProfile: { icon: <AssetIcon />, text: 'Asset hub' },
  audit: { icon: <AuditIcon />, text: 'Audit hub' },
  register: { icon: <RegistersIcon />, text: 'Register hub' },
  project: { icon: <ProjectIcon />, text: 'Projects' },
  routine: { icon: <RoutineIcon />, text: 'Routines' },
  web: { icon: <LanguageRounded color="primary" />, text: 'Web page' },
  view: { icon: <ViewQuiltRounded />, text: 'View' },
  workBig: { icon: <TaskIcon style={{ height: '70%', width: '70%' }} />, text: 'Work hub' },
  taskBig: { icon: <TaskIcon style={{ height: '70%', width: '70%' }} />, text: 'Tasks' },
  formBig: { icon: <FormIcon style={{ height: '70%', width: '70%' }} />, text: 'Form hub' },
  formEntryBig: { icon: <FormIcon style={{ height: '70%', width: '70%' }} />, text: 'Form hub' },
  processBig: { icon: <ProcessIcon style={{ height: '70%', width: '70%' }} />, text: 'Process hub' },
  processEntryBig: { icon: <ProcessIcon style={{ height: '70%', width: '70%' }} />, text: 'Process hub' },
  docBig: { icon: <DocIcon style={{ height: '65%', width: '65%' }} />, text: 'Doc hub' },
  areaBig: { icon: <DocIcon style={{ height: '65%', width: '65%' }} />, text: 'Doc hub' },
  areasBig: { icon: <DocIcon style={{ height: '65%', width: '65%' }} />, text: 'Doc hub' },
  kpiDashboardBig: {
    icon: <DashboardRounded style={{ height: '70%', width: '70%', color: teal[500] }} />,
    text: 'KPI hub',
  },
  dashboardBig: {
    icon: <DashboardRounded style={{ height: '70%', width: '70%', color: blue[500] }} />,
    text: 'Dashboard',
  },
  kpiBig: { icon: <KPIHubIcon style={{ height: '70%', width: '70%' }} />, text: 'KPI hub' },
  assetBig: { icon: <AssetIcon style={{ height: '70%', width: '70%' }} />, text: 'Asset hub' },
  assetProfileBig: { icon: <AssetIcon style={{ height: '70%', width: '70%' }} />, text: 'Asset hub' },
  auditBig: { icon: <AuditIcon style={{ height: '70%', width: '70%' }} />, text: 'Audit hub' },
  registerBig: { icon: <RegistersIcon style={{ height: '70%', width: '70%' }} />, text: 'Register hub' },
  projectBig: { icon: <ProjectIcon style={{ height: '70%', width: '70%' }} />, text: 'Projects' },
  routineBig: { icon: <RoutineIcon style={{ height: '70%', width: '70%' }} />, text: 'Routines' },
  webBig: { icon: <LanguageRounded style={{ height: '70%', width: '70%', color: 'primary' }} />, text: 'Web page' },
  viewBig: { icon: <ViewQuiltRounded style={{ height: '70%', width: '70%' }} />, text: 'View' },
};

function ActionItem({ editable, item, onDelete, onChange, portalSpaceId }) {
  const [loading, setLoading] = useState(false);
  const [colorPicker, setColorPicker] = useState(null);
  const [color, setColor] = useState(undefined);
  const [text, setText] = useState(null);
  const [image, setImage] = useState(null);
  const [icon, setIcon] = useState({ icon: 'ImageSearch' });
  const [fontSettings, setFontSettings] = useState({});
  const [uploadingImage, setUploadingImage] = useState(false);
  const [height, setHeight] = useState(null);
  const [sizeDialog, setSizeDialog] = useState(false);
  const [imageSize, setImageSize] = useState('contain');
  const theme = useTheme();
  const history = useHistory();
  const titleTimer = useRef(null);
  const elementRef = useRef(null);

  useEffect(() => {
    setColor(item?.color);
    setText(item?.text || null);
    setImage(item?.image || null);
    setFontSettings(item?.fontSettings || null);
    setIcon(item?.icon || { icon: 'ImageSearch' });
    setImageSize(item?.imageSize || 'contain');
  }, [item]);

  useEffect(() => {
    const updateSize = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        setHeight(rect.height);
      }
    };

    updateSize(); // Initial update
    window.addEventListener('resize', updateSize); // Update on resize

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  function saveColor(col) {
    setColor(col);
    onChange({ color: col });
  }

  function saveIcon(icon) {
    setIcon(icon);
    onChange({ icon: icon });
  }

  function saveTitle(title) {
    setText(title);
    if (titleTimer.current != null) {
      clearTimeout(titleTimer.current);
    }
    titleTimer.current = setTimeout(() => onChange({ text: title }), 1000);
  }

  function saveFontSettings(settings) {
    setFontSettings(settings);
    onChange({ fontSettings: settings });
  }

  function saveImageSize(size) {
    setImageSize(size);
    onChange({ imageSize: size });
  }

  function createTaskFromTemplate(templateId) {
    setLoading(true);
    axios
      .get('/tasks/templates/getTaskTemplate', { params: { templateId } })
      .then((res) => {
        StateManager.setNewTask({ template: res.data }, (task) => StateManager.selectTask(task._id));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function onClick() {
    if (item.type === 'taskTemplate') {
      createTaskFromTemplate(item.id);
    }
    if (item.type === 'form') {
      history.push(`/forms/${item.id}/new-entry${portalSpaceId ? `?portalSpaceId=${portalSpaceId}` : ''}`);
    }
    if (item.type === 'process') {
      history.push(`/processes/start-process/${item.id}${portalSpaceId ? `?portalSpaceId=${portalSpaceId}` : ''}`);
    }
    if (item.type === 'register') {
      history.push(`/registers/${item.id}?newRow=true`);
    }
  }

  function uploadFile(file) {
    setUploadingImage(true);
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        setImage(data.file);
        onChange({ image: data.file }, { h: 8 });
        setUploadingImage(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setUploadingImage(false);
      });
  }

  function handleOpenSize() {
    setSizeDialog(true);
  }
  return (
    <Grid
      container
      direction={'column'}
      style={{
        height: '100%',
        padding: 0,
        background: image ? `url('${image.location}') center center / ${imageSize} no-repeat` : color,
      }}
      wrap="nowrap"
      ref={elementRef}
    >
      {editable ? (
        <>
          <Grid
            container
            item
            alignItems="center"
            wrap="nowrap"
            style={{
              background: image ? `rgba(0,0,0, .5)` : 'inherit',
              maxHeight: 'fit-content',
              position: 'absolute',
              bottom: 0,
              paddingBottom: '5px',
              paddingLeft: '10px',
            }}
          >
            {icon && icon.icon !== 'ImageSearch'
              ? createElement(Icons[icon.icon], {
                  style: {
                    color: icon?.color
                      ? icon?.color
                      : image
                      ? '#ffffff'
                      : color
                      ? theme.palette.getContrastText(color)
                      : theme.palette.secondary,
                    mx: 2,
                  },
                })
              : types[item.type]?.icon}
            <InputBase
              style={{ marginLeft: 8 }}
              inputProps={{
                style: { fontSize: fontSettings?.size || 18, textAlign: fontSettings?.alignment ?? 'left' },
              }}
              value={text != null ? text : `${types[item.type]?.text} ${item.title}`}
              onChange={(e) => saveTitle(e.target.value)}
              className="non-draggable"
              sx={{
                width: '100%',
                color: image ? '#FFFFFF' : color ? theme.palette.getContrastText(color) : null,
                fontSize: fontSettings?.size || 18,
              }}
            />
            <ColorPicker
              anchor={colorPicker}
              open={Boolean(colorPicker)}
              column={400}
              customPicker
              onClose={(res) => {
                if (res?.color) {
                  saveColor(res?.color);
                }
                setColorPicker(null);
              }}
              className="non-draggable"
            />
            <MenuList
              image={image}
              item={item}
              uploadFile={uploadFile}
              uploadingImage={uploadingImage}
              onChange={onChange}
              color={color}
              setColorPicker={setColorPicker}
              icon={icon}
              saveIcon={saveIcon}
              onDelete={onDelete}
              fontSettings={fontSettings}
              saveFontSettings={saveFontSettings}
              mode={'action'}
              setSizeDialog={handleOpenSize}
            />
          </Grid>
          <ImageSizeDialog
            open={sizeDialog}
            onClose={() => setSizeDialog(false)}
            onChange={(value) => saveImageSize(value)}
          />
        </>
      ) : (
        <Grid
          container
          component={CardActionArea}
          onClick={onClick}
          justifyContent={image || height > 100 ? 'end' : 'center'}
          style={{
            padding: 0,
            height: '100%',
            width: '100%',
            borderRadius: 5,
            overflow: 'hidden',
            transition: 'background-color 0.3s ease',
            cursor: 'pointer',
          }}
          wrap="nowrap"
          direction="column"
        >
          {height > 100 && !item?.image && (
            <Grid container item justifyContent={'center'} maxHeight={'calc(100% - 50px)'} alignItems={'center'}>
              {item.icon &&
                icon.icon !== 'ImageSearch' &&
                !image &&
                createElement(Icons[item?.icon?.icon], {
                  style: {
                    color: item?.icon?.color ?? '#ffffff',
                    height: '50%',
                    width: '50%',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    flexGrow: 1,
                  },
                })}
              {!item.icon && types[`${item.type}Big`]?.icon}
            </Grid>
          )}
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justifyContent={fontSettings?.alignment ? fontSettings.alignment : 'center'}
            width="100%"
            style={{
              background: image ? `rgba(0,0,0, .5)` : 'inherit',
              maxHeight: 'fit-content',
              paddingLeft: '10px',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : item.icon && (height <= 100 || image) ? (
              createElement(Icons[item?.icon?.icon], {
                style: {
                  color: item?.icon?.color
                    ? item?.icon?.color
                    : image
                    ? '#ffffff'
                    : color
                    ? theme.palette.getContrastText(color)
                    : theme.palette.secondary,
                },
                mx: 2,
              })
            ) : height <= 100 || image ? (
              types[item.type]?.icon
            ) : null}
            <Typography
              noWrap
              style={{
                color: image ? '#FFFFFF' : color ? theme.palette.getContrastText(color) : null,
                marginLeft: 16,
                marginRight: 16,
                fontSize: fontSettings?.size ? fontSettings.size : 18,
              }}
            >
              {item.text || `${types[item.type]?.text} ${item.title}`}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export function ShortcutItem({ editable, item, onDelete, onChange, portalSpaceId, forDashboard = true }) {
  const { user } = useSelector(({ profile }) => profile);
  const [colorPicker, setColorPicker] = useState(null);
  const [color, setColor] = useState(undefined);
  const [text, setText] = useState(null);
  const [image, setImage] = useState(null);
  const [icon, setIcon] = useState({ icon: 'ImageSearch', color: '' });
  const [IconComponent, setIconComponent] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [fontSettings, setFontSettings] = useState({});
  const [sizeDialog, setSizeDialog] = useState(false);
  const [imageSize, setImageSize] = useState('contain');
  //const [height, setHeight] = useState(null);
  const theme = useTheme();
  const history = useHistory();
  const titleTimer = useRef(null);
  const [elementId] = useState(v4());
  const [height, setHeight] = useState(null);
  const elementRef = useRef(null);
  useEffect(() => {
    setColor(item?.color);
    setText(item?.text || null);
    setImage(item?.image || null);
    setIcon(item?.icon || null);
    setFontSettings(item?.fontSettings || null);
    setImageSize(item?.imageSize || 'contain');
  }, [item]);

  useEffect(() => {
    const updateSize = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        setHeight(rect.height);
      }
    };

    updateSize(); // Initial update
    window.addEventListener('resize', updateSize); // Update on resize

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  // Need to handle typescript loader before we can use the component
  // useEffect(() => {
  //   //eslint-disable-next-line
  // }, [icon]);

  // if (icon) {
  //   const iconName = icon.icon ?? 'ChevronRightRounded';
  //   const iconColor = icon.color ?? '';
  //   const loadIcon = async () => {
  //     try {
  //       const { default: ImportedIcon } = await import(`@mui/icons-material/${iconName}`);

  //       setIconComponent(<ImportedIcon sx={{ color: iconColor }} />);
  //     } catch (error) {
  //       console.error(`Icon "${iconName}" could not be loaded`, error);
  //     }
  //   };

  //   loadIcon();
  // }

  useEffect(() => {
    if (icon?.icon) {
      setIconComponent(
        createElement(Icons[icon?.icon], {
          style: {
            color: icon?.color
              ? icon?.color
              : image
              ? '#ffffff'
              : color
              ? theme.palette.getContrastText(color)
              : theme.palette.secondary,
            height: height > 100 && !image ? 'calc(100% - 50px)' : undefined,
            width: height > 100 && !image ? 'calc(100% - 50px)' : undefined,
          },
        }),
      );
    }
    //eslint-disable-next-line
  }, [icon, height]);

  function saveColor(col) {
    setColor(col);
    onChange({ color: col });
  }

  function saveIcon(icon) {
    setIcon(icon);
    onChange({ icon: icon });
  }

  function saveTitle(title) {
    setText(title);
    if (titleTimer.current != null) {
      clearTimeout(titleTimer.current);
    }
    titleTimer.current = setTimeout(() => onChange({ text: title }), 1000);
  }

  function saveImageSize(size) {
    setImageSize(size);
    onChange({ imageSize: size });
  }

  function handleOpenSize() {
    setSizeDialog(true);
  }

  function saveFontSettings(settings) {
    setFontSettings(settings);
    onChange({ fontSettings: settings });
  }

  function getWebPageIcon() {
    let parsed;
    try {
      parsed = new URL(item.link);
    } catch (_) {
      return null;
    }
    return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${parsed.host}&size=64`;
  }

  const webPageIcon = item?.type === 'web' ? getWebPageIcon() : null;

  function extractId(gridClass, type = 'process') {
    const prefixes = [
      `completed-${type}-entries-`,
      `working-${type}-entries-`,
      `held-${type}-entries-`,
      `cancelled-${type}-entries-`,
      `all-${type}-entries-`,
      `${type}-`,
    ];
    for (let prefix of prefixes) {
      if (gridClass.startsWith(prefix)) {
        return gridClass.split(prefix)[1];
      }
    }
    return null;
  }

  function getLinkForView(gridClass) {
    if (gridClass === 'controlledDocHub') {
      return '/DocLibrary/controlled';
    }
    if (gridClass === 'bookmark') {
      return '/DocLibrary/bookmarked';
    }
    if (gridClass === 'responsibleDocHub') {
      return '/DocLibrary/responsible';
    }
    if (gridClass.startsWith('docArea-')) {
      const area = gridClass.split('-')[1];
      if (area) {
        return `/DocLibrary/areas/${area}`;
      }
    }
    if (gridClass.startsWith('docAreaNavigation-')) {
      const area = gridClass.split('-')[1];
      const folder = gridClass.split('-')[2];
      if (area && folder) {
        return `/DocLibrary/areas/${area}/${folder}`;
      }
    }
    if (gridClass === 'my-tasks-working') {
      return '/tasks/my';
    }
    if (gridClass === 'assigned-tasks-working') {
      return '/tasks/assigned';
    }
    if (gridClass.includes('process')) {
      if (gridClass === 'processes-working-entries') {
        return '/processes/working';
      }
      if (gridClass === 'processes-completed-entries') {
        return '/processes/completed';
      }
      if (gridClass === 'processes-held-entries') {
        return '/processes/held';
      }
      if (gridClass === 'processes-cancelled-entries') {
        return '/processes/cancelled';
      }
      if (
        gridClass.startsWith('completed-process-entries-') ||
        gridClass.startsWith('working-process-entries-') ||
        gridClass.startsWith('held-process-entries-') ||
        gridClass.startsWith('cancelled-process-entries-') ||
        gridClass.startsWith('all-process-entries-')
      ) {
        // extract process id from class name by pattern
        const processId = extractId(gridClass, 'process');
        if (processId) {
          return `/processes/${processId}`;
        }
      }
    }
    if (gridClass.includes('form')) {
      if (gridClass === 'forms-working-entries') {
        return '/forms/working';
      }
      if (gridClass === 'forms-completed-entries') {
        return '/forms/completed';
      }
      if (
        gridClass.startsWith('completed-form-entries-') ||
        gridClass.startsWith('working-form-entries-') ||
        gridClass.startsWith('all-form-entries-')
      ) {
        const formId = extractId(gridClass, 'form');
        if (formId) {
          return `/forms/${formId}`;
        }
      }
    }
    if (gridClass === 'assetTasks') {
      return '/asset/tasks';
    }
    if (gridClass === 'assets') {
      return '/asset/all';
    }
    if (gridClass.startsWith('register-')) {
      const registerId = extractId(gridClass, 'register');
      return `/registers/${registerId}`;
    }
  }

  function getItemLink() {
    if (item.link) return item.link;
    if (item.type === 'project') return `/tasks/projects/project/${item.id}`;
    if (item.type === 'routine') return `/tasks/routine/${item.id}`;
    if (item.type === 'form')
      return portalSpaceId ? `/forms/${item.id}?portalSpaceId=${portalSpaceId}` : `/forms/${item.id}`;
    if (item.type === 'process')
      return portalSpaceId ? `/processes/${item.id}?portalSpaceId=${portalSpaceId}` : `/processes/${item.id}`;
    if (item.type === 'asset') return `/asset/${item.id}`;
    if (item.type === 'assetProfile') return `/asset/profile/${item.id}`;
    if (item.type === 'audit') return `/audits/${item.id}`;
    if (item.type === 'register') return `/registers/${item.id}`;
    if (item.type === 'doc') return `/DocLibrary/preview/${item.id}`;
    if (item.type === 'area') return `/DocLibrary/areas/${item.id}`;
    if (item.type === 'kpiDashboard') return `/kpiHub/dashboard/${item.id}`;
    if (item.type === 'dashboard') {
      if (user?.access === 'portal') {
        return portalSpaceId
          ? `/portal/dashboard/${item.id}?portalSpaceId=${portalSpaceId}`
          : `/portal/dashboard/${item.id}`;
      } else {
        return `/tasks/workspaces/${item.id}`;
      }
    }

    if (item.type === 'docFolder') {
      if (item.subId) {
        return `/DocLibrary/areas/${item.id}/${item.subId}`;
      }
    }
    if (item.type === 'view') {
      const viewId = item.id;
      if (item.access === 'private' && user?._id !== item.userId) {
        StateManager.setErrorAlert('You do not have access to this view');
        return '#';
      }
      const viewLink = getLinkForView(item.gridClass);
      if (viewLink) {
        return `${viewLink}?viewId=${viewId}`;
      }
    }

    return '#';
  }

  function onClick() {
    if (item.type === 'task' && item.id) {
      StateManager.selectTask(item.id);
      return;
    }
    if (item.type === 'web') {
      window.open(item.link, '_blank');
      return;
    }
    const link = getItemLink();
    history.push(link);
  }

  function uploadFile(file) {
    setUploadingImage(true);
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        setImage(data.file);
        onChange({ image: data.file }, { h: 8 });
        setUploadingImage(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setUploadingImage(false);
      });
  }

  return (
    <Grid
      container
      style={{
        height: forDashboard ? '100%' : null,
        background: image ? `url('${image.location}') center center / ${imageSize} no-repeat` : color,
      }}
      wrap="nowrap"
      direction={'column'}
      id={elementId}
      ref={elementRef}
    >
      {editable ? (
        <>
          <Grid
            container
            item
            alignItems="center"
            wrap="nowrap"
            style={{
              background: image ? `rgba(0,0,0, .5)` : 'inherit',
              maxHeight: 'fit-content',
              position: 'absolute',
              bottom: 0,
              paddingBottom: '5px',
              paddingLeft: '10px',
            }}
          >
            {webPageIcon ? (
              <Avatar variant="rounded" style={{ height: 24, width: 24 }} src={webPageIcon}>
                <LanguageRounded color="primary" />
              </Avatar>
            ) : icon && icon.icon !== 'ImageSearch' ? (
              createElement(Icons[icon.icon], {
                style: {
                  color: icon?.color
                    ? icon?.color
                    : image
                    ? '#ffffff'
                    : color
                    ? theme.palette.getContrastText(color)
                    : theme.palette.secondary,
                },
              })
            ) : (
              types[item.type]?.icon
            )}

            <InputBase
              style={{ marginLeft: 8 }}
              inputProps={{
                style: { fontSize: fontSettings?.size || 18, textAlign: fontSettings?.alignment ?? 'left' },
              }}
              value={text != null ? text : item.title}
              onChange={(e) => saveTitle(e.target.value)}
              className="non-draggable"
              sx={{
                width: '100%',
                color: image ? '#FFFFFF' : color ? theme.palette.getContrastText(color) : icon?.color || 'primary.main',
                fontSize: fontSettings?.size || 18,
                textAlign: fontSettings?.alignment ?? 'left',
                justifyContent: fontSettings?.alignment ?? 'left',
              }}
            />
            <ColorPicker
              anchor={colorPicker}
              open={Boolean(colorPicker)}
              column={400}
              customPicker
              onClose={(res) => {
                if (res?.color) {
                  saveColor(res?.color);
                }
                setColorPicker(null);
              }}
              className="non-draggable"
            />
            <MenuList
              image={image}
              item={item}
              uploadFile={uploadFile}
              uploadingImage={uploadingImage}
              onChange={onChange}
              color={color}
              setColorPicker={setColorPicker}
              icon={icon}
              saveIcon={saveIcon}
              onDelete={onDelete}
              fontSettings={fontSettings}
              saveFontSettings={saveFontSettings}
              mode={'shortcut'}
              setSizeDialog={handleOpenSize}
            />
          </Grid>
          <ImageSizeDialog
            open={sizeDialog}
            onClose={() => setSizeDialog(false)}
            onChange={(value) => saveImageSize(value)}
          />
        </>
      ) : (
        <Grid
          container
          item
          component={CardActionArea}
          onClick={onClick}
          style={{
            height: '100%',
            width: '100%',
            borderRadius: 5,
            background: image || color,
            justifyContent: height > 100 ? 'flex-end' : 'center',
          }}
          wrap="nowrap"
          direction="column"
        >
          {!item?.image && height > 100 ? (icon ? IconComponent : shortcutsTypes[`${item.type}Big`]?.icon) : null}
          <Grid
            container
            alignItems={'center'}
            justuifyContent={'flex-end'}
            direction={'column'}
            wrap="nowrap"
            width="100%"
            maxHeight="100%"
          >
            <Grid
              container
              wrap="nowrap"
              width="100%"
              justifyContent={image && height > 100 && 'flex-end'}
              style={{ background: image ? `rgba(0,0,0, .5)` : undefined }}
            >
              <Typography
                noWrap
                style={{
                  display: 'flex',
                  width: '100%',
                  maxWidth: '100%',
                  color: image ? '#FFFFFF' : color ? theme.palette.getContrastText(color) : undefined,
                  fontSize: fontSettings?.size || 18,
                  borderRadius: 2,
                  alignItems: 'center',
                  justifyContent: fontSettings?.alignment || 'start',
                  textAlign: fontSettings?.alignment || 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <Grid
                  container
                  item
                  height={30}
                  width={30}
                  alignItems={'center'}
                  mx={1}
                  display={height > 100 ? 'none' : 'flex'}
                >
                  {webPageIcon ? (
                    <Avatar src={webPageIcon} variant="rounded" style={{ height: 24, width: 24 }}>
                      <LanguageRounded color="primary" />
                    </Avatar>
                  ) : !icon && height <= 100 ? (
                    shortcutsTypes[item.type]?.icon
                  ) : icon && height <= 100 ? (
                    IconComponent
                  ) : item?.image ? (
                    icon ? (
                      IconComponent
                    ) : (
                      shortcutsTypes[item.type]?.icon
                    )
                  ) : null}
                </Grid>
                <Typography
                  sx={{
                    marginX: '10px',
                    fontSize: fontSettings?.size || 18,
                    justifyContent: fontSettings?.alignment || 'start',
                    textAlign: fontSettings?.alignment || 'left',
                  }}
                >
                  {item.text || item.title}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

function ShortcutBar({ data, editable, onEdit, onDelete, forPortal, portalSpaceId }) {
  const [title, setTitle] = useState('');
  const [dialog, setDialog] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!data) return;
    setTitle(data.title);
    setItems(data.items);
  }, [data]);

  function addShortcut(shortcut) {
    items.push({ data: shortcut, id: v4(), type: 'shortcut' });
    setItems([...items]);
    onEdit({ title, items });
  }

  function deleteItem(id) {
    const remaining = items.filter((x) => x.id !== id);
    setItems(remaining);
    onEdit({ title, items: remaining });
  }

  function editItem(itemId, update) {
    const index = items.findIndex((x) => x.id === itemId);
    if (index === -1) return;
    items[index].data = { ...items[index].data, ...update };
    onEdit({ title, items });
  }

  return (
    <Grid container style={{ height: '100%', position: 'relative' }}>
      <Grid
        container
        direction="column"
        style={{
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          padding: 8,
        }}
      >
        <Grid container item alignItems="center" wrap="nowrap" style={{ padding: 8 }}>
          <AdjustRounded style={{ color: grey[500] }} />
          {editable ? (
            <InputBase
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                onEdit({ title: e.target.value, items });
              }}
              onMouseDown={(e) => e.stopPropagation()}
              placeholder="Enter title"
              inputProps={{ maxLength: 256, style: { fontSize: 20 } }}
              style={{ marginLeft: 16, fontSize: 20 }}
            />
          ) : (
            <Typography style={{ marginLeft: 16, fontSize: 20 }}>{title}</Typography>
          )}
          {editable && (
            <>
              <IconButton color="primary" style={{ marginLeft: 'auto' }} onClick={() => setDialog(true)}>
                <AddRounded />
              </IconButton>
              <AddLinkDialog
                open={dialog}
                onClose={() => setDialog(false)}
                forDashboard
                onResult={addShortcut}
                forPortal={forPortal}
              />
              <IconButton onClick={(e) => onDelete()}>
                <DeleteOutlineRounded style={{ color: red[500] }} />
              </IconButton>
            </>
          )}
        </Grid>

        <Grid
          container
          item
          alignItems="center"
          style={{ marginTop: 16, padding: 8, overflow: 'auto', flex: 1 }}
          alignContent="flex-start"
        >
          {items.map((item) => (
            <Grid item container key={item.id} lg={3} md={4} xs={6} style={{ padding: 4 }}>
              <Paper variant="outlined" style={{ borderRadius: 8, border: `2px solid ${grey[300]}`, width: '100%' }}>
                <ShortcutItem
                  editable={editable}
                  item={item.data}
                  onDelete={() => deleteItem(item.id)}
                  onChange={(update) => editItem(item.id, update)}
                  portalSpaceId={portalSpaceId}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

function TaskBox({ editable, onDelete, onEdit, params, onChange }) {
  const [dialog, setDialog] = useState(false);
  const [boxParams, setBoxParams] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [colorPicker, setColorPicker] = useState(null);
  const [color, setColor] = useState(undefined);
  const tasksRef = useRef([]);
  const deleteSubscription = useRef(null);
  const updateSubscription = useRef(null);
  const [elementId] = useState(v4());
  const { height, width } = useElementSize(elementId);
  const fontSize = Math.min(height / 2.5, width / 6);

  useEffect(() => {
    if (!params || !params.type) return;
    setBoxParams(params);
    setColor(params.color || undefined);
    if (editable) return;
    setLoading(true);
    axios
      .post('/tasks/getTasksForBox', { box: params })
      .then((res) => {
        setTasks(res.data);
        tasksRef.current = res.data;

        const deleteCallback = ({ taskIds }) => {
          tasksRef.current = tasksRef.current.filter((x) => !taskIds.includes(x._id));
          setTasks(tasksRef.current);
        };

        const updateCallback = ({ task }) => {
          const index = tasksRef.current.findIndex((x) => x._id === task._id);
          if (index === -1) return;
          tasksRef.current[index] = { ...tasksRef.current[index], ...task };
          setTasks([...tasksRef.current]);
        };

        deleteSubscription.current = TaskManager.subscribeToAnyTaskDelete(deleteCallback);
        updateSubscription.current = TaskManager.subscribeToAnyTaskUpdate(updateCallback);

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params, editable, params?.type, params?.id, params?.dueFilter]);

  const priorityColors = {
    None: grey[400],
    Low: green[500],
    Medium: amber[500],
    High: deepOrange[500],
    Critical: deepPurple[500],
  };

  const dueOptions = {
    today: { text: 'Due today', color: amber[600] },
    thisWeek: { text: 'Due this week', color: blue[600] },
    overdue: { text: 'Overdue', color: red[600] },
  };

  function getTitle() {
    const suffix = boxParams && dueOptions[boxParams?.dueFilter] ? `(${dueOptions[boxParams?.dueFilter].text})` : '';
    if (!boxParams?.type) return;
    if (boxParams?.type === 'my') return `My tasks  ${suffix}`;
    if (boxParams?.type === 'assigned') return `Assigned tasks ${suffix}`;
    if (boxParams?.type === 'projects') return `Tasks from all projects ${suffix}`;
    if (boxParams?.type === 'project') return `Tasks from project '${boxParams.title}' ${suffix}`;
    if (boxParams?.type === 'process') return `Tasks from process '${boxParams.title}' ${suffix}`;
    if (boxParams?.type === 'team') return `Tasks from team '${boxParams.title}' ${suffix}`;
    return '';
  }
  function saveColor(col) {
    setColor(col);
    onChange({ color: col });
  }

  function formatDate(date) {
    if (!date) return '-';
    const dt = moment(date);
    if (dt.isSame(moment(), 'day')) {
      // if today, only time
      return dt.format('hh:mm A');
    }
    return dt.format('DD/MM/YYYY');
  }

  function openTaskDialog() {
    if (tasks.length === 0) return;
    if (tasks.length === 1) {
      StateManager.selectTask(tasks[0]._id);
      return;
    }
    setDialogOpen(true);
  }
  if (!params || !params.type) return;
  return (
    <Grid container style={{ height: '100%', backgroundColor: color }} direction="column" id={elementId}>
      {editable ? (
        <>
          <ColorPicker
            anchor={colorPicker}
            open={Boolean(colorPicker)}
            column={400}
            customPicker
            onClose={(res) => {
              if (res?.color) {
                saveColor(res?.color);
              }
              setColorPicker(null);
            }}
            className="non-draggable"
          />
          {boxParams?.displayAs === 'table' && (
            <Grid container item alignItems="center" wrap="nowrap">
              <WorkOutlineRounded style={{ color: grey[500], marginRight: 16 }} />
              <Typography noWrap>Task box</Typography>
              <IconButton style={{ marginLeft: 'auto' }} onClick={(e) => setDialog(true)}>
                <EditRounded style={{ color: grey[500] }} />
              </IconButton>
              <MenuList
                onDelete={onDelete}
                color={color}
                onChange={onChange}
                setColorPicker={setColorPicker}
                mode={'taskBox'}
              />
            </Grid>
          )}

          <Grid container item alignItems="center" justifyContent="center" style={{ flex: 1 }} wrap="nowrap">
            <Typography color="textSecondary">{getTitle()}</Typography>
            {boxParams?.displayAs === 'tile' && (
              <>
                <IconButton style={{ marginLeft: 'auto' }} onClick={(e) => setDialog(true)}>
                  <EditRounded style={{ color: grey[500] }} />
                </IconButton>
                <MenuList
                  onDelete={onDelete}
                  color={color}
                  onChange={onChange}
                  setColorPicker={setColorPicker}
                  mode={'taskBox'}
                />
              </>
            )}
          </Grid>

          {editable && (
            <TaskBoxOptions
              open={dialog}
              onClose={() => setDialog(false)}
              initial={boxParams}
              onResult={(res) => {
                setBoxParams(res);
                onEdit(res);
              }}
            />
          )}
        </>
      ) : (
        <>
          {boxParams?.displayAs === 'tile' ? (
            loading ? (
              <Grid container alignItems="center" wrap="nowrap" justifyContent="space-between">
                <Skeleton variant="text" style={{ flexGrow: 1, marginRight: 16 }} />
                <Skeleton variant="circular" width={40} height={40} />
              </Grid>
            ) : (
              <ListItem
                disabled={tasks?.length === 0}
                button
                onClick={openTaskDialog}
                style={{ height: '100%', width: '100%', borderRadius: 5 }}
              >
                <Grid container alignItems="center" wrap="nowrap" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    fontSize={fontSize ?? 16}
                    color={theme.palette.getContrastText(color || grey[400])}
                  >
                    {getTitle()}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: fontSize * 1.3,
                      fontWeight: 600,
                      marginLeft: 8,
                      color: theme.palette.getContrastText(color || theme.palette.getContrastText(grey[400])),
                    }}
                  >
                    {tasks.length}
                  </Typography>
                </Grid>
              </ListItem>
            )
          ) : (
            <Grid container direction="column" style={{ height: '100%', position: 'relative', padding: 8 }}>
              <Grid container item alignItems="center" style={{ padding: 8, position: 'absolute', right: 0, top: 0 }}>
                {loading ? (
                  <>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width={100} style={{ marginLeft: 16 }} />
                  </>
                ) : (
                  <>
                    <WorkOutlineRounded style={{ color: grey[500], marginRight: 16 }} />
                    <Typography variant="h6">{getTitle()}</Typography>
                  </>
                )}
              </Grid>

              <Grid container item alignContent="flex-start" style={{ padding: 8, flex: 1, overflow: 'auto' }}>
                {loading ? (
                  range(5).map((x) => (
                    <Grid
                      container
                      component={Paper}
                      key={x}
                      variant="outlined"
                      sx={{ p: 0, my: 1, border: `2px solid ${grey[300]}`, borderRadius: 1.5 }}
                    >
                      <ListItem sx={{ py: 1, px: 2, width: '100%', borderRadius: 1.5 }}>
                        <Grid container item alignItems="center">
                          <Skeleton variant="circular" width={40} height={40} />
                          <Skeleton variant="text" width={150} style={{ marginLeft: 16 }} />
                        </Grid>
                      </ListItem>
                    </Grid>
                  ))
                ) : (
                  <>
                    {tasks.length === 0 && (
                      <Typography variant="h6" color="textSecondary">
                        No tasks here
                      </Typography>
                    )}
                    {tasks.map((task) => (
                      <Grid
                        container
                        component={Paper}
                        key={task._id}
                        variant="outlined"
                        sx={{ p: 0, my: 1, border: `2px solid ${grey[300]}`, borderRadius: 1.5 }}
                      >
                        <ListItem
                          onClick={() => StateManager.selectTask(task._id)}
                          button
                          sx={{ py: 1, px: 2, width: '100%', borderRadius: 1.5 }}
                        >
                          <Grid container item alignItems="center">
                            <Grid container item alignItems="center" xs={6}>
                              <WhatshotRounded style={{ color: priorityColors[task.priority], marginRight: 16 }} />
                              <Typography style={{ fontSize: 18, fontWeight: 500 }} noWrap>
                                {task.title}
                              </Typography>
                            </Grid>
                            <Grid container item alignItems="center" xs={6} justifyContent="space-between">
                              <UserGroup ids={task.assignedUsers} />
                              <Typography
                                style={{
                                  color: task.isOverdue ? red[500] : '',
                                  fontWeight: task.isOverdue ? 700 : 400,
                                }}
                                noWrap
                              >
                                {formatDate(task.dueAt)}
                              </Typography>
                              <StatusButton status={task.progress} isDisabled isOverdue={task.isOverdue} />
                            </Grid>
                          </Grid>
                        </ListItem>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          )}
          <TasksDialog tasks={tasks} title={getTitle()} open={dialogOpen} onClose={() => setDialogOpen(false)} />
        </>
      )}
    </Grid>
  );
}

function FolderItem({ data, editable, onDelete, onEdit, onChange }) {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [colorPicker, setColorPicker] = useState(null);
  const [color, setColor] = useState(null);
  const [elementId] = useState(v4());
  const { height, width } = useElementSize(elementId);
  const [fontSettings, setFontSettings] = useState({});
  const fontSizeCoefficient = fontSettings?.size
    ? fontSettings.size === '24px'
      ? [2, 3]
      : fontSettings.size === '16px'
      ? [4, 6]
      : fontSettings.size === '10px'
      ? [8, 16]
      : [4, 6]
    : [4, 6];
  const fontSize = Math.min(height / fontSizeCoefficient[0], width / fontSizeCoefficient[1]);

  function saveColor(color) {
    setColor(color);
    onEdit({ color, title, folder: data?.folder });
  }

  function saveFontSettings(settings) {
    setFontSettings(settings);
    onChange({ fontSettings: settings });
  }

  useEffect(() => {
    setTitle(data?.title || '');
    setColor(data?.color || null);
    setFontSettings(data?.fontSettings || {});
  }, [data]);

  function onClick() {
    let link = '/DocLibrary/areas';
    if (!data?.folder) return;
    if (data?.folder?.area && !data.folder?.folder) {
      link = `${link}/${data?.folder?.area}`;
    }
    if (data?.folder?.area && data.folder?.folder) {
      link = `${link}/${data?.folder?.area}/${data.folder?.folder}`;
    }
    history.push(link);
  }

  return (
    <Grid container style={{ height: '100%' }} id={elementId}>
      {editable ? (
        <Grid container alignItems="center" wrap="nowrap" sx={{ p: 1 }}>
          <FolderRounded style={{ color: grey[700] }} />
          <InputBase
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              onEdit({ title: e.target.value, color, folder: data?.folder });
            }}
            onMouseDown={(e) => e.stopPropagation()}
            placeholder="Enter title"
            inputProps={{ maxLength: 256, style: { fontSize: 20 } }}
            style={{ marginLeft: 16, fontSize: 20 }}
          />
          <Tooltip title="Change color" placement="top"></Tooltip>
          <ColorPicker
            anchor={colorPicker}
            open={Boolean(colorPicker)}
            column={400}
            customPicker
            onClose={(res) => {
              if (res?.color) {
                saveColor(res?.color);
              }
              setColorPicker(null);
            }}
            className="non-draggable"
          />
          <MenuList
            mode={'header'}
            onDelete={onDelete}
            setColorPicker={setColorPicker}
            color={color}
            onChange={onChange}
            fontSettings={fontSettings}
            saveFontSettings={saveFontSettings}
          />
        </Grid>
      ) : (
        <CardActionArea
          style={{ padding: 16, height: '100%', width: '100%', borderRadius: 5, background: color }}
          onClick={onClick}
        >
          <Grid
            container
            style={{ height: '100%' }}
            alignItems="center"
            wrap="nowrap"
            justifyContent={fontSettings?.alignment || 'center'}
          >
            <FolderRounded style={{ color: grey[700] }} />
            <TooltipTypography style={{ marginLeft: 16, fontSize }} noWrap>
              {title ? title : isArray(data?.folder?.path) ? data.folder.path.join('/') : ''}
            </TooltipTypography>
          </Grid>
        </CardActionArea>
      )}
    </Grid>
  );
}

function ImageSizeDialog({ open, onClose, size, onChange }) {
  const [imageSize, setImageSize] = useState();
  useEffect(() => {
    setImageSize(size || 'contained');
  }, [size]);

  function handleChange(e, newVal) {
    setImageSize(newVal);
    onChange(newVal);
  }

  return (
    <RoundedDialog open={open}>
      <DialogTitle title="Image Size">{'Change image size'}</DialogTitle>
      <DialogContent>
        <ToggleButtonGroup value={imageSize} exclusive onChange={handleChange}>
          <ToggleButton value="contain">{'Contained'}</ToggleButton>
          <ToggleButton value="cover">{'Full-Sized'}</ToggleButton>
        </ToggleButtonGroup>
      </DialogContent>
      <StandardDialogActions onClose={onClose}></StandardDialogActions>
    </RoundedDialog>
  );
}
