import React, { useEffect, useState } from 'react';
import { RoundedDialog, StandardDialogActions } from './index';
import { DialogTitle, DialogContent } from '@mui/material';
import ContentEditor from '../ContentEditor';
import { v4 } from 'uuid';

export default function ContentEditorDialog({ open, onClose, onResult, initial }) {
  const [value, setValue] = useState('');
  const [id] = useState(v4());
  const TINY_HEADER_HEIGHT = 40;
  const MIN_HEIGHT = 300;

  useEffect(() => {
    setValue(initial || '');
  }, [initial]);

  function done() {
    onResult(value);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit text</DialogTitle>
      <DialogContent>
        <ContentEditor
          config={{
            width: '100%',
            height: MIN_HEIGHT + TINY_HEADER_HEIGHT,
            min_height: MIN_HEIGHT + TINY_HEADER_HEIGHT,
            resize: true,
            toolbar: 'image | fontsizeselect forecolor backcolor bold italic underline | bullist numlist',
            statusbar: false,
          }}
          handleEditorChange={(val) => setValue(val)}
          value={value}
          id={`rich-text-field-${id}`}
        />
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
