import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';
import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import TaskInfo from '../ReviewDialog/taskInfo';
import ReviewApprovals from '../ReviewDialog/approvals';
import CommentsSection from '../Comments';
import ActionGroup from '../../../../Global/Fields/ActionGroup';
import axios from 'axios';

export default ({ open, handleClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { tasks, review } = useSelector(({ review }) => review);
  const [dueDate, setDueDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open || !review || !review?.hubTask) return;
    setLoading(true);
    axios
      .get(`/tasks/getTask`, { params: { id: review?.hubTask } })
      .then((res) => {
        setDueDate(res.data?.dueAt);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [open, review]);

  const getReviewKPI = (completedDate, plannedDate) => {
    if (!plannedDate) return null;
    if (moment(completedDate).isBefore(plannedDate, 'day')) {
      return (
        <Typography variant="subtitle2" color="primary">
          Review was completed early
        </Typography>
      );
    }
    if (moment(completedDate).isSame(plannedDate, 'day')) {
      return (
        <Typography variant="subtitle2" color="primary">
          Review was completed on time
        </Typography>
      );
    }
    return (
      <Typography variant="subtitle2" color="error">
        Review was completed late
      </Typography>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="review-dialog-title"
      fullWidth={true}
      maxWidth={'md'}
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">
        {loading ? (
          <Skeleton variant="text" />
        ) : dueDate ? (
          `Planned Date: ${moment(dueDate).format('DD/MM/YY')} `
        ) : (
          'Review was not scheduled'
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>Review Completed on</Typography>
        <Typography>{moment(review?.completedAt || review?.createdAt).format('DD/MM/YY')}</Typography>
        {review?.completedAt && !loading && getReviewKPI(review?.completedAt, dueDate)}
        <Divider sx={{ marginTop: '20px', marginBottom: '20px', width: '100%' }} />
        <Typography>Review Reason</Typography>
        <Typography>{review?.reason}</Typography>
        <Divider sx={{ marginTop: '20px', marginBottom: '20px', width: '100%' }} />
        <Typography>Review Outcome</Typography>
        <Typography>{review?.outcome}</Typography>
        <Divider sx={{ marginTop: '20px', marginBottom: '20px', width: '100%' }} />
        <List dense>
          <Typography>Actions Taken</Typography>
          {tasks?.length === 0 && <Typography variant="caption">No Action Taken yet</Typography>}
          {tasks?.length > 0 && tasks?.map((t, i) => <TaskInfo task={t} key={i} actions={false} />)}
        </List>
        <ActionGroup forReview itemId={review?._id} activityInfo={{ entryId: review?._id }} />
        <Divider sx={{ marginTop: '20px', marginBottom: '20px', width: '100%' }} />
        <Grid container item direction="column" sx={{ marginTop: '15px' }}>
          <ReviewApprovals
            data={review?.approvals}
            onSubmit={() => {}}
            title=""
            isAutomated={review?.isAutomated && review?.isSystemGenerated}
          />
        </Grid>
        <Grid container direction={'column'} sx={{ overFlowY: 'scroll' }}>
          <Divider sx={{ marginTop: '20px', marginBottom: '20px', width: '100%' }} />
          <Typography>Comments</Typography>
          <CommentsSection
            disabled
            showCommentBar={false}
            upIssueComment={false}
            versionSelector={false}
            comments={review?.comments || []}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          sx={{ color: (t) => (t.palette.mode === 'dark' ? 'secondary.main' : 'primary.main') }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
