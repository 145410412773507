import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

export default function TooltipIconButton({ text, children, ...props }) {
  return (
    <Tooltip title={text || ''}>
      <IconButton {...props}>{children}</IconButton>
    </Tooltip>
  );
}
