import { useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { blueberryTwilightPalette } from '@mui/x-charts/colorPalettes';
import { LineChart } from '@mui/x-charts/LineChart';
import { isArray, isFunction } from 'lodash';
import Legend from './Legend';

export default function Chart({ data, onMarkClick, isPreview, chartParams }) {
  const theme = useTheme();
  const standardPalette = blueberryTwilightPalette(theme?.palette?.mode || 'light');
  const legendPosition = chartParams?.legendPosition || 'top';
  const legendHidden = chartParams?.hideLegend || Boolean(isPreview) || data?.useValue;
  const xAxisLabel = data?.xAxisLabel || '';
  const yAxisLabel = data?.yAxisLabel || '';

  // color chart lines
  const chartSeries = useMemo(() => {
    const targetLines = isArray(data?.targetLines) ? data.targetLines : [];

    const result = [
      {
        data: data?.data || [],
        label: data.label || 'Value',
      },
      ...targetLines,
    ];

    // reassign colors
    for (let i = 0; i < result.length; ++i) {
      result[i].color = standardPalette[i % standardPalette.length];
    }

    return result;
  }, [data, standardPalette]);

  const legendData = useMemo(() => {
    const linesLabels = chartSeries.map((x) => ({ label: x.label, color: x.color }));
    return linesLabels;
  }, [chartSeries]);

  // if render as 'no data'
  if (data?.data === null) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Typography color={'textSecondary'} variant={isPreview ? 'subtitle1' : 'h6'}>
          No data to display
        </Typography>
      </Grid>
    );
  }

  // if something's wrong
  if (!isArray(data?.xAxis)) return null;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
      direction={'column'}
      wrap="nowrap"
    >
      {!legendHidden && legendPosition === 'top' && (
        <Grid container sx={{ height: 32 }}>
          <Legend items={legendData} />
        </Grid>
      )}
      <Grid container style={{ flexGrow: 1, position: 'relative' }}>
        <Grid container style={{ height: '100%', position: 'absolute' }}>
          <LineChart
            xAxis={[{ data: data.xAxis, scaleType: 'band', label: xAxisLabel }]}
            yAxis={[{ label: yAxisLabel }]}
            series={chartSeries}
            onMarkClick={(_event, { dataIndex }) => isFunction(onMarkClick) && onMarkClick(dataIndex)}
            grid={{ horizontal: true, vertical: true }}
            tooltip={{ trigger: 'item' }}
            slotProps={{ legend: { hidden: true } }}
          />
        </Grid>
      </Grid>
      {!legendHidden && legendPosition === 'bottom' && (
        <Grid container sx={{ height: 32 }}>
          <Legend items={legendData} />
        </Grid>
      )}
    </Grid>
  );
}
