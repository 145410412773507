/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import { CLIENTID } from '../../../constants';
import { uploadPdf } from '../../Hubs/dochub/redux/actions';
class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId, viewerConfig, file, readOnly = false, props) {
    const config = {
      /* Pass your registered client id */
      clientId: CLIENTID,
    };
    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config);

    if (this.adobeDCView && file?.location) {
      /* Invoke the file preview API on Adobe DC View object */
      const previewFilePromise = this.adobeDCView
        .previewFile(
          {
            /* Pass information on how to access the file */
            content: {
              /* Location of file where it is hosted */
              location: {
                url: file.location,
              },
            },
            /* Pass meta data of file */
            metaData: {
              /* file name */
              fileName: file.name,
              /* file ID */
              id: file.id,
              hasReadOnlyAccess: readOnly,
            },
          },
          viewerConfig,
        )
        .then((adobeViewer) => {
          if (!readOnly && props) {
            this.registerAnnotationsListener(adobeViewer, props);
          }
        })
        .catch((error) => console.log(error));
      return previewFilePromise;
    }
    return null;
  }

  previewFileUsingFilePromise(divId, filePromise, fileName) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View({
      /* Pass your registered client id */
      clientId: CLIENTID,
      /* Pass the div id in which PDF should be rendered */
      divId,
    });

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* pass file promise which resolve to arrayBuffer */
          promise: filePromise,
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName: fileName,
        },
      },
      {},
    );
  }

  registerSaveApiHandler(file, encrypted, onUpdate) {
    const saveConfig = {
      autoSaveFrequency: 3,
      showSaveButton: false,
    };
    /* Define Save API Handler */
    const saveApiHandler = async (metaData, content, options) => {
      const updatedFile = await uploadPdf(content, file.name, encrypted);
      if (onUpdate) {
        onUpdate(updatedFile?.file);
      }
      return new Promise((resolve) => {
        const response = {
          code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
          data: {
            metaData: Object.assign(metaData, {
              updatedAt: new Date().getTime(),
            }),
            file: updatedFile.file,
          },
        };
        resolve(response);
      });
    };
    if (this.adobeDCView) {
      this.adobeDCView.registerCallback(window.AdobeDC.View.Enum.CallbackType.SAVE_API, saveApiHandler, saveConfig);
    }
  }

  registerEventsHandler() {
    const eventOptions = {
      enablePDFAnalytics: true,
    };
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /* call back function */
      (event) => event.preventDefault(),
      /* options to control the callback execution */
      eventOptions,
    );
  }

  registerUserCallback(profile) {
    if (this.adobeDCView) {
      this.adobeDCView.registerCallback(
        window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
        () => {
          return new Promise((resolve) => {
            resolve({
              code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
              data: profile,
            });
          });
        },
        {},
      );
    }
  }

  registerViewAnnotationsListener = (adobeViewer) => {
    let standardConfig = { showCommentsPanel: true };
    adobeViewer.getAnnotationManager().then((annotationManager) => {
      annotationManager.setConfig(standardConfig);
    });
  };

  registerAnnotationsListener = (adobeViewer, props) => {
    const {
      initRemove = false,
      onRemove = null,
      onAdd = null,
      onUpdate,
      selectId,
      onSelect,
      onReply,
      comments,
      onAnnotationCount,
    } = props;
    const GENERAL_EVENTS = [
      'ANNOTATION_MODE_STARTED',
      'ANNOTATION_MODE_ENDED',
      'ANNOTATION_CLICKED',
      'ANNOTATION_SELECTED',
    ];
    let standardConfig = { showCommentsPanel: false, downloadWithAnnotations: true };
    const LISTEN_EVENTS = [
      ...GENERAL_EVENTS,
      'ANNOTATION_ADDED',
      'ANNOTATION_UPDATED',
      'ANNOTATION_REMOVED',
      'ANNOTATION_COUNT',
    ];
    // Pass the events to receive.
    // If no event is passed in listenOn, then all the annotation events will be received.
    const eventOptions = { listenOn: LISTEN_EVENTS };
    adobeViewer.getAnnotationManager().then((annotationManager) => {
      annotationManager.setConfig(standardConfig);
      if (initRemove) {
        annotationManager.removeAnnotationsFromPDF();
      }
      if (Boolean(selectId) && onSelect) {
        annotationManager.getAnnotations().then((annotations) => {
          if (comments && comments.length > 0) {
            const selectedAnnotation = comments
              .map((x) => x.text)
              .find((annotation) => annotation.modified === selectId);
            const annotation = annotations.find((x) => x.modified === selectedAnnotation.modified);
            if (annotation) {
              annotationManager
                .selectAnnotation(annotation.id)
                .then(() => onSelect({ message: 'Success', status: 200 }))
                .catch((error) => onSelect(error));
            }
          }
        });
      }
      annotationManager.registerEventListener(function (event) {
        if (GENERAL_EVENTS.includes(event.type)) {
          annotationManager.setConfig({ ...standardConfig, showCommentsPanel: true });
        } else {
          annotationManager.setConfig({ ...standardConfig, showCommentsPanel: false });
        }
        if (event.type === 'ANNOTATION_ADDED' && onAdd) {
          if (
            event.data.motivation === 'commenting' &&
            event.data.target &&
            event.data.target.selector &&
            ['note', 'freetext'].includes(event.data.target.selector.subtype)
          ) {
            onAdd(event.data);
          }
          if (event.data.motivation === 'replying') {
            onReply(event.data);
          }
        }
        if (event.type === 'ANNOTATION_UPDATED' && onUpdate) {
          onUpdate(event.data);
        }
        if (event.type === 'ANNOTATION_REMOVED' && onRemove) {
          onRemove(event.data);
        }
        if (event.type === 'ANNOTATION_COUNT' && onAnnotationCount) {
          if (event?.data?.mentions === undefined) {
            onAnnotationCount(0);
          } else {
            onAnnotationCount(event.data);
          }
        }
      }, eventOptions);
    });
  };
}

export default ViewSDKClient;
