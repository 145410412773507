import React, { useState, useEffect, useMemo } from 'react';
import { Grid, TextField, Menu, MenuItem, ListItemText, Fade, Skeleton, Collapse } from '@mui/material';
import { Typography, ListItemIcon, IconButton, Tooltip, Checkbox, ListItemSecondaryAction } from '@mui/material';
import { SearchRounded, NotInterested, ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { CloseRounded, FiberManualRecord, ExpandMoreRounded } from '@mui/icons-material';
import { isArray, isEmpty, isFunction, orderBy, uniqBy, range } from 'lodash';
import TooltipTypography from './TooltipTypography';

export default function SearchMenu({
  anchorEl,
  open,
  onClose,
  onResult,
  items,
  showClear,
  idField = 'id',
  textField = 'text',
  minWidth = 150,
  withColor,
  withCheckboxes,
  checkedItems,
  onCheck,
  onUncheck,
  onClear,
  groupId,
  groupTitle,
  iconField,
  additionalItem,
  renderTitle,
  loading,
  map = (x) => x.id,
}) {
  const [sorted, setSorted] = useState([]);
  const [pattern, setPattern] = useState('');
  const [direction, setDirection] = useState(null);
  const [groupedView, setGroupedView] = useState(false);
  const [grouped, setGrouped] = useState([]);

  const noItems = !isArray(items) || isEmpty(items);
  const useCheckboxes = withCheckboxes && isArray(checkedItems) && isFunction(onCheck) && isFunction(onUncheck);

  useEffect(() => {
    if (!isArray(items)) {
      setSorted([]);
      return;
    }
    const normalPattern = String(pattern).toLowerCase();
    const filtered = items.filter((x) => String(x[textField]).toLowerCase().includes(normalPattern));
    if (!direction) {
      setSorted(filtered);
      return;
    }
    const filteredAndSorted = orderBy(filtered, textField, direction);
    setSorted(filteredAndSorted);
  }, [pattern, items, textField, direction]);

  useEffect(() => {
    setPattern('');
    setDirection(null);
  }, [open]);

  useEffect(() => {
    if (isArray(items) && !isEmpty(items) && groupId && items.every((x) => x[groupId])) {
      const normalPattern = String(pattern).toLowerCase();
      let filtered = items.filter((x) => String(x[textField]).toLowerCase().includes(normalPattern));
      if (direction) {
        filtered = orderBy(filtered, textField, direction);
      }
      const groups = uniqBy(filtered, groupId);
      const groupedItems = groups.map((group) => ({
        id: group[groupId],
        title: group[groupTitle],
        items: filtered.filter((x) => x[groupId] === group[groupId] && !x.parentId),
        allItems: filtered.filter((x) => x[groupId] === group[groupId]),
      }));
      setGrouped(groupedItems);
      setGroupedView(!isEmpty(groupedItems));
    } else {
      setGroupedView(false);
    }
  }, [groupId, items, groupTitle, pattern, textField, direction]);

  const MAX_NO_SEARCH_ITEMS = 5;
  const ITEM_HEIGHT = 36;
  const MAX_ITEMS_IN_HEIGHT = 20;
  const MAX_HEIGHT = ITEM_HEIGHT * MAX_ITEMS_IN_HEIGHT;

  const nothingFound = !isEmpty(items) && isEmpty(sorted);
  const searchNeeded = !noItems && items.length > MAX_NO_SEARCH_ITEMS;

  const notNestedItems = useMemo(() => {
    const result = sorted.filter((x) => !x.parentId);
    return result;
  }, [sorted]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      className="scroll-bar"
      slotProps={{ paper: { style: { minWidth } } }}
    >
      {loading ? (
        <Grid container sx={{ p: 2 }}>
          {range(5).map((i) => (
            <Grid container sx={{ my: 1 }} key={i}>
              <Skeleton width={'100%'} height={32} />
            </Grid>
          ))}
        </Grid>
      ) : noItems ? (
        <Grid container sx={{ p: 2 }}>
          <Grid container>
            <Typography>No items available</Typography>
          </Grid>

          {additionalItem && (
            <Grid container sx={{ mt: 1 }}>
              {additionalItem}
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container direction={'column'}>
          {searchNeeded && (
            <Grid container item wrap="nowrap" alignContent={'center'} sx={{ p: 1 }}>
              <TextField
                variant="standard"
                placeholder="Search"
                sx={{ flexGrow: 1 }}
                value={pattern}
                onChange={(e) => setPattern(e.target.value)}
                InputProps={{
                  startAdornment: <SearchRounded sx={{ mr: 1 }} />,
                  endAdornment: pattern && (
                    <IconButton size="small" onClick={() => setPattern('')}>
                      <CloseRounded fontSize="small" />
                    </IconButton>
                  ),
                }}
                focused
                autoFocus
                id="search-menu"
                inputRef={(input) => input && input.focus()}
              />
              <Tooltip title={direction === 'desc' ? 'Unsort' : 'Sort'}>
                <IconButton
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => {
                    if (!direction) {
                      setDirection('asc');
                    } else if (direction === 'asc') {
                      setDirection('desc');
                    } else if (direction === 'desc') {
                      setDirection(null);
                    }
                  }}
                >
                  {!direction && <ArrowUpwardRounded fontSize="small" />}
                  {direction === 'asc' && <ArrowDownwardRounded fontSize="small" />}
                  {direction === 'desc' && <NotInterested fontSize="small" />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid container item direction={'column'} wrap="nowrap">
            {nothingFound ? (
              <Grid container sx={{ p: 2, minHeight: '10vh' }} justifyContent={'center'} alignItems={'center'}>
                <Typography>No items found</Typography>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  item
                  direction={'column'}
                  style={{ overflow: 'auto', maxHeight: MAX_HEIGHT }}
                  wrap="nowrap"
                >
                  {groupedView
                    ? grouped.map((group) => (
                        <Grid container key={group.id}>
                          <Grid container item sx={{ px: 1, py: 0.5 }}>
                            <TooltipTypography sx={{ fontWeight: 500, fontSize: 18 }}>{group.title}</TooltipTypography>
                          </Grid>
                          <Grid container direction={'column'} wrap="nowrap">
                            {group.items.map((item, i) => (
                              <Item
                                key={i}
                                item={item}
                                allItems={group.allItems}
                                idField={idField}
                                useCheckboxes={useCheckboxes}
                                checkedItems={checkedItems}
                                onUncheck={onUncheck}
                                onCheck={onCheck}
                                withColor={withColor}
                                renderTitle={renderTitle}
                                iconField={iconField}
                                textField={textField}
                                onResult={onResult}
                                onClose={onClose}
                                map={map}
                              />
                            ))}
                          </Grid>
                        </Grid>
                      ))
                    : notNestedItems.map((item, i) => (
                        <Item
                          key={i}
                          item={item}
                          allItems={sorted}
                          idField={idField}
                          useCheckboxes={useCheckboxes}
                          checkedItems={checkedItems}
                          onUncheck={onUncheck}
                          onCheck={onCheck}
                          withColor={withColor}
                          renderTitle={renderTitle}
                          iconField={iconField}
                          textField={textField}
                          onResult={onResult}
                          onClose={onClose}
                          map={map}
                        />
                      ))}
                </Grid>
                {showClear && (
                  <Grid container item direction={'column'}>
                    <MenuItem
                      onClick={() => {
                        if (isFunction(onClear)) {
                          onClear();
                        }
                        onClose();
                        if (useCheckboxes) return;
                        onResult(null);
                      }}
                    >
                      <ListItemIcon>
                        <NotInterested color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Clear" />
                    </MenuItem>
                  </Grid>
                )}
                {additionalItem}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Menu>
  );
}

function Item({
  item,
  allItems,
  idField,
  useCheckboxes,
  checkedItems,
  onUncheck,
  onCheck,
  withColor,
  renderTitle,
  iconField,
  textField,
  onResult,
  onClose,
  map,
}) {
  const [expanded, setExpanded] = useState(false);

  const children = useMemo(() => {
    if (!isArray(allItems) || !item[idField]) return [];

    const result = allItems.filter((x) => x.parentId === item[idField]);

    return result;
  }, [allItems, item, idField]);

  const hasChildren = !isEmpty(children);

  return (
    <Grid container item>
      <Grid
        container
        item
        component={MenuItem}
        onClick={(e) => {
          e.stopPropagation();
          if (useCheckboxes) {
            checkedItems.includes(item[idField]) ? onUncheck(item[idField]) : onCheck(item[idField]);
            return;
          }
          onResult(map(item));
          onClose();
        }}
      >
        {useCheckboxes && (
          <Checkbox size="small" checked={checkedItems.includes(item[idField])} sx={{ mr: 1, p: 0.5 }} />
        )}
        {withColor && <FiberManualRecord size="small" sx={{ color: item.color, mr: 1 }} />}
        <TooltipTypography>{isFunction(renderTitle) ? renderTitle(item) : item[textField]}</TooltipTypography>
        <Grid sx={{ ml: 'auto' }} />
        {iconField && item[iconField] && <Grid item>{item[iconField]}</Grid>}
        {hasChildren && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
            sx={{
              transform: 'rotate(0deg)',
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              transform: expanded ? 'rotate(180deg)' : null,
              height: 24,
              width: 24,
            }}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreRounded fontSize="small" />
          </IconButton>
        )}
      </Grid>
      {hasChildren && (
        <Grid container item sx={{ pl: 2 }} component={Collapse} in={expanded}>
          {children.map((child, i) => (
            <Item
              key={i}
              item={child}
              allItems={allItems}
              idField={idField}
              useCheckboxes={useCheckboxes}
              checkedItems={checkedItems}
              onUncheck={onUncheck}
              onCheck={onCheck}
              withColor={withColor}
              renderTitle={renderTitle}
              iconField={iconField}
              textField={textField}
              onResult={onResult}
              onClose={onClose}
              map={map}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
}
