import React from 'react';

import { Grid } from '@mui/material';
import Loader from '../../../Global/Loader';

export default function OAuthRedirect() {
  return (
    <Grid container>
      <Loader />
    </Grid>
  );
}
