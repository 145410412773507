import React, { useState, useEffect } from 'react';
import { TextField, DialogTitle, DialogContent, Typography, Grid } from '@mui/material';
import { AlertTitle, Alert } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';
import StateManager from '../StateManager';
import axios from 'axios';

export default ({ open, handleClose }) => {
  const [saving, setSaving] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }, [open]);

  function done() {
    setSaving(true);
    axios
      .post('user/updatePassword', { oldPassword, newPassword })
      .then((res) => {
        StateManager.setSuccessAlert('Password has been changed');
        setSaving(false);
        handleClose();
      })
      .catch((err) => {
        StateManager.setErrorAlert(err?.response?.data?.message || 'Something went wrong');
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <TextField
          required
          autoFocus
          fullWidth
          label="Old password"
          type="password"
          variant="outlined"
          value={oldPassword}
          onChange={(event) => setOldPassword(event.target.value)}
          style={{ marginBottom: 16 }}
          autoComplete="off"
          inputProps={{
            autocomplete: 'off',
          }}
        />
        <Grid container item>
          <Alert severity="info" style={{ width: '100%', marginBottom: 16, borderRadius: 8 }}>
            <AlertTitle>To ensure the security of your account your password must:</AlertTitle>
            <Typography style={{ fontSize: 14 }}>Be at least 8 characters long</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 lowercase letter</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 uppercase letter</Typography>
            <Typography style={{ fontSize: 14 }}>Contain at least 1 special character</Typography>
          </Alert>
        </Grid>
        <TextField
          required
          fullWidth
          label="New password"
          type="password"
          variant="outlined"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          style={{ marginBottom: 16 }}
          inputProps={{
            autocomplete: 'new-password',
          }}
        />
        <TextField
          required
          fullWidth
          error={newPassword && newPassword !== confirmPassword}
          helperText={newPassword && newPassword !== confirmPassword ? 'Passwords do not match' : ''}
          label="Confirm new password"
          type="password"
          variant="outlined"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          inputProps={{
            autocomplete: 'new-password',
          }}
        />
      </DialogContent>
      <StandardDialogActions
        saving={saving}
        onClose={handleClose}
        closeButtonText="CANCEL"
        hideDone={!oldPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword}
        onDone={done}
      />
    </RoundedDialog>
  );
};
