import {
  FOLDERTEMPLATESLIST_ATTEMPT,
  FOLDERTEMPLATESACTION_ATTEMPT,
  FOLDERTEMPLATES_ERROR,
  GETFOLDERTEMPLATES,
  GETFOLDERTEMPLATE,
  UPDATEFOLDERTEMPLATE,
  DELETEFOLDERTEMPLATE,
  ADDFOLDERTEMPLATE,
  USEFOLDERTEMPLATE,
} from '../constants';

const initialState = {
  listLoading: false,
  actionLoading: false,
  error: null,
  folderTemplates: [],
  folderTemplate: null,
  redirectLink: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FOLDERTEMPLATESLIST_ATTEMPT:
      return {
        ...state,
        listLoading: true,
        error: null,
      };
    case FOLDERTEMPLATESACTION_ATTEMPT:
      return {
        ...state,
        actionLoading: true,
        error: null,
      };
    case FOLDERTEMPLATES_ERROR:
      return {
        ...state,
        actionLoading: false,
        listLoading: false,
        error: action.payload,
      };
    case GETFOLDERTEMPLATES:
      return {
        ...state,
        listLoading: false,
        folderTemplates: action.payload,
      };
    case GETFOLDERTEMPLATE:
      return {
        ...state,
        listLoading: false,
        folderTemplate: action.payload,
      };
    case ADDFOLDERTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        folderTemplates: [...state.folderTemplates, action.payload],
      };
    case UPDATEFOLDERTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        folderTemplates: state.folderTemplates.map((template) => {
          if (template.id === action.payload.id) {
            return action.payload;
          }
          return template;
        }),
      };
    case DELETEFOLDERTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        folderTemplates: state.folderTemplates.filter((template) => template.id !== action.payload.id),
      };
    case USEFOLDERTEMPLATE:
      return {
        ...state,
        actionLoading: false,
        redirectLink: action.payload,
      };
    default:
      return state;
  }
}
