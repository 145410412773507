import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Typography, IconButton, TextField, MenuItem, InputBase, Menu, Collapse, Tooltip } from '@mui/material';
import { ListItemIcon, ListItemText, DialogTitle, DialogContent, Button, FormControl } from '@mui/material';
import { DialogActions, Grid, useMediaQuery, CardActionArea, CircularProgress, Paper } from '@mui/material';
import { ListItemButton, useTheme, RadioGroup, FormLabel, FormControlLabel, Radio } from '@mui/material';
import { AddCircleOutline, AddCircle, Close, SearchRounded, DashboardRounded } from '@mui/icons-material';
import { FilterListRounded, AccountCircleOutlined, CheckCircle, HomeRounded } from '@mui/icons-material';
import { ShoppingBasketRounded, DuoRounded, VisibilityRounded, VisibilityOffRounded } from '@mui/icons-material';
import { VpnKeyRounded, GroupRounded, GroupAddRounded, ArrowDropDownRounded } from '@mui/icons-material';
import { FiberManualRecord, CheckBoxOutlined, DeleteOutlineRounded, Done, Edit } from '@mui/icons-material';
import { SettingsRounded } from '@mui/icons-material';
import { FormIcon, DocIcon, ProcessIcon, TaskIcon, KPIHubIcon } from '../Global/Icons';
import { ProjectIcon, AuditIcon, DataIcon, UserIcon } from '../Global/Icons';
import { BackButton, GeneralButton, HtmlTooltip, RoundedDialog, ClearFilter, ColorPicker } from '../Global/Components';
import { StandardDialogActions } from '../Global/Components';
import { useHistory, Link } from 'react-router-dom';
import DrawerLayout from '../Global/DrawerLayout';
import { blue, green, grey, red, amber, orange, deepOrange } from '@mui/material/colors';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import StateManager from '../Global/StateManager';
import { calendlyUrl } from '../../constants';
import Dashboard from '../Hubs/tasks/workspaces/components/Dashboard';
import Folder from '../Hubs/tasks/workspaces/components/Folder';
import { styled, alpha } from '@mui/material/styles';
import { isArray } from 'lodash';
import Chart from '../Global/Charts/Chart';

const blueShadow =
  '0px 2px 4px -1px rgba(60,137,254,0.2),0px 4px 5px 0px rgba(60,137,254,0.14),0px 1px 10px 0px rgba(60,137,254,0.12)';

const chartIntervals = [0, 3, 6, 12, 18, 24];

const ItemPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1, 2),
  boxShadow: blueShadow,
  width: '100%',
}));

const ChartContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const ShortcutItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1.5),
  border: `2px solid ${grey[300]}`,
  width: '100%',
}));

const ShortcutButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1.5),
  border: `2px solid ${grey[300]}`,
  width: 250,
  margin: theme.spacing(2),
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '1.5rem',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const hubs = ['workflow', 'dochub', 'forms', 'process', 'kpihub'];

const hubTitles = {
  workflow: 'Workflow',
  dochub: 'Doc Library',
  forms: 'Form Hub',
  process: 'Process Hub',
  kpihub: 'KPI Hub',
};

const hubIcons = {
  workflow: <TaskIcon />,
  dochub: <DocIcon />,
  forms: <FormIcon />,
  process: <ProcessIcon />,
  kpihub: <KPIHubIcon />,
  project: <ProjectIcon />,
  audit: <AuditIcon />,
  datahub: <DataIcon />,
  users: <UserIcon />,
};

const guideIconSize = '4rem';

const guides = (access, tour) => [
  {
    id: 'create-profile-guide',
    title: 'Create profile',
    icon: <AccountCircleOutlined style={{ width: guideIconSize, height: guideIconSize, color: blue[700] }} />,
    shown: true,
    link: '/profile?start_tour=true',
    external: false,
    checked: tour.profile,
  },
  {
    id: 'admin-details-guide',
    title: 'Admin details',
    icon: <VpnKeyRounded style={{ width: guideIconSize, height: guideIconSize, color: amber[600] }} />,
    shown: access === 'admin',
    link: '/admin/company?start_tour=true',
    external: false,
    checked: tour.company,
  },
  {
    id: 'add-users-guide',
    title: 'Add users',
    icon: <GroupRounded style={{ width: guideIconSize, height: guideIconSize, color: green[600] }} />,
    shown: access === 'admin',
    link: '/admin/users?start_tour=true',
    external: false,
    checked: tour.users,
  },
  {
    id: 'setup-groups-guide',
    title: 'Setup groups',
    icon: <GroupAddRounded style={{ width: guideIconSize, height: guideIconSize, color: blue[900] }} />,
    shown: access === 'admin',
    link: '/admin/groups?start_tour=true',
    external: false,
    checked: tour.groups,
  },
  {
    id: 'book-tour-guide',
    title: 'Book a tour',
    icon: <DuoRounded style={{ width: guideIconSize, height: guideIconSize, color: orange[500] }} />,
    shown: true,
    link: calendlyUrl,
    external: true,
  },
  {
    id: 'add-resources-guide',
    title: 'Add resources',
    icon: <ShoppingBasketRounded style={{ width: guideIconSize, height: guideIconSize, color: deepOrange[500] }} />,
    shown: true,
    link: '/resources',
    external: false,
  },
];

export default function Home() {
  const theme = useTheme();

  const history = useHistory();
  const { user } = useSelector(({ profile }) => profile);
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const mediumDevices = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [completedTasksChartData, setCompletedTasksChartData] = useState([]);
  const [chartInterval, setChartInterval] = useState(0);
  const [taskStatusesChart, setTaskStatusesChart] = useState(null);
  const [completedChartLoading, setCompletedChartLoading] = useState(false);
  const [shortcuts, setShortcuts] = useState([]);
  const [newShortcutDialog, setNewShortcutDialog] = useState(false);
  const [editShortcuts, setEditShortcuts] = useState(false);
  const [shortcutsPattern, setShortcutsPattern] = useState('');
  const [filterMenu, setFilterMenu] = useState();
  const [filterHub, setFilterHub] = useState();
  const [hideHelp, setHideHelp] = useState(localStorage.getItem('hideHelp') === 'true');
  const [dashboard, setDashboard] = useState(null);
  const [folder, setFolder] = useState(null);
  const [availableDashboards, setAvailableDashboards] = useState([]);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [selectedDashboardId, setSelectedDashboardId] = useState(null);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [settingsDialog, setSettingsDialog] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(false);
  const [display, setDisplay] = useState('dashboard');

  const selectedWorkspace = selectedDashboardId ? availableDashboards.find((x) => x._id === selectedDashboardId) : null;

  const filteredShortcuts = shortcuts.filter(
    (s) =>
      (!shortcutsPattern || String(s.text).toLowerCase().includes(shortcutsPattern)) &&
      (!filterHub || s.hub === filterHub) &&
      s.hub !== 'formhub',
  );

  const CHART_HEIGHT = 240;
  const COMPLETED_CHART_WIDTH = largeDevices ? (mediumDevices ? 500 : 670) : 320;
  const STATUS_CHART_WIDTH = largeDevices ? (mediumDevices ? 270 : 320) : 320;

  useEffect(() => {
    axios
      .get('/user/getUserHomePage')
      .then(({ data }) => {
        setShortcuts(data.shortcuts);
        setTaskStatusesChart(data.taskChart);
        setDashboard(data.dashboard);
        setSelectedDashboardId(data.homeDashboardId || null);
        setFolder(data.rootFolder || null);
        setSelectedFolderId(data.homeFolderId || null);
        setDisplay(data.homeFolderId ? 'folder' : 'dashboard');
        setAvailableDashboards(data.availableDashboards);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    loadCompletedChart(0);
  }, []);

  function loadCompletedChart(chartInterval) {
    setCompletedChartLoading(true);
    axios
      .get('/tasks/kpi/getCompletedTasksChart', { params: { chartInterval } })
      .then((res) => {
        setCompletedChartLoading(false);
        setCompletedTasksChartData(res.data);
      })
      .catch((err) => {
        setCompletedChartLoading(false);
        console.error(err);
      });
  }

  function deleteShortcut(id) {
    setShortcuts(shortcuts.filter((x) => x._id !== id));
    axios.post('/user/deleteUserShortcut', { id }).catch(console.error);
  }

  function saveHideHelp(value) {
    localStorage.setItem('hideHelp', value);
    setHideHelp(value);
  }

  function selectGiude(link, external = false) {
    if (external) {
      window.location.href = link;
    } else {
      history.push(link);
    }
  }

  function saveUserHomePage(homeDashboardId, homeFolderId) {
    if (homeDashboardId) {
      setLoadingDashboard(true);
    } else {
      setDashboard(null);
    }
    axios
      .post('/user/saveUserHomePage', { homeDashboardId, homeFolderId })
      .then((res) => {
        setDashboard(res.data.dashboard || null);
        setFolder(res.data.rootFolder);
        setSelectedDashboardId(res.data.homeDashboardId || null);
        setSelectedFolderId(res.data.homeFolderId || null);
        setLoadingDashboard(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingDashboard(false);
      });
  }

  useEffect(() => {
    if (chartInterval == null) return;
    loadCompletedChart(chartInterval);
  }, [chartInterval]);

  const dashboardMenu = (
    <>
      <Button
        onClick={(e) => setMenuAnchor(e.currentTarget)}
        sx={{ color: grey[600], textTransform: 'none', mr: 2 }}
        startIcon={selectedWorkspace ? <DashboardRounded /> : <ArrowDropDownRounded />}
      >
        {selectedWorkspace ? selectedWorkspace.title : 'No workspace'}
      </Button>

      {selectedWorkspace && (
        <RadioGroup
          value={display}
          onChange={(e) => {
            setDisplay(e.target.value);
            saveUserHomePage(
              selectedWorkspace._id,
              e.target.value === 'folder' ? selectedWorkspace.rootFolderId : null,
            );
          }}
          row
        >
          <FormControlLabel value={'dashboard'} label={'Dashboard'} control={<Radio />} />
          <FormControlLabel value={'folder'} label={'Folder'} control={<Radio />} />
        </RadioGroup>
      )}

      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        {availableDashboards.map((item) => (
          <MenuItem
            key={item._id}
            onClick={() => {
              setMenuAnchor(null);
              setSelectedDashboardId(item._id);
              saveUserHomePage(item._id, display === 'folder' ? item.rootFolderId : null);
            }}
          >
            <ListItemIcon>
              <DashboardRounded style={{ color: grey[500] }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ fontWeight: selectedDashboardId === item._id ? 600 : 400 }}>{item.title}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setMenuAnchor(null);
            setSelectedDashboardId(null);
            saveUserHomePage(null, null);
          }}
        >
          <ListItemIcon>
            <HomeRounded style={{ color: grey[500] }} />
          </ListItemIcon>
          <ListItemText>
            <Typography style={{ fontWeight: selectedDashboardId === null ? 600 : 400 }}>Standard home page</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <DrawerLayout title="Home">
      {loading ? (
        <Grid container style={{ height: '80vh' }}>
          <CircularProgress style={{ margin: 'auto' }} />
        </Grid>
      ) : error ? (
        <Grid container style={{ height: '80vh' }} justifyContent="center" alignItems="center">
          <Typography color={'textSecondary'} variant="h6">
            Failed to load the home page
          </Typography>
        </Grid>
      ) : (
        <Grid container alignItems="flex-start" className="scroll-bar">
          <Grid container item sx={{ px: 1 }}>
            {loadingDashboard ? (
              <Grid container item justifyContent="center" alignItems="center">
                <CircularProgress color="primary" size={30} />
              </Grid>
            ) : dashboard ? (
              <Grid container item justifyContent="center" alignItems="center">
                <Grid container item>
                  <Typography variant="h4">{dashboard.title}</Typography>
                  <div style={{ marginLeft: 'auto' }} />
                  {dashboardMenu}
                </Grid>
                <Grid container item sx={{ mt: folder ? 2 : 0 }}>
                  {folder ? (
                    <Folder initial={folder} readOnly />
                  ) : (
                    <Dashboard initialItems={dashboard.dashboardItems} initialLayouts={dashboard.dashboardLayouts} />
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container item justifyContent="center">
                <ChartContainer container item>
                  <Grid container item style={{ padding: theme.spacing(0.6, 0) }} alignItems="center">
                    <Typography style={{ color: blue[700] }}>Get started here</Typography>

                    <Button
                      onClick={() => saveHideHelp(!hideHelp)}
                      style={{ marginLeft: 'auto', color: grey[600], textTransform: 'none' }}
                      endIcon={hideHelp ? <VisibilityRounded /> : <VisibilityOffRounded />}
                    >
                      {hideHelp ? 'Show help' : 'Hide help'}
                    </Button>
                    {dashboardMenu}
                  </Grid>
                  <Collapse style={{ width: '100%' }} in={!hideHelp}>
                    <Grid container item>
                      <ItemPaper>
                        <Grid container>
                          {user &&
                            guides(user.access, user.tourStatus)
                              .filter((x) => x.shown)
                              .map((guide) => (
                                <Grid lg={2} md={2} sm={6} item container key={guide.id} sx={{ p: 1 }}>
                                  <Grid container component={Paper} sx={{ borderRadius: 1.5 }}>
                                    <CardActionArea
                                      onClick={() => selectGiude(guide.link, guide.external)}
                                      sx={{
                                        borderRadius: 1.5,
                                        py: 2,
                                        width: '100%',
                                        height: '100%',
                                      }}
                                    >
                                      <Grid container>
                                        <Grid container item justifyContent="center">
                                          {guide.icon}
                                        </Grid>
                                        <Grid
                                          container
                                          item
                                          style={{ paddingTop: theme.spacing(0.5), textAlign: 'center' }}
                                          justifyContent="center"
                                        >
                                          <Typography>{guide.title}</Typography>
                                        </Grid>
                                      </Grid>
                                      {guide.checked && (
                                        <div style={{ position: 'absolute', right: '3%', bottom: '10%' }}>
                                          <CheckCircle style={{ fontSize: 30, color: green[500] }} />
                                        </div>
                                      )}
                                    </CardActionArea>
                                  </Grid>
                                </Grid>
                              ))}
                        </Grid>
                      </ItemPaper>
                    </Grid>
                  </Collapse>
                </ChartContainer>

                <ChartContainer container item lg={8} md={8} sm={12} style={{ minWidth: COMPLETED_CHART_WIDTH }}>
                  <ItemPaper style={{ minWidth: COMPLETED_CHART_WIDTH }}>
                    <Grid container item alignItems="center">
                      <CheckBoxOutlined style={{ color: green[500], marginRight: theme.spacing(2) }} />
                      <Typography variant="h6">Tasks you’ve completed</Typography>
                      <TextField
                        value={chartInterval}
                        select
                        onChange={(e) => setChartInterval(e.target.value)}
                        style={{ marginLeft: 'auto' }}
                        variant="standard"
                      >
                        {chartIntervals.map((x) => (
                          <MenuItem value={x} key={x}>
                            {x === 0 ? 'This month' : `Last ${x} months`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid container item alignItems="center" justifyContent="center" style={{ height: CHART_HEIGHT }}>
                      {!completedChartLoading && <Chart chartType="line" data={completedTasksChartData} isPreview />}
                      {!completedChartLoading && completedTasksChartData?.length === 1 && (
                        <Grid
                          container
                          item
                          alignItems="center"
                          justifyContent="center"
                          style={{ width: COMPLETED_CHART_WIDTH, height: CHART_HEIGHT, textAlign: 'center' }}
                        >
                          <Typography color="textSecondary">
                            Start adding some tasks to see your progress here
                          </Typography>
                        </Grid>
                      )}
                      {completedChartLoading && (
                        <Grid
                          container
                          item
                          alignItems="center"
                          justifyContent="center"
                          style={{ width: COMPLETED_CHART_WIDTH, height: CHART_HEIGHT }}
                        >
                          <CircularProgress size={25} />
                        </Grid>
                      )}
                    </Grid>
                  </ItemPaper>
                </ChartContainer>

                <ChartContainer
                  container
                  item
                  justifyContent="center"
                  lg={4}
                  md={4}
                  sm={12}
                  style={{ minWidth: STATUS_CHART_WIDTH }}
                >
                  <ItemPaper style={{ minWidth: STATUS_CHART_WIDTH }}>
                    <Grid container item alignItems="center">
                      <Typography variant="h6">My current task statuses</Typography>
                    </Grid>
                    {taskStatusesChart?.length > 1 && (
                      <Grid container item justifyContent="center" style={{ height: CHART_HEIGHT }}>
                        <Chart chartType="pie" data={taskStatusesChart} isPreview />
                      </Grid>
                    )}
                    {(!taskStatusesChart || taskStatusesChart?.length === 1) && (
                      <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        style={{ width: STATUS_CHART_WIDTH, height: CHART_HEIGHT, textAlign: 'center' }}
                      >
                        <Typography color="textSecondary">Start adding some tasks to see your progress here</Typography>
                      </Grid>
                    )}
                  </ItemPaper>
                </ChartContainer>

                <ChartContainer container item>
                  <Grid container item alignItems="center" style={{ paddingBottom: theme.spacing(1) }}>
                    <Typography variant="h5">Your shortcuts</Typography>

                    <Search>
                      <SearchIconWrapper>
                        <SearchRounded />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        value={shortcutsPattern}
                        onChange={(e) => setShortcutsPattern(String(e.target.value).toLowerCase())}
                      />
                    </Search>

                    <GeneralButton
                      style={{
                        marginLeft: 'auto',
                        marginRight: theme.spacing(1),
                        border: filterHub ? `2px solid ${blue[900]}` : '',
                        fontWeight: filterHub ? 700 : '',
                      }}
                      endIcon={filterHub ? hubIcons[filterHub] : <FilterListRounded style={{ color: blue[900] }} />}
                      onClick={(e) => setFilterMenu(e.currentTarget)}
                    >
                      {filterHub ? hubTitles[filterHub] : 'Filter by hub'}
                    </GeneralButton>
                    <Menu anchorEl={filterMenu} open={Boolean(filterMenu)} onClose={() => setFilterMenu(null)}>
                      {hubs.map((hub) => (
                        <MenuItem
                          key={hub}
                          onClick={() => {
                            setFilterHub(hub);
                            setFilterMenu(null);
                          }}
                        >
                          <ListItemIcon>{hubIcons[hub]}</ListItemIcon>
                          <ListItemText primary={hubTitles[hub]} />
                        </MenuItem>
                      ))}
                      <ClearFilter
                        onClick={() => {
                          setFilterHub(null);
                          setFilterMenu(null);
                        }}
                      />
                    </Menu>

                    <HtmlTooltip
                      title={<Typography>{editShortcuts ? 'Done' : 'Edit shortcuts'}</Typography>}
                      placement="top"
                    >
                      <IconButton onClick={() => setEditShortcuts(!editShortcuts)}>
                        {editShortcuts ? <Done style={{ color: green[500] }} /> : <Edit style={{ color: grey[500] }} />}
                      </IconButton>
                    </HtmlTooltip>

                    <HtmlTooltip title={<Typography>Add shortcut</Typography>} placement="top">
                      <div>
                        <HoverButton onClick={() => setNewShortcutDialog(true)} />
                      </div>
                    </HtmlTooltip>
                    <NewShortcutDialog
                      open={newShortcutDialog}
                      onClose={() => setNewShortcutDialog(false)}
                      onResult={(shortcut) => setShortcuts([...shortcuts, shortcut])}
                    />
                  </Grid>

                  <Grid container item>
                    <ItemPaper>
                      <Grid container>
                        {!shortcuts[0] && (
                          <Typography style={{ margin: theme.spacing(2, 0) }} variant="h6" color="textSecondary">
                            You don't have any shortcuts
                          </Typography>
                        )}
                        {!filteredShortcuts[0] && shortcuts[0] && (
                          <Typography style={{ margin: theme.spacing(2, 0) }} variant="h6" color="textSecondary">
                            No shortcuts match your search
                          </Typography>
                        )}
                        {filteredShortcuts.map((item) => (
                          <Grid container item lg={3} md={4} xs={12} key={item._id} sx={{ p: 1 }}>
                            <Shortcut item={item} editing={editShortcuts} onDelete={() => deleteShortcut(item._id)} />
                          </Grid>
                        ))}
                      </Grid>
                    </ItemPaper>
                  </Grid>
                </ChartContainer>
              </Grid>
            )}
          </Grid>

          {/*
            <Grid container item lg={3} md={3} xs={12} className={classes.groupContainer}>
              <Grid container item style={{ height: 'fit-content' }}>
                <Grid container item justifyContent="center">
                  <Grid container item alignItems="center" style={{ padding: theme.spacing(1, 0) }}>
                    <DateRange style={{ color: blue[900], marginRight: theme.spacing(2) }} />
                    <Typography variant="h6">Company Notice board</Typography>
                    <HtmlTooltip
                      title={<Typography>This is where you see your latest company posts</Typography>}
                      placement="left"
                    >
                      <LiveHelp style={{ color: grey[500], marginLeft: 'auto' }} />
                    </HtmlTooltip>
                  </Grid>

                  <CardActionArea onClick={() => history.push('/noticeBoard')} className={classes.noticeBoard}>
                    <Grid container item direction="column" wrap="nowrap" style={{ height: '30vh', overflow: 'auto' }}>
                      {companyNoticeBoard?.length > 0 ? (
                        <>
                          {companyNoticeBoard.map((x, i) => (
                            <Grid container item key={i} style={{ margin: '1em 0em' }} wrap="nowrap">
                              <FiberManualRecord style={{ marginRight: '0.5em', color: pink[500] }} />
                              <Typography style={{ fontSize: 16 }} noWrap>
                                {x.title}
                              </Typography>
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <Typography variant="h6">Notice board is empty</Typography>
                      )}
                    </Grid>
                  </CardActionArea>
                </Grid>
                <Grid container item justifyContent="center" style={{ marginTop: 20 }}>
                  <Grid container item alignItems="center" style={{ padding: theme.spacing(1, 0) }}>
                    <img
                      alt="Logo"
                      src={user?.company?.logo && user?.company?.useAsLogo ? user.company.logo : QHubLogo}
                      style={{ maxHeight: 38, maxWidth: 150, verticalAlign: 'middle', marginRight: theme.spacing(2) }}
                    />
                    <Typography variant="h6">Software updates</Typography>
                    <HtmlTooltip title={<Typography>See our latest updates here</Typography>} placement="left">
                      <LiveHelp style={{ color: grey[500], marginLeft: 'auto' }} />
                    </HtmlTooltip>
                  </Grid>
                  <CardActionArea onClick={() => history.push('/q-hub-updates')} className={classes.noticeBoard}>
                    <Grid container item direction="column" wrap="nowrap" style={{ height: '30vh', overflow: 'auto' }}>
                      {qHubBoard?.length > 0 ? (
                        <>
                          {qHubBoard.map((x) => (
                            <Grid container item key={x._id} style={{ margin: '1em 0em' }} wrap="nowrap">
                              <FiberManualRecord style={{ marginRight: '0.5em', color: yellow[600] }} />
                              <Typography style={{ fontSize: 16 }} noWrap>
                                {x.title}
                              </Typography>
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <Typography variant="h6">Q-Hub notice board is empty</Typography>
                      )}
                    </Grid>
                  </CardActionArea>
                </Grid>
              </Grid>
            </Grid>
          */}
        </Grid>
      )}
    </DrawerLayout>
  );
}

function Shortcut({ item, editing, onDelete, onEdit }) {
  const [colorPicker, setColorPicker] = useState(null);
  const [color, setColor] = useState(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (!item) return;
    setColor(item.color);
  }, [item]);

  function saveColor(col) {
    setColor(col);
    axios.post('/user/saveShortcut', { shortcut: { ...item, color: col } }).catch(console.error);
  }

  if (editing) {
    return (
      <>
        <ShortcutItem>
          <ListItemIcon style={{ minWidth: 38, width: 38 }}>{hubIcons[item.hub]}</ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography noWrap color={'textPrimary'}>
                {item.text}
              </Typography>
            }
          />
          <Tooltip title="Change color" placement="top">
            <IconButton size="small" onClick={(e) => setColorPicker(e.currentTarget)}>
              <FiberManualRecord style={{ color: color || grey[500] }} />
            </IconButton>
          </Tooltip>
          <IconButton size="small" onClick={onDelete}>
            <DeleteOutlineRounded style={{ color: red[500] }} />
          </IconButton>
        </ShortcutItem>
        <ColorPicker
          anchor={colorPicker}
          open={Boolean(colorPicker)}
          column={400}
          customPicker
          onClose={(res) => {
            if (res?.color) {
              saveColor(res?.color);
            }
            setColorPicker(null);
          }}
        />
      </>
    );
  }
  return (
    <Link to={item.link} style={{ width: '100%' }}>
      <ShortcutItem style={{ background: color }}>
        <ListItemIcon style={{ minWidth: 38, width: 38 }}>{hubIcons[item.hub]}</ListItemIcon>

        <ListItemText
          disableTypography
          primary={
            <Typography
              noWrap
              style={{ color: color ? theme.palette.getContrastText(color) : undefined }}
              color={'textPrimary'}
            >
              {item.text}
            </Typography>
          }
        />
      </ShortcutItem>
    </Link>
  );
}

function HoverButton({ ...props }) {
  const [hovered, setHovered] = useState(false);
  return (
    <IconButton {...props} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {!hovered ? <AddCircleOutline style={{ color: blue[900] }} /> : <AddCircle style={{ color: blue[900] }} />}
    </IconButton>
  );
}

function NewShortcutDialog({ open, onClose, onResult }) {
  const [selectedHub, setSelectedHub] = useState();
  const [selectHub, setSelectHub] = useState(true);
  const [hubOptions, setHubOptions] = useState(false);
  const [search, setSearch] = useState(false);
  const [saving, setSaving] = useState(false);

  const [options, setOptions] = useState();
  const [items, setItems] = useState([]);
  const allForms = useRef([]);
  const allDocs = useRef([]);
  const allAreas = useRef([]);
  const allProcesses = useRef([]);

  function getForms() {
    axios
      .get('/forms/general/getFormsForSearch')
      .then((res) => {
        allForms.current = res.data;
      })
      .catch(console.error);
  }

  function getDocs() {
    axios
      .post('/dochub/documents/getCompanyDocuments', { category: 'normal' })
      .then((res) => {
        let data = res.data.Documents.docs;
        data.forEach((doc) => {
          if (doc.area.personal) {
            if (doc.isFolder) {
              doc.link = `/DocLibrary/myFiles/${doc.folder._id}`;
            } else {
              doc.link = '/DocLibrary/myFiles/';
            }
          } else {
            if (doc.isFolder) {
              doc.link = `/DocLibrary/areas/${doc.area._id}/${doc.folder._id}`;
            } else {
              doc.link = `/DocLibrary/areas/${doc.area._id}`;
            }
          }
        });
        allDocs.current = data.map((x) => ({ id: x._id, title: x.title, link: x.link }));
      })
      .catch(console.error);
  }

  function getAreas() {
    axios
      .post('/dochub/areas/getAreas')
      .then((res) => {
        allAreas.current = res.data.areas.map((x) => ({ id: x._id, title: x.name }));
      })
      .catch(console.error);
  }

  function getProcesses() {
    axios
      .get('/process/search/getSearchProcesses')
      .then((res) => {
        allProcesses.current = res.data;
      })
      .catch(console.error);
  }

  const loading = open && allForms.current.length === 0 && allDocs.current.length === 0;

  useEffect(() => {
    if (!loading) return;
    getForms();
    getDocs();
    getAreas();
    getProcesses();
  }, [loading]);

  const optionMap = {
    workflow: [
      { text: 'Go to the hub', link: '/tasks', label: 'Workflow' },
      {
        link: '/tasks/newTask',
        label: 'Create a task',
      },
      {
        link: '/tasks/newHubTask',
        label: 'Create a hub task',
      },
    ],
    forms: [
      {
        text: 'Go to the hub',
        link: '/forms/home',
        label: 'Form Hub',
      },
      {
        link: '/forms/form-builder',
        label: 'Create a form',
      },
      {
        text: 'Go to a form',
        link: '/forms',
        label: '',
        object: 'form',
      },
      {
        text: 'Add entry',
        link: '',
        label: '',
        object: 'form',
        action: 'entry',
      },
    ],
    dochub: [
      {
        text: 'Go to the hub',
        link: '/DocLibrary/areas',
        label: 'Doc Library',
      },
      {
        link: '/editor?new=true',
        label: 'Create a Qhub doc',
      },
      {
        text: 'Go to a file',
        link: '/DocLibrary',
        label: '',
        object: 'file',
      },
      {
        text: 'Go to an area',
        link: '/DocLibrary',
        label: '',
        object: 'area',
      },
    ],
    process: [
      {
        text: 'Go to the hub',
        link: '/processes/home',
        label: 'Process Hub',
      },
      {
        link: '/processes/process',
        label: 'Create a process',
      },
      {
        text: 'Go to a process',
        link: '',
        label: '',
      },
      {
        text: 'Start process',
        link: '',
        label: '',
        action: 'start',
      },
    ],
    kpihub: [{ text: 'Go to the hub', link: '/kpiHub/home', label: 'KPI Hub' }],
  };

  function save(params) {
    var link = params.options.link;

    if (params.selectedHub === 'dochub' && params.options.object === 'area') {
      link = `/DocLibrary/areas/${params.options.areaId}`;
    }

    if (params.selectedHub === 'dochub' && params.options.object === 'file') {
      link = params.options.link;
    }

    setSaving(true);

    const body = {
      hub: params.selectedHub,
      link,
      text: params.options.label,
    };
    axios
      .post('/user/createUserShortcut', body)
      .then((res) => {
        StateManager.setSuccessAlert('Shortcut added');
        setSaving(false);
        onResult(res.data);
        onClose();
      })
      .catch((err) => {
        StateManager.setErrorAlert('Failed to add the shortcut');
        console.error(err);
        setSaving(false);
      });
  }

  function goToHubOptions(hub) {
    setSelectedHub(hub);
    setSelectHub(false);
    setHubOptions(true);
  }

  function goToSearch(hub, option) {
    if (option.label) {
      save({ selectedHub: hub, options: option });
    } else {
      if (hub === 'forms') {
        setItems(allForms.current);
      } else if (hub === 'dochub') {
        if (option.object === 'area') {
          setItems(allAreas.current);
        } else {
          setItems(allDocs.current);
        }
      } else if (hub === 'process') {
        setItems(allProcesses.current);
      }
      setOptions(option);
      setHubOptions(false);
      setSearch(true);
    }
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>
        {selectHub && 'Select hub'}
        {hubOptions && 'Select action'}
        {search && 'Search'}
      </DialogTitle>
      <DialogContent>
        {selectHub && (
          <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '50vh' }}>
            {hubs.map((hub) => (
              <ShortcutButton key={hub} onClick={() => goToHubOptions(hub)}>
                <ListItemIcon>{hubIcons[hub]}</ListItemIcon>
                <ListItemText primary={<Typography noWrap>{hubTitles[hub]}</Typography>} />
              </ShortcutButton>
            ))}
          </Grid>
        )}

        {hubOptions && (
          <Grid container style={{ height: '50vh' }} direction="column">
            <Grid container item>
              <BackButton
                onClick={() => {
                  setSelectHub(true);
                  setHubOptions(false);
                }}
              />
            </Grid>
            <Grid container item alignItems="flex-end">
              {optionMap[selectedHub].map((option, i) => (
                <Grid container item justifyContent="center" key={i}>
                  <ShortcutButton onClick={() => goToSearch(selectedHub, option)}>
                    <ListItemIcon>{hubIcons[selectedHub]}</ListItemIcon>
                    <ListItemText primary={<Typography noWrap>{option.text || option.label}</Typography>} />
                  </ShortcutButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {search && (
          <Grid style={{ height: '50vh' }}>
            <Grid container item>
              <BackButton
                onClick={() => {
                  setSearch(false);
                  setHubOptions(true);
                }}
              />
            </Grid>
            <Grid container item>
              <Grid container item justifyContent="center">
                <Typography variant="h6" gutterBottom>
                  {options.text}
                </Typography>
              </Grid>
              <Grid container item justifyContent="center">
                <Autocomplete
                  options={items}
                  style={{ width: '60%', marginTop: '1rem' }}
                  getOptionLabel={(option) => option.title}
                  openOnFocus
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid container alignItems="center">
                        {hubIcons[selectedHub]}
                        <Typography style={{ marginLeft: '1rem' }} noWrap>
                          {option.title}
                        </Typography>
                      </Grid>
                    </li>
                  )}
                  renderInput={(params) => <TextField variant="outlined" {...params} label="Search here" />}
                  onChange={(e, value) => {
                    options.label = value.title;
                    if (selectedHub === 'forms') {
                      if (options.action === 'entry') {
                        options.label = `Entry: ${value.title}`;
                        options.link = `/forms/${value.id}/new-entry`;
                      } else {
                        options.link = `/forms/${value.id}`;
                      }
                    } else if (selectedHub === 'dochub') {
                      if (options.object === 'area') options.areaId = value.id;
                      else options.link = value.link;
                    } else if (selectedHub === 'process') {
                      if (options.action === 'start') {
                        options.label = `Start: ${value.title}`;
                        options.link = `/processes/start-process/${value.id}`;
                      } else {
                        options.link = `/processes/${value.id}`;
                      }
                    }
                    save({ selectedHub, options });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {!saving && (
          <Button style={{ color: red[500] }} onClick={onClose} startIcon={<Close />}>
            close
          </Button>
        )}
        {saving && <CircularProgress color="primary" size={20} />}
      </DialogActions>
    </RoundedDialog>
  );
}

/*<Grid container item>
    <Paper className={classes.itemPaper}>
      {editShortcuts ? (
        <Grid container>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container>
              {splitShortcuts(shortcuts).map((row, rowIndex) => (
                <Grid container item key={rowIndex}>
                  <Droppable droppableId={String(rowIndex)} direction="horizontal">
                    {(provided) => (
                      <Grid container wrap="nowrap" {...provided.droppableProps} ref={provided.innerRef}>
                        {row.map((item, itemIndex) => (
                          <Draggable key={itemIndex} draggableId={item._id} index={itemIndex}>
                            {(itemProvided, itemSnapshot) => (
                              <Grid
                                item
                                xs={12 / shortcutRowLength}
                                container
                                ref={itemProvided.innerRef}
                                {...itemProvided.draggableProps}
                                {...itemProvided.dragHandleProps}
                                className={classes.shortcutContainer}
                              >
                                <DndShortcut
                                  item={item}
                                  classes={classes}
                                  onDelete={() => deleteShortcut(item._id)}
                                />
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Grid>
                    )}
                  </Droppable>
                </Grid>
              ))}
            </Grid>
          </DragDropContext>
        </Grid>
      ) : (
        <Grid container>
          {!shortcuts[0] && (
            <Typography style={{ margin: theme.spacing(2, 0) }} variant="h6" color="textSecondary">
              You don't have any shortcuts
            </Typography>
          )}
          {shortcuts.map((item) => (
            <Grid
              container
              item
              lg={3}
              md={4}
              xs={6}
              key={item._id}
              className={classes.shortcutContainer}
            >
              <Shortcut item={item} classes={classes} />
            </Grid>
          ))}
        </Grid>
      )}
    </Paper>
  </Grid> */

function HomePageSettingsDialog({ availableDashboards, initial, open, onClose, onResult }) {
  const [menuAnchor, setMenuAnchor] = useState(false);
  const [selectedDashboardId, setSelectedDashboardId] = useState(null);
  const [display, setDisplay] = useState('dashboard');

  const selectedWorkspace =
    isArray(availableDashboards) && selectedDashboardId
      ? availableDashboards.find((x) => x._id === selectedDashboardId)
      : null;

  useEffect(() => {
    setSelectedDashboardId(initial?.homeDashboardId || null);
    setDisplay(initial?.homeFolderId ? 'folder' : 'dashboard');
  }, [initial]);

  function done() {
    onResult({
      homeDashboardId: selectedDashboardId,
      homeFolderId:
        display === 'folder' ? availableDashboards.find((x) => x._id === selectedDashboardId)?.rootFolderId : null,
    });
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Home page settings</DialogTitle>
      <DialogContent>
        <Grid container item sx={{ mt: 1 }}>
          <Typography variant="subtitle1" gutterBottom>
            Workspace to display:
          </Typography>
        </Grid>
        <Grid container item sx={{ py: 1 }}>
          <Button
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            style={{ marginLeft: 8, color: grey[600], textTransform: 'none', borderRadius: 8 }}
            startIcon={selectedWorkspace ? <DashboardRounded /> : <ArrowDropDownRounded />}
          >
            {selectedWorkspace ? selectedWorkspace.title : 'No workspace'}
          </Button>

          {isArray(availableDashboards) && (
            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
              {availableDashboards.map((item) => (
                <MenuItem
                  key={item._id}
                  onClick={() => {
                    setMenuAnchor(null);
                    setSelectedDashboardId(item._id);
                  }}
                >
                  <ListItemIcon>
                    <DashboardRounded style={{ color: grey[500] }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography style={{ fontWeight: selectedDashboardId === item._id ? 600 : 400 }}>
                      {item.title}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              ))}
              <MenuItem
                onClick={() => {
                  setMenuAnchor(null);
                  setSelectedDashboardId(null);
                }}
              >
                <ListItemIcon>
                  <HomeRounded style={{ color: grey[500] }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography style={{ fontWeight: selectedDashboardId === null ? 600 : 400 }}>
                    Standard home page
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          )}
        </Grid>

        {selectedDashboardId && (
          <Grid container>
            <FormControl component="fieldset">
              <FormLabel component="legend">Display</FormLabel>
              <RadioGroup value={display} onChange={(e) => setDisplay(e.target.value)} row>
                <FormControlLabel value={'dashboard'} label={'Dashboard'} control={<Radio />} />
                <FormControlLabel value={'folder'} label={'Folder'} control={<Radio />} />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
