import React, { useRef, lazy, Suspense } from 'react';
import { blue } from '@mui/material/colors';
import { Typography } from '@mui/material';
const EmailEditor = lazy(() => import('react-email-editor'));

const EmailDesigner = ({ tags = {}, design, setContent }) => {
  const emailEditorRef = useRef(null);

  const options = {
    mergeTags: tags,
    mergeTagsConfig: {
      autocompleteTriggerChar: '@',
      sort: false,
    },
    appearance: {
      theme: 'light',
      panels: {
        tools: {
          dock: 'left',
        },
      },
      loader: {
        url: 'https://app.q-hub.co.uk/main-logo.png',
      },
    },
    tools: {
      image: {
        enabled: false,
      },
    },
  };

  const tools = {
    button: {
      properties: {
        buttonColors: {
          value: {
            color: '#FFFFFF',
            backgroundColor: blue[500],
            hoverColor: '#FFFFFF',
            hoverBackgroundColor: '#3AAEE0',
            borderRadius: '10px',
          },
        },
      },
    },
    image: {
      enabled: false,
    },
  };

  const onReady = () => {
    if (design) {
      emailEditorRef?.current?.editor.loadDesign(design);
    }
    if (emailEditorRef && emailEditorRef.current) {
      emailEditorRef?.current?.addEventListener('design:updated', () => {
        emailEditorRef.current.exportHtml(setContent);
      });
    }
  };

  return (
    <Suspense fallback={<Typography>Loading...</Typography>}>
      <EmailEditor
        ref={emailEditorRef}
        onReady={onReady}
        minHeight="75vh"
        setMergeTags={tags}
        options={options}
        tools={tools}
      />
    </Suspense>
  );
};

export default EmailDesigner;
