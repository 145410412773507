import React, { useState, useEffect } from 'react';
import { ListItem, List, ListItemIcon, ListItemText, ListItemSecondaryAction, Divider } from '@mui/material';
import { DialogContent, DialogTitle, Typography, Grid, IconButton, CircularProgress } from '@mui/material';
import { Paper, Tooltip, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DeleteOutlineOutlined, FiberManualRecord, AddRounded } from '@mui/icons-material';
import { RoundedDialog, ColorPicker, StandardDialogActions, GeneralButton } from './index';
import { red, blue } from '@mui/material/colors';
import StateManager from '../StateManager';
import axios from 'axios';

export default function TagsCard({
  title,
  type,
  height = '50vh',
  disableHeight,
  disableElevation,
  selectable = false,
  onSelect,
  selectedItems,
  cardClass,
  addBtnProps = {},
  listProps = {},
}) {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tagDialog, setTagDialog] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [checked, setChecked] = useState(selectedItems);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    onSelect(newChecked);
  };

  useEffect(() => {
    if (!type) return;
    setLoading(true);
    axios
      .get('/tags/getTypeTags', { params: { type } })
      .then((res) => {
        setTags(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        StateManager.setErrorAlert(`Failed to load tags`);
      });
  }, [type]);

  function addTag(tag) {
    if (!tag?._id) return;
    let index = tags.findIndex((x) => x._id === tag._id);
    if (index > -1) {
      tags[index] = tag;
      setTags([...tags]);
    } else {
      setTags([...tags, tag]);
      if (selectable) {
        handleToggle(tag._id)();
      }
    }
  }

  function confirmDelete(tag) {
    StateManager.setConfirm(`You are about to delete tag ${tag.text}`, () => deleteTag(tag));
  }

  function deleteTag(tag) {
    let id = tag._id;
    axios
      .post('/tags/deleteTag', { id })
      .then(() => {
        StateManager.setSuccessAlert('Tag has been deleted');
        setTags([...tags.filter((x) => x._id !== id)]);
      })
      .catch((err) => {
        StateManager.setErrorAlert('Failed to delete the tag');
        console.error(err);
      });
  }

  return (
    <Grid container className={cardClass} sx={{ p: 2, borderRadius: 1.5 }}>
      <Grid
        container
        component={Paper}
        elevation={disableElevation ? 0 : 4}
        className={cardClass}
        sx={{ p: 2, borderRadius: 1.5 }}
      >
        <Grid container className="scroll-bar">
          {title && (
            <Grid container item>
              <Typography variant="h5" gutterBottom>
                {title}
              </Typography>
            </Grid>
          )}
          <Grid container item style={{ height: disableHeight ? undefined : height, overflow: 'auto' }}>
            {loading && <CircularProgress color="primary" size={32} style={{ margin: 'auto' }} />}
            {tags.length === 0 && !loading && (
              <Typography variant="h6" color="textSecondary">
                Nothing here
              </Typography>
            )}
            {tags.length > 0 && (
              <List style={{ width: '100%' }} {...listProps}>
                {tags.map((tag, i) => (
                  <React.Fragment key={tag._id}>
                    <ListItem
                      button
                      key={tag._id}
                      onClick={() => {
                        setSelectedTag(tag);
                        setTagDialog(true);
                      }}
                    >
                      <ListItemIcon>
                        <FiberManualRecord style={{ color: tag.color }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography style={{ marginRight: selectable ? 'auto' : '1rem' }} noWrap>
                            {tag.text}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        {selectable ? (
                          <Checkbox
                            edge="end"
                            onChange={handleToggle(tag._id)}
                            checked={checked.indexOf(tag._id) !== -1}
                            inputProps={{ 'aria-labelledby': 'checkboxTag' }}
                            color="primary"
                          />
                        ) : (
                          <IconButton style={{ marginLeft: '1rem' }} onClick={() => confirmDelete(tag)}>
                            <DeleteOutlineOutlined style={{ color: red[500] }} />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                    {i !== tags.length - 1 && <Divider light />}
                  </React.Fragment>
                ))}
              </List>
            )}
          </Grid>

          <Grid container item justifyContent="flex-end" {...addBtnProps}>
            <GeneralButton
              onClick={() => {
                setSelectedTag(null);
                setTagDialog(true);
              }}
              startIcon={<AddRounded color="textSecondary" />}
            >
              Add tag
            </GeneralButton>
          </Grid>

          <TagDialog
            tag={selectedTag}
            open={tagDialog}
            onClose={() => {
              setTagDialog(false);
              setSelectedTag(null);
            }}
            onResult={addTag}
            tagType={type}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function TagDialog({ tag, open, onClose, onResult, tagType }) {
  const [text, setText] = useState('');
  const [color, setColor] = useState(blue[500]);
  const [saving, setSaving] = useState(false);
  const [colourPicker, setColourPicker] = useState();

  useEffect(() => {
    if (open && !tag) {
      setText('');
      setColor(blue[500]);
    }
    if (open && tag) {
      setText(tag.text);
      setColor(tag.color);
    }
  }, [open, tag]);

  function createTag() {
    if (!text) {
      StateManager.setErrorAlert('Specify tag text');
      return;
    }
    setSaving(true);
    if (tag) {
      axios
        .post('/tags/editTag', { id: tag._id, text, color })
        .then((res) => {
          StateManager.setSuccessAlert('Tag has been saved');
          setSaving(false);
          onResult(res.data);
          onClose();
        })
        .catch((err) => {
          StateManager.setErrorAlert('Failed to save tag');
          setSaving(false);
          console.error(err);
        });
    } else {
      axios
        .post('/tags/createTag', { text, type: tagType, color })
        .then((res) => {
          StateManager.setSuccessAlert('Tag has been added');
          setSaving(false);
          onResult(res.data);
          onClose();
        })
        .catch((err) => {
          StateManager.setErrorAlert('Failed to add tag');
          setSaving(false);
          console.error(err);
        });
    }
  }

  function selectColor(color) {
    if (color?.color) {
      setColor(color.color);
    }
    setColourPicker(null);
  }

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={() => onClose(null)}>
      <DialogTitle>{tag ? 'Edit tag' : 'New tag'}</DialogTitle>

      <DialogContent>
        <Grid container alignItems="center">
          <TextField
            variant="outlined"
            label="Tag title"
            value={text}
            style={{ flexGrow: 1, marginRight: 5, height: 'fit-content' }}
            onChange={(event) => setText(event.target.value)}
            inputProps={{ maxLength: 64 }}
          />
          <Tooltip title="Pick color" placement="top">
            <IconButton onClick={(e) => setColourPicker(e.currentTarget)}>
              <FiberManualRecord style={{ color }} />
            </IconButton>
          </Tooltip>
          <ColorPicker anchor={colourPicker} open={Boolean(colourPicker)} onClose={selectColor} />
        </Grid>
      </DialogContent>
      <StandardDialogActions saving={saving} onClose={() => onClose(null)} onDone={createTag} hideDone={!text} />
    </RoundedDialog>
  );
}
