import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompanyGroup } from '../../../redux/actions';
import { Avatar, DialogTitle, IconButton } from '@mui/material';
import { Grid, DialogContent, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import RoundedDialog from '../Components/RoundedDialog';
import GroupIcon from '@mui/icons-material/Group';
import User from '../Components/User';
import { Cancel } from '@mui/icons-material';

export default function GroupButton({ id, fullWidth = true, dense = false, onDelete, ...props }) {
  const dispatch = useDispatch();
  const groups = useSelector(({ general }) => general.group);
  const group = groups[id] || {};
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!id) return;
    setOpen(false);
    dispatch(fetchCompanyGroup(id));
  }, [dispatch, id]);

  if (!id) return null;

  return (
    <Grid
      container={fullWidth}
      onClick={(e) => {
        e.preventDefault();
        setOpen(!open);
      }}
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        border: '1px solid #ccc',
        borderRadius: '7px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
      {...props}
    >
      <Grid item sx={{ margin: '0 10px' }}>
        <Typography sx={{ fontSize: 18 }} component="div">
          {group?._id ? group?.name : <Skeleton animation="wave" width={50} />}
        </Typography>
      </Grid>
      <Grid item>
        {group?._id ? (
          <Avatar
            style={{
              background: 'transparent',
              height: 32,
              width: 32,
            }}
          >
            <GroupIcon color="primary" />
          </Avatar>
        ) : (
          <Skeleton animation="wave" variant="circular" width={32} height={32} />
        )}
      </Grid>
      {onDelete && (
        <IconButton onClick={onDelete} color="error">
          <Cancel />
        </IconButton>
      )}
      <RoundedDialog
        open={open}
        maxWidth="xs"
        fullWidth
        onClose={() => setOpen(false)}
        scroll="body"
        titleId={group?.name}
      >
        <DialogTitle>{group?.name}</DialogTitle>
        <DialogContent>
          {group?.users && group?.users?.length > 0 && (
            <Grid container spacing={2} direction={'column'} style={{ padding: 10 }}>
              {group?.users?.map((x) => (
                <User id={x} key={x} />
              ))}
            </Grid>
          )}
        </DialogContent>
      </RoundedDialog>
    </Grid>
  );
}
