import React from 'react';
import Avatar from '@mui/material/Avatar';

import { avatarStyles, extStyles } from '../../constants';
import Icon from './icon';
import { getColor } from './color';

const FileIconAvatar = ({ document, fontSize = 28, extraStyles = {}, mini }) => {
  let extension = document?.extension;
  if (extension === undefined) {
    extension = document?.category === 'normal' ? document?.file?.mimetype : 'qhub';
  }

  const color = getColor(extension);

  return (
    <Avatar
      sx={{
        ...(document?.category === 'qhub' ? avatarStyles : extStyles),
        color: (t) => (t.palette.mode === 'dark' ? (color === 'black' ? 'white' : color) : color),
        ...(mini && { width: 16, height: 16 }),
        ...extraStyles,
      }}
    >
      <Icon ext={extension} fontSize={mini ? 16 : fontSize} mini={mini} />
    </Avatar>
  );
};

export default FileIconAvatar;
