import { isArray, isEmpty } from 'lodash';
import React from 'react';
import TooltipTypography from './TooltipTypography';

export default function TitleTypography({ item, suffix, number, ...props }) {
  const separatorMap = {
    '-': '-',
    _: '_',
    '/': '/',
    '.': '.',
    Space: ' ',
    None: '',
  };

  return (
    <TooltipTypography {...props}>
      {isArray(item?.namingConvention?.value) && !isEmpty(item.namingConvention?.value) ? (
        <>
          {item.namingConvention.value.map((x, i) => (
            <span key={i} style={{ color: x.color }}>
              {x.text}
              {i !== item.namingConvention.value.length - 1 &&
                x.text &&
                item?.namingConvention.separator &&
                separatorMap[item?.namingConvention.separator]}
            </span>
          ))}
          {'   '}
        </>
      ) : (
        <>
          {item?.prefix?.text ? (
            <span style={{ color: item.prefix.color }}>
              {item.prefix.text}-{number != null ? number : item.number != null ? item.number : 0}
              {'   '}
            </span>
          ) : null}
          {item?.title}
        </>
      )}

      {suffix}
    </TooltipTypography>
  );
}
