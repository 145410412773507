import React from 'react';
import { Avatar, Badge, Grid, ListItemButton, Typography, Divider } from '@mui/material';
import moment from 'moment';
import GroupIcon from '@mui/icons-material/Group';
import UserMap from '../UserMap';
import { blue } from '@mui/material/colors';
import { TooltipTypography } from '../../Global/Components';
import { FiberManualRecord } from '@mui/icons-material';

const userId = localStorage.getItem('_id');

function getMessagePreviewText(message) {
  if (message.attachments) {
    if (message.attachments.length > 1) return `${message.attachments.length} attachments`;
    if (message.attachments.length === 1 && message.attachments[0].type?.startsWith('image/')) return `Photo`;
  }
  return message.text;
}

export default function ChatListElement({ item, onSelect }) {
  function formateDate(date) {
    var dt;
    if (date) dt = moment(date);
    else dt = moment();
    var time = dt.format('hh:mm A');
    if (dt.isSame(moment(), 'day')) {
      // if today, only time
      return time;
    } else if (dt.isSame(moment().subtract(1, 'days'), 'day')) {
      // if yesterday
      return 'yesterday';
    } else if (dt.isSame(moment(), 'year')) {
      // if this year
      return dt.format('D MMM');
    } else {
      return dt.format('D-MMM-YY'); // if not this year
    }
  }

  var fromStr = '';

  var interlocutor = null;

  if (item.type === 'private' && item.participants) {
    let interlocutorId = item.participants.find((x) => x !== userId);
    interlocutor = UserMap.resolve(interlocutorId);
  }

  return (
    <Grid container>
      <Grid
        container
        component={ListItemButton}
        sx={{ p: 1.5, background: item.selected ? blue[600] : '' }}
        onClick={() => onSelect(item)}
        wrap="nowrap"
        alignItems={'center'}
      >
        <Grid item>
          {item.type === 'private' ? (
            <Avatar src={interlocutor.avatar} style={{ width: 50, height: 50 }}>
              {interlocutor.avatarLetters}
            </Avatar>
          ) : (
            <>
              {item.picture ? (
                <Avatar src={item.picture} style={{ width: 50, height: 50 }} />
              ) : (
                <Avatar style={{ width: 50, height: 50 }}>
                  <GroupIcon />
                </Avatar>
              )}
            </>
          )}
        </Grid>
        <Grid item sx={{ pl: 2, flexGrow: 1, width: '70%' }}>
          <Grid container sx={{ mb: 0.5 }} alignItems={'center'} wrap="nowrap">
            <TooltipTypography sx={{ fontSize: 18, fontWeight: 500, mr: 1 }}>
              {item.type === 'private' ? interlocutor.fullName : item.title}
            </TooltipTypography>
            <div style={{ marginLeft: 'auto' }} />
            {item.unreads[userId] > 0 && <Badge color="primary" sx={{ mr: 2 }} badgeContent={item.unreads[userId]} />}
            {item.type === 'private' && item.unreads[interlocutor._id] > 0 && <FiberManualRecord color="primary" />}
            <Typography sx={{ fontSize: 14, ml: 0.5 }}>{formateDate(item.lastMessage.time)}</Typography>
          </Grid>
          {item.lastMessage.from && (
            <Grid container sx={{ maxWidth: '100%' }}>
              {item.type === 'group' && item.lastMessage.from !== userId && fromStr && (
                <TooltipTypography sx={{ fontSize: 14 }}>{fromStr}</TooltipTypography>
              )}
              <TooltipTypography sx={{ fontSize: 14 }}>
                {item.lastMessage.from === userId ? 'You: ' : ''}
                {getMessagePreviewText(item.lastMessage)}
              </TooltipTypography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container sx={{ px: 2 }}>
        <Divider sx={{ flexGrow: 1 }} />
      </Grid>
    </Grid>
  );
}
