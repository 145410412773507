import {
  READUNDERSTOODACTION_ATTEMPT,
  READUNDERSTOODLIST_ATTEMPT,
  READUNDERSTOODCONFIRM_ATTEMPT,
  READUNDERSTOODDOCS_ATTEMPT,
  READUNDERSTOOD_ATTEMPT,
  GETCOMPANYTASKLIST,
  GETCOMPANYTASKLISTTOTALCOUNT,
  GETMYTASKLIST,
  GETMYTASKLISTTOTALCOUNT,
  GETSENTTASKLIST,
  GETSENTTASKLISTTOTALCOUNT,
  GETTASKSBYVIEW,
  GETTASKSBYVIEWTOTALCOUNT,
  GETTASKSBYPARAM,
  GETREADUNDERSTOOD,
  GETREADUNDERSTOODDOCS,
  READUNDERSTOODACTIONCONFIRMED,
  DELETEREADUNDERSTOOD,
  CLOSE_RU_DIALOG,
  READUNDERSTOOD_ERROR,
  DELETEREADUNDERSTOODS,
} from '../constants';

const initialState = {
  listLoading: false,
  actionLoading: false,
  error: null,
  companyTasks: [],
  companyTasksTotalCount: 0,
  myTasks: [],
  myTasksTotalCount: 0,
  sentTasks: [],
  sentTasksTotalCount: 0,
  viewTasks: [],
  paramTasks: [],
  ruDocs: [],
  ruActivity: null,
  readUnderstood: null,
  ruDialog: false,
  ruSaving: false,
  ruLoading: false,
  docsLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case READUNDERSTOODLIST_ATTEMPT:
      return { ...state, listLoading: true };
    case READUNDERSTOODACTION_ATTEMPT:
      return { ...state, actionLoading: true };
    case READUNDERSTOODCONFIRM_ATTEMPT:
      return { ...state, ruSaving: true };
    case READUNDERSTOOD_ATTEMPT:
      return { ...state, ruLoading: true };
    case READUNDERSTOODDOCS_ATTEMPT:
      return { ...state, docsLoading: true };
    case READUNDERSTOOD_ERROR:
      return {
        ...state,
        listLoading: false,
        actionLoading: false,
        ruSaving: false,
        ruLoading: false,
        docsLoading: false,
        error: action.payload,
      };
    case GETCOMPANYTASKLIST:
      return { ...state, listLoading: false, companyTasks: action.payload };
    case GETCOMPANYTASKLISTTOTALCOUNT:
      return { ...state, listLoading: false, companyTasksTotalCount: action.payload };
    case GETMYTASKLIST:
      return { ...state, listLoading: false, myTasks: action.payload };
    case GETMYTASKLISTTOTALCOUNT:
      return { ...state, listLoading: false, myTasksTotalCount: action.payload };
    case GETSENTTASKLIST:
      return { ...state, listLoading: false, sentTasks: action.payload };
    case GETSENTTASKLISTTOTALCOUNT:
      return { ...state, listLoading: false, sentTasksTotalCount: action.payload };
    case GETTASKSBYVIEW:
      return { ...state, listLoading: false, viewTasks: action.payload };
    case GETTASKSBYVIEWTOTALCOUNT:
      return { ...state, listLoading: false, viewTasksTotalCount: action.payload };
    case GETTASKSBYPARAM:
      return { ...state, listLoading: false, paramTasks: action.payload };
    case GETREADUNDERSTOOD:
      return {
        ...state,
        readUnderstood: action.payload.result,
        ruActivity: action.payload.activity ? action.payload.activity : null,
        ruDialog: true,
        ruLoading: false,
      };
    case READUNDERSTOODACTIONCONFIRMED:
      return {
        ...state,
        ruSaving: false,
        ruDialog: false,
        companyTasks: state.companyTasks.map((ru) => (ru.id === action.payload.id ? action.payload : ru)),
        myTasks: state.myTasks.map((ru) => (ru.id === action.payload.id ? action.payload : ru)),
        sentTasks: state.sentTasks.map((ru) => (ru.id === action.payload.id ? action.payload : ru)),
        viewTasks: state.viewTasks.map((ru) => (ru.id === action.payload.id ? action.payload : ru)),
        readUnderstood: action.payload,
      };
    case CLOSE_RU_DIALOG:
      return { ...state, ruDialog: false, readUnderstood: null, ruActivity: null };
    case DELETEREADUNDERSTOOD:
      return {
        ...state,
        companyTasks: state.companyTasks?.filter((ru) => ru.id !== action.payload.id),
        myTasks: state.myTasks?.filter((ru) => ru.id !== action.payload.id),
        sentTasks: state.sentTasks?.filter((ru) => ru.id !== action.payload.id),
        viewTasks: state.viewTasks?.filter((ru) => ru.id !== action.payload.id),
      };
    case DELETEREADUNDERSTOODS:
      return {
        ...state,
        companyTasks: state.companyTasks?.filter((ru) => !action.payload?.includes(ru.id)),
        myTasks: state.myTasks?.filter((ru) => !action.payload?.includes(ru.id)),
        sentTasks: state.sentTasks?.filter((ru) => !action.payload?.includes(ru.id)),
        viewTasks: state.viewTasks?.filter((ru) => !action.payload?.includes(ru.id)),
      };
    case GETREADUNDERSTOODDOCS:
      return {
        ...state,
        ruDocs: action.payload,
      };
    default:
      return state;
  }
}
