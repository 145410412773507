import { drawerOptions } from '../../../constants';

export const setupGroups = [
  {
    target: '.add-group',
    content: 'Click to Add Group',
    ...drawerOptions,
    locale: {
      last: 'Next',
    },
  },
];

export const createGroup = [
  {
    target: '.groupName',
    content: 'Add a Group Title',
    ...drawerOptions,
  },
  {
    target: '.groupUsers',
    content: 'Assign Users to the Group',
    ...drawerOptions,
  },
  {
    target: '.groupAddBtn',
    content: 'Finish Creating Group',
    ...drawerOptions,
    locale: {
      last: 'Finish',
    },
  },
];
