import { Button } from '@mui/material';
import React from 'react';
import { grey } from '@mui/material/colors';
import { Reply } from '@mui/icons-material';

export default function BackButton({ onClick }) {
  return (
    <Button
      style={{ color: grey[700], textTransform: 'none', borderRadius: 8 }}
      startIcon={<Reply />}
      onClick={onClick}
    >
      Back
    </Button>
  );
}
