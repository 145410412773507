import React from 'react';
import { Typography, Box } from '@mui/material';
import { formatDate, formatRevision } from '../../../../constants';

const captionStyles = {
  margin: 0,
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  marginBottom: '0.35em',
  display: 'block',
  textAlign: 'center',
};

const DynamicComponent = ({ value, document, metaData, issue }) => {
  switch (value) {
    case 'file-name':
      return (
        <Typography variant="caption" sx={captionStyles}>
          {document.title}
        </Typography>
      );
    case 'doc-owners-name':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          {document.owner_id?.fullName || document?.ownerName}
        </Typography>
      );
    case 'current-version':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          {formatRevision(issue ? document.baseVersion : document.issue, document.revisionFormat)}
        </Typography>
      );
    case 'image':
      return metaData ? (
        <Box
          component="div"
          sx={{ width: '100%', height: 30, alignItems: 'center', display: 'flex', justifyContent: 'center' }}
        >
          <Box
            component="img"
            src={metaData}
            sx={{ width: '100%', height: 30, objectFit: 'contain', align: 'center' }}
            alt="logo"
          />
        </Box>
      ) : null;
    case 'custom-text':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          {metaData}
        </Typography>
      );
    case 'doc-prefix-number':
      return (
        <Typography
          sx={{
            ...captionStyles,
            color: document.prefix?.color || 'inherit',
          }}
          variant="caption"
          align="justify"
        >
          {document.prefix?.name} {document.sequence}
        </Typography>
      );
    case 'last-review-date':
      return document.reviewDate ? (
        <Typography variant="caption" sx={captionStyles} align="justify">
          Reviewed {formatDate(document.reviewDate, 'DD/MM/YY')}
        </Typography>
      ) : (
        <Typography variant="caption" sx={captionStyles} align="justify">
          {formatDate(document.lastModified, 'DD/MM/YY')}
        </Typography>
      );
    case 'last-up-issue-date':
      return document.issueDate ? (
        <Typography variant="caption" sx={captionStyles} align="justify">
          Up-issued {document.issueDate && formatDate(document.issueDate, 'DD/MM/YY')}
        </Typography>
      ) : null;
    case 'published-date':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          Published {formatDate(document.lastModified, 'DD/MM/YY')}
        </Typography>
      );
    case 'page-number':
      return (
        <Typography variant="caption" sx={captionStyles} align="justify">
          Page #
        </Typography>
      );
    default:
      return null;
  }
};

export default DynamicComponent;
