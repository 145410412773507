import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { previewFile } from '../../redux/actions/file';
import { VFORMAT, formatMiniRevisionIteration, formatRevision } from '../../constants';
import { COLORS } from '../../constants';

import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';

import IssueInfo from './mini';

import { VersionCell, FileCell } from '../IssueComponents';

const HistoryTable = ({ document, miniRevisions }) => {
  const dispatch = useDispatch();

  const [issueBox, setIssueBox] = useState(false);
  const [revisionData, setRevisionData] = useState(null);

  const openFile = (selected) => {
    dispatch(previewFile(selected));
  };

  return (
    <TableContainer sx={{ margin: (theme) => theme.spacing(1, 0) }}>
      <Table aria-label="revision table table" size="small">
        <TableBody>
          {miniRevisions &&
            miniRevisions.length > 0 &&
            miniRevisions?.map((rev) => (
              <React.Fragment key={rev._id}>
                {document.fileHistory
                  ?.filter((x) => x.version === rev.version)
                  ?.map((x, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">
                        {formatMiniRevisionIteration(
                          document.baseVersion,
                          document?.revisionFormat || VFORMAT,
                          x.version || 1,
                          document?.manualIssueControl,
                          document?.manualIssue,
                        )}
                      </TableCell>
                      <TableCell align="left" colSpan="4" padding="none">
                        <FileCell user={x.uploadedBy} />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={() =>
                            openFile({ category: 'normal', file: x, title: rev.title, encrypted: document?.encrypted })
                          }
                        >
                          <DescriptionIcon sx={{ color: COLORS.SEARCH }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow key={rev._id}>
                  <TableCell align="left">
                    {formatMiniRevisionIteration(
                      rev.baseVersion,
                      rev?.revisionFormat || VFORMAT,
                      rev.version || 1,
                      rev?.manualIssueControl,
                      rev?.manualIssue,
                    )}
                  </TableCell>
                  <TableCell align="left" colSpan="4" padding="none">
                    <VersionCell rev={rev} />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setRevisionData(rev);
                        setIssueBox(true);
                      }}
                    >
                      <VisibilityIcon
                        sx={{
                          color: COLORS.SEARCH,
                        }}
                      />
                    </IconButton>
                    <IconButton size="small" onClick={() => openFile({ ...rev, encrypted: document?.encrypted })}>
                      <DescriptionIcon sx={{ color: COLORS.SEARCH }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      {issueBox && revisionData && (
        <IssueInfo
          open={issueBox}
          onClose={() => {
            setIssueBox(false);
            setRevisionData(null);
          }}
          document={revisionData}
          baseIssue={document?._id}
          miniVersion={revisionData?.version}
        />
      )}
    </TableContainer>
  );
};

export default HistoryTable;
