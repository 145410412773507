import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

export default function ProcessRedirect() {
  return (
    <Switch>
      <Route exact path="/process">
        <Redirect to="/processes/home" />
      </Route>

      <Route exact path="/process/home">
        <Redirect to="/processes/home" />
      </Route>

      <Route exact path="/process/flows">
        <Redirect to="/processes/home" />
      </Route>

      <Route exact path="/process/settings">
        <Redirect to="/processes/settings" />
      </Route>

      <Route exact path="/process/working">
        <Redirect to="/processes/working" />
      </Route>

      <Route exact path="/process/completed">
        <Redirect to="/processes/completed" />
      </Route>

      <Route exact path="/process/bookmarked">
        <Redirect to="/processes/bookmarked" />
      </Route>

      <Route exact path="/process/cancelled">
        <Redirect to="/processes/cancelled" />
      </Route>

      <Route exact path="/process/held">
        <Redirect to="/processes/held" />
      </Route>

      <Route exact path="/process/watching">
        <Redirect to="/processes/watching" />
      </Route>

      <Route exact path="/process/drafts">
        <Redirect to="/processes/drafts" />
      </Route>

      <Route exact path="/process/new-process">
        <Redirect to="/processes/process-builder" />
      </Route>

      <Route exact path="/process/forms">
        <Redirect to="/forms/home" />
      </Route>

      <Route
        path="/process/info/:id/:option?"
        render={({ match }) => <Redirect to={`/processes/${match.params.id}`} />}
      />

      <Route
        path="/workflow/processes/ongoing/:id/:stepId?"
        render={({ match }) => (
          <Redirect
            to={`/processes/ongoing/${match.params.id}${match.params.stepId ? '/' : ''}${match.params.stepId || ''}`}
          />
        )}
      />

      <Route path="/process/step/:id" render={({ match }) => <Redirect to={`/processes/step/${match.params.id}`} />} />

      <Route
        path="/process/start/:processId"
        render={({ match }) => <Redirect to={`/processes/start-process/${match.params.processId}`} />}
      />

      <Route
        path="/process/forms/:formId/new-entry"
        render={({ match }) => <Redirect to={`/forms/${match.params.formId}/new-entry`} />}
      />

      <Route
        path="/process/forms/entry/:entryId"
        render={({ match }) => <Redirect to={`/forms/entry/${match.params.entryId}`} />}
      />
    </Switch>
  );
}
