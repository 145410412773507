import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, Typography, useTheme } from '@mui/material';
import { Switch, Collapse, FormControlLabel } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { NumberRangePicker, RoundedDialog, StandardDialogActions } from '../Components';
import { isEmpty, isArray } from 'lodash';

export default function StatusAutomationDialog({ open, onClose, numberFields, options, initial, onResult }) {
  const [setAutomatically, setSetAutomatically] = useState(false);
  const [statusConditions, setStatusConditions] = useState({});
  const theme = useTheme();

  useEffect(() => {
    setSetAutomatically(initial?.setAutomatically || false);
    setStatusConditions(initial?.statusConditions || {});
  }, [initial]);

  function done() {
    onResult({ setAutomatically, statusConditions: setAutomatically ? statusConditions : null });
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Status automations</DialogTitle>
      <DialogContent>
        {(isEmpty(numberFields) || !isArray(numberFields)) && (
          <Grid container style={{ padding: theme.spacing(2, 0) }}>
            <Typography variant="h6" color={'textSecondary'}>
              No number fields defined
            </Typography>
          </Grid>
        )}
        {(isEmpty(options) || !isArray(options)) && (
          <Grid container style={{ padding: theme.spacing(2, 0) }}>
            <Typography variant="h6" color={'textSecondary'}>
              No options defined
            </Typography>
          </Grid>
        )}
        {!isEmpty(numberFields) && isArray(options) && !isEmpty(options) && (
          <Grid container style={{ padding: theme.spacing(1.5, 0) }}>
            <Grid container item style={{ padding: theme.spacing(1.5, 0) }}>
              <FormControlLabel
                label="Set statuses automatically based on other column's value"
                control={<Switch color="primary" checked={setAutomatically} />}
                onChange={(e) => setSetAutomatically(e.target.checked)}
              />
            </Grid>
            <Grid container item>
              <Collapse in={setAutomatically} style={{ width: '100%' }}>
                {options.map((option) => (
                  <Grid
                    key={option.id}
                    container
                    item
                    alignItems="flex-end"
                    wrap="nowrap"
                    style={{ padding: theme.spacing(1.5, 0) }}
                  >
                    <FiberManualRecord style={{ color: option.color }} />

                    <Typography style={{ marginLeft: 12, marginRight: 12 }}>{option.text}:</Typography>

                    <Grid container item style={{ width: 'auto', flexGrow: 1 }}>
                      <NumberRangePicker
                        columns={numberFields}
                        initial={statusConditions[option.id]}
                        onChange={(value) => setStatusConditions({ ...statusConditions, [option.id]: value })}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Collapse>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
