import React from 'react';
import { useHistory } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import LinkIcon from '@mui/icons-material/Link';
import { COLORS } from '../../constants';
import { Document, TooltipIconButton, TooltipTypography } from '../../../../Global/Components';
import { TableHead } from '@mui/material';

const LinkTable = ({ linkedFiles, handleLink }) => {
  if (linkedFiles?.length > 0) {
    return (
      <TableContainer>
        <Table aria-label="linked-files-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ color: 'primary.main', fontWeight: 600 }} width="50%">
                Link Document
              </TableCell>
              <TableCell align="center" sx={{ color: 'primary.main', fontWeight: 600 }}>
                Options
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {linkedFiles?.map((doc, i) => (
              <TableRow key={i}>
                <TableCell align="left" width="70%">
                  {doc?.targetType === 'document' && <Document id={doc?.targetItem} avatarSize={16} />}
                  {doc?.targetType !== 'document' && (
                    <TooltipTypography sx={{ width: '150px' }} align="left">
                      {doc?.title}
                    </TooltipTypography>
                  )}
                </TableCell>
                <TableCell align="center">
                  <TooltipIconButton text="Open Doc" onClick={() => handleLink(doc)}>
                    <LinkIcon sx={{ color: COLORS.SEARCH }} />
                  </TooltipIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
};

export default LinkTable;
