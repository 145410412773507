import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StandardDialogActions, RoundedDialog } from '../../../../Global/Components';

import { addTag, getTags, updateTag, deleteTag } from '../../redux/actions';

import { tagStyles } from '../../constants';

import Loader from '../../../../Global/Loader';
import { ColorMenu } from '../HTMLEditor/components';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import DialogContent from '@mui/material/DialogContent';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LabelIcon from '@mui/icons-material/Label';

const initialState = {
  name: '',
  color: 'FF6900',
};

const textStyles = (color) => ({
  backgroundColor: `#${color}`,
  color: (theme) => (color ? theme.palette.getContrastText(`#${color}`) : undefined),
  borderRadius: '10px',
  fontWeight: 'bold',
  width: '250px',
});

const TagsDialog = () => {
  const dispatch = useDispatch();
  const { loading, tags } = useSelector(({ settings }) => settings);

  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState(initialState);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState();
  const [colorMenu, setColorMenu] = useState(null);

  const removeTag = (id) => {
    dispatch(deleteTag(id));
  };

  const editTag = (id, data) => {
    dispatch(updateTag(id, data));
    setTag(initialState);
    setEdit(null);
  };

  const saveTag = () => {
    dispatch(addTag(tag));
    setAdd(false);
    setTag(initialState);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) return;
    setTag(initialState);
    setAdd(false);
    setEdit(false);
    setColorMenu(null);
    dispatch(getTags());
  }, [dispatch, open]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ color: 'primary.main' }} size="small">
        <LabelIcon />
      </IconButton>
      <RoundedDialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          {loading && <Loader fetching={loading} />}
          <Button sx={{ color: 'grey' }} onClick={() => setAdd(true)} size="small" startIcon={<AddCircleIcon />}>
            Add a tag
          </Button>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Tag</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Color code</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <ColorMenu
                  open={colorMenu}
                  handleClose={() => setColorMenu(null)}
                  handleClick={(value) => setTag({ ...tag, color: value.split('-')[1].replace('#', '') })}
                />
                {add && (
                  <TableRow>
                    <TableCell>
                      <OutlinedInput
                        value={tag.name}
                        placeholder="Tag Name"
                        sx={textStyles(tag?.color)}
                        onChange={(e) => setTag({ ...tag, name: e.target.value })}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <FiberManualRecordIcon
                        sx={{ color: `#${tag.color}` }}
                        onClick={(event) => setColorMenu(event.currentTarget)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <IconButton size="small" onClick={() => saveTag()}>
                        <DoneIcon />
                      </IconButton>
                      <IconButton size="small" onClick={() => setAdd(false)}>
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
                {tags &&
                  tags.length > 0 &&
                  tags.map((t, i) => (
                    <TableRow key={i}>
                      <TableCell sx={edit && edit.index === i && edit.status ? null : tagStyles(t.color)}>
                        {edit && edit?.index === i && edit?.status ? (
                          <OutlinedInput
                            value={tag.name}
                            placeholder="Tag Name"
                            sx={textStyles(tag?.color)}
                            onChange={(e) => setTag({ ...tag, name: e.target.value })}
                            size="small"
                          />
                        ) : (
                          t.name
                        )}
                      </TableCell>
                      <TableCell>
                        {edit && edit?.index === i && edit?.status ? (
                          <FiberManualRecordIcon
                            sx={{ color: `#${tag.color}` }}
                            onClick={(event) => setColorMenu(event.currentTarget)}
                          />
                        ) : (
                          t.color
                        )}
                      </TableCell>
                      <TableCell>
                        {edit && edit.index === i && edit.status ? (
                          <>
                            <IconButton size="small" onClick={() => editTag(t._id, tag)}>
                              <DoneIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => setEdit(null)}>
                              <CloseIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setEdit({ index: i, status: true });
                                setTag(t);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => removeTag(t._id)}>
                              <CloseIcon />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <StandardDialogActions onClose={handleClose} />
      </RoundedDialog>
    </>
  );
};

export default TagsDialog;
