import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, LinearProgress, Typography, Button, DialogTitle, ListItemButton } from '@mui/material';
import { DialogContent, CircularProgress, IconButton } from '@mui/material';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Chip } from '@mui/material';
import { Link, Hidden, useMediaQuery, Tooltip, Divider, InputBase, useTheme } from '@mui/material';
import { EditRounded, DescriptionRounded, SearchRounded, LockRounded } from '@mui/icons-material';
import { AttachmentRounded, CloseRounded, DoneRounded, LockOpenRounded } from '@mui/icons-material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import { RoundedDialog, CrossButton, GeneralButton, StandardDialogActions } from '../Components';
import Dropzone from 'react-dropzone';
import { DocIcon, FormIcon } from '../Icons';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { BASE_URL } from '../../../constants';
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { AuditIcon } from '../Icons';
import { WorkOutline, VisibilityRounded } from '@mui/icons-material';
import { useRouteMatch } from 'react-router-dom';
import { blue, grey } from '@mui/material/colors';
import StateManager from '../StateManager';
import { FormatDateShort, formatBytes } from '../Functions';
import { openPDFDialog } from '../PDFViewer/dialog';
import ImageDialog from '../Components/ImageDialog';
import FileIconAvatar from '../../Hubs/dochub/components/FileIconAvatar';
import { useSelector } from 'react-redux';
import axios from 'axios';
import _, { isEmpty } from 'lodash';
import { styled, alpha } from '@mui/material/styles';
import { getDecryptedFile } from '../../Hubs/dochub/redux/actions/file';
import { Extensions } from '../FilePreviewer/types';
import ConfirmDialog from './ConfirmDialog';
import RefLinkDialog from '../RefLinkDialog';

const ImageAccept = Extensions.Image.reduce((prev, curr) => ({ ...prev, [curr]: [] }), {});

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 50,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => {
  return {
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    },
  };
});

function hex24() {
  return [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
}

export default function Uploader({
  onChange,
  onStart,
  onResult,
  onCancel,
  onLock,
  onBulkLock,
  disableLock,
  uploaded,
  buttonText = 'Attach',
  open,
  onlyImages = false,
  clearOnDone,
  dialogOpen,
  onDialogClose,
  documentCategory = 'all',
  showLock = false,
  hideDropzone,
  maxFiles,
  buttonProps,
  encrypted = false,
  defaultLock = true,
  withLinkedFeatures,
}) {
  const [files, setFiles] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [src, setSrc] = useState('');
  const MAX_SIMULTANEOUSLY = 50;
  const [bulkLock, setBulkLock] = useState([]);

  //const timer = useRef(null);
  const result = useRef([]);
  const lockRef = useRef([]);

  const controlled = dialogOpen !== undefined && typeof onDialogClose === 'function';
  const uploadedCount = (_.isArray(uploaded) ? uploaded.length : 0) + files.length;
  const remainingFiles = _.isNumber(maxFiles) ? maxFiles - uploadedCount : null;
  const uploadedFiles = _.isArray(uploaded) ? uploaded : [];

  const externalUser =
    !localStorage.getItem('_id') && !localStorage.getItem('token') && localStorage.getItem('invitationId');

  useEffect(() => {
    if (open === true) {
      lockRef.current = [];
      setFiles([]);
      setBulkLock([]);
      setDialog(true);
    }
  }, [open]);

  useEffect(() => {
    // do not cause update after sending the result
    if (!_.isArray(uploaded) || _.isEqual(result.current, uploaded)) return;

    result.current = uploaded; // _.uniqBy([...uploaded, ...result.current], 'id');
    if (!clearOnDone) {
      setFiles(result.current.map((x) => ({ id: x.id, file: x, upload: false })));
    }
  }, [uploaded, clearOnDone]);

  function removeFile(id) {
    setFiles([...files.filter((x) => x.id !== id)]);
    result.current = result.current.filter((x) => x.id !== id);
    if (showLock) {
      lockRef.current = lockRef.current.filter((x) => x !== id);
    }
    if (onCancel) onCancel(id);
    if (onChange) onChange(result.current);
  }

  function updateFile(id, name) {
    let resIndex = result.current.findIndex((x) => x.id === id);
    if (resIndex === -1) return;
    result.current[resIndex].name = name;
    result.current[resIndex].originalname = name;
    if (onChange) onChange(result.current);
  }

  function sendResult(file) {
    // process the queue
    const nextToUploadIndex = files.findIndex((x) => x.upload === false);
    if (nextToUploadIndex > -1) {
      files[nextToUploadIndex].upload = true;
    }

    if (clearOnDone) {
      const index = files.findIndex((x) => x.id === file.id);
      files.splice(index, 1);
    } else {
      const index = files.findIndex((x) => x.id === file.id);
      if (index > -1) {
        files[index].upload = null;
      }
    }
    // CRITICAL not to use 'setfiles' here if there are files to upload

    // so all the files has been uploaded
    if (nextToUploadIndex === -1) {
      setFiles([...files]);
    }

    const res = {
      id: file.id,
      name: file.file.originalname,
      originalname: file.file.originalname,
      key: file.file.key,
      location: file.file.location,
      size: file.file.size,
      type: file.file.mimetype,
      mimetype: file.file.mimetype,
      link: file.file.link,
      hub: file.file.hub,
      form: file.file.form,
      encrypted: file.encrypted,
    };

    result.current.push(res);

    if (_.isFunction(onChange)) {
      onChange([...result.current]);
    }

    if (_.isFunction(onResult)) {
      onResult({
        id: file.id,
        file: res,
        encrypted: file.encrypted,
        preview: file.preview,
      });
      if (showLock && defaultLock) {
        lockRef.current.push(file.id);
        setBulkLock([...lockRef.current]);
      }
    }
  }

  function addFiles(newFiles) {
    // check that files has not been uploaded
    // and set 'do not upload' for the files that has no location
    const added = newFiles
      .filter((x) => !files.some((f) => f.id === x.id) && !uploadedFiles.some((f) => f.id === x.id))
      .map((x) => ({ ...x, upload: Boolean(x.file.location) }));

    const uploading = files.filter((f) => f.upload === true).length;
    if (uploading < MAX_SIMULTANEOUSLY) {
      for (let i = 0; i < MAX_SIMULTANEOUSLY - uploading && i < added.length; ++i) {
        const index = added.findIndex((x) => x.upload === false);
        if (index > -1) {
          added[index].upload = true;
        }
      }
    }
    setFiles([...added, ...files]);
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ padding: '0.5rem 0' }}>
      <Grid container item alignItems="center">
        {!externalUser && !controlled && (remainingFiles == null || remainingFiles > 0) && (
          <Grid container item lg={2} md={2} xs={12}>
            <Button
              sx={{
                textTransform: 'none',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main,
              }}
              startIcon={<AttachmentRounded />}
              onClick={() => setDialog(true)}
              {...buttonProps}
            >
              {buttonText}
            </Button>
          </Grid>
        )}
        {!hideDropzone && (remainingFiles == null || remainingFiles > 0) && (
          <Grid container item lg={externalUser ? 12 : 10} md={externalUser ? 12 : 10} xs={12}>
            <DropzoneComponent
              onlyImages={onlyImages}
              onResult={(items) => {
                const toAdd = items.map((f) => ({ id: hex24(), file: f }));
                addFiles(toAdd);
              }}
              dense
              maxFiles={remainingFiles}
            />
          </Grid>
        )}
      </Grid>

      {onBulkLock && (
        <Grid item container xs={11} justifyContent="flex-end">
          <Button
            onClick={() => {
              let updatedBulkFiles = files.map((f) => f.id);
              if (updatedBulkFiles.length === bulkLock.length) {
                setBulkLock([]);
                onBulkLock([]);
                lockRef.current = [];
              } else {
                setBulkLock(updatedBulkFiles);
                onBulkLock(updatedBulkFiles);
                lockRef.current = updatedBulkFiles;
              }
            }}
            style={{ textTransform: 'none', color: 'gray' }}
            disabled={disableLock}
            endIcon={
              lockRef.current.length > 0 && files.length > 0 ? (
                lockRef.current.length === files.length ? (
                  <LockRounded fontSize="small" />
                ) : (
                  <LockOpenRounded fontSize="small" />
                )
              ) : (
                <LockOpenRounded fontSize="small" />
              )
            }
          >
            {lockRef.current.length === files.length ? 'Unselect All' : 'Select All'}
          </Button>
        </Grid>
      )}

      <Grid container item direction="column" justifyContent="flex-start">
        {files.map((file) => (
          <FileUploader
            onStart={onStart}
            key={file.id}
            file={file}
            upload={file.upload}
            onResult={sendResult}
            onCancel={removeFile}
            onEdit={updateFile}
            onLock={(d) => {
              onLock(d);
              const index = lockRef.current.findIndex((x) => x === d.file.id);
              if (index > -1 && !d.locked) {
                lockRef.current.splice(index, 1);
              } else {
                lockRef.current.push(d.file.id);
              }
              setBulkLock([...lockRef.current]);
            }}
            disableLock={disableLock}
            onPreview={(src) => {
              setSrc(src);
              setImageDialog(true);
            }}
            lock={lockRef.current?.includes(file.id)}
            documentCategory={documentCategory}
            showLock={showLock}
            encrypted={encrypted}
          />
        ))}
      </Grid>
      {(remainingFiles == null || remainingFiles > 0) && (
        <UploadDialog
          onlyImages={onlyImages}
          open={controlled ? dialogOpen : dialog}
          onClose={controlled ? onDialogClose : () => setDialog(false)}
          onFiles={addFiles}
          documentCategory={documentCategory}
          remainingFiles={remainingFiles}
          encrypted={encrypted}
          withLinkedFeatures={withLinkedFeatures}
        />
      )}
      <ImageDialog open={imageDialog} onClose={() => setImageDialog(false)} src={src} />
    </Grid>
  );
}

function FileUploader({
  file,
  upload = true,
  onResult,
  onCancel,
  onEdit,
  onLock = null,
  disableLock,
  onPreview,
  onStart,
  lock = false,
  showLock = false,
  encrypted = false,
}) {
  const pdfViewer = useSelector(({ profile }) => profile.user?.pdfViewer) || 'adobe';
  const [locked, setLocked] = useState(false);
  const [loaded, setLoaded] = useState(null);
  const [total, setTotal] = useState(null);
  const [cancelToken, setCancelToken] = useState(null);
  const [done, setDone] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [edit, setEdit] = useState(null);
  const [name, setName] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const result = useRef(null);

  const invitationId = localStorage.getItem('invitationId') || null;
  const externalUser = !localStorage.getItem('_id') && !localStorage.getItem('token') && invitationId;

  function cancel() {
    if (!result.current) {
      cancelToken.cancel();
      setCanceled(true);
      onCancel(file.id);
    } else {
      onCancel(file.id);
    }
  }

  useEffect(() => {
    if (!showLock) return;
    setLocked(lock);
  }, [lock, showLock]);

  useEffect(() => {
    if (upload === true && file.file && !result.current && !file.file.location && !file.file.link) {
      setLoaded(0);
      setTotal(file.file.size);
      setDone(false);
      setCanceled(false);

      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('id', file.id);
      formData.append('name', file.file.name);
      const token = axios.CancelToken.source();
      setCancelToken(token);

      const config = {
        onUploadProgress: (event) => {
          setLoaded(event.loaded);
          setTotal(event.total);
        },
        cancelToken: token.token,
        headers: { 'Content-Type': 'multipart/form-data', invitationid: invitationId },
      };

      axios
        .post(
          `${externalUser ? '/external' : ''}/uploader/${encrypted ? 'encryptedUpload' : 'upload'}`,
          formData,
          config,
        )
        .then(({ data }) => {
          result.current = data.file;
          setDone(true);
          if (!data?.file) return;
          const uploaded = { id: file.id, file: data.file, preview: data.preview, encrypted };
          onResult(uploaded);
          decryptFileForPreview(uploaded);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
        });
      if (onStart) {
        onStart({
          id: file.id,
          file: {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
          },
        });
      }
    }

    if ((file.file.location || file.file.link) && !result.current) {
      setLoaded(file.file.size);
      setTotal(file.file.size);
      setDone(true);
      if (upload) {
        onResult({ id: file.id, file: file.file });
      }
      result.current = file.file;
    }
  }, [file, upload]); // eslint-disable-line react-hooks/exhaustive-deps

  async function decryptFileForPreview(file) {
    if (!file?.encrypted || !file?.file?.location) return;
    setFileLoading(true);
    const url = await getDecryptedFile(file);
    setFileUrl(url);
    setFileLoading(false);
  }

  useEffect(() => {
    decryptFileForPreview(file);
  }, [file]);

  return (
    <Grid container item alignItems="center" wrap="nowrap" style={{ padding: '0.5rem 0' }}>
      <Grid item container xs={1}>
        {(() => {
          if (!done) {
            return (
              <Avatar sx={{ background: (theme) => theme.palette.primary.main }}>
                <DescriptionRounded />
              </Avatar>
            );
          } else {
            if (result.current.link) {
              return (
                <Link color="inherit" href={result.current.link} target="_blank">
                  <Avatar sx={{ background: (theme) => theme.palette.primary.main }}>
                    <LinkIcon />
                  </Avatar>
                </Link>
              );
            }
            if (Extensions.Image.includes(result.current?.mimetype)) {
              const location = fileUrl || result.current.location;
              if (fileLoading) {
                return <CircularProgress />;
              }
              return (
                <img
                  alt="img"
                  src={location}
                  style={{ maxHeight: '2.5rem', maxWidth: '2.5rem', marginRight: '0.8rem', cursor: 'pointer' }}
                  onClick={() => onPreview(location)}
                />
              );
            } else if (Extensions.PDF.includes(result.current?.mimetype)) {
              const location = fileUrl || result.current.location;
              return (
                <Tooltip title="Open file">
                  <IconButton
                    onClick={() => {
                      if (pdfViewer === 'external') {
                        StateManager.openPdfDialog(location, result.current?.originalname);
                        return;
                      }
                      openPDFDialog({ ...result.current, name: result.current?.originalname, location });
                    }}
                  >
                    {fileLoading ? <CircularProgress size={24} /> : <PictureAsPdfIcon color="primary" />}
                  </IconButton>
                </Tooltip>
              );
            } else {
              return (
                <Avatar sx={{ background: (theme) => theme.palette.primary.main }}>
                  <DescriptionRounded />
                </Avatar>
              );
            }
          }
        })()}
      </Grid>

      <Grid item container xs={7} alignItems="center">
        <Grid container item style={{ paddingBottom: 4 }}>
          {edit ? (
            <InputBase style={{ width: '80%' }} value={name} onChange={(event) => setName(event.target.value)} />
          ) : (
            <Typography noWrap>{name ? name : file.file.location ? file.file.originalname : file.file.name}</Typography>
          )}
        </Grid>
        <Box width="100%" mr={1}>
          <StyledLinearProgress
            variant="determinate"
            value={total != null ? Math.round((loaded * 100) / total) : 100}
          />
        </Box>
      </Grid>
      <Grid item container xs={4} alignItems="center" justifyContent="flex-end" wrap="nowrap">
        <Typography color="textSecondary" noWrap style={{ fontSize: 14 }}>
          {!done && !canceled && loaded != null && total != null && (
            <>{`${Math.round((loaded * 100) / total)}% (${formatBytes(loaded)}/${formatBytes(total)})`}</>
          )}
          {done && !canceled && <>{total ? `Done (${formatBytes(total)})` : 'Done'}</>}
          {canceled && <>Cancelled</>}
          {upload === false && !canceled && !done && <>Pending</>}
        </Typography>
        <Hidden smDown>
          {done && (
            <Tooltip title={edit ? 'Finish' : 'Edit file name'}>
              <IconButton
                onClick={() => {
                  if (edit) {
                    if (name) onEdit(file.id, name);
                    setEdit(null);
                  } else {
                    setName(file.file.location ? file.file.originalname : file.file.name);
                    setEdit(true);
                  }
                }}
                size="small"
                style={{ marginLeft: 3 }}
              >
                {edit ? (
                  <DoneRounded fontSize="small" style={{ color: grey[500] }} />
                ) : (
                  <EditRounded fontSize="small" style={{ color: grey[500] }} />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Hidden>
        {showLock && onLock && done && (
          <IconButton
            onClick={() => {
              // if (locked) setLock(file.id);
              setLocked(!locked);
              onLock({ locked: !locked, file: result.current });
            }}
            disabled={disableLock}
            style={{ marginLeft: 3 }}
            size="small"
          >
            {locked || lock ? <LockRounded fontSize="small" /> : <LockOpenRounded fontSize="small" />}
          </IconButton>
        )}
        <Tooltip title="Cancel">
          <IconButton onClick={cancel} size="small" style={{ marginLeft: 3 }}>
            <CloseRounded fontSize="small" style={{ color: grey[500] }} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

function UploadDialog({
  open,
  onClose,
  onFiles,
  onlyImages,
  documentCategory,
  remainingFiles,
  encrypted,
  withLinkedFeatures,
}) {
  const { user } = useSelector(({ profile }) => profile);
  const isPortalUser = user?.access === 'portal';
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const hideLinkPath = useRouteMatch('/DocLibrary');

  const [tab, setTab] = useState(0);
  const [timer, setTimer] = useState(null);
  const [files, setFiles] = useState([]);

  const [settings, setSettings] = useState(null);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const loadSettings = isEmpty(settings) && open;

  useEffect(() => {
    if (!loadSettings) return;

    setLoadingSettings(true);

    axios
      .post('/general/company/loadCompanySettings', {
        keys: ['uploaderWarning', 'uploaderWarningText', 'uploaderWarningFiles'],
      })
      .then(({ data }) => {
        setSettings(data.settings);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoadingSettings(false);
      });
  }, [loadSettings]);

  useEffect(() => {
    setFiles([]);
    setTab(0);

    if (open && settings?.uploaderWarning && settings?.uploaderWarningText) {
      setConfirmDialogOpen(true);
    }
  }, [open, settings]);

  // recent uploads
  const [uploads, setUploads] = useState([]);
  const [loadingRecentUploads, setLoadingRecentUploads] = useState(true);
  const loadRecentUploads = user && open && tab === 0 && isEmpty(uploads);

  useEffect(() => {
    if (!loadRecentUploads) return;
    axios
      .get('/uploader/getRecentUploads', { params: { withEncrypted: Boolean(encrypted) } })
      .then((res) => {
        setUploads(res.data.map((x) => ({ id: x._id, file: x.file, encrypted: x.encrypted })));
        setLoadingRecentUploads(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingRecentUploads(false);
      });
  }, [loadRecentUploads, encrypted]);

  // forms
  const [forms, setForms] = useState([]);
  const [formPattern, setFormPattern] = useState('');
  const [loadingForms, setLoadingForms] = useState(true);
  const [currentForm, setCurrentForm] = useState(null);
  const loadForms = open && forms.length === 0 && tab === 2;
  const filteredForms = forms.filter((x) => !formPattern || String(x.title).toLowerCase().includes(formPattern));

  useEffect(() => {
    if (!loadForms) return;
    setLoadingForms(true);
    axios
      .get('/forms/general/getFormsForSearch')
      .then(({ data }) => {
        setForms(data);
        setLoadingForms(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingForms(false);
      });
  }, [loadForms]);

  // documents
  const [docs, setDocs] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [docsPattern, setDocsPattern] = useState('');

  // audit templates
  /*const [auditTemplates, setAuditTemplates] = useState([]);
  const [templatePattern, setTemplatePattern] = useState('');
  const loadAuditTemplates = false && user && open && tab === 3 && isEmpty(auditTemplates);
  const filteredAuditTemplates = auditTemplates.filter(
    (x) => !templatePattern || x.file.name.toLowerCase().includes(templatePattern.toLowerCase()),
  );

  useEffect(() => {
    if (!loadAuditTemplates) return;
    axios
      .get('/audit/getAllTemplates')
      .then((res) => {
        setAuditTemplates(
          res.data.map((x) => ({
            id: x._id,
            file: { name: x.title, originalname: x.title, link: `/audit/template/${x._id}`, hub: 'auditTemplate' },
          })),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, [loadAuditTemplates]);*/

  // audits
  const [audits, setAudits] = useState([]);
  const [auditPattern, setAuditPattern] = useState('');
  const loadAudits = user && open && tab === 3 && isEmpty(audits);
  const filteredAudits = audits.filter(
    (x) => !auditPattern || x.file.name.toLowerCase().includes(auditPattern.toLowerCase()),
  );

  useEffect(() => {
    if (!loadAudits) return;
    axios
      .get('/audits/getUserAudits')
      .then((res) => {
        setAudits(
          res.data.map((x) => ({
            id: x._id,
            file: { name: x.title, originalname: x.title, link: `/audits/${x._id}`, hub: 'audit' },
          })),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, [loadAudits]);

  function addFile(file) {
    if (remainingFiles != null && files.length >= remainingFiles) return;
    if (files.some((x) => x.id === file.id)) return;

    setFiles([...files, file]);
  }

  function addDocAsFile(file) {
    let data = {
      id: file.id,
      file: { ...file.file },
    };
    delete data.file.link;
    addFile(data);
  }

  function addDocAsLink(file) {
    let data = {
      id: file.id,
      file: {
        hub: 'doc',
        link: file.file.link,
        name: file.file.originalname,
        originalname: file.file.originalname,
        encrypted: file.file.encrypted,
      },
    };
    addFile(data);
  }

  //links
  function handleCreate(data) {
    if (data.new) {
      const res = data.new.map((link) => {
        let url = `/${link.targetType}${
          link.targetType === 'asset' ||
          link.targetType === 'asset/profile' ||
          link.targetType === 'portal/management/space'
            ? ''
            : link.targetType === 'process'
            ? 'es'
            : 's'
        }/${link.targetItem}`;

        if (link.targetType === 'docFolder') {
          url = `/DocLibrary/areas/${link.parentId}/${link.targetItem}`;
        }
        const hubs = {};
        return {
          id: link.targetItem,
          file: {
            hub: link.targetType,
            link: url,
            name: link.title,
            originalname: link.title,
            icon: link.icon,
          },
        };
      });

      setFiles((prev) => {
        const filteredRes = res.filter((newLink) => !prev.some((existingLink) => existingLink.id === newLink.id));
        return [...prev, ...filteredRes];
      });
    }

    if (data.deletedItems) {
      setFiles((prev) => prev.filter((link) => !data.deletedItems.some((deletedItem) => deletedItem._id === link._id)));
    }
  }

  function sendFiles() {
    onFiles(files);
    onClose();
  }

  function handleDocSearch(e) {
    const val = e.target.value;
    setDocsPattern(val);
    if (timer !== null) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        if (val && val.length > 2) {
          setLoadingDocs(true);
          axios
            .get('/dochub/search/docs', { params: { query: val, limit: 7, category: documentCategory } })
            .then((res) => {
              let data = res.data;
              data.forEach((doc) => {
                if (doc.category === 'normal') {
                  doc.file.link = `/DocLibrary/preview/${doc._id}`;
                  doc.file.encrypted = doc.encrypted;
                  doc.file.name = doc.file.name || doc.title;
                  doc.file.originalname = doc.file.originalname || doc.title;
                }
                if (doc.category === 'qhub') {
                  doc.file = {
                    name: doc.title,
                    originalname: doc.title,
                    link: `/DocLibrary/preview/${doc._id}`,
                  };
                }
              });
              if (hideLinkPath) {
                data = data.filter((d) => d.category === 'normal');
              }
              // to attach the file id to the document
              data = data.filter((x) => x.file).map((x) => ({ id: x.file.id, file: x.file, category: x.category }));
              setDocs(data);
              setLoadingDocs(false);
            })
            .catch((err) => {
              StateManager.setAxiosErrorAlert(err);
              setLoadingDocs(false);
            });
        }
      }, 500),
    );
  }

  return (
    <RoundedDialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      className="scroll-bar"
      fullScreen={!largeDevices}
      titleId="upload-dialog"
    >
      <DialogTitle id={'upload-dialog'} style={{ cursor: 'move' }}>
        <Grid container>
          <Typography variant="h5" style={{ margin: 'auto' }}>
            Upload files
          </Typography>
          <CrossButton onClick={onClose} />
        </Grid>
      </DialogTitle>

      <DialogContent style={{ padding: '0 1rem' }}>
        <ConfirmDialog
          open={confirmDialogOpen}
          onClose={() => {
            setConfirmDialogOpen(false);
            onClose();
          }}
          onConfirm={() => setConfirmDialogOpen(false)}
          settings={settings}
        />
        {loadingSettings ? (
          <Grid container style={{ height: '50vh' }}>
            <CircularProgress color="primary" style={{ margin: 'auto' }} />
          </Grid>
        ) : (
          <>
            {user && !isPortalUser ? (
              <Grid container direction={'row'} style={{ border: '1px solid lightgray' }}>
                <Grid container item xs={12} sm={4} style={{ borderRight: '1px solid lightgray', padding: '1rem' }}>
                  <Hidden smUp>
                    <Grid container direction="row" justifyContent="space-between">
                      <IconButton onClick={() => setTab(0)}>
                        <DescriptionRounded />
                      </IconButton>
                      <IconButton onClick={() => setTab(1)}>
                        <DocIcon />
                      </IconButton>
                      <IconButton onClick={() => setTab()}>
                        <FormIcon />
                      </IconButton>
                      {/*<IconButton onClick={() => setTab(3)}>
                    <WorkOutline style={{ color: '#00bfa5' }} />
                  </IconButton>*/}
                      <IconButton onClick={() => setTab(3)}>
                        <AuditIcon />
                      </IconButton>
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <List style={{ width: '100%' }}>
                      <ListItemButton onClick={() => setTab(0)}>
                        <ListItemIcon>
                          <DescriptionRounded />
                        </ListItemIcon>
                        <ListItemText primary="Upload file" />
                      </ListItemButton>
                      {!onlyImages && (
                        <>
                          <ListItemButton onClick={() => setTab(1)}>
                            <ListItemIcon>
                              <DocIcon />
                            </ListItemIcon>
                            <ListItemText primary="Doc Library" />
                          </ListItemButton>
                          <ListItemButton onClick={() => setTab(2)}>
                            <ListItemIcon>
                              <FormIcon />
                            </ListItemIcon>
                            <ListItemText primary="Form entry" />
                          </ListItemButton>
                          {/*<ListItem button onClick={() => setTab(3)}>
                        <ListItemIcon>
                          <WorkOutline style={{ color: '#00bfa5' }} />
                        </ListItemIcon>
                        <ListItemText primary="Audit template" />
                  </ListItem>*/}
                          <ListItemButton onClick={() => setTab(3)}>
                            <ListItemIcon>
                              <AuditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Audits" />
                          </ListItemButton>
                          {withLinkedFeatures && (
                            <ListItemButton onClick={() => setTab(4)}>
                              <ListItemIcon>
                                <AddLinkIcon />
                              </ListItemIcon>
                              <ListItemText primary="Link feature" />
                            </ListItemButton>
                          )}
                        </>
                      )}
                    </List>
                  </Hidden>
                </Grid>
                <Grid container item direction="column" xs={12} sm={8}>
                  {tab === 0 && (
                    <Grid item style={{ padding: '1rem' }}>
                      <DropzoneComponent
                        onlyImages={onlyImages}
                        onResult={(items) => setFiles([...items.map((f) => ({ id: hex24(), file: f })), ...files])}
                        remainingFiles={remainingFiles}
                      />
                      <Typography variant="h6" style={{ margin: '0.3rem 1rem' }}>
                        Recent uploads
                      </Typography>
                      <Grid container style={{ overflowY: 'auto', height: '28vh' }}>
                        {loadingRecentUploads ? (
                          <CircularProgress style={{ margin: 'auto' }} />
                        ) : (
                          <List style={{ width: '100%' }}>
                            {uploads
                              .filter((file) => !onlyImages || file.file.type?.startsWith('image'))
                              .filter((file) => (encrypted ? file : !file.encrypted))
                              .map((upload) => (
                                <ListItem key={upload.id} button onClick={() => addFile(upload)}>
                                  <ListItemAvatar>
                                    <Avatar style={{ background: blue[500] }}>
                                      <DescriptionRounded />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={upload.file.originalname}
                                    secondary={formatBytes(upload.file.size)}
                                    style={{ overflowWrap: 'anywhere' }}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {tab === 1 && (
                    <Grid item style={{ padding: '1rem' }}>
                      <Grid container item>
                        <Typography variant="h6" gutterBottom>
                          Documents
                        </Typography>
                      </Grid>
                      <Grid container item>
                        <Search>
                          <SearchIconWrapper>
                            <SearchRounded />
                          </SearchIconWrapper>
                          <StyledInputBase
                            placeholder="Search for a document"
                            autoFocus
                            value={docsPattern}
                            fullWidth
                            onChange={handleDocSearch}
                          />
                        </Search>
                      </Grid>

                      <Grid container style={{ overflow: 'auto', height: '36vh' }}>
                        {loadingDocs ? (
                          <CircularProgress style={{ margin: 'auto' }} />
                        ) : isEmpty(docs) ? (
                          <Grid
                            container
                            alignItems={'center'}
                            justifyContent="center"
                            sx={{ textAlign: 'center', height: '100%' }}
                          >
                            <Typography color={'textSecondary'}>
                              {docsPattern.length > 2
                                ? 'No documents match your search'
                                : 'Type document title in the above bar to search'}
                            </Typography>
                          </Grid>
                        ) : (
                          <List style={{ width: '100%' }}>
                            {docs.map((upload, i) => (
                              <>
                                <ListItem key={upload.id}>
                                  <ListItemAvatar>
                                    <FileIconAvatar document={upload} />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={upload.file.originalname}
                                    secondary={upload.category === 'normal' && formatBytes(upload.file.size)}
                                    style={{ overflowWrap: 'anywhere' }}
                                  />

                                  {!hideLinkPath && (
                                    <Tooltip title="Attach as a link" placement="top">
                                      <IconButton onClick={() => addDocAsLink(upload)}>
                                        <LinkIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}

                                  {upload.file.location && (
                                    <Tooltip title="Attach as a file" placement="top">
                                      <IconButton onClick={() => addDocAsFile(upload)}>
                                        <FileCopyIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </ListItem>
                                {i !== docs.length - 1 && <Divider light />}
                              </>
                            ))}
                          </List>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {tab === 2 && (
                    <Grid container style={{ padding: '1rem' }}>
                      <Grid container item>
                        <Typography gutterBottom variant="h6">
                          Forms
                        </Typography>
                      </Grid>
                      <Grid container item>
                        <Search>
                          <SearchIconWrapper>
                            <SearchRounded />
                          </SearchIconWrapper>
                          <StyledInputBase
                            placeholder="Search for a form"
                            autoFocus
                            fullWidth
                            value={formPattern}
                            onChange={(event) => setFormPattern(String(event.target.value).toLowerCase())}
                          />
                        </Search>
                      </Grid>

                      <Grid container item style={{ overflow: 'auto', height: '36vh' }}>
                        {filteredForms.length === 0 && !loadingForms && formPattern && (
                          <Typography variant="h6" color="textSecondary" style={{ margin: 'auto' }}>
                            No forms match your search
                          </Typography>
                        )}
                        {loadingForms ? (
                          <CircularProgress color="primary" style={{ margin: 'auto' }} />
                        ) : (
                          <List style={{ width: '100%' }}>
                            {filteredForms.map((form) => (
                              <ListItemButton
                                style={{ borderRadius: 5 }}
                                key={form.id}
                                onClick={() => setCurrentForm(form)}
                              >
                                <ListItemIcon>
                                  <FormIcon />
                                </ListItemIcon>
                                <ListItemText primary={form.title} style={{ overflowWrap: 'anywhere' }} />
                              </ListItemButton>
                            ))}
                          </List>
                        )}
                      </Grid>
                      <SelectEntryDialog
                        form={currentForm}
                        open={Boolean(currentForm)}
                        onClose={() => setCurrentForm(null)}
                        onResult={addFile}
                      />
                    </Grid>
                  )}
                  {/*tab === 3 && (
                <Grid item style={{ padding: '1rem' }}>
                  <Grid container item>
                    <Typography gutterBottom variant="h6">
                      Audit templates
                    </Typography>
                  </Grid>
                  <Grid container item>
                    <Search>
                      <SearchIconWrapper>
                        <SearchRounded />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search for an audit template"
                        autoFocus
                        fullWidth
                        value={templatePattern}
                        onChange={(event) => setTemplatePattern(String(event.target.value).toLowerCase())}
                      />
                    </Search>
                  </Grid>

                  <Grid container style={{ overflow: 'auto', height: '36vh' }}>
                    <List style={{ width: '100%' }}>
                      {filteredAuditTemplates.map((upload, i) => (
                        <>
                          <ListItemButton key={upload.id} onClick={() => addFile(upload)}>
                            <ListItemIcon>
                              <WorkOutline style={{ color: '#00bfa5' }} />
                            </ListItemIcon>
                            <ListItemText primary={upload.file.name} />
                          </ListItemButton>
                          {i !== auditTemplates.length - 1 && <Divider light />}
                        </>
                      ))}
                    </List>
                  </Grid>
                </Grid>
                      )*/}
                  {tab === 3 && (
                    <Grid item style={{ padding: '1rem' }}>
                      <Grid container item>
                        <Typography gutterBottom variant="h6">
                          Audits
                        </Typography>
                      </Grid>
                      <Grid container item>
                        <Search>
                          <SearchIconWrapper>
                            <SearchRounded />
                          </SearchIconWrapper>
                          <StyledInputBase
                            placeholder="Search for an audit"
                            autoFocus
                            fullWidth
                            value={auditPattern}
                            endAdornment={<SearchRounded style={{ color: grey[500] }} />}
                            onChange={(event) => setAuditPattern(String(event.target.value).toLowerCase())}
                          />
                        </Search>
                      </Grid>

                      <Grid container style={{ overflow: 'auto', height: '36vh' }}>
                        <List style={{ width: '100%' }}>
                          {filteredAudits.map((upload, i) => (
                            <>
                              <ListItemButton key={upload.id} onClick={() => addFile(upload)}>
                                <ListItemIcon>
                                  <AuditIcon />
                                </ListItemIcon>
                                <ListItemText primary={upload.file.name} />
                              </ListItemButton>
                              {i !== audits.length - 1 && <Divider light />}
                            </>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  )}
                  {tab === 4 && (
                    <Grid container sx={{ paddiing: 2 }}>
                      <RefLinkDialog handleCreate={handleCreate} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container style={{ border: '1px solid lightgray', height: '15vh' }}>
                <DropzoneComponent
                  onlyImages={onlyImages}
                  onResult={(items) => setFiles([...items.map((f) => ({ id: hex24(), file: f })), ...files])}
                  remainingFiles={remainingFiles}
                />
              </Grid>
            )}

            <Grid direction="column" container style={{ border: '1px solid lightgray', borderTop: 'none' }}>
              <Typography variant="h6" style={{ margin: '0.4rem 1rem' }}>
                Uploading files
                {remainingFiles != null
                  ? ` (you can select up to ${remainingFiles} file${remainingFiles === 1 ? '' : 's'})`
                  : ''}
                :
              </Typography>
              <Grid
                style={{
                  height: '18vh',
                  overflow: 'auto',
                  padding: '0.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {files.map((file) => (
                  <Chip
                    style={{ margin: '0.3rem' }}
                    key={file.id}
                    icon={file.file.icon ? file.file.icon : <DescriptionRounded />}
                    label={file.file.location ? file.file.originalname : file.file.name}
                    onDelete={() => setFiles(files.filter((x) => x.id !== file.id))}
                    color="primary"
                  />
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>

      <StandardDialogActions onClose={onClose} onDone={sendFiles} doneButtonText="upload" />
    </RoundedDialog>
  );
}

function DropzoneComponent({ onResult, onlyImages, dense, maxFiles }) {
  const theme = useTheme();

  return (
    <div style={{ width: '100%' }}>
      <Dropzone
        accept={onlyImages ? ImageAccept : undefined}
        onDrop={(acceptedFiles) => onResult(acceptedFiles)}
        maxFiles={maxFiles}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div
                style={{
                  height: '50%',
                  border: 'medium dashed',
                  borderColor: theme.palette.primary.main,
                  padding: dense ? '1rem 0.5rem' : '2rem 1rem',
                  margin: dense ? '0.5rem' : '1rem',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <Typography style={{ margin: 'auto', color: theme.palette.primary.main, fontSize: dense ? 16 : 18 }}>
                    Drop files here or click to select {_.isNumber(maxFiles) ? `up to ${maxFiles} files` : ''}
                  </Typography>
                </div>
                {onlyImages && (
                  <div style={{ display: 'flex' }}>
                    <Typography
                      style={{ margin: 'auto', color: theme.palette.primary.main, fontSize: dense ? 16 : 18 }}
                    >
                      (Only images will be accepted)
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

function SelectEntryDialog({ form, open, onClose, onResult }) {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!form?.id) return;
    setLoading(true);
    axios
      .post('/forms/entries/getFormEntriesTable', { formId: form.id, pageSize: 100, state: 'completed' })
      .then(({ data }) => {
        setEntries(data.entries);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setErrorAlert('Failed to load form entries');
        console.error(err);
      });
  }, [form]);

  function attach(entry) {
    axios
      .get('/forms/entries/formToPdf', { params: { entryId: entry._id } })
      .then((res) => {
        onResult({
          id: entry._id,
          file: {
            location: `${BASE_URL}${res.data.link}`,
            originalname: `${form.title} - entry #${entry.number}`,
            name: `${form.title} - entry #${entry.number}`,
            size: 1024 * 1024,
            type: 'application/pdf',
            mimetype: 'application/pdf',
          },
        });
        onClose();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function attachLink(entry) {
    onResult({
      id: entry._id,
      file: {
        originalname: `${form.title} - entry #${entry.number}`,
        name: `${form.title} - entry #${entry.number}`,
        link: `/forms/entry/${entry._id}`,
        hub: 'form',
      },
    });
    onClose();
  }

  return (
    <RoundedDialog maxWidth="md" fullWidth open={open} onClose={onClose} className="scroll-bar">
      <DialogTitle>Attach form entry</DialogTitle>
      <DialogContent dividers>
        {entries.length === 0 && !loading && (
          <Typography variant="h6" color="textSecondary" style={{ margin: '3rem' }}>
            This form has no entries yet
          </Typography>
        )}
        {loading ? (
          <Grid container style={{ height: '60vh' }}>
            <CircularProgress color="primary" style={{ margin: 'auto' }} />
          </Grid>
        ) : (
          <TableContainer style={{ maxHeight: '60vh' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Form version</TableCell>
                  <TableCell>Submitted</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.map((entry) => (
                  <TableRow>
                    <TableCell>{entry.number}</TableCell>
                    <TableCell>V{entry.version}</TableCell>
                    <TableCell>{FormatDateShort(entry.completedAt)}</TableCell>

                    <TableCell>
                      <GeneralButton
                        onClick={() => window.open(`/forms/entry/${entry._id}`, '_blank')}
                        startIcon={<VisibilityRounded style={{ color: grey[500] }} />}
                      >
                        Preview
                      </GeneralButton>
                    </TableCell>
                    <TableCell align="center">
                      <GeneralButton
                        onClick={() => attach(entry)}
                        startIcon={<FileCopyIcon style={{ color: grey[500] }} />}
                      >
                        Attach PDF
                      </GeneralButton>
                    </TableCell>
                    <TableCell align="center">
                      <GeneralButton
                        onClick={() => attachLink(entry)}
                        startIcon={<LinkIcon style={{ color: grey[500] }} />}
                      >
                        Attach Link
                      </GeneralButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
