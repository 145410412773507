import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Grid, CircularProgress, Paper, Typography, Button, MenuItem } from '@mui/material';
import { FormControlLabel, Collapse, useTheme } from '@mui/material';
import { ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { IconButton, Menu, Hidden, Switch, DialogContent } from '@mui/material';
import { FiberManualRecord, CheckCircleOutlineOutlined, Person, Flag, HighlightOffRounded } from '@mui/icons-material';
import { MoreHoriz, InfoOutlined, AddCircleOutline, DeleteOutline, Edit, LabelOutlined } from '@mui/icons-material';
import { WorkOutline, ExpandMore, NotificationsActiveRounded, CheckCircleRounded } from '@mui/icons-material';
import { CheckCircleOutlineRounded, DescriptionRounded, TimelineRounded, CallSplit } from '@mui/icons-material';
import { NotificationsRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { User, HtmlContent, Approval, UserGroup, RoundedDialog } from '../../../Global/Components';
import { StandardDialogActions, DatePicker, TimePicker } from '../../../Global/Components';
import { RequiredIcon, TaskIcon, FormIcon, ProcessIcon } from '../../../Global/Icons';
import { blue, grey, green, red } from '@mui/material/colors';
import StateManager from '../../../Global/StateManager';
import { FormatDate, isHtml, highlightElementError } from '../../../Global/Functions';
import { StatusButton } from '../../tasks/Components';
import CommentsSection from '../../../Global/CommentsSection';
import AdditionalFieldsDialog from '../components/AdditionalFieldsDialog';
import StepInfo from '../components/StepInfo';
import Field from '../../../Global/Fields/Field';
import StepNotificationsDialog from '../components/StepNotificationsDialog';
import NextStepDialog from '../components/NextStep';
import ApprovalDialog from '../components/ApprovalDialog';
import StepForms from './StepForms';
import moment from 'moment';
import axios from 'axios';
import { isArray, isEmpty, cloneDeep, isEqual } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import Uploader from '../../../Global/Uploader';
import FileViewer from '../../../Global/FileViewer';
import { styled } from '@mui/material/styles';

const DecisionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(8),
  padding: theme.spacing(1.25, 2.25),
  marginRight: theme.spacing(3),
  fontSize: 20,
  textTransform: 'none',
}));

export default function Step({
  step,
  onComplete,
  user,
  process,
  largeDevices,
  onCalculationUpdate,
  calculatedItems,
  hiddenItems,
  allCreatedActions,
  allExecutedActions,
  onExecutedActionDelete,
  onCreatedAction,
  onDeletedAction,
  onStepsUpdate,
  isLastStep,
  isFirstStep,
  editMode,
  involvedUsers,
}) {
  const [editable, setEditable] = useState(false);
  const [completedAt, setCompletedAt] = useState(false);
  const [completedBy, setCompletedBy] = useState(false);
  const [saving, setSaving] = useState(false);
  const [nextStepDialog, setNextStepDialog] = useState(false);
  const [decisionId, setDecisionId] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [approval, setApproval] = useState();
  const [menuAnchor, setMenuAnchor] = useState();
  const [infoOpen, setInfoOpen] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [additionalFieldsDialog, setAdditionalFieldsDialog] = useState(false);
  const [comments, setComments] = useState([]);
  const [additionalTasks, setAdditionalTasks] = useState([]);
  const [approvalUsersDialog, setApprovalUsersDialog] = useState(false);
  const [nextStepInfo, setNextStepInfo] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [dueAt, setDueAt] = useState(false);
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [notificationsDialog, setNotificationsDialog] = useState(false);
  const [owners, setOwners] = useState([]);
  const [items, setItems] = useState([]);

  const theme = useTheme();
  const fileTimer = useRef(null);
  const decisionRef = useRef();

  const defaultDueDate = moment().add(1, 'week').set({ minutes: 0, seconds: 0, milliseconds: 0 });
  const FILES_SAVING_TIMEOUT = 1200;
  const admin = user?.access === 'admin';

  useEffect(() => {
    if (!isArray(calculatedItems) || isEmpty(calculatedItems)) return;

    let modified = false;

    // essential to prevent side effects
    const copy = cloneDeep(items);

    // to prevent unnecessary forced updates
    copy.forEach((item) => {
      if (item.table?.forceUpdate) {
        delete item.table.forceUpdate;
      }
    });

    for (let i = 0; i < calculatedItems.length; ++i) {
      if (calculatedItems[i].isTable) {
        // tables may need to be updated
        const itemIndex = copy.findIndex(({ id }) => calculatedItems[i].itemId === id);
        if (itemIndex === -1 || !copy[itemIndex].table) continue;

        copy[itemIndex].table.rows = calculatedItems[i].rows;
        copy[itemIndex].table.forceUpdate = true;
        modified = true;
      } else {
        // calculation items are updated
        const itemIndex = copy.findIndex(({ id }) => calculatedItems[i].fieldId === id);
        if (itemIndex === -1) continue;
        if (!isEqual(copy[itemIndex].filledValue, calculatedItems[i].value)) {
          copy[itemIndex].filledValue = calculatedItems[i].value;
          modified = true;
        }
      }
    }

    if (modified) {
      setItems(copy);
    }
  }, [calculatedItems]); // eslint-disable-line

  const userStepOwner = isUserStepOwner();
  const userApprover = isUserApprover();

  const canUserEditStep = useMemo(() => {
    if (!process || !user || !step) return false;

    if (process.cancelledAt || process.heldAt) {
      return false;
    }

    // admins can edit anything
    if (user.access === 'admin') return true;

    const editableSettings = process.editableSettings || {};

    if (process.completedAt || ['editing', 'upissuing'].includes(process.state)) {
      const involvedPassed =
        editableSettings.completedEditing?.involved && (userStepOwner || involvedUsers?.includes(user._id));

      const assignedPassed = editableSettings.completedEditing?.assigned && userStepOwner;

      const ownersPassed =
        editableSettings.completedEditing?.owners &&
        (process.processOwner === user._id || process.coOwners?.includes(user._id));

      return involvedPassed || assignedPassed || ownersPassed;
    } else {
      const involvedPassed = editableSettings.inProgressEditing?.involved && involvedUsers?.includes(user._id);

      const assignedPassed = editableSettings.inProgressEditing?.assigned && userStepOwner;

      const ownersPassed =
        editableSettings.inProgressEditing?.owners &&
        (process.processOwner === user._id || process.coOwners?.includes(user._id));

      return involvedPassed || assignedPassed || ownersPassed;
    }
  }, [process, user, step, userStepOwner, involvedUsers]);

  const greyOut = (!canUserEditStep && !userStepOwner && !completedAt && !userApprover) || process?.cancelledAt;

  useEffect(() => {
    if (!step) return;
    setItems(step.items);
    setCompletedAt(step.completedAt);
    setCompletedBy(step.completedBy);

    // by default editable if not completed and not submitted
    const stepEditable = (!step.completedAt && !step.submittedAt && userStepOwner) || (editMode && canUserEditStep);
    setEditable(stepEditable);
    setSubmitted(Boolean(step.submittedAt));
    if (step.decisionId) {
      setDecisionId(step.decisionId);
      decisionRef.current = step.decisionId;
    }
    if (step.approval) {
      setApproval(step.approval);
    }
    setAdditionalFields(step.additionalFields || []);
    setComments(step.comments || []);
    setUploadedFiles(step.uploadedFiles || []);
    const stepOwners = getStepOwners(step);
    setOwners(stepOwners);
    setAdditionalTasks(step.tasks || []);
    const open = process.stepsOpen === 'incomplete' ? !step.completedAt : userStepOwner || (stepEditable && editMode);
    setExpanded(open || userApprover);
    setDueAt(step.dueAt);
  }, [step, user, process, editMode]); // eslint-disable-line

  function onFilesUploaded(files) {
    setUploadedFiles(files);
    if (fileTimer.current != null) {
      clearTimeout(fileTimer.current);
    }
    fileTimer.current = setTimeout(() => saveUploadedFiles(files), FILES_SAVING_TIMEOUT);
  }

  function saveUploadedFiles(files) {
    const body = {
      ongoingStepId: step.ongoingStepId,
      files,
    };
    axios.post('/process/entries/saveUploadedFiles', body).catch((err) => {
      StateManager.setAxiosErrorAlert(err);
    });
  }

  function checkToSubmit() {
    setSaving(true);
    axios
      .post('/process/entries/checkStep', {
        ongoingStepId: step.ongoingStepId,
        decisionId: decisionRef.current,
        forApproval: true,
      })
      .then((res) => {
        setSaving(false);
        if (res.data.approvalId) {
          submitStep();
        } else {
          setApprovalUsersDialog(true);
        }
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
        // highlight error item
        const itemId = err?.response?.data?.errorData?.itemId;
        if (itemId) {
          highlightElementError(itemId);
        }
      });
  }

  function checkToComplete(decision) {
    setSaving(true);
    axios
      .post('/process/entries/checkStep', { ongoingStepId: step.ongoingStepId, decisionId: decisionRef.current })
      .then((res) => {
        setSaving(false);
        checkNextStep(res.data);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);

        // highlight error item
        const itemId = err?.response?.data?.errorData?.itemId;
        if (itemId) {
          highlightElementError(itemId);
        }
      });
  }

  function submitStep(params) {
    setSaving(true);
    const body = {
      ongoingStepId: step.ongoingStepId,
      users: params?.users,
      approvalDue: params?.dueAt,
      decisionId: decisionRef.current,
    };
    axios
      .post('/process/submitStep', body)
      .then((res) => {
        if (res.data.approval) setApproval(res.data.approval);
        StateManager.setSuccessAlert('Step has been submitted');
        setSaving(false);
        setEditable(false);
        setSubmitted(true);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        // highlight error item
        const itemId = err?.response?.data?.errorData?.itemId;
        if (itemId) {
          highlightElementError(itemId);
        }
        setSaving(false);
      });
  }

  function checkNextStep(nextStepInfo) {
    if (step.type === 'final' || nextStepInfo.nextStepExternal) {
      if (isEmpty(nextStepInfo.completeActions) && isEmpty(nextStepInfo.startActions)) {
        completeStep();
      } else {
        setNextStepInfo({ final: true, completeActions: nextStepInfo.completeActions });
        setNextStepDialog(true);
      }
      return;
    }

    const info = {
      nextStep: nextStepInfo.nextStep,
      presetTime: nextStepInfo.presetTime,
      timePickable: nextStepInfo.timePickable,
      nextStepUsers: nextStepInfo.nextStepUsers?.users,
      nextStepGroups: nextStepInfo.nextStepUsers?.groups,
      usersPickable: nextStepInfo.nextStepUsers?.pickable,
      nextStepResolvedUsers: nextStepInfo.nextStepUsers?.resolvedUsers,
      nextStepResolvedGroups: nextStepInfo.nextStepUsers?.resolvedGroups,
      onlyPickFromGroups: nextStepInfo.nextStepUsers?.onlyPickFromGroups,
      completeActions: nextStepInfo.completeActions,
      startActions: nextStepInfo.startActions,
      nextStepEnd: nextStepInfo.nextStepEnd,
      endOption: nextStepInfo.endOption,
    };

    if (!info.usersPickable && !info.timePickable && isEmpty(info.completeActions) && isEmpty(info.startActions)) {
      // if the user cannot choose nether time nor user, and there is no actions - send automatically
      completeStep({ users: info.nextStepUsers, dueDate: info.presetTime });
    } else {
      // otherwise let them pick time/users
      setNextStepInfo(info);
      setNextStepDialog(true);
    }
  }

  function completeStep(params) {
    setSaving(true);
    const body = {
      ongoingStepId: step.ongoingStepId,
      selectedUsers: params?.users,
      decisionId: decisionRef.current,
      nextStepDueDate: params?.dueDate,
      nextProcessUserId: params?.nextProcessUserId,
      startNextProcess: params?.startNextProcess,
      completeActionsParams: params?.completeActionsParams,
      startActionsParams: params?.startActionsParams,
    };
    axios
      .post('/process/completeStep', body)
      .then((res) => {
        StateManager.setSuccessAlert('Step has been completed');
        setSaving(false);
        setCompletedAt(new Date());
        setCompletedBy(localStorage.getItem('_id'));
        setEditable(false);
        onComplete({ completedStep: step, result: res.data });
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function saveAdditionalFields(fields) {
    setAdditionalFields(fields);
    let body = {
      ongoingStepId: step.ongoingStepId,
      additionalFields: fields,
    };
    axios.post('/process/saveAdditionalFields', body).catch(() => {
      StateManager.setErrorAlert('Failed to save additional fields');
    });
  }

  function saveComments(value) {
    setComments(value);
    let body = {
      ongoingStepId: step.ongoingStepId,
      comments: value,
    };
    axios.post('/process/saveStepComments', body).catch(() => {
      StateManager.setErrorAlert('Failed to save comments');
    });
  }

  function onSave(itemId, value) {
    const body = {
      ongoingStepId: step.ongoingStepId,
      itemId,
      value,
    };
    axios
      .post('/process/entries/saveOngoingStepField', body)
      .then(({ data }) => {
        const index = items.findIndex(({ id }) => id === itemId);
        if (index === -1) return;
        items[index].filledValue = value;
        onCalculationUpdate(data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function onTableSave(itemId, value) {
    const body = {
      ongoingStepId: step.ongoingStepId,
      itemId,
      value,
      isTable: true,
    };
    axios
      .post('/process/entries/saveOngoingStepField', body)
      .then(({ data }) => {
        if (!isArray(data.rows) || !isArray(data.affectedItemsIds) || isEmpty(data.affectedItemsIds)) return;

        const copy = cloneDeep(items);

        // include the current item - need to update it
        const index = copy.findIndex((x) => x.id === itemId);

        if (index > -1) {
          copy[index].table.rows = data.rows;
        }

        if (!isArray(data.rows) || !isArray(data.affectedItemsIds) || isEmpty(data.affectedItemsIds)) return;

        // need to force update the tables, otherwise anly calculation columns will be updated
        copy.forEach((item) => {
          if (item.table?.forceUpdate) {
            delete item.table.forceUpdate;
          }
        });

        const calculated = [];

        for (let i = 0; i < data.affectedItemsIds.length; ++i) {
          const index = copy.findIndex((x) => x.id === data.affectedItemsIds[i]);
          if (index === -1) {
            // not found in items in the step - send as calculated items
            calculated.push({
              itemId: data.affectedItemsIds[i],
              isTable: true,
              rows: data.rows,
            });

            continue;
          }

          // something's wrong
          if (!copy[index].table) {
            continue;
          }

          copy[index].table.rows = data.rows;

          if (data.affectedItemsIds[i] !== itemId) {
            copy[index].table.forceUpdate = true;
          }
        }

        if (!isEmpty(calculated)) {
          onCalculationUpdate({ calculated });
        }

        setItems(copy);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function changeOwner(res) {
    if (!res.users) return;
    setSaving(true);
    const body = {
      ongoingStepId: step.ongoingStepId,
      newOwners: res.users,
    };
    axios
      .post('/process/changeStepOwner', body)
      .then((res) => {
        setOwners(res.data.owners);
        setSaving(false);
        StateManager.setSuccessAlert('Owners have been changed');
      })
      .catch(() => {
        setSaving(false);
        StateManager.setErrorAlert('Failed to change the owners');
      });
  }

  function selectNewOwner() {
    StateManager.selectMultipleUsers(changeOwner, { title: 'Select step owners', initiallySelected: owners });
  }

  function addTask(task) {
    if (!task) return;
    setAdditionalTasks([...additionalTasks, task]);
    const body = {
      ongoingStepId: step.ongoingStepId,
      taskId: task._id,
    };
    axios.post('/process/addStepTask', body).catch(() => {
      StateManager.setErrorAlert('Failed to add a task');
    });
  }

  function makeTaskRequired(taskId) {
    const index = additionalTasks.findIndex((x) => x._id === taskId);
    if (index > -1) {
      additionalTasks[index].required = !Boolean(additionalTasks[index].required);
      setAdditionalTasks([...additionalTasks]);
      const body = {
        ongoingStepId: step.ongoingStepId,
        taskId,
      };
      axios.post('/process/makeStepTaskRequired', body).catch(() => {
        StateManager.setErrorAlert('Failed to save the task');
      });
    }
  }

  function remindOfTheStep() {
    axios
      .post('/process/entries/remindOfTheStep', { stepId: step.ongoingStepId })
      .then(() => {
        StateManager.setSuccessAlert('Reminder has been sent');
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function deleteTask(taskId) {
    StateManager.setConfirm('You are about to delete this task', () => {
      setAdditionalTasks([...additionalTasks.filter((x) => x._id !== taskId)]);
      const body = {
        ongoingStepId: step.ongoingStepId,
        taskId,
      };
      axios.post('/process/deleteStepTask', body).catch(() => {
        StateManager.setErrorAlert('Failed to delete the task');
      });
    });
  }

  function updateTask(task) {
    if (!task?._id) return;
    const index = additionalTasks.findIndex((x) => x._id === task._id);
    if (index === -1) return;
    additionalTasks[index] = task;
    setAdditionalTasks([...additionalTasks]);
  }

  function getStepOwners(ongoingStep) {
    if (ongoingStep.assignedUserId) return [ongoingStep.assignedUserId];
    return ongoingStep.owners || [];
  }

  function isUserStepOwner() {
    if (!process || !user || !step) return false;
    if (step.assignedUserId && step.assignedUserId === user._id) return true;
    if (step.owners?.includes(user._id)) return true;
    return false;
  }

  function isUserApprover() {
    if (!user || !step || !step.submittedAt) return false;
    if (step.approvalUsers?.includes(user._id)) return true;
    return false;
  }

  function confirmStepDelete() {
    StateManager.setConfirm('You are about to delete this step', deleteStep);
  }

  function deleteStep() {
    const body = { ongoingProcessId: process._id, stepId: step.ongoingStepId };
    axios
      .post('/process/deleteLastStep', body)
      .then(({ data }) => {
        onStepsUpdate(data.steps);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function selectDecision(selectedDecisionId) {
    setDecisionId(selectedDecisionId);
    decisionRef.current = selectedDecisionId;
    if (step.approvalNeeded) {
      checkToSubmit();
    } else {
      checkToComplete();
    }
  }

  const notNestedItems = useMemo(() => {
    return items.filter((x) => !x.conditionalFieldId && !hiddenItems.includes(x.id));
  }, [items, hiddenItems]);

  return (
    <Grid container item id={step._id} sx={{ mb: 4 }}>
      <Grid
        container
        component={Paper}
        key={step._id}
        elevation={3}
        sx={{ border: step.overdue && !completedAt ? `2px solid ${red[500]}` : '', p: 2, borderRadius: 1.5 }}
      >
        <Grid container sx={{ py: 1 }} alignItems="center">
          <Grid container item alignItems="center" lg={6} md={6} sm={12} wrap="nowrap">
            {!process.cancelledAt && (
              <>
                {(step.type === 'final' || step.type === 'end') && (
                  <Flag style={{ color: green[500], marginRight: '0.5rem' }} />
                )}
                {step.type === 'decision' && <CallSplit style={{ color: grey[700], marginRight: '0.5rem' }} />}
                {completedAt && <CheckCircleOutlineOutlined style={{ color: green[500] }} />}
                {!completedAt && <FiberManualRecord style={{ color: blue[500] }} />}
              </>
            )}
            {step.type === 'end' && process.cancelledAt && <HighlightOffRounded style={{ color: red[500] }} />}

            {step.title?.length < 40 && (
              <Typography variant="h5" noWrap style={{ marginLeft: '0.5rem', whiteSpace: 'break-spaces' }}>
                {step.title}
              </Typography>
            )}
          </Grid>

          {step.type !== 'end' && (
            <Grid
              container
              item
              alignItems="center"
              lg={6}
              md={6}
              sm={12}
              justifyContent={'flex-end'}
              style={{ marginTop: largeDevices ? 0 : 8 }}
              wrap="nowrap"
            >
              {!completedAt && dueAt && !process.cancelledAt && (
                <Typography
                  noWrap
                  color="textSecondary"
                  style={{ marginRight: '1rem', color: moment(dueAt) < moment() ? red[600] : '' }}
                >
                  {`Due ${FormatDate(dueAt)}`}
                </Typography>
              )}
              {completedAt && (
                <Typography noWrap style={{ marginRight: '1rem', fontSize: 14 }} color="textSecondary">
                  {`Completed ${FormatDate(completedAt)}`}
                </Typography>
              )}

              {completedAt ? (
                <User id={completedBy} onlyAvatar avatarSize={30} />
              ) : (
                <UserGroup ids={owners} dialogTitle="Step owners" onlyAvatar />
              )}

              <IconButton
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                sx={{
                  transform: 'rotate(0deg)',
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  transform: expanded ? 'rotate(180deg)' : null,
                }}
              >
                <ExpandMore />
              </IconButton>

              <IconButton
                style={{ height: 40, width: 40, marginLeft: 8 }}
                onClick={(e) => setMenuAnchor(e.currentTarget)}
              >
                <MoreHoriz />
              </IconButton>

              <Menu open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)} anchorEl={menuAnchor}>
                {(editable || user?._id === step.startedBy || admin) && !completedAt && (
                  <MenuItem
                    onClick={() => {
                      setMenuAnchor(null);
                      selectNewOwner();
                    }}
                  >
                    <ListItemIcon>
                      <Person color="textSecondary" />
                    </ListItemIcon>
                    <ListItemText primary="Change step owners" />
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setMenuAnchor(null);
                    setInfoOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <InfoOutlined color="textSecondary" />
                  </ListItemIcon>
                  <ListItemText primary="Step info" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setMenuAnchor(null);
                    remindOfTheStep();
                  }}
                >
                  <ListItemIcon>
                    <NotificationsActiveRounded color="textSecondary" />
                  </ListItemIcon>
                  <ListItemText primary="Send reminder" />
                </MenuItem>
                {!completedAt && user && (userStepOwner || admin) && (
                  <MenuItem
                    onClick={() => {
                      setMenuAnchor(null);
                      setNotificationsDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <NotificationsRounded color="textSecondary" />
                    </ListItemIcon>
                    <ListItemText primary="Notifications" />
                  </MenuItem>
                )}
                {isLastStep && !isFirstStep && !process.completedAt && (
                  <MenuItem onClick={confirmStepDelete}>
                    <ListItemIcon>
                      <DeleteOutlineRounded sx={{ color: red[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Delete step" />
                  </MenuItem>
                )}
              </Menu>
              {!completedAt && (
                <StepNotificationsDialog
                  open={notificationsDialog}
                  onClose={() => setNotificationsDialog(false)}
                  ongoingStepId={step._id}
                />
              )}
              <StepInfo
                open={infoOpen}
                onClose={() => setInfoOpen(false)}
                stepId={step.ongoingStepId}
                onDueChange={setDueAt}
                onApprovalDueChange={(dueAt) => {
                  if (!approval) return;
                  setApproval({ ...approval, dueAt });
                }}
              />
            </Grid>
          )}
          {step.title?.length >= 40 && (
            <Grid container item>
              {' '}
              <Typography variant="h5" noWrap style={{ marginLeft: '0.5rem', whiteSpace: 'break-spaces' }}>
                {step.title}
              </Typography>
            </Grid>
          )}
        </Grid>
        {step.type !== 'end' && (
          <Collapse in={expanded} style={{ width: '100%' }}>
            <Grid container>
              {step.type === 'decision' && step.decisionDescription && (
                <>
                  {isHtml(step.decisionDescription) ? (
                    <Grid container item>
                      <HtmlContent content={step.decisionDescription} />
                    </Grid>
                  ) : (
                    <Grid container item>
                      <Typography style={{ whiteSpace: 'break-spaces', padding: theme.spacing(1, 0) }}>
                        {step.decisionDescription}
                      </Typography>
                    </Grid>
                  )}
                </>
              )}

              {step.contentType === 'form' ? (
                <StepForms ids={step.stepForms} />
              ) : (
                <Grid container item>
                  {notNestedItems.map((item) => (
                    <Field
                      key={item.id}
                      stepId={step.ongoingStepId}
                      item={item}
                      editable={editable && !item.locked}
                      onSave={onSave}
                      onTableSave={onTableSave}
                      allItems={items}
                      greyOut={greyOut}
                      assignedUsers={owners}
                      dueDate={dueAt || defaultDueDate}
                      activityId={step._id}
                      assignedBy={step.startedBy}
                      activity={'ProcessStep'}
                      activityInfo={{
                        type: 'processStep',
                        entryId: step.ongoingStepId,
                        ongoingProcessId: process._id,
                      }}
                      allCreatedActions={allCreatedActions}
                      onCreatedAction={onCreatedAction}
                      onDeletedAction={onDeletedAction}
                      hiddenItems={hiddenItems}
                      executedActions={allExecutedActions}
                      onExecutedActionDelete={onExecutedActionDelete}
                    />
                  ))}
                </Grid>
              )}
              {step.type === 'decision' && (
                <Grid container item alignItems="center" sx={{ my: 3 }}>
                  {isArray(step.decisions) &&
                    step.decisions.map((decision, i) => (
                      <DecisionButton
                        key={i}
                        variant="outlined"
                        style={
                          decision.color
                            ? {
                                color:
                                  decisionId === decision.id
                                    ? theme.palette.getContrastText(decision.color)
                                    : decision.color,
                                border: `${decision.color} 2px solid`,

                                background: decisionId === decision.id ? decision.color : undefined,
                              }
                            : {
                                color:
                                  decisionId === decision.id
                                    ? theme.palette.getContrastText(theme.palette.primary.main)
                                    : theme.palette.primary.main,
                                border: `${theme.palette.primary.main} 2px solid`,
                                background: decisionId === decision.id ? theme.palette.primary.main : undefined,
                              }
                        }
                        onClick={() => selectDecision(decision.id)}
                        disabled={!!completedAt || (!editable && !admin)}
                      >
                        {decision.condition}
                      </DecisionButton>
                    ))}
                </Grid>
              )}
              {additionalFields[0] && (
                <Grid container item lg={10} md={10} sm={12} style={{ margin: '1rem 0' }}>
                  <Grid container item>
                    <Typography variant="h6">Additional fields:</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    sx={{
                      borderTop: `2px solid ${grey[300]}`,
                      pt: 1,
                      mt: 1,
                    }}
                  >
                    {additionalFields.map((item, index) => (
                      <Field
                        key={item.id}
                        stepId={step.ongoingStepId}
                        item={item}
                        editable={editable}
                        onSave={onSave}
                        onTableSave={onTableSave}
                        allItems={additionalFields}
                        greyOut={greyOut}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}
              {additionalTasks[0] && (
                <Grid container item lg={10} md={10} sm={12} style={{ margin: '1rem 0' }}>
                  <Grid container item justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Additional tasks:</Typography>
                    <IconButton
                      onClick={(e) => StateManager.setNewTask({ ongoingStepId: step.ongoingStepId }, addTask)}
                    >
                      <AddCircleOutline style={{ color: blue[800] }} />
                    </IconButton>
                  </Grid>
                  <Grid
                    container
                    item
                    sx={{
                      borderTop: `2px solid ${grey[300]}`,
                      pt: 1,
                      mt: 1,
                    }}
                  >
                    {additionalTasks.map((task) => (
                      <TaskItem
                        key={task._id}
                        task={task}
                        makeTaskRequired={makeTaskRequired}
                        editable={editable}
                        deleteTask={deleteTask}
                        onChange={updateTask}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}

              {approval && <Approval variant="outlined" approval={approval} />}
              <Grid container item lg={12} md={12} sm={12} style={{ margin: '0.5rem 0' }}>
                <CommentsSection
                  comments={comments}
                  disabled={process.cancelledAt || process.disableStepComments}
                  addComment={(comment) => {
                    saveComments([...comments, comment]);
                  }}
                  deleteComment={(id) => {
                    saveComments(comments.filter((x) => x.id !== id));
                  }}
                  sendMentions
                  mentionType="process step"
                  mentionTitle={step.title}
                  mentionLink={`/processes/step/${step._id}`}
                />
              </Grid>
              {!isEmpty(uploadedFiles) && (
                <Grid container item lg={10} md={10} sm={12}>
                  <FileViewer files={uploadedFiles} title="" />
                </Grid>
              )}
              {editable && (
                <Grid container item lg={10} md={10} sm={12}>
                  <Uploader
                    clearOnDone
                    dialogOpen={uploaderOpen}
                    uploaded={uploadedFiles}
                    onChange={onFilesUploaded}
                    onDialogClose={() => setUploaderOpen(false)}
                    hideDropzone
                    withLinkedFeatures={true}
                  />
                </Grid>
              )}

              {!completedAt && (userStepOwner || admin) && process && !process.heldAt && !process.cancelledAt && (
                <Grid container item alignItems="center" sx={{ pt: 1, mt: 1 }}>
                  {!process.hideStepAddOptions && (
                    <>
                      {!saving && editable && (
                        <Button
                          startIcon={<LabelOutlined />}
                          onClick={() => setAdditionalFieldsDialog(true)}
                          style={{ color: grey[600], borderRadius: 8 }}
                        >
                          {largeDevices ? 'add fields' : 'field'}
                        </Button>
                      )}
                      {!saving && !additionalTasks[0] && editable && (
                        <Button
                          startIcon={<WorkOutline />}
                          onClick={() => StateManager.setNewTask({ ongoingStepId: step.ongoingStepId }, addTask)}
                          style={{ color: grey[600], borderRadius: 8, marginLeft: 5 }}
                        >
                          {largeDevices ? 'add tasks' : 'task'}
                        </Button>
                      )}
                      {!saving && editable && (
                        <Button
                          startIcon={<DescriptionRounded />}
                          onClick={() => setUploaderOpen(true)}
                          style={{ color: grey[600], borderRadius: 8, marginLeft: 5 }}
                        >
                          {largeDevices ? 'add files' : 'files'}
                        </Button>
                      )}
                    </>
                  )}

                  {!saving && step.approvalNeeded && !step.approved && !submitted && step.contentType !== 'form' && (
                    <Button
                      startIcon={<CheckCircleOutlineOutlined />}
                      onClick={() => checkToSubmit()}
                      color="primary"
                      variant="contained"
                      style={{ borderRadius: 8, marginLeft: 'auto' }}
                    >
                      {largeDevices ? 'submit for approval' : 'submit'}
                    </Button>
                  )}
                  {!saving &&
                    (!step.approvalNeeded || step.approved) &&
                    step.contentType !== 'form' &&
                    step.type !== 'decision' && (
                      <Button
                        startIcon={<CheckCircleOutlineOutlined />}
                        onClick={() => checkToComplete()}
                        variant="contained"
                        style={{
                          marginLeft: 'auto',
                          background: step?.completeStepButton?.backgroundColor ?? 'primary',
                          color: step?.completeStepButton?.backgroundColor
                            ? theme.palette.getContrastText(step?.completeStepButton?.backgroundColor)
                            : 'primary',
                        }}
                      >
                        {step?.completeStepButton?.text
                          ? step?.completeStepButton?.text
                          : largeDevices
                          ? 'complete step'
                          : 'complete'}
                      </Button>
                    )}
                  {saving && (
                    <CircularProgress style={{ color: blue[500], marginLeft: 'auto', width: 32, height: 32 }} />
                  )}
                </Grid>
              )}
            </Grid>
          </Collapse>
        )}

        {step.type === 'end' && (
          <Grid container item sx={{ py: 2 }}>
            {completedAt && (
              <Typography>
                The process was {process.cancelledAt ? 'cancelled' : 'completed'} at this step{' '}
                {FormatDate(process.cancelledAt || completedAt)}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      {!completedAt && (
        <NextStepDialog
          open={nextStepDialog}
          onClose={() => setNextStepDialog(false)}
          onResult={completeStep}
          nextStepInfo={nextStepInfo}
        />
      )}
      {!completedAt && step.approvalNeeded && !step.approved && (
        <ApprovalDialog
          open={approvalUsersDialog}
          onClose={() => setApprovalUsersDialog(false)}
          step={step}
          onResult={submitStep}
        />
      )}
      {editable && (
        <AdditionalFieldsDialog
          open={additionalFieldsDialog}
          onClose={() => setAdditionalFieldsDialog(false)}
          onResult={saveAdditionalFields}
          initialItems={additionalFields}
        />
      )}
      {isArray(step.formEntries) &&
        !isEmpty(step.formEntries) &&
        step.formEntries.map((entry) => (
          <Grid container key={entry._id}>
            <Grid container component={Paper} elevation={3} sx={{ mt: 1, p: 1, borderRadius: 1.5 }} alignItems="center">
              {entry.completedAt ? (
                <CheckCircleOutlineOutlined style={{ color: green[500] }} />
              ) : (
                <FiberManualRecord color="primary" />
              )}
              <Link to={`/forms/entry/${entry._id}`} style={{ margin: '0 1rem' }}>
                <Button endIcon={<FormIcon />} style={{ borderRadius: 8, textTransform: 'none', fontSize: '1rem' }}>
                  {entry.title} #{entry.number}
                </Button>
              </Link>
              <Typography>was started from this step</Typography>

              <UserGroup ids={entry.assignedUsers} avatarSize={32} style={{ marginLeft: 'auto' }} />
            </Grid>
          </Grid>
        ))}
      {isArray(step.processEntries) &&
        !isEmpty(step.processEntries) &&
        step.processEntries.map((entry) => (
          <Grid container key={entry._id}>
            <Grid container component={Paper} elevation={3} sx={{ mt: 1, p: 1, borderRadius: 1.5 }} alignItems="center">
              {entry.completedAt ? (
                <CheckCircleOutlineOutlined style={{ color: green[500] }} />
              ) : (
                <FiberManualRecord color="primary" />
              )}

              <Link to={`/processes/ongoing/${entry._id}`} style={{ margin: '0 1rem' }}>
                <Button endIcon={<ProcessIcon />} style={{ borderRadius: 8, textTransform: 'none', fontSize: '1rem' }}>
                  {entry.title} #{entry.number}
                </Button>
              </Link>
              <Typography>was started from this step</Typography>

              <UserGroup ids={entry.owners} avatarSize={32} style={{ marginLeft: 'auto' }} />
            </Grid>
          </Grid>
        ))}
      {isArray(step.stepTasks) &&
        !isEmpty(step.stepTasks) &&
        step.stepTasks.map((task) => (
          <Grid container key={task._id}>
            <Grid container component={Paper} elevation={3} sx={{ mt: 1, p: 1, borderRadius: 1.5 }} alignItems="center">
              {task.completedDate ? (
                <CheckCircleOutlineOutlined style={{ color: green[500] }} />
              ) : (
                <FiberManualRecord color="primary" />
              )}
              <Button
                onClick={() => StateManager.selectTask(task._id)}
                endIcon={<TaskIcon />}
                style={{ borderRadius: 8, textTransform: 'none', margin: '0 1rem', fontSize: '1rem' }}
              >
                {task.title}
              </Button>

              <Typography>was started from this step</Typography>

              <UserGroup ids={task.assignedUsers} avatarSize={32} style={{ marginLeft: 'auto' }} />
            </Grid>
          </Grid>
        ))}
      {isArray(step.actionsToStart) &&
        completedAt &&
        step.actionsToStart.map((action) => (
          <ActionButton key={action.id} action={action} parentProcessStepId={step._id} />
        ))}
    </Grid>
  );
}

function TaskItem({ task, makeTaskRequired, editable, deleteTask, onChange }) {
  const [taskMenu, setTaskMenu] = useState();
  return (
    <Grid container item key={task._id} sx={{ position: 'relative', py: 1 }}>
      {task.required && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
      <Grid container component={Paper} variant="outlined">
        <ListItem onClick={() => StateManager.selectTask(task._id, onChange)} button style={{ borderRadius: 8 }}>
          <Grid container alignItems="center">
            <Grid container item xs={5} alignItems="center" wrap="nowrap">
              <TaskIcon />
              <Typography noWrap style={{ marginLeft: '1rem' }}>
                {task.title}
              </Typography>
            </Grid>
            <Grid container item xs={7} alignItems="center" wrap="nowrap">
              <Hidden smDown>
                <User id={task.assigned_to} />
              </Hidden>
              <StatusButton isDisabled status={task.progress} style={{ marginLeft: 'auto' }} />
              {editable && (
                <>
                  <IconButton
                    style={{ height: 32, width: 32, marginLeft: '1rem' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setTaskMenu(e.currentTarget);
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    open={Boolean(taskMenu)}
                    anchorEl={taskMenu}
                    onClose={(e) => {
                      e.stopPropagation();
                      setTaskMenu(null);
                    }}
                    keepMounted
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setTaskMenu(null);
                        makeTaskRequired(task._id);
                      }}
                    >
                      <ListItemIcon>
                        <RequiredIcon />
                      </ListItemIcon>
                      <ListItemText primary="Required" />
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setTaskMenu(null);
                        deleteTask(task._id);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteOutline style={{ color: red[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="Delete task" />
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          </Grid>
        </ListItem>
      </Grid>
    </Grid>
  );
}

function ActionButton({ action, parentProcessStepId }) {
  const [starting, setStarting] = useState(false);
  const [optionsDialog, setOptionsDialog] = useState(false);
  const history = useHistory();

  function checkProcessUsers() {
    const selectable =
      (action.userType === 'selectedUsers' && action.provideUserChoice && !isEmpty(action.users)) ||
      action.startAnytime;

    if (selectable) {
      setOptionsDialog(true);
    } else {
      startProcess();
    }
  }

  function checkFormUsers() {
    const selectable =
      (action.userType === 'selectedUsers' && action.provideUserChoice && !isEmpty(action.users)) ||
      action.startAnytime;

    if (selectable) {
      setOptionsDialog(true);
    } else {
      startForm();
    }
  }

  function startProcess(params) {
    if (!action?.processId) return;
    setStarting(true);
    const dueAt = params
      ? params.dueAt
      : action.due
      ? moment().add(action.due.hours, 'hours').add(action.due.days, 'days').toDate()
      : null;

    const body = {
      processId: action.processId,
      parentProcessStepId,
      assignedUsers: params
        ? params.selectedUsers
        : action.userType === 'selectedUsers' && !isEmpty(action.users)
        ? action.users
        : null,
      dueAt,
    };

    axios
      .post('/process/startProcess', body)
      .then((res) => {
        StateManager.setSuccessAlert('Process has been started');
        setStarting(false);
        history.push(`/processes/ongoing/${res.data.ongoingProcess._id}?started=true`);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setStarting(false);
      });
  }

  function startForm(params) {
    if (!action?.formId) return;
    setStarting(true);

    const dueAt = params
      ? params.dueAt
      : action.due
      ? moment().add(action.due.hours, 'hours').add(action.due.days, 'days').toDate()
      : null;

    const body = {
      formId: action.formId,
      parentProcessStepId,
      assignedUsers: params
        ? params.selectedUsers
        : action.userType === 'selectedUsers' && !isEmpty(action.users)
        ? action.users
        : null,
      dueAt,
    };

    axios
      .post('/forms/entries/sendEntry', body)
      .then((res) => {
        StateManager.setSuccessAlert('Process has been started');
        setStarting(false);
        history.push(`/forms/entry/${res.data[0]._id}`);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setStarting(false);
      });
  }

  if (!action) return null;

  if (action.actionType === 'startProcess') {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ paddingTop: 12 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: 'none', borderRadius: 8 }}
          startIcon={<TimelineRounded />}
          endIcon={starting ? <CircularProgress size={24} style={{ color: 'white' }} /> : null}
          onClick={checkProcessUsers}
        >
          Start process: {action.processTitle}
        </Button>
        <OptionsDialog
          action={action}
          open={optionsDialog}
          onClose={() => setOptionsDialog(false)}
          onResult={startProcess}
        />
      </Grid>
    );
  }

  if (action.actionType === 'startForm') {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ paddingTop: 12 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: 'none', borderRadius: 8 }}
          startIcon={<DescriptionRounded />}
          endIcon={starting ? <CircularProgress size={24} style={{ color: 'white' }} /> : null}
          onClick={checkFormUsers}
        >
          Start form: {action.formTitle}
        </Button>
        <OptionsDialog
          action={action}
          open={optionsDialog}
          onClose={() => setOptionsDialog(false)}
          onResult={startForm}
        />
      </Grid>
    );
  }

  return null;
}

function OptionsDialog({ action, open, onClose, onResult }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [includeDueDate, setIncludeDueDate] = useState(false);
  const [dueAt, setDueAt] = useState(null);

  const defaultDue = action.due
    ? moment().add(action.due.hours, 'hours').add(action.due.days, 'days').toDate()
    : moment().add(7, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 });

  useEffect(() => {
    if (action?.due) {
      setIncludeDueDate(true);
      setDueAt(defaultDue);
    }
  }, [action]); // eslint-disable-line

  const choseUsers =
    action.userType === 'selectedUsers' && !isEmpty(action.users) && isArray(action.users) && action.provideUserChoice;
  const choseDue = action.startAnytime;

  const hideDone = choseUsers && isEmpty(selectedUsers);

  function done() {
    const result = { selectedUsers, dueAt: includeDueDate ? (dueAt ? moment(dueAt).toDate() : null) : null };
    onResult(result);
    onClose();
  }

  if (!action) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Grid container item style={{ margin: '1rem 0' }} wrap="nowrap" alignItems="center">
          {action.actionType === 'startProcess' && <ProcessIcon />}
          {action.actionType === 'startForm' && <FormIcon />}
          {action.actionType === 'sendTask' && <TaskIcon />}
          <Typography style={{ marginLeft: 8, whiteSpace: 'break-spaces' }}>
            You are about to{' '}
            {action.actionType === 'startProcess' && (
              <>
                start process: {'\n'}
                <span style={{ fontWeight: 500 }}>{action.processTitle}</span>
              </>
            )}
            {action.actionType === 'startForm' && (
              <>
                start form entry: {'\n'}
                <span style={{ fontWeight: 500 }}>{action.formTitle}</span>
              </>
            )}
            {action.actionType === 'sendTask' && (
              <>
                send task: {'\n'}
                <span style={{ fontWeight: 500 }}>{action.task?.title}</span>
              </>
            )}
          </Typography>
        </Grid>
        {choseUsers && (
          <Grid container item style={{ margin: '1rem 0' }}>
            <Grid container item>
              <Typography variant="h6" gutterBottom>
                Pick users:
              </Typography>
            </Grid>

            <Grid container item>
              {action.users.map((user, i) => (
                <User
                  key={i}
                  id={user}
                  fullWidth
                  markPortal
                  onClick={() => {
                    if (selectedUsers.includes(user)) {
                      setSelectedUsers(selectedUsers.filter((x) => x !== user));
                    } else {
                      setSelectedUsers([...selectedUsers, user]);
                    }
                  }}
                  action={
                    selectedUsers.includes(user) ? (
                      <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                    ) : (
                      <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                    )
                  }
                />
              ))}
            </Grid>
          </Grid>
        )}

        {choseDue && (
          <Grid container>
            <Grid container>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={includeDueDate}
                    onChange={(e) => {
                      setIncludeDueDate(e.target.checked);
                      if (e.target.checked && !dueAt) {
                        setDueAt(defaultDue);
                      }
                    }}
                  />
                }
                label="Include due date"
              />
            </Grid>
            <Collapse unmountOnExit style={{ width: '100%' }} in={includeDueDate}>
              <Grid container>
                <Grid item container xs={6} style={{ paddingRight: 4 }}>
                  <DatePicker value={dueAt} onChange={setDueAt} />
                </Grid>

                <Grid item container xs={6} style={{ paddingLeft: 4 }}>
                  <TimePicker value={dueAt} onChange={setDueAt} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={hideDone} />
    </RoundedDialog>
  );
}
