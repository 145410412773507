import { drawerOptions } from '../../constants';

export const firstFormTour = [
  {
    title: 'FormHub',
    target: '.addIcon',
    content: 'Create your form here',
    ...drawerOptions,
  },
  {
    title: 'Entries',
    target: '.entries',
    content: 'See all your entries, in progress and completed',
    ...drawerOptions,
    locale: {
      last: 'Finish',
    },
  },
];
