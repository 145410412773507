import { SET_DRAFT_TABLEKEY } from '../constants';

const initialState = {
  tableKey: null,
  tableKeyDialog: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DRAFT_TABLEKEY:
      return {
        ...state,
        tableKey: action.payload.key,
        tableKeyDialog: action.payload.state,
      };
    default:
      return state;
  }
}
