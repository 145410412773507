import { grey } from '@mui/material/colors';
import { DialogContent, DialogTitle, DialogActions, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { TextFields, Input } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, CrossButton, GeneralButton } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { FormIcon, TaskIcon } from '../../../Global/Icons';
import { useTheme } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import axios from 'axios';
import EditField from '../../../Global/Fields/EditField';

export default function AdditionalFieldsDialog({ initialItems, open, onClose, onResult }) {
  const [items, setItems] = useState([]);
  const theme = useTheme();

  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (!open) return;
    setItems(initialItems || []);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  function close() {
    onClose();
  }

  function save() {
    for (let i = 0; i < items.length; ++i) {
      // check if all fields have title
      if (!items[i].title && items[i].type === 'field') {
        StateManager.setErrorAlert(`Provide title for field number ${i + 1}`);
        return;
      }

      // check that forms set
      if (items[i].type === 'form' && !items[i].formId) {
        StateManager.setErrorAlert(`Provide form to fill for field number ${i + 1}`);
        return;
      }

      // chack that text set
      if (items[i].type === 'text' && !items[i].title && !items[i].text) {
        StateManager.setErrorAlert(`Provide text or title for field number ${i + 1}`);
        return;
      }

      // check if all fields have type
      if (items[i].type === 'field' && !items[i].fieldType) {
        StateManager.setErrorAlert(`Provide field type for field ${items[i].title}`);
        return;
      }
    }

    onResult(items);
    onClose();
  }

  function addField() {
    items.push({ id: v4(), type: 'field', title: '' });
    setItems([...items]);
  }

  function addTextItem() {
    items.push({ id: v4(), type: 'text', title: '' });
    setItems([...items]);
  }

  function addFormItem() {
    items.push({ id: v4(), type: 'form', formId: '', formName: '' });
    setItems([...items]);
  }

  function addTaskItem() {
    items.push({ id: v4(), type: 'task', formId: '', formName: '' });
    setItems([...items]);
  }

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    let list = [];
    if (result.type === 'fields') {
      list = items.filter((x) => !x.conditionalFieldId);
    } else {
      let index = items.findIndex((x) => x.id === result.draggableId);
      if (index === -1) return;
      list = items.filter((x) => x.conditionalFieldId === items[index].conditionalFieldId);
    }

    const srcIndex = items.findIndex((x) => x.id === list[result.source.index].id);
    const destIndex = items.findIndex((x) => x.id === list[result.destination.index].id);
    if (srcIndex > -1 && destIndex > -1) {
      const [removed] = items.splice(srcIndex, 1);
      items.splice(destIndex, 0, removed);
      setItems([...items]);
    }
  }

  function addConditionalField(conditionalFieldId, type) {
    items.push({ id: v4(), type, conditionalFieldId });
    setItems([...items]);
  }

  function saveItem(itemId, field, value) {
    let index = items.findIndex((x) => x.id === itemId);
    if (index > -1) {
      items[index][field] = value;
    }
  }

  function deleteItem(id) {
    let index = items.findIndex((x) => x.id === id);
    let remaining = items;
    if (index > -1 && items[index].fieldType === 'conditional') {
      // delete all conditional fields
      for (let i = 0; i < items[index].conditions.length; ++i) {
        remaining = remaining.filter((x) => x.conditionalFieldId !== items[index].conditions[i].id);
      }
    }
    setItems(remaining.filter((x) => x.id !== id));
  }

  function duplicateSimpleItem(item) {
    let clone = { ...item };
    clone.id = v4();
    if (clone.options) {
      clone.options = item.options.map((x) => ({ id: v4(), text: x.text }));
    }
    return clone;
  }

  function duplicateField(itemId) {
    let index = items.findIndex((x) => x.id === itemId);
    if (index === -1) return;

    let item = null;
    // for conditional deep copy is needed
    if (items[index].fieldType === 'conditional') {
      item = JSON.parse(JSON.stringify(items[index]));
      for (let i = 0; i < item.conditions.length; ++i) {
        let newId = v4();
        items
          .filter((x) => x.conditionalFieldId === item.conditions[i].id)
          .map((x) => ({ ...x, conditionalFieldId: newId }))
          .forEach((x) => {
            let clone = duplicateSimpleItem(x);
            items.push(clone);
          });
        item.conditions[i].id = newId;
      }
      item.id = v4();
      items.push(item);
    } else {
      let clone = duplicateSimpleItem(items[index]);
      items.push(clone);
    }
    setItems([...items]);
  }

  return (
    <RoundedDialog open={open} onClose={close} maxWidth="md" fullWidth fullScreen={!largeDevices}>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid style={{ width: '1.5em', height: '1.5em' }} />
          <Typography variant="h5">Additional step fields</Typography>
          <CrossButton onClick={close} />
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container item style={{ margin: theme.spacing(2, 0) }}>
          <Grid container item style={{ margin: theme.spacing(2, 0) }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="fields" direction="vertical" type="fields">
                {(provided) => (
                  <Grid item container {...provided.droppableProps} ref={provided.innerRef}>
                    {items
                      .filter((x) => !x.conditionalFieldId)
                      .map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <Grid
                              container
                              item
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <EditField
                                item={item}
                                isDragging={snapshot.isDragging}
                                allItems={items}
                                onAdd={addConditionalField}
                                onEdit={saveItem}
                                onDelete={deleteItem}
                                onDuplicate={() => duplicateField(item.id)}
                                nested
                              />
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          <Grid container item>
            <GeneralButton onClick={addField} startIcon={<Input style={{ color: grey[700] }} />}>
              {largeDevices ? 'Add a field' : 'Field'}
            </GeneralButton>
            <GeneralButton
              onClick={addTextItem}
              style={{ marginLeft: theme.spacing(2) }}
              startIcon={<TextFields style={{ color: grey[700] }} />}
            >
              {largeDevices ? 'Add fixed text' : 'Fixed text'}
            </GeneralButton>
            <GeneralButton onClick={addFormItem} style={{ marginLeft: theme.spacing(2) }} startIcon={<FormIcon />}>
              {largeDevices ? 'Fill out a form' : 'Form'}
            </GeneralButton>
            <GeneralButton onClick={addTaskItem} style={{ marginLeft: theme.spacing(2) }} startIcon={<TaskIcon />}>
              {largeDevices ? 'Add a task' : 'Task'}
            </GeneralButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={save} variant="contained" color="primary" style={{ borderRadius: '0.5rem' }}>
          save
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
