import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';

import { Typography, DialogTitle, Skeleton, AvatarGroup, Avatar } from '@mui/material';

import { RoundedDialog, StandardDialogActions, UserGroup } from '../../../../Global/Components';

const LoadingAvatars = ({ number = 4 }) => (
  <AvatarGroup sx={{ mb: 1 }}>
    {Array.from(Array(number).keys()).map((x) => (
      <Skeleton variant="circular" height={30} width={30} key={x}>
        <Avatar />
      </Skeleton>
    ))}
  </AvatarGroup>
);

export default ({ open, handleClose, id, area }) => {
  // const { selected } = useSelector(({ dochub }) => dochub);
  // const area = selected?.area?._id || selected?.area;
  // const id = selected?._id;
  const [reviewers, setReviewers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open || !area || !id) return;
    setLoading(true);
    Promise.all([
      axios.get(`/dochub/areas/getAreaRoles/${area}/Reviewer?documentId=${id}`),
      axios.get(`/dochub/areas/getAreaRoles/${area}/Approver?documentId=${id}`),
    ])
      .then((res) => {
        if (res[0].data && res[0].data.users) {
          setReviewers(res[0].data.users);
        }
        if (res[1].data && res[1].data.users) {
          setApprovers(res[1].data.users);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [open, area, id]);

  return (
    <RoundedDialog open={open} onClose={handleClose} titleId="reviewers-approvers" maxWidth="sm" fullWidth>
      <DialogTitle>Current Reviewers and Approvers</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid
            container
            spacing={1}
            sx={{ borderBottom: `1px solid ${grey[300]}`, marginBottom: (theme) => theme.spacing(3) }}
          >
            <Grid item container lg={5} md={5} sm={12} alignItems="center">
              <Typography variant="h5">Reviewers</Typography>
            </Grid>
            <Grid item container lg={7} md={7} sm={12} alignItems="center" justifyContent="flex-end">
              {loading ? (
                <LoadingAvatars />
              ) : (
                <UserGroup ids={reviewers?.map((x) => x._id)} dialogTitle="Reviewers" avatarSize={30} />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{ borderBottom: `1px solid ${grey[300]}`, marginBottom: (theme) => theme.spacing(1) }}
          >
            <Grid item container lg={5} md={5} sm={12} alignItems="center">
              <Typography variant="h5">Approvers</Typography>
            </Grid>
            <Grid item container lg={7} md={7} sm={12} alignItems="center" justifyContent="flex-end">
              {loading ? (
                <LoadingAvatars />
              ) : (
                <UserGroup ids={approvers?.map((x) => x._id)} dialogTitle="Approvers" avatarSize={30} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={handleClose} />
    </RoundedDialog>
  );
};
