/**
 * * Reducer for Document Hub, follows the cases as per relevant actions
 */
import { HOME_ATTEMPT, HOME_ERROR, GETDOCSTATS_SUCCESS, GETDOCFILES_SUCCESS } from '../constants';

const initialState = {
  docStats: null,
  docFiles: {},
  error: null,
  homeLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HOME_ATTEMPT:
      return { ...state, homeLoading: true };
    case HOME_ERROR:
      return { ...state, homeLoading: false, error: action.payload, settingLoading: false, folderLoading: false };
    case GETDOCSTATS_SUCCESS:
      return {
        ...state,
        homeLoading: false,
        error: null,
        docStats: action.payload.stats,
        docFiles: {
          ...action?.payload,
          recentlyViewed: action?.payload?.recentlyViewed?.filter((x) => x.document)?.map((x) => x.document) ?? [],
        },
      };
    case GETDOCFILES_SUCCESS:
      if (action.payload.type === 'newFiles') {
        return {
          ...state,
          homeLoading: false,
          error: null,
          docFiles: { ...state.docFiles, newFiles: action.payload.data },
        };
      }
      if (action.payload.type === 'recentDocs') {
        return {
          ...state,
          homeLoading: false,
          error: null,
          docFiles: { ...state.docFiles, recentDocs: action.payload.data },
        };
      }
      if (action.payload.type === 'reviewsDueSoon') {
        return {
          ...state,
          homeLoading: false,
          error: null,
          docFiles: { ...state.docFiles, reviewsDueSoon: action.payload.data },
        };
      }
      if (action.payload.type === 'reviewOverdue') {
        return {
          ...state,
          homeLoading: false,
          error: null,
          docFiles: { ...state.docFiles, reviewOverdue: action.payload.data },
        };
      }
      return { ...state, homeLoading: false };
    default:
      return state;
  }
}
