import axios from 'axios';
import { WS_BASE_URL } from '../../../constants';
import { Subject } from 'rxjs';
import { isEmpty, cloneDeep, isArray } from 'lodash';

const LASTSEEN_EVENT = 'lastSeen';
const USER_DELETED_EVENT = 'userDeleted';
const USER_CREATED_EVENT = 'userCreated';
const USER_RESTORED_EVENT = 'userRestored';
const USER_SUSPENDED_EVENT = 'userSuspended';
const GROUP_UPDATED_EVENT = 'groupUpdated';
const GROUP_DELETED_EVENT = 'groupDeleted';
const token = localStorage.getItem('token');

var currentUserId = localStorage.getItem('_id');
let invitationId = localStorage.getItem('invitationId');

var userSocket = null;
var userSubjects = {};
var userMap = {};
var groupSubjects = {};
var groupMap = {};
var portalGroupSubjects = {};
var portalGroupMap = {};
var appUsersLoaded = false;
var externalUsersLoaded = false;

/// user list
var userList = [];
var resolveUserListPromise = null;
var rejectUserListPromise = null;
const getUserListPromise = new Promise((resolve, reject) => {
  resolveUserListPromise = resolve;
  rejectUserListPromise = reject;
});

async function getUserList(withPortalUsers) {
  await getUserListPromise;

  const filteredUsers = userList.filter(
    (x) => x._id && !x.suspendedAt && !x.deletedAt && (withPortalUsers || x.access !== 'portal'),
  );
  const result = { data: cloneDeep(filteredUsers) };
  return result;
}

// users map (for resolving ids)
var resolveMapPromise = null;
var rejectMapPromise = null;
const getUserMapPromise = new Promise((resolve, reject) => {
  resolveMapPromise = resolve;
  rejectMapPromise = reject;
});

async function getUserMap() {
  await getUserMapPromise;
  return cloneDeep(userMap);
}

// normal groups
var groupList = [];
var resolveGroupListPromise = null;
var rejectGroupListPromise = null;
const getGroupListPromise = new Promise((resolve, reject) => {
  resolveGroupListPromise = resolve;
  rejectGroupListPromise = reject;
});

async function getGroupList() {
  await getGroupListPromise;
  return { data: cloneDeep(groupList) };
}

// portal groups
var portalGroupList = [];
var resolvePortalGroupListPromise = null;
var rejectPortalGroupListPromise = null;
const getPortalGroupListPromise = new Promise((resolve, reject) => {
  resolvePortalGroupListPromise = resolve;
  rejectPortalGroupListPromise = reject;
});

async function getPortalGroupList() {
  await getPortalGroupListPromise;
  return { data: cloneDeep(portalGroupList) };
}

function clear() {
  localStorage.clear();
  window.location.href = '/login';
}

function logout() {
  let subscriptionId = localStorage.getItem('pushSubscription'); // important!!!
  if (subscriptionId) {
    axios
      .post('/notifications/cancelPushSubscription', { subscriptionId })
      .then(() => clear())
      .catch(() => clear());
  } else {
    clear();
  }
}

function idleLogout(timeout) {
  var timer;
  const events = ['onload', 'onmousemove', 'onmousedown', 'ontouchstart', 'ontouchmove', 'onclick', 'onkeydown'];
  events.forEach((e) => (window[e] = resetTimer));
  window.addEventListener('scroll', resetTimer, true);

  function clearTimers() {
    if (timer != null) clearTimeout(timer);
    events.forEach((e) => (window[e] = undefined));
    window.removeEventListener('scroll', resetTimer, true);
  }

  function resetTimer() {
    if (timer != null) clearTimeout(timer);
    timer = setTimeout(() => {
      clearTimers();
      logout();
    }, timeout);
  }

  timer = setTimeout(() => {
    clearTimers();
    logout();
  }, timeout);
}

function browserCloseLogout() {
  // let possibleUnload = false;
  let isTabClosing = false;

  const navigationType = window.performance.getEntriesByType('navigation')[0].type;
  incrementTabCount();
  // Increment the count of open tabs
  function incrementTabCount() {
    let count = localStorage.getItem('openTabs');
    if (count === null) {
      count = 0;
    } else {
      count = parseInt(count, 10);
    }
    localStorage.setItem('openTabs', count + 1);
    isTabClosing = false;
  }

  // Decrement the count of open tabs
  function decrementTabCount() {
    let count = localStorage.getItem('openTabs');
    if (count !== null) {
      count = parseInt(count, 10);
      if (count === 0) {
        return;
      }
      localStorage.setItem('openTabs', count - 1);
    }
    isTabClosing = true;
  }

  // document.addEventListener('visibilitychange', function () {
  //   if (document.visibilityState === 'hidden') {
  //     isTabClosing = true;
  //   }
  // });

  window.addEventListener('beforeunload', function (e) {
    let count = localStorage.getItem('openTabs');
    if (isTabClosing) {
      e.preventDefault();
      if (count === '1' && navigationType !== 'reload') {
        return true;
      }
    } else {
      return null;
    }
  });

  // Perform the action only if the user confirmed the unload
  window.addEventListener('unload', function () {
    let count = localStorage.getItem('openTabs');
    console.log('unloading', count);
    if ((count === '1' || count === '0') && navigationType !== 'reload') {
      logout();
    }
    // Decrement the tab count on unload
    decrementTabCount();
  });
}

function addUser(id) {
  if (!token || typeof id !== 'string') return;

  axios
    .get('/user/getUser', { params: { id } })
    .then((response) => {
      let user = response.data;
      if (!user?._id) return;
      userMap[user._id] = { ...user };

      if (!userSubjects[user._id]) {
        userSubjects[user._id] = new Subject();
      } else {
        userSubjects[user._id].next({ ...user });
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

function init() {
  if (!token && !invitationId) return;

  if (token) {
    axios
      .get('/user/getCompanyUsersData')
      .then(({ data }) => {
        const { normalUsers: users, currUserId, timeout, externalUsers, groups, portalGroups, logOutOnClose } = data;

        // current user
        currentUserId = currUserId;

        if (timeout) {
          idleLogout(timeout * 60 * 1000);
        }

        if (logOutOnClose) {
          browserCloseLogout();
        }

        const filtered = users.filter((x) => !x.suspendedAt && !x.deletedAt);

        userList = cloneDeep(filtered);
        resolveUserListPromise();

        const allUsers = [...users, ...externalUsers];

        for (let i = 0; i < allUsers.length; ++i) {
          const user = allUsers[i];
          userMap[user._id] = cloneDeep(user);

          if (!userSubjects[user._id]) {
            userSubjects[user._id] = new Subject();
          } else {
            userSubjects[user._id].next({ ...userMap[user._id] });
          }
        }

        appUsersLoaded = true;
        externalUsersLoaded = true;

        resolveMapPromise();

        // company groups

        groupList = cloneDeep(groups);
        resolveGroupListPromise();

        for (let i = 0; i < groups.length; ++i) {
          const group = groups[i];
          groupMap[group._id] = cloneDeep(group);

          if (!groupSubjects[group._id]) {
            groupSubjects[group._id] = new Subject();
          } else {
            groupSubjects[group._id].next({ ...groupMap[group._id] });
          }
        }

        // portal groups

        portalGroupList = cloneDeep(portalGroups);
        resolvePortalGroupListPromise();

        for (let i = 0; i < portalGroups.length; ++i) {
          const portalGroup = portalGroups[i];
          portalGroupMap[portalGroup._id] = cloneDeep(portalGroup);

          if (!portalGroupSubjects[portalGroup._id]) {
            portalGroupSubjects[portalGroup._id] = new Subject();
          } else {
            portalGroupSubjects[portalGroup._id].next({ ...portalGroupMap[portalGroup._id] });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        rejectMapPromise(err);
        rejectUserListPromise(err);
        rejectGroupListPromise(err);
        rejectPortalGroupListPromise(err);
      });

    import('socket.io-client').then(({ io }) => {
      if (userSocket) return;

      userSocket = io(`${WS_BASE_URL}/users`, { query: { token }, transports: ['websocket'], secure: true });

      userSocket.on(USER_DELETED_EVENT, ({ userId }) => {
        if (userMap[userId]) {
          userMap[userId].deletedAt = new Date();
          userSubjects[userId].next({ ...userMap[userId] });
        }

        if (userId === currentUserId) {
          window.location.href = '/suspended';
        }

        userList = userList.filter((x) => x._id && x._id !== userId);
      });

      userSocket.on(USER_CREATED_EVENT, (user) => {
        userMap[user._id] = cloneDeep(user);

        if (!userSubjects[user._id]) {
          userSubjects[user._id] = new Subject();
        } else {
          userSubjects[user._id].next({ ...userMap[user._id] });
        }

        userList = [...userList, user];
      });

      userSocket.on(USER_SUSPENDED_EVENT, ({ userId }) => {
        if (userMap[userId]) {
          userMap[userId].suspendedAt = new Date();
          userSubjects[userId].next({ ...userMap[userId] });
        }

        if (userId === currentUserId) {
          window.location.href = '/suspended';
        }

        userList = userList.filter((x) => x._id && x._id !== userId);
      });

      userSocket.on(GROUP_UPDATED_EVENT, (group) => {
        groupMap[group._id] = cloneDeep(group);

        if (!groupSubjects[group._id]) {
          groupSubjects[group._id] = new Subject();
        } else {
          groupSubjects[group._id].next({ ...userMap[group._id] });
        }

        const index = groupList.findIndex((x) => x._id === group._id);

        if (index > -1) {
          groupList[index] = group;
        } else {
          groupList.push(group);
        }
      });

      userSocket.on(GROUP_DELETED_EVENT, ({ groupId }) => {
        groupList = groupList.filter((x) => x._id && x._id !== groupId);
      });

      userSocket.on(USER_RESTORED_EVENT, ({ userId }) => {
        if (userMap[userId]) {
          userMap[userId].deletedAt = null;
          userMap[userId].suspendedAt = null;
        }

        userSubjects[userId].next({ ...userMap[userId] });
        if (userId === currentUserId) {
          window.location.reload();
        }
      });

      userSocket.on(LASTSEEN_EVENT, (info) => {
        if (userMap[info.userId] && userSubjects[info.userId]) {
          userMap[info.userId].lastSeen = info.lastSeen;
          userMap[info.userId].online = info.online;
          userSubjects[info.userId].next({ ...userMap[info.userId] });
        }
      });

      window.addEventListener('focus', () => {
        userSocket.emit(LASTSEEN_EVENT, {
          type: 'reportOnline',
        });
      });

      window.addEventListener('blur', () => {
        userSocket.emit(LASTSEEN_EVENT, {
          type: 'reportOffline',
        });
      });
    });
  } else {
    loadUsersforExternalUser();
  }
}

init();

function resolveUser(id) {
  if (userMap[id]) return userMap[id];
  if (appUsersLoaded && externalUsersLoaded) addUser(id);
  return {};
}

function subscribeToUser(userId, callback) {
  if (!userSubjects[userId]) userSubjects[userId] = new Subject();

  return userSubjects[userId].subscribe(callback);
}

function loadUsersforExternalUser() {
  if (!isEmpty(userMap)) return;

  axios
    .get('/external/users/getCompanyUsers', { params: { invitationId } })
    .then(({ data }) => {
      resolveUserListPromise({ data: data.map((x) => ({ ...x })) });
      for (let i = 0; i < data.length; ++i) {
        let user = data[i];
        userMap[user._id] = { ...user };
      }
      resolveMapPromise({ ...userMap });
      appUsersLoaded = true;

      for (let i = 0; i < data.length; ++i) {
        let user = data[i];
        if (!userSubjects[user._id]) {
          userSubjects[user._id] = new Subject();
        } else {
          userSubjects[user._id].next({ ...userMap[user._id] });
        }
      }
    })
    .catch((err) => {
      console.error(err);
      rejectMapPromise(err);
      rejectUserListPromise(err);
    });
}

function initExternalUser() {
  invitationId = localStorage.getItem('invitationId');

  if (!invitationId) return;
  loadUsersforExternalUser();
}

function resolveGroup(id) {
  return groupMap[id] || null;
}

function resolvePortalGroup(id) {
  return portalGroupMap[id] || null;
}

function getUsersInGroup(ids) {
  if (!isArray(ids)) {
    return [];
  }
  const result = ids.flatMap((id) => groupMap[id]?.users || []);
  return result;
}

function subscribeToGroup(groupId, callback) {
  if (!groupSubjects[groupId]) groupSubjects[groupId] = new Subject();

  return groupSubjects[groupId].subscribe(callback);
}

function subscribeToPortalGroup(portalGroupId, callback) {
  if (!portalGroupSubjects[portalGroupId]) portalGroupSubjects[portalGroupId] = new Subject();

  return portalGroupSubjects[portalGroupId].subscribe(callback);
}

const UserManager = {
  subscribeToUser,
  resolveUser,
  getUserMap,
  getUserList,
  getGroupList,
  getPortalGroupList,
  initExternalUser,
  resolveGroup,
  resolvePortalGroup,
  subscribeToGroup,
  subscribeToPortalGroup,
  getUsersInGroup,
  browserCloseLogout,
};

export default UserManager;
