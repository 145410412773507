import React from 'react';
import { lazy, Suspense } from 'react';
import { Grid, CircularProgress, Container } from '@mui/material';
const ReactPlayer = lazy(() => import('react-player'));

export default function VideoViewer({ src, audio = false }) {
  return (
    <Container sx={{ height: audio ? '100%' : '65vh' }} maxWidth="xl" disableGutters>
      <Suspense
        fallback={
          <Grid container sx={{ height: '100%' }} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Grid>
        }
      >
        <ReactPlayer
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
            padding: '16px',
          }}
          url={src}
          controls
          playing
          stopOnUnmount={false}
          height={audio ? 100 : '100%'}
          width={'100%'}
        />
      </Suspense>
    </Container>
  );
}
