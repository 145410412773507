import React, { useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { DialogTitle, DialogContent } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import StateManager from '../../../../Global/StateManager';
import axios from 'axios';
import { isArray, isEmpty } from 'lodash';

export default function DeleteMultipleDialog({ open, onClose, onResult, entries }) {
  const [saving, setSaving] = useState(false);

  function done() {
    setSaving(true);
    axios
      .post('/process/entries/deleteEntries', { entries })
      .then(({ data }) => {
        setSaving(false);
        if (isEmpty(data.deleted)) {
          StateManager.setErrorAlert('Failed to delete entries');
        } else {
          StateManager.setSuccessAlert(
            `${data.deleted.length} ${data.deleted === 1 ? 'entry has' : 'entries have'} been deleted`,
          );

          onResult(data.deleted);
          onClose();
        }
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  if (!isArray(entries)) {
    return null;
  }

  return (
    <RoundedDialog maxWidth="xs" open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        You are about to delete {entries.length === 1 ? 'this entry' : `${entries.length} entries`}
      </DialogTitle>
      <DialogContent>
        <Grid container item sx={{ my: 3 }}>
          <Typography>
            This action will delete all of the child actions, such as processes or forms started because of a field
          </Typography>
        </Grid>
        <Grid container item>
          <Typography>Are you sure you want to continue?</Typography>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        saving={saving}
        onClose={onClose}
        onDone={done}
        doneButtonText="DELETE"
        closeButtonText="CANCEL"
      />
    </RoundedDialog>
  );
}
