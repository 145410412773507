import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Grid, Typography, List, ListItem, ListItemIcon, ListItemText, IconButton, ListSubheader } from '@mui/material';
import { BodyParts, PartsNames } from './bodyParts';
import { amber, grey } from '@mui/material/colors';
import { isEmpty, isArray, isFunction, isEqual } from 'lodash';
import { CheckCircleOutlineRounded, DeleteOutlineRounded } from '@mui/icons-material';

// eslint-disable-next-line
const BodyContainer = ({ children, width, height }) => (
  <Grid container style={{ width, height }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375.42 832.97">
      <g>{children}</g>
    </svg>
  </Grid>
);

// eslint-disable-next-line
const BodyPart = ({ id, d, fill, onClick, onMouseEnter, onMouseLeave, editable }) => {
  const handleClick = () => {
    onClick(id);
  };

  const handleMouseEnter = () => {
    onMouseEnter(id);
  };

  const handleMouseLeave = () => {
    onMouseLeave(id);
  };

  return (
    <path
      d={d}
      id={id}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        WebkitTapHighlightColor: 'transparent',
        cursor: editable ? 'pointer' : 'default',
        fill,
      }}
    />
  );
};

export const BodyMap = ({ initial, onChange, editable }) => {
  const lang = 'en';
  const bodyBaseHeight = 500;
  const bodyBaseWidth = 200;
  const scaling = 1.5;
  const bodyHeight = bodyBaseHeight * scaling;
  const bodyWidth = bodyBaseWidth * scaling;
  const [selected, setSelected] = useState([]);
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
    if (isArray(initial)) {
      if (!isEqual(initial, selected)) {
        setSelected(initial);
      }
    } else {
      setSelected([]);
    }
  }, [initial]); // eslint-disable-line

  const { frontBodyParts, backBodyParts } = useMemo(() => {
    return {
      frontBodyParts: BodyParts.filter(({ side }) => side === 'front'),
      backBodyParts: BodyParts.filter(({ side }) => side === 'back'),
    };
  }, []);

  const resolvedSelected = useMemo(() => {
    const result = selected.map((x) => ({ id: x, name: PartsNames[lang][x] }));

    return result;
  }, [lang, selected]);

  const getFill = useCallback(
    (bodyPartId) => {
      if (selected.includes(bodyPartId)) return amber[600];
      if (editable && hovered === bodyPartId) return grey[600];
      return grey[500];
    },
    [selected, hovered, editable],
  );

  const handleClick = (id) => {
    if (!editable) return;
    const result = selected.includes(id) ? selected.filter((x) => x !== id) : [...selected, id];
    setSelected(result);
    isFunction(onChange) && onChange(result);
  };

  const handleMouseEnter = (id) => {
    if ('ontouchstart' in window) return;
    setHovered(id);
  };

  const handleMouseLeave = () => {
    if ('ontouchstart' in window) return;
    setHovered(null);
  };

  return (
    <Grid container spacing={1} justifyContent={'space-between'} alignItems={'flex-start'}>
      <Grid container item justifyContent={'center'} lg={4} md={4} xs={12}>
        <BodyContainer width={bodyWidth} height={bodyHeight}>
          {frontBodyParts.map((bodyPart, index) => (
            <BodyPart
              key={index}
              id={bodyPart.id}
              d={bodyPart.d}
              fill={getFill(bodyPart.id)}
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              editable={editable}
            />
          ))}
        </BodyContainer>
      </Grid>
      <Grid container justifyContent={'center'} item lg={4} md={4} xs={12}>
        <BodyContainer width={bodyWidth} height={bodyHeight}>
          {backBodyParts.map((bodyPart, index) => (
            <BodyPart
              editable={editable}
              key={index}
              id={bodyPart.id}
              d={bodyPart.d}
              fill={getFill(bodyPart.id)}
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          ))}
        </BodyContainer>
      </Grid>
      <Grid container justifyContent={'center'} item lg={4} md={4} xs={12}>
        <Grid container direction={'column'}>
          {isEmpty(resolvedSelected) ? (
            <Typography color="text.secondary">
              {editable ? 'Click on a body part to select it' : 'Nothing selected'}
            </Typography>
          ) : (
            <List
              subheader={
                <Typography variant="h6" sx={{ ml: 2 }}>
                  Selected
                </Typography>
              }
              sx={{ maxWidth: 300, maxHeight: bodyHeight, overflow: 'auto' }}
            >
              {resolvedSelected.map((part) => (
                <ListItem key={part.id}>
                  <ListItemIcon>
                    <CheckCircleOutlineRounded />
                  </ListItemIcon>
                  <ListItemText primary={part.name} />
                  {editable && (
                    <IconButton size="small" onClick={() => handleClick(part.id)}>
                      <DeleteOutlineRounded color="error" />
                    </IconButton>
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BodyMap;
