import { drawerOptions } from '../../constants';

export const firstDocTour = [
  {
    title: 'Doc Library',
    target: '.addIcon',
    content: 'Add documents or create a Qdoc',
    ...drawerOptions,
  },
  {
    title: 'Areas',
    target: '.areas',
    content: 'See all of your areas',
    ...drawerOptions,
  },
  {
    title: 'Controlled Files',
    target: '.controlled',
    content: 'This will show you all the controlled files you have access to',
    ...drawerOptions,
    locale: {
      last: 'Finish',
    },
  },
];

export const documentSetting = [
  {
    target: '.docTitle',
    content: 'Change Document Title by clicking on edit button',
    ...drawerOptions,
  },
  {
    target: '.tags',
    content: 'Change Document Tags by clicking on edit button',
    ...drawerOptions,
  },
];

export const createArea = [
  {
    target: '.areaName',
    content: 'Add Area Name here',
    ...drawerOptions,
  },
  {
    target: '.storage',
    content: 'Add Area Storage',
    ...drawerOptions,
  },
  {
    target: '.privacy',
    content: 'Toggle Area Privacy by making it public/private',
    ...drawerOptions,
  },
  {
    target: '.createBtn',
    content: 'Click on button to create area',
    ...drawerOptions,
  },
];
