/**
 * * Reducer for General
 */

import {
  GENERAL_ATTEMPT,
  GENERAL_ERROR,
  FETCHCOMPANYGROUPS_ATTEMPT,
  FETCHCOMPANYGROUPS_SUCCESS,
  FETCHCOMPANYGROUPS_FAIL,
  FETCHCOMPANYGROUP_ATTEMPT,
  FETCHCOMPANYGROUP_SUCCESS,
  FETCHCOMPANYADMINS_SUCCESS,
  TOGGLEDRAWER,
  SEARCHATTEMPT,
  SEARCHSUCCESS,
  SEARCHFAIL,
  SELECTHUB,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../constants';

const initResult = {
  mytasks: [],
  dochub: {},
  formhub: [],
  processhub: [],
  assethub: [],
  kpihub: [],
  audithub: [],
};
const initialState = {
  loading: false,
  groups: null,
  group: {},
  fetching: false,
  drawer: document.documentElement.clientWidth / document.documentElement.clientHeight > 4 / 3,
  results: initResult,
  searchLoading: false,
  searchError: null,
  selectedHub: null,
  admins: [],
  notifications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GENERAL_ATTEMPT:
      return { ...state, fetching: true };
    case GENERAL_ERROR:
      return { ...state, fetching: false, loading: false, error: action.payload };
    case FETCHCOMPANYGROUPS_ATTEMPT:
      return { ...state, fetching: true };
    case FETCHCOMPANYGROUPS_SUCCESS:
      return { ...state, fetching: false, groups: action.payload.groups };
    case FETCHCOMPANYGROUPS_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case FETCHCOMPANYGROUP_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case FETCHCOMPANYGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        group: {
          ...state.group,
          [action.payload?._id]: action.payload,
        },
      };
    case FETCHCOMPANYADMINS_SUCCESS:
      return { ...state, fetching: false, admins: action.payload };
    case TOGGLEDRAWER:
      return { ...state, drawer: action.payload.drawer };
    case SEARCHATTEMPT:
      return { ...state, searchLoading: true, searchError: null, results: initResult };
    case SEARCHSUCCESS:
      return { ...state, searchLoading: false, results: action.payload };
    case SEARCHFAIL:
      return { ...state, searchLoading: false, searchError: action.payload };
    case SELECTHUB:
      return { ...state, selectedHub: action.payload };
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.key !== action.key),
      };
    default:
      return state;
  }
}
