import React, { useState, useEffect } from 'react';
import { Button, DialogTitle, DialogContent, Grid, Typography, CircularProgress } from '@mui/material';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { StartAndEndDates, DueDate } from '../workflowComponents';
import { RoundedDialog, SearchMenu, StandardDialogActions } from '../../../Global/Components';
import { StatusButton } from '../Components';
import { red, blue } from '@mui/material/colors';
import axios from 'axios';
import moment from 'moment';
import StateManager from '../../../Global/StateManager';

export default function SendToProjectDialog({ currProjectId, taskId, open, onClose }) {
  const [projects, setProjects] = useState([]);
  const [projectMenu, setProjectMenu] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [startDate, setStartDate] = useState(moment().add(1, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(moment().add(4, 'days').endOf('day'));
  const [due, setDue] = useState(moment().add(4, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }));
  const [status, setStatus] = useState();
  const [saving, setSaving] = useState(false);
  const [isStartEnd, setIsStartEnd] = useState(true);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    if (open && !projects[0]) {
      setLoadingProjects(true);
      axios
        .get('/project/getUserProjects')
        .then((res) => {
          setProjects(res.data.filter((x) => x._id !== currProjectId));
          setLoadingProjects(false);
        })
        .catch((err) => {
          console.error(err);
          setLoadingProjects(false);
        });
    }
    if (!open) {
      setSelectedProject(null);
      setStartDate(moment().add(1, 'day').startOf('day'));
      setEndDate(moment().add(4, 'days').endOf('day'));
      setStatus(null);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStatuses([]);
    if (!selectedProject) {
      return;
    }

    axios
      .get('/project/getProjectStatuses', { params: { id: selectedProject._id } })
      .then(({ data }) => setStatuses(data))
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }, [selectedProject]);

  function moveTask() {
    if (!status) {
      StateManager.setErrorAlert('Select task status');
      return;
    }
    setSaving(true);
    let body = {
      taskId,
      projectId: selectedProject._id,
      startDate: isStartEnd ? startDate : null,
      endDate: isStartEnd ? endDate : null,
      dueAt: isStartEnd ? endDate : due,
      status,
    };
    axios
      .post('/project/moveTaskToProject', body)
      .then((res) => {
        setSaving(false);
        onClose(res.data);
        StateManager.setSuccessAlert(`Task has been moved to project "${selectedProject.title}"`);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <RoundedDialog
      fullWidth
      elevation={28}
      maxWidth={selectedProject ? 'md' : 'sm'}
      open={open}
      onClose={() => onClose(null)}
    >
      <DialogTitle>Move task to project</DialogTitle>
      <DialogContent>
        {loadingProjects ? (
          <Grid container>
            <CircularProgress style={{ color: blue[500], margin: 'auto' }} />
          </Grid>
        ) : (
          <Grid container>
            <Grid container item alignItems="center" sx={{ my: 2 }}>
              <Typography variant="subtitle1" sx={{ mr: 2, fontWeight: 500 }}>
                Send to:
              </Typography>
              {selectedProject && (
                <Typography variant="subtitle1" gutterBottom sx={{ mr: 2 }}>
                  {selectedProject.title}
                </Typography>
              )}
              <Button variant="outlined" onClick={(e) => setProjectMenu(e.currentTarget)}>
                {selectedProject ? 'Change' : 'Select project'}
              </Button>
              <SearchMenu
                items={projects}
                idField="_id"
                textField="title"
                open={Boolean(projectMenu)}
                anchorEl={projectMenu}
                onClose={() => setProjectMenu(null)}
                onResult={setSelectedProject}
                map={(x) => x}
              />
            </Grid>
            {selectedProject && (
              <Grid container>
                <Grid container item sx={{ my: 1 }}>
                  <Grid container item alignItems="center">
                    <Typography sx={{ fontWeight: 500, mr: 2 }}>Due date type</Typography>
                    <RadioGroup
                      row
                      value={String(isStartEnd)}
                      onChange={(event) => setIsStartEnd(event.target.value === 'true')}
                    >
                      <FormControlLabel value="false" control={<Radio />} label="Only due date" />
                      <FormControlLabel value="true" control={<Radio />} label="Start and end dates" />
                    </RadioGroup>
                  </Grid>

                  {!isStartEnd && (
                    <Grid container item>
                      <DueDate editable value={due} setValue={setDue} />
                    </Grid>
                  )}

                  {isStartEnd && (
                    <Grid container item>
                      <StartAndEndDates
                        editable
                        start={startDate}
                        end={endDate}
                        setStart={setStartDate}
                        setEnd={setEndDate}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item container sx={{ my: 1 }} wrap="nowrap" alignItems="center">
                  <Typography variant="subtitle1" sx={{ mr: 2, fontWeight: 500 }}>
                    Select status
                  </Typography>
                  <StatusButton status={status} setStatus={setStatus} projectStatuses={statuses} />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>

      <StandardDialogActions
        onClose={() => onClose(null)}
        saving={saving}
        onDone={moveTask}
        hideDone={!selectedProject}
      />
    </RoundedDialog>
  );
}
