import React, { useEffect, useState } from 'react';
import { Checkbox, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { CircularProgress, Chip, TextField, Typography, Button, FormControlLabel } from '@mui/material';
import { CloseRounded, LabelRounded, NotInterestedRounded, CheckCircleRounded } from '@mui/icons-material';
import { Done, CheckCircleOutlineRounded } from '@mui/icons-material';
import { RoundedDialog } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { grey, red, amber, green } from '@mui/material/colors';
import Autocomplete from '@mui/material/Autocomplete';
import { isArray, isEmpty } from 'lodash';
import axios from 'axios';

export default function FilterDialog({ open, onClose, onResult, initial, kpiId, type }) {
  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const loading = open && fields.length === 0;

  useEffect(() => {
    if (!isEmpty(fields) && isArray(initial) && !isEmpty(initial)) {
      setSelectedFields(
        initial
          .map((item) => {
            const resolved = fields.find((f) => f.fieldId === item.fieldId);
            if (!isArray(resolved?.values) || !isArray(item.values)) return null;
            resolved.values = resolved.values.map((x) => ({
              value: x.value,
              selected: item.values.includes(x.value),
            }));
            return resolved;
          })
          .filter((x) => x),
      );
    } else {
      setSelectedFields([]);
    }
  }, [initial, fields]);

  function done() {
    const result = fields
      .map((field) => ({
        fieldId: field.fieldId,
        stepId: field.stepId,
        values: field.values.filter((x) => x.selected).map((x) => x.value),
      }))
      .filter((x) => x.values.length > 0);

    if (result.length === 0) {
      StateManager.setErrorAlert('Tick at least one box');
      return;
    }

    //console.log(result);

    onResult(result);
    onClose();
  }

  function clear() {
    for (let i = 0; i < fields.length; ++i) {
      for (let j = 0; j < fields[i].values.length; ++j) {
        fields[i].values[j].selected = false;
      }
    }

    setFields([...fields]);

    onResult(null);
    onClose();
  }

  useEffect(() => {
    const urls = {
      form: '/forms/kpi/getKpiFilters',
      process: '/process/kpi/getKpiFilters',
    };
    if (!kpiId || !loading || !urls[type]) return;
    axios
      .get(urls[type], { params: { kpiId } })
      .then((res) => {
        setFields(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [loading, kpiId, type]); // eslint-disable-line

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select filters</DialogTitle>
      <DialogContent>
        <Grid container alignItems="baseline">
          {!loading && !isEmpty(fields) && (
            <Grid container sx={{ mb: 4, mt: 1 }}>
              <Autocomplete
                multiple
                fullWidth
                disableCloseOnSelect
                value={selectedFields}
                onChange={(event, newValue) => setSelectedFields([...newValue])}
                getOptionLabel={(option) => (option ? option.title : '')}
                options={fields}
                renderInput={(params) => <TextField {...params} label="Select fields" variant="outlined" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip color="primary" label={option.title} {...getTagProps({ index })} />
                  ))
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Grid container item alignItems="center">
                      <LabelRounded style={{ color: amber[500] }} />
                      <Typography style={{ marginLeft: '1rem' }}>{option.title}</Typography>
                      {selected ? (
                        <CheckCircleRounded fontSize="small" style={{ color: green[500], marginLeft: 'auto' }} />
                      ) : (
                        <CheckCircleOutlineRounded fontSize="small" style={{ color: grey[300], marginLeft: 'auto' }} />
                      )}
                    </Grid>
                  </li>
                )}
              />
            </Grid>
          )}
          {!loading && !isEmpty(fields) && isEmpty(selectedFields) && (
            <Grid container style={{ height: '25vh' }}>
              <Typography gutterBottom variant="h5" color="textSecondary">
                Please select the fields
              </Typography>
            </Grid>
          )}
          {loading && (
            <Grid container style={{ height: '40vh' }}>
              <CircularProgress color="primary" size={32} style={{ margin: 'auto' }} />
            </Grid>
          )}
          {!loading && isEmpty(fields) && (
            <Typography gutterBottom variant="h5" color="textSecondary">
              No fields availabe to filter
            </Typography>
          )}
          {!loading &&
            selectedFields.map((field, i) => (
              <Grid container item xs={4} key={field.fieldId} style={{ padding: 8 }}>
                <Grid container item>
                  <Typography gutterBottom variant="h5">
                    {field.title}
                  </Typography>
                </Grid>

                {field.values.map((value, j) => (
                  <Grid container item key={value.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={Boolean(value.selected)}
                          onChange={(e) => {
                            selectedFields[i].values[j].selected = e.target.checked;
                            setSelectedFields([...selectedFields]);
                          }}
                        />
                      }
                      label={value.value}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: red[500], marginRight: 'auto' }} onClick={clear} startIcon={<NotInterestedRounded />}>
          Clear filters
        </Button>

        <Button style={{ color: red[500] }} onClick={onClose} startIcon={<CloseRounded />}>
          cancel
        </Button>
        <Button color="primary" variant="contained" onClick={done} startIcon={<Done />}>
          Done
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
