import { red, grey } from '@mui/material/colors';
import { DialogContent, DialogTitle, Button, Grid, Typography, TextField, useTheme } from '@mui/material';
import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Collapse, Divider } from '@mui/material';
import { DeleteOutlined, GroupAdd, WorkOutlineRounded } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, GeneralButton, HtmlContent, StandardDialogActions, UserChip } from '../Components';
import StateManager from '../StateManager';
import { TaskIcon } from '../Icons';
import { PriorityButton, Fields, SubTasks, TaskTemplatesDialog } from '../../Hubs/tasks/Components';
import Uploader from '../Uploader';
import { checkFields } from '../Functions';

export default function EditTaskDialog({ open, onClose, onResult, task, onDelete }) {
  const theme = useTheme();
  const [ownerSelect, setOwnerSelect] = useState('any');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [ownerList, setOwnerList] = useState([]);
  const [priority, setPriority] = useState('Low');
  const [attachments, setAttachments] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [fields, setFields] = useState([]);

  const [taskTemplateDialog, setTaskTemplateDialog] = useState(false);
  const [templateId, setTemplateId] = useState();

  function deleteTask() {
    onDelete();
    onClose();
  }

  function done() {
    if (!title) {
      StateManager.setErrorAlert('Provide title');
      return;
    }

    if (ownerSelect === 'list' && ownerList.length === 0) {
      StateManager.setErrorAlert('Provide list of owners');
      return;
    }

    const check = checkFields(fields);
    if (check.error) {
      StateManager.setErrorAlert(check.error);
      return;
    }

    const result = {
      ownerSelect,
      description,
      title,
      ownerList: ownerSelect === 'list' ? ownerList : null,
      priority,
      attachments,
      subTasks,
      templateId,
      fields,
    };

    onResult(result);
    onClose();
  }

  function addUsers() {
    StateManager.selectMultipleUsers(
      (res) => {
        if (res.users) {
          setOwnerList(res.users);
        }
      },
      { initiallySelected: ownerList },
    );
  }

  function fillFromTemplate(template) {
    if (!template) return;
    setTemplateId(template._id);
    setSubTasks(template.subTasks || []);
    setFields(template.fields);
    setAttachments(template.attachments || []);
    setDescription(template.description || '');
  }

  useEffect(() => {
    if (!open) return;

    setOwnerSelect(task ? task.ownerSelect : 'any');
    setDescription(task ? task.description : '');
    setTitle(task ? task.title : '');
    setOwnerList(task && task.ownerList ? task.ownerList : []);
    setPriority(task && task.priority ? task.priority : 'Low');
    setAttachments(task?.attachments || []);
    setSubTasks(task?.subTasks || []);
    setFields(task?.fields || []);
  }, [open]); // eslint-disable-line

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth className="scroll-bar">
      <DialogTitle>
        <Grid container item alignItems="center" justifyContent="center">
          <TaskIcon />
          <Typography variant="h6" style={{ marginLeft: '1rem' }}>
            Create a task
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column">
          <Grid
            container
            item
            style={{ margin: '1rem 0', paddingBottom: '1rem', borderBottom: `1px solid ${grey[300]}` }}
          >
            <Grid container item>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Task owner:</FormLabel>
                <RadioGroup row value={ownerSelect} onChange={(event) => setOwnerSelect(event.target.value)}>
                  <FormControlLabel
                    value="list"
                    control={<Radio color="primary" />}
                    label="Chosen by user from the list"
                  />
                  <FormControlLabel
                    value="any"
                    control={<Radio color="primary" />}
                    label="Chosen by user from anyone"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Collapse in={ownerSelect === 'list'} style={{ width: '100%' }}>
              <Grid container item style={{ margin: '0.5rem 0' }}>
                <GeneralButton onClick={addUsers} startIcon={<GroupAdd style={{ color: grey[500] }} />}>
                  Add users
                </GeneralButton>
              </Grid>
              <Grid container item>
                {ownerList.map((user) => (
                  <UserChip key={user} id={user} onDelete={() => setOwnerList(ownerList.filter((x) => x !== user))} />
                ))}
              </Grid>
            </Collapse>
          </Grid>

          <Grid container item alignItems="center" style={{ margin: '1rem 0' }}>
            <Typography style={{ marginRight: '1rem', fontWeight: 600 }}>Title:</Typography>
            <TextField
              value={title}
              variant="outlined"
              placeholder="Enter title"
              style={{ flexGrow: 1 }}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>

          <Grid container item alignItems="center" style={{ padding: theme.spacing(2, 0) }}>
            <Typography style={{ marginRight: '1rem', fontWeight: 600 }}>Priority:</Typography>
            <PriorityButton priority={priority} setPriority={setPriority}></PriorityButton>
          </Grid>

          <Grid container item style={{ padding: theme.spacing(2, 0) }}>
            <HtmlContent
              editable
              title="Task description:"
              placeholder="Provide description for the task"
              onSave={setDescription}
              onEdit={setDescription}
              content={description}
            />
          </Grid>

          <Divider />

          <Grid container item style={{ padding: theme.spacing(2, 0) }}>
            <Typography variant="h6">Attachments:</Typography>
            <Uploader uploaded={attachments} onChange={setAttachments} withLinkedFeatures={true} />
          </Grid>

          <Divider />

          <Grid container item style={{ padding: theme.spacing(2, 0) }}>
            <Typography variant="h6">Task Fields:</Typography>
            <Fields initial={fields} onItemsChange={setFields} hideTasks />
          </Grid>

          <Divider />

          <Grid container item style={{ padding: theme.spacing(2, 0) }}>
            <SubTasks subTasks={subTasks} setSubTasks={setSubTasks} isNewTask />
          </Grid>

          <TaskTemplatesDialog
            open={taskTemplateDialog}
            onClose={() => setTaskTemplateDialog(false)}
            onResult={fillFromTemplate}
          />
        </Grid>
      </DialogContent>

      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        additionalActions={
          <>
            {task && (
              <Button startIcon={<DeleteOutlined />} onClick={deleteTask} style={{ color: red[500], borderRadius: 8 }}>
                delete task
              </Button>
            )}
            <Button
              onClick={() => setTaskTemplateDialog(true)}
              startIcon={<WorkOutlineRounded />}
              style={{ color: grey[600], borderRadius: 8, marginRight: 'auto' }}
            >
              use task template
            </Button>
          </>
        }
      />
    </RoundedDialog>
  );
}
