import React, { useEffect, useRef } from 'react';
import { StandardDialogActions } from '../Components';
import { Dialog } from '@mui/material';

const DocumentIFrame = React.forwardRef((props, ref) => {
  const width = window.document.documentElement.clientWidth;
  const isXL = width >= 2000;

  return (
    <iframe
      id={`documentFrame-${props.title}`}
      src={props.src}
      title={props.title}
      width="100%"
      height={
        isXL
          ? window.document.documentElement.clientHeight
          : window.document.documentElement.clientHeight - window.document.documentElement.clientHeight * 0.3
      }
      ref={ref}
      style={{ border: '0px' }}
    />
  );
});

const ExternalPdfViewer = ({ url, title }) => {
  const ref = useRef();
  const [previewUrl, setPreviewUrl] = React.useState(null);

  useEffect(() => {
    setPreviewUrl(url || '');
  }, [url]);

  return <DocumentIFrame ref={ref} src={previewUrl} title={title} />;
};

export const ExternalPdfViewerDialog = ({ url, title, open, onClose }) => {
  if (!url) return null;
  if (!open) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullScreen>
      <ExternalPdfViewer url={url} title={title} />
      <StandardDialogActions onClose={onClose} />
    </Dialog>
  );
};

export default ExternalPdfViewer;
