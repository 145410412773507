import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

export default function ProcessRedirect() {
  return (
    <Switch>
      <Route exact path="/workflow/home">
        <Redirect to="/processes/home" />
      </Route>

      <Route exact path="/workflow/flows">
        <Redirect to="/processes/home" />
      </Route>

      <Route exact path="/workflow/settings">
        <Redirect to="/processes/settings" />
      </Route>

      <Route exact path="/workflow/working">
        <Redirect to="/processes/working" />
      </Route>

      <Route exact path="/workflow/completed">
        <Redirect to="/processes/completed" />
      </Route>

      <Route exact path="/workflow/bookmarked">
        <Redirect to="/processes/bookmarked" />
      </Route>

      <Route exact path="/workflow/cancelled">
        <Redirect to="/processes/cancelled" />
      </Route>

      <Route exact path="/workflow/held">
        <Redirect to="/processes/held" />
      </Route>

      <Route exact path="/workflow/watching">
        <Redirect to="/processes/watching" />
      </Route>

      <Route exact path="/workflow/drafts">
        <Redirect to="/processes/drafts" />
      </Route>

      <Route exact path="/workflow/forms">
        <Redirect to="/forms/home" />
      </Route>

      <Route exact path="/workflow/new-form">
        <Redirect to="/forms/form-builder" />
      </Route>

      <Route
        path="/workflow/processes/info/:id/:option?"
        render={({ match }) => <Redirect to={`/processes/${match.params.id}`} />}
      />

      <Route
        path="/workflow/new-process/:id?"
        render={({ match }) => (
          <Redirect to={`/processes/process-builder${match.params.id ? '/' : ''}${match.params.id || ''}`} />
        )}
      />

      <Route
        path="/workflow/new-form/:id?"
        render={({ match }) => (
          <Redirect to={`/forms/form-builder${match.params.id ? '/' : ''}${match.params.id || ''}`} />
        )}
      />

      <Route
        path="/workflow/processes/ongoing/:id/:stepId?"
        render={({ match }) => (
          <Redirect
            to={`/processes/ongoing/${match.params.id}${match.params.stepId ? '/' : ''}${match.params.stepId || ''}`}
          />
        )}
      />

      <Route
        path="/workflow/processes/step/:id"
        render={({ match }) => <Redirect to={`/processes/step/${match.params.id}`} />}
      />

      <Route
        path="/workflow/start-process/:processId"
        render={({ match }) => <Redirect to={`/processes/start-process/${match.params.processId}`} />}
      />

      <Route
        path="/workflow/processes/info/:id/:option?"
        render={({ match }) => <Redirect to={`/processes/${match.params.id}`} />}
      />

      <Route
        path="/workflow/forms/entry/:entryId"
        render={({ match }) => <Redirect to={`/forms/entry/${match.params.entryId}`} />}
      />

      <Route
        path="/workflow/forms/info/:formId"
        render={({ match }) => <Redirect to={`/forms/${match.params.formId}`} />}
      />

      <Route
        path="/workflow/forms/:formId/new-entry"
        render={({ match }) => <Redirect to={`/forms/${match.params.formId}/new-entry`} />}
      />
    </Switch>
  );
}
