import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { blue } from '@mui/material/colors';

export default function AssetRecall({ assetId }) {
  const [assetInfo, setAssetInfo] = useState(null);

  useEffect(() => {
    if (!assetId) return;
    axios
      .get('/asset/getAssetInfo', { params: { assetId } })
      .then((res) => setAssetInfo(res.data))
      .catch((err) => console.error(err));
  }, [assetId]);

  if (!assetInfo) return null;

  return (
    <Grid container item alignItems="center" style={{ margin: '1em 0' }}>
      <Typography style={{ fontSize: 18, fontWeight: 500, marginRight: '0.25em' }}>This is task for asset</Typography>
      <Link to={`/asset/${assetInfo._id}`} style={{ fontSize: 18, fontWeight: 500, color: blue[800] }}>
        {assetInfo.title}
      </Link>
    </Grid>
  );
}
