import React, { useState, useEffect } from 'react';
import { green, red, grey } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, TextField, MenuItem, IconButton, Avatar } from '@mui/material';
import { Typography, FormControlLabel, Checkbox, Collapse } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { RoundedDialog, UserAutocomplete, User, GeneralButton } from '../../../Global/Components';
import { StandardDialogActions } from '../../../Global/Components';
import { DeleteOutline, Public, Person } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import StateManager from '../../../Global/StateManager';
import axios from 'axios';
import { v4 } from 'uuid';

const triggers = [
  { id: 'stepStarted', text: 'Each step is started' },
  { id: 'stepCompleted', text: 'Each step is completed' },
  { id: 'stepOverdue', text: 'Any step becomes overdue' },
  { id: 'processCompleted', text: 'Process is completed' },
];

export default function ProcessNotificationsDialog({ open, onClose, ongoingProcessId }) {
  const [notifyList, setNotifyList] = useState(null);
  const [externalDialog, setExternalDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [addUserDialog, setAddUserDialog] = useState(false);

  const theme = useTheme();

  const load = open && notifyList == null && !loading && !error;

  useEffect(() => {
    if (!load || !ongoingProcessId) return;

    setLoading(true);

    axios
      .get('/process/getProcessNotificationsInfo', { params: { ongoingProcessId } })
      .then((res) => {
        if (!res.data.notifyList) throw Error('');
        setNotifyList(res.data.notifyList);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setError(true);
        StateManager.setErrorAlert('Failed to load step notifications info');
      });
  }, [load, ongoingProcessId]);

  function done() {
    setSaving(true);
    axios
      .post('/process/saveProcessNotificationsInfo', { ongoingProcessId, notifyList })
      .then(() => {
        onClose();
        StateManager.setSuccessAlert('Settings have been saved');
        setSaving(false);
      })
      .catch((err) => {
        StateManager.setErrorAlert('Failed to save the notification settings');
        console.error(err);
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth className="scroll-bar">
      <DialogTitle>Select users to notify</DialogTitle>
      <DialogContent>
        {loading && (
          <Grid container alignItems="center" justifyContent="center" style={{ height: '35vh' }}>
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!loading && error && (
          <Grid container alignItems="center" justifyContent="center" style={{ height: '35vh' }}>
            <Typography variant="h5" color="textSecondary">
              Something went wrong :-(
            </Typography>
          </Grid>
        )}
        {!loading && !error && notifyList && (
          <Grid container>
            <Grid container item>
              {notifyList.map((item) => (
                <Grid
                  key={item.id}
                  container
                  item
                  alignItems="center"
                  wrap="nowrap"
                  style={{ padding: theme.spacing(1, 0), borderBottom: `2px solid ${grey[300]}` }}
                >
                  {(() => {
                    if (item.type === 'user') {
                      return (
                        <>
                          <User id={item.userId} onlyAvatar />
                          <Typography style={{ margin: '0 0.5em', textTransform: 'lowercase' }}>
                            {`will be sent ${item.includePdf ? 'a PDF copy of the process' : 'an email'} when`}{' '}
                            <span style={{ fontWeight: 500, textTransform: 'lowercase', whiteSpace: 'nowrap' }}>
                              {triggers.find((t) => t.id === item.triggerId)?.text}
                            </span>
                          </Typography>
                        </>
                      );
                    } else {
                      return (
                        <Grid item>
                          <Typography>
                            <span style={{ fontWeight: 600 }}>{item.email}</span>{' '}
                            {`will be sent ${item.includePdf ? 'a PDF copy of the process' : 'an email'} when`}{' '}
                            <span style={{ fontWeight: 500, textTransform: 'lowercase', whiteSpace: 'nowrap' }}>
                              {triggers.find((x) => x.id === item.triggerId)?.text}
                            </span>
                          </Typography>
                        </Grid>
                      );
                    }
                  })()}
                  <IconButton
                    style={{ marginLeft: 'auto' }}
                    onClick={() => setNotifyList(notifyList.filter((x) => x.id !== item.id))}
                  >
                    <DeleteOutline style={{ color: red[500] }} />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
            <Grid container item alignItems="center" style={{ margin: theme.spacing(2, 0) }}>
              <GeneralButton
                onClick={() => setExternalDialog(true)}
                startIcon={<Public style={{ color: grey[500] }} />}
              >
                Add email
              </GeneralButton>
              <External
                open={externalDialog}
                onClose={() => setExternalDialog(false)}
                onResult={(res) => setNotifyList([...notifyList, res])}
              />

              <GeneralButton
                onClick={() => setAddUserDialog(true)}
                style={{ marginLeft: '1rem' }}
                startIcon={<Person style={{ color: grey[500] }} />}
              >
                Add user
              </GeneralButton>
              <AddUser
                open={addUserDialog}
                onClose={() => setAddUserDialog(false)}
                onResult={(res) => setNotifyList([...notifyList, res])}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions saving={saving} onClose={onClose} onDone={done} hideDone={!notifyList} />
    </RoundedDialog>
  );
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function External({ open, onClose, onResult }) {
  const [text, setText] = useState('');
  const [error, setError] = useState(false);
  const [triggerId, setTriggerId] = useState(triggers[0].id);
  const [includePdf, setIncludePdf] = useState(false);

  function add() {
    if (!validateEmail(text)) {
      setError(true);
      return;
    }
    onResult({
      id: v4(),
      type: 'external',
      email: text,
      triggerId,
      includePdf: (triggerId === 'stepCompleted' || triggerId === 'processCompleted') && includePdf,
    });
    onClose();
    setText('');
    setError(false);
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Add an email</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item style={{ height: 100 }}>
            <TextField
              error={error}
              variant="outlined"
              label="Email"
              helperText={error ? 'Incorrect email format' : undefined}
              fullWidth
              value={text}
              onChange={(e) => {
                if (error) setError(false);
                setText(e.target.value);
              }}
            />
          </Grid>

          <Grid container item alignItems="center">
            <Typography variant="h6">Send when:</Typography>
            <TextField
              select
              label="Select"
              value={triggerId}
              onChange={(event) => setTriggerId(event.target.value)}
              style={{ marginLeft: '1rem', flexGrow: 1 }}
              variant="standard"
            >
              {triggers.map((trigger) => (
                <MenuItem key={trigger.id} value={trigger.id}>
                  {trigger.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid container item style={{ paddingTop: '1rem' }}>
            <Collapse in={triggerId === 'stepCompleted' || triggerId === 'processCompleted'}>
              <FormControlLabel
                control={
                  <Checkbox checked={includePdf} color="primary" onChange={(e) => setIncludePdf(e.target.checked)} />
                }
                label="Would you like to include a PDF copy of the process?"
              />
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={add} hideDone={!text} />
    </RoundedDialog>
  );
}

function AddUser({ open, onClose, onResult }) {
  const [triggerId, setTriggerId] = useState(triggers[0].id);
  const [includePdf, setIncludePdf] = useState(false);
  const [user, setUser] = useState(null);

  function add() {
    onResult({
      id: v4(),
      type: 'user',
      userId: user._id,
      triggerId,
      includePdf: (triggerId === 'stepCompleted' || triggerId === 'processCompleted') && includePdf,
    });
    setUser(null);
    setTriggerId(triggers[0].id);
    setIncludePdf(false);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Add a user</DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid container item wrap="nowrap" alignItems="center">
            <Avatar src={user?.avatar} style={{ background: user?.avatarColor }}>
              {user?.avatarLetters}
            </Avatar>
            <UserAutocomplete onResult={setUser} style={{ flexGrow: 1, marginLeft: '1rem' }} selected={user} />
          </Grid>

          <Grid container item alignItems="center" style={{ paddingTop: '1rem' }}>
            <Typography style={{ fontWeight: 600 }}>Send when:</Typography>
            <TextField
              select
              label="Select"
              value={triggerId}
              onChange={(event) => setTriggerId(event.target.value)}
              style={{ marginLeft: '1rem', flexGrow: 1 }}
              variant="standard"
            >
              {triggers.map((trigger) => (
                <MenuItem key={trigger.id} value={trigger.id}>
                  {trigger.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid container item style={{ paddingTop: '1rem' }}>
            <Collapse in={triggerId === 'stepCompleted' || triggerId === 'processCompleted'}>
              <FormControlLabel
                control={
                  <Checkbox checked={includePdf} color="primary" onChange={(e) => setIncludePdf(e.target.checked)} />
                }
                label="Would you like to include a PDF copy of the process?"
              />
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={add} hideDone={!user} />
    </RoundedDialog>
  );
}
