/**
 * * Reducer for Document Reviews, follows the cases as per relevant actions
 */
import {
  GETREVIEWS_ATTEMPT,
  GETREVIEWS_SUCCESS,
  GETREVIEWS_FAIL,
  GETREVIEW_ATTEMPT,
  GETREVIEW_SUCCESS,
  GETREVIEW_FAIL,
  ADDREVIEW_ATTEMPT,
  ADDREVIEW_SUCCESS,
  ADDREVIEW_FAIL,
  UPDATEREVIEW_ATTEMPT,
  UPDATEREVIEW_SUCCESS,
  UPDATEREVIEW_FAIL,
  ADDTASK_ATTEMPT,
  ADDTASK_SUCCESS,
  ADDTASK_FAIL,
  SETTASK,
  SETTASKDIALOG,
  SETREVIEWMODE,
  UPDATETASK_ATTEMPT,
  UPDATETASK_SUCCESS,
  UPDATETASK_FAIL,
  DELETETASK_ATTEMPT,
  DELETETASK_SUCCESS,
  DELETETASK_FAIL,
  GETTASKSBYREVIEW_ATTEMPT,
  GETTASKSBYREVIEW_SUCCESS,
  GETTASKSBYREVIEW_FAIL,
  SETREVIEW,
  FETCHTASK_ATTEMPT,
  FETCHTASK_SUCCESS,
  FETCHTASK_FAIL,
  ADDHUBTASK_ATTEMPT,
  ADDHUBTASK_SUCCESS,
  ADDHUBTASK_FAIL,
  UPDATEHUBTASK_ATTEMPT,
  UPDATEHUBTASK_SUCCESS,
  UPDATEHUBTASK_FAIL,
  DELETEHUBTASK_ATTEMPT,
  DELETEHUBTASK_SUCCESS,
  DELETEHUBTASK_FAIL,
  CANCELREVIEW_ATTEMPT,
  CANCELREVIEW_SUCCESS,
  CANCELREVIEW_FAIL,
  ADDREVIEWCOMMENT,
  UPDATEREVIEWCOMMENT,
  DELETEREVIEWCOMMENT,
  STARTREVIEW_ATTEMPT,
  STARTREVIEW_SUCCESS,
  STARTREVIEW_FAIL,
  REVIEWCOMMENT_ATTEMPT,
  REVIEWCOMMENT_FAIL,
} from '../constants';

const initialState = {
  reviews: [],
  error: null,
  reviewsLoading: false,
  reviewStarting: false,
  reviewLoading: false,
  reviewUpdating: false,
  commentLoading: false,
  review: null,
  document: null,
  task: null,
  tasks: [],
  reviewTask: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETREVIEWS_ATTEMPT:
      return { ...state, reviewsLoading: true, reviews: [] };
    case GETREVIEWS_SUCCESS:
      return { ...state, reviewsLoading: false, reviews: action.payload.reviews };
    case GETREVIEWS_FAIL:
      return { ...state, reviewsLoading: false, error: action.payload };

    case GETREVIEW_ATTEMPT:
      return { ...state, reviewLoading: true, review: null };
    case GETREVIEW_SUCCESS:
      return {
        ...state,
        reviewLoading: false,
        review: {
          ...action.payload.review,
          resetReviewNow:
            action.payload.review?.resetReviewNow !== undefined ? action.payload.review.resetReviewNow : null,
          triggerUpIssue:
            action.payload.review?.triggerUpIssue !== undefined ? action.payload.review.triggerUpIssue : null,
        },
        document: action.payload.document,
        reviewTask: action.payload.task,
      };
    case GETREVIEW_FAIL:
      return { ...state, reviewLoading: false, error: action.payload };

    case SETREVIEWMODE:
      return { ...state, reviewEdit: action.payload };
    case SETREVIEW:
      return { ...state, review: action.payload };

    case ADDREVIEW_ATTEMPT:
      return { ...state, controlLoading: true, showTaskDialog: false, reviewEdit: false };
    case ADDREVIEW_SUCCESS:
      return {
        ...state,
        controlLoading: false,
        reviews: [...state.reviews, action.payload.review],
        review: action.payload.review,
        taskDialog: false,
        tasks: null,
        reviewEdit: true,
      };
    case ADDREVIEW_FAIL:
      return {
        ...state,
        controlLoading: false,
        error: action.payload,
        showTaskDialog: false,
        review: null,
        reviewEdit: false,
      };

    case STARTREVIEW_ATTEMPT:
      return { ...state, reviewStarting: true };
    case STARTREVIEW_SUCCESS:
      return {
        ...state,
        reviewStarting: false,
        reviews: [action.payload.review, ...state.reviews],
      };
    case STARTREVIEW_FAIL:
      return { ...state, reviewStarting: false, error: action.payload };

    case UPDATEREVIEW_ATTEMPT:
      return { ...state, reviewUpdating: true, reviewMessage: null };
    case UPDATEREVIEW_SUCCESS:
      let updatedReviews = state.reviews
        ? state.reviews.map((d) => {
            if (d._id === action.payload.review._id) {
              return action.payload.review;
            }
            return d;
          })
        : [action.payload.review];
      return {
        ...state,
        reviewUpdating: false,
        reviews: updatedReviews,
        review: {
          ...action.payload.review,
          resetReviewNow:
            action.payload.review?.resetReviewNow !== undefined ? action.payload.review?.resetReviewNow : null,
          triggerUpIssue:
            action.payload.review?.triggerUpIssue !== undefined ? action.payload.review?.triggerUpIssue : null,
        },
        reviewMessage: action.payload.message,
        tasks: null,
      };
    case UPDATEREVIEW_FAIL:
      return { ...state, reviewUpdating: false, error: action.payload, reviewMessage: 'Error Saving Review' };

    case CANCELREVIEW_ATTEMPT:
      return { ...state, controlLoading: true };
    case CANCELREVIEW_SUCCESS:
      return { ...state, controlLoading: false, reviews: state.reviews.filter((x) => x._id !== action.payload.id) };
    case CANCELREVIEW_FAIL:
      return { ...state, controlLoading: false, error: action.payload };

    case REVIEWCOMMENT_ATTEMPT:
      return { ...state, commentLoading: true };
    case REVIEWCOMMENT_FAIL:
      return { ...state, commentLoading: false, error: action.payload };
    case ADDREVIEWCOMMENT:
      return { ...state, commentLoading: false, review: { ...state.review, comments: action.payload.comments } };
    case UPDATEREVIEWCOMMENT:
      return { ...state, commentLoading: false, review: { ...state.review, comments: action.payload.comments } };
    case DELETEREVIEWCOMMENT:
      return {
        ...state,
        commentLoading: false,
        review: { ...state.review, comments: state.review.comments.filter((x) => x._id !== action.payload.id) },
      };

    case ADDTASK_ATTEMPT:
      return { ...state, fetching: true };
    case ADDTASK_SUCCESS:
      return {
        ...state,
        fetching: false,
        tasks: state.tasks ? state.tasks.concat([action.payload]) : [action.payload],
      };
    case ADDTASK_FAIL:
      return { ...state, fetching: false, error: action.payload };

    case ADDHUBTASK_ATTEMPT:
      return { ...state, fetching: true };
    case ADDHUBTASK_SUCCESS:
      const { review, ...hubTask } = action.payload;
      return {
        ...state,
        fetching: false,
        hubTask: hubTask,
        review: review,
        reviews: [...state.reviews, review],
        reviewEdit: true,
        tasks: null,
      };
    case ADDHUBTASK_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case UPDATEHUBTASK_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATEHUBTASK_SUCCESS:
      return { ...state, fetching: false, hubTask: action.payload };
    case UPDATEHUBTASK_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case DELETEHUBTASK_ATTEMPT:
      return { ...state, fetching: true };
    case DELETEHUBTASK_SUCCESS:
      return { ...state, fetching: false, hubTask: null };
    case DELETEHUBTASK_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case SETTASKDIALOG:
      return { ...state, taskDialog: action.payload };
    case SETTASK:
      return { ...state, task: action.payload };
    case UPDATETASK_ATTEMPT:
      return { ...state, fetching: true };
    case UPDATETASK_SUCCESS:
      let updatedTasks = state.tasks.map((d) => {
        if (d._id === action.payload._id) {
          return action.payload;
        }
        return d;
      });
      return {
        ...state,
        tasks: updatedTasks,
        taskDialog: false,
        fetching: false,
      };
    case UPDATETASK_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case FETCHTASK_ATTEMPT:
      return { ...state, fetching: true, taskDialog: false };
    case FETCHTASK_SUCCESS:
      return {
        ...state,
        task: action.payload.task,
        taskDialog: true,
        fetching: false,
      };
    case FETCHTASK_FAIL:
      return { ...state, fetching: false, error: action.payload, taskDialog: false };
    case DELETETASK_ATTEMPT:
      return { ...state, fetching: true };
    case DELETETASK_SUCCESS:
      return { ...state, fetching: false, tasks: state.tasks.filter((a) => a._id !== action.payload.id) };
    case DELETETASK_FAIL:
      return { ...state, fetching: false, error: action.payload };
    case GETTASKSBYREVIEW_ATTEMPT:
      return { ...state, fetching: true, taskDialog: false, tasks: null };
    case GETTASKSBYREVIEW_SUCCESS:
      return { ...state, fetching: false, tasks: action.payload ? action.payload : [] };
    case GETTASKSBYREVIEW_FAIL:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
