import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { RoundedDialog, StandardDialogActions, RichTextField, HtmlText } from './index';

export default function TextDialog({
  open,
  initial,
  editable,
  onClose,
  onResult,
  title,
  required,
  onlyText,
  disableImages,
}) {
  const [text, setText] = useState('');

  useEffect(() => {
    if (!open) return;
    const value = initial || '';
    setText(value);
  }, [initial, open]);

  function done() {
    onResult(text);
    onClose();
    setText('');
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title || 'Text'}</DialogTitle>
      <DialogContent>
        <Grid container>
          {editable ? (
            onlyText ? (
              <TextField
                fullWidth
                variant="outlined"
                multiline
                minRows={5}
                maxRows={30}
                value={text}
                inputProps={{ maxLength: 16384 }}
                onChange={(e) => setText(e.target.value)}
                id={`text-dialog-input`}
              />
            ) : (
              <RichTextField
                initial={text}
                onChange={setText}
                onDone={setText}
                editOpen
                hideButton
                savingTimeout={100}
                disableImages={disableImages}
              />
            )
          ) : (
            <HtmlText value={text} />
          )}
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={!editable || (required && !text)} />
    </RoundedDialog>
  );
}
