import React, { useState, forwardRef } from 'react';
import { IconButton } from '@mui/material';
import { AddCircleOutlineRounded, AddCircleRounded } from '@mui/icons-material';
import { green } from '@mui/material/colors';

const AddHoverButton = forwardRef(({ color = green[500], ...props }, ref) => {
  const [hover, setHover] = useState(false);
  return (
    <IconButton onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} {...props} ref={ref}>
      {hover ? <AddCircleRounded style={{ color }} /> : <AddCircleOutlineRounded style={{ color }} />}
    </IconButton>
  );
});

export default AddHoverButton;
