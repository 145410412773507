import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { isFunction, isNumber } from 'lodash';

export default function DurationPicker({ prefix, suffix, initial, onChange, fieldWidth = 100, ...props }) {
  const SAVING_TIMEOUT = 500;

  const timer = useRef(null);
  const [hours, setHours] = useState('');
  const [days, setDays] = useState('');

  useEffect(() => {
    setHours(isNumber(initial?.hours) ? initial.hours : '');
    setDays(isNumber(initial?.days) ? initial.days : '');
  }, [initial]);

  function handleChange(value) {
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    if (!isFunction(onChange)) return;

    timer.current = setTimeout(() => {
      onChange(value);
    }, SAVING_TIMEOUT);
  }

  return (
    <Grid container alignItems="baseline" {...props}>
      {prefix && <Typography>{prefix}</Typography>}
      <TextField
        label="Days"
        type="number"
        value={days}
        onChange={(e) => {
          const value = e.target.value === '' ? '' : Number(e.target.value);
          if (value !== '' && value < 0) return;
          setDays(value);
          handleChange({ days: value, hours });
        }}
        onWheel={(e) => e.target.blur()}
        style={{ marginLeft: prefix ? 16 : 0, width: fieldWidth }}
      />
      <TextField
        label="Hours"
        type="number"
        value={hours}
        onChange={(e) => {
          const value = e.target.value === '' ? '' : Number(e.target.value);
          if (value !== '' && value < 0) return;
          setHours(value);
          handleChange({ days, hours: value });
        }}
        onWheel={(e) => e.target.blur()}
        style={{ marginLeft: 16, width: fieldWidth }}
      />
      {suffix && <Typography style={{ marginLeft: 16 }}>{suffix}</Typography>}
    </Grid>
  );
}
