import React, { useState, useEffect } from 'react';
import { Grid, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import { Switch, Collapse } from '@mui/material';
import { RoundedDialog, StandardDialogActions, DateTimePicker } from '../../../Global/Components';
import moment from 'moment';

export default function EntryDueDate({ initial, open, onClose, onResult }) {
  const defaultDueDate = moment().add(2, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 });
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (!open) return;
    setDate(initial || null);
  }, [open, initial]);

  function done() {
    onResult(moment(date).set({ seconds: 0, milliseconds: 0 }).toDate());
    onClose();
  }
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Select due date</DialogTitle>
      <DialogContent>
        <Grid container>
          <Collapse style={{ width: '100%' }} in={Boolean(date)}>
            <DateTimePicker value={date} onChange={setDate} />
          </Collapse>
          <Grid item container>
            <FormControlLabel
              control={
                <Switch color="primary" checked={!date} onChange={() => setDate(date ? null : defaultDueDate)} />
              }
              label="No due date"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onDone={done} onClose={onClose} />
    </RoundedDialog>
  );
}
