import { red, grey, blue, amber } from '@mui/material/colors';
import { DialogContent, DialogTitle, DialogActions, Button, Grid, Typography } from '@mui/material';
import { ListItemText, ListItemIcon, IconButton } from '@mui/material';
import { Paper, List, ListItem, useMediaQuery } from '@mui/material';
import { FiberManualRecord, FileCopyRounded } from '@mui/icons-material';
import { TextFields, Label } from '@mui/icons-material';
import { Visibility } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { RoundedDialog, HtmlContent, StandardDialogActions } from '../Components';
import { FormIcon, RequiredIcon, ProjectIcon, TaskIcon, ReadUnderstoodIcon } from '../Icons';
import { useTheme } from '@mui/material';
import FileViewer from '../FileViewer';
import fieldTypes from './FieldTypes';

export default function FieldPreview({ item, allItems, nested = false }) {
  const [required, setRequired] = useState(false);
  const [text, setText] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [conditions, setConditions] = useState([]);
  const [conditionalOption, setConditionalOption] = useState({});
  const [options, setOptions] = useState([]);
  const [optionParams, setOptionParams] = useState({});
  const [optionsDialogOpen, setOptionsDialogOpen] = useState(false);
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  function editOptions() {
    setOptionParams({ options, isTickbox: fieldType === 'tickbox', selectType: item.selectType });
    setOptionsDialogOpen(true);
  }

  useEffect(() => {
    if (!item) return;
    setRequired(item.required || false);
    setText(item.text || '');
    setFieldType(item.fieldType || '');
    setConditionalOption({});
    setConditions(item.conditions || []);
    setOptions(item.options || []);
  }, [item]);

  if (item.conditionalFieldId && !nested) return null;

  if (item.type === 'field') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        {required && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
        <Paper variant="outlined" sx={{ my: 1.5, borderRadius: 1, width: '100%' }}>
          <Grid container sx={{ p: 2, borderRadius: 1 }}>
            <Grid item container alignItems="center">
              <Grid container item lg={8} md={8} sm={12} alignItems="center" wrap="nowrap">
                <Label style={{ color: amber[500], marginRight: '1rem' }} />
                <Typography variant="h6">{item.title}</Typography>
              </Grid>
              <Grid
                style={{ marginTop: theme.spacing(largeDevices ? 0 : 2) }}
                item
                container
                lg={4}
                md={4}
                sm={12}
                alignItems="center"
                wrap="nowrap"
                justifyContent="flex-end"
              >
                <Grid container item style={{ width: 'fit-content' }} alignItems="center" wrap="nowrap">
                  {fieldTypes[item.fieldType]?.icon}
                  <Typography style={{ marginLeft: '1rem', fontSize: 14 }}>
                    {fieldTypes[item.fieldType]?.text}
                  </Typography>
                </Grid>
                {(fieldType === 'dropbox' || fieldType === 'tickbox') && (
                  <IconButton onClick={editOptions} sx={{ height: 32, width: 32, ml: 2 }}>
                    <Visibility style={{ color: grey[500] }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            {fieldType === 'conditional' && !nested && (
              <Grid item container style={{ marginTop: '1rem' }}>
                <Grid item container alignItems="center" justifyContent="space-between">
                  <Typography variant="h6">Actions:</Typography>
                </Grid>

                <Grid item container alignItems="center" style={{ margin: theme.spacing(1, 0) }}>
                  {conditions.map((option) => (
                    <Paper
                      key={option.id}
                      variant="outlined"
                      style={{
                        borderRadius: 8,
                        margin: theme.spacing(1),
                        border: conditionalOption.id === option.id ? `2px solid ${blue[900]}` : '',
                      }}
                    >
                      <ListItem
                        button
                        style={{
                          borderRadius: 8,
                          padding: theme.spacing(1, 2),
                        }}
                        onClick={() => setConditionalOption({ ...option })}
                      >
                        <ListItemIcon>
                          <FiberManualRecord style={{ color: option.color }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography style={{ fontWeight: conditionalOption.id === option.id ? 700 : 500 }}>
                              {option.text}
                            </Typography>
                          }
                          disableTypography
                        />
                      </ListItem>
                    </Paper>
                  ))}
                </Grid>
                {conditionalOption.id && (
                  <Grid item container>
                    <Grid
                      item
                      container
                      style={{
                        marginTop: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                      }}
                    >
                      {conditionalOption.notApplicable && (
                        <Typography style={{ fontWeight: 600 }}>Not applicable</Typography>
                      )}
                      <div style={{ marginLeft: 'auto' }} />
                      {!conditionalOption.notApplicable && conditionalOption.templateId && (
                        <Button
                          endIcon={<ProjectIcon />}
                          style={{
                            fontWeight: 700,
                            fontSize: '14px',
                            borderRadius: '0.5rem',
                            marginRight: 10,
                            textTransform: 'none',
                            border: `2px solid ${blue[900]}`,
                          }}
                        >
                          Create a project
                        </Button>
                      )}

                      {!conditionalOption.notApplicable && conditionalOption.task && (
                        <Button
                          endIcon={<TaskIcon />}
                          style={{
                            fontWeight: 700,
                            fontSize: '14px',
                            borderRadius: '0.5rem',
                            marginRight: '15px',

                            textTransform: 'none',
                            border: `2px solid ${blue[900]}`,
                          }}
                        >
                          Create a task
                        </Button>
                      )}
                    </Grid>
                    <Grid item container>
                      <Grid item container>
                        {allItems
                          .filter((x) => x.conditionalFieldId === conditionalOption.id)
                          .map((nestedItem) => (
                            <Grid container item key={nestedItem.id}>
                              <FieldPreview item={nestedItem} nested />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          {(item.fieldType === 'dropbox' || item.fieldType === 'tickbox') && (
            <OptionsDialog
              initialOptions={optionParams.options}
              initialSelectType={optionParams.selectType}
              open={optionsDialogOpen}
              onClose={() => {
                setOptionsDialogOpen(false);
                setOptionParams({});
              }}
              isTickbox={optionParams.isTickbox}
            />
          )}
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'text') {
    return (
      <Grid container item>
        <Paper variant="outlined" sx={{ my: 1.5, borderRadius: 1, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2, borderRadius: 1 }}>
            <Grid item container alignItems="center" style={{ marginBottom: theme.spacing(1) }}>
              <TextFields style={{ color: grey[700], marginRight: '1rem' }} />
              <Typography variant="h6">{item.title}</Typography>
            </Grid>
            <HtmlContent content={text} />
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'form') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        {required && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
        <Paper variant="outlined" sx={{ my: 1.5, borderRadius: 1, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2, borderRadius: 1 }}>
            <Grid item container alignItems="center">
              <FormIcon />
              <Typography variant="h6" noWrap style={{ marginLeft: '1rem' }}>
                {`Fill form: ${item.formName}`}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'task') {
    return (
      <Grid container item style={{ position: 'relative' }}>
        {required && <RequiredIcon style={{ position: 'absolute', marginLeft: -10 }} />}
        <Paper variant="outlined" sx={{ my: 1.5, borderRadius: 1, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2, borderRadius: 1 }}>
            <Grid item container alignItems="center">
              <TaskIcon />
              <Typography variant="h6" noWrap style={{ marginLeft: '1rem' }}>
                {item.title}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'ru') {
    return (
      <Grid container item>
        <Paper variant="outlined" sx={{ my: 1.5, borderRadius: 1, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2, borderRadius: 1 }}>
            <Grid container item alignItems="center" wrap="nowrap">
              <ReadUnderstoodIcon style={{ color: blue[900] }} />
              <Typography variant="h6" component="h1" style={{ marginLeft: '0.7rem' }}>
                Read and understood - {item.documentTitle}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  if (item.type === 'resources') {
    return (
      <Grid container item>
        <Paper variant="outlined" sx={{ my: 1.5, borderRadius: 1, width: '100%' }}>
          <Grid item container alignItems="center" sx={{ p: 2, borderRadius: 1 }}>
            <Grid container item alignItems="center" wrap="nowrap">
              <FileCopyRounded style={{ color: blue[900] }} />
              <Typography variant="h6" component="h1" style={{ marginLeft: '0.7rem' }}>
                {item.title}
              </Typography>
            </Grid>
            <Grid container item sx={{ py: 1 }}>
              <FileViewer files={item.files} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return null;
}

function OptionsDialog({ initialOptions, initialSelectType, open, onClose, isTickbox }) {
  const [options, setOptions] = useState([]);
  const [selectType, setSelectType] = useState('single');

  useEffect(() => {
    if (!open) return;
    if (initialOptions) {
      setOptions([...initialOptions]);
    } else {
      setOptions([]);
    }
    if (initialSelectType) {
      setSelectType(initialSelectType);
    } else {
      setSelectType('single');
    }
  }, [open]); // eslint-disable-line

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{isTickbox ? 'Tick box options:' : 'Drop box options:'}</DialogTitle>
      <DialogContent>
        <Grid container item>
          {isTickbox && (
            <Grid container item>
              <Typography style={{ fontWeight: '700', marginRight: '0.5rem' }}>Select type: </Typography>
              <Typography>{selectType}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container item>
          <List style={{ width: '100%' }}>
            {options.map((option) => (
              <ListItem key={option.id} style={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <FiberManualRecord color="primary" />
                </ListItemIcon>
                <ListItemText primary={option.text} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </DialogContent>

      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
