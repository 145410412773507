import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, Typography, TextField } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';

export default function StockOptionsDialog({ initialOptions, open, onClose, onResult, onlyMinMax }) {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);
  const [initial, setInitial] = useState(100);
  const [perRow, setPerRow] = useState(false);

  function toNum(value) {
    return isNaN(+value) ? 0 : +value;
  }

  useEffect(() => {
    setMin(toNum(initialOptions?.min));
    setMax(toNum(initialOptions?.max));
    setInitial(toNum(initialOptions?.initial));
    setPerRow(Boolean(initialOptions?.perRow));
  }, [initialOptions]);

  function done() {
    const result = {
      min: toNum(min),
      max: toNum(max),
      initial: onlyMinMax ? undefined : toNum(initial),
      perRow: onlyMinMax ? undefined : perRow,
    };
    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Stock options:</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item xs={6} alignItems="center" style={{ padding: 8 }} wrap="nowrap">
            <Typography style={{ fontWeight: 500, marginRight: 8 }}>Min</Typography>
            <TextField
              value={min}
              onChange={(e) => {
                if (e.target.value < 0) return;
                setMin(e.target.value);
              }}
              style={{ flexGrow: 1 }}
              type="number"
              inputProps={{ maxLength: 16, min: 0, step: 1 }}
            />
          </Grid>

          <Grid container item xs={6} alignItems="center" style={{ padding: 8 }} wrap="nowrap">
            <Typography style={{ fontWeight: 500, marginRight: 8 }}>Max</Typography>

            <TextField
              value={max}
              onChange={(e) => {
                if (e.target.value < 0) return;
                setMax(e.target.value);
              }}
              style={{ flexGrow: 1 }}
              type="number"
              inputProps={{ maxLength: 16, min: 0, step: 1 }}
            />
          </Grid>
        </Grid>

        {!onlyMinMax && (
          <>
            <Grid container item alignItems="center" style={{ padding: 8 }}>
              <Typography style={{ fontWeight: 500, marginRight: 8 }}>Initial</Typography>

              <TextField
                value={initial}
                onChange={(e) => {
                  if (e.target.value < 0) return;
                  setInitial(e.target.value);
                }}
                style={{ flexGrow: 1 }}
                type="number"
                inputProps={{ maxLength: 16, min: 0, step: 1 }}
              />
            </Grid>

            <Grid container item alignItems="center" style={{ padding: 8 }}>
              <FormControlLabel
                onChange={(e) => setPerRow(e.target.checked)}
                control={<Checkbox checked={perRow} color="primary" />}
                label="Set min and max per row"
              />
            </Grid>
          </>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
