import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Typography, TextField, MenuItem, CircularProgress, ListItemText } from '@mui/material';
import { FormControl, Button, FormGroup, FormControlLabel, Checkbox, Menu } from '@mui/material';
import { Person, CheckBoxRounded, GestureRounded, DescriptionRounded } from '@mui/icons-material';
import { HelpRounded, ArrowDropDownRounded, CheckBoxOutlineBlankRounded } from '@mui/icons-material';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { VisibilityRounded } from '@mui/icons-material';
import { User, GeneralButton, HtmlTooltip, DatePicker, TimePicker } from '../Components';
import { RequiredIcon } from '../Icons';
import { grey, red } from '@mui/material/colors';
import StateManager from '../StateManager';
import { validateUkNumber, validateEmail } from '../Functions';
import moment from 'moment';
import Canvas from './Canvas';
import PageGridTable from './PageGridTable';
import ExternalUserField from './ExternalUserField';
import PeopleCard from './PeopleCard';
import { SignatureDialog, FileDialog } from './GridCell';
import { isArray, isNumber } from 'lodash';
import useElementSize from '../hooks/useElementSize';
import axios from 'axios';

import SelectedOptionsDialog from './SelectedOptionsDialog';
import SelectedRowsDialog from './SelectedRowsDialog';

const PREFIX = 'PageGridField';

const classes = {
  item: `${PREFIX}-item`,
  itemPaper: `${PREFIX}-itemPaper`,
  contentContainer: `${PREFIX}-contentContainer`,
  smallContentContainer: `${PREFIX}-smallContentContainer`,
  smallTitleContainer: `${PREFIX}-smallTitleContainer`,
  titleContainer: `${PREFIX}-titleContainer`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.item}`]: {
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.itemPaper}`]: {
    width: '100%',
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    position: 'relative',
  },

  [`& .${classes.contentContainer}`]: {
    padding: theme.spacing(0, 1, 1, 1),
    height: '100%',
  },

  [`& .${classes.smallContentContainer}`]: {
    padding: theme.spacing(0, 0.5, 0.5, 0.5),
    height: '100%',
  },

  [`& .${classes.smallTitleContainer}`]: {
    padding: theme.spacing(0.5, 0.5, 0, 0.5),
  },

  [`& .${classes.titleContainer}`]: {
    padding: theme.spacing(1),
  },
}));

export default function PageGridField({ item, editable, onSave, greyOut, external, layoutConfig }) {
  const timer = useRef();
  const { width, height } = useElementSize(item ? `${item.id}-inner-content` : null);
  const [loading, setLoading] = useState(true);
  const [hasValue, setHasValue] = useState(false);
  const [text, setText] = useState('');
  const [number, setNumber] = useState(0);
  const [date, setDate] = useState(null);
  const [dropboxValue, setDropboxValue] = useState('');
  const [tickboxValues, setTickboxValues] = useState([]);
  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState();
  const [dataSetValues, setDataSetValues] = useState([]);
  const [dataSetRows, setDataSetRows] = useState([]);
  const [signature, setSignature] = useState({});
  const [sugnatureDialog, setSignatureDialog] = useState(false);
  const [table, setTable] = useState();
  const [error, setError] = useState(false);
  const [people, setPeople] = useState({});
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dataSet, setDataSet] = useState(null);

  const noValue = <Typography style={{ fontSize: 14, color: red[400] }}>No value provided</Typography>;

  function save(value) {
    if (typeof onSave !== 'function') {
      console.error(onSave);
    }
    onSave(item.id, { value, valueType: item.fieldType });
    setHasValue(true);
  }

  const size = getFiedSize();

  const containerHeight = height ? height - (size === 'xs' ? 8 : 16) : 32;
  const fontSize = 16;

  const fontSizes = {
    xs: 14,
    sm: 16,
    md: 18,
  };

  function getFiedSize() {
    if (!height || !width) return 'sm';
    if (height <= 100 || width <= 150) return 'xs';
    if (height <= 150 && width <= 300) return 'sm';
    return 'md';
  }

  function getTitleTextSize() {
    return fontSizes[size] || 16;
  }

  useEffect(() => {
    setLoading(false);

    if (item.fieldType === 'table' && isArray(item.table?.columns)) {
      const columns = item.table?.columns;
      const rows = isArray(item.filledValue?.value?.rows) ? item.filledValue.value.rows : item.table.rows;
      setTable({ columns, rows });
      return;
    }

    if (!item?.filledValue?.value || Object.keys(item.filledValue.value).length === 0) {
      setSignature(null);
      return;
    }
    setHasValue(true);
    const value = item.filledValue.value;

    if (['text', 'textArea', 'phone', 'email'].includes(item.fieldType)) {
      setText(value);
    }
    if (item.fieldType === 'time') {
      setDate(moment(value).toDate());
    }

    if (item.fieldType === 'date') {
      setDate(moment(value).toDate());
    }

    if (item.fieldType === 'user') {
      setUserId(value);
    }

    if (item.fieldType === 'dropbox') {
      setDropboxValue(value);
    }

    if (item.fieldType === 'number') {
      setNumber(Number(value));
    }

    if (item.fieldType === 'tickbox') {
      setTickboxValues(value);
    }

    if (item.fieldType === 'image' || item.fieldType === 'file') {
      setFiles(value);
    }

    if (item.fieldType === 'dataSet') {
      setDataSetValues(Array.isArray(value?.value) ? value?.value : []);
      if (Array.isArray(value?.rows)) {
        setDataSetRows(value.rows);
      }
    }

    if (item.fieldType === 'signature') {
      setSignature(value);
    }

    if (item.fieldType === 'people') {
      setPeople(Array.isArray(value) ? value : []);
    }
  }, [item]); // eslint-disable-line

  useEffect(() => {
    if (!item?.dataSetParams?.setId || !editable || item?.fieldType !== 'dataSet') return;

    const invitationId = localStorage.getItem('invitationId');
    axios
      .get(`${invitationId ? '/external' : ''}/data/getDataSet`, {
        params: { id: item.dataSetParams.setId, invitationId },
      })
      .then((res) => {
        setDataSet(res.data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [item, editable]);

  function activateTimer(value) {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(save, 800, value);
  }

  function handleTextField(e) {
    if (!e?.target) return;
    //const { clientWidth, clientHeight, scrollWidth, scrollHeight, value } = e.target;
    // if the text doesn't fit into the field return
    //if (scrollWidth > clientWidth || scrollHeight > clientHeight) return;
    setText(e.target.value);
    activateTimer(e.target.value);
  }

  function saveNumber(value) {
    setNumber(value);
    activateTimer(value);
  }

  function saveDate(value) {
    setDate(value);
    save(value);
  }

  function saveDropboxValue(value) {
    setDropboxValue(value);
    save(value);
  }

  function handleTickbox(checked, itemId) {
    if (!editable) return;

    const newValue = checked
      ? item.selectType === 'multiple'
        ? [...tickboxValues, itemId].sort()
        : [itemId]
      : item.selectType === 'multiple'
      ? tickboxValues.filter((x) => x !== itemId).sort()
      : [];

    setTickboxValues(newValue);
    activateTimer(newValue);
  }

  function selectUser() {
    StateManager.selectUser((user) => {
      setUserId(user._id);
      save(user._id);
    });
  }

  function uploadFiles(value) {
    setFiles(value);
    activateTimer(value);
  }

  function saveSetRows(rows) {
    setDataSetRows(rows);
    activateTimer(rows);
  }

  function saveSignature(value) {
    setSignature(value);
    save(value);
  }

  function saveTable(value) {
    setTable(value);
    save(value);
  }

  function handleDataSetRows(id) {
    let ids = [];
    if (dataSetValues.includes(id)) {
      ids = dataSetValues.filter((x) => x !== id);
    } else {
      if (item.dataSetParams.selectType === 'single') {
        ids = [id];
      } else {
        ids = [...dataSetValues, id];
      }
    }
    const rows = dataSet.rows.filter((row) => ids.includes(row.id));
    setDataSetValues(ids);
    setDataSetRows(rows);
    activateTimer({ value: ids, rows, versionId: dataSet.versionId, version: dataSet.version });
  }

  const short = isNumber(layoutConfig?.h) && layoutConfig?.h < 3;

  if (loading) {
    return (
      <StyledGrid container item>
        <Paper variant="outlined" className={classes.itemPaper}>
          <CircularProgress color="primary" size={30} />
        </Paper>
      </StyledGrid>
    );
  }

  if (item.type === 'styledText') {
    return (
      <Grid container style={{ height: '100%', padding: 4 }}>
        <Grid container style={{ overflow: 'auto', maxHeight: '100%' }}>
          <Grid
            container
            style={{ padding: 4 }}
            justifyContent={
              item.titleStyle?.textAlign === 'right'
                ? 'flex-end'
                : item.titleStyle?.textAlign === 'center'
                ? 'center'
                : 'flex-start'
            }
          >
            <Typography
              style={{
                whiteSpace: 'break-spaces',
                maxHeight: '100%',
                overflow: 'auto',
                wordBreak: 'break-word',
                textAlign: item.titleStyle?.textAlign || undefined,
                color: item.titleStyle?.color || undefined,
                fontSize: item.titleStyle?.fontSize || 16,
                fontStyle: item.titleStyle?.italic ? 'italic' : undefined,
                textDecoration: item.titleStyle?.underline ? 'underline' : undefined,
                fontWeight: item.titleStyle?.bold ? 600 : undefined,
              }}
            >
              {item.text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'image' && item.picture?.location) {
    return (
      <Grid container style={{ height: '100%' }} justifyContent="center" alignItems="center">
        <img alt={item.picture.name} src={item.picture.location} style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </Grid>
    );
  }

  if (item.type === 'text') {
    return (
      <Grid container style={{ height: '100%', padding: 4 }}>
        <Grid container style={{ overflow: 'auto', maxHeight: '100%' }}>
          <Grid container style={{ padding: 4 }}></Grid>
        </Grid>
      </Grid>
    );
  }

  if (item.type === 'field' || (!item.type && item.fieldType)) {
    let content = <></>;

    if (['text', 'email', 'phone', 'textArea', 'number'].includes(item.fieldType)) {
      if (editable) {
        if (item.fieldType === 'text') {
          content = (
            <TextField
              fullWidth
              size={size === 'xs' ? 'small' : 'medium'}
              value={text}
              onChange={handleTextField}
              inputProps={{
                id: `${item.id}-input`,
                style: { textAlign: item.titleStyle?.textAlign || undefined, fontSize },
              }}
              style={{ textAlign: item.titleStyle?.textAlign || undefined }}
            />
          );
        }
        if (item.fieldType === 'email') {
          content = (
            <TextField
              placeholder="Email"
              fullWidth
              value={text}
              type="email"
              size={size === 'xs' ? 'small' : 'medium'}
              id={`${item.id}-email`}
              error={error && !!text}
              helperText={error && !!text ? 'Enter a correct email' : ''}
              onChange={(e) => {
                let email = String(e.target.value).toLowerCase();
                setError(!validateEmail(email));
                handleTextField(e);
              }}
              inputProps={{ style: { textAlign: item.titleStyle?.textAlign || undefined, fontSize } }}
              style={{ textAlign: item.titleStyle?.textAlign || undefined }}
            />
          );
        }
        if (item.fieldType === 'phone') {
          content = (
            <TextField
              placeholder="Phone number"
              fullWidth
              value={text}
              size={size === 'xs' ? 'small' : 'medium'}
              type="tel"
              id={`${item.id}-tel`}
              error={error && !!text}
              helperText={error && !!text ? 'Enter a correct phone number' : ''}
              onChange={(e) => {
                let value = String(e.target.value).replace(/[^\d+]/g, '');
                setError(!validateUkNumber(value));
                handleTextField(e);
              }}
              inputProps={{ style: { textAlign: item.titleStyle?.textAlign || undefined, fontSize } }}
              style={{ textAlign: item.titleStyle?.textAlign || undefined }}
            />
          );
        }

        if (item.fieldType === 'textArea') {
          content = (
            <TextField
              fullWidth
              multiline
              size={size === 'xs' || size === 'sm' ? 'small' : 'medium'}
              minRows={size === 'xs' ? 1 : 3}
              maxRows={size === 'xs' ? 10 : 30}
              value={text}
              inputProps={{
                style: {
                  textAlign: item.titleStyle?.textAlign || undefined,
                  height: '100%',
                  maxHeight: '100%',
                  fontSize,
                },
                id: `${item.id}-input`,
              }}
              InputProps={{ style: { height: '100%', maxHeight: '100%', padding: 6 } }}
              onChange={handleTextField}
              style={{ height: '100%', maxHeight: '100%' }}
            />
          );
        }

        if (item.fieldType === 'number') {
          content = (
            <TextField
              type="number"
              value={number}
              size={size === 'xs' || size === 'sm' ? 'small' : 'medium'}
              onChange={(e) => saveNumber(e.target.value)}
              inputProps={{
                id: `${item.id}-input`,
                maxLength: 8,
                style: { textAlign: item.titleStyle?.textAlign || undefined, fontSize: size === 'xs' ? 14 : 16 },
              }}
            />
          );
        }
      } else {
        content = hasValue ? (
          <Typography
            style={{ whiteSpace: 'break-spaces', maxHeight: '100%', overflow: 'auto', wordBreak: 'break-word' }}
          >
            {text}
          </Typography>
        ) : (
          noValue
        );
      }
    }

    if (item.fieldType === 'date') {
      if (editable) {
        content = <DatePicker value={date} onChange={(date) => saveDate(moment(date).startOf('day').toISOString())} />;
      } else {
        content = hasValue ? <Typography>{moment(date).format('DD/MM/yyyy')}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'time') {
      if (editable) {
        content = (
          <TimePicker
            value={date}
            onChange={(date) =>
              saveDate(moment(date).set({ year: 2000, month: 0, date: 1, seconds: 0, milliseconds: 0 }).toISOString())
            }
          />
        );
      } else {
        content = hasValue ? <Typography>{moment(date).format('hh:mm A')}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'dropbox' && isArray(item.options)) {
      if (editable) {
        content = (
          <TextField
            fullWidth
            select
            label="Pick value"
            value={dropboxValue}
            onChange={(e) => saveDropboxValue(e.target.value)}
            size={size === 'xs' ? 'small' : 'medium'}
          >
            {item.options.map((value) => (
              <MenuItem key={value.id} value={value.id}>
                {value.text}
              </MenuItem>
            ))}
          </TextField>
        );
      } else {
        content = hasValue ? <Typography>{item.options.find((o) => o.id === dropboxValue)?.text}</Typography> : noValue;
      }
    }

    if (item.fieldType === 'tickbox' && isArray(item.options)) {
      if (editable) {
        content =
          true || Math.round(containerHeight / 42) > item.options.length ? (
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {item.options.map((value) => (
                  <FormControlLabel
                    style={{ cursor: editable ? '' : 'default', height: 42 }}
                    key={value.id}
                    id={value.id}
                    label={value.text}
                    control={
                      <Checkbox
                        color="primary"
                        id={value.id}
                        checked={tickboxValues?.includes(value.id)}
                        onChange={(e) => handleTickbox(e.target.checked, value.id)}
                        style={{ cursor: editable ? '' : 'default' }}
                      />
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          ) : (
            <>
              <Button
                style={{ textTransform: 'none', justifyContent: 'flex-start' }}
                onClick={(e) => setMenuAnchor(e.currentTarget)}
                startIcon={<ArrowDropDownRounded style={{ color: grey[500] }} />}
                fullWidth
                size={size === 'xs' ? 'small' : 'medium'}
              >
                <Typography noWrap style={{ fontWeight: size === 'xs' ? 400 : 500, fontSize: size === 'xs' ? 14 : 16 }}>
                  {tickboxValues.length > 0
                    ? item.options
                        .filter((x) => tickboxValues.includes(x.id))
                        .map((x) => x.text)
                        .join(', ')
                    : `Pick option${item.selectType === 'multiple' ? 's' : ''}`}
                </Typography>
              </Button>

              <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
                {item.options.map((value) => (
                  <MenuItem key={value.id} onClick={(e) => handleTickbox(!tickboxValues.includes(value.id), value.id)}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankRounded fontSize="small" />}
                      checkedIcon={<CheckBoxRounded fontSize="small" />}
                      color="primary"
                      checked={tickboxValues.includes(value.id)}
                    />
                    <ListItemText primary={value.text} />
                  </MenuItem>
                ))}
              </Menu>
            </>
          );
      } else {
        if (tickboxValues.length === 0) {
          content = noValue;
        } else {
          const options = item.options.filter((x) => tickboxValues.includes(x.id)).map((x) => x.text);
          content =
            options.length === 1 ? (
              <Typography noWrap style={{ fontWeight: 500 }}>
                {options[0]}
              </Typography>
            ) : (
              <>
                <Button
                  style={{ textTransform: 'none', justifyContent: 'flex-start' }}
                  onClick={() => setDialogOpen(true)}
                  startIcon={<VisibilityRounded style={{ color: grey[500] }} />}
                  fullWidth
                >
                  <Typography noWrap style={{ maxWidth: '100%' }}>
                    {options.join(', ')}
                  </Typography>
                </Button>

                <SelectedOptionsDialog open={dialogOpen} onClose={() => setDialogOpen(false)} options={options} />
              </>
            );
        }
      }
    }

    if (item.fieldType === 'image' || item.fieldType === 'file') {
      content = (
        <>
          <Button
            style={{ textTransform: 'none', justifyContent: 'flex-start' }}
            onClick={() => setDialogOpen(true)}
            startIcon={<DescriptionRounded style={{ color: grey[500] }} />}
          >
            <Typography noWrap style={{ maxWidth: '100%' }}>
              {files.length > 0
                ? `${files.length} file${files.length > 1 ? 's' : ''}`
                : editable
                ? 'Add files'
                : 'No files'}
            </Typography>
          </Button>
          <FileDialog
            open={dialogOpen}
            initial={files}
            editable={editable}
            onClose={() => setDialogOpen(false)}
            onResult={uploadFiles}
            onlyImages={item.fieldType === 'image'}
            maxFiles={Math.round(containerHeight / 25)}
          />
        </>
      );
    }

    if (item.fieldType === 'user') {
      if (external) {
        content = (
          <ExternalUserField
            selected={userId}
            editable={editable}
            onResult={(userId) => {
              setUserId(userId);
              save(userId);
            }}
          />
        );
      } else {
        {
          content = userId ? (
            <User id={userId} fullWidth onClick={editable ? selectUser : undefined} avatarSize={30} />
          ) : (
            editable && (
              <GeneralButton
                onClick={selectUser}
                startIcon={<Person style={{ color: grey[500] }} />}
                size={size === 'xs' ? 'small' : 'medium'}
              >
                Select user
              </GeneralButton>
            )
          );
        }
      }
    }

    if (item.fieldType === 'people') {
      content = (
        <PeopleCard
          initial={people}
          editable={editable}
          options={item.peopleOptions}
          onChange={(value) => {
            setPeople(value);
            save(value);
          }}
        />
      );
    }

    if (item.fieldType === 'dataSet') {
      if (!item.dataSetParams?.setId || !Array.isArray(item.dataSetParams?.columns)) {
        content = editable ? <Typography color="textSecondary">Data set not selected</Typography> : noValue;
      }

      if (item.dataSetParams.columns.length === 1) {
        if (editable) {
          if (dataSet) {
            const rows = dataSet.rows;
            const selectType = item.dataSetParams.selectType;
            content = (
              <>
                <Button
                  style={{ textTransform: 'none', minWidth: 150, maxWidth: '100%', justifyContent: 'flex-start' }}
                  onClick={(e) => setMenuAnchor(e.currentTarget)}
                  startIcon={<ArrowDropDownRounded style={{ color: grey[500] }} />}
                >
                  <Typography noWrap style={{ fontWeight: 500 }}>
                    {dataSetValues.length > 0
                      ? rows
                          .filter((x) => dataSetValues.includes(x.id))
                          .map((x) => x[item.dataSetParams.columns[0].id])
                          .join(', ')
                      : `Pick option${selectType === 'multiple' ? 's' : ''}`}
                  </Typography>
                </Button>

                <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
                  {rows.map((value) => (
                    <MenuItem key={value.id} onClick={() => handleDataSetRows(value.id)}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankRounded fontSize="small" />}
                        checkedIcon={<CheckBoxRounded fontSize="small" />}
                        color="primary"
                        checked={dataSetValues.includes(value.id)}
                      />
                      <ListItemText primary={value[item.dataSetParams.columns[0].id]} />
                    </MenuItem>
                  ))}
                </Menu>
              </>
            );
          } else {
            content = <CircularProgress color="primary" size={20} />;
          }
        } else {
          if (dataSetValues.length === 0) {
            content = noValue;
          } else if (dataSetValues.length === 1) {
            const row = dataSetRows.find((x) => x.id === dataSetValues[0]);
            content = row ? (
              <Typography noWrap style={{ fontWeight: 500 }}>
                {row[item.dataSetParams.columns[0].id]}
              </Typography>
            ) : (
              noValue
            );
          } else if (dataSetValues.length > 1) {
            const options = dataSetRows.map((x) => x[item.dataSetParams.columns[0].id]);
            content = (
              <>
                <Button
                  style={{ textTransform: 'none', minWidth: 150, maxWidth: '100%', justifyContent: 'flex-start' }}
                  onClick={() => setDialogOpen(true)}
                  startIcon={<VisibilityRounded style={{ color: grey[500] }} />}
                  fullWidth
                >
                  <Typography noWrap>{options.join(', ')}</Typography>
                </Button>

                <SelectedOptionsDialog open={dialogOpen} onClose={() => setDialogOpen(false)} options={options} />
              </>
            );
          }
        }
      }
      if (item.dataSetParams.columns.length > 1) {
        if (editable) {
          if (dataSet) {
            const rows = dataSet.rows;
            const selectType = item.dataSetParams.selectType;
            content = (
              <>
                <Button
                  style={{ textTransform: 'none', justifyContent: 'flex-start' }}
                  onClick={(e) => setMenuAnchor(e.currentTarget)}
                  startIcon={<ArrowDropDownRounded style={{ color: grey[500] }} />}
                  fullWidth
                >
                  <Typography noWrap style={{ fontWeight: 500 }}>
                    {dataSetValues.length > 0
                      ? `${dataSetValues.length} row${dataSetValues.length > 1 ? 's' : ''} selected`
                      : `Pick row${selectType === 'multiple' ? 's' : ''}`}
                  </Typography>
                </Button>
                <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
                  <Table size="small" style={{ width: 'fit-content' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {item.dataSetParams.columns.map((column) => (
                          <TableCell key={column.id}>{column.title}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.id}
                          hover
                          onClick={() => handleDataSetRows(row.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableCell>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankRounded fontSize="small" />}
                              checkedIcon={<CheckBoxRounded fontSize="small" />}
                              id={row.id}
                              color="primary"
                              checked={dataSetValues.includes(row.id)}
                            />
                          </TableCell>
                          {item.dataSetParams.columns.map((column) => (
                            <TableCell key={`${column.id}#${row.id}`}>{row[column.id]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Menu>
              </>
            );
          } else {
            content = <CircularProgress color="primary" size={20} />;
          }
        } else {
          content =
            dataSetValues.length === 0 ? (
              noValue
            ) : (
              <>
                <Button
                  style={{ textTransform: 'none', justifyContent: 'flex-start' }}
                  onClick={() => setDialogOpen(true)}
                  startIcon={<VisibilityRounded style={{ color: grey[500] }} />}
                  fullWidth
                >
                  <Typography noWrap>
                    {dataSetValues.length} row{dataSetValues.length > 1 ? 's' : ''}
                  </Typography>
                </Button>

                <SelectedRowsDialog
                  open={dialogOpen}
                  onClose={() => setDialogOpen(false)}
                  rows={dataSetRows}
                  columns={item.dataSetParams.columns}
                />
              </>
            );
        }
      }
    }

    if (item.fieldType === 'signature') {
      if (!editable && !hasValue) {
        content = noValue;
      } else {
        if (width >= 200 && height >= 250) {
          content = (
            <Canvas
              onResult={saveSignature}
              initial={signature}
              editable={editable}
              width={width - 10}
              height={height - 1}
            />
          );
        } else {
          content = (
            <Grid container style={{ height: '100%', position: 'relative' }} alignContent="flex-end">
              {signature?.base64Data ? (
                <Button
                  onClick={() => setSignatureDialog(true)}
                  style={{ width: '100%', height: '100%', position: 'absolute' }}
                >
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      userSelect: 'none',
                      pointerEvents: 'none',
                    }}
                    src={signature?.base64Data}
                    alt="signature"
                  />
                </Button>
              ) : (
                <Button
                  color="primary"
                  startIcon={<GestureRounded />}
                  onClick={() => setSignatureDialog(true)}
                  style={{ borderRadius: 8, color: grey[600] }}
                >
                  add signature
                </Button>
              )}

              <SignatureDialog
                open={sugnatureDialog}
                onClose={() => setSignatureDialog(false)}
                onResult={saveSignature}
                editable={editable}
                initial={signature}
              />
            </Grid>
          );
        }
      }
    }

    if (item.fieldType === 'table') {
      if (!editable && !hasValue) {
        content = noValue;
      } else {
        content = <PageGridTable onChange={saveTable} initial={table} editable={editable} />;
      }
    }

    return (
      <Grid
        container
        item
        direction={short ? 'row' : 'column'}
        wrap={short ? 'wrap' : 'nowrap'}
        alignItems={short ? 'center' : 'flex-start'}
        alignContent="flex-start"
        style={{ height: '100%' }}
        id={item.id}
      >
        {item.required && editable && (
          <RequiredIcon style={{ display: 'flex', position: 'absolute', top: -10, left: -10 }} />
        )}
        {(item.fieldType !== 'table' || !item.table?.hideTitle) && (
          <Grid
            container
            justifyContent={
              item.titleStyle?.textAlign === 'right'
                ? 'flex-end'
                : item.titleStyle?.textAlign === 'center'
                ? 'center'
                : 'flex-start'
            }
            wrap="nowrap"
            className={size === 'xs' ? classes.smallTitleContainer : classes.titleContainer}
            alignItems={short ? 'center' : 'flex-start'}
            item
            xs={short ? 6 : 12}
          >
            <Typography
              noWrap
              style={{
                color: item.titleStyle?.color || (greyOut ? grey[500] : undefined),
                fontSize: item.titleStyle?.fontSize || 16,
                fontWeight: 500,
              }}
            >
              {item.title}
            </Typography>

            {item.helpText && (
              <HtmlTooltip placement="left" title={item.helpText}>
                <HelpRounded style={{ color: grey[500], marginLeft: 'auto' }} />
              </HtmlTooltip>
            )}
          </Grid>
        )}
        <Grid container item xs={short ? 6 : 12} id={`${item.id}-inner-content`} style={{ flexGrow: 1 }}>
          {!greyOut && (
            <Grid
              container
              item
              alignItems="center"
              alignContent="flex-end"
              className={
                item.fieldType === 'table'
                  ? undefined
                  : size === 'xs'
                  ? classes.smallContentContainer
                  : classes.contentContainer
              }
              style={{ overflow: editable ? undefined : 'auto' }}
            >
              {content}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container item>
      This item is not supported
    </Grid>
  );
}
