import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography } from '@mui/material';

import { uploadImage } from '../../redux/actions';

function Dropzone({
  accept,
  onFileUpload,
  onFileDrop,
  setUploading,
  maxFiles = 1,
  onProgress,
  title = 'Drag and Drop file here or Browse',
  encrypted,
  disabled,
  initialOpen = true,
}) {
  const onDrop = async (rawFiles) => {
    if (!rawFiles) {
      return;
    }
    const files = Array.from(rawFiles);
    if (files.length === 0) {
      return;
    }
    if (setUploading) {
      setUploading(true);
    }
    if (onProgress) {
      onProgress(0);
    }
    onFileDrop(maxFiles === 1 ? rawFiles[0] : rawFiles);
    if (onFileUpload) {
      let data = await uploadImage(
        maxFiles === 1 ? files[0] : files,
        (d) => {
          if (onProgress) {
            onProgress(d);
          }
        },
        encrypted,
      );
      if (data) {
        onFileUpload(data.file);
        setUploading(false);
      }
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    maxFiles,
    onDrop,
    disabled,
  });

  // useEffect(() => {
  //   if (initialOpen) {
  //     open();
  //   }
  // }, [initialOpen, open]);

  return (
    <Grid
      container
      sx={{
        padding: '30px',
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: (theme) => (disabled ? theme.palette.grey[100] : theme.palette.primary.main),
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'black' : '#ffffff'),
        outline: 'none',
      }}
      alignItems="center"
      {...getRootProps()}
    >
      <Grid item sm>
        <input {...getInputProps()} />
        <Typography variant="h5" align="center">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Dropzone;
