import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputBase } from '@mui/material';
import measurementsTypes from '../../asset/components/measurements';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { blue } from '@mui/material/colors';

export default function AssetMeasurements({ assetId, managementTaskId, taskId }) {
  const [assetInfo, setAssetInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [measurements, setMeasurements] = useState([]);
  const [saving, setSaving] = useState('');
  const timer = useRef();

  useEffect(() => {
    if (!assetId || !managementTaskId || !taskId) return;
    setLoading(true);
    axios
      .get('/asset/getAssetManagementTaskInfo', { params: { assetId, managementTaskId, taskId } })
      .then((res) => {
        setAssetInfo(res.data);
        setMeasurements(res.data.measurements.map((x) => ({ ...x })));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [assetId, managementTaskId, taskId]);

  function saveMeasurementDimension(index, value) {
    measurements[index].filled = value;
    activateTimer(measurements);
    setMeasurements([...measurements]);
  }

  function save(value) {
    setSaving('Saving...');
    axios
      .post('/asset/saveAssetManagementTaskInfo', { measurements: value, taskId, assetId })
      .then((res) => {
        setSaving('Saved');
      })
      .catch(() => setSaving('Failed to save the measurements'));
  }

  function activateTimer(value) {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(save, 1200, value);
  }

  if (!assetId) return null;

  if (loading) {
    return (
      <Grid container>
        <CircularProgress color="primary" size={30} style={{ margin: 8 }} />
      </Grid>
    );
  }

  if (!assetInfo) return null;

  return (
    <Grid container style={{ padding: '1rem 0em' }}>
      <Grid container item alignItems="center" style={{ marginBottom: '1em' }}>
        <Typography style={{ fontSize: 18, fontWeight: 500, marginRight: '0.25em' }}>This is task for asset</Typography>
        <Link to={`/asset/${assetInfo._id}`} style={{ fontSize: 18, fontWeight: 500, color: blue[800] }}>
          {assetInfo.title}
        </Link>
      </Grid>

      {measurements?.length > 0 && (
        <Grid container item>
          <Grid container item>
            <Typography variant="h6" gutterBottom>
              Please fill in the following measurements:
            </Typography>
          </Grid>

          <Grid container item>
            <TableContainer>
              <Table style={{ width: 'fit-content' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Measurement name</TableCell>
                    <TableCell>Dimension</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Initial</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {measurements.map((measurement, index) => (
                    <TableRow key={measurement.id}>
                      <TableCell>{measurement.title}</TableCell>
                      <TableCell>
                        <InputBase
                          value={measurement.filled}
                          placeholder="Dimension"
                          type="number"
                          style={{ flexGrow: 1 }}
                          onChange={(e) => saveMeasurementDimension(index, e.target.value)}
                        />
                      </TableCell>
                      <TableCell>{measurementsTypes[measurement.type]?.full}</TableCell>
                      <TableCell>{measurement.dimension}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid container item style={{ height: '2rem' }} alignItems="flex-end">
            <Typography color="textSecondary" style={{ fontSize: 12 }}>
              {saving}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
