import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useUserList() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get('/user/getUsers')
      .then((res) => {
        setUsers(res.data);
      })
      .catch(console.error);
  }, []);

  return users;
}
