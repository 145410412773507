import React, { useState } from 'react';
import { RoundedDialog, StandardDialogActions } from './';
import { DialogTitle, DialogContent, Grid, Typography } from '@mui/material';
import { TextField } from '@mui/material';

export default function CancelDialog({ open, title, onClose, onResult }) {
  const [reason, setReason] = useState('');

  function done() {
    onResult({ reason });
    onClose();
  }

  return (
    <RoundedDialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container item>
          <Typography>Are you sure you want to continue? If yes, please provide a reason</Typography>
        </Grid>
        <Grid container item sx={{ mt: 2 }}>
          <TextField
            value={reason}
            placeholder="Reason for cancelling"
            multiline
            fullWidth
            minRows={5}
            onChange={(e) => setReason(e.target.value)}
          />
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={!reason} />
    </RoundedDialog>
  );
}
