import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Chip } from '@mui/material';
import UserManager from '../UserManager';

function isHex24(str) {
  if (!str || typeof str !== 'string') return false;
  const re = /\b[0-9A-Fa-f]{24}\b/;
  return re.test(str);
}

export default function UserChip({ id, onDelete, disableMargin, margin = '8px 16px 8px 0px', sx, ...props }) {
  const [user, setUser] = useState(null);
  const subscription = useRef(null);

  useEffect(() => {
    return () => {
      if (!subscription.current) return;
      subscription.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isHex24(id)) return;
    let resolved = UserManager.resolveUser(id);
    if (resolved?._id) {
      setUser(resolved);
    } else {
      subscription.current = UserManager.subscribeToUser(id, (res) => {
        setUser(res);
        subscription.current.unsubscribe();
      });
    }
  }, [id]);

  return (
    <Chip
      sx={sx}
      color={user?.suspendedAt ? undefined : 'primary'}
      style={{ margin: disableMargin ? 0 : margin }}
      avatar={<Avatar src={user?.suspendedAt ? undefined : user?.avatar}>{user?.avatarLetters}</Avatar>}
      label={user?.fullName || 'Loading...'}
      onDelete={onDelete}
      {...props}
    />
  );
}
