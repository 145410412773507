import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Typography, Avatar, TextField } from '@mui/material';
import UserManager from '../UserManager';

export default function UserAutocomplete({ onResult, style, selected, width = 300, variant }) {
  const [value, setValue] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    UserManager.getUserList()
      .then((res) => setUsers(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (!selected) {
      setValue('');
      return;
    }
    if (typeof selected === 'object') {
      setValue(selected);
      return;
    }
    if (typeof selected === 'string') {
      const user = users.find((u) => u._id === selected);
      setValue(user || '');
      return;
    }
  }, [selected, users]);

  return (
    <Autocomplete
      options={users}
      value={value}
      style={{ width, ...style }}
      getOptionLabel={(option) => option?.fullName || ''}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      openOnFocus
      renderOption={(props, user) => (
        <li {...props}>
          <Grid container alignItems="center" wrap="nowrap">
            <Avatar src={user.avatar} style={{ background: user.avatarColor, marginRight: 10 }}>
              {user.avatarLetters}
            </Avatar>
            <Typography>{user.fullName}</Typography>
          </Grid>
        </li>
      )}
      renderInput={(params) => <TextField {...params} variant={variant || 'standard'} label="Select a user" />}
      onChange={(e, x) => {
        setValue(x);
        onResult({ ...x });
      }}
    />
  );
}
