import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Typography, Avatar, TextField } from '@mui/material';
import axios from 'axios';

export default function ExternalUserField({ onResult, selected, editable, style }) {
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState(null);
  const invitationId = localStorage.getItem('invitationId');

  useEffect(() => {
    if (!invitationId) return;
    axios
      .get('/external/users/getCompanyUsers', { params: { invitationId } })
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [invitationId]);

  useEffect(() => {
    if (!selected || users.length === 0) return;
    setValue(users.find((x) => x._id === selected) || null);
  }, [selected, users]);

  if (users.length === 0) return null;

  return (
    <Grid container alignItems="center">
      <Avatar src={value?.avatar} style={{ background: value?.avatarColor, marginRight: 8 }}>
        {value?.avatarLetters}
      </Avatar>
      {editable ? (
        <Autocomplete
          options={users}
          value={value}
          style={{ width: 300, ...style }}
          getOptionLabel={(option) => option?.fullName || ''}
          openOnFocus
          renderOption={(props, user) => (
            <li {...props}>
              <Grid container alignItems="center">
                <Avatar src={user.avatar} style={{ background: user.avatarColor, marginRight: 10 }}>
                  {user.avatarLetters}
                </Avatar>
                <Typography>{user.fullName}</Typography>
              </Grid>
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Select a user" />}
          onChange={(e, x) => {
            setValue(x);
            onResult(x?._id);
          }}
        />
      ) : (
        <Typography>{value?.fullName}</Typography>
      )}
    </Grid>
  );
}
