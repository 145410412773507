import axios from '../../../../../redux/apiCaller';
import { successAction, errorAction, attemptAction } from '../../../../../redux/constants';
import {
  GETREVIEW_ATTEMPT,
  GETREVIEW_SUCCESS,
  GETREVIEW_FAIL,
  SETREVIEWMODE,
  SETREVIEW,
  CANCELREVIEW_ATTEMPT,
  CANCELREVIEW_SUCCESS,
  CANCELREVIEW_FAIL,
  GETREVIEWS_ATTEMPT,
  GETREVIEWS_SUCCESS,
  GETREVIEWS_FAIL,
  STARTREVIEW_ATTEMPT,
  STARTREVIEW_SUCCESS,
  STARTREVIEW_FAIL,
  UPDATEREVIEW_ATTEMPT,
  UPDATEREVIEW_SUCCESS,
  UPDATEREVIEW_FAIL,
  ADDTASK_ATTEMPT,
  ADDTASK_SUCCESS,
  ADDTASK_FAIL,
  UPDATETASK_ATTEMPT,
  UPDATETASK_SUCCESS,
  UPDATETASK_FAIL,
  DELETETASK_ATTEMPT,
  DELETETASK_SUCCESS,
  DELETETASK_FAIL,
  FETCHTASK_ATTEMPT,
  FETCHTASK_SUCCESS,
  FETCHTASK_FAIL,
  SETTASKDIALOG,
  SETTASK,
  GETTASKSBYREVIEW_ATTEMPT,
  GETTASKSBYREVIEW_SUCCESS,
  GETTASKSBYREVIEW_FAIL,
  ADDHUBTASK_ATTEMPT,
  ADDHUBTASK_SUCCESS,
  ADDHUBTASK_FAIL,
  UPDATEHUBTASK_ATTEMPT,
  UPDATEHUBTASK_SUCCESS,
  UPDATEHUBTASK_FAIL,
  DELETEHUBTASK_ATTEMPT,
  DELETEHUBTASK_SUCCESS,
  DELETEHUBTASK_FAIL,
  REVIEWCOMMENT_ATTEMPT,
  REVIEWCOMMENT_FAIL,
  ADDREVIEWCOMMENT,
  UPDATEREVIEWCOMMENT,
  DELETEREVIEWCOMMENT,
} from '../constants';
import StateManager from '../../../../Global/StateManager';
import { isFunction } from 'lodash';

export const getReviews = (document_id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETREVIEWS_ATTEMPT));
      const body = await axios.post(`dochub/reviews/getAllReviews`, { document_id });
      dispatch(successAction(GETREVIEWS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETREVIEWS_FAIL, err));
    }
  };
  return thunk;
};

export const getReview = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETREVIEW_ATTEMPT));
      const body = await axios.post(`dochub/reviews/getReview`, { id });
      dispatch(successAction(GETREVIEW_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETREVIEW_FAIL, err));
    }
  };
  return thunk;
};

export const getReviewByDocument = async (id, onSet) => {
  try {
    const body = await axios.get(`dochub/reviews/getRunningReview/${id}`);
    if (isFunction(onSet)) onSet(body.data);
    return body?.data;
  } catch (err) {
    StateManager.setAxiosErrorAlert(err);
    return null;
  }
};

export const startReview = (id, onResult) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(STARTREVIEW_ATTEMPT));
      const body = await axios.post(`dochub/reviews/startReview`, { id });
      dispatch(successAction(STARTREVIEW_SUCCESS, body.data));
      if (onResult) onResult(body.data?.review?._id);
    } catch (err) {
      StateManager.setAxiosErrorAlert(err);
      dispatch(errorAction(STARTREVIEW_FAIL, err));
    }
  };
  return thunk;
};

export const submitReviewApproval = (id, approvalId, outcome, onResult) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEREVIEW_ATTEMPT));
      const body = await axios.post(`dochub/reviews/submitReviewApproval`, { id, approvalId, outcome });
      dispatch(successAction(UPDATEREVIEW_SUCCESS, { review: body.data?.review }));
      if (onResult && body.data?.url) {
        onResult(body.data?.url);
      }
    } catch (err) {
      dispatch(errorAction(UPDATEREVIEW_FAIL, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const updateReviewUsers = (id, data, onResult) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEREVIEW_ATTEMPT));
      const body = await axios.post(`dochub/reviews/updateReviewUsers`, { id, data });
      dispatch(successAction(UPDATEREVIEW_SUCCESS, { review: body.data?.review }));
      if (onResult && body.data?.url) {
        onResult(body.data?.url);
      }
    } catch (err) {
      dispatch(errorAction(UPDATEREVIEW_FAIL, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const updateReview = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEREVIEW_ATTEMPT));
      const body = await axios.post(`dochub/reviews/updateReview`, { review_id: id, update: data });
      dispatch(successAction(UPDATEREVIEW_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEREVIEW_FAIL, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const cancelReview = (id, reviewDate) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CANCELREVIEW_ATTEMPT));
      const body = await axios.post(`dochub/reviews/cancelReview`, { review_id: id, reviewDate });
      if (body.status === 200 && body.data.status === 200) {
        StateManager.setSuccessAlert('Document Review Cancelled Successfully');
      }
      dispatch(successAction(CANCELREVIEW_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CANCELREVIEW_FAIL, err));
    }
  };
  return thunk;
};

export const setReview = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETREVIEW, data));
  };
  return thunk;
};

export const setReviewMode = (value) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETREVIEWMODE, value));
  };
  return thunk;
};

export const setTaskDialog = (value) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETTASKDIALOG, value));
  };
  return thunk;
};

export const setTask = (value) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETTASK, value));
  };
  return thunk;
};

export const fetchTasksByReview = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETTASKSBYREVIEW_ATTEMPT));
      const body = await axios.get(`tasks/getLinkedTasks?id=${id}`);
      dispatch(successAction(GETTASKSBYREVIEW_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETTASKSBYREVIEW_FAIL, err));
    }
  };
  return thunk;
};

export const getTask = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHTASK_ATTEMPT));
      const body = await axios.post(`tasks/getTask`, data);
      dispatch(successAction(FETCHTASK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(FETCHTASK_FAIL, err));
    }
  };
  return thunk;
};

export const addHubTask = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDHUBTASK_ATTEMPT));
      const body = await axios.post(`tasks/createTask`, data);
      dispatch(successAction(ADDHUBTASK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(ADDHUBTASK_FAIL, err));
    }
  };
  return thunk;
};

export const updateHubTask = (task_id, update) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEHUBTASK_ATTEMPT));
      const body = await axios.post(`tasks/updateTask`, { task_id, update });
      dispatch(successAction(UPDATEHUBTASK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEHUBTASK_FAIL, err));
    }
  };
  return thunk;
};

export const deleteHubTask = (_id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DELETEHUBTASK_ATTEMPT));
      const body = await axios.post('/tasks/management/deleteTask', { taskId: _id });
      dispatch(successAction(DELETEHUBTASK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DELETEHUBTASK_FAIL, err));
    }
  };
  return thunk;
};

export const addTask = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(ADDTASK_ATTEMPT));
      const body = await axios.post(`tasks/createTask`, data);
      dispatch(successAction(ADDTASK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(ADDTASK_FAIL, err));
    }
  };
  return thunk;
};

export const updateTask = (task_id, update) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATETASK_ATTEMPT));
      const body = await axios.post(`tasks/updateTask`, { task_id, update });
      dispatch(successAction(UPDATETASK_SUCCESS, body.data));
      StateManager.setSuccessAlert('Task Updated');
    } catch (err) {
      dispatch(errorAction(UPDATETASK_FAIL, err));
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const deleteTask = (_id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(DELETETASK_ATTEMPT));
      const body = await axios.post('/tasks/management/deleteTask', { taskId: _id });
      dispatch(successAction(DELETETASK_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(DELETETASK_FAIL, err));
    }
  };
  return thunk;
};

export const addReviewComment = (id, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(REVIEWCOMMENT_ATTEMPT));
      const body = await axios.post(`dochub/reviews/${id}/comments`, data);
      dispatch(successAction(ADDREVIEWCOMMENT, body.data));
      if (body.data.status === 200) {
        StateManager.setSuccessAlert(body.data.message);
      } else {
        StateManager.setErrorAlert(body.data.message);
      }
    } catch (err) {
      dispatch(errorAction(REVIEWCOMMENT_FAIL, err));
    }
  };
  return thunk;
};

export const updateReviewComment = (id, cid, data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(REVIEWCOMMENT_ATTEMPT));
      const body = await axios.post(`dochub/reviews/${id}/comments/${cid}`, data);
      dispatch(successAction(UPDATEREVIEWCOMMENT, body.data));
      if (body.data.status === 200) {
        StateManager.setSuccessAlert(body.data.message);
      } else {
        StateManager.setErrorAlert(body.data.message);
      }
    } catch (err) {
      dispatch(errorAction(REVIEWCOMMENT_FAIL, err));
    }
  };
  return thunk;
};

export const deleteReviewComment = (id, cid) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(REVIEWCOMMENT_ATTEMPT));
      const body = await axios.delete(`dochub/reviews/${id}/comments/${cid}`);
      dispatch(successAction(DELETEREVIEWCOMMENT, body.data));
      if (body.data.status === 200) {
        StateManager.setSuccessAlert(body.data.message);
      } else {
        StateManager.setErrorAlert(body.data.message);
      }
    } catch (err) {
      dispatch(errorAction(REVIEWCOMMENT_FAIL, err));
    }
  };
  return thunk;
};
