import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

export default function FromNow({ value, prefix, ...props }) {
  const [text, setText] = useState('');
  const timer = useRef(null);
  const fullDate = value && moment(value).isValid() ? moment(value).format('hh:mm:ss A') : null;
  const space = prefix ? ' ' : '';

  useEffect(() => {
    if (!value || !moment(value).isValid()) {
      setText('');
      return;
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    update(value);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [value]); // eslint-disable-line

  function update(date) {
    const m = moment(date);
    const seconds = m.diff(moment(), 'seconds');
    if (seconds > 0) {
      setText('a long time ago');
      return;
    }
    // [0, 4) seconds
    if (seconds > -4) {
      setText('just now');
      timer.current = setTimeout(() => update(date), 4 * 1000);
      return;
    }

    // [4, 20) seconds
    if (seconds > -20) {
      setText('a few seconds ago');
      timer.current = setTimeout(() => update(date), 16 * 1000);
      return;
    }

    // [20, 60) seconds
    if (seconds > -60) {
      setText('half a minute ago');
      timer.current = setTimeout(() => update(date), 40 * 1000);
      return;
    }

    // [60, 120) seconds
    if (seconds > -120) {
      setText('a minute ago');
      timer.current = setTimeout(() => update(date), 60 * 1000);
      return;
    }

    const minutes = m.diff(moment(), 'minutes');

    // [2, 60) minutes
    if (minutes > -60) {
      setText(`${Math.abs(minutes)} minutes ago`);
      timer.current = setTimeout(() => update(date), 60 * 1000);
      return;
    }

    const hours = m.diff(moment(), 'hours');

    // [1, 24) hours
    if (hours > -24) {
      setText(`${Math.abs(hours)} hour${Math.abs(hours) > 1 ? 's' : ''} ago`);
      timer.current = setTimeout(() => update(date), 60 * 60 * 1000);
      return;
    }

    const days = m.diff(moment(), 'days');
    setText(`${Math.abs(days)} days ago`);
  }

  return (
    <Typography title={fullDate ? `${prefix || ''}${space}${fullDate}` : ''} {...props}>
      {prefix || ''}
      {space}
      {text}
    </Typography>
  );
}
