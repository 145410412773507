import React, { useState, useEffect } from 'react';
import { Grid, Tooltip, IconButton, Button, Typography } from '@mui/material';
import { DialogContent, DialogTitle, Paper, ButtonGroup } from '@mui/material';
import { FormatAlignLeftRounded, FormatAlignCenterRounded, FormatAlignRightRounded } from '@mui/icons-material';
import { PaletteRounded, AddRounded, RemoveRounded } from '@mui/icons-material';
import { FormatBoldRounded, FormatItalicRounded, FormatUnderlinedRounded } from '@mui/icons-material';
import { grey, blue } from '@mui/material/colors';
import { isFunction } from 'lodash';
import { RoundedDialog, StandardDialogActions, ColorPicker } from '../Components';

export default function TextStyleDialog({ open, onClose, initial, initialText, onResult, withFormat }) {
  const [title, setTitle] = useState('Field title');
  const [fontSize, setFontSize] = useState(16);
  const [color, setColor] = useState(undefined);
  const [textAlign, setTextAlign] = useState('left');
  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);

  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);

  useEffect(() => {
    if (!open) return;

    setTitle(initialText || 'Field title');
    setFontSize(initial?.fontSize || 16);
    setColor(initial?.color || undefined);
    setTextAlign(initial?.textAlign || 'left');
    setBold(initial?.bold || false);
    setItalic(initial?.italic || false);
    setUnderline(initial?.underline || false);
  }, [open, initial, initialText]);

  function done() {
    if (!isFunction(onResult)) return;
    const result = {
      fontSize,
      color,
      textAlign,
      bold,
      italic,
      underline,
    };
    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth className="non-draggable">
      <DialogTitle>Text style</DialogTitle>
      <DialogContent>
        <Grid container style={{ padding: '0.5rem 0', minHeight: '5rem' }} alignItems="center">
          <Paper variant="outlined" style={{ width: '100%', borderRadius: 0, padding: '0.5rem', textAlign }}>
            <Typography
              style={{
                fontWeight: withFormat ? (bold ? 600 : 400) : 500,
                fontSize,
                color,
                fontStyle: italic ? 'italic' : undefined,
                textDecoration: underline ? 'underline' : undefined,
              }}
            >
              {title}
            </Typography>
          </Paper>
        </Grid>
        <Grid container alignItems="center">
          <Typography style={{ fontWeight: 500, marginRight: '1rem' }}>Alignment: </Typography>
          <ButtonGroup>
            <Tooltip title="Left" placement="top">
              <Button onClick={() => setTextAlign('left')}>
                <FormatAlignLeftRounded style={{ color: textAlign === 'left' ? blue[900] : grey[600] }} />
              </Button>
            </Tooltip>

            <Tooltip title="Center" placement="top">
              <Button onClick={() => setTextAlign('center')}>
                <FormatAlignCenterRounded style={{ color: textAlign === 'center' ? blue[900] : grey[600] }} />
              </Button>
            </Tooltip>

            <Tooltip title="Right" placement="top">
              <Button onClick={() => setTextAlign('right')}>
                <FormatAlignRightRounded style={{ color: textAlign === 'right' ? blue[900] : grey[600] }} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Grid>
        <Grid container alignItems="center" style={{ margin: '0.5rem 0' }}>
          <Typography style={{ fontWeight: 500, marginRight: '1rem' }}>Color: </Typography>
          <IconButton onClick={(e) => setColorPickerAnchor(e.currentTarget)}>
            <PaletteRounded style={{ color }} />
          </IconButton>
          <ColorPicker
            anchor={colorPickerAnchor}
            open={Boolean(colorPickerAnchor)}
            column={700}
            onClose={(res) => {
              if (res?.color) {
                setColor(res?.color);
              }
              setColorPickerAnchor(null);
            }}
          />
        </Grid>
        <Grid container alignItems="center">
          <Typography style={{ marginRight: '1rem' }}>
            <span style={{ fontWeight: 500 }}>Size:</span> {fontSize}
          </Typography>
          <ButtonGroup>
            <Button
              disabled={fontSize <= 8}
              onClick={() => {
                if (fontSize <= 8) return;
                setFontSize(fontSize - 1);
              }}
            >
              <RemoveRounded style={{ color: grey[600] }} />
            </Button>

            <Button
              disabled={fontSize >= 40}
              onClick={() => {
                if (fontSize >= 40) return;
                setFontSize(fontSize + 1);
              }}
            >
              <AddRounded style={{ color: grey[600] }} />
            </Button>
          </ButtonGroup>
        </Grid>
        {withFormat && (
          <Grid container style={{ marginTop: '0.5rem' }} alignItems="center">
            <Typography style={{ fontWeight: 500, marginRight: '1rem' }}>Format: </Typography>
            <ButtonGroup>
              <Tooltip title="Bold" placement="top">
                <Button onClick={() => setBold(!bold)}>
                  <FormatBoldRounded style={{ color: bold ? blue[900] : grey[600] }} />
                </Button>
              </Tooltip>

              <Tooltip title="Italic" placement="top">
                <Button onClick={() => setItalic(!italic)}>
                  <FormatItalicRounded style={{ color: italic ? blue[900] : grey[600] }} />
                </Button>
              </Tooltip>

              <Tooltip title="Underline" placement="top">
                <Button onClick={() => setUnderline(!underline)}>
                  <FormatUnderlinedRounded style={{ color: underline ? blue[900] : grey[600] }} />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
