import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cancelReview } from '../../redux/actions/review';
import { Paper, FormLabel, FormControl } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

import { RoundedDialog, StandardDialogActions, DatePicker } from '../../../../Global/Components';

export default function ({ open, onClose, onDone }) {
  const [reviewDate, setReviewDate] = useState(null);

  return (
    <RoundedDialog
      fullWidth
      maxWidth="sm"
      open={open}
      scroll={'paper'}
      titleId="cancel-review-dialog"
      onClose={onClose}
    >
      <DialogTitle id="document-cancel-review-title">Cancel Document Review</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column">
          <Grid item container direction="column" justifyContent="flex-start">
            <FormControl sx={{ my: 1 }}>
              <FormLabel sx={{ color: (t) => t.palette.error.main }}>
                Please select a new date for the next review, if you don't pick a date, the review settings will be
                turned off.
              </FormLabel>
              <DatePicker value={reviewDate} onChange={setReviewDate} />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={() => onDone(reviewDate)} doneButtonText="Confirm" />
    </RoundedDialog>
  );
}
