import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectFile } from '../../redux/actions';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import NotFound from '../Global/NotFound';
import FilePreviewer from '../Global/FilePreviewer';
import { Close } from '@mui/icons-material';
import { RoundedDialog } from '../Global/Components';

export default function Preview() {
  const { file, previewDialog, fullScreen } = useSelector(({ file }) => file);
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(selectFile({ file: null, previewDialog: false }));
  };

  return (
    <RoundedDialog
      open={Boolean(previewDialog)}
      onClose={closeDialog}
      fullWidth
      scroll="paper"
      maxWidth="xl"
      fullScreen={fullScreen}
      titleId={`file-preview-dialog-${file?._id}`}
    >
      {file && file.title && (
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item sm={10}>
              <Typography variant="h6">{file.title}</Typography>
            </Grid>
            <Grid item sm={1} align="right">
              <IconButton size="small" onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      {previewDialog && (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: 'auto' }}>
          {(file && file.location && file.mimetype) || (file && file.content && file.category) ? (
            <FilePreviewer
              src={file.location ? file.location : file.content}
              extension={file.mimetype ? file.mimetype : file.category}
              title={file.title}
              hasToc={file.hasToc ? file.hasToc : false}
              document={file}
            />
          ) : (
            <NotFound />
          )}
        </Grid>
      )}
    </RoundedDialog>
  );
}
