import { Grid } from '@mui/material';

export default function ({ children, ...props }) {
  return (
    <Grid
      container
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0.5),
        },
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0.5),
        },
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(1),
        },
        marginTop: '20px',
      })}
      {...props}
    >
      {children}
    </Grid>
  );
}
