import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getSet } from '../../redux/actions/set';

import { StandardDialogActions, RoundedDialog } from '../../../../Global/Components';
import StateManager from '../../../../Global/StateManager';
import Fields from './fields';
import useDocumentName from '../../hooks/useDocumentName';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const UseConventionSetDialog = ({ open, title, setId, initial, saving = false, handleClose, handleConfirm }) => {
  const dispatch = useDispatch();

  const { set } = useSelector(({ sets }) => sets);

  const [params, setParams] = useState([]);

  const documentName = useDocumentName({
    title,
    sequence: '[to be generated]',
    setId,
    initial: params,
  });

  useEffect(() => {
    if (!open || !setId) return;
    dispatch(getSet(setId));
  }, [open, setId, dispatch]);

  useEffect(() => {
    if (!open || !initial) return;
    setParams(initial || []);
  }, [open, initial]);

  const handleDone = () => {
    const filteredFields = set?.fields?.filter((field) => ['textArea', 'number', 'dropbox'].includes(field.fieldType));
    if ((params?.length === 0 || params?.some((x) => x.value === '' || !x.value)) && filteredFields?.length > 0) {
      StateManager.setErrorAlert('Missing Data in Fields');
    } else {
      handleConfirm(params);
    }
  };

  const handleChange = (e) => {
    // filter fileds that are dynamic
    const filteredFields = e.filter((field) => ['textArea', 'number', 'dropbox'].includes(field.fieldType));
    const result = filteredFields?.map((x) => ({
      id: x.id,
      value: x.value ? x.value : '',
      fieldType: x.fieldType,
      type: x.type,
    }));
    setParams(result);
  };

  return (
    <RoundedDialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle component="div">
        <Typography variant="h6">Your Document Naming Convention</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Fields initial={set?.fields} onItemsChange={handleChange} initialParams={params} />
        <Divider sx={{ margin: (theme) => theme.spacing(2, 1) }} />
        <Grid container sx={{ padding: (theme) => theme.spacing(1, 0) }}>
          <Typography variant="h6" sx={{ padding: (theme) => theme.spacing(0, 1) }}>
            Document Name
          </Typography>
        </Grid>
        <Grid container alignItems="center" sx={{ padding: 1 }}>
          {documentName}
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={handleClose} onDone={handleDone} saving={saving} />
    </RoundedDialog>
  );
};

export default UseConventionSetDialog;
