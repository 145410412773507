export const DOCLIBRARY_ATTEMPT = 'DOCLIBRARY_ATTEMPT';
export const DOCLIBRARY_ERROR = 'DOCLIBRARY_ERROR';

export const SET_UPLOAD_DIALOG = 'SET_UPLOAD_DIALOG';

export const GETMYFILES_ATTEMPT = 'GETMYFILES_ATTEMPT';
export const GETMYFILES_SUCCESS = 'GETMYFILES_SUCCESS';
export const GETMYFILES_FAIL = 'GETMYFILES_FAIL';
export const CLEARMYFILES = 'CLEARMYFILES';

export const GETBOOKMARKEDFILES_ATTEMPT = 'GETBOOKMARKEDFILES_ATTEMPT';
export const GETBOOKMARKEDFILES_SUCCESS = 'GETBOOKMARKEDFILES_SUCCESS';
export const GETBOOKMARKEDFILES_FAIL = 'GETBOOKMARKEDFILES_FAIL';

export const GETUPLOADEDFILES_ATTEMPT = 'GETUPLOADEDFILES_ATTEMPT';
export const GETUPLOADEDFILES_SUCCESS = 'GETUPLOADEDFILES_SUCCESS';
export const GETUPLOADEDFILES_FAIL = 'GETUPLOADEDFILES_FAIL';

export const GETRESPONSIBLEFILES_ATTEMPT = 'GETRESPONSIBLEFILES_ATTEMPT';
export const GETRESPONSIBLEFILES_SUCCESS = 'GETRESPONSIBLEFILES_SUCCESS';
export const GETRESPONSIBLEFILES_FAIL = 'GETRESPONSIBLEFILES_FAIL';

export const GETCONTROLLEDFILES_ATTEMPT = 'GETCONTROLLEDFILES_ATTEMPT';
export const GETCONTROLLEDFILES_SUCCESS = 'GETCONTROLLEDFILES_SUCCESS';
export const GETCONTROLLEDFILES_FAIL = 'GETCONTROLLEDFILES_FAIL';

export const GETUSER_ATTEMPT = 'GETUSER_ATTEMPT';
export const GETUSER_SUCCESS = 'GETUSER_SUCCESS';
export const GETUSER_FAIL = 'GETUSER_FAIL';

export const GETAREAS_ATTEMPT = 'GETAREAS_ATTEMPT';
export const GETAREAS_SUCCESS = 'GETAREAS_SUCCESS';
export const GETAREAS_FAIL = 'GETAREAS_FAIL';

export const GETAREA_ATTEMPT = 'GETAREA_ATTEMPT';
export const GETAREA_SUCCESS = 'GETAREA_SUCCESS';
export const GETAREA_FAIL = 'GETAREA_FAIL';

export const GETMYAREA_ATTEMPT = 'GETMYAREA_ATTEMPT';
export const GETMYAREA_SUCCESS = 'GETMYAREA_SUCCESS';
export const GETMYAREA_FAIL = 'GETMYAREA_FAIL';

export const GETARCHIVES_ATTEMPT = 'GETARCHIVES_ATTEMPT';
export const GETARCHIVES_SUCCESS = 'GETARCHIVES_SUCCESS';
export const GETARCHIVES_FAIL = 'GETARCHIVES_FAIL';

export const UPDATEDOCUMENT_ATTEMPT = 'UPDATEDOCUMENT_ATTEMPT';
export const UPDATEDOCUMENT_SUCCESS = 'UPDATEDOCUMENT_SUCCESS';
export const UPDATEDOCUMENT_FAIL = 'UPDATEDOCUMENT_FAIL';

export const ADDAREA_ATTEMPT = 'ADDAREA_ATTEMPT';
export const ADDAREA_SUCCESS = 'ADDAREA_SUCCESS';
export const ADDAREA_FAIL = 'ADDAREA_FAIL';

export const UPDATEAREA_ATTEMPT = 'UPDATEAREA_ATTEMPT';
export const UPDATEAREA_SUCCESS = 'UPDATEAREA_SUCCESS';
export const UPDATEAREA_FAIL = 'UPDATEAREA_FAIL';

export const DELETEAREA_ATTEMPT = 'DELETEAREA_ATTEMPT';
export const DELETEAREA_SUCCESS = 'DELETEAREA_SUCCESS';
export const DELETEAREA_FAIL = 'DELETEAREA_FAIL';

export const UPLOADFILES_ATTEMPT = 'UPLOADFILES_ATTEMPT';
export const UPLOADFILES_SUCCESS = 'UPLOADFILES_SUCCESS';
export const UPLOADFILES_FAIL = 'UPLOADFILES_FAIL';

export const UPLOADFILE_ATTEMPT = 'UPLOADFILE_ATTEMPT';
export const UPLOADFILE_SUCCESS = 'UPLOADFILE_SUCCESS';
export const UPLOADFILE_FAIL = 'UPLOADFILE_FAIL';

export const REMOVEFILE = 'REMOVEFILE';
export const REMOVEFILES = 'REMOVEFILES';

export const GETDOCUMENT_ATTEMPT = 'GETDOCUMENT_ATTEMPT';
export const GETDOCUMENT_SUCCESS = 'GETDOCUMENT_SUCCESS';
export const GETDOCUMENT_FAIL = 'GETDOCUMENT_FAIL';

export const ADDDOCUMENTS_ATTEMPT = 'ADDDOCUMENTS_ATTEMPT';
export const ADDDOCUMENTS_SUCCESS = 'ADDDOCUMENTS_SUCCESS';
export const ADDDOCUMENTS_FAIL = 'ADDDOCUMENTS_FAIL';

export const ADDDOCUMENT_ATTEMPT = 'ADDDOCUMENT_ATTEMPT';
export const ADDDOCUMENT_SUCCESS = 'ADDDOCUMENT_SUCCESS';
export const ADDDOCUMENT_FAIL = 'ADDDOCUMENT_FAIL';

export const SETCURRENTFOLDER = 'SETCURRENTFOLDER';
export const SETCURRENTAREA = 'SETCURRENTAREA';
export const SETISFOLDER = 'SETISFOLDER';
export const SETCURRENTPATH = 'SETCURRENTPATH';
export const GOBACKPATH = 'GOBACKPATH';
export const SETCONTROLLEDINDEX = 'SETCONTROLLEDINDEX';
export const SETSELECTEDDOC = 'SETSELECTEDDOC';
export const VALIDATESEQLOADING = 'VALIDATESEQLOADING';
export const VALIDATESEQ = 'VALIDATESEQ';

export const GETBOOKMARKEDFOLDERS_ATTEMPT = 'GETBOOKMARKEDFOLDERS_ATTEMPT';
export const GETBOOKMARKEDFOLDERS_SUCCESS = 'GETBOOKMARKEDFOLDERS_SUCCESS';
export const GETBOOKMARKEDFOLDERS_FAIL = 'GETBOOKMARKEDFOLDERS_FAIL';

export const GETFOLDERS_ATTEMPT = 'GETFOLDERS_ATTEMPT';
export const GETFOLDERS_SUCCESS = 'GETFOLDERS_SUCCESS';
export const GETFOLDERS_FAIL = 'GETFOLDERS_FAIL';

export const GETFOLDER_ATTEMPT = 'GETFOLDER_ATTEMPT';
export const GETFOLDER_SUCCESS = 'GETFOLDER_SUCCESS';
export const GETFOLDER_FAIL = 'GETFOLDER_FAIL';

export const MOVEDOCUMENT_ATTEMPT = 'MOVEDOCUMENT_ATTEMPT';
export const MOVEDOCUMENT_SUCCESS = 'MOVEDOCUMENT_SUCCESS';
export const MOVEDOCUMENT_FAIL = 'MOVEDOCUMENT_FAIL';

export const DELETEDOCUMENT_ATTEMPT = 'DELETEDOCUMENT_ATTEMPT';
export const DELETEDOCUMENT_SUCCESS = 'DELETEDOCUMENT_SUCCESS';
export const DELETEDOCUMENT_FAIL = 'DELETEDOCUMENT_FAIL';

export const ADDFOLDER_ATTEMPT = 'ADDFOLDER_ATTEMPT';
export const ADDFOLDER_SUCCESS = 'ADDFOLDER_SUCCESS';
export const ADDFOLDER_FAIL = 'ADDFOLDER_FAIL';

export const UPDATEFOLDER_ATTEMPT = 'UPDATEFOLDER_ATTEMPT';
export const UPDATEFOLDER_SUCCESS = 'UPDATEFOLDER_SUCCESS';
export const UPDATEFOLDER_FAIL = 'UPDATEFOLDER_FAIL';

export const DELETEFOLDER_ATTEMPT = 'DELETEFOLDER_ATTEMPT';
export const DELETEFOLDER_SUCCESS = 'DELETEFOLDER_SUCCESS';
export const DELETEFOLDER_FAIL = 'DELETEFOLDER_FAIL';

export const ADDAREASETTINGS_ATTEMPT = 'ADDAREASETTINGS_ATTEMPT';
export const ADDAREASETTINGS_SUCCESS = 'ADDAREASETTINGS_SUCCESS';
export const ADDAREASETTINGS_FAIL = 'ADDAREASETTINGS_FAIL';

export const AREASETTING_ATTEMPT = 'AREASETTING_ATTEMPT';
export const AREASETTING_ERROR = 'AREASETTING_ERROR';

export const GETAREASETTINGS_ATTEMPT = 'GETAREASETTINGS_ATTEMPT';
export const GETAREASETTINGS_SUCCESS = 'GETAREASETTINGS_SUCCESS';
export const GETAREASETTING_ATTEMPT = 'GETAREASETTING_ATTEMPT';
export const GETAREASETTING_SUCCESS = 'GETAREASETTING_SUCCESS';
export const GETAREASETTINGS_FAIL = 'GETAREASETTINGS_FAIL';
export const GETAREASETTINGSBYUSER_SUCCESS = 'GETAREASETTINGSBYUSER_SUCCESS';
export const GETALLAREASETTINGSBYUSER_SUCCESS = 'GETALLAREASETTINGSBYUSER_SUCCESS';

export const UPDATEAREASETTINGS_ATTEMPT = 'UPDATEAREASETTINGS_ATTEMPT';
export const UPDATEAREASETTINGS_SUCCESS = 'UPDATEAREASETTINGS_SUCCESS';
export const UPDATEAREASETTINGS_FAIL = 'UPDATEAREASETTINGS_FAIL';

export const DELETEAREASETTINGS_ATTEMPT = 'DELETEAREASETTINGS_ATTEMPT';
export const DELETEAREASETTINGS_SUCCESS = 'DELETEAREASETTINGS_SUCCESS';
export const DELETEAREASETTINGS_FAIL = 'DELETEAREASETTINGS_FAIL';

export const REQUESTAREA_ATTEMPT = 'REQUESTAREA_ATTEMPT';
export const REQUESTAREA_SUCCESS = 'REQUESTAREA_SUCCESS';
export const REQUESTAREA_FAIL = 'REQUESTAREA_FAIL';
export const SETREQUESTALERT = 'SETREQUESTALERT';

export const GETREVISIONFILES_ATTEMPT = 'GETREVISIONFILES_ATTEMPT';
export const GETREVISIONFILES_SUCCESS = 'GETREVISIONFILES_SUCCESS';
export const GETREVISIONFILES_FAIL = 'GETREVISIONFILES_FAIL';

export const GETREVIEWS_ATTEMPT = 'GETREVIEWS_ATTEMPT';
export const GETREVIEWS_SUCCESS = 'GETREVIEWS_SUCCESS';
export const GETREVIEWS_FAIL = 'GETREVIEWS_FAIL';

export const ADDREVIEW_ATTEMPT = 'ADDREVIEW_ATTEMPT';
export const ADDREVIEW_SUCCESS = 'ADDREVIEW_SUCCESS';
export const ADDREVIEW_FAIL = 'ADDREVIEW_FAIL';

export const STARTREVIEW_ATTEMPT = 'STARTREVIEW_ATTEMPT';
export const STARTREVIEW_SUCCESS = 'STARTREVIEW_SUCCESS';
export const STARTREVIEW_FAIL = 'STARTREVIEW_FAIL';

export const UPDATEREVIEW_ATTEMPT = 'UPDATEREVIEW_ATTEMPT';
export const UPDATEREVIEW_SUCCESS = 'UPDATEREVIEW_SUCCESS';
export const UPDATEREVIEW_FAIL = 'UPDATEREVIEW_FAIL';

export const REVIEWCOMMENT_ATTEMPT = 'REVIEWCOMMENT_ATTEMPT';
export const REVIEWCOMMENT_FAIL = 'REVIEWCOMMENT_FAIL';

export const SETREVIEW = 'SETREVIEW';

export const SEARCHDOCUMENTS_ATTEMPT = 'SEARCHDOCUMENTS_ATTEMPT';
export const SEARCHDOCUMENTS_SUCCESS = 'SEARCHDOCUMENTS_SUCCESS';
export const SEARCHDOCUMENTS_FAIL = 'SEARCHDOCUMENTS_FAIL';

export const GETTAGS_ATTEMPT = 'GETTAGS_ATTEMPT';
export const GETTAGS_SUCCESS = 'GETTAGS_SUCCESS';
export const GETTAGS_FAIL = 'GETTAGS_FAIL';

export const ADDTAG_ATTEMPT = 'ADDTAG_ATTEMPT';
export const ADDTAG_SUCCESS = 'ADDTAG_SUCCESS';
export const ADDTAG_FAIL = 'ADDTAG_FAIL';

export const UPDATETAG_ATTEMPT = 'UPDATETAGS_ATTEMPT';
export const UPDATETAG_SUCCESS = 'UPDATETAGS_SUCCESS';
export const UPDATETAG_FAIL = 'UPDATETAGS_FAIL';

export const REMOVETAG_ATTEMPT = 'REMOVETAG_ATTEMPT';
export const REMOVETAG_SUCCESS = 'REMOVETAG_SUCCESS';
export const REMOVETAG_FAIL = 'REMOVETAG_FAIL';

export const GETPREFIXES_ATTEMPT = 'GETPREFIXES_ATTEMPT';
export const GETPREFIXES_SUCCESS = 'GETPREFIXES_SUCCESS';
export const GETPREFIXES_FAIL = 'GETPREFIXES_FAIL';

export const ADDPREFIX_ATTEMPT = 'ADDPREFIX_ATTEMPT';
export const ADDPREFIX_SUCCESS = 'ADDPREFIX_SUCCESS';
export const ADDPREFIX_FAIL = 'ADDPREFIX_FAIL';

export const UPDATEPREFIX_ATTEMPT = 'UPDATEPREFIXS_ATTEMPT';
export const UPDATEPREFIX_SUCCESS = 'UPDATEPREFIXS_SUCCESS';
export const UPDATEPREFIX_FAIL = 'UPDATEPREFIXS_FAIL';

export const REMOVEPREFIX_ATTEMPT = 'REMOVEPREFIX_ATTEMPT';
export const REMOVEPREFIX_SUCCESS = 'REMOVEPREFIX_SUCCESS';
export const REMOVEPREFIX_FAIL = 'REMOVEPREFIX_FAIL';

export const GETSETTINGS_ATTEMPT = 'GETSETTINGS_ATTEMPT';
export const GETSETTINGS_SUCCESS = 'GETSETTINGS_SUCCESS';
export const GETSETTINGS_FAIL = 'GETSETTINGS_FAIL';

export const UPDATESETTINGS_ATTEMPT = 'UPDATESETTINGS_ATTEMPT';
export const UPDATESETTINGS_SUCCESS = 'UPDATESETTINGS_SUCCESS';
export const UPDATESETTINGS_FAIL = 'UPDATESETTINGS_FAIL';
export const SETUPDATE = 'SETUPDATE';

export const GETALLDOCS_ATTEMPT = 'GETALLDOCS_ATTEMPT';
export const GETALLDOCS_SUCCESS = 'GETALLDOCS_SUCCESS';
export const GETALLDOCS_FAIL = 'GETALLDOCS_FAIL';

export const GETDOCUSERS_ATTEMPT = 'GETDOCUSERS_ATTEMPT';
export const GETDOCUSERS_SUCCESS = 'GETDOCUSERS_SUCCESS';
export const GETDOCUSERS_FAIL = 'GETDOCUSERS_FAIL';

export const DELETELINKEDFILE_ATTEMPT = 'DELETELINKEDFILE_ATTEMPT';
export const DELETELINKEDFILE_SUCCESS = 'DELETELINKEDFILE_SUCCESS';

export const GETLINKEDFILES_ATTEMPT = 'GETLINKEDFILES_ATTEMPT';
export const GETLINKEDFILES_SUCCESS = 'GETLINKEDFILES_SUCCESS';
export const GETLINKEDFILES_FAIL = 'GETLINKEDFILES_FAIL';

export const ADDLINK_ATTEMPT = 'ADDLINK_ATTEMPT';
export const ADDLINK_SUCCESS = 'ADDLINK_SUCCESS';
export const ADDLINK_FAIL = 'ADDLINK_FAIL';

export const UPDATELINKEDFILES_ATTEMPT = 'UPDATELINKEDFILES_ATTEMPT';
export const UPDATELINKEDFILES_SUCCESS = 'UPDATELINKEDFILES_SUCCESS';
export const UPDATELINKEDFILES_FAIL = 'UPDATELINKEDFILES_FAIL';

export const SEARCH_ATTEMPT = 'SEARCH_ATTEMPT';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAIL = 'SEARCH_FAIL';

export const QSEARCH_ATTEMPT = 'QSEARCH_ATTEMPT';
export const QSEARCH_SUCCESS = 'QSEARCH_SUCCESS';
export const QSEARCH_FAIL = 'QSEARCH_FAIL';
export const CLEARQRESULTS = 'CLEARQRESULTS';

export const RESETSECTIONS = 'RESETSECTIONS';

export const ADDSECTION = 'ADDSECTION';
export const UPDATESECTION = 'UPDATESECTION';
export const REMOVESECTION = 'REMOVESECTION';
export const MOVESECTION = 'MOVESECTION';
export const ALIGNSECTION = 'ALIGNSECTION';

export const ADDCONTENT = 'ADDCONTENT';
export const UPDATECONTENT = 'UPDATECONTENT';
export const MOVECONTENT = 'MOVECONTENT';
export const REMOVECONTENT = 'REMOVECONTENT';
export const ALIGNBOX = 'ALIGNBOX';

export const ALIGNBOXL2 = 'ALIGNBOXL2';
export const ADDBOXL2 = 'ADDBOXL2';
export const UPDATEBOXL2 = 'UPDATEBOXL2';
export const MOVEBOXL2 = 'MOVEBOXL2';
export const REMOVEBOXL2 = 'REMOVEBOXL2';

export const GETPATHAREAS_ATTEMPT = 'GETPATHAREAS_ATTEMPT';
export const GETPATHAREAS_SUCCESS = 'GETPATHAREAS_SUCCESS';
export const GETPATHAREAS_FAIL = 'GETPATHAREAS_FAIL';

export const GETPATHAREA_ATTEMPT = 'GETPATHAREA_ATTEMPT';
export const GETPATHAREA_SUCCESS = 'GETPATHAREA_SUCCESS';
export const GETPATHAREA_FAIL = 'GETPATHAREA_FAIL';

export const GETPATHMYAREA_ATTEMPT = 'GETPATHMYAREA_ATTEMPT';
export const GETPATHMYAREA_SUCCESS = 'GETPATHMYAREA_SUCCESS';
export const GETPATHMYAREA_FAIL = 'GETPATHMYAREA_FAIL';

export const GETPATHFOLDER_ATTEMPT = 'GETPATHFOLDER_ATTEMPT';
export const GETPATHFOLDER_SUCCESS = 'GETPATHFOLDER_SUCCESS';
export const GETPATHFOLDER_FAIL = 'GETPATHFOLDER_FAIL';

export const GETPATHFOLDERS_ATTEMPT = 'GETPATHFOLDERS_ATTEMPT';
export const GETPATHFOLDERS_SUCCESS = 'GETPATHFOLDERS_SUCCESS';
export const GETPATHFOLDERS_FAIL = 'GETPATHFOLDERS_FAIL';

export const SETPATHCURRENTFOLDER = 'SETPATHCURRENTFOLDER';

export const ADDQDOC_ATTEMPT = 'ADDQDOC_ATTEMPT';
export const ADDQDOC_SUCCESS = 'ADDQDOC_SUCCESS';
export const ADDQDOC_FAIL = 'ADDQDOC_FAIL';

export const ADDDRAFTQDOC_ATTEMPT = 'ADDDRAFTQDOC_ATTEMPT';
export const ADDDRAFTQDOC_SUCCESS = 'ADDDRAFTQDOC_SUCCESS';
export const ADDDRAFTQDOC_FAIL = 'ADDDRAFTQDOC_FAIL';

export const FETCHTASK_ATTEMPT = 'FETCHTASK_ATTEMPT';
export const FETCHTASK_SUCCESS = 'FETCHTASK_SUCCESS';
export const FETCHTASK_FAIL = 'FETCHTASK_FAIL';

export const ADDHUBTASK_ATTEMPT = 'ADDHUBTASK_ATTEMPT';
export const ADDHUBTASK_SUCCESS = 'ADDHUBTASK_SUCCESS';
export const ADDHUBTASK_FAIL = 'ADDHUBTASK_FAIL';

export const UPDATEHUBTASK_ATTEMPT = 'UPDATEHUBTASK_ATTEMPT';
export const UPDATEHUBTASK_SUCCESS = 'UDPATEHUBTASK_SUCCESS';
export const UPDATEHUBTASK_FAIL = 'UPDATEHUBTASK_FAIL';

export const DELETEHUBTASK_ATTEMPT = 'DELETEHUBTASK_ATTEMPT';
export const DELETEHUBTASK_SUCCESS = 'DELETEHUBTASK_SUCCESS';
export const DELETEHUBTASK_FAIL = 'DELETEHUBTASK_FAIL';

export const ADDTASK_ATTEMPT = 'ADDTASK_ATTEMPT';
export const ADDTASK_SUCCESS = 'ADDTASK_SUCCESS';
export const ADDTASK_FAIL = 'ADDTASK_FAIL';

export const UPDATETASK_ATTEMPT = 'UPDATETASK_ATTEMPT';
export const UPDATETASK_SUCCESS = 'UPDATETASK_SUCCESS';
export const UPDATETASK_FAIL = 'UPDATETASK_FAIL';

export const DELETETASK_ATTEMPT = 'DELETETASK_ATTEMPT';
export const DELETETASK_SUCCESS = 'DELETETASK_SUCCESS';
export const DELETETASK_FAIL = 'DELETETASK_FAIL';

export const SETSETUPDIALOG = 'SETSETUPDIALOG';
export const SETTASKDIALOG = 'SETTASKDIALOG';
export const SETTASK = 'SETTASK';
export const SETREVIEWMODE = 'SETREVIEWMODE';

export const GETREVIEW_ATTEMPT = 'GETREVIEW_ATTEMPT';
export const GETREVIEW_SUCCESS = 'GETREVIEW_SUCCESS';
export const GETREVIEW_FAIL = 'GETREVIEW_FAIL';

export const GETTASKSBYREVIEW_ATTEMPT = 'GETTASKSBYREVIEW_ATTEMPT';
export const GETTASKSBYREVIEW_SUCCESS = 'GETTASKSBYREVIEW_SUCCESS';
export const GETTASKSBYREVIEW_FAIL = 'GETTASKSBYREVIEW_FAIL';

export const GETQDOC_ATTEMPT = 'GETQDOC_ATTEMPT';
export const GETQDOC_SUCCESS = 'GETQDOC_SUCCESS';
export const GETQDOC_FAIL = 'GETQDOC_FAIL';

export const GETQISSUE_ATTEMPT = 'GETQISSUE_ATTEMPT';
export const GETQISSUE_SUCCESS = 'GETQISSUE_SUCCESS';
export const GETQISSUE_FAIL = 'GETQISSUE_FAIL';

export const UPDATEQDOC_ATTEMPT = 'UPDATEQDOC_ATTEMPT';
export const UPDATEQDOC_SUCCESS = 'UPDATEQDOC_SUCCESS';
export const UPDATEQDOC_FAIL = 'UPDATEQDOC_FAIL';

export const SETQDOC = 'SETQDOC';
export const SETQSTATUS = 'SETQSTATUS';
export const INITQDOC = 'INITQDOC';

export const SETANCHORDATA = 'SETANCHORDATA';
export const UPDATEANCHORDATA = 'UPDATEANCHORDATA';

export const ADDANCHOR_ATTEMPT = 'ADDANCHOR_ATTEMPT';
export const ADDANCHOR_SUCCESS = 'ADDANCHOR_SUCCESS';
export const ADDANCHOR_FAIL = 'ADDANCHOR_FAIL';

export const FETCHANCHORS_ATTEMPT = 'FETCHANCHORS_ATTEMPT';
export const FETCHANCHORS_SUCCESS = 'FETCHANCHORS_SUCCESS';
export const FETCHANCHORS_FAIL = 'FETCHANCHORS_FAIL';

export const SETDOCALERT = 'SETDOCALERT';

export const BOOKMARKDOC_ATTEMPT = 'BOOKMARKDOC_ATTEMPT';
export const BOOKMARKDOC_SUCCESS = 'BOOKMARKDOC_SUCCESS';
export const BOOKMARKDOC_FAIL = 'BOOKMARKDOC_FAIL';

export const UNBOOKMARKDOC_ATTEMPT = 'UNBOOKMARKDOC_ATTEMPT';
export const UNBOOKMARKDOC_SUCCESS = 'UNBOOKMARKDOC_SUCCESS';
export const UNBOOKMARKDOC_FAIL = 'UNBOOKMARKDOC_FAIL';

export const BOOKMARKFOLDER_ATTEMPT = 'BOOKMARKFOLDER_ATTEMPT';
export const BOOKMARKFOLDER_SUCCESS = 'BOOKMARKFOLDER_SUCCESS';
export const BOOKMARKFOLDER_FAIL = 'BOOKMARKFOLDER_FAIL';

export const UNBOOKMARKFOLDER_ATTEMPT = 'UNBOOKMARKFOLDER_ATTEMPT';
export const UNBOOKMARKFOLDER_SUCCESS = 'UNBOOKMARKFOLDER_SUCCESS';
export const UNBOOKMARKFOLDER_FAIL = 'UNBOOKMARKFOLDER_FAIL';

export const FETCHBOOKMARKS_ATTEMPT = 'FETCHBOOKMARKS_ATTEMPT';
export const FETCHBOOKMARKS_SUCCESS = 'FETCHBOOKMARKS_SUCCESS';
export const FETCHBOOKMARKS_FAIL = 'FETCHBOOKMARKS_FAIL';

export const FETCHPENDINGUPISSUE_ATTEMPT = 'FETCHPENDINGUPISSUE_ATTEMPT';
export const FETCHPENDINGUPISSUE_SUCCESS = 'FETCHPENDINGUPISSUE_SUCCESS';
export const FETCHPENDINGUPISSUE_FAIL = 'FETCHPENDINGUPISSUE_FAIL';

export const FINISHPENDINGUPISSUE_ATTEMPT = 'FINISHPENDINGUPISSUE_ATTEMPT';
export const FINISHPENDINGUPISSUE_SUCCESS = 'FINISHPENDINGUPISSUE_SUCCESS';
export const FINISHPENDINGUPISSUE_FAIL = 'FINISHPENDINGUPISSUE_FAIL';

export const CANCELREVIEW_ATTEMPT = 'CANCELREVIEW_ATTEMPT';
export const CANCELREVIEW_SUCCESS = 'CANCELREVIEW_SUCCESS';
export const CANCELREVIEW_FAIL = 'CANCELREVIEW_FAIL';

export const CANCELUPISSUE_ATTEMPT = 'CANCELUPISSUE_ATTEMPT';
export const CANCELUPISSUE_SUCCESS = 'CANCELUPISSUE_SUCCESS';
export const CANCELUPISSUE_FAIL = 'CANCELUPISSUE_FAIL';

export const SETISSUE = 'SETISSUE';
export const SETSEARCHDIALOG = 'SETSEARCHDIALOG';
export const SETCONTROLDIALOG = 'SETCONTROLDIALOG';
export const HIGHLIGHTDOC = 'HIGHLIGHTDOC';
export const SETMOVEDIALOG = 'SETMOVEDIALOG';

export const GETDOCSTATS_SUCCESS = 'GETDOCSTATS_SUCCESS';
export const GETDOCFILES_SUCCESS = 'GETDOCFILES_SUCCESS';
export const DOCVIEWED_SUCCESS = 'DOCVIEWED_SUCCESS';

export const GETFOLDERSETTINGSBYUSER_SUCCESS = 'GETFOLDERSETTINGSBYUSER_SUCCESS';
export const GETFOLDERSETTING_ATTEMPT = 'GETFOLDERSETTING_ATTEMPT';
export const GETFOLDERSETTING_SUCCESS = 'GETFOLDERSETTING_SUCCESS';
export const GETFOLDERSETTINGS_SUCCESS = 'GETFOLDERSETTINGS_SUCCESS';
export const ADDFOLDERSETTINGS_SUCCESS = 'ADDFOLDERSETTINGS_SUCCESS';
export const UPDATEFOLDERSETTINGS_SUCCESS = 'UPDATEFOLDERSETTINGS_SUCCESS';
export const DELETEFOLDERSETTINGS_SUCCESS = 'DELETEFOLDERSETTINGS_SUCCESS';
export const REQUESTFOLDER_SUCCESS = 'REQUESTFOLDER_SUCCESS';
export const SHOWALERT = 'SHOWALERT';
export const SETGLOBALDOCALERT = 'SETGLOBALDOCALERT';
export const SETINITIALUPLOADCONFIG = 'SETINITIALUPLOADCONFIG';
export const BULKDOCMOVE = 'BULKDOCMOVE';
export const BULKFOLDERMOVE = 'BULKFOLDERMOV';
export const BULKFOLDERDELETE = 'BULKFOLDERDELETE';
export const BULKDOCDELETE = 'BULKDOCDELETE';
export const BULKDOCARCHIVE = 'BULKDOCARCHIVE';
export const SETDOCDRAWER = 'SETDOCDRAWER';
export const FETCHDOCCOMMENTS = 'FETCHDOCCOMMENTS';
export const FETCHDOCCOMMENTS_ATTEMPT = 'FETCHDOCCOMMENTS_ATTEMPT';
export const FETCHDOCCOMMENTS_ERROR = 'FETCHDOCCOMMENTS_ERROR';
export const ADDDOCCOMMENT = 'ADDDOCCOMMENT';
export const UPDATEDOCCOMMENT = 'UPDATEDOCCOMMENT';
export const DELETEDOCCOMMENT = 'DELETEDOCCOMMENT';
export const SETEDITORSTATE = 'SETEDITORSTATE';

export const GETMINIREVISIONS_LOADER = 'GETMINIREVISIONS_LOADER';
export const GETMINIREVISIONS_ERROR = 'GETMINIREVISIONS_ERROR';
export const GETMINIREVISIONS = 'GETMINIREVISIONS';

export const GETREVISION = 'GETREVISION';
export const GETQREVISION_SUCCESS = 'GETQREVISION_SUCCESS';
export const GETQREVISION_ATTEMPT = 'GETQREVISION_ATTEMPT';
export const GETQREVISION_ERROR = 'GETQREVISION_ERROR';
export const UPDATEQREVISION = 'UPDATEQREVISION';
export const UPDATEHF = 'UPDATEHF';
export const ADD_ISSUE = 'ADD_ISSUE';
export const SETSUGGESTIONS = 'SETSUGGESTIONS';
export const SETSECTIONS = 'SETSECTIONS';

export const SETREMOVECOMMENT = 'SETREMOVECOMMENT';
export const SETREMOVECOMMENTFILE = 'SETREMOVECOMMENTFILE';
export const FETCHREVCOMMENTS_ATTEMPT = 'FETCHREVCOMMENTS_ATTEMPT';
export const FETCHREVCOMMENT_ATTEMPT = 'FETCHREVCOMMENT_ATTEMPT';
export const FETCHREVCOMMENT = 'FETCHREVCOMMENT';
export const FETCHREVCOMMENTS = 'FETCHREVCOMMENTS';
export const ADDREVCOMMENT = 'ADDREVCOMMENT';
export const UPDATEREVCOMMENT = 'UPDATEREVCOMMENT';
export const UPDATEREPLYINREVCOMMENT = 'UPDATEREPLYINREVCOMMENT';
export const DELETEREVCOMMENT = 'DELETEREVCOMMENT';
export const DELETEREVCOMMENTS = 'DELETEREVCOMMENTS';

export const LINK_ATTEMPT = 'LINK_ATTEMPT';
export const LINK_ERROR = 'LINK_ERROR';
export const LINK_SUCCESS = 'LINK_SUCCESS';

export const INBOUND_SUCCESS = 'INBOUND_SUCCESS';
export const REVISION_ATTEMPT = 'REVISION_ATTEMPT';
export const REVISION_ERROR = 'REVISION_ERROR';

export const ADDCOMMENTTOSTATE = 'ADDCOMMENTTOSTATE';
export const SETNEWFILE = 'SETNEWFILE';

export const SETISSUEINBOUNDSTATE = 'SETISSUEINBOUNDSTATE';

export const ADDREVIEWCOMMENT = 'ADDREVIEWCOMMENT';
export const UPDATEREVIEWCOMMENT = 'UPDATEREVIEWCOMMENT';
export const DELETEREVIEWCOMMENT = 'DELETEREVIEWCOMMENT';

export const GENERATEPDF_LOADING = 'GENERATEPDF_LOADING';
export const GENERATEPDF_FINISHED = 'GENERATEPDF_FINISHED';

export const DOCOWNERS_ATTEMPT = 'DOCOWNERS_ATTEMPT';
export const DOCOWNERS_SUCCESS = 'DOCOWNERS_SUCCESS';
export const DOCOWNERS_FAIL = 'DOCOWNERS_FAIL';

export const HOME_ATTEMPT = 'HOME_ATTEMPT';
export const HOME_ERROR = 'HOME_ERROR';

export const SETANNOTATION_COUNT = 'SETANNOTATION_COUNT';

export const SETSUPPORTINGDOCS = 'SETSUPPORTINGDOCS';

export const READUNDERSTOODLIST_ATTEMPT = 'READUNDERSTOODLIST_ATTEMPT';
export const READUNDERSTOODACTION_ATTEMPT = 'READUNDERSTOODACTION_ATTEMPT';
export const READUNDERSTOODCONFIRM_ATTEMPT = 'READUNDERSTOODCONFIRM_ATTEMPT';
export const READUNDERSTOOD_ATTEMPT = 'READUNDERSTOOD_ATTEMPT';
export const READUNDERSTOODDOCS_ATTEMPT = 'READUNDERSTOODDOCS_ATTEMPT';
export const READUNDERSTOOD_ERROR = 'READUNDERSTOOD_ERROR';

export const GETCOMPANYTASKLIST = 'GETCOMPANYTASKLIST';
export const GETCOMPANYTASKLISTTOTALCOUNT = 'GETCOMPANYTASKLISTTOTALCOUNT';
export const GETMYTASKLIST = 'GETMYTASKLIST';
export const GETMYTASKLISTTOTALCOUNT = 'GETMYTASKLISTTOTALCOUNT';
export const GETSENTTASKLIST = 'GETSENTTASKLIST';
export const GETSENTTASKLISTTOTALCOUNT = 'GETSENTTASKLISTTOTALCOUNT';
export const GETTASKSBYVIEW = 'GETTASKSBYVIEW';
export const GETTASKSBYVIEWTOTALCOUNT = 'GETTASKSBYVIEWTOTALCOUNT';
export const GETTASKSBYPARAM = 'GETTASKSBYPARAM';
export const GETREADUNDERSTOODDOCS = 'GETREADUNDERSTOODDOCS';
export const GETREADUNDERSTOOD = 'GETREADUNDERSTOOD';
export const CLOSE_RU_DIALOG = 'CLOSE_RU_DIALOG';
export const SENDREADUNDERSTOOD = 'SENDREADUNDERSTOOD';
export const READUNDERSTOODACTIONCONFIRMED = 'READUNDERSTOODACTIONCONFIRMED';
export const DELETEREADUNDERSTOOD = 'DELETEREADUNDERSTOOD';
export const DELETEREADUNDERSTOODS = 'DELETEREADUNDERSTOODS';

export const VIEWSTATSCOUNT = 'VIEWSTATSCOUNT';
export const VIEWSLIST_ATTEMPT = 'VIEWSLIST_ATTEMPT';
export const VIEWSACTION_ATTEMPT = 'VIEWSACTION_ATTEMPT';
export const VIEWS_ERROR = 'VIEWS_ERROR';

export const GETVIEWS = 'GETVIEWS';
export const ADDVIEW = 'ADDVIEW';
export const UPDATEVIEW = 'UPDATEVIEW';
export const DELETEVIEW = 'DELETEVIEW';

export const QTEMPLATESLIST_ATTEMPT = 'QTEMPLATESLIST_ATTEMPT';
export const QTEMPLATESACTION_ATTEMPT = 'QTEMPLATESACTION_ATTEMPT';
export const QTEMPLATES_ERROR = 'QTEMPLATES_ERROR';

export const GETQTEMPLATES = 'GETQTEMPLATES';
export const GETQTEMPLATE = 'GETQTEMPLATE';
export const ADDQTEMPLATE = 'ADDQTEMPLATE';
export const UPDATEQTEMPLATE = 'UPDATEQTEMPLATE';
export const DELETEQTEMPLATE = 'DELETEQTEMPLATE';
export const SETTEMPLATEEDITORSTATE = 'SETTEMPLATEEDITORSTATE';
export const SETQDOCTITLE = 'SETQDOCTITLE';
export const SETQTEMPLATETITLE = 'SETQTEMPLATETITLE';

export const NORMALTEMPLATESLIST_ATTEMPT = 'NORMALTEMPLATESLIST_ATTEMPT';
export const NORMALTEMPLATESACTION_ATTEMPT = 'NORMALTEMPLATESACTION_ATTEMPT';
export const NORMALTEMPLATES_ERROR = 'NORMALTEMPLATES_ERROR';

export const GETNORMALTEMPLATES = 'GETNORMALTEMPLATES';
export const GETNORMALTEMPLATE = 'GETNORMALTEMPLATE';
export const ADDNORMALTEMPLATE = 'ADDNORMALTEMPLATE';
export const UPDATENORMALTEMPLATE = 'UPDATENORMALTEMPLATE';
export const DELETENORMALTEMPLATE = 'DELETENORMALTEMPLATE';
export const USENORMALTEMPLATE = 'USENORMALTEMPLATE';

export const FOLDERTEMPLATESLIST_ATTEMPT = 'FOLDERTEMPLATESLIST_ATTEMPT';
export const FOLDERTEMPLATESACTION_ATTEMPT = 'FOLDERTEMPLATESACTION_ATTEMPT';
export const FOLDERTEMPLATES_ERROR = 'FOLDERTEMPLATES_ERROR';

export const GETFOLDERTEMPLATES = 'GETFOLDERTEMPLATES';
export const GETFOLDERTEMPLATE = 'GETFOLDERTEMPLATE';
export const ADDFOLDERTEMPLATE = 'ADDFOLDERTEMPLATE';
export const UPDATEFOLDERTEMPLATE = 'UPDATEFOLDERTEMPLATE';
export const DELETEFOLDERTEMPLATE = 'DELETEFOLDERTEMPLATE';
export const USEFOLDERTEMPLATE = 'USEFOLDERTEMPLATE';

export const SETSLIST_ATTEMPT = 'SETSLIST_ATTEMPT';
export const SETSACTION_ATTEMPT = 'SETSACTION_ATTEMPT';
export const SETS_ERROR = 'SETS_ERROR';

export const GETSETS = 'GETSETS';
export const GETSET = 'GETSET';
export const ADDSET = 'ADDSET';
export const UPDATESET = 'UPDATESET';
export const DELETESET = 'DELETESET';

export const COPYTEMPLATES_ATTEMPT = 'COPYTEMPLATES_ATTEMPT';
export const COPYTEMPLATES_SUCCESS = 'COPYTEMPLATES_SUCCESS';
export const COPYTEMPLATES_ERROR = 'COPYTEMPLATES_ERROR';

// DraftJS Utils
export const SET_DRAFT_TABLEKEY = 'SET_DRAFT_TABLEKEY';

export const SETREFETCHTABLE = 'SETREFETCHTABLE';
export const SETREFETCHTABLETYPE = 'SETREFETCHTABLETYPE';
