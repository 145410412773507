import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Grid, TextField, CircularProgress, Button, Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Alert, AlertTitle, Paper } from '@mui/material';
import { blue, red } from '@mui/material/colors';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../../constants/msal';
import { getGroupLink } from '../../../../constants';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { MicrosoftIcon } from '../../../Global/Icons';
import Loader from '../../../Global/Loader';
import { SettingUp2faDialog } from '../Login';
import { getUser } from '../../../Profile/redux/actions';

export default function GroupSignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { instance, inProgress, accounts } = useMsal();
  const [data, setData] = useState({
    email: '',
    password: '',
    loading: false,
    loggedIn: false,
    error: false,
    errorMessage: '',
    text: 'Email or password incorrect, please try again...',
    access: '',
  });

  const { linkId } = useParams();
  const signupLink = getGroupLink(linkId, 'signup', false);

  const [group, setGroup] = useState(null);
  const [groupLoading, setGroupLoading] = useState(true);
  const [groupError, setGroupError] = useState('');

  const [ssoError, setSsoError] = useState(false);
  const [ssoErrorMessage, setSsoErrorMessage] = useState('');
  const [ssoLoading, setSsoLoading] = useState(false);
  const [tfaDialog, setTfaDialog] = useState(false);
  const [settingUp2faDialog, setSettingUp2faDialog] = useState(false);
  const { user, loggedIn, error: fetchingError } = useSelector(({ profile }) => profile);

  // log user out if the user not found
  useEffect(() => {
    if (fetchingError !== 'User not found') return;
    localStorage.clear();
    location.reload();
  }, [fetchingError]);

  const verify = () => {
    setData({ ...data, loading: true });
    axios
      .post(`/auth/login`, { email: data.email, password: data.password, twoFaCode: data.twoFaCode })
      .then((res) => {
        if (res.data.success === false && res.data.twoFaRequired) {
          setTfaDialog(true);
          setData({ ...data, loading: false, error: false, text: '' });
          return;
        }
        if (res.data.success === false && res.data.settingUp2FaRequired) {
          setSettingUp2faDialog(true);
          setData({
            ...data,
            loading: false,
            error: false,
            text: '',
            userId: res.data.userId,
            verificationId: res.data.verificationId,
          });
          return;
        }

        if (res.data.success && res.data.authJSON) {
          const previousUser = localStorage.getItem('previousUser');
          const currentUser = res.data.authJSON.id; // Store the user ID
          setData({ ...data, loggedIn: true });
          localStorage.clear();
          localStorage.setItem('token', res.data.authJSON.token);
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('name', res.data.authJSON.name);
          localStorage.setItem('_id', res.data.authJSON.id);
          localStorage.setItem('company', res.data.authJSON.company);
          localStorage.setItem('access', res.data.authJSON.access);
          localStorage.setItem('previousUser', currentUser);
          if (previousUser === currentUser) {
            const lastVisitedPage = localStorage.getItem('lastVisitedPage');
            if (lastVisitedPage) {
              history.push(lastVisitedPage);
            } else {
              window.location.href = '/home';
            }
          } else {
            window.location.href = '/home';
          }
        } else {
          if (res.data.forbiddenToken) {
            localStorage.setItem('forbiddenToken', res.data.forbiddenToken);
            window.location.href = '/suspended';
          } else if (res.data.companyArchived) {
            window.location.href = '/suspended';
          } else if (res.data.passwordChangeRequired) {
            window.location.href = `/change-password/${res.data.userId}?token=${res.data.token}`;
          } else if (res.data.trialEnded || res.data.subscriptionCancelled) {
            window.location.href = '/expired';
          } else if (res.data.userDeleted || res.data.userSuspended) {
            window.location.href = '/suspended';
          } else {
            setData({ ...data, error: true, loading: false, text: res.data.message, twoFaCode: '' });
          }
        }
      })
      .catch((err) => {
        setData({ ...data, error: true, loading: false, text: 'Login failed' });
      });
  };

  const handleSSOLogin = async () => {
    try {
      setSsoLoading(true);
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      setSsoLoading(false);
      console.error('Authentication error:', error);
    }
  };

  useEffect(() => {
    if (!loggedIn) return;
    const token = localStorage.getItem('token');
    if (loggedIn && token) {
      dispatch(getUser());
    }
    if (user?.access === 'portal') {
      history.push('/portal');
    } else {
      history.push('/home');
    }
  }, [user, dispatch, history, loggedIn]);

  useEffect(() => {
    if (!linkId) return;
    setGroupLoading(false);
    axios
      .get(`/auth/getGroup/${linkId}`)
      .then((res) => {
        setGroupLoading(false);
        if (res.data.success) {
          setGroup(res.data.group);
          return;
        } else {
          setGroupError(res.data.message);
        }
      })
      .catch((err) => {
        setGroupLoading(false);
        setGroupError(err?.response?.data?.message || 'Something went wrong');
      });
  }, [linkId]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const sessionKeys = Object.keys(sessionStorage);
    if (!accounts || accounts?.length === 0 || token || sessionKeys?.length === 0) return;
    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      setSsoLoading(true);
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Call your web api for oauth
          const jwt = accessTokenResponse.idToken;
          const accesstoken = accessTokenResponse.accessToken;
          axios
            .post(`/auth/login-with-sso`, null, {
              headers: {
                Authorization: jwt,
                accesstoken,
              },
            })
            .then((res) => {
              setSsoError(false);
              setSsoErrorMessage('');
              if (res.data.success && res.data.authJSON) {
                localStorage.clear();
                localStorage.setItem('token', res.data.authJSON.token);
                localStorage.setItem('loggedIn', 'true');
                localStorage.setItem('name', res.data.authJSON.name);
                localStorage.setItem('_id', res.data.authJSON.id);
                localStorage.setItem('company', res.data.authJSON.company);
                localStorage.setItem('access', res.data.authJSON.access);
                setSsoLoading(false);
                window.location.reload();
              } else {
                sessionStorage.clear();
                if (res.data.forbiddenToken) {
                  localStorage.setItem('forbiddenToken', res.data.forbiddenToken);
                  window.location.href = '/suspended';
                } else if (res.data.companyArchived) {
                  window.location.href = '/suspended';
                } else if (res.data.trialEnded || res.data.subscriptionCancelled) {
                  window.location.href = '/expired';
                } else if (res.data.userDeleted || res.data.userSuspended) {
                  window.location.href = '/suspended';
                } else {
                  setSsoError(true);
                  setSsoErrorMessage(res.data.message);
                  setSsoLoading(false);
                }
              }
            })
            .catch((err) => {
              setSsoLoading(false);
              setSsoError(true);
              setSsoErrorMessage('Login Failed');
            });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress]);

  if (settingUp2faDialog) {
    return (
      <SettingUp2faDialog
        open
        onClose={() => setSettingUp2faDialog(false)}
        onResult={() => setTfaDialog(true)}
        userId={data.userId}
        verificationId={data.verificationId}
      />
    );
  }

  return (
    <Grid container>
      {groupLoading && <Loader />}
      {!groupLoading && !group && groupError && (
        <Grid container item justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
          <Paper
            elevation={4}
            style={{
              marginTop: '5vh',
              borderRadius: 12,
              padding: '1rem',
              width: '100%',
              width: '80vw',
              maxWidth: 400,
            }}
          >
            <Grid container justifyContent="center">
              <Alert severity="error" sx={{ width: '100%', borderRadius: 1 }}>
                <AlertTitle>Error</AlertTitle>
                {groupError}
              </Alert>
            </Grid>
          </Paper>
        </Grid>
      )}
      {!groupLoading && group && !loggedIn && !user && (
        <>
          {group?.logo && (
            <Grid container item justifyContent="center">
              <img alt="img" src={group?.logo} style={{ height: 80, marginTop: 50 }} />
            </Grid>
          )}
          {group.name && (
            <Grid
              container
              item
              justifyContent={'center'}
              sx={{
                marginTop: !group.logo ? '50px' : '10px',
              }}
            >
              <Typography variant="h4" gutterBottom>
                {group.name}
              </Typography>
            </Grid>
          )}
          <Grid container item justifyContent="center">
            <Paper
              elevation={4}
              style={{
                marginTop: '5vh',
                borderRadius: 12,
                padding: '1rem',
                width: '100%',
                width: '80vw',
                maxWidth: 400,
              }}
            >
              {tfaDialog && (
                <Grid container spacing={4}>
                  <Grid container justifyContent="center" item style={{ textAlign: 'center' }}>
                    <Typography variant="h6">Please enter the code from Authy app</Typography>
                  </Grid>
                  {data.error && (
                    <Grid container item>
                      <Alert severity="error" style={{ width: '100%', borderRadius: 8 }}>
                        <AlertTitle>Error</AlertTitle>
                        {data.text}
                      </Alert>
                    </Grid>
                  )}
                  <Grid container item>
                    <TextField
                      fullWidth
                      label="Authy code"
                      variant="outlined"
                      value={data.twoFaCode}
                      onChange={(e) => {
                        e.preventDefault();
                        setData({ ...data, twoFaCode: e.target.value });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          verify();
                        }
                      }}
                    />
                  </Grid>
                  <Grid container item justifyContent="space-between">
                    <Button
                      style={{ borderRadius: 8, color: red[500] }}
                      onClick={() => {
                        setData({ ...data, twoFaCode: '' });
                        setTfaDialog(false);
                      }}
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={verify}
                      startIcon={data.loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                      style={{ borderRadius: 8 }}
                    >
                      Log In
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!tfaDialog && (
                <Grid container spacing={4}>
                  <Grid container justifyContent="center" item style={{ textAlign: 'center' }}>
                    <Typography variant="h4">Log In</Typography>
                  </Grid>
                  {data.error && (
                    <Grid container item>
                      <Alert severity="error" style={{ width: '100%', borderRadius: 8 }}>
                        <AlertTitle>Error</AlertTitle>
                        {data.text}
                      </Alert>
                    </Grid>
                  )}
                  {ssoError && (
                    <Grid container item>
                      <Alert
                        severity={ssoErrorMessage === 'Login Failed' ? 'error' : 'info'}
                        sx={{ width: '100%', borderRadius: 1 }}
                      >
                        {ssoErrorMessage === 'Login Failed' && <AlertTitle>Error</AlertTitle>}
                        {ssoErrorMessage}
                        {ssoErrorMessage !== 'Login Failed' && (
                          <Grid container alignItems="center">
                            Click{' '}
                            <Link
                              to="/signup"
                              style={{ color: blue[600], marginLeft: 4, marginRight: 4 }}
                              color="primary"
                            >
                              here
                            </Link>{' '}
                            to create an account
                          </Grid>
                        )}
                      </Alert>
                    </Grid>
                  )}
                  {!ssoLoading && (
                    <Grid container item>
                      <TextField
                        fullWidth
                        type="email"
                        id="user-email"
                        label="Email or Username"
                        variant="outlined"
                        value={data.email}
                        onChange={(e) => {
                          e.preventDefault();
                          setData({ ...data, email: e.target.value });
                        }}
                      />
                    </Grid>
                  )}
                  {!ssoLoading && (
                    <Grid container item>
                      <TextField
                        fullWidth
                        id="user-password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={data.password}
                        onChange={(e) => {
                          setData({ ...data, password: e.target.value });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            verify();
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {!ssoLoading && (
                    <Grid container item justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={verify}
                        startIcon={data.loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                        style={{ borderRadius: 8 }}
                      >
                        Log In
                      </Button>
                    </Grid>
                  )}
                  {ssoLoading && (
                    <Grid container item justifyContent="center">
                      <CircularProgress size={20} style={{ color: 'white' }} />
                    </Grid>
                  )}
                  {!ssoLoading && (
                    <Grid container item justifyContent="center">
                      <Box onClick={handleSSOLogin} sx={{ cursor: 'pointer' }}>
                        <MicrosoftIcon />
                      </Box>
                    </Grid>
                  )}
                  <Grid container item justifyContent="center" direction="column" style={{ textAlign: 'center' }}>
                    <Typography>or</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => history.push(signupLink)}
                      sx={{ borderRadius: 1, my: 1.5, textTransform: 'none' }}
                      size="small"
                    >
                      Create a {group?.name} account
                    </Button>
                    <Link to="/forgot-password">
                      <Typography style={{ color: blue[600] }}>Forgot your password?</Typography>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}
