import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import { isFunction } from 'lodash';

export default function DnDList({
  items,
  setItems,
  renderItem,
  direction = 'vertical',
  dropableProps,
  spacing = 2,
  disabled,
  onDragStart,
  onDragEnd,
  ...props
}) {
  const theme = useTheme();
  const [ownItems, setOwnItems] = useState([]);

  useEffect(() => {
    if (!items) return;
    let data = [...items];
    for (let i = 0; i < data.length; ++i) {
      if (!data[i].id) data.id = v4();
    }
    setOwnItems(data);
  }, [items]);

  function save(result) {
    if (isFunction(onDragEnd)) {
      onDragEnd(result);
    }

    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    const [removed] = ownItems.splice(result.source.index, 1);
    ownItems.splice(result.destination.index, 0, removed);

    setItems([...ownItems]);
  }

  return (
    <Grid {...props} container>
      <DragDropContext onDragEnd={save} onDragStart={isFunction(onDragStart) ? onDragStart : undefined}>
        <Droppable droppableId="draggable" direction={direction}>
          {(provided) => (
            <Grid item container ref={provided.innerRef} {...provided.droppableProps} {...dropableProps}>
              {ownItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={Boolean(disabled)}>
                  {(provided, snapshot) => (
                    <Grid
                      item
                      container={direction === 'vertical'}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ margin: theme.spacing(spacing), ...provided.draggableProps.style }}
                    >
                      {renderItem(item, snapshot.isDragging)}
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
}
