import React, { useEffect, useState } from 'react';
import { DialogContent, Grid, CircularProgress, Typography, Button, Collapse } from '@mui/material';
import { Menu } from '@mui/material';
import { DeleteOutlineRounded, LabelRounded, EditRounded } from '@mui/icons-material';
import { VisibilityRounded, VisibilityOffRounded, FiberManualRecord } from '@mui/icons-material';
import { FilterListRounded, PrintRounded, CallSplitRounded, NotInterestedRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions, ProcessEntriesDialog } from '../../../Global/Components';
import { IntervalDialog, CheckboxWithIcon, ClearFilter } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { ProcessIcon } from '../../../Global/Icons';
import { grey, red, blue, amber } from '@mui/material/colors';
import { BASE_URL } from '../../../../constants';
import { Link } from 'react-router-dom';
import { isEmpty, toNumber, isArray, isNumber } from 'lodash';
import UsersDialog from '../../../Global/UsersDialog';
import FilterDialog from './FilterDialog';
import moment from 'moment';
import axios from 'axios';
import KpiTimescale from './KpiTimescale';
import Chart from '../../../Global/Charts/Chart';

export default function ProcessKPIDialog({ open, onClose, onDelete, kpi, onChange }) {
  const [hideSlices, setHideSlices] = useState(false);
  const [saving, setSaving] = useState(false);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [intervalDialog, setIntervalDialog] = useState(false);
  const [data, setData] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [entriesDialog, setEntriesDialog] = useState(false);
  const [entriesParams, setEntriesParams] = useState();
  const [fieldFilters, setFieldFilters] = useState(null);
  const [usersDialogOpen, setUsersDialogOpen] = useState(false);
  const [userFilters, setUserFilters] = useState(null);

  const [portalSpacesFilters, setPortalSpacesFilters] = useState([]);
  const [portalSpacesMenu, setPortalSpacesMenu] = useState(null);

  const userId = localStorage.getItem('_id');
  const isAnalysis = ['analysisStarted', 'analysisCompleted', 'analysisAverage', 'stepAnalysis'].includes(kpi?.kpiType);

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this KPI', deleteKPI);
  }

  function deleteKPI() {
    setSaving(true);
    axios
      .post('/process/kpi/deleteProcessKPI', { id: kpi._id })
      .then((res) => {
        onClose();
        onDelete();
        StateManager.setSuccessAlert('KPI has been deleted');
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setErrorAlert(err.response?.data?.message || 'Something went wrong');
      });
  }

  useEffect(() => {
    setInfo(null);
    if (!kpi || !open) return;

    setData(kpi.data);
    const preSetStart =
      kpi.appliedTimeFilters?.start && kpi.appliedTimeFilters?.days === -1
        ? moment(kpi.appliedTimeFilters?.start)
        : null;
    const preSetEnd =
      kpi.appliedTimeFilters?.end && kpi.appliedTimeFilters?.days === -1 ? moment(kpi.appliedTimeFilters?.end) : null;
    setStart(preSetStart);
    setEnd(preSetEnd);
    setInterval(isNumber(kpi.appliedTimeFilters?.days) ? kpi.appliedTimeFilters?.days : kpi.interval || 180);
    setLoading(true);
    setFieldFilters(kpi.filtersApplied && kpi.appliedFieldFilters);
    setUserFilters(null);
    setPortalSpacesFilters(kpi.portalSpacesFilters || []);
    const params = {
      processId: kpi.processId,
      stepLineId: kpi.stepLineId,
      fieldId: kpi.fieldId,
    };
    axios
      .get('/process/kpi/getFieldInfo', { params })
      .then((res) => {
        setInfo(res.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [kpi, open]);

  function applyFilters(days, from, to, fieldFilters, userFilters, portalSpacesFilters) {
    setFieldFilters(fieldFilters);
    setUserFilters(userFilters);

    const start = from
      ? moment(from)
      : moment()
          .subtract(days || interval, 'days')
          .startOf('day');
    const end = to ? moment(to) : moment().endOf('day');

    const params = {
      kpiId: kpi._id,
      start: start.toISOString(),
      end: end.toISOString(),
      fieldFilters,
      userFilters,
      days,
      portalSpacesFilters,
    };

    setLoadingChart(true);

    axios
      .post('/process/kpi/apllyKpiFilters', params)
      .then((res) => {
        setLoadingChart(false);
        setData(res.data);
        if (onChange) onChange({ data: res.data, _id: kpi?._id });
      })
      .catch((err) => {
        console.error(err);
        setLoadingChart(false);
        StateManager.setErrorAlert('Failed to load the chart');
      });
  }

  function print() {
    setSaving(true);
    const element = document.getElementById('chart');
    if (!element) {
      setSaving(false);
      return;
    }

    import('html2canvas').then(({ default: html2canvas }) => {
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const body = {
          image,
          title: kpi.title,
          processInfo: info,
        };
        axios
          .post('/kpi/exportToPdf', body)
          .then((res) => {
            window.open(`${BASE_URL}${res.data.link}`, '_blank');
            setSaving(false);
          })
          .catch((err) => {
            setSaving(false);
            StateManager.setAxiosErrorAlert(err);
          });
      });
    });
  }

  function applyUsersFilter(res) {
    setUsersDialogOpen(false);

    setUserFilters(res.users);
    if (!isArray(res?.users)) return;
    applyFilters(interval, start, end, fieldFilters, res.users, portalSpacesFilters);
  }

  function onBarClick(seriesIndexStr, dataIndex) {
    if (!seriesIndexStr || !isNumber(dataIndex) || isEmpty(kpi.data?.series)) {
      return;
    }

    const seriesIndex = toNumber(seriesIndexStr);
    const seriesEntries = kpi.data.series[seriesIndex]?.entries;

    if (!isArray(seriesEntries)) {
      return;
    }

    const entries = kpi.data.series[seriesIndex].entries[dataIndex];

    if (!isArray(entries)) {
      return;
    }

    const timeframe = kpi.data.xAxis[dataIndex];
    const label = kpi.data.series[seriesIndex].label;

    setEntriesParams({ processId: info.processId, entriesIds: entries, title: `${label} in ${timeframe}` });
    setEntriesDialog(true);
  }

  function onMarkClick(dataIndex) {
    if (!isNumber(dataIndex) || isEmpty(kpi.data?.data)) {
      return;
    }

    const entries = kpi.data.entries?.[dataIndex];

    if (!isArray(entries)) {
      return;
    }

    const timeframe = kpi.data.xAxis[dataIndex];

    setEntriesParams({ processId: info.processId, entriesIds: entries, title: timeframe });
    setEntriesDialog(true);
  }

  function onPieClick(pieData) {
    setEntriesParams({ processId: info.processId, entriesIds: pieData.entries, title: pieData.label });
    setEntriesDialog(true);
  }

  useEffect(() => {
    if (interval > 0) {
      setStart(null);
      setEnd(null);
    }
  }, [interval]);

  if (!kpi) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Grid container item>
          <Collapse in={Boolean(info)} style={{ width: '100%' }} collapsedSize={40}>
            {loading && (
              <Grid container item>
                <CircularProgress color="primary" size={30} style={{ margin: 5 }} />
              </Grid>
            )}
            {info && (
              <Grid container item>
                <Grid container item xs={6}>
                  <Grid container item style={{ paddingBottom: '0.5rem' }}>
                    <Typography variant="h3">{kpi.title}</Typography>
                  </Grid>
                  <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                    <ProcessIcon />
                    <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                      Process:
                    </Typography>
                    <Link
                      to={
                        info.outdated
                          ? `/processes/version-info/${info.processVersion}`
                          : `/processes/${info.processId}`
                      }
                    >
                      <Typography variant="h6" style={{ color: blue[700], marginLeft: '0.25rem' }}>
                        {info.processTitle}
                      </Typography>
                    </Link>
                  </Grid>

                  {info.stepTitle && (
                    <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                      <FiberManualRecord color="primary" />
                      <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                        Step:
                      </Typography>
                      <Typography variant="h6" style={{ marginLeft: '0.25rem' }}>
                        {info.stepTitle}
                      </Typography>
                    </Grid>
                  )}

                  {info.isDecision && (
                    <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                      <CallSplitRounded style={{ color: grey[600] }} />
                      <Typography variant="h6" style={{ marginLeft: '0.75rem' }}>
                        Decision
                      </Typography>
                    </Grid>
                  )}

                  {info.fieldTitle && (
                    <Grid container item alignItems="center" style={{ padding: '0.5rem 0' }}>
                      <LabelRounded style={{ color: amber[500] }} />

                      <Typography variant="h6" style={{ marginLeft: '0.75rem', fontWeight: 400 }}>
                        Field:
                      </Typography>
                      <Typography variant="h6" style={{ marginLeft: '0.25rem' }}>
                        {info.fieldTitle}
                      </Typography>
                    </Grid>
                  )}

                  {info.outdated && (
                    <Grid container item>
                      <Typography gutterBottom style={{ fontWeight: 500, color: red[700] }}>
                        This field does not exist in the latest version of the process
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid container item xs={6} alignItems="baseline">
                  <Grid container item justifyContent="flex-end" alignItems="center">
                    <Typography variant="h6" style={{ marginRight: '1rem' }}>
                      Change KPI timescale:
                    </Typography>
                    <KpiTimescale
                      value={interval}
                      onChange={(value) => {
                        setInterval(value);
                        if (value === -1) {
                          setIntervalDialog(true);
                        } else {
                          applyFilters(value, null, null, fieldFilters, userFilters, portalSpacesFilters);
                        }
                      }}
                    />
                  </Grid>

                  {kpi.fieldId && (
                    <Grid container item justifyContent="flex-end" alignItems="center">
                      {info?.users?.length > 1 && (
                        <>
                          <Button
                            color="primary"
                            sx={{ textTransform: 'none', mr: 1 }}
                            onClick={() => setUsersDialogOpen(true)}
                            startIcon={<FilterListRounded />}
                            variant={isEmpty(userFilters) ? 'standard' : 'contained'}
                          >
                            Filter by user
                          </Button>

                          <UsersDialog
                            open={usersDialogOpen}
                            onClose={applyUsersFilter}
                            initiallySelected={userFilters}
                            includedUsers={info?.users || []}
                            additionalActions={
                              <Button
                                style={{ color: red[500], borderRadius: 8, marginRight: 'auto' }}
                                onClick={() => applyUsersFilter({ users: null })}
                                startIcon={<NotInterestedRounded />}
                              >
                                Clear filters
                              </Button>
                            }
                          />
                        </>
                      )}

                      <Button
                        onClick={() => setFilterDialog(true)}
                        startIcon={<FilterListRounded />}
                        variant={isEmpty(fieldFilters) ? 'standard' : 'contained'}
                        color="primary"
                        sx={{ textTransform: 'none', mr: 1 }}
                      >
                        Filter by field
                      </Button>

                      <FilterDialog
                        open={filterDialog}
                        onClose={() => setFilterDialog(false)}
                        kpiId={kpi._id}
                        onResult={(filters) =>
                          applyFilters(interval, start, end, filters, userFilters, portalSpacesFilters)
                        }
                        initial={fieldFilters}
                        type="process"
                      />

                      {!isEmpty(info.portalSpaces) && (
                        <>
                          <Button
                            onClick={(e) => setPortalSpacesMenu(e.currentTarget)}
                            startIcon={<FilterListRounded />}
                            variant={isEmpty(portalSpacesFilters) ? 'standard' : 'contained'}
                            color="primary"
                            sx={{ textTransform: 'none' }}
                          >
                            Filter by portal spaces
                          </Button>

                          <Menu
                            anchorEl={portalSpacesMenu}
                            open={Boolean(portalSpacesMenu)}
                            onClose={() => setPortalSpacesMenu(null)}
                            keepMounted
                          >
                            {info.portalSpaces.map((option) => (
                              <CheckboxWithIcon
                                key={option._id}
                                checked={portalSpacesFilters.includes(option._id)}
                                onChange={(checked) => {
                                  const value = checked
                                    ? [...portalSpacesFilters, option._id]
                                    : portalSpacesFilters.filter((x) => x !== option._id);
                                  setPortalSpacesFilters(value);
                                  applyFilters(interval, start, end, fieldFilters, userFilters, value);
                                }}
                                text={option.title}
                              />
                            ))}
                            <ClearFilter
                              onClick={() => {
                                setPortalSpacesFilters([]);
                                applyFilters(interval, start, end, fieldFilters, userFilters, []);
                              }}
                            />
                          </Menu>
                        </>
                      )}
                    </Grid>
                  )}

                  {start && end && (
                    <Grid container item justifyContent="flex-end" alignItems="center" style={{ marginTop: '0.5rem' }}>
                      <Typography style={{ fontWeight: 500 }}>
                        {`From ${start.format('DD/MM/yyyy')} to ${end.format('DD/MM/yyyy')}`}
                      </Typography>
                    </Grid>
                  )}

                  <Grid container item style={{ marginTop: '0.5rem' }} justifyContent="flex-end">
                    {kpi.chartType === 'PieChart' && (
                      <Button
                        style={{ color: grey[500], textTransform: 'none', fontSize: 14 }}
                        startIcon={hideSlices ? <VisibilityRounded /> : <VisibilityOffRounded />}
                        onClick={() => setHideSlices(!hideSlices)}
                      >
                        {hideSlices ? 'Show small slices' : 'Hide small slices'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Collapse>
        </Grid>

        <Grid container item id="chart" sx={{ height: '55vh' }}>
          {!loadingChart && (
            <Chart
              data={data}
              chartType={kpi.chartType}
              barChartType={kpi.barChartType}
              onBarClick={onBarClick}
              onMarkClick={onMarkClick}
              onPieClick={onPieClick}
            />
          )}
          <ProcessEntriesDialog
            open={entriesDialog}
            onClose={() => {
              setEntriesDialog(false);
              setEntriesParams(null);
            }}
            {...entriesParams}
          />
          {loadingChart && (
            <Grid container item style={{ width: '100%', height: '55vh' }}>
              <CircularProgress color="primary" style={{ margin: 'auto' }} />
            </Grid>
          )}
          {!loadingChart && data?.length <= 1 && (
            <Grid container item style={{ width: '100%', height: '55vh' }}>
              <Typography variant="h5" color="textSecondary" style={{ margin: 'auto' }}>
                There is no data to display
              </Typography>
            </Grid>
          )}
        </Grid>

        <IntervalDialog
          open={intervalDialog}
          onClose={() => setIntervalDialog(false)}
          onResult={(res) => {
            setStart(res.start);
            setEnd(res.end);
            applyFilters(-1, res.start, res.end, fieldFilters, userFilters, portalSpacesFilters);
          }}
        />
      </DialogContent>

      <StandardDialogActions
        saving={saving}
        onClose={onClose}
        additionalActions={
          <>
            {userId === kpi.userId && !isAnalysis && (
              <Link to={`/kpiHub/process-${kpi.kpiType === 'entries' ? 'entries' : 'field'}-kpi-builder/${kpi._id}`}>
                <Button style={{ color: grey[600] }} startIcon={<EditRounded />}>
                  Edit
                </Button>
              </Link>
            )}

            {userId === kpi.userId && onDelete && (
              <Button style={{ color: red[500] }} onClick={confirmDelete} startIcon={<DeleteOutlineRounded />}>
                delete
              </Button>
            )}

            {info && (
              <Button onClick={print} style={{ color: grey[600] }} startIcon={<PrintRounded />}>
                print
              </Button>
            )}

            <div style={{ marginRight: 'auto' }} />
          </>
        }
      />
    </RoundedDialog>
  );
}
