import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { blue } from '@mui/material/colors';
import { RoutineIcon } from '../../../Global/Icons';

export default function RoutineTemplateInfo({ templateId }) {
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (!templateId) return;
    axios
      .get('/tasks/routines/getRoutineByTemplateId', { params: { templateId } })
      .then((res) => setInfo(res.data))
      .catch((err) => console.error(err));
  }, [templateId]);

  if (!info || !templateId) return null;

  return (
    <Grid container item alignItems="center" style={{ margin: '1em 0' }}>
      <RoutineIcon />
      <Typography style={{ fontSize: 18, fontWeight: 500, marginRight: '0.25em', marginLeft: '1rem' }}>
        This is a task for routine
      </Typography>
      <Link to={`/tasks/routine/${info._id}`} style={{ fontSize: 18, fontWeight: 500, color: blue[800] }}>
        {info.title}
      </Link>
    </Grid>
  );
}
