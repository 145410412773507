import React, { useState, useEffect } from 'react';
import { Typography, DialogContent, Grid, FormControlLabel, Checkbox, DialogTitle } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';
import { isEmpty, isArray } from 'lodash';
import FileViewer from '../FileViewer';

export default function ConfirmDialog({ open, settings, onConfirm, onClose }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [open]);

  const hasFiles = isArray(settings?.uploaderWarningFiles) && !isEmpty(settings?.uploaderWarningFiles);

  return (
    <RoundedDialog open={open} maxWidth={hasFiles ? 'sm' : 'xs'} fullWidth onClose={onClose}>
      <DialogTitle>Message</DialogTitle>
      <DialogContent sx={{ py: 2 }}>
        <Grid container sx={{ mb: 1 }}>
          <Typography>{settings?.uploaderWarningText}</Typography>
        </Grid>

        {hasFiles && <FileViewer title="" files={settings.uploaderWarningFiles} />}

        <Grid container sx={{ mt: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={checked} />}
            label="I have read and understood the above message"
            onChange={(e) => setChecked(e.target.checked)}
          />
        </Grid>
      </DialogContent>

      <StandardDialogActions onClose={onClose} disableDone={!checked} onDone={() => onConfirm()} />
    </RoundedDialog>
  );
}
