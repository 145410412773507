import React, { useRef, useState, useEffect } from 'react';
import { Tooltip, Typography } from '@mui/material';

export default function TooltipTypography({ children, ...props }) {
  const [overflowed, setOverflowed] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    setOverflowed(ref.current.scrollWidth > ref.current.clientWidth);
  }, [children]); // reevaluate the need for tooltip when the text changes

  return (
    <Tooltip title={overflowed ? children : ''}>
      <Typography ref={ref} noWrap {...props}>
        {children}
      </Typography>
    </Tooltip>
  );
}
