import React, { useState, useEffect } from 'react';
import { RoundedDialog, StandardDialogActions, DnDList, TaskParams } from '../Components';
import { DialogContent, DialogTitle, Grid, TextField, Typography, Button, CircularProgress } from '@mui/material';
import { Collapse, Autocomplete, IconButton, Paper, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { Switch, MenuItem } from '@mui/material';
import { WorkOutlineRounded, TimelineRounded, DescriptionRounded, GridOnRounded } from '@mui/icons-material';
import { ClearRounded, CheckCircleOutlineRounded, EditRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { ArrowForwardRounded, VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';
import { AccessibilityNewRounded } from '@mui/icons-material';
import { blue, grey } from '@mui/material/colors';
import { isArray, isEmpty, difference } from 'lodash';
import { FormIcon, ProcessIcon, RegistersIcon, ProjectIcon, TaskIcon, RequiredGrey, RequiredIcon } from '../Icons';
import axios from 'axios';
import StateManager from '../StateManager';
import { v4 } from 'uuid';
import { highlightElementError } from '../Functions';
import { TaskTemplatesDialog } from '../../Hubs/tasks/Components';
import fieldTypes from './FieldTypes';

export default function ActionGroupDialog({ initial, open, onClose, onResult, allFields, forTable, withSurveys }) {
  const [actions, setActions] = useState([]);
  const [addingAction, setAddingAction] = useState(false);
  const [addingActionType, setAddingActionType] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedActionIndex, setSelectedActionIndex] = useState(-1);

  const [actionTitle, setActionTitle] = useState('');

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [templatesDialog, setTemplatesDialog] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const [sendToProject, setSendToProject] = useState(false);

  const [processes, setProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const [registers, setRegisters] = useState([]);
  const [selectedRegister, setSelectedRegister] = useState(null);
  const [loadingRegisterInfo, setLoadingRegisterInfo] = useState(false);
  const [registerColumns, setRegisterColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selctedOtherActionsColumns, setSelctedOtherActionsColumns] = useState([]);
  const [mapRegisterRow, setMapRegisterRow] = useState(false);
  const [registerRowMap, setRegisterRowMap] = useState({});
  const [fillExistingRow, setFillExistingRow] = useState(false);

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);

  const formDone = addingActionType === 'form' && actionTitle && selectedForm;
  const processDone = addingActionType === 'process' && actionTitle && selectedProcess;
  const registerDone = addingActionType === 'register' && actionTitle && selectedRegister && !isEmpty(selectedColumns);
  const taskDone = addingActionType === 'task' && actionTitle && taskData?.title && (!sendToProject || selectedProject);
  const surveyDone = addingActionType === 'survey' && actionTitle && actionTitle && selectedForm;

  const actionDone = formDone || processDone || registerDone || taskDone || surveyDone;

  const filteredDomain = isArray(allFields)
    ? allFields.filter((field) => field.fieldType && field.title && field.fieldType !== 'actionGroup')
    : [];
  const filteredCodomain = isArray(registerColumns)
    ? registerColumns.filter((field) => field.fieldType && field.title && field.fieldType !== 'actionGroup')
    : [];
  const fields = filteredDomain.map((field) => ({
    id: field.id,
    title: field.title,
    icon: fieldTypes[field.fieldType]?.icon,
    codomain: filteredCodomain
      .filter((x) => x.fieldType === field.fieldType)
      .map((x) => ({ id: x.id, title: x.title, icon: fieldTypes[x.fieldType]?.icon })),
  }));

  useEffect(() => {
    setActions(isArray(initial) ? initial : []);
  }, [initial]);

  function loadAvailableProjects(projectId) {
    if (!isEmpty(projects)) {
      if (projectId) {
        const selected = projects.find((x) => x._id === projectId);
        if (selected) {
          setSelectedProject(selected);
        }
      }
      return;
    }
    axios
      .get('/project/getUserProjects')
      .then(({ data }) => {
        setProjects(data);
        if (projectId) {
          const selected = data.find((x) => x._id === projectId);
          if (selected) {
            setSelectedProject(selected);
          }
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function loadAvailableForms(formId) {
    if (!isEmpty(forms)) {
      if (formId) {
        const selected = forms.find((x) => x._id === formId);
        if (selected) {
          setSelectedForm(selected);
        }
      }
      return;
    }
    axios
      .get('/forms/general/getFormsForSearch')
      .then(({ data }) => {
        setForms(data);
        if (formId) {
          const selected = data.find((x) => x._id === formId);
          if (selected) {
            setSelectedForm(selected);
          }
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function loadAvailableProcesses(processId) {
    if (!isEmpty(processes)) {
      if (processId) {
        const selected = processes.find((x) => x._id === processId);
        if (selected) {
          setSelectedProcess(selected);
        }
      }
      return;
    }
    axios
      .get('/process/search/getSearchProcesses')
      .then(({ data }) => {
        setProcesses(data);
        if (processId) {
          const selected = data.find((x) => x._id === processId);
          if (selected) {
            setSelectedProcess(selected);
          }
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function loadAvailableRegisters(registerId, preSelectedColumns, actionId) {
    if (!isEmpty(registers)) {
      if (registerId) {
        const selected = registers.find((x) => x._id === registerId);
        if (selected) {
          selectRegister(selected, preSelectedColumns, actionId);
        }
      }
      return;
    }
    axios
      .get('/registers/general/getUserRegisters')
      .then(({ data }) => {
        setRegisters(data);
        if (registerId) {
          const selected = data.find((x) => x._id === registerId);
          if (selected) {
            selectRegister(selected, preSelectedColumns, actionId);
          }
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function addAction(type) {
    setAddingActionType(type);
    setAddingAction(true);
    setActionTitle('');
    if (type === 'process' && isEmpty(processes)) {
      loadAvailableProcesses();
    }
    if (type === 'form' && isEmpty(forms)) {
      loadAvailableForms();
    }
    if (type === 'register' && isEmpty(registers)) {
      loadAvailableRegisters();
    }
    if (type === 'survey' && isEmpty(forms)) {
      loadAvailableForms();
    }
  }

  function cancelAction(fromSave) {
    if (selectedAction && !fromSave && selectedActionIndex >= 0) {
      actions.splice(selectedActionIndex, 0, selectedAction);
      setActions([...actions]);
    }
    setAddingActionType(null);
    setAddingAction(false);
    setActionTitle('');
    setSelectedProcess(null);
    setSelectedForm(null);
    setSelectedRegister(null);
    setSelectedAction(null);
    setRegisterColumns([]);
    setSelectedProject(null);
    setTaskData(null);
    setRegisterRowMap({});
    setMapRegisterRow(false);
    setFillExistingRow(false);
  }

  function saveAction() {
    if (!actionDone) return;
    const action = { id: selectedAction?.id || v4(), title: actionTitle, type: addingActionType };
    if (addingActionType === 'form' || addingActionType === 'survey') {
      action.formId = selectedForm._id;
    } else if (addingActionType === 'process') {
      action.processId = selectedProcess._id;
    } else if (addingActionType === 'register') {
      action.registerId = selectedRegister._id;
      action.selectedColumns =
        fillExistingRow && !forTable ? difference(selectedColumns, selctedOtherActionsColumns) : selectedColumns;
      action.map = mapRegisterRow ? registerRowMap : false;
      action.fillExistingRow = fillExistingRow && !forTable;
    } else if (addingActionType === 'task') {
      action.taskData = taskData;
      action.projectId = sendToProject && selectedProject ? selectedProject?._id : null;
    }

    if (selectedAction?.required) {
      action.required = selectedAction.required;
    }

    if (selectedActionIndex >= 0) {
      actions.splice(selectedActionIndex, 0, action);
      setActions([...actions]);
    } else {
      const result = [...actions, action];
      setActions(result);
    }

    cancelAction(true);
  }

  function deleteActon(actionId) {
    setActions(actions.filter((x) => x.id !== actionId));
  }

  function editActon(actionId) {
    if (selectedAction && selectedActionIndex >= 0) {
      actions.splice(selectedActionIndex, 0, selectedAction);
      setActions([...actions]);
    }

    const actionIndex = actions.findIndex((x) => x.id === actionId);
    if (actionIndex === -1) return;
    const action = actions[actionIndex];

    setSelectedAction(action);
    setSelectedActionIndex(actionIndex);
    setActionTitle(action.title);
    setAddingActionType(action.type);
    if (action.type === 'form') {
      loadAvailableForms(action.formId);
    }
    if (action.type === 'process') {
      loadAvailableProcesses(action.processId);
    }
    if (action.type === 'register') {
      loadAvailableRegisters(action.registerId, action.selectedColumns, action.id);
      setRegisterRowMap(action.map || {});
      setMapRegisterRow(!isEmpty(action.map));
      setFillExistingRow(Boolean(action.fillExistingRow) && !forTable);
    }
    if (action.type === 'task') {
      setTaskData(action.taskData);
      setSendToProject(Boolean(action.projectId));
      if (action.projectId) {
        loadAvailableProjects(action.projectId);
      }
    }

    setAddingAction(true);
    deleteActon(actionId);
  }

  function done() {
    if (actionDone) {
      highlightElementError('action-area');
      StateManager.setWarningAlert('Do you want to save the action?');
      return;
    }
    console.log(actions);
    onResult(actions);
    onClose();
  }

  function selectRegister(register, preSelectedColumns, actionId) {
    setSelectedRegister(register);
    setRegisterRowMap({});
    if (!register) return;
    setLoadingRegisterInfo(true);
    axios
      .get('/registers/general/getFillableRegisterColumns', { params: { registerId: register._id } })
      .then(({ data }) => {
        setRegisterColumns(data);
        setLoadingRegisterInfo(false);
        setSelectedColumns(isArray(preSelectedColumns) ? preSelectedColumns : data.map((x) => x.id));
        const otherColumns = isArray(allFields)
          ? allFields
              .filter((field) => field.fieldType === 'actionGroup')
              .flatMap((x) => x.preSetActions)
              .filter(
                (x) =>
                  x &&
                  x.id !== actionId &&
                  x.registerId === register._id &&
                  !isEmpty(x.selectedColumns) &&
                  x.fillExistingRow,
              )
              .flatMap((x) => x.selectedColumns)
          : [];
        setSelctedOtherActionsColumns(otherColumns);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingRegisterInfo(false);
      });
  }

  function selectTemplate(template) {
    setTaskData(template);
  }

  function switchRequired(actionId) {
    const index = actions.findIndex((x) => x.id === actionId);
    if (index === -1) return;
    actions[index].required = !actions[index].required;
    setActions([...actions]);
  }

  function switchHidden(actionId) {
    const index = actions.findIndex((x) => x.id === actionId);
    if (index === -1) return;
    actions[index].hidden = !actions[index].hidden;
    setActions([...actions]);
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={'md'} fullWidth className="scroll-bar">
      <DialogTitle>Actions</DialogTitle>
      <DialogContent>
        <Grid container>
          {!isEmpty(actions) && (
            <Grid container item sx={{ mb: 2 }}>
              <DnDList
                items={actions}
                setItems={setActions}
                spacing={0.5}
                renderItem={(action, dragging) => (
                  <Grid
                    alignItems={'center'}
                    container
                    item
                    component={Paper}
                    elevation={3}
                    sx={{
                      background: (theme) => (dragging ? (theme.palette.mode === 'dark' ? blue[500] : blue[100]) : ''),
                      py: 1,
                      px: 2,
                      cursor: 'grab',
                      position: 'relative',
                    }}
                  >
                    {action.required && <RequiredIcon style={{ position: 'absolute', top: -10, left: -10 }} />}
                    {action.type === 'form' && <FormIcon />}
                    {action.type === 'task' && <TaskIcon />}
                    {action.type === 'process' && <ProcessIcon />}
                    {action.type === 'register' && <RegistersIcon />}
                    {action.type === 'survey' && <AccessibilityNewRounded />}

                    <Typography sx={{ ml: 1 }}>{action.title}</Typography>

                    <IconButton sx={{ ml: 'auto' }}>
                      <EditRounded style={{ color: grey[500] }} onClick={() => editActon(action.id)} />
                    </IconButton>
                    <Tooltip title={action.required ? 'Make NOT required' : 'Make required'}>
                      <IconButton sx={{ ml: 1 }} onClick={() => switchRequired(action.id)}>
                        <RequiredGrey />
                      </IconButton>
                    </Tooltip>
                    {!action.required && (
                      <Tooltip title={action.hidden ? 'Show' : 'Hide'}>
                        <IconButton sx={{ ml: 1 }} onClick={() => switchHidden(action.id)}>
                          {action.hidden ? <VisibilityOffRounded /> : <VisibilityRounded />}
                        </IconButton>
                      </Tooltip>
                    )}
                    <IconButton sx={{ ml: 1 }}>
                      <DeleteOutlineRounded color="secondary" onClick={() => deleteActon(action.id)} />
                    </IconButton>
                  </Grid>
                )}
              />
            </Grid>
          )}
          <Grid container item>
            <Typography gutterBottom variant="h6" color={'textSecondary'}>
              What action would you like to pre-set?
            </Typography>
          </Grid>
          <Grid container item sx={{ mb: 2 }}>
            <Grid container item xs={6} sx={{ p: 1 }}>
              <Button
                onClick={() => addAction('task')}
                fullWidth
                style={{ color: addingActionType === 'task' ? '' : grey[500] }}
                startIcon={<WorkOutlineRounded />}
              >
                Create a task
              </Button>
            </Grid>
            <Grid container item xs={6} sx={{ p: 1 }}>
              <Button
                onClick={() => addAction('process')}
                fullWidth
                style={{ color: addingActionType === 'process' ? '' : grey[500] }}
                startIcon={<TimelineRounded />}
              >
                Start a process
              </Button>
            </Grid>
            <Grid container item xs={6} sx={{ p: 1 }}>
              <Button
                style={{ color: addingActionType === 'form' ? '' : grey[500] }}
                onClick={() => addAction('form')}
                fullWidth
                startIcon={<DescriptionRounded />}
              >
                Start a form
              </Button>
            </Grid>
            <Grid container item xs={6} sx={{ p: 1 }}>
              <Button
                onClick={() => addAction('register')}
                fullWidth
                startIcon={<GridOnRounded />}
                style={{ color: addingActionType === 'register' ? '' : grey[500] }}
              >
                Populate a register row
              </Button>
            </Grid>
            {withSurveys && (
              <Grid container item xs={6} sx={{ p: 1 }}>
                <Button
                  style={{ color: addingActionType === 'survey' ? '' : grey[500] }}
                  onClick={() => addAction('survey')}
                  fullWidth
                  startIcon={<AccessibilityNewRounded />}
                >
                  Start a Survey
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container component={Collapse} item in={addingAction} id="action-area" sx={{ borderRadius: 1 }}>
            <Grid container item sx={{ mb: 2 }}>
              <TextField
                value={actionTitle}
                onChange={(e) => setActionTitle(e.target.value)}
                label="Action title"
                fullWidth
                required
                id="action-title"
              />
            </Grid>
            <Grid container item sx={{ mb: 2 }}>
              {(addingActionType === 'form' || addingActionType === 'survey') && (
                <>
                  <Autocomplete
                    options={forms}
                    value={selectedForm}
                    fullWidth
                    getOptionLabel={(option) => option?.title || ''}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    openOnFocus
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <Grid container alignItems="center">
                          <FormIcon />
                          <Typography style={{ marginLeft: '1rem' }} noWrap>
                            {option.title}
                          </Typography>
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Select a form" />}
                    onChange={(e, value) => setSelectedForm(value)}
                  />
                </>
              )}
              {addingActionType === 'process' && (
                <>
                  <Autocomplete
                    options={processes}
                    value={selectedProcess}
                    getOptionLabel={(option) => option?.title || ''}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    openOnFocus
                    fullWidth
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <Grid container alignItems="center">
                          <ProcessIcon />
                          <Typography style={{ marginLeft: '1rem' }} noWrap>
                            {option.title}
                          </Typography>
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Select a process" />}
                    onChange={(e, value) => setSelectedProcess(value)}
                  />
                </>
              )}
              {addingActionType === 'register' && (
                <>
                  <Autocomplete
                    options={registers}
                    value={selectedRegister}
                    getOptionLabel={(option) => option?.title || ''}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    openOnFocus
                    fullWidth
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <RegistersIcon />
                          <Typography style={{ marginLeft: '1rem' }} noWrap>
                            {option.title}
                          </Typography>
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Select a register" />}
                    onChange={(e, value) => selectRegister(value)}
                  />
                  <Grid container item sx={{ mt: 1 }}>
                    {loadingRegisterInfo ? (
                      <CircularProgress />
                    ) : (
                      isArray(registerColumns) &&
                      !isEmpty(registerColumns) && (
                        <Grid container item>
                          <Grid container item>
                            <Typography color={'textSecondary'} gutterBottom>
                              What columns would you like to fill?
                            </Typography>
                          </Grid>
                          <Grid container item>
                            {registerColumns.map((column) => (
                              <Grid key={column.id} container item lg={6} md={6} xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedColumns.includes(column.id) ||
                                        (fillExistingRow && selctedOtherActionsColumns.includes(column.id) && !forTable)
                                      }
                                      disabled={
                                        fillExistingRow && selctedOtherActionsColumns.includes(column.id) && !forTable
                                      }
                                    />
                                  }
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    const result = checked
                                      ? [...selectedColumns, column.id]
                                      : selectedColumns.filter((x) => x !== column.id);
                                    setSelectedColumns(result);
                                  }}
                                  label={column.title}
                                />
                              </Grid>
                            ))}
                          </Grid>
                          {!isEmpty(fields) && (
                            <>
                              <Grid container item sx={{ mt: 2 }}>
                                <FormControlLabel
                                  label="Would you like to map the data from the current entry?"
                                  control={<Switch checked={mapRegisterRow} />}
                                  onChange={(e) => setMapRegisterRow(e.target.checked)}
                                />
                              </Grid>
                              <Grid container item component={Collapse} in={mapRegisterRow}>
                                {fields.map((field) => (
                                  <Grid
                                    container
                                    item
                                    key={field.id}
                                    wrap="nowrap"
                                    alignItems="center"
                                    style={{ minHeight: 64 }}
                                  >
                                    <Grid container item xs={5} alignItems="center" wrap="nowrap">
                                      {field.icon}
                                      <Typography style={{ marginLeft: 12 }}>{field.title}</Typography>
                                    </Grid>
                                    <Grid container item xs={2}>
                                      <ArrowForwardRounded
                                        sx={{
                                          color: registerRowMap[field.id]
                                            ? (theme) => theme.palette.primary.main
                                            : grey[500],
                                        }}
                                      />
                                    </Grid>
                                    <Grid container item xs={5}>
                                      {!isEmpty(field.codomain) ? (
                                        <TextField
                                          value={registerRowMap[field.id] || ''}
                                          onChange={(e) =>
                                            setRegisterRowMap({ ...registerRowMap, [field.id]: e.target.value })
                                          }
                                          select
                                          fullWidth
                                          label="Select field"
                                          variant="standard"
                                        >
                                          {field.codomain.map((codField) => (
                                            <MenuItem key={codField.id} value={codField.id}>
                                              {codField.title}
                                            </MenuItem>
                                          ))}
                                          <MenuItem value="">NO OPTION</MenuItem>
                                        </TextField>
                                      ) : (
                                        <Typography color="textSecondary">No items available</Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )
                    )}
                    {!forTable && (
                      <Grid container item sx={{ mt: 1 }}>
                        <FormControlLabel
                          label="Fill the same row across the entry"
                          control={<Checkbox checked={fillExistingRow} />}
                          onChange={(e) => setFillExistingRow(e.target.checked)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              {addingActionType === 'task' && (
                <Grid container item>
                  <Grid container item>
                    <Button
                      startIcon={<WorkOutlineRounded />}
                      variant="outlined"
                      onClick={() => setTemplatesDialog(true)}
                    >
                      use task template
                    </Button>
                  </Grid>
                  <TaskParams initial={taskData} onResult={setTaskData} />
                  <TaskTemplatesDialog
                    open={templatesDialog}
                    onClose={() => setTemplatesDialog(false)}
                    onResult={selectTemplate}
                  />
                  <Grid container item sx={{ mt: 1 }}>
                    <FormControlLabel
                      label="Send this task to a project"
                      control={<Switch checked={sendToProject} />}
                      onChange={(e) => {
                        setSendToProject(e.target.checked);
                        if (e.target.checked && isEmpty(projects)) {
                          loadAvailableProjects();
                        }
                      }}
                    />
                  </Grid>
                  <Grid container item component={Collapse} in={sendToProject} sx={{ mt: 1 }}>
                    <Autocomplete
                      options={projects}
                      value={selectedProject}
                      getOptionLabel={(option) => option?.title || ''}
                      isOptionEqualToValue={(option, value) => option?._id === value?._id}
                      openOnFocus
                      fullWidth
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <ProjectIcon />
                            <Typography style={{ marginLeft: '1rem' }} noWrap>
                              {option.title}
                            </Typography>
                          </Grid>
                        </li>
                      )}
                      renderInput={(params) => <TextField {...params} label="Select a project" />}
                      onChange={(e, value) => setSelectedProject(value)}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container item sx={{ mb: 4 }} justifyContent="flex-end">
              <Button color="secondary" startIcon={<ClearRounded />} onClick={() => cancelAction()}>
                cancel
              </Button>
              {actionDone && (
                <Button
                  variant="contained"
                  onClick={saveAction}
                  startIcon={<CheckCircleOutlineRounded />}
                  sx={{ ml: 1 }}
                >
                  save
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
