import React, { useEffect, useState, useMemo } from 'react';
import { isArray, isEmpty } from 'lodash';
import { RoundedDialog, StandardDialogActions } from './index';
import { DialogTitle, DialogContent, Grid, Typography, CircularProgress } from '@mui/material';
import StateManager from '../StateManager';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { DataGridPremium } from '@mui/x-data-grid-premium';

export default function RegisterRowsDialog({ open, onClose, registerId, entriesIds, loadingInfo, title = 'Rows' }) {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!registerId || !isArray(entriesIds) || isEmpty(entriesIds)) {
      setRows([]);
      return;
    }

    setLoading(true);
    const body = { registerId, rowsIds: entriesIds, convertToText: true };
    axios
      .post('/registers/general/getRegisterRowsByIds', body)
      .then((res) => {
        if (isArray(res.data?.rows) && !isEmpty(res.data?.rows)) {
          setRows(res.data.rows);
        } else {
          setRows([]);
        }
        if (isArray(res.data?.columns) && !isEmpty(res.data?.columns)) {
          setColumns(res.data.columns);
        } else {
          setColumns([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [registerId, entriesIds]);

  const gridColumns = useMemo(() => {
    const result = [
      {
        width: 50,
        headerName: '#',
        field: 'index',
        type: 'number',
      },
      ...(isArray(columns) ? columns : []).map((field) => ({
        headerName: field.title,
        field: `textData.${field.id}`,
        width: 250,
        valueGetter: ({ row }) => row.textData?.[field.id],
      })),
    ];

    return result;
  }, [columns]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth className="scroll-bar">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {loading || loadingInfo ? (
          <Grid container justifyContent="center" alignItems="center" style={{ height: '75vh' }}>
            <CircularProgress color="primary" />
          </Grid>
        ) : isEmpty(rows) ? (
          <Grid container style={{ height: '75vh' }} justifyContent="center" alignItems="center">
            <Typography color="textSecondary" variant="h5">
              No entries
            </Typography>
          </Grid>
        ) : (
          <Grid container style={{ height: '75vh' }}>
            <DataGridPremium
              disableAggregation
              disableRowGrouping
              getRowId={(row) => row._id}
              columns={gridColumns}
              rows={rows}
              disableRowSelectionOnClick
              onRowClick={({ row }) => history.push(`/registers/${registerId}?rowId=${row._id}`)}
            />
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
