import React, { useEffect, useState } from 'react';
import { Grid, Typography, ListItemButton, ListItem } from '@mui/material';
import { grey, green, blue } from '@mui/material/colors';
import { Skeleton } from '@mui/material';
import { FormIcon } from '../../../Global/Icons';
import axios from 'axios';
import { MarkunreadMailboxRounded } from '@mui/icons-material';
import { HtmlTooltip } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import { isArray, isEmpty } from 'lodash';

export default function StepForms({ ids }) {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isArray(ids) || isEmpty(ids)) {
      setEntries([]);
      return;
    }
    setLoading(true);
    axios
      .post('/forms/entries/getEntriesByIds', { ids })
      .then((res) => {
        setLoading(false);
        setEntries(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [ids]);

  function onFormComplete(entry) {
    if (!entry?._id) return;
    const index = entries.findIndex((x) => x._id === entry._id);
    if (index === -1) return;
    const copy = [...entries];
    copy[index] = { ...copy[index], completedAt: entry.completedAt };
    setEntries(copy);
  }

  if (!isArray(ids) || isEmpty(ids)) return null;

  if (loading) {
    return (
      <Grid container spacing={2}>
        {ids.map((id) => (
          <Grid container item key={id}>
            <ListItem key={id} style={{ border: `1px solid ${grey[300]}`, borderRadius: 8, width: '100%' }}>
              <Grid container alignItems="center" style={{ padding: '8px 0' }}>
                <Skeleton variant="circular" width={24} height={24} />
                <Typography style={{ marginLeft: 16 }} variant="h6">
                  <Skeleton width={100} />
                </Typography>
              </Grid>
            </ListItem>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {entries.map((entryInfo) => (
        <Grid container item key={entryInfo._id}>
          <ListItemButton
            style={{
              borderRadius: 8,
              border: entryInfo
                ? entryInfo.completedAt
                  ? `3px solid ${green[600]}`
                  : `3px solid ${blue[600]}`
                : `1px solid ${grey[300]}`,
              background: entryInfo?.completedAt ? green[50] : undefined,
            }}
            onClick={() => StateManager.openFormEntry(entryInfo._id, onFormComplete)}
          >
            <Grid container alignItems="center" style={{ padding: '8px 0' }}>
              <FormIcon />
              <Typography style={{ marginLeft: 16 }} variant="h6">
                {entryInfo.title}
                {'  '}
                <span style={{ color: grey[500] }}>- entry #{entryInfo.number}</span>
              </Typography>
              {entryInfo.invitationEmailSent && (
                <HtmlTooltip
                  title={`Form has been ${entryInfo.completedAt ? 'completed by' : 'sent to'} ${
                    entryInfo.invitationEmail
                  }`}
                >
                  <MarkunreadMailboxRounded style={{ color: grey[500], marginLeft: 'auto' }} />
                </HtmlTooltip>
              )}
            </Grid>
          </ListItemButton>
        </Grid>
      ))}
    </Grid>
  );
}
