import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Layout from '../Global/Layout';

import moment from 'moment';
import axios from 'axios';
import FormatNotification from '../Global/Notifications/notificationFormatter';
import { List, useTheme, CircularProgress, Button, Grid, ListItem, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { WS_BASE_URL } from '../../constants';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { Typography, useMediaQuery } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useHistory } from 'react-router-dom';
import { DateFormatter } from '../Global/Components';
import useNotifications from '../Global/Notifications/useNotifications';
import DoneAllIcon from '@mui/icons-material/DoneAll';

function Notification(props) {
  function acceptTask() {
    props.read(props.notification._id);
    if (props.notification.body.taskId) {
      axios
        .post('/tasks/saveTaskAcceptance', {
          taskId: props.notification.taskId,
          accept: true,
        })
        .catch(console.error);
    }
  }

  var button =
    props.notification.type === 'newTask' && !props.notification?.body?.toCreator ? (
      <Button variant="outlined" sx={{ ml: 'auto' }} onClick={acceptTask}>
        Accept
      </Button>
    ) : (
      <Button variant="outlined" sx={{ ml: 'auto' }} onClick={() => props.read(props.notification._id)}>
        Read
      </Button>
    );

  var icon =
    props.notification.type === 'newTask' && props.notification.taskAcceptance === 'false' ? (
      <CloseIcon style={{ color: 'red' }} /> // cross
    ) : (
      <DoneIcon style={{ color: 'blue' }} />
    ); // tick

  const titleStyles = { fontSize: 22, fontWeight: 600 };
  const borderStyles = { width: '100%', borderBottom: '4px solid #636363', padding: '4% 0px 1em 0.7em' };

  return (
    <>
      {props.notification.firstToday && (
        <div style={borderStyles}>
          <span style={titleStyles}>Today</span>
        </div>
      )}
      {props.notification.firstEarlier && (
        <div style={borderStyles}>
          <span style={titleStyles}>Earlier</span>
        </div>
      )}
      <ListItem
        disableGutters={props.mobile}
        sx={
          !props.notification.firstToday && !props.notification.firstEarlier ? { borderTop: '3px solid #d0d0d0aa' } : ''
        }
      >
        <Grid container item xs={12} sx={{ py: 1 }} spacing={1}>
          <Grid item container lg={3} md={3} sm={12}>
            <Typography style={{ fontSize: 15, color: '#304ffe' }}>
              <DateFormatter date={props.notification.createdAt} />
            </Typography>
          </Grid>
          <Grid item container lg={9} sm={12}>
            {FormatNotification({
              notification: props.notification,
              read: props.read,
              clear: props.clear,
              hideButton: props.hideButton,
              unread: props.unread,
            })}
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
}

export default function Notifications() {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const history = useHistory();
  const socket = useRef(); // WS connection
  const [notifications, setNotifications] = useState([]);
  const currNotifications = useRef([]); // to store data
  const [loading, setLoading] = useState(true);
  const { clear } = useNotifications();
  const [tab, setTab] = useState('unread');
  const TASK_MENTION = 'taskMention';
  const MENTION_EVENT = 'mentionEvent';

  const tabList = [
    {
      label: 'Unread',
      value: 'unread',
    },
    {
      label: 'Mentions',
      value: 'task_mention',
    },
    {
      label: 'All',
      value: 'all',
    },
  ];

  function groupNotifications(list) {
    var res = list;
    res.forEach((element) => {
      element.firstToday = null;
      element.firstEarlier = null;
    });
    if (res[0] && moment(res[0].createdAt).isSame(moment(), 'day')) res[0].firstToday = true;
    for (var i = 0; i < res.length; ++i) {
      if (!moment(res[i].createdAt).isSame(moment(), 'day')) {
        res[i].firstEarlier = true;
        break;
      }
    }
    return res;
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    const NEW_NOTIFICATION_EVENT = 'newNotification';
    const NOTIFICATION_UPDATE = 'notificationUpdate';

    import('socket.io-client').then(({ io }) => {
      if (socket.current) return;

      socket.current = io(`${WS_BASE_URL}/notifications`, {
        query: {
          token,
        },
        transports: ['websocket'],
        secure: true,
      });

      // Processing notifications
      socket.current.on(NEW_NOTIFICATION_EVENT, (notification) => {
        currNotifications.current.unshift(notification);
        const list = groupNotifications(currNotifications.current);
        currNotifications.current = list;
        setNotifications([...list]);
      });

      // Processing notification updates
      socket.current.on(NOTIFICATION_UPDATE, (notification) => {
        const index = currNotifications.current.findIndex((x) => x._id === notification._id);
        if (index > -1) {
          currNotifications.current[index] = notification;
          setNotifications([...currNotifications.current]);
        }
      });
    });

    // load notifications
    axios
      .get('/notifications/getAll', {
        params: {
          loadCleared: true,
        },
      })
      .then((res) => {
        const list = groupNotifications(res.data);
        currNotifications.current = list;
        setNotifications(list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (!notifications || notifications.length === 0) return;
    const unreadCount = notifications.filter((x) => !x.readAt).length;
    if (unreadCount > 0) document.title = `(${unreadCount}) Notifications`;
    else document.title = 'Notifications';
  }, [notifications]);

  const read = (id) => {
    if (id) {
      axios.post('/notifications/markAsRead', { ids: [id] });
      const index = currNotifications.current.findIndex((x) => x._id === id);
      if (index > -1) {
        currNotifications.current[index].readAt = new Date();
        setNotifications([...currNotifications.current]);
      }
    } else {
      let ids = currNotifications.current.filter((x) => !x.readAt && x.type !== 'newTask').map((x) => x._id);
      //let ids = currNotifications.current.filter(x => !x.readAt).map(x => x._id);
      axios.post('/notifications/markAsRead', { ids });
      currNotifications.current.forEach((x) => {
        if (x.type !== 'newTask') x.readAt = new Date();
      });
      setNotifications([...currNotifications.current]);
    }
  };

  const unread = (id) => {
    if (id) {
      axios.post('/notifications/markAsUnread', { ids: [id] });
      const index = currNotifications.current.findIndex((x) => x._id === id);
      if (index > -1) {
        currNotifications.current[index].readAt = null;
        currNotifications.current[index].clearedAt = null;
        setNotifications([...currNotifications.current]);
      }
    } else {
      let ids = currNotifications.current.filter((x) => x.readAt && x.type !== 'newTask').map((x) => x._id);
      //let ids = currNotifications.current.filter(x => !x.readAt).map(x => x._id);
      axios.post('/notifications/markAsUnread', { ids });
      currNotifications.current.forEach((x) => {
        if (x.type !== 'newTask') {
          x.readAt = null;
          x.clearedAt = null;
        }
      });
      setNotifications([...currNotifications.current]);
    }
  };

  return (
    <Layout
      icon={<NotificationsActiveOutlinedIcon style={{ color: '#4a4e54' }} />}
      title="Notifications"
      hideNotificationIcon={true}
    >
      <Grid container direction="column">
        {/* {!notifications[0] && !loading && (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <span style={{ fontSize: 18 }}>You don't have any notifications</span>
          </div>
        )} */}
        {loading ? (
          <div style={{ width: '100%', display: 'flex' }}>
            <CircularProgress style={{ margin: '2em auto', color: '#1e88e5' }} />
          </div>
        ) : (
          <Grid item container justifyContent="center">
            <Grid item container>
              <Grid container item lg={6} md={6} sm={12}>
                <Typography style={{ marginLeft: theme.spacing(1) }} variant="h5">
                  Notifications
                </Typography>
              </Grid>
              <Grid
                item
                container
                lg={6}
                md={6}
                sm={12}
                justifyContent={largeDevices ? 'flex-end' : 'space-between'}
                alignItems="center"
                style={{ padding: largeDevices ? 0 : theme.spacing(1, 0) }}
              >
                {notifications.filter((x) => !x.readAt)[0] && (
                  <Button
                    onClick={() => read()}
                    variant="outlined"
                    style={{ border: '2px solid lightgray', textTransform: 'none', borderRadius: '0.5rem' }}
                  >
                    <DoneAllIcon sx={{ fontSize: 20, marginRight: 1 }} />
                    Mark all as read
                  </Button>
                )}
                <Button
                  variant="outlined"
                  style={{
                    border: '2px solid lightgray',
                    marginLeft: '1rem',
                    textTransform: 'none',
                    borderRadius: '0.5rem',
                  }}
                  startIcon={<SettingsOutlinedIcon />}
                  onClick={() => history.push('/notifications/settings')}
                >
                  Settings
                </Button>
              </Grid>
            </Grid>
            <Grid item container lg={8} md={10} sm={12} justifyContent="center">
              <Tabs indicatorColor="primary" textColor="primary" value={tab} sx={{ mb: 1, width: '100%' }}>
                {tabList?.map((tab) => (
                  <Tab label={tab.label} value={tab.value} onClick={() => setTab(tab.value)} />
                ))}
              </Tabs>
              <List dense={!largeDevices} style={{ width: '100%' }}>
                {tab === 'all' &&
                  (notifications?.length > 0 ? (
                    notifications.map((notification) => (
                      <Notification
                        notification={notification}
                        mobile={!largeDevices}
                        key={notification._id}
                        read={read}
                        clear={clear}
                        hideButton={true}
                        unread={unread}
                      />
                    ))
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <span style={{ fontSize: 18 }}>You don't have any notifications</span>
                    </div>
                  ))}
                {tab === 'unread' &&
                  (notifications?.filter((x) => !x.readAt)?.length > 0 ? (
                    notifications
                      .filter((x) => !x.readAt)
                      .map((notification) => (
                        <Notification
                          notification={notification}
                          mobile={!largeDevices}
                          key={notification._id}
                          read={read}
                          clear={clear}
                          hideButton={true}
                          unread={unread}
                        />
                      ))
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <span style={{ fontSize: 18 }}>You don't have any notifications</span>
                    </div>
                  ))}
                {tab === 'task_mention' &&
                  (notifications?.filter((data) => [TASK_MENTION, MENTION_EVENT].includes(data.type))?.length > 0 ? (
                    notifications
                      .filter((data) => [TASK_MENTION, MENTION_EVENT].includes(data.type))
                      .map((notification) => (
                        <Notification
                          notification={notification}
                          mobile={!largeDevices}
                          key={notification._id}
                          read={read}
                          clear={clear}
                          hideButton={true}
                          unread={unread}
                        />
                      ))
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <span style={{ fontSize: 18 }}>You don't have any notifications</span>
                    </div>
                  ))}
              </List>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
