import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { ListItemIcon, ListItemText, Fade } from '@mui/material';
import { FiberManualRecord, ExpandMore, ExpandLess, CancelOutlined, ClearOutlined } from '@mui/icons-material';
import { isArray, isEmpty } from 'lodash';
import { useTheme } from '@mui/material';
import { blue, grey, green, amber, red, deepPurple } from '@mui/material/colors';

const defaultStatuses = [
  { text: 'Not Started', color: grey[300] },
  { text: 'Started', color: deepPurple[500] },
  { text: 'Working', color: blue[500] },
  { text: 'Held', color: amber[500] },
  { text: 'Complete', color: green[500] },
];

export default function StatusButton({
  status,
  setStatus,
  isDisabled,
  isOverdue,
  style,
  onCancel,
  onFail,
  projectStatuses,
  ...props
}) {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [statusChangeMenu, setStatusChangeMenu] = useState(null);
  const [ownStatus, setOwnStatus] = useState('Not Selected');
  const [statuses, setStatuses] = useState([...defaultStatuses]);
  const [options, setOptions] = useState(false);

  useEffect(() => {
    setStatuses(isArray(projectStatuses) && !isEmpty(projectStatuses) ? [...projectStatuses] : [...defaultStatuses]);
  }, [projectStatuses]);

  useEffect(() => {
    if (status) setOwnStatus(status);
  }, [status]);

  function openMenu(event) {
    event.stopPropagation();
    setStatusChangeMenu(event.currentTarget);
  }

  function getBackgroundColor(text) {
    if (text === 'Overdue' || text === 'Cancelled' || text === 'Failed') return red[500];
    return statuses.find((x) => x.text === text)?.color || grey[200];
  }

  function getTextColor(text) {
    if (text === 'Overdue' || text === 'Cancelled' || text === 'Failed') return 'white';
    return theme.palette.getContrastText(getBackgroundColor(text));
  }

  return (
    <>
      <Button
        size={largeDevices ? 'medium' : 'small'}
        disabled={isDisabled}
        style={{
          textTransform: 'none',
          minWidth: largeDevices ? '130px' : '100px',
          border: isOverdue ? `2px solid ${red[800]}` : '',
          fontSize: 14,
          backgroundColor: getBackgroundColor(ownStatus),
          color: getTextColor(ownStatus),
          ...style,
        }}
        onClick={openMenu}
        {...props}
      >
        {ownStatus}
      </Button>

      <Menu
        anchorEl={statusChangeMenu}
        open={Boolean(statusChangeMenu)}
        onClose={(event) => {
          event.stopPropagation();
          setStatusChangeMenu(null);
        }}
        TransitionComponent={Fade}
        keepMounted
      >
        {statuses
          .filter((st) => !st.overdue)
          .map((st, i) => (
            <MenuItem
              key={i}
              onClick={(event) => {
                event.stopPropagation();
                setStatusChangeMenu(null);
                setStatus(st.text);
                setOwnStatus(st.text);
              }}
            >
              <ListItemIcon>
                <FiberManualRecord style={{ color: st.color }} />
              </ListItemIcon>
              <ListItemText primary={st.text} />
            </MenuItem>
          ))}
        {onCancel && onFail && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              setOptions(!options);
            }}
          >
            <ListItemIcon>
              {options ? <ExpandLess style={{ color: grey[600] }} /> : <ExpandMore style={{ color: grey[600] }} />}
            </ListItemIcon>
            <ListItemText primary={options ? 'Less options' : 'More options'} />
          </MenuItem>
        )}
        {options && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              setStatusChangeMenu(null);
              onCancel();
            }}
          >
            <ListItemIcon>
              <CancelOutlined style={{ color: red[500] }} />
            </ListItemIcon>
            <ListItemText primary="Cancelled" />
          </MenuItem>
        )}
        {options && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              setStatusChangeMenu(null);
              onFail();
            }}
          >
            <ListItemIcon>
              <ClearOutlined style={{ color: red[500] }} />
            </ListItemIcon>
            <ListItemText primary="Failed" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
