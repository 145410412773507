import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Typography, Paper, IconButton, Button, TextField, CircularProgress, useTheme } from '@mui/material';
import { DialogActions, DialogContent, DialogTitle, useMediaQuery, Chip } from '@mui/material';
import { User, RoundedDialog, DateFormatter } from '../Components';
import { DeleteOutline, DoneRounded, CloseRounded, CancelOutlined } from '@mui/icons-material';
import { CheckCircleOutlineRounded, HighlightOffRounded, PersonAddRounded } from '@mui/icons-material';
import { grey, red, green } from '@mui/material/colors';
import { FormatDate } from '../Functions';
import StateManager from '../StateManager';
import axios from 'axios';
import moment from 'moment';
import { isArray, isFunction, isString } from 'lodash';
import { useSelector } from 'react-redux';

export default function Approval({
  id,
  approval,
  activityId,
  linkOnStateChange,
  elevation = 2,
  onLinkResponse,
  onChange,
  title = 'Approvals',
  variant = 'elevation',
  disablePaper,
}) {
  const [approvalInfo, setApprovalInfo] = useState(null);
  const [approvals, setApprovals] = useState([]);
  const [comment, setComment] = useState('');
  const [saving, setSaving] = useState(false);
  const [editable, setEditable] = useState(false);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const { user } = useSelector(({ profile }) => profile);
  const userId = user?._id;
  const admin = user?.access === 'admin';
  const currentAttempt = approvalInfo?.currentAttempt;

  const attempts = useMemo(() => {
    if (!isArray(approvalInfo?.attempts) || !isArray(approvalInfo?.userApprovals)) {
      return [];
    }

    const result = [];

    for (let i = 0; i < approvalInfo.attempts.length; ++i) {
      const attempt = {
        ...approvalInfo.attempts[i],
        userApprovals: approvalInfo.userApprovals
          .map((userApproval) => ({
            _id: userApproval._id,
            userId: userApproval.userId,
            result: userApproval.approvals.find((x) => x.attempt === approvalInfo.attempts[i]._id),
          })) // leave only users approvals if they have input ot for the current attempt
          .filter(
            (x) =>
              x.result ||
              (approvalInfo.currentAttempt === approvalInfo.attempts[i]._id && approvalInfo.state === 'pending'),
          ),
      };

      result.push(attempt);
    }

    return result.reverse();
  }, [approvalInfo]);

  function submit(userApprovalId, state) {
    if (!comment) {
      StateManager.setErrorAlert('Please provide a comment');
      return;
    }
    setSaving(true);
    axios
      .post('/standardApproval/submitApproval', { approvalId: approvalInfo._id, userApprovalId, comment, state })
      .then((res) => {
        if (approvalInfo.state !== res.data.state) {
          onStateChange(res.data._id, res.data.activityId, res.data.state);
        }
        setApprovalInfo(res.data);
        setApprovals(res.data.userApprovals);
        setSaving(false);
        StateManager.setSuccessAlert('Saved');
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to save');
        setSaving(false);
      });
  }

  function getStateColor(state) {
    if (state === 'rejected') return red[500];
    if (state === 'approved') return green[500];
    return grey[500];
  }

  function onStateChange(approvalId, activityId, approvalState) {
    if (isFunction(onChange)) {
      onChange(approvalState);
    }
    if (!linkOnStateChange || !isString(linkOnStateChange)) return;
    const body = {
      approvalId,
      activityId,
      approvalState,
    };
    axios
      .post(linkOnStateChange, body)
      .then((res) => {
        if (onLinkResponse) onLinkResponse(res);
      })
      .catch(console.error);
  }

  function deleteApproval(userId) {
    StateManager.setConfirm('You are about to remove this user from the approval', () => {
      axios
        .post('/standardApproval/deleteUserApproval', { approvalId: approvalInfo._id, userId })
        .then((res) => {
          setApprovalInfo(res.data);
          setApprovals(res.data.userApprovals);
          StateManager.setSuccessAlert('Approval has been deleted');
        })
        .catch(() => {
          StateManager.setErrorAlert('Failed to delete the approval');
        });
    });
  }

  function cancelApproval(userId) {
    StateManager.setConfirm('You are about to cancel this approval', () => {
      axios
        .post('/standardApproval/cancelApproval', { approvalId: approvalInfo._id })
        .then((res) => {
          setApprovalInfo(res.data);
          setApprovals(res.data.userApprovals);
          StateManager.setSuccessAlert('Approval has been cancelled');
          onStateChange(res.data._id, res.data.activityId, res.data.state);
        })
        .catch(() => {
          StateManager.setErrorAlert('Failed to cancel the approval');
        });
    });
  }

  function addApproval(userId) {
    axios
      .post('/standardApproval/addUserApproval', { approvalId: approvalInfo._id, userId })
      .then((res) => {
        setApprovalInfo(res.data);
        setApprovals(res.data.userApprovals);
        StateManager.setSuccessAlert('Approval has been added');
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to add the approval');
      });
  }

  useEffect(() => {
    if (!approvalInfo) return;
    let value = approvalInfo.state === 'pending';
    if (value) {
      let index = approvals.findIndex((x) => x.userId === userId);
      if (index > -1) {
        if (approvals[index].approvals.find((x) => x.attempt === approvalInfo.currentAttempt)) {
          value = false;
        }
      } else {
        value = false;
      }
    }
    setEditable(value);
    if (approvalInfo.allApprovalUsers && approvalInfo.allApprovalUsers.length > 0) {
      setUsersToAdd(approvalInfo.allApprovalUsers.filter((x) => !approvals.find((y) => y.userId === x)));
    } else {
      setUsersToAdd([]);
    }
  }, [approvalInfo, approvals]); // eslint-disable-line

  useEffect(() => {
    if (approval?.userApprovals) {
      //if (approvalInfo) return;
      setApprovalInfo(approval);
      setApprovals(approval.userApprovals);
    } else if (id) {
      axios
        .get('/standardApproval/getApprovalById', { params: { id } })
        .then((res) => {
          setApprovalInfo(res.data);
          setApprovals(res.data.userApprovals);
          setComment('');
        })
        .catch(() => {
          setApprovalInfo(null);
          setApprovals([]);
          setComment('');
        });
    } else if (activityId) {
      axios
        .get('/standardApproval/getApprovalByActivityId', { params: { activityId } })
        .then((res) => {
          setApprovalInfo(res.data);
          setApprovals(res.data.userApprovals);
          setComment('');
        })
        .catch(() => {
          setApprovalInfo(null);
          setApprovals([]);
          setComment('');
        });
    } else {
      setApprovalInfo(null);
      setApprovals([]);
    }
  }, [id, approval, activityId]); // eslint-disable-line

  if (!approvalInfo) {
    return (
      <Grid
        container
        sx={{ height: 200 }}
        component={disablePaper ? undefined : Paper}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container sx={{ my: 1 }}>
      <Grid
        container
        component={disablePaper ? undefined : Paper}
        variant={disablePaper ? undefined : variant}
        elevation={disablePaper ? undefined : elevation}
        sx={{ p: disablePaper ? 0 : 1 }}
      >
        <Grid container sx={{ p: 1 }}>
          <Grid container sx={{ mb: 2 }}>
            <Typography variant="h5">{title}</Typography>

            <Grid sx={{ ml: 'auto' }} />
            {approvalInfo.dueAt && approvalInfo.state === 'pending' && (
              <Typography
                style={{
                  marginRight: 16,
                  fontWeight: moment(approvalInfo.dueAt) < moment() ? 500 : 400,
                  color: moment(approvalInfo.dueAt) < moment() ? red[600] : undefined,
                }}
              >{`Due ${FormatDate(approvalInfo.dueAt)}`}</Typography>
            )}
            {approvalInfo.state === 'approved' && (
              <Chip
                sx={{
                  '& .MuiChip-label': {
                    fontSize: 18,
                  },
                }}
                color="success"
                icon={<DoneRounded fontSize="large" />}
                label={`Completed ${FormatDate(approvalInfo.decidedAt)}`}
              />
            )}
            {approvalInfo.state === 'rejected' && (
              <Chip
                sx={{
                  '& .MuiChip-label': {
                    fontSize: 18,
                  },
                }}
                color="error"
                icon={<CloseRounded fontSize="large" />}
                label={`Rejected ${FormatDate(approvalInfo.decidedAt)}`}
              />
            )}
            {approvalInfo.state === 'cancelled' && (
              <Chip
                sx={{
                  '& .MuiChip-label': {
                    fontSize: 18,
                  },
                }}
                color="warning"
                icon={<CloseRounded fontSize="large" />}
                label={`Cancelled ${FormatDate(approvalInfo.cancelledAt)}`}
              />
            )}
            {(userId === approvalInfo.createdBy || admin) && approvalInfo.state === 'pending' && (
              <Button sx={{ color: red[500] }} onClick={cancelApproval} startIcon={<CancelOutlined />}>
                Cancel approval
              </Button>
            )}
          </Grid>
          {approvalInfo.reason && (
            <Grid container sx={{ mb: 2 }} wrap="nowrap">
              <Typography style={{ whiteSpace: 'break-spaces' }}>
                <strong>Reason for approval:</strong> {approvalInfo.reason}
              </Typography>
            </Grid>
          )}

          <Grid container>
            {attempts.map((attempt, i) => (
              <Grid container key={attempt._id} sx={{ mb: 2 }}>
                {attempts.length > 1 && (
                  <Grid container alignItems={'center'}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: 18 }}>
                      Attempt #{attempts.length - i}
                    </Typography>
                    <Typography sx={{ fontSize: 14, ml: 'auto' }} color="textSecondary">
                      Started <DateFormatter date={attempt.createdAt} formatFunction={FormatDate} />
                    </Typography>
                  </Grid>
                )}
                {isArray(attempt.userApprovals) &&
                  attempt.userApprovals.map((userApproval) => (
                    <Grid
                      container
                      key={userApproval._id}
                      component={Paper}
                      variant="outlined"
                      sx={{ my: 1, py: 1, px: 1.5 }}
                    >
                      <Grid container alignItems={'center'}>
                        <User id={userApproval.userId} avatarSize={32} />

                        <Chip
                          sx={{
                            '& .MuiChip-label': {
                              textTransform: 'capitalize',
                            },
                            ml: 'auto',
                          }}
                          color={
                            userApproval.result?.state === 'rejected'
                              ? 'error'
                              : userApproval.result?.state === 'approved'
                              ? 'success'
                              : 'default'
                          }
                          label={userApproval.result?.state || 'pending'}
                        />

                        {(userId === approvalInfo.createdBy || admin) &&
                          currentAttempt === attempt._id &&
                          !userApproval.result && (
                            <IconButton sx={{ ml: 1 }} onClick={() => deleteApproval(userApproval.userId)}>
                              <DeleteOutline style={{ color: red[500] }} />
                            </IconButton>
                          )}
                      </Grid>
                      {((userId === userApproval.userId && editable && currentAttempt === attempt._id) ||
                        userApproval.result?.comment) && (
                        <>
                          <Grid container sx={{ pt: 1 }} direction={'column'}>
                            {userId === userApproval.userId && editable && currentAttempt === attempt._id ? (
                              <TextField
                                label="Leave a comment"
                                variant="outlined"
                                value={comment}
                                multiline
                                minRows={3}
                                maxRows={10}
                                fullWidth
                                onChange={(e) => setComment(e.target.value)}
                                id="approval-comment"
                                sx={{ mt: 1 }}
                              />
                            ) : (
                              <>
                                <Typography sx={{ whiteSpace: 'break-spaces' }}>
                                  {userApproval.result?.comment}
                                </Typography>
                                <Typography sx={{ fontSize: 13, mt: 1 }} color="textSecondary">
                                  <DateFormatter date={userApproval.result?.createdAt} formatFunction={FormatDate} />
                                </Typography>
                              </>
                            )}
                          </Grid>

                          {userId === userApproval.userId && editable && currentAttempt === attempt._id && (
                            <Grid container sx={{ py: 1 }} justifyContent={'flex-end'}>
                              {saving && <CircularProgress color="primary" size={30} />}
                              {!saving && editable && (
                                <>
                                  <Button
                                    sx={{ mr: 1, color: red[500] }}
                                    startIcon={<HighlightOffRounded />}
                                    onClick={() => submit(userApproval._id, 'rejected')}
                                    disabled={!comment}
                                  >
                                    Reject
                                  </Button>
                                  <Button
                                    style={{ background: green[500] }}
                                    startIcon={<CheckCircleOutlineRounded />}
                                    onClick={() => submit(userApproval._id, 'approved')}
                                    variant="contained"
                                    disabled={!comment}
                                  >
                                    Approve
                                  </Button>
                                </>
                              )}
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  ))}
              </Grid>
            ))}
          </Grid>

          {userId === approvalInfo.createdBy &&
            approvalInfo.state === 'pending' &&
            approvalInfo.allApprovalUsers &&
            approvalInfo.allApprovalUsers.length > 0 &&
            approvalInfo.allApprovalUsers.length > approvals.length && (
              <Grid item container>
                <Button color="primary" onClick={() => setAddDialogOpen(true)} startIcon={<PersonAddRounded />}>
                  Add approval user
                </Button>
              </Grid>
            )}
          {approvalInfo.state === 'pending' &&
            approvalInfo.minUsersToApprove &&
            approvalInfo.minUsersToApprove > approvals.length && (
              <Grid item container>
                <Typography style={{ margin: '0.5rem 0' }}>
                  {`At least ${approvalInfo.minUsersToApprove} users must approve`}
                </Typography>
              </Grid>
            )}
          <NextStepUser
            open={addDialogOpen}
            onClose={() => setAddDialogOpen(false)}
            onResult={addApproval}
            users={usersToAdd}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function NextStepUser({ open, onClose, onResult, users }) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={users.length === 0 ? 'sm' : 'xs'} fullWidth>
      <DialogTitle>Select user for approval</DialogTitle>
      <DialogContent>
        <Grid container style={{ margin: '1rem 0' }}>
          {users.map((user) => (
            <User
              key={user}
              id={user}
              fullWidth
              onClick={() => {
                onResult(user);
                onClose();
              }}
            />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: red[500] }}>
          cancel
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
