import React from 'react';
import { IconButton } from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { grey } from '@mui/material/colors';

export default function CrossButton({ onClick, style }) {
  return (
    <IconButton onClick={onClick} style={{ width: 32, height: 32, ...style }}>
      <CloseRounded style={{ color: grey[500] }} />
    </IconButton>
  );
}
